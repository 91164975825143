import {config} from "@/lib/config";

export const vacancy = {
    actions: {
        deleteVacancy({getters}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'employer/vacancy/delete/' + payload.id;
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            // req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send();
                payload && payload.callback ? payload.callback() : null;
            } catch (e) {
                console.log(e);
            }
        },
        separateVacancy({commit}, vacancyId) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + "vacancy/get/" + vacancyId;
            xmlHttp.open('GET', url, false)
            xmlHttp.send(vacancyId);
            try {
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setSeparateVacancy', data)
        },
        familiarVacancy({commit}, vacancyId) {
            let data = [];
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + "vacancy/familiar/" + vacancyId;
            xmlHttp.open('GET', url, false)
            xmlHttp.send(vacancyId);
            try {
                let response = JSON.parse(xmlHttp.response);
                for (let i = 0; i < data.length; i++) {
                    data.push(response.data[i]);
                }
                data = data.slice(0, 3);

            } catch (e) {
                console.log(e);
            }
            commit('setFamiliarVacancy', data)
        },

				getIndustries({commit}, locale){
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'employer/industries/all?lang='+locale;
					request.open("GET", url, false);
					try {
						request.send();
						let response = JSON.parse(request.response);
						commit('setDataIndustries', response.data);
					} catch (e) {
						console.log(e);
					}
				},
				fetchFavoriteVacancy({commit}, payload) {
					if (this.getters.favoriteVacanciesFetched) {
						return; 
					}
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'vacancy/favorite/all';
					request.open("GET", url, true);
					request.setRequestHeader('Content-Type', 'application/json');
					request.setRequestHeader('X-Auth-Token', payload.token);
			
					request.onreadystatechange = function () {
							if (request.readyState === XMLHttpRequest.DONE) {
									if (request.status === 200) {
											let response = JSON.parse(request.response);
											commit('setFavoriteVacancy', response.data);
											commit('setFavoriteVacanciesFetched', true);
									} else {
											console.log('Error:', request.responseText);
									}
							}
					};
			
					request.send();
			},
			getVacancyRecommendationFormat(_, payload) {
				return new Promise((resolve, reject) => {
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'vacancy/recommendation/get/' + payload.id;
					request.open("GET", url, true);
					request.onload = () => {
						if (request.status >= 200 && request.status < 300) {
							const response = JSON.parse(request.responseText);
							resolve(response.data); 
						} else {
							reject('Error: ' + request.statusText);
						}
					};
					request.onerror = () => reject('Error: ' + request.statusText);
					request.send();
				});
			},
			
			addFavoriteVacancy({commit, dispatch}, payload) {
				const request = new XMLHttpRequest();
				const url = config.apiPrefix + 'vacancy/favorite/add';
				request.open("POST", url, true);
				request.setRequestHeader('Content-Type', 'application/json');
				request.setRequestHeader('X-Auth-Token', payload.token);
		
				request.onreadystatechange = function () {
						if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
								dispatch('getVacancyRecommendationFormat', {id: payload.vacancy.id})
										.then(vacancy => {
												commit('addFavoriteVacancy', vacancy);
										})
										.catch(error => console.error('Vacancy not found', error));
						} else if (request.readyState === XMLHttpRequest.DONE) {
								console.error('Error:', request.responseText);
						}
				};
		
				// Отправка запроса с JSON телом
				request.send(JSON.stringify({vacancyId: payload.vacancy.id}));
		},
		
		
			
			removeFavoriteVacancy({commit}, payload){
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'vacancy/favorite/remove';
					request.open("POST", url, true);
					request.setRequestHeader('Content-Type', 'application/json');
					request.setRequestHeader('X-Auth-Token', payload.token);
			
					request.onreadystatechange = function () {
							if (request.readyState === XMLHttpRequest.DONE) {
									// обработка ответа сервера, например:
									if (request.status === 200) {
											commit('removeFavoriteVacancy', payload.vacancy.id);
									} else {
											console.log('Error:', request.responseText);
									}
							}
					};
			
					// Отправка запроса с JSON телом
					request.send(JSON.stringify({vacancyId: payload.vacancy.id}));
			},
			

    },
    mutations: {
        setSeparateVacancy(state, data) {
            state.vacancy = data;
        },
        setFamiliarVacancy(state, data) {
            state.familiarVacancy = data;
        },
        setResponseVacancy(state, data) {
            state.responseVacancy = data;
        },
		setDataIndustries(state, data){
			state.dataIndustries = data;
		},
		setFavoriteVacancy(state, data){
			state.favoriteVacancy = data;
		},
		addFavoriteVacancy(state, data) {
		// Check if the id is not already in the array to prevent duplicates
		if (!state.favoriteVacancy.includes(data)) {
			state.favoriteVacancy.push(data);
		}
		},
		setFavoriteVacanciesFetched(state, fetched) {
			state.favoriteVacanciesFetched = fetched;
		},
			
		removeFavoriteVacancy(state, data) {
			state.favoriteVacancy = state.favoriteVacancy.filter(item => item.id !== data);
		}
			
    },
    state: {
        vacancy: null,
        familiarVacancy: null,
        responseVacancy: false,
		dataIndustries:[],
		favoriteVacancy:[],
		favoriteVacanciesFetched: false,
    },
    getters: {
        vacancy(state) {
            return state.vacancy;
        },
        familiarVacancy(state) {
            return state.familiarVacancy;
        },
        responseVacancy: state => state.responseVacancy,
				dataIndustries: state => state.dataIndustries,
				favoriteVacancy: state => state.favoriteVacancy,
				favoriteVacanciesFetched: state => state.favoriteVacanciesFetched,
    }
}