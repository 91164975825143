import {config} from "@/lib/config";

const {convert} = require('html-to-text');


export const blog = {
    actions: {
        fetchDefaultBlogs({commit}, page) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+"article/get";
            xmlHttp.open('GET', url + '?page=' + page, false);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }

            commit('setBlogs', {data: data, category: false, page: page});
        },

        fetchCategoryBlogs({commit}, payload) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let params = '?page=' + payload.page + '&category=' + payload.category;
            let url = config.apiPrefix+"article/get";
            xmlHttp.open('GET', url + params, false);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setBlogs', {data: data, category: true, page: payload.page});
        },

        fetchCategory({commit}) {
            let data;
            let xmlHttp = new XMLHttpRequest()
            let url = config.apiPrefix+"article/categories"
            xmlHttp.open("GET", url, false);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e)
            }
            commit('setCategory', data);
        },


        fetchArticles({commit}, articlesId) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+'article/' + articlesId
            xmlHttp.open('GET', url, false);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setArticles', data);
        },
        fetchFamiliarArticles({commit}, category) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+'article/get'
            xmlHttp.open('GET', url + '?page=1&category=' + category, false);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setFamiliarArticles', data);

        }
    },
    mutations: {
        setArticles(state, data) {
            state.articles = data;
        },
        setBlogs(state, payload) {
            for (let i = 0; i < payload.data.length; i++) {
                payload.data[i].body = convert(payload.data[i].body, {
                    selectors: [{
                        selector: 'img',
                        format: 'skip'
                    }, {selector: 'a', format: 'skip'}]
                })
            }
            state.display = payload.data.length >= 20;
            if (!payload.category) {
                if (payload.page === 1)
                    state.allBlog = payload.data.slice(4);
                else {
                    state.allBlog = state.allBlog.concat(payload.data);
                }
            } else {
                if (payload.page === 1) {
                    state.allBlog = payload.data;
                } else {
                    state.allBlog = state.allBlog.concat(payload.data);
                }
            }
        },
        setFourBlogs(state) {
            state.fourBlogs = state.allBlog.slice(0, 4)
        },
        setCategory(state, data) {
            state.category = data
        },
        addBlog(state, data) {
            state.allBlog = data;
        },
        nextPage(state) {
            state.page += 1;
        },
        setFamiliarArticles(state, data) {
            state.familiarArticles = data;
        },

        displayHelpQuestions(state, id) {
            state.displayHelpQuestion[id] = !state.displayHelpQuestion[id]
        }
    },
    state: {
        allBlog: null,
        category: null,
        fourBlogs: null,
        display: true,
        articles: null,
        familiarArticles: null,

        displayHelpQuestion: [false, false, false, false]
    },
    getters: {
        fourBlogs(state) {
            return state.fourBlogs;
        },
        allBlog(state) {
            return state.allBlog
        },
        category(state) {
            return state.category;
        },
        display(state) {
            return state.display;
        },

        articles(state) {
            return state.articles;
        },
        familiarArticles(state) {
            return state.familiarArticles
        },

        displayHelpQuestion(state) {
            return state.displayHelpQuestion
        }
    }
}