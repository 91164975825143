<template>
	<div class="recomedation__wrapper-element" @click="goToPage" style="cursor: pointer;">
		<div class="recomedation__wrapper-element-container1">
			<div class="recomedation__wrapper-element-container1-company">
				<div class="recomedation__wrapper-element-container1-company-icon">
					<img :src="vacancy.companyAvatarUrl" alt=""
						class="recomedation__wrapper-element-container1-company-icon-img" />
				</div>
				<div class="recomedation__wrapper-element-container1-company-name">
					<p class="recomedation__wrapper-element-container1-company-name-disignation">
						{{ $t('components.landing.separateVacancy.company') }}
					</p>
					<p class="recomedation__wrapper-element-container1-company-name-gang">
						{{ vacancy.companyName ? vacancy.companyName : "Untitled" }}
					</p>
					<div class="recomedation__wrapper-element-container2-rating-part sumarry-mobile">
						<p class="recomedation__wrapper-element-container2-rating-part-number">
							{{ vacancy.companyRate ? vacancy.companyRate : 0 }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="recomedation__wrapper-element-container2 desctop-recomendation">
			<div class="recomedation__wrapper-element-container2-rating">
				<p class="recomedation__wrapper-element-container2-rating-name">
					{{ $t('components.landing.separateVacancy.rating') }}
				</p>
				<div class="recomedation__wrapper-element-container2-rating-part">
					<p class="recomedation__wrapper-element-container2-rating-part-number">
						{{ vacancy.companyRate ? vacancy.companyRate : 0 }}
					</p>
				</div>
			</div>
		</div>
		<div class="recomedation__wrapper-element-container3">
			<div class="recomedation__wrapper-element-container3-specification">
				<h3 class="recomedation__wrapper-element-container3-specification-title">
					{{ vacancy.vacancyName ? vacancy.vacancyName : "Untitled" }}
				</h3>
				<p class="recomedation__wrapper-element-container3-specification-text">
					{{ limit(vacancy.vacancyDescription, 40) }}
				</p>
			</div>
		</div>
		<div class="recomedation__wrapper-element-container4">
			<div class="recomedation__wrapper-element-container4-place">
				<p class="recomedation__wrapper-element-container4-place-town desctop-recomendation">
					{{ $t('components.landing.separateVacancy.city') }}
				</p>
				<p class="recomedation__wrapper-element-container4-place-name">
					{{ vacancy.city ? vacancy.city : "not determined" }}
				</p>
			</div>
		</div>
		<div class="recomedation__wrapper-element-container5">
			<div class="recomedation__wrapper-element-container5-price">
				<p class="recomedation__wrapper-element-container5-price-text desctop-recomendation">
					{{ $t('components.landing.separateVacancy.salary') }}
				</p>
				<p class="recomedation__wrapper-element-container5-price-number">
					{{ vacancy.payFrom }} $
				</p>
			</div>
		</div>
	</div>
</template>


<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import Cookies from "js-cookie";

export default {
	name: "SeparateVacancy",
	props: {
		vacancy: {
			type: Object,
		},
	},
	data() {
		return {
			limit: textSymbolLimit
		};
	},
	methods: {
		goToPage() {
			switch (this.$store.getters.role) {
				case "CANDIDATE":
					this.$router.push({
						name: "vacancyDashboard",
						params: { vacancyId: this.vacancy.id },
					});
					break;
				case "EMPLOYER":
					Cookies.set("vacancyId", this.vacancy.id);
					this.$router.push({ name: "candidateLogin" });
					break;
				case null:
					Cookies.set("vacancyId", this.vacancy.id);
					this.$router.push({ name: "candidateLogin" });
					break;
			}
		}
	}
};
</script>

<style scoped>
.recomedation__wrapper-element {
	width: 100%;
	box-sizing: border-box;
	height: 105px;
	align-items: center;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	display: flex;
}

.recomedation__wrapper-element:not(:last-child) {
	margin-bottom: 11px;
}

.recomedation__wrapper-element-container1 {
	flex-shrink: 1;
	overflow: hidden;
	flex-basis: 27%;
}

.recomedation__wrapper-element-container2 {
	flex-shrink: 1;
	overflow: hidden;
	flex-basis: 11%;
}

.recomedation__wrapper-element-container3 {
	flex-shrink: 1;
	overflow: hidden;
	flex-basis: 27%;
}

.recomedation__wrapper-element-container4 {
	flex-shrink: 1;
	overflow: hidden;
	flex-basis: 17.5%;
}

.recomedation__wrapper-element-container5 {
	width: 226px;
	flex-shrink: 1;
	overflow: hidden;
	flex-basis: 17.5%;
}

.recomedation__wrapper-element-container1-company {
	display: flex;
	padding: 25px 0 25px 25px;
	align-items: center;
}

.recomedation__wrapper-element-container1-company-icon-img {
	border-radius: 10px;
	width: 64px;
	height: 64px;
	object-fit: cover;
}

.recomedation__wrapper-element-container1-company-name {
	margin-left: 32px;
}

.recomedation__wrapper-element-container1-company-name-disignation {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container1-company-name-gang {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.recomedation__wrapper-element-container2-rating {
	padding: 25px 10px 25px 25px;
}

.recomedation__wrapper-element-container2-rating-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container2-rating-part {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	margin-top: 4px;
	width: 57px;
}

.recomedation__wrapper-element-container2-rating-part-number {
	background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
	background-size: 11px;
	background-repeat: no-repeat;
	background-position: 9px;
	padding: 0px 9px 0px 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.recomedation__wrapper-element-container3-specification {
	padding: 25px 10px 25px 25px;
}

.recomedation__wrapper-element-container3-specification-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #171625;
}

.recomedation__wrapper-element-container3-specification-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container4-place {
	padding: 25px 10px 25px 25px;
}

.recomedation__wrapper-element-price-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container4-place-name {
	padding-left: 18px;
	background-image: url("~@/assets/img/recommendation-page/recommendation/place.svg");
	background-position: 0;
	background-position-y: center;
	background-repeat: no-repeat;
	background-size: 14px 16px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.recomedation__wrapper-element-container4-place-town {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container5-price {
	padding: 25px 10px 25px 25px;
}

.recomedation__wrapper-element-container5-price-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.recomedation__wrapper-element-container5-price-number {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 17px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.sumarry-mobile {
	display: none;
}

.recomedation__text-sumarry-mobile {
	display: none;
}

@media (max-width: 1100px) {
	.sumarry-mobile {
		display: block;
	}

	.desctop-recomendation {
		display: none;
	}

	.recomedation__wrapper-element {
		width: 300px;
		flex-direction: column;
		display: block;
		height: 100%;
	}

	.recomedation__wrapper-element-container1 {
		width: 100%;
		flex-basis: 100%;
	}

	.recomedation__wrapper-element-container2 {
		width: 100%;
		flex-basis: 100%;
	}

	.recomedation__wrapper-element-container3 {
		width: 100%;
		flex-basis: 100%;
	}

	.recomedation__wrapper-element-container4 {
		width: 100%;
		flex-basis: 100%;
	}

	.recomedation__wrapper-element-container5 {
		width: 100%;
		flex-basis: 100%;
	}

	.recomedation__wrapper-element-container1-company {
		padding: 20px;
		border-bottom: 1px solid #f1f1f5;
	}

	.recomedation__wrapper-element-container2-rating-part {
		margin-top: 0;
	}

	.recomedation__wrapper-element-container3-specification {
		padding: 11px 20px 20px 20px;
	}

	.recomedation__wrapper-element-container4-place {
		padding: 0 20px 11px 20px;
		border-bottom: 1px solid #f1f1f5;
	}

	.recomedation__wrapper-element-container5-price {
		padding: 12px 20px 20px 20px;
	}

	.recomedation__wrapper-element-container1-company-icon {
		margin-top: 3px;
	}

	.recomedation__wrapper-element-container1-company-name-disignation {
		font-size: 12px;
	}

	.recomedation__wrapper-element-container2-rating-part {
		margin-top: 2px;
	}

	.recomedation__wrapper-element-container3-specification-title {
		font-size: 15px;
	}

	.recomedation__wrapper-element-container3-specification-text {
		font-size: 13px;
	}

	.recomedation__wrapper-element {
		margin: 0 auto;
	}
}
</style>
