<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="test">
				<div class="container test-container">
					<div class="test__top">
						<div class="test__top-text">
							<a href="#" class="test__top-logo"><img src="~@/assets/img/all-img/logo-mobile.svg"
									alt="" /></a>
							<h1 class="test__top-text-title">{{ $t('pages.landing.homePage.main.test.topText.title') }}
							</h1>
							<p class="test__top-text-subtitle">
								{{ $t('pages.landing.homePage.main.test.topText.subtitle') }}
							</p>
						</div>
						<div class="test__top-save">
							<div class="test__top-save-roundabout">
								<div class="test__top-save-roundabout-caption">
									<p class="test__top-save-roundabout-caption-number">
										{{ carousel ? iter + 1 + "/8" : "" }}
									</p>
									<div class="test__top-save-roundabout-caption-line">
										<div class="test__top-save-roundabout-caption-line-filling"
											:style="{ maxWidth: (iter + 1) * 12.5 + '%' }"></div>
									</div>
									<div class="test__top-save-roundabout-caption-btns">
										<button class="
                        test__top-save-roundabout-caption-btns-element-back
                      " @click="prevIter"></button>
										<button class="
                        test__top-save-roundabout-caption-btns-element-next
                      " @click="nextIter"></button>
									</div>
								</div>
								<div class="test__top-save-roundabout-filling">
									<div class="test__top-save-roundabout-left">
										<h2 class="test__top-save-roundabout-left-title">
											{{ carousel ? carousel.header : "" }}
										</h2>
										<p class="test__top-save-roundabout-left-text">
											{{ carousel ? carousel.body : "" }}
										</p>
										<button class="
                        test__top-save-roundabout-left-btn
                        desctop-btn-randabout
                      " @click="goToTesting">
											{{ $t('pages.landing.homePage.main.test.topSave.roundaboutLeft.btnDesktop')
											}}
										</button>
									</div>
									<div class="test__top-save-roundabout-right">
										<div class="test__top-save-roundabout-right-img" :class="{
											'roundabout-right-2': iter === 1,
											'roundabout-right-3': iter === 2,
											'roundabout-right-4': iter === 3,
											'roundabout-right-5': iter === 4,
											'roundabout-right-6': iter === 5,
											'roundabout-right-7': iter === 6,
											'roundabout-right-8': iter === 7,
										}" style="transition: background 1s linear"></div>
									</div>
								</div>
								<button class="
                    test__top-save-roundabout-left-btn
                    mobile-btn-randabout
                  " @click="goToTesting">
									{{ $t('pages.landing.homePage.main.test.topSave.roundaboutLeft.btnMobile') }}
								</button>
							</div>
						</div>
						<!-- <div class="test__top-bottom">
              <input placeholder="UX Designer" type="text" class="test__top-bottom-search"
                v-model="searchVacanciesForm.specialization" />
              <div class="search-wrapper">
                <button @click="cityVisible=!cityVisible" class="test__top-bottom-town">
                  {{ searchVacanciesForm.city ? cityName : 'New York' }} <span
                    class="test__top-bottom-town-img"></span></button>
                <div class="search-list" v-if="cityVisible">
                  <input placeholder="City" type="text" @input="e=>searchCity(e.target.value)"
                         class="search-list__element search-list__element-input"/>
                  <button class="search-list__element" @click="pickCity" :value="item.id" v-for="item in dataCities"
                          :key="item.id">{{
                      item.name
                    }}
                  </button>
                </div>
              </div>
              <div class="test__top-bottom-work-container">
                <button ref="select" class="test__top-bottom-work" @click="filtersVisible = !filtersVisible"
                  @blur="blurSelect">
                  Employment type
                </button>
                <div class="test__top-bottom-work-group" v-if="filtersVisible">
                  <input type="radio" id="one" value="0" class="test__top-bottom-work-group-element-input"
                    v-model="searchVacanciesForm.empl_type" @change="pickedEmplType" @click="checkEmpl" />
                  <label for="one" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '0',
                  }">Full employment</label>

                  <input type="radio" id="two" value="1"
                    class="test__top-bottom-work-group-element-input" v-model="searchVacanciesForm.empl_type"
                    @change="pickedEmplType" @click="checkEmpl" />
                  <label for="two" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '1',
                  }">
                    Part-time employment
                  </label>

                  <input type="radio" id="three" value="2" class="test__top-bottom-work-group-element-input"
                    v-model="searchVacanciesForm.empl_type" @change="pickedEmplType" @click="checkEmpl" />
                  <label for="three" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '2',
                  }">
                    Project work
                  </label>

                  <input type="radio" id="four" value="3" class="test__top-bottom-work-group-element-input"
                    v-model="searchVacanciesForm.empl_type" @change="pickedEmplType" @click="checkEmpl" />
                  <label for="four" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '3',
                  }">
                    Internship
                  </label>

                  <input type="radio" id="five" value="4" class="test__top-bottom-work-group-element-input"
                    v-model="searchVacanciesForm.empl_type" @change="pickedEmplType" @click="checkEmpl" />
                  <label for="five" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '4',
                  }">
                    Volunteering
                  </label>

                  <input type="radio" id="six" value="5" class="test__top-bottom-work-group-element-input"
                    v-model="searchVacanciesForm.empl_type" @change="pickedEmplType" @click="checkEmpl" />
                  <label for="six" class="test__top-bottom-work-group-element-label" :class="{
                    select_picked:
                      searchVacanciesForm.empl_type === '5',
                  }">
                    Distant work
                  </label>
                </div>
              </div>

              <button class="test__top-bottom-findwork" @click="searchVacancies">
                Find Jobs
              </button>
            </div> -->
					</div>
				</div>
			</div>

			<div class="bg">


				<!-- <div class="container">
          <div class="applicants">
            <div class="applicants__head">
              <div class="applicants__head-title">
                <a href="#" class="applicants__head-title-vacancy">Job seekers</a>
                <h2 class="applicants__head-title-subtitle">
                  List of verified <br>
                  candidates
                </h2>
              </div>
              <button class="applicants__head-btn btn__blue" @click="goToCandidateSearch">
                Show more
              </button>
            </div>
            <div class="applicants__group">
              <Workers v-for="(item, index) in workers" :key="item.id" :worker="item" :index="index + 1"></Workers>
            </div>
            <button class="applicants__endbtn btn__blue" @click="goToCandidateSearch">
              Show more
            </button>
          </div>
        </div> -->

				<div class="container">
					<div class="suggestions">
						<p class="suggestions__text">{{ $t('pages.landing.homePage.main.bg.suggestions.text') }}</p>
						<div class="suggestions__btngroup">
							<a class="suggestions__btngroup-findwork" @click="goToVacancySearch">{{
								$t('pages.landing.homePage.main.bg.suggestions.btnGroup.findWork') }}</a>
							<a class="suggestions__btngroup-findsworker" @click="goToCandidateSearch">{{
								$t('pages.landing.homePage.main.bg.suggestions.btnGroup.findWorker') }}</a>
						</div>
					</div>
				</div>
			</div>


			<div class="container">
				<div class="jobs">
					<div class="jobs__head">
						<div class="jobs__head-title">
							<a href="#" class="jobs__head-title-vacancy">{{
								$t('pages.landing.homePage.main.jobs.head.title.vacancy') }}</a>
							<h2 class="jobs__head-title-subtitle">
								{{ $t('pages.landing.homePage.main.jobs.head.title.subtitle') }}
							</h2>
						</div>
						<button class="jobs__head-btn btn__blue" @click="goToVacancySearch">
							{{ $t('pages.landing.homePage.main.jobs.head.btn') }}
						</button>
					</div>
					<div class="proposed-group proposed-group-company">
						<DetailedCompany v-show="vacancies" v-for="item in vacancies" :key="item.id" :vacancy="item">
						</DetailedCompany>
					</div>
					<button class="jobs__endbtn btn__blue" @click="goToVacancySearch">
						{{ $t('pages.landing.homePage.main.jobs.head.endBtn') }}
					</button>
				</div>
			</div>

			<div class="mobile-bacground">
				<div class="container">
					<div class="mobileapp">
						<div class="mobileapp__head">
							<a href="#" class="mobileapp__head-title">{{
								$t('pages.landing.homePage.main.mobileApp.head.title') }}</a>
							<h2 class="mobileapp__head-subtitle">
								{{ $t('pages.landing.homePage.main.mobileApp.head.title') }}
							</h2>
						</div>
						<div class="mobileapp__body">
							<div class="mobileapp__body-left">
								<div class="mobileapp__body-left-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/bell.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.left.elements[0].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-left-elemen-info">
										<p class="mobileapp__body-left-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[0].title')
											}}
										</p>
										<p class="mobileapp__body-left-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[0].text') }}
										</p>
									</div>
								</div>
								<div class="mobileapp__body-left-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/check.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.left.elements[1].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-left-elemen-info">
										<p class="mobileapp__body-left-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[1].text') }}
										</p>
										<p class="mobileapp__body-left-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[1].text') }}
										</p>
									</div>
								</div>
								<div class="mobileapp__body-left-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/work.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.left.elements[2].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-left-elemen-info">
										<p class="mobileapp__body-left-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[2].text') }}
										</p>
										<p class="mobileapp__body-left-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.left.elements[2].text') }}
										</p>
									</div>
								</div>
							</div>
							<div class="mobileapp__body-midle"></div>
							<div class="mobileapp__body-right">
								<div class="mobileapp__body-right-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/circle.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.right.elements[0].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-right-elemen-info">
										<p class="mobileapp__body-right-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[0].title')
											}}
										</p>
										<p class="mobileapp__body-right-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[0].text')
											}}
										</p>
									</div>
								</div>
								<div class="mobileapp__body-right-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/chat.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.right.elements[1].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-right-elemen-info">
										<p class="mobileapp__body-right-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[1].title')
											}}
										</p>
										<p class="mobileapp__body-right-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[1].text')
											}}
										</p>
									</div>
								</div>
								<div class="mobileapp__body-right-element">
									<img src="~@/assets/img/landing-img/main-page/mobile/sitings.svg"
										:alt="$t('pages.landing.homePage.main.mobileApp.body.right.elements[2].imgAlt')"
										class="mobileapp__body-left-elemen-img" />
									<div class="mobileapp__body-right-elemen-info">
										<p class="mobileapp__body-right-elemen-info-title">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[2].title')
											}}
										</p>
										<p class="mobileapp__body-right-elemen-info-text">
											{{ $t('pages.landing.homePage.main.mobileApp.body.right.elements[1].text')
											}}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="mobileapp__appstore">
							<a href="https://apps.apple.com/ru/app/the-professional/id1617617850"
								class="mobileapp__appstore-apple"></a>
							<a href="https://play.google.com/store/apps/details?id=com.zkagency.the_professional&pcampaignid=web_share"
								class="mobileapp__appstore-google"></a>
						</div>
					</div>
				</div>
			</div>


			<div class="news">
				<div class="container">
					<div class="news__head">
						<a href="#" class="news__head-title">{{ $t('pages.landing.homePage.main.news.head.title') }}</a>
						<h2 class="news__head-subtitle">{{ $t('pages.landing.homePage.main.news.head.subtitle') }}</h2>
					</div>
					<div class="news__list">
						<div class="news__list-left">
							<div class="news__list-img-container">
								<img :src="fourBlogs[0].files[0]" :alt="$t('pages.landing.homePage.main.news.photo')"
									class="news__list-left-desctopimg" />
								<img :src="fourBlogs[0].files[0]" :alt="$t('pages.landing.homePage.main.news.photo')"
									class="news__list-left-mobileimg" />
							</div>
							<h3 class="news__list-left-title news-title">
								{{ fourBlogs[0].title ? limit(fourBlogs[0].body, 44) :
									$t('pages.landing.homePage.main.news.noData') }}
							</h3>
							<p class="news__list-left-text news-text">
								{{ fourBlogs[0].body ? limit(fourBlogs[0].body, 52) :
									$t('pages.landing.homePage.main.news.noData') }}
							</p>
							<router-link :to="{
								name: 'articles',
								params: { articlesId: fourBlogs[0].id },
							}" class="news__list-left-btn news-more">{{ $t('pages.landing.homePage.main.news.readMore') }}
							</router-link>
						</div>
						<div class="news__list-midle">
							<div class="news__list-img-container">
								<img :src="fourBlogs[1].files[0]" :alt="$t('pages.landing.homePage.main.news.photo')"
									class="news__list-midle-desctopimg" />
								<img :src="fourBlogs[1].files[0]" :alt="$t('pages.landing.homePage.main.news.photo')"
									class="news__list-midle-mobileimg" />
							</div>
							<h3 class="news__list-midle-title news-title">
								{{ fourBlogs[1].title ? limit(fourBlogs[1].title, 44) :
									$t('pages.landing.homePage.main.news.noData') }}
							</h3>
							<p class="news__list-midle-text news-text">
								{{ fourBlogs[1].body ? limit(fourBlogs[1].body, 52) :
									$t('pages.landing.homePage.main.news.noData') }}
							</p>
							<router-link :to="{
								name: 'articles',
								params: { articlesId: fourBlogs[1].id },
							}" class="news__list-midle-btn news-more">{{ $t('pages.landing.homePage.main.news.readMore') }}
							</router-link>
						</div>
						<div class="news__list-right">
							<div class="news__list-right-article1 article-wrapper">
								<h3 class="news__list-right-article1-title news-title">
									{{ fourBlogs[2].title ? limit(fourBlogs[2].title, 40) :
										$t('pages.landing.homePage.main.news.noData') }}
								</h3>
								<router-link :to="{
									name: 'articles',
									params: { articlesId: fourBlogs[2].id },
								}" class="news__list-right-article1-btn news-more">{{ $t('pages.landing.homePage.main.news.readMore') }}
								</router-link>
							</div>
							<div class="news__list-right-article2 article-wrapper">
								<h3 class="news__list-right-article2-title news-title">
									{{ fourBlogs[3].title ? limit(fourBlogs[3].title, 40) :
										$t('pages.landing.homePage.main.news.noData') }}
								</h3>
								<router-link :to="{
									name: 'articles',
									params: { articlesId: fourBlogs[3].id },
								}" class="news__list-right-article2-btn news-more">{{ $t('pages.landing.homePage.main.news.readMore') }}
								</router-link>
							</div>
							<button class="news__list-right-btn btn__blue" @click="$router.push({ name: 'blog' })">
								{{ $t('pages.landing.homePage.main.news.moreNews') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</main>

		<Footer></Footer>
	</div>
</template>

<script>
//import axios from 'axios';
import Header from "../../components/landing/Header.vue";
// import Vacancy from "../../components/landing/Vacancy.vue";
import Footer from "../../components/landing/Footer.vue";
import DetailedCompany
	from "../../components/account/account_worker/account_worker_vacancy/item/DetailedCompany.vue";
// import Workers from "../../components/landing/Workers.vue";
import Cookies from "js-cookie";
import { config } from "@/lib/config";
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
	data() {
		return {
			limit: textSymbolLimit,
			cityName: null,
			cityVisible: false,
			carouselTimer: null,
			showWorkType: false,
			filtersVisible: false,
			workers: null,

			searchVacanciesForm: {
				specialization: null,
				city: null,
				empl_type: null,
			},
			array: null,
			arrIter: null,
		};
	},

	components: {
		Header,
		// Vacancy,
		Footer, DetailedCompany
		//  Workers
	},

	name: "Home",

	computed: {
		carousel() {
			return this.$store.getters.carousel;
		},
		iter() {
			return this.$store.getters.iter;
		},
		fourBlogs() {
			return this.$store.getters.fourBlogs;
		},
		dataCities() {
			return this.$store.getters.dataCities;
		},
		vacancies: {
			get() {
				return this.$store.getters.vacancies;
			},
			set(val) {
				this.$store.commit('setVacancies', val);
			}
		},
		translatedCarouselData() {
			return [
				{
					header: this.$t('pages.landing.homePage.carousel.slides[0].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[0].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[1].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[1].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[2].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[2].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[3].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[3].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[4].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[4].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[5].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[5].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[6].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[6].body')
				},
				{
					header: this.$t('pages.landing.homePage.carousel.slides[7].header'),
					body: this.$t('pages.landing.homePage.carousel.slides[7].body')
				},
			];
		}
	},

	beforeMount() {
		scroll(0, 0);
		this.getLastVacancies();
		// this.getLastWorkers();
		// this.companyLoad();
		this.$store.dispatch("fetchCarouselText", this.translatedCarouselData);
		this.carouselTimer = setInterval(() => {
			this.$store.commit("carouselIncrement");
		}, 7000);
		this.$store.dispatch("fetchDefaultBlogs", 1);
		this.$store.commit("setFourBlogs");
		//  this.$store.dispatch('searchCity', '');
	},
	destroyed() {
		clearInterval(this.carouselTimer);
	},
	methods: {
		companyLoad() {
			const req = new XMLHttpRequest();
			const url = config.apiPrefix + 'friends/search?role=employer&page=1&limit=10&name';
			req.open('GET', url, false);
			req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
			try {
				req.send();
				const { data } = JSON.parse(req.response);
				this.$store.commit('setCompanyCard', data);
			} catch (e) {
				console.log(e);
			}
		},
		blurSelect() {
			setTimeout(() => {
				this.filtersVisible = false;
			}, 300);
		},
		pickCity(e) {
			this.cityVisible = false;
			this.cityName = this.dataCities[this.dataCities.map(item => item.id).indexOf(e.target.value)].name;
			this.searchVacanciesForm.city = e.target.value;
			this.$store.dispatch('searchCity', '');
		},
		searchCity(val) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.$store.dispatch('searchCity', val)
			}, 1000);
		},
		goToTesting() {
			switch (this.$store.getters.role) {
				case "CANDIDATE":
					this.$router.push({ name: "testing" });
					break;
				case "EMPLOYER":
					Cookies.set("vacancyId", "testing");
					this.$router.push({ name: "candidateLogin" });
					break;
				case null:
					Cookies.set("vacancyId", "testing");
					this.$router.push({ name: "candidateLogin" });
					break;
			}
		},
		goToVacancySearch() {
			switch (this.$store.getters.role) {
				case "CANDIDATE":
					this.$router.push({ name: "vacancies" });
					break;
				case "EMPLOYER":
					Cookies.set("vacancyId", "all");
					this.$router.push({ name: "candidateLogin" });
					break;
				case null:
					Cookies.set("vacancyId", "all");
					this.$router.push({ name: "candidateLogin" });
					break;
			}
		},
		goToCandidateSearch() {
			switch (this.$store.getters.role) {
				case "CANDIDATE":
					Cookies.set("candidateId", "all");
					this.$router.push({ name: "employerLogin" });
					break;
				case "EMPLOYER":
					this.$router.push({ name: "vacanciesCompany" });
					break;
				case null:
					Cookies.set("candidateId", "all");
					this.$router.push({ name: "employerLogin" });
					break;
			}
		},
		nextIter() {
			this.$store.commit("carouselIncrement");
			clearInterval(this.carouselTimer);
			this.carouselTimer = setInterval(() => {
				this.$store.commit("carouselIncrement");
			}, 7000);
		},
		prevIter() {
			this.$store.commit("prevIter");
			clearInterval(this.carouselTimer);
			this.carouselTimer = setInterval(() => {
				this.$store.commit("carouselIncrement");
			}, 7000);
		},
		searchVacancies() {
			this.$store.dispatch(
				"setNewSearchVacanciesParams",
				this.searchVacanciesForm
			);
			this.$router.push("/vacancy");
		},
		getLastVacancies() {
			let xmlHttp = new XMLHttpRequest();
			//	let queryParams = "city=&education=&empl_type=&key_skills=&rate_from=&rate_to=&specialization=&work_experience=";
			let url = config.apiPrefix + "vacancy/search";
			xmlHttp.open("GET", url, false);

			try {
				xmlHttp.send(); // Нет тела запроса для GET
				let response = JSON.parse(xmlHttp.response);
				this.vacancies = response.data.slice(0, 6);
			} catch (e) {
				console.log(e);
			}
		},

		getLastWorkers() {
			let request = new XMLHttpRequest();
			request.open(
				"GET",
				config.apiPrefix + "vacancy/summary/all/search",
				false
			);
			request.setRequestHeader(
				"Content-Type",
				"application/json;charset=UTF-8"
			);
			try {
				request.send(JSON.stringify({}));
				let response = JSON.parse(request.response);
				this.workers = response.data.slice(0, 6);
			} catch (e) {
				console.log(e);
			}
		},
		pickedEmplType() {
			this.filtersVisible = !this.filtersVisible;
		},
		checkEmpl() {
			if (this.searchVacanciesForm.empl_type !== null)
				this.searchVacanciesForm.empl_type = null;
		},
	},
};
</script>

<style scoped>
/* Test css */


.test-container {
	overflow: visible;
}

.test {
	background-image: url("~@/assets/img/landing-img/main-page/test/test-bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 822px;
	box-sizing: border-box;
}

.test__top {
	padding-top: 200px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 69px;
	flex-wrap: wrap;
}

.test__top-logo {
	display: none;
}

.test__top-text-title {
	min-height: 132px;
	width: 546px;
	font-weight: 600;
	font-size: 60px;
	color: #ffffff;
}

.test__top-text-subtitle {
	padding-top: 56px;
	font-family: "Roboto", sans-serif;
	max-width: 413px;
	font-weight: 400;
	font-size: 18px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.test__top-save {
	max-width: 554px;
	width: 100%;
}

.test__top-save-roundabout {
	padding: 26px 25px 32px 32px;
	z-index: 100;
	height: 356px;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	background: rgba(11, 108, 149, 0.41999998688697815);
	border: 1px solid rgba(132, 176, 190, 0.4);
	border-radius: 9px;
	backdrop-filter: blur(24px);
	position: relative;
	box-sizing: border-box;
}

.test__top-save-roundabout-caption {
	display: flex;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
}

.test__top-save-roundabout-caption-number {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 13px;
	color: #ffffff;
	opacity: 0.4;
}

.test__top-save-roundabout-caption-line {
	margin-left: 36px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	height: 5px;
	background: #eef0f3;
	border-radius: 69px;
}

.test__top-save-roundabout-caption-btns {
	width: 50px;
	margin-left: 20px;
	flex-shrink: 0;
}

.test__top-save-roundabout-caption-btns-element-back {
	padding: 10px;
	background: url("~@/assets/img/landing-img/main-page/randabout/last.svg");
	background-repeat: no-repeat;
	background-position-y: 3px;
	cursor: pointer;
}

.test__top-save-roundabout-caption-btns-element-next {
	padding: 10px;
	background: url("~@/assets/img/landing-img/main-page/randabout/next.svg");
	background-repeat: no-repeat;
	background-position-y: 3px;
	background-position-x: 14px;
	cursor: pointer;
}

.test__top-save-roundabout-caption-line-filling {
	width: 100%;
	box-sizing: border-box;
	background: #009ed1;
	border-radius: 69px;
	height: 100%;
}

.test__top-save-roundabout-filling {
	padding-top: 20px;
	display: flex;
}

.test__top-save-roundabout-left {
	flex-shrink: 0;
}

.test__top-save-roundabout-left-number {
	margin: 0;
	max-width: 36px;
	position: relative;
	font-weight: 600;
	font-size: 13px;
	color: #ffffff;
	opacity: 0.4;
}

.ivisable {
	display: none;
}

.test__top-save-roundabout-left-title {
	max-width: 277px;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.27;
	color: #ffffff;
}

.test__top-save-roundabout-left-text {
	padding-top: 12px;
	font-family: "SFUIDisplay";
	max-width: 277px;
	font-weight: 400;
	font-size: 13px;
	line-height: 1.71;
	color: #ffffff;
	opacity: 0.8;
}

.test__top-save-roundabout-left-btn {
	position: absolute;
	bottom: 32px;
	margin-top: 12px;
	width: 180px;
	min-height: 48px;
	background: #14c686;
	border-radius: 7px;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.proposed-group {
	margin-top: 17px;
	max-width: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
}

.proposed-group-company {
	grid-template-columns: repeat(3, 1fr);
}

.test__top-save-roundabout-left-btn:hover {
	opacity: 0.5;
}

.test__top-save-roundabout-right {
	position: relative;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
}

.test__top-save-roundabout-right {
	position: relative;
}

.test__top-save-roundabout-right-img {
	position: absolute;
	content: "";
	background: url("~@/assets/img/landing-img/main-page/randabout/group-1.svg");
	background-repeat: no-repeat;
	width: 100%;
	display: block;
	width: 205px;
	height: 265px;
	transition: background 1s linear;
}

.roundabout-right-2 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-2.svg");
	background-repeat: no-repeat;
	width: 234px;
	top: -14px;
}

.roundabout-right-3 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-3.svg");
	background-repeat: no-repeat;
	width: 234px;
	top: 0px;
}

.roundabout-right-4 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-4.svg");
	background-repeat: no-repeat;
	width: 234px;
	top: 0px;
}

.roundabout-right-5 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-5.svg");
	background-repeat: no-repeat;
	width: 234px;
	left: 6px;
	top: -10px;
}

.roundabout-right-6 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-6.svg");
	background-repeat: no-repeat;
	width: 234px;
	left: 0px;
	top: 0px;
}

.roundabout-right-7 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-7.svg");
	background-repeat: no-repeat;
	width: 256px;
	left: -13px;
	top: 19px;
}

.roundabout-right-8 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-8.svg");
	background-repeat: no-repeat;
	width: 234px;
	left: 9px;
	top: 38px;
}

.test__top-bottom {
	position: relative;
	width: 100%;
	height: 70px;
	background-color: #fff;
	align-items: center;
	margin: 0 auto;
	display: flex;
	border-radius: 10px 13px 13px 10px;
	margin-top: 62px;
}

.test__top-bottom-search {
	width: 29.9%;
	height: 70px;
	border-radius: 10px 0px 0px 10px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	background-image: url("~@/assets/img/landing-img/main-page/surch.svg");

	background-repeat: no-repeat;
	background-size: auto;
	background-position: 36px;
	padding-left: 60px;
	-webkit-text-fill-color: #171625;
	-webkit-input-placeholder-color: red;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
}

.test__top-bottom-search::placeholder {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
}

.test__top-bottom-town {
	width: 100%;
	height: 100%;
	padding-left: 60px;
	background-image: url("~@/assets/img/landing-img/main-page/place-blue.svg");
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 36px;
	text-align: left;
}

.test__top-bottom-town-img {
	position: absolute;
	display: block;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-size: 10px;
	width: 10px;
	height: 9px;
	right: 20px;
	top: calc(50% - 4px);
}

.search-wrapper {
	width: 26.7%;
	height: 70px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
	position: relative;
}

.search-list {
	position: absolute;
	background: #fff;
	max-height: 278px;
	overflow-y: scroll;
	border-radius: 0px 0px 7px 7px;
	border: 1px solid #eaeaee;
}

.search-list__element {
	padding-left: 60px;
}

.search-list__element {
	padding-left: 60px;
}

.search-list__element {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
	line-height: 1;
}

.search-list__element-input {
	cursor: auto;
	border-bottom: 1px solid #eaeaee;
	padding-right: 20px;
}

.search-list__element:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.search-list__element:last-child {
	border-radius: 0px 0px 7px 7px;
	border-bottom: none;
}


.test__top-bottom-town::placeholder {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
}

.test__top-bottom-work {
	text-align: left;
	position: relative;
	padding-left: 60px;
	width: 100%;
	height: 70px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	cursor: pointer;
	border-left: 1px solid #f1f1f5;
	border-right: none;
	border-top: none;
	border-bottom: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #171725;

	height: inherit;
	flex-shrink: 1;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
	transition: background-color 1s ease;
}

.test__top-bottom-work-container {
	height: 100%;
	position: relative;
	width: 22.2%;
}

.test__top-bottom-work-container::after {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/work.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	left: 36px;
	top: 28px;
}

.test__top-bottom-work-group {
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	top: 70px;
	left: 0;
	z-index: 1000;
	border-radius: 0px 0px 7px 7px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
}

.test__top-bottom-work-group-element-label {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
	border: 1px solid #eaeaee;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-label:last-child {
	border-radius: 0px 0px 7px 7px;
}

.test__top-bottom-work-group-element-input {
	-webkit-appearance: none;
	height: 0px;
}

.test__top-bottom-work-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	text-align: left;
	color: #171725;
}

.label-checked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-span {
	position: absolute;
	display: inline-block;
	background-image: url("~@/assets/img/landing-img/main-page/test/work-btn.svg");
	background-repeat: no-repeat;
	width: 11px;
	top: 30px;
	right: 18px;
	height: 11px;
}

.test__top-bottom-findwork {
	width: 16.9%;
	height: 70px;
	background: #009ed1;
	border-radius: 0px 10px 10px 0px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.test__top-bottom-findwork:hover {
	opacity: 0.5;
}

.mobile-btn-randabout {
	display: none;
}

@media (max-width: 1130px) {
	.test {
		min-height: 600px;
	}

	.test__top-text-subtitle {
		margin-right: 10px;
	}

	.test__top {
		padding-top: 129px;
	}

	.test__top-text-title {
		font-size: 36px;
		width: 310px;
		min-height: 77px;
	}

	.test__top-text-subtitle {
		padding-top: 23px;
		font-size: 14px;
		min-width: 300px;
	}

	.test__top-save-roundabout-left-title::after {
		width: 53%;
	}

	.test__top-bottom-search {
		width: 20.9%;
	}

	.test__top-bottom-town {
		width: 21.9%;
	}

	.test__top-bottom-work-container {
		width: 25%;
	}

	.test__top-bottom::after {
		right: calc(40%);
		top: 28px;
	}

	.test__top-bottom-findwork {
		width: 22%;
	}

	.test__top-text {
		max-width: 325px;
	}
}

@media (max-width: 910px) {
	.test__top-logo {
		display: inline-block;
		text-align: center;
		margin: 94px auto 0 auto;
		display: inline-block;
		order: 1;
	}

	.test__top {
		padding-top: 0;
		flex-direction: column;
	}

	.test__top-text {
		align-items: center;
		margin: 0 auto;
		text-align: center;
	}

	.test__top-text-title {
		padding-top: 20px;
		margin: 0 auto;
		text-align: center;
	}

	.test__top-text-subtitle {
		margin: 0 auto;
		text-align: center;
	}

	.test__top-save {
		margin: 0 auto;
		padding-top: 20px;
	}

	.test__top-bottom {
		margin-top: 62px;
	}

	.test {
		min-height: 820px;
	}

	.test__top-bottom-search {
		width: 17.9%;
		padding-left: 42px;
		background-position: 18px;
	}

	.search-wrapper {
		width: 100%;
		box-shadow: none;
	}

	.search-list {
		z-index: 101;
	}

	.search-list {
		border-radius: 7px;
	}

	.test__top-bottom-work {
		width: 30%;
		padding-right: 0px;
	}

	.test__top-bottom::after {
		right: calc(46%);
		top: 28px;
	}

	.test__top-bottom-findwork {
		width: 24%;
	}

	.test__top-text {
		max-width: 550px;
	}

	.test__top-bottom-work-group {
		top: 60px;
		border-radius: 7px;
	}

	.first-labele {
		border-radius: 7px 7px 0 0;
	}
}

@media (max-width: 910px) {
	.test__top-logo {
		order: 1;
	}

	.test__top-bottom {
		box-sizing: border-box;
		width: 100%;
		max-width: 554px;
		margin: 35px auto 52px;
		flex-direction: column;
		order: 2;
		min-height: 302px;
		padding: 20px;
	}

	.test__top-bottom-search {
		height: 58px;
		box-sizing: border-box;
		width: 100%;
		background: #ffffff;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
		padding-left: 46px;
		background-position: 18px;
		background-image: url("~@/assets/img/landing-img/main-page/test/loop.svg");
		background-repeat: no-repeat;
		background-size: auto;
	}

	.test__top-bottom-town {
		height: 58px;
		margin-top: 10px;
		box-sizing: border-box;
		width: 100%;
		background: #ffffff;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
		padding-left: 46px;
		background-position: 18px;
		background-image: url("~@/assets/img/landing-img/main-page/test/place.png");
		background-repeat: no-repeat;
		background-size: auto;
	}

	.test__top-bottom-work {
		box-sizing: border-box;
		width: 100%;
		background-color: #ffffff;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
		padding-left: 46px;
	}

	.test__top-bottom-work-container {
		width: 100%;
		height: 58px;
		margin-top: 10px;
	}

	.test__top-bottom-work-container::after {
		left: 18px;
		top: 21px;
	}

	.test__top-bottom-findwork {
		margin-top: 10px;
		height: 58px;
		box-sizing: border-box;
		width: 100%;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.test__top-save {
		order: 3;
	}

	.test__top-bottom-work-group {
		top: 58px;
		border-radius: 7px;
	}

	.test__top-bottom-work-group-element-label:nth-child(1) {
		border-radius: 7px;
	}
}

@media (max-width: 576px) {
	.test__top-save-roundabout {
		height: 527px;
	}

	.test__top-save {
		max-width: 350px;
	}

	.test__top-save-roundabout-caption-line {
		margin-left: 43px;
	}

	.test__top-save-roundabout-filling {
		flex-direction: column;
	}

	.test__top-save-roundabout-left {
		padding: 27px 24px 0px 24px;

		order: 2;
	}

	.test__top-save-roundabout-right {
		order: 1;
		margin-top: 13px;
	}

	.test__top-save-roundabout-right-img {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-1-mobile.svg");
		background-repeat: no-repeat;
		width: 311px;
		height: 123px;
		left: calc(50% - 155px);
		top: 15px;
	}

	.roundabout-right-2 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-2-mobile.svg");
		background-repeat: no-repeat;
		width: 229px;
		height: 155px;
		left: calc(50% - 122px);
		top: 15px;
	}

	.roundabout-right-3 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-3-mobile.svg");
		background-repeat: no-repeat;
		width: 275px;
		left: calc(50% - 138px);
		top: 15px;
		height: 133px;
	}

	.roundabout-right-4 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-4-mobile.svg");
		background-repeat: no-repeat;
		width: 176px;
		left: calc(50% - 88px);
		top: 15px;
		height: 151px;
	}

	.roundabout-right-5 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-5-mobile.svg");
		background-repeat: no-repeat;
		width: 222px;
		left: calc(50% - 111px);
		top: 15px;
		height: 151px;
	}

	.roundabout-right-6 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-6-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 160px;
		left: calc(50% - 75px);
		top: 15px;
		height: 151px;
	}

	.roundabout-right-7 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-7-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 320px;

		left: calc(50% - 160px);
		top: 15px;
		height: 151px;
	}

	.roundabout-right-8 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-8-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 330px;

		left: calc(50% - 165px);
		top: 15px;
		height: 151px;
	}

	.test__top-save-roundabout-caption-btns {
		display: none;
	}

	.test__top-save-roundabout-caption-btns-element-back {
		padding: 10px 10px 5px 4px;
		background: url("~@/assets/img/landing-img/main-page/randabout/last.svg");
		background-repeat: no-repeat;
		background-position-y: 0px;
	}

	.test__top-save-roundabout-caption-btns-element-next {
		padding: 10px 10px 5px 4px;
		background-position-y: 0px;
		background-position-x: 8px;
	}

	.test__top-text {
		max-width: 310px;
	}

	.test__top-save-roundabout {
		padding: 32px 24px;
		box-sizing: border-box;
		max-width: 350px;
		width: 100%;
		flex-direction: column;
	}

	.test__top-save-roundabout-filling {
		padding-top: 0;
	}

	.test__top-save-roundabout-left {
		margin-top: 180px;
		padding: 0;
	}

	.test__top-save-roundabout::after {
		width: 69%;
	}

	.test__top-save-roundabout-right-group {
		position: relative;
		background-image: url("~@/assets/img/landing-img/main-page/test/big-ring.svg");
		background-repeat: no-repeat;
		width: 113px;
		height: 113px;
		margin-left: 53px;
	}

	.test__top-save-roundabout-right-group-img1 {
		background-size: cover;
		height: 112px;
		left: -70px;
		top: -7px;
		width: 112px;
	}

	.test__top-save-roundabout-right-group-img2 {
		background-size: cover;
		height: 56px;
		left: 77px;
		top: 64px;
		width: 56px;
	}

	.test__top-save-roundabout-right-group-img3 {
		background-size: cover;
		height: 92px;
		left: 123px;
		top: -5px;
		width: 92px;
	}

	.test__top-save-roundabout-left-number {
		display: none;
	}

	.ivisable {
		display: inline-block;
		position: absolute;
		top: -42px;
		left: -52px;
	}

	.test__top-save-roundabout-left-title {
		padding-top: 0px;
		max-width: 100%;
	}

	.test__top-save-roundabout-left-text {
		max-width: 100%;
	}

	.test__top-save-roundabout-left-btn {
		width: 100%;
		max-width: 300px;
	}

	.test__top-bottom {
		max-width: 350px;
	}
}

@media (max-width: 415px) {
	.mobile-btn-randabout {
		display: block;
		max-width: 252px;
		left: calc(50% - 126px);
	}

	.desctop-btn-randabout {
		display: none;
	}

	.test__top-save-roundabout::after {
		width: 55%;
	}

	.test__top-logo {
		text-align: left;
		margin: 94px 0 0 0;
		display: inline-block;
		order: 1;
	}

	.test__top-text {
		box-sizing: border-box;
		margin: 0;
		width: 100%;
		max-width: 350px;
		margin: 0 auto;
		text-align: left;
	}

	.test__top-text-title {
		margin: 0;
		text-align: left;
	}

	.test__top-text-subtitle {
		margin: 0;
		text-align: left;
	}
}

/* /Test css */

/* Jobs css */
.jobs {
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	box-sizing: border-box;
	width: 100%;
}

.applicants__group-element-cash {
	margin: auto;
}

.jobs__head {
	display: flex;
	justify-content: space-between;
}

.jobs__head-title {
	max-width: 520px;
}

.jobs__head-title-vacancy {
	letter-spacing: 0.1px;
	color: #009ed1;
}

.jobs__head-title-subtitle {
	padding-top: 8px;
	font-family: "Montserrat", serif;
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.btn__blue {
	min-width: 248px;
	min-height: 50px;
	height: 65px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #009ed1;
	border: 2px solid #cde8f1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.btn__blue:hover {
	opacity: 0.5;
}

.jobs__group {
	padding-top: 52px;
	margin-bottom: 30px;
	display: grid;
	gap: 10px;
}

.jobs__endbtn {
	display: none;
	transition: opacity 1s ease;
}

.jobs__endbtn:hover {
	opacity: 0.5;
}

@media (max-width: 1100px) {
	.jobs__head-title {
		max-width: 100%;
	}

	.jobs__group-element {
		margin: 0 auto;
	}

	.jobs__group {
		grid-template-columns: repeat(3, 1fr);
	}

	.proposed-group {
		grid-template-columns: repeat(2, 1fr);
	}

	.jobs__head-btn {
		display: none;
	}

	.jobs__endbtn {
		display: block;
		margin: 20px auto 0 auto;
	}

	.jobs__group {
		padding-top: 25px;
	}

	.jobs__head-title-subtitle {
		margin: 0 auto;
	}

	.jobs__head {
		display: block;
		text-align: center;
	}
}

@media (max-width: 950px) {
	.proposed-group {
		grid-template-columns: repeat(1, 1fr);
	}

}

@media (max-width: 650px) {
	.jobs__head-title-subtitle {
		font-size: 20px;
	}


	.jobs__head-title-vacancy {
		font-size: 13px;
	}

	.jobs__group {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}


	.btn__blue {
		height: 50px;
	}

	.jobs__endbtn {
		width: 100%;
		max-width: 300px;
		font-size: 13px;
	}
}

@media (max-width: 400px) {
	.jobs__head-title {
		text-align: left;
	}

	.jobs__endbtn {
		box-sizing: border-box;
		width: 100%;
	}
}

/* /Jobs css */

/* Applicationcss */
.applicants {
	margin-top: 63px;
	background: #fff;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	box-sizing: border-box;
	width: 100%;
}

.applicants__head {
	display: flex;
	justify-content: space-between;
}

.applicants__head-title {
	max-width: 520px;
}

.applicants__head-title-vacancy {
	letter-spacing: 0.1px;
	color: #009ed1;
}

.applicants__head-title-subtitle {
	padding-top: 8px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.btn__blue {
	min-width: 248px;
	min-height: 50px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #009ed1;
	border: 2px solid #cde8f1;
	border-radius: 7px;
}

.applicants__group {
	padding-top: 52px;
	display: grid;
	gap: 10px;
	margin-bottom: 30px;
}

.applicants__endbtn {
	display: none;
}

@media (max-width: 1100px) {
	.applicants__head-title {
		max-width: 100%;
	}

	.applicants__group {
		grid-template-columns: repeat(3, 1fr);
		padding-top: 25px;
	}

	.applicants__endbtn {
		display: block;
		margin: 0 auto;
	}

	.applicants__head-btn {
		display: none;
	}

	.applicants__head-title-subtitle {
		margin: 0 auto;
	}

	.applicants__head {
		display: block;
		text-align: center;
	}
}

@media (max-width: 950px) {
	.applicants__group {
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}

	.applicants__endbtn {
		margin: auto;
	}
}

@media (max-width: 650px) {
	.applicants__head-title-subtitle {
		font-size: 20px;
	}

	.applicants__head-title-vacancy {
		font-size: 13px;
	}

	.applicants__group {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}

	.applicants {
		margin-top: 56px;
	}

	.applicants__endbtn {
		font-size: 13px;
		max-width: 300px;
		width: 100%;
	}

	.btn__blue {
		font-size: 13px;
	}
}

@media (max-width: 400px) {
	.applicants__head-title {
		text-align: left;
	}

	.applicants__endbtn {
		box-sizing: border-box;
		width: 100%;
	}
}

/* /Applicationcss */

/* Suggestions */
.suggestions {
	align-items: center;
	margin-top: 103px;
	display: flex;
	justify-content: space-around;
	background-image: url("~@/assets/img/landing-img/main-page/suggestions-bg-1.png");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 180px;
	box-sizing: border-box;
	border-radius: 9px;
	margin-bottom: 124px;
}

.suggestions__text {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 25px;
	color: #ffffff;
}

.suggestions__btngroup-findwork {
	margin-right: 18px;
	padding: 23px 46px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	background: #009ed1;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 7px;
	transition: opacity 1s ease;
	cursor: pointer;
}

.suggestions__btngroup-findwork:hover {
	opacity: 0.5;
}

.suggestions__btngroup-findsworker {
	background: #14c686;
	border: 1px solid #69e7b9;
	border-radius: 7px;
	padding: 23px 46px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	transition: opacity 1s ease;
}

.suggestions__btngroup-findsworker:hover {
	opacity: 0.5;
}

@media (max-width: 1100px) {
	.suggestions__text {
		font-size: 20px;
	}

	.suggestions__btngroup-findwork {
		font-size: 13px;
	}

	.suggestions__btngroup-findsworker {
		font-size: 13px;
	}
}

@media (max-width: 786px) {
	.suggestions {
		min-height: 260px;
		background-image: url("~@/assets/img/landing-img/main-page/suggestions-bg-2.png");
		padding: 0px 20px 0px 20px;
	}

	.suggestions {
		flex-direction: column;
		justify-content: normal;
	}

	.suggestions__text {
		margin: 33px 0 57px 0;
	}

	.suggestions__btngroup {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
	}

	.suggestions__btngroup-findwork {
		padding: 15px 30px 16px 30px;
		margin-right: 0;
		line-height: 15px;
		margin-bottom: 18px;
		width: 100%;
	}

	.suggestions__btngroup-findsworker {
		padding: 15px 30px 16px 30px;
		width: 100%;
		line-height: 15px;
	}
}

@media (max-width: 650px) {
	.suggestions {
		margin: 61px 0;
	}
}

@media (max-width: 400px) {
	.suggestions__text {
		margin: 33px 0 29px 0;
	}

	.suggestions__btngroup {
		box-sizing: border-box;
		width: 100%;
	}

	.suggestions__btngroup-findwork {
		margin-right: 0;
		margin-bottom: 14px;
		line-height: 15px;
		box-sizing: border-box;
		width: 100%;
	}

	.suggestions__btngroup-findsworker {
		box-sizing: border-box;
		width: 100%;
	}
}

/* /Suggestions */

/* Mobile */

.mobileapp {
	margin-top: 124px;
	font-family: "Roboto";
}

.mobileapp__head {
	text-align: center;
}

.mobileapp__head-title {
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.mobileapp__head-subtitle {
	padding-top: 9px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.mobileapp__body {
	margin-top: 70px;
	display: flex;
	justify-content: space-around;
}

.mobileapp__body-left {
	padding-top: 73px;
	display: flex;
	flex-direction: column;
}

.mobileapp__body-left-element {
	display: flex;
	box-sizing: border-box;
	width: 100%;
}

.mobileapp__body-left-element:not(:first-child) {
	padding-top: 76px;
}

.mobileapp__body-left-elemen-img {
	padding: 20px;
	background: #e6f6fc;
	border: 1px solid rgba(0, 158, 209, 0.2);
	border-radius: 10px;
	width: 24px;
	height: 24px;
	margin-right: 25px;
	background-size: cover;
	background-repeat: no-repeat;
	box-sizing: content-box;
}

.mobileapp__body-left-elemen-info {
	box-sizing: border-box;
	max-width: 216px;
}

.mobileapp__body-left-elemen-info-title {
	box-sizing: border-box;
	width: 100%;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.1px;
	line-height: 20px;
	color: #171625;
}

.mobileapp__body-left-elemen-info-text {
	padding-top: 8px;
	box-sizing: border-box;
	width: 100%;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #686873;
	line-height: 22px;
}

.mobileapp__body-right {
	padding-top: 73px;
	display: flex;
	flex-direction: column;
}

.mobileapp__body-right-element {
	display: flex;
	box-sizing: border-box;
	width: 100%;
}

.mobileapp__body-right-element:not(:first-child) {
	padding-top: 76px;
}

.mobileapp__body-right-elemen-img {
	width: 64px;
	height: 64px;
	margin-right: 25px;
	background-size: cover;
	background-repeat: no-repeat;
}

.mobileapp__body-right-elemen-info {
	box-sizing: border-box;
	max-width: 216px;
}

.mobileapp__body-right-elemen-info-title {
	box-sizing: border-box;
	width: 100%;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.1px;
	line-height: 20px;
	color: #171625;
}

.mobileapp__body-right-elemen-info-text {
	padding-top: 8px;
	box-sizing: border-box;
	width: 100%;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #686873;
	line-height: 22px;
}

.mobileapp__body-midle {
	background-image: url("~@/assets/img/landing-img/main-page/mobile/bg.png");
	width: 47%;
	height: 605px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.mobileapp__appstore {
	margin-top: 80px;
	margin-bottom: 80px;
	display: flex;
	justify-content: center;
}

.mobileapp__appstore-apple {
	background: url("~@/assets/img/landing-img/main-page/mobile/apple.png");
	width: 175px;
	height: 52px;
	background-size: cover;
	margin-right: 23px;
}

.mobileapp__appstore-google {
	background: url("~@/assets/img/landing-img/main-page/mobile/google.png");
	width: 175px;
	height: 52px;
	background-size: cover;
}

@media (max-width: 1230px) {
	.mobileapp__body {
		flex-wrap: wrap-reverse;
	}

	.mobileapp__body-left {
		order: 1;
	}

	.mobileapp__body-midle {
		width: 100%;
		height: 338px;
		order: 3;
	}

	.mobileapp__body-right {
		order: 2;
	}
}

@media (max-width: 650px) {
	.mobileapp__head-subtitle {
		font-size: 20px;
	}

	.mobileapp__head-title {
		font-size: 13px;
	}

	.mobileapp__body-right-elemen-info-title {
		font-size: 14px;
	}

	.mobileapp__body-right-elemen-info-text {
		font-size: 12px;
	}

	.mobileapp__body-left-elemen-info-title {
		font-size: 14px;
	}

	.mobileapp__body-left-elemen-info-text {
		font-size: 12px;
	}

}

@media (max-width: 607px) {
	.mobileapp__body-right {
		padding-top: 53px;
	}

	.mobileapp__appstore {
		margin-top: 40px;
		margin-bottom: 68px;
	}

	.mobileapp__appstore-apple {
		width: 140px;
		height: 41px;
		margin-right: 18px;
	}

	.mobileapp__appstore-google {
		width: 140px;
		height: 41px;
	}
}

/* /Mobile */

/* News */
.news {
	padding-bottom: 100px;
}

.news__head {
	padding-top: 78px;
}

.news__head-title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.news__head-subtitle {
	padding-top: 8px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
	max-width: 520px;
}

.news__list {
	margin-top: 27px;
	display: flex;
	justify-content: space-between;
}

.news-title {
	padding-top: 16px;
	max-width: 322px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 21px;
	line-height: 31.992000579833984px;
	color: #283646;
}

.news__list-left {
	max-width: 33%;
	box-sizing: border-box;
}

.news__list-img-container img {
	height: 272px;
	object-fit: cover;
}


.news__list-right {
	max-width: 33%;
	box-sizing: border-box;
}

.news__list-midle {
	max-width: 33%;
	box-sizing: border-box;
}

.news-text {
	padding-top: 16px;
	max-width: 261px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #77808b;
}

.news-more {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 16px;
	line-height: 1.5;
	color: #009ed1;
	background-image: url("~@/assets/img/landing-img/main-page/news/arrow.svg");
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 110px;
	padding-right: 34px;
	margin-top: 40px;
	display: inline-block;
	transition: opacity 1s ease;
	width: 150px;
}

.news-more:hover {
	opacity: 0.5;
}

.article-wrapper {
	border: 1px solid #77808b;
	border-radius: 8px;
	padding: 0px 51px 19px 15px;
}

.news__list-right :not(:last-child) {
	margin-bottom: 25px;
}

.news__list-right-btn {
	width: 100%;
	height: 70px;
	box-sizing: border-box;
	border: 2px solid #009ed1;
	border-radius: 10px;
}

.news__list :not(:last-child) {
	margin-right: 15px;
	width: 100%;
	box-sizing: border-box;
}

.news__list-left-mobileimg {
	display: none;
}

.news__list-midle-mobileimg {
	display: none;
}

@media (max-width: 1267px) {
	.article-wrapper {
		padding: 0px 5px 19px 15px;
	}

	.news__list-right-article1-btn {
		margin-top: 30px;
	}

	.news__list-right-article2-btn {
		margin-top: 30px;
	}

	.news__list-right :not(:last-child) {
		margin-bottom: 25px;
	}
}

@media (max-width: 1094px) {
	.news__list-right-article1-btn {
		margin-top: 20px;
	}

	.news__list-right-article2-btn {
		margin-top: 20px;
	}

	.news__list-right :not(:last-child) {
		margin-bottom: 15px;
	}

	.news__list-right-article1-title {
		font-size: 15px;
	}

	.news__list-right-article2-title {
		font-size: 15px;
	}

	.news__list-right-btn {
		min-width: auto;
	}

	.news__list-img-container img {
		height: 200px;
		object-fit: cover;
	}
}

@media (max-width: 832px) {
	.news__list-right-article1-btn {
		margin-top: 5px;
	}

	.news__list-right-article2-btn {
		margin-top: 5px;
	}

	.news__list-right :not(:last-child) {
		margin-bottom: 15px;
	}

	.news__list-right-article1-title {
		font-size: 15px;
	}

	.news__list-right-article2-title {
		font-size: 15px;
	}
}

@media (max-width: 650px) {
	.news {
		max-width: 400px;
		margin: 0 auto;
		padding-bottom: 59px;
	}

	.news__head {
		padding-top: 45px;
		text-align: center;
	}

	.news__head-title {
		font-size: 13px;
	}

	.news__head-subtitle {
		font-size: 20px;
	}

	.news__list {
		margin-top: 20px;
		flex-direction: column;
	}

	.news__list-left {
		max-width: 100%;
		margin-bottom: 10px;
	}

	.news__list-midle {
		max-width: 100%;
		margin-bottom: 10px;
	}

	.news__list-right {
		max-width: 100%;
	}

	.news__list-left {
		background: #ffffff;
		border: 1px solid #ebebeb;
		border-radius: 8px;
	}

	.news__list-midle {
		background: #ffffff;
		border: 1px solid #ebebeb;
		border-radius: 8px;
	}

	.news__list-left-desctopimg {
		display: none;
	}

	.news__list-left-mobileimg {
		width: 100%;
		display: block;
		background: #a2a9b0;
		border-radius: 8px 8px 0px 0px;
	}

	.news__list-midle-desctopimg {
		display: none;
	}

	.news__list-midle-mobileimg {
		display: block;
		width: 100%;
		background: #a2a9b0;
		border-radius: 8px 8px 0px 0px;
	}


	.news__list-left-text {
		margin: 0px 0px 18px 15px;
	}

	.news__list-left-btn {
		margin: 0 0 21px 15px;
	}

	.news__list-midle-title {
		margin: 0px 0 9px 15px;
	}

	.news__list-midle-text {
		margin: 0px 0px 18px 15px;
		max-width: 100%;
		padding-right: 20px;
		box-sizing: border-box;
	}

	.news__list-midle-btn {
		margin: 0 0 21px 15px;
	}

	.article-wrapper {
		background: #ffffff;
		border: 1px solid #ebebeb;
		border-radius: 8px;
	}

	.news-title {
		max-width: 100%;
		padding-right: 30px;
		font-size: 21px;
		line-height: 31.992000579833984px;
	}

	.news-text {
		max-width: 100%;
		padding-right: 30px;
		box-sizing: border-box;
	}

	.mobile__head {
		margin-top: 33px;
	}

	.mobile__body {
		margin-top: 35px;
	}

	.mobile__head-title {
		font-size: 13px;
	}

	.mobile__head-subtitle {
		font-size: 20px;
	}

	.mobile__body-right-elemen-info {
		max-width: 220px;
	}

	.mobile__body-left-elemen-info {
		max-width: 220px;
	}

	.mobile__body-right-element:not(:first-child) {
		padding-top: 22px;
	}

	.mobile__body-left-element:not(:first-child) {
		padding-top: 22px;
	}

	.mobile__body-left {
		padding-top: 22px;
	}

	.mobile__body-right {
		padding-top: 22px;
	}

	.mobile__body-midle {
		background-image: url("~@/assets/img/landing-img/main-page/mobile/bg-700.png");
		width: 100%;
		height: 418px;
	}

	.mobile__body-left-elemen-img {
		padding: 19px;
		width: 20px;
		height: 20px;
	}

	.mobile__body-right-elemen-info-title {
		font-size: 14px;
	}

	.mobile__body-right-elemen-info-text {
		font-size: 12px;
	}

	.mobile__body-left-elemen-info-title {
		font-size: 14px;
	}

	.mobile__body-left-elemen-info-text {
		font-size: 12px;
	}

	.mobile__body-right {
		display: flex;
		flex-direction: column;
	}

	.mobile__appstore {
		margin-top: 38px;
		margin-bottom: 69px;
	}

	.mobile__appstore-apple {
		width: 140px;
		height: 41px;
		margin-right: 18px;
	}

	.mobile__appstore-google {
		width: 140px;
		height: 41px;
	}

	.news__list-right-btn {
		max-width: 300px;
		height: unset;
		width: 100%;
		box-sizing: border-box;
		border: 1px solid #009ed1;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		padding: 15px 0;
		display: flex;
		margin: 30px auto 0 auto;
	}

	.news__list-right-btn {
		font-size: 13px;
	}

	.news-title {
		max-width: 226px;
		font-size: 15px;
		line-height: 22.492000579833984px;
	}

	.news-text {
		font-size: 12px;
	}

	.mobileapp__body-right-element:not(:first-child) {
		padding-top: 22px;
	}

	.mobileapp__body-left {
		padding-top: 22px;
	}

	.mobileapp__body-left-element:not(:first-child) {
		padding-top: 22px;
	}

	.news__list-img-container img {
		height: 170px;
	}

	.news-title {
		padding-right: 0;
		max-width: 100%;
		width: 100%;
	}

	.news__list-left-title {
		margin-right: 0 !important;
		padding: 16px 15px 0 15px;
	}

	.news__list-midle-title {
		margin: 0 !important;
		padding: 16px 15px 0 15px;
	}

	.mobileapp {
		margin-top: 61px;
	}

}

@media (max-width: 400px) {
	.news__head {
		text-align: left;
	}

	.news__list-left-mobileimg {
		height: 121px !important;
	}

	.news__list-midle-mobileimg {
		height: 121px !important;
	}
}

/* /News */
.select_picked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}
</style>