<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="container-account">
					<div class="faq">
						<h1 class="faq__text mobile">{{
							$t('pages.account.account_company.accountCompanyFaq.personalCabinet') }}</h1>
						<h1 class="faq__title">{{ $t('pages.account.account_company.accountCompanyFaq.title') }}</h1>
						<p class="faq__text desctop">{{ $t('pages.account.account_company.accountCompanyFaq.welcome') }}
						</p>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>


<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";

export default {
	components: { MenuCompany, HeaderAccountCompany },
	name: "AccountCompanyFaq",
};
</script>


<style scoped>
.account {
	display: flex;
}

.mobile {
	display: none;
}

.faq__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.faq__text {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

@media (max-width: 1650px) {
	.faq__title {
		text-align: center;
	}

	.faq__text {
		text-align: center;
	}
}

@media (max-width: 700px) {
	.desctop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.faq__text {
		padding-top: 0;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		line-height: 35.5px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}
}
</style>