import { render, staticRenderFns } from "./AccountWorkerVideoInterviewPage.vue?vue&type=template&id=0bbff8eb&scoped=true"
import script from "./AccountWorkerVideoInterviewPage.vue?vue&type=script&lang=js"
export * from "./AccountWorkerVideoInterviewPage.vue?vue&type=script&lang=js"
import style0 from "./AccountWorkerVideoInterviewPage.vue?vue&type=style&index=0&id=0bbff8eb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbff8eb",
  null
  
)

export default component.exports