var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"complaint-form",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"complaint__select-reason"},[_c('button',{staticClass:"close",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closeWindow')}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z","stroke":"#3B4C68","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"tweet-preview"}),(!_vm.selectedReason)?_c('p',{staticClass:"complaint_reason_p"},[_vm._v(" "+_vm._s(_vm.$t('components.social.complaintForm.promptMessage'))+" ")]):_vm._e(),(_vm.selectedReason)?_c('button',{staticClass:"complaint_reason_p complaint__back-btn",on:{"click":function($event){$event.stopPropagation();return _vm.cancelSelection.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('components.social.complaintForm.backButton'))+" ")]):_vm._e(),_c('div',{staticClass:"complaint__list-reason"},_vm._l((_vm.typeComplaints),function(complaint){return _c('button',{key:complaint.id,staticClass:"complaint__btn",class:{
					'complaint__btn--active': _vm.selectedReason === complaint.id,
					'complaint__btn--inactive':
						_vm.selectedReason !== null && _vm.selectedReason !== complaint.id,
				},attrs:{"value":complaint.name,"disabled":_vm.selectedReason !== null && _vm.selectedReason !== complaint.id},on:{"click":function($event){$event.stopPropagation();return _vm.selectReason(complaint.id)}}},[_vm._v(" "+_vm._s(complaint.name)+" ")])}),0),_c('button',{staticClass:"complaint__submit-btn",class:_vm.selectedReason
			? 'complaint__submit-btn-disabled-active'
			: 'complaint__submit-btn-disabled',attrs:{"type":"submit","disabled":!_vm.selectedReason},on:{"click":function($event){$event.stopPropagation();return _vm.submitComplaint.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('components.social.complaintForm.submitButton'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }