<template>
	<div class="complaint-form" @click.stop>
		<div class="complaint__select-reason">
			<button @click.stop="$emit('closeWindow')" class="close">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z"
						stroke="#3B4C68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</button>
			<div class="tweet-preview">
				<!-- Здесь разместите предпросмотр твита или другого контента, по которому подается жалоба -->
			</div>

			<p class="complaint_reason_p" v-if="!selectedReason">
				{{ $t('components.social.complaintForm.promptMessage') }}
			</p>
			<button v-if="selectedReason" @click.stop="cancelSelection" class="complaint_reason_p complaint__back-btn">
				{{ $t('components.social.complaintForm.backButton') }}
			</button>
			<div class="complaint__list-reason">
				<button v-for="complaint in typeComplaints" :key="complaint.id" :value="complaint.name"
					:disabled="selectedReason !== null && selectedReason !== complaint.id" :class="{
						'complaint__btn--active': selectedReason === complaint.id,
						'complaint__btn--inactive':
							selectedReason !== null && selectedReason !== complaint.id,
					}" class="complaint__btn" @click.stop="selectReason(complaint.id)">
					{{ complaint.name }}
				</button>
			</div>

			<button type="submit" class="complaint__submit-btn" :class="selectedReason
				? 'complaint__submit-btn-disabled-active'
				: 'complaint__submit-btn-disabled'" :disabled="!selectedReason" @click.stop="submitComplaint">
				{{ $t('components.social.complaintForm.submitButton') }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: ["url", "typeComplaints", "addresserId", "addresserRole"],
	data() {
		return {
			selectedReason: null,
		};
	},
	methods: {
		selectReason(id) {
			this.selectedReason = id;
		},
		submitComplaint() {
			if (this.selectedReason) {
				this.$store.dispatch("sendComplaint", {
					token: this.$store.getters.token,
					categoryId: this.selectedReason,
					url: this.url,
					addresserId: this.addresserId,
					addresserRole: this.addresserRole,
				});
				this.$emit("closeWindow");
			} else {
				alert(this.$t('components.social.complaintForm.alertMessage'));
			}
		},
		cancelSelection() {
			this.selectedReason = null;
		},
	},
};
</script>


<style>
.complaint__back-btn {
	border-left: 5px solid #617188;
}

.complaint-form {
	position: fixed;
	z-index: 1010;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.5);

	height: 100vh;
	height: 100dvh;
	width: 100%;
	max-width: 100%;
	padding: 40px;
	overflow: auto;
	scrollbar-width: thin;
}

.complaint__select-reason {
	position: relative;
	max-width: 450px;
	width: 50%;
	background: #fff;
	margin: 0 auto;
	padding: 30px 20px;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	margin-top: 10%;
}

.close {
	position: absolute;
	right: -30px;
	top: 0;
	width: 20px;
	height: 20px;
}

.close svg {
	width: 100%;
	height: 100%;
}

.close path {
	stroke: #fff;
}

.complaint__list-reason {
	display: flex;
	flex-direction: column;
	justify-content: left;
	text-align: left;
}

.complaint__btn {
	text-align: left;
	padding: 10px 24px;
	border-bottom: 1px solid #D9D9D9;
}

.complaint__btn {
	transition: all 0.3s ease;
}

.complaint__btn--active {
	border-left: 5px solid #617188;
	animation: pulseAnimation 1s infinite;
}

.complaint__btn--inactive {
	color: #ccc;
}

.complaint_reason_p {
	margin-bottom: 16px;
	padding-left: 10px;
	text-align: left;
}

.complaint__submit-btn {
	margin-top: 24px;
	padding: 8px 32px;
	border: 1px solid #617188;
	/* Светло-серый обводка */
	background-color: #E6F6FC;
	/* Светло-голубой фон */
	color: #617188;
	/* Светло-серый текст */
	font-weight: bold;
	border-radius: 4px;
	transition: all 0.3s ease;
	/* Плавное изменение стилей */
	cursor: pointer;
}

.complaint__submit-btn-disabled {
	background-color: #f0f0f0;
	/* Серый фон */
	color: #ccc;
	/* Светло-серый текст */
	border: 1px solid #ccc;
	/* Светло-серая обводка */
	cursor: default;
}

.complaint__submit-btn:not(.complaint__submit-btn-disabled) {
	position: relative;
	overflow: hidden;
}

.complaint__submit-btn:not(.complaint__submit-btn-disabled)::after {
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
	transition: all 0.6s ease;
}

.complaint__submit-btn:not(.complaint__submit-btn-disabled):hover::after {
	left: 100%;
}

.complaint__submit-btn-disabled::after {
	display: none;
}

.complaint__submit-btn-disabled-active {
	border: 1px solid #617188;
	background-color: #E6F6FC;
	cursor: pointer;

}

.complaint__btn:disabled,
.complaint__submit-btn-disabled {
	cursor: default;
}

.complaint-form button[disabled] {
	cursor: default;
}

.complaint__submit-btn,
.complaint__submit-btn-disabled,
.complaint__submit-btn-disabled-active {
	padding: 16px 32px;
	/* Исправление: Добавление единиц измерения */
	cursor: pointer;
	/* Для активных кнопок */
}

.complaint__submit-btn[disabled] {
	cursor: default;
	/* Убедитесь, что этот стиль применяется последним */
}


@media (max-width: 767px) {
	.complaint-form {
		padding: 20px 10px;
	}

	.complaint__select-reason {
		padding: 20px 12px;
	}

	.close {
		width: 16px;
		height: 16px;
		position: relative;
		margin-left: auto;
		right: unset;
		top: unset;
		margin-right: 4px;
	}

	.close path {
		stroke: #000;
	}
}
</style>
