<template>
	<div class="chat__wrapper-dialogue">
		<div class="chat__wrapper-dialogue-caption">
			<button class="chat__wrapper-dialogue-caption-back" @click="back">
				<img src="~@/assets/img/account-img/chat/back.svg" alt="Back"
					class="chat__wrapper-dialogue-caption-back-img" />
			</button>
			<div class="chat__wrapper-dialogue-caption-card" :style="role === 'admin' ? { pointerEvents: 'none' } : ''"
				@click="$router.push({ name: userRole === 'candidate' ? 'candidateProfile' : 'employerProfile', params: { summaryId: userId } })">
				<div class="chat__wrapper-dialogue-caption-avatar"
					:style="{ backgroundImage: userAvatar ? 'url(' + userAvatar + ')' : 'url(' + require('/src/assets/img/account-img/chat/avatar.svg') + ')' }">
					<div class="
            chat__wrapper-dialogue-caption-avatar-notification
            notification-online
          "></div>
				</div>
				<div class="chat__wrapper-dialogue-caption-info">
					<p class="chat__wrapper-dialogue-caption-info-name">{{ userName ? userName : 'Administrator' }}</p>
				</div>
			</div>
		</div>
		<div class="chat__wrapper-dialogue-messages"
			:class="{ 'wrapper-three-photo-desctop': fileInMessage.length === 3, 'wrapper-two-photo-desctop': fileInMessage.length === 2 || fileInMessage.length === 1 }"
			id="dialoge">

			<SeparateWorkerChatMessage @bigPicture="bigPicture" @registerRef="registerRef"
				@unregisterRef="unregisterRef" v-for="item in message" :key="item.id" :message="item">
			</SeparateWorkerChatMessage>
			<button v-show="!isAtBottom" @click="scrollToBottom" class="scroll-to-bottom">
				↓
			</button>
		</div>

		<div class="chat__wrapper-dialogue-entry"
			:class="canWriteMessage === true ? '' : 'chat__wrapper-dialogue-entry-disabled'">
			<div class="chat__wrapper-dialogue-entry-filling">
				<textarea id="textarea"
					:placeholder="$t('components.account_worker.separateWorkerChatDialogue.enterMessage')"
					v-model="newMessage" class="chat__wrapper-dialogue-entry-filling-input"
					v-on:keyup.enter="send"></textarea>
				<div class="chat__wrapper-dialogue-entry-filling-interactions">
					<ChatsEmojiVue @emojiAdd="emojiAdd" class="emoji"></ChatsEmojiVue>
					<button v-if="fileInMessage.length !== 3 && role !== 'admin'" @click="$refs.file.click()"
						class="chat__wrapper-dialogue-entry-filling-interactions-file"></button>
					<input hidden @change="addFile" ref="file" type="file">
					<button id="send" @click="send"
						class="chat__wrapper-dialogue-entry-filling-interactions-send"></button>
				</div>
			</div>
			<div class="chat__wrapper-dialogue-entry-photos-wrapper">
				<div class="chat__wrapper-dialogue-entry-photos" v-for="(item, index) in fileInMessage" :key="item.id">
					<div class="photos-element-wrapper">
						<button class="close" @click="deleteFiles(index)"></button>
						<button class="photos-element">
							<img style="cursor: pointer" @click="bigPicture(item.url)" :src="item.url" alt=""
								class="photos-element__img">
						</button>
					</div>
				</div>
			</div>
		</div>
		<BigPost v-if="bigPost && bigPostMetaData" :post="bigPost" :meta-data="bigPostMetaData"
			@closeWindow="closeBigPost" @viewBigPhoto="bigPicture"></BigPost>
	</div>
</template>

<script>
import SeparateWorkerChatMessage from "../../../account_worker/account_worker_chat/item/SeparateWorkerChatMessage.vue";
import { errorHandler } from "@/lib/errorHandler";
import ChatsEmojiVue from "../../../../social/ChatsEmoji.vue";
import BigPost from "@/components/account/account_worker/modals/item/BigPost.vue";
import { config } from "@/lib/config";

export default {
	watch: {
		message(newVal, oldVal) {
			if (newVal[newVal.length - 1].from === 'myself' && newVal.length !== oldVal.length) {
				this.$nextTick(() => {
					setTimeout(() => {
						this.scrollToBottom();
					}, 50);
				});
			} else if (newVal.length > oldVal.length && this.isAtBottom && this.isWindowActive) {
				console.log('test')
				this.$nextTick(() => {
					this.scrollToBottom();
					this.$store.commit('readMessage', {
						messageId: newVal[newVal.length - 1].id
					});
				});
			}
			this.$nextTick(() => {
				if (newVal[newVal.length - 1].from === 'companion') {
					const element = this.messageRefs[newVal[newVal.length - 1].id].$el
					console.log(element)
					if (element) {
						this.observer.observe(element);
					}
				}
			});
		}
	},
	computed: {
		message() {
			return this.$store.getters.currentDialogue;
		},
		fileInMessage() {
			return this.$store.getters.fileInMessage;
		},
		userAvatar() {
			return this.$store.getters.userAvatar;
		},
		userName() {
			return this.$store.getters.userName;
		},
		userId() {
			return this.$store.getters.userId;
		},
		userRole() {
			return this.$store.getters.userRole;
		}
	},
	props: ['role'],
	name: "SeparateWorkerChatPersone",
	components: {
		BigPost,
		SeparateWorkerChatMessage, ChatsEmojiVue
	},
	data() {
		return {
			newMessage: '',
			timerId: null,
			bigPost: null,
			bigPostMetaData: null,
			messageRefs: {},
			isAtBottom: true,
			canWriteMessage: true,
			isWindowActive: true,
			observer: null,
			isSendingMessage: false,
		};
	},
	methods: {
		observeMessages() {
			this.observer = new IntersectionObserver((entries) => {
				entries.forEach(entry => {
					if (entry.isIntersecting && entry.target.__vue__.message.read === false && entry.target.__vue__.message.from !== "myself") {
						console.log(entry.target.__vue__.message.from)
						this.$store.commit('readMessage', {
							messageId: entry.target.__vue__.message.id
						});
					}
				});
			}, { threshold: 1.0 });
			this.message.forEach((message) => {
				const element = this.messageRefs[message.id].$el
				if (element) {
					this.observer.observe(element);
				}
			});
		},
		handleVisibilityChange() {
			this.isWindowActive = !document.hidden;
		},
		registerRef(ref, id) {
			this.$set(this.messageRefs, id, ref);
		},
		unregisterRef(id) {
			this.$delete(this.messageRefs, id);
		},
		async viewBigPost(val) {
			if (!val.postId) return;
			await fetch(`${config.apiPrefix}community/post/${val.postId}/get`, {
				method: "GET",
				headers: {
					'X-Auth-Token': this.$store.getters.token
				}
			}).then(async (res) => {
				const { data } = await res.json();
				this.bigPost = data;
				this.bigPostMetaData = {
					avatarUrl: val.communityAvatarUrl,
					communityId: val.communityId,
					title: val.communityName,
				}
			}).catch(() => {
				errorHandler({ page: 'openPost', status: 500 })
			})
		},
		closeBigPost() {
			this.bigPost = null;
			this.bigPostMetaData = null;
		},
		setNewMessage(data) {
			this.newMessage = data;
		},
		deleteFiles(index) {
			this.$store.commit('deleteFileInMessages', { index: index })
			this.$refs.file.value = null;
		},
		bigPicture(item) {
			console.log('test')
			this.$emit('bigPicture', item);
		},
		emojiAdd(emoji) {
			this.newMessage += emoji;
		},
		addFile() {
			const file = this.$refs.file.files[this.$refs.file.files.length - 1];
			const url = URL.createObjectURL(file);
			this.$store.commit('addFileInMessages', { url: url, file: file });
			this.$refs.file.value = null;
		},
		addPagination() {
			const el = document.getElementById('dialoge');
			el.onscroll = () => {
				if (el.scrollTop === 0) {
					this.$store.commit('upPageMessages');
					this.$store.dispatch('paginationMessage', this.deletePagination);
				}
			}
		},
		back() {
			this.$store.commit('clearDialogue');
			clearInterval(this.timerId);
		},
		allMessages() {
			if (this.role === 'admin')
				this.$store.dispatch('fetchAllMessageWithAdmin', this.readMessage);
			else this.$store.dispatch('fetchAllMessageWithUser', { id: this.userId, callback: this.readMessage });
		},
		allDialogues() {
			if (this.role === 'admin') {
				this.$store.dispatch('fetchAllDialoguesWithAdmin', this.allMessages);
			} else {
				this.$store.dispatch('fetchAllNewDialogues', this.allMessages);
			}
		},
		readMessage() {
			this.$store.dispatch('readMessage', () => {
				if (this.$store.getters.role === 'CANDIDATE') {
					this.$store.dispatch('fetchCandidateData');
				} else if (this.$store.getters.role === 'EMPLOYER') {
					this.$store.dispatch('fetchEmployerData');
				}
			});
		},
		send() {
			if (this.newMessage.trim().length !== 0 || this.fileInMessage.length !== 0) {
				this.isSendingMessage = true;
				this.$store.dispatch('sendMessage', {
					message: this.newMessage,
					callback: () => {
						this.newMessage = '';
						this.isSendingMessage = false;
					},
					userRole: this.userRole
				}).catch(() => {
					this.isSendingMessage = false;
				});
			} else errorHandler({ status: 400, page: "emptyMessage" });
		},
		deletePagination() {
			document.getElementById('dialoge').onscroll = null;
		},
		checkScroll() {
			const container = document.getElementById('dialoge');
			if (!container) return;
			const threshold = 100;
			this.isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
		},
		scrollToBottom() {
			const container = document.getElementById('dialoge');
			if (container) {
				container.scrollTo({
					top: container.scrollHeight,
					behavior: 'smooth'
				});
			}
		},
		canWriteMessageCheck() {
			const req = new XMLHttpRequest();
			const url = config.apiPrefix + '/system/deleted?id=' + this.userId + '&role=' + this.userRole;
			req.open('GET', url, false);
			req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
			req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
			try {
				req.send();
				const response = JSON.parse(req.response)
				this.canWriteMessage = response.data;
			} catch (e) {
				console.log(e);
			}
		}
	},
	beforeMount() {
		this.$emit('stopTimer');
		this.canWriteMessageCheck();
	},
	mounted() {
		this.$nextTick(() => {
			this.observeMessages();
		});
		document.addEventListener('visibilitychange', this.handleVisibilityChange);
		this.$store.commit('setActiveComponent', true);
		this.$nextTick(() => {
			const firstUnreadMessageId = this.message.find(m => !m.read)?.id;
			if (firstUnreadMessageId && this.messageRefs[firstUnreadMessageId]) {
				const element = this.messageRefs[firstUnreadMessageId].$el;
				if (element) {
					element.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			}
			else {
				const el = document.getElementById("dialoge");
				el.scroll(0, 100000);
			}
			const container = document.getElementById('dialoge');
			if (container) {
				container.addEventListener('scroll', this.checkScroll);
				this.checkScroll();
			}
			if (this.role !== 'admin') {
				this.addPagination();
			}
			this.readMessage();
		});
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect();
		}
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);
		this.$store.commit('setActiveComponent', null);
		const container = document.getElementById('dialoge');
		if (container) {
			container.removeEventListener('scroll', this.checkScroll);
		}
	},
	destroyed() {
		clearInterval(this.timerId);
		this.$emit('allDialoguesReload')
	}
};
</script>


<style scoped>
.chat__wrapper-dialogue-entry-disabled {
	pointer-events: none;
}

.chat__wrapper-dialogue-entry-disabled .chat__wrapper-dialogue-entry-filling-input,
.chat__wrapper-dialogue-entry-disabled .chat__wrapper-dialogue-entry-filling-interactions-file,
.chat__wrapper-dialogue-entry-disabled .chat__wrapper-dialogue-entry-filling-interactions-send,
.chat__wrapper-dialogue-entry-disabled .emoji,
.chat__wrapper-dialogue-entry-disabled .photos-element__img {
	cursor: not-allowed;
	opacity: 0.5;
}

.chat__wrapper-dialogue {
	flex-shrink: 0;
	position: relative;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.chat__wrapper-dialogue-caption {
	padding: 15px 70px 15px 30px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	border-bottom: 1px solid rgba(182, 210, 255, 0.5);
	align-items: center;
}

.chat__wrapper-dialogue-caption-back {
	width: 60px;
	height: 60px;
	display: block;
	box-sizing: border-box;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-back:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-avatar {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	position: relative;
}

.chat__wrapper-dialogue-caption-card {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.chat__wrapper-dialogue-caption-avatar-notification {
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 1px;
	right: 0;
}

.chat__wrapper-dialogue-caption-info {
	margin-left: 16px;
}

.chat__wrapper-dialogue-caption-info-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
}

.chat__wrapper-dialogue-caption-info-online {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #009ed1;
}

.ofline {
	color: #999999;
}

.chat__wrapper-dialogue-caption-opportunities {
	margin-left: auto;
}

.chat__wrapper-dialogue-caption-opportunities-surch {
	margin-right: 18px;
	padding: 14px 14px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-surch:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-opportunities-tabs {
	padding: 11px 11px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-tabs:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages {
	padding: 24px 70px 24px 32px;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	z-index: 10;
}

.chat__wrapper-dialogue-messages-interlocutor {
	max-width: 372px;
	width: 100%;
	box-sizing: border-box;
	background: #e4f6fc;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-right: auto;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-interlocutor::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	left: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-interlocutor:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-messages-interlocutor-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #666666;
}

.chat__wrapper-dialogue-messages-interlocutor-time {
	margin-top: 29px;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999999;
}

.chat__wrapper-dialogue-messages-user {
	max-width: 302px;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-left: auto;
	background: #009ed1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-user-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
}

.chat__wrapper-dialogue-messages-user::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/user-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	right: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-user:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-entry {
	border-top: 1px solid rgba(182, 210, 255, 0.5);
	background: none;
	margin-top: auto;
	width: 100%;
	padding: 36px 40px;
	box-sizing: border-box;
	z-index: 30;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
	background: #edeef0;
}


.chat__wrapper-dialogue-entry-filling {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.chat__wrapper-dialogue-entry-filling-input {
	width: 100%;
	padding: 18px 136px 18px 26px;
	box-sizing: border-box;
	height: 56px;
	max-height: calc(100vh - 470px);
	background: #ffffff;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #171725;
	border-radius: 10px;
	overflow-y: scroll;
	scrollbar-width: none;
	border: none;
}

.chat__wrapper-dialogue-entry-filling-input:hover {
	overflow-y: scroll;
	scrollbar-width: none;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-entry-filling-input::placeholder {
	color: #b7c1d1;
}

.chat__wrapper-dialogue-entry-filling-interactions {
	position: absolute;
	align-items: center;
	display: flex;
	right: 26px;
	bottom: 18px;
}

.chat__wrapper-dialogue-entry-filling-interactions-file {
	background: url("~@/assets/img/account-img/chat/file-add.svg");
	width: 19px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-file:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-entry-filling-interactions-send {
	margin-left: 21px;
	background: url("~@/assets/img/account-img/chat/send.svg");
	width: 20px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-send:hover {
	opacity: 0.5;
}

.emoji {
	margin-right: 20px;
}

.chat__wrapper-dialogue-entry-photos-wrapper {
	display: flex;
	gap: 10px;
}

.scroll-to-bottom {
	position: fixed;
	right: 120px;
	bottom: 180px;
	cursor: pointer;
	font-size: 36px;
	z-index: 10000;
	color: #171725;
	background-color: rgba(182, 210, 255, 1);
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

@media (max-width: 1400px) {
	.chat__wrapper-dialogue-caption {
		padding: 10px 20px 10px 0px;
	}

	.chat__wrapper-dialogue-messages {
		padding: 24px 20px 24px 31px;
	}

	.chat__wrapper-dialogue-entry {
		padding: 24px 20px 24px 31px;
	}
}

@media (max-width: 900px) {
	.scroll-to-bottom {
		right: 25px;
		bottom: 110px;
	}

	.chat__wrapper-dialogue-entry-filling-input {
		padding-right: 96px;
	}

	.chat__wrapper-dialogue-caption-back {
		width: 40px;
		height: 40px;
	}

	.chat__wrapper-dialogue-caption-avatar {
		flex-shrink: 0;
	}

	.chat__wrapper-dialogue-caption-info-name {
		font-size: 15px;
	}

	.chat__wrapper-dialogue-entry {
		background: #FAFAFB;
	}

	.chat__wrapper-dialogue-caption {
		padding: 18px 10px 18px 0px;
		top: 48px;
	}

	.chat__wrapper-dialogue-messages {
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
	}
}

@media (max-width: 750px) {
	.emoji {
		display: none !important;
	}
}

@media (max-width: 500px) {
	.chat__wrapper-dialogue-entry-photos {
		justify-content: center;
	}
}

@media (max-width: 430px) {


	.chat__wrapper-dialogue-caption-opportunities-surch {
		margin-right: 10px;
		padding: 8px 8px;
		width: 20px;
	}

	.chat__wrapper-dialogue-caption-opportunities-tabs {
		padding: 6px 6px;
	}

	.chat__wrapper-dialogue-caption-info {
		margin-left: 10px;
	}

	.chat__wrapper-dialogue-messages {
		padding: 24px 18px 24px 18px;
	}

}

/* Photos modificators */


.chat__wrapper-dialogue-entry-photos {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	row-gap: 10px;
	column-gap: 10px;
}

.photos-element-wrapper {
	position: relative;
	max-width: 150px;
	width: 100%;
	height: 80px;
	flex-shrink: 0;
	display: flex;
}

.photos-element {
	width: 100%;
	height: 100%;
	display: flex;
}

.close {
	right: 5px;
	top: 5px;
	background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
	z-index: 20;
	background-size: cover;
	background-position: center;
	position: absolute;
	height: 15px;
	width: 15px;
}

.photos-element__img {
	width: 100%;
	object-fit: cover;
	border-radius: 8px;
	box-sizing: border-box;
}

.wrapper-three-photo-desctop {
	height: calc(100vh - 494px);
}

.wrapper-two-photo-desctop {
	height: calc(100vh - 577px);
}

@media (max-width: 1400px) {
	.wrapper-two-photo-desctop {
		height: calc(100vh - 495px);
	}
}

@media (max-width: 1030px) {
	.wrapper-three-photo-desctop {
		height: calc(100vh - 583px);
	}
}

@media (max-width: 900px) {
	.wrapper-three-photo-desctop {
		height: calc(100vh - 357px);
	}

	.wrapper-two-photo-desctop {
		height: calc(100vh - 357px);
	}
}

@media (max-width: 500px) {
	.wrapper-three-photo-desctop {
		height: calc(100vh - 446px);
	}

	.photos-element {
		max-width: 120px;
		height: 60px;
	}
}

@media (max-width: 431px) {
	.wrapper-two-photo-desctop {
		height: calc(100vh - 366px);
	}
}

@media (max-width: 339px) {
	.wrapper-three-photo-desctop {
		height: calc(100vh - 488px);
	}

	.wrapper-three-photo-desctop {
		height: calc(100vh - 488px);
	}

	.photos-element-wrapper {
		max-width: 100%;
		height: 60px;
	}
}

@media (max-width: 339px) {
	.wrapper-two-photo-desctop {
		height: calc(100vh - 419px);
	}
}
</style>