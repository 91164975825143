<template>
  <div class="record-comments-element">
    <img :src="comment.userAvatarUrl" alt="" class="record-caption__logo" @click="navigateBasedOnRole"/>
    <div class="comments-element__info">
      <div class="flex">
        <h4 class="record-caption__description-name" @click="navigateBasedOnRole">{{ comment.userName }}</h4>
      </div>
      <p class="record-info mt10 record-comment-text">{{ comment.body }}</p>
      <div class="mt10 flex record-caption__description-mobile">
        <time class="record-caption__description-time block">{{
            date(comment.createDate) + " " + time(comment.createDate)
          }}
        </time>
      </div>
    </div>
    <button v-if="$store.getters?.user?.id===comment?.userId" @click="deleteComment" class="delete"></button>
  </div>
</template>
<!-- candidateProfile
employerProfile -->
<script>
import {getDate, getTime} from "@/lib/dateUtils";

export default {
  props: ["comment"],
  name: "SeparateComment",
  data() {
    return {
      date: getDate,
      time: getTime,
    };
  },
  methods: {
		navigateBasedOnRole() {
      if (this.comment.role === 'candidate') {
        this.$router.push({ name: 'candidateProfile', params: { summaryId: this.comment.userId } });
      } else {
        this.$router.push({ name: 'employerProfile', params: { summaryId: this.comment.userId } });
      }
    },
    deleteComment() {
      this.$store.dispatch('deleteComment', this.comment?.id).then(status => {
        if (status === 200) {
          this.$emit('deleteComment', this.comment?.id);
        }
      })
    }
  }
};
</script>

<style scoped>
.record-comments-element {
  display: flex;
  margin-top: 40px;
  align-items: flex-start;
  border-top: 1px solid #efeff4;
  padding-top: 40px;
}

.record-caption__logo {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;
	cursor: pointer
}

.comments-element__info {
  margin-left: 20px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.record-caption__description-name {
  font-family: "Roboto";
	cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.record-activity__element {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.record-activity__element:not(:last-child) {
  margin-right: 40px;
}

.record-activity__like-img {
  background: url("~@/assets/img/account-img/communities/like.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 18px;
  display: block;
}

.record-activity__text {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.record-info {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
  margin-top: 20px;
  max-width: 70%;
  width: 100%;
}

.record-comment-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 1.6;
  text-align: left;
  color: #424242;
}

.mt10 {
  margin-top: 10px;
}

.record-caption__description-time {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.4000000059604645);
  margin-top: 10px;
}

.block {
  display: block;
}

.element__activite-reply {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.comments-element__activite {
  display: flex;
  align-items: center;
}

.element__activite-reply {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.record-activity__element {
  display: flex;
  align-items: center;
}

.record-activity__element:not(:last-child) {
  margin-right: 40px;
}

.record-activity__like-img {
  background: url("~@/assets/img/account-img/communities/like.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 18px;
  display: block;
}

.record-activity__text {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.mr20 {
  margin-right: 20px;
}

.mobile-block {
  display: none;
}

.flex {
  display: flex;
}

.delete {
  margin-left: 10px;
  display: flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background: center / contain no-repeat url("~@/assets/img/vacancy/delete.svg");
}

@media (max-width: 1575px) {
  .record-caption__description-mobile {
    align-items: center;
  }

  .record-caption__description-time {
    margin-top: 0;
  }
}

@media (max-width: 950px) {
  .desctop-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .record-comment-text {
    font-size: 12px !important;
  }

  .mt10 {
    margin-top: 10px;
  }

  .element__activite-reply {
    margin-left: 20px;
    font-size: 10px;
  }

  .record-caption__description-mobile time {
    font-size: 10px;
    margin-top: 0;
  }

  .mobile-likes button {
    height: 11px;
    width: 11px;
    background-size: cover;
  }

  .mobile-likes p {
    font-size: 10px;
    margin-left: 6px;
  }

  .record-caption__logo {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .comments-element__info {
    margin-left: 10px;
  }
}
</style>
