<template>
  <div class="documenet">
    <div class="documenet-filling">
      <p class="title">{{ $t('components.account_worker.documentDelete.title') }}</p>
      <div class="actions">
        <button class="actions-btn save" @click="$emit('approve')">{{
          $t('components.account_worker.documentDelete.approve') }}</button>
        <button class="actions-btn cancel" @click="$emit('cancel')">{{
          $t('components.account_worker.documentDelete.cancel') }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DocumentDelete",
}
</script>


<style scoped>
* {
  box-sizing: border-box;
}

.documenet {
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documenet-filling {

  max-width: 380px;
  width: 100%;
  background: #fff;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}


.title {
  font-size: 24px;
  font-weight: 500;
}


.actions {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.actions-btn {
  max-width: 180px;
  width: 100%;
  display: flex;
  padding: 16px;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 1s ease-in-out;
}

.actions-btn:hover {
  opacity: 0.5;
}

.save {
  background: #14C686;
  color: #fff;
}

.cancel {
  background: #db0a0a;
  color: #fff;
}

@media (max-width: 767px) {
  .documenet {
    padding: 20px 10px;
  }

  .actions {
    justify-content: center;
  }

  .title {
    font-size: 20px;
  }

  .search {
    margin-top: 12px;
  }

  .documenet-filling {
    padding: 20px 12px;
  }
}
</style>