<template>
  <router-link
      :to="user.role==='candidate'?{name:'candidateProfile',params:{summaryId:user.id}}:{name:'employerProfile',params:{summaryId:user.id}}"
      class="participants-group__persone" :style="{backgroundImage:'url('+user.avatarUrl+')' }"></router-link>
</template>

<script>

export default {
  name: "SeparatePersone",
  props: ['user']

};


</script>

<style scoped>
.participants-group__persone {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
</style>