<template>
	<div>
		<SocialHeader :role="$store.getters.role"></SocialHeader>
		<main class="main">
			<div class="account">
				<SocialMenu :role="$store.getters.role"></SocialMenu>
				<div class="all">
					<p class="subtitle">{{ $t('pages.social.socialCommunitiesListPage.applicantProfile') }}</p>
					<div class="title__wrapper">
						<h1 class="title">{{ $t('pages.social.socialCommunitiesListPage.communities') }}</h1>
						<button class="community-who mobile-block" v-on:click="statusVizable = !statusVizable">
							<p class="community-who-text">{{ $t('pages.social.socialCommunitiesListPage.myCommunities') }} <span
									class="community-who-img"></span></p>
							<div class="community" v-if="statusVizable">
								<div class="community__caption">
									<a href="" class="community__caption-btn community__caption-btn-selected">{{
										$t('pages.social.socialCommunitiesListPage.myCommunities') }}</a>
									<a href="" class="community__caption-btn">{{
										$t('pages.social.socialCommunitiesListPage.myApplications') }}</a>
									<a href="" class="community__caption-btn">{{
										$t('pages.social.socialCommunitiesListPage.communityInvitations') }} <span
											class="community-messages">99+</span></a>
								</div>
								<div class="community__best-container" v-if="false">
									<a href="#" class="community__best">
										<span class="community__best-icon"></span>
										<span class="community__best-text">{{
											$t('pages.social.socialCommunitiesListPage.possibleYouLike') }}</span>
									</a>
								</div>
							</div>
						</button>
					</div>
					<nav class="nav desctop-block">
						<div class="list">
							<a href="" class="list__item">{{ $t('pages.social.socialCommunitiesListPage.allCommunities') }}</a>
							<a href="" class="list__item list__item-selected">{{
								$t('pages.social.socialCommunitiesListPage.myCommunities') }}</a>
							<a href="" class="list__item" v-if="false">{{ $t('pages.social.socialCommunitiesListPage.governedBy')
								}}</a>
						</div>
					</nav>
					<div class="possible mobile-block" v-if="false">
						<h2 class="possible__title">{{ $t('pages.social.socialCommunitiesListPage.possibleCommunities') }}</h2>
						<div class="possible__list">
							<PossibleCommunities></PossibleCommunities>
							<PossibleCommunities></PossibleCommunities>
							<PossibleCommunities></PossibleCommunities>
							<PossibleCommunities></PossibleCommunities>
						</div>
						<button class="possible__show">{{ $t('pages.social.socialCommunitiesListPage.viewAll') }} <span
								class="possible__show-icon"></span></button>
					</div>
					<SurchBlue></SurchBlue>
					<div class="filling">
						<div class="filling__people">
							<SeparateCommunity v-for="item in myCommunities" :key="item.id" :com-data="item">
							</SeparateCommunity>
						</div>
						<div class="interaction">
							<div class="community desctop-block">
								<div class="community__caption">
									<a href="" class="community__caption-btn community__caption-btn-selected">{{
										$t('pages.social.socialCommunitiesListPage.myCommunities') }}</a>
									<a href="" class="community__caption-btn">{{
										$t('pages.social.socialCommunitiesListPage.myApplications') }}</a>
									<a href="" class="community__caption-btn">{{
										$t('pages.social.socialCommunitiesListPage.communityInvitations') }} <span
											class="community-messages">99+</span></a>
								</div>
								<div class="community__best-container" v-if="false">
									<a href="#" class="community__best">
										<span class="community__best-icon"></span>
										<span class="community__best-text">{{
											$t('pages.social.socialCommunitiesListPage.possibleYouLike') }}</span>
									</a>
								</div>
							</div>
							<div class="possible desctop-block" v-if="false">
								<h2 class="possible__title">{{ $t('pages.social.socialCommunitiesListPage.possibleYouLike') }}
								</h2>
								<div class="possible__list">
									<PossibleCommunities></PossibleCommunities>
									<PossibleCommunities></PossibleCommunities>
									<PossibleCommunities></PossibleCommunities>
									<PossibleCommunities></PossibleCommunities>
								</div>
								<button class="possible__show">{{ $t('pages.social.socialCommunitiesListPage.viewAll') }} <span
										class="possible__show-icon"></span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparateCommunity from "../../components/social/account_communitis/SeparateCommunity.vue";
import SurchBlue from "../../components/social/account_friensds/SurchBlue.vue";
import PossibleCommunities from "../../components/social/account_communitis/PossibleCommunities.vue";

export default {
	components: { SocialMenu, SocialHeader, SeparateCommunity, SurchBlue, PossibleCommunities },
	name: "SocialCommunitiesListPage",
	computed: {
		myCommunities() {
			return this.$store.getters.getMyCommunities;
		}
	},
	data() {
		return {
			statusVizable: false,
		};
	},
	beforeMount() {
		this.$store.dispatch('fetchMyCommunities');
	}
};
</script>


<style scoped>
.community-messages {
	margin-left: 11px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 12px;
	display: inline;
	padding: 3px 8px;
	background: #14c686;
	border-radius: 20px;
	color: #fff;
	line-height: 1;
}

.account {
	display: flex;
}

.all {
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	padding: 59px 30px 0 30px;
}

.subtitle {
	display: none;
}

.title {
	padding-top: 37px;
	font-weight: 700;
	font-size: 26px;
	color: #3b4c68;
}

.nav {
	margin-top: 40px;
}

.list__item:not(:last-child) {
	margin-right: 40px;
}

.list__item {
	position: relative;
	padding-bottom: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #3b4c68;
}

.list__item.list__item-selected::after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	content: "";
	display: block;
	background: #009ed1;
}

.filling {
	margin-top: 40px;
	display: flex;
	width: 100%;
	box-sizing: border-box;
}

.filling__people {
	width: 100%;
	max-width: 100%;
}

.interaction {
	max-width: 388px;
	width: 100%;
	box-sizing: border-box;
	margin-left: 30px;
}

.community {
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
}

.community__caption {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
}

.community__caption-btn {
	margin-top: 10px;
	padding: 5px 0 5px 22px;
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	color: #44444f;

}

.community__caption-btn.community__caption-btn-selected {
	display: block;
	background: rgba(239, 239, 244, 0.5);
	position: relative;
}

.community__caption-btn.community__caption-btn-selected::before {
	position: absolute;
	content: "";
	left: -1px;
	border-radius: 0px 20px 20px 0;
	width: 2px;
	height: 100%;
	display: block;
	background: #009ED1;
	top: 0;
}

.community__best-container {
	margin-top: 15px;
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding: 0 12px;
}

.community__best {
	display: flex;
	width: 100%;
	padding: 18px 6px 13px 6px;
	border-top: 1px solid #efeff4;
	box-sizing: border-box;
	align-items: center;

}

.community__best-text {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.03em;
	color: #44444f;
	margin: 2px 0 0 3px;
}

.community__best-icon {
	background: url("~@/assets/img/account-img/friends/star.svg");
	width: 24px;
	height: 24px;
	display: block;
}

.possible {
	margin-top: 20px;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	padding: 0 12px;
}

.possible__title {
	padding-top: 20px;
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
}

.possible__list {
	padding-top: 10px;
	width: 100%;
}

.possible__show {
	display: flex;
	justify-content: center;
	width: 100%;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.03em;
	color: #44444f;
	padding: 15px 0;
}

.possible__show-icon {
	width: 9px;
	height: 9px;
	margin-left: 4px;
	display: flex;
	background-image: url("~@/assets/img/account-img/friends/list.svg");
	background-size: cover;
	cursor: pointer;
	margin-top: 3px;
}

.mobile-block {
	display: none;
}

.show-more {
	display: none;
}

@media (max-width: 1575px) {
	.filling__people {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 20px;
		column-gap: 20px;
	}

	.filling__people {
		margin-bottom: 0;
	}
}

@media (max-width: 1100px) {
	.filling__people {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 950px) {
	.title {
		padding-top: 0;
	}

	.all {
		padding: 76px 10px 30px 10px;
	}
}

@media (max-width: 720px) {
	.form {
		margin-top: 40px;
	}

	.possible__title {
		padding-left: 0;
	}

	.filling {
		margin-top: 20px;
	}

	.desctop-block {
		display: none;
	}

	.mobile-block {
		display: block;
	}

	.title {

		font-size: 24px;
		line-height: 29px;
	}

	.title__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.community-who {
		margin-left: auto;
		text-align: left;
		padding: 10px 0 10px 10px;
		position: relative;
	}

	.community-who-text {
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #3e4453;
		position: relative;
		align-items: center;
		display: flex;
	}

	.community-who-text::before {
		position: absolute;
		left: -6px;
		width: 4px;
		height: 4px;
		border-radius: 100%;
		background: #14c686;
		display: block;
		content: "";
		top: calc(50% - 2px);
	}

	.community-who-img {
		display: block;
		background-image: url("~@/assets/img/account-img/friends/list-blue.svg");
		background-repeat: no-repeat;
		margin-left: 10px;
		width: 10px;
		height: 10px;
		background-position-y: 1px;
	}

	.subtitle {
		display: block;
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #009ed1;
	}

	.all {
		padding: 76px 10px 30px 10px;
		max-width: 420px;
	}

	.community {
		position: absolute;
		width: 300px;
		right: 0px;
		z-index: 30;
		top: 44px;
	}


	.interaction {
		display: none;
	}

	.show-more {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		border: 1px solid #009ed1;
		border-radius: 7px;
		font-family: "SFUIDisplay";
		font-weight: 600;
		font-size: 15px;
		line-height: 17px;
		color: #009ed1;
	}

}
</style>
