<template>
  <div>
    <ModalWindow v-if="viewVideo" @close="viewVideo = false" :questions="currentVideoInterview"
      :video="(mode === 'interview' ? getDocumentsData.videoInterviewUrl : getDocumentsData.psychologicalTestUrl) + '?token=' + $store.getters.token">
    </ModalWindow>
    <header-account-worker></header-account-worker>
    <main class="main">
      <div class="account">
        <menu-worker></menu-worker>

        <div class="container-account">
          <div class="test">
            <div class="test__caption-category" v-if="!start">
              <div class="select">
                <div class="select-filling" @click="viewMode = !viewMode">
                  <p class="select-text">{{ mode === 'interview' ?
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.videoInterview') :
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.psychologicalTest') }}</p>
                </div>
                <div class="pop-up" v-if="viewMode">
                  <button class="pop-up__element" @click="changeMode('interview')">{{
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.videoInterview') }}</button>
                  <button class="pop-up__element" @click="changeMode('test')">{{
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.psychologicalTest') }}</button>
                </div>
              </div>
            </div>
            <div class="test__wrapper">
              <div class="test__wrapper-content"
                v-if="(mode === 'interview' && !getDocumentsData.videoInterviewUrl) || ((mode === 'test' && !getDocumentsData.psychologicalTestUrl))">
                <div class="test__wrapper-filling">
                  <div class="video-container">
                    <video ref="preview" playsinline class="video" autoplay muted></video>
                    <div class="video-active">
                    </div>
                  </div>
                  <div class="filling-text">
                    <p ref="log"></p>
                    <div class="test__wrapper-content-caption">
                      <p class="test__wrapper-content-caption-question">
                        {{ currentVideoInterview[iterator].title }}
                      </p>
                    </div>
                    <p class="test__wrapper-content-text">
                      {{ currentVideoInterview[iterator].description }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="already-recorded">
                <h3 class="already-recorded-title">{{
                  $t('pages.account.account_worker.accountWorkerVideoInterviewPage.alreadyRecordedVideo') }}</h3>
                <div class="actions">
                  <button class="already-recorded-button" @click="viewVideo = true">{{
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.watchVideo') }}</button>
                  <button class="already-recorded-button delete" @click="deleteVideo">{{
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.deleteVideo') }}</button>
                </div>
                <a ref="view" hidden target="_blank"
                  :href="(mode === 'interview' ? getDocumentsData.videoInterviewUrl : getDocumentsData.psychologicalTestUrl) + '?token=' + $store.getters.token"></a>
              </div>
              <nav class="test__wrapper-nav">
                <button v-if="start" class="test__wrapper-nav-next-btn" @click="nextQuestion">
                  {{ currentVideoInterview.length - 1 === iterator ?
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.finish') :
                    $t('pages.account.account_worker.accountWorkerVideoInterviewPage.nextQuestion') }}
                </button>
                <button class="video-button video-start"
                  v-if="!start && ((mode === 'interview' && !getDocumentsData.videoInterviewUrl) || (mode === 'test' && !getDocumentsData.psychologicalTestUrl))"
                  @click="recordLoading">
                  {{ $t('pages.account.account_worker.accountWorkerVideoInterviewPage.startRecording') }}
                </button>
                <button class="video-button video-stop" v-if="start" ref="stop" @click="recordStop">
                  {{ $t('pages.account.account_worker.accountWorkerVideoInterviewPage.stopRecording') }}
                </button>

              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuWorker from "@/components/account/account_worker/MenuWorker";
import HeaderAccountWorker from "@/components/account/account_worker/HeaderAccountWorker";
import ModalWindow from "../../../components/account/account_worker/account_worker_video/ModalWindow.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(['currentVideoInterview', 'getDocumentsData'])
  },
  name: "AccountWorkerVideoInterviewPage",
  components: { HeaderAccountWorker, MenuWorker, ModalWindow },
  data() {
    return {
      file: null,
      viewMode: false,
      mode: null,
      start: false,
      iterator: 0,
      end: false,
      accessDevice: false,
      viewVideo: false,
      mediaRecorder: null,
      chunks: []
    }
  },
  beforeMount() {
    this.changeMode('interview');
    this.$store.dispatch('fetchDocuments');
  },
  methods: {
    deleteVideo() {
      this.$store.commit('deleteVideoInterview', this.mode);
      this.$store.dispatch('saveDocumentData', {
        doc: () => this.$store.dispatch('fetchDocuments')
      });
    },
    async nextQuestion() {
      if (this.iterator < this.currentVideoInterview.length - 1) {
        this.iterator++;
      } else {
        this.end = true;
        this.recordStop();
        this.iterator = 0;
      }
    },
    async changeMode(value) {
      this.mode = value;
      this.viewMode = false;
      await this.$store.dispatch('fetchCurrentVideoInterview', this.mode);
      this.iterator = 0;
      this.end = false;
    },

    recordStart() {
      this.mediaRecorder?.start();
      this.start = true;
    },
    recordStop() {
      this.mediaRecorder?.stop();
      this.start = false;
    },
    onSuccess(stream) {
      this.mediaRecorder = new MediaRecorder(stream);
      const preview = this.$refs.preview;
      preview.srcObject = stream;

      this.mediaRecorder.onstop = async () => {
        if (this.end) {
          this.file = new File(this.chunks, 'file.mp4', { 'type': 'video/mp4; codecs=avc1.4d002a' });
          await this.$store.commit('setFileQueue', { field: this.mode, file: this.file });
          await this.$store.dispatch("saveDocumentData", {
            files: () => this.$store.dispatch('uploadDocumentDataFiles', () => this.$store.dispatch('saveDocumentData', {
              doc: () => this.$store.dispatch('fetchDocuments')
            })),
            doc: () => this.$store.dispatch('fetchDocuments')
          });
        }
        await this.changeMode(this.mode)
        this.chunks = [];
        await stream.getTracks().forEach(track => track.stop());
      }

      this.mediaRecorder.ondataavailable = (e) => {
        this.chunks.push(e.data);
      }
    },

    recordLoading() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(this.onSuccess).then(this.recordStart);
      } else {
        console.log('getUserMedia not supported on your browser!');
      }
    },
  },
  beforeDestroy() {
    if (this.mediaRecorder?.state !== 'inactive')
      this.recordStop();
  }
}
</script>


<style scoped>
.account {
  display: flex;
}

.account {
  display: flex;
}



.test__wrapper {
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content {
  padding: 46px 63px 59px 62px;
  border-bottom: 1px solid #e9e9f9;
}

.test__wrapper-filling {
  display: flex;
  gap: 20px;
}

.already-recorded {
  padding: 46px 63px 6px 62px;
}

.already-recorded-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.actions {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.already-recorded-button {

  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #009ed1;
  border-radius: 12px;
  max-width: 207px;
  width: 100%;
  transition: opacity 1s ease;
}

.delete {
  background: #f9eeee;
  color: #ab3e5e;
}

.already-recorded-button:hover {
  opacity: 0.5;
}

.filling-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.test__wrapper-content-caption {
  display: flex;
}

.test__wrapper-content-caption-question {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 15px;
  line-height: 1.28;
  color: #009ed1;
}

.test__wrapper-content-text {
  margin-top: 23px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.test__wrapper-nav {
  padding: 20px 63px 20px 62px;
  display: flex;
  align-items: center;
  position: relative;
}


.test__wrapper-nav-next-btn {
  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #009ed1;
  border-radius: 12px;
  max-width: 207px;
  width: 100%;
  transition: opacity 1s ease;
}

.test__wrapper-nav-next-btn:hover {
  opacity: 0.5;
}

.video-button {
  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  border-radius: 12px;
  max-width: 207px;
  width: 100%;
  transition: opacity 1s ease;
  margin-left: auto;
}

.video-button:hover {
  opacity: 0.5;
}

.video-start {
  background: #14c686;
}

.video-stop {
  background: #FF003D;
}

.test__caption-category {
  display: flex;
}

.select {
  position: relative;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 500;
  box-sizing: border-box;

  color: #171725;
}

.select-filling {
  min-width: 250px;
  padding: 16px 20px;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease, border 1s ease, color 1s ease;
}

.select-filling:hover {
  background-color: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  border: 1px solid #009ed1;
  color: #009ed1;
}

.pop-up {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #bcbcbc;
  border-top: none;
  border-radius: 8px;
  left: 0;
  background-color: #fff;
  z-index: 20;
}

.pop-up__element {
  text-align: left;
  padding: 16px 20px;
  transition: color 1s ease;
}

.pop-up__element:hover {
  color: #009ed1;
}

.pop-up__element:not(:first-child) {
  border-top: 1px solid #bcbcbc;
}

/* video */
.video-container {
  width: 100%;
  position: relative;
}

.video {
  width: 100%;
  height: 40vh;
  background: #000;
  border-radius: 12px;
  transform: scale(-1, 1);
}

.video-active {
  position: absolute;
  left: calc(50%);
  bottom: 40px;
}

@media (max-height: 800px) {
  .video {
    height: 30vh;
  }
}

@media (max-width: 800px) {
  .container-account {
    padding-top: 77px;
  }

  .test__wrapper-content {
    padding: 20px;
  }

  .container-account {
    max-width: 700px;
  }

  .test__wrapper-nav {
    padding: 20px;
  }
}

@media (max-width: 700px) {
  .test {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .already-recorded {
    padding: 10px 0 0 0;
  }

  .already-recorded-title {
    font-size: 24px;
  }

  .already-recorded-button {
    font-size: 13px;
    padding: 13px 0;
    max-width: 140px;
    margin-top: 20px;
  }

  .test__wrapper {
    background: none;
  }

  .test__wrapper-nav {
    padding: 20px 0;
  }

  .test__wrapper-nav-next-btn {
    max-width: 170px;
  }

  .video-button {
    max-width: 170px;
  }


  .test__wrapper {
    margin-top: 20px;
    border: none;
  }

  .test__wrapper-content {
    padding: 0 0 40px 0;
  }

  .test__wrapper-content-caption-question {
    font-size: 13px;
  }

  .test__wrapper-content-text {
    margin-top: 10px;
    font-size: 13px;
  }

  .test__wrapper-nav-next-btn {
    font-size: 13px;
    padding: 13px 0;
    max-width: 140px;
  }

  .video-button {
    font-size: 13px;
    padding: 13px 0;
    max-width: 140px;
  }

  .video-container {
    margin-top: 0px;
  }

  .test__wrapper-filling {
    flex-direction: column;
  }
}
</style>