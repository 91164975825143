import {config} from "@/lib/config";
import {errorHandler} from "@/lib/errorHandler";

export const friends = {
    actions: {
        fetchMyFriends({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/all' + '?role=' + payload.role + '&page=' + payload.page + '&limit=' + payload.limit + `&name=${payload.name ? payload.name : ''}`;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1) {
                    commit('setMyFriends', response.data);
                } else {
                    commit('addFriendData', response.data)
                }
            } catch (e) {
                console.log(e);
            }
        },
        deleteFriends({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/delete';
            request.open('POST', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                request.send(JSON.stringify({friendId: payload.id, userRole: payload.role}));
                errorHandler({status: request.status, page: 'deleteFriend'})
                commit('deleteFriend', payload.index);

            } catch (e) {
                console.log(e);
            }
        },


        fetchIncomingRequests({commit}, payload) {
            const request = new XMLHttpRequest()
            const url = config.apiPrefix + 'friends/income/all' + '?role=' + payload.role + '&page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1)
                    commit('setIncomingRequests', response.data);
                else commit('addIncomingData', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        acceptFriend({commit, getters}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/income/accept';
            request.open('POST', url, false);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send(JSON.stringify({friendId: payload.id, userRole: payload.role}))
                errorHandler({status: 200, page: 'acceptIncoming'})
                commit('cancelIncoming', payload.index);
            } catch (e) {
                console.log(e);
            }

            if (getters.role === 'CANDIDATE') {
                this.dispatch('fetchCandidateData');
            } else if (getters.role === 'EMPLOYER') {
                this.dispatch('fetchEmployerData');
            }
        },
        cancelIncomingRequest({commit, getters}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/income/decline';
            request.open('POST', url, false);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send(JSON.stringify({friendId: payload.id, userRole: payload.role}))
                errorHandler({status: 400, page: 'cancelIncoming'})
                commit('cancelIncoming', payload.index);
            } catch (e) {
                console.log(e);
            }
            if (getters.role === 'CANDIDATE') {
                this.dispatch('fetchCandidateData');
            } else if (getters.role === 'EMPLOYER') {
                this.dispatch('fetchEmployerData');
            }
        },


        fetchOutgoingRequests({commit}, payload) {
            const request = new XMLHttpRequest()
            const url = config.apiPrefix + 'friends/outcome/all' + '?role=' + payload.role + '&page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1)
                    commit('setOutgoingRequests', response.data);
                else commit('addOutgoingData', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        cancelOutgoingRequest({commit, getters}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/outcome/delete';
            request.open('POST', url, false);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send(JSON.stringify({friendId: payload.id, userRole: payload.role}))
                errorHandler({status: 400, page: 'cancelOutgoing'})
                commit('cancelOutgoing', payload.index);
            } catch (e) {
                console.log(e);
            }
            if (getters.role === 'CANDIDATE') {
                this.dispatch('fetchCandidateData');
            } else if (getters.role === 'EMPLOYER') {
                this.dispatch('fetchEmployerData');
            }
        },


        findUser({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'friends/search' + '?role=' + payload.role + '&page=' + payload.page + '&limit=' + payload.limit + '&name=' + (payload.name ? payload.name : '') + '&sort=' + (payload.sort ? payload.sort : 'default') + '&minrate=' + (payload.minrate ? payload.minrate : '') + '&maxrate=' + (payload.maxrate ? payload.maxrate : '') + '&city=' + (payload.city ? payload.city : '') + '&profession=' + (payload.profession ? payload.profession : '');
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1) {
                    commit('setFindUser', response.data);
                } else {
                    commit('addFindUser', response.data)
                }
            } catch (e) {
                console.log(e);
            }
        },
        // sendRequestFriend({commit}, payload) {
        //     const request = new XMLHttpRequest();
        //     const url = config.apiPrefix + 'friends/outcome';
        //     request.open('POST', url, false)
        //     request.setRequestHeader('X-Auth-Token', payload.token);
        //     request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        //     try {
        //         request.send(JSON.stringify({userRole: payload.role, userId: payload.id}))
        //         errorHandler({status: 200, page: 'addFriend'});
        //         commit('deleteFindUser', payload.index);
        //     } catch (e) {
        //         console.log(e)
        //     }

        // },  
				blockFriend({commit}, payload) {
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'friends/blocked/add';
					request.open('POST', url, false)
					request.setRequestHeader('X-Auth-Token', payload.token);
					request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
					try {
							request.send(JSON.stringify({blockingRole: payload.blockingRole, blockingUserId: payload.blockingUserId}))
					//		errorHandler({status: 200, page: 'addFriend'});
							commit('addBlockedFriend', payload.blockingUserId);
					} catch (e) {
							console.log(e)
					}

			},	
			unblockFriend({commit}, payload) {
					const request = new XMLHttpRequest();
					const url = config.apiPrefix + 'friends/blocked/remove';
					request.open('POST', url, false)
					request.setRequestHeader('X-Auth-Token', payload.token);
					request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
					try {
							request.send(JSON.stringify({blockingRole: payload.blockingRole, blockingUserId: payload.blockingUserId}))
					//		errorHandler({status: 200, page: 'addFriend'});
					commit('removeBlockedFriend', payload.blockingUserId);
					} catch (e) {
							console.log(e)
					}

			},
			fetchMyBlockedFriends({commit}, payload) {
				const params = new URLSearchParams({
						page: payload.page,
						limit: payload.limit,
						role: payload.role
				}).toString();
				const url = `${config.apiPrefix}friends/blocked/all?${params}`;
				const request = new XMLHttpRequest();
				request.open('GET', url, false);
				request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
				request.setRequestHeader('X-Auth-Token', payload.token);
		
				try {
						request.send(); 
						const response = JSON.parse(request.response);
						if (response.data.length < payload.limit) {
								payload.callback();
						}
						if (payload.page === 1) {
								commit('setMyBlockedFriends', response.data);
						} else {
								commit('addBlockedFriendData', response.data);
						}
				} catch (e) {
						console.log(e);
				}
		},
			fetchFavoriteFriends({commit}, payload) {
				const params = new URLSearchParams({
						page: payload.page,
						limit: payload.limit,
						role: payload.role
				}).toString();
				const url = `${config.apiPrefix}friends/favorite/all?${params}`;
				const request = new XMLHttpRequest();
				request.open('GET', url, false);
				request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
				request.setRequestHeader('X-Auth-Token', payload.token);
		
				try {
						request.send(); 
						const response = JSON.parse(request.response);
						if (response.data.length < payload.limit) {
								payload.callback();
						}
						if (payload.page === 1) {
								commit('setFavoriteFriends', response.data);
						} else {
								commit('addFavoriteFriend', response.data);
						}
				} catch (e) {
						console.log(e);
				}
		},
		addToFavoriteFriends({commit}, payload) {
			const request = new XMLHttpRequest();
			const url = config.apiPrefix + 'friends/favorite/add';
			request.open('POST', url, false)
			request.setRequestHeader('X-Auth-Token', payload.token);
			request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
			try {
					request.send(JSON.stringify({friendId: payload.friendId, friendRole: payload.friendRole}))
			//		errorHandler({status: 200, page: 'addFriend'});
					commit('addFavoriteFriend', payload.friendId);
			} catch (e) {
					console.log(e)
			}

	},	
	removeFromFavoriteFriends({commit}, payload) {
			const request = new XMLHttpRequest();
			const url = config.apiPrefix + 'friends/favorite/remove';
			request.open('POST', url, false)
			request.setRequestHeader('X-Auth-Token', payload.token);
			request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
			try {
					request.send(JSON.stringify({friendId: payload.friendId, friendRole: payload.friendRole}))
			commit('removeFavoriteFriend', payload.friendId);
			} catch (e) {
					console.log(e)
			}

	},
    },
    mutations: {
        cancelOutgoing(state, index) {
            state.outgoingRequests.splice(index, 1);
        },
        cancelIncoming(state, index) {
            state.incomingRequest.splice(index, 1);

        },
        deleteFindUser(state, index) {
            state.myFriends.splice(index, 1);
        },
        setFindUser(state, data) {
            state.myFriends = data;
        },
        addFindUser(state, data) {
            state.myFriends = state.myFriends.slice().concat(data);
        },
        addOutgoingData(state, data) {
            state.outgoingRequests = state.outgoingRequests.slice().concat(data);
        },
        addIncomingData(state, data) {
            state.incomingRequest = state.incomingRequest.slice().concat(data);
        },
        addFriendData(state, data) {
            state.myFriends = state.myFriends.slice().concat(data);
        }, 
				addBlockedFriendData(state, data) {
            state.myBlockedFriends = state.myBlockedFriends.slice().concat(data);
        },
				addBlockedFriend(state, userId) {
					const userExistsInBlocked = state.myBlockedFriends.some(user => user.id === userId);
					if (!userExistsInBlocked) {
						const userToBlock = state.myFriends.find(user => user.id === userId);
						if (userToBlock) {
							state.myBlockedFriends = [...state.myBlockedFriends, userToBlock];
						}
					}
				},
				removeBlockedFriend(state, userId) {
					state.myBlockedFriends = state.myBlockedFriends.filter(user => user.id !== userId);
				},
        setIncomingRequests(state, data) {
            state.incomingRequest = data;
        },
        setMyFriends(state, data) {
            state.myFriends = data;
        },  
				setMyBlockedFriends(state, data) {
            state.myBlockedFriends = data;
        },	
				setFavoriteFriends(state, data) {
            state.favoriteFriends = data;
        },
				addFavoriteFriend(state, userId) {
					const userExistsInBlocked = state.favoriteFriends.some(user => user.id === userId);
					if (!userExistsInBlocked) {
						const userToFavorite = state.myFriends.find(user => user.id === userId);
						if (userToFavorite) {
							state.favoriteFriends = [...state.favoriteFriends, userToFavorite];
						}
					}
				},
				removeFavoriteFriend(state, userId) {
					state.favoriteFriends = state.favoriteFriends.filter(user => user.id !== userId);
				},
        resetPages(state) {
            state.page = 1;
        },
        setOutgoingRequests(state, data) {
            state.outgoingRequests = data;
        },
        saveCopyData(state, data) {
            state.copyData = JSON.parse(JSON.stringify(data));
        },
        pageUp(state) {
            state.page++;
        },
        deleteFriend(state, index) {
            state.myFriends.splice(index, 1);
        }
    },
    state: {
        findUsers: null,
        copyData: null,
        myFriends: null,
        myBlockedFriends: null,
        incomingRequest: null,
        outgoingRequests: null,
				favoriteFriends:[],
        page: 1,
        limit: 20
    },
    getters: {
        getCopyData(state) {
            return state.copyData;
        },
        getOutgoingRequests
            (state) {
            return state.outgoingRequests;
        }
        ,
        getMyFriends(state) {
            return state.myFriends;
        }
        ,
        friendPage(state) {
            return state.page;
        }
        ,
        getIncomingRequests(state) {
            return state.incomingRequest;
        }
        ,
        getLimit(state) {
            return state.limit;
        }
        ,
        getFindUsers(state) {
            return state.findUsers;
        },  
				getMyBlockedUser(state) {
            return state.myBlockedFriends;
        },
				getFavoriteFriends(state) {
            return state.favoriteFriends;
        }
    }
}