<template>
	<div class="test__group-element">
		<div class="test__group-element-caption" :style="{ backgroundImage: 'url(' + interview.previewUrl + ')' }">
			<div class="test__group-element-caption-rating-container">
				<div class="test__group-element-caption-rating">
					<div class="test__group-element-caption-rating-number-container">
						<p class="test__group-element-caption-rating-number">
							{{ interview.companyRate ? interview.companyRate : 0 }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="test__group-element-description">
			<div class="test__group-element-description-company">
				<img :src="interview.companyAvatarUrl" alt="" class="test__group-element-description-company-logo" />
				<p class="test__group-element-description-company-name">
					{{ limit(interview.companyName, 15) }}
				</p>
			</div>
			<h2 class="test__group-element-description-title">
				{{ limit(interview.title, 23) }}
			</h2>
			<p class="test__group-element-description-text">
				{{ limit(interview.description, 78) }}
			</p>
		</div>
		<div class="test__group-element-end">
			<div class="test__group-element-opportunities">
				<router-link class="test__group-element-opportunities-preview" :to="{
					name: 'interviewConstructPreview',
					params: { interviewId: this.interview.id },
				}">
					{{ $t('components.tests.separateInterview.preview') }}
				</router-link>
				<router-link class="test__group-element-opportunities-edit" :to="{
					name: 'interviewConstruct',
					params: { interviewId: this.interview.id },
				}">
					{{ $t('components.tests.separateInterview.edit') }}
				</router-link>
			</div>
			<router-link :to="{
				name: 'resultInterview',
				params: { interviewId: this.interview.id },
			}" class="test__group-element-results">
				{{ $t('components.tests.separateInterview.watchResults') }}
			</router-link>
		</div>
	</div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
	name: "SeparateInterview",
	props: {
		interview: {
			type: Object,
		},
	},
	data() {
		return {
			limit: textSymbolLimit
		};
	},
};
</script>


<style scoped>
.test__group-element {
	position: relative;
	max-width: 338px;
	width: 100%;
	box-sizing: border-box;
	height: 473px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 9px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.test__group-element-caption {
	height: 147px;
	border-radius: 9px 9px 0 0;
	background-repeat: no-repeat;
	background-size: cover;
}

.test__group-element-caption-rating-container {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 16px 19px;
}

.test__group-element-caption-rating-number-container {
	background: #3cd598;
	border-radius: 20px;
}

.test__group-element-caption-rating {
	display: flex;
}

.test__group-element-caption-rating-date {
	padding: 0 7px;
	margin-left: auto;
	background: rgba(50, 56, 76, 0.6000000238418579);
	border-radius: 20px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.test__group-element-caption-rating-number {
	padding: 0 10px 0 22px;
	background: url("~@/assets/img/test-page/test/star.png");
	background-position: 9px;
	background-position-y: 6px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.test__group-element-description {
	padding: 15px 25px 20px 20px;
}

.test__group-element-description-company {
	display: flex;
	align-items: center;
}

.test__group-element-description-company-logo {
	width: 46px;
	height: 46px;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
}

.test__group-element-description-company-name {
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.test__group-element-description-title {
	padding-top: 14px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.36;
	color: #3b4c68;
}

.test__group-element-description-text {
	padding-top: 11px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
	color: #686873;
	max-height: 43px;
	overflow-y: hidden;
}

.test__group-element-end {
	position: absolute;
	border-top: 1px solid #f1f1f5;
	padding: 15px 25px 20px 20px;
	width: 100%;
	box-sizing: border-box;
	bottom: 0;
}

.test__group-element-opportunities {
	text-align: center;
	display: flex;
	max-width: 300px;
	width: 100%;
	box-sizing: border-box;
}

.test__group-element-opportunities-preview {
	margin-right: 10px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	border: 1px solid #009ed1;
	border-radius: 10px;
	padding: 12px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #009ed1;
	transition: opacity 1s ease;
}

.test__group-element-opportunities-preview:hover {
	opacity: 0.5;
}

.test__group-element-opportunities-edit {
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	background: #f1f1f5;
	border-radius: 10px;
	padding: 12px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #696974;
	transition: opacity 1s ease;
}

.test__group-element-opportunities-edit:hover {
	opacity: 0.5;
}

.test__group-element-results {
	margin-top: 13px;
	display: flex;
	justify-content: center;
	max-width: 300px;
	width: 100%;
	box-sizing: border-box;
	background: #e6f6fc;
	border-radius: 10px;
	padding: 12px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #009ed1;
	transition: opacity 1s ease;
}

.test__group-element-results:hover {
	opacity: 0.5;
}

@media (max-width: 1369px) {
	.test__group-element {
		margin: 0 auto;
	}
}

@media (max-width: 400px) {
	.test__group-element {
		max-width: 300px;
	}

	.test__group-element-description {
		padding: 15px 25px 15px 20px;
	}

	.test__group-element-description-company-logo {
		width: 32px;
		height: 32px;
	}

	.test__group-element-description-title {
		font-size: 15px;
		line-height: 1.48;
	}

	.test__group-element-description-text {
		padding-top: 7px;
		font-size: 13px;
		line-height: 24px;
	}

	.test__group-element-opportunities {
		margin-top: 20px;
		max-width: 260px;
	}

	.test__group-element-opportunities-edit {
		font-size: 12px;
	}

	.test__group-element-opportunities-preview {
		font-size: 12px;
	}

	.test__group-element-results {
		margin-bottom: 21px;
		max-width: 260px;
		font-size: 12px;
	}
}
</style>
