<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="comtainer-account">
				<div class="account">
					<MenuCompany></MenuCompany>

					<div class="account-filling">
						<div class="account-filling-caption">
							<div class="account-filling-caption-description">
								<p class="account-filling-caption-description-text filling-caption-mobile">
									{{ $t('pages.tests.test_company.testConstructorPagePreview.previewTest') }}
								</p>
								<h2 class="account-filling-caption-description-title">
									{{ $t('pages.tests.test_company.testConstructorPagePreview.testing') }}
								</h2>
								<p class="account-filling-caption-description-text filling-caption-desctop">
									{{ $t('pages.tests.test_company.testConstructorPagePreview.previewTest') }}
								</p>
							</div>
							<div class="account-filling-caption-cancle">
								<button class="account-filling-caption-cancle-btn" @click="$router.go(-1)">
									{{ $t('pages.tests.test_company.testConstructorPagePreview.cancel') }}
								</button>
							</div>
						</div>
						<preview-wrapper-watch :preview="dataPreview"></preview-wrapper-watch>
						<TestConstructorWrapperWatch v-for="(item, index) in question" :key="item.id" :question="item"
							:count="index">
						</TestConstructorWrapperWatch>
						<div class="account-filling-end">
							<div class="account-filling-end-finish">
								<button class="account-filling-end-finish-btn" @click="saveAnswers">
									<p>{{ $t('pages.tests.test_company.testConstructorPagePreview.finishPreview') }}</p>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import PreviewWrapperWatch from "@/components/tests/test_constructor_elements/wrapper_component/PreviewWrapperWatch";
import TestConstructorWrapperWatch from "../../../components/tests/test_constructor_elements/wrapper_component/TestConstructorWrapperWatch";

export default {
	computed: {
		construct() {
			return this.$store.getters.getConstruct;
		},
	},
	components: {
		PreviewWrapperWatch,
		MenuCompany,
		HeaderAccountCompany,
		TestConstructorWrapperWatch,
	},
	props: {
		interviewId: {},
	},
	data() {
		return {
			dataPreview: null,
			question: null,
		};
	},
	beforeMount() {
		scroll(0, 0);
		this.$store.dispatch("fetchConstruct", {
			id: this.interviewId,
			token: this.$store.getters.token,
		});
		this.dataPreview = {
			title: this.construct.title,
			previewUrl: this.construct.previewUrl,
			description: this.construct.description,
			maxPassingTimeSeconds: this.construct.maxPassingTimeSeconds,
		};
		this.question = this.construct.questions;
	},
	methods: {
		saveAnswers() {
			this.$router.go(-1);
			scroll(0, 0);
		},
	},
	name: "TestConstructorPagePreview",
};
</script>



<style scoped>
.account {
	display: flex;
}

.account-filling {
	padding: 96px 30px 77px 30px;
	max-width: 1518px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}

.account-filling-end {
	margin-top: 30px;
	border-top: 2px solid #e8e8e8;
	width: 100%;
	box-sizing: border-box;
	padding-top: 102px;
	position: relative;
}

.account-filling-end-add {
	position: absolute;
	top: -33px;
	left: calc(50% - 33px);
	transition: opacity 1s ease;
}

.account-filling-end-add:hover {
	opacity: 0.5;
}

.account-filling-end-add-img {
	padding: 19px;
	background: #009ed1;
	border-radius: 16px;
}

.account-filling-end-finish {
	display: flex;
	justify-content: center;
}

.account-filling-end-finish-btn {
	padding: 23px 0;
	width: 273px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.account-filling-end-finish-btn:hover {
	opacity: 0.5;
}

.account-filling-end-cancel {
	display: none;
}

@media (max-width: 700px) {
	.account-filling {
		padding: 76px 10px 35px 10px;
	}

	.account-filling-end {
		padding-top: 54px;
		margin-top: 38px;
	}

	.account-filling-end-add {
		top: -23px;
		left: calc(50% - 22px);
	}

	.account-filling-end-add-img {
		padding: 13px;
		border-radius: 16px;
		width: 18px;
		height: 18px;
	}

	.account-filling-end-finish-btn {
		padding: 13px 0;
		font-size: 13px;
		width: 300px;
		border-radius: 10px;
	}

	.account-filling-end-cancel {
		margin-top: 11px;
		display: flex;
		justify-content: center;
	}

	.account-filling-end-cancel-btn {
		width: 133px;
		padding: 13px 0;
		background: #f1f1f5;
		border-radius: 10px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 13px;
		color: #696974;
		transition: opacity 1s ease;
	}

	.account-filling-end-cancel-btn:hover {
		opacity: 0.5;
	}
}

/* Caption */
.filling-caption-mobile {
	display: none;
}

.account-filling-caption {
	display: flex;
	align-items: center;
	padding-bottom: 40px;
}

.account-filling-caption-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.account-filling-caption-description-text {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.account-filling-caption-cancle {
	margin-left: auto;
}

.account-filling-caption-cancle-btn {
	padding: 16px 54px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #696974;

	background: #f1f1f5;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.account-filling-caption-cancle-btn:hover {
	opacity: 0.5;
}

@media (max-width: 700px) {
	.filling-caption-desctop {
		display: none;
	}

	.filling-caption-mobile {
		display: block;
	}

	.account-filling-caption-description-text {
		color: #009ed1;
		font-size: 13px;
	}

	.account-filling-caption-description-title {
		color: #000000;
		font-size: 20px;
	}
}

@media (max-width: 460px) {
	.account-filling-caption {
		padding-bottom: 16px;
		flex-direction: column;
		align-items: unset;
		align-items: center;
		text-align: center;
	}

	.account-filling-caption-cancle {
		margin-left: 0;
		margin-top: 15px;
	}

	.account-filling-caption-cancle-btn {
		font-size: 13px;
		padding: 12px 56px;
	}
}

/* /Caption */
</style>
