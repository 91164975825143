<template>
  <div class="test-container">
    <div class="test">
      <div class="test__number">
        <p class="test__number-element">{{ count + 2 }}</p>
      </div>
      <div class="test__wrapper">
        <div class="test__wrapper-content">
          <div class="test__wrapper-content-caption">
            <div class="test__wrapper-content-caption-name">
              <h2 class="test__wrapper-content-caption-name-title">
                {{ $t('components.tests.testConstructorWrapper.questionTitle') }}
              </h2>
              <textarea
                placeholder="{{ $t('components.tests.testConstructorWrapper.enterTitle') }}"
                v-model="dataQuestion.title"
                v-on:blur="
                  $store.commit('saveQuestionTitle', {
                    data: dataQuestion.title,
                    index: count,
                  })
                "
                class="test__wrapper-content-caption-name-textarea"
              ></textarea>
            </div>
            <div
              class="test__wrapper-content-caption-photo"
              v-if="dataQuestion.photoPath !== null"
            >
              <h3 class="test__wrapper-content-caption-visual-title">
                {{ $t('components.tests.testConstructorWrapper.image') }}
              </h3>
              <div class="test__wrapper-content-caption-photo-container">
                <button
                  class="test__wrapper-content-caption-visual-delete"
                  @click="removeFile('photo')"
                >
                  <img
                    src="~@/assets/img/test-img/constractor/delete-photo.svg"
                    alt="delete photo"
                    class="test__wrapper-content-caption-photo-visual-delete-img"
                  />
                </button>
                <button
                  class="test__wrapper-content-caption-visual-add"
                  @click="$refs.photo.click()"
                >
                  <img
                    src="~@/assets/img/test-img/constractor/add-photo.svg"
                    alt="add photo"
                    class="test__wrapper-content-caption-visual-add-img"
                  />
                </button>
                <img
                  :src="dataQuestion.photoPath"
                  alt="Add photo"
                  class="test__wrapper-content-caption-visual-photo"
                />
              </div>
            </div>
            <div
              class="test__wrapper-content-caption-video"
              v-if="dataQuestion.videoPath !== null"
            >
              <h3 class="test__wrapper-content-caption-visual-title">
                {{ $t('components.tests.testConstructorWrapper.video') }}
              </h3>
              <div class="test__wrapper-content-caption-video-container">
                <button
                  class="test__wrapper-content-caption-visual-delete"
                  @click="removeFile('video')"
                >
                  <img
                    src="~@/assets/img/test-img/constractor/delete-photo.svg"
                    alt="delete photo"
                    class="test__wrapper-content-caption-photo-visual-delete-img"
                  />
                </button>
                <button
                  class="test__wrapper-content-caption-visual-add"
                  @click="$refs.video.click()"
                >
                  <img
                    src="~@/assets/img/test-img/constractor/add-photo.svg"
                    alt="add photo"
                    class="test__wrapper-content-caption-visual-add-img"
                  />
                </button>

                <video
                  v-if="dataQuestion.videoPath !== null"
                  controls
                  :src="dataQuestion.videoPath"
                  class="test__wrapper-content-caption-visual-video"
                ></video>
              </div>
            </div>
            <div class="test__wrapper-content-caption-description">
              <h3 class="test__wrapper-content-caption-description-title">
                {{ $t('components.tests.testConstructorWrapper.description') }}
              </h3>
              <textarea
                class="test__wrapper-content-caption-description-textarea"
                placeholder="{{ $t('components.tests.testConstructorWrapper.enterContent') }}"
                v-model="dataQuestion.body"
                v-on:blur="
                  $store.commit('saveQuestionBody', {
                    index: count,
                    data: dataQuestion.body,
                  })
                "
              ></textarea>
            </div>
          </div>
          <div class="test__wrapper-content-filling">
            <div class="test__wrapper-content-filling-select-container">
              <button
                v-if="!dataQuestion.id || dataQuestion.id === undefined"
                ref="select"
                class="test__wrapper-content-filling-select"
                @click="selectVisible = !selectVisible"
              >
                <span
                  v-if="
                    !dataQuestion.questionType ||
                    dataQuestion.questionType === undefined
                  "
                >
                  {{ $t('components.tests.testConstructorWrapper.chooseAnswerType') }}
                </span>
                <span v-if="dataQuestion.questionType === 'single'">
                  {{ $t('components.tests.testConstructorWrapper.singleAnswer') }}
                </span>
                <span v-if="dataQuestion.questionType === 'multi'">
                  {{ $t('components.tests.testConstructorWrapper.multiAnswer') }}
                </span>
                <span v-if="dataQuestion.questionType === 'open'">
                  {{ $t('components.tests.testConstructorWrapper.openAnswer') }}
                </span>
              </button>
              <div
                class="test__wrapper-content-filling-select-group"
                v-if="selectVisible"
              >
                <input
                  type="radio"
                  id="one"
                  value="single"
                  class="test__wrapper-content-filling-select-element-input"
                  v-model="dataQuestion.questionType"
                  @change="selectVisible = !selectVisible"
                />
                <label
                  for="one"
                  class="test__wrapper-content-filling-select-element-label first-label"
                  >{{ $t('components.tests.testConstructorWrapper.singleAnswer') }}</label
                >
                <input
                  type="radio"
                  id="three"
                  value="multi"
                  class="test__wrapper-content-filling-select-element-input"
                  v-model="dataQuestion.questionType"
                  @change="selectVisible = !selectVisible"
                />
                <label
                  for="three"
                  class="test__wrapper-content-filling-select-element-label"
                  >{{ $t('components.tests.testConstructorWrapper.multiAnswer') }}</label
                >
                <input
                  type="radio"
                  id="four"
                  value="open"
                  class="test__wrapper-content-filling-select-element-input"
                  v-model="dataQuestion.questionType"
                  @change="selectVisible = !selectVisible"
                />
                <label
                  for="four"
                  class="test__wrapper-content-filling-select-element-label"
                  >{{ $t('components.tests.testConstructorWrapper.openAnswer') }}</label
                >
              </div>
            </div>
            <p
              class="test__wrapper-content-caption-description-textarea-readonly"
              v-if="dataQuestion.questionType === 'open'"
            >
              {{ $t('components.tests.testConstructorWrapper.enterAnswer') }}
            </p>

            <TestSeparateAnswer
              v-else
              v-for="(item, index) in dataQuestion.answers"
              :key="item.id"
              :answer="item"
              :questionType="dataQuestion.questionType"
              :answerIndex="index"
              :questionIndex="count"
            ></TestSeparateAnswer>
          </div>
          <div class="test__wrapper-content-edd">
            <button
              class="test__wrapper-content-edd-btn"
              v-if="
                dataQuestion.questionType !== 'open' &&
                dataQuestion.questionType !== null
              "
              @click="$store.commit('addAnswer', count)"
            >
              {{ $t('components.tests.testConstructorWrapper.addAnswerOption') }}
            </button>
          </div>
        </div>
      </div>
      <div class="test__change">
        <button class="test__change-more">
          <img
            src="~@/assets/img/test-img/constractor/more.svg"
            alt=""
            class="test__change-more-img"
          />
        </button>
        <div class="test__change-options desctop-option">
          <button
            class="test__change-options-element"
            v-if="dataQuestion.photoPath === null"
            @click="$refs.photo.click()"
          >
            <img
              src="~@/assets/img/test-img/constractor/img.svg"
              alt=""
              class="test__change-options-element-photo"
            />
          </button>

          <button
            class="test__change-options-element"
            v-if="dataQuestion.videoPath === null"
            @click="$refs.video.click()"
          >
            <img
              src="~@/assets/img/test-img/constractor/video.svg"
              alt=""
              class="test__change-options-element-video"
            />
          </button>
        </div>
        <div class="test__change-options">
          <button
            v-if="!dataQuestion.id || dataQuestion.id === undefined"
            class="test__change-options-element"
            @click="$store.commit('deleteQuestion', count)"
          >
            <img
              src="~@/assets/img/test-img/constractor/delete.svg"
              alt=""
              class="test__change-options-element-delete"
            />
          </button>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="photo"
      v-on:change="addFile('photo')"
      style="display: none"
      accept=".jpg , .jpeg, .png , .gif, .webp"
    />
    <input
      type="file"
      ref="video"
      v-on:change="addFile('video')"
      style="display: none"
      accept=".mp4, .mov, .avi ,.3gp"
    />
  </div>
</template>

<script>
import TestSeparateAnswer from "../../test_contructor_wrapper_elements/item/TestSeparateAnswer.vue";
import { errorHandler } from "../../../../lib/errorHandler";

export default {
  components: { TestSeparateAnswer },
  props: {
    question: {},
    count: {},
  },
  data() {
    return {
      dataQuestion: null,
      selectVisible: false,
    };
  },
  name: "TestConstructorWrapper",
  beforeMount() {
    this.dataQuestion = this.question;
  },
  methods: {
    addFile(typeFile) {
      if (typeFile === "photo") {
        this.$store.commit("saveQuestionPhoto", {
          index: this.count,
          data: URL.createObjectURL(
            this.$refs.photo.files[this.$refs.photo.files.length - 1]
          ),
        });
        this.$store.commit("addFileData", {
          index: this.count,
          data: this.$refs.photo.files[this.$refs.photo.files.length - 1],
          type: typeFile,
        });
      } else {
        if (
          this.$refs.video.files[this.$refs.video.files.length - 1].size <
          39000000
        ) {
          this.$store.commit("saveQuestionVideo", {
            index: this.count,
            data: URL.createObjectURL(
              this.$refs.video.files[this.$refs.video.files.length - 1]
            ),
          });
          this.$store.commit("addFileData", {
            index: this.count,
            data: this.$refs.video.files[this.$refs.video.files.length - 1],
            type: typeFile,
          });
        } else errorHandler({ status: 400, page: "fileSize" });
      }
    },
    removeFile(typeFile) {
      this.$store.commit("removeFile", { data: typeFile, index: this.count });
    },
  },
};
</script>

<style scoped>
/* Caption */

.test-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 64px 0 62px 0px;
}

.test__wrapper-content-caption {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.test__wrapper-content-caption-name-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-name-textarea {
  width: 100%;
  box-sizing: border-box;
  min-height: 155px;
  margin-top: 10px;
  padding: 13px 31px 25px 25px;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.test__wrapper-content-caption-description {
  padding-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content-caption-description-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 1.28;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-description-textarea {
  min-height: 87px;
  box-sizing: border-box;
  padding: 15px 31px 20px 23px;
  width: 100%;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
}

.test__wrapper-content-caption-description-textarea-readonly {
  min-height: 87px;
  box-sizing: border-box;
  padding: 15px 31px 20px 23px;
  width: 100%;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
}

@media (max-width: 950px) {
  .test-container {
    padding: 18px 0 30px 0;
  }

  .test__wrapper-content-caption {
    border-bottom: none;
  }

  .test__wrapper-content-caption-name-title {
    font-size: 12px;
  }

  .test__wrapper-content-caption-name-textarea {
    font-size: 11px;
    padding: 11px 16px 14px 16px;
    line-height: 1.72;
    min-height: 117px;
  }

  .test__wrapper-content-caption-description {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-description-title {
    font-size: 11px;
  }

  .test__wrapper-content-caption-description-textarea {
    padding: 11px 16px 16px 16px;
    font-size: 13px;
    line-height: 1.75;
  }

  .test__wrapper-content-caption-description-textarea-readonly {
    padding: 11px 16px 16px 16px;
    font-size: 13px;
    line-height: 1.75;
  }
}

/* /Caption */

/* Filling and add */
.test__wrapper-content-filling {
  padding-top: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.test__wrapper-content-edd {
  padding-top: 25px;
  max-width: 273px;
}

.test__wrapper-content-edd-btn {
  width: 100%;
  text-align: center;
  padding: 23px 0;
  font-family: "Roboto";
  background: #e6f6fc;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #009ed1;
  transition: opacity 1s ease;
}

.test__wrapper-content-edd-btn:hover {
  opacity: 0.5;
}
@media (max-width: 950px) {
  .test__wrapper-content-filling-select {
    padding: 11px 15px;
    margin: 0;
    font-size: 12px;
  }

  .test__wrapper-content-filling {
    border-bottom: none;
  }

  .test__wrapper-content-edd {
    max-width: 200px;
    padding-top: 0;
  }

  .test__wrapper-content-edd-btn {
    padding: 13px 0;
    font-size: 13px;
  }
}

@media (max-width: 360px) {
  .test__wrapper-content-filling-select {
    width: 100%;
  }
}

/* /Filling and add */
/* Wrapper */
.test {
  display: flex;
  max-width: 1518px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.test__number-element {
  padding: 17px 24px;
  background: #f0f4fa;
  border-radius: 9px;
  color: #a0a9b7;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 21px;
  line-height: 1.28;
}

.test__wrapper {
  margin: 0 80px;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content {
  padding: 46px 63px 59px 62px;
}

@media (max-width: 1740px) {
  .test__wrapper {
    margin: 0 40px;
  }
}

@media (max-width: 950px) {
  .test__wrapper {
    margin: 22px 0 0 0;
  }

  .test {
    flex-wrap: wrap;
  }

  .test__number {
    padding-top: 6px;
    order: 1;
    max-width: 76px;
  }

  .test__number-element {
    width: 100%;
    padding: 0;
    background: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .test__change {
    margin-left: auto;
    order: 2;
    display: flex;
  }

  .test__wrapper {
    border: none;
    order: 3;
  }

  .test__wrapper-content {
    padding: 0;
  }

  .test__wrapper-content {
    background: #fafafb;
  }
}

/* /Wrapper */

/* Change */

.test__change-more {
  margin-bottom: 25px;
  transition: opacity 1s ease;
}

.test__change-more:hover {
  opacity: 0.5;
}

.test__change-more-img {
  padding: 26px 15px 25px 15px;
  background: #e6f6fc;
  border: 1px solid #009ed1;
  border-radius: 9px;
}

.test__change-options {
  padding: 20px 0;
  display: flex;
  border-top: 1px solid #dde0e6;
  flex-direction: column;
}

.test__change-options-element:not(:last-child) {
  margin-bottom: 14px;
}

.test__change-options-element-video {
  padding: 18px 18px 18px 18px;
  background: #e6f6fc;
  border-radius: 9px;
  transition: opacity 1s ease;
}

.test__change-options-element-video:hover {
  opacity: 0.5;
}

.test__change-options-element-photo {
  padding: 19px 18px 19px 18px;
  background: #e6f6fc;
  border-radius: 9px;
  transition: opacity 1s ease;
}

.test__change-options-element-photo:hover {
  opacity: 0.5;
}

.test__change-options-element-view {
  padding: 19px 17px 20px 18px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-view:hover {
  opacity: 0.5;
}

.test__change-options-element-delete {
  padding: 16px 18px 17px 19px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-delete:hover {
  opacity: 0.5;
}

.test__change-options-element-up {
  padding: 26px 23px 25px 22px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-up:hover {
  opacity: 0.5;
}

.test__change-options-element-down {
  padding: 26px 23px 25px 22px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-down:hover {
  opacity: 0.5;
}

@media (max-width: 950px) {
  .test__change-more {
    margin-left: 9px;
    margin-bottom: 0;
    order: 3;
  }

  .test__change-more-img {
    width: 12px;
    height: 3px;
    padding: 12px 7px 11px 7px;
    background: #e6f6fc;
    border: 1px solid #009ed1;
    border-radius: 9px;
  }

  .test__change-options {
    padding: 0px 9px;
    display: flex;
    border-top: none;
    border-right: 1px solid #dde0e6;
    flex-direction: row;
  }

  .test__change-options-element:not(:last-child) {
    margin-bottom: 0;
    margin-right: 9px;
  }

  .test__change-options-element-view {
    width: 12px;
    height: 10px;
    padding: 9px 8px 9px 8px;
  }

  .test__change-options-element-delete {
    width: 14px;
    height: 14px;
    padding: 7px;
  }

  .test__change-options-element-up {
    width: 8px;
    height: 11px;
    padding: 8px 10px 9px 10px;
  }

  .test__change-options-element-down {
    width: 8px;
    height: 11px;
    padding: 8px 10px 9px 10px;
  }

  .desctop-option {
    display: none;
  }
}

/* /Change */

/* Visual */
.test__wrapper-content-caption-photo {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
}

.test__wrapper-content-caption-video {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
}

.test__wrapper-content-caption-visual-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-photo-container {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 300px;
}

.test__wrapper-content-caption-video-container {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 300px;
}

.test__wrapper-content-caption-visual-delete {
  z-index: 42;
  top: 17px;
  right: 23px;
  border-radius: 100%;
  position: absolute;
  padding: 13px 15px 13px 15px;
  background: #febdbd;
  border-radius: 24px;
  transition: opacity 1s ease;
}

.test__wrapper-content-caption-visual-delete:hover {
  opacity: 0.5;
}

.test__wrapper-content-caption-photo-visual-delete-img {
  width: 17px;
  height: 17px;
}

.test__wrapper-content-caption-visual-add {
  z-index: 42;
  top: 74px;
  right: 23px;
  border-radius: 100%;
  position: absolute;
  padding: 13px 15px 12px 15px;
  background: #99d8ed;
  border-radius: 24px;
  transition: opacity 1s ease;
}

.test__wrapper-content-caption-visual-add:hover {
  opacity: 0.5;
}

.test__wrapper-content-caption-visual-add-img {
  width: 16px;
  height: 18px;
}

.test__wrapper-content-caption-visual-photo {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  background: #000;
}

.test__wrapper-content-caption-visual-video {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
  border-radius: 16px;
  background: #000;
}

@media (max-width: 950px) {
  .test__wrapper-content-caption-photo {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-video {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-visual-title {
    font-size: 11px;
  }

  .test__wrapper-content-caption-photo-container {
    margin-top: 15px;
    max-height: 160px;
  }

  .test__wrapper-content-caption-video-container {
    margin-top: 15px;
    max-height: 160px;
  }

  .test__wrapper-content-caption-visual-delete {
    top: 11px;
    right: 13px;
    padding: 9px 12px 8px 12px;
  }

  .test__wrapper-content-caption-photo-visual-delete-img {
    width: 11px;
    height: 14px;
  }

  .test__wrapper-content-caption-visual-add {
    top: 54px;
    right: 13px;
    padding: 9px 12px 8px 12px;
  }

  .test__wrapper-content-caption-visual-add-img {
    width: 12px;
    height: 13px;
  }

  .test__wrapper-content-caption-visual-photo {
    height: 160px;
  }

  .test__wrapper-content-caption-visual-video {
    height: 160px;
  }
}

/* /Visual */

/* select */
.test__wrapper-content-filling-select-container {
  width: 300px;
  position: relative;
  box-sizing: border-box;
  display: block;
  align-items: center;
  border-radius: 13px;
}

.test__wrapper-content-filling-select {
  padding: 14px 25px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #009ed1;
  border-radius: 13px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: color 1s ease;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
}
.test__wrapper-content-filling-select:hover {
  color: #19191d;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.test__wrapper-content-filling-select-group {
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: 13px;
  z-index: 20;
}
.test__wrapper-content-filling-select-element-input {
  -webkit-appearance: none;
  height: 0px;
}
.test__wrapper-content-filling-select-element-label {
  cursor: pointer;
  padding: 7px 25px;
  width: 100%;
  border: 1px solid #009ed1;
  border-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
}
.test__wrapper-content-filling-select-element-label:hover {
  background-color: #eefbff;
  color: #009ed1;
}
.test__wrapper-content-filling-select-element-label:last-child {
  border-radius: 0px 0px 13px 13px;
}

.first-label {
  border-radius: 13px 13px 0 0;
}
@media (max-width: 950px) {
  .test__wrapper-content-filling-select {
    padding: 14px 16px 14px 16px;
    font-size: 13px;
  }
  .test__wrapper-content-filling-select-element-label {
    font-size: 13px;
    padding-left: 16px;
  }
}

/* /select */
</style>
