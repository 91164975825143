<template>
	<div class="friend-wrapper">
	  <div class="friend__face">
		<img src="~@/assets/img/account-img/friends/face.png" alt="" class="friend__face-img" />
		<div class="friend__face-status friend__face-status-active"></div>
	  </div>
	  <div class="friend__info">
		<h3 class="friend__info-name">
		  Apple Incorporation <span class="friend__info-rating">100</span>
		</h3>
		<p class="friend__info-speciality">{{ $t('components.social.possibleFriends.mutualFriends') }}</p>
	  </div>
	  <button class="friend__add"></button>
	</div>
  </template>
  
  <script>
  export default {
	name: "PossibleFriends",
  };
  </script>
  

<style scoped>
.friend-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	background: #ffffff;
	padding: 10px 10px;
	align-items: center;
	border-bottom: 1px solid #efeff4;
}

.friend__face {
	position: relative;
}

.friend__face-img {
	width: 40px;
	height: 40px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
}

.friend__face-status {
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 100%;
}

.friend__face-status.friend__face-status-active {
	background: #14c686;
	border: 2px solid #fff;
}

.friend__info {
	margin-left: 19px;
}

.friend__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.friend__info-rating {
	margin-left: 10px;
	padding: 4px 11px 4px 25px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
	background-repeat: no-repeat;
	background-position-x: 9px;
	background-position-y: 6px;
	color: #14c686;
}

.friend__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.friend__add {
	width: 24px;
	height: 24px;
	margin: auto 0 auto auto;
	display: flex;
	background-image: url("~@/assets/img/account-img/friends/add-friend.png");
	background-size: cover;
	cursor: pointer;
}

@media (max-width: 720px) {
	.friend-wrapper {
		padding: 10px 0;
	}

	.friend__info-rating {
		padding: 3px 6px 3px 18px;
		font-size: 10px;
		background-size: 9px 8px;
		background-position-x: 7px;
		background-position-y: 5px;
	}

	.friend__info {
		margin-left: 10px;
	}
}
</style>
