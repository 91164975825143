<template>
  <button @click="$router.push({ name: 'employerProfile', params: { summaryId: vacancy.id } })" class="vacancy">
    <div class="vacancy-caption">
      <img :src="vacancy.companyAvatarUrl ? vacancy.companyAvatarUrl : vacancy.avatarUrl" alt=" "
        class="vacancy-caption__img">
      <div class="vacancy-description green">
        <p class="statistics__name">{{ $t('components.account_worker.detailedCompany.company') }}</p>
        <h4 class="vacancy-description__title">{{ vacancy.vacancyName }}</h4>
      </div>
      <div class="vacancy-description vacancy-rating green">
        <p class="statistics__name">{{ $t('components.account_worker.detailedCompany.rating') }}</p>
        <p class="statistics__number">
          <svg class="statistics__number-icon" width="11" height="10" viewBox="0 0 11 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
              fill="#0000" />
          </svg>
          {{ vacancy.vacancyRate }}
        </p>
      </div>
    </div>
  </button>
</template>

<script>

import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
  name: "DetailedCompany",
  props: ['vacancy', 'workerRate'],
  data() {
    return {
      textSymbolLimit
    }
  },
  methods: {
    goToProfile(e) {
      e.stopPropagation();
      this.$router.push({ name: 'employerProfile', params: { summaryId: this.vacancy.employerId } })
    }
  }
};
</script>


<style scoped>
.vacancy {
  max-width: 100%;
  width: 100%;
  padding: 10px 20px 10px 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  height: 75px;
  box-sizing: border-box;
}

.vacancy-caption {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.vacancy-caption__img {
  width: 53px;
  height: 53px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  object-fit: cover;
}

.caption-statistics {
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-width: 100%;
  width: 100%;
}

.statistics-wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.statistics__text {
  font-family: "SFUIDisplay";
  font-size: 12px;
  line-height: 1.5;
  color: rgba(23, 23, 37, 0.5);
}

.statistics-line {
  margin-left: 6px;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 4px;
  border-radius: 200px;
}

.your-line {
  margin-left: 20px;
}

.statistics-line__filling {
  max-width: 100%;
  height: 100%;
  border-radius: 200px;

}

.statistics__number {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  flex-shrink: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  margin-top: 6px;
  line-height: 1;
  letter-spacing: 0.1px;
  width: 49px;
  justify-content: center;
  height: 20px;
  background-color: rgba(20, 198, 134, 0.20000000298023224);
  border-radius: 20px;
}

.statistics__number-icon {
  margin-right: 6px;
}


.vacancy-description {
  text-align: left;
  margin-left: 20px;
  margin-right: 10px;
}

.vacancy-rating {
  margin-left: auto;
  margin-right: 0px;
}

.vacancy-description__title {
  font-family: "Roboto";
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  text-align: left;
  color: #000000;
}

.vacancy-description__text {
  text-align: left;

  font-weight: 500;
  font-family: "Roboto";
  font-size: 10px;
  line-height: 1.4;
  color: rgba(23, 23, 37, 0.4000000059604645);
}

.vacancy-about {
  max-width: 100%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.vacancy-about__element {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vacancy-about__text {
  margin-top: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  color: rgba(23, 23, 37, 0.30000001192092896);
}

/* Modificators */
.yellow .statistics-line {
  background: rgba(220, 149, 11, 0.20000000298023224);
}

.yellow .statistics__number {
  background-color: rgba(220, 149, 11, 0.20000000298023224);
}

.yellow .statistics-line__filling {
  background: #db940a;
}

.yellow path {
  fill: #db940a;
}

.yellow .statistics__number {
  color: #db940a;
}

.green .statistics-line {
  background: rgba(20, 198, 134, 0.20000000298023224);
}

.green .statistics-line__filling {
  background: #14c686;
}

.green path {
  fill: #14c686;
}

.green .statistics__number {
  color: #14c686;
}

.green .statistics__number {
  background: rgba(20, 198, 134, 0.20000000298023224);
}

.red .statistics-line {
  background: rgba(220, 11, 11, 0.20000000298023224);
}

.red .statistics-line__filling {
  background: #db0a0a;
}

.red path {
  fill: #db0a0a;
}

.red .statistics__number {
  color: #db0a0a;
}

.red .statistics__number {
  background: rgba(220, 11, 11, 0.20000000298023224);
}
</style>
