<template>
  <router-link :to="{
    name: 'responsePage',
    params: {
      responseId: this.vacancy.id,
    },
  }" class="responses__wrapper-element" v-if="checkCategory()">
    <div class="responses__wrapper-element-caption">
      <div class="responses__wrapper-element-caption-company">
        <img :src="vacancy.companyAvatarUrl" alt="" class="responses__wrapper-element-caption-company-logo-img" />
        <div class="responses__wrapper-element-caption-company-info">
          <div class="responses__wrapper-element-caption-company-info-rating">
            <p class="responses__wrapper-element-caption-company-info-rating-number">
              {{ vacancy.companyRate ? vacancy.companyRate : 0 }}
            </p>
          </div>
          <p class="responses__wrapper-element-caption-company-info-name">
            {{ vacancy.companyName ? limit(vacancy.companyName, 23) : $t('components.account_worker.separateRecomendationSameWork.untitled')
            }}
          </p>
        </div>
      </div>
      <div class="responses__wrapper-element-caption-company-status">
        <p class="responses__wrapper-element-caption-company-status-text" :class="{
          'responses__wrapper-element-caption-company-status-text':
            (this.status === $t('components.account_worker.separateRecomendationSameWork.underConsideration') || this.status === $t('components.account_worker.separateRecomendationSameWork.waitingResults')),
          'status-text-refusal': this.status === $t('components.account_worker.separateRecomendationSameWork.refusal'),
          'status-text-application': (this.status === $t('components.account_worker.separateRecomendationSameWork.invitation') || this.status === $t('components.account_worker.separateRecomendationSameWork.verified')),
        }">
          {{ status ? status : $t('components.account_worker.separateRecomendationSameWork.unknownStatus') }}
        </p>
        <p class="responses__wrapper-element-caption-company-status-date">
          {{
            vacancy.responseDate
              ? new Date(vacancy.responseDate).toISOString().slice(0, 10)
              : "undefined"
          }}
        </p>
      </div>
    </div>
    <div class="responses__wrapper-element-description">
      <h2 class="responses__wrapper-element-description-title">
        {{ vacancy.vacancyName ? limit(vacancy.vacancyName, 23) : $t('components.account_worker.separateRecomendationSameWork.untitled') }}
      </h2>
      <p class="responses__wrapper-element-description-text">
        {{
          vacancy.vacancyDescription
            ? limit(vacancy.vacancyDescription, 60)
            : $t('components.account_worker.separateRecomendationSameWork.noDescription')
        }}...
      </p>
      <p class="responses__wrapper-element-description-place">
        {{ vacancy.vacancyCity ? limit(vacancy.vacancyCity, 23) : "undefined" }}
      </p>
    </div>
    <p class="responses__wrapper-element-price">
      {{
        vacancy.vacancyPrice ? vacancy.vacancyPrice + "$" : $t('components.account_worker.separateRecomendationSameWork.byAgreement')
      }}
    </p>
    <div class="responses__wrapper-element-detailed"></div>
  </router-link>
</template>

<script>
import { textSymbolLimit } from "../../../../../lib/textSymbolLimit";

export default {
  beforeMount() {
    switch (this.vacancy.responseStatus) {
      case "new":
        this.status = this.$t('components.account_worker.separateRecomendationSameWork.underConsideration');
        break;
      case "reject":
        this.status = this.$t('components.account_worker.separateRecomendationSameWork.refusal');
        break;
      case "invite":
        this.status = this.$t('components.account_worker.separateRecomendationSameWork.invitation');
        break;
      case "passed":
        this.status = this.$t('components.account_worker.separateRecomendationSameWork.waitingResults');
        break;
      case "checked":
        this.status = this.$t('components.account_worker.separateRecomendationSameWork.verified');
        break;
    }
  },
  name: "Vacancy",
  props: {
    vacancy: {
      type: Object,
    },
    category: {
      type: String,
    },
  },
  methods: {
    checkCategory() {
      return this.status === this.category || this.category === "All responses";
    },
  },
  data() {
    return {
      limit: textSymbolLimit,
      DESCRIPTION_SYMBOL_LIMIT: 60,
      status: null,
    };
  },
};
</script>


<style scoped>
.similar {
  position: relative;
  width: 100%;
  max-width: 367px;
  margin-top: 20px;
}

.similar__vacncy-element {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.similar__vacncy-element-nomiation {
  margin: 20px 20px 0 20px;
  max-width: 327px;
  display: flex;
  align-items: center;
}

.recomedation-img {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;

}


.similar__vacncy-element-nomiation-name {
  margin-left: 19px;
}


.similar__vacncy-element-nomiation-name-company {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  margin-top: 3px;
}

.similar__vacncy-element-description {
  padding: 17px 20px 16px 20px;
  max-width: 327px;
  border-bottom: 1px solid #f1f1f5;
}

.similar__vacncy-element-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #171625;
}

.similar__vacncy-element-description-text {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.similar__vacncy-element-description-place {
  margin-top: 10px;
  padding-left: 18px;
  background-image: url("~@/assets/img/recommendation-page/recommendation/place.svg");
  background-position-y: 3px;
  background-repeat: no-repeat;
  align-items: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.similar__vacncy-element-price {
  margin: 14px 20px 0 20px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.similar__vacncy-element-btn {
  margin: 20px;
  width: 327px;
  height: 50px;
  background: #f1f1f5;
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #696974;
  transition: opacity 1s ease;
}

.similar__vacncy-element-btn:hover {
  opacity: 0.5;
}


.similar__vacncy-element-nomiation-name-rating-number {
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-repeat: no-repeat;
  background-position-y: 5px;
  padding: 3px 9px 3px 9px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  border-radius: 20px;
  display: inline;
  line-height: 1;
}

.similar-rating {
  margin-top: 19px;
  display: flex;
  align-items: center;
}

.similar-rating__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.similar-rating__text span {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.caption__nomination-name-company-rating-tally {
  background-image: url("~@/assets/img/company-page/caption/star.svg");
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-size: 11px;
  border-radius: 20px;
  margin-left: 4px;
  background-repeat: no-repeat;
  background-position: 9px;
  background-position-y: 5px;
  padding: 4px 9px 4px 25px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
}

.mobile {
  display: none;
}

@media (max-width: 1470px) {
  .similar {
    width: 300px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }
}

@media (max-width: 1070px) {
  .similar {
    width: 400px;
  }

  .similar__vacncy-element-btn {
    width: 360px;
  }

}

@media (max-width: 960px) {
  .similar {
    width: 367px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }

  .similar__btn {
    width: 367px;
    margin: 36px auto 57px auto;
  }

  .similar__vacncy-element-btn {
    width: 327px;
  }

}

@media (max-width: 786px) {
  .similar {
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 640px) {
  .similar {
    width: 100%;
    max-width: 300px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }
}

@media (max-width: 400px) {
  .mobile {
    display: block;
  }

  .similar__vacncy-element {
    width: 300px;
  }

  .similar__vacncy-element-nomiation {
    margin: 0;
    padding: 20px 20px 18px 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  .similar__vacncy-element-nomiation-icon-img {
    padding: 14px 17px;
  }

  .similar__vacncy-element-nomiation-icon-img {
    width: 30px;
    height: 36px;
  }

  .similar__vacncy-element-nomiation-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .similar__vacncy-element-nomiation-name-sign {
    order: 1;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #696974;
  }

  .similar__vacncy-element-nomiation-name-company {
    order: 2;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #171625;
  }

  .similar__vacncy-element-nomiation-name-rating {
    margin-top: 4px;
    order: 3;
  }

  .similar__vacncy-element-nomiation-name-rating-name {
    font-size: 13px;
    line-height: 24px;
  }

  .similar__vacncy-element-description-title {
    font-size: 15px;
  }

  .similar__vacncy-element-description-text {
    font-size: 13px;
  }

  .similar__vacncy-element-price {
    margin: 14px 20px 20px 20px;
  }

  .similar__vacncy-element-btn {
    display: none;
  }


}
</style>
