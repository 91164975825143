<template>
  <TestSelect v-if="questionType === 'confrontation'" :question="question"></TestSelect>

  <div v-else-if="countTrueAnswer === 1 && questionType === 'other'" class="radioBtn">
    <label class="selection" v-for="item in question.answers" :key="item.id"
      :class="{ 'selct-label': item.id === choose }">
      {{ item.body }}
      <input type="radio" v-model="pickAnswer" :value="item.id" class="selection__input" v-on:change="pickChoose" />
      <span class="selection__checkbox" :style="{ borderRadius: '' + 100 + '' + '%' }"
        :class="{ 'selct-checkbox': item.id === choose }"></span>
    </label>
  </div>

  <div v-else-if="countTrueAnswer > 1 && questionType === 'other'" class="checkbox">
    <label class="selection" v-for="item in question.answers" :key="item.id"
      :class="{ 'selct-label': checkCheckbox(item.id) }">
      {{ item.body }}
      <input type="checkbox" v-model="pickAnswer" :value="item.id" class="selection__input" v-on:change="pickChoose" />
      <span class="selection__checkbox" :class="{ 'selct-checkbox': checkCheckbox(item.id) }"></span>
    </label>
  </div>
</template>

<script>
import TestSelect from "@/components/tests/test_wrapper_elements/items/TestSelect";

export default {
  components: {
    TestSelect,
  },
  props: {
    question: {},
    countTrueAnswer: {},
    choose: {},
  },
  data() {
    return {
      pickAnswer: [],
      questionType: null,
    };
  },
  beforeMount() {
    const confrontation =
      this.question.answers.map((item) => item.comparisonBody).indexOf(null) ===
      -1;

    if (confrontation) {
      this.questionType = "confrontation";
      return;
    }

    this.questionType = "other";
  },
  methods: {
    checkCheckbox(id) {
      if (this.choose) return this.choose.indexOf(id) !== -1;
    },
    pickChoose() {
      if (this.pickAnswer !== null)
        this.$store.commit("setChoose", this.pickAnswer);
      else this.$store.commit("setChoose", null);
    },
  },
  name: "TestInputCircle",
};
</script>


<style scoped>
.selection {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f5f8f9;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  padding: 20px 40px 20px 90px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
  color: #3b4c68;
  transition: background 1s ease, border 1s ease;
  line-height: 1.67;
}

.selection:hover {
  background: #eef8ff;
  border: 1px solid #009ed1;
}

.selection:not(:last-child) {
  margin-bottom: 18px;
}

.selection__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.selection__checkbox {
  position: absolute;
  border: 2px solid rgba(59, 76, 104, 0.5);
  border-radius: 6px;
  top: calc(50% - 12px);
  left: 33px;
  height: 21px;
  width: 21px;
  background-color: #fff;
}

.selection__input~.selection__checkbox {
  transition: background 1s ease, border 1s ease;
}

.selection__input:hover~.selection__checkbox {
  background: #009ed1;
  border: 2px solid #009ed1;
}

.selection__checkbox::before {
  content: "";
  position: absolute;
  display: none;
}

.selection__input:checked~.selection__checkbox::before {
  display: block;
}

@media (max-width: 700px) {
  .selection:not(:last-child) {
    margin-bottom: 12px;
  }

  .selection {
    padding: 10px 18px 10px 43px;
    font-size: 11px;
  }

  .selection__checkbox {
    left: 14px;
    width: 12px;
    height: 12px;
    top: calc(50% - 8px);
  }
}

.selct-checkbox {
  background: #009ed1;
  border: 2px solid #009ed1;
}

.selct-label {
  background: #eef9ff;
  border: 1px solid #009ed1;
}
</style>
