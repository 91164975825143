<template>
	<div class="wrapper">
		<emoji-picker @emoji="append">
			<div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
				@click.stop="clickEvent">
				<button><img src="~@/assets/img/account-img/chat/smile.png" alt=""></button>
			</div>
			<div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
				<div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
					<div>
						<div v-for="(emojiGroup, category) in emojis" :key="category">
							<h5>{{ category }}</h5>
							<div class="emojis">
								<span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
									:title="emojiName">{{ emoji }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</emoji-picker>
	</div>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'

export default {
	components: { EmojiPicker },
	name: "ChatsEmoji",
  props:['message'],
	methods: {
		append(emoji) {
      this.$emit('emojiAdd',emoji);
		},
	},
}
</script>

<style scoped>
.wrapper {
	position: relative;
	display: inline-block;
}

.regular-input {
	padding: 0.5rem 1rem;
	border-radius: 3px;
	border: 1px solid #ccc;
	width: 20rem;
	height: 12rem;
	outline: none;
}

.regular-input:focus {
	box-shadow: 0 0 0 3px rgba(66, 153, 225, .5);
}

.emoji-invoker {
	width: 20px;
	height: 20px;
	transition: opacity 1s ease;
}

.emoji-invoker img {
	width: 100%;
	box-sizing: border-box;
}

.emoji-invoker:hover {
	opacity: 0.5;
}

.emoji-invoker>svg {
	fill: #b1c6d0;
}

.emoji-picker {
	position: absolute;
	z-index: 20;
	font-family: "Montserrat", sans-serif;
	border: 1px solid #ccc;
	width: 15rem;
	height: 20rem;
	overflow: scroll;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 0.5rem;
	background: #fff;
	box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker__search {
	display: flex;
}

.emoji-picker__search>input {
	flex: 1;
	border-radius: 10rem;
	border: 1px solid #ccc;
	padding: 0.5rem 1rem;
	outline: none;
}

.emoji-picker h5 {
	margin-bottom: 0;
	color: #b1b1b1;
	text-transform: uppercase;
	font-size: 0.8rem;
	cursor: default;
}

.emoji-picker .emojis {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.emoji-picker .emojis:after {
	content: "";
	flex: auto;
}

.emoji-picker .emojis span {
	padding: 0.2rem;
	cursor: pointer;
	border-radius: 5px;
}

.emoji-picker .emojis span:hover {
	background: #ececec;
	cursor: pointer;
}

.emoji-picker {
	top: -330px !important;
	left: -220px !important;
}
</style>