<template>
  <div class="post-big" @click="handleBackgroundClick">
    <div class="post-big-filling">
      <button @click="$emit('closeWindow')" class="close">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z" stroke="#3B4C68"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>{{ $t('components.account_worker.bigPost.close') }}</span>
      </button>
      <SeparatePost @sharePost="setSharedPost" @viewBigPhoto="val => $emit('viewBigPhoto', val)" :post="post"
        :avatar-url="metaData.avatarUrl" :name="metaData.title" :community-id="metaData.communityId">
      </SeparatePost>
    </div>
    <SharePost @closeWindow="sharingPost = null" :meta-data="metaData" :sharing-post="sharingPost" v-if="sharingPost">
    </SharePost>
  </div>
</template>

<script>
import SeparatePost from "@/components/social/account_communitis_main/SeparatePost.vue"
import SharePost from "@/components/account/account_worker/modals/item/SharePost.vue"
import { errorHandler } from "@/lib/errorHandler";

export default {
  name: "BigPost",
  components: {
    SeparatePost,
    SharePost
  },
  props: ['post', 'metaData', 'cantShare'],
  data() {
    return {
      sharingPost: null
    }
  },
  methods: {
    handleBackgroundClick(event) {
      if (event.target === event.currentTarget) {
        this.$emit('closeWindow');
      }
    },
    setSharedPost(val) {
      if (this.cantShare) {
        errorHandler({ page: 'sharedPost', status: 500 });
        return;
      }
      this.sharingPost = val;
    }
  }
}
</script>


<style scoped>
* {
  box-sizing: border-box;
}

.post-big {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-width: 100%;
  padding: 40px;
  overflow: auto;
  scrollbar-width: thin;
}

.post-big-filling {
  position: relative;
  max-width: 900px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.close {
  position: absolute;
  right: -30px;
  top: 0;
  width: 20px;
  height: 20px;
}

.close svg {
  width: 100%;
  height: 100%;
}

.close path {
  stroke: #fff;
}

.community-block {
  padding: 0;
  border: none;
  margin-bottom: 0;
  box-shadow: none;
}

@media (max-width: 767px) {
  .post-big {
    padding: 20px 10px;
  }

  .post-big-filling {
    padding: 20px 12px;
  }

  .close {
    width: 16px;
    height: 16px;
    position: relative;
    margin-left: auto;
    right: unset;
    top: unset;
    margin-right: 4px;
  }

  .close path {
    stroke: #000;
  }

  .community-block {
    margin-top: 10px;
  }

}
</style>