<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>

				<div class="container-account">
					<p class="responses__text mobile">{{
						$t('pages.account.account_worker.accountWorkerResponsesPage.personalCabinet') }}</p>
					<h1 class="responses__title">{{
						$t('pages.account.account_worker.accountWorkerResponsesPage.myApplications') }}</h1>
					<p class="responses__text desctop">{{
						$t('pages.account.account_worker.accountWorkerResponsesPage.welcome') }}</p>
					<nav class="responses__nav">
						<button class="responses__nav-btn" :class="{ 'active-blue': this.category === 'All responses' }"
							@click="chooseCategory('All responses')">
							{{ $t('pages.account.account_worker.accountWorkerResponsesPage.allResponses') }}
						</button>
						<button class="responses__nav-btn"
							:class="{ 'active-blue': this.category === 'Under consideration' }"
							@click="chooseCategory('Under consideration')">
							{{ $t('pages.account.account_worker.accountWorkerResponsesPage.underConsideration') }}
						</button>
						<button class="responses__nav-btn" :class="{ 'active-blue': this.category === 'Invitation' }"
							@click="chooseCategory('Invitation')">
							{{ $t('pages.account.account_worker.accountWorkerResponsesPage.invitation') }}
						</button>
						<button :class="{ 'active-blue': this.category === 'Refusal' }" class="responses__nav-btn"
							@click="chooseCategory('Refusal')">
							{{ $t('pages.account.account_worker.accountWorkerResponsesPage.refusal') }}
						</button>
					</nav>
					<div class="responses__wrapper">
						<Response v-for="item in dataVacancy" :key="item.id" :vacancy="item" :category="category">
						</Response>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import Response from "../../../components/account/account_worker/account_worker_responses/item/Response.vue";
import { config } from "@/lib/config";

export default {
	components: { MenuWorker, HeaderAccountWorker, Response },
	data() {
		return {
			dataVacancy: [],
			category: "",
		};
	},
	methods: {
		activeBlueBtn(category) {
			return this.category === category;
		},

		chooseCategory(category) {
			this.category = category;
			return true;
		},
		fetchResponses() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/response/all";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataVacancy = response.data;
			} catch (e) {
				console.log(e);
			}
		},
	},
	name: "AccountWorkerResponsesPage",
	beforeMount() {
		this.fetchResponses();
		scroll(0, 0);
		this.category = "All responses";
	},
	created() { },
};
</script>
<!-- TODO -->


<style scoped>
.account {
	display: flex;
}

.mobile {
	display: none;
}

.responses__text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.responses__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.responses__nav {
	margin-top: 34px;
	display: flex;
	overflow-x: hidden;
}

.responses__nav-btn {
	flex-shrink: 0;
	margin-right: 14px;
	width: 167px;
	height: 48px;
	border: 1px solid #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #009ed1;
	transition: color 1s ease, border 1s ease, background 1s ease;
}

.active-blue {
	background: #e6f6fc;
	border: 1px solid #e6f6fc;
	color: #009ed1;
}

.responses__nav-btn:hover {
	border: 1px solid #e6f6fc;
	background: #e6f6fc;
	color: #009ed1;
}

.responses__wrapper {
	margin-top: 38px;
	display: grid;
	row-gap: 33px;
	grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1860px) {
	.responses__text {
		text-align: center;
	}

	.responses__title {
		text-align: center;
	}

	.responses__nav {
		justify-content: center;
	}

	.responses__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 1370px) {
	.responses__nav {
		overflow-x: hidden;
	}
}

@media (max-width: 680px) {
	.responses__nav {
		justify-content: left;
	}
}

@media (max-width: 980px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}
}

@media (max-width: 850px) {
	.responses__text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.responses__title {
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.responses__nav {
		margin-top: 10px;
	}

	.responses__nav-btn {
		font-size: 11px;
		width: 126px;
		height: 36px;
	}

	.responses__wrapper {
		margin-top: 23px;
	}
}

@media (max-width: 640px) {
	.responses__text {
		text-align: center;
	}

	.responses__title {
		text-align: center;
	}

	.responses__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 566px) {
	.responses__nav {
		overflow-x: scroll;
	}
}

@media (max-width: 330px) {
	.responses__text {
		text-align: left;
	}

	.responses__title {
		text-align: left;
	}
}
</style>
