<template>
	<div class="test__top-bottom-work-container">
		<button ref="select" class="test__top-bottom-work" :class="{
			'disable-btn':
				selectStatus === 'checking' || selectStatus === 'approved',
		}" @click="selectVisible = !selectVisible" @blur="blurSelect">
			<span v-if="selectTypeData === 'atestat'">
				<span v-if="selectModelData === 'gold'">Golden</span>
				<span v-if="selectModelData === 'silver'">Silver</span>
				<span v-if="selectModelData === 'none'">none</span>
			</span>
			<span v-if="selectTypeData === 'profCompet'">
				<span v-if="selectModelData === 'solo'">One</span>
				<span v-if="selectModelData === 'team'">In a team</span>
			</span>
			<span v-if="selectTypeData === 'science'">
				<span v-if="selectModelData === 'article'">Article</span>
				<span v-if="selectModelData === 'VAK_article'">Research article</span>
				<span v-if="selectModelData === 'not_russian'">foreign article</span>
			</span>

			<span v-if="selectTypeData === 'recommends'">
				<span v-if="selectModelData === 'prorector'">Vice-rector</span>
				<span v-if="selectModelData === 'teacher'">Teacher</span>
				<span v-if="selectModelData === 'employer'">Employer</span>
			</span>
		</button>
		<div class="test__top-bottom-work-group" v-if="selectVisible">
			<div class="search__sorting-select-speciality-group-element" v-for="(item, index) in selectData" :key="item.id">
				<input type="radio" :id="index" :value="item" class="test__top-bottom-work-group-element-input"
					v-model="currentData" @change="changeSelect" />
				<label :for="index" class="test__top-bottom-work-group-element-label"
					:class="{ select_picked: item === selectModelData }">
					{{ selectName[index] }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SelectForm",
	props: {
		iter: {},
		selectData: {
			type: Array,
		},
		selectName: {
			type: Array,
		},
		selectModelData: {},
		selectTypeData: {},
		selectStatus: {},
	},
	data() {
		return {
			selectVisible: false,
			currentName: null,
			currentData: null,
		};
	},
	methods: {
		changeSelect() {
			this.selectVisible = !this.selectVisible;
			this.$store.commit("setSelectData", {
				data: this.currentData,
				type: this.selectTypeData,
				index: this.iter,
			});
			if (this.selectTypeData === "recommends") this.$emit("updateSelect");
		},
		blurSelect() {
			setTimeout(() => {
				this.selectVisible = false;
			}, 300);
		},
	},
	beforeMount() {
		this.currentData = this.selectModelData;
	},
};
</script>

<style scoped>
button {
	margin: 0;
}

span {
	margin: 0;
	-webkit-appearance: none;
}

.test__top-bottom-work-container {
	height: 50px;
	position: relative;
	width: 300px;
	box-sizing: border-box;
	border-radius: 8px;
}

.test__top-bottom-work {
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	text-align: left;
	position: relative;
	padding-left: 31px;
	width: 100%;
	height: 100%;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #171725;

	height: inherit;
	flex-shrink: 1;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
	transition: background-color 1s ease;
}

.test__top-bottom-work-group {
	border: 1px solid #bcbcbc;
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	top: 49px;
	left: -1px;
	z-index: 1000;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	box-sizing: border-box;
	width: 300px;
	border-radius: 8px;
}

.test__top-bottom-work-group-element-label {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
}

.search__sorting-select-speciality-group-element:not(:last-child) {
	border-bottom: 1px solid #bcbcbc;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-input {
	-webkit-appearance: none;
	height: 0px;
}

.search__sorting-select-speciality-group-element {
	cursor: pointer;
	width: 100%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
	display: flex;
}

.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.select_picked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

@media (max-width: 330px) {
	.test__top-bottom-work-container {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}
}

.disable-btn {
	opacity: 0.5;
	pointer-events: none;
}
</style>