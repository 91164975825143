<template>
	<div class="record community-block">
		<div class="record-bg" @click="openBigPost"></div>
		<div class="record-caption">
			<img :src="avatarUrl" alt="" class="record-caption__logo" />
			<div class="record-caption__description">
				<h4 class="record-caption__description-name">{{ name }}</h4>
				<p class="record-caption__description-time">
					{{ $t('components.social.separatePost.postPublished') }}
					<time>{{ date(post.createDate) + " " + time(post.createDate) }}</time>
				</p>
			</div>
			<div class="post-interaction">
				<button class="post-btn more" id="interactionMenu" @click="interactionOpen = !interactionOpen"></button>
				<div class="post-interaction-list" :class="{ 'post-interaction__open': interactionOpen }">
					<router-link hidden="hidden" id="postLink"
						:to="{ name: 'communitiesPage', params: { communitiesId: communityId, postId: post.id } }">
					</router-link>
					<button id="copyLink" class="interaction-element" @click="copyLink">{{
						$t('components.social.separatePost.copyLink') }}</button>
					<button id="sendResponse" class="interaction-element" @click.stop="sendComplaint">{{
						$t('components.social.separatePost.complain') }}</button>
					<button id="delete" class="interaction-element" v-if="admin" @click="deletePost">{{
						$t('components.social.separatePost.deletePost') }}</button>
				</div>
			</div>
		</div>
		<ComplaintForm :url="currentUrl" :typeComplaints="dataComplaintTypes" :addresserId="dataCommunity.owner"
			:addresserRole="dataCommunity.ownerRole" v-if="complaintVisible"
			@closeWindow="complaintVisible = !complaintVisible">
		</ComplaintForm>
		<p class="record-info">{{ viewFullPost(post.body) }}</p>
		<button id="showMore" class="text-view-btn" v-if="post.body && post.body.length > textPostSize"
			@click="fullPost = !fullPost">
			{{ fullPost ? $t('components.social.separatePost.showLess') : $t('components.social.separatePost.showAll')
			}}
		</button>
		<SeparateContent @viewBigPhoto="(val) => {
			$emit('viewBigPhoto', val);
		}
			" v-if="post.files && post.files.length !== 0" :url="post.files" :postId="post.id">
		</SeparateContent>
		<div class="record-activity" @click.stop="">
			<div class="record-activity__element">
				<button id="comments" @click="loadComments" class="record-activity__comments-img"></button>
				<p class="record-activity__text">{{ post.commentsCount }}</p>
			</div>
			<button class="record-activity__element" @click="sharePost" id="sharePost">
				<div class="record-activity__share-img"></div>
			</button>
		</div>
		<div class="record-comments">
			<SeparateComment @deleteComment="deleteComment" v-for="item in comments" :key="item.id" :comment="item">
			</SeparateComment>
		</div>
		<button class="load-comments" @click="loadMoreComments" v-if="comments && trigger">
			{{ $t('components.social.separatePost.downloadMore') }}
		</button>

		<div class="record-answer" v-if="comments" @click.stop="">
			<img :src="avatar" alt="" class="record-caption__logo" />
			<form action="" class="answer-form">
				<div class="answer-textarea-wrapper">
					<textarea :placeholder="$t('components.social.separatePost.writeComment')" v-model="message"
						class="answer-textarea"></textarea>
				</div>
				<button type="button" @click="sendNewComments" class="answer-send"></button>
			</form>
		</div>
	</div>
</template>


<script>
import SeparateComment from "../account_communitis_main/SeparateComment.vue";
import SeparateContent from "../account_communitis_main/SeparateContent.vue";
import ComplaintForm from "../account_complaint/ComplaintForm.vue";
import { getDate, getTime } from "@/lib/dateUtils";
import { config } from "@/lib/config";
import Cookies from "js-cookie";
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { logoutWithToken } from "@/lib/logoutWithToken";
import { errorHandler } from "@/lib/errorHandler";

export default {
	props: ["post", "name", "avatarUrl", "index", "admin", "communityId", 'communityPost'],
	components: { SeparateComment, SeparateContent, ComplaintForm },
	name: "SeparatePost",
	data() {
		return {
			comments: null,
			date: getDate,
			time: getTime,
			page: 1,
			avatar: null,
			trigger: true,
			limit: 5,
			message: null,
			fullPost: false,
			interactionOpen: false,
			textPostSize: 250,
			complaintVisible: false,
		};
	},
	computed: {
		dataComplaintTypes() {
			return this.$store.getters.getComplaintCategories;
		},
		currentUrl() {
			return window.location.href + "/" + this.post.id;
		},
		dataCommunity() {
			return this.$store.getters.getDataCommunities;
		},
		currentLang(){
			return this.$i18n.locale
		}

	},
	watch:{
		currentLang(){
			this.$store.dispatch("fetchComplaintCategories", { token: this.$store.getters.token, locale: this.$i18n.locale });
		}
	},
	methods: {
		sharePost() {
			if (this.communityPost) {
				this.$emit('sharePostCommunity', this.post);
				return;
			}
			this.$emit('sharePost', this.post)
		},
		openBigPost(event) {
			switch (event?.target) {
				case document.getElementById('viewBigPhoto'):
					event.preventDefault();
					return;
				case document.getElementById('showMore'):
					event.preventDefault();
					return;
				case document.getElementById('interactionMenu'):
					event.preventDefault();
					return;

				case document.getElementById('copyLink'):
					event.preventDefault();
					return;

				case document.getElementById('delete'):
					event.preventDefault();
					return;

				case document.getElementById('sendResponse'):
					event.preventDefault();
					return;

				case document.getElementById('sharePost'):
					event.preventDefault();
					return;

				case document.getElementById('comments'):
					event.preventDefault();
					return;


				default:
					this.$emit('setCurrentPost', this.post);

			}

		},
		copyLink() {

			if (!this.post.id || !this.communityId) {
				errorHandler({ page: 'linkCopy', status: 500 });
				return;
			}


			navigator.clipboard.writeText(document.getElementById('postLink')?.href)
				.then(() => {
					errorHandler({ page: 'linkCopy', status: 200 });
					this.interactionOpen = false;
				})
				.catch(() => {
					errorHandler({ page: 'linkCopy', status: 500 })
				});

		},
		sendComplaint() {
			this.complaintVisible = !this.complaintVisible;
			this.interactionOpen = !this.interactionOpen
		},
		deleteComment(commentId) {
			const index = this.comments.map(item => item.id).indexOf(commentId);
			if (index > -1)
				this.comments.splice(index, 1);
		},

		deletePost() {
			this.$store.dispatch("deletePost", {
				token: this.$store.getters.token,
				id: this.post.id,
				index: this.index,
			});
		},

		viewFullPost(post) {
			if (post) {
				if (post.length > this.textPostSize) {
					if (!this.fullPost) {
						return textSymbolLimit(post, this.textPostSize);
					} else {
						return post;
					}
				} else {
					return post;
				}
			}
		},
		loadComments() {
			const request = new XMLHttpRequest();
			const url =
				config.apiPrefix +
				"community/post/" +
				this.post.id +
				"/comment" +
				"?page=" +
				this.page +
				"&limit=" +
				this.limit;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				logoutWithToken(request.status, this.$store);
				const response = JSON.parse(request.response);
				this.trigger = response.data.length >= this.limit;
				const loadedComments = response.data;

				let filteredComments;
				// Фильтрация комментариев, чтобы избежать дублирования
				if (this.comments !== null) {
					filteredComments = loadedComments.filter((newComment) =>
						!this.comments.some((existingComment) =>
							existingComment.body === newComment.body &&
							Math.abs(new Date(existingComment.createDate).getTime() - new Date(newComment.createDate).getTime()) < 1000 && newComment.userId === existingComment.userId
						));
				}
				else {
					filteredComments = loadedComments;
				}

				if (this.page === 1) this.comments = filteredComments;
				else this.comments = [...new Set([...this.comments, ...filteredComments])];
			} catch (e) {
				console.log(e);
			}
		},
		loadMoreComments(event) {
			event.stopPropagation();
			this.page++;
			this.loadComments();
		},
		sendNewComments() {
			const req = new XMLHttpRequest();
			const url =
				config.apiPrefix + "community/post/" + this.post.id + "/comment";
			req.open("POST", url, false);
			req.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
			try {
				req.send(JSON.stringify({ body: this.message }));
				logoutWithToken(req.status, this.$store);
				const newComment = {
					id: 'temp-' + Date.now(), // Временный ID
					userAvatarUrl: this.$store.getters.user.avatarPath,
					userName: this.$store.getters.user.name,
					userId: this.$store.getters.user.id,
					body: this.message,
					createDate: new Date()
				};
				this.page = 1;
				this.comments = [newComment, ...this.comments];
				this.post.commentsCount++;
				this.message = null;
			} catch (e) {
				console.log(e);
			}
		},
	},
	beforeMount() {
		this.avatar = Cookies.get("avatar");
		this.$store.dispatch("fetchComplaintCategories", { token: this.$store.getters.token, locale: this.$i18n.locale });
	},
};
</script>

<style scoped>
* {
	box-sizing: border-box;
}

.community-block {
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 30px;
	margin-bottom: 40px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
}

.post-interaction {
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
}

.post-interaction-list {
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: 10px;
	gap: 5px;
	min-width: 200px;
	border-radius: 10px;
	border: 1px solid #EFEFF4;
	background: #FFF;
	box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.12);
	top: 30px;
	opacity: 0;
	transform: translateY(10%);
	transition: all 0.5s ease-in-out;
	z-index: -2;
}

.post-interaction__open {
	opacity: 1;
	transform: translateY(0);
	z-index: 2;
}

.interaction-element {
	padding: 5px;
	font-size: 14px;
	text-align: left;
	transition: all 1s ease-in-out;
}

.interaction-element:hover {
	opacity: 0.5;
}

.post-btn {
	display: flex;
	flex-shrink: 0;
	height: 24px;
	width: 24px;
	position: relative;
	z-index: 2;
}

.more {
	background-image: url("~@/assets/img/test-img/constractor/ellipsis.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.record {
	position: relative;
}

.record-bg {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}

.record-caption {
	display: flex;
	width: 100%;
	align-items: center;
}

.record-caption__logo {
	width: 64px;
	height: 64px;
	border-radius: 10px;
	object-fit: cover;
}

.record-caption__description {
	margin-left: 20px;
	margin-right: 10px;
}

.record-caption__description-name {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #000000;
}

.record-caption__description-time {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.4000000059604645);
	margin-top: 10px;
}

.community__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin-left: auto;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.community__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -113px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;
}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.popap-list__button:last-child {
	padding-bottom: 10px;
}

.community__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.record-info {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	color: #424242;
	margin-top: 20px;
	max-width: 100%;
	width: 100%;
}

.record-activity {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.record-activity__element {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
}

.record-activity__element:not(:last-child) {
	margin-right: 20px;
}

.record-activity__like-img {
	background: url("~@/assets/img/account-img/communities/like.svg");
	background-repeat: no-repeat;
	width: 20px;
	height: 18px;
	display: block;
}

.record-activity__text {
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: rgba(68, 68, 79, 0.5);
}

.record-activity__comments-img {
	background: url("~@/assets/img/account-img/communities/comment.svg");
	background-repeat: no-repeat;
	width: 18px;
	height: 18px;
	display: block;
}

.record-activity__share-img {
	background: url("~@/assets/img/account-img/communities/share.svg");
	background-repeat: no-repeat;
	width: 19px;
	height: 18px;
	display: block;
	position: relative;
	z-index: 2;
}

.record-answer {
	display: flex;
	margin-top: 40px;
	position: relative;
	z-index: 2;
}

.record-caption__logo {
	width: 64px;
	height: 64px;
	border-radius: 10px;
}

.answer-form {
	margin-left: 20px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.answer-textarea-wrapper {
	width: 100%;
	box-sizing: border-box;
	position: relative;
}

.answer-textarea {
	width: 100%;
	border: 1px solid #efeff4;
	border-radius: 10px;
	padding: 20px 60px 20px 20px;
	box-sizing: border-box;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.02em;
	color: #434343;
	overflow: auto;
	height: 64px;
	scrollbar-width: auto;
}

.answer-textarea::placeholder {
	color: rgba(66, 66, 66, 0.5);
}

.answer-textarea-file {
	position: absolute;
	right: 20px;
	top: 21px;
	background: url("~@/assets/img/account-img/communities/file.svg");
	display: block;
	background-repeat: no-repeat;
	width: 20px;
	height: 20px;
}

.answer-send {
	background: url("~@/assets/img/account-img/communities/send.svg");
	display: block;
	background-repeat: no-repeat;
	width: 34px;
	height: 34px;
	margin-left: 16px;
}

@media (max-width: 1575px) {
	.record-info {
		max-width: 100%;
	}
}

@media (max-width: 950px) {
	.community-block {
		padding: 20px 14px;
		margin-top: 0px;
		margin-bottom: 20px;
	}

	.record-caption__logo {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}

	.record-caption__description {
		margin-left: 10px;
	}

	.record-caption__description-time {
		font-size: 12px;
		margin-top: 6px;
	}

	.record-photos__elemnt {
		border-radius: 5px;
	}

	.record-activity {
		margin-top: 10px;
	}

	.record-activity__element:not(:last-child) {
		margin-right: 20px;
	}

	.record-activity__text {
		font-size: 12px;
	}

	.record-caption__logo {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}

	.answer-form {
		margin-left: 10px;
	}

	.answer-textarea {
		height: 40px;
		padding: 13px 24px 13px 10px;
		line-height: 14px;
	}

	.answer-textarea-file {
		height: 13px;
		width: 13px;
		right: 10px;
		top: 13px;
		background-size: cover;
	}

	.answer-send {
		height: 25px;
		width: 25px;
		background-size: cover;
		margin-left: 10px;
	}
}

.load-comments {
	background: #009ed1;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	margin: 30px auto 0;
	width: 150px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 1s ease;
}

.load-comments:hover {
	opacity: 0.5;
}

.text-view-btn {
	margin-top: 15px;
	font-family: "Roboto";
	font-size: 16px;
	line-height: 1;
	color: #0b76ef;
	position: relative;
	z-index: 2;
}
</style>
