<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<div class="recommendations">
						<h1 class="recommendations__text-mobile">{{
							$t('pages.account.account_worker.accountWorkerRecomendationPage.personalCabinet') }}</h1>
						<h1 class="recommendations__title">{{
							$t('pages.account.account_worker.accountWorkerRecomendationPage.personalRecommendations') }}
						</h1>
						<p class="recommendations__text-desctop">{{
							$t('pages.account.account_worker.accountWorkerRecomendationPage.welcome') }}</p>
						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_worker.accountWorkerRecomendationPage.vacancy') }}</h2>
							<router-link :to="{ name: 'accountWorkerRecommendationVacancy' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_worker.accountWorkerRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>
						<div class="recommendations__wrapper">
							<separate-recomendation v-for="item in dataVacancy" :key="item.id" :vacancy="item"
								:isFavorite="isVacancyFavorite(item.id)"></separate-recomendation>
						</div>

						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_worker.accountWorkerRecomendationPage.possibleFriends') }}
							</h2>
							<router-link :to="{ name: 'friends' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_worker.accountWorkerRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>

						<div class="recommendations__wrapper">
							<SeparateFriend v-for="(item, index) in dataFriends" :is-friends="isFriends" :key="item.id"
								:friend="item" :index="index" @messageVisible="messageVisible"></SeparateFriend>
						</div>

						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_worker.accountWorkerRecomendationPage.communities') }}</h2>
							<router-link :to="{ name: 'communities' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_worker.accountWorkerRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>

						<div class="recommendations__wrapper__group">
							<div class="proposed-group">
								<button class="recommendations-test">
									<div class="recommendations-test-caption">
										<img alt=" "
											src="~@/assets/img/recommendation-page/recommendation/community.svg"
											class="recommendations-test-caption__img">
										<div class="recommendations-description">
											<p class="recommendations-description__comany">{{
												$t('pages.account.account_worker.accountWorkerRecomendationPage.myCommunities')
												}}</p>
											<p class="recommendations-description__format">{{
												$t('pages.account.account_worker.accountWorkerRecomendationPage.totalCommunities')
												}} {{ getMyCommunities.length }}</p>
										</div>
									</div>
									<div class="recommendations-test-info">
										<div class="recommendations-info-element green">
											<p class="recommendations-info-name">{{
												$t('pages.account.account_worker.accountWorkerRecomendationPage.open')
												}}</p>
											<div class="recommendations-info-text">
												<p class="recommendations-info-text__number">{{
													getCountMyOpenCommunities }}</p>
											</div>
										</div>
										<div class="recommendations-info-element green">
											<p class="recommendations-info-name">{{
												$t('pages.account.account_worker.accountWorkerRecomendationPage.closed')
												}}</p>
											<div class="recommendations-info-text">
												<p class="recommendations-info-text__number">{{
													getCountMyClosedCommunities }}</p>
											</div>
										</div>
									</div>
									<router-link :to="{ name: 'communities' }" class="recommendations-test__start">{{
										$t('pages.account.account_worker.accountWorkerRecomendationPage.readMore')
										}}</router-link>
								</button>

								<SeparateCommunity v-for="item in dataCommunity" :comData="item" :key="item.id">
								</SeparateCommunity>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import SeparateRecomendation from "../../../components/account/account_worker/account_worker_recomendation/item/SeparateRecomendation.vue";
import SeparateFriend from "../../../components/social/account_friensds/SeparateFriend.vue";
import SeparateCommunity from "../../../components/social/account_communitis/SeparateCommunityMini.vue";
import { config } from "@/lib/config";

export default {
	components: { SeparateRecomendation, MenuWorker, HeaderAccountWorker, SeparateFriend, SeparateCommunity },
	data() {
		return {
			dataVacancy: {},
			dataFriends: {},
			dataCommunity: {},
			isFriends: true
		};
	},
	computed: {
		getMyCommunities() {
			return this.$store.getters.getMyCommunities || [];
		},

		getCountMyClosedCommunities() {
			const closedCommunities = (this.$store.getters.getMyCommunities || []).filter((community) => community.communityType === "closed");
			return closedCommunities.length;
		},

		getCountMyOpenCommunities() {
			const openCommunities = (this.$store.getters.getMyCommunities || []).filter((community) => community.communityType === "open");
			return openCommunities.length;
		}
	},
	methods: {
		isVacancyFavorite(vacancyId) {
			const favoriteVacancies = this.$store.getters.favoriteVacancy;
			return favoriteVacancies.some(vacancy => vacancy.id === vacancyId);
		},
		fetchDataVacancy() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/recommendations/vacancy?lang=ru";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataVacancy = response.data.slice(0, 4);
			} catch (e) {
				console.log(e);
			}
		},
		fetchDataFriends() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/recommendations/friends?lang=ru";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataFriends = response.data.slice(0, 4);
			} catch (e) {
				console.log(e);
			}
		},
		fetchDataCommunity() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/recommendations/community?lang=ru";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataCommunity = response.data.slice(0, 3);
			} catch (e) {
				console.log(e);
			}
		},
		messageVisible(payload) {
			this.newMessage = !this.newMessage;
			if (payload) {
				this.newMessageUserId = payload.id;
				this.newMessageUserName = payload.name;
			}
		}
	},
	name: "AccountWorkerRecomendationPage",
	beforeMount() {
		this.$store.dispatch("fetchFavoriteVacancy", { token: this.$store.getters.token });
		this.fetchDataVacancy();
		this.fetchDataFriends();
		this.fetchDataCommunity();
		this.$store.dispatch('fetchFavoriteFriends', {
			token: this.$store.getters.token,
			page: this.$store.getters.friendPage,
			role: 'all',
			limit: this.$store.getters.getLimit,
			callback: this.deletePagination
		});
		this.$store.dispatch('fetchMyBlockedFriends', {
			token: this.$store.getters.token,
			page: this.$store.getters.friendPage,
			role: 'all',
			limit: this.$store.getters.getLimit,
			callback: this.deletePagination
		});
		scroll(0, 0);
	}
};
</script>




<style scoped>
/* Recomendation */
.account {
	display: flex;
}

.recommendations {
	margin-bottom: 40px;
	width: 100%;
	box-sizing: border-box;
}

.recommendations__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.container-recomendation__header {
	margin-top: 36px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.recommendations__link {
	font-size: 16px;
	color: #009ED1;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;
}

.recommendations__subtitle {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 24px;
	color: #44444F;
}

.recommendations__text-desctop {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.recommendations__wrapper {
	box-sizing: border-box;
	width: 100%;
	margin-top: 12px;
	display: grid;
	gap: 12px;
}

.recommendations__wrapper:deep(.friend-wrapper) {
	margin-bottom: 0;
}



.recommendations__text-mobile {
	display: none;
}

/*recommendations-test*/

.proposed-group {
	max-width: 100%;
	width: 100%;
	margin-top: 12px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 20px;
}

.recommendations-test {
	max-width: 100%;
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background: #009ED1;
	border-radius: 10px;
	height: 205px;
	box-sizing: border-box;
	font-family: "Roboto";
	text-align: left;
}



.recommendations-test-caption {
	display: flex;
	max-width: 100%;
	align-items: center;
	gap: 10px;
	width: 100%;
}

.recommendations-test-caption__img {
	width: 53px;
	height: 53px;
	background: #c4c4c4;
	border-radius: 10px;
	display: flex;
	flex-shrink: 0;
	object-fit: cover;
}

.recommendations-description__comany {
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
	display: flex;
	align-items: center;
	gap: 6px;
}

.recommendations-description__format {
	margin-top: 6px;
	font-weight: 500;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
}


.recommendations-test-info {
	margin-top: 20px;
	display: flex;
	max-width: 100%;
	gap: 5px;
	width: 100%;
}

.recommendations-info-element {
	display: flex;
	max-width: 100%;
	text-align: left;
	width: 100%;
	flex-direction: column;
}

.recommendations-info-name {
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: #fff;
}

.recommendations-info-text {
	display: flex;
	align-items: center;
	margin-top: 5px;

}

.recommendations-info-text__number {
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: #fff;
}


.recommendations-test__start {
	max-width: 100%;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
	border-top: 1px solid rgba(231, 233, 236, 0.8);
	padding-top: 20px;
	margin-top: auto;
	padding-bottom: 10px;
}

.mobile {
	display: none;
}

@media (max-width: 1575px) {
	.recommendations__wrapper {
		/* grid-template-columns: repeat(3, 1fr); */
	}

	.recommendations__wrapper:deep(.friend-wrapper) {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}
}

@media (max-width: 1488px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}
}

@media (max-width: 1340px) {
	.recommendations__title {
		text-align: center;
	}

	.recommendations__subtitle {
		text-align: center;
		font-size: 16px;
		line-height: 1.2;
	}

	.recommendations__link {
		font-size: 14px;
	}

	.recommendations__text-desctop {
		text-align: center;
	}

}

@media (max-width: 1300px) {
	.proposed-group {
		/* grid-template-columns: repeat(4, 1fr); */
	}

}

@media (max-width: 1190px) {
	.recommendations__wrapper {
		width: 100%;
		margin-top: 12px;
		display: grid;
		row-gap: 12px;
		/* grid-template-columns: repeat(3, 1fr); */
	}


}

@media (max-width: 1040px) {
	.recommendations__wrapper {
		/* grid-template-columns: repeat(2, 1fr); */
	}

	.proposed-group {
		/* grid-template-columns: repeat(3, 1fr); */
	}
}

@media (max-width: 767px) {

	.proposed-group {
		/* grid-template-columns: repeat(2, 1fr); */
	}
}

@media (max-width: 654px) {
	.recommendations__wrapper {
		/* grid-template-columns: repeat(1, 1fr); */
	}

	.recommendations__wrapper__group {
		/* grid-template-columns: repeat(1, 1fr); */
	}

	.recommendations__more-btn {
		width: 300px;
	}

	.recommendations__subtitle {
		text-align: center;
		font-size: 14px;
		line-height: 1.2;
	}

	.recommendations__link {
		font-size: 12px;
	}
}

@media (max-width: 450px) {
	.proposed-group {
		/* grid-template-columns: repeat(1, 1fr); */
	}
}

@media (max-width: 360px) {
	.recommendations__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.container-recomendation__header {
		margin-top: 20px;
		display: block;
		width: 100%;
		justify-content: space-between;
	}

	.recommendations__subtitle {
		text-align: left;
		font-weight: 700;
		font-family: "Montserrat";
		font-size: 18px;
		margin-top: 20px;
		color: #44444F;
	}

	.container-recomendation__header a {
		font-size: 12px;
		color: #009ED1;
		font-weight: 500;
		text-decoration: underline;
		cursor: pointer;
	}

	.recommendations__text-desctop {
		display: none;
	}

	.recommendations__text-mobile {
		display: block;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.recommendations__wrapper {
		margin-top: 8px;
	}

	.recommendations__wrapper__group {
		margin-top: 8px;
	}
}
</style>
