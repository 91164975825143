<template>
  <div class="choice-wrapper">
    <div
        class="choice-element"
        v-for="(item, index) in question.answers"
        :key="item.id + index"
    >
      <p class="choice__text">{{ item.body }}</p>
      <div class="choice-select">
        <button
            type="button"
            class="choice__btn"
            @click="viewListByIndex(index)"
        >
          {{
            getPickedAnswer(item.id)
                ? getPickedAnswer(item.id)
                : "Not chosen"
          }}
          <svg
              width="11"
              height="9"
              viewBox="0 0 11 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M5.49899 8.60011L0.889677 0.263196L10.4143 0.439871L5.49899 8.60011Z"
                fill="#99A2B1"
            />
          </svg>
        </button>
        <div class="choice-popup" v-if="viewListIndex === index">
          <button
              type="button"
              class="choice-popup__element"
              @click="
              pickAnswer(item,answer)
            "
              v-for="(answer, index2) in answerArray"
              :key="answer.id + index2"
          >
            {{ answer.comparisonBody }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "TestSelect",
  props: ["question"],
  computed: {
    saveTesting() {
      return this.$store.getters.getSaveSetting;
    },
  },
  methods: {
    viewListByIndex(index) {
      if (this.viewListIndex === index) {
        this.viewListIndex = null;
        return;
      }
      this.viewListIndex = index;
    },
    pickAnswer(item, answer) {
      this.$store.commit("setTestingSelectAnswer", {
        questionId: this.question.id,
        answerId: item.id,
        comparisonBody: answer.comparisonBody,
      });
      this.viewListIndex = null;
      this.getPickedAnswer(answer);
    },
    shuffle(arr) {
      let array = JSON.parse(JSON.stringify(arr));
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    getPickedAnswer(id) {
      let filtered = this.saveTesting.answers.filter(item => item.testingQuestionId === this.question.id && item.testingAnswerId === id)
      return filtered.length ? filtered[0].comparisonBody : null;
    }
  },
  data() {
    return {
      viewListIndex: null,
      answerArray: null
    };
  },
  beforeMount() {
    this.answerArray = lodash.sortBy([...this.question.answers], ['comparisonBody'], ['desc'])
  }
};
</script>

<style scoped>
.choice-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.choice-element {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.choice__text {
  background: #f5f8f9;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  padding: 20px 33px;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.78;
  color: #3b4c68;
}

.choice-select {
  position: relative;
}

.choice__btn {
  display: flex;
  align-items: center;
  gap: 34px;
  background: #f6f8fa;

  border: 1px solid #dae9f3;
  border-radius: 13px;
  padding: 24px 14px 24px 25px;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.28;
  color: #3b4c68;
}

.choice-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background: #f6f8fa;
  border-radius: 13px;
  border: 1px solid #dae9f3;
  right: 0;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  max-height: 210px;
}

.choice-popup__element {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.28;
  color: #3b4c68;
  padding: 24px;
  max-width: unset;
  width: 100%;
  border-bottom: 1px solid #dae9f3;
}

.choice-popup :last-child {
  border-bottom: none;
}

@media (max-width: 700px) {
  .choice-wrapper {
    gap: 10px;
  }

  .choice-element {
    gap: 10px;
  }

  .choice__text {
    padding: 10px 18px 10px 18px;
    font-size: 11px;
  }

  .choice-select {
    position: relative;
  }

  .choice__btn {
    padding: 10px 18px 10px 18px;
    font-size: 11px;
    gap: 8px;
  }

  .choice__btn svg {
    width: 8px;
  }

  .choice-popup {
    max-height: 110px;
  }

  .choice-popup__element {
    padding: 10px 18px 10px 18px;
    font-size: 11px;
  }

  .choice-popup :last-child {
    border-bottom: none;
  }
}

.blocked {
  opacity: 0.4;
  pointer-events: none;
}
</style>
