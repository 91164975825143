export const values = [
    {
        name: 'Russian ruble',
        value: 'rub'
    },
    {
        name: 'Euro',
        value: 'eur'
    },
    {
        name: 'Dollar USD',
        value: 'usd'
    },
    {
        name: 'Indian Rupee',
        value: 'inr'
    },
    {
        name: 'Qatari rial',
        value: 'qar'
    },
    {
        name: 'Indonesian rupiah',
        value: 'idr'
    },
    {
        name: 'CNY',
        value: 'chy'
    },
    {
        name: 'UAE dirham',
        value: 'aed'
    },
    {
        name: 'Singapore dollar',
        value: 'sgd'
    },
    {
        name: 'Turkish lira',
        value: 'trl'
    },
    {
        name: 'Japanese yen',
        value: 'jpy'
    },
];

const fx = require('money')

export const convertSalary = function (val, from, to) {
    const index = values.map(item => item.value).indexOf(to.toLowerCase());
    return val && index > -1 ? `${Math.floor(fx.convert(val, {
        from: from.toUpperCase(),
        to: to.toUpperCase()
    }))} ${values[index].name}` : null;
}
