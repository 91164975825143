<template>
	<header class="header">
		<div class="header__wrapper">
			<a href="" class="header__wrapper-logo logo"><img src="~@/assets/img/all-img/logo.png" alt=""
					class="header__wrapper-logo-img" /></a>
			<div class="header__wrapper-burger" v-on:click="menuVisible = !menuVisible">
				<span class="header__wrapper-burger-span"></span>
			</div>
			<ul class="header__wrapper-nav">
				<li><a href="#" class="header__wrapper-nav-item">{{
					$t('components.account_worker.headerAccount.mainPage') }}</a></li>
				<li><a href="#" class="header__wrapper-nav-item">{{
					$t('components.account_worker.headerAccount.aboutUs') }}</a></li>
				<!-- <li><a href="#" class="header__wrapper-nav-item">Pricing</a></li> -->
				<li><a href="#" class="header__wrapper-nav-item">{{ $t('components.account_worker.headerAccount.blog')
						}}</a></li>
				<li><a href="#" class="header__wrapper-nav-item">{{ $t('components.account_worker.headerAccount.help')
						}}</a></li>
				<li><a href="#" class="header__wrapper-nav-item">{{
					$t('components.account_worker.headerAccount.contactUs') }}</a></li>
			</ul>
			<div class="header__wrapper-btngroup">
				<button href="#" class="header__wrapper-btngroup-btn">
					{{ $t('components.account_worker.headerAccount.forJobSeekers') }}
				</button>
				<button href="#" class="header__wrapper-btngroup-btn">
					{{ $t('components.account_worker.headerAccount.forEmployers') }}
				</button>
			</div>
			<div class="header__wrapper-profile">
				<img :src="avatarPath" class="header__wrapper-profile-img" alt="Logo"
					@click="menuVisible = !menuVisible" />
				<p class="header__wrapper-profile-name">{{ limit(name, 20) }}</p>
				<button class="header__wrapper-profile-list"></button>
			</div>
		</div>
		<div class="header__menu" v-if="menuVisible">
			<a href="" class="header__menu-logo logo"><img src="~@/assets/img/main-page/Logo.svg" alt=""
					class="header__menu-logo-img" /></a>
			<ul class="header__menu-nav">
				<li><a href="#" class="header__menu-nav-item">{{ $t('components.account_worker.headerAccount.mainPage')
						}}</a></li>
				<li><a href="#" class="header__menu-nav-item">{{ $t('components.account_worker.headerAccount.aboutUs')
						}}</a></li>
				<!-- <li><a href="#" class="header__menu-nav-item">Pricing</a></li> -->
				<li><a href="#" class="header__menu-nav-item">{{ $t('components.account_worker.headerAccount.blog')
						}}</a></li>
				<li><a href="#" class="header__menu-nav-item">{{ $t('components.account_worker.headerAccount.help')
						}}</a></li>
				<li><a href="#" class="header__menu-nav-item">{{ $t('components.account_worker.headerAccount.contactUs')
						}}</a></li>
			</ul>
			<div class="header__menu-btngroup">
				<button href="#" class="header__menu-btngroup-btn">{{
					$t('components.account_worker.headerAccount.forJobSeekers') }}</button>
				<button href="#" class="header__menu-btngroup-btn">
					{{ $t('components.account_worker.headerAccount.forEmployers') }}
				</button>
			</div>
		</div>
	</header>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
	name: "HeaderAccount",

	data() {
		return {
			menuVisible: false,
			limit: textSymbolLimit
		};
	},

	computed: {
		avatarPath() {
			return this.$store.getters.avatarPath;
		},
		name() {
			return this.$store.getters.name;
		},
	},
};
</script>


<style scoped>
.header {
	width: 100%;
	position: fixed;
	background: #fff;
	z-index: 1000;
}

.header__wrapper {
	box-shadow: inset 0px -1px 0px #e2e2ea;
	max-height: 70px;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0 auto;
	font-family: "Montserrat", sans-serif;
}

.header__wrapper-logo {
	padding-left: 49px;
	order: 1;
	height: 31px;
}

.header__wrapper-logo-img {
	width: 177px;
	height: 31px;
}

.header__wrapper-nav {
	margin-left: auto;
	position: relative;
	order: 2;
}

.header__wrapper-nav-item:hover {
	color: #009ed1;
}

.header__wrapper-nav-item {
	text-decoration: none;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #44444f;
	transition: color 1s ease;
}

.header__wrapper-nav :not(:last-child) {
	padding-right: 84px;
}

.header__wrapper-btngroup {
	margin-left: auto;
	margin-right: 42px;
	order: 3;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.1px;
	transition: color 1s ease, background 1s ease;
}

.header__wrapper-btngroup :hover {
	color: #009ed1;
	background: #e5f6fb;
}

.header__wrapper-btngroup :not(:last-child) {
	margin-right: 13px;
}

.header__wrapper-btngroup-btn {
	text-decoration: none;
	width: 150px;
	height: 43px;
	border-radius: 4px;
	background: #f0f4f9;
	color: #607088;
	transition: color 1s ease, background 1s ease;
}

.header__wrapper-lang {
	display: flex;
	gap: 5px;
}

.header-lang__element {
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #000;
}

.lang-chosen {

	color: #009ed1;
}

.header__wrapper-bell {
	width: 18px;
	height: 21px;
	background-image: url("~@/assets/img/aboutus-page/header/bell.svg");
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.header__wrapper-bell-notifications {
	display: none;
	position: absolute;
	width: 12px;
	height: 12px;
	background: #14c686;
	border-radius: 12px;
	top: -4px;
	right: -6px;
}

.header__wrapper-profile {
	margin-right: 32px;
	order: 4;
	display: flex;
	text-align: center;
	align-items: center;
}

.header__wrapper-profile-img {
	width: 32px;
	height: 32px;
	margin-left: 27px;
	border-radius: 100%;
	object-fit: cover;
	object-position: center;
}

.header__wrapper-profile-name {
	padding-left: 10px;
	font-weight: 600;
	font-size: 13px;
	line-height: 14px;
	letter-spacing: 0.1px;
	color: #171625;
}

.header__wrapper-profile-list {
	padding-left: 62px;
	width: 11px;
	height: 7px;
	background: url("~@/assets/img/aboutus-page/header/header-list.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.header__menu {
	display: none;
}

@media (max-width: 1700px) {
	.header__wrapper-profile {
		margin-right: 14px;
	}

	.header__wrapper-logo {
		padding-left: 29px;
	}

	.header__wrapper-nav {
		display: none;
	}

	.header__wrapper-burger {
		cursor: pointer;
		margin-right: 18px;
		order: 5;
		display: block;
		position: relative;
		width: 30px;
		height: 20px;
	}

	.header__wrapper-burger::after,
	.header__wrapper-burger::before {
		content: "";
		background-color: #009ed1;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
	}

	.header__wrapper-burger::after {
		top: 0;
	}

	.header__wrapper-burger::before {
		bottom: 0;
	}

	.header__wrapper-burger-span {
		background-color: #009ed1;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 9px;
	}
}

@media (max-width: 1700px) {
	.header__wrapper-nav {
		display: none;
	}

	.header__menu {
		box-shadow: inset 0px -1px 0px #e2e2ea;
		display: block;
		background: #fafafb;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		margin-right: auto;
		flex-direction: column;
		padding: 0px 24px 20px 26px;
		text-align: center;
	}

	.header__menu-logo {
		display: none;
	}

	.header__menu-nav {
		padding-top: 19px;
		transition: color 1s ease;
	}

	.header__menu-btngroup {
		display: none;
	}

	.header__menu-nav-item {
		font-size: 20px;
		display: block;
		font-family: "Montserrat";
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.1px;
		color: #44444f;
		margin-bottom: 19px;
		padding-bottom: 21px;
		border-bottom: 1px solid rgba(68, 68, 79, 0.11);
		transition: color 1s ease;
	}

	.header__menu-nav-item :hover {
		color: #009ed1;
	}
}

@media (max-width: 950px) {
	.header__wrapper-profile {
		margin-left: auto;
	}

	.header__menu-logo {
		margin-top: 23px;
		display: block;
	}

	.header__menu {
		text-align: left;
	}

	.header__menu-btngroup {
		display: block;
		box-sizing: border-box;
		order: 3;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.1px;
		transition: color 1s ease, background 1s ease;
	}

	.header__menu-btngroup :hover {
		color: #009ed1;
		background: #e5f6fb;
	}

	.header__menu-btngroup :not(:last-child) {
		margin-bottom: 12px;
	}

	.header__menu-btngroup-btn {
		text-decoration: none;
		width: 100%;
		height: 43px;
		border-radius: 4px;
		background: #f0f4f9;
		color: #607088;
		transition: color 1s ease, background 1s ease;
	}

	.header__wrapper-logo {
		display: none;
	}

	.header__wrapper-btngroup {
		display: none;
	}

	.header__wrapper-burger {
		order: 1;
		margin-left: 24px;
	}

	.header__wrapper-burger {
		margin-right: 49px;
		order: 3;
		display: block;
		position: relative;
		width: 16px;
		height: 10px;
	}

	.header__wrapper-burger::before {
		content: "";
		background-color: #92929d;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
	}

	.header__wrapper-burger::after {
		content: none;
	}

	.header__wrapper-burger-span {
		background-color: #92929d;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 2px;
	}
}

@media (max-width: 767px) {
	.header__wrapper-profile-list {
		display: none;
	}

	.header__wrapper-profile {
		margin-right: 24px;
	}
}
</style>
