import {errorHandler} from "../../lib/errorHandler";
import {config} from "@/lib/config";

export const interviewConstruct = {
    actions: {
        deleteInterview({getters}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'employer/interview/delete/' + payload.id;
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                payload && payload.callback ? payload.callback() : null;
                errorHandler({status:req.status,page:'interviewDelete'});
            } catch (e) {
                console.log(e)
            }
        },

        fetchConstruct({commit}, payload) {
            let data;
            if (payload.id !== 'new') {
                let xmlhttp = new XMLHttpRequest();
                let url = config.apiPrefix + 'interview/get/' + payload.id
                xmlhttp.open('GET', url, false);
                xmlhttp.setRequestHeader('X-Auth-Token', payload.token)
                try {
                    xmlhttp.send();
                    let response = JSON.parse(xmlhttp.response);
                    data = response.data;
                } catch (e) {
                    console.log(e);
                }
            } else {
                data = {
                    title: null,
                    previewUrl: null,
                    maxPassingTimeSeconds: null,
                    description: null,
                    questions: []
                }
                commit('setNewConstruct');
            }
            commit('setConstruct', data);

        },


        updateFile({commit, state}, payload) {
            commit('checkCountFile');

            for (let i = 0; i < state.dataFile.length; i++) {
                if (state.dataFile[i].photo) {
                    let request = new XMLHttpRequest();
                    let url = config.apiPrefix + 'file/upload';
                    request.open('POST', url, true);
                    try {
                        let formData = new FormData();
                        formData.append('file', state.dataFile[i].photo)
                        request.send(formData)
                        request.onreadystatechange = function () {
                            if (this.readyState === 4 && this.status === 200) {
                                let response = JSON.parse(this.response)
                                commit('updateDataFile', {
                                    data: response.data.url,
                                    index: state.dataFile[i].index,
                                    type: 'photo'
                                });
                                commit('fileComplete', payload.requestSave);
                            }

                        }
                    } catch (e) {
                        console.log(e);
                    }
                }

                if (state.dataFile[i].video) {
                    let request = new XMLHttpRequest();
                    let url = config.apiPrefix + 'file/upload';
                    request.open('POST', url, true);
                    try {
                        let formData = new FormData();
                        formData.append('file', state.dataFile[i].video)
                        request.send(formData)
                        request.onreadystatechange = function () {
                            if (this.readyState === 4 && this.status === 200) {
                                let response = JSON.parse(request.response)
                                commit('updateDataFile', {
                                    data: response.data.url,
                                    index: state.dataFile[i].index,
                                    type: 'video',
                                });
                                commit('fileComplete', payload.requestSave);
                            }
                        }

                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            if (state.previewFile !== null) {
                let formData = new FormData()
                formData.append('file', state.previewFile)
                let newUrl;
                let xmlHttp = new XMLHttpRequest();
                let url = config.apiPrefix + "file/upload";
                xmlHttp.open("POST", url, false);
                try {
                    xmlHttp.send(formData);
                    let response = JSON.parse(xmlHttp.response);
                    newUrl = response.data.url;
                    commit('setPreviewPhoto', {url: newUrl, file: null});
                } catch (e) {
                    console.log(e);
                }

            }

        },


        saveDataConstruct({state, commit}, payload) {

            commit('CheckOpenAnswer');

            let data = {
                interviewId: state.dataConstruct.id,
                maxPassingTimeSeconds: state.dataConstruct.maxPassingTimeSeconds,
                title: state.dataConstruct.title,
                description: state.dataConstruct.description,
                previewUrl: state.dataConstruct.previewUrl,
                questions: state.dataConstruct.questions
            }


            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + 'interview/update'
            xmlHttp.open('POST', url, true);
            xmlHttp.setRequestHeader('X-Auth-Token', payload.token);
            xmlHttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                xmlHttp.send(JSON.stringify(data))
                xmlHttp.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        errorHandler({status: xmlHttp.status});
                        payload.routeBack();

                    }
                }
            } catch (e) {
                console.log(e);
            }
            commit('nullDataFile');

        },
        saveNewConstruct({state, commit}, payload) {
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + 'interview/create'
            xmlHttp.open('POST', url, true);
            xmlHttp.setRequestHeader('X-Auth-Token', payload.token);
            xmlHttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                xmlHttp.send(JSON.stringify(state.dataConstruct))
                xmlHttp.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        errorHandler({status: xmlHttp.status});
                        payload.routeBack();

                    }
                }

            } catch (e) {
                console.log(e);
            }
            commit('nullDataFile');
        }
    },
    mutations: {
        checkCountFile(state) {
            for (let i = 0; i < state.dataFile.length; i++) {
                if (state.dataFile[i].video) state.countFile++;
                if (state.dataFile[i].photo) state.countFile++;
            }
        },
        fileComplete(state, data) {
            state.countLoadedFile++;
            if (state.countLoadedFile === state.countFile)
                data();
        },
        statusLoader(state, data) {
            state.loader = data;
        },
        // SET DATA CONSTRUCT
        setNewConstruct(state) {
            state.newConstruct = true;
        },
        setPreviewPhoto(state, payload) {
            state.dataConstruct.previewUrl = payload.url;
            state.previewFile = payload.file;
        },
        setConstruct(state, data) {
            state.dataConstruct = data;
            if (!state.newConstruct)
                state.dataConstruct.questions.forEach(temp => {
                    if (temp.answers.length === 0)
                        temp.answers = [];
                })
        },
        setTrueAnswer(state, payload) {
            if (payload.questionType === "multi") {
                state.dataConstruct.questions[payload.queId].answers[payload.answerId] = payload.answer;
            } else {
                for (let i = 0; i < state.dataConstruct.questions[payload.queId].answers.length; i++)
                    state.dataConstruct.questions[payload.queId].answers[i].correct = false;
                state.dataConstruct.questions[payload.queId].answers[payload.answerId].correct = true;
            }
        },
        setTypeAnswer(state, payload) {
            if (payload.type) {
                state.dataConstruct.questions[payload.index].questionType = payload.type;
            }
        },
        //CHANGE DATA CONSTRUCT
        CheckOpenAnswer(state) {
            for (let i = 0; i < state.dataConstruct.questions.length; i++) {
                if (state.dataConstruct.questions[i].questionType === 'open')
                    for (let j = 0; j < state.dataConstruct.questions[i].answers.length; j++)
                        state.dataConstruct.questions[i].answers[j].deleted = true;
            }


        },
        nullDataFile(state) {
            state.dataFile = [];
        },
        updateDataFile(state, payload) {
            if (payload.type === 'photo') state.dataConstruct.questions[payload.index].photoPath = payload.data
            if (payload.type === 'video') state.dataConstruct.questions[payload.index].videoPath = payload.data
        },
        addFileData(state, payload) {
            if (state.dataFile.length !== 0) {
                let add = 0;
                state.dataFile.forEach(file => {
                    if (file.index === payload.index) {
                        if (payload.type === 'photo')
                            file.photo = payload.data;
                        else
                            file.video = payload.data;
                    } else add++;
                })

                if (add >= state.dataFile.length) {
                    if (payload.type === 'photo')
                        state.dataFile.push({
                            photo: payload.data,
                            index: payload.index,
                            video: null
                        });
                    else
                        state.dataFile.push({
                            photo: null,
                            index: payload.index,
                            video: payload.data
                        });
                }
            } else {
                if (payload.type === 'photo')
                    state.dataFile.push({
                        photo: payload.data,
                        index: payload.index,
                        video: null
                    });
                else
                    state.dataFile.push({
                        photo: null,
                        index: payload.index,
                        video: payload.data
                    });
            }
        },
        deleteAnswer(state, index) {
            state.dataConstruct.questions[index.question].answers.splice(index.answer, 1)
        },
        addAnswer(state, index) {
            if (!state.newConstruct)
                state.dataConstruct.questions[index].answers.push(
                    {
                        id: null,
                        body: null,
                        deleted: false,
                        correct: false

                    }
                )
            else state.dataConstruct.questions[index].answers.push(
                {
                    deleted: false,
                    body: null,
                    correct: false
                }
            )
        },
        deleteQuestion(state, index) {
            state.dataConstruct.questions.splice(index, 1);
        },
        addQuestion(state) {
            if (!state.newConstruct)
                state.dataConstruct.questions.push({
                    id: null,
                    title: null,
                    body: null,
                    deleted: false,
                    videoPath: null,
                    photoPath: null,
                    questionType: null,
                    answers: [],
                })
            else {
                state.dataConstruct.questions.push({
                    title: null,
                    body: null,
                    videoPath: null,
                    photoPath: null,
                    deleted: false,
                    questionType: null,
                    answers: [],
                })
            }
        },
        removeFile(state, payload) {
            if (payload.data === 'photo')
                state.dataConstruct.questions[payload.index].photoPath = null
            else state.dataConstruct.questions[payload.index].videoPath = null
            state.dataFile.forEach(file => {
                if (file.index === payload.index) {
                    if (payload.data === 'photo')
                        file.photo = null;
                    else file.video = null;
                }
            })
        },
        //SAVE DATA CONSTRUCT
        saveTimerConstruct(state, data) {
            state.dataConstruct.maxPassingTimeSeconds = data * 60
        }
        ,
        savePreviewTitle(state, data) {
            state.dataConstruct.title = data;
        }
        ,
        savePreviewDescription(state, data) {
            state.dataConstruct.description = data;
        }
        ,
        saveQuestionTitle(state, payload) {
            state.dataConstruct.questions[payload.index].title = payload.data;
        }
        ,
        saveQuestionBody(state, payload) {
            state.dataConstruct.questions[payload.index].body = payload.data;
        }
        ,
        saveQuestionPhoto(state, payload) {
            state.dataConstruct.questions[payload.index].photoPath = payload.data
        }
        ,
        saveQuestionVideo(state, payload) {
            state.dataConstruct.questions[payload.index].videoPath = payload.data
        }
    },
    state: {
        dataConstruct: null,
        dataFile: [],
        previewFile: null,
        newConstruct: false,
        loader: false,
        countFile: 0,
        countLoadedFile: 0

    },
    getters: {
        loader(state) {
            return state.loader
        },
        getConstruct(state) {
            return state.dataConstruct;
        },
        dataFile(state) {
            return state.dataFile;
        }
    }
}