import {config} from "@/lib/config";

export const testing = {
    actions: {
        fetchComplitedTest({commit, getters}, category) {
					if(category ==='all'){
						category = ''
					}
            let xmlHttp = new XMLHttpRequest();
            let url =
                config.apiPrefix + "candidate/testing/passed?category=" + category;
            xmlHttp.open("GET", url, false);
            xmlHttp.setRequestHeader("X-Auth-Token", getters.token);
            try {
                xmlHttp.send();
                let {data} = JSON.parse(xmlHttp.response);
                commit('setActiveTesting', data);
            } catch (e) {
                console.log(e);
            }
        },

        fetchAvailableTest({commit, getters}, category) {
					if(category ==='all'){
						category = ''
					}
            let xmlHttp = new XMLHttpRequest();
            let url =
                config.apiPrefix + "candidate/testing/available?category=" + category;

            xmlHttp.open("GET", url, false);
            xmlHttp.setRequestHeader("X-Auth-Token", getters.token);
            try {
                xmlHttp.send();
                let {data} = JSON.parse(xmlHttp.response);
                commit('setActiveTesting', data)
            } catch (e) {
                console.log(e);
            }
        },
    },
    mutations: {

        setActiveTesting(state, data) {
            state.activeTesting = data;
        }
    },
    state: {
        activeTesting: null
    },
    getters: {
        activeTesting: state => state.activeTesting
    }
}