import { Stomp } from '@stomp/stompjs';
import Cookies from 'js-cookie';

export const webSocket = {
  state: {
    stompClient: null,
  },
  
  mutations: {
    // Инициализация и подключение WebSocket
    webSocketInit(state) {
			console.log(this.getters.user.id)
      //  const wsUri = "ws://localhost:8282/socket/chat?id=" + this.getters.user.id;
       const wsUri = "wss://theprofessional.ru:8086/staging/socket/chat?id=" + this.getters.user.id;
      const stompClient = Stomp.client(wsUri);

      stompClient.connect({}, frame => {
        console.log("Connected: " + frame);

        // Подписываемся на необходимый топик для получения сообщений
        stompClient.subscribe('/user/'+this.getters.user.id+'/queue/messages', message => {
					const response = JSON.parse(message.body);
					if((response.senderId===this.getters.userId || response.senderId === this.getters.user.id) && this.getters.activeComponent === true){
						this.commit("addNewMessages",response.message)
					}else{
						this.commit('setNotificationView', true);
						this.commit('setChatsCount',1);
					}
					let check = false;
					const newAllDialogues = this.getters.allDialogues.map(dialogue => {
						if (dialogue.userId === response.senderId) {
							check=true;
							return { ...dialogue, message: response.message.message, read: response.read, from: response.from};
						}
						else if(dialogue.userId === response.userId){
							check = true;
							return { ...dialogue, message: response.message.message, read: true, from: response.from};
						}
						else {
								return dialogue;
						}
				});
				if(check===false){
					this.dispatch("fetchAllNewDialogues")
				}
				this.commit("setAllDialogues", newAllDialogues);
					
        });

				stompClient.subscribe('/user/'+this.getters.user.id+'/queue/messages/read',idMessage =>{
					console.log('read message')
					let response = JSON.parse(idMessage.body);
					const newCurrentDialogue = this.getters.currentDialogue.map(currentMessage =>{
						if(currentMessage.id ===response){
							return {...currentMessage, read:true}
						}
						else{
							return currentMessage
						}
					})
					this.commit("setCurrentDialogue", newCurrentDialogue);

				})
				stompClient.subscribe('/user/'+this.getters.user.id+'/queue/friends/request',request =>{
				//	console.log('new request friend')
					let response = JSON.parse(request.body);
					console.log(response)
					this.commit("setNewFriendRequests", this.getters.user.newFriendRequests + 1);




					})
				

        state.stompClient = stompClient;
      }, error => {
        console.error("Connection error: " + error);
      });
    },
		sendRequestFriend(state, payload) {
			const message = {
				outcomeUserId: this.getters.user.id,
				outcomeUserRole: this.getters.role,
				userId: payload.id,
				userRole:payload.role,
			}
			if (state.stompClient && state.stompClient.connected) {
				state.stompClient.send("/app/friend/request", {}, JSON.stringify(message));
				console.log('Request sent');
			} 
			else {
				console.error('Stomp client is not connected.');
			}

	},
    sendMessage(state, payload) {
      const message = {
        writerId: payload.writerId,
        writerRole: payload.writerRole,
        message: payload.message, 
        userId: payload.userId, 
        userRole: payload.userRole, 
        files: payload.files
      };

      if (state.stompClient && state.stompClient.connected) {
       state.stompClient.send("/app/chat/message", {}, JSON.stringify(message));
        console.log('Message sent');
      } else {
        console.error('Stomp client is not connected.');
      }
    },
		readMessage(state, payload){
			console.log('Message read send');
			
			if (state.stompClient && state.stompClient.connected) {
				this.commit('setChatsCount',-1)
        state.stompClient.send("/app/chat/message/read", {}, JSON.stringify({messageId: payload.messageId, userId: this.getters.userId}));
        const newCurrentDialogue = this.getters.currentDialogue.map(currentMessage =>{
					if(currentMessage.id ===payload.messageId){
						if(this.getters.currentDialogue[this.getters.currentDialogue.length-1].id === currentMessage.id){
							let newAllDialogues = this.getters.allDialogues.map(dialogue=>{
									if(this.getters.userId===dialogue.userId){
										return {...dialogue, read:true};
									}
									else{
										return dialogue
									}
							});

							this.commit("setAllDialogues", newAllDialogues)
						}
						return {...currentMessage, read:true}
					}
					else{
						return currentMessage
					}
				})
				this.commit("setCurrentDialogue", newCurrentDialogue);
      } else {
        console.error('Stomp client is not connected.');
      }
		},

    socketClose(state) {
      if (state.stompClient) {
        state.stompClient.disconnect(() => {
          console.log('Disconnected');
          state.stompClient = null;
        });
      }
    }
  },

  actions: {
		sendRequestFriend({commit}, payload){
			commit("sendRequestFriend",{
				role: payload.role,
				id: payload.id
			})
		},
    sendMessage({ commit, getters,dispatch }, payload) {
			if (getters.fileInMessage.length !== 0)
			dispatch('updateFileInMessage');

      const id = Cookies.get('currentDialogueId'); 
      const body = {
        writerId: getters.user.id,
        writerRole: getters.role,
        message: payload.message, 
        userId: id, 
        userRole: payload.userRole, 
        files: getters.newFileInMessages 
      };
      
      console.log('Sending message:', body);
      commit('sendMessage', body); 
			if (payload.callback) {
					payload.callback();
			}
			commit('resetFileInMessages');
    }
  },

  getters: {
    
  }
};
