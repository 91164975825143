<template>
  <button class="test yellow">
    <div class="test-caption">
      <img alt=" " :src="avatar" class="test-caption__img">
      <div class="description">
        <p class="description__comany">{{ name ? textSymbolLimit(name, 20) : null }}<span
            class="description__comany-status"></span>
        <p class="description__format">{{ employer ? $t('components.account_worker.detailedTest.interview') :
          $t('components.account_worker.detailedTest.testing') }}</p>
      </div>
    </div>
    <div class="test-info">
      <div class="info-element">
        <p class="info-name" v-if="false">For:</p>
        <p class="info-text">{{ test.title ? textSymbolLimit(test.title, 20) : null }}</p>
      </div>
      <p class="test-info__description">{{ test.description ? textSymbolLimit(test.description, 60) : null }}</p>
    </div>
    <button class="test__start" @click="goToPage">{{ $t('components.account_worker.detailedTest.readMore') }}</button>
  </button>
</template>

<script>

import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
  name: "DetailedTest",
  props: ['test', 'name', 'avatar', 'employer'],
  methods: {
    textSymbolLimit,
    goToPage() {
      if (!this.employer) {
        this.$router.push({
          name: 'activeTest',
          params: { testingId: this.test.id }
        })
      } else {
        this.$router.push({
          name: 'resultInterview',
          params: { interviewId: this.test.id }
        });
      }
    }
  }
};
</script>


<style scoped>
.test {
  max-width: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  height: 225px;
  box-sizing: border-box;
  font-family: "Roboto";
  text-align: left;
}

.test-caption {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.test-caption__img {
  width: 53px;
  height: 53px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  object-fit: cover;
}

.description__comany {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  display: flex;
  align-items: center;
  gap: 6px;
}

.description__format {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.description__comany-status {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-top: 2px;
}

.test-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 0px;
  width: 100%;
}

.info-element {
  display: flex;
  max-width: 100%;
  text-align: left;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.info-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.info-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #3b4c68;
}

.test-info__description {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.test__start {
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  border-top: 1px solid rgba(231, 233, 236, 0.8);
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
}

/* Modificators */
.yellow .description__comany-status {
  background: #db940a;
}


.green .description__comany-status {
  background: #14c686;
}

.red .description__comany-status {
  background: #db0a0a;
}
</style>
