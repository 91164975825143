<template>
	<div class="answer">
		<p class="answer__caption">Enter answer</p>
		<textarea name="" id="" class="answer__textarea"
			placeholder="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ">
    </textarea>
	</div>
</template>

<script>
export default {
	name: "TestTextarea",
};
</script>

<style scoped>
.answer {
	width: 100%;
	box-sizing: border-box;
}

.answer__caption {
	margin-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #83939a;
}

.answer__textarea {
	width: 100%;
	resize: none;
	min-height: 135px;
	box-sizing: border-box;
	margin-top: 15px;
	padding: 15px 30px;
	background: #ffffff;
	border: 1px solid #dae9f3;
	border-radius: 16px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.78;
	color: #3b4c68;
}

@media (max-width: 700px) {
	.answer__textarea {
		padding: 10px 16px;
		font-size: 11px;
		line-height: 1.67;
	}
}
</style>