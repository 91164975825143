<template>
	<div id="app">
		<support-form></support-form>
		<router-view :key="$route.fullPath" />
	</div>
</template>

<script>


import axios from 'axios'
import SupportForm from "@/components/general/support/SupportForm.vue";
export default {
	components: { SupportForm },
	data() {
		return {
			posts: null,
			endpoint: "https://jsonplaceholder.typicode.com/posts/"
		}
	},

	methods: {
		getPosts() {
			axios.get(this.endpoint)
				.then(response => {
					this.posts = response.data;
				})
				.catch(error => {
					console.log("Error App.vue: " + error);
				});
		}
	}
}
</script>

<style scoped></style>
