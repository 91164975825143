<template>
  <div class="themes__group-element" v-if="displayCategory() || displayDefault()">
    <div class="themes__group-element-img" :style="{ backgroundImage: 'url(' + blogData.files[0] + ')' }">
      <span class="themes__group-element-img-designation">
        {{ blogData.categories[0] }}
      </span>
    </div>
    <p class="themes__group-element-date">
      {{ new Date(blogData.createDate).toISOString().slice(0, 10) }}
    </p>
    <h3 class="themes__group-element-title">
      {{ limit(blogData.title, 50) }}
    </h3>
    <p class="themes__group-element-text">
      {{ limit(blogData.body, 60) }}
    </p>
    <router-link :to="{ name: 'articles', params: { articlesId: this.blogData.id } }">
      <a href="" class="themes__group-element-read">{{ $t('components.landing.article.read') }}</a>
    </router-link>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
  name: "Article",
  data() {
    return {
      limit: textSymbolLimit,
      displayStatus: false,
    };
  },
  props: {
    blogData: {
      type: Object,
    },
    category: {
      type: String,
    },
  },
  methods: {
    displayCategory() {
      return this.blogData.category === this.category;
    },
    displayDefault() {
      return this.category === "default";
    },
  },
};
</script>

<style scoped>
.themes__group-element {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}



.themes__group-element-img {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 261px;
  border-radius: 5px;
  background-color: #009ed1;
}

.themes__group-element-img-designation {
  top: 19px;
  right: 19px;
  position: absolute;
  display: inline-block;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}

.themes__group-element-date {
  padding-top: 14px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6c757d;
}

.themes__group-element-title {
  padding-top: 14px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #495057;
}

.themes__group-element-text {
  padding-top: 14px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #6c757d;
}

.themes__group-element-read {
  padding-top: 17px;
  display: block;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 11px;
  line-height: 1.5;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #009ed1;
}

@media (max-width: 320px) {
  .themes__group-element {
    margin: 0;
  }
}
</style>
