<template>
	<div>
	<Header></Header>
	<main class="main">
		<div class="blog" :style="{ backgroundImage: 'url(' + fourBlogs[0].files[0] + ')' }">
		<div class="container">
			<p class="blog__text">{{ $t('pages.landing.blogPage.blog.title') }}</p>
			<div class="blog__topic">
			<p class="blog__topic-text topic-text">
				{{ fourBlogs[0].categories[0] }}
			</p>
			<p class="blog__profile-date mobile">
				{{ new Date(fourBlogs[0].createDate).toISOString().slice(0, 10) }}
			</p>
			</div>
			<h1 class="blog__title">
			{{ fourBlogs[0].title }}
			</h1>
			<div class="blog__profile">
			<div class="blog__profile-face"></div>
			<p class="blog__profile-name">{{ fourBlogs[0].author }}</p>
			<p class="blog__profile-date">
				{{ new Date(fourBlogs[0].createDate).toISOString().slice(0, 10) }}
			</p>
			</div>
			<div class="blog__interacrive">
		<router-link :to="{
				name: 'articles',
				params: { articlesId: fourBlogs[0].id },
			}" class="blog__interacrive-btn">
				{{ $t('pages.landing.blogPage.blog.readArticle') }}
			</router-link>
			<div class="blog__interacrive-randabout"></div>
			</div>
		</div>
		</div>
		<div class="container">
		<div class="themes">
			<h2 class="themes__title">{{ $t('pages.landing.blogPage.blog.mostPopularTopics') }}</h2>
			<div class="themes__nav">
			<ul class="themes__nav-list">
				<label class="themes__nav-list-elemnt" v-for="item in category" :key="item.id" :class="{ active: pickedCategory === item }">
				<span>{{ item }}</span>
				<input type="radio" class="themes__nav-list-elemnt-btn" v-model="pickedCategory" :value="item" v-on:click="chooseCategory(item)" />
				</label>
			</ul>
			</div>
			<div class="themes__group">
			<Article v-for="item in allBlog" :key="item.id" :blog-data="item"></Article>
			</div>
			<a v-if="display" class="themes-btnmore" @click="updateBlogData">{{ $t('pages.landing.blogPage.blog.moreNews') }}</a>
		</div>
		</div>
		<div class="tidings">
		<div class="container">
			<div class="tidings__wrapper">
			<router-link :to="{
				name: 'articles',
				params: { articlesId: fourBlogs[1].id },
			}">
				<div class="tidings__wrapper-mainarticle" :style="{ backgroundImage: 'url(' + fourBlogs[1].files[0] + ')' }">
				<div class="tidings__wrapper-mainarticle-topic">
					<p class="tidings__wrapper-mainarticle-topic-text">
					{{ fourBlogs[1].categories[0] }}
					</p>
				</div>
				<h4 class="tidings__wrapper-mainarticle-title tidings-title">
					{{ fourBlogs[1].title }}
				</h4>
				<p class="tidings__wrapper-mainarticle-text">
					{{ fourBlogs[1].body.substring(0, BODY_SYMBOL_LIMIT) }}...
				</p>
				<p class="tidings__wrapper-mainarticle-date date">
					{{ new Date(fourBlogs[1].createDate).toISOString().slice(0, 10) }}
					<span class="tidings__wrapper-mainarticle-date-span"></span>
				</p>
				</div>
			</router-link>
			<div class="tidings__wrapper-smallarticle">
				<InterestingPage :blogData="fourBlogs[2]"></InterestingPage>
				<InterestingPage :blogData="fourBlogs[3]"></InterestingPage>
			</div>
			</div>
		</div>
		</div>
	</main>
	<Footer></Footer>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";
import Article from "../../components/landing/Article.vue";
import InterestingPage from "../../components/landing/InterestingPage.vue";

export default {
	computed: {
		allBlog() {
			return this.$store.getters.allBlog;
		},
		category() {
			return this.$store.getters.category;
		},
		fourBlogs() {
			return this.$store.getters.fourBlogs;
		},
		display() {
			return this.$store.getters.display;
		},
	},

	components: { Header, Footer, Article, InterestingPage },

	name: "Blog",
	watch: {
		'$route.params.category': {
			immediate: true,
			handler(value) {
				this.pickedCategory = value;
			}
		}
	},
	data() {
		return {
			page: 1,
			pickedCategory: null,
			displayBlogDataButton: true,
			BODY_SYMBOL_LIMIT: 130,
			blogData: [],
		};
	},
	methods: {
		chooseCategory(item) {
			if (this.pickedCategory === item) {
				this.pickedCategory = null;
				this.page = 1;
				this.$store.dispatch("fetchDefaultBlogs", this.page);
			} else {
				this.pickedCategory = item;
				this.page = 1;
				this.$store.dispatch("fetchCategoryBlogs", {
					page: this.page,
					category: this.pickedCategory,
				});
			}
		},
		updateBlogData() {
			this.page += 1;
			if (this.pickedCategory !== null)
				this.$store.dispatch("fetchCategoryBlogs", {
					page: this.page,
					category: this.pickedCategory,
				});
			else this.$store.dispatch("fetchDefaultBlogs", this.page);
		},
	},

	beforeMount() {
		scroll(0, 0);
		this.page = 1;
		if(this.pickedCategory !=null){
			this.$store.dispatch("fetchCategoryBlogs", {
					page: this.page,
					category: this.pickedCategory,
				});
		}else{
			this.$store.dispatch("fetchDefaultBlogs", this.page);
			
		this.$store.commit("setFourBlogs");
		}


		this.$store.dispatch("fetchCategory");
	},
};
</script>

<style scoped>
.main {
	background: #ffffff;
}

/* Blog css */

.blog {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	box-sizing: border-box;
	height: 100%;
}

.blog__text {
	padding-top: 170px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 18px;
	line-height: 63.98400115966797px;
	color: #ffffff;
}

.blog__topic {
	padding-top: 43px;
	display: flex;
	justify-content: space-between;
}

.topic-text {
	text-transform: uppercase;
	text-align: center;
	padding: 4px 13px;
	background: rgba(255, 255, 255, 0.15000000596046448);
	border-radius: 8px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #ffffff;
}

.blog__topic-text {
	display: inline;
}

.blog__title {
	padding-top: 14px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 36px;
	max-width: 540px;
	color: #ffffff;
}

.blog__profile {
	padding-top: 42px;
	display: flex;
	align-items: center;
}

.blog__profile-face {
	background-image: url("~@/assets/img/news-page/blog/profile-face.svg");
	border-radius: 100%;
	object-fit: cover;
	object-position: center;
	width: 42px;
	height: 42px;
}

.blog__profile-name {
	padding-left: 17px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #ffffff;
}

.date {
	font-family: "Roboto";
	font-weight: 300;
	width: 169px;
	font-size: 13px;
	text-align: center;
	color: #ffffff;
}

.blog__profile-date {
	padding-left: 17px;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 13px;
	color: #ffffff;
}

.mobile {
	display: none;
}

.blog__interacrive {
	display: flex;
	flex-direction: column;
	padding-bottom: 72px;
}

.blog__interacrive-btn {
	display: inline-block;
	margin-top: 66px;
	width: 123px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	padding: 16px 22px;
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 7px;
	transition: opacity 1s ease;
}

.blog__interacrive-btn:hover {
	opacity: 0.5;
}

.blog__interacrive-randabout {
	display: none;
	margin-top: 86px;
	background-image: url("~@/assets/img/news-page/blog/Pagination.svg");
	width: 42px;
	height: 8px;
}

@media (max-width: 767px) {
	.blog__text {
		padding-top: 96px;
	}

	.blog__topic {
		padding-top: 33px;
	}

	.blog__profile-date {
		display: none;
	}

	.mobile {
		display: block;
	}

	.blog__interacrive {
		margin-top: 85px;
		padding-bottom: 55px;
	}

	.blog__interacrive-btn {
		margin-top: 35px;
		order: 2;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
	}

	.blog__interacrive-randabout {
		order: 1;
		margin: 0 auto;
	}
}

/* /Blog css */

/* /Themes  css */
.themes {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.themes__title {
	padding-top: 113px;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 35px;
	line-height: 43px;
	color: #000000;
}

.themes__nav {
	padding-top: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.themes__nav-list :not(:last-child) {
	margin-right: 38px;
}

.themes__nav-list :hover {
	color: #009ed1;
}

.themes__nav-list-elemnt {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 12px;
	line-height: 25px;
	text-align: left;
	color: #495057;
	transition: color 1s ease;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.themes__nav-list-elemnt-btn {
	color: #495057;
	transition: color 1s ease;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.themes__nav-list-elemnt-btn:hover {
	color: #009ed1;
}

.active {
	color: #009ed1;
}

.themes__nav-more:hover {
	color: #009ed1;
}

.themes__nav-more {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 12px;
	line-height: 25px;
	color: #495057;
	transition: color 1s ease;
}

.themes__group {
	padding-bottom: 88px;
	margin-top: 45px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 30px 10px;
}

.themes-btnmore {
	cursor: pointer;
	display: block;
	margin: 0px auto 60px auto;
	padding: 24px 20px;

	font-family: "Roboto";
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #009ed1;
	width: 163px;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.themes-btnmore:hover {
	opacity: 0.5;
}

@media (max-width: 1210px) {
	.themes__group {
		grid-template-columns: repeat(3, 1fr);
	}

	.themes__nav {
		position: absolute;
		display: flex;
		overflow-x: scroll;
	}

	.themes__nav-list {
		display: flex;
		flex-shrink: 0;
	}

	.themes__group {
		margin-top: 126px;
	}
}

@media (max-width: 930px) {
	.themes__group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 814px) {
	.themes {
		padding-bottom: 43px;
	}

	.themes__nav {
		justify-content: left;
		width: 100%;
		margin-right: 38px;
	}

	.themes__nav-list {
		margin-right: 28px;
	}

	.themes__group {
		padding-bottom: 38px;
	}

	.themes-btnmore {
		width: 300px;
		padding: 13px 0;
		box-sizing: border-box;
	}

	.themes__title {
		padding-top: 36px;
	}

	.themes__group {
		margin-top: 73px;
	}

	.themes__nav {
		padding-top: 16px;
	}
}

@media (max-width: 680px) {
	.themes__group {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}
}

/* /Themes  css */

/* /Tidings css */
.tidings {
	background-color: #fafafb;
}

.tidings__wrapper-mainarticle {
	display: flow-root;
	margin-top: 113px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-sizing: border-box;
	border-radius: 8px;
	margin-bottom: 41px;
}

.tidings__wrapper-mainarticle-title {
	margin: 24px auto 0 auto;
	max-width: 578px;
	text-align: center;
}

.tidings__wrapper-mainarticle-topic {
	margin-top: 178px;
	display: flex;
	justify-content: center;
}

.tidings__wrapper-mainarticle-topic-text {
	display: inline;
	text-align: center;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #ffffff;
	padding: 5px 10px;
	background: rgba(255, 255, 255, 0.15000000596046448);
	border-radius: 8px;
}

.tidings-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 36px;
	color: #ffffff;
}

.tidings__wrapper-mainarticle-text {
	margin: 17px auto 0 auto;
	max-width: 420px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #e5e5e5;
}

.tidings__wrapper-mainarticle-date {
	display: block;
	margin: 39px auto 178px auto;
}

.date {
	position: relative;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #e5e5e5;
}

.tidings__wrapper-mainarticle-date-span {
	display: inline-block;
	position: absolute;
	content: "";
	width: 30px;
	height: 1px;
	background-color: #e5e5e5;
	top: -15px;
	left: 70px;
}

.tidings__wrapper-smallarticle {
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	margin-bottom: 105px;
}

@media (max-width: 1020px) {
	.tidings__wrapper-mainarticle-topic {
		margin: 120px auto 0 auto;
	}

	.tidings__wrapper-mainarticle-date {
		margin: 39px auto 120px auto;
	}

	.tidings__wrapper-smallarticle {
		flex-direction: column;
		margin-bottom: 65px;
	}
}

@media (max-width: 680px) {
	.tidings__wrapper-mainarticle {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	.tidings__wrapper-mainarticle-topic {
		margin: 77px auto 0 auto;
	}

	.tidings__wrapper-mainarticle-date {
		margin: 39px auto 76px auto;
	}

	.tidings__wrapper-mainarticle-title {
		max-width: 250px;
		font-size: 18px;
	}

	.tidings__wrapper-mainarticle-text {
		max-width: 210px;
	}

	.tidings-title {
		font-size: 18px;
	}

	.tidings__wrapper-mainarticle-date-span {
		display: none;
	}

	.tidings__wrapper-mainarticle-date {
		margin: 32px auto 76px auto;
	}

	.tidings__wrapper-smallarticle {
		margin-bottom: 47px;
	}

	.themes__nav-list {
		margin-right: 0;
	}
}

/* /Tidings css */
</style>
themes__nav