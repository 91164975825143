<template>
	<div class="vacancy">
		<button @click="$router.push({ name: 'vacancyDashboard', params: { vacancyId: vacancy.id } })"
			class="vacancy-caption">
			<img :src="vacancy.companyAvatarUrl ? vacancy.companyAvatarUrl : vacancy.avatarUrl" alt=" "
				class="vacancy-caption__img">
			<div class="caption-statistics">
				<div class="statistics-wrapper "
					:class="{ 'green': vacancy.vacancyRate >= 80, 'yellow': vacancy.vacancyRate < 80 && vacancy.vacancyRate >= 30, 'red': vacancy.vacancyRate < 30 }">
					<p class="statistics__text">{{ $t('components.account_worker.detailedVacancy.necessary') }}</p>
					<div class="statistics-line">
						<div class="statistics-line__filling" :style="{ width: vacancy.vacancyRate + '%' }"></div>
					</div>
					<p class="statistics__number">
						<svg class="statistics__number-icon" width="11" height="10" viewBox="0 0 11 10" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
								fill="#0000" />
						</svg>
						{{ vacancy.vacancyRate }}
					</p>
				</div>
				<div class="statistics-wrapper"
					:class="{ 'green': workerRate >= 80, 'yellow': workerRate < 80 && workerRate >= 30, 'red': workerRate < 30 }">
					<p class="statistics__text">{{ $t('components.account_worker.detailedVacancy.your') }}</p>
					<div class="statistics-line your-line">
						<div class="statistics-line__filling" :style="{ width: workerRate + '%' }"></div>
					</div>
					<p class="statistics__number">
						<svg class="statistics__number-icon" width="11" height="10" viewBox="0 0 11 10" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
								fill="#0000" />
						</svg>
						{{ workerRate }}
					</p>
				</div>
			</div>
		</button>
		<div class="vacancy-description">
			<h4 class="vacancy-description__title">{{ vacancy.vacancyName }}</h4>
			<p class="vacancy-description__text">
				{{ vacancy.vacancyDescription ? textSymbolLimit(vacancy.vacancyDescription, 50) : null }}</p>
		</div>
		<div class="vacancy-about">
			<button @click="writeMessage" class="vacancy-about__element">
				<img src="~@/assets/img/vacancy/chat-logo.svg" alt="" class="vacancy-about__img">
				<p class="vacancy-about__text">{{ $t('components.account_worker.detailedVacancy.chat') }}</p>
			</button>
			<button @click="response" class="vacancy-about__element">
				<img src="~@/assets/img/vacancy/test-logo.svg" alt="" class="vacancy-about__img">
				<p class="vacancy-about__text">{{ $t('components.account_worker.detailedVacancy.tests') }}</p>
			</button>
			<button @click="goToProfile" class="vacancy-about__element">
				<img src="~@/assets/img/vacancy/read-more-logo.svg" alt="" class="vacancy-about__img">
				<p class="vacancy-about__text">{{ $t('components.account_worker.detailedVacancy.more') }}</p>
			</button>
		</div>
		<button @click="toggleFavoriteList" class="favorite " :class="{ 'favorite--active': localIsFavorite }">
			<svg data-v-1171bf72="" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path data-v-1171bf72=""
					d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
					stroke="#009ED1">
				</path>
			</svg>
		</button>
	</div>
</template>

<script>
import { textSymbolLimit } from "../../../../../lib/textSymbolLimit";
import Cookies from "js-cookie";

export default {
	name: "DetailedVacancy",
	props: ['vacancy', 'workerRate', 'isFavorite'],
	data() {
		return {
			textSymbolLimit,
			localIsFavorite: this.isFavorite,
		}
	},
	methods: {
		toggleFavoriteList() {
			this.localIsFavorite = !this.localIsFavorite;
			if (this.localIsFavorite) {
				this.$store.dispatch("addFavoriteVacancy", {
					token: this.$store.getters.token,
					vacancy: this.vacancy,
				})
			}
			else {
				this.$store.dispatch("removeFavoriteVacancy", {
					token: this.$store.getters.token,
					vacancy: this.vacancy,
				})
			}
		},
		writeMessage(e) {
			e.stopPropagation();
			Cookies.set('currentDialogueId', this.vacancy.employerId);
			Cookies.set('currentDialogueName', this.vacancy.companyName);
			Cookies.set('currentDialogueAvatar', this.vacancy.companyAvatarUrl);
			Cookies.set('currentDialogueRole', 'employer');
			Cookies.set('currentUserRole', 'employer');
			console.log(this.$store.getters.userRole);
			this.$router.push({ name: 'allChats' });
		},
		response(e) {
			e.stopPropagation();
			this.$router.push({ name: 'employerProfile', params: { summaryId: this.vacancy.employerId }, hash: '#testing' })
		},
		goToProfile(e) {
			e.stopPropagation();
			this.$router.push({ name: 'employerProfile', params: { summaryId: this.vacancy.employerId } })
		},
	}
};
</script>


<style scoped>
.vacancy {
	max-width: 100%;
	width: 100%;
	padding: 14px;
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	height: 205px;
	box-sizing: border-box;
	position: relative;
}

.favorite {
	right: 0px;
}

.favorite svg {
	width: 14px;
	height: 19px;
}

.vacancy-caption {
	max-width: 100%;
	width: 100%;
	display: flex;
	gap: 10px;
	align-items: center;
}

.vacancy-caption__img {
	width: 53px;
	height: 53px;
	background: #c4c4c4;
	border-radius: 10px;
	display: flex;
	flex-shrink: 0;
	object-fit: cover;
}

.caption-statistics {
	display: flex;
	flex-direction: column;
	gap: 7px;
	max-width: 100%;
	width: 100%;
}

.statistics-wrapper {
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 100%;
}

.statistics__text {
	font-family: "SFUIDisplay";
	font-size: 12px;
	line-height: 1.5;
	color: rgba(23, 23, 37, 0.5);
}

.statistics-line {
	margin-left: 6px;
	display: flex;
	max-width: 100%;
	width: 100%;
	height: 4px;
	border-radius: 200px;
}

.your-line {
	margin-left: 21px;
}

.statistics-line__filling {
	max-width: 100%;
	height: 100%;
	border-radius: 200px;

}

.statistics__number {
	display: flex;
	align-items: center;
	font-family: "Roboto";
	flex-shrink: 0;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	width: 38px;
	margin-left: 6px;
}

.statistics__number-icon {
	margin-right: 6px;
}


.vacancy-description {
	margin-top: 16px;
	max-width: 100%;
	width: 100%;
}

.vacancy-description__title {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.1px;
	text-align: left;
	color: #000000;
}

.vacancy-description__text {
	text-align: left;
	margin-top: 4px;
	font-weight: 500;
	font-family: "Roboto";
	font-size: 10px;
	line-height: 1.4;
	color: rgba(23, 23, 37, 0.4000000059604645);
}

.vacancy-about {
	max-width: 100%;
	width: 100%;
	margin-top: auto;
	display: flex;
	justify-content: space-between;
}

.vacancy-about__element {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.vacancy-about__text {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 10px;
	line-height: 1;
	color: rgba(23, 23, 37, 0.30000001192092896);
}

/* Modificators */
.yellow .statistics-line {
	background: rgba(220, 149, 11, 0.20000000298023224);
}

.yellow .statistics-line__filling {
	background: #db940a;
}

.yellow path {
	fill: #db940a;
}

.yellow .statistics__number {
	color: #db940a;
}

.green .statistics-line {
	background: rgba(20, 198, 134, 0.20000000298023224);
}

.green .statistics-line__filling {
	background: #14c686;
}

.green path {
	fill: #14c686;
}

.green .statistics__number {
	color: #14c686;
}

.red .statistics-line {
	background: rgba(220, 11, 11, 0.20000000298023224);
}

.red .statistics-line__filling {
	background: #db0a0a;
}

.red path {
	fill: #db0a0a;
}

.red .statistics__number {
	color: #db0a0a;
}

@media (max-width: 900px) {
	.favorite {
		right: -7px;
	}

}
</style>