import Vue from "vue";

let params = {
    position: "top-center",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}


export function successNotification(app, msg) {
    Vue.$toast.success(msg,params);
}

export function errorNotification(app, msg) {
    Vue.$toast.error(msg, params);
}

export function limitWarning(app, msg) {
    params.timeout=null;
    Vue.$toast.warning(msg, params)
    params.timeout = 3000;
}

export function warningNotification(app, msg) {
    Vue.$toast.warning(msg, params);
}

export function infoNotification(app, msg) {
    Vue.$toast.info(msg, params);
}

export function t(app, msg) {
    Vue.$toast.info(msg, params);
}