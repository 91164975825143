import {config} from "@/lib/config";

export const chatCommunity = {
    actions: {
        fetchDialogueWithCommunity({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/chats/' + payload.id + '?page=' + payload.page + '&limit=' + payload.limit;
            req.open('GET', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            try {
                req.send();
                const response = JSON.parse(req.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.currentData) {
                    commit('addMessages', response.data);
                } else {
                    commit('setMessages', response.data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        sendMessagesWithCommunity(_, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/chats';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify({communityId: payload.id, message: payload.message}));
                payload.callback();
            } catch (e) {
                console.log(e);
            }
        },
    },
    mutations: {
        setMessages(state, data) {
            state.communityMessages = data;
        },
        addMessages(state, data) {
            if (data.length !== 0)
                for (let i = 0; i < data.length; i++) {
                    let add = true;
                    for (let j = 0; j < state.communityMessages.length; j++) {
                        if (state.communityMessages[j].id === data[i].id)
                            add = false;
                    }
                    if (add) {
                        state.communityMessages.push(data[i])
                    }
                }

        },
        resetCommunityMessagesPage(state) {
            state.communityMessagesPage = 1;
        },
        upCommunityMessagesPage(state) {
            state.communityMessagesPage++;
        }

    },
    state: {
        communityMessages: null,
        communityMessagesPage: null,
        communityMessagesLimit: 20,
        communityMessagesAvatar: null,
        communityMessagesName: null
    },
    getters: {
        getCommunityMessages(state) {
            return state.communityMessages;
        },
        getCommunityMessagesLimit(state) {
            return state.communityMessagesLimit;
        },
        getCommunityMessagesPage(state) {
            return state.communityMessagesPage
        },
        getCommunityMessagesInfo(state) {
            return {name: state.communityMessagesName, avatar: state.communityMessagesAvatar}
        }
    }
}