import {config} from "@/lib/config";
import {logoutWithToken} from "@/lib/logoutWithToken";

export const vacancies = {
    state: {
        searchVacanciesParams: null,
        vacancies: []
    },

    mutations: {
        setSearchVacanciesParams(state, data) {
            state.searchVacanciesParams = data;
        },
        setVacancies(state,data){
            state.vacancies = data;
        },
        setCompanyCard(state, data) {
            data = data.map(item=>({
                id:item.id,
                vacancyName:item.name,
                vacancyRate:item.rate,
                companyAvatarUrl:item.avatarUrl,
                vacancyDescription:item.profession,
                isCompany:true
            }))
            state.vacancies = data;
        }

    },

    actions: {
        setNewSearchVacanciesParams({commit}, searchVacanciesParams) {
            commit('setSearchVacanciesParams', searchVacanciesParams);
        },

				getVacancies({commit, state}) {
					let xmlHttp = new XMLHttpRequest();
					let url = config.apiPrefix + "vacancy/search";
					let form = state.searchVacanciesParams;
			
					// Функция для создания строки запроса
					function createQueryString(params) {
						if (!params) { // Проверяем, что params не null и не undefined
							return ''; // Возвращаем пустую строку, если нет параметров
					}
							return Object.keys(params)
									.filter(key => params[key] != null) // Исключаем null и undefined значения
									.map(key => {
											let value = params[key];
											// Преобразуем массивы в строку с повторяющимися ключами
											if (Array.isArray(value)) {
													return value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
											} else {
													return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
											}
									}).join('&');
					}
			
					let queryString = createQueryString(form);
					let finalUrl = `${url}?${queryString}`;
			
					xmlHttp.open('GET', finalUrl, false);
			
					try {
							xmlHttp.send();
							logoutWithToken(xmlHttp.status, this); // Убедитесь, что функция logoutWithToken правильно обрабатывает статус ответа
							if (xmlHttp.status === 200) {
									let response = JSON.parse(xmlHttp.response);
									commit('setVacancies', response.data);
							} else {
									console.error('Error fetching vacancies:', xmlHttp.statusText);
							}
					} catch (e) {
							console.log(e);
					}
			}
			
			
			
    },

    getters: {
        vacancies: (state) => state.vacancies,
        searchVacanciesParams: (state) => state.searchVacanciesParams,
    }
};