<template>
  <div class="news">
    <div class="news-caption">
      <img src="../../../assets/img/news/new-level.svg" alt="" class="news-caption__icon">
      <div class="caption-info">
        <h3 class="caption-info__title">A new community has been created</h3>
          <p class="caption-info__text">USER NAME created a new user name in which there will be a lot of interesting information</p>
      </div>
      <time class="news-caption__time">00:00</time>
    </div>
    <div class="news-filling">
      <img src="../../../assets/img/news/apple.png" alt="" class="news-persone__face">
      <div class="statistics">
        <div class="statistics-last red">
          <p class="statistics__name">It was</p>
          <div class="statistics-line" >
            <div class="statistics-line__filling" style="width: 50%"></div>
          </div>
          <p class="statistics__star">
            <svg class="statistics__star-icon" width="11" height="10" viewBox="0 0 11 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
                fill="#0000"/>
            </svg>
            12
          </p>
        </div>
        <div class="statistics-next green">
          <p class="statistics__name">It became </p>
          <div class="statistics-line">
            <div class="statistics-line__filling" style="width: 20%;"></div>
          </div>
          <p class="statistics__star">
            <svg class="statistics__star-icon" width="11" height="10" viewBox="0 0 11 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
                fill="#0000"/>
            </svg>
            99
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LastNewsLevelUp",

};


</script>

<style scoped>
.news {
  font-family: "Roboto";
  padding: 18px 12px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  position: relative;
}
.news-caption {
  display: flex;
  align-items: flex-start;
}
.news-caption__icon {
  background: #f7f7f7;
  width: 53px;
  height: 53px;
  object-fit: cover;
  display: flex;
  border-radius: 8px;
}
.caption-info {
  margin-left: 16px;
  max-width: 100%;
}
.caption-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #3b4c68;
}
.caption-info__text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(23, 23, 37, 0.4000000059604645);
}
.news-caption__time {
  max-width: 97px;
width: 100%;
  margin-left: auto;
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}
.news-filling {
  padding: 30px 0 0 0;
  display: flex;
  align-items: center;
}
.news-community{
  max-width: 300px;
  display: flex;
  align-items: flex-start;
}
.community-description{
  margin-left: 16px;
}
.description-name{
  display: flex;
  align-items: center;
}
.description-name__text{
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}
.description-name__status{
  margin-left: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  padding: 5px 6px 4px 6px;
}
.news-persone__face {
  background: #000000;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  object-fit: cover;
}
.description__direction{
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #009ed1;
}
.description__text{
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: rgba(23, 22, 37, 0.5);
}
.news-request{
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #009ed1;
  display: flex;
  align-items: center;
}
.statistics{
  max-width: 100%;
  width: 100%;
  margin-left: 16px;
}
.statistics-next{
  display: flex;
  margin-top: 13px;
  max-width: 100%;
  width: 100%;
  align-items: center;
}
.statistics__name{
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(23, 23, 37, 0.4000000059604645);
  
}
.statistics-last{

  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
}
.news-filling{

  display: flex;
  align-items: center;
}
.statistics-line{
  max-width: 100%;
  width: 100%;
  margin-left: 9px;

  height: 4px;
  border-radius: 200px;
}
.statistics-line__filling{
  height: 100%;
  border-radius: 200px;
}

.statistics__star{
  display: flex;
  margin-left: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
}
.statistics__star svg{
  margin-right: 4px;
}
@media (max-width: 1770px) {
  .news-request{
    margin: 20px auto 0 auto;
  }
}
@media (max-width: 950px) {
  .news-request{
    margin: 0 0 0 auto;
  }
}

@media (max-width: 720px) {
  .news {
    padding: 12px;
  }
  .caption-info {
    margin-left: 14px;
    max-width: 170px;
  }
  .caption-info__title {
    font-size: 16px;
  }
  .caption-info__text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.4;
  }
  .news-filling {
    padding-top: 20px;
  }

  .news-request{
    margin: 20px auto 0 auto;
  }
  .news-persone__face {
    width: 48px;
    height: 48px;
  }
  .community-description{
    margin-left: 14px;
  }
  .description-name__text{
    font-size: 14px;
  }
  .description-name__status{
    font-size: 10px;
  }
  .description__direction{
    font-size: 10px;
  }
  .description__text{
    font-size: 10px;
  }
  .news-request{
    font-size: 12px;
  }
}

/* Modificators */
.status-close{
  background: #ffe3e3;
  color: #b53151;
}

.yellow .statistics-line {
  background: rgba(220, 149, 11, 0.20000000298023224);
}

.yellow .statistics-line__filling {
  background: #db940a;
}

.yellow path {
  fill: #db940a;
}

.yellow .statistics__star {
  color: #db940a;
}

.green .statistics-line {
  background: rgba(20, 198, 134, 0.20000000298023224);
}

.green .statistics-line__filling {
  background: #14c686;
}

.green path {
  fill: #14c686;
}

.green .statistics__star {
  color: #14c686;
}

.red .statistics-line {
  background: rgba(220, 11, 11, 0.20000000298023224);
}

.red .statistics-line__filling {
  background: #db0a0a;
}

.red path {
  fill: #db0a0a;
}

.red .statistics__star {
  color: #db0a0a;
}
</style>
