<template>
  <div class="company-wrapper">
    <router-link :to="{ name: 'aboutWorker', params: { summaryId: this.summary.id } }" class="company">
      <div class="company-container1">
        <div class="company-container1-company">
          <div class="company-container1-company-icon">
            <img :src="summary.avatarUrl" alt="" class="company-container1-company-icon-img" />
          </div>
          <div class="company-container1-company-name">
            <p class="
              company-container1-company-name-disignation
            ">
              {{ $t('components.account_company.separateCompanyResume.name') }}
            </p>
            <p class="company-container1-company-name-gang line-clamp-1">
              {{ summary.name ? summary.name : $t('components.account_company.separateCompanyResume.noName') }}
            </p>
            <div class="
              company-container2-rating-part
              sumarry-mobile
            ">
              <p class="company-container2-rating-part-number">
                {{ summary.rate ? summary.rate : 0 }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="company-container2 desctop-recomendation">
        <div class="company-container2-rating">
          <p class="company-container2-rating-name">
            {{ $t('components.account_company.separateCompanyResume.rating') }}
          </p>

          <div class="company-container2-rating-part">
            <p class="company-container2-rating-part-number">
              {{ summary.rate ? summary.rate : 0 }}
            </p>
          </div>
        </div>
      </div>
      <div class="company-container3">
        <div class="company-container3-specification">
          <h3 class="company-container3-specification-title">
            {{ $t('components.account_company.separateCompanyResume.profession') }}
          </h3>
          <p class="company-container3-specification-text line-clamp-1">
            {{
              summary.specialization ? summary.specialization :
                $t('components.account_company.separateCompanyResume.undefined')
            }}
          </p>
        </div>
      </div>
      <div class="company-container4">
        <div class="company-container4-place">
          <p class="
            company-container4-place-town
            desctop-recomendation
          ">
            {{ $t('components.account_company.separateCompanyResume.city') }}
          </p>
          <p class="company-container4-place-name line-clamp-1">
            {{ summary.city ? summary.city : $t('components.account_company.separateCompanyResume.undefined') }}
          </p>
        </div>
      </div>
      <div class="company-container5">
        <div class="company-container5-price">
          <p class="
            company-container5-price-text
            desctop-recomendation
          ">
            {{ $t('components.account_company.separateCompanyResume.expectedSalary') }}
          </p>
          <p class="company-container5-price-number line-clamp-1">
            {{ salary }}
          </p>
        </div>
      </div>
    </router-link>
    <button @click="addToFavorite = !addToFavorite" class="favorite " :class="{ 'favorite--active': addToFavorite }"
      v-if="false">
      <svg viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
          stroke="#009ED1" />
      </svg>
    </button>
  </div>
</template>

<script>

import { convertSalary } from "@/lib/values";

export default {

  name: "SeparateCompanyResume",
  computed: {
    salary() {
      return this.convertSalary(this.summary?.salary ? this.summary?.salary : 0, this.summary?.candidateCurrency ? this.summary?.candidateCurrency.toUpperCase() : "usd", this.$store.getters?.user?.expectedSalaryCurrency ? this.$store.getters?.user?.expectedSalaryCurrency.toUpperCase() : 'usd')
    },
  },
  props: {
    summary: {
      type: Object,
    },
  },
  data() {
    return {
      addToFavorite: false
    }
  },
  methods: {
    convertSalary
  }
};
</script>


<style scoped>
.company-wrapper {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.company {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  display: grid;
  grid-template-columns: 20% 10% 25% 10% 15%;
  padding: 20px 30px;

  gap: 5%;
}

.company-container1-company {
  display: flex;
  align-items: center;
}

.company-container1-company-icon {
  width: 64px;
  height: 64px;
  display: flex;
  flex-shrink: 0;
}

.company-container1-company-icon-img {
  background: #000000;
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.company-container1-company-name {
  margin-left: 32px;
  max-width: 100%;
  width: 100%;
}

.company-container1-company-name-disignation {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.company-container1-company-name-gang {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.company-container2-rating-name {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.company-container2-rating-part {
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  margin-top: 4px;
  width: 57px;
}

.company-container2-rating-part-number {
  background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: 9px;
  padding: 0px 9px 0px 25px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #14c686;
}

.company-container3-specification-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #171625;
}

.company-container3-specification-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.company-price-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.company-container4-place-name {
  padding-left: 18px;
  background-image: url("~@/assets/img/recommendation-page/recommendation/place.svg");
  background-position: 0;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.company-container4-place-town {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}


.company-container5-price-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.company-container5-price-number {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.sumarry-mobile {
  display: none;
}

.recomedation__text-sumarry-mobile {
  display: none;
}

.favorite {
  position: absolute;
  padding: 10px;
  right: 23px;
  top: -10px;
}

.favorite svg {
  width: 17px;
  height: 24px;
  transition: all 0.5s ease;
}

.favorite--active svg path {
  fill: #009ED1;
}

@media (max-width: 1190px) {
  .sumarry-mobile {
    display: block;
  }

  .desctop-recomendation {
    display: none;
  }


  .company {
    padding: 0;
    max-width: 100%;
    width: 100%;
    gap: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .company-container1 {
    width: 100%;
    flex-basis: 100%;
  }

  .company-container2 {
    width: 100%;
    flex-basis: 100%;
  }

  .company-container3 {
    width: 100%;
    flex-basis: 100%;
  }

  .company-container4 {
    width: 100%;
    flex-basis: 100%;
  }

  .company-container5 {
    margin-top: auto;
    width: 100%;
    flex-basis: 100%;
  }

  .company-container1-company {
    padding: 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  .company-container2-rating-part {
    margin-top: 0;
  }

  .company-container3-specification {
    padding: 11px 20px 20px 20px;
  }

  .company-container4-place {
    padding: 0 20px 11px 20px;
  }

  .company-container5-price {
    border-top: 1px solid #f1f1f5;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    padding: 12px 20px 20px 20px;
  }

  .company-container1-company-icon {
    margin-top: 3px;
  }

  .company-container1-company-name-disignation {
    font-size: 12px;
  }

  .company-container2-rating-part {
    margin-top: 2px;
  }

  .company-container3-specification-title {
    font-size: 15px;
  }

  .company-container3-specification-text {
    font-size: 13px;
  }

  .favorite {
    top: 0;
    padding: 0px 20px 20px 20px;
    right: 0px;
  }

  .line-clamp-1 {
    -webkit-line-clamp: 3;
  }
}
</style>