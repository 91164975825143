<template>
	<div>
		<Header></Header>
		<main class="main">
			<div class="caption">
				<div class="container caption-container">
					<button @click="$router.go(-1)" class="caption__back mobile">
						<div class="caption__back-img"></div>
					</button>
					<h1 class="caption__title mobile">
						{{ vacancy.companyName ? vacancy.companyName : "undefined" }}
					</h1>
					<div class="caption__nomination">
						<div class="caption__nomination-imgcontainer">
							<img :src="vacancy.companyAvatarUrl" alt="Company"
								class="caption__nomination-imgcontainer-img" />
						</div>
						<div class="caption__nomination-name">
							<p class="caption__nomination-name-designation caption-name">
								{{ $t('pages.companyPage.companyLabel') }}
							</p>
							<div class="caption__nomination-name-company">
								<p class="caption__nomination-name-company-name caption-designation">
									{{ vacancy.companyName ? vacancy.companyName : "undefined" }}
								</p>
								<div class="caption__nomination-name-company-rating">
									<p class="caption__nomination-name-company-rating-tally">
										{{ vacancy.companyRate ? vacancy.companyRate : "undefined" }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<h1 class="caption__title desctop">
						{{ vacancy.vacancyName ? vacancy.vacancyName : "undefined" }}
					</h1>
					<div class="caption__info">
						<div class="caption__info-chart">
							<p class="caption__info-chart-name caption-name">{{ $t('pages.companyPage.scheduleLabel') }}
							</p>
							<p class="caption__info-chart-schedule caption-designation">
								{{ vacancy.employType ? vacancy.employType : "undefined" }}
							</p>
						</div>
						<div class="caption__info-experience">
							<p class="caption__info-experience-name caption-name">{{
								$t('pages.companyPage.experienceLabel') }}</p>
							<p class="caption__info-experience-time caption-designation">
								{{ vacancy.workExperience ? vacancy.workExperience : "undefined" }}
							</p>
						</div>
						<div class="caption__info-education">
							<p class="caption__info-education-name caption-name">{{
								$t('pages.companyPage.educationLabel') }}</p>
							<p class="caption__info-education-level caption-designation">
								{{ vacancy.education ? vacancy.education : "undefined" }}
							</p>
						</div>
						<div class="caption__info-price">
							<p class="caption__info-price-name caption-name">{{ $t('pages.companyPage.wageLabel') }}</p>
							<p class="caption__info-price-payment">
								{{ vacancy.payFrom ? vacancy.payFrom + "$" : "undefined" }}
							</p>
						</div>
						<div class="caption__info-views">
							<p class="caption__info-views-name">{{ $t('pages.companyPage.viewsLabel') }}</p>
							<p class="caption__info-views-number">{{ $t('pages.companyPage.viewsCount') }}</p>
						</div>
					</div>
				</div>
				<picture class="caption__after">
					<source :srcset="vacancy.previewImgUrl" media="(max-width: 700px)" />
					<img :src="vacancy.previewImgUrl" alt="AboutCompany" class="caption__after-img" />
				</picture>
			</div>
			<div class="warp">
				<div class="container caption-container">
					<div class="warp__document">
						<p class="warp__document-text">{{ vacancy.description }}</p>
					</div>
					<div class="caption__nomination second-nomination">
						<div class="caption__nomination-imgcontainer">
							<img :src="vacancy.companyAvatarUrl" alt="Company"
								class="caption__nomination-imgcontainer-img" />
						</div>
						<div class="caption__nomination-name">
							<p class="caption__nomination-name-designation caption-name">
								{{ $t('pages.companyPage.companyLabel') }}
							</p>
							<div class="caption__nomination-name-company">
								<p class="caption__nomination-name-company-name caption-designation">
									{{ vacancy.companyName ? vacancy.companyName : "undefined" }}
								</p>
								<div class="caption__nomination-name-company-rating">
									<p class="caption__nomination-name-company-rating-tally">
										{{ vacancy.companyRate ? vacancy.companyRate : "undefined" }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="warp__map">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.75567029275!2d49.144507441506384!3d55.784953880510315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415ead2213d91e8b%3A0x2d529e14c58e372!2z0JLRgdC1INC00LvRjyDQvNGD0LfRi9C60LDQvdGC0L7Qsg!5e0!3m2!1sru!2sru!4v1642703589055!5m2!1sru!2sru"
						class="warp__map-card" allowfullscreen="" loading="lazy"></iframe>
					<div class="warp__map-address">
						<h3 class="warp__map-address-title">{{ $t('pages.companyPage.addressTitle') }}</h3>
						<p class="warp__map-address-place">
							{{ $t('pages.companyPage.addressText') }}
						</p>
					</div>
				</div>
			</div>
			<div class="similar">
				<div class="container">
					<h4 class="similar__title">{{ $t('pages.companyPage.moreVacanciesTitle') }}</h4>
					<p class="similar__text">{{ $t('pages.companyPage.similarVacanciesText') }}</p>
					<div class="similar__vacncy" v-if="fa">
						<MoreJob v-for="item in familiarVacancy" :key="item.id" :vacancy="item"></MoreJob>
					</div>
					<button class="similar__btn">{{ $t('pages.companyPage.seeAllButton') }}</button>
				</div>
			</div>
		</main>
		<Footer></Footer>
	</div>
</template>


<script>
import Header from "../../components/landing/Header";
import Footer from "../../components/landing/Footer.vue";
import MoreJob from "../../components/landing/MoreJob.vue";

export default {
	components: { Header, Footer, MoreJob },

	name: "CompanyPage",
	computed: {
		vacancy() {
			return this.$store.getters.vacancy;
		},
		familiarVacancy() {
			return this.$store.getters.familiarVacancy;
		},
	},
	props: {
		vacancyId: {
			type: String,
		},
	},
	data() {
		return {
			messageVisible: false,
		};
	},
	beforeMount() {
		scroll(0, 0);
		this.$store.dispatch("separateVacancy", this.vacancyId);
		this.$store.dispatch("familiarVacancy", this.vacancyId);
	},
	methods: {
		unDisplayMessage() {
			this.messageVisible = false;
		},
	},
};
</script>

<style scoped>
/* Caption css */
.caption {
	padding-top: 59px;
	max-width: 100%;
	background: #fff;
}

.caption-container {
	max-width: 1020px;
}

.caption__nomination {
	margin-top: 73px;
	display: flex;
	align-items: center;
	padding-bottom: 28px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.caption-name {
	margin-right: 0;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
	margin-right: 0;
}

.caption-designation {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #000000;
}

.caption__nomination-imgcontainer-img {
	width: 64px;
	height: 64px;
	border-radius: 100%;
	object-fit: cover;
}

.caption__nomination-name {
	margin-left: 17px;
}

.caption__nomination-name-company {
	display: flex;
	align-items: center;
}

.caption__nomination-name-company-rating {
	margin-left: 17px;
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
}

.caption__nomination-name-company-rating-tally {
	background-image: url("~@/assets/img/company-page/caption/star.svg");
	background-size: 11px;
	background-repeat: no-repeat;
	background-position: 9px;
	padding: 0px 9px 0px 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.caption__nomination-group :not(:last-child) {
	margin-right: 14px;
}

.caption__nomination-group {
	margin-left: auto;
	display: flex;
}

.caption__nomination-group-btn {
	padding: 16px 35px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #ffffff;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.btn-green {
	background: #14c686;
}

.caption__nomination-group-btn:hover {
	opacity: 0.5;
}

.btn-blue {
	background: #009ed1;
}

.caption__title {
	margin-top: 34px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 32px;
	line-height: 43px;
	color: #000000;
}

.caption__info {
	margin-top: 39px;
	display: flex;
}

.caption__info :last-child {
	margin-left: auto;
}

.caption__info-chart {
	margin-right: 50px;
}

.caption__info-experience {
	margin-right: 50px;
}

.caption__info-education {
	margin-right: 50px;
}

.caption__info-price {
	margin-right: 50px;
}

.caption__info-price-payment {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #009ed1;
}

.caption__info-views {
	display: none;
}

.caption__info-views-name {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
	opacity: 0.5;
}

.caption__info-views-number {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #696974;
}

.caption__after {
	box-sizing: border-box;
	padding: 0 10px;
	margin: 37px auto 0 auto;
	max-width: 1220px;
	display: flex;
	width: 100%;
}

.caption__after-img {
	background: #c4c4c4;
	object-fit: cover;
	object-position: center;
	width: 100%;
	box-sizing: border-box;
	border-radius: 8px;
}

.invisable {
	display: none;
}

.mobile {
	display: none;
}

@media (max-width: 786px) {
	.mobile {
		display: block;
	}

	.caption-name {
		font-size: 10px;
	}

	.caption {
		background: #fafafb;
	}

	.caption__back {
		display: flex;
		margin-top: 60px;
		height: 30px;
		align-items: center;
	}

	.caption__back-img {
		width: 30px;
		height: 9px;
		background: url("~@/assets/img/company-page/caption/back.svg");
		background-size: cover;
		background-repeat: no-repeat;
	}

	.caption__title {
		text-align: center;
		margin-top: 13px;
		font-size: 25px;
	}

	.caption__nomination {
		justify-content: center;
		margin-top: 25px;
		border: none;
		padding-bottom: 0;
	}

	.caption__nomination-name-company-rating {
		margin-left: 45px;
	}

	.caption__info {
		padding-bottom: 23px;
		max-width: 100%;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	.caption__info :last-child {
		margin: 0 auto;
	}

	.caption__info-chart {
		text-align: center;
		margin: 0 auto;
		width: 150px;
	}

	.caption__info-chart-schedule {
		font-size: 11px;
	}

	.caption__info-experience {
		text-align: center;
		width: 150px;
		margin: 0 auto;
	}

	.caption__info-experience-time {
		font-size: 11px;
	}

	.caption__info-education {
		text-align: center;
		width: 150px;
		margin: 0 auto;
	}

	.caption__info-education-level {
		font-size: 11px;
	}

	.caption__info-price {
		text-align: center;
		width: 150px;
		margin: 0 auto;
	}

	.caption__info-price-payment {
		font-size: 11px;
	}

	.caption__info-views {
		text-align: center;
		width: 150px;
	}

	.caption__info-views-name {
		font-size: 10px;
	}

	.caption__info-views-number {
		font-size: 11px;
	}

	.caption__nomination-group {
		margin-top: 23px;
		margin-bottom: 23px;
		display: flex;
		width: 100%;
		box-sizing: border-box;
		justify-content: center;
	}

	.caption__nomination-group-btn {
		padding: 13px 12px;
	}

	.btn-green {
		padding: 13px 24px;
	}

	.caption__nomination-group :not(:last-child) {
		margin-right: 20px;
	}

	.caption__after-img {
		height: 297px;
	}

	.desctop {
		display: none;
	}
}

@media (max-width: 470px) {
	.caption__title {
		text-align: left;
	}

	.caption__nomination {
		justify-content: left;
	}

	.caption__info-chart {
		margin: 0;
		text-align: left;
	}

	.caption__info-experience {
		margin: 0;
		text-align: left;
	}

	.caption__info-education {
		margin: 0;
		text-align: left;
	}

	.caption__info-price {
		margin: 0;
		text-align: left;
	}

	.caption__info-views {
		text-align: left;
		width: 150px;
	}

	.caption__info :last-child {
		margin: 0;
	}

	.caption__info {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*/ Caption css */

/* Warp css */
.warp {
	background: #fff;
	padding-bottom: 60px;
}

.warp__document {
	margin-top: 64px;
	padding-bottom: 67px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.warp__document-title {
	margin-top: 30px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 26px;
	line-height: 1.6;
	color: #424242;
}

.warp__document-text {
	margin-top: 30px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 20px;
	line-height: 1.6;
	color: #424242;
}

.second-nomination {
	margin-top: 50px;
	padding-bottom: 0;
	border-bottom: none;
}

.warp__map {
	height: 340px;
	position: relative;
	box-sizing: border-box;
	padding: 0 10px;
	margin: 63px auto 60px auto;
	max-width: 1220px;
	width: 100%;
}

.warp__map-card {
	width: 100%;
	height: 100%;
	border: none;
}

.warp__map-address {
	top: 73px;
	left: 99px;
	background: #fff;
	position: absolute;
	padding: 31px 47px 31px 42px;
	border: 1px solid #dedede;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea, 0px 26px 24px rgba(64, 64, 64, 0.25);
}

.warp__map-address-title {
	max-width: 307px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 30px;
	letter-spacing: 0.1px;
	color: #696974;
}

.warp__map-address-place {
	margin-top: 20px;
	max-width: 275px;
	background: url("~@/assets/img/company-page/map/place.svg");
	background-position-y: 6px;
	background-size: 16px 19px;
	background-repeat: no-repeat;
	padding-left: 35px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
	color: #444e57;
}

@media (max-width: 786px) {
	.caption {
		padding-top: 47px;
	}

	.warp {
		background: #fafafb;
		padding-bottom: 47px;
	}

	.warp__document {
		margin-top: 10px;
		padding-bottom: 40px;
	}

	.warp__document-title {
		font-size: 15px;
	}

	.warp__document-text {
		margin-top: 16px;
		font-size: 13px;
	}

	.second-nomination {
		margin-top: 20px;
	}

	.warp__map {
		display: none;
	}
}

/*/ Warp css */

/* Similar css */
.similar {
	padding-top: 108px;
	background: #fafafb;
}

.similar__title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.similar__text {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	line-height: 43px;
	color: #000000;
}

.similar__vacncy {
	width: 100%;
	box-sizing: border-box;
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.similar__btn {
	margin: 66px auto 114px auto;
	justify-content: center;
	display: flex;
	width: 234px;
	height: 60px;
	align-items: center;
	text-align: center;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #009ed1;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.similar__btn:hover {
	opacity: 0.5;
}

.mobile {
	display: none;
}

@media (max-width: 960px) {
	.similar {
		padding-top: 24px;
	}

	.similar__title {
		text-align: center;
	}

	.similar__text {
		text-align: center;
	}

	.similar__vacncy {
		margin-top: 22px;
		display: flex;
		flex-direction: column;
	}

	.similar__btn {
		width: 367px;
		margin: 36px auto 57px auto;
	}
}

@media (max-width: 786px) {
	.similar {
		background: #fff;
	}
}

@media (max-width: 400px) {
	.mobile {
		display: block;
	}

	.similar__title {
		font-size: 13px;
		text-align: left;
		line-height: 35.5px;
	}

	.similar__text {
		text-align: left;
		font-size: 20px;
		line-height: 34px;
	}

	.similar__vacncy {
		margin-top: 22px;
		display: flex;
		flex-direction: column;
	}

	.similar__btn {
		width: 300px;
		height: 50px;
		font-size: 13px;
		margin: 36px auto 57px auto;
		border: 1px solid #009ed1;
	}
}

/*/ Similar css */
</style>
