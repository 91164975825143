<template>
	<div class="group__element">
		<div class="group__element-caption">
			<div class="group__element-caption-company">
				<img src="~@/assets/img/all-img/company.svg" alt="" class="group__element-caption-company-photo" />
				<p class="group__element-caption-company-name">Apple Incorporation</p>
			</div>
		</div>
		<div class="group__element-filling">
			<h2 class="group__element-filling-title">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit
			</h2>
			<p class="group__element-filling-text">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua...
			</p>
		</div>
		<div class="group__element-go">
			<button class="group__element-go-btn">Go</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "SeparateGroup",
};
</script>

<style scoped>
.group__element {
	max-width: 338px;
	height: 424px;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 9px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	position: relative;
}

.group__element-caption {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	height: 190px;
	background: url("~@/assets/img/account-img/company/dashboard/groups-bg.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 9px 9px 0px 0px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.group__element-caption-company {
	position: absolute;
	left: 20px;
	bottom: 16px;
	display: flex;
	align-items: center;
}

.group__element-caption-company-photo {
	border-radius: 100%;
	width: 24px;
	height: 24px;
	object-fit: contain;
	object-position: center;
}

.group__element-caption-company-name {
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.group__element-filling {
	padding: 20px 20px;
}

.group__element-filling-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 1.36;
	color: #3b4c68;
}

.group__element-filling-text {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
	color: #686873;
}

.group__element-go {
	position: absolute;
	bottom: 0;
	box-sizing: border-box;
	width: 100%;
	box-sizing: border-box;
	padding: 13px 20px;
	border-top: 1px solid #f1f1f5;
}

.group__element-go-btn {
	padding: 15px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #009ed1;
	border: 1px solid #009ed1;
	border-radius: 10px;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	transition: opacity 1s ease;
}

.group__element-go-btn:hover {
	opacity: 0.5;
}
</style>