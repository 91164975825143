<template>
	<div class="chat__wrapper-dialogue">
		<social-header :role="$store.getters.role"></social-header>
		<div class="chat-filling">
			<social-menu :role="$store.getters.role"></social-menu>
			<div class="chat-wrapper">
				<div class="chat__wrapper-dialogue-caption">
					<button class="chat__wrapper-dialogue-caption-back" @click="$router.go(-1)">
						<img src="~@/assets/img/account-img/chat/back.svg" alt=""
							class="chat__wrapper-dialogue-caption-back-img" />
					</button>
					<div class="chat__wrapper-dialogue-caption-avatar"
						:style="{ backgroundImage: 'url(' + avatar + ')' }">
						<div class="chat__wrapper-dialogue-caption-avatar-notification notification-online"></div>
					</div>
					<div class="chat__wrapper-dialogue-caption-info">
						<p class="chat__wrapper-dialogue-caption-info-name">
							{{ name ? name : $t('pages.social.socialCommunityChat.noData') }}
						</p>
					</div>
					<div class="chat__wrapper-dialogue-caption-opportunities" v-if="false">
						<button class="chat__wrapper-dialogue-caption-opportunities-surch-container">
							<img src="~@/assets/img/account-img/chat/find.svg" alt=""
								class="chat__wrapper-dialogue-caption-opportunities-surch" />
						</button>
						<button class="chat__wrapper-dialogue-caption-opportunities-tabs-container">
							<img src="~@/assets/img/account-img/chat/tabs.svg" alt=""
								class="chat__wrapper-dialogue-caption-opportunities-tabs" />
						</button>
					</div>
				</div>
				<div class="chat__wrapper-dialogue-messages wrapper-two-photo-desctop-community" id="dialoge"
					ref="dialoge">
					<separate-message v-for="(item, index) in dialogue" :key="item.id" :message="item"
						:ref="`message-${index}`"></separate-message>
					<button v-show="!isAtBottom" @click="scrollToBottom" class="scroll-to-bottom">
						{{ $t('pages.social.socialCommunityChat.scrollToBottom') }}
					</button>
				</div>
				<div class="chat__wrapper-dialogue-entry">
					<div class="chat__wrapper-dialogue-entry-filling">
						<textarea id="textarea" type="text" class="chat__wrapper-dialogue-entry-filling-input"
							:placeholder="$t('pages.social.socialCommunityChat.enterMessage')" v-model="newMessage"
							v-on:keyup.enter="send" />
						<div class="chat__wrapper-dialogue-entry-filling-interactions">
							<ChatsEmojiVue @emojiAdd="emojiAdd" class="emoji"></ChatsEmojiVue>
							<button v-if="false"
								class="chat__wrapper-dialogue-entry-filling-interactions-file"></button>
							<button ref="send" @click="send"
								class="chat__wrapper-dialogue-entry-filling-interactions-send"></button>
						</div>
					</div>
					<div v-if="false" class="chat__wrapper-dialogue-entry-photos">
						<div class="photos-element-wrapper">
							<button class="close"></button>
							<button class="photos-element">
								<img src="~@/assets/img/account-img/chat/back.svg" alt="" class="photos-element__img">
							</button>
						</div>
						<div class="photos-element-wrapper">
							<button class="close"></button>
							<button class="photos-element">
								<img src="~@/assets/img/account-img/chat/back.svg" alt="" class="photos-element__img">
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";
import SocialHeader from "@/components/social/SocialHeader";
import SocialMenu from "@/components/social/SocialMenu";
import Cookies from "js-cookie";
import SeparateMessage from "@/components/social/account_communitis_main/SeparateMessage";
import ChatsEmojiVue from "../../components/social/ChatsEmoji.vue";

export default {
	props: ['communityId'],
	computed: {
		dialogue() {
			return this.$store.getters.getCommunityMessages;
		},
		page() {
			return this.$store.getters.getCommunityMessagesPage;
		},
		limit() {
			return this.$store.getters.getCommunityMessagesLimit;
		},
		communityInfo() {
			return this.$store.getters.getCommunityMessagesInfo;
		},
		fileInMessages() {
			return this.$store.getters.fileInMessage;
		}
	},
	beforeMount() {
		this.name = Cookies.get('nameCom');
		this.avatar = Cookies.get('avatarCom');

		this.$store.commit('resetCommunityMessagesPage');
		const request = new XMLHttpRequest();
		const url = config.apiPrefix + `community/chats/last-message?communityId=` + this.communityId;
		request.open('GET', url, false);
		request.setRequestHeader('X-Auth-Token', this.$store.getters.token);
		request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
		try {
			request.send();
			this.lastVisibleMessageId = JSON.parse(request.response).data;
		} catch (e) {
			console.log(e);
		}

		this.loadMessages();
		this.addPagination();
	},
	async mounted() {
		const messagesContainer = this.$refs.dialoge;
		if (this.lastVisibleMessageId) {
			this.scrollToLastVisibleMessage();
		} else {
			messagesContainer.scroll(0, 100000);
		}

		messagesContainer.addEventListener('scroll', this.handleScroll);

		window.addEventListener('beforeunload', this.handleBeforeUnload);
		if (this.dialogue.length !== 0)
			this.timerId = setInterval(() => {
				this.loadMessages();
			}, 6000);
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		const messagesContainer = this.$refs.dialoge;
		if (messagesContainer) {
			messagesContainer.removeEventListener('scroll', this.handleScroll);
		}
	},
	name: "SocialCommunityChat",
	components: {
		SeparateMessage,
		SocialHeader, SocialMenu,
		ChatsEmojiVue
	},
	data() {
		return {
			newMessage: '',
			timerId: null,
			name: null,
			avatar: null,
			lastVisibleMessageIndex: null,
			lastVisibleMessageId: null,
			allMessagesLoaded: false,
			isAtBottom: true,
		};
	},
	methods: {
		emojiAdd(item) {
			this.newMessage += item;
		},
		deletePagination() {
			window.onscroll = null;
		},
		scrollToBottom() {
			const container = this.$refs.dialoge;
			if (container) {
				container.scrollTo({
					top: container.scrollHeight,
					behavior: 'smooth'
				});
			}
		},
		async loadMessages() {
			this.$store.dispatch('fetchDialogueWithCommunity', {
				id: this.communityId,
				page: this.page,
				limit: this.limit,
				token: this.$store.getters.token,
				currentData: this.dialogue,
				callback: this.deletePagination
			})
		},
		send() {
			if (this.newMessage) {
				this.$store.dispatch('sendMessagesWithCommunity', {
					token: this.$store.getters.token,
					id: this.communityId,
					message: this.newMessage,
					callback: this.loadMessages
				})
				this.newMessage = null;
				this.scrollToBottom();
			} else errorHandler({ status: 400, page: "emptyMessage" });
		},
		addPagination() {
			window.onscroll = () => {
				let el = document.getElementById("dialoge");
				el.onscroll = () => {
					if (el.scrollTop === 0) {
						this.$store.commit('upCommunityMessagesPage');
						this.loadMessages();
					}
				}
			}
		},
		scrollToLastVisibleMessage() {
			let foundMessage = this.findMessageById(this.lastVisibleMessageId);
			while (!foundMessage && !this.allMessagesLoaded) {
				this.loadMessages();
				foundMessage = this.findMessageById(this.lastVisibleMessageId);
			}

			if (foundMessage) {
				this.lastVisibleMessageIndex = foundMessage.index;
				this.$nextTick(() => {
					const messageComponent = this.$refs[`message-${foundMessage.index}`];
					if (messageComponent && messageComponent[0]) {
						const element = messageComponent[0].$el;
						if (element) {
							element.scrollIntoView();
						}
					}
				});
			}
		},
		findMessageById(messageId) {
			const index = this.dialogue.findIndex(message => message.id === messageId);
			const foundMessage = this.dialogue[index];
			return { foundMessage, index };
		},
		handleScroll() {
			const container = document.getElementById('dialoge');
			if (!container) return;
			this.isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
			this.updateLastVisibleMessage();
		},
		updateLastVisibleMessage() {
			let newLastVisibleIndex = null;
			for (let i = 0; i < this.dialogue.length; i++) {
				const messageElement = this.$refs[`message-${i}`][0].$el;
				if (messageElement.getBoundingClientRect().bottom < window.innerHeight) {
					newLastVisibleIndex = i;
				}
			}
			if (newLastVisibleIndex !== null && (this.lastVisibleMessageIndex === null || newLastVisibleIndex > this.lastVisibleMessageIndex)) {
				this.lastVisibleMessageIndex = newLastVisibleIndex;
			}
		},
		handleBeforeUnload() {
			if (this.lastVisibleMessageIndex !== null) {
				const lastVisibleMessageId = this.dialogue[this.lastVisibleMessageIndex].id;
				if (lastVisibleMessageId) {
					this.$store.dispatch("readCommunityChat", {
						communityId: this.communityId,
						lastMessageReadId: lastVisibleMessageId
					});
				}
			}
		},
	},
	destroyed() {
		clearInterval(this.timerId);
		this.$store.commit('setMessages', null);
	},
	beforeRouteLeave(to, from, next) {
		this.handleBeforeUnload();
		next();
	},
};
</script>


<style scoped>
.scroll-to-bottom {
	position: fixed;
	right: 120px;
	bottom: 180px;
	cursor: pointer;
	font-size: 36px;
	z-index: 10000;
	color: #171725;
	background-color: rgba(182, 210, 255, 1);
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.chat-wrapper {
	width: 100%;
	padding-top: 59px;
	max-width: 100%;
	box-sizing: border-box;
	position: relative;
}

.chat-filling {
	display: flex;
	max-width: 100%;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	min-height: 100vh;
}

.chat__wrapper-dialogue {

	position: relative;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	height: 100%;
}

.chat__wrapper-dialogue-caption {
	padding: 30px 30px 30px 30px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	border-bottom: 1px solid rgba(182, 210, 255, 0.5);
	align-items: center;
}

.chat__wrapper-dialogue-caption-back {
	width: 60px;
	height: 60px;
	display: block;
	box-sizing: border-box;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-back:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-avatar {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	position: relative;
}

.chat__wrapper-dialogue-caption-avatar-notification {
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 1px;
	right: 0;
}

.chat__wrapper-dialogue-caption-info {
	margin-left: 16px;
}

.chat__wrapper-dialogue-caption-info-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
}

.chat__wrapper-dialogue-caption-info-online {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #009ed1;
}

.ofline {
	color: #999999;
}

.chat__wrapper-dialogue-caption-opportunities {
	margin-left: auto;
}

.chat__wrapper-dialogue-caption-opportunities-surch {
	margin-right: 18px;
	padding: 14px 14px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-surch:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-opportunities-tabs {
	padding: 11px 11px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-tabs:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages {
	padding: 24px 30px 24px 32px;
	display: flex;
	flex-direction: column;
	position: relative;
	height: calc(100vh - 356px);
	overflow-y: scroll;
	scrollbar-width: none;
}

.chat__wrapper-dialogue-messages-interlocutor {
	max-width: 372px;
	width: 100%;
	box-sizing: border-box;
	background: #e4f6fc;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-right: auto;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-interlocutor::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	left: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-interlocutor:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-messages-interlocutor-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #666666;
}

.chat__wrapper-dialogue-messages-interlocutor-time {
	margin-top: 29px;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999999;
}

.chat__wrapper-dialogue-messages-user {
	max-width: 302px;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-left: auto;
	background: #009ed1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-user-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
}

.chat__wrapper-dialogue-messages-user::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/user-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	right: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-user:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-entry {
	border-top: 1px solid rgba(182, 210, 255, 0.5);
	background: #fafafb;
	position: absolute;
	margin-top: auto;
	bottom: 0;
	width: 100%;
	padding: 36px 40px;
	box-sizing: border-box;
	z-index: 20;
	display: flex;
	box-sizing: border-box;
	max-width: 100%;
}

.chat__wrapper-dialogue-entry-filling {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.chat__wrapper-dialogue-entry-filling-input {
	width: 100%;
	padding: 18px 103px 18px 26px;
	box-sizing: border-box;
	height: 56px;
	max-height: calc(100vh - 470px);
	background: #ffffff;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #171725;
	border-radius: 10px;
	overflow-y: scroll;
	scrollbar-width: none;
	border: none;
}

.chat__wrapper-dialogue-entry-filling-input:hover {
	overflow-y: scroll;
	scrollbar-width: none;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-entry-filling-input::placeholder {
	color: #b7c1d1;
}

.chat__wrapper-dialogue-entry-filling-interactions {
	position: absolute;
	align-items: center;
	display: flex;
	right: 26px;
	bottom: calc(50% - 9px);
}


.chat__wrapper-dialogue-entry-filling-interactions-file {
	background: url("~@/assets/img/account-img/chat/file-add.svg");
	width: 19px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-file:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-entry-filling-interactions-send {
	margin-left: 21px;
	background: url("~@/assets/img/account-img/chat/send.svg");
	width: 20px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-send:hover {
	opacity: 0.5;
}

@media (max-width: 1400px) {
	.chat__wrapper-dialogue-caption {
		padding: 10px 20px 10px 0px;
	}

	.chat__wrapper-dialogue-entry {
		padding: 15px;
	}

	.chat__wrapper-dialogue-messages {
		padding: 24px 20px 24px 31px;
		height: calc(100vh - 275px);
	}
}

@media (max-width: 950px) {
	.chat-wrapper {
		padding-top: 48px;
	}
}

@media (max-width: 900px) {
	.chat__wrapper-dialogue-caption {
		padding: 18px 24px 18px 24px;
	}

	.scroll-to-bottom {
		right: 25px;
		bottom: 110px;
	}

	.chat__wrapper-dialogue-caption-back {
		width: 40px;
		height: 40px;
	}

	.chat__wrapper-dialogue-caption-avatar {
		flex-shrink: 0;
	}

	.chat__wrapper-dialogue-caption-info-name {
		font-size: 15px;
	}
}

@media (max-width: 430px) {
	.chat__wrapper-dialogue-caption {
		padding: 18px 10px 18px 0px;
	}

	.chat__wrapper-dialogue-caption-opportunities-surch {
		margin-right: 10px;
		padding: 8px 8px;
		width: 20px;
	}

	.chat__wrapper-dialogue-messages {
		height: calc(100vh - 267px);
	}


	.chat__wrapper-dialogue-caption-opportunities-tabs {
		padding: 6px 6px;
	}

	.chat__wrapper-dialogue-caption-info {
		margin-left: 10px;
	}

	.chat__wrapper-dialogue-messages {
		padding: 24px 18px 24px 18px;
	}

	.chat__wrapper-dialogue-messages {
		height: calc(100vh - 269px);
	}
}

/* Photos modificators */


.chat__wrapper-dialogue-entry-photos {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	row-gap: 10px;
	column-gap: 10px;
}

.photos-element-wrapper {
	position: relative;
	max-width: 150px;
	width: 100%;
	height: 80px;
	flex-shrink: 0;
	display: flex;
}

.photos-element {
	width: 100%;
	height: 100%;
	display: flex;
}

.close {
	right: 5px;
	top: 5px;
	background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
	z-index: 20;
	background-size: cover;
	background-position: center;
	position: absolute;
	height: 15px;
	width: 15px;
}

.photos-element__img {
	width: 100%;
	object-fit: cover;
	border-radius: 8px;
	box-sizing: border-box;
}


.wrapper-three-photo-desctop-community {
	height: calc(100vh - 456px);
}

.wrapper-two-photo-desctop-community {
	height: calc(100vh - 456px);
}

@media (max-width: 1400px) {
	.wrapper-three-photo-desctop-community {
		height: calc(100vh - 376px);
	}

	.wrapper-two-photo-desctop-community {
		height: calc(100vh - 376px);
	}
}

@media (max-width: 750px) {
	.emoji {
		display: none;
	}
}

@media (max-width: 500px) {
	.wrapper-three-photo-desctop-community {
		height: calc(100vh - 463px);
	}

	.photos-element {
		max-width: 120px;
		height: 60px;
	}
}

@media (max-width: 431px) {
	.wrapper-two-photo-desctop-community {
		height: calc(100vh - 366px);
	}
}

@media (max-width: 339px) {
	.wrapper-three-photo-desctop-community {
		height: calc(100vh - 488px);
	}

	.photos-element-wrapper {
		max-width: 100%;
		height: 60px;
	}
}

@media (max-width: 339px) {
	.wrapper-two-photo-desctop-community {
		height: calc(100vh - 419px);
	}
}
</style>
