<template>
  <div>
    <Header></Header>

    <main class="main">
      <div class="container vacancies-container">
        <div class="work__filters invisable-menu" v-show="filtersVisible">
          <div class="work__filters-caption">
            <div class="work__filters-caption-back" v-on:click="showFilters()">
              <button class="work__filters-caption-back-img"></button>
            </div>
            <p class="work__filters-caption-text">{{ $t('pages.vacancyPage.filtration') }}</p>
          </div>
          <div class="work__filters-group">
            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.workExperience') }}</h3>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-1" name="filter-1"
                  value="0" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-1">{{ $t('pages.vacancyPage.over10Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-2" name="filter-2"
                  value="1" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-2">{{ $t('pages.vacancyPage.5to10Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3" name="filter-3"
                  value="2" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-3">{{ $t('pages.vacancyPage.3to5Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-4" name="filter-4"
                  value="3" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-4">{{ $t('pages.vacancyPage.1to3Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-5" name="filter-5"
                  value="4" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-5">{{ $t('pages.vacancyPage.lessThan1Year') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-6" name="filter-6"
                  value="5" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-6">{{ $t('pages.vacancyPage.noExperience') }}</label>
              </div>
            </div>

            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.education') }}</h3>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-1" name="filter-3-1"
                  value="0" v-model="searchVacanciesForm.education" />
                <label for="filter-3-1">{{ $t('pages.vacancyPage.academicDegree') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-2" name="filter-3-2"
                  value="1" v-model="searchVacanciesForm.education" />
                <label for="filter-3-2">{{ $t('pages.vacancyPage.higher') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-3" name="filter-3-3"
                  value="2" v-model="searchVacanciesForm.education" />
                <label for="filter-3-3">{{ $t('pages.vacancyPage.secondaryVocational') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-4" name="filter-3-4"
                  value="3" v-model="searchVacanciesForm.education" />
                <label for="filter-3-4">{{ $t('pages.vacancyPage.averageTotal') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-5" name="filter-3-5"
                  value="4" v-model="searchVacanciesForm.education" />
                <label for="filter-3-5">{{ $t('pages.vacancyPage.basicGeneral') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-6" name="filter-3-6"
                  value="5" v-model="searchVacanciesForm.education" />
                <label for="filter-3-6">{{ $t('pages.vacancyPage.irrelevant') }}</label>
              </div>

            </div>

            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.keySkills') }}</h3>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="0"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.projectManagement') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="1"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.negotiation') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="2"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.teamwork') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="3"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.pcUser') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="4"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.pcUser') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="5"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.businessConversation') }}</label>
              </div>
            </div>

          </div>
          <div class="work__filters-btn-container">
            <button class="work__filters-btn" v-on:click="showFilters()">
              {{ $t('pages.vacancyPage.apply') }}
            </button>
          </div>
        </div>
        <div class="search">
          <h1 class="search__title">{{ $t('pages.vacancyPage.jobs') }}</h1>
          <h2 class="search__subtitle">{{ $t('pages.vacancyPage.onlyCurrentVacancies') }}</h2>
          <div class="search__specifications">
            <span class="search__specifications-work-span"></span>
            <input type="text" placeholder="UX Designer" class="search__specifications-work"
              v-model="searchVacanciesForm.specialization" />
            <div class="search__specifications-rating">
              <span class="search__specifications-rating-span"></span>
              <p class="search__specifications-rating-text">{{ $t('pages.vacancyPage.rating') }}</p>
              <p class="search__specifications-rating-input-text">{{ $t('pages.vacancyPage.from') }}</p>
              <input type="text" class="search__specifications-rating-input" v-model="searchVacanciesForm.rate_from" />
              <p class="search__specifications-rating-input-text">{{ $t('pages.vacancyPage.until') }}</p>
              <input type="text" class="search__specifications-rating-input" v-model="searchVacanciesForm.rate_to" />
            </div>
            <div class="search-wrapper">
              <button @click="cityVisible = !cityVisible" class="test__top-bottom-town">
                {{ searchVacanciesForm.city ? cityName : 'New York' }} <span
                  class="test__top-bottom-town-img"></span></button>
              <div class="search-list" v-if="cityVisible">
                <input placeholder="{{ $t('pages.vacancyPage.city') }}" type="text"
                  @input="e => searchCity(e.target.value)" class="search-list__element search-list__element-input" />
                <button class="search-list__element" @click="pickCity" :value="item.id" v-for="item in dataCities"
                  :key="item.id">{{ item.name }}
                </button>
              </div>
            </div>
            <div class="test__top-bottom-work-container">
              <button ref="select" class="test__top-bottom-work" @click="selectVisible = !selectVisible">
                <span>{{ $t('pages.vacancyPage.employmentType') }}</span>
              </button>
              <div class="test__top-bottom-work-group" v-if="selectVisible">
                <input type="radio" id="one" value="0" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="one" :class="{ select_picked: searchVacanciesForm.empl_type === '0' }"
                  class="test__top-bottom-work-group-element-label first-label">{{
                    $t('pages.vacancyPage.fullEmployment') }}</label>

                <input type="radio" id="two" value="1" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="two" :class="{ select_picked: searchVacanciesForm.empl_type === '1' }"
                  class="test__top-bottom-work-group-element-label">{{ $t('pages.vacancyPage.partTimeEmployment')
                  }}</label>

                <input type="radio" id="three" value="2" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="three" :class="{ select_picked: searchVacanciesForm.empl_type === '2' }"
                  class="test__top-bottom-work-group-element-label">{{ $t('pages.vacancyPage.projectActivity')
                  }}</label>

                <input type="radio" id="four" value="3" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="four" :class="{ select_picked: searchVacanciesForm.empl_type === '3' }"
                  class="test__top-bottom-work-group-element-label">{{ $t('pages.vacancyPage.internship') }}</label>

                <input type="radio" id="five" value="4" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="five" :class="{ select_picked: searchVacanciesForm.empl_type === '4' }"
                  class="test__top-bottom-work-group-element-label">{{ $t('pages.vacancyPage.volunteering') }}</label>

                <input type="radio" id="six" value="5" class="test__top-bottom-work-group-element-input"
                  v-model="searchVacanciesForm.empl_type" @change="selectVisible = !selectVisible"
                  @click="checkSelect('select')" />
                <label for="six" :class="{ select_picked: searchVacanciesForm.empl_type === '5' }"
                  class="test__top-bottom-work-group-element-label">{{ $t('pages.vacancyPage.distantWork') }}</label>

              </div>
            </div>
            <button class="search__specifications-btn" @click="vacancyLoad()">
              {{ $t('pages.vacancyPage.findJobs') }}
            </button>
          </div>
          <p class="search__sorting-text mobile">{{ $t('pages.vacancyPage.sorting') }}</p>
          <div class="search__sorting">
            <p class="search__sorting-text desctop">{{ $t('pages.vacancyPage.sorting') }}</p>
            <div class="search__sorting-price-container">
              <button ref="select" class="search__sorting-price" @click="priceVisible = !priceVisible">
                {{ $t('pages.vacancyPage.wage') }}
              </button>
              <div class="search__sorting-price-group" v-if="priceVisible">
                <input type="radio" id="up" value="Increase" class="search__sorting-price-element-input"
                  @click="sortedVacancy('Increase')" />
                <label :class="{ sort_picked: sortPrice === 'Increase' }" for="up"
                  class="search__sorting-price-element-label first-label">{{ $t('pages.vacancyPage.increase') }}
                </label>
                <input type="radio" id="down" value="Descending" class="search__sorting-price-element-input"
                  @click="sortedVacancy('Descending')" />
                <label :class="{ sort_picked: sortPrice === 'Descending' }" for="down"
                  class="search__sorting-price-element-label">{{ $t('pages.vacancyPage.descending') }}</label>
              </div>
            </div>
            <button class="search__sorting-filter" v-on:click="showFilters()">
              <img src="~@/assets/img/all-img/filters/filter-open-btn.svg" class="search__sorting-filter-img"
                alt="filter" />
            </button>
          </div>
        </div>
      </div>

      <div class="container vacancies-container">
        <div class="work">
          <div class="work__filters">
            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.workExperience') }}</h3>

              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-1" name="filter-1"
                  value="0" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-1">{{ $t('pages.vacancyPage.over10Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-2" name="filter-2"
                  value="1" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-2">{{ $t('pages.vacancyPage.5to10Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3" name="filter-3"
                  value="2" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-3">{{ $t('pages.vacancyPage.3to5Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-4" name="filter-4"
                  value="3" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-4">{{ $t('pages.vacancyPage.1to3Years') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-5" name="filter-5"
                  value="4" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-5">{{ $t('pages.vacancyPage.lessThan1Year') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-6" name="filter-6"
                  value="5" v-model="searchVacanciesForm.work_experience" />
                <label for="filter-6">{{ $t('pages.vacancyPage.noExperience') }}</label>
              </div>
            </div>
            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.education') }}</h3>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-1" name="filter-3-1"
                  value="0" v-model="searchVacanciesForm.education" />
                <label for="filter-3-1">{{ $t('pages.vacancyPage.academicDegree') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-2" name="filter-3-2"
                  value="1" v-model="searchVacanciesForm.education" />
                <label for="filter-3-2">{{ $t('pages.vacancyPage.higher') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-3" name="filter-3-3"
                  value="2" v-model="searchVacanciesForm.education" />
                <label for="filter-3-3">{{ $t('pages.vacancyPage.secondaryVocational') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-4" name="filter-3-4"
                  value="3" v-model="searchVacanciesForm.education" />
                <label for="filter-3-4">{{ $t('pages.vacancyPage.averageTotal') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-5" name="filter-3-5"
                  value="4" v-model="searchVacanciesForm.education" />
                <label for="filter-3-5">{{ $t('pages.vacancyPage.basicGeneral') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" id="filter-3-6" name="filter-3-6"
                  value="5" v-model="searchVacanciesForm.education" />
                <label for="filter-3-6">{{ $t('pages.vacancyPage.irrelevant') }}</label>
              </div>

            </div>

            <div class="work__filters-element">
              <h3 class="work__filters-element-title">{{ $t('pages.vacancyPage.keySkills') }}</h3>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="0"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.projectManagement') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="1"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.negotiation') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="2"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.teamwork') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="3"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.pcUser') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="4"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.pcUser') }}</label>
              </div>
              <div class="work__filters-element-filter">
                <input type="checkbox" class="work__filters-element-filter-checkbox" value="5"
                  v-model="searchVacanciesForm.key_skills" />
                <label>{{ $t('pages.vacancyPage.businessConversation') }}</label>
              </div>
            </div>

            <button class="work__filters-btn" @click="uppriceFilters()">
              {{ $t('pages.vacancyPage.apply') }}
            </button>
          </div>
          <div class="work__workers">
            <SeparateVacancy v-for="item in copyVacancy" :key="item.id" :vacancy="item"></SeparateVacancy>
            <button class="work__workers-more">{{ $t('pages.vacancyPage.showMore') }}</button>
          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>


<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";
import SeparateVacancy from "../../components/landing/SeparateVacancy.vue";
import { config } from "@/lib/config";

export default {
  components: { Header, Footer, SeparateVacancy },

  name: "VacancyPage",
  computed: {
    searchVacanciesParams() {
      return this.$store.getters.searchVacanciesParams;
    },
    vacancies() {
      return this.$store.getters.vacancies;
    },
    dataCities() {
      return this.$store.getters.dataCities;
    }
  },
  data() {
    return {
      filtersVisible: false,
      selectVisible: false,
      priceVisible: false,
      cityVisible: false,
      cityName: null,
      searchVacanciesForm: {
        specialization: null,
        city: null,
        empl_type: null,
        rate_from: null,
        rate_to: null,
        education: [],
        work_experience: [],
        key_skills: [],
      },

      keySkills: [],

      sortPrice: null,
      copyVacancy: null,
    };
  },

  methods: {
    checkSelect() {
      if (this.searchVacanciesForm.empl_type !== null)
        this.searchVacanciesForm.empl_type = null;
    },
    showFilters() {
      this.filtersVisible = !this.filtersVisible;
    },
    uppriceFilters() {
      this.$store.dispatch(
        "setNewSearchVacanciesParams",
        this.searchVacanciesForm
      );
      this.copyVacancy = this.vacancies.slice();
    },
    vacancyLoad() {
      this.uppriceFilters();
      this.$store.dispatch("getVacancies");

      this.copyVacancy = this.vacancies.slice();
    },
    sortedVacancy(params) {
      if (this.sortPrice === params) this.sortPrice = null;
      else this.sortPrice = params;
      switch (this.sortPrice) {
        case "Increase":
          this.copyVacancy = this.copyVacancy.slice().sort(function (a, b) {
            return a.payFrom - b.payFrom;
          });
          break;
        case "Descending":
          this.copyVacancy = this.copyVacancy.slice().sort(function (a, b) {
            return b.payFrom - a.payFrom;
          });
          break;
        case null:
          this.copyVacancy = this.vacancies;
          break;
      }
      this.priceVisible = !this.priceVisible;
    },
    getKeySkills() {
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + 'candidate/skills/all';
      request.open('GET', url, false);
      try {
        request.send();
        let response = JSON.parse(request.response);
        this.keySkills = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    searchCity(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$store.dispatch('searchCity', val)
      }, 1000)
    },
    pickCity(e) {
      this.cityVisible = false;
      this.cityName = this.dataCities[this.dataCities.map(item => item.id).indexOf(e.target.value)].name;
      this.searchVacanciesForm.city = e.target.value;
      this.$store.dispatch('searchCity', '');
    }
  },

  beforeMount() {
    scroll(0, 0);
    this.getKeySkills();
    this.$store.dispatch('searchCity', '');
    this.searchVacanciesForm.empl_type = this.searchVacanciesParams ? this.searchVacanciesParams.empl_type : null;
    this.searchVacanciesForm.city = null;
    if (this.searchVacanciesParams.city) {
      this.searchVacanciesForm.city = this.searchVacanciesParams.city;
      this.cityName = this.dataCities[this.dataCities.map(item => item.id).indexOf(this.searchVacanciesParams.city)].name;
    }
    this.searchVacanciesForm.specialization = this.searchVacanciesParams ? this.searchVacanciesParams.specialization : null;
    this.vacancyLoad();
  },
};
</script>

<style scoped>
/* Search */
.show {
  display: block;
}

.main {
  background: #fafafb;
}

.vacancies-container {
  background: #fafafb;
  max-width: 1440px;
  padding: 0 10px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 100%;
  overflow: visible;
}

.search {
  background: #fafafb;
  padding-top: 59px;
}

.search__title {
  padding-top: 74px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.search__subtitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 35px;
  color: #000000;
}

.search__specifications {
  box-shadow: inset -1px -1px 0px #f1f1f5;
  position: relative;
  margin-top: 66px;
  width: 100%;
  box-sizing: border-box;
  height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search__specifications-work-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/find.svg");
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  left: 2.9%;
}

.search__specifications-work {
  border-right: 1px solid #f1f1f5;
  width: 305px;
  flex-shrink: 1;
  border-radius: 10px 0px 0px 10px;
  padding: 0px 0 0px 64px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171625;
  height: inherit;
  transition: background 1s ease;
}

.search__specifications-work:hover {
  background: #eefbff;
}

.search__specifications-rating-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/rating.svg");
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  left: 2.9%;
}

.search__specifications-rating {
  border-right: 1px solid #f1f1f5;
  position: relative;
  height: inherit;
  width: 305px;
  flex-shrink: 1;
  display: flex;
  background: #fff;
  padding-right: 24px;
  transition: background 1s ease;
}

.search__specifications-rating-text {
  padding: 23px 0 23px 64px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  background: inherit;
  line-height: 26px;
  letter-spacing: 0.1px;
  transition: inherit;
  color: #171625;
  transition: background 1s ease;
}

.search__specifications-rating-input {
  margin-left: 10px;
  width: 32px;
  border-bottom: 1px solid #50b5ff;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  padding: 0px 0 0px 0px;
  background: inherit;
  height: 26px;
  transition: inherit;
  margin-top: 23px;
  transition: background 1s ease;
}

.search__specifications-rating-input-text {
  font-family: "Roboto";
  margin-left: 11px;
  padding: 23px 0 23px 0px;
  font-weight: 400;
  font-size: 16px;
  transition: inherit;
  line-height: 26px;
  background: inherit;
  letter-spacing: 0.1px;
  color: #171625;
}

.search__specifications-rating:hover {
  background: #eefbff;
}

.search__specifications-rating-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/rating.svg");
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  left: 13.9%;
  top: 30px;
}

.search__specifications-place-container {
  box-sizing: border-box;
  align-items: center;
  height: inherit;
  position: relative;
  flex-shrink: 1;
  width: 305px;
  background: #fff;
}

.search__specifications-place-container-input {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 0px 0px 0px 64px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  background: #fff;
  transition: background 1s ease;
}

.search__specifications-place-container-input:hover {
  background: #eefbff;
}

.search__specifications-place-container-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/place.svg");
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  left: 15.4%;
  top: 28px;
}

/* search */
.test__top-bottom-town {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  background-image: url("~@/assets/img/landing-img/main-page/place-blue.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 36px;
  text-align: left;
  background-color: #fff;
}

.test__top-bottom-town-img {
  position: absolute;
  display: block;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 9px;
  right: 20px;
  top: calc(50% - 4px);
}


.search-wrapper {
  width: 26.7%;
  height: 70px;
  box-shadow: inset -1px -1px 0px #f1f1f5;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  position: relative;
}

.search-list {
  position: absolute;
  background: #fff;
  max-height: 278px;
  overflow-y: scroll;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eaeaee;
}

.search-list__element {
  padding-left: 60px;
}

.search-list__element {
  padding-left: 60px;
}

.search-list__element {
  -webkit-appearance: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
  line-height: 1;
}

.search-list__element-input {
  cursor: auto;
  border-bottom: 1px solid #eaeaee;
  padding-right: 20px;
}

.search-list__element:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.search-list__element:last-child {
  border-radius: 0px 0px 7px 7px;
  border-bottom: none;
}

.test__top-bottom-town::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
}

/* /search */
.search__specifications-employment-container {
  cursor: pointer;
  padding-left: 70px;
  border-left: 1px solid #f1f1f5;
  border-right: none;
  border-top: none;
  border-bottom: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171725;

  align-items: center;
  height: inherit;
  position: relative;
  flex-shrink: 1;
  width: 305px;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  transition: background-color 1s ease;
}

.search__specifications-employment-container:hover {
  background-color: #eefbff;
}

.search__specifications-btn {
  justify-content: center;
  flex-shrink: 1;
  z-index: 2;
  box-sizing: border-box;
  width: 200px;
  font-family: "Roboto";
  border-radius: 0px 10px 10px 0px;
  height: inherit;
  background: #009ed1;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  transition: opacity 1s ease;
}

.search__specifications-btn:hover {
  opacity: 0.5;
}

.search__sorting {
  margin-top: 34px;
  display: flex;
  align-items: center;
}

.search__sorting-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #696974;
}

.mobile {
  display: none;
}

.invisable-menu {
  display: none;
}

.search__sorting-filter {
  display: none;
}

@media (max-width: 1320px) {
  .search__sorting-filter {
    display: block;
    margin-left: 10px;
    margin-top: 3px;
  }

  .search__sorting-filter-img {
    width: 16px;
    height: 14px;
    padding: 10px 9px;
    background: #e6f6fc;
    border: 1px solid #009ed1;
    border-radius: 9px;
    transition: opacity 1s ease;
  }

  .search__sorting-filter-img:hover {
    opacity: 0.5;
  }
}

@media (max-width: 1200px) {
  .search__specifications {
    background: #fff;
    width: 70%;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    padding: 20px 20px 10px 20px;
    border: 1px solid #f1f1f5;
    border-radius: 20px;
    margin: 25px auto 0 auto;
  }

  .search__specifications-work {
    font-size: 14px;
    padding: 0px 0 0px 41px;
    margin-bottom: 10px;
    height: 42px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-work-span {
    left: 35px;
    top: 33px;
  }

  .search__specifications-rating {
    font-size: 14px;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-rating-text {
    font-size: 14px;
    padding: 0px 0 0px 41px;
  }

  .search__specifications-rating-input-text {
    font-size: 14px;
    padding: 0px 0 0px 0px;
  }

  .search__specifications-rating-span {
    left: 16px;
    top: 17px;
  }

  .search__specifications-place-container {
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
    overflow: hidden;
  }

  .search__specifications-place-container-input {
    font-size: 14px;
    padding-left: 41px;
    height: inherit;
    border-radius: 10px;
  }

  .search__specifications-place-container-span {
    top: 15px;
    left: 16px;
  }

  .search__specifications-employment-container {
    padding-left: 37px;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
    background-position: calc(97%);
    overflow: hidden;
  }

  .search__specifications::after {
    top: 205px;
    right: unset;
    left: 37px;
  }

  .search__specifications-rating-input {
    margin: 10px 0 auto 10px;
  }

  .search__specifications-place {
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-employment {
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-btn {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    border-radius: 10px;
  }
}

@media (max-width: 760px) {
  .search__title {
    padding-top: 28px;
    font-size: 13px;
  }

  .search__subtitle {
    font-size: 20px;
  }

  .search__specifications {
    width: 100%;
    font-size: 14px;
  }

  .search__specifications-work {
    font-size: 14px;
  }

  .search__specifications-rating {
    font-size: 14px;
  }

  .search__specifications-rating-input {
    font-size: 14px;
  }

  .search__specifications-rating-text {
    font-size: 14px;
  }

  .search__specifications-rating-input-text {
    font-size: 14px;
  }

  .search__specifications-place {
    font-size: 14px;
  }

  .search__specifications-employment {
    font-size: 14px;
  }

  .search__specifications-btn {
    font-size: 14px;
  }

  .mobile {
    display: block;
    padding-top: 23px;
  }

  .search__sorting {
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
  }

  .desctop {
    display: none;
  }

  .search__sorting-price {
    margin-left: 0;
  }

  .search__sorting-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #696974;
  }
}

@media (max-width: 380px) {
  .search__specifications-work {
    padding-left: 41px;
    background-position: 15px;
  }

  .search__specifications-rating-text {
    padding-left: 41px;
    background-position: 15px;
  }

  .search__specifications-place {
    font-size: 14px;
    padding-left: 41px;
    background-position: 15px;
  }

  .search__specifications-employment {
    padding-left: 41px;
    background-position: 15px;
  }
}

/* Search */

/* Work */
.work {
  margin-top: 50px;
  display: flex;
  padding-bottom: 100px;
}

.work__filters {
  margin-right: 39px;
  width: 374px;
}

.work__filters-element :not(:last-child) {
  margin-bottom: 10px;
}

.work__filters-element :first-child {
  margin-bottom: 14px;
}

.work__filters-element {
  margin-bottom: 14px;
  padding: 8px;
  width: 100%;
  background: #f7f7fa;
  border-radius: 4px;
}

.work__filters-element-title {
  margin-bottom: 4px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #19191d;
}

.work__filters-element-filter-checkbox {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.work__filters-element-filter-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #19191d;
}

.work__filters-element-filter-checkbox+label::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #4a4b57;
  border-radius: 2px;
  margin-right: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.work__filters-element-filter-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.work__filters-btn {
  padding: 16px 8px;
  width: 100%;
  box-sizing: inherit;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fafafb;
  background: #009ed1;
  border-radius: 10px;
  transition: opacity 1s ease;
}

.work__filters-btn:hover {
  opacity: 0.5;
}

.jobs__group-element {
  margin-bottom: 11px;
}

.work__workers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.work__workers-more {
  display: none;
  width: 234px;
  text-align: center;
  padding: 21px 0;
  margin: 48px auto 0 auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #009ed1;
  border: 2px solid #009ed1;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.work__workers-more:hover {
  opacity: 0.5;
}

@media (max-width: 1320px) {
  .active {
    display: block;
  }

  .work {
    padding-bottom: 37px;
  }

  .work__filters {
    display: none;
  }

  .invisable-menu {
    padding-top: 59px;
    box-sizing: border-box;
    position: fixed;
    z-index: 3;
    display: block;
    background: #fafafb;
    width: 100%;
    padding-right: 20px;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: -10px;
    padding-left: 10px;
    height: 100vh;
  }

  .work__filters-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    row-gap: 20px;
    column-gap: 20px;
  }

  .work__filters-element {
    margin-bottom: 0px;
    width: 95%;
    border-radius: 4px;
  }

  .work__filters-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
  }

  .work__filters-caption-back {
    height: 30px;
    align-items: center;
  }

  .work__filters-caption-back-img {
    background: url("~@/assets/img/vacancies-page/search/back.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30px;
    height: 9px;
  }

  .work__filters-caption-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    line-height: 35.5px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .work__filters-btn {
    width: 25%;
  }

  .work__filters-btn-container {
    margin: 37px 0 56px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1100px) {
  .work__workers {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 950px) {
  .work__workers {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .work__filters-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .work__workers-more {
    margin-top: 37px;
    width: 300px;
    padding: 17px 0;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .work__filters-btn {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .work__filters-group {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 14px;
  }

  .work__filters-element-title {
    font-size: 15px;
  }

  .work__filters-element-filter-checkbox+label {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .work__workers {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

/* Work */

/* Select */

.test__top-bottom-work-container {
  height: 100%;
  position: relative;
  width: 22.2%;
  border-left: 1px solid #f1f1f5;
}

.test__top-bottom-work-container::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/work.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  left: 36px;
  top: 28px;
}

.test__top-bottom-work-container {
  height: 100%;
  position: relative;
  width: 22.2%;
}

.test__top-bottom-work-container::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/work.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  left: 36px;
  top: 28px;
}

.test__top-bottom-work {
  text-align: left;
  position: relative;
  padding-left: 60px;
  width: 100%;
  height: 70px;
  cursor: pointer;
  border-left: 1px solid #f1f1f5;
  border-right: none;
  border-top: none;
  border-bottom: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171725;

  height: inherit;
  flex-shrink: 1;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  transition: background-color 1s ease;
}

.test__top-bottom-work-group {
  position: absolute;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 70px;
  left: 0;
  z-index: 10;
  border-radius: 0px 0px 7px 7px;
  box-shadow: inset -1px -1px 0px #f1f1f5;
}

.test__top-bottom-work-group-element-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
  border: 1px solid #eaeaee;
}

.test__top-bottom-work-group-element-label:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.test__top-bottom-work-group-element-label:last-child {
  border-radius: 0px 0px 7px 7px;
}

.test__top-bottom-work-group-element-input {
  -webkit-appearance: none;
  height: 0px;
}

@media (max-width: 1200px) {
  .test__top-bottom-work-container {
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #f1f1f5;
  }

  .test__top-bottom-work-container::after {
    left: 16px;
    top: 17px;
  }

  .test__top-bottom-work {
    border-radius: 10px;
    padding-left: 41px;
  }

  .test__top-bottom-work-group {
    top: 48px;
    border-radius: 7px;
  }

  .test__top-bottom-work-group-element-label:nth-child(1) {
    border-radius: 7px;
  }

  .first-label {
    border-radius: 7px 7px 0 0;
  }

  /* search */
  .test__top-bottom-town {
    padding-left: 42px;
    background-position: 18px;
  }

  .search-wrapper {
    font-size: 14px;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search-list {
    z-index: 101;
  }

  .test__top-bottom-town {
    border-radius: 10px;
  }

  .search-list {
    border-radius: 10px;
  }
}

@media (max-width: 760px) {
  .test__top-bottom-work {
    font-size: 14px;
  }

  .test__top-bottom-work-group-element-label {
    font-size: 14px;
  }
}

/* /Select */

/* Price select */
.search__sorting-price-container {
  margin-left: 20px;
  width: 177px;
  border: 1px solid #b4b4bb;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  display: block;
  height: 35px;
  align-items: center;
  transition: border 1s ease;
}

.search__sorting-price-container:hover {
  border: 1px solid #009ed1;
}

.search__sorting-price {
  padding: 9px 12px;
  width: 100%;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: color 1s ease;
  background-image: url("~@/assets/img/all-img/list-grey.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
}

.search__sorting-price:hover {
  color: #19191d;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search__sorting-price-group {
  background: #fafafb;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 100;
}

.search__sorting-price-element-input {
  -webkit-appearance: none;
  height: 0px;
}

.search__sorting-price-element-label {
  cursor: pointer;
  padding: 7px 12px;
  width: 100%;
  border: 1px solid #b4b4bb;
  border-top: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
}

.search__sorting-price-element-label:hover {
  background-color: #eefbff;
  color: #009ed1;
}

.search__sorting-price-element-label:last-child {
  border-radius: 0px 0px 4px 4px;
}

@media (max-width: 766px) {
  .search__sorting-price-container {
    margin-left: 0;
  }
}

/* /Price select */
.select_picked {
  background-color: #eefbff;
  color: #009ed1;
}

.sort_picked {
  background-color: #eefbff;
  color: #009ed1;
}
</style>
