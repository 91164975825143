<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="registration">
				<div class="registration__img">
					<img src="~@/assets/img/registration-page/background-logo.svg" alt=""
						class="registration__img-logo" />
				</div>
				<div class="registration__wrapper">
					<div class="registration__wrapper-authorization">
						<div class="registration__wrapper-authorization-who">
							<p class="registration__wrapper-authorization-text">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.registration') }}
							</p>
							<h1 class="registration__wrapper-authorization-title">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.createAccount') }}
							</h1>
							<div class="registration__wrapper-authorization-who-btns">
								<button class="registration__wrapper-authorization-who-btns-employer">
									{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.employer') }}
								</button>
								<router-link :to="{ name: 'candidateReg' }"
									class="registration__wrapper-authorization-who-btns-worker">
									{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.jobSeeker') }}
								</router-link>
							</div>
							<div class="registration__wrapper-help">
								<router-link :to="{ name: 'candidateLogin' }"
									class="registration__wrapper-help-registration">
									{{
										$t('pages.authorization.authorizationEmploerSmallRegistrationPage.alreadyHaveAccount')
									}}
								</router-link>
							</div>
						</div>

						<div class="registration__wrapper-authorization-fields">
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.companyName') }}
							</p>
							<input type="text" :placeholder="$t('pages.authorization.authorizationEmploerSmallRegistrationPage.enterOrganizationName')"
								class="registration__wrapper-authorization-mail" v-model="dataEmployer.orgName" />
							<div v-if="$v.dataEmployer.orgName.$error" class="error-message">
								<span>{{
									$t('pages.authorization.authorizationEmploerSmallRegistrationPage.nameRequired')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.phoneNumber') }}
							</p>
							<PhoneMaskInput v-model="dataEmployer.phone" :placeholder="$t('pages.authorization.authorizationEmploerSmallRegistrationPage.enterPhoneNumber')" showFlag
								wrapperClass="registration__wrapper-authorization-mail"></PhoneMaskInput>
							<div v-if="$v.dataEmployer.phone.$error" class="error-message">
								<span>{{
									$t('pages.authorization.authorizationEmploerSmallRegistrationPage.phoneIncorrect')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.email') }}
							</p>
							<input type="email" :placeholder="$t('pages.authorization.authorizationEmploerSmallRegistrationPage.enterEmail')" v-model="dataEmployer.email"
								class="registration__wrapper-authorization-mail" />
							<div v-if="$v.dataEmployer.email.$error" class="error-message">
								<span v-if="!$v.dataEmployer.email.required">{{
									$t('pages.authorization.authorizationEmploerSmallRegistrationPage.emailRequired')
									}}</span>
								<span v-else-if="!$v.dataEmployer.email.email">{{
									$t('pages.authorization.authorizationEmploerSmallRegistrationPage.emailInvalid')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.password') }}
							</p>
							<div class="registration__wrapper-authorization-password">
								<input type="password" :placeholder="$t('pages.authorization.authorizationEmploerSmallRegistrationPage.enterPassword')" v-model="dataEmployer.password"
									class="registration__wrapper-authorization-password-input" />
							</div>
							<div v-if="$v.dataEmployer.password.$error" class="error-message">
								<span>{{
									$t('pages.authorization.authorizationEmploerSmallRegistrationPage.passwordInvalid')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.confirmPassword')
								}}
							</p>
							<div class="registration__wrapper-authorization-password">
								<input type="password" :placeholder="$t('pages.authorization.authorizationEmploerSmallRegistrationPage.confirmPassword')"
									v-model="dataEmployer.checkPassword"
									class="registration__wrapper-authorization-password-input" />
								<div v-if="$v.dataEmployer.checkPassword.$error" class="error-message">
									<span v-if="!$v.dataEmployer.checkPassword.sameAsPassword">{{
										$t('pages.authorization.authorizationEmploerSmallRegistrationPage.passwordsMustMatch')
										}}</span>
								</div>
							</div>
							<button class="registration__wrapper-authorization-enter-btn" @click="saveData">
								{{ $t('pages.authorization.authorizationEmploerSmallRegistrationPage.register') }}
							</button>
						</div>
					</div>

				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";
import PhoneMaskInput from "vue-phone-mask-input";
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
	components: { Header, PhoneMaskInput },

	mixins: [validationMixin],
	validations: {
		dataEmployer: {
			orgName: { required, maxLength: maxLength(100) },
			phone: { required, minLength: minLength(10), maxLength: maxLength(14) },
			email: { required, email, maxLength: maxLength(100) },
			password: {
				required,
				maxLength: maxLength(100),
				minLength: minLength(8),
				hasUpperCase: value => /[A-Z]/.test(value),
				hasLowerCase: value => /[a-z]/.test(value),
				hasNumeric: value => /[0-9]/.test(value),
				hasSpecial: value => /[\W]/.test(value)
			},
			checkPassword: {
				sameAsPassword: sameAs('password')
			}
		},

	},

	data() {
		return {
			dataEmployer: {
				orgName: null,
				password: null,
				phone: null,
				email: null,
				checkPassword: null
			},

		};
	},
	beforeMount() {
		scroll(0, 0);
	},
	methods: {
		saveData() {
			let a = false;
			this.$v.$touch()
			if (this.$v.$invalid) {
				a = true;
			}

			if (this.checkNullField()) {
				if (a === true) {
					return
				}
				let xmlHhtp = new XMLHttpRequest();
				let url = config.apiPrefix + "employer/register";
				xmlHhtp.open("POST", url, false);
				xmlHhtp.setRequestHeader(
					"Content-Type",
					"application/json;charset=UTF-8"
				);
				try {
					xmlHhtp.send(JSON.stringify(this.dataEmployer));
					errorHandler({ status: xmlHhtp.status, page: "reg" });
					this.$router.push({ name: "employerLogin" });
				} catch (e) {
					console.log(e);
				}
			}
		},
		checkNullField() {
			let stack = [];
			if (!this.dataEmployer.orgName || this.dataEmployer.orgName.replace(/\s/g, "") === '')
				stack.push('companyName');
			if (!this.dataEmployer.phone || this.dataEmployer.phone.replace(/\s/g, "") === '')
				stack.push('phone');
			if (!this.dataEmployer.email || !this.checkEmail(this.dataEmployer.email) || this.dataEmployer.email.replace(/\s/g, '') === '')
				stack.push('email');
			if (!this.dataEmployer.password || !this.repeatingChar(this.dataEmployer.password))
				stack.push('passwordReplay');
			if (!this.dataEmployer.checkPassword || this.dataEmployer.checkPassword !== this.dataEmployer.password)
				stack.push('passwordNotMatch');
			if (stack.length !== 0) {
				errorHandler({ status: 400, page: 'invalidField', stack: stack });
				stack = [];
				return false;
			} else {
				return true;
			}
		},
		validateEmail(email) {
			const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return re.test(String(email).toLowerCase());
		},
		repeatingChar(value) {
			const element = value[0];
			let count = 0;
			for (let i = 0; i < value.length; i++) {
				if (value[i] === element)
					count++;
			}
			return count !== value.length;
		},
		checkEmail(value) {
			const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return regex.test(value)
		}
	},
	mounted() {
		//this.phoneValidation();
	},
	name: "AuthorizationEmploerSmallRegistrationPage",
};
</script>


<style scoped>
.error-message {
	color: red;
	font-size: 12px;
}

.phone-flag {
	margin: 0;
	padding: 0;
}

.registration {
	display: flex;
	padding-top: 58px;
	padding-right: 40px;
	align-items: flex-start;
}

.registration__img {
	padding: 45px 0;
	display: flex;
	max-width: 733px;
	height: calc(100vh - 59px);
	position: sticky;
	top: 59px;
	width: 100%;
	background: url("~@/assets/img/login-page/background.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.registration__img-logo {
	max-width: 328px;
	margin: auto;
	height: 187px;
}

.registration__wrapper {
	padding-top: 61px;
	margin: auto auto auto 144px;
	max-width: 683px;
	width: 100%;
	padding-bottom: 30px;
}

.registration__wrapper-authorization {
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 8px;
	width: 100%;
	box-sizing: border-box;
}

.registration__wrapper-authorization-who {
	padding: 27px 63px 20px 63px;
	border-bottom: 2px solid #f6f4f3;
}

.registration__wrapper-authorization-fields {
	padding: 9px 63px 34px 63px;
}

.registration__wrapper-authorization-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.registration__wrapper-authorization-title {
	padding-top: 5px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
	padding-bottom: 16px;
}

.registration__wrapper-authorization-who-btns {
	align-items: center;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	border: 2px solid #009ed1;
	border-radius: 10px;
}

.registration__wrapper-authorization-who-btns-employer {
	width: 100%;
	box-sizing: border-box;
	height: 100%;
	border-radius: 5px 0px 0px 5px;
	background: #009ed1;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	display: block;
	padding: 16px 0;
	text-align: center;
	color: #fff;
	transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-border {
	display: block;
	content: "";
	height: 100%;
	width: 2px;
	background: #009ed1;
}

.registration__wrapper-authorization-who-btns-worker {
	width: 100%;
	box-sizing: border-box;
	height: 100%;
	border-radius: 0px 10px 10px 0px;
	background: #fff;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	padding: 16px 0;
	text-align: center;
	color: #1c283f;
	transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-worker:hover {
	background: #009ed1;
	color: #ffffff;
	border-radius: 0px 5px 5px 0px;
}

.registration__wrapper-authorization-designation {
	margin-top: 21px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.registration__wrapper-authorization-designation span {
	color: red;
}

.registration__wrapper-authorization-mail {
	display: flex;
	align-items: center;
	margin-top: 8px;
	width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #171725;
	box-sizing: border-box;
}

.registration__wrapper-authorization-mail::placeholder {
	color: #b7c1d1;
}

.registration__wrapper-authorization-password {
	margin-top: 8px;
	width: 100%;
	border: none;
	align-items: center;
}

.registration__wrapper-authorization-password-input {
	border: 1px solid #bcbcbc;
	width: 100%;
	box-sizing: border-box;
	padding: 13px 16px;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #171725;
}

.registration__wrapper-authorization-password-input::placeholder {
	color: #b7c1d1;
}

.registration__wrapper-authorization-enter-btn {
	margin-top: 31px;
	width: 100%;
	box-sizing: border-box;
	height: 65px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
}

.registration__wrapper-authorization-fields-text {
	padding-top: 22px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	text-align: center;
	color: #9aafb6;
}

.registration__wrapper-help {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.registration__wrapper-help-registration {
	text-decoration: underline;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #009ed1;
}

.mobile {
	display: none;
}

@media (max-width: 1250px) {
	.registration__wrapper {
		margin: auto auto auto 80px;
	}
}

@media (max-width: 1000px) {
	.registration {
		flex-direction: column;
		padding-right: 0;
	}

	.registration__img {
		display: none;
	}

	.registration__wrapper {
		box-sizing: border-box;
		margin: 50px auto auto auto;
		max-width: 683px;
		width: 100%;
		padding: 0px 20px 30px 20px;
	}
}

@media (max-width: 950px) {
	.registration {
		padding-top: 48px;
	}
}

@media (max-width: 600px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.registration__wrapper {
		margin: 28px auto auto auto;
	}

	.registration__wrapper-authorization {
		padding: 0;
		border: none;
		background: inherit;
	}

	.registration__wrapper-authorization-who {
		padding: 0 10px 20px 10px;
	}

	.registration__wrapper-authorization-fields {
		padding: 8px 10px 0px 10px;
	}

	.registration__wrapper-authorization-who-btns {
		margin-top: 22px;
	}

	.registration__wrapper-authorization-who-btns-employer {
		padding: 13px 0;
		font-size: 12px;
	}

	.registration__wrapper-authorization-who-btns-worker {
		font-size: 12px;
		padding: 13px 0;
	}

	.registration__wrapper-authorization-text {
		font-size: 13px;
	}

	.registration__wrapper-authorization-title {
		font-size: 20px;
		padding-bottom: 0;
	}

	.registration__wrapper-authorization-designation {
		margin-top: 14px;
	}

	.registration__wrapper-authorization-mail {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorizationpassword-input {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorization-password-input {
		padding: 12px 18px;
		font-size: 14px;
	}

	.registration__wrapper-authorization-enter-btn {
		margin-top: 50px;
		height: 42px;
		border-radius: 10px;
		font-size: 13px;
	}

	.registration__wrapper-authorization-fields-fields-addfields {
		margin-top: 13px;
		width: 100%;
		padding: 13px 0;
		border: 1px solid #009ed1;
		border-radius: 10px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 13px;
		text-align: center;
		color: #009ed1;
	}

	.registration__wrapper-authorization-fields-text {
		font-size: 12px;
	}

	.registration__wrapper-help {
		margin-top: 20px;
	}

	.registration__wrapper-help-registration {
		padding-right: 0px;
		font-size: 13px;
	}
}
</style>
