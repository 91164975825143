<template>
  <div v-on:keyup.enter="submitLogin">
    <Header></Header>

    <main class="main">
      <div class="login">
        <div class="login__img">
          <img src="~@/assets/img/login-page/background-logo.svg" alt="" class="login__img-logo" />
        </div>
        <div class="login__wrapper">
          <div class="login__wrapper-authorization">
            <h1 class="login__wrapper-authorization-title">{{ $t('pages.authorization.candidateLoginPage.signIn') }}
            </h1>
            <p class="login__wrapper-authorization-designation">{{ $t('pages.authorization.candidateLoginPage.email') }}
            </p>
            <input type="email" placeholder="E-mail@website.ru" class="login__wrapper-authorization-mail"
              v-model="email" />
            <p class="login__wrapper-authorization-designation">{{ $t('pages.authorization.candidateLoginPage.password')
              }}</p>
            <label for="password" class="login__wrapper-authorization-password">
              <input id="password" ref="pass" type="password" :placeholder="$t('pages.authorization.candidateLoginPage.password')" name="password"
                class="login__wrapper-authorization-password-input" v-model="password" />
              <button class="login__wrapper-authorization-password-btn" @click="viewPass"></button>
            </label>
            <button class="login__wrapper-authorization-enter-btn" @click="submitLogin">
              {{ $t('pages.authorization.candidateLoginPage.signInButton') }}
            </button>
          </div>
          <div class="login__wrapper-help">
            <router-link :to="{ name: 'candidateReg' }" class="login__wrapper-help-login">
              {{ $t('pages.authorization.candidateLoginPage.noAccount') }}
            </router-link>
            <router-link :to="{ name: 'restorePassword', params: { id: 'candidate' } }"
              class="login__wrapper-help-losepassword">
              {{ $t('pages.authorization.candidateLoginPage.forgotPassword') }}
            </router-link>
          </div>
        </div>
      </div>
    </main>

  </div>

</template>

<script>
import Header from "../../components/landing/Header.vue";
import Cookies from "js-cookie";
import { errorHandler } from "@/lib/errorHandler";

export default {
  components: { Header },
  name: "CandidateLoginPage",
  data() {
    return {
      email: null,
      password: null,
    };
  },
  beforeMount() {
    scroll(0, 0);
  },
  methods: {
    validationLogin() {
      const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const regexPhone = /^\d+$/;
      return regexEmail.test(this.email) || regexPhone.test(this.email);
    },
    submitLogin: async function () {
      if (await this.validationLogin()) {
        await this.$store.dispatch("authorization", {
          login: this.email,
          password: this.password,
        }).then(() => {
          this.$store.getters.role === 'CANDIDATE' ? this.$router.push({ name: 'dashboard' }) : this.$store.getters.role === 'EMPLOYER' ? this.$router.push({ name: 'dashboardCompany' }) : null;
        });
      } else errorHandler({ status: 400, page: 'login' })

    },
    viewPass() {
      let pass = this.$refs.pass;
      if (pass.getAttribute("type") === "password")
        this.$refs.pass.setAttribute("type", "text");
      else this.$refs.pass.setAttribute("type", "password");
    },
  },
  destroyed() {
    Cookies.remove('vacancyId');
  }
};
</script>


<style scoped>
.login {
  padding-top: 58px;
  padding-right: 40px;
  display: flex;
  height: 100vh;
}

.login__img {
  padding: 20px 0;
  display: flex;
  max-width: 733px;
  height: 100%;
  width: 100%;
  background: url("~@/assets/img/login-page/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.registration__wrapper-authorization-who-btns {
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #009ed1;
  border-radius: 10px;
}

.registration__wrapper-authorization-who-btns-employer {
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  background: #fff;
  font-family: "Inter";
  font-weight: 600;
  font-size: 13px;
  padding: 16px 0;
  text-align: center;
  color: #1c283f;
  transition: color 1s ease, background 1s ease, border-radius 1s ease;
  border-radius: 10px 0px 0px 10px;
}

.registration__wrapper-authorization-who-btns-border {
  display: block;
  content: "";
  height: 100%;
  width: 2px;
  background: #009ed1;
}

.registration__wrapper-authorization-who-btns-worker {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  border-radius: 0 5px 5px 0;
  background: #009ed1;
  font-family: "Inter";
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 16px 0;
  text-align: center;
  color: #fff;
  transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-employer:hover {
  background: #009ed1;
  color: #ffffff;
  border-radius: 5px 0px 0px 5px;
}

.login__img-logo {
  max-width: 328px;
  margin: auto;
  height: 187px;
}

.login__wrapper {
  padding: 20px 0px;
  margin: auto auto auto 144px;
  max-width: 683px;
  width: 100%;
}

.login__wrapper-authorization {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 8px;
  padding: 27px 63px 50px 63px;
  width: 100%;
  box-sizing: border-box;
}

.login__wrapper-authorization-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.login__wrapper-authorization-title {
  padding-top: 5px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 35px;
  color: #000000;
  padding-bottom: 16px;
}

.login__wrapper-authorization-designation {
  margin-top: 21px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #595959;
}

.login__wrapper-authorization-mail {
  margin-top: 8px;
  width: 100%;
  padding: 21px 16px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #171725;
  box-sizing: border-box;
}

.login__wrapper-authorization-mail::placeholder {
  color: #b7c1d1;
}

.login__wrapper-authorization-password {
  display: flex;
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border: 1px solid #bcbcbc;
  align-items: center;
  border-radius: 8px;
  position: relative;
  color: #171725;
}

.login__wrapper-authorization-password-input {
  width: 100%;
  padding: 22px 56px 22px 16px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  letter-spacing: 0.25px;
  color: #171725;
}

.login__wrapper-authorization-password-input::placeholder {
  color: #b7c1d1;
}

.login__wrapper-authorization-password-btn {
  position: absolute;
  background: url("~@/assets/img/login-page/login-password-btn-eye.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 14px;
  right: 17px;
  top: calc(50% - 7);
}

.login__wrapper-authorization-enter-btn {
  margin-top: 31px;
  width: 100%;
  box-sizing: border-box;
  height: 65px;
  background: #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  transition: opacity 1s ease;
}

.login__wrapper-authorization-enter-btn:hover {
  opacity: 0.5;
}

.login__wrapper-help {
  margin-top: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__wrapper-help-login {
  text-decoration: underline;
  padding-right: 30px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #009ed1;
  transition: opacity 1s ease;
}

.login__wrapper-help-login:hover {
  opacity: 0.5;
}

.login__wrapper-help-losepassword {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: #9aafb6;
  transition: opacity 1s ease;
}

.login__wrapper-help-losepassword:hover {
  opacity: 0.5;
}

@media (max-width: 1250px) {
  .login__wrapper {
    margin: auto auto auto 80px;
  }
}

@media (max-width: 1000px) {
  .login {
    flex-direction: column;
    padding-right: 0;
  }

  .login__img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    width: 100%;
    height: 220px;
    padding: 20px 0;
  }

  .login__img-logo {
    width: 141px;
    height: 86px;
  }

  .login__wrapper {
    box-sizing: border-box;
    margin: 50px auto auto auto;
    max-width: 683px;
    width: 100%;
    padding: 0px 20px 30px 20px;
  }
}

@media (max-width: 950px) {
  .login {
    padding-top: 48px;
  }
}

@media (max-width: 600px) {

  .login__wrapper {
    margin: 25px auto auto auto;
    padding: 0;
    padding-bottom: 30px;
  }

  .registration__wrapper-authorization-who-btns {
    margin-top: 22px;
  }

  .registration__wrapper-authorization-who-btns-employer {
    padding: 13px 0;
    font-size: 12px;
  }

  .registration__wrapper-authorization-who-btns-worker {
    font-size: 12px;
    padding: 13px 0;
  }

  .login__wrapper-authorization {
    padding: 0px 9px 0px 9px;
    border: none;
    background: inherit;
  }

  .login__wrapper-authorization-text {
    font-size: 13px;
  }

  .login__wrapper-authorization-title {
    font-size: 20px;
    padding-bottom: 0;
  }

  .login__wrapper-authorization-designation {
    margin-top: 14px;
  }

  .login__wrapper-authorization-mail {
    font-size: 14px;
    padding: 12px 18px;
  }

  .login__wrapper-authorizationpassword-input {
    font-size: 14px;
    padding: 12px 18px;
  }

  .login__wrapper-authorization-password-input {
    font-size: 14px;
    padding: 12px 49px 12px 18px;
  }

  .login__wrapper-authorization-enter-btn {
    margin-top: 76px;
    height: 42px;
    border-radius: 10px;
    font-size: 13px;
  }

  .login__wrapper-help {
    margin-top: 20px;
    flex-direction: column;
  }

  .login__wrapper-help-login {
    padding-right: 0px;
    margin-bottom: 19px;
    font-size: 13px;
  }

  .login__wrapper-help-losepassword {
    font-size: 13px;
  }
}
</style>
