import Vuex from 'vuex';
import { vacancies } from "./landing/vacancies";
import Vue from "vue";
import {userData} from "./user/userData";
import {questions} from "./questions/questions";
import {interview} from "./interview/interview";
import {interviewConstruct} from "@/store/interview/interviewConstruct";
import {examinationInterview} from "@/store/interview/examinationInterview";
import {vacancy} from "./vacancy/vacancy";
import {blog} from "./landing/blog";
import {homePage} from "@/store/landing/homePage";
import {documentsCandidate} from "@/store/documents/documentsCandidate";
import {friends} from "@/store/friends/friends";
import {communities} from "@/store/communities/communities";
import {chat} from './chat/chat';
import {chatCommunity} from "@/store/user/chatCommunity";
import {complaint} from "@/store/complaint/complaint";
import {documentsCandidateCopy} from "@/store/documents/documentsCandidateCopy";
import {testing} from "@/store/testing/testing";
import {webSocket} from "@/store/chat/websocket";


Vue.use(Vuex);

// const i18nPlugin = store => {
//     store.subscribeAction({
//         before: (action) => {
//             if (action.payload && action.payload.root) {
//                 action.payload.$t = action.payload.root.$t;
//             }
//         }
//     });
// };

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		webSocket,
		vacancies,
		userData,
		questions,
		interview,
		interviewConstruct,
		examinationInterview,
		vacancy,
		blog,
		homePage,
		documentsCandidate,
		friends,
		communities,
		chat,
		chatCommunity,
		documentsCandidateCopy,
		testing,
		complaint
	}
});