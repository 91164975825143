<template>
	<div class="community-wrapper">
		<div class="communitys-separate__left">
			<div class="community__face">
				<img :src="community.avatar" alt="" class="community__face-img">
			</div>
			<div class="community__info">
				<h3 class="community__info-name">
					{{ community.name }}
					<span class="community__status"
						:class="{ 'status-cancellation': community.communityType === 'close' }">
						{{ community.communityType === 'close' ?
							$t('components.social.separateCommunityMainChange.closed') :
							$t('components.social.separateCommunityMainChange.open') }}
					</span>
				</h3>
				<p class="community__info-designation">
					{{ community.userCount ? community.userCount : 0 }}
					{{ $t('components.social.separateCommunityMainChange.participants') }}
				</p>
			</div>
		</div>
		<div class="community__end community-mobile">
			<router-link v-if="community.communitiesType === 'close'"
				:to="{ name: 'communitiesIncomingRequest', params: { communitiesId: community.id } }"
				class="community__wright">
				{{ $t('components.social.separateCommunityMainChange.applications') }}
				<span class="friends-messages">99+</span>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "SeparateCommunityMainChange",
	props: ['community'],
	data() {
		return {
			menuVisible: false,
		};
	},
};
</script>


<style scoped>
.community-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	margin-bottom: 20px;
	align-items: center;
}


.communitys-separate__left {
	display: flex;
}

.community__face {
	position: relative;
}

.community__face-img {
	width: 64px;
	height: 64px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
}


.community__info {
	margin-left: 19px;
}

.community__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.community__status {
	margin-left: 10px;
	padding: 5px 10px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	color: #14c686;
}

.community__status.status-cancellation {
	color: #e7665e;
	background: rgba(231, 102, 94, 0.20000000298023224);
}

.community__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.community__info-designation {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}


.community__message-settings {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.community__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.community__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin-left: auto;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.community__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -113px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;
}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.popap-list__button:last-child {
	padding-bottom: 10px;
}


.community__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.community-mobile {
	display: none;
}

@media (max-width: 1575px) {
	.community-wrapper {
		max-width: 100%;
		padding: 0;
		padding: 20px 14px;
	}

	.community-desctop {
		display: none;
	}


	.communitys-separate__left {
		align-items: center;
	}


	.community__info {
		width: 100%;
	}

	.community__info-name {
		font-size: 13px;
		display: flex;
		align-items: center;
	}

	.community__place-town {
		color: #696974;
		margin-top: 5px;
	}


	.community__info-speciality {
		margin-top: 2px;
	}

	.community__status {
		padding: 5px 11px;
		font-size: 10px;
		line-height: 1;
	}

	.community__info-designation {
		margin-top: 6px;
		align-items: center;
		padding-top: 0;
	}


}

@media (max-width: 950px) {
	.community-mobile {
		display: flex;
	}

	.community__face-img {
		width: 40px;
		height: 40px;
	}

	.community__info {
		margin-left: 11px;
	}

	.community-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}

	.community__settings {
		display: none;
	}

	.community__message {
		margin-top: 20px;
		background: #009ed1;
		border-radius: 8px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 12px;
		color: #ffffff;
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.community__end {
		width: 100%;
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	.community__wright {
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: 0.02em;
		color: #009ed1;
	}

	.friends-messages {
		margin-left: 11px;
		font-family: "Montserrat";
		font-weight: 600;
		font-size: 12px;
		display: inline;
		padding: 3px 8px;
		background: #14c686;
		border-radius: 20px;
		color: #fff;
		line-height: 1;
		display: none;
	}

	.community__info-designation {
		padding-top: 6px;
		margin: 0;
	}
}
</style>
