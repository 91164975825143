<template>
  <div>
    <SocialHeader :role="$store.getters.role"></SocialHeader>
    <main class="main">
      <div class="account">
        <SocialMenu :role="$store.getters.role"></SocialMenu>
        <div class="all">
          <p class="subtitle">{{ $t('pages.social.possibleFriendsPage.friends') }}</p>
          <div class="title__wrapper">
            <div class="caption">
              <button @click="$router.go(-1)" class="back">
                <img src="~@/assets/img/account-img/friends/back.svg" alt="" class="back-img" />
              </button>
              <h1 class="title">{{ $t('pages.social.possibleFriendsPage.searchForFriends') }}</h1>
            </div>
            <button class="friends-who mobile-block" @click="statusVizable = !statusVizable">
              <p class="friends-who-text">{{ $t('pages.social.possibleFriendsPage.lists') }} <span
                  class="friends-who-img"></span></p>
              <div class="friends" v-if="statusVizable">
                <div class="friends__caption">
                  <router-link :to="{ name: 'friends' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.myFriends') }}</router-link>
                  <router-link :to="{ name: 'outgoingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.myApplications') }}</router-link>
                  <router-link :to="{ name: 'incomingFriend' }" class="friends__caption-btn">
                    {{ $t('pages.social.possibleFriendsPage.incomingApplications') }}
                    <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span>
                  </router-link>
                  <router-link :to="{ name: 'blockedUsers' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.blockedUsers') }}</router-link>
                </div>
                <div class="friends__best-container">
                  <a href="#" class="friends__best">
                    <span class="friends__best-icon"></span>
                    <span class="friends__best-text">{{ $t('pages.social.possibleFriendsPage.findFriends') }}</span>
                  </a>
                </div>
              </div>
            </button>
          </div>
          <nav class="nav desctop-block">
            <div class="list">
              <button @click="changeRole('all')" class="list__item"
                :class="{ 'list__item-selected': role === 'all' }">{{ $t('pages.social.possibleFriendsPage.allFriends')
                }}</button>
              <button @click="changeRole('candidate')" class="list__item"
                :class="{ 'list__item-selected': role === 'candidate' }">{{
                  $t('pages.social.possibleFriendsPage.jobSeekers') }}</button>
              <button @click="changeRole('employer')" class="list__item"
                :class="{ 'list__item-selected': role === 'employer' }">{{ $t('pages.social.possibleFriendsPage.employers')
                }}</button>
            </div>
          </nav>
          <SurchBlue @deletePagination="deletePagination" @find="byName" @default="changeRole(role)" page="allUsers">
          </SurchBlue>
          <div class="filling">
            <div class="filling__people">
              <SeparatePossibleFriend v-show="user.id !== item.id" v-for="(item, index) in findUser" :key="item.id"
                :user="item" :index="index"></SeparatePossibleFriend>
            </div>
            <div class="interaction">
              <div class="friends">
                <div class="friends__caption">
                  <router-link :to="{ name: 'friends' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.myFriends') }}</router-link>
                  <router-link :to="{ name: 'outgoingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.myApplications') }}</router-link>
                  <router-link :to="{ name: 'incomingFriend' }" class="friends__caption-btn">
                    {{ $t('pages.social.possibleFriendsPage.incomingApplications') }}
                    <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span>
                  </router-link>
                  <router-link :to="{ name: 'blockedUsers' }" class="friends__caption-btn">{{
                    $t('pages.social.possibleFriendsPage.blockedUsers') }}</router-link>
                </div>
                <div class="friends__best-container find-friend-active">
                  <a href="#" class="friends__best">
                    <span class="friends__best-icon"></span>
                    <span class="friends__best-text">{{ $t('pages.social.possibleFriendsPage.findFriends') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button v-if="false" class="show-more">{{ $t('pages.social.possibleFriendsPage.seeAll') }}</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparatePossibleFriend from "../../components/social/account_friensds/SeparatePossibleFriend.vue";
import SurchBlue from "../../components/social/account_friensds/SurchBlue.vue";

export default {
  components: { SocialMenu, SocialHeader, SeparatePossibleFriend, SurchBlue },
  name: "SocialPossibleFriendsPage",
  computed: {
    findUser() {
      return this.$store.getters.getFindUsers;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  data() {
    return {
      statusVizable: false,
      role: null
    };
  },
  methods: {
    byName(name) {
      this.$store.commit('resetPages');
      this.$store.dispatch('findUser', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.role,
        name: name,
        limit: this.$store.getters.getLimit,
        callback: this.deletePagination
      });
    },
    changeRole(value) {
      this.role = value;
      this.$store.commit('resetPages');
      this.paginationLoad();
      this.$store.dispatch('findUser', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.role,
        limit: this.$store.getters.getLimit,
        callback: this.deletePagination
      });
    },
    addData() {
      this.$store.commit('pageUp');
      this.$store.dispatch('findUser', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.role,
        limit: this.$store.getters.getLimit,
        callback: this.deletePagination
      });
    },
    deletePagination() {
      window.onscroll = null;
    },
    paginationLoad() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.addData();
        }
      };
    }
  },
  beforeMount() {
    this.changeRole('all');
  }
};
</script>


<style scoped>
.friends-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}

.account {
  display: flex;
}

.all {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  padding: 59px 30px 0 30px;
}

.title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 26px;
  color: #3b4c68;
}

.nav {
  margin-top: 40px;
}

.caption {
  display: flex;
  align-items: center;
  padding-top: 37px;
}

.back {
  width: 30px;
  height: 30px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.list__item:not(:last-child) {
  margin-right: 40px;
}

.list__item {
  position: relative;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4c68;
}

.list__item.list__item-selected::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background: #009ed1;
}

.filling {
  margin-top: 40px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.filling__people {
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
}

.interaction {
  max-width: 380px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 30px;
}

.friends {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.friends__caption {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.friends__caption-btn {
  margin-top: 10px;
  padding: 5px 0 5px 22px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #44444f;

}

.friends__caption-btn.friends__caption-btn-selected {
  display: block;
  background: rgba(239, 239, 244, 0.5);
  position: relative;
}

.friends__caption-btn.friends__caption-btn-selected::before {
  position: absolute;
  content: "";
  left: -1px;
  border-radius: 0px 20px 20px 0;
  width: 2px;
  height: 100%;
  display: block;
  background: #009ED1;
  top: 0;
}

.friends__best-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.friends__best {
  display: flex;
  width: 100%;
  padding: 18px 6px 13px 6px;
  border-top: 1px solid #efeff4;
  box-sizing: border-box;
  align-items: center;

}

.friends__best-text {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #44444f;
  margin: 2px 0 0 3px;
}

.friends__best-icon {
  background: url("~@/assets/img/account-img/friends/surch.svg");
  width: 24px;
  height: 24px;
  display: block;
}

.find-friend-active {
  position: relative;
  background: rgba(239, 239, 244, 0.5) !important;
  color: #44444f;
}

.find-friend-active::before {
  position: absolute;
  content: "";
  left: -1px;
  border-radius: 0px 20px 20px 0;
  width: 2px;
  height: 100%;
  display: block;
  background: #009ED1;
  top: 0;
}

.mobile-block {
  display: none;
}

.show-more {
  display: none;
}

.subtitle {
  display: none;
}

@media (max-width: 1575px) {
  .filling__people {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .filling__people {
    margin-bottom: 0;
  }
}

@media (max-width: 1100px) {
  .filling__people {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 950px) {
  .title {
    padding-top: 0;
  }

  .all {
    padding: 76px 10px 30px 10px;
  }
}

@media (max-width: 720px) {
  .caption {
    padding-top: 0;
    align-items: unset;
  }

  .form {
    margin-top: 40px;
  }

  .filling {
    margin-top: 20px;
  }

  .desctop-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .title {

    font-size: 24px;
    line-height: 29px;
  }

  .title__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 6px;
  }

  .friends-who {
    margin-left: auto;
    text-align: left;
    padding: 10px 0 10px 10px;
    position: relative;
  }

  .friends-who-text {
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #3e4453;
    position: relative;
    align-items: center;
    display: flex;
  }

  .friends-who-text::before {
    position: absolute;
    left: -6px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #14c686;
    display: block;
    content: "";
    top: calc(50% - 2px);
  }

  .friends-who-img {
    display: block;
    background-image: url("~@/assets/img/account-img/friends/list-blue.svg");
    background-repeat: no-repeat;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    background-position-y: 1px;
  }

  .subtitle {
    display: block;
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #009ed1;
  }

  .all {
    padding: 76px 10px 30px 10px;
    max-width: 420px;
  }

  .friends {
    position: absolute;
    width: 300px;
    right: 0px;
    z-index: 30;
    top: 44px;
  }


  .interaction {
    display: none;
  }

  .show-more {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid #009ed1;
    border-radius: 7px;
    font-family: "SFUIDisplay";
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #009ed1;
  }

}
</style>
