<template>
	<div class="table-wrapper">
		<table class="table" border="1px">
			<tr class="table-row" v-for="(item, index) in data" :key="item.id">
				<th class="table-column" v-if="!item.delete">
					<input v-model="item.name" :disabled="status === 'checking' || status === 'approved'"
						placeholder="Item name" />
				</th>
				<th class="table-column" v-if="!item.delete">

					<input v-model="item.score" :disabled="status === 'checking' || status === 'approved'"
						placeholder="Number of points" @keydown="scoreValidation" size="3" />
				</th>
				<th class="table-column close-btn" v-if="!item.delete">
					<button v-if="status !== 'checking' && status !== 'approved'" tabindex="-1"
						@click="deleteRow(item.id, index)">
						<img src="~@/assets/img/all-img/documents/close.svg" alt="" />
					</button>
				</th>
			</tr>
		</table>

		<button class="button-add-row" tabindex="-1" @click="addData" v-if="status !== 'approved' && status !== 'checking'">
			Add a note
		</button>
	</div>
</template>

<script>
export default {
	name: "SchoolAtestate",
	props: {
		data: {},
		status: {}
	},
	data() {
		return {
			atestate: null,
		};
	},
	beforeMount() {
		this.atestate = this.data;
	},
	methods: {
		addData() {
			this.$store.commit("addAtestateData");
			this.scoreValidation();
			this.nameValidation();
		},
		checkNumber(i) {
			if (isNaN(Number(this.atestate[i].score))) {
				this.atestate[i].score = null;
			}
		},
		deleteRow(id, index) {
			this.$store.commit('deleteSchoolAtestateRow', {
				id: id,
				index: index
			})
			this.$forceUpdate();
		},
		scoreValidation(e) {
			if (e)
				if (e.code !== 'Backspace')
					if (!/\d/.test(e.key))
						e.preventDefault();
		},
	},
};
</script>

<style scoped>
.table-column {
	border: solid 1px;
	padding-bottom: 50px;
}

.table {
	max-width: 100%;
	width: 100%;
}

.table-wrapper {
	width: 100%;
	box-sizing: border-box;
	margin-left: 40px;
	position: relative;
	padding-bottom: 50px;
}

.button-add-row {
	margin: 0 auto;
	display: flex;
	margin-top: 20px;
	background: #009ed1;
	border-radius: 7px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	padding: 15px 60px;
	color: #ffffff;
	position: absolute;
	bottom: -25px;
	left: calc(50% - 132px);
}

.table-wrapper table {
	border: none;
}

.table-wrapper tr {
	border: none;
}

.table-wrapper th {
	border: none;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
}

.table-wrapper input ::placeholder {
	color: #c7c7cc;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.table-wrapper input {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
}

.table-row th:nth-child(1) {
	padding-right: 20px;
}

.table-row th {
	padding-bottom: 5px;
}

.table-row th:nth-child(2n) {
	padding-right: 20px;
}

th p {
	font-size: 20px;
	margin-bottom: 10px;
}

.close-btn {
	width: 16px;
}

@media (max-width: 816px) {
	.table-wrapper {
		margin-top: 20px;
		margin-left: 0;
	}

	tr {
		display: flex;
		flex-direction: column;
	}

	.table-row th:nth-child(2n + 1) {
		padding-right: 0px;
	}

	.table-row th:nth-child(2n) {
		padding-right: 0px;
	}

	.table-row th:nth-child(3n) {
		padding-bottom: 30px;
	}

	.table {
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.table-row th {
		padding-bottom: 10px;
	}

	.button-add-row {
		position: unset;
		max-width: 300px;
		width: 100%;
		display: block;
	}

	.table-row {
		width: 300px;
	}

	.table-column {
		text-align: center;
	}

	.close-btn {
		margin: 0 auto;
	}
}

@media (max-width: 330px) {
	.button-add-row {
		text-align: center;
	}
}
</style>