var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"chat__wrapper-dialogue-message",class:{
    'my-message': _vm.message.from === 'user' || _vm.message.from === 'myself',
    'over-message': _vm.message.from !== 'user' && _vm.message.from !== 'myself'
  }},[_c('p',{staticClass:"chat__wrapper-dialogue-messages-user-text",domProps:{"innerHTML":_vm._s(_vm.message.message.replaceAll('\n', '<br/>'))}}),_c('div',{staticClass:"chat__wrapper-dialogue-message-user-info"},[_c('time',{staticClass:"chat__wrapper-dialogue-message-user-info-time"},[_vm._v(_vm._s(Date.now() - _vm.message.createDate > 86400000 ? _vm.getDay(_vm.message.createDate) : _vm.getTime(_vm.message.createDate))+" ")]),_c('span',{class:{
        'messages-stage-shipped': !_vm.message.read,
        'chat__wrapper-dialogue-message-user-info-stage': _vm.message.read,
      }}),(_vm.role === 'admin')?_c('a',{staticClass:"chat__wrapper-dialogue-message-user-info-name"},[_vm._v(" "+_vm._s(_vm.role === 'admin' ? _vm.$t('compocomponents.account_company.separateCompanyChatMessage.adminName') : '')+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }