<template>
	<div>
	<Header></Header>
	<main class="main">
		<div class="caption">
		<div class="container caption-container">
			<div class="caption__topic">
			<button class="caption__topic-arrow" @click="$router.go(-1)">{{ $t('pages.landing.articlesPage.article.backButton') }}</button>
			<p class="caption__topic-text">{{ articles.categories[0] }}</p>
			<p class="caption__profile-date mobile">
				{{ new Date(articles.createDate).toISOString().slice(0, 10) }}
			</p>
			</div>
			<h1 class="caption__title">
			{{ articles.title }}
			</h1>
			<div class="caption__profile">
			<img :src="articles.avatarPath" alt="" class="caption__profile-face" />
			<div class="caption__profile-denomination">
				<p class="caption__profile-denomination-wrighter">
				{{ $t('pages.landing.articlesPage.article.articleAuthor') }}
				</p>
				<p class="caption__profile-denomination-name">
				{{ articles.author }}
				</p>
			</div>
			<p class="caption__profile-date">
				{{ new Date(articles.createDate).toISOString().slice(0, 10) }}
			</p>
			<button class="caption__profile-btn">{{ $t('pages.landing.articlesPage.article.shareButton') }}</button>
			</div>
		</div>
		</div>
		<div class="post">
		<div class="post__before">
			<img :src="articles.files[0]" alt="" class="post__before-img-big" />
			<img :src="articles.files[0]" alt="" class="post__before-img" />
		</div>
		<div class="post__after"></div>
		</div>
		<div class="filling">
		<div class="container caption-container">
			<div class="filling__wrapper">
			<div class="filling__wrapper-text" id="body"></div>
			<div class="filling__wrapper-links">
				<router-link class="filling__wrapper-links-element" v-for="item in articles.categories" :key="item.id" :to="{ name: 'blog', params: { category: item } }">
				{{ item }}
				</router-link>
			</div>
			</div>
		</div>
		<span class="filling__line"></span>
		<div class="caption-container">
			<div class="caption__profile filling-profile">
			<img :src="articles.avatarPath" alt="" class="caption__profile-face" />
			<div class="caption__profile-denomination filling-denomination">
				<p class="caption__profile-denomination-wrighter filling-wrighter">
				{{ $t('pages.landing.articlesPage.article.articleAuthor') }}
				</p>
				<p class="caption__profile-denomination-name filling-name">
				{{ articles.author }}
				</p>
			</div>
			<div class="filling__profile-networks">
				<a :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`" target="_blank" class="filling__profile-networks-facebook"></a>
				<a :href="`https://twitter.com/intent/tweet?url=${currentUrl}&text=${encodeURIComponent(articles.body)}`" target="_blank" class="filling__profile-networks-twitter"></a>
				<a :href="`https://pinterest.com/pin/create/button/?url=${currentUrl}&media=${encodeURIComponent(articles.files[0])}&description=${encodeURIComponent(articles.title)}`" target="_blank" class="filling__profile-networks-pn"></a>
			</div>
			</div>
		</div>
		</div>
		<div class="tidings">
		<div class="container">
			<div class="tidings__wrapper">
			<h3 class="tidings__wrapper-title">{{ $t('pages.landing.articlesPage.article.moreArticlesTitle') }}</h3>
			<h4 class="tidings__wrapper-subtitle">{{ $t('pages.landing.articlesPage.article.moreArticlesSubtitle') }}</h4>
			<div class="tidings__wrapper-smallarticle">
				<InterestingPage v-for="item in familiarArticles.slice(0, 2)" :key="item.id" :blog-data="item">
				</InterestingPage>
			</div>
			</div>
		</div>
		</div>
	</main>
	<Footer></Footer>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";
import InterestingPage from "../../components/landing/InterestingPage.vue";
import { inputParse } from "../../lib/textParser";

export default {
	components: { Header, Footer, InterestingPage },
	computed: {
		articles() {
			return this.$store.getters.articles;
		},
		familiarArticles() {
			return this.$store.getters.familiarArticles;
		},
	},
	props: {
		articlesId: {
			type: String,
		},
	},
	data(){
		return {
			currentUrl: window.location.href
		}
	},
	name: "Articles",
	beforeMount() {
		this.$store.dispatch("fetchArticles", this.articlesId);
		this.$store.dispatch("fetchFamiliarArticles", this.articles.categories[0]);
		this.articles.body = inputParse(this.articles.body)
		scroll(0, 0);
	},
	mounted() {
		document.getElementById("body").innerHTML = this.articles.body;
		let a = document.getElementById("body").getElementsByTagName("img");
		for (let i = 0; i < a.length; i++) {
			a[i].classList.add("filling__wrapper-text-img");
		}
	},
};
</script>

<style scoped>
/* Caption css */

.caption {
	background: #062b4d;
	position: relative;
	padding-bottom: 65px;
}

.caption__imgafter {
	position: absolute;
	max-width: 1230px;
	padding: 0px 15px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 402px auto 0 auto;
	box-sizing: border-box;
	width: 100%;
}

.caption__imgafter-img {
	height: 550px;
	width: 100%;
	border-radius: 8px;
	object-fit: cover;
	object-position: center;
}

.caption-container {
	overflow: visible;
	max-width: 1030px;
	margin: 0 auto;
	padding: 0px 15px;
	overflow: hidden;
	overflow: visible;
}

.caption__topic {
	padding-top: 170px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.caption__topic-text {
	text-transform: uppercase;
	text-align: center;
	padding: 6px 10px;
	background: rgba(255, 255, 255, 0.20000000298023224);
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #ffffff;
}

.caption__topic-arrow {
	width: 30px;
	height: 10px;
	background: url("~@/assets/img/articles-page/caption/arrow.svg");
	background-repeat: no-repeat;
	background-size: cover;
}

.caption__title {
	padding-top: 26px;
	max-width: 672px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 32px;
	color: #feffff;
}

.caption__profile {
	padding-top: 33px;
	display: flex;
	align-items: center;
}

.caption__profile-face {
	border-radius: 100%;
	background-position: center;
	width: 57px;
	height: 57px;
	flex-shrink: 0;
	object-fit: cover; /* Убедитесь, что изображение полностью заполняет контейнер */
}

.caption__profile-denomination {
	padding-left: 17px;
}

.caption__profile-denomination-wrighter {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #ffffff;
}

.caption__profile-denomination-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #ffffff;
}

.caption__profile-date {
	width: 99px;
	margin-left: auto;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 13px;
	text-align: center;
	color: #ffffff;
}

.mobile {
	display: none;
	margin-right: 0;
}

.caption__profile-btn {
	display: none;
	margin-left: 43px;
	padding: 16px 24px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 7px;
}

@media (max-width: 1230px) {
	.caption__imgafter {
		max-width: 1058px;
		margin: 335px auto 0 auto;
	}

	.caption__topic {
		padding-top: 96px;
	}

	.caption__topic-arrow {
		left: -59px;
	}
}

@media (max-width: 1000px) {
	.caption__imgafter-img {
		height: 375px;
	}
}

@media (max-width: 680px) {
	.caption__imgafter {
		padding: 0;
	}

	.caption__imgafter-img {
		height: 375px;
	}

	.caption__topic {
		padding-top: 103px;
	}

	.caption__title {
		font-size: 25px;
	}

	.caption__profile-face {
		width: 42px;
		height: 42px;
		background-size: cover;
	}

	.caption__profile-denomination-wrighter {
		display: none;
	}

	.caption__profile-date {
		display: none;
	}

	.mobile {
		display: block;
	}

	.caption__profile-btn {
		display: none;
	}

	.caption__imgafter {
		margin: 293px auto 0 auto;
	}
	.caption__topic-text{
		margin-left: 24px;
	}
}

@media (max-width: 440px) {
	.caption__imgafter {
		margin: 327px auto 0 auto;
	}
}

/* /Caption css */

/* Post */
.post {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
}

.post__before {
	height: 275px;
	width: 100%;
	box-sizing: border-box;
	background: #062b4d;
	position: relative;
}

.post__before-img {
	display: none;
}

.post__before-img-big {
	border-radius: 8px;
	display: block;
	max-width: 1200px;
	width: 100%;
	box-sizing: border-box;
	content: "";
	bottom: calc(-275px);
	left: calc(50% - 600px);
	position: absolute;
	width: 100%;
	height: 550px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.post__after {
	height: 275px;
	width: 100%;
	box-sizing: border-box;
	background: #fff;
}

@media (max-width: 1200px) {
	.post__before-img-big {
		max-width: 1030px;
		left: calc(50% - 515px);
	}
}

@media (max-width: 1040px) {
	.post__before {
		height: 550px;
	}

	.post__before-img-big {
		display: none;
	}

	.post__before-img {
		display: block;
		width: 100%;
		box-sizing: border-box;
		object-fit: cover;
		height: 100%;
	}

	.post__after {
		display: none;
	}
}

@media (max-width: 800px) {
	.post__before {
		height: 400px;
	}
}

@media (max-width: 680px) {
	.post__before {
		height: 375px;
	}
}

/* /Post */

/* /Filling  css */
.filling {
	background: #fff;
}

.filling__wrapper {
	padding-top: 80px;
	width: 100%;
	box-sizing: border-box;
}

.filling__wrapper-title {
	padding-top: 67px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 26px;
	color: #424242;
}

.filling__wrapper-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 20px;
	color: #424242;
}

.filling__wrapper-text-img {
	width: 100%;
	box-sizing: border-box;
	object-fit: cover;
	object-position: center;
}

.filling__wrapper-text-img {
	width: 100%;
	box-sizing: border-box;
	object-fit: cover;
	object-position: center;
}

.filling__wrapper-img {
	margin: 51px 0 47px 0;
	width: 100%;
	background: #303030;
	box-sizing: border-box;
	border-radius: 8px;
}

.filling__wrapper-links {
	margin-top: 107px;
	display: flex;
	justify-content: left;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
}

.filling__wrapper-links-element:not(:last-child) {
	margin-right: 13px;
}

.filling__wrapper-links-element {
	text-transform: uppercase;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 10px;
	text-align: center;
	color: #6c757d;
	padding: 5px 10px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	transition: color 1s ease, border 1s ease;
}

.filling__wrapper-links-element:hover {
	border: 1px solid #009ed1;
	color: #009ed1;
}

.filling__line {
	content: "";
	border-bottom: 1px solid #000000;
	max-width: 1230px;
	padding: 0px 15px;
	box-sizing: border-box;
	width: 100%;
	margin: 45px auto 0 auto;
	display: block;
}

.filling-wrighter {
	color: #000000;
}

.filling-name {
	color: #000000;
}

.filling__profile-networks {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.filling__profile-networks :not(:last-child) {
	margin-right: 13px;
}

.filling__profile-networks-facebook {
	background-image: url("~@/assets/img/articles-page/filling/Fb.svg");
	border-radius: 100%;
	width: 26px;
	height: 26px;
}

.filling__profile-networks-twitter {
	background-image: url("~@/assets/img/articles-page/filling/Tw.svg");
	width: 26px;
	height: 26px;
	border-radius: 100%;
}

.filling__profile-networks-pn {
	background-image: url("~@/assets/img/articles-page/filling/Pn.svg");
	width: 26px;
	border-radius: 100%;
	height: 26px;
}

.filling__profile-networks-be {
	background-image: url("~@/assets/img/articles-page/filling/Be.svg");
	width: 26px;
	border-radius: 100%;
	height: 26px;
}

.filling-profile {
	padding-bottom: 107px;
}

@media (max-width: 680px) {
	.filling {
		background: #fafafb;
	}

	.filling__wrapper {
		padding-top: 30px;
	}

	.filling__wrapper-text {
		font-size: 15px;
	}

	.filling__wrapper-img {
		margin: 37px 0 9px 0;
	}

	.filling__wrapper-title {
		padding-top: 29px;
	}

	.filling__wrapper-links {
		margin-top: 69px;
	}

	.filling-profile {
		padding-top: 32px;
	}

	.filling-profile {
		flex-direction: column;
		margin: 0 auto;
		padding-bottom: 56px;
	}

	.filling-face {
		width: 73px;
		height: 73px;
	}

	.filling-denomination {
		padding-top: 9px;
		padding-left: 0;
		text-align: center;
	}

	.filling-wrighter {
		display: block;
	}

	.filling__profile-networks {
		margin-left: 0;
		margin-top: 25px;
	}
}

/* /Filling  css */

/* /Tidings css */
.tidings {
	background-color: #fafafb;
}

.tidings__wrapper-title {
	padding-top: 105px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.tidings__wrapper-subtitle {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.tidings__wrapper-smallarticle {
	margin-top: 62px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 105px;
}

.tidings__wrapper-smallarticle :not(:last-child) {
	margin-right: 40px;
}

@media (max-width: 1020px) {
	.tidings__wrapper-smallarticle {
		flex-direction: column;
		margin-bottom: 65px;
	}
}

@media (max-width: 680px) {
	.tidings__wrapper-subtitle {
		font-size: 20px;
	}

	.tidings {
		background-color: #fff;
	}

	.tidings__wrapper-title {
		font-size: 13px;
		padding-top: 36px;
	}

	.tidings__wrapper-smallarticle :not(:last-child) {
		margin-right: auto;
	}

	.tidings__wrapper-smallarticle {
		margin-top: 36px;
		margin-bottom: 47px;
	}
}

/* /Tidings css */
</style>
