<template>
	<router-link :to="{ name: 'communitiesPage', params: { communitiesId: comData.id } }" class="community-wrapper">
		<div class="communitys-separate__left">
			<div class="community__face">
				<img :src="comData.avatarUrl" alt="" class="community__face-img">
			</div>
			<div class="community__info">
				<h3 class="community__info-name">{{ comData.name ? limit(comData.name, 25) :
					$t('components.social.separateCommunity.noData') }}
					<span class="community__status"
						:class="{ 'status-cancellation': comData.communityType === 'close' }">
						{{ (comData.communityType === 'close') ? $t('components.social.separateCommunity.closed') :
							$t('components.social.separateCommunity.open') }}
					</span>
				</h3>
				<p class="community__info-designation ">{{ comData.userCount ? comData.userCount : 0 }} {{
					$t('components.social.separateCommunity.participants') }}</p>
			</div>
		</div>
	</router-link>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
	name: "SeparateCommunity",
	props: ['comData'],
	data() {
		return {
			menuVisible: false,
			limit: textSymbolLimit
		};
	}
};
</script>


<style scoped>
.community-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	margin-bottom: 20px;
}

.communitys-separate__left {
	display: flex;
}

.community__face {
	position: relative;
}

.community__face-img {
	width: 64px;
	height: 64px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
}


.community__info {
	margin-left: 19px;
}

.community__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.community__status {
	margin-left: 10px;
	padding: 5px 10px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	color: #14c686;
}

.community__status.status-cancellation {
	color: #e7665e;
	background: rgba(231, 102, 94, 0.20000000298023224);
}

.community__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.community__info-designation {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}


.community__message {
	background: #009ed1;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	margin: auto 0 auto auto;
	width: 214px;
	height: 45px;
	box-shadow: 0px 4px 12px -6px rgba(0, 158, 209, 1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.community__message-settings {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.community__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin: auto 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.community__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -140px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;
}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.popap-list__button:last-child {
	padding-bottom: 10px;
}

.community__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.community__message-icon {
	background-image: url("~@/assets/img/account-img/menu/communities-white.png");
	width: 24px;
	height: 24px;
	display: block;
	background-repeat: no-repeat;
	margin-right: 7px;
	background-size: cover;
}

.community-mobile {
	display: none;
}

@media (max-width: 1575px) {
	.community-wrapper {
		max-width: 400px;
		padding: 0;
		flex-direction: column;
	}

	.community-desctop {
		display: none;
	}

	.community-mobile {
		display: flex;
	}

	.communitys-separate__left {
		padding: 13px 15px;
		align-items: center;
	}

	.community__status {
		margin-left: auto;
	}

	.community__info {
		width: 100%;
	}

	.community__info-designation {
		padding-top: 0;
	}

	.community__info-designation {
		align-items: center;
	}

	.community__info-name {
		font-size: 13px;
		display: flex;
		align-items: center;
	}

	.community__place-town {
		color: #696974;
		margin-top: 5px;
	}

	.community__message {
		width: 100%;
		margin-left: 0;
		border-radius: 0px 0px 10px 10px;
	}

	.community__info-speciality {
		margin-top: 2px;
	}

	.community__status {
		padding: 5px 11px;
		font-size: 10px;
		line-height: 1;
	}

	.community__info-designation {
		margin-top: 6px;
	}
}

@media (max-width: 720px) {
	.community-wrapper {
		padding: 0;
		margin-bottom: 0;
	}


}
</style>
