<template>
	<div class="table-wrapper">
		<div class="table" border="1px">
			<div class="table-row" v-for="(item, index) in values" :key="item.id">
				<div class="table-column-first-wrapper">
					<div class="table-column table-column-first" v-if="!item.delete">
						<input v-model="item.name" :disabled="status === 'checking' || status === 'approved'"
							:placeholder="$t('components.account_worker.schoolAtestateTableCopy.theNameSubjectPlaceholder')"
							@change="(e) => setName(e.target.value, index)" />
					</div>
				</div>
				<div class="number-wrapper">
					<div class="table-column number-element" v-if="!item.delete">
						<input @blur="blurEvent(index)" :ref="`estimation${index}`" :value="item.estimation"
							:disabled="status === 'checking' || status === 'approved'"
							:placeholder="$t('components.account_worker.schoolAtestateTableCopy.numberOfPointsPlaceholder')"
							@input="e => findEstimations(e, index)" size="3" />
					</div>
					<div class="table-column close-btn" v-if="!item.delete">
						<button tabindex="-1" @click="deleteRow(item.id, index)">
							<img src="~@/assets/img/all-img/documents/close.svg" alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="button-add-row-wrapper" v-if="status !== 'approved' && status !== 'checking' && pointsSystem">
			<button class="button-add-row" tabindex="-1" @click="addData">
				{{ $t('components.account_worker.schoolAtestateTableCopy.addNoteButton') }}
				<span class="button-add-row__icon"></span>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import IMask from 'imask';

export default {
	computed: {
		...mapGetters(['getCurrentPointSystemSchool', 'getCurrentPointSystemUniversity', "getDocumentsData"]),

		systemPoints() {
			return this.$store.getters.getSystemPoints.map(e => {
				return { id: e.id, name: e.name };
			});
		},

	},
	name: "SchoolAtestateCopy",
	props: ['values', 'status', 'field', 'index'],
	data() {
		return {
			emnistionView: false,
			emnistionIndex: null,
			pointsSystem: null,
			point: null
		};
	},
	methods: {
		changePointSystem() {
			if (this.index != null) {
				this.pointsSystem = this.getDocumentsData.additionUniversity[this.index].pointSystem;
			} else {
				if (this.field === 'school-attest') {
					this.pointsSystem = this.getDocumentsData.school.pointSystem;
				} else {
					this.pointsSystem = this.getDocumentsData.university.pointSystem;
				}
			}
		},
		addData() {
			this.$store.commit("addDocumentsDataTable", { field: this.field, index: this.index });
		},
		deleteRow(id, index) {
			this.$store.commit('deleteDocumentDataTable', {
				id,
				index,
				tableIndex: this.index,
				field: this.field
			});
		},
		setEmnistion(value, index) {
			this.$store.commit('setDocumentDataTableEstimation', { field: this.field, value, index, tableIndex: this.index });
			this.emnistionView = false;
			this.emnistionIndex = null;
			this.$refs[`estimation${index}`][0].value = value;
		},
		setName(value, index) {
			this.$store.commit('setDocumentDataTableName', { field: this.field, value, index, tableIndex: this.index });
		},
		setEstimation(value, index) {
			this.$store.commit('setDocumentDataTableEstimation', { field: this.field, value, index, tableIndex: this.index });
		},
		findEstimations(e, index) {
			console.log(e.target.value);
			const isInputEmpty = e.target.value.trim() === '';
			if (isInputEmpty) {
				console.log("test");
				this.setEstimation(null, index);
			} else {
				let mask = new IMask(e.target, {
					scale: 0,
					mask: Number,
					min: 1,
					max: this.systemPoints
						.filter(e => e.id === this.pointsSystem)
						.map(e => {
							if (e.name === '100 point') {
								return 100;
							} else if (e.name === '10 point') {
								return 10;
							} else {
								return 5;
							}
						})
				})[0];
				this.setEstimation(e?.target?.value, index);
				mask.updateValue();
			}
		},
		blurEvent(index) {
			this.$refs[`estimation${index}`][0].value = (this.values[index] ? this.values[index].estimation : '');
		}
	},
	beforeMount() {
		this.changePointSystem();
	}
};
</script>


<style scoped>
.table-column {
	position: relative;

}

.table-column-first {
	max-width: 100%;
	width: 100%;
}

.number-element {
	margin-left: 20px;
	max-width: 200px;
	width: 100%;
}

.table {
	max-width: 100%;
	width: 100%;
	border-spacing: 0px 20px;
}


.table-drop-list {
	max-height: 400px;
	border: 1px solid #bcbcbc;
	position: absolute;
	width: 100%;
	max-width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	z-index: 10;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	box-sizing: border-box;
	border-radius: 8px;
	box-sizing: border-box;
	overflow-y: scroll;
	scrollbar-width: thin;
}

.table-column-first-wrapper {
	max-width: 100%;
	width: 100%;
}

.number-wrapper {
	max-width: 250px;
	width: 100%;
	display: flex;
}

.table-drop-list::-webkit-scrollbar {
	width: 10px;
	height: 8px;
	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.table-drop-list-btn:not(:last-child) {
	border-bottom: 1px solid #bcbcbc;
}

.table-drop-list-btn {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
}


.table-drop-list-btn:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.table-wrapper {
	margin-top: 20px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	position: relative;
}

.button-add-row-wrapper {
	max-width: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	background: #ffffff;
	padding: 13px 0;
	border: 1px solid #efeff4;
	border-radius: 8px;
}

.button-add-row {
	background: #009ed1;
	border-radius: 8px;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #ffffff;
	letter-spacing: 0.1px;
	width: 214px;
	height: 45px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
}

.button-add-row__icon {
	display: flex;
	width: 28px;
	height: 28px;
	background: url(../../../../../assets/img/all-img/pkus.svg);
	flex-shrink: 0;
}


.table-wrapper table {
	border: none;
}

.table-wrapper tr {
	border: none;
}

.table-wrapper th {
	border: none;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
}

.table-wrapper input ::placeholder {
	color: rgba(23, 23, 37, 0.20000000298023224);
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.table-wrapper input {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
}

.table-row {
	width: 100%;
	display: flex;
	max-width: 100%;
	align-items: center;
	margin-bottom: 20px;
}

.close-btn {
	display: flex;
	padding-left: 20px;
	width: 16px;
}

@media (max-width: 950px) {
	.table-wrapper {
		margin-top: 20px;
		margin-left: 0;
	}

	tr {
		display: flex;
		flex-direction: column;
	}

	.table {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
	}


	.table-row {
		flex-direction: column;
	}

	.number-wrapper {
		max-width: 100%;
		width: 100%;
		margin-top: 10px;
	}

	.number-element {
		margin-left: 0;
		width: 100%;
		max-width: 100%;
	}

	.close-btn {
		width: 24px;
	}

	.table-column {
		text-align: center;
	}

	.close-btn {
		margin: 0 auto;
	}
}

@media (max-width: 330px) {
	.button-add-row {
		text-align: center;
	}
}
</style>