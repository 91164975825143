<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="chat">
					<div class="chat__caption" v-if="!allMessage || desctop">
						<div class="chat__caption-head">
							<div class="chat__caption-head-greetings">
								<p class="chat__caption-head-greetings-text mobile">
									{{ $t('pages.account.account_company.accountCompanyChatPage.personalCabinet') }}
								</p>
								<h1 class="chat__caption-head-greetings-title">
									{{ $t('pages.account.account_company.accountCompanyChatPage.chat') }}
								</h1>
								<p class="chat__caption-head-greetings-text desctop-rating">
									{{ $t('pages.account.account_company.accountCompanyChatPage.welcome') }}
								</p>
							</div>
							<SearchElement>
							</SearchElement>
						</div>
					</div>
					<div class="chat__wrapper">
						<div class="chat__wrapper-contacts" v-if="!allMessage || desctop">
							<div class="chat__wrapper-contacts-caption">
								<p class="chat__wrapper-contacts-caption-messages">
									{{
										role === 'admin' ?
											$t('pages.account.account_company.accountCompanyChatPage.administrator') :
											role === 'candidate' ?
												$t('pages.account.account_company.accountCompanyChatPage.jobSeeker') :
												role === 'employer' ?
													$t('pages.account.account_company.accountCompanyChatPage.employer') :
													$t('pages.account.account_company.accountCompanyChatPage.noData')
									}}
								</p>
								<button class="chat__wrapper-contacts-caption-more" @click="pickChat = !pickChat">

								</button>
								<div class="test__top-bottom-work-group" v-if="pickChat">
									<div class="search__sorting-select-speciality-group-element">
										<button @click="changeRole('admin')"
											class="test__top-bottom-work-group-element-label"
											:class="{ 'select_picked': role === 'admin' }">
											{{ $t('pages.account.account_company.accountCompanyChatPage.administrator')
											}}
										</button>
										<button @click="changeRole('candidate')"
											class="test__top-bottom-work-group-element-label"
											:class="{ 'select_picked': role === 'candidate' }">
											{{ $t('pages.account.account_company.accountCompanyChatPage.jobSeeker') }}
										</button>
										<button @click="changeRole('employer')"
											class="test__top-bottom-work-group-element-label"
											:class="{ 'select_picked': role === 'employer' }">
											{{ $t('pages.account.account_company.accountCompanyChatPage.employer') }}
										</button>
									</div>
								</div>
								<button class="chat__wrapper-contacts-caption-tabs"></button>
							</div>
							<div class="chat__wrapper-contacts-people">
								<SeparateCompanyChatPersone v-for="item in lastMessage" :key="item.id" :message="item"
									:role="role">
								</SeparateCompanyChatPersone>
							</div>
						</div>
						<div class="chat__wrapper-dialogue-contaner">
							<SeparateCompanyChatDialogue v-if="allMessage" :messages="allMessage" :role="role" :user="{
								name: userNameInDialogue,
								avatar: userAvatarInDialogue
							}">
							</SeparateCompanyChatDialogue>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>


<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import SeparateCompanyChatDialogue
	from "../../../components/account/account_company/account_company_chat/item/SeparateCompanyChatDialogue.vue";
import SearchElement
	from "../../../components/account/SearchElement.vue";
import SeparateCompanyChatPersone
	from "../../../components/account/account_company/account_company_chat/item/SeparateCompanyChatPersone.vue";

export default {
	computed: {
		lastMessage() {
			return this.$store.getters.lastMessageEmployer;
		},
		allMessage() {
			return this.$store.getters.allMessageEmployer;
		},
		role() {
			return this.$store.getters.getDialogueRole;
		},
		userNameInDialogue() {
			return this.$store.getters.userNameInDialogue
		},
		userAvatarInDialogue() {
			return this.$store.getters.userAvatarInDialogue
		},
		currentDialogueId() {
			return this.$store.getters.getCurrentDialogueId;
		}
	},
	components: {
		MenuCompany,
		HeaderAccountCompany,
		SeparateCompanyChatPersone,
		SeparateCompanyChatDialogue,
		SearchElement
	},
	name: "AccountCompanyChatPage",
	beforeMount() {
		this.$store.commit('resetPageDialogue');
		if (window.matchMedia("(max-width: 900px)").matches) {
			this.desctop = false;
		}
		if (this.role) {
			this.$store.dispatch(
				"fetchDialoguesWithUsers",
				{
					token: this.$store.getters.token,
					role: this.role,
					limit: this.$store.getters.getLimitDialogue,
					page: this.$store.getters.getPageDialogue
				},
			);
			if (this.currentDialogueId) {
				this.$store.commit('resetPageMessages');
				this.$store.dispatch(
					"fetchAllMessageWithUser",
					{
						token: this.$store.getters.token,
						id: this.currentDialogueId,
						limit: this.$store.getters.getLimitMessages,
						page: this.$store.getters.getPageMessages
					}
				);
			}
		} else {
			this.changeRole('admin')
		}

	},
	data() {
		return {
			desctop: true,
			pickChat: false
		};
	},
	methods: {
		deletePagination() {
			window.onscroll = null;
		},
		changeRole(value) {
			this.$store.commit('setAllMessageEmployer', null)
			this.$store.commit('setRoleDialogue', value);
			this.$store.commit('backToAllDialogue');
			switch (this.role) {
				case 'admin':
					this.$store.dispatch(
						"fetchLastMessageEmployer",
						this.$store.getters.token
					);
					break;
				default:
					this.$store.dispatch(
						"fetchDialoguesWithUsers",
						{
							token: this.$store.getters.token,
							role: this.role,
							limit: this.$store.getters.getLimitDialogue,
							page: this.$store.getters.getPageDialogue,
							callback: this.deletePagination
						});
					break;
			}
			this.pickChat = false;
		}
	},
	destroyed() {
		this.$store.commit('backToAllDialoge');
		this.$store.commit('resetAllChat');
	}
};
</script>


<style scoped>
.account {
	display: flex;
}

.chat {
	padding-top: 96px;
	width: 100%;
	box-sizing: border-box;
}

.mobile {
	display: none;
}

.chat__caption {
	padding-left: 61px;
	max-width: 545px;
	width: 100%;
}

.chat__caption-head {
	display: flex;
	align-items: center;
}

.chat__caption-head-greetings {
	margin: auto 0;
}

.chat__caption-head-greetings-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.chat__caption-head-greetings-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.chat__caption-head-surch {
	margin-left: auto;
	position: relative;
}

.chat__caption-head-surch-entry {
	height: 55px;
	margin: auto 0;
	width: 307px;
	padding-left: 33px;
	padding-right: 58px;
	align-items: center;
	background: #ffffff;
	border: 1px solid #f0f0f0;
	border-radius: 100px;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #171725;
}

.chat__caption-head-surch-entry::placeholder {
	color: #b7c1d1;
}

.chat__caption-head-surch-find {
	top: 9px;
	position: absolute;
	display: inline-block;
	right: 10px;
	transition: opacity 1s ease;
	padding: 12px;
	line-height: 0;
	background: linear-gradient(192.41deg, #4099ff 0%, #a1a8ff 138.89%);
	border-radius: 100%;
}

.chat__caption-head-surch-find-img {
	object-fit: cover;
	width: 12px;
	height: 12px;
}

.chat__caption-head-surch-find:hover {
	opacity: 0.5;
}

.chat__wrapper {
	margin-top: 26px;
	width: 100%;
	border-top: 1px solid rgba(182, 210, 255, 0.5);
	display: flex;
	height: calc(100vh - 178px);
}

.chat__wrapper-contacts {
	max-width: 600px;
	width: 100%;
	box-sizing: border-box;
	border-right: 1px solid rgba(182, 210, 255, 0.5);
}

.chat__wrapper-contacts-caption {
	position: relative;
	width: 100%;
	padding: 18px 24px 18px 61px;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(182, 210, 255, 0.5);
	display: flex;
	align-items: center;
}

.chat__wrapper-contacts-caption-messages {
	max-width: 132px;
	width: 100%;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #333333;
}

.chat__wrapper-contacts-caption-more {
	margin-left: 33px;
	width: 16px;
	height: 10px;
	background: url("~@/assets/img/account-img/chat/more-chat.svg");
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-contacts-caption-more:hover {
	opacity: 0.5;
}

.chat__wrapper-contacts-caption-tabs {
	margin-left: auto;
	background: url("~@/assets/img/account-img/chat/tabs.svg");
	width: 25px;
	height: 25px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-contacts-caption-tabs:hover {
	opacity: 0.5;
}

.chat__wrapper-contacts-people {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 241px);
	scrollbar-width: none;
	overflow-y: scroll;
}

.chat__wrapper-contacts-people::-webkit-scrollbar {
	width: 0px;

	height: px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-contacts-people::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-contacts-people-separate {
	cursor: pointer;
	padding: 24px 24px 24px 61px;
	display: flex;
	align-items: center;
	transition: background 1s ease;
}

.chat__wrapper-contacts-people-separate:hover {
	background: #e5f7fd;
}

.chat__wrapper-contacts-people-separate-avatar {
	background: url("~@/assets/img/account-img/chat/avatar.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	position: relative;
}

.chat__wrapper-contacts-people-separate-avatar-notification {
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 1px;
	right: 0;
}

.notification-online {
	width: 9px;
	height: 9px;
	background: #14c686;
}

.chat__wrapper-dialogue-contaner {
	width: 100%;
	height: calc(100vh - 179px);
}

.mobile-chat {
	display: none;
}

@media (max-width: 1780px) {
	.chat__wrapper-contacts {
		max-width: 454px;
		flex-shrink: 0;
	}

	.chat__wrapper-dialogue {
		flex-shrink: 1;
	}
}

@media (max-width: 1780px) {
	.chat__wrapper-dialogue-messages-user {
		max-width: 270px;
	}

	.chat__wrapper-dialogue-messages-interlocutor {
		max-width: 270px;
	}
}

@media (max-width: 950px) {
	.chat {
		padding-top: 68px;
	}

	.chat__caption {
		box-sizing: border-box;
		max-width: 100%;
		padding-left: 24px;
		padding-right: 20px;
	}

	.chat__caption-head-greetings-title {
		font-size: 20px;
	}

	.chat__caption-head-greetings-text {
		font-size: 13px;
	}

	.chat__caption-head-surch {
		margin-left: auto;
	}

	.chat__caption-head-surch-entry {
		font-size: 15px;
		padding-left: 27px;
	}

	.chat__wrapper-contacts-caption {
		width: 100%;
		padding: 18px 24px 18px 24px;
	}

	.chat__wrapper-contacts-people-separate {
		padding: 24px 24px 24px 25px;
	}
}

@media (max-width: 900px) {
	.chat {
		padding-top: 47px;
	}

	.chat__wrapper-dialogue-caption {
		padding: 18px 24px 18px 24px;
	}

	.chat__wrapper-contacts {
		max-width: 100%;
	}

	.chat__wrapper-dialogue-contaner {
		height: calc(100vh - 48px);
	}

	.chat__caption-head {
		display: none;
	}

	.chat__wrapper {
		margin-top: 0;
		height: calc(100vh - 48px);
	}
}

@media (max-width: 700px) {
	.chat__caption-head {
		flex-direction: column;
	}

	.chat__caption-head-surch {
		margin-top: 15px;
		max-width: 300px;
		margin-left: 0;
	}

	.chat__caption-head-greetings {
		text-align: center;
	}

	.chat__wrapper-contacts-caption {
		padding: 15px 10px 15px 10px;
	}
}

@media (max-width: 700px) {
	.chat__caption {
		flex-direction: column;
	}

	.chat__caption {
		padding-left: 10px;
		padding-right: 10px;
	}

	.chat__caption-head-surch {
		max-width: 300px;
		width: 100%;
	}

	.chat__caption-head-surch-entry {
		width: 100%;
	}

	.chat__wrapper-contacts-people-separate {
		padding: 15px 10px;
	}

	.chat__wrapper-contacts-people-separate-filling {
		max-width: 200px;
	}

	.chat__wrapper-contacts-people-separate-filling-message {
		max-height: 16px;
		overflow: hidden;
	}
}

.search__sorting-select-speciality-group-element {
	background: white;
	cursor: pointer;
	width: 100%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: black;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
}

.test__top-bottom-work-group {
	border: 1px solid #bcbcbc;
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	top: 49px;
	left: -1px;
	z-index: 1000;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	box-sizing: border-box;
	width: 300px;
	border-radius: 8px;
}


.test__top-bottom-work-group-element-label {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
}

.select_picked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}
</style>
