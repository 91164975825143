<template>
  <div class="news">
    <div class="news-caption">
      <img src="../../../assets/img/news/community.png" alt="" class="news-caption__icon">
      <div class="caption-info">
        <div class="caption-info-top">
          <h3 class="caption-info__title">A new community has been created</h3>
          <time class="news-caption__time">{{ getDate(post.createDate) + ' ' + getTime(post.createDate) }}</time>
        </div>
        <p class="caption-info__text">{{ post.userFromName }} Created a new community
          «{{ body.communityName ? textSymbolLimit(body.communityName, 15) : null }}», in which there will be a lot of
          interesting
          information</p>
      </div>

    </div>
    <div class="news-filling">
      <div class="news-community">
        <img :src="body.previewUrl" alt="" class="news-persone__face">
        <div class="community-description">
          <div class="description-name">
            <p class="description-name__text">{{
                body.communityName ? textSymbolLimit(body.communityName, 15) : null
              }}</p>
            <p class="description-name__status " :class="{'status-close':body.communityType==='close'}">
              {{ body.communityType === 'close' ? 'Закрытое' : 'Открытое' }}</p>
          </div>
          <p class="description__direction" v-if="false">The direction of the community</p>
          <p class="description__text">{{ body.description ? textSymbolLimit(body.description, 15) : null }}</p>
        </div>
      </div>
      <button class="news-request" @click="$router.push({name:'communities'})">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_934_15857" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18"
                height="18">
            <rect width="18" height="18" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_934_15857)">
            <path
                d="M8.4375 14.0625V9.5625H3.9375V8.4375H8.4375V3.9375H9.5625V8.4375H14.0625V9.5625H9.5625V14.0625H8.4375Z"
                fill="#009ED1"/>
          </g>
        </svg>
        Apply
      </button>
    </div>
  </div>
</template>

<script>
import {textSymbolLimit} from "@/lib/textSymbolLimit";
import {getDate, getTime} from "@/lib/dateUtils";

export default {

  name: "LastNewsNewCommunity",
  props: ['post'],
  data() {
    return {
      body: null,
      textSymbolLimit, getDate, getTime
    }
  },
  beforeMount() {
    this.body = JSON.parse(this.post.body);
  }

};


</script>

<style scoped>
p{
  word-break: break-word;
}
h3{
  word-break: break-word;
}
.news {
  font-family: "Roboto";
  padding: 18px 12px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  position: relative;
}

.news-caption {
  display: flex;
  align-items: flex-start;
}

.news-caption__icon {
  background: #f7f7f7;
  width: 53px;
  height: 53px;
  object-fit: cover;
  display: flex;
  border-radius: 8px;
  flex-shrink: 0;
}

.caption-info {
  margin-left: 16px;
  max-width: 100%;
  width: 100%;
}
.caption-info-top{
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}
.caption-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #3b4c68;
}

.caption-info__text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(23, 23, 37, 0.4000000059604645);
}

.news-caption__time {
  max-width: 97px;
  width: 100%;
  margin-left: auto;
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.news-filling {
  padding: 30px 0 0 0;
  display: flex;
  align-items: center;
}

.news-community {
  max-width: 300px;
  display: flex;
  align-items: flex-start;
}

.community-description {
  margin-left: 16px;
}

.description-name {
  display: flex;
  align-items: center;
}

.description-name__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.description-name__status {
  margin-left: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  padding: 5px 6px 4px 6px;
}

.news-persone__face {
  background: #000000;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.description__direction {
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.description__text {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: rgba(23, 22, 37, 0.5);
}

.news-request {
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #009ed1;
  display: flex;
  align-items: center;
}

.news-request svg {
  margin-right: 2px;
}

@media (max-width: 1770px) {
  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 950px) {
  .news-filling {
    flex-direction: row;
    align-items: center;
  }

  .news-request {
    margin: 0 0 0 auto;
  }
}

@media (max-width: 720px) {
  .news {
    padding: 12px;
  }

  .caption-info {
    margin-left: 14px;
    max-width: 100%;
  }
  .caption-info-top{
    flex-direction: column-reverse;
    gap: 6px;
  }
  .news-caption__time{
    margin: 0;
  }

  .caption-info__title {
    font-size: 16px;
  }

  .caption-info__text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.4;
  }

  .news-filling {
    padding-top: 20px;
  }

  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }

  .news-persone__face {
    width: 48px;
    height: 48px;
  }

  .community-description {
    margin-left: 14px;
  }

  .description-name__text {
    font-size: 14px;
  }

  .description-name__status {
    font-size: 10px;
  }

  .description__direction {
    font-size: 10px;
  }

  .description__text {
    font-size: 10px;
  }

  .news-request {
    font-size: 12px;
  }
}

/* Modificators */
.status-close {
  background: #ffe3e3;
  color: #b53151;
}
</style>
