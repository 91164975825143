var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
	name: 'separateResponse',
	params: { responseId: _vm.response.responseId },
}}},[_c('div',{staticClass:"response"},[_c('div',{staticClass:"response__caption"},[_c('div',{staticClass:"response__caption-wrapper"},[_c('img',{staticClass:"response__caption-face",attrs:{"src":_vm.response.candidateAvatarUrl,"alt":""}}),_c('div',{staticClass:"response__caption-wrapper-description"},[_c('div',{staticClass:"response__caption-wrapper-raing"},[_c('p',{staticClass:"response__caption-wrapper-raing-number"},[_vm._v(" "+_vm._s(_vm.response.candidateRate)+" ")])]),_c('p',{staticClass:"response__caption-wrapper-name"},[_vm._v(" "+_vm._s(_vm.response.candidateName)+" ")])])]),_c('div',{staticClass:"response__caption-status"},[_c('p',{staticClass:"response__caption-status-text",class:{
					'status-text-refusal': this.response.responseStatus === 'reject',
					'status-text-application':
						this.response.responseStatus === 'invite',
				}},[_vm._v(" "+_vm._s(_vm.status)+" ")]),_c('p',{staticClass:"response__caption-status-date"},[_vm._v(" "+_vm._s(new Date(_vm.response.responseDate).toDateString())+" ")])])]),_c('div',{staticClass:"response__filling"},[_c('div',{staticClass:"response__filling-profession"},[_c('p',{staticClass:"response__filling-profession-name"},[_vm._v(" "+_vm._s(_vm.response.candidateSpecialization)+" ")]),_c('p',{staticClass:"response__filling-profession-descripton"},[_vm._v(" "+_vm._s(_vm.response.candidateAboutText ? _vm.response.candidateAboutText.substring( 0, _vm.DESCRIPTION_SYMBOL_LIMIT ) : _vm.$t('components.account_company.response.noDescription'))+"... ")])]),_c('div',{staticClass:"response__filling-place"},[_c('div',{staticClass:"response__filling-place-name"},[_vm._v(" "+_vm._s(_vm.response.candidateCity ? _vm.response.candidateCity : _vm.$t('components.account_company.response.undefined'))+" ")])]),_c('div',{staticClass:"response__filling-salary"},[_c('p',{staticClass:"response__filling-salary-name"},[_vm._v(_vm._s(_vm.$t('components.account_company.response.expectedSalary'))+" :")]),_c('p',{staticClass:"response__filling-salary-price"},[_vm._v(" "+_vm._s(_vm.response.candidateSalary && _vm.response.candidateSalary > 0 ? _vm.response.candidateSalary : _vm.$t('components.account_company.response.undefined'))+" $ ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }