<template>
	<div>
		<HeaderAccountWorker @delete="deleteAvatar" @close="viewAvatar = false"
			:photos="[...(dataWorker.avatarPath ? [dataWorker.avatarPath] : []), ...(typeof dataWorker.avatarArchive === 'object' ? dataWorker.avatarArchive : [])]"
			:avatar="viewAvatar" :change="true"></HeaderAccountWorker>
		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<p class="settings__text mobile">
						{{ $t('pages.account.account_worker.accountWorkerSettingsPage.welcome', {
							name:
								dataWorker.name?.split(" ")[0]
						}) }}
					</p>
					<h1 class="settings__title">{{
						$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings') }}</h1>
					<p class="settings__text desktop">
						{{ $t('pages.account.account_worker.accountWorkerSettingsPage.welcome', {
							name:
								dataWorker.name?.split(" ")[0]
						}) }}
					</p>
					<div class="settings-nav desktop">
						<button @click="switchSettings('settingProfile')" class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'settingProfile' }">{{
								$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings') }}</button>
						<button @click="switchSettings('privacy')" class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'privacy' }">{{
								$t('pages.account.account_worker.accountWorkerSettingsPage.privacy') }}</button>
						<button @click="switchSettings('blockedUsers')" class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'blockedUsers' }">{{
								$t('pages.account.account_worker.accountWorkerSettingsPage.blockedUsers') }}</button>
						<button @click="switchSettings('about')" class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'about' }">{{
								$t('pages.account.account_worker.accountWorkerSettingsPage.about') }}</button>
						<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
							$t('pages.account.account_worker.accountWorkerSettingsPage.help') }}</router-link>
					</div>
					<div class="settings__wrapper " v-if="selectedItem === 'settingProfile'">
						<div class="user-container">
							<div class="user">
								<div class="user-face">
									<button @click="viewAvatar = true" class="user-face-img" :style="{
										backgroundImage: 'url(' + dataWorker.avatarPath + ')',
										pointerEvents:
											dataWorker.avatarPath && dataWorker.avatarPath !== ''
												? 'default'
												: 'none',
									}"></button>
									<p class="user-face-name">
										{{ limit(dataWorker.name, 23) }}
									</p>
									<p class="user-face-designation">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.applicant') }}
									</p>
								</div>
								<div class="user-change">
									<button @click="$refs.file.click()" class="user-change-photo">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.changePhoto') }}
									</button>
									<input type="file" id="file" ref="file" v-on:change="handleFileUpload" hidden />
								</div>
								<button @click="$store.dispatch('deleteUser')" class="delete">
									{{ $t('pages.account.account_worker.accountWorkerSettingsPage.deleteAccount') }}
								</button>
							</div>
						</div>
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button @click="switchSettings('privacy')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.privacy') }}</button>
								<button @click="switchSettings('blockedUsers')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.blockedUsers')
								}}</button>
								<button @click="switchSettings('about')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.about') }}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.help') }}</router-link>
							</div>
						</div>
						<div class="content">
							<div class="settings-block info">
								<div class="info-small">
									<div class="info-column">
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.fullName') }}
											</p>
											<input v-model="dataWorker.name" type="text" class="info-input"
												@keydown="nameValidation" />
											<span v-if="$v.dataWorker.name.$error" class="error-message">
												<span v-if="!$v.dataWorker.name.required">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.invalidFieldError')
												}}</span>
												<span v-if="!$v.dataWorker.name.maxLength">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength',
														{ max: 100 }) }}</span>
											</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.phoneNumber')
											}}</p>
											<PhoneMaskInput showFlag v-model="dataWorker.phone"
												:value="dataWorker.phone" wrapperClass="info-input">
											</PhoneMaskInput>
											<span v-if="$v.dataWorker.phone.$error" class="error-message">
												<span v-if="!$v.dataWorker.phone.required">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.invalidFieldError')
												}}</span>
												<span v-if="!$v.dataWorker.phone.minLength">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.minLength',
														{ min: 10 }) }}</span>
												<span v-if="!$v.dataWorker.phone.maxLength">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength',
														{ max: 14 }) }}</span>
											</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.passportNumberSeries')
											}}</p>
											<input v-model="dataWorker.passportNumberSerial" type="text"
												class="info-input" maxlength="10" @keydown="numberValidation" />
											<span v-if="$v.dataWorker.passportNumberSerial.$error"
												class="error-message">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
														max: 10
													}) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.residenceAddress')
											}}</p>
											<input v-model="dataWorker.residentialAddress" type="text"
												class="info-input" />
											<span v-if="$v.dataWorker.residentialAddress.$error"
												class="error-message">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
														max: 200
													}) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.universityName')
											}}</p>
											<input v-model="dataWorker.universityName" type="text" class="info-input" />
											<span v-if="$v.dataWorker.residentialAddress.$error"
												class="error-message">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
														max: 100
													}) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.expectedSalary')
											}}</p>
											<input v-model="dataWorker.expectedSalary" min="1" type="number"
												class="info-input" @input="validateSalary" />
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.currency') }}
											</p>
											<div class="search__sorting-select-container" ref="currencyContainer">
												<button ref="select" class="search__sorting-select"
													@click="toggleCurrencyVisible">
													{{ currencyName ? currencyName :
														$t('pages.account.account_worker.accountWorkerSettingsPage.currency')
													}}
												</button>
												<div class="search__sorting-select-speciality-group"
													v-if="viewCurrency">
													<div class="search__sorting-select-speciality-group-element"
														v-for="(item, index) in currencies" :key="index">
														<input type="radio" :id="item.value" :value="item.value"
															class="search__sorting-select-speciality-group-element-input"
															@change="hideCurrencyOptions"
															v-model="dataWorker.expectedSalaryCurrency" />
														<label :for="item.value"
															class="search__sorting-select-speciality-group-element-label">{{
																item.name
															}}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="info-column ">
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.birthday') }}
											</p>
											<input type="date" v-model="dataWorker.birthDate" class="info-input" />
											<div v-if="$v.dataWorker.birthDate.$error" class="error-message">
												<span v-if="!$v.dataWorker.birthDate.required">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.dateValidation.required')
												}}</span>
												<span v-else-if="!$v.dataWorker.birthDate.beforeToday">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.dateValidation.beforeToday')
												}}</span>
												<span v-else-if="!$v.dataWorker.birthDate.isDateReasonable">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.dateValidation.isDateReasonable')
												}}</span>
											</div>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.email') }}
											</p>
											<input v-model="dataWorker.email" type="email" class="info-input" />
											<span v-if="$v.dataWorker.email.$error" class="error-message">
												<span v-if="!$v.dataWorker.email.required">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.invalidFieldError')
												}}</span>
												<span v-if="!$v.dataWorker.email.email">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.emailInvalid')
												}}</span>
												<span v-if="!$v.dataWorker.email.maxLength">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength',
														{ max: 100 }) }}</span>
											</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.registrationAddress')
											}}</p>
											<input v-model="dataWorker.passportRegAddress" type="text"
												class="info-input" />
											<span v-if="$v.dataWorker.passportRegAddress.$error"
												class="error-message">{{
													$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
														max: 100
													}) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.schoolName')
											}}</p>
											<input v-model="dataWorker.schoolName" type="text" class="info-input" />
											<span v-if="$v.dataWorker.schoolName.$error" class="error-message">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
													max: 100
												}) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.speciality')
											}}</p>
											<div class="search__sorting-select-container">
												<button ref="select" class="search__sorting-select"
													@click="viewSpecializations = !viewSpecializations">
													{{ specializationName }}
												</button>
											</div>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_worker.accountWorkerSettingsPage.city') }}</p>
											<div class="search__sorting-select-container" ref="cityContainer">
												<button @click="toggleCityVisible" class="search__sorting-select">
													{{ cityName ? cityName :
														$t('pages.account.account_worker.accountWorkerSettingsPage.city') }}
													<span class="test__top-bottom-town-img"></span>
												</button>
												<div class="search__sorting-select-speciality-group town-select"
													v-if="cityVisible">
													<input placeholder="City name" type="text"
														@input="(e) => searchCity(e.target.value)"
														class="town-input search__sorting-select-speciality-group-element" />
													<button
														class="search__sorting-select-speciality-group-element select-btn"
														@click="pickCity" :value="item.name" v-for="item in dataCities"
														:key="item.id">
														{{ item.name }}
													</button>
												</div>

											</div>
										</div>

										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.lang')
											}}</p>
											<div class="search__sorting-select-container">
												<button ref="select" class="search__sorting-select"
													@click="viewLang = !viewLang">
													{{ $i18n.locale ? locales.find((el) => el.value === $i18n.locale).name :
														$t('pages.account.account_company.accountCompanySettingsPage.lang')
													}}
												</button>
												<div class="search__sorting-select-speciality-group"
													v-if="viewLang">
													<div class="search__sorting-select-speciality-group-element"
														v-for="(item, index) in locales" :key="index">
														<input type="radio" @change="viewLang = false"
															:id="item.value" :value="item.value"
															class="search__sorting-select-speciality-group-element-input"
															@click ="changeLanguage(item.value)" />
														<label :for="item.value"
															class="search__sorting-select-speciality-group-element-label">{{
																item.name
															}}</label>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
								<div class="info-big">
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.achievements') }}
										</p>
										<textarea name="" id="" class="info-textaria"
											v-model="dataWorker.achievements" />
										<span v-if="$v.dataWorker.achievements.$error" class="error-message">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
												max: 300
											}) }}</span>
									</div>
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.aboutMe') }}</p>
										<textarea name="" id="" class="info-textaria" v-model="dataWorker.aboutText" />
										<span v-if="$v.dataWorker.aboutText.$error" class="error-message">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
												max: 300
											}) }}</span>
									</div>
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.skills') }}</p>
										<textarea name="" id="" class="info-textaria" v-model="dataWorker.skills" />
										<span v-if="$v.dataWorker.skills.$error" class="error-message">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
												max: 300
											}) }}</span>
									</div>
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.articles') }}</p>
										<textarea name="" id="" class="info-textaria"
											v-model="dataWorker.articlesText" />
										<span v-if="$v.dataWorker.articlesText.$error" class="error-message">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
												max: 300
											}) }}</span>
									</div>
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.courses') }}</p>
										<textarea name="" id="" class="info-textaria"
											v-model="dataWorker.coursesText" />
										<span v-if="$v.dataWorker.coursesText.$error" class="error-message">{{
											$t('pages.account.account_worker.accountWorkerSettingsPage.maxLength', {
												max: 300
											}) }}</span>
									</div>
								</div>
							</div>
							<div class="settings_wrapper-info-checkbox">
								<div class="info-btngroup">
									<button @click="updateSettings" class="info-btngroup-btn btn-blue">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.save') }}
									</button>
									<button class="info-btngroup-btn" @click="$router.go(-1)">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.cancel') }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedItem === 'privacy'">
						<div class="settings__wrapper">
							<div class="user-container">
								<div class="user">
									<div class="user-face">
										<button @click="viewAvatar = true" class="user-face-img" :style="{
											backgroundImage: 'url(' + dataWorker.avatarPath + ')',
											pointerEvents:
												dataWorker.avatarPath && dataWorker.avatarPath !== ''
													? 'default'
													: 'none',
										}"></button>
										<p class="user-face-name">
											{{ limit(dataWorker.name, 23) }}
										</p>
										<p class="user-face-designation">
											{{ $t('pages.account.account_worker.accountWorkerSettingsPage.applicant') }}
										</p>
									</div>
									<div class="user-change">
										<button @click="$refs.file.click()" class="user-change-photo">
											{{ $t('pages.account.account_worker.accountWorkerSettingsPage.changePhoto')
											}}
										</button>
										<input type="file" id="file" ref="file" v-on:change="handleFileUpload" hidden />
									</div>
									<button @click="$store.dispatch('deleteUser')" class="delete">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.deleteAccount') }}
									</button>
								</div>
							</div>
							<div class="settings-nav mobile">
								<button class="settings-nav__element settings-nav__element--selected"
									@click="openNavList = !openNavList">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.privacy') }}
									<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
										:class="{ 'settings-nav__icon--active': openNavList }"></button>
								<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
									<button @click="switchSettings('settingProfile')" class="settings-nav__element">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings')
									}}</button>
									<button @click="switchSettings('blockedUsers')" class="settings-nav__element">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.blockedUsers')
									}}</button>
									<button @click="switchSettings('about')" class="settings-nav__element">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.about') }}</button>
									<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.help')
									}}</router-link>
								</div>
							</div>
							<div class="settings-block info">
								<div class="settings_wrapper-info-checkbox">
									<input type="checkbox" id="invisible-in-friend" v-model="dataWorker.hidden" />
									<label class="friend-checkbox" for="invisible-in-friend">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.hideFromSearch')
									}}</label>
								</div>
								<div class="settings_wrapper-info-checkbox">
									<input type="checkbox" id="invisible-in-friend"
										v-model="dataWorker.allowFriendsOnly" />
									<label class="friend-checkbox" for="invisible-in-friend">{{
										$t('pages.account.account_worker.accountWorkerSettingsPage.preventMessages')
									}}</label>
								</div>
								<div class="info-btngroup">
									<button @click="updateSettings" class="info-btngroup-btn btn-blue">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.save') }}
									</button>
									<button class="info-btngroup-btn" @click="$router.go(-1)">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.cancel') }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedItem === 'blockedUsers'" style="margin-top: 30px;">
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.blockedUsers') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button @click="switchSettings('settingProfile')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings')
								}}</button>
								<button @click="switchSettings('privacy')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.privacy') }}</button>
								<button @click="switchSettings('about')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.about') }}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.help') }}</router-link>
							</div>
						</div>
						<div class="settings-block blocked-users mt15">
							<SeparateFriend v-for="(item, index) in blockedUsers" :is-friends="false" :key="item.id"
								:friend="item" :index="index">
							</SeparateFriend>
							<div v-if="blockedUsers.length <= 0">
								<h2>{{ $t('pages.account.account_worker.accountWorkerSettingsPage.noBlockedUsers') }}
								</h2>
							</div>
						</div>
					</div>
					<div v-if="selectedItem === 'about'" class="settings__wrapper">
						<div class="user-container">
							<div class="user">
								<div class="user-face">
									<button @click="viewAvatar = true" class="user-face-img" :style="{
										backgroundImage: 'url(' + dataWorker.avatarPath + ')',
										pointerEvents:
											dataWorker.avatarPath && dataWorker.avatarPath !== ''
												? 'default'
												: 'none',
									}"></button>
									<p class="user-face-name">
										{{ limit(dataWorker.name, 23) }}
									</p>
									<p class="user-face-designation">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.applicant') }}
									</p>
								</div>
								<div class="user-change">
									<button @click="$refs.file.click()" class="user-change-photo">
										{{ $t('pages.account.account_worker.accountWorkerSettingsPage.changePhoto') }}
									</button>
									<input type="file" id="file" ref="file" v-on:change="handleFileUpload" hidden />
								</div>
								<button @click="$store.dispatch('deleteUser')" class="delete">
									{{ $t('pages.account.account_worker.accountWorkerSettingsPage.deleteAccount') }}
								</button>
							</div>
						</div>
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.about') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button @click="switchSettings('settingProfile')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.profileSettings')
								}}</button>
								<button @click="switchSettings('privacy')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.privacy') }}</button>
								<button @click="switchSettings('blockedUsers')" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.blockedUsers')
								}}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_worker.accountWorkerSettingsPage.help') }}</router-link>
							</div>
						</div>
						<h1></h1>
					</div>
				</div>
			</div>
		</main>
		<SpecialitySelect :specialisations="dataSpeciality" :current-specialisation="dataWorker?.specialization"
			@pickSpecialization="pickSpecialization" @closeWindow="viewSpecializations = false"
			:industries="dataIndustries" v-if="viewSpecializations">
		</SpecialitySelect>
	</div>
</template>
<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker";
import SpecialitySelect from "@/components/account/account_worker/modals/item/SpecialitySelect";
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import PhoneMaskInput from "vue-phone-mask-input";
import { values } from "@/lib/values";
import SeparateFriend from "@/components/social/account_friensds/SeparateFriend.vue";
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

const beforeToday = value => {
	const today = new Date();
	const inputDate = new Date(value);
	today.setHours(0, 0, 0, 0);
	return inputDate <= today;
};

const isDateReasonable = value => {
	const minDate = new Date('1900-01-01');
	const birthDate = new Date(value);
	return birthDate >= minDate;
};

export default {
	components: { SeparateFriend, MenuWorker, HeaderAccountWorker, PhoneMaskInput, SpecialitySelect },
	mixins: [validationMixin],
	validations: {
		dataWorker: {
			aboutText: { maxLength: maxLength(300) },
			phone: { required, minLength: minLength(10), maxLength: maxLength(14) },
			achievements: { maxLength: maxLength(300) },
			articlesText: { maxLength: maxLength(300) },
			coursesText: { maxLength: maxLength(300) },
			email: { required, email, maxLength: maxLength(100) },
			expectedSalary: { maxLength: maxLength(20) },
			name: { required, maxLength: maxLength(100) },
			birthDate: { required, beforeToday, isDateReasonable },
			passportNumberSerial: { maxLength: maxLength(100) },
			passportRegAddress: { maxLength: maxLength(100) },
			residentialAddress: { maxLength: maxLength(100) },
			schoolName: { maxLength: maxLength(100) },
			skills: { maxLength: maxLength(300) },
			universityName: { maxLength: maxLength(100) },
		}
	},
	data() {
		return {
			dataWorker: {},
			file: "",
			checkChangeFile: false,
			specialityVisible: false,
			dataSpeciality: null,
			limit: textSymbolLimit,
			viewAvatar: false,
			timer: null,
			cityVisible: false,
			cityName: null,
			viewSpecializations: false,
			searchSpecial: null,
			currencies: values,
			viewCurrency: false,
			viewLang: false,
			openNavList: false,
			industryCategoriesVisible: false,
			selectedItem: 'settingProfile',
			locales:[
			{
				value:"ru",
				name:"Русский"
			}, 
			{
				value:"en",
				name:"English"
			}],
		};
	},
	computed: {
		blockedUsers() {
			return this.$store.getters.getMyBlockedUser;
		},

		dataCities() {
			return this.$store.getters.dataCities;
		},
		dataIndustries() {
			return this.$store.getters.dataIndustries;
		},
		currencyName() {
			if (!this.dataWorker || !this.dataWorker.expectedSalaryCurrency)
				return null;
			return this.currencies[
				this.currencies
					.map((item) => item.value)
					.indexOf(this.dataWorker.expectedSalaryCurrency)
			].name;
		},
		specializationName() {
			if (!this.dataWorker?.specialization) {
				return this.$t('pages.account.account_worker.accountWorkerSettingsPage.selectSpeciality');
			}
			const index = this.dataSpeciality?.map(item => item.id).indexOf(this.dataWorker?.specialization);
			return index > -1 ? this.dataSpeciality[index].name : this.$t('pages.account.account_worker.accountWorkerSettingsPage.selectSpeciality');
		},
		user() {
			return this.$store.getters.user
		}
	},
	name: "AccountWorkerSettingsPage",
	methods: {
		validateSalary() {
			if (this.dataWorker.expectedSalary < 1) {
				this.dataWorker.expectedSalary = '';
			}
		},
		pickSpecialization(val) {
			this.dataWorker.specialization = val;
			this.viewSpecializations = false
		},
		deleteAvatar(iter) {
			if (iter === -1) {
				this.dataWorker.avatarPath = "";
				if (this.dataWorker?.avatarArchive?.length) {
					this.dataWorker.avatarPath = this.dataWorker?.avatarArchive[0];
					this.dataWorker.avatarArchive.shift();
					this.checkChangeFile = false;
				}
			} else {
				this.dataWorker.avatarArchive.splice(iter, 1);
			}
			this.viewAvatar = false;
		},
		changeLanguage(lang) {
			this.$i18n.locale = lang;
		},
		checkEmptyField() {
			let stack = [];
			if (!this.dataWorker.email || !this.checkEmail(this.dataWorker.email)) {
				stack.push("email");
			}
			if (
				!this.dataWorker.name ||
				this.dataWorker.name.replace(/\s/g, "") === ""
			)
				stack.push("name");

			if (stack.length !== 0) {
				errorHandler({ status: 400, page: "invalidField", stack: stack });
				stack = [];
				return false;
			} else {
				return true;
			}
		},
		numberValidation(e) {
			if (e) {
				if (e.code !== "Backspace") {
					if (!/\d/.test(e.key)) {
						e.preventDefault();
					}
				}
			}
		},
		changeSelect(name) {
			this.nameSpecializations = name;
			this.specialityVisible = !this.specialityVisible;
		},
		dataSpecialityLoad() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "employer/specialization/all?lang=en";
			request.open("GET", url, false);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.dataSpeciality = response.data;
			} catch (e) {
				console.log(e);
			}
		},
		loadFile() {
			let formData = new FormData();
			formData.append("file", this.file);
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "file/upload";
			xmlHttp.open("POST", url, false);
			try {
				xmlHttp.send(formData);
				let response = JSON.parse(xmlHttp.response);
				this.dataWorker.avatarPath = response.data.url;
			} catch (e) {
				console.log(e);
			}
		},
		saveSettings() {
			console.log('save set')
			let check = this.checkEmptyField();
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			if (check) {
				let saveDataWorker = {
					name: this.dataWorker.name,
					birthDate: Date.parse(this.dataWorker.birthDate),
					passportNumberSerial: this.dataWorker.passportNumberSerial,
					passportRegAddress: this.dataWorker.passportRegAddress,
					residentialAddress: this.dataWorker.residentialAddress,
					avatarPath: this.dataWorker.avatarPath,
					schoolName: this.dataWorker.schoolName,
					avatarArchive: this.dataWorker.avatarArchive.join(","),
					universityName: this.dataWorker.universityName,
					specialization: this.dataWorker.specialization,
					achievements: this.dataWorker.achievements,
					aboutText: this.dataWorker.aboutText,
					skills: this.dataWorker.skills,
					articles: this.dataWorker.articles,
					courses: this.dataWorker.courses,
					articlesText: this.dataWorker.articlesText,
					coursesText: this.dataWorker.coursesText,
					hidden: this.dataWorker.hidden,
					city: this.dataWorker.city,
					email: this.dataWorker.email,
					expectedSalary: Number(this.dataWorker.expectedSalary),
					expectedSalaryCurrency: this.dataWorker.expectedSalaryCurrency,
					allowFriendsOnly: this.dataWorker.allowFriendsOnly
				};
				let xmlHttp = new XMLHttpRequest();
				let url = config.apiPrefix + "candidate/update";
				xmlHttp.open("POST", url, false);
				xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
				xmlHttp.setRequestHeader(
					"Content-Type",
					"application/json;charset=UTF-8"
				);

				try {
					xmlHttp.send(JSON.stringify(saveDataWorker));
					errorHandler({ status: xmlHttp.status });
				} catch (e) {
					console.log(e);
				}

				// обновление аватара и имени в верхней части страницы
				this.$store.dispatch("changeAvatar", this.dataWorker.avatarPath);
				this.$store.dispatch("changeName", this.dataWorker.name);
				this.$store.dispatch('fetchCandidateData');
			}
		},
		updateSettings() {
			this.$v.$touch();
			console.log(this.$v.dataWorker);
			if (this.$v.$invalid) {
				errorHandler({ status: 500 });
				return;
			}
			if (this.checkChangeFile) this.loadFile();
			this.saveSettings();
			scroll(0, 0);
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[this.$refs.file.files.length - 1];
			if (this.dataWorker.avatarPath) {
				this.dataWorker?.avatarArchive?.unshift(this.dataWorker?.avatarPath);
			}
			this.dataWorker.avatarPath = URL.createObjectURL(this.file);
			this.checkChangeFile = true;
		},
		fetchDataWorker() {
			this.$store.dispatch('fetchCandidateData').then(() => {
				this.dataWorker = JSON.parse(JSON.stringify(this.user));
				let date = new Date(this.dataWorker.birthDate);
				date.setDate(date.getDate() + 1);
				this.dataWorker.birthDate = date.toISOString().slice(0, 10);
				this.cityName = this.dataWorker?.city;
				this.dataWorker.avatarArchive = this.dataWorker.avatarArchive ? this.dataWorker.avatarArchive?.split(",").filter((item) => item) : [];
				this.checkChangeFile = false;
			})
		},
		nameValidation(e) {
			if (this.dataWorker.name) {
				let count = 0;
				for (let i = 0; i < this.dataWorker.name.length; i++) {
					if (this.dataWorker.name[i] === " ") ++count;
				}
				if (e.code !== "Backspace")
					if (count === 3) {
						e.preventDefault();
					}
			}
		},
		checkEmail(value) {
			const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return regex.test(value);
		},
		phoneValidation() {
			let phone = document.getElementById("phone");
			phone.addEventListener("keypress", (e) => {
				if (!/\d/.test(e.key)) e.preventDefault();
			});
		},
		searchCity(val) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.$store.dispatch("searchCity", val);
			}, 1000);
		},
		pickCity(e) {
			this.cityVisible = false;
			this.cityName = e.target.value;
			this.dataWorker.city = e.target.value;
			this.$store.dispatch("searchCity", "");
		},
		switchSettings(selectedItem) {
			this.openNavList = false;
			this.selectedItem = selectedItem;
		},
		deletePagination() {
			window.onscroll = null
		},
		toggleCityVisible() {
			this.cityVisible = !this.cityVisible;
		},
		toggleCurrencyVisible() {
			this.viewCurrency = !this.viewCurrency;
		},
		hideCurrencyOptions() {
			this.viewCurrency = false;
		},
		handleClickOutside(event) {
			if (!(this.$refs.cityContainer === event.target || this.$refs.cityContainer?.contains(event.target))) {
				this.cityVisible = false;
			}
			if (!(this.$refs.currencyContainer === event.target || this.$refs.currencyContainer?.contains(event.target))) {
				this.viewCurrency = false;
			}
		}
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	beforeDestroy() {
		document.removeEventListener('click', this.handleClickOutside);
	},
	beforeMount() {
		this.$store.dispatch("searchCity", "");
		this.$store.dispatch("getIndustries", this.$i18n.locale);
		scroll(0, 0);
		this.fetchDataWorker();
		this.dataSpecialityLoad();
		this.$store.dispatch('fetchMyBlockedFriends', {
			token: this.$store.getters.token,
			page: this.$store.getters.friendPage,
			role: 'all',
			limit: this.$store.getters.getLimit,
			callback: this.deletePagination
		})
	},
};
</script>


<style scoped>
.error-message {
	color: red;
	font-size: 12px;
	margin-top: 0.5rem;
}


.account {
	display: flex;
}

.settings__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.settings-nav {
	margin-top: 24px;
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.settings-nav__element {
	padding: 14px 0;
	transition: all 0.5s ease;
	font-family: "SFUIDisplay", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 1.2;
	letter-spacing: -0.01em;
	text-align: left;
	color: rgba(0, 0, 0, 0.5);
}

.settings-nav__element--selected {
	color: #009ed1;
	background: #eaf5f9;
	border-radius: 7px;
	padding: 14px 50px;
	display: flex;

}

.content {
	max-width: 100%;
	width: 100%;
}

.town-select {

	max-height: 300px;
	overflow: auto;
}

.select-btn {
	padding: 7px 12px;
	font-size: 16px;
}

.settings__text {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.settings__wrapper {
	margin-top: 32px;
	display: flex;
	gap: 69px;
	align-items: flex-start;
}

.user-container {
	max-width: 315px;
	width: 100%;
}

.user {
	max-width: 100%;
	width: 100%;
	padding: 35px 45px 27px 45px;
	border-radius: 20px;
	text-align: center;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
}

.delete {
	display: block;
	margin: 20px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	text-align: center;
	padding: 14px 32px 16px 32px;
	background: #f1f5f9;
	border-radius: 8px;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #009ed1;
}


.user-face-img {
	cursor: pointer;
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background-position: center;
	background-repeat: repeat;
	background-size: auto;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 100%;
}

.user-face-name {
	margin-top: 20px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #3b4c68;
	word-break: break-all;
}

.user-face-designation {
	padding-top: 7px;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #b7c1d1;
}

.user-change {
	margin-top: 20px;
	background: #009ed1;
	border-radius: 8px;
}

.user-change-photo {
	height: 45px;
	width: 100%;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.user-change-photo:hover {
	opacity: 0.5;
}

.info {
	max-width: 100%;
	width: 100%;
}

.info-small {
	display: flex;
	gap: 20px;
	justify-content: space-between;
	max-width: 100%;

}


.info-column {
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.info-text {
	overflow-y: hidden;

	width: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.info-input {
	display: flex;
	align-items: center;
	margin-top: 8px;
	padding-left: 16px;
	height: 46px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #171725;
	flex-shrink: 1;

	width: 100%;
	padding-right: 20px;
	-webkit-appearance: none;
}

.info-input::placeholder {
	color: #b7c1d1;
}

.info-big {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.info-textaria {
	font-family: "Montserrat";

	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.25px;
	text-align: left;
	margin-top: 8px;
	width: 100%;
	height: 95px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	padding: 12px 16px;
	color: #171725;
}

.info-textaria::placeholder {
	color: #b7c1d1;
}


.info-btngroup {
	display: flex;
	margin-top: 51px;
	width: 100%;
	justify-content: right;
}

.info-btngroup-btn {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #696974;
	width: 100%;
	height: 65px;
	background: #f1f1f5;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.info-btngroup-btn:hover {
	opacity: 0.5;
}

.btn-blue {
	margin-right: 54px;
	color: #ffffff;
	background: #009ed1;
}

.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	width: 100%;
	max-width: 100%;
}

.confidentiality {
	display: flex;
	flex-direction: column;
	gap: 20px;
}


.mobile {
	display: none;
}


.blocked-users {
	gap: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.blocked-users :deep(.friend-wrapper) {
	margin-bottom: 0;
}

@media (max-width: 1576px) {
	.blocked-users {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.settings__wrapper {
		gap: 30px;
	}
}

@media (max-width: 1028px) {
	.settings__title {
		text-align: center;
	}

	.settings__text {
		text-align: center;
	}

	.settings__wrapper {
		margin-top: 20px;
		flex-direction: column;
	}

	.user-container {
		margin: 0 auto;
	}

	.info {
		margin-left: 0;
	}

	.user {
		margin: 0px auto 0 auto;
	}

	.info-btngroup {
		justify-content: space-around;
	}

	.info-btngroup-btn {
		height: 42px;
		width: 300px;
		border-radius: 10px;
	}


}

@media (max-width: 767px) {
	.mobile {
		display: block;
	}

	.desktop {
		display: none;
	}

	.settings__wrapper {
		margin-top: 20px;
	}

	.settings__text {
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.info-small {
		flex-direction: column;
	}

	.info-column {
		max-width: 100%;
	}

	.settings-nav {
		position: relative;
		max-width: 315px;
		width: 100%;
		margin: 0 auto;
	}

	.settings-nav__list {
		margin-top: 8px;
		position: absolute;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border: 1px solid #efeff4;
		border-radius: 10px;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
		padding: 33px 16px;
		gap: 8px;
		transform: translateY(10%);
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s ease;
		z-index: 2;
	}

	.settings-nav__list--open {
		transform: translateY(0%);
		opacity: 1;
		pointer-events: all;
	}

	.settings-nav__element {
		padding: 13px 22px;
		background: #efeff4;
		border-radius: 8px;
	}

	.settings-nav__element--selected {
		max-width: 100%;
		width: 100%;
		background: #eaf5f9;
		justify-content: space-between;
		gap: 10px;
		border-radius: 7px;
		align-items: center;
	}

	.settings-nav__icon {
		display: flex;
		flex-shrink: 0;
		width: 12px;
		height: 12px;
		transition: all 0.5s ease;
	}

	.settings-nav__icon--active {
		transform: rotate(180deg);
	}

	.grid-2 {
		gap: 10px;
		grid-template-columns: 1fr;
	}

	.info-big {
		gap: 10px;
	}

	.info-column {
		gap: 10px;
	}

	.info-small {
		gap: 10px;
	}
}

@media (max-width: 720px) {
	.blocked-users {
		gap: 20px;
	}
}

@media (max-width: 640px) {
	.info-btngroup {
		flex-direction: column;
	}

	.info-btngroup-btn {
		margin: 0 auto 15px auto;
	}

}

@media (max-width: 570px) {
	.blocked-users {
		grid-template-columns: repeat(1, 1fr);
	}

	.blocked-users:deep(.friend-wrapper) {
		max-width: 100%;
	}
}

@media (max-width: 370px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.settings__text {
		text-align: left;
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.settings__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

}

.search__sorting-select-container {
	width: 100%;
	height: 46px;
	margin-top: 8px;
	position: relative;

	display: block;
	align-items: center;
	transition: border 1s ease;
	border-radius: 8px;
}

.search__sorting-select-container {
	width: 100%;
	height: 46px;
	margin-top: 8px;
	position: relative;

	display: block;
	align-items: center;
	transition: border 1s ease;
	border-radius: 8px;
}

.search__sorting-select {
	text-align: left;
	padding: 10px 20px 9px 20px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 1.76;
	color: #424242;
	width: 100%;
	transition: color 1s ease, border 1s ease;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-repeat: no-repeat;
	background-position: calc(96%);
	background-size: 10px;
}

.search__sorting-select-speciality-group {
	background: #fff;
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;

	z-index: 100;
	max-height: 250px;
	overflow: auto;
	scrollbar-width: thin;
	border: 1px solid #b4b4bb;
	border-radius: 8px;
}

.search__sorting-select-speciality-group-element {
	cursor: pointer;
	width: 100%;
	border-bottom: 1px solid #b4b4bb;
	border-top: none;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
	display: flex;
	padding-left: 4px;
}

.search__sorting-select-speciality-group-element-input {}

.search__sorting-select-speciality-group-element-label {
	width: 100%;
	box-sizing: border-box;
	display: block;
	width: 100%;
	padding: 7px 12px;
	cursor: pointer;
}




.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.search__sorting-select-speciality-group-element:last-child {
	border-bottom: none;
}



.search__sorting-select:hover {
	color: #19191d;
	border: 1px solid #009ed1;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search__sorting-select-speciality-group-element-label {
	width: 100%;

	display: block;
	width: 100%;
	padding: 7px 12px;
	cursor: pointer;
}

.settings_wrapper-info-checkbox {
	width: 100%;
}

.town-input {
	width: 100%;

	display: block;
	width: 100%;
	padding: 7px 12px;
	font-size: 16px;
	cursor: auto;
}

.friend-checkbox {
	margin-left: 10px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	color: #171725;
}

.mt15 {
	margin-top: 15px;
}

.header__wrapper-lang-wrapper {

background: #fff;
border: 1px solid #EAEAEE;
border-radius: 6px;
position: relative;
margin-right: 10px;
font-family: "Roboto";
font-weight: 400;
font-size: 14px;
color: #171725;
}

.header__wrapper-lang-selected {
padding: 5px 25px 5px 5px;
background: url(../../../assets/img/all-img/list-grey.svg);
background-position: 33px;
background-size: 8px;
background-repeat: no-repeat;
}

.header__wrapper-lang-choice {
position: absolute;
left: -1px;
bottom: -22px;
}

.header__wrapper-lang-choice-element {
padding: 5px 24px 5px 5px;
background: #fff;
border-radius: 6px;
border: 1px solid #EAEAEE;
border-radius: 6px;
transition: all 1s ease-in-out;
color: #000;
}

.header__wrapper-lang-choice-element:hover {
background: #e3f4fa;
color: #009ed1;
}

.header__wrapper-lang {
display: flex;
gap: 3px;
font-weight: 500;
font-size: 15px;
line-height: 18px;
letter-spacing: 0.1px;
color: #000;
cursor: pointer;

}

.header-lang__element {
transition: opacity 1s ease;
color: inherit;
}

.header-lang__element:hover {
opacity: 0.5;
}

.lang-chosen {

color: #009ed1;
}

</style>
