<template>
	<div class="community-wrapper">
		<div class="community__face">
			<img src="~@/assets/img/account-img/communities/logo.svg" alt="" class="community__face-img">
			<div class="community__face-status community__face-status-active"></div>
		</div>
		<div class="community__info">
			<h3 class="community__info-name">{{ $t('components.social.possibleCommunities.communityName') }} <span class="community__status ">{{ $t('components.social.possibleCommunities.openStatus') }}</span></h3>
			<p class="community__info-speciality">{{ $t('components.social.possibleCommunities.participantsCount') }}</p>
		</div>
		<button class="community__add community__added"></button>
	</div>
</template>

<script>
export default {
	name: "PossibleCommunities",
};
</script>


<style scoped>
.community-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	background: #ffffff;
	padding: 10px 10px;
	align-items: center;
	border-bottom: 1px solid #efeff4;
}

.community__face {
	position: relative;
}

.community__face-img {
	width: 40px;
	height: 40px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
}

.community__face-status {
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 100%;
}

.community__face-status.community__face-status-active {
	background: #14c686;
	border: 2px solid #fff;
}

.community__info {
	margin-left: 10px;
}

.community__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.community__status {
	margin-left: 10px;
	padding: 4px 6px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 10px;
	border-radius: 20px;
	color: #14c686;
}

.community__status.status-cancellation {
	color: #e7665e;
	background: rgba(231, 102, 94, 0.20000000298023224);
}


.community__info-speciality {
	margin-top: 5px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.community__add {
	width: 15px;
	height: 15px;
	margin: auto 0 auto auto;
	display: flex;
	background-image: url("~@/assets/img/account-img/communities/plus.svg");
	background-size: cover;
	cursor: pointer;
	transition: opacity 1s ease;
}

.community__add:hover {
	opacity: 0.5;
}

.community__add.community__added {
	height: 11px;
	background-image: url("~@/assets/img/account-img/communities/succes.svg");
}

.community__add.community__add-text {
	font-family: "Roboto";
	background: none;
	font-weight: 500;
	font-size: 10px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.5);
	width: unset;
	height: unset;
}

@media (max-width: 720px) {
	.community-wrapper {
		padding: 10px 0;
	}

	.community__info {
		margin-left: 10px;
	}

	.community__info-name {
		display: flex;
		align-items: center;
	}

	.community__status {
		display: block;
		width: 4px;
		height: 4px;
		background: #14c686;
		font-size: 0;
		padding: 0;
		margin-left: 2px;
	}

	.community__status.status-cancellation {
		background: #e7665e;
	}
}
</style>
