<template>
	<div class="photos-wrapper">
		<button class="photos__left" :class="{ 'btn_disabled': !index }" @click="index--">
			<img src="../../../../../assets/img/all-img/arrow-back.svg" v-if="index" alt="" class="photos__left-img">
		</button>
		<div class="photos-content">
			<button class="delete" v-if="change" @click="$emit('delete', index - 1)">
				{{ $t('components.account_worker.showPhotos.delete') }}
			</button>
			<img :src="currentPhoto" alt=" " class="photos__img">
		</div>
		<button class="photos__right" :class="{ 'btn_disabled': index === photos?.length - 1 }" @click="index++">
			<img src="../../../../../assets/img/all-img/arrow-back.svg" v-if="index !== photos?.length - 1" alt=""
				class="photos__right-img">
		</button>
		<button class="close" @click="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
				<g clip-path="url(#clip0_0_2689)">
					<path
						d="M38 12.83L35.17 10L24 21.17L12.83 10L10 12.83L21.17 24L10 35.17L12.83 38L24 26.83L35.17 38L38 35.17L26.83 24L38 12.83Z"
						fill="white" />
				</g>
				<defs>
					<clipPath id="clip0_0_2689">
						<rect width="48" height="48" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</button>
	</div>
</template>

<script>
export default {
	name: "ShowPhotos",
	props: ['photos', 'change', 'current'],
	computed: {
		currentPhoto() {
			return this.photos?.length > 0 ? this.photos[this.index] : null;
		}
	},
	data() {
		return {
			index: 0,
		}
	},
	methods: {
		close() {
			this.$emit('close');
		}
	},
	beforeMount() {
		this.photos = this.current ? [this.current].concat(this.photos || []) : (this.photos || []);
		if (!this.photos.length) {
			this.$emit('close');
		}
	}
};
</script>


<style scoped>
* {
	box-sizing: border-box;
}

.btn_disabled {
	pointer-events: none;

}

.photos-wrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99999;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 16px;
	overflow: auto;
	scrollbar-width: thin;
}

.delete {
	position: absolute;
	top: 20px;
	right: 20px;
	background: #f9eeee;
	border-radius: 7px;
	display: flex;
	align-items: center;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ab3e5e;
	width: 120px;
	height: 40px;
	justify-content: center;
	padding: 16px;
	overflow: auto;
}

.photos {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.photos__left {
	height: 100%;
	width: 10vw;
	display: flex;
	align-items: center;
	transition: opacity 1s ease-in-out;
}

.photos__left:hover {
	opacity: 0.5;
}

.photos__left-img {
	width: 2vw;
	object-fit: cover;
	margin-left: 3vw;
}

.photos-content {
	width: 40vw;
	position: relative;
	margin: auto;
}

.photos__img {
	width: 100%;
}

.photos__right {
	height: 100%;
	width: 10vw;
	display: flex;
	align-items: center;
	justify-content: right;
	transition: opacity 1s ease-in-out;
}

.photos__right:hover {
	opacity: 0.5;
}

.photos__right-img {
	width: 2vw;
	object-fit: cover;
	margin-right: 3vw;
	transform: rotate(180deg);

}

.close {
	position: absolute;
	top: 16px;
	right: 16px;
	width: 40px;
	height: 40px;
}

.close svg {
	width: 100%;
	height: 100%;
}

@media (max-width: 850px) {

	.photos-content {
		width: 80vw;
	}

	.photos__left-img {
		width: 4vw;
	}

	.photos__right-img {
		width: 4vw;
	}

	.delete {
		top: 10px;
		right: 10px;
		font-size: 13px;
		width: 80px;
		height: 30px;
	}

	.close {

		width: 30px;
		height: 30px;
	}

}
</style>
