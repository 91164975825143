<template>
  <header class="header">
    <div class="header__wrapper">
      <router-link
        :to="{ name: authorized ? ($store.getters.role === 'CANDIDATE' ? 'dashboard' : 'dashboardCompany') : 'home' }"
        class="header__wrapper-logo logo">
        <img src="~@/assets/img/all-img/logo.png" alt="" class="header__wrapper-logo-img" />
      </router-link>
      <div class="header__wrapper-burger" v-on:click="showFilters()">
        <span class="header__wrapper-burger-span"></span>
      </div>
      <ul class="header__wrapper-nav">
        <li>
          <router-link :to="{ name: 'home' }" class="header__wrapper-nav-item">{{
            $t('components.landing.header.mainPage') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'about' }" class="header__wrapper-nav-item">{{
            $t('components.landing.header.aboutUs') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'blog' }" class="header__wrapper-nav-item">{{ $t('components.landing.header.blog')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'help' }" class="header__wrapper-nav-item">{{ $t('components.landing.header.help')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'contact' }" class="header__wrapper-nav-item">{{
            $t('components.landing.header.contactUs') }}</router-link>
        </li>
      </ul>

      <div class="header__wrapper-profile" v-if="authorized">
        <div class="header__wrapper-lang-wrapper" v-if="false">
          <button class="header__wrapper-lang-selected" @click="viewLanguage = !viewLanguage">En</button>
          <div class="header__wrapper-lang-choice" v-if="viewLanguage">
            <a :href="'https://theprofessional.ru' + $route.path" class="header__wrapper-lang-choice-element">Ru</a>
          </div>
        </div>
        <button tabindex="-1" class="menu-container" style="
            display: flex;
            align-items: center;
            position: relative;
          ">
          <div :class="{ 'menu-noname': !$store.getters.name }" class="menu-worker"
            v-if="menuWorkerVisible && role === 'CANDIDATE'">
            <div class="menu-worker__wrapper">
              <nav class="menu-worker__wrapper-nav">
                <router-link class="menu-worker__wrapper-nav-item item-dashboard" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/dashboard',
                }" :to="{ name: 'dashboard' }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-dashboard" :class="{
                    'item-white-dashboard':
                      this.$route.path === '/candidate/account/dashboard',
                  }">{{ $t('components.landing.header.personalCabinet') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-friends" :class="{
                  'item-blue': this.$route.path === '/user/social/friends',
                }" :to="{ name: 'friends' }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-friends" :class="{
                    'item-white-friends':
                      this.$route.path === '/user/social/friends',
                  }">{{ $t('components.landing.header.friends') }} <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span></a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-communities" :class="{
                  'item-blue': this.$route.path === '/candidate/account/communities',
                }" :to="{ name: 'communities' }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-communities" :class="{
                    'item-white-communities':
                      this.$route.path === '/candidate/account/communities',
                  }">{{ $t('components.landing.header.communities') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-settings" :to="{ name: 'settings' }" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/settings',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-settings" :class="{
                    'item-white-settings':
                      this.$route.path === '/candidate/account/settings',
                  }">{{ $t('components.landing.header.profileSettings') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-documents" :to="{ name: 'documents' }" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/documents',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-documents" :class="{
                    'item-white-documents':
                      this.$route.path === '/candidate/account/documents',
                  }">{{ $t('components.landing.header.myDocuments') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-rating" :to="{ name: 'rate' }" :class="{
                  'item-blue': this.$route.path === '/candidate/account/rate',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-rating" :class="{
                    'item-white-rating':
                      this.$route.path === '/candidate/account/rate',
                  }">{{ $t('components.landing.header.rating') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-tests" :to="{ name: 'testing' }" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/testing',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-tests" :class="{
                    'item-white-tests':
                      this.$route.path === '/candidate/account/testing',
                  }">{{ $t('components.landing.header.careerTest') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-interview" :to="{ name: 'videoInterview' }"
                  :class="{
                    'item-blue':
                      this.$route.path === '/candidate/account/video-interview',
                  }">
                  <a href="" class="menu__wrapp-nav-item-link-interview" :class="{
                    'item-white-interview':
                      this.$route.path === '/candidate/account/video-interview',
                  }">{{ $t('components.landing.header.videoInterview') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-vacancies" :to="{ name: 'vacancies' }" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/vacancies',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-vacancies" :class="{
                    'item-white-vacancies':
                      this.$route.path === '/candidate/account/vacancies',
                  }">{{ $t('components.landing.header.searchJob') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item  item-favorites" :to="{ name: 'favorites' }" :class="{
                  'item-blue': this.$route.path === '/candidate/account/vacancies',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-favorites" :class="{
                    'item-white-favorites':
                      this.$route.path === '/candidate/account/vacancies',
                  }">{{ $t('components.landing.header.favorites') }}<span
                      class="friends-messages favorites--message">25+</span></a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-rates" :to="{ name: 'responses' }" :class="{
                  'item-blue':
                    this.$route.path === '/candidate/account/responses',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-rates" :class="{
                    'item-white-rates':
                      this.$route.path === '/candidate/account/responses',
                  }">{{ $t('components.landing.header.myApplications') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-recommendations"
                  :to="{ name: 'recommendations' }" :class="{
                    'item-blue':
                      this.$route.path === '/candidate/account/recommendations',
                  }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-recommendations" :class="{
                    'item-white-recommendations':
                      this.$route.path ===
                      '/candidate/account/recommendations',
                  }">{{ $t('components.landing.header.recommendations') }}</a>
                </router-link>

                <router-link class="menu-worker__wrapper-nav-item item-faq" :to="{ name: 'faq' }" :class="{
                  'item-blue': this.$route.path === '/candidate/account/faq',
                }">
                  <a href="" class="menu-worker__wrapper-nav-item-link-faq" :class="{
                    'item-white-faq':
                      this.$route.path === '/candidate/account/faq',
                  }">{{ $t('components.landing.header.faq') }}</a>
                </router-link>
              </nav>
              <div class="menu-worker__wrapper-end">
                <div class="menu-worker__wrapper-leave">
                  <button class="menu-worker__wrapper-leave-text" @click="logout">
                    {{ $t('components.landing.header.logOut') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div :class="{ 'menu-noname': !$store.getters.name }" class="menu-company"
            v-if="menuWorkerVisible && role === 'EMPLOYER'">
            <div class="menu-company__wrapper">
              <nav class="menu-company__wrapper-nav">
                <router-link class="menu-company__wrapper-nav-item item-dashboard" :class="{
                  'item-blue':
                    this.$route.path === '/employer/account/dashboard',
                }" :to="{ name: 'dashboardCompany' }">
                  <a href="" class="menu-company__wrapper-nav-item-link-dashboard" :class="{
                    'item-white-dashboard':
                      this.$route.path === '/employer/account/dashboard',
                  }">{{ $t('components.landing.header.personalCabinet') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-friends" :class="{
                  'item-blue': this.$route.path === '/user/social/friends',
                }" :to="{ name: 'friends' }">
                  <a href="" class="menu-company__wrapper-nav-item-link-friends" :class="{
                    'item-white-friends':
                      this.$route.path === '/user/social/friends',
                  }">{{ $t('components.landing.header.friends') }} <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span></a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-communities" :class="{
                  'item-blue': this.$route.path === '/candidate/account/communities',
                }" :to="{ name: 'communities' }">
                  <a href="" class="menu-company__wrapper-nav-item-link-communities" :class="{
                    'item-white-communities':
                      this.$route.path === '/candidate/account/communities',
                  }">{{ $t('components.landing.header.communities') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-settings" :to="{ name: 'settingsCompany' }"
                  :class="{
                    'item-blue':
                      this.$route.path === '/employer/account/settings',
                  }">
                  <a href="" class="menu-company__wrapper-nav-item-link-settings" :class="{
                    'item-white-settings':
                      this.$route.path === '/employer/account/settings',
                  }">{{ $t('components.landing.header.profileSettings') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-rating" :to="{ name: 'rateCompany' }" :class="{
                  'item-blue': this.$route.path === '/employer/account/rate',
                }">
                  <a href="" class="menu-company__wrapper-nav-item-link-rating" :class="{
                    'item-white-rating':
                      this.$route.path === '/employer/account/rate',
                  }">{{ $t('components.landing.header.rating') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-tests" :to="{ name: 'interviewPage' }" :class="{
                  'item-blue':
                    this.$route.path === '/employer/account/interview',
                }">
                  <a href="" class="menu-company__wrapper-nav-item-link-tests" :class="{
                    'item-white-tests':
                      this.$route.path === '/employer/account/interview',
                  }">{{ $t('components.landing.header.careerTest') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-vacancies" :to="{ name: 'vacanciesCompany' }"
                  :class="{
                    'item-blue':
                      this.$route.path === '/employer/account/vacancies',
                  }">
                  <a href="" class="menu-company__wrapper-nav-item-link-vacancies" :class="{
                    'item-white-vacancies':
                      this.$route.path === '/employer/account/vacancies',
                  }">{{ $t('components.landing.header.searchSpecialists') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-favorites" :to="{ name: 'favorites' }" :class="{
                  'item-blue': this.$route.path === '/candidate/account/vacancies',
                }">
                  <a href="" class="menu-company__wrapper-nav-item-link-favorites" :class="{
                    'item-white-favorites':
                      this.$route.path === '/candidate/account/vacancies',
                  }">{{ $t('components.landing.header.favorites') }}<span
                      class="friends-messages favorites--message">25+</span></a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-responses" :to="{ name: 'vacancyCompany' }"
                  :class="{
                    'item-blue':
                      this.$route.path === '/employer/account/vacancy',
                  }">
                  <a href="" class="menu-company__wrapper-nav-item-link-responses" :class="{
                    'item-white-responses':
                      this.$route.path === '/employer/account/vacancy',
                  }">{{ $t('components.landing.header.myVacancies') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-recommendations"
                  :to="{ name: 'recommendationsCompany' }" :class="{
                    'item-blue':
                      this.$route.path === '/employer/account/recommendations',
                  }">
                  <a href="" class="menu-company__wrapper-nav-item-link-recommendations" :class="{
                    'item-white-recommendations':
                      this.$route.path ===
                      '/employer/account/recommendations',
                  }">{{ $t('components.landing.header.recommendations') }}</a>
                </router-link>

                <router-link class="menu-company__wrapper-nav-item item-rates" :to="{ name: 'pricesCompany' }" :class="{
                  'item-blue':
                    this.$route.path === '/employer/account/prices',
                }">
                  <a href="" class="menu-company__wrapper-nav-item-link-rates" :class="{
                    'item-white-rates':
                      this.$route.path === '/employer/account/prices',
                  }">{{ $t('components.landing.header.pricing') }}</a>
                </router-link>

                <router-link :to="{ name: 'faqCompany' }" :class="{
                  'item-blue': this.$route.path === '/employer/account/faq',
                }" class="menu-company__wrapper-nav-item item-faq">
                  <a href="" class="menu-company__wrapper-nav-item-link-faq" :class="{
                    'item-white-faq':
                      this.$route.path === '/employer/account/faq',
                  }">{{ $t('components.landing.header.faq') }}</a>
                </router-link>
              </nav>
              <div class="menu-company__wrapper-end">
                <button @click="logout" class="menu-company__wrapper-leave-text">
                  {{ $t('components.landing.header.logOut') }}
                </button>
              </div>
            </div>
          </div>
          <img :src="avatarPath" @click="menuWorkerVisible = !menuWorkerVisible" class="header__wrapper-profile-img" />
          <div style="
					display: flex;
					align-items: center;
					position: relative;
					cursor: pointer;
					" class="menu-click-wrapper" v-on:click="menuWorkerVisible = !menuWorkerVisible" @blur="blurSelect">
            <p class="header__wrapper-profile-name">{{ name ? limit(name, 20) : '' }}</p>
            <button class="header__wrapper-profile-list"></button>
          </div>
        </button>
      </div>
      <div class="header__wrapper-profile" v-else>
        <div class="header__wrapper-lang-wrapper" v-if="false">
          <button class="header__wrapper-lang-selected" @click="viewLanguage = !viewLanguage">En</button>
          <div class="header__wrapper-lang-choice" v-if="viewLanguage">
            <a :href="'https://theprofessional.ru' + $route.path" class="header__wrapper-lang-choice-element">Ru</a>
          </div>
        </div>
        <router-link :to="{ name: 'employerReg' }" class="header__wrapper-ptofile-authorization">
          {{ $t('components.landing.header.registrationLogin') }}
        </router-link>
      </div>
    </div>
    <div class="header__menu" v-if="filtersVisible">
      <a href="" class="header__menu-logo logo"><img src="~@/assets/img/all-img/logo.png" alt=""
          class="header__menu-logo-img" /></a>
      <ul class="header__menu-nav">
        <li>
          <router-link :to="{ name: 'home' }" class="header__menu-nav-item">{{ $t('components.landing.header.mainPage')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'about' }" class="header__menu-nav-item">{{ $t('components.landing.header.aboutUs')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'blog' }" class="header__menu-nav-item">{{ $t('components.landing.header.blog')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'help' }" class="header__menu-nav-item">{{ $t('components.landing.header.help')
            }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'contact' }" class="header__menu-nav-item">{{
            $t('components.landing.header.contactUs') }}</router-link>
        </li>
      </ul>
      <div class="header__menu-btngroup">
        <router-link v-if="!authorized" :style="$route.path === '/user/login' ? { 'opacity': 0.4 } : null"
          to="/user/login" class="header__menu-btngroup-btn">
          Login
        </router-link>
      </div>
    </div>
    <ShowPhotos style="display: none;">
    </ShowPhotos>
    <transition name="fade">
      <Notification v-if="notificationView">
      </Notification>
    </transition>
  </header>
</template>


<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import ShowPhotos from "../account/account_worker/accout_worker_header-element/item/ShowPhotos.vue";
import Notification from "../../components/social/Notification.vue";

export default {
  name: "Header",
  components: { ShowPhotos, Notification },
  data() {
    return {
      menuWorkerVisible: false,
      filtersVisible: false,
      limit: textSymbolLimit,
      viewLanguage: false
    };
  },

  methods: {
    blurSelect() {
      setTimeout(() => {
        this.menuWorkerVisible = false;
      }, 300);
    },
    showFilters() {
      this.filtersVisible = !this.filtersVisible;
      if (this.filtersVisible) {
        document.onkeyup = (event) => {
          if (event.key === "Escape") {
            this.filtersVisible = false;
          }
        };
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  destroyed() {
    document.onkeyup = null;
  },
  computed: {
    avatarPath() {
      return this.$store.getters.avatarPath;
    },
    name() {
      return this.$store.getters.name;
    },
    authorized() {
      return this.$store.getters.authorized;
    },
    role() {
      return this.$store.getters.role;
    },
    user() {
      return this.$store.getters.user;
    },
    notificationView() {
      return this.$store.getters.notificationView;
    }
  },

  beforeMount() {
    this.$store.dispatch("loadUserData");
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
  {
  opacity: 0;
}


.header {
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 1000;
}

.header__wrapper {
  box-shadow: inset 0px -1px 0px #e2e2ea;
  max-height: 70px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
}

.header__wrapper-logo {
  padding-left: 49px;
  order: 1;
  height: 31px;
}

.header__wrapper-logo-img {
  width: 177px;
  height: 31px;
}

.header__wrapper-nav {
  margin: 0 auto;
  position: relative;
  order: 2;
}

.header__wrapper-nav-item:hover {
  color: #009ed1;
}

.header__wrapper-nav-item {
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #44444f;
  transition: color 1s ease;
}

.header__wrapper-nav :not(:last-child) {
  padding-right: 84px;
}

.header__wrapper-btngroup {
  display: flex;
  margin-left: auto;
  margin-right: 42px;
  order: 3;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  transition: color 1s ease, background 1s ease;
  text-align: center;
}

.header__wrapper-btngroup :hover {
  color: #009ed1;
  background: #e5f6fb;
}

.header__wrapper-btngroup :not(:last-child) {
  margin-right: 13px;
}

.header__wrapper-btngroup-btn {
  padding: 13px 0;
  display: block;
  text-decoration: none;
  width: 150px;
  border-radius: 4px;
  background: #f0f4f9;
  color: #607088;
  transition: color 1s ease, background 1s ease;
}

.header__wrapper-lang-wrapper {

  background: #fff;
  border: 1px solid #EAEAEE;
  border-radius: 6px;
  position: relative;
  margin-right: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: #171725;
}

.header__wrapper-lang-selected {
  padding: 5px 25px 5px 5px;
  background: url(../../assets/img/all-img/list-grey.svg);
  background-position: 33px;
  background-size: 8px;
  background-repeat: no-repeat;
}

.header__wrapper-lang-choice {
  position: absolute;
  left: -1px;
  bottom: -22px;
}

.header__wrapper-lang-choice-element {
  padding: 5px 24px 5px 5px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #EAEAEE;
  border-radius: 6px;
  transition: all 1s ease-in-out;
  color: #000;
}

.header__wrapper-lang-choice-element:hover {
  background: #e3f4fa;
  color: #009ed1;
}

.header__wrapper-lang {
  display: flex;
  gap: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #000;

}

.header-lang__element {
  transition: opacity 1s ease;
  color: inherit;
}

.header-lang__element:hover {
  opacity: 0.5;
}

.lang-chosen {

  color: #009ed1;
}


.header__wrapper-bell {
  width: 18px;
  height: 21px;
  background-image: url("~@/assets/img/all-img/header/bell.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.header__wrapper-bell-notifications {
  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  background: #14c686;
  border-radius: 12px;
  top: -4px;
  right: -6px;
}

.header__wrapper-profile {
  margin-right: 32px;
  order: 4;
  display: flex;
  text-align: left;
  align-items: center;
}

.header__wrapper-profile-img {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 100%;
  background: url("~@/assets/img/all-img/header/user.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.friends-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}

.favorites--message {
  background: #FAFAFB;
  color: #009ED1;
}

.header__wrapper-profile-name {
  padding-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #171625;
  max-width: 152px;
  overflow: hidden;
  display: block;
  box-sizing: border-box;
}

.header__wrapper-profile-list {
  position: relative;
  padding-left: 62px;
  width: 11px;
  height: 7px;
  background: url("~@/assets/img/all-img/list-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.header__wrapper-ptofile-authorization {
  padding: 13px 20px;
  background: #009ed1;
  border-radius: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: opacity 1s ease;
}

.header__wrapper-ptofile-authorization:hover {
  opacity: 0.5;
}

.header__menu {
  display: none;
}

@media (max-width: 1700px) {
  .header__wrapper-logo {
    padding-left: 29px;
  }

  .header__wrapper-profile {
    margin-left: auto;
  }

  .header__wrapper-nav {
    display: none;
  }

  .header__wrapper-burger {
    cursor: pointer;
    margin-right: 18px;
    order: 5;
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
  }

  .header__wrapper-burger::after,
  .header__wrapper-burger::before {
    content: "";
    background-color: #009ed1;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
  }

  .header__wrapper-burger::after {
    top: 0;
  }

  .header__wrapper-burger::before {
    bottom: 0;
  }

  .header__wrapper-burger-span {
    background-color: #009ed1;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;
  }

  .header__wrapper-nav {
    display: none;
  }

  .header__menu {
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: inset 0px -1px 0px #e2e2ea;
    display: block;
    background: #fafafb;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-right: auto;
    flex-direction: column;
    padding: 0px 24px 0px 26px;
    text-align: left;
    overflow: auto;
    padding-bottom: 40px;
  }

  .header__menu-logo {
    display: none;
  }

  .header__menu-nav {
    padding-top: 19px;
    transition: color 1s ease;
    text-align: center;
  }

  .header__menu-btngroup {
    display: none;
  }

  .header__menu-nav-item {
    font-size: 20px;
    display: block;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #44444f;
    margin-bottom: 19px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(68, 68, 79, 0.11);
    transition: color 1s ease;
  }

  .header__menu-nav-item :hover {
    color: #009ed1;
  }
}

@media (max-width: 958px) {
  .header__wrapper-profile {
    margin-left: auto;
    margin-right: 15px;
  }

  .header__menu-logo {
    margin-top: 23px;
    display: block;
    height: 31px;
  }

  .header__menu-logo-img {
    height: 31px;
    width: 177px;
    display: flex;
    margin: 0 auto;
  }

  .header__menu {
    text-align: left;
  }

  .header__menu-nav {
    padding-top: 48px;
  }

  .header__menu-nav-item {
    font-size: 15px;
  }

  .header__menu-btngroup {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    box-sizing: border-box;
    order: 3;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    transition: color 1s ease, background 1s ease;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
  }

  .header__menu-btngroup :hover {
    color: #009ed1;
    background: #e5f6fb;
  }

  .header__menu-btngroup :not(:last-child) {
    margin-bottom: 12px;
  }

  .header__menu-btngroup-btn {
    padding: 13px 0px;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    background: #f0f4f9;
    color: #607088;
    transition: color 1s ease, background 1s ease;
  }

  .header__wrapper-logo {
    display: none;
  }

  .header__wrapper-btngroup {
    display: none;
  }

  .header__wrapper-burger {
    order: 1;
    margin-left: 24px;
  }

  .header__wrapper-burger {
    margin-right: 49px;
    order: 3;
    display: block;
    position: relative;
    width: 16px;
    height: 10px;
  }

  .header__wrapper-burger::before {
    content: "";
    background-color: #92929d;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
  }

  .header__wrapper-burger::after {
    content: none;
  }

  .header__wrapper-burger-span {
    background-color: #92929d;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 2px;
  }

  .header__wrapper-ptofile-authorization {
    padding: 8px 15px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .header__wrapper-profile-list {
    display: none;
  }

  .header__wrapper-profile {
    margin-right: 24px;
  }
}

@media (max-height: 750px) {
  .header__menu {
    height: calc(100vh - 20px);
  }

  .header__menu {
    overflow-y: scroll;
    scrollbar-width: thin;
  }
}

/* Menu-worker */

/* Worker */
.menu-worker {
  position: absolute;
  top: 40px;
  width: 230px;
  background: #fafafa;
  border-radius: 0px 0px 7px 7px;
  right: 0;
}

.menu-worker__wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 230px;
}

.menu-worker__wrapper::-webkit-scrollbar {
  width: 10px;

  height: 8px;

  background-color: #f6f8fa;
  border-radius: 0px 8px 8px 0;
}

.menu-worker__wrapper::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border-radius: 10px;
}

@media (max-height: 850px) {
  .menu-worker__wrapper {
    height: calc(100vh - 120px);
  }

  .menu-worker__wrapper:hover {
    overflow-y: scroll;
    scrollbar-width: thin;
  }


}

.menu-worker__wrapper-nav {
  margin-top: 11px;
  width: 100%;
  border: center;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  box-sizing: border-box;
}

.menu-worker__wrapper-nav :not(:last-child) {
  margin-bottom: 15px;
}

.menu-worker__wrapper-nav-item {
  color: #44444f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 45px;
  box-sizing: border-box;
  background: #fafafa;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  display: flex;
  padding-left: 10px;
  text-align: left;
}

.menu-worker__wrapper-nav-item:hover {
  background: #009ed1;
  color: #fff;
}

.item-blue {
  background: #009ed1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #fff;
}

.menu-worker__wrapper-nav-item-link-dashboard {
  width: 260px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/dashboard.svg");
  background-repeat: no-repeat;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-friends {
  width: 260px;
  color: inherit;
  padding-left: 30px;
  background-image: url("~@/assets/img/account-img/menu/friends.png");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0px;
  background-position-y: center;
}

.menu-worker__wrapper-nav-item-link-communities {
  width: 260px;
  color: inherit;
  padding-left: 30px;
  background-image: url("~@/assets/img/account-img/menu/communities.png");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0px;
  background-position-y: center;
}


.menu-worker__wrapper-nav-item-link-settings {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/settings.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-documents {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/documents.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: -3px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-rating {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/rating.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  padding-left: 30px;
  background-position-y: center;
}

.menu-worker__wrapper-nav-item-link-tests {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tests.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-vacancies {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/surch.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-favorites {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/favorites.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-recommendations {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/recomendation.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-rates {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/like.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-faq {
  color: inherit;
  width: 260px;
  background: url("~@/assets/img/account-img/menu/question.svg");
  background-repeat: no-repeat;
  text-align: left;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-tarif {
  width: 260px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tarif.svg");
  background-repeat: no-repeat;
  text-align: left;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-worker__wrapper-leave-text {
  padding: 15px 0;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  background: rgba(220, 11, 11, 0.1599999964237213);

  border: 1px solid rgba(220, 11, 11, 0.4);
  border-radius: 8px;
  transition: opacity 1s ease;
  color: #db0a0a;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.menu-worker__wrapper-leave-text:hover {
  opacity: 0.5;
}

.menu-worker__wrapper-end {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 17px 0 22px 0;
}



@media (max-width: 350px) {
  .menu-worker__wrapper-chat-text {
    background-position: 103px;
  }
}

/* /Worker */

/* /Menu worker */

/* /Menu company */

/* Company */

.menu-company {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 230px;
  background: #fafafa;
  border-radius: 0px 0px 7px 7px;
}

.menu-company__wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 230px;
}

.menu-company__wrapper::-webkit-scrollbar {
  width: 10px;

  height: 8px;

  background-color: #f6f8fa;
  border-radius: 0px 8px 8px 0;
}

.menu-company__wrapper::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border-radius: 10px;
}

@media (max-height: 850px) {
  .menu-company__wrapper {
    height: calc(100vh - 120px);
  }

  .menu-company__wrapper:hover {
    overflow-y: scroll;
    scrollbar-width: thin;
  }
}

.menu-company__wrapper-nav {
  margin-top: 11px;
  width: 100%;
  border: center;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  box-sizing: border-box;
}

.menu-company__wrapper-nav :not(:last-child) {
  margin-bottom: 15px;
}

.menu-company__wrapper-nav-item {
  color: #44444f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 45px;
  box-sizing: border-box;
  background: #fafafa;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  display: flex;
  padding-left: 10px;
  text-align: left;
}

.menu-company__wrapper-nav-item:hover {
  background: #009ed1;
  color: #fff;
}

.item-blue {
  background: #009ed1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #fff;
}

.menu-company__wrapper-nav-item-link-dashboard {
  width: 260px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/dashboard.svg");
  background-repeat: no-repeat;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-friends {
  width: 260px;
  color: inherit;
  padding-left: 30px;
  background-image: url("~@/assets/img/account-img/menu/friends.png");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0px;
  background-position-y: center;
}

.menu-company__wrapper-nav-item-link-communities {
  width: 260px;
  color: inherit;
  padding-left: 30px;
  background-image: url("~@/assets/img/account-img/menu/communities.png");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0px;
  background-position-y: center;
}

.menu-company__wrapper-nav-item-link-settings {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/settings.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-documents {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/documents.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-rating {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/rating.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  padding-left: 30px;
  background-position-y: center;
}

.menu-company__wrapper-nav-item-link-responses {
  background: url("~@/assets/img/account-img/menu/response-black.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  padding-left: 30px;
  background-position-y: center;
  color: inherit;
}

.menu-company__wrapper-nav-item-link-groups {
  background: url("~@/assets/img/account-img/menu/groups.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  padding-left: 30px;
  background-position-y: center;
  color: inherit;
}

.menu-company__wrapper-nav-item-link-recommendations {
  background: url("~@/assets/img/account-img/menu/recomendation.svg");
  background-repeat: no-repeat;
  width: 260px;
  text-align: left;
  background-position: 0px;
  padding-left: 30px;
  background-position-y: center;
  color: inherit;
}

.menu-company__wrapper-nav-item-link-tests {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tests.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu__wrapp-nav-item-link-interview {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/video.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
  text-align: left;
}

.menu-company__wrapper-nav-item-link-vacancies {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/surch.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-favorites {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/favorites.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-recommendations {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/recomendation.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-rates {
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tarif.svg");
  background-repeat: no-repeat;
  width: 260px;
  background-position: 0px;
  background-position-y: center;
  text-align: left;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-faq {
  color: inherit;
  width: 260px;
  background: url("~@/assets/img/account-img/menu/question.svg");
  background-repeat: no-repeat;
  text-align: left;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-company__wrapper-nav-item-link-tarif {
  width: 260px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tarif.svg");
  background-repeat: no-repeat;
  text-align: left;
  background-position: 0px;
  background-position-y: center;
  padding-left: 30px;
}

.menu-company__wrapper-leave-text {
  padding: 15px 0;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  background: rgba(220, 11, 11, 0.1599999964237213);

  border: 1px solid rgba(220, 11, 11, 0.4);
  border-radius: 8px;
  transition: opacity 1s ease;
  color: #db0a0a;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.menu-company__wrapper-leave-text:hover {
  opacity: 0.5;
}

.menu-company__wrapper-end {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 17px 0 22px 0;
}

@media (max-width: 958px) {
  .menu-company {
    top: 40px;
  }
}

@media (max-width: 500px) {

  .header__wrapper-lang {
    font-size: 13px;
    gap: 2px;
  }

  .header__wrapper-profile-name {
    display: none;
  }

}

/* /Company */

/* Modificators */
.item-white-dashboard {
  background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
  background-repeat: no-repeat;
}

.item-white-friends {
  background-image: url("~@/assets/img/account-img/menu/friends-white.png");
  background-repeat: no-repeat;
}

.item-white-communities {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
  background-repeat: no-repeat;
}

.item-white-settings {
  background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
  background-repeat: no-repeat;
}

.item-white-documents {
  background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
  background-repeat: no-repeat;
}

.item-white-rating {
  background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
  background-repeat: no-repeat;
}

.item-white-tests {
  background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
  background-repeat: no-repeat;
}

.item-white-interview {
  background-image: url("~@/assets/img/account-img/menu/video-white.svg");
  background-repeat: no-repeat;
}


.item-white-vacancies {
  background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
  background-repeat: no-repeat;
}

.item-white-favorites {
  background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
  background-repeat: no-repeat;
}

.item-white-recommendations {
  background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
  background-repeat: no-repeat;
}

.item-white-rates {
  background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
  background-repeat: no-repeat;
}

.item-white-faq {
  background-image: url("~@/assets/img/account-img/menu/question-white.svg");
  background-repeat: no-repeat;
}

.item-white-responses {
  background-image: url("~@/assets/img/account-img/menu/response-white.svg");
  background-repeat: no-repeat;
}

.item-white-groups {
  background-image: url("~@/assets/img/account-img/menu/groups-white.svg");
  background-repeat: no-repeat;
}

/*  */

.item-dashboard:hover a {
  background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
}

.item-dashboard:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-friends:hover a {
  background-image: url("~@/assets/img/account-img/menu/friends-white.png");
}

.item-friends:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-communities:hover a {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
}

.item-communities:hover {
  background-color: #009ed1;
  color: #fff;
}


.item-settings:hover a {
  background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
}

.item-settings:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-documents:hover a {
  background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
}

.item-documents:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-rating:hover a {
  background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
}

.item-rating:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-tests:hover a {
  background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
}

.item-tests:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-interview:hover a {
  background-image: url("~@/assets/img/account-img/menu/video-white.svg");
}

.item-interview:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-vacancies:hover a {
  background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
}

.item-vacancies:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-favorites:hover a {
  background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
}

.item-favorites:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-rates:hover a {
  background-image: url("~@/assets/img/account-img/menu/rates-white.svg");
}

.item-rates:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-recommendations:hover a {
  background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
}

.item-recommendations:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-tarif:hover a {
  background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
}

.item-tarif:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-faq:hover a {
  background-image: url("~@/assets/img/account-img/menu/question-white.svg");
}

.item-faq:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-responses:hover a {
  background-image: url("~@/assets/img/account-img/menu/response-white.svg");
}

.item-responses:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-groups:hover a {
  background-image: url("~@/assets/img/account-img/menu/groups-white.svg");
}

.item-groups:hover {
  background-color: #009ed1;
  color: #fff;
}



/* /Menu company */
</style>
