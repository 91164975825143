<template>
  <div class="community-wrapper" v-if="
    (mode === 'community' && comData.requestApproved === 'in') ||
    (mode === 'request' && comData.requestApproved === 'request') ||
    (role === 'all' && mode === 'community') ||
    role === 'myCreate'
  ">
    <div class="communitys-separate__left" @click="goToCommunity">
      <div class="community__face">
        <img :src="comData.avatarUrl" alt="" class="community__face-img" />
      </div>
      <div class="community__info">
        <div class="community__info-caption">
          <h3 class="community__info-name">
            {{ comData.name ? limit(comData.name, 25) : $t('components.social.mySeparateCommunity.noData')
            }}<span class="community__status" :class="{
              'status-cancellation': comData.communityType === 'close',
            }">{{ comData.communityType === "close" ? $t('components.social.mySeparateCommunity.closed') :
                $t('components.social.mySeparateCommunity.open') }}</span>
          </h3>
        </div>
        <p class="community__info-speciality">
          {{
            comData?.description?.slice(0, 70) +
            (comData?.description?.length > 70 ? "..." : "")
          }}
        </p>
        <p class="community__info-designation">
          {{ comData.userCount ? comData.userCount : 0 }} {{ $t('components.social.mySeparateCommunity.participants') }}
        </p>
      </div>
    </div>
    <button type="button" class="community__join-button" v-if="
      role !== 'my' && role !== 'myCreate' && comData.requestApproved !== 'in'
    " :class="{
        'button-disable': sendRequest || comData.requestApproved === 'request',
        'button-join': joinCommunity,
      }" @click="joinInCommunity">
      {{
        comData.communityType === "close"
          ? comData.requestApproved === "request"
            ? $t('components.social.mySeparateCommunity.applicationUnderConsideration')
            : $t('components.social.mySeparateCommunity.apply')
          : $t('components.social.mySeparateCommunity.joinCommunity')
      }}
    </button>

    <button v-if="role === 'myCreate' || mode === 'request'" type="button" class="community__settings community-desctop"
      v-on:click="menuVisible = !menuVisible">
      <div class="community__settings-popap" v-if="menuVisible">
        <button class="community__settings-delete" v-if="role === 'myCreate'" @click="
          $store.dispatch('deleteMyCommunities', {
            id: comData.id,
            token: $store.getters.token,
            index: index,
          })
          ">
          {{ $t('components.social.mySeparateCommunity.delete') }}
        </button>

        <button class="community__settings-delete" v-if="role === 'my'" @click="
          $store.dispatch('deleteMyApplicationInCommunities', {
            id: comData.id,
            token: $store.getters.token,
            index: index,
          })
          ">
          {{ $t('components.social.mySeparateCommunity.cancel') }}
        </button>
      </div>
    </button>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { errorHandler } from "@/lib/errorHandler";

export default {
  computed: {
    role() {
      return this.$store.getters.getRoleCommunity;
    },
  },
  name: "MySeparateCommunity",
  props: ["comData", "index", "mode"],
  data() {
    return {
      menuVisible: false,
      limit: textSymbolLimit,
      sendRequest: false,
      joinCommunity: false,
    };
  },
  methods: {
    goToCommunity() {
      switch (this.role) {
        case "myCreate":
          this.$router.push({
            name: "myCommunities",
            params: { communitiesId: this.comData.id },
          });
          break;
        case "all":
          if (this.comData.communityType === "close") {
            if (!this.comData.requestApproved)
              errorHandler({ status: 400, page: "closeCom" });
            if (this.comData.requestApproved === "request")
              errorHandler({ status: 400, page: "reqCom" });
            if (this.comData.requestApproved === "in")
              this.$router.push({
                name: "communitiesPage",
                params: { communitiesId: this.comData.id },
              });
          } else {
            this.$router.push({
              name: "communitiesPage",
              params: { communitiesId: this.comData.id },
            });
          }
          break;
        case "my":
          if (this.mode === "community") {
            this.$router.push({
              name: "communitiesPage",
              params: { communitiesId: this.comData.id },
            });
          } else {
            errorHandler({ status: 400, page: "reqCom" });
          }
          break;
        default:
          errorHandler({ status: 500 });
          break;
      }
    },
    joinInCommunity() {
      this.$store.dispatch("sendRequestJoinCommunity", {
        id: this.comData.id,
        token: this.$store.getters.token,
        callback: (val) => {
          this.$emit("updatePage", val);
        },
      });
    },
  },
};
</script>


<style scoped>
.community-wrapper {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  padding: 20px 30px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  margin-bottom: 20px;
  flex-shrink: 1;
}

.caption-wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
}

.communitys-separate__left {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.community__face {
  position: relative;
}

.community__face-img {
  width: 64px;
  height: 64px;
  display: flex;
  flex-shrink: 0;
  border-radius: 10px;
}

.community__info {
  margin-left: 19px;
}

.community__info-name {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.community__status {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  color: #14c686;
}

.community__status.status-cancellation {
  color: #e7665e;
  background: rgba(231, 102, 94, 0.20000000298023224);
}

.community__info-speciality {
  margin-top: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: rgba(23, 22, 37, 0.5);
}

.community__info-designation {
  padding-top: 6px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #009ed1;
}

.community__join-button {
  background: #009ed1;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin: auto 0 auto 0;
  width: 214px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
}

.community__join-button:hover {
  opacity: 0.5;
}

.community__message-settings {
  background-image: url("~@/assets/img/account-img/menu/chat.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.community__settings {
  position: relative;
  display: block;
  background: url("~@/assets/img/account-img/friends/settings.svg");
  width: 40px;
  height: 40px;
  margin: auto 0 auto auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.community__settings-popap {
  position: absolute;
  border: 1px solid #efeff4;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  padding: 10px 20px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  bottom: -55px;
  right: -45px;
  z-index: 20;
}

.popap-list {
  position: relative;
  text-align: left;
}

.community__settings-popap::after {
  position: absolute;
  top: -10px;
  right: 52px;
  display: block;
  width: 14px;
  height: 10px;
  background: url("~@/assets/img/account-img/friends/arrow.svg");
  content: "";
  background-repeat: no-repeat;
}

.button-disable {
  opacity: 0.3;
  pointer-events: none;
}

.button-join {
  opacity: 0.3;
  pointer-events: none;
  background: #00b884;
}

.popap-list__button {
  text-align: left;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding-left: 10px;
  width: 100%;
  cursor: pointer;
}

.popap-list__button:not(:first-child) {
  margin-top: 6px;
}

.popap-list__button:last-child {
  padding-bottom: 10px;
}

.community__settings-delete {
  font-family: "Roboto";
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #e7665e;
  cursor: pointer;
}

.community-mobile {
  display: none;
}

@media (max-width: 1100px) {
  .community__info-caption {
    display: flex;
  }

  .community__settings {
    margin-left: auto;
    height: 20px;
  }

  .community__join-button {
    width: 100%;
    border-radius: 0 0 10px 10px;
  }

  .community-wrapper {
    max-width: 100%;
    padding: 0;
    flex-direction: column;
  }

  .community-desctop {
    display: none;
  }

  .community-mobile {
    display: flex;
  }

  .communitys-separate__left {
    padding: 13px 15px;
    align-items: center;
  }

  .community__info {
    width: 100%;
    margin-left: 10px;
  }

  .community__info-designation {
    padding-top: 0;
  }

  .community__info-designation {
    align-items: center;
  }

  .community__info-name {
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .community__place-town {
    color: #696974;
    margin-top: 5px;
  }

  .community__message {
    width: 100%;
    margin-left: 0;
    border-radius: 0px 0px 10px 10px;
  }

  .community__info-speciality {
    margin-top: 2px;
  }

  .community__status {
    padding: 5px 11px;
    font-size: 10px;
    line-height: 1;
  }

  .community__info-designation {
    margin-top: 6px;
  }

  .community__settings-popap {
    right: -16px;
  }

  .community__settings-popap ::after {
    right: 28px;
  }
}

@media (max-width: 720px) {
  .community-wrapper {
    padding: 0;
    margin-bottom: 0;
  }
}
</style>
