import {config} from "../../lib/config";

export const questions = {
    actions: {
        fetchTesting({commit}, payload) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + 'testing/get/' + payload.testingId;
            xmlHttp.open('GET', url, false);
            xmlHttp.setRequestHeader('X-Auth-Token', payload.token);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setTesting', data);
        },
        saveTestingData({getters, commit}, token) {
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix + 'testing/pass'
            xmlHttp.open('POST', url, false);
            xmlHttp.setRequestHeader('X-Auth-Token', token);
            xmlHttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                xmlHttp.send(JSON.stringify(getters.getSaveSetting));
                const {data} = JSON.parse(xmlHttp.response);
                commit('setPassedTestingAnswers', data);
                if (getters.getSaveSetting.answers !== null) {
                    commit('setPassedTestingInfo', {
                        title: getters.getDataTesting.title,
                        description: getters.getDataTesting.description
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }

    },
    mutations: {
        setPassedTestingAnswers(state, data) {
            state.passedTestingAnswers = data;
        },
        setPassedTestingInfo(state, data) {
            state.passedTestingInfo = data;
        },
        setTesting(state, data) {
            state.dataTesting = data;
            state.dataTesting.questions = state.dataTesting.questions.sort((a, b) => a?.qorder - b?.qorder);
        },
        setTestingId(state, data) {
            state.saveTesting.testingId = data;
            state.saveTesting.answers = [];
        },
        saveQuestionAnswer(state, payload) {
            if (state.saveTesting.answers === null)
                state.saveTesting.answers = payload.answer
            else
                state.saveTesting.answers.push(payload.answer)

        },
        setTestingSelectAnswer(state, {questionId, answerId, comparisonBody}) {
            const questionIndex = state.saveTesting.answers.map(item => (item.testingQuestionId === questionId && item.testingAnswerId === answerId) ? item.testingQuestionId : null).indexOf(questionId);
            if (questionIndex !== -1) {
                state.saveTesting.answers[questionIndex]['comparisonBody'] = comparisonBody;
                return;
            }
            state.saveTesting.answers.push({
                testingQuestionId: questionId, testingAnswerId: answerId, comparisonBody
            })
        },
        setChoose(state, data) {
            state.choose = data
        },
        setStatus(state, data) {
            state.status = data
        },
        setNullAnswer(state) {
            state.saveTesting.answers = [];
        },
        clearTesting(state) {
            state.dataTesting = null;
            state.saveTesting = {
                testingId: null,
                answers: []
            }
        }
    },
    state: {
        dataTesting: {},
        saveTesting: {
            testingId: null,
            answers: []
        },
        choose: null,
        status: null,
        passedTestingInfo: null,
        passedTestingAnswers: null
    },
    getters: {
        passedTestingAnswers: state => state.passedTestingAnswers,
        getDataTesting(state) {
            return state.dataTesting;
        },
        getChoose
            (state) {
            return state.choose;
        },
        getSaveSetting(state) {
            return state.saveTesting
        },
        getStatus(state) {
            return state.status
        },
        passedTestingInfo: state => state.passedTestingInfo
    }


}