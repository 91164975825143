<template>
	<div class="support" v-if="$route.name !== 'allChats'">
		<div class="chat" :class="{ 'chat--open': openSupport }">
			<button class="close" @click="openSupport = !openSupport"></button>
			<div class="chat-caption">
				<div class="chat-info">
					<p class="chat__title">{{ $t('components.general.support.supportForm.helpDesk') }}</p>
					<p class="chat__subtitle">{{ $t('components.general.support.supportForm.questions') }}</p>
				</div>
				<div class="admin">
					<div class="admin__status"></div>
					<img src="@/assets/img/test-data/admin-face.png" alt="Admin Face" class="admin__face">
				</div>
			</div>
			<div class="messages">
				<p class="time">{{ $t('components.general.support.supportForm.now') }}</p>
				<component v-for="(message, index) in messages" :key="index"
					:is="message.type === 'admin' ? 'admin-message' : 'user-message'" :message="message.message"
					:time="message.time">
				</component>
				<div class="recommendation">
					<p class="recommendation__message" v-for="item in currentRecommendationMessages" :key="item.id"
						@click="handleRecommendationClick(item)">{{ item.name }}</p>
					<p v-if="currentStage > 0" @click="fetchCategoriesBack"
						class="recommendation__message recommendation__back">
						{{ $t('components.general.support.supportForm.back') }}</p>
				</div>
			</div>

			<form class="form form--disabled">
				<label class="form-field">
					<input type="text" class="form__input" :placeholder="$t('components.general.support.supportForm.enterMessage')">
					<button class="emoji form__btn" type="button">
						<img src="@/assets/img/emoji.svg" alt="Emoji" class="emoji__icon form__icon">
					</button>
					<button class="file form__btn" type="button">
						<img src="@/assets/img/file.svg" alt="File" class="file__icon form__icon">
					</button>
				</label>
				<button type="button" class="form__send">
					<img src="@/assets/img/send.svg" alt="Send" class="form__send-icon form__icon">
				</button>
			</form>
		</div>
		<button class="support-start" @click="openSupport = !openSupport"
			:class="{ 'support-start--small': resizeIcon }">
			<img src="@/assets/img/support-logo.svg" alt="Support" class="support-start__img">
			<span class="support-start__text">{{ $t('components.general.support.supportForm.online') }}</span>
		</button>
	</div>
</template>

<script>
import AdminMessage from "@/components/general/support/AdminMessage.vue";
import UserMessage from "@/components/general/support/UserMessage.vue";
import { config } from "@/lib/config";
export default {
	name: "SupportForm",
	components: { UserMessage, AdminMessage },
	data() {
		return {
			openSupport: false,
			categories: [],
			faqs: [],
			viewingFAQs: false,
			currentCategoryId: null,
			messages: [],
			currentRecommendationMessages: [],
			parentId: null,
			currentStage: -1,
			contactSupportId: this.$t('components.general.support.supportForm.contactSupport'),
			timer: null,
			resizeIcon: false,
		}
	},
	computed: {
		chatBotLoaded() {
			return this.$store.getters.chatBotLoaded
		},
		currentLang(){
			return this.$i18n.locale;
		}
	},
	watch:{
		currentLang(){
			this.fetchCategories(this.parentId)
		}
	},
	methods: {
		fetchCategories(parentId = null) {
			if (this.contactSupportId === parentId) {
				this.$router.push({ name: 'help' });
			}
			let request = new XMLHttpRequest();
			let url = `${config.apiPrefix}chatbot/categories${parentId ? "?parentId=" + parentId + "&lang="+this.currentLang : "?lang="+this.currentLang}`;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			request.setRequestHeader("Content-Type", "application/json");
			try {
				request.send();
				let response = JSON.parse(request.response);
				if (response.data.length === 0 && parentId) {
					this.fetchFAQs(parentId);
					this.viewingFAQs = true;
				}
				else {
					this.categories = response.data;
					this.currentRecommendationMessages = this.categories.map(category => ({
						id: category.category_id,
						name: category.name,
					}));
					this.viewingFAQs = false;
					this.parentId = parentId;
					this.currentStage++;
					if (this.contactSupportId === this.$t('components.general.support.supportForm.contactSupport')) {
						for (let item of this.categories) {
							if (item.name === this.$t('components.general.support.supportForm.contactSupport')) {
								this.contactSupportId = item.category_id;
								break;
							}
						}
					}
				}
			} catch (e) {
				console.log(e);
			}
		},
		fetchFAQs(categoryId) {
			let request = new XMLHttpRequest();

			let url = config.apiPrefix + "chatbot/faqs?categoryId=" + categoryId+"&lang" + this.currentLang;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.faqs = response.data;
				this.currentRecommendationMessages = this.faqs.map(faq => ({
					id: faq.faq_id,
					name: faq.question,
					answer: faq.answer,
				}));
				this.parentId = categoryId
				this.currentStage++;
			} catch (e) {
				console.log(e);
			}
		},
		handleCategorySelection(categoryId) {
			this.currentCategoryId = categoryId;
			this.fetchFAQs(categoryId);
		},
		getCurrentTime() {
			const now = new Date();
			const hours = now.getHours().toString().padStart(2, '0');
			const minutes = now.getMinutes().toString().padStart(2, '0');
			return `${hours}:${minutes}`;
		},
		addUserMessage(text) {
			this.messages.push({
				type: 'user',
				message: text,
				time: this.getCurrentTime()
			});
		},
		addAdminMessage(text) {
			this.messages.push({
				type: 'admin',
				message: text,
				time: this.getCurrentTime()
			});
		},
		handleRecommendationClick(item) {
			if (this.viewingFAQs === true) {
				this.messages.push({
					type: 'user',
					message: item.name,
					time: this.getCurrentTime()
				});
				this.messages.push({
					type: 'admin',
					message: item.answer,
					time: this.getCurrentTime()
				})
			}
			else {
				this.fetchCategories(item.id);
				this.messages.push({
					type: 'user',
					message: item.name,
					time: this.getCurrentTime()
				});
			}
		},
		fetchCategoriesBack() {
			let request = new XMLHttpRequest();
			let url = `${config.apiPrefix}chatbot/back${this.parentId ? "?childId=" + this.parentId+ "&lang="+this.currentLang : "?lang="+this.currentLang}`;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			request.setRequestHeader("Content-Type", "application/json");
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.categories = response.data;

				this.parentId = this.categories[0].parentId;
				if (!this.parentId) {
					this.parentId = null
				}
				this.currentStage--;
				this.currentRecommendationMessages = this.categories.map(category => ({
					id: category.category_id,
					name: category.name,
				}));
				this.viewingFAQs = false;

			} catch (e) {
				console.log(e);
			}
		}
	},
	created() {
		this.fetchCategories();
		this.addAdminMessage(this.$t('components.general.support.supportForm.welcomeMessage'));
		if (this.chatBotLoaded) {
			this.resizeIcon = true;
		}
	},
	mounted() {
		if (!this.chatBotLoaded) {
			this.timer = setTimeout(() => {
				this.resizeIcon = true;
				this.$store.commit('setChatBotLoaded', true);
			}, 5000);
		}
	}

}

</script>


<style scoped>
.support {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 10000;
	font-family: "Roboto", sans-serif;
}

.chat {
	background: #f3f4f6;
	height: 70vh;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	border: 1px solid #e2e2ea;
	width: 449.5px;
	position: absolute;
	bottom: 0;
	pointer-events: none;
	max-height: 69px;
	overflow: hidden;
	transition: all 1s ease;
	opacity: 0;
	z-index: 2;
	right: 0;
}

.chat--open {
	max-height: 70vh;
	overflow: visible;
	pointer-events: all;
	opacity: 1;
}

.close {
	position: absolute;
	width: 28px;
	height: 28px;
	right: -14px;
	top: -14px;
	background: url("~@/assets/img/chat-close.svg");
	background-size: contain;
	border-radius: 100%;
}

.chat-caption {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 10px 10px 0px 0px;
	padding: 24px 24px 19px 24px;
	justify-content: space-between;
}

.chat__title {
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	color: #6c6c6c;
}

.chat__subtitle {
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: #000000;
}

.admin {
	position: relative;
	margin-left: auto;
	width: 44px;
	height: 44px;
	background: #ffffff;
	display: flex;
	flex-shrink: 0;
}

.admin__status {
	position: absolute;
	width: 10px;
	height: 10px;
	right: -2px;
	top: -2px;
	border-radius: 100%;
	background: #04d900;
	border: 1px solid #ffffff;
}

.admin__face {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
}

.messages {
	padding: 24px;
	max-width: 100%;
	width: 100%;
	height: 100%;
	overflow: auto;
	scrollbar-width: thin;
	gap: 12px;
	display: flex;
	flex-direction: column;
}

.time {
	margin: 0 auto;
	display: inline;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	text-align: left;
	color: #ffffff;
	padding: 6px 17px 7px 17px;
	background: rgba(0, 158, 209, 0.4000000059604645);
	border-radius: 20px;
}

.form {
	padding: 17px 24px;
	background: #ffffff;
	border-radius: 0 0 10px 10px;
	box-shadow: 4px 0 4px rgba(2, 60, 97, 0.05);
	display: flex;
	align-items: center;
	gap: 16px;
	max-width: 100%;
	width: 100%;
}

.form-field {
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 100%;
	background: #ffffff;
	border: 1px solid #c9c9c9;
	border-radius: 10px;
	padding: 15px 24px;
	gap: 6px;
}

.form__input {
	max-width: 100%;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #000;
}

.form__input::placeholder {
	color: #c8c8c8;
}

.form__btn {
	width: 24px;
	height: 24px;
	display: flex;
	flex-shrink: 0;
}

.form__btn img {
	width: 100%;
	height: 100%;
}

.form__send {
	width: 36px;
	height: 40px;
}

.form--disabled {
	pointer-events: none;
}

.form--disabled .form__input,
.form--disabled .form__btn,
.form--disabled .form__send {
	cursor: not-allowed;
}

.form--disabled .form__input,
.form--disabled .form__btn,
.form--disabled .form__send {
	opacity: 0.5;
}

.support-start {
	background: #009ed1;
	border: 2px solid #009ed1;
	border-radius: 20px;
	padding: 14px 15px;
	display: flex;
	align-items: center;
	transition: all 1s ease;
	width: 449.5px;
	overflow: hidden;
	position: relative;
}

.support-start__text {
	margin-left: 25px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: all 1s ease;
	position: absolute;
	right: 15px;
	width: 345px;
}

.support-start--small {
	padding: 8px;
	width: 56px;
	border-radius: 30px;
}

.support-start--small .support-start__img {
	width: 36px;
	height: 36px;
}

.support-start--small .support-start__text {
	opacity: 0;
	transform: translateX(100%);
}

.recommendation {
	margin-top: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.recommendation__message {
	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;
	color: #ffffff;
	padding: 7px 11px;
	background: #009ed1;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(2, 60, 97, 0.05);
	border: 1px solid #009ed1;
	cursor: pointer;

}

.recommendation__back {
	background: #E6F6FC;
	color: #009ED1;
	border: 1px solid #009ED1
}

@media (max-width: 850px) {
	.support {
		position: fixed;
		bottom: 40px;
		right: 10px;
		z-index: 10000;
		font-family: "Roboto", sans-serif;
		padding: 8px;
		width: 56px;
		border-radius: 30px;
	}

	.support-start {
		right: 10px;
		width: 56px;
	}

	.support .support-start__img {
		width: 36px;
		height: 36px;
	}

	.support .support-start__text {
		opacity: 0;
		transform: translateX(100%);
	}

	.chat {
		height: 70vh;
		border: 1px solid #e2e2ea;
		width: calc(100vw - 20px);
	}

	.close {
		width: 24px;
		height: 24px;
		right: -12px;
		top: -12px;
	}

	.chat-caption {
		padding: 16px;
	}

	.chat__title {
		font-size: 12px;
		line-height: 1.2;
	}

	.chat__subtitle {
		font-size: 18px;
		line-height: 1.2;
	}

	.admin {
		width: 30px;
		height: 30px;
	}

	.messages {
		padding: 16px;
		gap: 8px;
	}

	.time {
		font-size: 12px;
		line-height: 1.2;
		padding: 4px 12px;
		border-radius: 16px;
	}

	.form {
		padding: 16px;
		gap: 8px;
	}

	.form-field {
		border-radius: 10px;
		padding: 12px 16px;
		gap: 6px;
	}

	.form__input {
		max-width: 100%;
		width: 100%;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.3;
		letter-spacing: 0.1px;
		color: #000;
	}

	.form__btn {
		width: 18px;
		height: 18px;
	}

	.form__send {
		width: 26px;
		height: 32px;
	}

	.form__send img {
		width: 100%;
		height: 100%;
	}

	.recommendation__message {
		font-size: 13px;
	}

	.form__input {
		font-size: 13px !important;
		line-height: 1.2;
	}

	.support-start {
		border-radius: 100%;
		padding: 8px;
	}

	.support-start__img {
		width: 36px;
		height: 36px;
	}

	.support-start__text {
		display: none;
	}
}
</style>