<template>
  <div class="menu">
    <div class="menu__wrapp">
      <div class="menu__wrapp-back menu-mobile">
        <div class="menu__wrapp-back-btn"></div>
        <p class="menu__wrapp-back-text">{{ $t('components.account_worker.menuWorker.back') }}</p>
      </div>
      <nav class="menu__wrapp-nav">
        <router-link class="menu__wrapp-nav-item item-dashboard" :class="{
          'item-blue': this.$route.path === '/candidate/account/dashboard',
        }" :to="{ name: 'dashboard' }">
          <a href="" class="menu__wrapp-nav-item-link-dashboard" :class="{
            'item-white-dashboard':
              this.$route.path === '/candidate/account/dashboard',
          }">{{ $t('components.account_worker.menuWorker.dashboard') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-friends" :class="{
          'item-blue': this.$route.path === '/user/social/friends',
        }" :to="{ name: 'friends' }">
          <a href="" class="menu__wrapp-nav-item-link-friends" :class="{
            'item-white-friends':
              this.$route.path === '/user/social/friends',
          }">{{ $t('components.account_worker.menuWorker.friends') }} <span class="friends-messages"
              v-if="user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests }}+</span></a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-communities" :class="{
          'item-blue': this.$route.path === '/user/social/communities',
        }" :to="{ name: 'communities' }">
          <a href="" class="menu__wrapp-nav-item-link-communities" :class="{
            'item-white-communities':
              this.$route.path === '/user/social/communities',
          }">{{ $t('components.account_worker.menuWorker.communities') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-settings" :to="{ name: 'settings' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/settings',
        }">
          <a href="" class="menu__wrapp-nav-item-link-settings" :class="{
            'item-white-settings':
              this.$route.path === '/candidate/account/settings',
          }">{{ $t('components.account_worker.menuWorker.settings') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-documents" :to="{ name: 'documents' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/documents',
        }">
          <a href="" class="menu__wrapp-nav-item-link-documents" :class="{
            'item-white-documents':
              this.$route.path === '/candidate/account/documents',
          }">{{ $t('components.account_worker.menuWorker.documents') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-rating" :to="{ name: 'rate' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/rate',
        }">
          <a href="" class="menu__wrapp-nav-item-link-rating" :class="{
            'item-white-rating':
              this.$route.path === '/candidate/account/rate',
          }">{{ $t('components.account_worker.menuWorker.rating') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-tests" :to="{ name: 'testing' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/testing',
        }">
          <a href="" class="menu__wrapp-nav-item-link-tests" :class="{
            'item-white-tests':
              this.$route.path === '/candidate/account/testing',
          }">{{ $t('components.account_worker.menuWorker.tests') }}</a>
        </router-link>


        <router-link class="menu__wrapp-nav-item item-video" :to="{ name: 'videoInterview' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/video-interview',
        }">
          <a href="" class="menu__wrapp-nav-item-link-video" :class="{
            'item-white-video':
              this.$route.path === '/candidate/account/video-interview',
          }">{{ $t('components.account_worker.menuWorker.videoInterview') }}</a>
        </router-link>


        <router-link class="menu__wrapp-nav-item item-vacancies" :to="{ name: 'vacancies' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/vacancies',
        }">
          <a href="" class="menu__wrapp-nav-item-link-vacancies" :class="{
            'item-white-vacancies':
              this.$route.path === '/candidate/account/vacancies',
          }">{{ $t('components.account_worker.menuWorker.vacancies') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-favorites" :to="{ name: 'favorites' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/favorites',
        }">
          <a href="" class="menu__wrapp-nav-item-link-favorites" :class="{
            'item-white-favorites':
              this.$route.path === '/candidate/account/favorites',
          }">{{ $t('components.account_worker.menuWorker.favorites') }}
            <span class="friends-messages favorites--message">
              {{ totalFavorites > 25 ? '25+' : totalFavorites }}</span></a>
        </router-link>


        <router-link class="menu__wrapp-nav-item item-rates" :to="{ name: 'responses' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/responses',
        }">
          <a href="" class="menu__wrapp-nav-item-link-rates" :class="{
            'item-white-rates':
              this.$route.path === '/candidate/account/responses',
          }">{{ $t('components.account_worker.menuWorker.responses') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-recommendations" :to="{ name: 'recommendations' }" :class="{
          'item-blue':
            this.$route.path === '/candidate/account/recommendations',
        }">
          <a href="" class="menu__wrapp-nav-item-link-recommendations" :class="{
            'item-white-recommendations':
              this.$route.path === '/candidate/account/recommendations',
          }">{{ $t('components.account_worker.menuWorker.recommendations') }}</a>
        </router-link>

        <router-link class="menu__wrapp-nav-item item-faq" :to="{ name: 'faq' }" :class="{
          'item-blue': this.$route.path === '/candidate/account/faq',
        }">
          <a href="" class="menu__wrapp-nav-item-link-faq" :class="{
            'item-white-faq': this.$route.path === '/candidate/account/faq',
          }">{{ $t('components.account_worker.menuWorker.faq') }}</a>
        </router-link>

        <router-link :to="{ name: 'allChats' }" class="menu__wrapp-chat-text">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H9C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V6C11 6.53043 10.7893 7.03914 10.4142 7.41421C10.0391 7.78929 9.53043 8 9 8H7L4 11V8H2C1.46957 8 0.960859 7.78929 0.585786 7.41421C0.210714 7.03914 0 6.53043 0 6V2Z"
              fill="white" />
            <path
              d="M13.0005 4V6C13.0005 7.06087 12.5791 8.07828 11.8289 8.82843C11.0788 9.57857 10.0614 10 9.0005 10H7.8285L6.0625 11.767C6.3425 11.916 6.6615 12 7.0005 12H9.0005L12.0005 15V12H14.0005C14.5309 12 15.0396 11.7893 15.4147 11.4142C15.7898 11.0391 16.0005 10.5304 16.0005 10V6C16.0005 5.46957 15.7898 4.96086 15.4147 4.58579C15.0396 4.21071 14.5309 4 14.0005 4H13.0005Z"
              fill="white" />
          </svg>
          {{ $t('components.account_worker.menuWorker.chat') }}
          <span v-if="user.newMessages && user.newMessages > 0" class="friends-messages">{{ user.newMessages }}</span>
        </router-link>
      </nav>
      <div class="menu__wrapp-end">
        <div class="menu__wrapp-resume">
          <button class="menu__wrapp-resume-text">{{ $t('components.account_worker.menuWorker.resume') }}</button>
        </div>
        <!-- <li>
          <div class="header__wrapper-lang">
            <a @click="changeLanguage('ru')" class="header-lang__element"
              :class="$i18n.locale === 'ru' ? 'lang-chosen' : ''">Ru</a>
            <p class="header-lang__element-distinction">/</p>
            <a @click="changeLanguage('en')" class="header-lang__element"
              :class="$i18n.locale === 'en' ? 'lang-chosen' : ''">En</a>
          </div>
        </li> -->
        <div class="menu__wrapp-leave">
          <button @click="logout" class="menu__wrapp-leave-text">
            {{ $t('components.account_worker.menuWorker.signout') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
    totalFavorites() {
      return this.$store.getters.favoriteVacancy.length;
    },
  },
  name: "MenuWorker",
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    changeLanguage(lang) {
    this.$i18n.locale = lang;
    }
  },
  beforeMount() {
    this.$store.dispatch("fetchFavoriteVacancy", { token: this.$store.getters.token });
  }
};
</script>


<style scoped>
.menu {
  scrollbar-width: thin;
  padding-top: 48px;
  border-right: 1px solid #ebedf0;
  position: sticky;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0;
  left: 0;
  max-width: 290px;
  width: 100%;
  height: 100vh;
  background: #fff;
}

.menu::-webkit-scrollbar {
  width: 10px;

  height: 8px;

  background-color: #f6f8fa;
  border-radius: 0px 8px 8px 0;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border-radius: 10px;
}

@media (max-height: 1100px) {
  .menu:hover {
    overflow: auto;
  }
}

.menu-mobile {
  display: none;
}

.menu__wrapp-back {
  direction: flex;
  align-items: center;
}

.menu__wrapp-back-btn {
  display: block;
  width: 31px;
  height: 10px;
  background: url("~@/assets/img/account-img/menu/back.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.menu__wrapp-back-text {
  font-family: "Roboto";
  margin-left: auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 35.5px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.menu__wrapp {
  margin: 0px auto 0 auto;
  width: 214px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

.menu__wrapp-logo {
  width: 100%;
  padding: 0 0px 21px 61px;
  margin: 18px 0 0px 0;
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px #e2e2ea;
}

.menu__wrapp-logo-img {
  height: 100%;
}

.menu__wrapp-nav {
  margin: 41px 0 30px 0;
  width: 214px;
  align-items: center;
}

.menu__wrapp-nav :not(:last-child) {
  margin-bottom: 15px;
}

.menu__wrapp-nav-item {
  color: #44444f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 45px;
  background-color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  background-repeat: no-repeat;
}

.menu__wrapp-nav-item-link-dashboard {
  color: inherit;
  padding-left: 48px;
  background-image: url("~@/assets/img/account-img/menu/dashboard.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-friends {
  display: flex;
  align-items: center;
  color: inherit;
  padding-left: 48px;
  background-image: url("~@/assets/img/account-img/menu/friends.png");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 5px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-communities {
  color: inherit;
  padding-left: 48px;
  background-image: url("~@/assets/img/account-img/menu/communities.png");
  background-repeat: no-repeat;
  background-size: 24px;

  background-position: 5px;
  background-position-y: center;
}

.item-blue {
  background: #009ed1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #fff;
}

.menu__wrapp-nav-item-link-settings {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/settings.svg");
  background-repeat: no-repeat;
  background-position: 9px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-documents {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/documents.svg");
  background-repeat: no-repeat;
  background-position: 7px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-rating {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/rating.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-tests {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tests.svg");
  background-repeat: no-repeat;
  background-position: 10px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-vacancies {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/surch.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-favorites {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/favorites.svg");
  background-repeat: no-repeat;
  background-position: 9px;
  background-position-y: center;
  display: flex;
  align-items: center;
}

.menu__wrapp-nav-item-link-video {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/video.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-recommendations {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/recomendation.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-rates {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/like.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-faq {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/question.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-nav-item-link-tarif {
  padding-left: 48px;
  color: inherit;
  background: url("~@/assets/img/account-img/menu/tarif.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
}

.menu__wrapp-chat-text {
  display: flex;
  align-items: center;
  padding: 15px 23px 15px 23px;
  background-position: 78px;
  background-repeat: no-repeat;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 0.02em;
  color: #ffffff;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: #009ed1;
  border-radius: 8px;
  box-shadow: 0px 3px 5px #009ed1;
  transition: opacity 1s ease;
  align-items: center;
  position: relative;
  overflow: visible !important;
}

.menu__wrapp-chat-text svg {
  margin-right: 7px;
  margin-bottom: 0 !important;
}

.menu__wrapp-chat-text:hover {
  opacity: 0.5;
}

.menu__wrapp-resume {
  display: none;
  margin-top: 14px;
  width: 100%;
  letter-spacing: 0.02em;
  text-align: center;
  background: #e6f6fc;
  border: 1px solid #009ed1;
  border-radius: 8px;
  transition: opacity 1s ease;
}

.menu__wrapp-resume-text {
  padding: 15px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #009ed1;
}

.menu__wrapp-resume:hover {
  opacity: 0.5;
}

.menu__wrapp-leave {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  transition: opacity 1s ease;
}

.menu__wrapp-leave:hover {
  opacity: 0.5;
}

.menu__nav-leave:hover {
  opacity: 0.5;
}

.menu__wrapp-leave-text {
  background: url("~@/assets/img/account-img/menu/exit.svg");
  background-repeat: no-repeat;
  background-position: 11px;
  background-position-y: center;
  padding-left: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  color: inherit;
  letter-spacing: -0.02em;
}

.menu__wrapp-end {
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
}

.friends-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}

.favorites--message {
  background: #FAFAFB;
  color: #009ED1;
}

@media (max-height: 920px) {
  .menu__wrapp-nav :not(:last-child) {
    margin-bottom: 10px;
  }

  .menu__wrapp-nav-item {
    height: 35px;
  }

  .menu__wrapp-chat-text {
    padding: 10px 0 10px 0px;
    background-position: 68px;
  }

  .menu__wrapp-resume-text {
    padding: 10px 0 10px 0px;
  }
}

@media (max-width: 1360px) {
  .menu {
    max-width: 80px;
  }

  .menu__wrapp-nav {
    margin-top: 41px;
  }

  .menu__wrapp-nav :not(:last-child) {
    margin-bottom: 10px;
  }

  .menu__wrapp-nav-item {
    height: 35px;
  }

  .menu__wrapp-logo {
    padding-left: 34px;
  }

  .menu__wrapp {
    width: 35px;
    overflow: hidden;
  }

  .menu__wrapp-nav {
    width: 100%;
  }

  .menu__wrapp-nav-item {
    width: 100%;
    overflow: hidden;
  }

  .menu__wrapp-nav-item-link-dashboard,
  .menu__wrapp-nav-item-link-settings,
  .menu__wrapp-nav-item-link-rating,
  .menu__wrapp-nav-item-link-vacancies,
  .menu__wrapp-nav-item-link-recommendations,
  .menu__wrapp-nav-item-link-rates,
  .menu__wrapp-nav-item-link-faq {
    background-position: 9px;
  }

  .menu__wrapp-nav-item-link-documents {
    background-position: 6px;
  }

  .menu__wrapp-nav-item-link-rating {
    background-position: 10px;
  }

  .menu__wrapp-nav-item-link-settings {
    background-position: 8px;
  }

  .menu__wrapp-chat-text {
    background-image: url("~@/assets/img/account-img/menu/chat.png");
    background-repeat: no-repeat;
    background-position: 10px;
    padding: 10px 0px 10px 37px;
    width: 100%;
    box-sizing: border-box;
  }

  .menu__wrapp-resume-text {
    background: url("~@/assets/img/account-img/menu/summary.svg");
    background-position: 10px;
    background-repeat: no-repeat;
    background-position-y: center;
    overflow: hidden;
    padding: 3px 0px 3px 48px;
    color: #fff;
  }

  .menu__wrapp-leave {
    margin-top: 2px;
  }

  .menu__wrapp-leave-text {
    background: url("~@/assets/img/account-img/menu/exit.svg");
    background-repeat: no-repeat;
    background-position: 2px;
    color: #fff;
  }

  .menu__wrapp-end {
    bottom: 0px;
    width: 35px;
  }

  .menu__wrapp-chat-text {
    font-size: 0;
  }

  .menu__wrapp-chat-text svg {
    margin-left: 15px;
  }

  .menu__wrapp-nav-item {
    overflow: visible;
    font-size: 0;
    position: relative;
  }

  .friends-messages {
    position: absolute;
    bottom: -3px;
    right: -3px;
    margin-left: 0;
    padding: 4px 4px;
    font-size: 10px;
  }
}

@media (max-width: 950px) {
  .menu {
    display: none;
    margin-top: 48px;
    max-width: 100%;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 2;
  }

  .menu__wrapp {
    width: 100%;
    max-width: 400px;
  }

  .menu-mobile {
    display: flex;
  }

  .menu__wrapp-logo {
    display: none;
  }

  .menu__wrapp-nav {
    margin-top: 17px;
  }

  .menu__wrapp-back {
    margin-top: 27px;
    display: flex;
    width: 100%;
  }

  .menu__wrapp-chat-text {
    padding: 16px 0 16px 0;
    background: none;
  }

  .menu__wrapp-resume-text {
    background: none;
    padding: 16px 0px 16px 0px;
    color: #009ed1;
  }

  .menu__wrapp-leave {
    width: 100%;
    margin-top: 14px;
    background: rgba(209, 0, 62, 0.05000000074505806);
    border-radius: 8px;
    text-align: center;
    transition: opacity 1s ease;
  }

  .menu__wrapp-leave:hover {
    opacity: 0.5;
  }

  .menu__wrapp-leave-text {
    background: none;
    padding: 16px 0px 16px 0px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #d1003f;
    opacity: 0.6;
  }
}

/* Modificators */
.item-white-dashboard {
  background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
  background-repeat: no-repeat;
}

.item-white-friends {
  background-image: url("~@/assets/img/account-img/menu/friends-white.png");
  background-repeat: no-repeat;
}

.item-white-communities {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
  background-repeat: no-repeat;
}

.item-white-settings {
  background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
  background-repeat: no-repeat;
}

.item-white-documents {
  background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
  background-repeat: no-repeat;
}

.item-white-rating {
  background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
  background-repeat: no-repeat;
}

.item-white-tests {
  background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
  background-repeat: no-repeat;
}

.item-white-vacancies {
  background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
  background-repeat: no-repeat;
}

.item-white-favorites {
  background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
  background-repeat: no-repeat;
}

.item-white-video {
  background-image: url("~@/assets/img/account-img/menu/video-white.svg");
  background-repeat: no-repeat;
}

.item-white-recommendations {
  background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
  background-repeat: no-repeat;
}

.item-white-rates {
  background-image: url("~@/assets/img/account-img/menu/like-white.svg");
  background-repeat: no-repeat;
}

.item-white-faq {
  background-image: url("~@/assets/img/account-img/menu/question-white.svg");
  background-repeat: no-repeat;
}

.item-white-tarif {
  background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
  background-repeat: no-repeat;
}

/*  */

.item-dashboard:hover a {
  background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
}

.item-dashboard:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-friends:hover a {
  background-image: url("~@/assets/img/account-img/menu/friends-white.png");
}

.item-friends:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-communities:hover a {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
}

.item-communities:hover {
  background-color: #009ed1;
  color: #fff;
}


.item-settings:hover a {
  background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
}

.item-settings:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-documents:hover a {
  background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
}

.item-documents:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-rating:hover a {
  background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
}

.item-rating:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-tests:hover a {
  background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
}

.item-tests:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-vacancies:hover a {
  background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
}

.item-vacancies:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-favorites:hover a {
  background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
}

.item-favorites:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-video:hover a {
  background-image: url("~@/assets/img/account-img/menu/video-white.svg");
}

.item-video:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-recommendations:hover a {
  background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
}

.item-recommendations:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-rates:hover a {
  background-image: url("~@/assets/img/account-img/menu/like-white.svg");
}

.item-rates:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-faq:hover a {
  background-image: url("~@/assets/img/account-img/menu/question-white.svg");
}

.item-faq:hover {
  background-color: #009ed1;
  color: #fff;
}

.item-tarif:hover a {
  background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
}

.item-tarif:hover {
  background-color: #009ed1;
  color: #fff;
}
.header__wrapper-lang {
	display: flex;
	gap: 3px;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #7b7f87;
	line-height: 40px;
	font-family: "Roboto";
	font-weight: 400;
  margin: 0 auto;
  width: 100px;
}

.header-lang__element {
	transition: opacity 1s ease;
	color: inherit;
}

.header-lang__element:hover {
	opacity: 0.5;
	cursor: pointer;
}

.lang-chosen {

	color: #009ed1;
}
/* /Modificators */
</style>
