<template>
	<div :key="this.$route.path">
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>

				<div class="container-account">
					<div class="test">
						<div class="test__caption">
							<div class="test__caption-wrapper">
								<p class="test__caption-wrapper-text test-mobile">
									{{
										$t('pages.account.account_company.accountCompanyInterviewsListPage.personalCabinet')
									}}
								</p>
								<h1 class="test__caption-wrapper-title">
									{{ $t('pages.account.account_company.accountCompanyInterviewsListPage.title') }}
								</h1>
								<p class="test__caption-wrapper-text desctop">
									{{ $t('pages.account.account_company.accountCompanyInterviewsListPage.welcome') }}
								</p>
							</div>
							<router-link class="test__caption-create"
								:to="{ name: 'newInterview', params: { interviewId: 'new' } }">
								{{ $t('pages.account.account_company.accountCompanyInterviewsListPage.createInterview')
								}}
							</router-link>
						</div>
						<flash-message></flash-message>
						<div class="test__group">
							<SeparateInterview v-for="item in dataInterview" :key="item.id" :interview="item">
							</SeparateInterview>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import SeparateInterview from "../../../components/tests/company_interviews_list_element/item/SeparateInterview.vue";
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import { config } from "@/lib/config";

export default {
	components: { MenuCompany, HeaderAccountCompany, SeparateInterview },
	data() {
		return {
			dataInterview: null,
		};
	},
	methods: {
		fetchInterviewEmployer() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "interview/all";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataInterview = response.data;
			} catch (e) {
				console.log(e);
			}
		},
	},
	name: "AccountCompanyInterviewsListPage",
	beforeMount() {
		require("vue-flash-message/dist/vue-flash-message.min.css");
		this.fetchInterviewEmployer();
		scroll(0, 0);
	},
};
</script>


<style scoped>
.account {
	display: flex;
}

.test__caption {
	display: flex;
	align-items: center;
}

.test__caption-wrapper-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.test__caption-wrapper-text {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.test__caption-create {
	margin-right: 27px;
	padding: 15px 56px;
	background: #009ed1;
	border-radius: 7px;
	margin-left: auto;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.test__caption-create:hover {
	opacity: 0.5;
}

.test__group {
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 48px;
}

.test-mobile {
	display: none;
}

@media (max-width: 1729px) {
	.test__group {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 1369px) {
	.test__group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 767px) {
	.test-mobile {
		display: block;
	}

	.test-desctop {
		display: none;
	}

	.test__caption-wrapper {
		text-align: center;
		margin: 0 auto;
	}

	.test__caption-wrapper-text {
		color: #009ed1;
	}

	.test__caption-create {
		display: none;
	}

	.test__group {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 400px) {
	.test__caption-wrapper-title {
		font-size: 20px;
	}

	.test__caption-wrapper-text {
		font-size: 13px;
	}

	.test__group {
		margin-top: 30px;
		row-gap: 12px;
	}
}

@media (max-width: 340px) {
	.test__caption-wrapper {
		text-align: left;
		margin: 0;
	}
}
</style>
