<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>

				<div class="container-account">
					<p class="tested__text mobile">{{ $t('pages.account.account_company.accountCompanyTestedWorkersPage.personalCabinet') }}</p>
					<h1 class="tested__title">{{ $t('pages.account.account_company.accountCompanyTestedWorkersPage.testing') }}</h1>
					<p class="tested__text desctop">{{ $t('pages.account.account_company.accountCompanyTestedWorkersPage.checkingCandidates') }}</p>
					<div class="tested__nomination">
						<button class="tested__nomination-caption-back" @click="$router.go(-1)"></button>
						<!--              <p class="tested__nomination-caption-name">Test name</p>-->
						<!--            <p class="tested__nomination-description">-->
						<!--              {{-->
						<!--                interviewTitle-->
						<!--              }}-->
						<!--            </p>-->
					</div>
					<div class="tested__stage">
						<div class="tested__stage-notverified">
							<button class="tested__stage-verified-btn" :class="{ 'active-blue': this.status === 'unchecked' }" @click="setStatus('unchecked')">
								{{ $t('pages.account.account_company.accountCompanyTestedWorkersPage.waitingForChecks') }}
							</button>
						</div>
						<div class="tested__stage-verified">
							<button class="tested__stage-verified-btn" :class="{ 'active-blue': this.status === 'checked' }" @click="setStatus('checked')">
								{{ $t('pages.account.account_company.accountCompanyTestedWorkersPage.verified') }}
							</button>
						</div>
					</div>
					<flash-message></flash-message>
					<div class="tested__wrapper">
						<TestedWorker v-for="item in dataPassing" :key="item.id" :dataPassing="item" :status="status"></TestedWorker>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import TestedWorker from "../../../components/account/account_company/account_company_tested_workers/item/TestedWorker.vue";
import { config } from "@/lib/config";

export default {
	components: { MenuCompany, HeaderAccountCompany, TestedWorker },
	props: {
		interviewId: {},
	},
	data() {
		return {
			dataPassing: null,
			status: null,
		};
	},
	methods: {
		setStatus(data) {
			if (this.status === data) this.status = "default";
			else this.status = data;
		},
		fetchDataPassing() {
			let xmlHttp = new XMLHttpRequest();
			let url =
				config.apiPrefix + "interview/passings/" +
				this.interviewId;
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataPassing = response.data;
			} catch (e) {
				console.log(e);
			}
		},
	},
	name: "AccountCompanyTestedWorkersPage",
	beforeMount() {
		this.status = "default";
		this.fetchDataPassing();
	},
	mounted() {
		scroll(0, 0);
	},
};
</script>



<style scoped>
.active-blue {
	background: #e6f6fc;
	border: 1px solid #e6f6fc;
	color: #009ed1;
}

.account {
	display: flex;
}

.mobile {
	display: none;
}

.tested__text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.tested__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.tested__nomination {
	margin-top: 20px;
}

.tested__nomination-caption-back {
	padding: 20px;
	display: flex;
	align-items: center;
	background-image: url("~@/assets/img/company-elements/back.svg");
	background-size: 17px;
	background-repeat: no-repeat;
	width: 17px;
	height: 10px;
}

.tested__nomination-caption-name {
	margin-left: 13px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.tested__nomination-description {
	padding-top: 10px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 1.38;
	color: #3b4c68;
}

.tested__stage {
	display: flex;
	align-items: center;
}

.tested__stage-notverified {
	display: flex;
	text-align: center;
	transition: opacity 1s ease;
}

.tested__stage-notverified:hover {
	opacity: 0.5;
}

.tested__stage-notverified-btn {
	background: #e6f6fc;
	border-radius: 7px;
	padding: 17px 33px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.tested__stage-verified {
	display: flex;
	text-align: center;
	margin-left: 14px;
	transition: opacity 1s ease;
}

.tested__stage-verified:hover {
	opacity: 0.5;
}

.tested__stage-verified-btn {
	border: 1px solid #009ed1;
	border-radius: 7px;
	padding: 17px 24px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.tested__wrapper {
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 38px;
}

@media (max-width: 1720px) {
	.tested__wrapper {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 1185px) {
	.tested__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.tested {
		margin: 0 auto;
	}

	.tested__title {
		text-align: center;
	}

	.tested__text {
		text-align: center;
	}

	.tested__nomination-caption {
		justify-content: center;
	}

	.tested__nomination-description {
		text-align: center;
	}

	.tested__stage {
		justify-content: center;
	}
}

@media (max-width: 760px) {
	.tested__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 400px) {
	.desctop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.tested__text {
		color: #009ed1;
		font-size: 13px;
		text-align: left;
	}

	.tested__title {
		text-align: left;
		color: #000000;
		font-size: 20px;
	}

	.tested__nomination-caption {
		margin-top: 13px;
		justify-content: unset;
	}

	.tested__nomination-caption-name {
		font-size: 12px;
	}

	.tested__nomination-description {
		text-align: left;
		font-size: 13px;
	}

	.tested__stage {
		justify-content: unset;
		margin-top: 20px;
	}

	.tested__stage-notverified-btn {
		font-size: 11px;
		padding: 13px 20px;
	}

	.tested__stage-verified-btn {
		padding: 13px 20px;
		font-size: 11px;
	}
}
</style>
