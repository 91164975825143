<template>
  <div>
    <BigPhoto @closeBigPhoto="bigPhoto = false" v-if="bigPhoto" :url="bigPhotoUrl">
    </BigPhoto>

    <SocialHeader :role="$store.getters.role"></SocialHeader>

    <main class="main">
      <div class="account">
        <SocialMenu :role="$store.getters.role"></SocialMenu>
        <div class="all">
          <div class="caption" :style="{ backgroundImage: 'url(' + dataCommunities.previewUrl + ')' }">
            <button @click="$router.go(-1)" class="back"></button>
          </div>
          <div class="wrapper">
            <div class="filling">
              <SeparateCommunityMainChange :community="{
                id: dataCommunities.id,
                avatar: dataCommunities.avatarUrl,
                communityType: dataCommunities.communityType,
                userCount: dataCommunities.userCount,
                name: dataCommunities.name
              }">
              </SeparateCommunityMainChange>
              <div class="info community-block">
                <h3 class="info__subtitle">{{ $t('pages.social.socialCommunitiesMyChanges.information') }}</h3>
                <p class="info__description record-info">{{ dataCommunities.description }}</p>
              </div>
              <div class="post community-block">
                <form class="post-filling">
                  <div class="post-filling__caption">
                    <img :src="dataCommunities.avatarUrl" alt="" class="record-caption__logo">
                    <div class="community__info mobile-block">
                      <h3 class="community__info-name">{{ dataCommunities.name }}</h3>
                      <p class="community__info-designation">{{ dataCommunities.userCount }} {{ $t('pages.social.socialCommunitiesMyChanges.participants') }}</p>
                    </div>
                  </div>
                  <textarea rows="4" v-model="newPostBody" class="post__textarea" :placeholder="$t('pages.social.socialCommunitiesMyChanges.createPostPlaceholder')"></textarea>
                  <button type="button" @click="$refs.postFile.click()" v-if="newPost.files.length !== 3" class="post__add-file desctop-block"></button>
                </form>
                <separate-content @viewBigPhoto="viewBigPhoto" v-if="newPost.files && newPost.files.length !== 0"
                                  :url="newPost.files" :files="postFiles"></separate-content>
                <div class="post__actions">
                  <button @click="$refs.postFile.click()" v-if="newPost.files.length !== 3" class="post__add-file mobile-block"></button>
                  <button @click="createNewPost" class="post-actions__create">{{ $t('pages.social.socialCommunitiesMyChanges.createPostButton') }}</button>
                  <input type="file" hidden ref="postFile" @change="addPostFile">
                </div>
              </div>
              <SeparatePost :admin="true" @viewBigPhoto="viewBigPhoto" v-for="(item, index) in dataCommunities.posts"
                            :key="item.id" @sharePostCommunity="sharePostCommunity" :community-post="true" :post="item"
                            :index="index" :avatar-url="dataCommunities.avatarUrl" :name="dataCommunities.name"
                            :community-id="communitiesId" @setCurrentPost="val => currentPost = val">
              </SeparatePost>
            </div>
            <div class="interaction">
              <div class="community interaction-element desctop-block">
                <div class="community__caption">
                  <button @click="goToChat" class="community__wright"><span class="community__wright-img"></span>{{ $t('pages.social.socialCommunitiesMyChanges.writeToChat') }}</button>
                </div>
                <div class="community__filling">
                  <router-link v-if="dataCommunities.communityType === 'close'"
                               :to="{ name: 'communitiesIncomingRequest', params: { communitiesId: dataCommunities.id } }"
                               class="community__filling-btn">{{ $t('pages.social.socialCommunitiesMyChanges.applications') }}<span class="friends-messages">99+</span>
                  </router-link>
                </div>
              </div>
              <div class="participants interaction-element desctop-block">
                <div class="participants-caption">
                  <h3 class="participants__title">{{ $t('pages.social.socialCommunitiesMyChanges.participants') }}</h3>
                  <p class="participants-number">{{ dataCommunities.userCount }}</p>
                </div>
                <div class="participants-group">
                  <SeparatePersone v-for="item in dataCommunities.users" :key="item.id" :user="item">
                  </SeparatePersone>
                </div>
              </div>
              <div class="blog interaction-element" v-if="false">
                <div class="participants-caption blog-caption">
                  <h3 class="participants__title">{{ $t('pages.social.socialCommunitiesMyChanges.blog') }}</h3>
                  <p class="participants-number">999</p>
                </div>
                <div class="blog-list">
                  <SeparateBlog></SeparateBlog>
                  <SeparateBlog></SeparateBlog>
                  <SeparateBlog></SeparateBlog>
                </div>
                <button class="possible__show">{{ $t('pages.social.socialCommunitiesMyChanges.viewAll') }}<span class="possible__show-icon"></span></button>
              </div>
            </div>
          </div>
        </div>
        <BigPost @viewBigPhoto="viewBigPhoto" @closeWindow="currentPost = null" :cant-share="false" :post="currentPost"
                 ref="bigPost" :metaData="{
                   title: dataCommunities.name,
                   avatarUrl: dataCommunities.avatarUrl,
                   communityId: dataCommunities.id
                 }" v-if="currentPost && !bigPhoto">
        </BigPost>
      </div>
    </main>
  </div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparateCommunityMainChange from "../../components/social/account_communitis_main/SeparateCommunityMainChange.vue";
import SeparatePersone from "../../components/social/account_communitis_main/SeparatePersone.vue";
import SeparateBlog from "../../components/social/account_communitis_main/SeparateBlog.vue";
import SeparatePost from "@/components/social/account_communitis_main/SeparatePost";
import SeparateContent from "@/components/social/account_communitis_main/SeparateContent";
import BigPhoto from "@/components/social/account_communitis_main/BigPhoto";
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";
import Cookies from "js-cookie";
import BigPost from "@/components/account/account_worker/modals/item/BigPost.vue";

export default {
  computed: {
    dataCommunities() {
      return this.$store.getters.getDataCommunities;
    },
    newPost() {
      return this.$store.getters.getNewPost;
    },
    postFiles() {
      return this.$store.getters.getFilesNewPost;
    }
  },
  components: {
    BigPost,
    SeparateContent,
    SeparatePost,
    SocialMenu, SocialHeader, SeparateCommunityMainChange, SeparatePersone, SeparateBlog, BigPhoto
  },
  name: "SocialCommunitiesMyChanges",
  props: ['communitiesId', 'postId'],
  data() {
    return {
      statusVizable: false,
      menuVisible: false,
      newPostBody: null,
      bigPhoto: false,
      bigPhotoUrl: null,
      currentPost: null,
      redirect: false
    };
  },
  beforeMount() {
    scroll(0, 0);
    this.loadPosts();
    if (this.dataCommunities.owner !== 'myself') {
      if (this.dataCommunities.inCommunity === 'in') {
        this.$store.commit('setDataCommunities', null);
        this.redirect = true;
        this.$router.push({
          name: 'communitiesPage', params: {
            communitiesId: this.communitiesId,
            postId: this.postId ? this.postId : null
          }
        });
      } else {
        this.$router.push({ name: 'communities' }).then(() => {
          errorHandler({ name: 'noAccess' });
        });
      }
      return;
    }

    if (this.postId) {
      (async function () {
        await fetch(`${config.apiPrefix}community/post/${this.postId}/get`, {
          method: "GET",
          headers: {
            'X-Auth-Token': this.$store.getters.token
          }
        }).then(async (res) => {
          const { data } = await res.json();
          this.currentPost = data;
        }).catch(() => {
          errorHandler({ page: 'openPost', status: 500 });
        });
      })().bind(this)();
    }
  },
  beforeCreate() {
    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.addPosts();
      }
    };
  },
  methods: {
    viewBigPhoto(item) {
      this.bigPhoto = true;
      this.bigPhotoUrl = item;
    },
    goToChat() {
      this.$router.push({ name: 'communityChat', params: { communityId: this.dataCommunities.id } });
      Cookies.set('avatarCom', this.dataCommunities.avatarUrl);
      Cookies.set('nameCom', this.dataCommunities.name);
    },
    sharePostCommunity(val) {
      this.currentPost = val;
      setTimeout(() => this.$refs.bigPost?.setSharedPost(val), 100);
    },
    loadPosts() {
      this.$store.commit('resetPagePost');
      this.$store.dispatch('fetchDataCommunities', {
        data: this.$store.getters.getDataCommunities,
        communityId: this.communitiesId,
        page: this.$store.getters.getPostPage,
        limit: this.$store.getters.getPostLimit,
        token: this.$store.getters.token,
        callback: this.deletePagination
      });
    },
    addPostFile() {
      const file = this.$refs.postFile.files[this.$refs.postFile.files.length - 1];
      const url = URL.createObjectURL(file);
      this.$store.commit('addFileUrlNewPost', url);
      this.$store.commit('addFileNewPost', file);
    },
    deletePagination() {
      window.onscroll = null;
    },
    addPosts() {
      this.$store.commit('upPagePost');
      this.$store.dispatch('fetchDataCommunities', {
        data: this.$store.getters.getDataCommunities,
        communityId: this.communitiesId,
        token: this.$store.getters.token,
        page: this.$store.getters.getPostPage,
        limit: this.$store.getters.getPostLimit,
        callback: this.deletePagination
      });
    },
    createNewPost() {
      if (this.newPostBody && this.newPostBody.replace(/\s/g, "") !== "" || this.postFiles.length !== 0) {
        if (this.postFiles.length !== 0) {
          for (let i = 0; i < this.postFiles.length; i++) {
            this.$store.commit('updateNewPostFile', { url: this.updateFilePost(this.postFiles[i]), index: i });
          }
        }
        this.$store.dispatch('createNewPost', {
          body: this.newPostBody,
          id: this.communitiesId,
          token: this.$store.getters.token,
          callback: this.loadPosts
        });
        this.newPostBody = null;
      } else {
        errorHandler({ status: 400, page: 'notAllData' });
      }
    },
    updateFilePost(file) {
      let formData = new FormData();
      formData.append('file', file);
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + 'file/upload';
      request.open('POST', url, false);
      try {
        request.send(formData);
        const response = JSON.parse(request.response);
        return response.data.url;
      } catch (e) {
        console.log(e);
      }
    },
  },
  beforeDestroy() {
    if (!this.redirect)
      this.$store.commit('setDataCommunities', null);
  }
};
</script>


<style scoped>
.community-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}

.account {
  display: flex;
}

.all {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  padding: 59px 30px 0 0px;
}

.caption {
  width: 100%;
  height: 202px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  border-radius: 0 0px 10px 0;
}

.back {
  background: url("~@/assets/img/account-img/communities/back-white.svg");
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  margin: 40px 0 0 80px;
}

.wrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0 0 30px;
}

.filling {
  max-width: 100%;
  width: 100%;


}

.interaction {
  max-width: 388px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 30px;
}

.community {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.community__caption {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.community__bottom {
  padding: 20px 0 0 18px;
  border-top: 1px solid #efeff4;
}

.interaction-element {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  padding: 20px 12px;
}

.community {
  margin-top: 0;
}

.community__caption {
  padding: 0 0 20px 18px;
  border-bottom: 1px solid #efeff4;
  width: 100%;
}

.community__wright {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #009ed1;
  display: flex;
  align-items: center;
}

.community__wright-img {
  margin-right: 7px;
  background: url("~@/assets/img/account-img/communities/chat-blue.svg");
  width: 16px;
  height: 15px;
  display: block;
  background-repeat: no-repeat;
}

.community__filling {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding: 10px 0 20px 18px;
}

.community__filling-btn {
  color: #009ed1;
  margin-top: 10px;
  display: inline;
  width: 100%;
  text-align: left;
}

.participants {
  padding: 20px 22px;
}


.participants-caption {
  display: flex;
  align-items: center;
}

.participants__title {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
}

.participants-number {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: rgba(68, 68, 79, 0.5);
}

.participants-group {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

.blog {
  padding: 20px 12px;
}

.blog-caption {
  padding: 0 10px;
}

.blog-list__element {
  padding: 20px 10px;
  border-bottom: 1px solid #efeff4;
}

.blog-list__element-img {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  height: 61px;
  object-fit: cover;
}

.blog-list__element-description {
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #424242;
}

.possible__show {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #44444f;
  padding: 15px 0 0 0;
}

.possible__show-icon {
  width: 9px;
  height: 9px;
  margin-left: 4px;
  display: flex;
  background-image: url("~@/assets/img/account-img/friends/list.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: 3px;
}

.community-block {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  padding: 30px;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.info__subtitle {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.post-filling {
  display: flex;
}

.post__textarea {
  border: none;
  margin-left: 20px;
  max-width: 100%;
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(66, 66, 66, 0.6000000238418579);
  margin-right: 10px;
}

.post__textarea::placeholder {
  color: rgba(66, 66, 66, 0.6000000238418579);
}

.post__textarea:focus {
  outline: none;
}

.post__add-file {
  background: url("~@/assets/img/account-img/communities/file.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-left: auto;
  transition: opacity 1s ease;
  display: flex;
  flex-shrink: 0;
}

.post__add-file:hover {
  opacity: 0.5;
}

.post__actions {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.post-actions__settings {
  margin-left: auto;
  background: url("~@/assets/img/account-img/communities/settings-grey.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 19px;
  transition: opacity 1s ease;
}

.post-actions__settings:hover {
  opacity: 0.5;
}

.post-actions__create {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #009ed1;
  border-radius: 8px;
  box-shadow: 0px 4px 12px -6px #009ED1;
  padding: 15px 0;
  max-width: 214px;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 1s ease;
}

.post-actions__create:hover {
  opacity: 0.5;
}


.record-info {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
  margin-top: 20px;
  max-width: 70%;
  width: 100%;
	word-break: break-all;
}

.record-caption {
  display: flex;
  width: 100%;
  align-items: center;
}

.record-caption__logo {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.record-caption__description {
  margin-left: 20px;
}


.record-caption__description-time {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.4000000059604645);
  margin-top: 10px;
}

.community__settings {
  position: relative;
  display: block;
  background: url("~@/assets/img/account-img/friends/settings.svg");
  width: 40px;
  height: 40px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.community__settings-popap {
  position: absolute;
  border: 1px solid #efeff4;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  padding: 10px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  bottom: -113px;
  right: -45px;
  z-index: 20;
}

.popap-list {
  position: relative;
  text-align: left;
}

.popap-list::after {
  position: absolute;
  top: -20px;
  right: 47px;
  display: block;
  width: 14px;
  height: 10px;
  background: url("~@/assets/img/account-img/friends/arrow.svg");
  content: "";
  background-repeat: no-repeat;
}

.popap-list__button {
  text-align: left;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding-left: 10px;
  width: 100%;
  cursor: pointer;
}

.popap-list__button:not(:first-child) {
  margin-top: 6px;
}

.popap-list__button:last-child {
  padding-bottom: 10px;
}

.community__settings-delete {
  padding: 10px 10px 0px 10px;
  border-top: 1px solid #efeff4;
  font-family: "Roboto";
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #e7665e;
  cursor: pointer;
}

.record-photos {
  margin-top: 40px;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 143px);
  gap: 10px;
}

.record-photos__elemnt {
  border: 1px solid #efeff4;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.record-photos__elemnt:nth-child(3n+1) {
  grid-column: 1 span;
  grid-row: 2 span;
}

.record-photos__elemnt:nth-child(3n+2) {
  grid-column: 2 span;
  grid-row: 4 span;
}

.record-photos__elemnt:nth-child(3n+3) {
  grid-column: 1 span;
  grid-row: 2 span;
}

.record-photos__big {
  max-width: 100%;
  width: 100%;
}

.record-photos__big-element {
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.record-activity {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.record-activity__like-img {
  background: url("~@/assets/img/account-img/communities/like.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 18px;
  display: block;
}

.record-activity__element {
  display: flex;
  align-items: center;

}

.record-activity__element:not(:last-child) {
  margin-right: 40px;
}

.record-activity__text {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.record-activity__comments-img {
  background: url("~@/assets/img/account-img/communities/comment.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: block;
}

.record-activity__share-img {
  background: url("~@/assets/img/account-img/communities/share.svg");
  background-repeat: no-repeat;
  width: 19px;
  height: 18px;
  display: block;
}


.mt10 {
  margin-top: 10px;
}

.mr20 {
  margin-right: 20px;
}

.block {
  display: block;
}


.record-answer {
  display: flex;
  margin-top: 40px;
}

.answer-form {
  margin-left: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.answer-textarea-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;

}

.answer-textarea {
  width: 100%;
  border: 1px solid #efeff4;
  border-radius: 10px;
  padding: 24px 60px 26px 20px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #434343;
  overflow: auto;
  height: 64px;
  scrollbar-width: auto;
}


.answer-textarea::placeholder {
  color: rgba(66, 66, 66, 0.5);
}

.answer-textarea-file {
  position: absolute;
  right: 20px;
  top: 21px;
  background: url("~@/assets/img/account-img/communities/file.svg");
  display: block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.answer-send {
  background: url("~@/assets/img/account-img/communities/send.svg");
  display: block;
  background-repeat: no-repeat;
  width: 34px;
  height: 34px;
  margin-left: 16px;
}

.friends-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  display: none;
  line-height: 1;
}


.flex {
  display: flex;
}

.mobile-block {
  display: none;
}


@media (max-width: 1575px) {
  .record-info {
    max-width: 100%;
  }

  .record-photos {
    grid-template-rows: repeat(4, 80px);
  }
}

@media (max-width: 950px) {
  .desctop-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .interaction {
    display: none;
  }

  .all {
    padding: 47px 0 0 0;
  }

  .caption {
    border-radius: 0;
    height: 156px;
  }

  .wrapper {
    padding: 20px 10px 0 10px;
  }

  .back {
    margin: auto auto auto 25px;
  }

  .settings-white {
    background: url("~@/assets/img/account-img/communities/settings-white.svg");
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    display: block;
    background-position: center;
    margin: auto 25px auto auto;
  }


  .community-block {
    padding: 20px 14px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .record-caption__description {
    margin-left: 10px;
  }

  .record-info {
    margin-top: 10px;
    font-size: 12px;
  }

  .record-photos {
    margin-top: 20px;
    grid-template-rows: repeat(4, 46px);
    gap: 2px;
  }

  .record-photos__elemnt {
    border-radius: 5px;
  }

  .record-activity {
    margin-top: 10px;
  }


  .record-activity__text {
    font-size: 12px;
  }

  .record-activity__element:not(:last-child) {
    margin-right: 20px;
  }


  .record-caption__logo {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .record-caption__description-time {
    font-size: 12px;
    margin-top: 6px;
  }

  .record-caption__description-mobile {
    align-items: center;
  }

  .record-caption__description-mobile time {
    font-size: 10px;
    margin-top: 0;
  }

  .mobile-likes {
    margin-left: auto;
  }

  .flex {
    display: flex;
  }

  .answer-form {
    margin-left: 10px;
  }

  .answer-textarea {
    height: 40px;
    padding: 13px 24px 13px 10px;
    line-height: 14px;
  }

  .answer-send {
    height: 25px;
    width: 25px;
    background-size: cover;
    margin-left: 10px;
  }

  .answer-textarea-file {
    height: 13px;
    width: 13px;
    right: 10px;
    top: 13px;
    background-size: cover;
  }

  .post__textarea {
    max-width: 100%;
    margin-right: 0;
    margin-top: 20px;
    margin-left: 0;
  }

  .community__info {
    margin-left: 10px;
  }

  .community__info-name {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 13px;
    color: #000000;
  }

  .community__info-designation {
    padding-top: 6px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    color: #009ed1;
  }

  .post-filling__caption {
    display: flex;
    align-items: center;
  }

  .post-filling {
    flex-direction: column;
  }

  .post__textarea {
    font-size: 12px;
    line-height: 1.8;
    height: unset;
  }

  .post__actions {
    margin-top: 20px;
  }

  .post-actions__create {
    order: 1;
    max-width: 100%;
    margin-left: 0;
  }

  .post-actions__settings {
    order: 2;
    margin-left: 20px;
    display: flex;
    flex-shrink: 0;
  }

  .post__add-file {
    order: 3;
    margin-left: 40px;
  }

  .community__settings {
    background-size: 20px;
  }
}
</style>