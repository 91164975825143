<template>
	<div class="similar__vacncy-element">
		<div class="similar__vacncy-element-nomiation">
			<div class="similar__vacncy-element-nomiation-icon">
				<img :src="vacancy.companyAvatarUrl" alt="" class="similar__vacncy-element-nomiation-icon-img" />
			</div>
			<div class="similar__vacncy-element-nomiation-name">
				<p class="similar__vacncy-element-nomiation-name-sign mobile">
					{{ $t('components.landing.moreJob.company') }}
				</p>
				<div class="similar__vacncy-element-nomiation-name-rating">
					<p class="similar__vacncy-element-nomiation-name-rating-number">
						{{ vacancy.companyRate }}
					</p>
				</div>
				<p class="similar__vacncy-element-nomiation-name-company">
					{{ vacancy.companyName }}
				</p>
			</div>
		</div>
		<div class="similar__vacncy-element-description">
			<h5 class="similar__vacncy-element-description-title">
				{{ vacancy.vacancyName }}
			</h5>
			<p class="similar__vacncy-element-description-text">
				{{ vacancy.description.substring(0, DESCRIPTION_SYMBOL_LIMIT) }}...
			</p>
			<p class="similar__vacncy-element-description-place">New York</p>
		</div>
		<p class="similar__vacncy-element-price">{{ vacancy.payFrom }} $</p>
		<router-link :to="{ name: 'company', params: { vacancyId: this.vacancy.id } }"
			class="similar__vacncy-element-btn">
			{{ $t('components.landing.moreJob.more') }}
		</router-link>
	</div>
</template>


<script>
export default {
	name: "MoreJob",
	props: {
		vacancy: {
			type: Object,
		},
		count: {},
	},
	data() {
		return {
			DESCRIPTION_SYMBOL_LIMIT: 125,
		};
	},
};
</script>

<style scoped>
.similar__vacncy-element {
	width: 367px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
}

.similar__vacncy-element-nomiation {
	border-bottom: 1px solid #f1f1f5;
	padding: 17px 20px 16px 20px;
	display: flex;
	align-items: center;
}

.similar__vacncy-element-nomiation-icon-img {
	width: 64px;
	height: 64px;
	object-fit: cover;
	border-radius: 10px;
}

.similar__vacncy-element-nomiation-name {
	margin-left: 19px;
}

.similar__vacncy-element-nomiation-name-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	width: 57px;
}

.similar__vacncy-element-nomiation-name-rating-number {
	background-image: url("~@/assets/img/company-page/caption/star.svg");
	background-size: 11px;
	background-repeat: no-repeat;
	background-position: 9px;
	padding: 0px 9px 0px 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.similar__vacncy-element-nomiation-name-company {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.similar__vacncy-element-description {
	padding: 17px 20px 16px 20px;
	max-width: 327px;
	border-bottom: 1px solid #f1f1f5;
}

.similar__vacncy-element-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #171625;
}

.similar__vacncy-element-description-text {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.similar__vacncy-element-description-place {
	margin-top: 10px;
	padding-left: 18px;
	background: url("~@/assets/img/company-page/similar/place.svg");
	background-position-y: 3px;
	background-repeat: no-repeat;
	align-items: center;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.similar__vacncy-element-price {
	margin: 14px 20px 0 20px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.similar__vacncy-element-btn {
	padding: 14px 0;
	display: block;
	margin: 20px;
	width: 327px;
	background: #f1f1f5;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
	transition: opacity 1s ease;
}

.similar__vacncy-element-btn:hover {
	opacity: 0.5;
}

.mobile {
	display: none;
}

@media (max-width: 1150px) {
	.similar__vacncy-element {
		width: 300px;
	}

	.similar__vacncy-element-btn {
		width: 260px;
	}
}

@media (max-width: 960px) {
	.similar__vacncy-element {
		width: 367px;
		margin: 10px auto 0 auto;
	}

	.similar__vacncy-element-btn {
		width: 260px;
	}

	.similar__vacncy-element-btn {
		width: 327px;
	}
}

@media (max-width: 400px) {
	.mobile {
		display: block;
	}

	.similar__vacncy-element {
		width: 300px;
		margin: 10px auto 0 auto;
	}

	.similar__vacncy-element-nomiation {
		margin: 0;
		padding: 20px 20px 18px 20px;
		border-bottom: 1px solid #f1f1f5;
	}

	.similar__vacncy-element-nomiation-icon-img {
		padding: 14px 17px;
	}

	.similar__vacncy-element-nomiation-icon-img {
		width: 30px;
		height: 36px;
	}

	.similar__vacncy-element-nomiation-name {
		display: flex;
		flex-direction: column;
	}

	.similar__vacncy-element-nomiation-name-sign {
		order: 1;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.1px;
		color: #696974;
	}

	.similar__vacncy-element-nomiation-name-company {
		order: 2;
		font-weight: 700;
		font-size: 14px;
		line-height: 16px;
		color: #171625;
	}

	.similar__vacncy-element-nomiation-name-rating {
		margin-top: 4px;
		order: 3;
	}

	.similar__vacncy-element-nomiation-name-rating-name {
		font-size: 13px;
		line-height: 24px;
	}

	.similar__vacncy-element-description-title {
		font-size: 15px;
	}

	.similar__vacncy-element-description-text {
		font-size: 13px;
	}

	.similar__vacncy-element-price {
		margin: 14px 20px 20px 20px;
	}

	.similar__vacncy-element-btn {
		display: none;
	}
}
</style>
