import {errorHandler} from "@/lib/errorHandler";
import {config} from "../../lib/config";

export const documentsCandidate = {
    actions: {
        fetchAllDocuments({commit}, token) {
            let request = new XMLHttpRequest();
            let url = config.apiPrefix + 'candidate/documents/all/get';
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', token);
            try {
                request.send();
                let response = JSON.parse(request.response);
                if (response.data) {
                    commit('setAllDocuments', response.data);
                } else {
                    let data = {
                        schoolAtestatePhotoUrl: null,
                        schoolAtestateApproved: 'unloaded',
                        schoolAtestate: [],

                        schoolMedal: null,
                        schoolMedalApproved: 'unloaded',

                        schoolOlimpiads: [],
                        grandDocUrl: null,
                        grandDocApproved: 'unloaded',

                        egePhotoUrl: null,
                        egeApproved: 'unloaded',

                        egeScores: [],

                        bakalavrAtestatePhotoUrl: null,
                        bakalavrAtestateApproved: 'unloaded',

                        bakalavrAtestate: [],
                        stipendyPhotoUrl: null,
                        stipendyApproved: 'unloaded',
                        scienceWorks: [],
                        addEduDocs: [],
                        mwaDiplomUrl: null,
                        mwaApproved: 'unloaded',
                        profCompetition: [],
                        schoolOlympiads: [],
                        recommends: [],
                        eventsActivity: [],
                        volonteerActivity: [],


                        publicWork: [],

                        studyAndWorkUrl: null,
                        studyAndWorkDescription: null,
                        studyAndWorkApproved: 'unloaded',

                        infoTechKnowDocUrl: null,
                        infoTechKnowDocDescription: null,
                        infoTechApproved: 'unloaded',

                        worldCerificate: [],
                        studyAbroad: [],
                        translations: [],
                        socials: [],

                        groupOlderUrl: null,
                        groupOlderApproved: 'unloaded',

                        redDiplom: false,
                        redDiplomApproved: 'unloaded'


                    };
                    commit('setAllDocuments', data)
                }
            } catch (e) {
                console.log(e);
            }


        },
        loadAllFiles({state, commit}, payload) {
            commit('changeLoader', true);
            for (let i = 0; i < state.documentFiles.length; i++) {
                let formData = new FormData();
                formData.append('file', state.documentFiles[i].file);
                let request = new XMLHttpRequest();
                let url = config.apiPrefix + 'file/candidate/upload';
                request.open('POST', url, true);
                request.setRequestHeader('X-Auth-Token', payload.token);
                try {
                    request.send(formData);
                    request.onreadystatechange = function () {
                        if (this.readyState === 4 && this.status === 200) {
                            let response = JSON.parse(request.response);
                            commit('saveNewFile', {
                                data: response.data.url,
                                type: state.documentFiles[i].type,
                                index: state.documentFiles[i].index,
                                callback: payload.callback
                            })
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        saveAllDocument({state, commit}, payload) {
            let data = {
                schoolAtestatePhotoUrl: (state.cloneDocuments.schoolAtestatePhotoUrl === state.allDocuments.schoolAtestatePhotoUrl) ? null : state.allDocuments.schoolAtestatePhotoUrl,
                schoolAtestate: [],
                schoolMedal: (state.cloneDocuments.schoolMedal === state.allDocuments.schoolMedal) ? null : state.allDocuments.schoolMedal,
                schoolOlimpiads: [],
                grandDocUrl: (state.cloneDocuments.grandDocUrl === state.allDocuments.grandDocUrl) ? null : state.allDocuments.grandDocUrl,
                egePhotoUrl: (state.cloneDocuments.egePhotoUrl === state.allDocuments.egePhotoUrl) ? null : state.allDocuments.egePhotoUrl,
                egeScores: [],
                bakalavrAtestatePhotoUrl: (state.cloneDocuments.bakalavrAtestatePhotoUrl === state.allDocuments.bakalavrAtestatePhotoUrl) ? null : state.allDocuments.bakalavrAtestatePhotoUrl,
                bakalavrAtestate: [],
                stipendyPhotoUrl: (state.cloneDocuments.stipendyPhotoUrl === state.allDocuments.stipendyPhotoUrl) ? null : state.allDocuments.stipendyPhotoUrl,
                scienceWorks: [],
                addEduDocs: [],
                mwaDiplomUrl: (state.cloneDocuments.mwaDiplomUrl === state.allDocuments.mwaDiplomUrl) ? null : state.allDocuments.mwaDiplomUrl,
                profCompetition: [],
                schoolOlympiads: [],
                recommends: [],
                eventsActivity: [],
                volonteerActivity: [],
                publicWork: [],
                studyAndWorkUrl: (state.cloneDocuments.studyAndWorkUrl === state.allDocuments.studyAndWorkUrl) ? null : state.allDocuments.studyAndWorkUrl,
                studyAndWorkDescription: (state.cloneDocuments.studyAndWorkDescription === state.allDocuments.studyAndWorkDescription) ? null : state.allDocuments.studyAndWorkDescription,
                infoTechKnowDocUrl: (state.cloneDocuments.infoTechKnowDocUrl === state.allDocuments.infoTechKnowDocUrl) ? null : state.allDocuments.infoTechKnowDocUrl,
                infoTechKnowDocDescription: (state.cloneDocuments.infoTechKnowDocDescription === state.allDocuments.infoTechKnowDocDescription) ? null : state.allDocuments.infoTechKnowDocDescription,
                worldCerificate: [],
                studyAbroad: [],
                translations: [],
                socials: [],
                groupOlderUrl: (state.cloneDocuments.groupOlderUrl === state.allDocuments.groupOlderUrl) ? null : state.allDocuments.groupOlderUrl,
                redDiplom: (state.cloneDocuments.redDiplom === state.allDocuments.redDiplom) ? null : state.allDocuments.redDiplom
            }

            for (let i = 0; i < state.allDocuments.schoolAtestate.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.schoolAtestate.length; j++) {
                    if (state.allDocuments.schoolAtestate[i].id === state.cloneDocuments.schoolAtestate[j].id && state.allDocuments.schoolAtestate[i].name === state.cloneDocuments.schoolAtestate[j].name && state.allDocuments.schoolAtestate[i].score === state.cloneDocuments.schoolAtestate[j].score)
                        add = false;
                    if (state.allDocuments.schoolAtestate[i].delete)
                        add = true;
                }
                if (add) {
                    data.schoolAtestate.push(state.allDocuments.schoolAtestate[i])
                }
            }
            for (let i = 0; i < state.allDocuments.schoolOlimpiads.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.schoolOlimpiads.length; j++) {
                    if (state.allDocuments.schoolOlimpiads[i].id === state.cloneDocuments.schoolOlimpiads[j].id && state.allDocuments.schoolOlimpiads[i].docUrl === state.cloneDocuments.schoolOlimpiads[j].docUrl && state.allDocuments.schoolOlimpiads[i].description === state.cloneDocuments.schoolOlimpiads[j].description)
                        add = false;
                    if (state.allDocuments.schoolOlimpiads[i].delete)
                        add = true;
                }
                if (add) {
                    data.schoolOlimpiads.push(state.allDocuments.schoolOlimpiads[i])
                }
            }

            for (let i = 0; i < state.allDocuments.egeScores.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.egeScores.length; j++) {
                    if (state.allDocuments.egeScores[i].id === state.cloneDocuments.egeScores[j].id && state.allDocuments.egeScores[i].name === state.cloneDocuments.egeScores[j].name && state.allDocuments.egeScores[i].score === state.cloneDocuments.egeScores[j].score)
                        add = false;
                    if (state.allDocuments.egeScores[i].delete)
                        add = true;
                }
                if (add) {
                    data.egeScores.push(state.allDocuments.egeScores[i])
                }
            }

            for (let i = 0; i < state.allDocuments.bakalavrAtestate.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.bakalavrAtestate.length; j++) {
                    if (state.allDocuments.bakalavrAtestate[i].id === state.cloneDocuments.bakalavrAtestate[j].id && state.allDocuments.bakalavrAtestate[i].name === state.cloneDocuments.bakalavrAtestate[j].name && state.allDocuments.bakalavrAtestate[i].score === state.cloneDocuments.bakalavrAtestate[j].score)
                        add = false;
                    if (state.allDocuments.bakalavrAtestate[i].delete)
                        add = true;
                }
                if (add) {
                    data.bakalavrAtestate.push(state.allDocuments.bakalavrAtestate[i])
                }
            }

            for (let i = 0; i < state.allDocuments.scienceWorks.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.scienceWorks.length; j++) {
                    if (state.allDocuments.scienceWorks[i].id === state.cloneDocuments.scienceWorks[j].id && state.allDocuments.scienceWorks[i].workLink === state.cloneDocuments.scienceWorks[j].workLink && state.allDocuments.scienceWorks[i].workType === state.cloneDocuments.scienceWorks[j].workType)
                        add = false;
                    if (state.allDocuments.scienceWorks[i].delete)
                        add = true;
                }
                if (add) {
                    data.scienceWorks.push(state.allDocuments.scienceWorks[i])
                }
            }

            for (let i = 0; i < state.allDocuments.addEduDocs.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.addEduDocs.length; j++) {
                    if (state.allDocuments.addEduDocs[i].id === state.cloneDocuments.addEduDocs[j].id && state.allDocuments.addEduDocs[i].docUrl === state.cloneDocuments.addEduDocs[j].docUrl && state.allDocuments.addEduDocs[i].title === state.cloneDocuments.addEduDocs[j].title)
                        add = false;
                    if (state.allDocuments.addEduDocs[i].delete)
                        add = true;
                }
                if (add) {
                    data.addEduDocs.push(state.allDocuments.addEduDocs[i])
                }
            }

            for (let i = 0; i < state.allDocuments.profCompetition.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.profCompetition.length; j++) {
                    if (state.allDocuments.profCompetition[i].id === state.cloneDocuments.profCompetition[j].id && state.allDocuments.profCompetition[i].docUrl === state.cloneDocuments.profCompetition[j].docUrl && state.allDocuments.profCompetition[i].title === state.cloneDocuments.profCompetition[j].title && state.allDocuments.profCompetition[i].compType === state.cloneDocuments.profCompetition[j].compType)
                        add = false;
                    if (state.allDocuments.profCompetition[i].delete)
                        add = true;
                }
                if (add) {
                    data.profCompetition.push(state.allDocuments.profCompetition[i])
                }
            }

            for (let i = 0; i < state.allDocuments.schoolOlympiads.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.schoolOlympiads.length; j++) {
                    if (state.allDocuments.schoolOlympiads[i].id === state.cloneDocuments.schoolOlympiads[j].id && state.allDocuments.schoolOlympiads[i].docUrl === state.cloneDocuments.schoolOlympiads[j].docUrl && state.allDocuments.schoolOlympiads[i].title === state.cloneDocuments.schoolOlympiads[j].title)
                        add = false;
                    if (state.allDocuments.schoolOlympiads[i].delete)
                        add = true;
                }
                if (add) {
                    data.schoolOlympiads.push(state.allDocuments.schoolOlympiads[i])
                }
            }

            for (let i = 0; i < state.allDocuments.recommends.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.recommends.length; j++) {
                    if (state.allDocuments.recommends[i].id === state.cloneDocuments.recommends[j].id && state.allDocuments.recommends[i].docUrl === state.cloneDocuments.recommends[j].docUrl && state.allDocuments.recommends[i].owner === state.cloneDocuments.recommends[j].owner)
                        add = false;
                    if (state.allDocuments.recommends[i].delete)
                        add = true;
                }
                if (add) {
                    data.recommends.push(state.allDocuments.recommends[i])
                }
            }
            for (let i = 0; i < state.allDocuments.eventsActivity.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.eventsActivity.length; j++) {
                    if (state.allDocuments.eventsActivity[i].id === state.cloneDocuments.eventsActivity[j].id && state.allDocuments.eventsActivity[i].docUrl === state.cloneDocuments.eventsActivity[j].docUrl && state.allDocuments.eventsActivity[i].title === state.cloneDocuments.eventsActivity[j].title)
                        add = false;
                    if (state.allDocuments.eventsActivity[i].delete)
                        add = true;
                }
                if (add) {
                    data.eventsActivity.push(state.allDocuments.eventsActivity[i])
                }
            }

            for (let i = 0; i < state.allDocuments.volonteerActivity.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.volonteerActivity.length; j++) {
                    if (state.allDocuments.volonteerActivity[i].id === state.cloneDocuments.volonteerActivity[j].id && state.allDocuments.volonteerActivity[i].docUrl === state.cloneDocuments.volonteerActivity[j].docUrl && state.allDocuments.volonteerActivity[i].title === state.cloneDocuments.volonteerActivity[j].title)
                        add = false;
                    if (state.allDocuments.volonteerActivity[i].delete)
                        add = true;
                }
                if (add) {
                    data.volonteerActivity.push(state.allDocuments.volonteerActivity[i])
                }
            }

            for (let i = 0; i < state.allDocuments.publicWork.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.publicWork.length; j++) {
                    if (state.allDocuments.publicWork[i].id === state.cloneDocuments.publicWork[j].id && state.allDocuments.publicWork[i].docUrl === state.cloneDocuments.publicWork[j].docUrl && state.allDocuments.publicWork[i].title === state.cloneDocuments.publicWork[j].title)
                        add = false;
                    if (state.allDocuments.publicWork[i].delete)
                        add = true;
                }
                if (add) {
                    data.publicWork.push(state.allDocuments.publicWork[i])
                }
            }

            for (let i = 0; i < state.allDocuments.worldCerificate.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.worldCerificate.length; j++) {
                    if (state.allDocuments.worldCerificate[i].id === state.cloneDocuments.worldCerificate[j].id && state.allDocuments.worldCerificate[i].docUrl === state.cloneDocuments.worldCerificate[j].docUrl && state.allDocuments.worldCerificate[i].title === state.cloneDocuments.worldCerificate[j].title)
                        add = false;
                    if (state.allDocuments.worldCerificate[i].delete)
                        add = true;
                }
                if (add) {
                    data.worldCerificate.push(state.allDocuments.worldCerificate[i])
                }
            }

            for (let i = 0; i < state.allDocuments.studyAbroad.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.studyAbroad.length; j++) {
                    if (state.allDocuments.studyAbroad[i].id === state.cloneDocuments.studyAbroad[j].id && state.allDocuments.studyAbroad[i].docUrl === state.cloneDocuments.studyAbroad[j].docUrl && state.allDocuments.studyAbroad[i].title === state.cloneDocuments.studyAbroad[j].title)
                        add = false;
                    if (state.allDocuments.studyAbroad[i].delete)
                        add = true;
                }
                if (add) {
                    data.studyAbroad.push(state.allDocuments.studyAbroad[i])
                }
            }

            for (let i = 0; i < state.allDocuments.translations.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.translations.length; j++) {
                    if (state.allDocuments.translations[i].id === state.cloneDocuments.translations[j].id && state.allDocuments.translations[i].translationLink === state.cloneDocuments.translations[j].translationLink && state.allDocuments.translations[i].description === state.cloneDocuments.translations[j].description)
                        add = false;
                    if (state.allDocuments.translations[i].delete)
                        add = true;
                }
                if (add) {
                    data.translations.push(state.allDocuments.translations[i])
                }
            }
            for (let i = 0; i < state.allDocuments.socials.length; i++) {
                let add = true;
                for (let j = 0; j < state.cloneDocuments.socials.length; j++) {
                    if (state.allDocuments.socials[i].id === state.cloneDocuments.socials[j].id && state.allDocuments.socials[i].socialLink === state.cloneDocuments.socials[j].socialLink && state.allDocuments.socials[i].subsCount === state.cloneDocuments.socials[j].subsCount)
                        add = false;
                    if (state.allDocuments.socials[i].delete)
                        add = true;
                }
                if (add) {
                    data.socials.push(state.allDocuments.socials[i])
                }
            }


            for (let key in data) {
                if (data[key] === null || data[key].length === 0)
                    delete data[key];
            }

            let request = new XMLHttpRequest();
            let url = config.apiPrefix + 'candidate/documents/all/update';
            request.open('POST', url, true);
            request.setRequestHeader('X-Auth-Token', payload.token);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                request.send(JSON.stringify(data));

                request.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        commit('changeLoader', false);
                        errorHandler({status: request.status, page: 'doc'})
                        payload.callback();
                    }
                }

            } catch (e) {
                console.log(e);
            }
        }
    },
    mutations: {

        changeLoader(state, data) {
            state.docLoading = data;
        },
        saveNewFile(state, payload) {
            switch (payload.type) {
                case "prof&comp":
                    state.allDocuments.profCompetition[payload.index].docUrl = payload.data;
                    break;
                case "recommend":
                    state.allDocuments.recommends[payload.index].docUrl = payload.data;
                    break;
                case "attest":
                    state.allDocuments.schoolAtestatePhotoUrl = payload.data;
                    break;
                case "school&olimpiad":
                    state.allDocuments.schoolOlimpiads[payload.index].docUrl = payload.data;
                    break;
                case "grand":
                    state.allDocuments.grandDocUrl = payload.data;
                    break;
                case "ege":
                    state.allDocuments.egePhotoUrl = payload.data;
                    break;
                case "baklava":
                    state.allDocuments.bakalavrAtestatePhotoUrl = payload.data
                    break;
                case "stipend":
                    state.allDocuments.stipendyPhotoUrl = payload.data;
                    break;
                case "edu":
                    state.allDocuments.addEduDocs[payload.index].docUrl = payload.data;
                    break;
                case "school&olympiad":
                    state.allDocuments.schoolOlympiads[payload.index].docUrl = payload.data;
                    break;
                case "event":
                    state.allDocuments.eventsActivity[payload.index].docUrl = payload.data;
                    break;
                case "volunteer":
                    state.allDocuments.volonteerActivity[payload.index].docUrl = payload.data;
                    break;
                case "public&work":
                    state.allDocuments.publicWork[payload.index].docUrl = payload.data;
                    break;
                case "study&work":
                    state.allDocuments.studyAndWorkUrl = payload.data;
                    break;
                case "info&tech":
                    state.allDocuments.infoTechKnowDocUrl = payload.data;
                    break;
                case "world":
                    state.allDocuments.worldCerificate[payload.index].docUrl = payload.data;
                    break;
                case "study":
                    state.allDocuments.studyAbroad[payload.index].docUrl = payload.data;
                    break;
                case "translations":
                    state.allDocuments.translations[payload.index].docUrl = payload.data;
                    break;
                case "group&older":
                    state.allDocuments.groupOlderUrl = payload.data;
                    break;
                case "mwa":
                    state.allDocuments.mwaDiplomUrl = payload.data;
                    break;
            }
            state.fileComplete++;
            if (state.fileComplete === state.documentFiles.length) {
                payload.callback();
            }

        },
        setAllDocuments(state, data) {
            state.allDocuments = data;
            state.cloneDocuments = JSON.parse(JSON.stringify(state.allDocuments));
        },
        addAtestateData(state) {
            state.allDocuments.schoolAtestate.push({
                name: null,
                score: null,
                id: null
            });
        },
        setSelectData(state, payload) {
            switch (payload.type) {
                case 'atestat':
                    state.allDocuments.schoolMedal = payload.data;
                    break;
                case 'profCompet':
                    state.allDocuments.profCompetition[payload.index].compType = payload.data;
                    break;
                case 'science':
                    state.allDocuments.scienceWorks[payload.index].workType = payload.data;
                    break;
                case 'recommends':
                    state.allDocuments.recommends[payload.index].owner = payload.data;
                    break;

            }
        },
        addEgeData(state) {
            state.allDocuments.egeScores.push({
                name: null,
                score: null,
                id: null

            });

        },
        addBakalavarData(state) {
            state.allDocuments.bakalavrAtestate.push(
                {
                    name: null,
                    id: null,
                    score: null,

                });
        },
        addScienceData(state) {
            state.allDocuments.scienceWorks.push({
                workLink: null,
                workType: 'article',
                id: null
            })
        },
        addOlympiadData(state) {
            state.allDocuments.schoolOlimpiads.push({
                docUrl: null,
                description: null,
                id: null
            })
        },
        addEduData(state) {
            state.allDocuments.addEduDocs.push({
                docUrl: null,
                title: null,
                id: null
            })
        },
        addProfCompData(state) {
            state.allDocuments.profCompetition.push({
                docUrl: null,
                title: null,
                compType: 'solo',
                id: null
            })
        },
        addOlympiadByTitleData(state) {
            state.allDocuments.schoolOlympiads.push({
                docUrl: null,
                title: null,
                id: null
            })
        },
        addRecommendsData(state) {
            state.allDocuments.recommends.push({
                    docUrl: null,
                    owner: 'prorector',
                    id: null,
                }
            )
        },
        addEventData(state) {
            state.allDocuments.eventsActivity.push({
                    docUrl: null,
                    title: null,
                    id: null,

                }
            )
        },
        addVolonterData(state) {
            state.allDocuments.volonteerActivity.push({
                    docUrl: null,
                    title: null,
                    id: null,

                }
            )
        },
        deleteRecomm(state, payload) {
            if (payload.id) {
                state.allDocuments.recommends[payload.index]['delete'] = true;
            } else {
                state.allDocuments.recommends.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'recommend' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        addPublicData(state) {
            state.allDocuments.publicWork.push(
                {
                    docUrl: null,
                    title: null,
                    id: null,

                }
            )
        },
        addWorldCertData(state) {
            state.allDocuments.worldCerificate.push({
                docUrl: null,
                title: null,
                id: null
            })
        },
        addStudyAbroadData(state) {
            state.allDocuments.studyAbroad.push({
                docUrl: null,
                title: null,
                id: null
            })
        },
        addTranslationsData(state) {
            state.allDocuments.translations.push({
                translationLink: null,
                description: null,
                id: null
            })
        },
        addSocialsData(state) {
            state.allDocuments.socials.push({
                socialLink: null,
                subsCount: null,
                id: null
            })
        },
        changeRedDiplom(state, data) {
            state.allDocuments.redDiplom = data;
        },
        updateLocalUrl(state, payload) {
            switch (payload.type) {
                case "recommend":
                    state.allDocuments.recommends[payload.index].docUrl = payload.data;
                    state.allDocuments.recommends[payload.index].approved = 'fileLoaded';
                    break;
                case "attest":
                    state.allDocuments.schoolAtestatePhotoUrl = payload.data;
                    state.allDocuments.schoolAtestateApproved = 'fileLoaded';

                    break;
                case "school&olimpiad":
                    state.allDocuments.schoolOlimpiads[payload.index].docUrl = payload.data;
                    state.allDocuments.schoolOlimpiads[payload.index].approved = 'fileLoaded';

                    break;
                case "grand":
                    state.allDocuments.grandDocUrl = payload.data;
                    state.allDocuments.grandDocApproved = 'fileLoaded';

                    break;
                case "ege":
                    state.allDocuments.egePhotoUrl = payload.data;
                    state.allDocuments.egeApproved = 'fileLoaded';

                    break;
                case "baklava":
                    state.allDocuments.bakalavrAtestatePhotoUrl = payload.data
                    state.allDocuments.bakalavrAtestateApproved = 'fileLoaded';

                    break;
                case "stipend":
                    state.allDocuments.stipendyPhotoUrl = payload.data;
                    state.allDocuments.stipendyApproved = 'fileLoaded';

                    break;
                case "edu":
                    state.allDocuments.addEduDocs[payload.index].docUrl = payload.data;
                    state.allDocuments.addEduDocs[payload.index].approved = 'fileLoaded';

                    break;
                case "school&olympiad":
                    state.allDocuments.schoolOlympiads[payload.index].docUrl = payload.data;
                    state.allDocuments.schoolOlympiads[payload.index].approved = 'fileLoaded';

                    break;
                case "event":
                    state.allDocuments.eventsActivity[payload.index].docUrl = payload.data;
                    state.allDocuments.eventsActivity[payload.index].approved = 'fileLoaded';
                    break;
                case "prof&comp":
                    state.allDocuments.profCompetition[payload.index].docUrl = payload.data;
                    state.allDocuments.profCompetition[payload.index].approved = 'fileLoaded';

                    break;
                case "volunteer":
                    state.allDocuments.volonteerActivity[payload.index].docUrl = payload.data;
                    state.allDocuments.volonteerActivity[payload.index].approved = 'fileLoaded';

                    break;
                case "public&work":
                    state.allDocuments.publicWork[payload.index].docUrl = payload.data;
                    state.allDocuments.publicWork[payload.index].approved = 'fileLoaded';

                    break;
                case "study&work":
                    state.allDocuments.studyAndWorkUrl = payload.data;
                    state.allDocuments.studyAndWorkApproved = 'fileLoaded';

                    break;
                case "info&tech":
                    state.allDocuments.infoTechKnowDocUrl = payload.data;
                    state.allDocuments.infoTechApproved = 'fileLoaded';

                    break;
                case "world":
                    state.allDocuments.worldCerificate[payload.index].docUrl = payload.data;
                    state.allDocuments.worldCerificate[payload.index].approved = 'fileLoaded';

                    break;
                case "study":
                    state.allDocuments.studyAbroad[payload.index].docUrl = payload.data;
                    state.allDocuments.studyAbroad[payload.index].approved = 'fileLoaded';

                    break;
                case "translations":
                    state.allDocuments.translations[payload.index].docUrl = payload.data;
                    state.allDocuments.translations[payload.index].approved = 'fileLoaded';

                    break;
                case "group&older":
                    state.allDocuments.groupOlderUrl = payload.data;
                    state.allDocuments.groupOlderApproved = 'fileLoaded';

                    break;
                case "mwa":
                    state.allDocuments.mwaDiplomUrl = payload.data;
                    state.allDocuments.mwaApproved = 'fileLoaded';

                    break;
            }
        },

        addFileAllDocument(state, payload) {
            if (state.documentFiles.length !== 0) {
                let addFile = true;
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === payload.type && state.documentFiles[i].index === payload.index) {
                        state.documentFiles[i].file = payload.file;
                        addFile = false;
                    }
                }
                if (addFile) {
                    state.documentFiles.push(payload);
                }
            } else {
                state.documentFiles.push(payload);
            }

        },
        deleteSchoolAtestateRow(state, payload) {
            if (payload.id) {
                state.allDocuments.schoolAtestate[payload.index]['delete'] = true;
            } else {
                state.allDocuments.schoolAtestate.splice(payload.index, 1);
            }
        },
        deleteSchoolOlimpiad(state, payload) {
            if (payload.id) {
                state.allDocuments.schoolOlimpiads[payload.index]['delete'] = true;
            } else {
                state.allDocuments.schoolOlimpiads.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'school&olimpiad' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteEge(state, payload) {
            if (payload.id) {
                state.allDocuments.egeScores[payload.index]['delete'] = true;
            } else {
                state.allDocuments.egeScores.splice(payload.index, 1);
            }
        },
        deleteBakalavar(state, payload) {
            if (payload.id) {
                state.allDocuments.bakalavrAtestate[payload.index]['delete'] = true;
            } else {
                state.allDocuments.bakalavrAtestate.splice(payload.index, 1);
            }
        },
        deleteScience(state, payload) {
            if (payload.id) {
                state.allDocuments.scienceWorks[payload.index]['delete'] = true;
            } else {
                state.allDocuments.scienceWorks.splice(payload.index, 1);
            }
        },
        deleteEdu(state, payload) {
            if (payload.id) {
                state.allDocuments.addEduDocs[payload.index]['delete'] = true;
            } else {
                state.allDocuments.addEduDocs.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'edu' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteProfComp(state, payload) {
            if (payload.id) {
                state.allDocuments.profCompetition[payload.index]['delete'] = true;
            } else {
                state.allDocuments.profCompetition.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'prof&comp' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteSchoolOlympiad(state, payload) {
            if (payload.id) {
                state.allDocuments.schoolOlympiads[payload.index]['delete'] = true;
            } else {
                state.allDocuments.schoolOlympiads.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'school&olympiad' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteEvent(state, payload) {
            if (payload.id) {
                state.allDocuments.eventsActivity[payload.index]['delete'] = true;
            } else {
                state.allDocuments.eventsActivity.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'event' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteVolonter(state, payload) {
            if (payload.id) {
                state.allDocuments.volonteerActivity[payload.index]['delete'] = true;
            } else {
                state.allDocuments.volonteerActivity.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'volunteer' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deletePublic(state, payload) {
            if (payload.id) {
                state.allDocuments.publicWork[payload.index]['delete'] = true;
            } else {
                state.allDocuments.publicWork.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'public&work' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteWorldCer(state, payload) {
            if (payload.id) {
                state.allDocuments.worldCerificate[payload.index]['delete'] = true;
            } else {
                state.allDocuments.worldCerificate.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'world' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteStudy(state, payload) {
            if (payload.id) {
                state.allDocuments.studyAbroad[payload.index]['delete'] = true;
            } else {
                state.allDocuments.studyAbroad.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'study' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteTranslations(state, payload) {
            if (payload.id) {
                state.allDocuments.translations[payload.index]['delete'] = true;
            } else {
                state.allDocuments.translations.splice(payload.index, 1);
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (state.documentFiles[i].type === 'translations' && state.documentFiles[i].index === payload.index) {
                        state.documentFiles.splice(i, 1);
                    }
                }
                for (let i = 0; i < state.documentFiles.length; i++) {
                    if (payload.index < state.documentFiles[i].index)
                        state.documentFiles[i].index--;
                }
            }
        },
        deleteSocials(state, payload) {
            if (payload.id) {
                state.allDocuments.socials[payload.index]['delete'] = true;
            } else {
                state.allDocuments.socials.splice(payload.index, 1);
            }
        },
        upTrigger(state){
            state.updateTrigger++;
        }
    },
    state: {
        allDocuments: null,
        documentFiles: [],
        fileComplete: 0,
        updateTrigger: 0,
        docLoading: false,
        cloneDocuments: {}
    },
    getters: {
        docLoading(state) {
            return state.docLoading
        },
        getAllDocuments(state) {
            return state.allDocuments;
        },
        documentsFiles(state) {
            return state.documentFiles;
        },
        trigger(state) {
            return state.updateTrigger;
        }
    }
}