import {errorHandler} from "@/lib/errorHandler";
import {config} from "@/lib/config";

export const communities = {
    actions: {
        createNews({getters}, data) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/news';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(data));
                errorHandler({status: req.status, page: 'news'});
            } catch (e) {
                console.log(e);
            }
        },
        fetchMyCommunities({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community' + '?page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback ? payload.callback() : null;
                }
                if (payload.page === 1) {
                    commit('setAllMyCommunities', response.data);
                } else
                    commit('addAllMyCommunities', response.data)
            } catch (e) {
                console.log(e);
            }
        },

        createNewPost({commit, state}, payload) {
            commit('updateNewPostBody', {body: payload.body, id: payload.id});
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/post';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(state.newPost));
                errorHandler({status: req.status, page: 'createPost'});
                commit('resetNewPostObject')
                payload.callback();
            } catch (e) {
                console.log(e);
            }
        },

        deletePost({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/post/' + payload.id + '/delete';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            try {
                req.send();
                errorHandler({status: 200, page: 'postDelete'});
                commit('deletePost', payload.index);
            } catch (e) {
                console.log(e);
            }
        },
        fetchMyCreateCommunities({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/my' + '?page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1)
                    commit('setAllMyCommunities', response.data);
                else
                    commit('addAllMyCommunities', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        deleteMyCommunities({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.id + '/delete';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            try {
                req.send();
                commit('deleteMyCreateCommunities', payload.index);
                errorHandler({status: req.status, page: 'deleteCom'});
            } catch (e) {
                console.log(e);
            }
        },

        deleteMyApplicationInCommunities({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + `community/${payload?.id}/cancel`;
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            try {
                req.send();
                commit('deleteMyCreateCommunities', payload?.index);
                errorHandler({status: req.status, page: 'deleteApplication', index: payload.index});
            } catch (e) {
                console.log(e);
            }
        },

        fetchDataCommunities({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.communityId + '?page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.posts.length < payload.limit) {
                    payload.callback();
                }
                console.log(payload)
                if (!payload.data) {
                    commit('setDataCommunities', response.data);
                } else {
                    commit('addPostsData', response.data.posts)
                }
            } catch (e) {
                console.log(e);
            }
        },

        fetchCommunitiesRequest({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.communityId + '/income' + '?page=' + payload.page + '&limit=' + payload.limit;
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1) {
                    commit('setCommunitiesRequest', response.data);
                } else {
                    commit('addCommunitiesRequest', response.data);
                }
            } catch (e) {
                console.log(e);
            }
        },

        acceptRequest({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.communityId + '/accept';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(payload.body));
                errorHandler({status: req.status, page: 'acceptReq'});
                commit('deleteCommunityRequest', payload.index);

            } catch (e) {
                console.log(e);
            }


        },
        cancelRequest({commit}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.communityId + '/decline';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(payload.body));
                errorHandler({status: req.status, page: 'cancelReq'});
                commit('deleteCommunityRequest', payload.index);
            } catch (e) {
                console.log(e);
            }

        },

        //TODO ОТМЕНИТЬ ИСХОДЯЩУЮ ЗАЯВКУ В СООБЩЕСТВО

        sendRequestJoinCommunity(_, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/' + payload.id + '/request';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', payload.token);
            try {
                req.send();
                errorHandler({status: req.status, page: 'reqCommunity'});
                payload.callback('all');
            } catch (e) {
                console.log(e);
            }
        },

        fetchAllCommunities({commit}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community/search' + '?page=' + payload.page + '&limit=' + payload.limit + '&name=' + (payload.name ? payload.name : '');
            request.open('GET', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            try {
                request.send();
                const response = JSON.parse(request.response);
                if (response.data.length < payload.limit) {
                    payload.callback();
                }
                if (payload.page === 1)
                    commit('setAllMyCommunities', response.data);
                else
                    commit('addAllMyCommunities', response.data);
            } catch (e) {
                console.log(e);
            }
        },

        saveNewCommunity(_, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + 'community';
            request.open('POST', url, false);
            request.setRequestHeader('X-Auth-Token', payload.token);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                request.send(JSON.stringify(payload.community));
                errorHandler({status: request.status, page: 'createCommunity'})
                payload.callback('myCreate');
                const {data} = JSON.parse(request.response);
                return data;
            } catch (e) {
                console.log(e);
            }
        },
        deleteComment({getters}, communityId) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + `community/post/${getters.getDataCommunities?.id}/comment`;
            request.open('DELETE', url, false);
            request.setRequestHeader('X-Auth-Token', getters.token);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                request.send(JSON.stringify({communityId}));
                const {status} = request;
                if (status === 200) {
                    errorHandler({status: request.status, page: 'deleteComment'});
                }
                return status;
            } catch (e) {
                console.log(e);
            }
        },
				readCommunityChat({getters}, payload) {
            const request = new XMLHttpRequest();
            const url = config.apiPrefix + `community/chats/read`;
            request.open('POST', url, false);
            request.setRequestHeader('X-Auth-Token', getters.token);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                request.send(JSON.stringify({
									communityId: payload.communityId,
									lastMessageReadId: payload.lastMessageReadId
								}));
            } catch (e) {
                console.log(e);
            }
        }
    },
    mutations: {
        // deleteComment(state,commentId){
        //
        // },
        deletePost(state, index) {
            state.dataCommunities.posts.splice(index, 1);
        },
        resetNewPostObject(state) {
            state.newPost = {
                communityId: null,
                body: null,
                files: []
            };
            state.newPostFiles = [];
            state.dataCommunities = null;
        }
        ,
        updateNewPostFile(state, payload) {
            state.newPost.files[payload.index] = payload.url;
        },
        deleteFileInCommunity(state, index) {
            state.newPost.files.splice(index, 1);
            state.newPostFiles.splice(index, 1);
        },
        addPostsData(state, data) {
            state.dataCommunities.posts = state.dataCommunities.posts.slice().concat(data);
        },
        deleteCommunityRequest(state, index) {
            state.communitiesRequest.splice(index, 1);
        },
        setCommunitiesRequest(state, data) {
            state.communitiesRequest = data;
        },
        addCommunitiesRequest(state, data) {
            state.communitiesRequest = state.communitiesRequest.slice().concat(data);
        },
        deleteMyCreateCommunities(state, index) {
            state.myCommunities?.splice(index, 1);
        },
        addAllMyCommunities(state, data) {
            state.myCommunities = state.myCommunities.slice().concat(data);
        },
        setAllMyCommunities(state, data) {
            state.myCommunities = data;
        },
        setDataCommunities(state, data) {
            state.dataCommunities = data;
        },
        resetPageCommunities(state) {
            state.page = 1;
        },
        upPageCommunities(state) {
            state.page++;
        },
        addFileUrlNewPost(state, data) {
            state.newPost.files.push(data);
        },
        updateNewPostBody(state, payload) {
            state.newPost.body = payload.body;
            state.newPost.communityId = payload.id;
        },
        addFileNewPost(state, data) {
            state.newPostFiles.push(data);
        },
        setRoleCommunity(state, data) {
            state.roleCommunity = data;
        },
        resetPagePost(state) {
            state.postPage = 1;
        },
        upPagePost(state) {
            state.postPage++;
        },
        setMode(state, value) {
            state.mode = value;
        },
    },
    state: {
        postPage: null,
        postLimit: 5,
        communitiesRequest: null,
        myCommunitiesCopy: null,
        page: null,
        roleCommunity: null,
        limitCommunities: 20,
        myCommunities: null,
        dataCommunities: null,
        comments: null,
        newPost: {
            communityId: null,
            body: null,
            files: []
        },
        newPostFiles: [],
        mode: 'community'
    },
    getters: {
        getCommunitiesRequest(state) {
            return state.communitiesRequest;
        },
        getMyCommunities(state) {
            return state.myCommunities;
        },
        getDataCommunities(state) {
            return state.dataCommunities;
        },
        getPageCommunities(state) {
            return state.page;
        },
        getLimitCommunities(state) {
            return state.limitCommunities;
        },
        getNewPost(state) {
            return state.newPost;
        },
        getFilesNewPost(state) {
            return state.newPostFiles;
        },
        getRoleCommunity(state) {
            return state.roleCommunity;
        },
        getPostPage(state) {
            return state.postPage;
        },
        getPostLimit(state) {
            return state.postLimit;
        },
        getModeCommunities(state) {
            return state.mode;
        }
    }
}