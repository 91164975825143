<template>
	<div class="responses__wrapper-element">
		<div class="responses__wrapper-element-caption">
			<div class="responses__wrapper-element-caption-company">
				<div class="responses__wrapper-element-caption-company-logo">
					<img src="~@/assets/img/about-company-page/responses/apple.png" alt=""
						class="responses__wrapper-element-caption-company-logo-img" />
				</div>
				<div class="responses__wrapper-element-caption-company-info">
					<p class="responses__wrapper-element-caption-company-info-discription mobile">
						{{ $t('components.landing.aboutCompanyVacancy.company') }}
					</p>
					<div class="responses__wrapper-element-caption-company-info-rating">
						<p class="responses__wrapper-element-caption-company-info-rating-number">
							{{ $t('components.landing.aboutCompanyVacancy.rating') }}
						</p>
					</div>
					<p class="responses__wrapper-element-caption-company-info-name">
						{{ $t('components.landing.aboutCompanyVacancy.companyName') }}
					</p>
				</div>
			</div>
		</div>
		<div class="responses__wrapper-element-description">
			<h2 class="responses__wrapper-element-description-title">
				{{ $t('components.landing.aboutCompanyVacancy.title') }}
			</h2>
			<p class="responses__wrapper-element-description-text">
				{{ $t('components.landing.aboutCompanyVacancy.description') }}
			</p>
			<p class="responses__wrapper-element-description-place">
				{{ $t('components.landing.aboutCompanyVacancy.location') }}
			</p>
		</div>
		<p class="responses__wrapper-element-price">
			{{ $t('components.landing.aboutCompanyVacancy.price') }}
		</p>
		<div class="responses__wrapper-element-detailed">
			<button class="responses__wrapper-element-detailed-btn desctop">
				{{ $t('components.landing.aboutCompanyVacancy.more') }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "AboutCompanyVacancy",
};
</script>

<style scoped>
.responses__wrapper-element {
	margin: 0 auto;
	width: 372px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.responses__wrapper-element-caption {
	margin: 20px 25px 0px 29px;
	align-items: center;
	display: flex;
}

.responses__wrapper-element-caption-company {
	align-items: center;
	display: flex;
}

.responses__wrapper-element-caption-company-logo-img {
	background: #000000;
	border-radius: 10px;
	padding: 11px 17px 17px 17px;
}

.responses__wrapper-element-caption-company-info {
	margin-left: 16px;
}

.responses__wrapper-element-caption-company-info-rating {
	width: 53px;
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
}

.responses__wrapper-element-caption-company-info-rating-number {
	padding-left: 22px;
	background: url("~@/assets/img/about-company-page/responses/star.png");
	background-position: 7px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 23px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.responses__wrapper-element-caption-company-info-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.responses__wrapper-element-caption-company-status {
	margin-left: auto;
}

.responses__wrapper-element-caption-company-status-text {
	font-family: "Roboto";
	padding: 0 10px;
	background: rgba(204, 210, 229, 0.20000000298023224);
	border-radius: 20px;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #979eb8;
}

.responses__wrapper-element-caption-company-status-date {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	text-align: right;
	color: #686873;
	opacity: 0.4;
}

.responses__wrapper-element-description {
	padding: 17px 27px 15px 29px;

	border-bottom: 1px solid #f1f1f5;
}

.responses__wrapper-element-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #171625;
}

.responses__wrapper-element-description-text {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.responses__wrapper-element-description-place {
	padding-left: 23px;
	padding-top: 14px;
	background: url("~@/assets/img/about-company-page/responses/place.png");
	background-position-y: 15px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.responses__wrapper-element-price {
	padding: 14px 27px 0 29px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.responses__wrapper-element-detailed {
	margin: 22px 28px 31px 29px;
}

.responses__wrapper-element-detailed-btn {
	width: 100%;
	box-sizing: border-box;
	height: 50px;
	background: #f1f1f5;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
}

.mobile {
	display: none;
}

@media (max-width: 1860px) {
	.responses__wrapper-element {
		margin: 0 auto;
	}
}

@media (max-width: 980px) {
	.responses__wrapper-element {
		width: 298px;
	}

	.responses__wrapper-element-caption {
		flex-direction: column;
		margin: 0;
		padding: 18px 20px 22px 22px;
		align-items: normal;
		border-bottom: 1px solid #f1f1f5;
	}

	.responses__wrapper-element-caption-company {
		order: 2;
		margin-top: 20px;
	}

	.responses__wrapper-element-caption-company-info {
		display: flex;
		flex-direction: column;
	}

	.responses__wrapper-element-caption-company-info-rating {
		margin-top: 3px;
		order: 3;
	}

	.responses__wrapper-element-caption-company-info-discription {
		order: 1;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.1px;
		color: #696974;
	}

	.responses__wrapper-element-caption-company-info-name {
		order: 2;
		font-weight: 700;
	}

	.responses__wrapper-element-caption-company-status {
		order: 1;
		margin: 0;
		display: flex;
	}

	.responses__wrapper-element-caption-company-status-date {
		margin-left: auto;
	}

	.responses__wrapper-element-description-title {
		font-size: 15px;
	}

	.responses__wrapper-element-description-text {
		font-size: 13px;
	}

	.responses__wrapper-element-price {
		padding-bottom: 20px;
	}
}

@media (max-width: 850px) {
	.mobile {
		display: block;
	}

	.responses__wrapper-element-detailed {
		display: none;
	}
}

@media (max-width: 330px) {
	.responses__wrapper-element {
		margin: 0 auto;
	}
}
</style>
