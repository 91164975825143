<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="restore">
				<div class="restore__img">
					<img src="~@/assets/img/restoreaccept-page/background-logo.svg" alt="" class="restore__img-logo" />
				</div>
				<div class="restore__wrapper">
					<div class="restore__wrapper-authorization">
						<p class="restore__wrapper-authorization-text">{{
							$t('pages.authorization.authorizationRestoreAcceptPage.forgotPassword') }}</p>
						<h1 class="restore__wrapper-authorization-title">
							{{ $t('pages.authorization.authorizationRestoreAcceptPage.passwordRecoveryInstructions') }}
						</h1>
						<div class="restore__wrapper-authorization-accept">
							<div class="restore__wrapper-authorization-accept-img"></div>
						</div>
						<button class="restore__wrapper-authorization-enter-btn" @click="goAuthorization">
							{{ $t('pages.authorization.authorizationRestoreAcceptPage.backToLogin') }}
						</button>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";

export default {
	components: { Header },
	props: { id: {} },
	name: "AuthorizationRestoreAcceptPage",
	methods: {
		goAuthorization() {
			if (this.id === 'employer') this.$router.push({ name: 'employerLogin' });
			else this.$router.push({ name: 'candidateLogin' });
		}
	}
};
</script>


<style scoped>
.restore {
	padding-top: 59px;
	padding-right: 40px;
	display: flex;
	height: calc(100vh);
}

.restore__img {
	display: flex;
	max-width: 733px;
	height: 100%;
	width: 100%;
	background: url("~@/assets/img/restoreaccept-page/background.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.restore__img-logo {
	max-width: 328px;
	margin: auto;
	height: 187px;
}

.restore__wrapper {
	margin: auto auto auto 144px;
	max-width: 683px;
	width: 100%;
}

.restore__wrapper-authorization {
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 8px;
	padding: 27px 63px 50px 63px;
	width: 100%;
	box-sizing: border-box;
}

.restore__wrapper-authorization-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.restore__wrapper-authorization-title {
	padding-top: 5px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.restore__wrapper-authorization-accept {
	display: flex;
	margin-top: 16px;
}

.restore__wrapper-authorization-accept-img {
	margin: 0 auto;
	width: 122px;
	height: 120px;
	background: url("~@/assets/img/restoreaccept-page/accept.png");
	background-position: center;
	background-repeat: no-repeat;
}

.restore__wrapper-authorization-enter-btn {
	margin-top: 57px;
	width: 100%;
	box-sizing: border-box;
	height: 65px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
}

@media (max-width: 1250px) {
	.restore__wrapper {
		margin: auto auto auto 40px;
	}
}

@media (max-width: 1000px) {
	.restore {
		flex-direction: column;
		padding-right: 0;
	}

	.restore__img {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		max-width: 100%;
		width: 100%;
		height: 220px;
	}

	.restore__img-logo {
		width: 141px;
		height: 86px;
	}

	.restore__wrapper {
		box-sizing: border-box;
		margin: 50px auto auto auto;
		max-width: 683px;
		width: 100%;
		padding: 0px 20px 0px 20px;
	}
}

@media (max-width: 950px) {
	.restore {
		padding-top: 48px;
	}
}

@media (max-width: 600px) {
	.restore__wrapper {
		margin: 25px auto auto auto;
		padding: 0;
	}

	.restore__wrapper-authorization {
		padding: 0px 9px 0px 9px;
		border: none;
	}

	.restore__wrapper-authorization-text {
		font-size: 13px;
	}

	.restore__wrapper-authorization-title {
		font-size: 20px;
		padding-bottom: 0;
	}

	.restore__wrapper-authorization-accept {
		margin-top: 39px;
	}

	.restore__wrapper-authorization-enter-btn {
		margin-top: 53px;
		height: 42px;
		border-radius: 10px;
		font-size: 13px;
	}
}
</style>
