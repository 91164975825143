<template>
  <div class="menu">
    <MenuWorker v-if="role==='CANDIDATE'">
    </MenuWorker>
    <MenuCompany v-else>
    </MenuCompany>
  </div>
</template>

<script>
import MenuWorker from "../../components/account/account_worker/MenuWorker.vue";
import MenuCompany from "../../components/account/account_company/MenuCompany.vue";

export default {
  components: {MenuWorker, MenuCompany},
  name: "SocialMenu",
  props: ['role']

};
</script>

<style scoped>
.menu{
    max-width: 290px;
    width: 100%;
}
@media (max-width: 1360px) {
  .menu {
    max-width: 80px;
        width: 100%;
  }
}


@media (max-width: 950px) {
	.menu {
    display: none;
  }
}
</style>
