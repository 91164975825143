<template>
  <div class="news" @click="$emit('pickPost', post)">
    <div class="news-filling">
      <div class="news-community">
        <img :src="body.avatarUrl" alt="" class="news-persone__face">
        <div class="community-description">
          <div class="description-name">
            <p class="description-name__text">{{ body.title }}</p>
          </div>
          <p class="description__text">{{ body.text ? textSymbolLimit(body.text, 120) : null }}</p>
        </div>
      </div>
      <button v-if="false" class="news-request" @click="$router.push({ name: 'testing' })">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 2.25C1 1.91848 1.1317 1.60054 1.36612 1.36612C1.60054 1.1317 1.91848 1 2.25 1H3.5C3.83152 1 4.14946 1.1317 4.38388 1.36612C4.6183 1.60054 4.75 1.91848 4.75 2.25V3.5C4.75 3.83152 4.6183 4.14946 4.38388 4.38388C4.14946 4.6183 3.83152 4.75 3.5 4.75H2.25C1.91848 4.75 1.60054 4.6183 1.36612 4.38388C1.1317 4.14946 1 3.83152 1 3.5V2.25ZM7.25 2.25C7.25 1.91848 7.3817 1.60054 7.61612 1.36612C7.85054 1.1317 8.16848 1 8.5 1H9.75C10.0815 1 10.3995 1.1317 10.6339 1.36612C10.8683 1.60054 11 1.91848 11 2.25V3.5C11 3.83152 10.8683 4.14946 10.6339 4.38388C10.3995 4.6183 10.0815 4.75 9.75 4.75H8.5C8.16848 4.75 7.85054 4.6183 7.61612 4.38388C7.3817 4.14946 7.25 3.83152 7.25 3.5V2.25ZM1 8.5C1 8.16848 1.1317 7.85054 1.36612 7.61612C1.60054 7.3817 1.91848 7.25 2.25 7.25H3.5C3.83152 7.25 4.14946 7.3817 4.38388 7.61612C4.6183 7.85054 4.75 8.16848 4.75 8.5V9.75C4.75 10.0815 4.6183 10.3995 4.38388 10.6339C4.14946 10.8683 3.83152 11 3.5 11H2.25C1.91848 11 1.60054 10.8683 1.36612 10.6339C1.1317 10.3995 1 10.0815 1 9.75V8.5ZM7.25 8.5C7.25 8.16848 7.3817 7.85054 7.61612 7.61612C7.85054 7.3817 8.16848 7.25 8.5 7.25H9.75C10.0815 7.25 10.3995 7.3817 10.6339 7.61612C10.8683 7.85054 11 8.16848 11 8.5V9.75C11 10.0815 10.8683 10.3995 10.6339 10.6339C10.3995 10.8683 10.0815 11 9.75 11H8.5C8.16848 11 7.85054 10.8683 7.61612 10.6339C7.3817 10.3995 7.25 10.0815 7.25 9.75V8.5Z"
            stroke="#009ED1" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ $t('compocomponents.account_company..repostElement.passTesting') }}
      </button>
    </div>
    <img v-if="body.previewUrl" :src="body.previewUrl" alt="" class="post-img">
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { getDate, getTime } from "@/lib/dateUtils";

export default {
  name: "LastNewsRepost",
  props: ['post'],
  data() {
    return {
      body: null,
      textSymbolLimit, getDate, getTime
    }
  },
  beforeMount() {
    this.body = JSON.parse(this.post.body);
  }
};
</script>


<style scoped>
.news {
  font-family: "Roboto";
  padding: 18px 12px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  position: relative;
}

.news-caption {
  display: flex;
  align-items: flex-start;
}

.news-caption__icon {
  background: #f7f7f7;
  width: 53px;
  height: 53px;
  object-fit: cover;
  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
}

.caption-info {
  margin-left: 16px;
  max-width: 100%;
}

.caption-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #3b4c68;
}

.caption-info__text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(23, 23, 37, 0.4000000059604645);
}

.news-caption__time {
  max-width: 97px;
  width: 100%;
  margin-left: auto;
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.news-filling {
  padding: 30px 0 0 0;
  display: flex;
  align-items: center;
}

.news-community {
  max-width: 300px;
  display: flex;
  align-items: flex-start;
}

.community-description {
  margin-left: 16px;
}

.description-name {
  display: flex;
  align-items: center;
}

.description-name__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.description-name__status {
  margin-left: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  padding: 5px 6px 4px 6px;
}

.news-persone__face {
  display: flex;
  flex-shrink: 0;
  background: #000000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.description__direction {
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.description__text {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: rgba(23, 22, 37, 0.5);
}

.news-request {
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #009ed1;
  display: flex;
  align-items: center;
}

.news-request svg {
  margin-right: 7px;
}

.post-img {
  margin-top: 16px;
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

@media (max-width: 1770px) {
  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 950px) {
  .news-filling {
    flex-direction: row;
    align-items: center;
  }

  .news-request {
    margin: 0 0 0 auto;
  }
}

@media (max-width: 720px) {
  .news {
    padding: 12px;
  }

  .caption-info {
    margin-left: 14px;
    max-width: 170px;
  }

  .caption-info__title {
    font-size: 16px;
  }

  .caption-info__text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.4;
  }

  .news-filling {
    padding-top: 20px;
  }

  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }

  .news-persone__face {
    width: 48px;
    height: 48px;
  }

  .community-description {
    margin-left: 14px;
  }

  .description-name__text {
    font-size: 14px;
  }

  .description-name__status {
    font-size: 10px;
  }

  .description__direction {
    font-size: 10px;
  }

  .description__text {
    font-size: 10px;
  }

  .news-request {
    font-size: 12px;
  }
}

/* Modificators */
.status-close {
  background: #ffe3e3;
  color: #b53151;
}
</style>
