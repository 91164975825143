<template>
  <div class="">
    <HeaderAccountWorker v-if="role==='CANDIDATE'">
    </HeaderAccountWorker>
    <HeaderAccountCompany v-else>
    </HeaderAccountCompany>
  </div>
</template>

<script>
import HeaderAccountWorker from "../../components/account/account_worker/HeaderAccountWorker.vue";
import HeaderAccountCompany from "../../components/account/account_company/HeaderAccountCompany.vue";

export default {
  components: {HeaderAccountWorker, HeaderAccountCompany},
  name: "SocialHeader",
  props: ['role'],

};
</script>

<style scoped>
</style>

