var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documents__group-element"},[(_vm.url)?_c('button',{staticClass:"delete",on:{"click":_vm.deleteDocument}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z","stroke":"#3B4C68","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),_c('div',{staticClass:"documents__group-element-download",style:({ display: (_vm.url ? 'flex' : 'none') })},[_c('div',{staticClass:"documents__demonstration-container"},[(_vm.url)?_c('img',{staticClass:"documents__demonstration",class:{ 'documents-icon': _vm.documentType !== 'img' },attrs:{"src":_vm.documentType === 'img' ? (_vm.url.includes('blob:') ? _vm.url : `${_vm.url}?token=${_vm.$store.getters.token}`) : _vm.documentType === 'pdf' ? require('/src/assets/img/all-img/pdf-icon.png') : _vm.documentType === 'doc' ? require('/src/assets/img/all-img/doc-icon.svg.png') : require('/src/assets/img/all-img/exel-icon.svg.png'),"alt":""}}):_vm._e()]),_c('input',{ref:"file",attrs:{"type":"file","hidden":"","accept":"image/*,.pdf,.docx,.doc,.xlsx"},on:{"change":_vm.changeFile}}),_c('a',{ref:"download",attrs:{"href":(_vm.documentType === 'img' || _vm.documentType === 'pdf') ? (_vm.url.includes('blob:') ? _vm.url : `${_vm.url}?token=${_vm.$store.getters.token}`) : (_vm.documentType === 'exel' || _vm.documentType === 'doc') ? ('http://docs.google.com/viewer?url=' + _vm.url + '?token=' + _vm.$store.getters.token) : _vm.url + '?token=' + _vm.$store.getters.token,"target":"_blank"}})]),_c('div',{staticClass:"activities"},[_c('button',{staticClass:"documents__group-element-download-btn",class:{
      'download-btn-blue':
        _vm.status === 'checking' || _vm.status === 'approved',
    },on:{"click":_vm.uploadFile}},[(_vm.status === 'unloaded' || _vm.status == null || _vm.status === 'rejected')?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.upload')))]):(
        _vm.status === 'checking' || _vm.status === 'approved')?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.watchDocument')))]):_vm._e(),_c('span',{staticClass:"download-icon"})]),_c('div',{staticClass:"documents__group-element-caption-stage"},[_c('div',{staticClass:"documents__group-element-caption-stage-circle",class:{
        'documents__group-element-caption-stage-circle':
          _vm.status === 'unloaded' || _vm.status == null,
        'stage-circle-blue': _vm.status === 'checking',
        'stage-circle-green': _vm.status === 'approved',
        'stage-circle-red': _vm.status === 'rejected',
      }}),_c('p',{staticClass:"documents__group-element-caption-stage-text"},[(_vm.status === 'unloaded' || _vm.status == null)?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.requiresLoading')))]):(_vm.status === 'checking')?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.atTheTest')))]):(_vm.status === 'approved')?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.checked')))]):(_vm.status === 'rejected')?_c('span',[_vm._v(_vm._s(_vm.$t('components.account_worker.separateDocumentCopy.divestable')))]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }