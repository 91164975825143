import Vue from 'vue';
import VueGtag from 'vue-gtag'
import Router from 'vue-router';
import App from './App.vue';
import store from './store';
import VueFleshMessage from 'vue-flash-message'
import Toast from "vue-toastification";
import VueApexCharts from 'vue-apexcharts'
import "vue-toastification/dist/index.css";
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n';


import HomePage from './pages/landing/HomePage.vue';
import BlogPage from './pages/landing/BlogPage.vue';
import ArticlesPage from './pages/landing/ArticlesPage.vue';
import AboutUsPage from './pages/landing/AboutUsPage.vue';
import ContactPage from './pages/landing/ContactPage.vue';
import HelpPage from './pages/landing/HelpPage.vue';
import VacancyPage from './pages/landing/VacancyPage.vue';
import CompanyPage from './pages/landing/CompanyPage.vue';
import AboutWorkerPage from './pages/landing/AboutWorkerPage.vue';
import AboutCompanyPage from './pages/landing/AboutCompanyPage.vue';
import TariffPage from './pages/landing/TariffPage.vue';
import AccountWorkerVacancyPage from './pages/account/account_worker/AccountWorkerVacancyPage.vue';
import AccountWorkerFavoritesPage from './pages/account/account_worker/AccountWorkerFavoritesPage.vue';
import AccountCompanyDashboardPage from './pages/account/account_company/AccountCompanyDashboardPage.vue';
import AccountCompanySettingsPage from './pages/account/account_company/AccountCompanySettingsPage.vue';
import AccountCompanyRatingPage from './pages/account/account_company/AccountCompanyRatingPage.vue';
import AccountCompanyRecomendationPage from './pages/account/account_company/AccountCompanyRecomendationPage.vue';
import AccountCompanyTariffPage from './pages/account/account_company/AccountCompanyTariffPage.vue';
import AccountCompanyResponsesPage from './pages/account/account_company/AccountCompanyResponsesPage.vue';
import AccountCompanySeparateResponsePage from './pages/account/account_company/AccountCompanySeparateResponsePage.vue';
import AccountCompanyResumesPage from './pages/account/account_company/AccountCompanyResumesPage.vue';
import AccountCompanyFavoritesPage from './pages/account/account_company/AccountCompanyFavoritesPage.vue';
import AccountCompanyWorkerInfoPage from './pages/account/account_company/AccountCompanyWorkerInfoPage.vue';
import AccountCompanyTestedWorkersPage from './pages/account/account_company/AccountCompanyTestedWorkersPage.vue';
import AccountCompanyInterviewsListPage from './pages/account/account_company/AccountCompanyInterviewsListPage.vue';
import AccountCompanyVacancyPage from './pages/account/account_company/AccountCompanyVacancyPage.vue';
import AccountWorkerRecommendationVacancy from './pages/account/account_worker/AccountWorkerRecommendationVacancy.vue';
import AccountCompanyCreationVacancyPage from './pages/account/account_company/AccountCompanyCreationVacancyPage.vue';
import AccountCompanyChatPage from './pages/account/account_company/AccountCompanyChatPage.vue';
import AccountCompanyGroupsPage from './pages/account/account_company/AccountCompanyGroupsPage.vue';
import AccountWorkerRatingPage from './pages/account/account_worker/AccountWorkerRatingPage.vue';
import AccountWorkerDashboardPage from './pages/account/account_worker/AccountWorkerDashboardPage.vue';
import AccountWorkerRecomendationPage from './pages/account/account_worker/AccountWorkerRecomendationPage.vue';
import AccountWorkerSettingsPage from './pages/account/account_worker/AccountWorkerSettingsPage.vue';
import AccountWorkerTariffPage from './pages/account/account_worker/AccountWorkerTariffPage.vue';
import AccountWorkerDocumentsPage from './pages/account/account_worker/AccountWorkerDocumentsPage.vue';
import AccountWorkerResponsesPage from './pages/account/account_worker/AccountWorkerResponsesPage.vue';
import AccountWorkerSeparateResponsePage from './pages/account/account_worker/AccountWorkerSeparateResponsePage.vue';
import AccountWorkerChatPage from './pages/account/account_worker/AccountWorkerChatPage.vue';
import AccountWorkerCompanyInfoPage from './pages/account/account_worker/AccountWorkerCompanyInfoPage.vue';
import AuthorizationLoginPage from './pages/authorization/AuthorizationLoginPage.vue';
import AuthorizationRestorePage from './pages/authorization/AuthorizationRestorePage.vue';
import AuthorizationRestoreAcceptPage from './pages/authorization/AuthorizationRestoreAcceptPage.vue';
import AuthorizationWorkerRegistrationPage from './pages/authorization/AuthorizationWorkerRegistrationPage.vue';
import AuthorizationEmploerSmallRegistrationPage
    from './pages/authorization/AuthorizationEmploerSmallRegistrationPage.vue';
import AuthorizationEmploerRegistrationBigPage from './pages/authorization/AuthorizationEmploerRegistrationBigPage.vue';
import TestAvailableListPage from './pages/tests/test_worker/TestAvailableListPage.vue';
import TestSeparatePreviewPage from './pages/tests/test_worker/TestSeparatePreviewPage.vue';
import TestInputPage from './pages/tests/test_worker/TestInputPage.vue';
import TestVideoPage from './pages/tests/test_worker/TestVideoPage.vue';
import TestWrapperPage from './pages/tests/test_worker/TestWrapperPage.vue';
import TestConstructorPage from './pages/tests/test_company/TestConstructorPage.vue';
import TestConstructorPagePreview from "./pages/tests/test_company/TestConstructorPagePreview";
import TestCandidateExaminationPage from './pages/tests/test_company/TestCandidateExaminationPage.vue';
import CandidateLoginPage from "./pages/authorization/CandidateLoginPage.vue";
import AccountWorkerVacancyInfoPage from "./pages/account/account_worker/AccountWorkerVacancyInfoPage";
// import EmployerLoginPage from "./pages/authorization/EmployerLoginPage";
import InterviewSeparatePreviewPage from "./pages/interview/InterviewSeparatePreviewPage";
import InterviewInputPage from "./pages/interview/InterviewInputPage";
import AccountCompanyFaq from './pages/account/account_company/AccountCompanyFaq'
import AccountWorkerFaq from './pages/account/account_worker/AccountWorkerFaq'
import UnknownPage from './pages/landing/UnknownPage'
import SocialFriendsPage from './pages/social/SocialFriendsPage.vue';
import SocialPossibleFriendsPage from './pages/social/SocialPossibleFriendsPage.vue';
import SocialFriendsIncomingApplicationsPage from './pages/social/SocialFriendsIncomingApplicationsPage.vue';
import SocialFriendsBlockedUsersPage from './pages/social/SocialFriendsBlockedUsersPage.vue';
import SocialFriendsFavoritesPage from './pages/social/SocialFriendsFavoritesPage.vue';
import SocialCommunitiesListPage from './pages/social/SocialCommunitiesListPage.vue';
import SocialCommunitiesMainPage from './pages/social/SocialCommunitiesMainPage.vue';
import SocialCommunitiesMyListPage from './pages/social/SocialCommunitiesMyListPage.vue';
import SocialCommunitiesMyChanges from './pages/social/SocialCommunitiesMyChanges.vue';
import SocialCommunitiesApplications from './pages/social/SocialCommunitiesApplications.vue';
import SocialCommunityChat from "./pages/social/SocialCommunityChat";
import SocialChatPage from "./pages/social/SocialChatPage";
import AccountWorkerDocumentsPageCopy from "@/pages/account/account_worker/AccountWorkerDocumentsPageCopy";
import AccountWorkerVideoInterviewPage from "@/pages/account/account_worker/AccountWorkerVideoInterviewPage";
import AuthorizationRestorePasswordPage from "@/pages/authorization/AuthorizationRestorePasswordPage";
import TestPreviewPage from "@/pages/tests/test_worker/TestPreviewPage";
// import AccountWorkerDocumentsNew from './pages/account/account_worker/AccountWorkerDocumentsNew.vue';


Vue.use(Router);
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});

Vue.use(Vuelidate)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueFleshMessage);
Vue.config.productionTip = false;

export const router = new Router({
    mode: 'history',
    routes: [
        // {
        //     path: '/test',
        //     name: 'testDocs',
        //     component: AccountWorkerDocumentsNew
        // },
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '*',
            component: UnknownPage
        },
        {
            path: '/blog',
            name: 'blog',
            component: BlogPage
        },
        {
            path: '/blog/:articlesId',
            name: 'articles',
            props: true,
            component: ArticlesPage
        },
        {
            path: '/about',
            name: 'about',
            component: AboutUsPage
        },
        {
            path: '/contact',
            name: 'contact',
            component: ContactPage
        },
        {
            path: '/help',
            name: 'help',
            component: HelpPage
        },
        {
            path: '/vacancy',
            component: VacancyPage
        },
        {
            path: '/vacancy/:vacancyId',
            name: 'company',
            props: true,
            component: CompanyPage
        },
        {
            path: '/AboutWorkerPage',
            component: AboutWorkerPage
        },
        {
            path: '/AboutCompanyPage',
            component: AboutCompanyPage
        },
        {
            path: '/TariffPage',
            name: 'tariff',
            component: TariffPage
        },
        {
            path: '/AccountCompanyTestedWorkersPage',
            component: AccountCompanyTestedWorkersPage
        },
        {
            path: '/accountcompanydashboard',
            component: AccountCompanyDashboardPage
        },
        {
            path: '/AccountCompanySettingsPage',
            component: AccountCompanySettingsPage
        },
        {
            path: '/AccountCompanyRatingPage',
            component: AccountCompanyRatingPage
        },
        {
            path: '/AccountCompanyRecomendationPage',
            component: AccountCompanyRecomendationPage
        },
        {
            path: '/AccountCompanyTariffPage',
            component: AccountCompanyTariffPage
        },
        {
            path: '/AccountCompanyResponsesPage',
            component: AccountCompanyResponsesPage
        },
        {
            path: '/AccountCompanySeparateResponsePage',
            component: AccountCompanySeparateResponsePage
        },
        {
            path: '/AccountCompanyResumesPage',
            component: AccountCompanyResumesPage
        },
        {
            path: '/AccountCompanyWorkerInfoPage',
            component: AccountCompanyWorkerInfoPage
        },
        {
            path: '/AccountCompanyVacancyPage',
            component: AccountCompanyVacancyPage
        },
        {
            path: '/AccountCompanyCreationVacancyPage',
            component: AccountCompanyCreationVacancyPage
        },
        {
            path: '/AccountWorkerRatingPage',
            component: AccountWorkerRatingPage
        },
        {
            path: '/AccountWorkerVacancyPage',
            component: AccountWorkerVacancyPage
        },
        {
            path: '/accountworkerdashboard',
            component: AccountWorkerDashboardPage
        },
        {
            path: '/accountworkerrecomendation',
            component: AccountWorkerRecomendationPage
        },
        {
            path: '/AccountWorkerSettingsPage',
            component: AccountWorkerSettingsPage
        },
        {
            path: '/AccountWorkerTariffPage',
            component: AccountWorkerTariffPage
        },
        {
            path: '/AccountWorkerDocumentsPage',
            component: AccountWorkerDocumentsPage
        },
        {
            path: '/AccountWorkerResponsesPage',
            component: AccountWorkerResponsesPage
        },
        {
            path: '/AccountWorkerSeparateResponsePage',
            component: AccountWorkerSeparateResponsePage
        },
        {
            path: '/login',
            component: AuthorizationLoginPage
        },
        {
            path: '/restorepassword/:id',
            name: 'restorePassword',
            props: true,
            component: AuthorizationRestorePage
        },
        {
            path: '/restorepassword/:id',
            name: 'restoreComplete',
            props: true,
            component: AuthorizationRestoreAcceptPage
        },
        {
            path: '/restore-password/:code',
            name: 'restorePasswordPage',
            props: true,
            component: AuthorizationRestorePasswordPage
        },
        {
            path: '/candidate/registration',
            name: 'candidateReg',
            component: AuthorizationWorkerRegistrationPage
        },
        {
            path: '/employer/registration',
            name: 'employerReg',
            component: AuthorizationEmploerSmallRegistrationPage
        },
        {
            path: '/AuthorizationEmploerRegistrationBigPage',
            component: AuthorizationEmploerRegistrationBigPage
        },
        {
            path: '/TestAvailableListPage',
            component: TestAvailableListPage
        },
        {
            path: '/TestSeparatePreviewPage',
            component: TestSeparatePreviewPage
        },
        {
            path: '/TestInputPage',
            component: TestInputPage
        },
        {
            path: '/TestVideoPage',
            component: TestVideoPage
        },
        {
            path: '/TestCandidateExaminationPage',
            component: TestCandidateExaminationPage
        },
        {
            path: '/TestWrapperPage',
            component: TestWrapperPage
        },
        {
            path: '/communities',
            name: 'candidateCommunities',
            component: SocialCommunitiesListPage
        },
        {
            path: '/user/login',
            name: 'candidateLogin',
            component: CandidateLoginPage
        },
        {
            path: '/candidate/account',
            component: App,
            children: [
                {
                    path: 'passing/:passingId/:status',
                    name: 'passingInterviewCandidate',
                    props: true,
                    component: TestCandidateExaminationPage
                },

                {
                    path: 'documents',
                    name: 'documents',
                    component: AccountWorkerDocumentsPageCopy
                },

                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: AccountWorkerDashboardPage
                },

                {
                    path: 'settings',
                    name: 'settings',
                    component: AccountWorkerSettingsPage
                },
                {
                    path: 'rate',
                    name: 'rate',
                    component: AccountWorkerRatingPage,
                },
                {
                    path: 'testing',
                    name: 'testing',
                    component: TestAvailableListPage,

                },
                {
                    path: 'testing/:testingId',
                    name: 'activeTest',
                    props: true,
                    component: TestSeparatePreviewPage

                },
                {
                    path: 'testing/:testingId',
                    name: 'activeQuestion',
                    props: true,
                    component: TestInputPage,
                }
                ,
                {
                    path: 'vacancies',
                    name: 'vacancies',
                    component: AccountWorkerVacancyPage
                },
                {
                    path: 'favorites',
                    name: 'favorites',
                    component: AccountWorkerFavoritesPage
                },
                {
                    path: 'responses',
                    name: 'responses',
                    component: AccountWorkerResponsesPage,
                },
                {
                    path: 'responses/:responseId',
                    name: 'responsePage',
                    props: true,
                    component: AccountWorkerSeparateResponsePage
                },
                {
                    path: 'interview/:interviewId',
                    name: 'interview',
                    props: true,
                    component: InterviewSeparatePreviewPage
                },
                {
                    path: 'interview/:interviewId',
                    name: 'actualInterview',
                    props: true,
                    component: InterviewInputPage
                },
                {
                    path: 'recommendations',
                    name: 'recommendations',
                    component: AccountWorkerRecomendationPage
                },
                {
                    path: 'prices',
                    name: 'prices',
                    component: AccountWorkerTariffPage
                },
                {
                    path: 'faq',
                    name: 'faq',
                    component: AccountWorkerFaq
                },
                {
                    path: 'vacancy/:vacancyId',
                    name: 'vacancyDashboard',
                    props: true,
                    component: AccountWorkerVacancyInfoPage
                },
                {
                    path: 'chat',
                    name: 'chatCandidate',
                    props: true,
                    component: AccountWorkerChatPage
                },
                {
                    path: 'video-interview',
                    name: 'videoInterview',
                    component: AccountWorkerVideoInterviewPage
                }, {
                    path: 'results/testing',
                    name: 'viewTestingResults',
                    component: TestPreviewPage

                },
                {
                    path: 'recommendation',
                    component: App,
                    children: [
                        {
                            path: 'vacancies',
                            name: 'accountWorkerRecommendationVacancy',
                            component: AccountWorkerRecommendationVacancy
                        },
                        // {
                        // 	path: 'friends',
                        //   name: 'viewTestingResults',
                        //   component: TestPreviewPage
                        // },
                        // {
                        // 	path: 'communities',
                        //   name: 'viewTestingResults',
                        //   component: TestPreviewPage
                        // }
                    ],
                }

            ],
            beforeEnter: async function (from, to, next) {
                if (store.getters.isCandidate && store.getters.token) {
                    next();
                    return
                }
                await store.dispatch('loadUserData');
                if (store.getters.isCandidate && store.getters.token) {
                    next();
                } else {
                    next({ path: '/user/login' });
                }
            }
        },

        // {
        //     path: '/employer/login',
        //     name: 'employerLogin',
        //     component: EmployerLoginPage
        // },
        {
            path: '/employer/account',
            component: App,
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboardCompany',
                    component: AccountCompanyDashboardPage
                },
                {
                    path: 'settings',
                    name: 'settingsCompany',
                    component: AccountCompanySettingsPage
                },
                {
                    path: 'interview',
                    name: 'interviewPage',
                    component: AccountCompanyInterviewsListPage
                },

                {
                    path: 'interview/:interviewId/view',
                    name: 'interviewConstructPreview',
                    props: true,
                    component: TestConstructorPagePreview
                },
                {
                    path: 'interview/:interviewId/editing',
                    name: 'interviewConstruct',
                    props: true,
                    component: TestConstructorPage
                }, {
                    path: 'interview/:interviewId',
                    name: 'newInterview',
                    props: true,
                    component: TestConstructorPage
                },
                {
                    path: 'interview/result/:interviewId',
                    name: 'resultInterview',
                    props: true,
                    component: AccountCompanyTestedWorkersPage
                },
                {
                    path: 'passing/:passingId/:status',
                    name: 'passingInterview',
                    props: true,
                    component: TestCandidateExaminationPage
                },
                {
                    path: 'rate',
                    name: 'rateCompany',
                    component: AccountCompanyRatingPage
                },
                {
                    path: 'vacancy',
                    name: 'vacancyCompany',
                    component: AccountCompanyVacancyPage
                },
                {
                    path: 'vacancies',
                    name: 'vacanciesCompany',
                    component: AccountCompanyResumesPage,
                    props: true
                },
                {
                    path: 'favorites',
                    name: 'favorites',
                    component: AccountCompanyFavoritesPage,
                    props: true
                },
                {
                    path: 'vacancy/responses/:vacancyId',
                    name: 'vacancyResponses',
                    props: true,
                    component: AccountCompanyResponsesPage
                },
                {
                    path: "summary/:summaryId",
                    name: 'aboutWorker',
                    props: true,
                    component: AccountCompanyWorkerInfoPage
                },
                {
                    path: 'groups',
                    name: 'groupsCompany',
                    component: AccountCompanyGroupsPage
                },
                {
                    path: 'recommendations',
                    name: 'recommendationsCompany',
                    component: AccountCompanyRecomendationPage
                },
                {
                    path: 'prices',
                    name: 'pricesCompany',
                    component: AccountCompanyTariffPage
                },
                {
                    path: 'faq',
                    name: 'faqCompany',
                    component: AccountCompanyFaq
                },
                {
                    path: 'vacancy/create',
                    name: 'createVacancy',
                    component: AccountCompanyCreationVacancyPage
                },
                {
                    path: 'vacancy/:vacancyId',
                    name: 'updateVacancy',
                    props: true,
                    component: AccountCompanyCreationVacancyPage
                },
                {
                    path: 'response/get/:responseId',
                    name: 'separateResponse',
                    props: true,
                    component: AccountCompanySeparateResponsePage
                },
                {
                    path: 'chat',
                    props: true,
                    name: 'chatEmployer',
                    component: AccountCompanyChatPage
                },

            ],
            beforeEnter: async function (from, to, next) {
                if (store.getters.isEmployer && store.getters.token) {
                    next();
                    return
                }
                await store.dispatch('loadUserData');
                if (store.getters.isEmployer && store.getters.token) {
                    next();
                } else {
                    next({ path: '/user/login' });
                }
            }
        },
        {
            path: '/user/social',
            component: App,
            children: [
                {
                    path: 'friends',
                    name: 'friends',
                    component: SocialFriendsPage
                },
                {
                    name: 'outgoingFriend',
                    path: 'outgoing-requests',
                    component: SocialFriendsIncomingApplicationsPage
                },
                {
                    name: 'blockedUsers',
                    path: 'blocked-list',
                    component: SocialFriendsBlockedUsersPage
                },
                {
                    path: 'incoming-requests',
                    name: 'incomingFriend',
                    component: SocialFriendsIncomingApplicationsPage
                },
                {
                    path: 'favorite',
                    name: 'favorite-list',
                    component: SocialFriendsFavoritesPage
                },

                {
                    path: 'incoming-applications/:communitiesId',
                    name: 'communitiesIncomingRequest',
                    props: true,
                    component: SocialCommunitiesApplications
                },
                {
                    path: "candidate/:summaryId",
                    name: 'candidateProfile',
                    props: true,
                    component: AccountCompanyWorkerInfoPage
                },
                {
                    path: 'communities/:communitiesId/:postId?',
                    props: true,
                    name: 'communitiesPage',
                    component: SocialCommunitiesMainPage,
                },
                {
                    path: 'communities',
                    name: 'communities',
                    component: SocialCommunitiesMyListPage
                },
                {
                    path: 'my-communities/:communitiesId/:postId?',
                    props: true,
                    name: 'myCommunities',
                    component: SocialCommunitiesMyChanges
                },
                {
                    path: 'friend/find',
                    name: 'findFriends',
                    component: SocialPossibleFriendsPage
                },
                {
                    path: 'employer/:summaryId',
                    props: true,
                    name: 'employerProfile',
                    component: AccountWorkerCompanyInfoPage
                },
                {
                    path: 'communities/chats/:communityId',
                    props: true,
                    name: 'communityChat',
                    component: SocialCommunityChat
                },
                {
                    path: 'chats',
                    name: 'allChats',
                    component: SocialChatPage
                }
            ],
            beforeEnter: async function (from, to, next) {
                if (store.getters.isEmployer || store.getters.isCandidate && store.getters.token) {
                    next();
                    return
                }
                await store.dispatch('loadUserData');
                if (store.getters.isEmployer || store.getters.isCandidate && store.getters.token) {
                    next();
                } else {
                    next({ path: '/user/login' });
                }
            }
        }
    ]
});

const fx = require('money');
const request = new XMLHttpRequest();
request.open("GET", "https://www.cbr-xml-daily.ru/latest.js", false);
try {
    request.send();
    const { rates, base } = JSON.parse(request.response);
    fx.base = base;
    fx.rates = rates;

} catch (e) {
    console.log(e);
}

Vue.use(VueGtag, {
    config: {
        id: 'G-5913JZM71C',
        params: {
            send_page_view: false
        }
    }
}, router)


Vue.config.productionTip = false;

Vue.use(VueI18n);





function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/\/([a-z0-9-_]+)\/(.+)\.json$/i);
        if (matched && matched.length > 2) {
            const locale = matched[1];
            const path = matched[2].split('/');

            if (!messages[locale]) {
                messages[locale] = {};
            }

            let currentLevel = messages[locale];

            for (let i = 0; i < path.length; i++) {
                const segment = path[i];

                if (i === path.length - 1) {
                    currentLevel[segment] = locales(key).default || locales(key);
                } else {
                    if (!currentLevel[segment]) {
                        currentLevel[segment] = {};
                    }
                    currentLevel = currentLevel[segment];
                }
            }
        }
    });

    return messages;
}

const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0]; // Возвращает 'en' из 'en-US'
};
const browserLanguage = getBrowserLanguage();
const defaultLanguage = 'en';

const i18n = new VueI18n({
    locale: browserLanguage || defaultLanguage,
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});

i18n.locale = i18n.locale || defaultLanguage;





new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
}).$mount('#app');