import { render, staticRenderFns } from "./ArticlesPage.vue?vue&type=template&id=ee1a0832&scoped=true"
import script from "./ArticlesPage.vue?vue&type=script&lang=js"
export * from "./ArticlesPage.vue?vue&type=script&lang=js"
import style0 from "./ArticlesPage.vue?vue&type=style&index=0&id=ee1a0832&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee1a0832",
  null
  
)

export default component.exports