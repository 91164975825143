<template>
	<div class="tested" v-if="dataPassing.status === status || status === 'default'">
		<div class="tested__caption">
			<img :src="dataPassing.candidateAvatarUrl" alt="Face" class="tested__caption-face" />
			<div class="tested__caption-descripton">
				<div class="tested__caption-descripton-rating">
					<p class="tested__caption-descripton-rating-number">
						{{ dataPassing.candidateRate ? dataPassing.candidateRate : 0 }}
					</p>
				</div>
				<p class="tested__caption-descripton-name">
					{{ dataPassing.candidateName ? dataPassing.candidateName :
						$t('components.account_company.testedWorker.noData') }}
				</p>
			</div>
		</div>
		<div class="tested__info">
			<p class="tested__info-text">
				{{ dataPassing.candidateAboutText ? dataPassing.candidateAboutText.slice(0, DESCRIPTION_SYMBOL_LIMIT) +
					'...' : $t('components.account_company.testedWorker.noData') }}
			</p>
			<div class="tested__info-date">
				<p class="tested__info-date-text">{{ $t('components.account_company.testedWorker.testDate') }}:</p>
				<time class="tested__info-date-when">{{ new Date(dataPassing.passingDate).toLocaleDateString() }}</time>
			</div>
			<div class="tested__info-time">
				<p class="tested__info-date-text">{{ $t('components.account_company.testedWorker.testTime') }}:</p>
				<time class="tested__info-date-when">{{ parsingTime(dataPassing.timePassingSeconds) }}</time>
			</div>
			<div class="tested__caption-descripton-rating-result" v-if="dataPassing.status === 'checked'">
				<p class="tested__caption-descripton-rating-number-result">
					{{ $t('components.account_company.testedWorker.result') }}
					<span class="tested__caption-descripton-rating-number-result-span">{{ dataPassing.totalScore
						}}</span>
				</p>
			</div>
		</div>
		<div class="tested__check">
			<div class="tested__check-container">
				<router-link :to="{ name: 'passingInterview', params: { passingId: dataPassing.id, status: 'check' } }"
					v-if="dataPassing.status === 'unchecked'">
					<button class="tested__check-btn">{{ $t('components.account_company.testedWorker.checkTest')
						}}</button>
				</router-link>
				<router-link :to="{ name: 'passingInterview', params: { passingId: dataPassing.id, status: 'view' } }"
					v-else>
					<button class="tested__check__result-btn">{{
						$t('components.account_company.testedWorker.viewResult') }}</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TestedWorker",
	props: {
		dataPassing: {},
		status: {}
	},
	data() {
		return {
			DESCRIPTION_SYMBOL_LIMIT: 100,
		};
	},
	methods: {
		parsingTime(time) {
			let hours = Math.floor(time / 60 / 60);
			let minutes = Math.floor(time / 60) % 60;
			let seconds = Math.floor(time) % 60;
			if (hours === 0) return minutes + ":" + seconds;
			else return hours + ":" + minutes + ":" + seconds;
		},
	},
};
</script>


<style scoped>
.tested {
	height: 380px;
	position: relative;
	max-width: 338px;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 9px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.tested__caption {
	padding: 19px 19px 14px 19px;
	border-bottom: 1px solid #f1f1f5;
	display: flex;
	align-items: center;
}

.tested__caption-face {
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
	width: 64px;
	height: 64px;
}

.tested__caption-descripton {
	margin-left: 10px;
}

.tested__caption-descripton-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	max-width: 54px;
	align-items: center;
}

.tested__caption-descripton-rating-number {
	align-items: center;
	border-radius: 20px;
	background-image: url("~@/assets/img/company-elements/rating.svg");
	background-repeat: no-repeat;
	background-position: 9px;
	background-position-y: 6px;
	padding: 0 10px 0 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.tested__caption-descripton-rating-result {
	margin-top: 10px;
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	align-items: center;
	max-width: 120px;
}

.tested__caption-descripton-rating-number-result {
	background: url("~@/assets/img/company-elements/rating.svg");
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-repeat: no-repeat;
	background-position: 81px;
	background-position-y: center;
	background-position-y: center;
	background-position-y: 6px;
	padding: 0 10px 0 10px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
	background-position-y: 7px;
}

.tested__caption-descripton-rating-number-result-span {
	margin-left: 18px;
}

.tested__caption-descripton-name {
	margin-top: 1px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.tested__info {
	padding: 15px 19px;
}

.tested__info-text {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #686873;
}

.tested__info-date {
	align-items: center;
	margin-top: 20px;
	display: flex;
}

.tested__info-date-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
}

.tested__info-date-when {
	margin-left: 5px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.tested__info-time {
	align-items: center;
	margin-top: 10px;
	display: flex;
}

.tested__check {
	width: 100%;
	box-sizing: border-box;
	padding: 13px 19px;
	position: absolute;
	border-top: 1px solid #f1f1f5;
	bottom: 0px;
}

.tested__check-container {
	cursor: pointer;
	background: #e6f6fc;
	border-radius: 10px;
	text-align: center;
	transition: opacity 1s ease;
}

.tested__check-container:hover {
	opacity: 0.5;
}

.tested__check-btn {
	width: 100%;
	box-sizing: border-box;
	padding: 15px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #009ed1;
}

.tested__check__result-btn {
	width: 100%;
	box-sizing: border-box;
	padding: 15px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	background: #f1f1f5;
	color: #696974;
	border-radius: 10px;
}

@media (max-width: 400px) {
	.tested__caption-descripton-name {
		font-size: 12px;
	}

	.tested__caption-descripton-rating {
		max-width: 51px;
	}

	.tested__caption-descripton-rating-number {
		background-position: 6px;
		padding: 0 3px 0px 21px;
		line-height: 20px;
		font-size: 13px;
	}

	.tested__info-text {
		font-size: 13px;
		color: #686873;
	}

	.tested__info-date-text {
		color: #686873;
	}
}
</style>