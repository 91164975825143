<template>
	<div class="friend-wrapper">
		<div class="friends-separate__left">
			<div class="friend__face">
				<img :src="application.avatarUrl" alt="" class="friend__face-img" />
				<div class="friend__face-status friend__face-status-active"></div>
			</div>
			<div class="friend__info">
				<p class="friend__info-designation friend-mobile">
					{{
						application.role
							? application.role === 'candidate'
								? $t('components.social.separateApplications.jobSeeker')
								: $t('components.social.separateApplications.employer')
							: $t('components.social.separateApplications.noData')
					}}
					<span class="friend__info-rating">{{ application.rate ? application.rate : 0 }}</span>
				</p>
				<h3 class="friend__info-name">
					{{ application.name ? application.name : $t('components.social.separateApplications.noData') }}
					<span class="friend__info-rating friend-desctop">{{ application.rate ? application.rate : 0
						}}</span>
				</h3>
				<p class="friend__info-speciality friend-desctop">
					{{ application.additionalDescription ? application.additionalDescription :
						$t('components.social.separateApplications.noData') }}
				</p>
				<p class="friend__info-designation friend-desctop">
					{{
						application.role
							? application.role === 'candidate'
								? $t('components.social.separateApplications.jobSeeker')
								: $t('components.social.separateApplications.employer')
							: $t('components.social.separateApplications.noData')
					}}
				</p>
				<p class="friend__place-town friend-mobile">
					<span class="friend__place-icon"></span>
					{{ application.additionalDescription ? application.additionalDescription :
						$t('components.social.separateApplications.noData') }}
				</p>
			</div>
			<div class="friend__place friend-desctop">
				<p class="friend__place-name">{{ $t('components.social.separateApplications.city') }}</p>
				<p class="friend__place-town">
					<span class="friend__place-icon"></span>
					{{ application.additionalDescription ? application.additionalDescription :
						$t('components.social.separateApplications.noData') }}
				</p>
			</div>
		</div>
		<div class="friend__action">
			<button type="button" @click="acceptRequest" class="friend__action-button">{{
				$t('components.social.separateApplications.accept') }}</button>
			<button type="button" @click="cancelRequest" class="friend__action-button friend__action-button-red">{{
				$t('components.social.separateApplications.reject') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "SeparateApplications",
	props: ["application", "communityId", "index"],
	methods: {
		acceptRequest() {
			this.$store.dispatch("acceptRequest", {
				token: this.$store.getters.token,
				body: {
					userId: this.application.userId,
					role: this.application.role,
				},
				communityId: this.communityId,
				index: this.index,
			});
		},
		cancelRequest() {
			this.$store.dispatch("cancelRequest", {
				token: this.$store.getters.token,
				body: {
					userId: this.application.userId,
					role: this.application.role,
				},
				communityId: this.communityId,
				index: this.index,
			});
		},
	},
};
</script>


<style scoped>
.friend-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	margin-bottom: 20px;
}

.friends-separate__left {
	display: flex;
}

.friend__face {
	position: relative;
}

.friend__face-img {
	width: 64px;
	height: 64px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
}

.friend__face-status {
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 9px;
	height: 9px;
	display: block;
	border-radius: 100%;
}

.friend__face-status.friend__face-status-active {
	background: #14c686;
	border: 2px solid #fff;
}

.friend__info {
	margin-left: 19px;
}

.friend__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.friend__info-rating {
	margin-left: 10px;
	padding: 4px 11px 4px 25px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
	background-repeat: no-repeat;
	background-position-x: 9px;
	background-position-y: 6px;
	color: #14c686;
}

.friend__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.friend__info-designation {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}

.friend__place {
	margin-left: 40px;
}

.friend__place-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #686873;
}

.friend__place-town {
	margin-top: 10px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 12px;
	color: #000000;
	display: flex;
}

.friend__place-icon {
	background: url("~@/assets/img/account-img/friends/town.svg");
	width: 11px;
	height: 13px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.friend__message {
	background: #009ed1;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	margin: auto 0 auto auto;
	width: 214px;
	height: 45px;
	box-shadow: 0px 4px 12px -6px rgba(0, 158, 209, 1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.friend__message-settings {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.friend__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin: auto 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.friend__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -99px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;


}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.friend__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.friend__message-icon {
	background-image: url("~@/assets/img/account-img/friends/add-friend-white.png");
	width: 16px;
	height: 15px;
	display: block;
	background-repeat: no-repeat;
	margin-right: 7px;
	background-size: cover;
}

.friend__action {
	display: flex;
	margin: auto 0 auto auto;
}

.friend__action-button {
	background: rgba(20, 198, 134, 0.20000000298023224);
	border-radius: 8px;
	box-shadow: 0px 4px 12px -6px #14C686;
	font-family: "Roboto";
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #14c686;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 45px;
	width: 150px;
	cursor: pointer;
	transition: opacity 1s ease;
}

.friend__action-button:hover {
	opacity: 0.5;
}

.friend__action-button.friend__action-button-red {
	box-shadow: 0px 4px 12px -6px #e8675f;
	color: #e7665e;
	background: rgba(231, 102, 94, 0.20000000298023224);
	margin-left: 10px;
}

.friend-mobile {
	display: none;
}

@media (max-width: 1575px) {
	.friend-wrapper {
		max-width: 100%;
		padding: 0;
		flex-direction: column;
	}

	.friend-desctop {
		display: none;
	}

	.friend-mobile {
		display: flex;
	}

	.friends-separate__left {
		padding: 13px 15px;
		align-items: center;
	}

	.friend__info-rating {
		margin-left: auto;
	}

	.friend__info {
		width: 100%;
	}

	.friend__info-designation {
		padding-top: 0;
	}

	.friend__info-designation {
		align-items: center;
	}

	.friend__info-name {
		font-size: 13px;
	}

	.friend__place-town {
		color: #696974;
		margin-top: 5px;
	}

	.friend__message {
		width: 100%;
		margin-left: 0;
		border-radius: 0px 0px 10px 10px;
	}

	.friend__action {
		display: flex;
		margin: 0;
		width: 100%;
	}

	.friend__action-button {
		max-width: 100%;
		width: 100%;
		box-shadow: none;
	}

	.friend__action-button:first-child {
		border-radius: 0 0 0 8px;
	}

	.friend__action-button:nth-child(2) {
		border-radius: 0 0px 8px 0px;
	}

	.friend__action-button.friend__action-button-red {
		margin-left: 0;
		box-shadow: none;
	}
}

@media (max-width: 720px) {
	.friend-wrapper {
		padding: 0;

	}
}
</style>
