<template>
	<div class="table-wrapper">
		<table class="table" border="1px" v-for="(item, index) in data" :key="item.id">
			<tr class="table-row">
				<th class="table-column table-status" v-if="!item.delete">
					<separate-document :document-url="item.docUrl" :document-status="item.approved" :index-doc="index"
						type-doc="school&olimpiad"></separate-document>
				</th>
				<th class="table-column table-textarea" v-if="!item.delete">
					<textarea :disabled="item.approved === 'checking'" class="input-description"
						placeholder="Description of the Olympiad" v-model="item.description" />
				</th>
				<th class="close-btn" v-if="!item.delete">
					<button v-if="item.approved !== 'checking' && item.approved !== 'approved'"
						@click="deleteRow(item.id, index)">
						<img src="~@/assets/img/all-img/documents/close.svg" alt="" />
					</button>
				</th>
			</tr>
		</table>
		<button class="button-add-row" @click="addData">Add a note</button>
	</div>
</template>

<script>
import SeparateDocument from "@/components/account/account_worker/account_worker_documents/item/SeparateDocument";

export default {
	components: { SeparateDocument },
	name: "SchoolOlympiad",
	props: {
		data: {
			type: Array,
		},
	},
	methods: {
		addData() {
			this.$store.commit("addOlympiadData");
		},
		deleteRow(id, index) {

			this.$store.commit('deleteSchoolOlimpiad', {
				id: id,
				index: index,
			})
			this.$forceUpdate();

		}
	},
};
</script>

<style scoped>
.table {
	margin-top: 20px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
}

.table-column {
	border: solid 1px;
}

.table-wrapper {
	width: 100%;
	box-sizing: border-box;
	position: relative;
	padding-bottom: 50px;
}

.button-add-row {
	display: flex;
	margin: 0 auto;
}

.table-row {
	position: relative;
}

.button-add-row {
	margin: 0 auto;
	display: flex;
	margin-top: 23px;
	background: #009ed1;
	border-radius: 7px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	padding: 15px 60px;
	color: #ffffff;
	position: absolute;
	bottom: -25px;
	left: calc(50% - 132px);
}

.table-wrapper table {
	border: none;
}

.table-wrapper tr {
	border: none;
}

.table-wrapper th {
	border: none;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
}

.table-wrapper textarea ::placeholder {
	color: #c7c7cc;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.table-textarea {
	margin-left: 40px;
	display: flex;
}

.table-textarea textarea {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	height: 342px;
}

.documents__group-element {
	margin-top: 0;
}

.table-row th:nth-child(2n) {
	padding-right: 20px;
}

th p {
	font-size: 20px;
	margin-bottom: 10px;
}

.table-status {
	max-width: 300px;
	width: 300px;
	box-sizing: border-box;
}

.close-btn {
	width: 16px;
}

@media (max-width: 816px) {
	.table-wrapper {
		margin-top: 20px;
		margin-left: 0;
	}

	tr {
		display: flex;
		flex-direction: column;
	}

	.table-row th:nth-child(2n + 1) {
		padding-right: 0px;
	}

	.table-row th:nth-child(2n) {
		padding-right: 0px;
	}

	.table-row th:nth-child(3n) {
		padding-bottom: 30px;
	}

	.table {
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.table-row th {
		padding-bottom: 10px;
	}

	.button-add-row {
		position: unset;
		max-width: 300px;
		width: 100%;
		display: block;
	}

	.table-row {
		width: 300px;
	}

	.table-column {
		text-align: center;
	}

	.table-textarea {
		margin-left: 0;
	}

	.table-row {
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
	}

	.table-status {
		width: 100%;
		box-sizing: border-box;
	}

	.close-btn {
		margin: 0 auto;
	}
}

@media (max-width: 330px) {
	.table {
		text-align: center;
	}
}
</style>