var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkCategory())?_c('router-link',{staticClass:"responses__wrapper-element",attrs:{"to":{
  name: 'responsePage',
  params: {
    responseId: this.vacancy.id,
  },
}}},[_c('div',{staticClass:"responses__wrapper-element-caption"},[_c('div',{staticClass:"responses__wrapper-element-caption-company"},[_c('img',{staticClass:"responses__wrapper-element-caption-company-logo-img",attrs:{"src":_vm.vacancy.companyAvatarUrl,"alt":""}}),_c('div',{staticClass:"responses__wrapper-element-caption-company-info"},[_c('div',{staticClass:"responses__wrapper-element-caption-company-info-rating"},[_c('p',{staticClass:"responses__wrapper-element-caption-company-info-rating-number"},[_vm._v(" "+_vm._s(_vm.vacancy.companyRate ? _vm.vacancy.companyRate : 0)+" ")])]),_c('p',{staticClass:"responses__wrapper-element-caption-company-info-name"},[_vm._v(" "+_vm._s(_vm.vacancy.companyName ? _vm.limit(_vm.vacancy.companyName, 23) : _vm.$t('components.account_worker.separateRecomendationSameWork.untitled'))+" ")])])]),_c('div',{staticClass:"responses__wrapper-element-caption-company-status"},[_c('p',{staticClass:"responses__wrapper-element-caption-company-status-text",class:{
        'responses__wrapper-element-caption-company-status-text':
          (this.status === _vm.$t('components.account_worker.separateRecomendationSameWork.underConsideration') || this.status === _vm.$t('components.account_worker.separateRecomendationSameWork.waitingResults')),
        'status-text-refusal': this.status === _vm.$t('components.account_worker.separateRecomendationSameWork.refusal'),
        'status-text-application': (this.status === _vm.$t('components.account_worker.separateRecomendationSameWork.invitation') || this.status === _vm.$t('components.account_worker.separateRecomendationSameWork.verified')),
      }},[_vm._v(" "+_vm._s(_vm.status ? _vm.status : _vm.$t('components.account_worker.separateRecomendationSameWork.unknownStatus'))+" ")]),_c('p',{staticClass:"responses__wrapper-element-caption-company-status-date"},[_vm._v(" "+_vm._s(_vm.vacancy.responseDate ? new Date(_vm.vacancy.responseDate).toISOString().slice(0, 10) : "undefined")+" ")])])]),_c('div',{staticClass:"responses__wrapper-element-description"},[_c('h2',{staticClass:"responses__wrapper-element-description-title"},[_vm._v(" "+_vm._s(_vm.vacancy.vacancyName ? _vm.limit(_vm.vacancy.vacancyName, 23) : _vm.$t('components.account_worker.separateRecomendationSameWork.untitled'))+" ")]),_c('p',{staticClass:"responses__wrapper-element-description-text"},[_vm._v(" "+_vm._s(_vm.vacancy.vacancyDescription ? _vm.limit(_vm.vacancy.vacancyDescription, 60) : _vm.$t('components.account_worker.separateRecomendationSameWork.noDescription'))+"... ")]),_c('p',{staticClass:"responses__wrapper-element-description-place"},[_vm._v(" "+_vm._s(_vm.vacancy.vacancyCity ? _vm.limit(_vm.vacancy.vacancyCity, 23) : "undefined")+" ")])]),_c('p',{staticClass:"responses__wrapper-element-price"},[_vm._v(" "+_vm._s(_vm.vacancy.vacancyPrice ? _vm.vacancy.vacancyPrice + "$" : _vm.$t('components.account_worker.separateRecomendationSameWork.byAgreement'))+" ")]),_c('div',{staticClass:"responses__wrapper-element-detailed"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }