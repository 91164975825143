<template>
  <div class="page">
    <Header></Header>

    <main class="main">
      <div class="contacts">
        <div class="container">
          <div class="contacts__wrapper">
            <div class="contacts__wrapper-requisites">
              <h1 class="contacts__wrapper-requisites-title">{{ $t('pages.landing.contactPage.contactPage.title') }}</h1>
              <p class="contacts__wrapper-requisites-text">
                {{ $t('pages.landing.contactPage.contactPage.description') }}
              </p>
              <div class="contacts__wrapper-requisites-list desctop" v-if="false">
                <p class="contacts__wrapper-requisites-list-designation">{{
                  $t('pages.landing.contactPage.contactPage.companyName') }}</p>
                <p class="contacts__wrapper-requisites-list-records">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                </p>
                <p class="contacts__wrapper-requisites-list-designation">{{ $t('pages.landing.contactPage.contactPage.tin') }}
                </p>
                <p class="contacts__wrapper-requisites-list-records">
                  2352342356234235235
                </p>
                <p class="contacts__wrapper-requisites-list-designation">{{
                  $t('pages.landing.contactPage.contactPage.registrationCode') }}</p>
                <p class="contacts__wrapper-requisites-list-records">
                  2352342356234235235
                </p>
                <p class="contacts__wrapper-requisites-list-designation">{{ $t('pages.landing.contactPage.contactPage.bin') }}
                </p>
                <p class="contacts__wrapper-requisites-list-records">
                  00000000000000000000000000
                </p>
              </div>
            </div>
            <div class="contacts__wrapper-cards">
              <div class="contacts__wrapper-cards-element">
                <h2 class="contacts__wrapper-cards-element-title" v-if="false">
                  {{ $t('pages.landing.contactPage.contactPage.areYouJobSeeker') }}
                </h2>
                <p class="contacts__wrapper-cards-element-text" v-if="false">
                  {{ $t('pages.landing.contactPage.contactPage.jobSeekerDescription') }}
                </p>
                <div class="contacts__wrapper-cards-element-communications">
                  <a href=""
                    class="contacts__wrapper-cards-element-communications-mail">Theprofessional.contact@yahoo.com</a>
                  <a href="" v-if="false" class="contacts__wrapper-cards-element-communications-telephone">+7 999
                    999-99-99</a>
                </div>
              </div>
              <div class="contacts__wrapper-cards-element" v-if="false">
                <h2 class="contacts__wrapper-cards-element-title">
                  {{ $t('pages.landing.contactPage.contactPage.areYouEmployer') }}
                </h2>
                <p class="contacts__wrapper-cards-element-text">
                  {{ $t('pages.landing.contactPage.contactPage.employerDescription') }}
                </p>
                <div class="contacts__wrapper-cards-element-communications">
                  <a href=""
                    class="contacts__wrapper-cards-element-communications-mail">Theprofessional.contact@yahoo.com</a>
                  <a href="" class="contacts__wrapper-cards-element-communications-telephone">+7 999 999-99-99</a>
                </div>
              </div>
              <div class="contacts__wrapper-cards-element" v-if="false">
                <h2 class="contacts__wrapper-cards-element-title">
                  {{ $t('pages.landing.contactPage.contactPage.forPressAndPartners') }}
                </h2>
                <p class="contacts__wrapper-cards-element-text">
                  {{ $t('pages.landing.contactPage.contactPage.pressAndPartnersDescription') }}
                </p>
                <div class="contacts__wrapper-cards-element-communications">
                  <a href=""
                    class="contacts__wrapper-cards-element-communications-mail">Theprofessional.contact@yahoo.com</a>
                  <a href="" class="contacts__wrapper-cards-element-communications-telephone">+7 999 999-99-99</a>
                </div>
              </div>
            </div>
            <div class="contacts__wrapper-requisites-list mobile" v-if="false">
              <p class="contacts__wrapper-requisites-list-designation">{{
                $t('pages.landing.contactPage.contactPage.contactInformation') }}</p>
              <p class="contacts__wrapper-requisites-list-records">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
              </p>
              <p class="contacts__wrapper-requisites-list-designation">{{ $t('pages.landing.contactPage.contactPage.tin') }}</p>
              <p class="contacts__wrapper-requisites-list-records">
                2352342356234235235
              </p>
              <p class="contacts__wrapper-requisites-list-designation">{{
                $t('pages.landing.contactPage.contactPage.registrationCode') }}</p>
              <p class="contacts__wrapper-requisites-list-records">
                2352342356234235235
              </p>
              <p class="contacts__wrapper-requisites-list-designation">{{ $t('pages.landing.contactPage.contactPage.bin') }}</p>
              <p class="contacts__wrapper-requisites-list-records">
                00000000000000000000000000
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="map" v-if="false">
        <div class="container map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.75567029275!2d49.144507441506384!3d55.784953880510315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415ead2213d91e8b%3A0x2d529e14c58e372!2z0JLRgdC1INC00LvRjyDQvNGD0LfRi9C60LDQvdGC0L7Qsg!5e0!3m2!1sru!2sru!4v1642703589055!5m2!1sru!2sru"
            class="map__wrapper map-dectop" allowfullscreen="" loading="lazy"></iframe>
          <div class="map__info">
            <p class="map__info-text">{{ $t('pages.landing.contactPage.contactPage.contactInformation') }}</p>
            <a class="map__info-place">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore</a>
            <a href="" class="map__info-telephone">+7 999 999-99-99
              <span class="map__info-telephone-place">Moscow</span></a>
            <a href="" class="map__info-telephone">+7 999 999-99-99
              <span class="map__info-telephone-place">Russia</span></a>
            <a href="" class="map__info-mail">mail@website.ru</a>
          </div>
        </div>
      </div>
    </main>

    <Footer style="margin-top: auto"></Footer>
  </div>
</template>


<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";

export default {
  components: { Header, Footer },
  beforeMount() {
    scroll(0, 0);
  },
  name: "ContactPage",
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fafafb;
}

* {
  box-sizing: border-box;
}

/* /Mobile */
.contacts {
  background: #fafafb;
}

.contacts__wrapper {
  margin-top: 157px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.contacts__wrapper-requisites {
  max-width: 517px;
}

.contacts__wrapper-requisites-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 35px;
  color: #000000;
}

.contacts__wrapper-requisites-text {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 2px solid #e8e8e8;
  padding-top: 25px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #696974;
}

.contacts__wrapper-requisites-list {
  padding-top: 25px;
}

.contacts__wrapper-requisites-list-designation {
  margin-top: 20px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.contacts__wrapper-requisites-list-records {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.1px;
  color: #444e57;
}

.contacts__wrapper-cards {
  max-width: 580px;
  width: 100%;
}

.contacts__wrapper-cards-element {
  margin-bottom: 27px;
  position: inherit;
  width: 487px;
  padding: 28px 31px 22px 60px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.contacts__wrapper-cards-element-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.contacts__wrapper-cards-element-text {
  padding-top: 12px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: #696974;
}

.contacts__wrapper-cards-element-communications {
  display: flex;
  padding: 0;
  flex-direction: column;
}

.contacts__wrapper-cards-element-communications-telephone {
  margin-top: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 17px;
  line-height: 34px;
  color: #444e57;
  background-image: url("~@/assets/img/contact-page/map/map-phone.svg");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 36px;
}

.contacts__wrapper-cards-element-communications-mail {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 17px;
  line-height: 34px;
  color: #444e57;
  background-image: url("~@/assets/img/contact-page/map/map-block-mail.svg");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 36px;
}

.mobile {
  display: none;
}

@media (max-width: 1155px) {
  .contacts__wrapper-requisites {
    max-width: 335px;
    width: 100%;
  }

  .contacts__wrapper {
    margin-bottom: 56px;
    justify-content: space-around;
  }

  .contacts__wrapper-requisites-title {
    font-size: 20px;
  }

  .contacts__wrapper-requisites-text {
    font-size: 13px;
  }

  .contacts__wrapper-requisites-list-designation {
    font-size: 12px;
  }

  .contacts__wrapper-requisites-list-records {
    font-size: 14px;
  }

  .contacts__wrapper-cards-element {
    margin-bottom: 14px;
    padding: 21px;
    max-width: 100%;
    width: 100%;
  }

  .contacts__wrapper-cards-element-title {
    font-size: 15px;
  }

  .contacts__wrapper-cards-element-text {
    font-size: 12px;
    line-height: 1.75;
  }

  .contacts__wrapper-cards-element-communications {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .contacts__wrapper-cards-element-communications-telephone {
    font-size: 16px;
    line-height: 34px;
    margin-left: 0;
  }

  .contacts__wrapper-cards-element-communications-mail {
    font-size: 16px;
    line-height: 34px;
  }
}

@media (max-width: 767px) {
  .desctop {
    display: none;
  }

  .contacts__wrapper-requisites-text {
    padding-bottom: 0;
    border: none;
  }

  .contacts__wrapper {
    margin-top: 92px;
    flex-direction: column;
  }

  .contacts__wrapper-requisites {
    margin: 0 auto;
    text-align: center;
  }

  .contacts__wrapper-cards {
    margin: 30px auto 0 auto;
  }

  .mobile {
    display: block;
    margin: 0 auto;
    max-width: 335px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .contacts__wrapper-requisites {
    max-width: 100%;
  }

  .contacts__wrapper-requisites-text {
    padding-bottom: 0;
    border: none;
  }

  .contacts__wrapper-requisites {
    margin: 0;
    text-align: left;
  }

  .contacts__wrapper-cards {
    margin: 0;
    margin-top: 30px;
  }

  .mobile {
    text-align: left;
  }

  .contacts__wrapper-cards-element {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

/* /Mobile */

/* Map css */
.map {
  background: #fafafb;
  padding-top: 143px;
  padding-bottom: 113px;
}

.map-container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 10px;
  overflow: hidden;
  position: relative;
}

.map__wrapper {
  width: 100%;
  height: 480px;
  box-sizing: border-box;
  border: none;
}

.map__img {
  box-sizing: border-box;
  width: 100%;
}

.map__info {
  display: flex;
  flex-direction: column;
  padding: 31px 40px 40px 40px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea, 0px 26px 24px rgba(64, 64, 64, 0.25);
  width: 318px;
  height: 227px;
  top: 85px;
  left: 99px;
  position: absolute;
}

.map__info-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #696974;
}

.map__info-place {
  padding-top: 20px;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-block-place.svg");
  background-repeat: no-repeat;
  background-position: 0% 37%;
  padding-left: 36px;
}

.map__info-telephone {
  padding-top: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-phone.svg");
  background-repeat: no-repeat;
  background-position: 0% 84%;
  padding-left: 36px;
}

.map__info-telephone-place {
  margin-left: 15px;
  padding-left: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  opacity: 0.6;
  border-left: 1px solid #8f959a;
}

.map__info-mail {
  padding-top: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-block-mail.svg");
  background-repeat: no-repeat;
  background-position: 0% 84%;
  padding-left: 36px;
}

@media (max-width: 780px) {
  .map {
    background: #f3f4f6;
    padding: 0;
    padding-bottom: 0;
  }

  .map-container {
    max-width: 100%;
    margin: 0;
    padding: 0px;
  }

  .map__wrapper {
    height: 559px;
  }

  .map__info {
    padding: 14px 16px 26px 16px;
    background: #ffffff;
    width: 266px;
    height: 203px;
    top: 237px;
    left: 10px;
  }

  .map__info-text {
    font-size: 12px;
  }

  .map__info-place {
    padding-top: 5px;
    font-size: 11px;
    background-position: 0% 28%;
    padding-left: 32px;
  }

  .map__info-telephone {
    font-size: 12px;
    padding-left: 32px;
  }

  .map__info-telephone-place {
    font-size: 12px;
  }

  .map__info-mail {
    padding-top: 10px;
    padding-left: 32px;
  }

  .contacts__wrapper-cards-element-communications-mail {
    font-size: 14px;
    line-height: 34px;
  }

  .contacts__wrapper-cards-element-communications-telephone {
    font-size: 14px;
    line-height: 34px;
  }
}

@media (max-width: 360px) {
  .contacts__wrapper-cards-element-communications-mail {
    font-size: 12px;
    line-height: 34px;
    padding-left: 26px;
  }

  .contacts__wrapper-cards-element-communications-telephone {
    font-size: 12px;
    line-height: 34px;
    padding-left: 26px;
  }
}

/* /Map css */
</style>