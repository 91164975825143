<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<Menu></Menu>

				<div class="container-account">
					<div class="test">
						<div class="test__caption">
							<div class="test__caption-description">
								<h1 class="test__caption-description-title">Test name</h1>
								<p class="test__caption-description-text">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
									do eiusmod tempor
								</p>
							</div>
							<div class="test__caption-cancel">
								<button class="test__caption-cancel-btn">Cancel</button>
							</div>
						</div>
						<div class="test__wrapper">
							<div class="test__wrapper-content">
								<div class="test__wrapper-content-caption">
									<p class="test__wrapper-content-caption-question">
										question #2
									</p>
									<p class="test__wrapper-content-caption-time">
										Time left: <time class="time">13:12</time>
									</p>
								</div>
								<p class="test__wrapper-content-text">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris tetur adipisicing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim
									veniam nostrud exercitation ullamco laboris tetur ?
								</p>
							</div>
							<nav class="test__wrapper-nav">
								<div class="test__wrapper-nav-last">
									<button class="test__wrapper-nav-last-btn">
										Previous question
									</button>
								</div>
								<p class="test__wrapper-nav-number">1/30</p>
								<div class="test__wrapper-nav-next">
									<button class="test__wrapper-nav-next-btn">
										Next question
									</button>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Menu from "../../../components/account/Menu.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";

export default {
	components: { Menu, HeaderAccountWorker },

	name: "TestWrapperPage",
};
</script>


<style scoped>
.account {
	display: flex;
}

.container-account {
	max-width: 1220px;
}

.test__caption {
	display: flex;
	align-items: center;
}

.test__caption-description {
	max-width: 343px;
	width: 100%;
	box-sizing: border-box;
}

.test__caption-description-title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.test__caption-description-text {
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 1.38;
	color: #3b4c68;
}

.test__caption-cancel {
	margin-left: auto;
	background: #f1f1f5;
	border-radius: 7px;
	max-width: 207px;
	width: 100%;
	text-align: center;
}

.test__caption-cancel-btn {
	padding: 17px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #696974;
	transition: opacity 1s ease;
}

.test__caption-cancel-btn:hover {
	opacity: 0.5;
}

.test__wrapper {
	margin-top: 38px;
	background: #ffffff;
	border: 1px solid #e9e9f9;
	border-radius: 19px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
}

.test__wrapper-content {
	padding: 46px 63px 59px 62px;
	border-bottom: 1px solid #e9e9f9;
}

.test__wrapper-content-caption {
	display: flex;
}

.test__wrapper-content-caption-question {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 15px;
	line-height: 1.28;
	color: #009ed1;
}

.test__wrapper-content-caption-time {
	margin-left: auto;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 15px;
	line-height: 1.28;
	color: #3b4c68;
	opacity: 0.5;
}

.time {}

.test__wrapper-content-text {
	margin-top: 23px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 18px;
	line-height: 1.78;
	color: #3b4c68;
}

.test__wrapper-content-answers {
	margin-top: 39px;
	display: flex;
	flex-direction: column;
}

.test__wrapper-content-answers-element {
	position: relative;
	display: flex;
	background: #f5f8f9;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
	border: 1px solid #dae9f3;
	border-radius: 16px;
	transition: background 1s ease, border 1s ease;
}

.test__wrapper-content-answers-element:hover {
	background: #eef8ff;
	border: 1px solid #009ed1;
}

.test__wrapper-content-answers-element:not(:last-child) {
	margin-bottom: 18px;
}

.test__wrapper-content-answers-label {
	padding: 21px 20px 21px 0;
	order: 2;
	margin-left: 95px;
	font-family: "Montserrat";
	font-weight: 500;
	line-height: 1.78;
	color: #3b4c68;
}

.test__wrapper-content-answers-input {
	order: 1;
	-webkit-appearance: none;
	position: absolute;
	margin-left: 33px;
	width: 25px;
	height: 25px;
	border: 2px solid rgba(59, 76, 104, 0.5);
	border-radius: 100%;
	transition: background 1s ease, border 1s ease;
}

.test__wrapper-content-answers-input:hover {
	background: #009ed1;
	border: none;
}

.test__wrapper-nav {
	padding: 20px 63px 20px 62px;
	display: flex;
	align-items: center;
}

.test__wrapper-nav-last {
	max-width: 218px;
	width: 100%;
	text-align: center;
	background: #eef5f9;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.test__wrapper-nav-last:hover {
	opacity: 0.5;
}

.test__wrapper-nav-last-btn {
	padding: 18px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.test__wrapper-nav-number {
	margin: 0 auto;
	padding: 13px 29px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #82939a;
	background: #edf1f4;
	border-radius: 28px;
}

.test__wrapper-nav-next {
	max-width: 207px;
	width: 207px;
	text-align: center;
	background: #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.test__wrapper-nav-next:hover {
	opacity: 0.5;
}

.test__wrapper-nav-next-btn {
	padding: 19px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

@media (max-width: 800px) {
	.container-account {
		padding-top: 77px;
	}

	.test__wrapper-content {
		padding: 20px;
	}

	.test__wrapper-nav {
		padding: 20px;
	}
}

@media (max-width: 700px) {
	.test {
		max-width: 400px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	.test__caption {
		max-width: 100%;
		width: 100%;
		margin-top: 10px;
		order: 2;
		flex-direction: column;
		align-items: unset;
	}

	.test__caption-description {
		max-width: 100%;
		width: 100%;
	}

	.test__caption-description-title {
		font-size: 13px;
	}

	.test__caption-description-text {
		font-size: 14px;
	}

	.test__caption-cancel {
		margin: 20px 0 0 0;
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		box-sizing: border-box;
	}

	.test__caption-cancel-btn {
		font-size: 13px;
		padding: 13px 0;
	}

	.test__wrapper {
		margin-top: 0;
		border: none;
	}

	.test__wrapper-content {
		background: #fafafb;
	}

	.test__wrapper-nav {
		background: #fafafb;
	}

	.test__wrapper-content {
		padding: 0 0 40px 0;
	}

	.test__wrapper-content-caption-question {
		font-size: 13px;
	}

	.test__wrapper-content-caption-time {
		font-size: 13px;
	}

	.test__wrapper-content-text {
		margin-top: 10px;
		font-size: 13px;
	}

	.test__wrapper-content-answers {
		margin-top: 16px;
		margin-bottom: 85px;
	}

	.test__wrapper-content-answers-element:not(:last-child) {
		margin-bottom: 12px;
	}

	.test__wrapper-content-answers-label {
		padding: 10px 20px 10px 0;
		font-size: 11px;
		margin-left: 43px;
	}

	.test__wrapper-content-answers-input {
		margin-left: 14px;
		width: 16px;
		height: 16px;
	}

	.test__wrapper-nav {
		padding: 25px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	.test__wrapper-nav-number {
		display: none;
	}

	.test__wrapper-nav-last {
		max-width: 100%;
		margin-right: 20px;
		width: 100%;
		box-sizing: border-box;
	}

	.test__wrapper-nav-last-btn {
		font-size: 13px;
		padding: 13px 0;
	}

	.test__wrapper-nav-next {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}

	.test__wrapper-nav-next-btn {
		font-size: 13px;
		padding: 13px 0;
	}
}
</style>
