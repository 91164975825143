import { render, staticRenderFns } from "./ScienceWorksTable.vue?vue&type=template&id=c27f8574&scoped=true"
import script from "./ScienceWorksTable.vue?vue&type=script&lang=js"
export * from "./ScienceWorksTable.vue?vue&type=script&lang=js"
import style0 from "./ScienceWorksTable.vue?vue&type=style&index=0&id=c27f8574&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c27f8574",
  null
  
)

export default component.exports