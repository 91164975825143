import { render, staticRenderFns } from "./SchoolAtestateTable.vue?vue&type=template&id=6ce122aa&scoped=true"
import script from "./SchoolAtestateTable.vue?vue&type=script&lang=js"
export * from "./SchoolAtestateTable.vue?vue&type=script&lang=js"
import style0 from "./SchoolAtestateTable.vue?vue&type=style&index=0&id=6ce122aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce122aa",
  null
  
)

export default component.exports