<template>
  <div>
    <SocialHeader :role="$store.getters.role"></SocialHeader>

    <main class="main">
      <div class="account">
        <SocialMenu :role="$store.getters.role"></SocialMenu>
        <FrendNewMessage v-if="newMessage" :name="newMessageUserName" :id="newMessageUserId"
          @messageVisible="messageVisible"></FrendNewMessage>

        <div class="all container-account">
          <p class="subtitle mobile-block">{{ $t('pages.social.socialFriendsPage.friends') }}</p>
          <div class="title__wrapper">
            <h1 class="title">{{ $t('pages.social.socialFriendsPage.myFriends') }}</h1>
            <button class="friends-who mobile-block" @click="statusVizable = !statusVizable">
              <p class="friends-who-text">{{ $t('pages.social.socialFriendsPage.myFriends') }} <span
                  class="friends-who-img"></span></p>
              <div class="friends" v-if="statusVizable">
                <div class="friends__caption">
                  <a href="" class="friends__caption-btn friends__caption-btn-selected">{{
                    $t('pages.social.socialFriendsPage.myFriends') }}</a>
                  <router-link :to="{ name: 'outgoingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.myApplications') }}</router-link>
                  <router-link :to="{ name: 'incomingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.incomingApplications') }}
                    <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span>
                  </router-link>
                  <router-link :to="{ name: 'blockedUsers' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.blockedUsers') }}</router-link>
                </div>
                <div class="friends__best-container">
                  <button class="friends__best">
                    <span class="friends__best-icon"></span>
                    <span class="friends__best-text">{{ $t('pages.social.socialFriendsPage.favorites') }}</span>
                  </button>
                </div>
              </div>
            </button>
          </div>
          <nav class="nav">
            <div class="list">
              <button @click="changeFriendRole('all')" class="list__item"
                :class="{ 'list__item-selected': role === 'all' }">{{ $t('pages.social.socialFriendsPage.allFriends') }}</button>
              <button @click="changeFriendRole('candidate')" class="list__item"
                :class="{ 'list__item-selected': role === 'candidate' }">{{ $t('pages.social.socialFriendsPage.jobSeeker')
                }}</button>
              <button @click="changeFriendRole('employer')" class="list__item"
                :class="{ 'list__item-selected': role === 'employer' }">{{ $t('pages.social.socialFriendsPage.employers')
                }}</button>
            </div>
          </nav>
          <div class="possible mobile-block" v-if="false">
            <h2 class="possible__title">{{ $t('pages.social.socialFriendsPage.possibleFriends') }}</h2>
            <div class="possible__list">
              <PossibleFriends></PossibleFriends>
              <PossibleFriends></PossibleFriends>
              <PossibleFriends></PossibleFriends>
              <PossibleFriends></PossibleFriends>
            </div>
            <button class="possible__show">{{ $t('pages.social.socialFriendsPage.viewAll') }} <span
                class="possible__show-icon"></span></button>
          </div>
          <SurchBlue @find="findUsers" @default="changeRole(role)" :friends="true">
          </SurchBlue>
          <div class="filling">
            <div class="filling__people">
              <SeparateFriend v-for="(item, index) in myFriends" :is-friends="isFriends" :key="item.id" :friend="item"
                :index="index" @messageVisible="messageVisible">
              </SeparateFriend>
            </div>
            <div class="interaction">
              <div class="friends desctop-block">
                <div class="friends__caption">
                  <a href="" class="friends__caption-btn friends__caption-btn-selected">{{
                    $t('pages.social.socialFriendsPage.myFriends') }}</a>
                  <router-link :to="{ name: 'outgoingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.myApplications') }}</router-link>
                  <router-link :to="{ name: 'incomingFriend' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.incomingApplications') }}
                    <span class="friends-messages"
                      v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{ user.newFriendRequests
                      }}+</span>
                  </router-link>
                  <router-link :to="{ name: 'blockedUsers' }" class="friends__caption-btn">{{
                    $t('pages.social.socialFriendsPage.blockedUsers') }}</router-link>
                </div>
                <router-link :to="{ name: 'favorite-list' }">
                  <button class="friends__best">
                    <span class="friends__best-icon"></span>
                    <span class="friends__best-text">{{ $t('pages.social.socialFriendsPage.favorites') }}</span>
                  </button>
                </router-link>
              </div>
              <div class="possible desctop-block" v-if="false">
                <h2 class="possible__title">{{ $t('pages.social.socialFriendsPage.possibleFriends') }}</h2>
                <div class="possible__list">
                  <PossibleFriends></PossibleFriends>
                  <PossibleFriends></PossibleFriends>
                  <PossibleFriends></PossibleFriends>
                  <PossibleFriends></PossibleFriends>
                </div>
                <button class="possible__show">{{ $t('pages.social.socialFriendsPage.viewAll') }} <span
                    class="possible__show-icon"></span></button>
              </div>
            </div>
          </div>
          <button class="show-more" v-if="false">{{ $t('pages.social.socialFriendsPage.seeAll') }}</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparateFriend from "../../components/social/account_friensds/SeparateFriend.vue";
import SurchBlue from "../../components/social/account_friensds/SurchBlue.vue";
import PossibleFriends from "../../components/social/account_friensds/PossibleFriends.vue";
import FrendNewMessage from "@/components/social/account_friensds/FrendNewMessage";

export default {
  components: { FrendNewMessage, SocialMenu, SocialHeader, SeparateFriend, SurchBlue, PossibleFriends },
  name: "SocialFriendsPage",
  computed: {
    myFriends() {
      return this.$store.getters.getMyFriends;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  data() {
    return {
      statusVizable: false,
      role: null,
      newMessage: false,
      newMessageUserName: null,
      newMessageUserId: null,
      searchParams: null,
      isFriends: true
    };
  },
  beforeMount() {
    this.changeFriendRole('all');
    // TODO: add pagination
    this.$store.dispatch('fetchFavoriteFriends', {
      token: this.$store.getters.token,
      page: this.$store.getters.friendPage,
      role: this.role,
      limit: this.$store.getters.getLimit,
      callback: this.deletePagination
    });
    this.$store.dispatch('fetchMyBlockedFriends', {
      token: this.$store.getters.token,
      page: this.$store.getters.friendPage,
      role: this.role,
      limit: this.$store.getters.getLimit,
      callback: this.deletePagination
    });
  },
  methods: {
    paginationLoad() {
      window.onscroll = () => {
        let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight + 10) >= document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.addData();
        }
      };
    },
    findUsers(param) {
      this.searchParams = param;
      this.$store.commit('resetPages');
      this.isFriends = false;
      this.paginationLoad();
      this.$store.dispatch('findUser', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.searchParams.profession || this.searchParams.city ? 'candidate' : this.role,
        limit: this.$store.getters.getLimit,
        name: this.searchParams.name ? this.searchParams.name : '',
        sort: this.searchParams.sort ? this.searchParams.sort : 'default',
        city: this.searchParams.city ? this.searchParams.city : '',
        profession: this.searchParams.profession ? this.searchParams.profession : '',
        minrate: this.searchParams.minrate ? this.searchParams.minrate : '',
        maxrate: this.searchParams.maxrate ? this.searchParams.maxrate : '',
        callback: this.deletePagination
      });
    },
    changeRole(value) {
      this.find = false;
      this.role = value;
      this.$store.commit('resetPages');
      this.paginationLoad();
      this.isFriends = true;
      this.$store.dispatch('fetchMyFriends', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.role,
        limit: this.$store.getters.getLimit,
        callback: this.deletePagination
      });
    },
    messageVisible(payload) {
      this.newMessage = !this.newMessage;
      if (payload) {
        this.newMessageUserId = payload.id;
        this.newMessageUserName = payload.name;
      }
    },
    changeFriendRole(value) {
      this.role = value;
      this.searchParams = null;
      this.paginationLoad();
      this.$store.commit('resetPages');
      this.isFriends = true;
      this.$store.dispatch('fetchMyFriends', {
        token: this.$store.getters.token,
        page: this.$store.getters.friendPage,
        role: this.role,
        limit: this.$store.getters.getLimit,
        callback: this.deletePagination
      });
    },
    addData() {
      this.$store.commit('pageUp');
      if (!this.searchParams) {
        this.isFriends = true;
        this.$store.dispatch('fetchMyFriends', {
          token: this.$store.getters.token,
          page: this.$store.getters.friendPage,
          role: this.role,
          limit: this.$store.getters.getLimit,
          callback: this.deletePagination
        });
      } else {
        this.isFriends = false;
        this.$store.dispatch('findUser', {
          token: this.$store.getters.token,
          page: this.$store.getters.friendPage,
          role: this.role,
          limit: this.$store.getters.getLimit,
          name: this.searchParams.name ? this.searchParams.name : '',
          sort: this.searchParams.sort ? this.searchParams.sort : 'default',
          minrate: this.searchParams.minrate ? this.searchParams.minrate : '',
          maxrate: this.searchParams.maxrate ? this.searchParams.maxrate : '',
          callback: this.deletePagination
        });
      }
    },
    deletePagination() {
      window.onscroll = null;
    }
  }
};
</script>
<style scoped>
.friends-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}


.account {
  display: flex;
}

.container-account {
  min-height: 120vh;
}

.subtitle {
  display: none;
}

.title {
  font-weight: 700;
  font-size: 26px;
  color: #3b4c68;
}

.nav {
  margin-top: 40px;
}

.list__item:not(:last-child) {
  margin-right: 40px;
}

.list__item {
  position: relative;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4c68;
}

.list__item.list__item-selected::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background: #009ed1;
}

.filling {
  margin-top: 40px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
}

.filling__people {
  width: 100%;
  max-width: 100%;
}

.interaction {
  max-width: 388px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 30px;
}

.friends {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.friends__caption {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.friends__caption-btn {
  margin-top: 10px;
  padding: 5px 0 5px 22px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #44444f;

}

.friends__caption-btn.friends__caption-btn-selected {
  display: block;
  background: rgba(239, 239, 244, 0.5);
  position: relative;
}

.friends__caption-btn.friends__caption-btn-selected::before {
  position: absolute;
  content: "";
  left: -1px;
  border-radius: 0px 20px 20px 0;
  width: 2px;
  height: 100%;
  display: block;
  background: #009ED1;
  top: 0;
}

.friends__best-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.friends__best {
  display: flex;
  width: 100%;
  padding: 18px 6px 13px 6px;
  border-top: 1px solid #efeff4;
  box-sizing: border-box;
  align-items: center;

}

.friends__best-text {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #44444f;
  margin: 2px 0 0 3px;
}

.friends__best-icon {
  background: url("~@/assets/img/all-img/favorites-small.svg");
  width: 24px;
  height: 24px;
  display: block;
}

.possible {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  padding: 0 12px;
}

.possible__title {
  padding-top: 20px;
  padding-left: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
}

.possible__list {
  padding-top: 10px;
  width: 100%;
}

.possible__show {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #44444f;
  padding: 15px 0;
}

.possible__show-icon {
  width: 9px;
  height: 9px;
  margin-left: 4px;
  display: flex;
  background-image: url("~@/assets/img/account-img/friends/list.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: 3px;
}

.mobile-block {
  display: none;
}

.show-more {
  display: none;
}

@media (max-width: 1575px) {
  .filling__people {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .filling__people {
    margin-bottom: 0;
  }
}

@media (max-width: 1100px) {
  .filling__people {
    grid-template-columns: repeat(1, 1fr);
  }
}


@media (max-width: 720px) {
  .form {
    margin-top: 40px;
  }

  .possible__title {
    padding-left: 0;
  }

  .nav {
    margin-top: 20px;
  }


  .filling {
    margin-top: 20px;
  }

  .desctop-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .title {

    font-size: 24px;
    line-height: 29px;
  }

  .title__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .friends-who {
    margin-left: auto;
    text-align: left;
    padding: 10px 0 10px 10px;
    position: relative;
  }

  .friends-who-text {
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #3e4453;
    position: relative;
    align-items: center;
    display: flex;
  }

  .friends-who-text::before {
    position: absolute;
    left: -6px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #14c686;
    display: block;
    content: "";
    top: calc(50% - 2px);
  }

  .friends-who-img {
    display: block;
    background-image: url("~@/assets/img/account-img/friends/list-blue.svg");
    background-repeat: no-repeat;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    background-position-y: 1px;
  }

  .subtitle {
    display: block;
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #009ed1;
  }

  .all {
    padding: 76px 10px 30px 10px;
    max-width: 420px;
  }

  .friends {
    position: absolute;
    width: 300px;
    right: 0px;
    z-index: 30;
    top: 44px;
  }


  .interaction {
    display: none;
  }

  .show-more {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid #009ed1;
    border-radius: 7px;
    font-family: "SFUIDisplay";
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #009ed1;
  }


  .list {
    display: flex;
    flex-direction: column;
    padding: 9px 0;
    background: #ffffff;
    width: 100%;
    border: 1px solid #efeff4;
    border-radius: 10px;
  }

  .list__item {
    text-align: left;
    padding: 5px 0 5px 22px;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    color: #44444f;
  }

  .list__item-selected {
    display: block;
    background: rgba(239, 239, 244, 0.5);
    position: relative;
  }

  .list__item.list__item-selected::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-radius: 0px 20px 20px 0;
    content: "";
    display: block;
    background: #009ed1;
  }

  .list__item:not(:last-child) {
    margin-right: 0;
  }


}
</style>
