<template>
  <div class="answer"
       :class="{'answer-green':correctAnswer,'answer-red':userAnswer&&!correctAnswer}"

  >
    <div :class="{
     'answer__circle':questionType==='single',
    'answer__box':questionType==='multi',
    'input-green':userAnswer&&correctAnswer,
    'input-red':userAnswer&&!correctAnswer}"></div>
    <p class="answer__text">{{ answer.body }}</p>
  </div>
</template>

<script>
export default {
  name: "TestExaminationSeaprateAnswer",
  props: {
    answer: {},
    questionType: null,
    correctAnswer: null,
    userAnswer: null
  },

};
</script>

<style scoped>
.answer {
  margin-top: 20px;
  display: flex;
  padding: 15px 44px 15px 24px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #f5f8f9;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  align-items: center;
}

.answer__circle {
  max-width: 21px;
  width: 100%;
  height: 21px;
  border: 2px solid rgba(59, 76, 104, 0.5);
  border-radius: 100%;
}

.answer__box {
  max-width: 21px;
  width: 100%;
  height: 21px;
  border: 2px solid rgba(59, 76, 104, 0.5);
  border-radius: 6px;
}

.answer__text {
  margin-left: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.78;
  color: #3b4c68;
}

@media (max-width: 700px) {
  .answer {
    margin-top: 13px;
    padding: 10px 15px;
  }

  .answer__circle {
    max-width: 12px;
    height: 12px;
  }

  .answer__box {
    max-width: 12px;
    height: 12px;
  }

  .answer__text {
    margin-left: 13px;
    line-height: 1.67;
    font-size: 11px;
  }
}

/* Modificators */
.answer-red {
  background: #faf6f6;
  border: 1px solid #ab3e5f;
}

.answer-green {
  background: #eefff5;
  border: 1px solid #14c686;
}

.input-green {
  background: #14c686;
  border: 2px solid #14c686;
}

.input-red {
  background: #ab3e5e;
  border: 2px solid #ab3e5e;
}

/* /Modificators */
</style>