<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="comtainer-account">
				<div class="account">
					<MenuCompany></MenuCompany>

					<div class="account-filling">
						<div class="account-filling-caption">
							<div class="account-filling-caption-description">
								<p class="account-filling-caption-description-text filling-caption-mobile">
									{{ $t('pages.tests.test_company.testCandidateExaminationPage.checkingCandidates') }}
								</p>
								<h2 class="account-filling-caption-description-title">
									{{ $t('pages.tests.test_company.testCandidateExaminationPage.testing') }}
								</h2>
								<p class="account-filling-caption-description-text filling-caption-desctop">
									{{ $t('pages.tests.test_company.testCandidateExaminationPage.checkingCandidates') }}
								</p>
							</div>
							<div class="account-filling-caption-wrapper">
								<button class="account-filling-caption-wrapper-back">
									<img src="~@/assets/img/test-img/constractor/back.svg" alt="back"
										class="account-filling-caption-wrapper-back-img" />
								</button>
								<div class="account-filling-caption-wrapper-container">
									<div class="account-filling-caption-wrapper-date">
										<p class="account-filling-caption-wrapper-date-text">
											{{ $t('pages.tests.test_company.testCandidateExaminationPage.testDate') }}:
										</p>
										<span class="account-filling-caption-wrapper-date-when">
											{{ new Date(dataPassing.passingDate).toISOString().slice(0, 10) }}
										</span>
									</div>
									<div class="account-filling-caption-wrapper-time">
										<p class="account-filling-caption-wrapper-time-text">
											{{ $t('pages.tests.test_company.testCandidateExaminationPage.testTime') }}:
										</p>
										<time class="account-filling-caption-wrapper-time-when">
											{{ parseTime(dataPassing.timePassingSeconds) }}
										</time>
									</div>
									<div class="account-filling-caption-wrapper-profile">
										<img :src="dataPassing.candidateAvatarUrl" alt="Profile"
											class="account-filling-caption-wrapper-profile-face" />
										<div class="account-filling-caption-wrapper-profile-about">
											<div class="account-filling-caption-wrapper-profile-about-rating">
												<p class="account-filling-caption-wrapper-profile-about-rating-number">
													{{ dataPassing.candidateRate }}
												</p>
											</div>
											<p class="account-filling-caption-wrapper-profile-about-name">
												{{ dataPassing.candidateName }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<TestExaminationWrapper v-for="(item, index) in dataPassing.passes" :key="item.id"
							:passes="item" :count="index" :change="change()"></TestExaminationWrapper>
						<div class="account-filling-caption-end">
							<button class="button-filling-caption-end-confirm" @click="close">
								<span v-if="change()">{{
									$t('pages.tests.test_company.testCandidateExaminationPage.confirmCheck') }}</span>
								<span v-else>{{ $t('pages.tests.test_company.testCandidateExaminationPage.finishView')
									}}</span>
							</button>
							<button class="button-filling-caption-end-cancel" v-if="change()" @click="$router.go(-1)">
								{{ $t('pages.tests.test_company.testCandidateExaminationPage.cancel') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import TestExaminationWrapper from "../../../components/tests/test_constructor_elements/wrapper_component/TestExaminationWrapper.vue";
import { errorHandler } from "../../../lib/errorHandler";

export default {
	computed: {
		getPassing() {
			return this.$store.getters.getPassing;
		},
		getSavePassing() {
			return this.$store.getters.getSavePassing;
		},
	},
	components: {
		MenuCompany,
		HeaderAccountCompany,
		TestExaminationWrapper,
	},
	props: {
		passingId: {},
		status: {},
	},
	data() {
		return {
			dataPassing: {},
			dataInterview: {},
		};
	},
	name: "TestCandidateExaminationPage",
	beforeMount() {
		scroll(0, 0);
		this.$store.dispatch("fetchPassing", {
			passingId: this.passingId,
			token: this.$store.getters.token,
		});

	},
	methods: {
		change() {
			return this.$route.path.indexOf("view") === -1;
		},
		parseTime(time) {
			let hours = Math.floor(time / 60 / 60);
			let minutes = Math.floor(time / 60) % 60;
			let seconds = Math.floor(time) % 60;
			if (hours === 0) return minutes + ":" + seconds;
			else return hours + ":" + minutes + ":" + seconds;
		},
		close() {
			if (
				this.getPassing.passes.length === this.getSavePassing.passes.length ||
				!this.change()
			) {
				if (this.change())
					this.$store.dispatch("saveExamination", this.$store.getters.token);
				this.$router.go(-1);
			} else {
				errorHandler({ status: 400, page: "notAllData" });
				scroll(0, 0);
			}
		},
	},
	mounted() {
		this.dataPassing = this.getPassing;
		this.$store.commit("setSavePassingId", this.getPassing.id);
	},
};
</script>



<style scoped>
.account {
	display: flex;
}

.account-filling {
	padding: 96px 30px 77px 30px;
	max-width: 1518px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
}

.account-filling-end {
	margin-top: 30px;
	border-top: 2px solid #e8e8e8;
	width: 100%;
	box-sizing: border-box;
	padding-top: 102px;
	position: relative;
}

.account-filling-end-add {
	position: absolute;
	top: -33px;
	left: calc(50% - 33px);
	transition: opacity 1s ease;
}

.account-filling-end-add:hover {
	opacity: 0.5;
}

.account-filling-end-add-img {
	padding: 19px;
	background: #009ed1;
	border-radius: 16px;
}

.account-filling-end-finish {
	display: flex;
	justify-content: center;
}

.account-filling-end-finish-btn {
	padding: 23px 0;
	width: 273px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.account-filling-end-finish-btn:hover {
	opacity: 0.5;
}

.account-filling-end-cancel {
	display: none;
}

@media (max-width: 700px) {
	.account-filling {
		padding: 76px 10px 35px 10px;
	}

	.account-filling-end {
		padding-top: 54px;
		margin-top: 38px;
	}

	.account-filling-end-add {
		top: -23px;
		left: calc(50% - 22px);
	}

	.account-filling-end-add-img {
		padding: 13px;
		border-radius: 16px;
		width: 18px;
		height: 18px;
	}

	.account-filling-end-finish-btn {
		padding: 13px 0;
		font-size: 13px;
		width: 300px;
		border-radius: 10px;
	}

	.account-filling-end-cancel {
		margin-top: 11px;
		display: flex;
		justify-content: center;
	}

	.account-filling-end-cancel-btn {
		width: 133px;
		padding: 13px 0;
		background: #f1f1f5;
		border-radius: 10px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 13px;
		color: #696974;
		transition: opacity 1s ease;
	}

	.account-filling-end-cancel-btn:hover {
		opacity: 0.5;
	}
}

/* Caption */
.filling-caption-mobile {
	display: none;
}

.account-filling-caption {
	padding-bottom: 40px;
}

.account-filling-caption-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.account-filling-caption-description-text {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.account-filling-caption-wrapper {
	position: relative;
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.account-filling-caption-wrapper-container {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.account-filling-caption-wrapper-back {
	position: absolute;
	top: 0px;
	left: -19px;
}

.account-filling-caption-wrapper-back-img {
	padding: 20px;
}

.account-filling-caption-wrapper-date-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
}

.account-filling-caption-wrapper-date-when {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.account-filling-caption-wrapper-time {
	margin-left: 25px;
}

.account-filling-caption-wrapper-time-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
}

.account-filling-caption-wrapper-time-when {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.account-filling-caption-wrapper-profile {
	align-items: center;
	margin-left: 40px;
	display: flex;
}

.account-filling-caption-wrapper-profile-face {
	width: 64px;
	height: 64px;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
}

.account-filling-caption-wrapper-profile-about {
	margin-left: 20px;
}

.account-filling-caption-wrapper-profile-about-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	max-width: 58px;
}

.account-filling-caption-wrapper-profile-about-rating-number {
	padding: 2px 5px 2px 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
	background: url("~@/assets/img/test-img/constractor/star-green.svg");
	background-repeat: no-repeat;
	background-position: 9px;
}

.account-filling-caption-wrapper-profile-about-name {
	padding-top: 1px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

@media (max-width: 700px) {
	.filling-caption-desctop {
		display: none;
	}

	.filling-caption-mobile {
		display: block;
	}

	.account-filling-caption-description-text {
		color: #009ed1;
		font-size: 13px;
	}

	.account-filling-caption-description-title {
		color: #000000;
		font-size: 20px;
	}

	.account-filling-caption-wrapper-container {
		margin: 0 auto;
		align-items: unset;
		padding: 20px;
		flex-direction: column;
		background: #ffffff;
		border: 1px solid #efeff4;
		border-radius: 10px;
	}

	.account-filling-caption-wrapper-profile {
		padding-bottom: 22px;
		order: 1;
		margin-left: 0;
	}

	.account-filling-caption-wrapper-profile-about {
		display: flex;
		flex-direction: column;
	}

	.account-filling-caption-wrapper-profile-about-name {
		padding-top: 0;
	}

	.account-filling-caption-wrapper-profile-about-rating {
		order: 2;
	}

	.account-filling-caption-wrapper-date {
		align-items: center;
		display: flex;
		padding-top: 18px;
		border-top: 1px solid #f1f1f5;
		order: 2;
	}

	.account-filling-caption-wrapper-date-when {
		margin-left: 4px;
	}

	.account-filling-caption-wrapper-time {
		align-items: center;
		display: flex;
		padding-top: 10px;
		margin-left: 0;
		order: 3;
	}

	.account-filling-caption-wrapper-time-when {
		margin-left: 4px;
	}

	.account-filling-caption-wrapper-back {
		top: -84px;
		left: -4px;
	}

	.account-filling-caption {
		padding-bottom: 16px;
		text-align: center;
	}
}

/* /Caption */

/* End */

.account-filling-caption-end {
	display: flex;
	justify-content: center;
}

.button-filling-caption-end-confirm {
	width: 272px;
	padding: 23px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #ffffff;
	background: #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.button-filling-caption-end-confirm:hover {
	opacity: 0.5;
}

.button-filling-caption-end-cancel {
	width: 207px;
	margin-left: 20px;
	padding: 23px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	background: #f1f1f5;
	border-radius: 7px;
	color: #696974;
	transition: opacity 1s ease;
}

.button-filling-caption-end-cancel:hover {
	opacity: 0.5;
}

@media (max-width: 700px) {
	.account-filling-caption-end {
		margin: 0 auto;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
		padding-top: 27px;
		display: flex;
		flex-direction: column;
	}

	.button-filling-caption-end-confirm {
		margin: 0 auto;
		padding: 13px 0;
		font-size: 13px;
	}

	.button-filling-caption-end-cancel {
		margin: 13px auto 0 auto;
		padding: 13px 0;
		font-size: 13px;
	}
}
</style>
