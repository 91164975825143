<template>
  <div>
    <HeaderAccountCompany></HeaderAccountCompany>

    <main class="main">
      <div class="account">
        <MenuCompany></MenuCompany>
        <div class="container-account">
          <div class="account__caption">
            <p class="account__caption-text mobile">
              {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.personalCabinet') }}
            </p>
            <h2 class="account__caption-title">
              {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.creationOfVacancies') }}
            </h2>
            <p class="account__caption-text desctop">
              {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.personalCabinet') }}
            </p>
          </div>
          <div class="account__info">
            <div class="account__info-filling">
              <div class="account__info-caption">
                <p class="account__info-caption-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.jobTitle') }}
                </p>
                <input type="text"
                  :placeholder="$t('pages.account.account_company.accountCompanyCreationVacancyPage.jobTitle')"
                  class="account__info-caption-input" v-model="dataVacancy.vacancyName" />
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.schedule') }}
                </p>
                <div class="search__sorting-select-container">
                  <button ref="select" class="search__sorting-select" @click="graphVisible = !graphVisible"
                    @blur="blurGraph">
                    {{
                      dataVacancy.employType === "0"
                        ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.fullEmployment')
                        : dataVacancy.employType === "1"
                          ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.partTimeEmployment')
                          : dataVacancy.employType === "2"
                            ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.projectWork')
                            : dataVacancy.employType === "3"
                              ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.internship')
                              : dataVacancy.employType === "4"
                                ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.volunteering')
                                : dataVacancy.employType === "5"
                                  ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.distantWork')
                                  : $t('pages.account.account_company.accountCompanyCreationVacancyPage.schedule')
                    }}
                  </button>
                  <div class="search__sorting-select-group" v-if="graphVisible">
                    <input type="radio" id="oneEmpl" value="0" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="oneEmpl" class="search__sorting-select-element-label first-select-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.fullEmployment') }}</label>
                    <input type="radio" id="twoEmpl" value="1" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="twoEmpl" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.partTimeEmployment')
                      }}</label>
                    <input type="radio" id="threeEmpl" value="2" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="threeEmpl" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.projectWork') }}</label>
                    <input type="radio" id="fourEmpl" value="3" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="fourEmpl" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.internship') }}</label>
                    <input type="radio" id="fiveEmpl" value="4" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="fiveEmpl" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.volunteering') }}</label>
                    <input type="radio" id="sixEmpl" value="5" class="search__sorting-select-element-input"
                      v-model="dataVacancy.employType" @change="graphVisible = !graphVisible" />
                    <label for="sixEmpl" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.distantWork') }}</label>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.experience') }}
                </p>
                <div class="search__sorting-select-container">
                  <button ref="select" class="search__sorting-select" @click="expirienceVisible = !expirienceVisible"
                    @blur="blurExp">
                    {{
                      dataVacancy.workExperience === "0"
                        ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.over10Years')
                        : dataVacancy.workExperience === "1"
                          ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.5to10Years')
                          : dataVacancy.workExperience === "2"
                            ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.3to5Years')
                            : dataVacancy.workExperience === "3"
                              ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.1to3Years')
                              : dataVacancy.workExperience === "4"
                                ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.lessThan1Year')
                                : dataVacancy.workExperience === "5"
                                  ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.noExperience')
                                  : $t('pages.account.account_company.accountCompanyCreationVacancyPage.experience')
                    }}
                  </button>
                  <div class="search__sorting-select-group" v-if="expirienceVisible">
                    <input type="radio" id="oneExp" value="0" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="oneExp" class="search__sorting-select-element-label first-select-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.over10Years') }}</label>
                    <input type="radio" id="twoExp" value="1" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="twoExp" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.5to10Years') }}</label>
                    <input type="radio" id="threeExp" value="2" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="threeExp" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.3to5Years') }}</label>
                    <input type="radio" id="fourExp" value="3" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="fourExp" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.1to3Years') }}</label>
                    <input type="radio" id="fiveExp" value="4" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="fiveExp" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.lessThan1Year') }}</label>
                    <input type="radio" id="sixExp" value="5" class="search__sorting-select-element-input"
                      v-model="dataVacancy.workExperience" @change="expirienceVisible = !expirienceVisible" />
                    <label for="sixExp" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.noExperience') }}</label>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.education') }}
                </p>
                <div class="search__sorting-select-container">
                  <button ref="select" class="search__sorting-select" @click="educationVisible = !educationVisible"
                    @blur="blurEdu">
                    {{
                      dataVacancy.education === "0"
                        ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.academicDegree')
                        : dataVacancy.education === "1"
                          ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.higher')
                          : dataVacancy.education === "2"
                            ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.secondaryVocational')
                            : dataVacancy.education === "3"
                              ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.averageTotal')
                              : dataVacancy.education === "4"
                                ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.basicGeneral')
                                : dataVacancy.education === "5"
                                  ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.irrelevant')
                                  : $t('pages.account.account_company.accountCompanyCreationVacancyPage.education')
                    }}
                  </button>
                  <div class="search__sorting-select-group" v-if="educationVisible">
                    <input type="radio" id="oneEdu" value="0" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="oneEdu" class="search__sorting-select-element-label first-select-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.academicDegree') }}</label>
                    <input type="radio" id="twoEdu" value="1" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="twoEdu" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.higher') }}</label>
                    <input type="radio" id="threeEdu" value="2" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="threeEdu" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.secondaryVocational')
                      }}</label>
                    <input type="radio" id="fourEdu" value="3" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="fourEdu" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.averageTotal') }}</label>
                    <input type="radio" id="fiveEdu" value="4" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="fiveEdu" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.basicGeneral') }}</label>
                    <input type="radio" id="sixEdu" value="5" class="search__sorting-select-element-input"
                      v-model="dataVacancy.education" @change="educationVisible = !educationVisible" />
                    <label for="sixEdu" class="search__sorting-select-element-label">{{
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.irrelevant') }}</label>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.wage') }}
                </p>
                <input type="text" class="account__info-filling-element-input"
                  :placeholder="$t('pages.account.account_company.accountCompanyCreationVacancyPage.wage')"
                  v-model="dataVacancy.payFrom" v-on:blur="checkNumber" />
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.currency') }}
                </p>
                <div class="search-wrapper">
                  <button class="test__top-bottom-town" @click="viewCurrencies = !viewCurrencies">
                    {{ currencyName ? currencyName :
                      $t('pages.account.account_company.accountCompanyCreationVacancyPage.currency') }}
                    <span class="test__top-bottom-town-img"></span>
                  </button>
                  <div class="search-list" v-if="viewCurrencies">
                    <button class="search-list__element" v-for="item in currencies"
                      @click="dataVacancy.expectedSalaryCurrency = item.value; viewCurrencies = false" :key="item.value"
                      :value="item.value">
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.city') }}
                </p>
                <div class="search-wrapper">
                  <button class="test__top-bottom-town" @click="cityVisible = !cityVisible">
                    {{ cityName ? cityName : $t('pages.account.account_company.accountCompanyCreationVacancyPage.city')
                    }}
                    <span class="test__top-bottom-town-img"></span>
                  </button>
                  <div class="search-list" v-if="cityVisible">
                    <input
                      :placeholder="$t('pages.account.account_company.accountCompanyCreationVacancyPage.city')"
                      @input="(e) => searchCity(e.target.value)" type="text"
                      class="search-list__element search-list__element-input" />
                    <button class="search-list__element" v-for="item in dataCities" @click="pickCity" :key="item.id"
                      :value="item.id">
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.speciality') }}
                </p>
                <div class="search__sorting-select-container">
                  <button ref="select" class="search__sorting-select" @click="specialityVisible = !specialityVisible">
                    {{
                      specializationName
                    }}
                  </button>
                  <!-- <div
                      class="search__sorting-select-speciality-group"
                      v-if="specialityVisible"
                  >
                    <div
                        class="search__sorting-select-speciality-group-element"
                        v-for="item in specializations"
                        :key="item.id"
                    >
                      <input
                          type="radio"
                          :id="item.id"
                          :value="item.id"
                          class="search__sorting-select-speciality-group-element-input"
                          v-model="dataVacancy.specialization"
                          @change="changeSelect(item.name)"
                      />
                      <label
                          :for="item.id"
                          class="search__sorting-select-speciality-group-element-label"
                      >{{ item.name }}</label>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.keySkills') }}
                </p>
                <div class="search__sorting-select-container">
                  <button ref="select" class="search__sorting-select" @click="keySkillsVisible = !keySkillsVisible">
                    {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.keySkills') }}
                  </button>
                  <div class="search__sorting-select-speciality-group" v-if="keySkillsVisible">
                    <div class="search__sorting-select-speciality-group-element checkbox-select-container"
                      v-for="(item, index) in keySkills" :key="item.id">
                      <input type="checkbox" class="work__filters-element-filter-checkbox select-input" :id="item.id"
                        :value="index" v-model="dataVacancy.vacancyKeySkills" />
                      <label class="select-input-text" :for="item.id">{{
                        item
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account__info-filling-element">
                <p class="account__info-filling-element-description">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.minimumScore') }}
                </p>
                <input type="text" class="account__info-filling-element-input"
                  :placeholder="$t('pages.account.account_company.accountCompanyCreationVacancyPage.minimumScore')"
                  v-model="dataVacancy.score" v-on:blur="checkNumber" />
              </div>
            </div>
          </div>
          <div class="account__filling">
            <div class="account__filling-preview">
              <p class="account__filling-preview-description">
                {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.preview') }}
              </p>
              <div class="account__filling-preview-picture">
                <img v-if="dataVacancy.previewImgUrl !== null" :src="dataVacancy.previewImgUrl" alt="Company photo"
                  class="account__filling-preview-picture-img" />
                <input type="file" ref="file" style="display: none" v-on:change="changeFile" />
                <button class="account__filling-preview-picture-btn" @click="$refs.file.click()">
                  {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.downloadPhoto') }}
                </button>
              </div>
            </div>
            <div class="account__filling-description">
              <p class="account__filling-description-name">
                {{ $t('pages.account.account_company.accountCompanyCreationVacancyPage.vacancyDescription') }}
              </p>
              <textarea class="account__filling-description-textarea"
                :placeholder="$t('pages.account.account_company.accountCompanyCreationVacancyPage.enterDescription')"
                v-model="dataVacancy.description"></textarea>
            </div>
          </div>
          <div class="account__add" @click="saveVacancy">
            <button class="account__add-btn">
              {{ vacancyId != null ? $t('pages.account.account_company.accountCompanyCreationVacancyPage.editAndSave') :
                $t('pages.account.account_company.accountCompanyCreationVacancyPage.createJob') }}
            </button>
          </div>
        </div>
      </div>
    </main>
    <SpecialitySelect @pickSpecialization="pickSpecialization" @closeWindow="specialityVisible = false"
      :specialisations="specializations" :current-specialisation="dataVacancy.specialization"
      :industries="dataIndustries" v-if="specialityVisible">
    </SpecialitySelect>
  </div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany";
import { errorNotification, successNotification } from "@/lib/notification";
import SpecialitySelect from "@/components/account/account_worker/modals/item/SpecialitySelect";
import { config } from "@/lib/config";
import { logoutWithToken } from "@/lib/logoutWithToken";
import { mapGetters } from "vuex";
import { values } from "@/lib/values";

export default {
  computed: {
    ...mapGetters(["dataCities", "dataIndustries"]),
    currencyName() {
      const index = this.currencies.map(item => item.value).indexOf(this.dataVacancy.expectedSalaryCurrency);
      return index > -1 ? this.currencies[index].name : null;
    },
    specializationName() {
      if (!this.dataVacancy?.specialization) {
        return this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.speciality');
      }
      const index = this.specializations?.map(item => item.id).indexOf(this.dataVacancy?.specialization);
      return index > -1 ? this.specializations[index].name : this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.speciality');
    }
  },
  components: { HeaderAccountCompany, MenuCompany, SpecialitySelect },
  name: "AccountCompanyCreationVacancyPage",
  props: ["vacancyId"],
  data() {
    return {
      cityVisible: false,
      cityName: null,
      timer: null,
      nameSpecializations: null,
      specializations: null,
      file: null,
      graphVisible: false,
      expirienceVisible: false,
      educationVisible: false,
      specialityVisible: false,
      keySkillsVisible: false,
      fileLoad: false,
      currencies: values,
      viewCurrencies: false,
      keySkills: null,
      dataVacancy: {
        vacancyName: null,
        employType: null,
        workExperience: null,
        payFrom: null,
        previewImgUrl: null,
        description: null,
        city: null,
        education: null,
        score: null,
        specialization: null,
        expectedSalaryCurrency: null,
        vacancyKeySkills: [],
      },
    };
  },
  methods: {
    blurGraph() {
      setTimeout(() => {
        this.graphVisible = false;
      }, 300);
    },
    blurExp() {
      setTimeout(() => {
        this.expirienceVisible = false;
      }, 300);
    },
    blurEdu() {
      setTimeout(() => {
        this.educationVisible = false;
      }, 300);
    },
    changeSelect(name) {
      this.nameSpecializations = name;
      this.specialityVisible = !this.specialityVisible;
    },
    changeFile() {
      this.file = this.$refs.file.files[this.$refs.file.files.length - 1];
      this.dataVacancy.previewImgUrl = URL.createObjectURL(this.file);
      this.fileLoad = true;
    },
    checkNumber() {
      if (isNaN(Number(this.dataVacancy.payFrom))) {
        this.dataVacancy.payFrom = null;
        errorNotification(this, this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.invalidNumericValue'));
      }
      if (isNaN(Number(this.dataVacancy.score))) {
        this.dataVacancy.score = null;
        errorNotification(this, this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.invalidNumericValue'));
      }
    },
    saveVacancy() {
      if (this.vacancyId == null) {
        if (!this.file) {
          errorNotification(this, this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.selectJobPreview'));
        }
        for (let prop in this.dataVacancy) {
          if (this.dataVacancy[prop] === null) {
            errorNotification(this, this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.notAllDataComplete'));
            return;
          }
        }
        let formData = new FormData();
        formData.append("file", this.file);
        let xmlHttp = new XMLHttpRequest();
        let url = config.apiPrefix + "file/upload";
        xmlHttp.open("POST", url, false);
        try {
          xmlHttp.send(formData);
          logoutWithToken(xmlHttp.status, this.$store);
          let response = JSON.parse(xmlHttp.response);
          this.dataVacancy.previewImgUrl = response.data.url;
        } catch (e) {
          console.log(e);
        }
        xmlHttp = new XMLHttpRequest();
        url = config.apiPrefix + "employer/vacancy/create";
        xmlHttp.open("POST", url, false);
        xmlHttp.setRequestHeader(
          "Content-Type",
          "application/json;charset=UTF-8"
        );
        xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
        try {
          xmlHttp.send(JSON.stringify(this.dataVacancy));
        } catch (e) {
          console.log(e);
        }
        successNotification(this, this.$t('pages.account.account_company.accountCompanyCreationVacancyPage.vacancyCreated'));
      } else {
        if (this.fileLoad) {
          let formData = new FormData();
          formData.append("file", this.file);
          let xmlHttp = new XMLHttpRequest();
          let url = config.apiPrefix + "file/upload";
          xmlHttp.open("POST", url, false);
          try {
            xmlHttp.send(formData);
            logoutWithToken(xmlHttp.status, this.$store);
            let response = JSON.parse(xmlHttp.response);
            this.dataVacancy.previewImgUrl = response.data.url;
          } catch (e) {
            console.log(e);
          }
        }
        this.dataVacancy["id"] = this.vacancyId;
        this.dataVacancy["name"] = this.dataVacancy.vacancyName;
        delete this.dataVacancy.vacancyName;
        let xmlHttp = new XMLHttpRequest();
        let url = config.apiPrefix + "employer/vacancy/update";
        xmlHttp.open("POST", url, false);
        xmlHttp.setRequestHeader(
          "Content-Type",
          "application/json;charset=UTF-8"
        );
        xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
        try {
          xmlHttp.send(JSON.stringify(this.dataVacancy));
          logoutWithToken(xmlHttp.status, this.$store);
          let response = JSON.parse(xmlHttp.response);
          console.log(JSON.stringify(response));
        } catch (e) {
          console.log(e);
        }
      }
      this.$router.push({ name: "vacancyCompany" });
    },
    getKeySkills() {
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + "candidate/skills/all?lang=en";
      request.open("GET", url, false);
      try {
        request.send();
        let response = JSON.parse(request.response);
        this.keySkills = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    fetchVacancy() {
      const req = new XMLHttpRequest();
      req.open(
        "GET",
        config.apiPrefix + "employer/vacancy/get/" + this.vacancyId,
        false
      );
      req.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      try {
        req.send();
        const { data } = JSON.parse(req.response);
        this.dataVacancy.vacancyName = data.name;
        this.dataVacancy.employType = data.employType;
        this.dataVacancy.workExperience = data.workExperience;
        this.dataVacancy.payFrom = data.payFrom;
        this.dataVacancy.previewImgUrl = data.previewImgUrl;
        this.dataVacancy.description = data.description;
        this.cityName = data.city;
        this.dataVacancy.city = data.cityId;
        this.dataVacancy.education = data.education;
        this.dataVacancy.expectedSalaryCurrency = data.expectedSalaryCurrency;
        this.dataVacancy.score = data.score;
        this.dataVacancy.specialization = data.specialization.id;
        this.nameSpecializations = data.specialization.name;
        for (let i = 0; data.keySkills.length; i++) {
          this.dataVacancy.vacancyKeySkills.push(data.keySkills[i].name);
        }
      } catch (e) {
        console.log(e);
      }
    },
    searchCity(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$store.dispatch("searchCity", val);
      }, 1000);
    },
    pickCity(e) {
      this.cityVisible = false;
      this.cityName =
        this.dataCities[
          this.dataCities.map((item) => item.id).indexOf(e.target.value)
        ].name;
      this.dataVacancy.city = e.target.value;
      this.$store.dispatch("searchCity", "");
    },
    pickSpecialization(item) {
      this.dataVacancy.specialization = item;
      this.specialityVisible = false;
    }
  },
  beforeMount() {
    this.$store.dispatch("searchCity", "");
    this.$store.dispatch("getIndustries", this.$i18n.locale);
    let xmlHttp = new XMLHttpRequest();
    let url = config.apiPrefix + "employer/specialization/all";
    xmlHttp.open("GET", url, false);
    xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
    try {
      xmlHttp.send();
      let response = JSON.parse(xmlHttp.response);
      this.specializations = response.data;
    } catch (e) {
      console.log(e);
    }
    this.getKeySkills();
    if (this.vacancyId != null) {
      this.fetchVacancy();
    }
  },
};
</script>


<style scoped>
.account {
  display: flex;
}

.mobile {
  display: none;
}

.account__caption-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.account__caption-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.account__info {
  margin-top: 30px;
}

.account__info-caption {
  max-width: 300px;
}

.account__info-caption-description {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.account__info-caption-input {
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px 9px 20px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
  box-sizing: border-box;
}

.account__info-filling {
  row-gap: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.account__info-filling-element-description {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

/* search */
.test__top-bottom-town {
  width: 100%;
  height: 100%;
  padding: 10px 20px 9px 20px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 36px;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
}

.test__top-bottom-town-img {
  position: absolute;
  display: block;
  background-image: url("~@/assets/img/all-img/list-grey.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 9px;
  right: 13px;
  top: calc(50% - 4px);
}

.search-wrapper:hover {
  color: #19191d;
  border: 1px solid #009ed1;
}

.search-wrapper:hover .test__top-bottom-town-img {
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search-wrapper {
  width: 100%;
  height: 47.3px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  position: relative;
  transition: border 1s ease;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
}

.search-list {
  position: absolute;
  background: #fff;
  max-height: 278px;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
}

.search-list__element {
  padding-left: 60px;
}

.search-list {
  z-index: 101;
}

.search-list__element {
  padding-left: 60px;
}

.search-list__element {
  -webkit-appearance: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
  line-height: 1;
}

.search-list__element-input {
  cursor: auto;
  border-bottom: 1px solid #bcbcbc;
  padding-right: 20px;
}

.search-list__element:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.search-list__element:last-child {
  border-radius: 0px 0px 7px 7px;
  border-bottom: none;
}

.test__top-bottom-town::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
}

/* /search */
.account__info-filling-element {
  max-width: 300px;
  width: 100%;
}

.account__info-filling-element-input {
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
  box-sizing: border-box;
}

.test__wrapper-content-filling-select {
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #a1a1a1;
  width: 100%;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-grey.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  transition: background-color 1s ease, border 1s ease, background-image 1s ease;
}

.test__wrapper-content-filling-select:hover {
  background-color: #eefbff;
  border: 1px solid #009ed1;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.test__wrapper-content-filling-select:checked {
  background-color: #eefbff;
  border: 1px solid #009ed1;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.account__filling {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;
}

.account__filling-preview {
  max-width: 665px;
  width: 100%;
  box-sizing: border-box;
}

.account__filling-preview-description {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.account__filling-preview-picture {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #dae9f3;
  background: #fff;
  border-radius: 16px;
}

.account__filling-preview-picture-img {
  border-radius: 16px;
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.account__filling-preview-picture-btn {
  padding: 19px 0;
  position: absolute;
  width: 208px;
  text-align: center;
  left: calc(50% - 104px);
  background: #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  bottom: 20px;
  transition: opacity 1s ease;
}

.account__filling-preview-picture-btn:hover {
  opacity: 0.5;
}

.account__filling-description {
  margin-left: 64px;
  width: 100%;
  max-width: 100%;
  margin-right: 65px;
  box-sizing: border-box;
}

.account__filling-description-name {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.account__filling-description-textarea {
  padding: 20px 30px;
  margin-top: 20px;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.78;
  color: #3b4c68;
  background: #ffffff;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  height: 300px;
  box-sizing: border-box;
  transition: border 1s ease;
}

.account__filling-description-textarea:hover {
  border: 1px solid #009ed1;
}

.account__add {
  margin-right: 65px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.account__add-btn {
  padding: 20px 0;
  background: #009ed1;
  width: 270px;
  text-align: center;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: opacity 1s ease;
}

.account__add-btn:hover {
  opacity: 0.5;
}

@media (max-width: 1802px) {
  .account__info-filling {
    grid-template-columns: repeat(3, 1fr);
  }

  .account__filling-description {
    margin-right: 0;
  }

  .account__add {
    margin-right: 0;
  }
}

@media (max-width: 1080px) {
  .account__info-filling {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .account__filling {
    flex-direction: column;
  }

  .account__filling-description {
    margin-top: 40px;
    margin-left: 0;
    box-sizing: border-box;
  }
}

@media (max-width: 640px) {
  .account__info-filling {
    row-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  .account__info-caption {
    max-width: 100%;
  }

  .account__info-filling-element {
    max-width: 100%;
  }
}

@media (max-width: 545px) {
  .mobile {
    display: block;
  }

  .desctop {
    display: none;
  }

  .account__caption-text {
    color: #009ed1;
  }
}

/* select select */
.search__sorting-select-container {
  width: 100%;
  height: 47.3px;
  margin-top: 20px;
  position: relative;
  box-sizing: border-box;
  display: block;
  align-items: center;
  transition: border 1s ease;
  border-radius: 8px;
}

.search__sorting-select {
  text-align: left;
  padding: 10px 20px 9px 20px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
  width: 100%;
  transition: color 1s ease, border 1s ease;
  background-image: url("~@/assets/img/all-img/list-grey.svg");
  background-repeat: no-repeat;
  background-position: calc(96%);
  background-size: 10px;
}

.search__sorting-select-speciality-group {
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  border-radius: 8px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: thin;
}

.search__sorting-select-speciality-group-element {
  cursor: pointer;
  width: 100%;
  border: 1px solid #b4b4bb;
  border-top: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
  display: flex;
}

.search__sorting-select-speciality-group-element:hover {
  background-color: #eefbff;
  color: #009ed1;
}

.search__sorting-select-speciality-group-element:last-child {
  border-radius: 0px 0px 8px 8px;
}

.search__sorting-select-speciality-group-element:first-child {
  border-radius: 8px 8px 0px 0px;
}

.search__sorting-select-speciality-group-element-input {
  -webkit-appearance: none;
  height: 0px;
}

.search__sorting-select-speciality-group-element-label {
  width: 100%;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 7px 12px;
  cursor: pointer;
}

.search__sorting-select:hover {
  color: #19191d;
  border: 1px solid #009ed1;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search__sorting-select-group {
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  border-radius: 8px;
}

.search__sorting-select-element-input {
  -webkit-appearance: none;
  height: 0px;
}

.search__sorting-select-element-label {
  cursor: pointer;
  padding: 7px 12px;
  width: 100%;
  border: 1px solid #b4b4bb;
  border-top: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
  display: block;
}

.search__sorting-select-element-label:hover {
  background-color: #eefbff;
  color: #009ed1;
}

.search__sorting-select-element-label:last-child {
  border-radius: 0px 0px 8px 8px;
}

.first-select-label {
  border-radius: 8px 8px 0 0;
}

.work__filters-element-filter-checkbox {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.select-input {
  width: 100%;
  cursor: pointer;
  height: 30px;
}

.work__filters-element-filter-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #787885;
  transition: color 1s ease;
  color: inherit;
}

.work__filters-element-filter-checkbox+label::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 3px;
  margin-right: 18px;
  margin-left: 9px;
  background-repeat: no-repeat;
  background-position: center center;
  background: #deeaf3;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: inherit;
}

.work__filters-element-filter-checkbox:checked+label::before {
  background-color: #009ed1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox-select-container {
  height: 30px;
}

.checkbox-select-container:hover {
  color: #009ed1;
}

/* /select select */
</style>
