<template>
  <div :key="queIterator">
    <HeaderAccountWorker></HeaderAccountWorker>

    <main class="main">
      <div class="account">
        <MenuWorker></MenuWorker>

        <div class="container-account">
          <div class="test">
            <div class="test__caption">
              <div class="test__caption-description">
                <h1 class="test__caption-description-title">{{ $t('pages.tests.test_worker.testInputPage.testName') }}
                </h1>
                <p class="test__caption-description-text">
                  {{ getDataTesting.title }}
                </p>
              </div>
            </div>
            <div class="test__wrapper">
              <div class="test__wrapper-content">
                <div class="test__wrapper-content-caption">
                  <p class="test__wrapper-content-caption-question">
                    {{ questions[queIterator].title }}
                  </p>
                  <p class="test__wrapper-content-caption-time">
                    {{ $t('pages.tests.test_worker.testInputPage.timeLeft') }}
                    <time v-if="questions[queIterator].maxPassingTimeSeconds" class="time">{{ parseTime(timeLimit)
                      }}</time>
                    <span v-else>{{ $t('pages.tests.test_worker.testInputPage.limitless') }}</span>
                  </p>
                </div>
                <p class="test__wrapper-content-text">
                  {{ questions[queIterator].body }}
                </p>
                <div class="video__wrapper" v-if="questions[queIterator].videoPath !== null">
                  <video class="video__wrapper-filling" controls>
                    <source :src="questions[queIterator].videoPath" />
                  </video>
                </div>
                <div class="audio" v-if="questions[queIterator].audioPath !== null">
                  <p class="audio-text">{{ $t('pages.tests.test_worker.testInputPage.audioPlayedOnce') }}</p>
                  <button @click="playAudio" class="audio-start" :class="{ blocked: isAudioPlay }">
                    <img src="../../../assets/img/audio.svg" alt="" />
                  </button>
                  <audio ref="audioPlayer" :src="questions[queIterator].audioPath" class="audio__player"></audio>
                </div>
                <div class="photo__wrapper" v-if="questions[queIterator].photoPath !== null">
                  <img class="photo__wrapper-img" :src="questions[queIterator].photoPath" alt="error" />
                </div>
                <form class="test__wrapper-content-answers">
                  <TestInputCircle :question="questions[queIterator]"
                    :countTrueAnswer="countTrueAnswer(questions[queIterator])" :choose="getChoose"></TestInputCircle>
                </form>
                <div class="choice"></div>
              </div>
              <nav class="test__wrapper-nav">
                <p class="test__wrapper-nav-number">
                  {{ queIterator + 1 }}/{{ questions.length }}
                </p>
                <div class="test__wrapper-nav-next" v-if="queIterator !== questions.length - 1" @click="nextQue">
                  <button class="test__wrapper-nav-next-btn">{{ $t('pages.tests.test_worker.testInputPage.nextQuestion')
                    }}</button>
                </div>
                <div class="test__wrapper-nav-next" v-else @click="closeTest">
                  <button class="test__wrapper-nav-next-btn">{{ $t('pages.tests.test_worker.testInputPage.completeTest')
                    }}</button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import TestInputCircle from "../../../components/tests/test_wrapper_elements/items/TestInputCircle.vue";
import MenuWorker from "../../../components/account/account_worker/MenuWorker";
import { errorHandler } from "../../../lib/errorHandler";

export default {
  computed: {
    getChoose() {
      return this.$store.getters.getChoose;
    },
    getSaveSetting() {
      return this.$store.getters.getSaveSetting;
    },
    getDataTesting() {
      return this.$store.getters.getDataTesting;
    },
  },
  components: { MenuWorker, HeaderAccountWorker, TestInputCircle },
  props: {
    testingId: {
      type: String,
    },
  },
  name: "TestInputPage",
  data() {
    return {
      queIterator: 0,
      questions: null,
      isAudioPlay: false,
      timer: null,
      timeLimit: null,
      blurCount: 0
    };
  },
  methods: {
    startTimer() {
      this.timeLimit = this.questions[this.queIterator].maxPassingTimeSeconds;
      this.timer = setInterval(() => {
        this.timeLimit--;
        if (!this.timeLimit) {
          if (this.queIterator === this.questions.length - 1) {
            this.closeTest();
          } else {
            this.nextQue();
          }
        }
      }, 1000);
    },
    playAudio() {
      this.$refs.audioPlayer.play();
      this.isAudioPlay = true;
    },
    saveAnswer() {
      if (this.questions[this.queIterator].answers.map(item => item.comparisonBody).indexOf(null) === -1) return;

      if (this.countTrueAnswer(this.questions[this.queIterator]) === 1)
        this.$store.commit("saveQuestionAnswer", {
          answer: {
            testingQuestionId: this.questions[this.queIterator].id,
            testingAnswerId: this.getChoose,
            comparisonBody: null
          },
        });
      else {
        if (this.getChoose !== null) {
          this.getChoose.forEach((a) => {
            let b = {
              answer: {
                testingQuestionId: this.questions[this.queIterator].id,
                testingAnswerId: a,
                comparisonBody: null
              },
            };
            this.$store.commit("saveQuestionAnswer", b);
          });
        } else {
          this.$store.commit("saveQuestionAnswer", {
            answer: {
              testingQuestionId: this.questions[this.queIterator].id,
              testingAnswerId: null,
              comparisonBody: null
            },
          });
        }
      }
    },
    nextQue() {
      this.saveAnswer();
      this.$store.commit("setChoose", null);
      scroll(0, 0);
      this.queIterator++;
      clearInterval(this.timer);
      this.startTimer();
    },
    closeTest() {
      this.saveAnswer();
      errorHandler({ status: 200, page: "interview" });
      this.$store.dispatch("saveTestingData", this.$store.getters.token);
      this.$router.push({
        name: "activeTest",
        params: { testingId: this.testingId },
      });
    },
    countTrueAnswer(question) {
      let count = 0;
      for (let i = 0; i < question.answers.length; i++)
        if (question.answers[i].correct === true) count++;
      return count;
    },
    closeTestError() {
      this.blurCount++;
      if (this.blurCount === 1) {
        errorHandler({ status: 401, page: 'interview' });
        return;
      }
      this.$store.commit("setNullAnswer");
      errorHandler({ status: 500, page: "interview" });
      this.$store.dispatch("saveTestingData", this.$store.getters.token);
      this.$router.push({ name: "testing" });
    },
    parseTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes ? (minutes >= 10 ? minutes : ('0' + minutes)) : '00'}:${seconds >= 10 ? seconds : ('0' + seconds)} `;
    }
  },
  beforeMount() {
    this.questions = this.getDataTesting.questions;
    this.startTimer();
    window.onblur = this.closeTestError;
  },
  destroyed() {
    window.onblur = null;
  },
  created() {
    this.$store.commit("setTestingId", this.testingId);
  },
};
</script>


<style scoped>
.account {
  display: flex;
}

.container-account {
  max-width: 1220px;
  padding-bottom: 100px;
}

.test__caption {
  display: flex;
  align-items: center;
}

.test__caption-description {
  max-width: 343px;
  width: 100%;
  box-sizing: border-box;
}

.test__caption-description-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 35.5px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.test__caption-description-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.test__caption-cancel-btn {
  padding: 17px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #696974;
  transition: opacity 1s ease;

  margin-left: auto;
  background: #f1f1f5;
  border-radius: 7px;
  max-width: 207px;
  width: 100%;
  text-align: center;
}

.test__caption-cancel-btn:hover {
  opacity: 0.5;
}

.test__wrapper {
  margin-top: 38px;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content {
  padding: 46px 63px 59px 62px;
  border-bottom: 1px solid #e9e9f9;
}

.test__wrapper-content-caption {
  display: flex;
}

.test__wrapper-content-caption-question {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 15px;
  line-height: 1.28;
  color: #009ed1;
}

.test__wrapper-content-caption-time {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 1.28;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-text {
  margin-top: 23px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.test__wrapper-content-answers {
  margin-top: 39px;
  display: flex;
  flex-direction: column;
}

.test__wrapper-nav {
  padding: 20px 63px 20px 62px;
  display: flex;
  align-items: center;
  position: relative;
}

.test__wrapper-nav-last {
  max-width: 218px;
  width: 100%;
  text-align: center;
  background: #eef5f9;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.test__wrapper-nav-last:hover {
  opacity: 0.5;
}

.test__wrapper-nav-last-btn {
  padding: 18px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.test__wrapper-nav-number {
  margin: 0 auto;
  padding: 13px 29px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #82939a;
  background: #edf1f4;
  border-radius: 28px;
}

.test__wrapper-nav-next {
  position: absolute;
  right: 62px;
  cursor: pointer;
  max-width: 207px;
  width: 207px;
  text-align: center;
  background: #009ed1;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.test__wrapper-nav-next:hover {
  opacity: 0.5;
}

.test__wrapper-nav-next-btn {
  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

/* select */
.choice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
}

.audio-caption {
  display: none;
}

.audio {
  margin-top: 10px;
}

.audio-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.28;
  color: #3b4c68;
  opacity: 0.5;
}

.audio-start {
  margin-top: 12px;
}

.audio-close {}

.audio__player {
  visibility: hidden;
}

@media (max-width: 800px) {
  .container-account {
    padding-top: 77px;
  }

  .test__wrapper-content {
    padding: 20px;
  }

  .test__wrapper-nav-next {
    right: 20px;
  }

  .test__wrapper-nav {
    padding: 20px;
  }
}

@media (max-width: 700px) {
  .test {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: none;
  }

  .test__caption {
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
    order: 2;
    flex-direction: column;
    align-items: unset;
  }

  .test__wrapper-content {
    background: #fafafb;
  }

  .test__wrapper-nav {
    background: #fafafb;
  }

  .test__caption-description {
    max-width: 100%;
    width: 100%;
  }

  .test__caption-description-title {
    font-size: 13px;
  }

  .test__caption-description-text {
    font-size: 14px;
  }

  .test__caption-cancel {
    margin: 20px 0 0 0;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .test__caption-cancel-btn {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper {
    margin-top: 0;
    border: none;
    background: unset;
  }

  .test__wrapper-content {
    padding: 0 0 40px 0;
    background: unset;
  }

  .test__wrapper-content-caption-question {
    font-size: 13px;
  }

  .test__wrapper-content-caption-time {
    font-size: 13px;
  }

  .test__wrapper-content-text {
    margin-top: 10px;
    font-size: 13px;
  }

  .test__wrapper-content-answers {
    margin-top: 16px;
  }

  .test__wrapper-nav {
    padding: 25px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: unset;
  }

  .test__wrapper-nav-number {
    display: none;
  }

  .test__wrapper-nav-last {
    max-width: 100%;
    margin-right: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .test__wrapper-nav-last-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper-nav-next {
    position: unset;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .test__wrapper-nav-next-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .audio-text {
    font-size: 12px;
  }

  .audio-start {
    margin-top: 8px;
    height: 28px;
  }

  .audio-start img {
    width: 28px;
    height: 28px;
  }
}

/* Video */
.video__wrapper {
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
}

.video__wrapper-filling {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: none;
}

@media (max-width: 700px) {
  .video__wrapper {
    height: 160px;
  }

  .choice {
    gap: 12px;
  }
}

/* /Video */

/* Photo */
.photo__wrapper {
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
}

.photo__wrapper-img {
  border-radius: 16px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .photo__wrapper {
    height: 160px;
  }
}

/* /Photo*/

.blocked {
  opacity: 0.4;
  pointer-events: none;
}
</style>
