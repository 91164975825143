<template>
	<div class="answer">
		<p class="answer__caption">{{ $t('components.tests.testFile.attachFile') }}</p>
		<div class="answer__addfile">
			<button class="answer__addfile-btn">{{ $t('components.tests.testFile.downloadFile') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "TestFile",
};
</script>

<style scoped>
.answer {
	width: 100%;
	box-sizing: border-box;
}

.answer__caption {
	margin-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #83939a;
}

.answer__addfile {
	text-align: center;
	margin-top: 11px;
	max-width: 310px;
	width: 100%;
	background: #88a1aa;
	border-radius: 7px;
}

.answer__addfile-btn {
	padding: 13px 0;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

@media (max-width: 700px) {
	.answer__addfile {
		max-width: 100%;
	}
}
</style>