<template>
  <div class="message">
    <p class="message__name">{{ $t('components.general.support.userMessage.yourMessage') }}</p>
    <div class="message__filling">
      <pre class="message__text">{{ message }}</pre>
      <div class="info">
        <div class="status status--watched"></div>
        <p class="time">{{ time }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserMessage",
  props: {
    message: String,
    time: String
  }
}
</script>


<style scoped>
.message {
  gap: 10px;
  max-width: 100%;
  margin-left: auto;
  text-align: right;
}

.message__name {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #595959;
}

.message__filling {
  margin-top: 6px;
}

.message__text {
  background: #009ed1;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(2, 60, 97, 0.05);
  padding: 19px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.info {
  justify-content: flex-end;
  margin-top: 4px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.status {
  width: 20px;
  height: 20px;
  background: url("~@/assets/img/chat-status.svg");
}

.status--watched {
  background: url("~@/assets/img/chat-status--watched.svg");
}

.time {

  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: rgba(115, 115, 115, 0.5);

}

@media (max-width: 850px) {
  .message {
    gap: 7px;
  }

  .face {
    width: 30px;
    height: 30px;
  }

  .message__name {
    font-size: 13px;
    line-height: 1.2;
  }

  .message__filling {
    margin-top: 4px;
  }

  .message__text {
    border-radius: 10px;
    padding: 12px;
    font-size: 14px;
    line-height: 1.2;
  }

  .status {
    width: 16px;
    height: 16px;
    background-size: contain;
  }

  .time {
    margin-top: 2px;
    font-size: 9px;
    line-height: 1.2;
  }
}
</style>