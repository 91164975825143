<template>
  <router-link :to="{name:'allChats'}" class="notification">
    <button class="close" v-if="false"></button>
    <div class="notification-filling">
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.137 15.6472C24.137 20.5873 20.1322 24.5921 15.1921 24.5921C13.2933 24.5921 11.5326 24.0004 10.0844 22.9913L6.24723 25.018V15.8602L6.2497 15.8598C6.24805 15.7891 6.24723 15.7182 6.24723 15.6472C6.24723 10.7071 10.252 6.7023 15.1921 6.7023C20.1322 6.7023 24.137 10.7071 24.137 15.6472ZM30.3842 15.6472C30.3842 24.0376 23.5825 30.8393 15.1921 30.8393C6.80174 30.8393 0 24.0376 0 15.6472C0 7.25682 6.80174 0.455078 15.1921 0.455078C23.5825 0.455078 30.3842 7.25682 30.3842 15.6472Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.137 15.6472C24.137 20.5873 20.1322 24.5921 15.1921 24.5921C13.2933 24.5921 11.5326 24.0004 10.0844 22.9913L6.24723 25.018V15.8602L6.2497 15.8598C6.24805 15.7891 6.24723 15.7182 6.24723 15.6472C6.24723 10.7071 10.252 6.7023 15.1921 6.7023C20.1322 6.7023 24.137 10.7071 24.137 15.6472ZM30.3842 15.6472C30.3842 24.0376 23.5825 30.8393 15.1921 30.8393C6.80174 30.8393 0 24.0376 0 15.6472C0 7.25682 6.80174 0.455078 15.1921 0.455078C23.5825 0.455078 30.3842 7.25682 30.3842 15.6472Z" fill="#009ED1"/>
        <path d="M19.8767 15.6473C19.8767 18.235 17.779 20.3328 15.1913 20.3328C12.6036 20.3328 10.5059 18.235 10.5059 15.6473C10.5059 13.0596 12.6036 10.9619 15.1913 10.9619C17.779 10.9619 19.8767 13.0596 19.8767 15.6473Z" fill="black"/>
        <path d="M19.8767 15.6473C19.8767 18.235 17.779 20.3328 15.1913 20.3328C12.6036 20.3328 10.5059 18.235 10.5059 15.6473C10.5059 13.0596 12.6036 10.9619 15.1913 10.9619C17.779 10.9619 19.8767 13.0596 19.8767 15.6473Z" fill="#009ED1"/>
      </svg>
      <p class="notification__persone">{{ $t('components.social.notification.newMessage', {count: user.newMessages}) }}</p>
    </div>
  </router-link>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Notification",
  computed: {
    ...mapGetters(['user'])
  }
}
</script>


<style scoped>
.notification {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 100;
  background:#FAFAFB;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px 50px 10px 10px;
  transition: all 1s ease-in-out;
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.notification-filling{
  display: flex;
  align-items: center;
}
.notification-filling svg{
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
}
.notification__persone {
  margin-left: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #000000;
}

.notification-end{
  opacity: 0;
}

</style>