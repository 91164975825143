import Cookies from 'js-cookie';
import {errorHandler} from "@/lib/errorHandler";
import {config} from "@/lib/config";
import {router} from "@/main";

export const userData = {
    state: {
        token: null,
        role: null,
        avatarPath: null,
        name: null,
        dataCities: null,
        user: null,
        notificationView: false,
        notificationTimer: null,

    },

    mutations: {
        setUserData(state, data) {
            state.user = data;
        },
				setNewFriendRequests(state, data) {
            state.user.newFriendRequests = data;
        }, setCity(state, data) {
            state.dataCities = data;
        }, setToken(state, data) {
            state.token = data;
        }, setRole(state, data) {
            state.role = data;
        }, setName(state, data) {
            state.name = data;
        }, setAvatarPath(state, data) {
            state.avatarPath = data;
        },
        setChatsCount(state, data) {
					if(state.user.newMessages>0 || data > 0)
            state.user.newMessages = state.user.newMessages + data;
						console.log(state.user.newMessages)
            clearTimeout(state.notificationTimer);
						if(data>0){
            state.notificationTimer = setTimeout(() => {
                this.commit('setNotificationView', false);
            }, 3500)
					}else{
						state.notificationTimer = setTimeout(() => {
							this.commit('setNotificationView', false);
					}, 0)
					}
        },
        setNotificationView(state, data) {
            state.notificationView = data;
        },

				
    },

    actions: {
        async deleteUser({getters}) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/acc/delete';
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                const status = req.status;
                if (status === 200) {
                    errorHandler({page: 'userDelete', status});
                    await this.dispatch('logout');
                }
            } catch (e) {
                console.log(e);
            }
        },

        restorePassword(_, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'password/restore';
            req.open('POST', url, false);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(payload));
                errorHandler({status: req.status, page: 'restore'});
            } catch (e) {
                console.log(e);
            }
        },
        searchCity({commit}, city) {
            const url = config.apiPrefix + 'system/cities?name=' + city + '&lang=en';
            const reqOptions = {
                method: 'GET'
            }
            fetch(url, reqOptions).then(res => res.json()).then(function (res) {
                commit('setCity', res.data);
            })
        }, changeAvatar({commit}, newAvatarPath) {
            Cookies.set('avatar', newAvatarPath, {expires: 365});
            commit('setAvatarPath', newAvatarPath);
        },

        changeName({commit}, newName) {
            Cookies.set('name', newName, {expires: 365});
            commit('setName', newName);
        },

        logout({commit}) {
					const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/logout';
            req.open('POST', url, false);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
						req.setRequestHeader('X-Auth-Token', this.getters.token);
            try {
                req.send();
								Cookies.remove('token',);
								Cookies.remove('role',);
								Cookies.remove('name');
								Cookies.remove('avatar');
								commit('setToken', null);
								commit('setRole', null);
								commit('setName', null);
								commit('setAvatarPath', null);
								commit('socketClose');
								router.push({name: 'home'})
                
            } catch (e) {
							errorHandler({status: req.status, page: 'logout'});
            }
           
        },

        async loadUserData({getters, commit}) {
            if (getters.token && getters.role) {
                if (getters.role === 'CANDIDATE') {
                    await this.dispatch('fetchCandidateData');
                } else if (getters.role === 'EMPLOYER') {
                    await this.dispatch('fetchEmployerData');
                }
                commit('webSocketInit');
                return;
            }
            let token = Cookies.get('token');
            let role = Cookies.get('role');

            if (token && token !== 'null') {
                commit('setToken', token);
            } else {
                return;
            }
            if (role && role !== 'null') {
                commit('setRole', role);
            } else {
                return;
            }
            if (getters.role === 'CANDIDATE') {
                await this.dispatch('fetchCandidateData');
            } else if (getters.role === 'EMPLOYER') {
                await this.dispatch('fetchEmployerData');
            }
            commit('webSocketInit');
            let name = Cookies.get('name');
            let avatar = Cookies.get('avatar');

            if (name && name !== 'null') {
                commit('setName', name);
            } else {
                Cookies.set('name', getters.user.name, {expires: 365});
                commit('setName', getters.user.name);
            }
            if (avatar && avatar !== 'null') {
                commit('setAvatarPath', avatar);
            } else {
                if (getters.role === 'CANDIDATE') {
                    Cookies.set('avatar', getters.user.avatarPath, {expires: 365});
                    commit('setAvatarPath', getters.user.avatarPath);
                } else if (getters.role === 'EMPLOYER') {
                    Cookies.set('avatar', getters.user.logoPath, {expires: 365});
                    commit('setAvatarPath', getters.user.logoPath);
                }
            }
        },

        fetchCandidateData({commit, getters}) {
            let request = new XMLHttpRequest();
            let url = config.apiPrefix + "candidate/data?lang=en";
            request.open("GET", url, false);
            request.setRequestHeader("X-Auth-Token", getters.token);
            try {
                request.send();
                if (request.status === 401 || request.status === 403) {
                    errorHandler({status: request.status, page: 'invalidToken'});
                    this.dispatch('logout').then(router.push({name: 'candidateLogin'}));
                }
                let {data} = JSON.parse(request.response);
                commit('setUserData', data);
            } catch (e) {
                console.log(e);
            }
        },
        fetchEmployerData({commit, getters}) {
            let request = new XMLHttpRequest();
            let url = config.apiPrefix + "employer/data";
            request.open("GET", url, false);
            request.setRequestHeader("X-Auth-Token", getters.token);
            try {
                request.send();
                if (request.status === 401 || request.status === 403) {
                    this.dispatch('logout').then(router.push({name: 'employerLogin'}));
                    errorHandler({status: request.status, page: 'invalidToken'});

                }
                let {data} = JSON.parse(request.response);
                commit('setUserData', data);
            } catch (e) {
                console.log(e);
            }
        },

        async login({getters, commit}, data) {
            if (getters.token && getters.role) return;
            //console.log("login data: " + JSON.stringify(data) + " || " + getters.token);
            let dataResponse;
            let token;
            let xmlHttp = new XMLHttpRequest()
            if (data.role === "CANDIDATE") {
                let url = config.apiPrefix + 'candidate/login'
                xmlHttp.open('POST', url, false);
                xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                try {
                    xmlHttp.send(JSON.stringify({email: data.email, password: data.password}))
                    let response = JSON.parse(xmlHttp.response);
                    if (response.data === null) token = null; else token = response.data.token;
                } catch (e) {
                    console.log(e);
                }
                dataResponse = {
                    token: token, role: 'CANDIDATE'
                };
                errorHandler({status: xmlHttp.status});

                // loading avatar and name of candidate
            } else {
                let url = config.apiPrefix + 'employer/login';
                xmlHttp.open('POST', url, false);
                xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                try {
                    xmlHttp.send(JSON.stringify({email: data.email, password: data.password}))
                    let response = JSON.parse(xmlHttp.response);
                    if (response.data === null) token = null; else token = response.data.token;
                } catch (e) {
                    console.log(e);
                }
                dataResponse = {
                    token: token, role: 'EMPLOYER'
                };
                errorHandler({status: xmlHttp.status});

                // loading avatar and name of employer

            }
            if (token) {
                Cookies.set('token', dataResponse.token, {expires: 365});
                Cookies.set('role', dataResponse.role, {expires: 365});
                commit('setToken', dataResponse.token);
                commit('setRole', dataResponse.role);
                if (getters.role === 'CANDIDATE') {
                    await this.dispatch('fetchCandidateData');
                } else if (getters.role === 'EMPLOYER') {
                    await this.dispatch('fetchEmployerData');
                }
                Cookies.set('name', getters.user.name, {expires: 365});
                commit('setName', getters.user.name);
                if (getters.role === 'CANDIDATE') {
                    Cookies.set('avatar', getters.user.avatarPath, {expires: 365});
                    commit('setAvatarPath', getters.user.avatarPath);
                } else if (getters.role === 'EMPLOYER') {
                    Cookies.set('avatar', getters.user.logoPath, {expires: 365});
                    commit('setAvatarPath', getters.user.logoPath);
                }

            }

        },
        async authorization({commit, getters}, user) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/login';
            req.open('POST', url, false);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify(user));
                const {data} = JSON.parse(req.response);
                errorHandler({status: req.status});
                if (data && data.token) {
                    Cookies.set('token', data.token, {expires: 365});
                    Cookies.set('role', data.role.toUpperCase(), {expires: 365});
                    commit('setToken', data.token);
                    commit('setRole', data.role.toUpperCase());
                    if (getters.role === 'CANDIDATE') {
                        await this.dispatch('fetchCandidateData');
                    } else if (getters.role === 'EMPLOYER') {
                        await this.dispatch('fetchEmployerData');
                    }
                    Cookies.set('name', getters.user.name, {expires: 365});
                    commit('setName', getters.user.name);
                    if (getters.role === 'CANDIDATE') {
                        Cookies.set('avatar', getters.user.avatarPath, {expires: 365});
                        commit('setAvatarPath', getters.user.avatarPath);
                    } else if (getters.role === 'EMPLOYER') {
                        Cookies.set('avatar', getters.user.logoPath, {expires: 365});
                        commit('setAvatarPath', getters.user.logoPath);
                    }
                    commit('webSocketInit');
                }
								else {
									// Обработка ошибок
									const errorResponse = JSON.parse(req.responseText);
									if (errorResponse && errorResponse.message === 'account_banned') {
											errorHandler({status: 403, page: 'login'});
									} else {
											errorHandler({status: req.status, page: 'login'});
									}
							}
            } catch (e) {
                console.log(e);
            }
        }

    },

    getters: {
        token: (state) => state.token,
        role: (state) => state.role,
        isCandidate: (state) => state.token && state.role && state.role === 'CANDIDATE',
        isEmployer: (state) => state.token && state.role && state.role === 'EMPLOYER',
        avatarPath: (state) => state.avatarPath,
        name: (state) => state.name,
        dataCities: state => state.dataCities,
        authorized: (state) => (state.token && state.token.toString() !== 'null') && (state.role && state.role.toString() !== 'null'),
        user: state => state.user,
        notificationView: state => state.notificationView,

        // && (state.name && state.name.toString() !== 'null') && (state.avatarPath && state.avatarPath.toString() !== 'null')
    }
};