<template>
	<div class="friend-wrapper">
		<div class="friends-separate__left" style="cursor: pointer" @click="goToProfile">
			<div class="friend__face">
				<img :src="user.avatarUrl" alt="" class="friend__face-img">
			</div>
			<div class="friend__info">
				<div class="friend__info-caption">
					<p class="friend__info-designation friend-mobile">
						{{ user.role ? (user.role === 'candidate') ?
							$t('components.social.separatePossibleFriend.jobSeeker') :
							$t('components.social.separatePossibleFriend.employer') :
							$t('components.social.separatePossibleFriend.noData') }}
						<span class="friend__info-rating">{{ user.rate ? user.rate : 0 }}</span>
					</p>
					<button class="friend__settings friend-mobile" v-if="false" v-on:click="menuVisible = !menuVisible">
						<div class="friend__settings-popap" v-if="menuVisible">
							<div class="popap-list">
								<button @click="goToProfile" class="popap-list__button">{{
									$t('components.social.separatePossibleFriend.profile') }}</button>
								<button @click="sendMessage" class="popap-list__button">{{
									$t('components.social.separatePossibleFriend.writeMessage') }}</button>
							</div>
						</div>
					</button>
				</div>
				<h3 class="friend__info-name">{{ user.name ? limit(user.name, 20) :
					$t('components.social.separatePossibleFriend.noData') }}
					<span class="friend__info-rating friend-desctop">{{ user.rate ? user.rate : 0 }}</span>
				</h3>
				<p class="friend__info-speciality friend-desctop">
					{{ user.profession ? limit(user.profession, 20) :
						$t('components.social.separatePossibleFriend.noData') }}
				</p>
				<p class="friend__info-designation friend-desctop">
					{{ user.role ? (user.role === 'candidate') ?
						$t('components.social.separatePossibleFriend.jobSeeker') :
						$t('components.social.separatePossibleFriend.employer') :
						$t('components.social.separatePossibleFriend.noData') }}
				</p>
				<p class="friend__place-town friend-mobile">
					<span
						:class="{ 'friend__place-icon': user.role === 'candidate', 'friend__info-icon': user.role === 'employer' }"></span>
					{{ user.city ? limit(user.city, 20) : $t('components.social.separatePossibleFriend.noData') }}
				</p>
			</div>
			<div class="friend__place friend-desctop" v-if="user.role === 'candidate'">
				<p class="friend__place-name">{{ $t('components.social.separatePossibleFriend.city') }}</p>
				<p class="friend__place-town">
					<span class="friend__place-icon"></span>
					{{ user.city ? limit(user.city, 20) : $t('components.social.separatePossibleFriend.noData') }}
				</p>
			</div>
			<div class="friend__place friend-desctop" v-if="user.role === 'employer'">
				<p class="friend__place-name">{{ $t('components.social.separatePossibleFriend.information') }}</p>
				<p class="friend__place-town">
					<span class="friend__info-icon"></span>
					{{ user.city ? limit(user.city, 20) : $t('components.social.separatePossibleFriend.noData') }}
				</p>
			</div>
		</div>
		<div class="interaction">
			<button @click="sendRequestForFriend" class="friend__message">
				<span class="friend__message-icon"></span>{{ $t('components.social.separatePossibleFriend.addFriend') }}
			</button>
			<button @click="sendMessage" class="wright">
				{{ $t('components.social.separatePossibleFriend.write') }}
				<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H9C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V6C11 6.53043 10.7893 7.03914 10.4142 7.41421C10.0391 7.78929 9.53043 8 9 8H7L4 11V8H2C1.46957 8 0.960859 7.78929 0.585786 7.41421C0.210714 7.03914 0 6.53043 0 6V2Z"
						fill="#009ED1" />
					<path
						d="M13.0005 4V6C13.0005 7.06087 12.5791 8.07828 11.8289 8.82843C11.0788 9.57857 10.0614 10 9.0005 10H7.8285L6.0625 11.767C6.3425 11.916 6.6615 12 7.0005 12H9.0005L12.0005 15V12H14.0005C14.5309 12 15.0396 11.7893 15.4147 11.4142C15.7898 11.0391 16.0005 10.5304 16.0005 10V6C16.0005 5.46957 15.7898 4.96086 15.4147 4.58579C15.0396 4.21071 14.5309 4 14.0005 4H13.0005Z"
						fill="#009ED1" />
				</svg>
			</button>
		</div>
		<button class="friend__settings friend-desctop" v-on:click="menuVisible = !menuVisible">
			<div class="friend__settings-popap" v-if="menuVisible">
				<div class="popap-list">
					<button @click="goToProfile" class="popap-list__button">{{
						$t('components.social.separatePossibleFriend.profile') }}</button>
				</div>
			</div>
		</button>
	</div>
</template>

<script>

import { textSymbolLimit } from "@/lib/textSymbolLimit";
import Cookies from "js-cookie";

export default {
	name: "SeparatePossibleFriend",
	props: ['user', 'index'],
	data() {
		return {
			menuVisible: false,
			limit: textSymbolLimit
		};
	},
	methods: {
		sendMessage() {
			Cookies.set('currentDialogueId', this.user.id);
			Cookies.set('currentDialogueName', this.user.name);
			Cookies.set('currentDialogueAvatar', this.user.avatarUrl);
			Cookies.set('currentDialogueRole', this.user.role);
			this.$router.push({ name: 'allChats' });
		},
		sendRequestForFriend() {
			this.$store.dispatch('sendRequestFriend', {
				role: this.user.role,
				id: this.user.id,
				token: this.$store.getters.token,
				index: this.index
			});
		},
		goToProfile() {
			if (this.user.role === 'candidate') {
				this.$router.push({ name: 'candidateProfile', params: { summaryId: this.user.id } });
			} else {
				Cookies.set('currentDialogueName', this.user.name);
				this.$router.push({ name: 'employerProfile', params: { summaryId: this.user.id } });
			}
		}
	}
};

</script>


<style scoped>
.friend-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	margin-bottom: 20px;
}

.friends-separate__left {
	display: flex;
}

.friend__face {
	position: relative;
}

.friend__face-img {
	width: 64px;
	height: 64px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
	object-fit: cover;
}

.friend__face-status {
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 9px;
	height: 9px;
	display: block;
	border-radius: 100%;
}

.friend__face-status.friend__face-status-active {
	background: #14c686;
	border: 2px solid #fff;
}

.friend__info {
	margin-left: 19px;
}

.friend__info-caption {
	display: flex;
}

.friend__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.friend__info-rating {
	margin-left: 10px;
	padding: 4px 11px 4px 25px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
	background-repeat: no-repeat;
	background-position-x: 9px;
	background-position-y: 6px;
	color: #14c686;
}

.friend__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.friend__info-designation {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}

.friend__place {
	margin-left: 40px;
}

.friend__place-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #686873;
}

.friend__place-town {
	margin-top: 10px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 12px;
	color: #000000;
	display: flex;
}

.friend__place-icon {
	background: url("~@/assets/img/account-img/friends/town.svg");
	width: 11px;
	height: 13px;
	background-repeat: no-repeat;
	margin-right: 6px;
}


.interaction {
	margin: auto 0 auto auto;
	display: flex;
	align-items: center;
	max-width: 250px;
	width: 100%;
	height: 45px;
	align-items: center;
	border-radius: 8px;
}

.wright {
	border-radius: 0px 8px 8px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	max-width: 100%;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	background: #e6f6fc;
	align-items: center;
	color: #009ed1;
}

.wright svg {
	margin-left: 7px;
	display: flex;
	flex-shrink: 0;
}

.friend__message {
	background: #009ed1;
	border-radius: 8px 0 0 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	height: 100%;
	color: #ffffff;
	max-width: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.friend__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin: auto 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.friend__message-settings {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.friend__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -55px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;
}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.friend__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}


.friend__message-icon {
	background-image: url("~@/assets/img/account-img/friends/add-friend-white.png");
	width: 20px;
	height: 20px;
	display: flex;
	flex-shrink: 0;
	background-repeat: no-repeat;
	margin-right: 7px;
	background-size: cover;
}

.friend-mobile {
	display: none;
}

@media (max-width: 1575px) {
	.friend-wrapper {
		max-width: 400px;
		padding: 0;
		flex-direction: column;
	}

	.friend__settings {
		margin-left: auto;
		height: 20px;
	}


	.friend-desctop {
		display: none;
	}

	.friend-mobile {
		display: flex;
	}

	.friends-separate__left {
		padding: 13px 15px;
		align-items: center;
	}

	.friend__info-rating {
		margin-left: 10px;
	}

	.friend__info {
		width: 100%;
	}

	.friend__info-designation {
		align-items: center;
		padding-top: 0;
	}

	.friend__info-name {
		font-size: 13px;
		margin-top: 2px;
	}

	.friend__place-town {
		color: #696974;
		margin-top: 5px;
	}

	.friend__message {
		width: 100%;
		margin-left: 0;
		border-radius: 0px 0px 10px 10px;
	}

	.friend__settings-popap {
		right: -16px;
	}

	.popap-list::after {
		right: 18px;
	}

	.interaction {
		max-width: 100%;
		border-radius: 0 0 10px 10px;
	}

	.friend__message {
		border-radius: 0 0 0 10px;
	}

	.wright {
		border-radius: 0 0 10px 0;
	}
}

@media (max-width: 720px) {
	.friend-wrapper {
		padding: 0;
		margin-bottom: 0;
	}
}

.friend__info-icon {
	background: url("~@/assets/img/account-img/friends/info.png");
	background-size: cover;

	width: 11px;
	height: 11px;
	background-position: center;
	margin: auto 3px auto 0;
}
</style>
