<template>
  <button class="test">
    <div class="test-caption">
      <img alt=" " :src="community.avatarUrl" class="test-caption__img">
      <div class="description">
        <p class="description__comany">{{ textSymbolLimit(community.name, 20) }}</p>
        <p class="description__format">{{ community.communityType === 'open' ? $t('components.account_worker.detailedCommunity.openCommunity') : $t('components.account_worker.detailedCommunity.closedCommunity') }}</p>
      </div>
    </div>
    <p class="test-info">
      {{ textSymbolLimit(community.description, 20) }}
    </p>
    <button class="test__start"
      @click="$router.push({ name: 'communitiesPage', params: { communitiesId: community.id } })">{{ $t('components.account_worker.detailedCommunity.readMore') }}
    </button>
  </button>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
  name: "DetailedCommunity",
  data() {
    return {
      textSymbolLimit
    }
  },
  props: ['community']
};
</script>


<style scoped>
.test {
  max-width: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  height: 205px;
  box-sizing: border-box;
  font-family: "Roboto";
  text-align: left;
}

.test-caption {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.test-caption__img {
  width: 53px;
  height: 53px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  object-fit: cover;
}

.description__comany {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
}

.test-info {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.description__format {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.test__start {
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  border-top: 1px solid rgba(231, 233, 236, 0.8);
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
}
</style>
