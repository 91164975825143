var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-wrapper"},[_vm._l((_vm.data),function(item,index){return _c('table',{key:item.id,staticClass:"table",attrs:{"border":"1px"}},[(!item.delete)?_c('tr',[_c('div',{staticClass:"status"},[_c('div',{staticClass:"documents__group-element-caption-stage-circle",class:{
					'documents__group-element-caption-stage-circle':
						item.approved === 'unloaded' ||
						item.approved === null ||
						item.approved === undefined,
					'stage-circle-blue': item.approved === 'checking',
					'stage-circle-green': item.approved === 'approved',
					'stage-circle-red': item.approved === 'rejected',
				}}),_c('p',{staticClass:"documents__group-element-caption-stage-text"},[(
						item.approved === 'unloaded' ||
						item.approved === null ||
						item.approved === undefined
					)?_c('span',[_vm._v("Download required")]):_vm._e(),(item.approved === 'checking')?_c('span',[_vm._v("Under review")]):_vm._e(),(item.approved === 'approved')?_c('span',[_vm._v("Checked")]):_vm._e(),(item.approved === 'rejected')?_c('span',[_vm._v("Rejected")]):_vm._e()])])]):_vm._e(),(!item.delete)?_c('tr',{staticClass:"table-row"},[_c('th',{staticClass:"table-column mb20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.translationLink),expression:"item.translationLink"}],staticClass:"input-description",attrs:{"placeholder":"Link to translation","disabled":item.approved === 'checking' || item.approved === 'approved'},domProps:{"value":(item.translationLink)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "translationLink", $event.target.value)}}})]),_c('th',{staticClass:"table-column table-textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.description),expression:"item.description"}],staticClass:"input-description",attrs:{"placeholder":"Description","disabled":item.approved === 'checking' || item.approved === 'approved'},domProps:{"value":(item.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "description", $event.target.value)}}})]),_c('th',{staticClass:"close-btn"},[(item.approved !== 'checking' && item.approved !== 'approved')?_c('button',{on:{"click":function($event){return _vm.deleteRow(item.id, index)}}},[_c('img',{attrs:{"src":require("@/assets/img/all-img/documents/close.svg"),"alt":""}})]):_vm._e()])]):_vm._e()])}),_c('button',{staticClass:"button-add-row",on:{"click":_vm.addData}},[_vm._v("Add a note")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }