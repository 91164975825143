import {config} from "@/lib/config";
import Cookies from "js-cookie";
//import {errorHandler} from "@/lib/errorHandler";


export const chat = {
    actions: {
        saveWorkingMessage({getters, commit}, {id, message}) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/chats/update/' + id;
            req.open('POST', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            try {
                req.send(JSON.stringify({message}));
                commit('setWorkingMessage', {id, message});
            } catch (e) {
                console.log(e);
            }
        },
        fetchAllMessageWithAdmin({commit, getters}, callback) {
            const req = new XMLHttpRequest();
            let url;
            if (getters.role === 'EMPLOYER') {
                url = config.apiPrefix + 'employer/chat/admin/all';
            } else {
                url = config.apiPrefix + 'candidate/chat/admin/all';
            }
            req.open('GET', url, true)
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = function () {
                    if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                        let res = JSON.parse(this.response);
                        commit('setCurrentDialogue', res.data)
                        if (callback)
                            callback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        fetchAllDialoguesWithAdmin({commit, getters}, callback) {
            let request = new XMLHttpRequest();
            let url;
            if (getters.role === 'EMPLOYER') {
                url = config.apiPrefix + 'employer/chat/admin/last';
            } else {
                url = config.apiPrefix + 'candidate/chat/admin/last';
            }
            request.open('GET', url, true)
            request.setRequestHeader('X-Auth-Token', getters.token);
            try {
                request.send();
                request.onreadystatechange = function () {
                    if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
                        let response = JSON.parse(this.response);
                        if (!response.data) {
                            response.data = {
                                from: null,
                                adminName: null,
                                createDate: null,
                                message: null,
                                read: true
                            }
                        }
                        if (getters.allDialogues)
                            commit('setAllDialogues', null);
                        commit('setAllDialogues', [response.data])
                        if (callback)
                            callback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        fetchAllMessageWithUser({commit, getters}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/chats/' + payload.id + '?page=' + 1 + '&limit=' + getters.limitMessages;
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = function () {
                    if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                        let res = JSON.parse(req.response);
                        if (!getters.currentDialogue)
                            commit('setCurrentDialogue', res.data.reverse());
                        else commit('addCurrentDialogue', res.data.reverse());
                        if (payload.callback)
                            payload.callback();

                        // if (getters.lastPageMessage) {
                        //     if (getters.lastPageMessage !== 1)
                        //         for (let i = 0; i < getters.lastPageMessage-1; i++)
                        //             commit('upPageMessages');
                        //     commit('setLastPageMessage',null)
                        // }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        fetchAllDialoguesWithUser({commit, getters}, callback) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/chats?page=' + getters.pageDialogues + '&role=' + getters.currentRole + '&limit=' + getters.limitDialogues;
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = function () {
                    if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                        let res = JSON.parse(req.response);
                        if (!getters.allDialogues)
                            commit('setAllDialogues', res.data); //res.data.concat(res.data.concat(res.data.concat(res.data)))
                        else commit('addAllDialogues', res.data);
                        if (getters.allDialogues.length !== 0 && getters.pageDialogues === 1)
                            commit('setLastTimestamp', new Date(getters.allDialogues[0].createDate).toISOString());
                        else {
                            commit('setLastTimestamp', new Date(Date.now()).toISOString());
                        }
                        if (callback)
                            callback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        // readMessage({getters}, callback) {
        //     const req = new XMLHttpRequest();
        //     let url;
        //     if (getters.currentRole === 'admin') {
        //         if (getters.role === 'EMPLOYER') {
        //             url = config.apiPrefix + 'employer/chat/admin/read';
        //         } else {
        //             url = config.apiPrefix + 'candidate/chat/admin/read';
        //         }
        //     } else {
        //         url = config.apiPrefix + 'user/chats/read';
        //     }
        //     req.open('POST', url, true);
        //     req.setRequestHeader('X-Auth-Token', getters.token);
        //     req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        //     try {
        //         if (getters.currentRole === 'admin') {
        //             req.send();
        //         } else {
        //             const id = Cookies.get('currentDialogueId');
        //             req.send(JSON.stringify({userId: id}))
        //         }
        //         req.onreadystatechange = function () {
        //             if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
        //                 if (callback) {
        //                     callback();
        //                 }
        //             }
        //         }
        //     } catch (e) {
        //         console.log(e);
        //     }
        // },

        updateFileInMessage({getters, commit}) {
            for (let i = 0; i < getters.fileInMessage.length; i++) {
                let req = new XMLHttpRequest();
                let url = config.apiPrefix + 'file/upload';
                req.open('POST', url, false);
                let formData = new FormData();
                formData.append('file', getters.fileInMessage[i].file)
                try {
                    req.send(formData)
                    // req.onreadystatechange = function () {
                    //     if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                    const res = JSON.parse(req.response)
                    commit('fileUpdateCallback', res.data.url);
                    //     }
                    // }
                } catch (e) {
                    console.log(e);
                }
            }
        },

      //  sendMessage({getters, dispatch, commit}, payload) {
            // if (getters.fileInMessage.length !== 0)
            //     dispatch('updateFileInMessage');

						// const id = Cookies.get('currentDialogueId');
						// let body = JSON.stringify({
						// 	writerId: getters.user.id,
						// 	writerRole: getters.role,
						// 	message: payload.message, 
						// 	userId: id, 
						// 	userRole: payload.userRole, 
						// 	files: getters.newFileInMessages
						// });
						// console.log(body)
						
						// this.getters.websocket.send(body);
						// console.log('test')
						// commit('setLastPageMessage', getters.pageMessages);
            // commit('resetPageMessages');
            // if (payload.callback) {
            //     payload.callback();
            // }
            // commit('resetFileInMessages');
            // const req = new XMLHttpRequest();
            // let url;
            // if (getters.currentRole === 'admin') {
            //     if (getters.role === 'EMPLOYER')
            //         url = config.apiPrefix + 'employer/chat/admin/new';
            //     else url = config.apiPrefix + 'candidate/chat/admin/new';
            // } else {
            //     url = config.apiPrefix + 'user/chats/write';
            // }
            // req.open('POST', url, true);
            // req.setRequestHeader('X-Auth-Token', getters.token);
            // req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            // try {
            //     let body;
            //     if (getters.currentRole === 'admin') {
            //         body = {message: payload.message};
            //         if (getters.fileInMessage.length !== 0)
            //             body = {message: payload.message, files: getters.newFileInMessages};

            //     } else {
            //         const id = Cookies.get('currentDialogueId');

            //         body = {
						// 					message: payload.message, 
						// 					userId: id,
						// 					userRole: payload.userRole, 
										
						// 				};

            //         if (getters.fileInMessage.length !== 0)
            //             body = {
						// 					message: payload.message, 
						// 					userId: id, 
						// 					userRole: payload.userRole, 
						// 					files: getters.newFileInMessages
						// 				};
            //     }
						// 		console.log(body)
            //     req.send(JSON.stringify(body));

            //     req.onreadystatechange = function () {

            //         if (req.readyState === XMLHttpRequest.DONE) {

            //             if (req.status === 200) {
            //                 // commit('setLastPageMessage', getters.pageMessages);
            //                 // commit('resetPageMessages');
            //                 if (payload.callback) {
            //                     payload.callback();
            //                 }
            //                 commit('resetFileInMessages');
            //             }

            //             if (payload.sharePost) {
            //                 errorHandler({name: 'sharedPost', status: req.status})
            //             }
            //         }
            //     }
            // } catch (e) {
            //     console.log(e);
            // }
        // },
        fetchAllNewDialogues({getters, commit}, callback) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/chats/new?timestamp=' + getters.lastTimestamp + '&limit=' + (getters.pageDialogues * getters.limitDialogues) + '&role=' + getters.currentRole;
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = function () {
                    if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                        const res = JSON.parse(req.response);
                        if (res.data.length !== 0)
                            commit('setLastDialogues', res.data.reverse());
                        if (getters.allDialogues.length === 0 && res.data.length === 0) {
                            commit('setLastTimestamp', new Date(Date.now()).toISOString());
                        }
                        if (callback)
                            callback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        paginationMessage({getters, commit}, callback) {
            const id = Cookies.get('currentDialogueId')
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'user/chats/' + id + '?page=' + getters.pageMessages + '&limit=' + getters.limitMessages;
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = function () {
                    if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                        let res = JSON.parse(req.response);
                        commit('addOldMessages', res.data.reverse());
                        if (res.data.length < getters.limitMessages)
                            callback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

    },
    mutations: {
        setWorkingMessage(state, data) {
            const index = state.currentDialogue.map(item => item.id).indexOf(data.id);
            if (index !== -1) {
                state.currentDialogue[index].message = data.message;
            }
        },
        updateUserData(state) {
            state.userName = Cookies.get('currentDialogueName');
            state.userAvatar = Cookies.get('currentDialogueAvatar');
            state.userId = Cookies.get('currentDialogueId');
            state.userRole = Cookies.get('currentUserRole');

        },
        fileUpdateCallback(state, data) {
            // state.countFilesInMessage++;
            state.newFileInMessages = state.newFileInMessages + data + ';';
            // if (state.countFilesInMessage === state.fileInMessage.length)
            //     data.callback();
        },

        addAllDialogues(state, data) {
            state.allDialogues = state.allDialogues.concat(data);
        },
        setLastPageMessage(state, data) {
            state.lastPageMessage = data;
        },
        addOldMessages(state, data) {
            state.currentDialogue = data.concat(state.currentDialogue)
        }, 
				addNewMessages(state, data) {
            state.currentDialogue = state.currentDialogue.concat(data)
        },

        addCurrentDialogue(state, data) {
            if (data.length !== 0) {
                for (let i = 0; i < data.length; i++) {
                    let add = true;
                    for (let j = 0; j < state.currentDialogue.length; j++) {
                        if (data[i].id === state.currentDialogue[j].id) {
                            add = false;
                        }
                    }
                    if (add) {
                        if (state.currentDialogue.length === state.limitMessages) {
                            state.currentDialogue.shift();
                        }
                        state.currentDialogue.push(data[i]);
                    }
                }
            }
        },

        setCurrentRole(state, data) {
            state.currentRole = data;
        },
        setAllDialogues(state, data) {
            state.allDialogues = data;
        },
        setCurrentDialogue(state, data) {
            state.currentDialogue = data;
        },
        resetPageMessages(state) {
            state.pageMessages = 1;
        },
        upPageMessages(state) {
            state.pageMessages++;
        },
        resetPageDialogues(state) {
            state.pageDialogues = 1;
        },
        upPageDialogues(state) {
            state.pageDialogues++;
        },
        clearDialogue(state) {
            state.currentDialogue = null;
            Cookies.remove('currentDialogueName');
            Cookies.remove('currentDialogueAvatar');
            Cookies.remove('currentDialogueId');
            Cookies.remove('currentUserRole');
        },
        setLastTimestamp(state, data) {
            state.lastTimestamp = data;
        },
				setChatBotLoaded(state, data) {
            state.chatBotLoaded = data;
        },
        setLastDialogues(state, data) {
            let newDialogue = 0;
            for (let i = 0; i < data.length; i++) {
                let add = true;
                if (state.allDialogues.length !== 0) {
                    for (let j = 0; j < state.allDialogues.length; j++) {
                        if (data[i].userId === state.allDialogues[j].userId) {
                            state.allDialogues.splice(j, 1);
                            state.allDialogues.unshift(data[i]);
                            add = false;
                        }
                    }
                    if (add) {
                        state.allDialogues.unshift(data[i]);
                        newDialogue++;
                        if (state.allDialogues.length === state.pageDialogues * state.limitDialogues)
                            state.allDialogues.splice(state.allDialogues[state.allDialogues.length - newDialogue], 1);
                    }
                } else {
                    state.allDialogues.push(data[i]);
                }
            }
            state.lastTimestamp = new Date(state.allDialogues[0].createDate).toISOString();
        },
        resetFileInMessages(state) {
            state.fileInMessage = [];
            state.newFileInMessages = '';
        },
        deleteFileInMessages(state, payload) {
            state.fileInMessage.splice(payload.index, 1);
        },
        addFileInMessages(state, data) {
            state.fileInMessage.push(data);
        },
		setActiveComponent(state, componentName) {
			state.activeComponent = componentName;
		},
        setUserRole(state, data){
            state.userRole = data;
        },
    },
    state: {
        pageMessages: null,
        limitMessages: 20,
        pageDialogues: null,
        limitDialogues: 10,
        currentRole: 'all',
        allDialogues: null,
        currentDialogue: null,
        lastTimestamp: null,
        lastPageMessage: null,
        fileInMessage: [],
        newFileInMessages: '',
        countFilesInMessage: 0,
        userName: null,
        userAvatar: null,
        userId: null,
        userRole: null,
		chatBotLoaded:false,
		activeComponent: null,

    },
    getters: {
        pageMessages: (state) => {
            return state.pageMessages;
        },
				chatBotLoaded: (state) => {
            return state.chatBotLoaded;
        },
        limitMessages: (state) => {
            return state.limitMessages;
        },
        pageDialogues: (state) => {
            return state.pageDialogues;
        },
        limitDialogues: (state) => {
            return state.limitDialogues;
        },
        currentDialogue(state) {
            return state.currentDialogue;
        },
        currentRole: (state) => {
            return state.currentRole;
        },
        allDialogues: (state) => {
            return state.allDialogues;
        },  
				activeComponent: (state) => {
            return state.activeComponent;
        },
        lastTimestamp: (state) => {
            return state.lastTimestamp;
        },
        lastPageMessage: (state) => {
            return state.lastPageMessage;
        },
        fileInMessage: (state) => {
            return state.fileInMessage;
        },
        newFileInMessages: (state) => {
            return state.newFileInMessages;
        },
        userName: (state) => {
            return state.userName;
        },
        userAvatar: (state) => {
            return state.userAvatar;
        },
        userId: (state) => {
            return state.userId;
        },
        userRole: (state) => {
            return state.userRole;
        }
    }
}