<template>
  <div>
    <HeaderAccountCompany></HeaderAccountCompany>
    <ModalWindow v-if="viewVideo" @close="viewVideo = false" :questions="currentQuestions"
      :video="currentVideo + '?token=' + $store.getters.token"></ModalWindow>
    <main class="main">
      <div class="account">
        <MenuCompany></MenuCompany>
        <div class="container-account">
          <div class="responses__wrapper">
            <div class="responses__wrapper-company">
              <div class="responses__wrapper-company-persone">
                <div class="responses__wrapper-company-persone-representation">
                  <img :src="dataResponse.candidateAvatarUrl" alt="" class="
                      responses__wrapper-company-persone-representation-icon
                    " />
                  <div class="rating-number-container">
                    <p class="rating-number">
                      {{
                        dataResponse.candidateRate
                          ? dataResponse.candidateRate
                          : 0
                      }}
                    </p>
                  </div>
                  <p class="
                      responses__wrapper-company-persone-representation-employer
                    ">
                    {{ dataResponse.candidateName }}
                  </p>
                  <p class="
                      responses__wrapper-company-persone-representation-designation
                    ">
                    {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.jobSeeker') }}
                  </p>
                  <div class="
                      responses__wrapper-company-persone-representation-information
                    ">
                    <p class="
                        responses__wrapper-company-persone-representation-information-place
                      ">
                      {{
                        dataResponse.candidateCity
                          ? dataResponse.candidateCity
                          : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                      }}
                    </p>
                    <p class="
                        responses__wrapper-company-persone-representation-information-age
                      ">
                      {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.dateOfBirth') }} -
                      {{
                        new Date(dataResponse.candidateBirthDate).toDateString()
                      }}
                    </p>
                  </div>
                </div>

                <div class="responses__wrapper-company-persone-suggestions"
                  v-if="dataResponse.responseStatus === 'new'">

                  <div class="search__sorting-price-container">
                    <button ref="select" class="search__sorting-price" @click="selectVisable = !selectVisable">
                      {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.inviteForInterview') }}
                    </button>
                    <div class="search__sorting-price-group" v-if="selectVisable">
                      <div class="search__sorting-price-element-element" v-for="item in dataInterview" :key="item.id">
                        <input type="radio" :id="item.id" :value="item.id" class="search__sorting-price-element-input"
                          v-model="interviewId" v-on:change="sendAnswer(interviewId)" />
                        <label class="search__sorting-price-element-element-label" :for="item.id">
                          {{ item.title }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <button @click="sendAnswer(null)" class="responses__wrapper-company-persone-suggestions-test">
                    {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.reject') }}
                  </button>
                </div>

                <div v-else class="responses__wrapper-company-status">
                  <div class="responses__wrapper-company-status-caption"></div>
                  <p class="responses__wrapper-company-status-stage" :class="{
                    'suggestions-work-red':
                      dataResponse.responseStatus === 'reject',
                  }">
                    <span v-if="dataResponse.responseStatus === 'invite'">{{
                      $t('pages.account.account_company.accountCompanySeparateResponsePage.invitedForInterview')
                      }}</span>
                    <span v-else>{{ $t('pages.account.account_company.accountCompanySeparateResponsePage.rejected')
                      }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="responses__wrapper-info">
              <h3 class="responses__wrapper-info-title">
                {{
                  dataResponse.candidateSpecialization
                    ? dataResponse.candidateSpecialization
                    : $t('pages.account.account_company.accountCompanySeparateResponsePage.specialtyNotSelected')
                }}
              </h3>
              <div class="responses__wrapper-info-list">
                <div class="responses__wrapper-info-list-element">
                  <p class="responses__wrapper-info-list-element-name">
                    {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.expectedWage') }}
                  </p>
                  <p class="
                      responses__wrapper-info-list-element-information
                      text-blue
                    ">
                    {{
                      dataResponse.candidateSalary
                        ? dataResponse.candidateSalary
                        : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                    }}
                  </p>
                </div>
                <div class="responses__wrapper-info-list-element">
                  <p class="responses__wrapper-info-list-element-name">
                    {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.higherEducation') }}
                  </p>
                  <p class="
                      responses__wrapper-info-list-element-information
                      text-blue
                    ">
                    {{
                      dataResponse.candidateUniversityName
                        ? dataResponse.candidateUniversityName
                        : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                    }}
                  </p>
                </div>
              </div>
              <div class="responses__wrapper-info-about">
                <h1 class="responses__wrapper-info-about-title">{{
                  $t('pages.account.account_company.accountCompanySeparateResponsePage.aboutMyself') }}</h1>
                <p class="responses__wrapper-info-about-text">
                  {{
                    dataResponse.candidateAboutText
                      ? dataResponse.candidateAboutText
                      : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                  }}
                </p>
                <div class="about__wrapper-data-skills data-standart-block" style="margin-top: 20px">
                  <h1 class="responses__wrapper-info-about-title">{{
                    $t('pages.account.account_company.accountCompanySeparateResponsePage.videoInterview') }}</h1>
                  <button class="already-recorded-button" v-if="dataResponse.videoInterviewUrl"
                    @click="watchingVideo('interview')">{{
                      $t('pages.account.account_company.accountCompanySeparateResponsePage.watchVideo') }}
                  </button>
                  <p class="responses__wrapper-info-about-text" v-else>{{
                    $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined') }}</p>
                </div>
                <div class="about__wrapper-data-skills data-standart-block">
                  <h1 class="responses__wrapper-info-about-title">{{
                    $t('pages.account.account_company.accountCompanySeparateResponsePage.psychologicalTest') }}</h1>
                  <button class="already-recorded-button" v-if="dataResponse.psychologicalTestUrl"
                    @click="watchingVideo('test')">{{
                      $t('pages.account.account_company.accountCompanySeparateResponsePage.watchVideo') }}</button>
                  <p class="responses__wrapper-info-about-text" v-else>{{
                    $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined') }}</p>
                </div>
                <h2 class="responses__wrapper-info-about-contacts-title">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.contactInformation') }}
                </h2>
                <div class="responses__wrapper-info-about-contacts-group">
                  <div class="responses__wrapper-info-about-contacts-group-element">
                    <p class="data-text">{{
                      $t('pages.account.account_company.accountCompanySeparateResponsePage.telephone') }}</p>
                    <a href="#" class="
                        responses__wrapper-info-about-contacts-group-element-link
                      ">{{ dataResponse.candidatePhone }}</a>
                  </div>
                  <div class="responses__wrapper-info-about-contacts-group-element">
                    <p class="data-text">{{ $t('pages.account.account_company.accountCompanySeparateResponsePage.email')
                      }}</p>
                    <a href="#" class="
                        responses__wrapper-info-about-contacts-group-element-link
                      ">{{ dataResponse.candidateEmail }}</a>
                  </div>
                  <div class="responses__wrapper-info-about-contacts-group-element">
                    <p class="data-text data-social">{{
                      $t('pages.account.account_company.accountCompanySeparateResponsePage.socialNetworks') }}</p>
                    <div class="
                        responses__wrapper-info-about-contacts-group-element-social
                      ">
                      <div class="
                          responses__wrapper-info-about-contacts-group-element-social-logo
                        "></div>
                      <div class="
                          responses__wrapper-info-about-contacts-group-element-social-logo
                        "></div>
                      <div class="
                          responses__wrapper-info-about-contacts-group-element-social-logo
                        "></div>
                      <div class="
                          responses__wrapper-info-about-contacts-group-element-social-logo
                        "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="responses__wrapper-info-education data-standart-block">
                <h3 class="responses__wrapper-info-education-title">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.nameOfUniversity') }}
                </h3>
                <p class="education-text"></p>
                <p class="education-description">
                  {{
                    dataResponse.candidateUniversityName
                      ? dataResponse.candidateUniversityName
                      : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                  }}
                </p>
                <p class="education-text"></p>
                <h3 class="responses__wrapper-info-education-title">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.levelOfEducation') }}
                </h3>
                <p class="education-text"></p>
                <p class="education-description">
                  {{
                    dataResponse.educationLevel === '1'
                      ? 'B.S.' : dataResponse.educationLevel === '2' ? 'M.S.' : dataResponse.educationLevel === '3' ?
                        'Ph.D.' : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                  }}
                </p>
                <p class="education-text"></p>
                <h3 class="responses__wrapper-info-education-title">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.schoolName') }}
                </h3>
                <p class="education-text"></p>
                <p class="education-description">
                  {{
                    dataResponse.candidateSchoolName
                      ? dataResponse.candidateSchoolName
                      : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                  }}
                </p>
              </div>
              <div class="
                  responses__wrapper-info-qualification
                  data-standart-block
                ">
                <h3 class="responses__wrapper-info-education-title">{{
                  $t('pages.account.account_company.accountCompanySeparateResponsePage.skills') }}</h3>
                <p class="education-description">
                  {{
                    dataResponse.candidateSkills
                      ? dataResponse.candidateSkills
                      : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                  }}
                </p>
              </div>
              <div class="responses__wrapper-info-experience data-standart-block">
                <h4 class="responses__wrapper-info-experience-title">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.achievements') }}
                </h4>
                <div class="responses__wrapper-info-experience-group">
                  <div class="responses__wrapper-info-experience-group-element">
                    <p class="education-description">
                      {{
                        dataResponse.candidateAchievements
                          ? dataResponse.candidateAchievements
                          : $t('pages.account.account_company.accountCompanySeparateResponsePage.undefined')
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="responses__wrapper-info-message">
                <p class="responses__wrapper-info-message-caption">
                  {{ $t('pages.account.account_company.accountCompanySeparateResponsePage.yourMessage') }}
                </p>
                <div class="responses__wrapper-info-message-filling">
                  <p class="responses__wrapper-info-message-filling-text">
                    {{ dataResponse.responseCoverLetter }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ModalWindow from "@/components/account/account_worker/account_worker_video/ModalWindow";
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";

export default {
  computed: {
    currentQuestions() {
      return this.$store.getters.currentVideoInterview;
    }
  },
  methods: {
    async watchingVideo(mode) {
      if (mode === 'interview') {
        this.currentVideo = this.dataResponse.videoInterviewUrl;
      } else {
        this.currentVideo = this.dataResponse.psychologicalTestUrl;
      }
      await this.$store.dispatch('fetchCurrentVideoInterview', mode);
      this.viewVideo = true;

    },
    sendAnswer(interviewId) {
      if (interviewId === null) {
        this.dataResponse.responseStatus = "reject";
      } else {
        this.dataResponse.responseStatus = "invite";
        this.dataResponse.responseInterviewId = interviewId;
      }
      let xmlHttp = new XMLHttpRequest();
      let url = config.apiPrefix + "employer/interview/request";
      xmlHttp.open("POST", url, false);
      let body = {
        responseId: this.responseId,
        interviewId: interviewId,
        status: this.dataResponse.responseStatus,
      };
      xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      xmlHttp.setRequestHeader(
        "Content-Type",
        "application/json;charset=UTF-8"
      );
      try {
        xmlHttp.send(JSON.stringify(body));
        let response = JSON.parse(xmlHttp.response);
        console.log("Data: " + response.data);
      } catch (e) {
        console.log(e);
      }
      // TODO: connect to errorHandler
      if (xmlHttp.status === 200) {
        if (body.status === "invite") {
          errorHandler({ status: xmlHttp.status, page: "invite" });
        } else {
          errorHandler({ status: xmlHttp.status, page: "reject" });
        }
      } else {
        errorHandler({ status: xmlHttp.status });
      }
    },
  },
  components: { MenuCompany, HeaderAccountCompany, ModalWindow },
  props: {
    responseId: {},
  },
  data() {
    return {
      dataResponse: {},
      interviewId: null,
      dataInterview: {},
      selectVisable: false,
      viewVideo: false,
      currentVideo: null
    };
  },

  name: "AccountCompanySeparateResponsePage",
  beforeMount() {
    scroll(0, 0);
    let xmlHttp = new XMLHttpRequest();
    let url = config.apiPrefix + "employer/response/get/" + this.responseId;
    xmlHttp.open("GET", url, false);
    xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
    try {
      xmlHttp.send();
      let response = JSON.parse(xmlHttp.response);
      this.dataResponse = response.data;
    } catch (e) {
      console.log(e);
    }
    url = config.apiPrefix + "interview/all";
    xmlHttp.open("GET", url, false);
    xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
    try {
      xmlHttp.send();
      let response = JSON.parse(xmlHttp.response);
      this.dataInterview = response.data;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>



<style scoped>
.already-recorded-button {
  margin: 20px 0px;
  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #009ed1;
  border-radius: 12px;
  max-width: 207px;
  width: 100%;
  transition: opacity 1s ease;
}

.already-recorded-button:hover {
  opacity: 0.5;
}

.account {
  display: flex;
}

.mobile {
  display: none;
}

.responses__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.responses__title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.responses__caption {
  display: flex;
  align-items: center;
}

.responses__caption-title {
  position: relative;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.responses__caption-title::after {
  position: absolute;
  top: 7%;
  right: -25px;
  content: "/";
  display: inline-block;
  width: 10px;
  height: 32px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
  opacity: 0.29;
}

.responses__caption-text {
  padding-left: 48px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #009ed1;
}

.responses__wrapper {
  margin-top: 50px;
  display: flex;
}

.responses__wrapper-company {
  max-width: 337px;
  width: 100%;
  min-width: 300px;
}

.responses__wrapper-company-persone {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(59, 76, 104, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.responses__wrapper-company-persone-representation {
  padding: 20px 22px 25px 22px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.responses__wrapper-company-persone-suggestions {
  box-sizing: border-box;
  width: 100%;
  padding: 32px 22px 36px 22px;
}

.responses__wrapper-company-persone-representation-icon {
  object-fit: cover;
  object-position: center;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  border-radius: 64px;
}

.responses__wrapper-company-persone-representation-rating {
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
}

.responses__wrapper-company-persone-representation-rating-number {
  padding: 5px 2px 5px 25px;
  background: url("~@/assets/img/about-person-page/responses/star.png");
  background-repeat: no-repeat;
  background-position: 9px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.1px;
  color: #14c686;
}

.about-rating {
  width: 49px;
  margin: 10px auto 0 auto;
  order: unset;
}

.responses__wrapper-company-persone-representation-employer {
  margin-top: 10px;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.responses__wrapper-company-persone-representation-designation {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #b7c1d1;
}

.responses__wrapper-company-persone-representation-more {
  margin-top: 46px;
  height: 50px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #696974;
  background: #f1f1f5;
  border-radius: 10px;
}

.responses__wrapper-company-persone-representation-information {
  justify-content: center;
  align-items: center;
  margin-top: 19px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #000000;
}

.responses__wrapper-company-persone-representation-information-place {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 18px;
  background-image: url("~@/assets/img/about-person-page/responses/place.png");
  background-repeat: no-repeat;
  background-position-y: center;
  border-right: 1px solid #919eb4;
  padding-right: 13px;
}

.responses__wrapper-company-persone-representation-information-age {
  padding-left: 16px;
}

.responses__wrapper-company-persone-suggestions-work {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 45px;
  border: none;
  background-color: #009ed1;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffffff;
  transition: opacity 1s ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.responses__wrapper-company-persone-suggestions-work:hover {
  opacity: 0.5;
}

.responses__wrapper-company-persone-suggestions-test {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  height: 45px;
  background: #f1f5f9;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #009ed1;
  transition: opacity 1s ease;
}

.responses__wrapper-company-persone-suggestions-test:hover {
  opacity: 0.5;
}

.responses__wrapper-company-status {
  padding: 16px 22px 17px 22px;
  background: #ffffff;
  border-radius: 0px 0 20px 20px;
}

.responses__wrapper-company-status-caption {
  display: flex;
  align-items: center;
}

.responses__wrapper-company-status-caption-name {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.responses__wrapper-company-status-caption-date {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
  opacity: 0.4;
}

.responses__wrapper-company-status-stage {
  text-align: center;
  display: block;
  padding: 2px 0;
  margin-top: 10px;
  max-width: 100%;
  background: rgba(184, 242, 193, 0.20000000298023224);
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #44c194;
}

.responses__wrapper-company-contact {
  margin-top: 21px;
  width: 100%;
  height: 50px;
  background: #14c686;
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  transition: opacity 1s ease;
}

.responses__wrapper-company-contact:hover {
  opacity: 0.5;
}

.responses__wrapper-info {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-left: 80px;
}

.responses__wrapper-info-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  color: #000000;
}

.responses__wrapper-info-list {
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.responses__wrapper-info-list-element-name {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 11px;
  line-height: 1.5;
  color: #000000;
}

.responses__wrapper-info-list-element-information {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  color: #000000;
}

.text-blue {
  color: #009ed1;
}

.responses__wrapper-info-message {
  margin-top: 45px;
  border: 1px solid rgba(59, 76, 104, 0.1);
  border-radius: 20px;
}

.responses__wrapper-info-message-caption {
  padding: 15px 0 15px 0px;
  background: #f6f6f8;
  border-radius: 20px 20px 0px 0px;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 15px;
  line-height: 1.6;
  color: #2f2f2f;
  text-align: center;
}

.responses__wrapper-info-message-filling {
  padding: 33px 31px 38px 37px;
  border-radius: 0px 0px 20px 20px;
  background: #fff;
}

.responses__wrapper-info-message-filling-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 24px;
  line-height: 1.6;
  color: #2f2f2f;
}

.responses__wrapper-info-message-filling-text {
  padding-top: 20px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 19px;
  line-height: 1.6;
  color: #424242;
}

.responses__wrapper-info-message-filling-file {
  margin-top: 40px;
  width: 242px;
  background: #e6f6fc;
  border-radius: 7px;
}

.responses__wrapper-info-message-filling-file-text {
  background: url("~@/assets/img/responses-page/responses/file.png");
  background-position: 16px;
  background-repeat: no-repeat;

  padding: 11px 17px 11px 47px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #009ed1;
}

.responses__wrapper-info-about {
  padding-top: 37px;
  padding-bottom: 51px;
}

.responses__wrapper-info-about-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #3b4c68;
}

.responses__wrapper-info-about-text {
  padding-top: 25px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
}

.responses__wrapper-info-about-contacts-title {
  padding-top: 37px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #3b4c68;
}

.responses__wrapper-info-about-contacts-group {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.data-text {
  margin-bottom: 9px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
}

.responses__wrapper-info-about-contacts-group-element-link {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
  color: #009ed1;
}

.responses__wrapper-info-about-contacts-group-element-social {
  display: flex;
}

.data-social {
  margin-bottom: 5px;
}

.responses__wrapper-info-about-contacts-group-element-social-logo {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 9px;
  background: rgba(0, 158, 209, 0.15000000596046448);
}

/* data tipicale block */

.data-standart-block {
  padding: 32px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.responses__wrapper-info-education-title {
  margin-bottom: 32px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #3b4c68;
}

.responses__wrapper-info-qualification-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  color: #009ed1;
}

.responses__wrapper-info-experience-title {
  margin-bottom: 32px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #3b4c68;
}

.responses__wrapper-info-education-degree {
  padding-top: 24px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  color: #14c686;
}

.education-text {
  padding-top: 20px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.education-description {
  padding-top: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
  color: #3b4c68;
}

.rating-number-container {
  margin: 10px auto 0 auto;
}

.rating-number {
  display: inline;
  padding: 4px 13px 4px 32px;
  position: relative;
  margin-top: 11px;
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-image: url("~@/assets/img/main-page/jobs/stars/star1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 16px;
  background-position-y: 7px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  color: #14c686;
}

@media (max-width: 1044px) {
  .responses__wrapper-info {
    margin-left: 50px;
  }

  .responses__wrapper-info-about-contacts-group {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
}

@media (max-width: 850px) {
  .already-recorded-button {
    font-size: 13px;
    padding: 13px 0;
    max-width: 140px;
    margin-top: 20px;
  }

  .mobile {
    display: block;
  }

  .about {
    padding-top: 86px;
  }

  .about__wrapper-caption {
    display: flex;
    align-items: center;
  }

  .about__wrapper-caption-back {
    width: 30px;
    height: 10px;
    background: url("~@/assets/img/about-person-page/about/back.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about__wrapper-caption-text {
    margin-left: auto;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    line-height: 35.5px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .about__wrapper {
    flex-direction: column;
  }

  .about__wrapper-worker {
    margin: 17px auto 0 auto;
  }

  .responses__wrapper-info {
    margin-left: 0px;
  }

  .responses__wrapper-info {
    text-align: center;
  }

  .responses__wrapper-info-about {
    padding-top: 30px;
  }

  .about__wrapper-worker-change {
    display: none;
  }

  .responses__wrapper-info-about-contacts-group {
    grid-template-columns: repeat(3, 1fr);
  }

  .responses__wrapper-info-about-contacts-group-element-social {
    justify-content: center;
  }

  .about__wrapper {
    padding-bottom: 0;
  }

  .about__wrapper-worker {
    width: 300px;
    min-width: 300px;
  }

  .responses__wrapper-info-about {
    padding-bottom: 36px;
  }

  .about__wrapper-worker-person-suggestions {
    display: flex;
    flex-direction: column;
  }

  .about__wrapper-worker-person-suggestions-work {
    width: 232px;
    margin: 0 auto;
    font-size: 13px;
  }

  .about__wrapper-worker-person-suggestions-test {
    width: 232px;
    margin: 10px auto 0px auto;
    font-size: 13px;
  }

  .responses__wrapper-info-about-title {
    font-size: 15px;
  }

  .responses__wrapper-info-about-text {
    padding-top: 15px;
    font-size: 15px;
  }

  .responses__wrapper-info-about-contacts-title {
    font-size: 15px;
  }

  .responses__wrapper-info-about-contacts-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .responses__wrapper-info-about-contacts-group-element-link {
    font-size: 17px;
  }

  .responses__wrapper-info-education-title {
    font-size: 15px;
  }

  .responses__wrapper-info-education-degree {
    font-size: 14px;
    padding-top: 16px;
  }

  .education-text {
    padding-top: 16px;
    font-size: 14px;
  }

  .education-description {
    padding-top: 6px;
    font-size: 15px;
    line-height: 1.7;
  }

  .responses__wrapper-info-qualification-title {
    font-size: 14px;
  }

  .responses__wrapper-info-experience-title {
    font-size: 15px;
    margin-bottom: 16px;
  }

  .responses__wrapper-info-skills-title {
    font-size: 15px;
  }

  .responses__wrapper-info-skills-group {
    flex-direction: column;
  }

  .responses__wrapper-info-skills-group-element-text {
    padding-left: 0;
    margin-bottom: 23px;
  }

  .skills-left-element {
    margin-right: 0;
  }

  .responses__wrapper-info-message-filling {
    padding: 20px 15px 23px 15px;
  }

  .responses__wrapper-info-message-filling-title {
    font-size: 15px;
  }

  .responses__wrapper-info-message-filling-text {
    font-size: 13px;
    padding-top: 15px;
  }

  .responses__wrapper-info-message-filling-file {
    max-width: 256px;
    width: 100%;
    margin-top: 30px;
  }

  .responses__wrapper-info-message-filling-file-text {
    text-align: left;
  }
}

@media (max-width: 400px) {
  .responses__wrapper-info {
    text-align: left;
  }

  .responses__wrapper-info-about-contacts-group-element-social {
    justify-content: left;
  }

  .responses__wrapper-info-skills-group-element-text {
    padding-left: 25px;
  }

  .about__wrapper-worker-person-representation {
    padding: 20px 17px 27px 17px;
    width: 100%;
    box-sizing: border-box;
  }

  .about__wrapper-worker-person-representation-more {
    margin-top: 20px;
  }

  .about__wrapper-worker-person-representation-employer {
    font-size: 24px;
  }

  .about__wrapper-worker-person-representation-designation {
    font-size: 16px;
  }
}

/* /About */

/* Similar */
.similar {
  padding-top: 48px;
  padding-bottom: 87px;
}

.similar__title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #3b4c68;
}

.similar__vacncy {
  width: 100%;
  box-sizing: border-box;
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 960px) {
  .similar__title {
    text-align: center;
  }

  .similar__vacncy {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .similar__title {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .similar {
    padding: 24px 0 65px 0;
  }

  .similar__title {
    text-align: left;
  }

  .similar__vacncy {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
  }
}

/* /Similar */

.mobile-back {
  display: none;
}

@media (max-width: 1470px) {
  .responses__wrapper-info-list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
}

@media (max-width: 1180px) {
  .responses__wrapper-info {
    margin-left: 40px;
  }
}

@media (max-width: 850px) {
  .mobile {
    display: block;
  }

  .desctop {
    display: none;
  }

  .responses {
    padding-top: 28px;
  }

  .responses__text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
    text-align: center;
  }

  .responses__title {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    color: #000000;
    text-align: center;
  }

  .responses__wrapper {
    margin-top: 28px;
    flex-direction: column;
  }

  .responses__wrapper-company {
    margin: 0 auto;
  }

  .responses__wrapper-info {
    margin-top: 46px;
    margin-left: 0;
  }

  .responses__wrapper-info-title {
    text-align: center;
    font-size: 25px;
  }

  .responses__wrapper-info-list {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .responses__wrapper-company-persone-suggestions {
    display: flex;
    flex-direction: column;
  }

  .responses__wrapper-company-persone-suggestions-work {
    width: 100%;
    margin: 0 auto;
    font-size: 13px;
  }

  .responses__wrapper-company-persone-suggestions-test {
    width: 100%;
    margin: 10px auto 0px auto;
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .responses__wrapper-company-persone-representation-more {
    margin-top: 20px;
  }

  .responses__wrapper-company-persone-representation-employer {
    font-size: 24px;
  }

  .responses__wrapper-company-persone-representation-designation {
    font-size: 16px;
  }
}

@media (max-width: 370px) {
  .mobile-back {
    display: block;
  }

  .responses__caption-back {
    margin-top: 10px;
    background: url("~@/assets/img/responses-page/responses/back.svg");
    background-position: 0;
    background-repeat: no-repeat;
    margin-top: 10px;
    padding-left: 32px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #009ed1;
  }

  .responses__wrapper-company {
    width: 100%;
  }

  .responses__wrapper-company-contact {
    height: 42px;
  }

  .responses__text {
    text-align: left;
  }

  .responses__title {
    text-align: left;
  }

  .responses__wrapper-info-title {
    text-align: left;
  }

  .responses__wrapper-info-list {
    text-align: left;
  }

  .responses__wrapper-info-list-element-name {
    font-size: 10px;
  }

  .responses__wrapper-info-list-element-information {
    font-size: 11px;
  }

  .responses__wrapper-info-text {
    text-align: left;
  }

  .responses__wrapper-info-message {
    margin-top: 30px;
  }

  .responses__wrapper-info-message-caption {
    text-align: center;
    padding-left: 0;
    font-size: 11px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

/* Price select */
.search__sorting-price-container {
  width: 100%;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  display: block;
  align-items: center;
  transition: background-color 1s ease;
  height: 45px;
}

.search__sorting-price-container:hover {
  background-color: #80cfe8;
}

.search__sorting-price {
  padding: 15px 12px;
  width: 100%;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  background-color: #009ed1;
  box-sizing: border-box;
  transition: background-color 1s ease;
}

.search__sorting-price:hover {
  background-color: #80cfe8;
}

.search__sorting-price-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
}

.search__sorting-price-element-input {
  -webkit-appearance: none;
  height: 0px;
}

.search__sorting-price-element-element {
  background-color: #009ed1;
  cursor: pointer;

  width: 100%;
  border-top: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  transition: background-color 1s ease;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.search__sorting-price-element-element:hover {
  background-color: #80cfe8;
}

.search__sorting-price-element-element-label {
  width: 100%;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 7px 12px;
  cursor: pointer;
}

.search__sorting-price-element-element:first-child {
  border-radius: 8px 8px 0 0;
}

.search__sorting-price-element-element:last-child {
  border-radius: 0px 0px 8px 8px;
}

@media (max-width: 766px) {
  .search__sorting-price-container {
    margin-left: 0;
  }
}

/* /Price select */
.select_picked {
  background-color: #eefbff;
  color: #009ed1;
}

.sort_picked {
  background-color: #eefbff;
  color: #009ed1;
}

/* Modificators */
.suggestions-work-red {
  pointer-events: none;
  background: #f9eeee;
  color: #ab3e5e;
}

.suggestions-work-green {
  background: #14c686;
}

.suggestions-work-gray {
  pointer-events: none;
  background: #f1f1f5;
  color: #696974;
}

/* /Modificators */
</style>
