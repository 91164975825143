<template>
  <div class="similar__vacncy-element">
    <div class="similar__vacncy-element-nomiation">
      <div class="similar__vacncy-element-nomiation-icon">
        <img :src="vacancy.companyAvatarUrl" alt="Company" class="recomedation-img" />
      </div>
      <div class="similar__vacncy-element-nomiation-name">
        <p class="similar__vacncy-element-nomiation-name-sign mobile">{{
          $t('components.account_worker.separateRecomendationSameVacancy.company') }}</p>
        <p class="similar__vacncy-element-nomiation-name-rating-number">
          {{ vacancy.companyRate ? vacancy.companyRate : 0 }}
        </p>
        <p class="similar__vacncy-element-nomiation-name-company">
          {{ vacancy.companyName ? limit(vacancy.companyName, 25) :
            $t('components.account_worker.separateRecomendationSameVacancy.noData') }}
        </p>
      </div>
    </div>
    <div class="similar__vacncy-element-description">
      <h5 class="similar__vacncy-element-description-title">
        {{ vacancy.vacancyName ? limit(vacancy.vacancyName, 25) :
          $t('components.account_worker.separateRecomendationSameVacancy.noData') }}
      </h5>
      <p class="similar__vacncy-element-description-text">
        {{ vacancy.description ? limit(vacancy.description, 120) :
          $t('components.account_worker.separateRecomendationSameVacancy.noData') }}
      </p>
      <p class="similar__vacncy-element-description-place">
        {{ vacancy.city ? limit(vacancy.city, 25) : $t('components.account_worker.separateRecomendationSameVacancy.noData') }}
      </p>
      <div class="similar-rating">
        <p class="similar-rating__text">{{ $t('components.account_worker.separateRecomendationSameVacancy.requiredRating') }}
          <span>{{ $t('components.account_worker.separateRecomendationSameVacancy.from') }}</span></p>
        <p class="caption__nomination-name-company-rating-tally">
          {{ vacancy.vacancyRate ? vacancy.vacancyRate : 0 }}
        </p>
      </div>
    </div>
    <p class="similar__vacncy-element-price">
      {{ (vacancy.payFrom && vacancy.payFrom !== 0) ? salary :
        $t('components.account_worker.separateRecomendationSameVacancy.interviewResults') }}
    </p>
    <button @click="goToVacancy" class="similar__vacncy-element-btn">{{
      $t('components.account_worker.separateRecomendationSameVacancy.more') }}</button>
    <button @click="toggleFavoriteList" class="favorite" :class="{ 'favorite--active': isFavorite }">
      <svg viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
          stroke="#009ED1" />
      </svg>
    </button>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { convertSalary } from "@/lib/values";

export default {
  name: "SeparateRecomendation",
  props: ['vacancy', 'isFavorite'],
  data() {
    return {
      limit: textSymbolLimit,
      localIsFavorite: this.isFavorite,
    }
  },
  watch: {
    isFavorite(newValue) {
      this.localIsFavorite = newValue;
    },
  },
  computed: {
    salary() {
      return this.convertSalary(this.vacancy?.payFrom ? this.vacancy?.payFrom : 0, this.vacancy?.expectedSalaryCurrency ? this.vacancy?.expectedSalaryCurrency.toUpperCase() : "usd", this.$store.getters?.user?.expectedSalaryCurrency ? this.$store.getters?.user?.expectedSalaryCurrency.toUpperCase() : 'usd')
    },
  },
  methods: {
    convertSalary,
    toggleFavoriteList() {
      this.localIsFavorite = !this.localIsFavorite;
      if (this.localIsFavorite) {
        this.$store.dispatch("addFavoriteVacancy", {
          token: this.$store.getters.token,
          vacancy: this.vacancy,
        })
      } else {
        this.$store.dispatch("removeFavoriteVacancy", {
          token: this.$store.getters.token,
          vacancy: this.vacancy,
        })
      }
    },
    goToVacancy() {
      this.$router.push({ name: 'vacancyDashboard', params: { vacancyId: this.vacancy.id } })
    }
  }
};
</script>


<style scoped>
.similar__vacncy-element {
  width: 367px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  position: relative;
}

.similar__vacncy-element-nomiation {
  margin: 20px 20px 0 20px;
  max-width: 327px;
  display: flex;
  align-items: center;
}

.recomedation-img {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;

}


.similar__vacncy-element-nomiation-name {
  margin-left: 19px;
}


.similar__vacncy-element-nomiation-name-company {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  margin-top: 3px;
}

.similar__vacncy-element-description {
  padding: 17px 20px 16px 20px;
  max-width: 327px;
  border-bottom: 1px solid #f1f1f5;
}

.similar__vacncy-element-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #171625;
}

.similar__vacncy-element-description-text {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.similar__vacncy-element-description-place {
  margin-top: 10px;
  padding-left: 18px;
  background-image: url("~@/assets/img/recommendation-page/recommendation/place.svg");
  background-position-y: 3px;
  background-repeat: no-repeat;
  align-items: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.similar__vacncy-element-price {
  margin: 14px 20px 0 20px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.similar__vacncy-element-btn {
  margin: 20px;
  width: 327px;
  height: 50px;
  background: #f1f1f5;
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #696974;
  transition: opacity 1s ease;
}

.similar__vacncy-element-btn:hover {
  opacity: 0.5;
}


.similar__vacncy-element-nomiation-name-rating-number {
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-repeat: no-repeat;
  background-position-y: 5px;
  padding: 3px 9px 3px 9px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  border-radius: 20px;
  display: inline;
  line-height: 1;
}

.similar-rating {
  margin-top: 19px;
  display: flex;
  align-items: center;
}

.similar-rating__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.similar-rating__text span {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.caption__nomination-name-company-rating-tally {
  background-image: url("~@/assets/img/company-page/caption/star.svg");
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-size: 11px;
  border-radius: 20px;
  margin-left: 4px;
  background-repeat: no-repeat;
  background-position: 9px;
  background-position-y: 5px;
  padding: 4px 9px 4px 25px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
}

.mobile {
  display: none;
}

@media (max-width: 1470px) {
  .similar__vacncy-element {
    width: 300px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }
}

@media (max-width: 1070px) {
  .similar__vacncy-element {
    width: 400px;
  }

  .similar__vacncy-element-btn {
    width: 360px;
  }

  .similar__vacncy-element:nth-child(3n+2) {
    margin: 0 0 0 auto;
  }

  .similar__vacncy-element:nth-child(3n+3) {
    margin-left: 0 !important;
  }
}

@media (max-width: 960px) {
  .similar__vacncy-element {
    width: 367px;
    margin: 10px auto 0 auto;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }

  .similar__btn {
    width: 367px;
    margin: 36px auto 57px auto;
  }

  .similar__vacncy-element-btn {
    width: 327px;
  }

  .similar__vacncy-element {
    margin: 0 auto !important;
  }

  .similar__vacncy-element:nth-child(3n+3) {
    margin-left: auto !important;
  }
}

@media (max-width: 786px) {
  .similar__vacncy-element {
    width: 300px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }
}

@media (max-width: 640px) {
  .similar__vacncy-element {
    width: 100%;
    max-width: 300px;
  }

  .similar__vacncy-element-btn {
    width: 260px;
  }
}

@media (max-width: 400px) {
  .mobile {
    display: block;
  }

  .similar__vacncy-element {
    width: 300px;
  }

  .similar__vacncy-element-nomiation {
    margin: 0;
    padding: 20px 20px 18px 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  .similar__vacncy-element-nomiation-icon-img {
    padding: 14px 17px;
  }

  .similar__vacncy-element-nomiation-icon-img {
    width: 30px;
    height: 36px;
  }

  .similar__vacncy-element-nomiation-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .similar__vacncy-element-nomiation-name-sign {
    order: 1;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #696974;
  }

  .similar__vacncy-element-nomiation-name-company {
    order: 2;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #171625;
  }

  .similar__vacncy-element-nomiation-name-rating {
    margin-top: 4px;
    order: 3;
  }

  .similar__vacncy-element-nomiation-name-rating-name {
    font-size: 13px;
    line-height: 24px;
  }

  .similar__vacncy-element-description-title {
    font-size: 15px;
  }

  .similar__vacncy-element-description-text {
    font-size: 13px;
  }

  .similar__vacncy-element-price {
    margin: 14px 20px 20px 20px;
  }

  .similar__vacncy-element-btn {
    display: none;
  }


}
</style>
