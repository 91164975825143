<template>
  <div>
    <SocialHeader :role="$store.getters.role"></SocialHeader>
    <QuestionModal @close="viewModal = false" @createNews="createNews" v-if="viewModal" page="community"></QuestionModal>
    <div class="creation" v-if="creationVizable">
      <div class="creation__wrapper">
        <form class="creation__filling">
          <div class="title-wrpper">
            <h3 class="creation__title">{{ $t('pages.social.socialCommunitiesMyListPage.buildingCommunity') }}</h3>
            <button class="cancel-button" @click="creationVizable = false"></button>
          </div>
          <div class="creation__photo" ref="avatarForm"></div>
          <input type="file" ref="avatar" @change="loadAvatar" accept="image/png, image/jpeg" hidden>
          <button type="button" class="creation__download" @click="$refs.avatar.click()">{{
            $t('pages.social.socialCommunitiesMyListPage.uploadAvatar') }}</button>
          <div class="creation__preview" ref="previewForm"></div>
          <input type="file" ref="preview" hidden @change="loadPreview" accept="image/png, image/jpeg">
          <button type="button" class="creation__download" @click="$refs.preview.click()">{{
            $t('pages.social.socialCommunitiesMyListPage.uploadPreview') }}</button>
          <input type="text" :placeholder="$t('pages.social.socialCommunitiesMyListPage.name')" v-model="saveCommunity.name"
            class="creation__input">
          <textarea class="creation__textarea" v-model="saveCommunity.description"
            :placeholder="$t('pages.social.socialCommunitiesMyListPage.description')"></textarea>
          <p class="creation__text">{{ $t('pages.social.socialCommunitiesMyListPage.communityType') }}</p>
          <div class="creation-choice">
            <div @click="saveCommunity.communityType = 'open'" class="creation-choice__element"
              :class="{ 'creation-choice__element-selected': saveCommunity.communityType === 'open' }">
              {{ $t('pages.social.socialCommunitiesMyListPage.open') }}
            </div>
            <div @click="saveCommunity.communityType = 'close'" class="creation-choice__element"
              :class="{ 'creation-choice__element-selected': saveCommunity.communityType === 'close' }">
              {{ $t('pages.social.socialCommunitiesMyListPage.closed') }}
            </div>
          </div>
          <div class="creation-create">
            <button @click="createCommunity" type="button" class="creation-create__button">{{
              $t('pages.social.socialCommunitiesMyListPage.createCommunity') }}</button>
          </div>
        </form>
      </div>
    </div>

    <main class="main">
      <div class="account">
        <SocialMenu :role="$store.getters.role"></SocialMenu>
        <div class="container-account">
          <p class="subtitle">{{ $t('pages.social.socialCommunitiesMyListPage.communities') }}</p>
          <div class="title__wrapper">
            <h1 class="title">{{ $t('pages.social.socialCommunitiesMyListPage.communities') }}</h1>
            <button class="community-who mobile-block" @click="statusVizable = !statusVizable">
              <p class="community-who-text">{{ $t('pages.social.socialCommunitiesMyListPage.myCommunities') }} <span
                  class="community-who-img"></span></p>
              <div class="community" v-if="statusVizable">
                <div class="community__caption">
                  <button @click="changeRole('community')" class="community__caption-btn"
                    :class="{ 'community__caption-btn-selected': getMode === 'community' }">
                    {{ $t('pages.social.socialCommunitiesMyListPage.myCommunities') }}
                  </button>
                  <button @click="changeRole('request')" class="community__caption-btn"
                    :class="{ 'community__caption-btn-selected': getMode === 'request' }">
                    {{ $t('pages.social.socialCommunitiesMyListPage.myApplications') }}
                  </button>
                </div>
              </div>
            </button>
          </div>
          <nav class="nav" v-if="getMode === 'community'">
            <div class="list">
              <button @click="changeMode('all')" class="list__item"
                :class="{ 'list__item-selected': getCommunityRole === 'all' }">
                {{ $t('pages.social.socialCommunitiesMyListPage.allCommunities') }}
              </button>
              <button @click="changeMode('my')" class="list__item"
                :class="{ 'list__item-selected': getCommunityRole === 'my' }">
                {{ $t('pages.social.socialCommunitiesMyListPage.myCommunities') }}
              </button>
              <button @click="changeMode('myCreate')" class="list__item"
                :class="{ 'list__item-selected': getCommunityRole === 'myCreate' }">
                {{ $t('pages.social.socialCommunitiesMyListPage.governedBy') }}
              </button>
            </div>
            <button class="community__message" @click="creationVizable = !creationVizable">
              <span class="community__message-icon"></span>{{
                $t('pages.social.socialCommunitiesMyListPage.createNewCommunity') }}
            </button>
          </nav>
          <div class="possible mobile-block" v-if="false">
            <h2 class="possible__title">{{ $t('pages.social.socialCommunitiesMyListPage.possibleFriends') }}</h2>
            <div class="possible__list">
              <PossibleCommunities></PossibleCommunities>
              <PossibleCommunities></PossibleCommunities>
              <PossibleCommunities></PossibleCommunities>
              <PossibleCommunities></PossibleCommunities>
            </div>
            <button class="possible__show">{{ $t('pages.social.socialCommunitiesMyListPage.viewAll') }} <span
                class="possible__show-icon"></span></button>
          </div>
          <SurchBlue @find="findByName" @default="changeMode('all')"
            v-if="getCommunityRole === 'all' && getMode === 'community'" />
          <div class="filling">
            <div class="filling__people">
              <MySeparateCommunity v-for="(item, index) in getMyCommunities" :key="item.id" :com-data="item"
                :mode="getMode" :index="index" :role="getCommunityRole" @updatePage="changeMode"></MySeparateCommunity>
            </div>
            <div class="interaction">
              <div class="community desctop-block">
                <div class="community__caption">
                  <button @click="changeRole('community')" class="community__caption-btn"
                    :class="{ 'community__caption-btn-selected': getMode === 'community' }">
                    {{ $t('pages.social.socialCommunitiesMyListPage.myCommunities') }}
                  </button>
                  <button @click="changeRole('request')" class="community__caption-btn"
                    :class="{ 'community__caption-btn-selected': getMode === 'request' }">
                    {{ $t('pages.social.socialCommunitiesMyListPage.myApplications') }}
                  </button>
                </div>
              </div>
              <div class="possible desctop-block" v-if="false">
                <h2 class="possible__title">{{ $t('pages.social.socialCommunitiesMyListPage.possibleFriends') }}</h2>
                <div class="possible__list">
                  <PossibleCommunities></PossibleCommunities>
                  <PossibleCommunities></PossibleCommunities>
                  <PossibleCommunities></PossibleCommunities>
                  <PossibleCommunities></PossibleCommunities>
                </div>
                <button class="possible__show">{{ $t('pages.social.socialCommunitiesMyListPage.viewAll') }} <span
                    class="possible__show-icon"></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import MySeparateCommunity from "../../components/social/account_communitis_my/MySeparateCommunity.vue";
import PossibleCommunities from "../../components/social/account_communitis/PossibleCommunities.vue";
import SurchBlue from "@/components/social/account_friensds/SurchBlue";
import { config } from "@/lib/config";
import { errorHandler } from "@/lib/errorHandler";
import QuestionModal from "@/components/account/account_worker/modals/item/QuestionModal";

export default {
  components: {
    SurchBlue,
    SocialMenu,
    SocialHeader,
    MySeparateCommunity,
    PossibleCommunities,
    QuestionModal,
  },
  name: "SocialCommunitiesMyListPage",
  computed: {
    getMyCommunities() {
      return this.$store.getters.getMyCommunities;
    },
    getCommunityRole() {
      return this.$store.getters.getRoleCommunity;
    },
    getMode() {
      return this.$store.getters.getModeCommunities;
    },
  },
  data() {
    return {
      statusVizable: false,
      creationVizable: false,
      saveCommunity: {
        name: null,
        description: null,
        avatarUrl: null,
        previewUrl: null,
        communityType: null,
      },
      avatarFile: null,
      previewFile: null,
      viewModal: false,
      newCommunity: null,
    };
  },
  beforeMount() {
    this.changeMode("my");
  },
  methods: {
    createNews() {
      const data = {
        fromUser: null,
        toUser: null,
        body: JSON.stringify({
          communityName: this.newCommunity.name,
          communityId: this.newCommunity.id,
          communityType: this.newCommunity.communityType,
          previewUrl: this.newCommunity.previewUrl,
          description: this.newCommunity.description,
        }),
        newsType: "community",
      };
      this.$store.dispatch("createNews", data).then(() => {
        this.viewModal = false;
        this.newCommunity = null;
      });
    },
    changeRole(value) {
      this.changeMode("my");
      this.$store.commit("setMode", value);
    },
    findByName(name) {
      this.$store.commit("resetPageCommunities");
      this.$store.dispatch("fetchAllCommunities", {
        page: this.$store.getters.getPageCommunities,
        limit: this.$store.getters.getLimitCommunities,
        token: this.$store.getters.token,
        name: name.name,
        callback: this.deletePagination,
      });
    },
    loadAvatar() {
      let avatar = this.$refs.avatarForm;
      this.avatarFile = this.$refs.avatar.files[this.$refs.avatar.files.length - 1];
      const photo = URL.createObjectURL(this.avatarFile);
      avatar.style.backgroundImage = "url('" + photo + "')";
      this.saveCommunity.avatarUrl = photo;
    },
    loadPreview() {
      let preview = this.$refs.previewForm;
      this.previewFile = this.$refs.preview.files[this.$refs.preview.files.length - 1];
      const photo = URL.createObjectURL(this.previewFile);
      preview.style.backgroundImage = "url('" + photo + "')";
      this.saveCommunity.previewUrl = photo;
    },
    createCommunity() {
      const check = this.checkEmptyField(this.saveCommunity);
      if (check) {
        this.saveCommunity.avatarUrl = this.updateFile(this.avatarFile);
        this.saveCommunity.previewUrl = this.updateFile(this.previewFile);
        this.$store.dispatch("saveNewCommunity", {
          token: this.$store.getters.token,
          community: this.saveCommunity,
          callback: this.changeMode,
        }).then((data) => {
          this.newCommunity = data;
          this.creationVizable = false;
          this.viewModal = true;
        });
        this.creationVizable = false;
      } else {
        errorHandler({ status: 400, page: "notAllData" });
      }
    },
    deletePagination() {
      window.onscroll = null;
    },
    addCommunity() {
      this.$store.commit("upPageCommunities");
      switch (this.getCommunityRole) {
        case "all":
          this.$store.dispatch("fetchAllCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
        case "my":
          this.$store.dispatch("fetchMyCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
        case "myCreate":
          this.$store.dispatch("fetchMyCreateCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
      }
    },
    changeMode(value) {
      this.$store.commit("resetPageCommunities");
      this.$store.commit("setRoleCommunity", value);
      this.paginationLoad();
      switch (this.getCommunityRole) {
        case "all":
          this.$store.dispatch("fetchAllCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
        case "my":
          this.$store.dispatch("fetchMyCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
        case "myCreate":
          this.$store.dispatch("fetchMyCreateCommunities", {
            page: this.$store.getters.getPageCommunities,
            limit: this.$store.getters.getLimitCommunities,
            token: this.$store.getters.token,
            callback: this.deletePagination,
          });
          break;
      }
    },
    updateFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + "file/upload";
      request.open("POST", url, false);
      try {
        request.send(formData);
        const response = JSON.parse(request.response);
        return response.data.url;
      } catch (e) {
        console.log(e);
      }
    },
    checkEmptyField(value) {
      for (let key in value) {
        if (!value[key]) return false;
      }
      return true;
    },
    paginationLoad() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.addCommunity();
        }
      };
    },
  },
};
</script>


<style scoped>
.community-messages {
  margin-left: 11px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  display: inline;
  padding: 3px 8px;
  background: #14c686;
  border-radius: 20px;
  color: #fff;
  line-height: 1;
}

.account {
  display: flex;
}


.subtitle {
  display: none;
}

.title {
  font-weight: 700;
  font-size: 26px;
  color: #3b4c68;
}

.nav {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.list__item:not(:last-child) {
  margin-right: 40px;
}

.community__message {
  background: #009ed1;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin-left: 40px;
  width: 214px;
  height: 45px;
  box-shadow: 0px 4px 12px -6px rgba(0, 158, 209, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.community__message-icon {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  margin-right: 7px;
  background-size: cover;
}


.list__item {
  position: relative;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b4c68;
}

.list__item.list__item-selected::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background: #009ed1;
}

.filling {
  margin-top: 40px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.filling__people {
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
}

.interaction {
  max-width: 388px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 30px;
}

.community {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
}

.community__caption {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.community__caption-btn {
  margin-top: 10px;
  padding: 5px 0 5px 22px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #44444f;
  text-align: left;
}

.community__caption-btn.community__caption-btn-selected {
  display: block;
  background: rgba(239, 239, 244, 0.5);
  position: relative;
}

.community__caption-btn.community__caption-btn-selected::before {
  position: absolute;
  content: "";
  left: -1px;
  border-radius: 0px 20px 20px 0;
  width: 2px;
  height: 100%;
  display: block;
  background: #009ED1;
  top: 0;
}

.community__best-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.community__best {
  display: flex;
  width: 100%;
  padding: 18px 6px 13px 6px;
  border-top: 1px solid #efeff4;
  box-sizing: border-box;
  align-items: center;

}

.community__best-text {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #44444f;
  margin: 2px 0 0 3px;
}

.community__best-icon {
  background: url("~@/assets/img/account-img/friends/star.svg");
  width: 24px;
  height: 24px;
  display: block;
}

.possible {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  padding: 0 12px;
}

.possible__title {
  padding-top: 20px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding-left: 10px;
}

.possible__list {
  padding-top: 10px;
  width: 100%;
}

.possible__show {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #44444f;
  padding: 15px 0;
}

.possible__show-icon {
  width: 9px;
  height: 9px;
  margin-left: 4px;
  display: flex;
  background-image: url("~@/assets/img/account-img/friends/list.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: 3px;
}

.mobile-block {
  display: none;
}

.show-more {
  display: none;
}

.creation {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: calc(100vh);
  position: fixed;
  z-index: 8000;
  background: rgba(0, 0, 0, 0.5);
}

.creation__wrapper {
  position: absolute;
  top: calc(50% - 462px);
  left: calc(50% - 21%);
  max-width: calc(41%);
  height: 925px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(59, 76, 104, 0.1);
  border-radius: 20px;
}

.creation__filling {
  max-width: 454px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  padding: 40px;
}

.cancel-button {
  background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
  height: 15px;
  width: 15px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: calc(50% - 6px);
  right: 0px;
}

.title-wrpper {
  position: relative;
}

.creation__title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.creation__photo {
  background-image: url("~@/assets/img/account-img/communities/create-bg.png");
  width: 163px;
  height: 163px;
  background-size: cover;
  border-radius: 100%;
  margin: 20px auto 0 auto;
}

.creation__preview {
  background-image: url("~@/assets/img/account-img/communities/preview.png");
  height: 163px;
  background-size: cover;
  border-radius: 8px;
  background-position: center;
  margin: 20px auto 0 auto;
}

.creation__download {
  margin-top: 10px;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #009ed1;
  transition: opacity 1s ease;
}

.creation__download:hover {
  opacity: 0.5;
}

.creation__input {
  margin-top: 40px;
  border: 1px solid #efeff4;
  border-radius: 10px;
  width: 100%;
  padding: 16px 20px;
  color: rgba(66, 66, 66);
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  box-sizing: border-box;
}

.creation__input::placeholder {
  color: rgba(66, 66, 66, 0.5);
}

.creation__textarea {
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  padding: 16px 20px;
  color: rgba(66, 66, 66);
  border: 1px solid #efeff4;
  border-radius: 10px;
  width: 100%;
  min-height: 95px;
  box-sizing: border-box;
}

.creation__textarea::placeholder {
  color: rgba(66, 66, 66, 0.5);
}

.creation__text {
  font-family: "Roboto";
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: rgba(66, 66, 66, 0.5);
  margin: 20px 0 0 20px;
  box-sizing: border-box;
}

.creation-choice {
  background: #efeff4;
  display: flex;
  width: 100%;
  border-radius: 5px;
  height: 45px;
  align-items: center;
  width: 100%;
  margin-top: 13px;
}

.creation-choice__element {
  width: 100%;
  max-width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #44444f;
  cursor: pointer;
  transition: opacity 1s ease;
}

.creation-choice__element:hover {
  opacity: 0.5;
}

.creation-choice__element.creation-choice__element-selected {
  background: #14c686;
  color: #ffffff;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
}

.creation-create {
  margin-top: 40px;
  width: 100%;
}

.creation-create__button {
  max-width: 166px;
  width: 100%;
  height: 45px;
  background: #009ed1;
  border-radius: 8px;
  box-shadow: 0px 4px 12px -6px #009ED1;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.community__caption:last-child {
  margin-bottom: 10px;
}


@media (max-width: 1575px) {
  .filling__people {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }

  .filling__people {
    margin-bottom: 0;
  }
}

@media (max-width: 1100px) {
  .filling__people {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 950px) {

  .creation__wrapper {
    left: calc(50% - 30.5%);
    max-width: calc(61%);
    height: 736px;
    top: calc(50% - 368px);
  }


}

@media (max-height: 950px) {
  .creation__wrapper {
    top: 20px;
  }

  .creation__filling {
    height: 925px;
    margin-bottom: 40px;
  }

  .creation {
    overflow-y: scroll;
    padding-bottom: 20px;
  }
}

@media (max-width: 850px) {
  .community__message {
    max-width: 100%;
    margin-left: 0;
    width: 100%;
  }

  .nav {
    margin-top: 20px;
    flex-direction: column;
  }

  .community__message {
    margin-top: 20px;
  }

  .form {
    margin-top: 40px;
  }

  .possible__title {
    padding-left: 0;
  }

  .filling {
    margin-top: 20px;
  }

  .desctop-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .title {

    font-size: 24px;
    line-height: 29px;
  }

  .title__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .community-who {
    margin-left: auto;
    text-align: left;
    padding: 10px 0 10px 10px;
    position: relative;
  }

  .community-who-text {
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #3e4453;
    position: relative;
    align-items: center;
    display: flex;
  }

  .community-who-text::before {
    position: absolute;
    left: -6px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #14c686;
    display: block;
    content: "";
    top: calc(50% - 2px);
  }

  .community-who-img {
    display: block;
    background-image: url("~@/assets/img/account-img/friends/list-blue.svg");
    background-repeat: no-repeat;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    background-position-y: 1px;
  }

  .subtitle {
    display: block;
    font-family: "SFUIDisplay";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #009ed1;
  }


  .community {
    position: absolute;
    width: 300px;
    right: 0px;
    z-index: 30;
    top: 44px;
  }


  .interaction {
    display: none;
  }

  .show-more {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid #009ed1;
    border-radius: 7px;
    font-family: "SFUIDisplay";
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #009ed1;
  }


  .list {
    display: flex;
    flex-direction: column;
    padding: 9px 0;
    background: #ffffff;
    width: 100%;
    border: 1px solid #efeff4;
    border-radius: 10px;
  }

  .list__item {
    text-align: left;
    padding: 5px 0 5px 22px;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    color: #44444f;
  }

  .list__item-selected {
    display: block;
    background: rgba(239, 239, 244, 0.5);
    position: relative;
  }

  .list__item.list__item-selected::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-radius: 0px 20px 20px 0;
    content: "";
    display: block;
    background: #009ed1;
  }

  .list__item:not(:last-child) {
    margin-right: 0;
  }

}

@media (max-width: 650px) {
  .creation__wrapper {
    left: calc(50% - 150px);
    max-width: 300px;
    height: 676px;
    top: calc(50% - 338px);
  }

  .creation__filling {
    padding: 40px 13px;
  }

  .creation__title {
    font-size: 24px;
  }

  .creation__photo {
    width: 132px;
    height: 132px;
  }

  .creation__download {
    font-size: 14px;
  }

  .creation__input {
    margin-top: 20px;
    font-size: 12px;
    padding: 15px 10px;
  }

  .creation__textarea {
    font-size: 12px;
    padding: 15px 10px;
  }

  .creation__text {
    margin-left: 10px;
  }

  .creation-create__button {
    max-width: 100%;
  }

}

@media (max-height: 700px) {
  .creation__wrapper {
    overflow-y: scroll;
    top: calc(50% - 275px);
  }

  .creation {
    height: 100%;
    overflow-y: scroll;
  }
}
</style>