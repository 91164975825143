<template>
  <router-link
      :to ="{name:'articles',params:{articlesId:blogData.id}}"
    class="tidings__wrapper-smallarticle-element"
    :style="{ backgroundImage: 'url(' + blogData.files + ')' }"
  >
    <div class="tidings__wrapper-smallarticle-element-topic">
      <p class="tidings__wrapper-smallarticle-element-topic-text">
        {{ blogData.categories[0] }}
      </p>
    </div>
    <h4 class="tidings__wrapper-smallarticle-element-title tidings-title">
      {{ blogData.title }}
    </h4>
    <p class="tidings__wrapper-smallarticle-element-date date">
      {{ new Date(blogData.createDate).toISOString().slice(0,10)}}
    </p>
  </router-link>
</template>

<script>
export default {
  name: "InterestingPage",
  props: {
    blogData: {
      type: Object,
    },
  },
  data(){
    return{
      date:null
    }
  }

};
</script>
<style scoped>
.tidings-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
}

.tidings__wrapper-smallarticle-element {
  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  min-height: 456px;
}
.tidings__wrapper-smallarticle-element:not(:last-child) {
  margin-right: 40px;
}

.tidings__wrapper-smallarticle-element-title {
  max-width: 430px;
  margin: 304px 0 0 24px;
}

.tidings__wrapper-smallarticle-element-date {
  margin: 17px 0 37px 24px;
  width: 64px;
}

.tidings__wrapper-smallarticle-element-topic-text {
  position: absolute;
  top: 27px;
  right: 38px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.20000000298023224);
  border-radius: 8px;
}

.date {
  position: relative;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #e5e5e5;
}

.topic-text {
  text-transform: uppercase;
  text-align: center;
  padding: 4px 13px;
  background: rgba(255, 255, 255, 0.15000000596046448);
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

@media (max-width: 1020px) {
  .tidings__wrapper-smallarticle-element {
    min-height: 189px;
  }

  .tidings__wrapper-smallarticle-element:not(:last-child) {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .tidings__wrapper-smallarticle-element-title {
    max-width: 540px;
    margin: 150px 0 0 24px;
  }

  .tidings__wrapper-smallarticle-element-date {
    margin: 17px 0 37px 24px;
    width: 64px;
  }
}

@media (max-width: 680px) {
  .tidings-title {
    font-size: 18px;
  }

  .tidings__wrapper-smallarticle-element {
    height: 189px;
    margin-bottom: 10px;
  }

  .tidings__wrapper-smallarticle-element-topic {
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  .tidings__wrapper-smallarticle-element-topic-text {
    margin-top: 28px;
    position: unset;
  }

  .tidings__wrapper-smallarticle-element-title {
    max-width: 250px;
    text-align: center;
    margin: 23px auto 0 auto;
  }

  .tidings__wrapper-smallarticle-element-date {
    margin: 20px auto 33px auto;
  }
}
</style>
