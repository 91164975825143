<template>
  <div class="news">
    <div class="news-container">
      <div class="wrapper" v-for="item in posts" :key="item.id">
        <LastNewsPartners v-if="item.newsType === 'friends'" :post="item">
        </LastNewsPartners>
        <LastNewsPlace v-if="item.newsType === 'job'" :post="item">
        </LastNewsPlace>
        <LastNewsNewCommunity v-if="item.newsType === 'community'" :post="item">
        </LastNewsNewCommunity>
        <LastNewsNewTest v-if="item.newsType === 'test'" :post="item">
        </LastNewsNewTest>
        <LastNewsRepost style="cursor: pointer" @pickPost="fetchCurrentPost" v-if="item.newsType === 'repost'"
                        :post="item">
        </LastNewsRepost>
        <LastNewsNewBirthday v-if="item.newsType === 'birthday'" :post="item">
        </LastNewsNewBirthday>
        <LastNewsLevelUp v-if="false">
        </LastNewsLevelUp>
      </div>
    </div>
    <BigPost @viewBigPhoto="val=>$emit('viewBigPhoto',val)" @closeWindow="closePost" :post="currentPost" :metaData="postMetaData" v-if="currentPost&&!bigPhoto">
    </BigPost>
  </div>
</template>

<script>
import LastNewsPartners from "./LastNewsPartners.vue";
import LastNewsPlace from "./LastNewsPlace.vue";
import LastNewsNewCommunity from "./LastNewsNewCommunity.vue";
import LastNewsNewTest from "./LastNewsNewTest.vue";
import LastNewsNewBirthday from "./LastNewsNewBirthday.vue";
import LastNewsLevelUp from "./LastNewsLevelUp.vue";
import LastNewsRepost from './LastNewsRepost.vue';
import BigPost from "@/components/account/account_worker/modals/item/BigPost";
import {config} from "@/lib/config";
import {errorHandler} from "@/lib/errorHandler";

export default {
  props: ['posts', 'bigPhoto'],
  name: "LastNewsWrapper",
  components: {
    LastNewsPartners,
    LastNewsPlace,
    LastNewsNewCommunity,
    LastNewsNewTest,
    LastNewsNewBirthday,
    LastNewsLevelUp,
    LastNewsRepost,
    BigPost,
  },
  data() {
    return {
      currentPost: null,
      postMetaData: null
    }
  },
  methods: {
    closePost() {
      this.currentPost = null;
      this.postMetaData = null;
    },
    async fetchCurrentPost(news) {
      const body = JSON.parse(news.body);
      if (!body.postId) return;
      await fetch(`${config.apiPrefix}community/post/${body.postId}/get`, {
        method: "GET",
        headers: {
          'X-Auth-Token': this.$store.getters.token
        }
      }).then(async (res) => {
        const {data} = await res.json();
        this.currentPost = data;
        this.postMetaData = body;
      }).catch(() => {
        errorHandler({page: 'openPost', status: 500})
      })
    }
  }
};


</script>

<style scoped>
p{
  word-break: break-word;
}
h3{
  word-break: break-word;
}
.news-container {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.wrapper {
  max-width: 100%;
  width: 100%;
}


@media (max-width: 1200px) {
  .news-container{
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 950px) {
  .news-container{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 720px) {
  .news-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
