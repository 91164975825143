<template>
  <div class="modal">
    <div class="modal-filling">
      <div class="caption">
        <p class="title">{{ $t('components.account_worker.sharePost.share_post') }}</p>
        <button @click="$emit('closeWindow')" class="close">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z" stroke="#3B4C68"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
      <div class="share-wrapper">
        <label class="check-wrapper">
          <div class="check">
            <input type="radio" value="me" v-model="sharingTo" name="radio" class="check__input">
            <div class="check__box"></div>
          </div>
          <p class="check-text">
            {{ $t('components.account_worker.sharePost.to_your_feed') }}
          </p>
        </label>
        <div class="share share__messages">
          <label class="check-wrapper">
            <div class="check">
              <input type="radio" value="friends" v-model="sharingTo" name="radio" class="check__input">
              <div class="check__box"></div>
            </div>
            <p class="check-text">
              {{ $t('components.account_worker.sharePost.in_a_personal_message') }}
            </p>
          </label>
          <div class="select" v-if="sharingTo === 'friends'">
            <label class="select-label" @click="listOpen = !listOpen" @focus="listOpen = !listOpen">
              <input type="text"
                :placeholder="selectedFriend ? selectedFriend?.name : $t('components.account_worker.sharePost.enter_recipients_name')"
                class="select-input">
              <img src="@/assets/img/all-img/list-grey.svg" alt="" class="select-icon">
            </label>
            <div class="select-list" :class="{ 'select-list__open': listOpen }">
              <button @click="selectedFriend = friend" v-for="friend in myFriends" :key="friend.id"
                class="select-element friend select-element-add">
                <img src="@/assets/img/no-logo.png" alt="" class="friend-img">
                <p class="friend-name">{{ friend.name }}</p>
              </button>
            </div>
          </div>
          <button class="save" @click="sharePost">{{ $t('components.account_worker.sharePost.send') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Cookies from "js-cookie";

export default {
  name: "SharePost",
  props: ['sharingPost', 'metaData'],
  computed: {
    myFriends() {
      return this.$store.getters.getMyFriends;
    }
  },
  watch: {
    sharingTo() {
      this.selectedFriend = null;
    },
    searchName() {
      this.fetchFriends();
    },
    selectedFriend() {
      this.searchName = null;
      this.listOpen = false;
    }
  },
  data() {
    return {
      listOpen: false,
      page: 1,
      limit: 20,
      canLoadFriends: true,
      sharingTo: 'me',
      searchName: null,
      selectedFriend: null
    }
  },
  methods: {
    fetchFriends() {
      this.$store.dispatch('fetchMyFriends', {
        page: this.page,
        limit: this.limit,
        name: this.name,
        token: this.$store.getters.token,
        role: 'all',
        callback: () => this.canLoadFriends = false
      });
    },
    loadMoreFriends() {
      if (!this.canLoadFriends) return;
      this.page++;
      this.fetchFriends();
    },
    createNews() {

    },
    sendFriends() {

    },
    sharePost() {
      if (this.sharingTo === 'me') {
        const news = {
          fromUser: null,
          toUser: null,
          newsType: 'repost',
          body: JSON.stringify({
            title: this.metaData.title,
            text: this.sharingPost.body,
            avatarUrl: this.metaData.avatarUrl,
            previewUrl: this.sharingPost.files.length ? this.sharingPost.files[0] : null,
            postId: this.sharingPost.id,
            communityId: this.metaData.communityId
          })
        }
        this.$store.dispatch('createNews', news);
      } else {
        const post = {
          communityId: this.metaData.communityId,
          communityAvatarUrl: this.metaData.avatarUrl,
          communityName: this.metaData.title,
          postId: this.sharingPost.id,
          postBody: this.sharingPost.body,
          postFile: this.sharingPost.files.length ? this.sharingPost.files[0] : null
        }
        Cookies.set('currentDialogueId', this.selectedFriend.id);
        this.$store.dispatch('sendMessage', {
          message: `communityPost#@${JSON.stringify(post)}#@`,
          sharePost: true
        }).then(() => {
          Cookies.remove('currentDialogueId');
        });
      }
      this.$emit('closeWindow');
    }
  },
  beforeMount() {
    this.fetchFriends();
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.modal {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-width: 100%;
  padding: 40px;
  overflow: auto;
  scrollbar-width: thin;

}

.modal-filling {
  position: relative;
  max-width: 700px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.close {
  margin-left: auto;
  width: 16px;
  height: 16px;
}

.close svg {
  width: 100%;
  height: 100%;
}

.close path {
  stroke: #000;
}

.check-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.check {
  display: flex;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.check:hover {
  opacity: 0.8;
}

.check__input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
}

.check__box {
  width: 100%;
  height: 100%;
  background: none;
  border: 2px rgba(59, 76, 104, 0.50) solid;
  border-radius: 100%;
  transition: all 0.5s ease-in-out;
}

.check__input:checked+.check__box {
  background: #009ED1;
  border-color: #009ED1;
}

.caption {
  display: flex;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.share-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.select-label {
  margin-top: 0.5rem;
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #BCBCBC;
  background: #FFF;
  padding: 0.81rem 1rem;
  gap: 0.5rem;
}

.select {
  position: relative;
}

.select-input {
  max-width: 100%;
  width: 100%;

}


.select-list {
  position: absolute;
  max-width: 100%;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  scrollbar-width: thin;
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: -1;
}

.select-list :last-child {
  border-bottom: none;
}

.select-list__open {
  opacity: 1;
  transform: translateY(0%);
  border-radius: 10px;
  z-index: 1;
}

.friend {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  background: #fff;
  padding: 10px;
  border-bottom: 1px solid #000;
}

.friend:hover {
  background: #E6F6FC;
}

.friend-img {
  display: flex;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.text {
  margin-top: 1rem;
  font-size: 16px;
}

.textarea {
  margin-top: 0.5rem;
  padding: 10px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  min-height: 140px;
  border-radius: 0.5rem;
  resize: vertical;
}

.save {
  margin-top: 1rem;
  margin-left: auto;
  width: 200px;
  border-radius: 0.5rem;
  background: #009ED1;
  text-align: center;
  padding: 10px;
  color: #fff;
  transition: opacity 1s ease-in-out;
}

.save:hover {
  opacity: 0.5;
}

@media (max-width: 767px) {
  .modal {
    padding: 20px 10px;
  }

  .modal-filling {
    padding: 20px 12px;
  }

  .close {
    width: 16px;
    height: 16px;
    position: relative;
    margin-left: auto;
    right: unset;
    top: unset;
  }

  .select-label {
    padding: 10px;
  }

}
</style>