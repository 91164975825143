<template>
	<div class="chat__wrapper-dialogue">
		<div class="chat__wrapper-dialogue-caption">
			<button class="chat__wrapper-dialogue-caption-back" @click="back">
				<img src="~@/assets/img/account-img/chat/back.svg" alt=""
					class="chat__wrapper-dialogue-caption-back-img" />
			</button>
			<div class="chat__wrapper-dialogue-caption-avatar"
				:style="{ backgroundImage: user.avatar ? 'url(' + user.avatar + ')' : 'url(' + require('/src/assets/img/account-img/chat/avatar.svg') + ')' }">
				<div class="chat__wrapper-dialogue-caption-avatar-notification notification-online"></div>
			</div>
			<div class="chat__wrapper-dialogue-caption-info">
				<p class="chat__wrapper-dialogue-caption-info-name">{{ role === 'admin' ?
					$t('compocomponents.account_company.separateCompanyChatDialogue.administrator') : user.name }}</p>
			</div>
			<div class="chat__wrapper-dialogue-caption-opportunities">
				<button class="chat__wrapper-dialogue-caption-opportunities-surch-container">
					<img src="~@/assets/img/account-img/chat/find.svg" alt=""
						class="chat__wrapper-dialogue-caption-opportunities-surch" />
				</button>
				<button class="chat__wrapper-dialogue-caption-opportunities-tabs-container">
					<img src="~@/assets/img/account-img/chat/tabs.svg" alt=""
						class="chat__wrapper-dialogue-caption-opportunities-tabs" />
				</button>
			</div>
		</div>
		<div class="chat__wrapper-dialogue-messages" id="dialoge">
			<RepostElement></RepostElement>
			<SeparateCompanyChatMessage v-for="item in messages" :key="item.id" :message="item" :role="role">
			</SeparateCompanyChatMessage>
		</div>
		<div class="chat__wrapper-dialogue-entry">
			<div class="chat__wrapper-dialogue-entry-filling">
				<textarea id="textarea" type="text" class="chat__wrapper-dialogue-entry-filling-input"
					:placeholder="$t('compocomponents.account_company.separateCompanyChatDialogue.enterMessage')"
					v-model="newMessage" v-on:keyup.enter="send" />
				<div class="chat__wrapper-dialogue-entry-filling-interactions">
					<button v-if="false" class="chat__wrapper-dialogue-entry-filling-interactions-file"></button>
					<button ref="send" @click="send"
						class="chat__wrapper-dialogue-entry-filling-interactions-send"></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SeparateCompanyChatMessage from "../../../account_company/account_company_chat/item/SeparateCompanyChatMessage.vue";
import { errorHandler } from "@/lib/errorHandler";
import RepostElement from "./RepostElement";

export default {
	computed: {
		currentId() {
			return this.$store.getters.getCurrentDialogueId;
		}
	},
	mounted() {
		document.getElementById("dialoge").scroll(0, document.documentElement.offsetHeight);
		this.timerId = setInterval(() => {
			this.allMessage();
		}, 6000);
		this.addPagination();
	},
	name: "SeparateCompanyChatPersone",
	props: ['messages', 'role', 'user'],
	components: {
		SeparateCompanyChatMessage,
		RepostElement
	},
	data() {
		return {
			newMessage: null,
			timerId: null,
		};
	},
	methods: {
		back() {
			this.$store.commit("backToAllDialoge");
			clearInterval(this.timerId);
		},
		lastMessage() {
			if (this.role === 'admin') {
				this.$store.dispatch("fetchLastMessageEmployer", this.$store.getters.token);
			} else {
				this.$store.dispatch('fetchDialoguesWithUsers', {
					token: this.$store.getters.token,
					role: this.role,
					limit: this.$store.getters.getLimitDialogue,
					page: this.$store.getters.getPageDialogue
				});
			}
		},
		readMessage() {
			if (this.role === 'admin') {
				this.$store.dispatch("readMessageEmployer", {
					token: this.$store.getters.token,
					callback: this.lastMessage,
				});
			} else {
				this.$store.dispatch('readMessageWithUsers', {
					id: this.currentId,
					token: this.$store.getters.token,
					callback: this.lastMessage
				});
			}
		},
		allMessage() {
			if (this.role === 'admin') {
				this.$store.dispatch("fetchAllMessageEmployer", {
					token: this.$store.getters.token,
					callback: this.readMessage,
				});
			} else {
				this.$store.dispatch('fetchAllMessageWithUser', {
					callback: this.readMessage,
					token: this.$store.getters.token,
					id: this.currentId,
					limit: this.$store.getters.getLimitMessages,
					page: this.$store.getters.getPageMessages
				});
			}
		},
		send() {
			if (this.newMessage) {
				if (this.role === 'admin')
					this.$store.dispatch("sendMessageEmployer", {
						data: this.newMessage,
						token: this.$store.getters.token,
						callback: this.allMessage,
					});
				else {
					this.$store.dispatch('sendMessageWithUsers', {
						token: this.$store.getters.token,
						message: this.newMessage,
						userId: this.currentId,
						callback: this.allMessage
					})
				}
				this.newMessage = null;
			} else errorHandler({ status: 400, page: "emptyMessage" });
		},
		addPagination() {
			window.onscroll = () => {
				let el = document.getElementById("dialoge");
				el.onscroll = () => {
					if (el.scrollTop === 0) {
						console.log('test');
					}
				}
			}
		}
	},
	destroyed() {
		clearInterval(this.timerId);
	},
};
</script>


<style scoped>
.chat__wrapper-dialogue {
	flex-shrink: 0;
	position: relative;
	max-width: 1031px;
	width: 100%;
	box-sizing: border-box;
	height: 100%;
}

.chat__wrapper-dialogue-caption {
	padding: 30px 70px 30px 30px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	border-bottom: 1px solid rgba(182, 210, 255, 0.5);
	align-items: center;
}

.chat__wrapper-dialogue-caption-back {
	width: 60px;
	height: 60px;
	display: block;
	box-sizing: border-box;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-back:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-avatar {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	position: relative;
}

.chat__wrapper-dialogue-caption-avatar-notification {
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 1px;
	right: 0;
}

.chat__wrapper-dialogue-caption-info {
	margin-left: 16px;
}

.chat__wrapper-dialogue-caption-info-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
}

.chat__wrapper-dialogue-caption-info-online {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #009ed1;
}

.ofline {
	color: #999999;
}

.chat__wrapper-dialogue-caption-opportunities {
	margin-left: auto;
}

.chat__wrapper-dialogue-caption-opportunities-surch {
	margin-right: 18px;
	padding: 14px 14px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-surch:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-caption-opportunities-tabs {
	padding: 11px 11px;
	border: 1px solid #f2f2f2;
	border-radius: 14px;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-caption-opportunities-tabs:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-messages::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages {
	padding: 24px 70px 24px 32px;
	display: flex;
	flex-direction: column;
	position: relative;
	height: calc(100vh - 470px);
	overflow-y: scroll;
	scrollbar-width: none;
}

.chat__wrapper-dialogue-messages-interlocutor {
	max-width: 372px;
	width: 100%;
	box-sizing: border-box;
	background: #e4f6fc;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-right: auto;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-interlocutor::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	left: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-interlocutor:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-messages-interlocutor-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #666666;
}

.chat__wrapper-dialogue-messages-interlocutor-time {
	margin-top: 29px;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999999;
}

.chat__wrapper-dialogue-messages-user {
	max-width: 302px;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
	padding: 16px 16px 8px 16px;
	margin-left: auto;
	background: #009ed1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-messages-user-text {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

}

.chat__wrapper-dialogue-messages-user::after {
	display: block;
	content: "";
	width: 20px;
	height: 17px;
	background: url("~@/assets/img/account-img/chat/user-chat-element.svg");
	position: absolute;
	background-repeat: no-repeat;
	background-size: cover;
	right: -8px;
	top: 0;
}

.chat__wrapper-dialogue-messages-user:not(:last-child) {
	margin-bottom: 30px;
}

.chat__wrapper-dialogue-entry {
	border-top: 1px solid rgba(182, 210, 255, 0.5);
	background: #fafafb;
	position: absolute;
	margin-top: auto;
	bottom: 0;
	width: 100%;
	padding: 36px 40px;
	box-sizing: border-box;
	z-index: 20;
	display: flex;
	align-items: center;
}

.chat__wrapper-dialogue-entry-filling {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.chat__wrapper-dialogue-entry-filling-input {
	width: 100%;
	padding: 18px 103px 18px 26px;
	box-sizing: border-box;
	height: 56px;
	max-height: calc(100vh - 470px);
	background: #ffffff;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #171725;
	border-radius: 10px;
	overflow-y: scroll;
	scrollbar-width: none;
	border: none;
}

.chat__wrapper-dialogue-entry-filling-input:hover {
	overflow-y: scroll;
	scrollbar-width: none;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar {
	width: 0px;

	height: 0px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.chat__wrapper-dialogue-entry-filling-input::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.chat__wrapper-dialogue-entry-filling-input::placeholder {
	color: #b7c1d1;
}

.chat__wrapper-dialogue-entry-filling-interactions {
	position: absolute;
	align-items: center;
	display: flex;
	right: 26px;
	bottom: 18px;
}

.chat__wrapper-dialogue-entry-filling-interactions-file {
	background: url("~@/assets/img/account-img/chat/file-add.svg");
	width: 19px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-file:hover {
	opacity: 0.5;
}

.chat__wrapper-dialogue-entry-filling-interactions-send {
	margin-left: 21px;
	background: url("~@/assets/img/account-img/chat/send.svg");
	width: 20px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: opacity 1s ease;
}

.chat__wrapper-dialogue-entry-filling-interactions-send:hover {
	opacity: 0.5;
}

@media (max-width: 900px) {
	.chat__wrapper-dialogue-entry-filling-input {
		padding-right: 96px;
	}

	.chat__wrapper-dialogue-caption {
		padding: 18px 24px 18px 24px;
	}

	.chat__wrapper-dialogue-caption-back {
		width: 40px;
		height: 40px;
	}

	.chat__wrapper-dialogue-caption-avatar {
		flex-shrink: 0;
	}

	.chat__wrapper-dialogue-caption-info-name {
		font-size: 15px;
	}

	.chat__wrapper-dialogue-messages {
		height: calc(100vh - 218px);
	}

	.chat__wrapper-dialogue-entry {
		position: fixed;
		background: #FAFAFB;
	}

	.chat__wrapper-dialogue-caption {
		padding: 18px 10px 18px 0px;
		position: fixed;
		top: 48px;
	}

	.chat__wrapper-dialogue-messages {
		position: absolute;
		width: 100%;
		top: 84px;
		max-width: 100%;
		box-sizing: border-box;
	}
}

@media (max-width: 430px) {

	.chat__wrapper-dialogue-caption-opportunities-surch {
		margin-right: 10px;
		padding: 8px 8px;
		width: 20px;
	}

	.chat__wrapper-dialogue-caption-opportunities-tabs {
		padding: 6px 6px;
	}

	.chat__wrapper-dialogue-caption-info {
		margin-left: 10px;
	}

	.chat__wrapper-dialogue-messages {
		padding: 24px 18px 24px 18px;
	}

	.chat__wrapper-dialogue-entry {
		padding: 36px 18px;
	}
}
</style>