<template>
	<div class="friend-wrapper">
		<div class="friends-separate__left" style="cursor: pointer" @click="goToProfile">
			<div class="friend__face">
				<img :src="friend.avatarUrl" alt="" class="friend__face-img" />
			</div>
			<div class="friend__info">
				<div class="friend__info-designation friend-mobile">
					<p>{{ friend.role ? ((friend.role === 'candidate') ?
						$t('components.social.separateFriend.jobSeeker') :
						$t('components.social.separateFriend.employer')) : $t('components.social.separateFriend.noData')
						}}</p>
					<span class="friend__info-rating">{{ friend.rate ? friend.rate : 0 }}</span>
					<button class="friend__settings friend-mobile" v-on:click="viewMenu">
						<div class="friend__settings-popap" v-if="menuVisible">
							<div class="popap-list">
								<button v-if="false" @click="goToProfile" class="popap-list__button">{{
									$t('components.social.separateFriend.profile') }}</button>
								<button class="popap-list__button" @click="addToFavoriteFriends" v-if="!isFavorite">{{
									$t('components.social.separateFriend.addToFavorites') }}</button>
								<button class="popap-list__button" @click="removeFromFavoriteFriends" v-else>{{
									$t('components.social.separateFriend.removeFromFavorites') }}</button>
								<button class="popap-list__button" @click="blockUser" v-if="!isBlocked">{{
									$t('components.social.separateFriend.block') }}</button>
								<button class="popap-list__button" @click="unblockUser" v-else>{{
									$t('components.social.separateFriend.unblock') }}</button>
							</div>
							<button v-if="!isFriends" @click="sendRequestForFriend" class="friend__settings-add">
								<span class="friend__message-icon"></span>{{
									$t('components.social.separateFriend.addAsFriend') }}
							</button>
							<button @click="deleteFriend" v-if="isFriends" class="friend__settings-delete">{{
								$t('components.social.separateFriend.removeFromFriends') }}</button>
						</div>
					</button>
				</div>
				<h3 class="friend__info-name">{{ friend.name ? limit(friend.name, 20) :
					$t('components.social.separateFriend.noData') }}
					<span class="friend__info-rating friend-desctop">{{ friend.rate ? friend.rate : 0 }}</span>
				</h3>
				<p class="friend__info-speciality friend-desctop">{{ friend.profession ? limit(friend.profession, 20) :
					$t('components.social.separateFriend.noData') }}</p>
				<p class="friend__info-designation friend-desctop">{{ friend.role ? ((friend.role === 'candidate') ?
					$t('components.social.separateFriend.jobSeeker') : $t('components.social.separateFriend.employer'))
					: $t('components.social.separateFriend.noData') }}</p>
				<p class="friend__place-town friend-mobile" v-if="friend.role === 'candidate'">
					<span class="friend__place-icon"></span>{{ friend.city ? limit(friend.city, 20) :
						$t('components.social.separateFriend.noData') }}
				</p>
				<p class="friend__place-town friend-mobile" v-if="friend.role === 'employer'">
					<span class="friend__info-icon"></span>{{ friend.city ? limit(friend.city, 20) :
						$t('components.social.separateFriend.noData') }}
				</p>
			</div>
			<div class="friend__place friend-desctop" v-if="friend.role === 'candidate'">
				<p class="friend__place-name">{{ $t('components.social.separateFriend.city') }}</p>
				<p class="friend__place-town"><span class="friend__place-icon"></span>{{ friend.city ?
					limit(friend.city, 20) : $t('components.social.separateFriend.noData') }}</p>
			</div>
			<div class="friend__place friend-desctop" v-if="friend.role === 'employer'">
				<p class="friend__place-name">{{ $t('components.social.separateFriend.information') }}</p>
				<p class="friend__place-town"><span class="friend__info-icon"></span>{{ friend.city ? limit(friend.city,
					20) : $t('components.social.separateFriend.noData') }}</p>
			</div>
		</div>
		<button @click="writeMessage" class="friend__message"><span class="friend__message-icon"></span>{{
			$t('components.social.separateFriend.writeMessage') }}</button>
		<button class="friend__settings friend-desctop" @click="viewMenu">
			<div class="friend__settings-popap" v-if="menuVisible">
				<div class="popap-list">
					<button @click="goToProfile" v-if="false" class="popap-list__button">{{
						$t('components.social.separateFriend.profile') }}</button>
					<button class="popap-list__button" @click="addToFavoriteFriends" v-if="!isFavorite">{{
						$t('components.social.separateFriend.addToFavorites') }}</button>
					<button class="popap-list__button" @click="removeFromFavoriteFriends" v-else>{{
						$t('components.social.separateFriend.removeFromFavorites') }}</button>
					<button class="popap-list__button" @click="blockUser" v-if="!isBlocked">{{
						$t('components.social.separateFriend.block') }}</button>
					<button class="popap-list__button" @click="unblockUser" v-else>{{
						$t('components.social.separateFriend.unblock') }}</button>
				</div>
				<button v-if="!isFriends" @click="sendRequestForFriend" class="friend__settings-add">
					<span class="friend__message-icon"></span>{{ $t('components.social.separateFriend.addAsFriend') }}
				</button>
				<button @click="deleteFriend" v-if="isFriends" class="friend__settings-delete">{{
					$t('components.social.separateFriend.removeFromFriends') }}</button>
			</div>
		</button>
	</div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import Cookies from "js-cookie";

export default {
	name: "SeparateFriend",
	props: ["friend", "index", "isFriends"],
	data() {
		return {
			menuVisible: false,
			limit: textSymbolLimit,
		};
	},
	computed: {
		blockedUsers() {
			return this.$store.getters.getMyBlockedUser;
		},
		isBlocked() {
			return this.blockedUsers.some((user) => user.id === this.friend.id);
		},
		favoritesFriends() {
			return this.$store.getters.getFavoriteFriends;
		},
		isFavorite() {
			return this.favoritesFriends.some((user) => user.id === this.friend.id);
		},
	},
	methods: {
		viewMenu(event) {
			event.stopPropagation();
			this.menuVisible = !this.menuVisible;
		},
		writeMessage() {
			Cookies.set("currentDialogueId", this.friend.id);
			Cookies.set("currentDialogueName", this.friend.name);
			Cookies.set("currentDialogueAvatar", this.friend.avatarUrl);
			Cookies.set("currentDialogueRole", this.friend.role);
			Cookies.set("currentUserRole", this.friend.role);
			this.$router.push({ name: "allChats" });
		},
		goToProfile() {
			if (this.friend.role === "candidate") {
				this.$router.push({ name: "candidateProfile", params: { summaryId: this.friend.id } });
			} else {
				this.$router.push({ name: "employerProfile", params: { summaryId: this.friend.id } });
			}
		},
		deleteFriend() {
			this.$store.dispatch("deleteFriends", {
				token: this.$store.getters.token,
				id: this.friend.id,
				index: this.index,
				role: this.friend.role,
			});
		},
		sendRequestForFriend() {
			this.$store.dispatch("sendRequestFriend", {
				role: this.friend.role,
				id: this.friend.id,
			});
		},
		blockUser() {
			this.$store.dispatch("blockFriend", {
				blockingRole: this.friend.role,
				blockingUserId: this.friend.id,
				token: this.$store.getters.token,
			});
		},
		unblockUser() {
			this.$store.dispatch("unblockFriend", {
				blockingRole: this.friend.role,
				blockingUserId: this.friend.id,
				token: this.$store.getters.token,
			});
		},
		addToFavoriteFriends() {
			this.$store.dispatch("addToFavoriteFriends", {
				friendRole: this.friend.role,
				friendId: this.friend.id,
				token: this.$store.getters.token,
			});
		},
		removeFromFavoriteFriends() {
			this.$store.dispatch("removeFromFavoriteFriends", {
				friendRole: this.friend.role,
				friendId: this.friend.id,
				token: this.$store.getters.token,
			});
		},
	},
};
</script>


<style scoped>
.friend-wrapper {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	display: flex;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	margin-bottom: 20px;
}

.friends-separate__left {
	display: flex;
}

.friend__face {
	position: relative;
}

.friend__face-img {
	width: 64px;
	height: 64px;
	display: flex;
	flex-shrink: 0;
	border-radius: 10px;
	object-fit: cover;
}

.friend__face-status {
	position: absolute;
	bottom: -2px;
	right: -4px;
	width: 9px;
	height: 9px;
	display: block;
	border-radius: 100%;
}

.friend__face-status.friend__face-status-active {
	background: #14c686;
	border: 2px solid #fff;
}

.friend__info {
	margin-left: 19px;
	width: 190px;
}

.friend__info-name {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 14px;
	color: #000000;
}

.friend__info-rating {
	margin-left: 10px;
	padding: 4px 11px 4px 25px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	font-weight: 700;
	font-size: 12px;
	border-radius: 20px;
	background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
	background-repeat: no-repeat;
	background-position-x: 9px;
	background-position-y: 5px;
	color: #14c686;
	line-height: 1;
}

.friend__info-speciality {
	margin-top: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: rgba(23, 22, 37, 0.5);
}

.friend__info-designation {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}

.friend__place {
	margin-left: 40px;
}

.friend__place-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #686873;
}

.friend__place-town {
	margin-top: 10px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 12px;
	color: #000000;
	display: flex;
}

.friend__place-icon {
	background: url("~@/assets/img/account-img/friends/town.svg");

	width: 11px;
	height: 13px;
	background-repeat: no-repeat;
	margin-right: 6px;

}

.friend__info-icon {
	background: url("~@/assets/img/account-img/friends/info.png");
	background-size: cover;

	width: 11px;
	height: 11px;
	background-position: center;
	margin: auto 3px auto 0;
}

.friend__message {
	background: #009ed1;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	margin: auto 0 auto auto;
	width: 214px;
	height: 45px;
	box-shadow: 0px 4px 12px -6px rgba(0, 158, 209, 1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.friend__message-settings {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	margin-right: 6px;
}

.friend__settings {
	position: relative;
	display: block;
	background: url("~@/assets/img/account-img/friends/settings.svg");
	width: 40px;
	height: 40px;
	margin: auto 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.friend__settings-popap {
	position: absolute;
	border: 1px solid #efeff4;
	border-radius: 10px;
	background: #fff;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 10px;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	bottom: -94px;
	right: -45px;
	z-index: 20;
}

.popap-list {
	position: relative;
	text-align: left;
}

.popap-list::after {
	position: absolute;
	top: -20px;
	right: 47px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;


}

.popap-list__button {
	text-align: left;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding-left: 10px;
	width: 100%;
	cursor: pointer;
}

.popap-list__button:not(:first-child) {
	margin-top: 6px;
}

.popap-list__button:last-child {
	padding-bottom: 10px;
}

.friend__settings-delete {
	padding: 10px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #e7665e;
	cursor: pointer;
}

.friend__settings-add {
	padding: 0px 10px 0px 10px;
	border-top: 1px solid #efeff4;
	font-family: "Roboto";
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #009ed1;
	cursor: pointer;
}

.friend__message-icon {
	background-image: url("~@/assets/img/account-img/menu/chat.svg");
	width: 16px;
	height: 15px;
	display: block;
	background-repeat: no-repeat;
	margin-right: 7px;
	background-size: cover;
}

.friend-mobile {
	display: none;
}

@media (max-width: 1575px) {
	.friend-wrapper {
		max-width: 400px;
		padding: 0;
		flex-direction: column;
	}

	.friend-desctop {
		display: none;
	}

	.friend-mobile {
		display: flex;
	}

	.friends-separate__left {
		padding: 13px 15px 13px 15px;
		align-items: center;
	}

	.friend__info-rating {
		margin-left: 10px;
	}

	.friend__info {
		width: 100%;
	}

	.friend__settings {
		margin-left: auto;
		height: 20px;
	}

	.friend__settings-popap {
		right: -15px;
	}

	.popap-list::after {
		right: 17px;
	}

	.friend__message {
		box-shadow: none;
	}

	.friend__info-designation {
		padding-top: 0;
	}

	.friend__info-designation {
		align-items: center;
	}

	.friend__info-name {
		font-size: 13px;
	}

	.friend__place-town {
		color: #696974;
		margin-top: 5px;
	}

	.friend__message {
		width: 100%;
		margin-left: 0;
		border-radius: 0px 0px 10px 10px;
	}
}

@media (max-width: 720px) {
	.friend-wrapper {
		padding: 0;
		margin-bottom: 0;
	}

	.friend__info-rating {
		padding: 4px 6px 4px 18px;
		font-size: 10px;
		background-size: 9px 8px;
		background-position-x: 6px;
		background-position-y: 5px;
		line-height: 1;
	}
}
</style>