<template>
  <div>
    <HeaderAccountCompany @close="viewAvatar = false" :photos="photos" :avatar="viewAvatar"></HeaderAccountCompany>

    <big-photo v-if="bigPhotoUrl" @closeBigPhoto="bigPhotoUrl = false" :url="bigPhotoUrl"></big-photo>


    <main class="main" ref="main">
      <div class="account">
        <MenuCompany></MenuCompany>

        <div class="container-account">
          <div class="filling__caption">
            <div class="filling__caption-head">
              <div class="filling__caption-head-greetings">
                <p class="filling__caption-head-greetings-text mobile">
                  {{ $t('pages.account.account_company.accountCompanyDashboardPage.personalCabinet') }}
                </p>
                <h1 class="filling__caption-head-greetings-title">
                  {{ $t('pages.account.account_company.accountCompanyDashboardPage.personalCabinet') }}
                </h1>
                <p class="filling__caption-head-greetings-text desctop">
                  {{ $t('pages.account.account_company.accountCompanyDashboardPage.welcome', {
                    name: dataEmployer.name
                  }) }}
                </p>
              </div>
              <SearchElement v-if="false">
              </SearchElement>
            </div>
            <div class="filling__caption-wrapp desctop">
              <div class="filling__caption-wrapp-statistics">
                <div class="filling__caption-wrapp-statistics-graph">
                  <h2 class="filling__caption-wrapp-statistics-graph-title">
                    {{ $t('pages.account.account_company.accountCompanyDashboardPage.statistics') }}
                  </h2>
                  <nav class="filling__caption-wrapp-statistics-graph-nav">
                    <button href="" class="filling__caption-wrapp-statistics-graph-nav-date">
                      Month
                    </button>
                    <button href="" class="filling__caption-wrapp-statistics-graph-nav-date">
                      A week
                    </button>
                    <button href="" class="filling__caption-wrapp-statistics-graph-nav-date">
                      Year
                    </button>
                  </nav>
                  <apexchart height="185" class="apexchart" type="bar" :options="chartOptions" :series="series">
                  </apexchart>
                </div>
                <span class="filling__caption-wrapp-statistics-border"></span>
                <div class="filling__caption-wrapp-statistics-circle">
                  <div class="filling__caption-wrapp-statistics-circle-title">
                    <h4 class="
                        filling__caption-wrapp-statistics-circle-title-text
                      ">
                      {{ $t('pages.account.account_company.accountCompanyDashboardPage.rating') }}
                    </h4>
                    <router-link :to="{ name: 'rateCompany' }" class="
                        filling__caption-wrapp-statistics-circle-title-link
                      ">
                      <img src="~@/assets/img/all-img/arrow-45.svg" alt="" />
                    </router-link>
                  </div>
                  <p class="filling__caption-wrapp-statistics-circle-rating">
                    <span>
                      {{ dataEmployer.companyRate }}
                    </span>
                  </p>
                  <p class="filling__caption-wrapp-statistics-circle-text">
                    {{ $t('pages.account.account_company.accountCompanyDashboardPage.positive') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="filling__caption-profile mobile">
              <div class="user__wrapper-person">
                <img :src="dataEmployer.logoPath" @click="viewAvatar = true" :style="{
                  cursor: 'pointer', backgroundImage: 'url(' + dataEmployer.logoPath + ')',
                }" alt="Company" class="user__wrapper-person-icon" />
                <p class="user__wrapper-person-name">
                  {{ dataEmployer.orgName }}
                </p>
                <p class="user__wrapper-person-finder">{{
                  $t('pages.account.account_company.accountCompanyDashboardPage.employer') }}</p>
                <button class="user__wrapper-person-change-btn" @click="$router.push({ name: 'settingsCompany' })">
                  {{ $t('pages.account.account_company.accountCompanyDashboardPage.edit') }}
                </button>
              </div>
              <div class="user__wrapper-description">
                <p class="user__wrapper-description-text">
                  {{ dataEmployer.companyDescription }}
                </p>
              </div>
            </div>
          </div>

          <div class="filling__vacancy">
            <div class="filling__vacancy-caption">
              <h2 class="filling__vacancy-caption-title">
                {{ $t('pages.account.account_company.accountCompanyDashboardPage.recommendedResumes') }}
              </h2>
              <router-link :to="{ name: 'recommendationsCompany' }" class="filling__vacancy-caption-btn">{{
                $t('pages.account.account_company.accountCompanyDashboardPage.seeAll') }}
              </router-link>
            </div>
            <div class="filling__vacancy-group">
              <div class="recomedation__wrapper">
                <Summary v-for="item in dataSummary" :key="item.id" :summary="item"></Summary>
              </div>
            </div>
          </div>

          <div class="proposed">
            <div class="proposed-caption">
              <h3 class="proposed__title">{{ $t('pages.account.account_company.accountCompanyDashboardPage.testing') }}
              </h3>
              <router-link :to="{ name: 'interviewPage' }" class="filling__vacancy-caption-btn">{{
                $t('pages.account.account_company.accountCompanyDashboardPage.seeAll') }}</router-link>
            </div>
            <div class="proposed-group">
              <button class="recomended-test recomended-test-big">
                <div class="recomended-test-caption">
                  <img alt="" src="~@/assets/img/vacancy/test-bg.svg" class="recomended-test-caption__img">
                  <div class="recomended-description">
                    <p class="recomended-description__comany">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.myInterview') }}</p>
                    <p class="recomended-description__format">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.totalTests', {
                        length:
                      dataInterview.length }) }}</p>
                  </div>
                </div>
                <div class="recomended-test-info">
                  <div class="recomended-info-element green">
                    <p class="recomended-info-name">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.underReview') }}</p>
                    <div class="recomended-info-text">
                      <p class="recomended-info-text__number">{{ dataInfo.waiting }}</p>
                      <p class="recomended-info-text__notifications" v-if="false">+99</p>
                    </div>
                  </div>
                  <div class="recomended-info-element red">
                    <p class="recomended-info-name">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.verified') }}</p>
                    <div class="recomended-info-text">
                      <p class="recomended-info-text__number">{{ dataInfo.checked }}</p>
                      <p class="recomended-info-text__notifications" v-if="false">-1</p>
                    </div>
                  </div>
                </div>
                <button @click="$router.push({ name: 'interviewPage' })" class="recomended-test__start">{{
                  $t('pages.account.account_company.accountCompanyDashboardPage.readMore') }}</button>
              </button>
              <DetailedTest :key="item.id" v-for="item in dataInterview" :name="dataEmployer.orgName"
                :avatar="dataEmployer.logoPath" :test="item" :employer="true">
              </DetailedTest>
            </div>
          </div>

          <div class="proposed">
            <div class="proposed-caption">
              <h3 class="proposed__title">{{ $t('pages.account.account_company.accountCompanyDashboardPage.communities')
                }}</h3>
              <router-link :to="{ name: 'communities' }" class="filling__vacancy-caption-btn">{{
                $t('pages.account.account_company.accountCompanyDashboardPage.seeAll') }}</router-link>
            </div>
            <div class="proposed-group">
              <button class="recomended-test">
                <div class="recomended-test-caption">
                  <img alt="" src="~@/assets/img/vacancy/community-bg.svg" class="recomended-test-caption__img">
                  <div class="recomended-description">
                    <p class="recomended-description__comany">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.myCommunities') }}</p>
                    <p class="recomended-description__format">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.totalCommunities', {
                        total:
                      dataInfo.closedComms + dataInfo.openComms }) }}</p>
                  </div>
                </div>
                <div class="recomended-test-info">
                  <div class="recomended-info-element green">
                    <p class="recomended-info-name">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.open') }}</p>
                    <div class="recomended-info-text">
                      <p class="recomended-info-text__number">{{ dataInfo.openComms }}</p>
                      <p class="recomended-info-text__notifications" v-if="false">+99</p>
                    </div>
                  </div>
                  <div class="recomended-info-element green">
                    <p class="recomended-info-name">{{
                      $t('pages.account.account_company.accountCompanyDashboardPage.closed') }}</p>
                    <div class="recomended-info-text">
                      <p class="recomended-info-text__number">{{ dataInfo.closedComms }}</p>
                      <p class="recomended-info-text__notifications" v-if="false">+99</p>
                    </div>
                  </div>
                </div>
                <button class="recomended-test__start" @click="$router.push({ name: 'communities' })">{{
                  $t('pages.account.account_company.accountCompanyDashboardPage.readMore') }}</button>
              </button>
              <DetailedCommunity v-for="item in dataGroups" :community="item" :key="item.id">
              </DetailedCommunity>
            </div>
          </div>

          <div class="post-wrapper">
            <div class="post-caption">
              <h2 class="post__title">{{ $t('pages.account.account_company.accountCompanyDashboardPage.recentEvents') }}
              </h2>
              <div class="select">
                <button class="select__btn" @click="viewFilter = !viewFilter">
                  {{
                    filter === 'self' ? $t('pages.account.account_company.accountCompanyDashboardPage.news.myNews') :
                      filter === 'friends' ?
                        $t('pages.account.account_company.accountCompanyDashboardPage.news.friendsNews') : filter ===
                          'extended' ? $t('pages.account.account_company.accountCompanyDashboardPage.news.recommendedNews') :
                          $t('pages.account.account_company.accountCompanyDashboardPage.news.allNews')
                  }}<span class="list-icon"></span></button>
                <div class="select-list" v-if="viewFilter">
                  <span class="select-list-icon"></span>
                  <button class="select-list__element" @click="changeFilter('self')">{{
                    $t('pages.account.account_company.accountCompanyDashboardPage.news.myNews') }}</button>
                  <button class="select-list__element" @click="changeFilter('friends')">{{
                    $t('pages.account.account_company.accountCompanyDashboardPage.news.friendsNews') }}</button>
                  <button class="select-list__element" @click="changeFilter('all')">{{
                    $t('pages.account.account_company.accountCompanyDashboardPage.news.allNews') }}</button>
                </div>
              </div>
            </div>
            <LastNewsWrapper @viewBigPhoto="val => bigPhotoUrl = val" :big-photo="bigPhotoUrl" :posts="posts">
            </LastNewsWrapper>
          </div>

        </div>

        <div class="user desctop-user">
          <div class="user__wrapper">
            <div class="user__wrapper-caption">
              <h6 class="user__wrapper-caption-title">{{
                $t('pages.account.account_company.accountCompanyDashboardPage.profile') }}</h6>
              <div class="user__wrapper-caption-btns">
                <button class="user__wrapper-caption-btns-bell">
                  <span class="user__wrapper-caption-btns-bell-notifications"></span>
                  <img src="~@/assets/img/all-img/bell-blue.svg" alt="" class="user__wrapper-caption-btns-bell-img" />
                </button>
                <router-link :to="{ name: 'settingsCompany' }" class="user__wrapper-caption-btns-change">
                  <img src="~@/assets/img/all-img/change.svg" alt="" class="user__wrapper-caption-btns-change-img" />
                </router-link>
              </div>
            </div>
            <div class="user__wrapper-person">
              <img :src="dataEmployer.logoPath" @click="viewAvatar = true" :style="{
                cursor: 'pointer',

                backgroundImage: 'url(' + dataEmployer.logoPath + ')',
              }" alt="Company" class="user__wrapper-person-icon" />
              <p class="user__wrapper-person-name">
                {{ dataEmployer.orgName }}
              </p>
              <p class="user__wrapper-person-finder">{{
                $t('pages.account.account_company.accountCompanyDashboardPage.employer') }}</p>
            </div>
            <div class="user__wrapper-description">
              <p class="user__wrapper-description-text">
                {{ dataEmployer.companyDescription }}
              </p>
            </div>
            <div class="user__wrapper-opportunities" v-if="false">
              <span class="user__wrapper-opportunities-decoration-1"></span>
              <span class="user__wrapper-opportunities-decoration-2"></span>
              <span class="user__wrapper-opportunities-decoration-3"></span>
              <span class="user__wrapper-opportunities-decoration-4"></span>
              <p class="user__wrapper-opportunities-text">
                {{ $t('pages.account.account_company.accountCompanyDashboardPage.upgradePlan') }}
              </p>
              <router-link :to="{ name: 'pricesCompany' }" class="user__wrapper-opportunities-button-link">
                {{ $t('pages.account.account_company.accountCompanyDashboardPage.morePossibilities') }}
              </router-link>
            </div>
            <a href="#" class="user__wrapper-help-btn">{{
              $t('pages.account.account_company.accountCompanyDashboardPage.needHelp') }}</a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import Summary from "../../../components/account/account_company/account_company_dashboard/item/Sumarry.vue";
import DetailedTest
  from "../../../components/account/account_worker/account_worker_dashboard/item/DetailedTest.vue";
import SearchElement
  from "../../../components/account/SearchElement.vue";
import DetailedCommunity
  from "../../../components/account/account_worker/account_worker_dashboard/item/DetailedCommunity.vue";
import { config } from "@/lib/config";
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import LastNewsWrapper from "@/components/social/account_news/LastNewsWrapper";
import BigPhoto from "@/components/social/account_communitis_main/BigPhoto.vue";

export default {
  components: {
    BigPhoto,
    LastNewsWrapper,
    Summary,
    MenuCompany,
    HeaderAccountCompany,
    DetailedTest, DetailedCommunity, SearchElement
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    photos() {
      let photos = [];
      if (this.dataEmployer?.logoPath) {
        photos.push(this.dataEmployer.logoPath);
      }
      if (this.dataEmployer?.avatarArchive && typeof this.dataEmployer?.avatarArchive === 'object') {
        photos = photos.concat(this.dataEmployer.avatarArchive);
      }
      return photos;
    }
  },

  data() {
    return {
      viewAvatar: false,

      dataInfo: null,
      posts: null,
      page: 1,
      postLimit: 6,
      dataEmployer: {},
      dataSummary: [],
      dataInterview: [],
      chartOptions: {
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        chart: {
          id: "vuechart-example",
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: ["#009ed1"],
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec",
          ],
          labels: {
            style: {
              colors: "#adbdd6",
              fontSize: "10px",
              fontWeight: 500,
              fontFamily: "Montserrat",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          showAlways: true,
          showForNullSeries: true,
          seriesName: undefined,
          opposite: false,
          reversed: false,
          logarithmic: false,
          logBase: 10,
          tickAmount: 4,
          min: 0,
          max: 100,
          forceNiceScale: false,
          floating: false,
          decimalsInFloat: undefined,
          labels: {
            style: {
              colors: "#adbdd6",
              fontSize: "10px",
              fontWeight: 500,
              fontFamily: "Montserrat",
            },
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 9,
            columnWidth: "32%",
            barHeight: "100%",
            colors: {
              ranges: [
                {
                  from: 3,
                  to: "100%",
                },
              ],
              backgroundBarColors: ["#e3f0ff"],
              backgroundBarRadius: 9,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },
      },
      series: [
        {
          name: "Points",
          data: [20, 50, 75, 99, 20, 50, 75, 99, 20, 50, 75, 99],
        },
      ],
      dataGroups: null,
      limit: textSymbolLimit,
      resumeName: null,
      viewFilter: false,
      filter: 'self',
      bigPhotoUrl: null
    };
  },
  methods: {
    changeFilter(filter) {
      this.filter = filter;
      this.page = 1;
      this.viewFilter = false;
      this.loadPosts();
      this.paginationLoad();

    },
    searchResumes() {
      this.$router.push({ name: 'vacanciesCompany', params: { resumeName: this.resumeName } });
    },
    fetchInterviewEmployer() {
      let xmlHttp = new XMLHttpRequest();
      let url = config.apiPrefix + "interview/all";
      xmlHttp.open("GET", url, false);
      xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      try {
        xmlHttp.send();
        let response = JSON.parse(xmlHttp.response);
        this.dataInterview = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    fetchDataEmployer() {
      this.$store.dispatch('fetchEmployerData').then(() => {
        this.dataEmployer = this.user;
        this.dataEmployer.avatarArchive = this.dataEmployer.avatarArchive ? this.dataEmployer.avatarArchive.split(',').filter(item => item !== '') : null;

      })
    },
    fetchFamiliarSummary() {
      let xmlHttp = new XMLHttpRequest();
      let url = config.apiPrefix + "employer/recommends";
      xmlHttp.open("GET", url, false);
      xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      try {
        xmlHttp.send();
        let response = JSON.parse(xmlHttp.response);
        this.dataSummary = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    fetchDataGroups() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'community?page=1&limit=4';
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        const res = JSON.parse(req.response);
        this.dataGroups = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    fetchTestingInfo() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'employer/interview/stats';
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        const res = JSON.parse(req.response);
        this.dataInfo = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    loadPosts() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'user/news?page=' + this.page + '&limit=' + this.postLimit + '&filter=' + this.filter;
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        const { data } = JSON.parse(req.response);
        if (this.page > 1) {
          this.posts = this.posts.concat(data)
        } else {
          this.posts = data;
        }
        if (data.length < this.postLimit) {
          window.onscroll = null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    paginationLoad() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 10 >= this.$refs.main.offsetHeight;
        if (bottomOfWindow) {
          this.page++;
          this.loadPosts();
        }
      }
    }
  },
  name: "AccountCompanyDashboardPage",
  beforeMount() {
    scroll(0, 0);
    this.fetchDataEmployer();
    this.fetchFamiliarSummary();
    this.fetchInterviewEmployer();
    this.fetchDataGroups();
    this.fetchTestingInfo();
    this.loadPosts();
  },
  beforeDestroy() {
    window.onscroll = null;
  }
};
</script>


<style scoped>
.account {
  display: flex;
}

.mobile {
  display: none;
}

.filling {
  margin: 0 auto;
  max-width: 1083px;
  width: 100%;
  padding: 59px 30px 50px 30px;
}

.filling__caption {
  width: 100%;
}

.filling__caption-head {
  display: flex;
  align-items: center;
}

.filling__caption-head-greetings {
  margin: auto 0;
}

.filling__caption-head-greetings-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.filling__caption-head-greetings-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.filling__caption-head-surch {
  margin-left: auto;
  position: relative;
}

.filling__caption-head-surch-entry {
  height: 55px;
  margin: auto 0;
  width: 307px;
  padding-left: 33px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  letter-spacing: 0.02em;
  color: #171725;
  font-weight: 400;
  padding-right: 61px;
  font-size: 16px;
}

.filling__caption-head-surch-entry::placeholder {
  color: #b7c1d1;
}

.filling__caption-head-surch-find {
  top: 9px;
  position: absolute;
  display: inline-block;
  right: 10px;
  transition: opacity 1s ease;
  padding: 12px;
  line-height: 0;
  background: linear-gradient(192.41deg, #4099ff 0%, #a1a8ff 138.89%);
  border-radius: 100%;
}

.filling__caption-head-surch-find-img {
  object-fit: cover;
  width: 12px;
  height: 12px;
}

.filling__caption-head-surch-find:hover {
  opacity: 0.5;
}

.filling__caption-wrapp {
  margin-top: 40px;
  display: flex;
  max-height: 267px;
}

.filling__caption-wrapp-statistics {
  padding: 0px 40px 0 0;
  min-height: 300px;
  align-items: center;
  background: #fff;
}

.filling__caption-wrapp-statistics-graph {
  padding-right: 43px;
  width: 100%;
}

.filling__caption-wrapp-statistics-graph-title {
  padding-top: 29px;
  padding-left: 45px;
  font-family: "Montserrat";
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filling__caption-wrapp-statistics-graph-nav {
  padding-left: 45px;
  margin-top: 24px;
  align-items: center;
  display: flex;
}

.filling__caption-wrapp-statistics-graph-nav-date {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 9px;
  text-align: left;
  color: #b7c1d1;
  transition: color 1s ease;
}

.filling__caption-wrapp-statistics-graph-nav-date:hover {
  color: #3b4c68;
}

.date-active {
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-nav-date:not(:last-child) {
  margin-right: 25px;
}

.filling__caption-wrapp-statistics-graph-group-element {
  margin-top: 15px;
}

.filling__caption-wrapp-statistics-graph-group-element-text {
  display: flex;
}

.filling__caption-wrapp-statistics-graph-group-element-text-category {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group-element-text-points {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #009ed1;
}

.filling__caption-wrapp-statistics-graph-group-element-field {
  margin-top: 17px;
  width: 100%;
  height: 10px;
  background: #e3f0ff;
  border-radius: 69px;
}

.filling__caption-wrapp-statistics-graph-group-element-fill-repletion {
  width: 51%;
  height: 100%;
  border-radius: 69px;
  background: #009ed1;
}

.filling__caption-wrapp-statistics-circle {
  padding-left: 17px;
  max-width: 190px;
  width: 100%;
}

.filling__caption-wrapp-statistics-circle-title {
  display: flex;
  align-items: center;
}

.filling__caption-wrapp-statistics-circle-title-text {
  padding-left: 19px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-circle-title-link {
  margin-left: auto;
  padding: 6px 8px;
  background: #f2fbfc;
  border-radius: 100%;
}

.filling__caption-wrapp-statistics-circle-rating {
  background: #f3f3f3;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 28px;
  border-radius: 100%;
  margin: 49px auto 0 auto;
  text-align: center;
  color: #14c686;
  line-height: 28px;
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filling__caption-wrapp-statistics-circle-rating span {
  display: flex;
  justify-content: center;
}

.filling__caption-wrapp-statistics-circle-text {
  text-align: center;
  padding-top: 31px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: #14c686;
}

/* proposed */
.proposed {
  margin-top: 40px;
  max-width: 100%;
  width: 100%;
}

.proposed-caption {
  display: flex;
  justify-content: space-between;
}

.proposed__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #3b4c68;
}

.proposed__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #3b4c68;
}

.proposed-group {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* post */
.post-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.post-caption {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #3b4c68;
}

.select {
  position: relative;
}

.select__btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #4099ff;
  transition: all 0.5s ease;
}

.select__btn:hover {
  opacity: 0.5;
}

.filling__vacancy-caption-btn:hover {
  opacity: 0.5;
}

.list-icon {
  background: url(../../../assets/img/all-img/list-grey.svg);
  width: 8px;
  height: 6px;
  margin-left: 6px;
  background-size: contain;
}

.select-list {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  bottom: -130px;
  width: 218px;
  right: -15px;
  z-index: 1000000;
}

.select-list-icon {
  display: block;
  position: absolute;
  background: url(../../../assets/img/all-img/select-arrow.svg);
  right: 12px;
  top: -10px;
  width: 14px;
  height: 10px;
  content: "";
  background-size: contain;
}

.select-list__element {
  padding: 6px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  max-width: 100%;
  width: 100%;
  text-align: left;
  color: #44444f;
  transition: all 0.5s ease;
}

.select-list__element:hover {
  color: #4099ff;
}

.filling__caption-wrapp-statistics-circle-rating span {
  display: flex;
  justify-content: center;
}

.filling__caption-wrapp-statistics-circle-text {
  text-align: center;
  padding-top: 31px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: #14c686;
  min-width: 160px;
}

/*recomended-test*/
.recomended-test {
  max-width: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #f1f5f9;
  border-radius: 10px;
  height: 205px;
  box-sizing: border-box;
  font-family: "Roboto";
  text-align: left;
}

.recomended-test-big {
  height: 225px;
}

.recomended-test-caption {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.recomended-test-caption__img {
  width: 53px;
  height: 53px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  object-fit: cover;
}

.recomended-description__comany {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  display: flex;
  align-items: center;
  gap: 6px;
}

.recomended-description__format {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.recomended-description__comany-status {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-top: 2px;
}

.recomended-test-info {
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  gap: 5px;
  width: 100%;
}

.recomended-info-element {
  display: flex;
  max-width: 100%;
  text-align: left;
  width: 100%;
  flex-direction: column;
}

.recomended-info-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(59, 76, 104, 0.800000011920929);
}

.recomended-info-text {
  display: flex;
  align-items: center;
  margin-top: 5px;

}

.recomended-info-text__number {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #3b4c68;
}

.recomended-info-text__notifications {
  margin-left: 5px;
  padding: 4px 8px;
  border-radius: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #14c686;

}

.red .recomended-info-text__notifications {
  background: #db0a0a;
}

.recomended-test__start {
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  border-top: 1px solid rgba(231, 233, 236, 0.8);
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
  color: #009ed1;
}

@media (max-width: 1710px) {
  .proposed-group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1360px) {
  .filling__caption-wrapp-statistics-graph-title {
    padding-top: 4px;
  }
}

.filling__caption-wrapp-statistics {
  width: 100%;
  border: 1px solid #ebedf0;
  border-radius: 20px;
  display: flex;
}

.filling__caption-wrapp-statistics-border {
  margin-left: auto;
  width: 1px;
  height: 221px;
  background: url("~@/assets/img/profile-page/caption/border.png");
}

/* Vacancy */
.filling__vacancy {
  margin-top: 65px;
}

.filling__vacancy-caption {
  display: flex;
  align-items: center;
}

.filling__vacancy-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__vacancy-caption-btn {
  margin-left: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4099ff;
  transition: all 0.5s ease;
}

.filling__vacancy-caption-btn:hover {
  opacity: 0.5;
}

.filling__vacancy-group {
  display: grid;
  padding-bottom: 30px;
}

@media (max-width: 1200px) {
  .proposed-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1360px) {

  .user__wrapper {
    margin: 40px auto 0 auto;
  }

  .filling__caption-head-surch-entry {
    width: 256px;
    height: 46px;
    padding-left: 27px;
  }

  .filling__caption-head-surch-find {
    padding: 9px;
    top: 8px;
  }
}

@media (max-width: 950px) {
  .filling {
    max-width: 690px;
    box-sizing: border-box;
  }
}

@media (max-width: 720px) {
  .filling__caption-profile {
    flex-direction: column;
  }

  .user__wrapper-person {
    margin: 0 auto;
  }

  .user__wrapper-description {
    margin: 20px auto 0 auto;
  }

  .filling__vacancy {
    margin-top: 40px;
  }

  .proposed {
    max-width: 300px;
    margin: 40px auto 0 auto;
  }

  .proposed-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .post-wrapper {
    max-width: 300px;
    margin: 40px auto 0 auto;
  }

  .big-recomended-test {
    height: 205px;
  }

  .list-icon {
    display: none;
  }

  .select__btn {
    font-size: 0;
    background: url(../../../assets/img/all-img/select-icon.svg);
    width: 24px;
    height: 24px;
  }

  .select-list {
    bottom: -130px;
    right: 0;
    width: 300px;
  }

  .select-list-icon {
    right: 6px;
  }
}

@media (max-width: 600px) {
  .filling__caption-head {
    flex-direction: column;
  }

  .filling__caption-head-greetings-text {
    text-align: center;
  }

  .filling__caption-head-surch {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }

  .filling__caption-head-surch-entry {
    width: 100%;
  }

  .filling__caption-head-greetings-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .filling__caption-head-greetings-title {
    color: #000000;
    font-size: 20px;
  }
}

@media (max-width: 490px) {
  .filling {
    padding: 76px 10px 60px 10px;
  }

  .filling__vacancy-caption-title {
    font-size: 13px;
  }

  .filling__vacancy-caption-btn {
    font-size: 12px;
  }
}

@media (max-width: 340px) {
  .filling__caption-head-greetings {
    margin: 0;
  }

  .filling__caption-head-greetings-text {
    text-align: left;
  }

  .filling__caption-head {
    align-items: unset;
  }
}

/* /Vacancy */

/* Smmary */

.recomedation__wrapper {
  margin-top: 20px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 36px;
  display: grid;
  row-gap: 12px;
}

@media (max-width: 1840px) {
  .recomedation__wrapper {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 336px);
    column-gap: 38px;
    row-gap: 20px;
  }
}

@media (max-width: 1779px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1140px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 950px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* /Smmary */

/* User */
.user {
  padding-top: 59px;
  background: #fff;
  max-width: 429px;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  border-left: 1px solid #ebedf0;
}

@media (max-height: 950px) {
  .user {
    scrollbar-width: thin;
  }
}

.user__wrapper {
  display: flex;
  width: 284px;
  margin: 26px auto 0 auto;
  flex-direction: column;
}

.user__wrapper-caption {
  display: flex;
  align-items: center;
}

.user__wrapper-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  color: #3b4c68;
}

.user__wrapper-caption-btns {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.user__wrapper-caption-btns-bell {
  margin-right: 9px;
  position: relative;
  background-position: center;
  transition: opacity 1s ease;
  background: #edf7f9;
  border-radius: 100%;
  padding: 12px 13px;
  line-height: 0;
}

.user__wrapper-caption-btns-bell:hover {
  opacity: 0.5;
}

.user__wrapper-caption-btns-bell-notifications {
  display: none;
  position: absolute;
  content: "";
  top: 8px;
  right: 9px;
  width: 10px;
  height: 10px;
  background: #14c686;
  border-radius: 100%;
}

.user__wrapper-caption-btns-change {
  background: #edf7f9;
  border-radius: 100%;
  padding: 12px 13px;
  line-height: 0;
  transition: opacity 1s ease;
}

.user__wrapper-caption-btns-change:hover {
  opacity: 0.5;
}

.user__wrapper-person {
  margin-top: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.user__wrapper-person-icon {
  margin: 0 auto;
  background: #000000;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  object-fit: contain;
  object-position: center;
}

.user__wrapper-person-name {
  padding-top: 32px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.user__wrapper-person-finder {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #b7c1d1;
}

.user__wrapper-description {
  margin-top: 30px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.user__wrapper-description-text {
  overflow: hidden;
  max-height: 198px;
  padding: 18px 27px 20px 27px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #495468;
}

.user__wrapper-opportunities {
  margin-top: 37px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #ecf5fa;
  border-radius: 20px;
}

.user__wrapper-opportunities-decoration-1 {
  position: absolute;
  display: block;
  top: -8px;
  left: 16px;
  background: url("~@/assets/img/account-img/dashboard/decoration-1.svg");
  width: 24px;
  height: 22px;
}

.user__wrapper-opportunities-decoration-2 {
  position: absolute;
  display: block;
  right: -11px;
  top: -8px;
  background: url("~@/assets/img/account-img/dashboard/decoration-2.svg");
  width: 27px;
  height: 28px;
}

.user__wrapper-opportunities-decoration-3 {
  position: absolute;
  display: block;
  bottom: 21px;
  left: -11px;
  background: url("~@/assets/img/account-img/dashboard/decoration-3.svg");
  width: 24px;
  height: 30px;
}

.user__wrapper-opportunities-decoration-4 {
  position: absolute;
  display: block;
  bottom: 0;
  right: -5px;
  background: url("~@/assets/img/account-img/dashboard/decoration-4.svg");
  width: 28px;
  height: 29px;
}

.user__wrapper-opportunities-text {
  padding: 27px 20px 0 34px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.user__wrapper-opportunities-button-link {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #ffffff;

  display: block;
  margin: 22px auto 26px auto;
  width: 232px;
  padding: 16px 0;
  box-sizing: border-box;
  background: #009ed1;
  text-align: center;
  border-radius: 8px;
  transition: background 1s ease;
}

.user__wrapper-opportunities-button-link:hover {
  background: #80cfe8;
}

.user__wrapper-help-btn {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #009ed1;

  display: block;
  margin: 22px auto 26px auto;
  width: 232px;
  padding: 16px 0;
  box-sizing: border-box;
  background: rgba(0, 158, 209, 0.05000000074505806);
  text-align: center;
  border-radius: 8px;
  transition: opacity 1s ease;
}

.user__wrapper-help-btn:hover {
  opacity: 0.5;
}

@media (max-width: 1779px) {
  .filling {
    max-width: 760px;
  }
}

@media (max-width: 950px) {
  .desctop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .filling {
    padding-top: 47px;
    max-width: 700px;
  }

  .filling__caption-profile {
    margin-top: 28px;
    display: flex;
  }

  .user__wrapper-person {
    margin-top: 0;
    padding: 20px 20px 30px 20px;
    max-width: 300px;
    border: 1px solid #ebedf0;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
  }

  .user__wrapper-person-name {
    padding-top: 23px;
  }

  .user__wrapper-person-change-btn {
    margin-top: 20px;
    padding: 15px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #009ed1;
    border-radius: 8px;

    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #009ed1;
    transition: opacity 1s ease;
  }

  .user__wrapper-person-change-btn:hover {
    opacity: 0.5;
  }

  .user__wrapper-description {
    margin-top: 0;
    border: 1px solid #ebedf0;
    margin-left: auto;
    max-width: 314px;
    width: 100%;
  }

  .user__wrapper-description-text {
    padding: 18px 15px 24px 21px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.73;
  }
}

@media (max-width: 1740px) {
  .user {
    max-width: 326px;
  }
}

@media (max-width: 950px) {
  .desctop-user {
    display: none;
  }
}

@media (max-width: 720px) {
  .user__wrapper-description {
    margin-top: 20px;
    max-width: 300px;
  }
}

/* /User */

/* Tests */

.filling__tests {
  margin-top: 40px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.filling__tests-caption {
  padding-top: 23px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.filling__tests-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__tests-caption-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4099ff;
  margin-left: auto;
}

.filling__tests-group {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 336px);
  column-gap: 38px;
  row-gap: 20px;
}

@media (max-width: 1779px) {
  .filling__tests-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

.test__group-element:nth-child(2n) {
  margin-left: auto;
}

@media (max-width: 1140px) {
  .filling__tests-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .test__group-element {
    margin: 0 auto;
    max-width: 400px;
  }
}

@media (max-width: 950px) {
  .filling__tests-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .test__group-element {
    margin: 0;
    max-width: 300px;
  }

  .test__group-element:nth-child(2n) {
    margin-left: auto;
  }
}

@media (max-width: 720px) {
  .filling__tests-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .test__group-element {
    margin: 0 auto;
    max-width: 300px;
  }
}

@media (max-width: 490px) {
  .filling__tests-caption-title {
    font-size: 13px;
  }

  .filling__tests-caption-link {
    font-size: 12px;
  }
}

/* /Tests */

/* Responses adaptive */
@media (max-width: 1840px) {
  .recomedation__wrapper {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 336px);
    -moz-column-gap: 38px;
    column-gap: 38px;
    row-gap: 20px;
  }
}

@media (max-width: 1779px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1140px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 950px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* /Responses adaptive */

/* Groups */
.filling__groups {
  margin-top: 40px;
}

.filling__groups-list {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 336px);
  column-gap: 38px;
  row-gap: 20px;
}

.filling__groups-list-element {
  width: 336px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 9px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
}

.filling__groups-list-element-caption {
  height: 190px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-position: center;
  background-size: cover;
  border: 1px solid #efeff4;
  border-radius: 9px 9px 0px 0px;
}

.filling__groups-list-element-caption-company {
  position: absolute;
  left: 20px;
  bottom: 16px;
  align-items: center;
  display: flex;
}

.filling__groups-list-element-caption-company-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.filling__groups-list-element-caption-company-name {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.filling__groups-list-element-about {
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f5;
}

.filling__groups-list-element-about-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 1.36;
  color: #3b4c68;
}

.filling__groups-list-element-about-text {
  padding-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #686873;
}

.filling__groups-list-element-about-go {
  padding: 15px 20px;
  width: 100%;
  box-sizing: border-box;
}

.filling__groups-list-element-about-go-btn {
  display: block;
  padding: 14px 0;
  border: 1px solid #009ed1;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #009ed1;
  transition: opacity 1s ease;
}

.filling__groups-list-element-about-go-btn:hover {
  opacity: 0.5;
}

.filling__groups-list-element:nth-child(2n) {
  margin-left: auto;
}

@media (max-width: 1840px) {
  .filling__groups-list {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 336px);
    -moz-column-gap: 38px;
    column-gap: 38px;
    row-gap: 20px;
  }

  .filling__groups-list-element {
    max-width: 334px;
  }
}

@media (max-width: 1779px) {
  .filling__groups-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1140px) {
  .filling__groups-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .filling__groups-list-element {
    margin: 0 auto;
  }
}

@media (max-width: 950px) {
  .filling__groups-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .filling__groups-list-element {
    margin: 0;
    max-width: 300px;
  }

  .filling__groups-list-element-about-go-btn {
    padding: 7px 0;
  }
}

@media (max-width: 720px) {
  .filling__groups-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .filling__groups-list-element {
    margin: 0 auto;
  }
}

/* /Groups */

/* Adaptive for 1360 */
.apexchart {
  margin-left: 26px;
  max-width: 713px;
  width: 100%;
  max-height: 200px;
  box-sizing: border-box;
}

.apexcharts-toolbar {
  display: none;
}

@media (max-width: 1360px) {
  .filling__caption-wrapp-statistics {
    align-items: unset;
  }

  .filling__caption-wrapp-statistics-graph-title {
    padding-top: 30px;
  }

  .filling__caption-wrapp-statistics-circle-title {
    padding-top: 30px;
  }

  .filling__caption-wrapp-statistics-circle-title {
    align-items: unset;
  }
}

@media (max-width: 1106px) {
  .filling__tests-caption {
    padding-top: 0;
  }

  .filling {
    padding: 59px 20px 0 20px;
  }

  .filling__caption-wrapp-statistics {
    padding-right: 20px;
    min-height: 274px;
  }

  .filling__caption-wrapp-statistics-graph-title {
    padding-left: 20px;
    padding-top: 20px;
  }

  .filling__caption-wrapp-statistics-graph-nav {
    padding-left: 20px;
  }

  .apexchart {
    margin-left: 4px;
  }

  .filling__caption-wrapp-statistics-graph {
    padding-right: 10px;
  }

  .filling__caption-wrapp-statistics-circle {
    max-width: 165px;
  }

  .filling__caption-wrapp-statistics-circle-title-text {
    padding-left: 0;
  }

  .filling__caption-wrapp-statistics-circle-title {
    padding-top: 20px;
  }

  .user {
    max-width: 270px;
  }

  .user__wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

/* Adaptive for 1360 */
</style>
filling__tests-caption