import {config} from "@/lib/config";
import {errorHandler} from "@/lib/errorHandler";

export const documentsCandidateCopy = {
    actions: {
				fetchUniversityById({ getters }, universityId) {
					console.log('test')
					return new Promise((resolve, reject) => {
						const req = new XMLHttpRequest();
						const url = config.apiPrefix + 'system/university?id=' + universityId;
						req.open('GET', url, false);
						req.setRequestHeader('X-Auth-Token', getters.token); 
				
						req.onload = () => {
							if (req.status >= 200 && req.status < 300) {
								const { data } = JSON.parse(req.response);
								resolve(data); 
							} else {
								reject(new Error('Error fetching university data')); 
							}
						};
				
						req.onerror = () => reject(new Error(req.statusText)); 
				
						req.send(); 
					});
				},
				updateUniversityData({ dispatch, commit}, payload) {
					const documentsData = payload.documentsData;
					const universityRequests = [];
					if (documentsData.university && documentsData.university.name) {
						universityRequests.push(dispatch('fetchUniversityById', documentsData.university.name));
					}
			
					if (documentsData.additionUniversity && documentsData.additionUniversity.length > 0) {
						documentsData.additionUniversity.forEach(university => {
							if (university.name) {
								universityRequests.push(dispatch('fetchUniversityById', university.name));
							}
						}
						);
					}
					
					Promise.all(universityRequests).then(universityData => {
						let a = false;
						if (documentsData.university && documentsData.university.name) {
							documentsData.university.name = universityData[0]; 
							documentsData.university.country.id = universityData[0].countryId; 
							a=true;
						}
			
						if (documentsData.additionUniversity && documentsData.additionUniversity.length > 0) {
							documentsData.additionUniversity = documentsData.additionUniversity.map((university, index) => {
								if(a){
								university.name = universityData[index+1]
								university.country.id = universityData[index+1].countryId; 
								}else{
									university.name = universityData[index]
									university.country.id = universityData[index].countryId; 
								}
								return university; 
							});
						}
						commit('setDocuments', documentsData);
					}).catch(error => {
						console.error('Error updating university data:', error);
					});
				},
			
			
        fetchCurrentVideoInterview({commit}, mode) {
            let data;
            if (mode === 'interview') {
                data = [
                    {
                        id: '1',
                        title: 'Question 1',
                        description: "What is your name?",
                    },
                    {
                        id: '2',
                        title: 'Question 2',
                        description: "How old are you?",
                    },
                    {
                        id: '3',
                        title: 'Question 3',
                        description: "Tell us about plans for the near future.",
                    },
                    {
                        id: '4',
                        title: 'Question 4',
                        description: "Tell us about a turning point, fateful moment.",
                    },
                    {
                        id: '5',
                        title: 'Question 5',
                        description: "Tell us about your strongest fear.",
                    }

                ];
            } else {
                data = [{
                    id: '1',
                    title: 'Question 1',
                    description: "What are my true intentions?",
                },
                    {
                        id: '2',
                        title: 'Question 2',
                        description: "What are my main values?",
                    },
                    {
                        id: '3',
                        title: 'Question 3',
                        description: "What hidden desires and aspirations are I used to ignoring?",
                    }, {
                        id: '4',
                        title: 'Question 4',
                        description: "Who are those people who are always cheating with me?",
                    }, {
                        id: '5',
                        title: 'Question 5',
                        description: "Who, on the contrary, always as if drawing energy from me?",
                    },

                ];
            }
            commit('setCurrentVideoInterview', data);
        },
        fetchCompanies({commit, getters}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/forbes_companies?page=1&limit=200' + (payload && payload.name ? '&name=' + payload.name : '');
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = async function () {
                    if (this.readyState === 4 && this.status === 200) {
                        const {data} = JSON.parse(req.response);
                        commit('setCompanies', data);
                        payload && payload.callback ? payload.callback() : null;
                    }
                }

            } catch (e) {
                console.log(e);
            }
        },
        fetchCountries({commit, getters}) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/countries?page=1&limit=200'
            req.open('GET', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                const {data} = JSON.parse(req.response);
                commit('setCountries', data);
            } catch (e) {
                console.log(e);
            }
        },
        fetchSystemPoints({commit, getters}, locale) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/point?page=1&limit=100&lang='+locale;
            req.open('GET', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                const {data} = JSON.parse(req.response);
                commit('setSystemPoints', data);
            } catch (e) {
                console.log(e);
            }
        },
        fetchDifference({commit, getters}, locale) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/grades?page=1&limit=100&lang='+locale;
            req.open('GET', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                const {data} = JSON.parse(req.response);
                commit('setDifference', data);
            } catch (e) {
                console.log(e);
            }
        },
        fetchUniversity({commit, getters}, payload) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'system/universities?page=1&limit=200' + (payload && payload.name ? '&name=' + payload.name : '') +(payload && payload.country ? '&country=' + payload.country : '');
            req.open('GET', url, true);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                req.onreadystatechange = async function () {
                    if (this.readyState === 4 && this.status === 200) {
                        const {data} = JSON.parse(req.response);
                        commit('setUniversity', data);
                        payload && payload.callback ? payload.callback() : null;
                    }
                }

            } catch (e) {
                console.log(e);
            }
        },
        async deleteDocument({getters, commit}) {
            commit('setDocumentsDataSave', getters.documentToBeDeleted)
            await fetch(config.apiPrefix + 'candidate/documents/save', {
                method: 'POST',
                body: JSON.stringify(getters.getDocumentsDataSave),
                headers: {
                    'X-Auth-Token': getters.token,
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then(() => {
                commit('setDocumentBeDeleted', null)
                this.dispatch('fetchDocuments');
            })
        },
        fetchDocuments({commit, getters,dispatch}) {
            const req = new XMLHttpRequest();
            const url = config.apiPrefix + 'candidate/documents/all';
            req.open('GET', url, false);
            req.setRequestHeader('X-Auth-Token', getters.token);
            try {
                req.send();
                const {data} = JSON.parse(req.response);
                commit('setDocuments', data);
								console.log(data)
								dispatch('updateUniversityData', {documentsData: data}).then(() => {
									console.log('University data updated after fetching documents');
								}).catch(error => {
									console.error('Error updating university data:', error);
								});

            } catch (e) {
                console.log(e);
            }
        },
        async saveDocumentData({getters, commit}, callback) {

            if (getters.getDocumentsDataFile.length !== 0) {
                await callback?.files();
            } else {
                await commit('setDocumentsDataSave', getters.getDocumentsData);
                const req = new XMLHttpRequest();
                const url = config.apiPrefix + 'candidate/documents/save';
                req.open('POST', url, true);
                req.setRequestHeader('X-Auth-Token', getters.token);
                req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                try {
                    req.send(JSON.stringify(getters.getDocumentsDataSave));
                    req.onreadystatechange = async function () {
                        if (this.readyState === 4 && this.status === 200) {
                            await callback?.doc();
                            errorHandler({page: 'doc', status: req.status})
                        }
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        },
        async uploadDocumentDataFiles({getters, commit}, callback) {
            await commit('changeLoading', true);
            let loadSuccess = 0;
            let files = getters.getDocumentsDataFile;
            for (let i = 0; i < files.length; i++) {
                let formData = new FormData();
                formData.append('file', files[i].file);
                let request = new XMLHttpRequest();
                let url = config.apiPrefix + 'file/candidate/upload';
                request.open('POST', url, true);
                request.setRequestHeader('X-Auth-Token', getters.token);
                try {
                    request.send(formData);
                    request.onreadystatechange = async function () {
                        if (this.readyState === 4 && this.status === 200) {
                            const {data} = JSON.parse(request.response);
                            await commit('setDocumentsDataFile', {
                                index: files[i].index,
                                url: data.url,
                                data: {
                                    field: files[i].field
                                }
                            });
                            loadSuccess++;
                            if (loadSuccess === files.length) {
                                await commit('changeLoading', false);
                                await commit('setFileQueue', null);
                                callback();
                            }
                        }
												else{
													errorHandler({page: 'doc', status: request.status})
													commit('changeLoading', false);
												}
                    }
                } catch (e) {
									console.log('error upload file')
                }
            }


        }
    },
    mutations: {
        deleteVideoInterview(state, mode) {
            if (!state.documentsData) return;
            if (mode === 'interview') {
                state.documentsData.videoInterviewUrl = '';

            } else {
                state.documentsData.psychologicalTestUrl = '';
            }
        },


        setDocumentBeDeleted(state, data) {
            state.documentToBeDeleted = data;
        },
        setDocumentsDataSave(state, data) {
            state.saveDocuments = JSON.parse(JSON.stringify(data));
            if (state.saveDocuments.school.country != null) {
                state.saveDocuments.school.country = state.saveDocuments.school.country.id ? state.saveDocuments.school.country.id : state.saveDocuments.school.country;
            }
            delete state.saveDocuments.school.cr1.status;
            delete state.saveDocuments.school.cr2.status;
            if (state.saveDocuments.university.country != null) {
                state.saveDocuments.university.country = state.saveDocuments.university.country.id ? state.saveDocuments.university.country.id : state.saveDocuments.university.country;
            } 
						if (state.saveDocuments.university.name != null) {
                state.saveDocuments.university.name = state.saveDocuments.university.name.id ? state.saveDocuments.university.name.id : state.saveDocuments.university.name;
            } 
            if (state.saveDocuments.university.educationLevel != null) {
                state.saveDocuments.university.educationLevel = state.saveDocuments.university.educationLevel.value;
            }
            delete state.saveDocuments.university.cr3.status;
            delete state.saveDocuments.university.cr4.status;
            delete state.saveDocuments.university.cr11.status;

            state.saveDocuments.technicalSkills.cr5.grant?.forEach(item => delete item.status);
            delete state.saveDocuments.technicalSkills.cr6.status;
            state.saveDocuments.technicalSkills.cr7.competition?.forEach(item => delete item.status);
            state.saveDocuments.technicalSkills.cr8.competition?.forEach(item => delete item.status);
            delete state.saveDocuments.technicalSkills.cr9.status;
            delete state.saveDocuments.technicalSkills.cr10.status;
            delete state.saveDocuments.personalQualities.cr12.status;
            state.saveDocuments.personalQualities.cr13.message?.forEach(item => delete item.status);
            state.saveDocuments.personalQualities.cr14.competition?.forEach(item => delete item.status);
            state.saveDocuments.personalQualities.cr15.socialNetwork?.forEach(item => delete item.status);
            delete state.saveDocuments.id;

            state.saveDocuments.school.cr1.attestat?.forEach(item => {
                if (item.estimation) item.estimation = Number(item.estimation)
            })
            state.saveDocuments.university.cr3.attestat?.forEach(item => {
                if (item.estimation) item.estimation = Number(item.estimation)
            })
            state.saveDocuments.school.cr1.attestat = state.saveDocuments.school.cr1.attestat?.filter(item => item.name != null && item.estimation != null);
            state.saveDocuments.university.cr3.attestat = state.saveDocuments.university.cr3.attestat?.filter(item => item.name != null && item.estimation != null);
            state.saveDocuments.technicalSkills.cr5.grant = state.saveDocuments.technicalSkills.cr5.grant?.filter(item => item.docUrl !== null);
            state.saveDocuments.technicalSkills.cr7.competition = state.saveDocuments.technicalSkills.cr7.competition?.filter(item => item.docUrl != null);
            state.saveDocuments.technicalSkills.cr8.competition = state.saveDocuments.technicalSkills.cr8.competition?.filter(item => item.docUrl != null);
            state.saveDocuments.personalQualities.cr13.message = state.saveDocuments.personalQualities.cr13.message?.filter(item => item.docUrl != null);
            state.saveDocuments.personalQualities.cr14.competition = state.saveDocuments.personalQualities.cr14.competition?.filter(item => item.docUrl != null);
            state.saveDocuments.personalQualities.cr15.socialNetwork = state.saveDocuments.personalQualities.cr15.socialNetwork?.filter(item => item.url != null && item.count != null);
            if (!(state.saveDocuments.school.cr2.docUrl && state.saveDocuments.school.cr2.difference)) {
                state.saveDocuments.school.cr2.docUrl = null;
                state.saveDocuments.school.cr2.difference = null;
            }
            if (!(state.saveDocuments.university.cr4.docUrl && state.saveDocuments.university.cr4.difference)) {
                state.saveDocuments.university.cr4.docUrl = null;
                state.saveDocuments.university.cr4.difference = null;
            }
            if (!(state.saveDocuments.technicalSkills.cr6.url && state.saveDocuments.technicalSkills.cr6.value)) {
                state.saveDocuments.technicalSkills.cr6.url = null;
                state.saveDocuments.technicalSkills.cr6.value = null;
            }
            if (!(state.saveDocuments.technicalSkills.cr9.docUrl && state.saveDocuments.technicalSkills.cr9.name)) {
                state.saveDocuments.technicalSkills.cr9.docUrl = null;
                state.saveDocuments.technicalSkills.cr9.name = null;
            }
            if (!(state.saveDocuments.technicalSkills.cr10.docUrl && state.saveDocuments.technicalSkills.cr10.name)) {
                state.saveDocuments.technicalSkills.cr10.docUrl = null;
                state.saveDocuments.technicalSkills.cr10.name = null;
            }

            if (!(state.saveDocuments.personalQualities.cr12.docUrl && state.saveDocuments.personalQualities.cr12.name)) {
                state.saveDocuments.personalQualities.cr12.docUrl = null;
                state.saveDocuments.personalQualities.cr12.name = null;
            }

            if (state.saveDocuments.additionUniversity) {
                for (let i = 0; i < state.saveDocuments.additionUniversity.length; i++) {
                    if (state.saveDocuments.additionUniversity[i].country != null) {
                        state.saveDocuments.additionUniversity[i].country = state.saveDocuments.additionUniversity[i].country.id ? state.saveDocuments.additionUniversity[i].country.id : state.saveDocuments.additionUniversity[i].country;
                    }
                    if (state.saveDocuments.additionUniversity[i].educationLevel != null) {
                        state.saveDocuments.additionUniversity[i].educationLevel = state.saveDocuments.additionUniversity[i].educationLevel.value;
                    }  
										if (state.saveDocuments.additionUniversity[i].name != null) {
                        state.saveDocuments.additionUniversity[i].name = state.saveDocuments.additionUniversity[i].name.id;
                    }
                    state.saveDocuments.additionUniversity[i].status = (state.saveDocuments.additionUniversity[i].status === 'rejected' || state.saveDocuments.additionUniversity[i].status === 'unloaded' ? 'checking' : state.saveDocuments.additionUniversity[i].status);
                    state.saveDocuments.additionUniversity[i].attestat?.map(item => item.estimation ? Number(item.estimation) : null)?.filter(item => item.name != null && item.estimation != null);
                }
            }
            if (state.saveDocuments.additionalDoc) {
                for (let i = 0; i < state.saveDocuments.additionalDoc.length; i++) {
                    if (state.saveDocuments.additionalDoc[i].checking) {
                        state.saveDocuments.additionalDoc[i].status = 'checking';
                        delete state.saveDocuments.additionalDoc[i].checking;
                    }

                    if (state.saveDocuments.additionalDoc[i].delete)
                        delete state.saveDocuments.additionalDoc[i].status;
                    state.saveDocuments.additionalDoc[i].sideData = JSON.stringify(state.saveDocuments.additionalDoc[i].sideData);
                }
            }


        },
        setCurrentVideoInterview(state, data) {
            state.currentVideoInterview = data;
        },
        changeLoading(state, data) {
            state.isLoading = data;
        },
        setUniversity(state, data) {
            state.university = data;
        },
        setCompanies(state, data) {
            state.companies = data;
        },
        setDocuments(state, data) {
            if (data) {
                state.documentsData = data;
                if (state.documentsData.school.country != null) {
                    for (let i = 0; i < state.countries?.length; i++) {
                        if (state.countries[i].id.indexOf(state.documentsData.school.country) !== -1) {
                            state.documentsData.school.country = state.countries[i];
                            break;
                        }
                    }
                }
                if (state.documentsData.university.country != null) {
                    for (let i = 0; i < state.countries?.length; i++) {
                        if (state.countries[i].id.indexOf(state.documentsData.university.country) !== -1) {
                            state.documentsData.university.country = state.countries[i];
                            break;
                        }
                    }
                }

                if (state.documentsData.additionUniversity && state.documentsData.additionUniversity.length) {
                    for (let i = 0; i < state.documentsData.additionUniversity?.length; i++) {
                        for (let j = 0; j < state.countries?.length; j++) {
                            if (state.countries[j].id.indexOf(state.documentsData.additionUniversity[i].country) !== -1) {
                                state.documentsData.additionUniversity[i].country = state.countries[j];
                                break;
                            }
                        }
                        switch (state.documentsData.additionUniversity[i].educationLevel) {
                            case '1':
                                state.documentsData.additionUniversity[i].educationLevel = {
                                    id: '0',
                                    name: 'Bachelor',
                                    value: 1
                                }
                                break;
                            case '2':
                                state.documentsData.additionUniversity[i].educationLevel = {
                                    id: '1',
                                    name: 'Masters Degree',
                                    value: 2
                                }
                                break;
                            case '3':
                                state.documentsData.additionUniversity[i].educationLevel = {
                                    id: '2',
                                    name: 'Specialty',
                                    value: 3
                                }
                                break;   
																case '4':
                                state.documentsData.additionUniversity[i].educationLevel = {
                                    id: '3',
                                    name: 'Graduate school',
                                    value: 4
                                }
                                break;   
																case '5':
                                state.documentsData.additionUniversity[i].educationLevel = {
                                    id: '4',
                                    name: 'PhD program',
                                    value: 5
                                }
                                break;
                        }
                    }
                } else {
                    state.documentsData.additionUniversity = [];
                    state.documentsData = JSON.parse(JSON.stringify(state.documentsData));
                }
                switch (state.documentsData.university.educationLevel) {
                    case '1':
                        state.documentsData.university.educationLevel = {id: '0', name: 'Bachelor', value: 1}
                        break;
                    case '2':
                        state.documentsData.university.educationLevel = {id: '1', name: 'Masters Degree', value: 2}
                        break;
                    case '3':
                        state.documentsData.university.educationLevel = {id: '2', name: 'Specialty', value: 3}
                        break;  
												case '4':
                        state.documentsData.university.educationLevel = {id: '3', name: 'Graduate school', value: 4}
                        break;  
												case '5':
                        state.documentsData.university.educationLevel = {id: '4', name: 'PhD program', value: 5}
                        break;
                }

                if (state.documentsData.additionalDoc) {
                    for (let i = 0; i < state.documentsData.additionalDoc?.length; i++) {
                        state.documentsData.additionalDoc[i].sideData = JSON.parse(state.documentsData.additionalDoc[i].sideData);
                    }
                } else {
                    state.documentsData.additionalDoc = [];
                }

            } else {
                state.documentsData = {
                    school: {
                        country: null,  // Value from the request 92
                        pointSystem: null,   // Value from the request 91
                        cr1: {
                            docUrl: null,
                            status: null,
                            attestat: [],
                        },
                        cr2: {
                            docUrl: null,
                            difference: null, // Value from the request 93
                            status: 'unloaded'
                        }
                    },
                    university: {
                        country: null, // Value from the request 92
                        name: null, // Value from the request 94
                        pointSystem: null, // Value from the request 91
                        educationLevel: null,
                        cr3: {
                            docUrl: null,
                            status: 'unloaded',
                            attestat: [],
                        },
                        cr4: {
                            difference: null, // Value from the request 93
                            docUrl: null,
                            status: 'unloaded'
                        },
                        cr11: {
                            status: 'unloaded',
                            docUrl: null
                        }
                    },
                    technicalSkills: {
                        cr5: {grant: []},
                        cr6: {
                            url: null,
                            status: 'unloaded',
                            value: null
                        },
                        cr7: {competition: []},
                        cr8: {competition: []},
                        cr9: {
                            name: null,
                            status: 'unloaded',
                            docUrl: null
                        },
                        cr10: {
                            status: 'unloaded',
                            name: null, // Value from the request 95
                            docUrl: null
                        }
                    },
                    personalQualities: {
                        cr12: {
                            status: 'unloaded',
                            name: null, // Value from the request 95
                            docUrl: null
                        },
                        cr13: {message: []},
                        cr14: {competition: []},
                        cr15: {socialNetwork: []}
                    },
                    additionUniversity: [],
                    additionalDoc: [],
                    videoInterviewUrl: null,
                    psychologicalTestUrl: null

                }
            }
        },
        setCountries(state, data) {
            state.countries = data;
        },
        setDifference(state, data) {
            state.difference = data;
        },
        setSystemPoints(state, data) {
            state.systemPoints = data;
        },
        addUniversityInList(state, category) {
            state.documentsData.additionUniversity.push({
                id: null,
                country: null,
                name: null,
                pointSystem: null,
                educationLevel: null,
                docUrl: null,
                category,
                status: 'unloaded',
                attestat: []
            });
        },

        setDocumentsDataSelect(state, payload) {
            switch (payload.field) {
                case 'school-country':
                    if (payload.index != null) {
                        state.documentsData.additionUniversity[payload.index].country = payload.value; //todo
                    } else {
                        state.documentsData.school.country = payload.value;
                    }
                    break;
                case "school-system":
                    if (payload.index != null) {
                        state.documentsData.additionUniversity[payload.index].pointSystem = payload.value.id;
                        state.documentsData.additionUniversity[payload.index].attestat = state.documentsData.additionUniversity[payload.index].attestat.filter(item => item.id)
                        state.documentsData.additionUniversity[payload.index].attestat.forEach(item => item['delete'] = true);
                    } else {
                        state.documentsData.school.pointSystem = payload.value.id;
                        state.documentsData.school.cr1.attestat = state.documentsData.school.cr1.attestat.filter(item => item.id)
                        state.documentsData.school.cr1.attestat.forEach(item => item['delete'] = true);

                    }

                    // switch (payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem) {
                    //     case '100 point':
                    //         state.currentPointSystemSchool = {
                    //             name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem,
                    //             values: []
                    //         };
                    //         for (let i = 1; i <= 100; i++) {
                    //             state.currentPointSystemSchool.values.push(i)
                    //         }
                    //         break;
                    //     case 'Lamping value':
                    //         state.currentPointSystemSchool = {
                    //             name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem,
                    //             values: [5, 4, 3, 2, 1]
                    //         };
                    //         break;
                    //     case '10 point':
                    //         state.currentPointSystemSchool = {
                    //             name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem,
                    //             values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                    //         };
                    //         break
                    //     case '5 point':
                    //         state.currentPointSystemSchool = {
                    //             name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem,
                    //             values: [1, 2, 3, 4, 5]
                    //         };
                    //         break;
                    //     case   'Off/Hard':
                    //         state.currentPointSystemSchool = {
                    //             name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.documentsData.school.pointSystem,
                    //             values: [0, 1]
                    //         };
                    //         break;
                    // }
                    break;
                case 'school-difference':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.difference = payload.value.id; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.school.cr2.difference = payload.value.id;
                    }
                    break;
                case 'university-country':
                    if (payload.index != null) {
											if(state.documentsData.additionUniversity[payload.index].name && payload.value.id !== state.documentsData.additionUniversity[payload.index].name.countryId){
												state.documentsData.additionUniversity[payload.index].name = null;
											}
                        state.documentsData.additionUniversity[payload.index].country = payload.value; //todo
                    } else {
											if(state.documentsData.university.name && payload.value.id !== state.documentsData.university.name.countryId){
												state.documentsData.university.name = null;
											}
                        state.documentsData.university.country = payload.value;
                    }
                    break;
                case "university-system":

                    if (payload.index != null) {
                        state.documentsData.additionUniversity[payload.index].pointSystem = payload.value.id;
                        state.documentsData.additionUniversity[payload.index].attestat = state.documentsData.additionUniversity[payload.index].attestat.filter(item => item.id)
                        state.documentsData.additionUniversity[payload.index].attestat.forEach(item => item['delete'] = true);
                    } else {
                        state.documentsData.university.pointSystem = payload.value.id;
                        state.documentsData.university.cr3.attestat = state.documentsData.university.cr3.attestat.filter(item => item.id)
                        state.documentsData.university.cr3.attestat.forEach(item => item['delete'] = true);

                    }
                    switch (payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id) {
                        case '100 point':
                            state.currentPointSystemUniversity = {
                                name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id,
                                values: []
                            };
                            for (let i = 1; i <= 100; i++) {
                                state.currentPointSystemUniversity.values.push(i)
                            }
                            break;
                        case 'Lamping value':
                            state.currentPointSystemUniversity = {
                                name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id,
                                values: [5, 4, 3, 2, 1]
                            };
                            break;
                        case '10 point':
                            state.currentPointSystemUniversity = {
                                name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id,
                                values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                            };
                            break
                        case '5 point':
                            state.currentPointSystemUniversity = {
                                name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id,
                                values: [1, 2, 3, 4, 5]
                            };
                            break;
                        case   'Off/Hard':
                            state.currentPointSystemUniversity = {
                                name: payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem.id : state.documentsData.university.pointSystem.id,
                                values: [0, 1]
                            };
                            break;
                    }
                    state.currentPointSystemCopy = JSON.parse(JSON.stringify(state.currentPointSystemUniversity));
                    break;
                case 'university-name':
                    if (payload.index != null) {
                        state.documentsData.additionUniversity[payload.index].name = payload.value; //todo
												for (let i = 0; i < state.countries?.length; i++) {
													if (state.countries[i].id.indexOf(payload.value.countryId) !== -1) {
															state.documentsData.additionUniversity[payload.index].country = state.countries[i];
															break;
													}
											}
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
												
                    } else {
                        state.documentsData.university.name = payload.value;
												for (let i = 0; i < state.countries?.length; i++) {
													if (state.countries[i].id.indexOf(payload.value.countryId) !== -1) {
															state.documentsData.university.country = state.countries[i];
															break;
													}
											}
                    }
                    break;
                case 'university-difference':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.difference = payload.value.id; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.university.cr4.difference = payload.value.id;
                    }
                    break;
                case 'university-education-level':
                    if (payload.index != null) {
                        state.documentsData.additionUniversity[payload.index].educationLevel = payload.value; //todo
                    } else {
                        state.documentsData.university.educationLevel = payload.value;
                    }
                    break;

                case 'technical-quote-url':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.url = payload.value; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.technicalSkills.cr6.url = payload.value;
                    }
                    break;
                case 'technical-quote-value':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.value = payload.value; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.technicalSkills.cr6.value = payload.value;
                    }
                    break;
                case 'technical-event':
                    state.documentsData.technicalSkills.cr7.competition[payload.index].position = payload.value;
                    break;
                case'technical-event-professional':
                    state.documentsData.technicalSkills.cr8.competition[payload.index].position = payload.value;
                    break;
                case 'technical-certificate':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.name = payload.value; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.technicalSkills.cr9.name = payload.value;
                    }
                    break;
                case 'technical-experience':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.name = payload.value; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.technicalSkills.cr10.name = payload.value;
                    }
                    break;
                case 'personal-message':
                    if (payload.index != null) {
                        state.documentsData.additionalDoc[payload.index].sideData.name = payload.value; //todo
                        state.documentsData.additionalDoc[payload.index]['checking'] = true
                    } else {
                        state.documentsData.personalQualities.cr12.name = payload.value;
                    }
                    break;
                case 'personal-message-user':
                    state.documentsData.personalQualities.cr13.message[payload.index].description = payload.value;
                    break;
                case 'personal-event':
                    state.documentsData.personalQualities.cr14.competition[payload.index].name = payload.value;
                    break;
                case 'personal-event-select':
                    state.documentsData.personalQualities.cr14.competition[payload.index].theme = payload.value;
                    break;
                case 'personal-social-url':
                    state.documentsData.personalQualities.cr15.socialNetwork[payload.index].url = payload.value;
                    break;
                case 'personal-social-count':
                    state.documentsData.personalQualities.cr15.socialNetwork[payload.index].count = Number(payload.value);
                    break;
            }
        },

        setDocumentDataTableEstimation(state, payload) {
					let estimationValue = payload.value;
					if (estimationValue === null || estimationValue === '') {
							estimationValue = null; 
					} else {
							estimationValue = Number(estimationValue); 
					}
			
					switch (payload.field) {
							case 'school-attest':
									if (payload.tableIndex != null) {
											state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index].estimation = estimationValue;
									} else {
											state.documentsData.school.cr1.attestat[payload.index].estimation = estimationValue;
									}
									break;
							case 'university-attest':
									if (payload.tableIndex != null) {
											state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index].estimation = estimationValue;
									} else {
											state.documentsData.university.cr3.attestat[payload.index].estimation = estimationValue;
									}
									break;
					}
			},
        setDocumentDataTableName(state, payload) {
            switch (payload.field) {
                case 'school-attest':
                    if (payload.tableIndex != null) {
                        state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index].name = payload.value;
                    } else {
                        state.documentsData.school.cr1.attestat[payload.index].name = payload.value;
                    }
                    break;
                case 'school-university':
                    if (payload.tableIndex != null) {
                        state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index].name = payload.value;
                    } else {
                        state.documentsData.university.cr3.attestat[payload.index].name = payload.value;
                    }
                    break;
            }
        },
        setDocumentsDataFile(state, payload) {

            switch (payload.data.field) {
                case 'school-attest':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionUniversity[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        } else {
                            state.documentsData.school.cr1.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionUniversity[payload.index].docUrl = payload.url;
                        } else {
                            state.documentsData.school.cr1.docUrl = payload.url;
                        }
                    }

                    break;
                case 'school-difference':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.school.cr2.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url; //todo
                        } else {
                            state.documentsData.school.cr2.docUrl = payload.url
                        }
                    }
                    break;
                case 'university-attest':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionUniversity[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        } else {
                            state.documentsData.university.cr3.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionUniversity[payload.index].docUrl = payload.url;
                        } else {
                            state.documentsData.university.cr3.docUrl = payload.url;
                        }
                    }
                    break;
                case 'university-difference':

                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.university.cr4.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url;
                        } else {
                            state.documentsData.university.cr4.docUrl = payload.url;
                        }
                    }
                    break;
                case 'university-name-stipend':
                    if (payload.data.file) {
                        state.documentsData.technicalSkills.cr5.grant[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        payload.callback(payload.data);
                    } else {
                        state.documentsData.technicalSkills.cr5.grant[payload.index].docUrl = payload.url
                    }
                    break;
                case 'university-message':


                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.university.cr11.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url;
                        } else {
                            state.documentsData.university.cr11.docUrl = payload.url;
                        }
                    }

                    break;

                case 'technical-event':
                    if (payload.data.file) {
                        state.documentsData.technicalSkills.cr7.competition[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        payload.callback(payload.data);
                    } else {
                        state.documentsData.technicalSkills.cr7.competition[payload.index].docUrl = payload.url
                    }
                    break;
                case 'technical-event-professional':
                    if (payload.data.file) {
                        state.documentsData.technicalSkills.cr8.competition[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        payload.callback(payload.data);
                    } else {
                        state.documentsData.technicalSkills.cr8.competition[payload.index].docUrl = payload.url;
                    }
                    break;
                case 'technical-certificate':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.technicalSkills.cr9.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url;
                        } else {
                            state.documentsData.technicalSkills.cr9.docUrl = payload.url;
                        }
                    }

                    break;
                case 'technical-experience':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.technicalSkills.cr10.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url;
                        } else {
                            state.documentsData.technicalSkills.cr10.docUrl = payload.url;
                        }
                    }

                    break;
                case 'personal-message':
                    if (payload.data.file) {
                        if (payload.data.index != null) {
                            state.documentsData.additionalDoc[payload.data.index].sideData.docUrl = URL.createObjectURL(payload.data.file);
                            state.documentsData.additionalDoc[payload.data.index]['checking'] = true
                        } else {
                            state.documentsData.personalQualities.cr12.docUrl = URL.createObjectURL(payload.data.file);
                        }
                        payload.callback(payload.data);
                    } else {
                        if (payload.index != null) {
                            state.documentsData.additionalDoc[payload.index].sideData.docUrl = payload.url;
                        } else {
                            state.documentsData.personalQualities.cr12.docUrl = payload.url;
                        }
                    }

                    break;
                case 'personal-message-user':
                    if (payload.data.file) {
                        state.documentsData.personalQualities.cr13.message[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        payload.callback(payload.data);
                    } else {
                        state.documentsData.personalQualities.cr13.message[payload.index].docUrl = payload.url;
                    }
                    break;
                case 'personal-event':
                    if (payload.data.file) {
                        state.documentsData.personalQualities.cr14.competition[payload.data.index].docUrl = URL.createObjectURL(payload.data.file);
                        payload.callback(payload.data);
                    } else {
                        state.documentsData.personalQualities.cr14.competition[payload.index].docUrl = payload.url;
                    }
                    break;
                case 'interview':
                    state.documentsData.videoInterviewUrl = payload.url;
                    break;
                case 'test':
                    state.documentsData.psychologicalTestUrl = payload.url;
                    break;
            }
        },
        addDocumentsDataTable(state, {field, index}) {

            switch (field) {
                case 'school-attest':
                    if (index != null) {
                        state.documentsData.additionUniversity[index].attestat.push({
                            id: null,
                            name: null,
                            estimation: null
                        });
                    } else {
                        state.documentsData.school.cr1.attestat.push({id: null, name: null, estimation: null});
                    }

                    break;
                case 'university-attest':
                    if (index != null) {
                        state.documentsData.additionUniversity[index].attestat.push({
                            id: null,
                            name: null,
                            estimation: null
                        });
                    } else {
                        state.documentsData.university.cr3.attestat.push({id: null, name: null, estimation: null});
                    }
                    break;
                case 'university-name-stipend':
                    state.documentsData.technicalSkills.cr5.grant.push({id: null, status: 'unloaded', docUrl: null});
                    break;
                case 'technical-event':
                    state.documentsData.technicalSkills.cr7.competition.push({
                        id: null,
                        status: 'unloaded',
                        position: null,
                        docUrl: null
                    });
                    break;
                case 'technical-event-professional':
                    state.documentsData.technicalSkills.cr8.competition.push({
                        id: null,
                        status: 'unloaded',
                        position: null,
                        docUrl: null
                    });
                    break;
                case 'personal-message-user':
                    state.documentsData.personalQualities.cr13.message.push({
                        id: null,
                        status: 'unloaded',
                        description: null,
                        docUrl: null
                    });
                    break;
                case 'personal-event':
                    state.documentsData.personalQualities.cr14.competition.push({
                        id: null,
                        theme: null,
                        name: null,
                        status: 'unloaded',
                        docUrl: null
                    });
                    break;
                case 'personal-social':
                    state.documentsData.personalQualities.cr15.socialNetwork.push({
                        id: null,
                        status: 'unloaded',
                        url: null,
                        count: null
                    });
                    break;
            }
        },
        setFileQueue(state, payload) {
            if (payload) {
                if (state.documentsDataFile.length !== 0) {
                    let add = true;
                    for (let i = 0; i < state.documentsDataFile.length; i++) {
                        if (state.documentsDataFile[i].field === payload.field) {
                            if (state.documentsDataFile[i].index != null) {
                                if (state.documentsDataFile[i].index === payload.index) {
                                    state.documentsDataFile[i] = payload;
                                    add = false;
                                }
                            } else {
                                state.documentsDataFile[i] = payload;
                                add = false;
                            }
                        }
                    }
                    if (add) {
                        state.documentsDataFile.push(payload);
                    }
                } else {
                    state.documentsDataFile.push(payload);
                }
            } else {
                state.documentsDataFile = [];
            }
        },
        deleteDocumentDataTable(state, payload) {
            const deltedObject = {
                school: {
                    cr1: {
                        attestat: []
                    },
                    cr2: {}
                },
                university: {
                    cr3: {},
                    cr4: {
                        attestat: []
                    },
                    cr11: {}
                },
                technicalSkills: {
                    cr5: {grant: []},
                    cr6: {},
                    cr7: {competition: []},
                    cr8: {competition: []},
                    cr9: {},
                    cr10: {}
                },
                personalQualities: {
                    cr12: {},
                    cr13: {message: []},
                    cr14: {competition: []},
                    cr15: {socialNetwork: []}
                },
                additionUniversity: [],
                additionalDoc: []
            }

            switch (payload.field) {
                case 'school-attest':
                    if (payload.id) {
                        state.documentsData.school.cr1.attestat[payload.index]['delete'] = true;
                        deltedObject.school.cr1.attestat.push(state.documentsData.school.cr1.attestat[payload.index])
                    } else {
                        state.documentsData.school.cr1.attestat.splice(payload.index, 1);
                    }
                    break;
                case 'university-attest':
                    if (payload.id) {
                        if (payload.tableIndex != null) {
                            state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index]['delete'] = true;
                            deltedObject.additionUniversity.push(state.documentsData.additionUniversity[payload.tableIndex].attestat[payload.index])

                        } else {
                            state.documentsData.university.cr3.attestat[payload.index]['delete'] = true;
                            deltedObject.university.cr3.attestat.push(state.documentsData.university.cr3.attestat[payload.index])
                        }
                    } else {
                        if (payload.tableIndex != null) {
                            state.documentsData.additionUniversity[payload.tableIndex].attestat.splice(payload.index, 1);
                        } else {
                            state.documentsData.university.cr3.attestat.splice(payload.index, 1);
                        }
                    }
                    break;
                case 'personal-social':
                    if (payload.id) {
                        state.documentsData.personalQualities.cr15.socialNetwork[payload.index]['delete'] = true;
                        deltedObject.personalQualities.cr15.socialNetwork.push(state.documentsData.personalQualities.cr15.socialNetwork[payload.index])

                    } else {
                        state.documentsData.personalQualities.cr15.socialNetwork.splice(payload.index, 1);
                    }
                    break;
            }
            state.documentToBeDeleted = deltedObject;
        },
        findEstimations(state, payload) {
            switch (payload.field) {
                case 'school-attest':
                    state.currentPointSystemSchool = JSON.parse(JSON.stringify(payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.currentPointSystemCopy));
                    if (payload.value && payload.value !== '') {
                        let result = state.currentPointSystemSchool.values.filter(item => item.toString().indexOf(payload.value) !== -1);
                        state.currentPointSystemSchool.values = result;
                    }
                    payload.callback();
                    break;
                case 'university-attest':
                    state.currentPointSystemUniversity = JSON.parse(JSON.stringify(payload.index != null ? state.documentsData.additionUniversity[payload.index].pointSystem : state.currentPointSystemCopy));
                    if (payload.value && payload.value !== '') {
                        let result = state.currentPointSystemUniversity.values.filter(item => item.toString().indexOf(payload.value) !== -1);
                        state.currentPointSystemUniversity.values = result;
                    }
                    payload.callback();
                    break;
            }
        },
        deleteDocuments(state, {index, field}) {
            const deltedObject = {
                school: {
                    cr1: {},
                    cr2: {}
                },
                university: {
                    cr3: {},
                    cr4: {},
                    cr11: {}
                },
                technicalSkills: {
                    cr5: {grant: []},
                    cr6: {},
                    cr7: {competition: []},
                    cr8: {competition: []},
                    cr9: {},
                    cr10: {}
                },
                personalQualities: {
                    cr12: {},
                    cr13: {message: []},
                    cr14: {competition: []},
                    cr15: {socialNetwork: []}
                },
                additionUniversity: [],
                additionalDoc: []
            }
            switch (field) {
                case 'school-attest':
                    if (index != null) {
                        state.documentsData.additionUniversity[index]['delete'] = true;
                        deltedObject.additionUniversity.push(state.documentsData.additionUniversity[index])
                        break;
                    }
                    deltedObject.school.cr1['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     school: {
                    //         cr1: state.documentsData.school.cr1
                    //     }
                    // }))
                    break;
                case 'school-difference':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.school.cr2['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     school: {
                    //         cr2: state.documentsData.school.cr2
                    //     }
                    // }));
                    // state.documentsData.school.cr2.docUrl = null;
                    break;
                case 'university-attest':
                    if (index != null) {
                        state.documentsData.additionUniversity[index]['delete'] = true;
                        deltedObject.additionUniversity.push(state.documentsData.additionUniversity[index])
                        break;
                    }
                    deltedObject.university.cr3['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     school: {
                    //         cr3: state.documentsData.university.cr3
                    //     }
                    // }));
                    // state.documentsData.university.cr3.docUrl = null;
                    break;
                case 'university-difference':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.university.cr4['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     university: {
                    //         cr4: state.documentsData.university.cr4
                    //     }
                    // }));
                    // state.documentsData.university.cr4.docUrl = null;
                    break;
                case 'university-name-stipend':
                    state.documentsData.technicalSkills.cr5.grant[index]['delete'] = true;
                    deltedObject.technicalSkills.cr5.grant.push(state.documentsData.technicalSkills.cr5.grant[index])
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     technicalSkills: {
                    //         cr5: {
                    //             grant: [
                    //                 state.documentsData.technicalSkills.cr5.grant[index]
                    //             ]
                    //         }
                    //     }
                    // }));
                    // state.documentsData.technicalSkills.cr5.grant[index].docUrl = null;
                    break;
                case 'university-message':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.university.cr11['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     university: {
                    //         cr11: state.documentsData.university.cr11
                    //     }
                    // }));
                    // state.documentsData.university.cr11.docUrl = null;
                    break;
                case 'technical-event':
                    state.documentsData.technicalSkills.cr7.competition[index]['delete'] = true;
                    deltedObject.technicalSkills.cr7.competition.push(state.documentsData.technicalSkills.cr7.competition[index])

                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     technicalSkills: {
                    //         cr7: {
                    //             competition: [state.documentsData.technicalSkills.cr7.competition[index]]
                    //         }
                    //     }
                    // }));
                    // state.documentsData.technicalSkills.cr7.competition[index].docUrl = null;
                    break;
                case 'technical-event-professional':
                    state.documentsData.technicalSkills.cr8.competition[index]['delete'] = true;
                    deltedObject.technicalSkills.cr8.competition.push(state.documentsData.technicalSkills.cr8.competition[index])

                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     technicalSkills: {
                    //         cr8: {
                    //             competition: [
                    //                 state.documentsData.technicalSkills.cr8.competition[index]
                    //             ]
                    //         }
                    //     }
                    // }));
                    // state.documentsData.technicalSkills.cr8.competition[index].docUrl = null;
                    break;
                case 'technical-certificate':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.technicalSkills.cr9['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     technicalSkills: {
                    //         cr9: state.documentsData.technicalSkills.cr9
                    //     }
                    // }));
                    // state.documentsData.technicalSkills.cr9.docUrl = null;
                    break;
                case 'technical-experience':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.technicalSkills.cr10['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     technicalSkills: {
                    //         cr10: state.documentsData.technicalSkills.cr10
                    //     }
                    // }));
                    // state.documentsData.technicalSkills.cr10.docUrl = null;
                    break;
                case 'personal-message':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.personalQualities.cr12['delete'] = true;
                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     personalQualities: {
                    //         cr2: state.documentsData.personalQualities.cr12
                    //     }
                    // }));
                    // state.documentsData.personalQualities.cr12.docUrl = null;
                    break;
                case 'personal-message-user':
                    state.documentsData.personalQualities.cr13.message[index]['delete'] = true;
                    deltedObject.personalQualities.cr13.message.push(state.documentsData.personalQualities.cr13.message[index])

                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     personalQualities: {
                    //         cr13: {
                    //             message: [state.documentsData.personalQualities.cr13.message[index]]
                    //         }
                    //     }
                    // }));
                    // state.documentsData.personalQualities.cr13.message[index].docUrl = null;
                    break;
                case 'personal-event':
                    state.documentsData.personalQualities.cr14.competition[index]['delete'] = true;
                    deltedObject.personalQualities.cr14.competition.push(state.documentsData.personalQualities.cr14.competition[index])

                    // state.documentToBeDeleted = JSON.parse(JSON.stringify({
                    //     personalQualities: {
                    //         cr14: {
                    //             competition: [state.documentsData.personalQualities.cr14.competition[index]]
                    //         }
                    //     }
                    // }));
                    // state.documentsData.personalQualities.cr14.competition[index].docUrl = null;
                    break;
                case 'citation-index':
                    if (index != null) {
                        state.documentsData.additionalDoc[index]['delete'] = true;
                        deltedObject.additionalDoc.push(state.documentsData.additionalDoc[index])
                        break;
                    }
                    deltedObject.technicalSkills.cr6['delete'] = true;
                    break;
            }


            state.documentToBeDeleted = deltedObject;

            for (let i = 0; i < state.documentsDataFile.length; i++) {
                if (state.documentsDataFile[i].field === field) {
                    if (state.documentsDataFile[i].index != null) {
                        if (state.documentsDataFile[i].index === index) {
                            state.documentsDataFile.splice(i, 1);
                        }
                    } else {
                        state.documentsDataFile.splice(i, 1);
                    }
                }
            }
        },
        addDocumentInAdditionalData(state, {category, componentContext}) {
            let sideData = {};

            switch (category) {
                case 'cr2':
                    sideData = {
                        docUrl: null,
                        difference: null,
                    }
                    break;
                case 'cr4':
                    sideData = {
                        docUrl: null,
                        difference: null,
                    }
                    break;
                case 'cr11':
                    sideData = {
                        docUrl: null
                    }
                    break;
                case 'cr6':
                    sideData = {
                        url: null,
                        value: null,
                    }
                    break;
                case 'cr9':
                    sideData = {
                        name: null,
                        docUrl: null
                    }
                    break;
                case 'cr10':
                    sideData = {
                        name: null,
                        docUrl: null
                    }
                    break;
                case 'cr12':
                    sideData = {
                        name: null,
                        docUrl: null
                    }
                    break;
            }
            state.documentsData?.additionalDoc?.push({
                category,
                sideData,
                status: 'unloaded'
            })

            componentContext?.$forceUpdate();

        }

    },
    state: {
        documentsData: null,
        documentsDataFile: [],
        countries: null,
        difference: null,
        companies: null,
        university: null,
        systemPoints: null,
        saveDocuments: null,
        currentPointSystemSchool: null,
        currentPointSystemUniversity: null,
        currentPointSystemCopy: null,
        isLoading: false,
        currentVideoInterview: null,
        documentToBeDeleted: null

    },
    getters: {
        getDocumentsData: (state) => state.documentsData,
        getCountries: (state) => state.countries,
        getDifference: (state) => state.difference,
        getSystemPoints: (state) => state.systemPoints,
        getCompanies: (state) => state.companies,
        getUniversity: (state) => state.university,
        getCurrentPointSystemSchool: (state) => state.currentPointSystemSchool,
        getCurrentPointSystemUniversity: (state) => state.currentPointSystemUniversity,
        getDocumentsDataFile: (state) => state.documentsDataFile,
        isLoading: (state) => state.isLoading,
        currentVideoInterview: (state) => state.currentVideoInterview,
        getDocumentsDataSave: (state) => state.saveDocuments,
        documentToBeDeleted: (state) => state.documentToBeDeleted,

    }
}