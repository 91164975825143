<template>
	<div class="documents__group-element">
		<div class="documents__group-element-caption">
			<div class="documents__group-element-caption-stage">
				<div class="documents__group-element-caption-stage-circle" :class="{
					'documents__group-element-caption-stage-circle':
						documentStatus === 'unloaded',
					'stage-circle-blue': documentStatus === 'checking',
					'stage-circle-green': documentStatus === 'approved',
					'stage-circle-red': documentStatus === 'rejected',
				}"></div>
				<p class="documents__group-element-caption-stage-text">
					<span v-if="
						documentStatus === 'unloaded' ||
						!documentStatus ||
						documentStatus === undefined
					">Requires download</span>
					<span v-else-if="documentStatus === 'fileLoaded'">File downloaded</span>
					<span v-else-if="documentStatus === 'checking'">Under review</span>
					<span v-else-if="documentStatus === 'approved'">Checked</span>
					<span v-else-if="documentStatus === 'rejected'">Rejected</span>
				</p>
			</div>
			<button class="documents__group-element-caption-stage-change">
				<img src="~@/assets/img/all-img/documents/change.svg" alt="Change"
					class="documents__group-element-caption-stage-change-img" />
			</button>
		</div>
		<div class="documents__group-element-download">
			<div class="documents__demonstration-container">
				<img v-if="documentView" :src="documentView" alt="" class="documents__demonstration"
					:class="{ 'documents-icon': documentType !== 'img' }">
			</div>
			<button class="documents__group-element-download-btn" :class="{
				'download-btn-blue':
					documentStatus === 'checking' || documentUrl === 'approved',
			}" @click="uploadFile">
				<span v-if="
					documentStatus === 'unloaded' ||
					!documentStatus ||
					documentStatus === undefined ||
					documentStatus === 'rejected' ||
					documentStatus === 'fileLoaded'
				">Download</span>
				<span v-else-if="
					documentStatus === 'checking' || documentStatus === 'approved'
				">View Document</span>
			</button>
			<input type="file" ref="file" hidden v-on:change="changeFile" accept="image/*,.pdf,.docx,.doc,.xlsx" />
			<a ref="download"
				:href="(documentType === 'img' || documentType === 'pdf') ? (documentUrl + '?token=' + $store.getters.token) : (documentType === 'exel' || documentType === 'doc') ? ('http://docs.google.com/viewer?url=' + documentUrl + '?token=' + $store.getters.token) : documentUrl + '?token=' + $store.getters.token"
				target="_blank" />
		</div>
	</div>
</template>

<script>
import { errorHandler } from "@/lib/errorHandler";

export default {
	name: "SeparateDocument",
	props: {
		documentUrl: {
			type: String,
		},
		documentStatus: {
			type: String,
		},
		typeDoc: {
			type: String,
		},
		indexDoc: {
			type: Number,
		},
	},
	data() {
		return {
			file: "",
			fileStatus: "",
			urlFile: null,
			documentView: null,
			documentType: null
		};
	},
	methods: {
		uploadFile() {
			switch (this.documentStatus) {
				case "unloaded":
					this.$refs.file.click();
					break;
				case "checking":
					this.$refs.download.click();
					break;
				case "approved":
					this.$refs.download.click();
					break;
				case "rejected":
					this.$refs.file.click();
					break;
				case undefined:
					this.$refs.file.click();
					break;
				case null:
					this.$refs.file.click();
					break;
				default:
					this.$refs.file.click();
					break;
			}
		},

		changeFile() {
			this.file = this.$refs.file.files[0];
			if (typeof this.file === "object") {
				if (this.file.size === 0) {
					errorHandler({ status: 400, page: 'emptyFile' })
				} else {
					if (this.file.size < 41943040) {
						let payload = {
							file: this.file,
							type: this.typeDoc,
							index: this.indexDoc,
						};
						this.$store.commit("addFileAllDocument", payload);
						this.$store.commit("updateLocalUrl", {
							type: this.typeDoc,
							index: this.indexDoc,
							data: URL.createObjectURL(this.file),
						});
						this.documentView = URL.createObjectURL(this.file);
						this.setDocType(this.documentView, this.file);
						if (this.typeDoc === 'recommend') {
							this.$emit('updateStatus')
						}
					} else errorHandler({ status: 400, page: 'fileSize' })
				}
			}
		},
		setDocType(name, file) {
			if (file) {
				name = file.name;
			}
			let type = name.split('.');
			type = type[type.length - 1].toLowerCase();
			if (type === 'raw' || type === 'jpeg ' || type === 'tiff' || type === 'psd' || type === 'bmp' || type === 'gif' || type === 'png' || type === 'jp2' || type === 'jpg') {
				this.documentType = 'img';
				this.documentView = this.documentUrl + '?token=' + this.$store.getters.token;
			} else if (type === 'doc' || type === 'docx') {
				this.documentType = 'doc';
				this.documentView = require('/src/assets/img/all-img/doc-icon.svg.png');
			} else if (type === 'pdf') {
				this.documentType = 'pdf';
				this.documentView = require('/src/assets/img/all-img/pdf-icon.png');
			} else if (type === 'xlsx' || type === 'xls') {
				this.documentType = 'exel';
				this.documentView = require('/src/assets/img/all-img/exel-icon.svg.png');
			}
		}
	},
	destroyed() {
		if (this.typeDoc === 'recommend') {
			this.$emit('updateStatus')
		}
	},
	beforeMount() {
		if (this.documentUrl) {
			this.setDocType(this.documentUrl);
		}
	}
};
</script>

<style scoped>
.documents__group-element {
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
  height: 344px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 9px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  position: relative;
}

.documents__group-element-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0 20px;
}

.documents__group-element-caption-stage {
  display: flex;
  align-items: center;
}

.documents__group-element-caption-stage-circle {
  width: 11px;
  height: 11px;
  background: #c4c4c4;
  border-radius: 100%;
}

.documents__group-element-caption-stage-text {
  margin-left: 11px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3b4c68;
}

.documents__group-element-caption-stage-change {
  display: none;
  margin-left: auto;
  transition: opacity 1s ease;
}

.documents__group-element-caption-stage-change:hover {
  opacity: 0.5;
}

.documents__group-element-caption-stage-change-img {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 8px;
  background: #edf7f9;
}

.documents__group-element-text {
  margin: 0px 20px 0px 20px;
  padding-top: 8px;
  height: 95px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  line-height: 1.39;
  color: #3b4c68;
}

.documents__group-element-download {
  margin: 0 20px 16px 20px;
  padding-top: 13px;
  border-top: 1px solid #f1f1f5;

}

.documents__demonstration-container {
  height: 190px;
  width: 100%;
  border: 1px solid #efeff4;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documents__demonstration {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;

  border-radius: 9px;
}

.documents__group-element-download-btn {
  bottom: 20px;
  height: 45px;
  background: #f1f1f5;
  border-radius: 10px;
  background: #f1f1f5;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #696974;
  transition: opacity 1s ease;
  position: absolute;
  box-sizing: border-box;
  width: 259px;
  left: 20px;
}

.documents__group-element-download-btn:hover {
  opacity: 0.5;
}

.mobile {
  display: none;
}

/* Modificators */
.stage-circle-green {
  background: #14c686;
}

.stage-circle-blue {
  background: #009ed1;
}

.stage-circle-red {
  background: #ff3c47;
}

.change-visable {
  display: block;
}

.download-btn-blue {
  background: #e6f6fc;
  color: #009ed1;
}

/* /Modificators */

@media (max-width: 816px) {
  .documents-wrapper {
    flex-direction: column;
  }

  .documents__group-element {
    margin-top: 0;
    margin: 0 auto;
  }
}

.documents-icon {
  height: 75px;
  width: 75px;
}
</style>