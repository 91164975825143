<template>
	<div class="menu">
		<div class="menu__wrapp">
			<div class="menu__wrapp-back menu-mobile">
				<div class="menu__wrapp-back-btn"></div>
				<p class="menu__wrapp-back-text">{{ $t('components.account_worker.menu.personalCabinet') }}</p>
			</div>
			<nav class="menu__wrapp-nav">
				<router-link class="menu__wrapp-nav-item" :to="{ path: 'dashboard' }">
					<a href="" class="menu__wrapp-nav-item-link-dashboard">{{
						$t('components.account_worker.menu.personalCabinet') }}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'settings' }">
					<a href="" class="menu__wrapp-nav-item-link-settings">{{
						$t('components.account_worker.menu.profileSettings') }}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'documents' }">
					<a href="" class="menu__wrapp-nav-item-link-documents">{{
						$t('components.account_worker.menu.myDocuments') }}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'rate' }">
					<a href="" class="menu__wrapp-nav-item-link-rating">{{ $t('components.account_worker.menu.rating')
						}}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'testing' }">
					<a href="" class="menu__wrapp-nav-item-link-tests">{{ $t('components.account_worker.menu.takeTest')
						}}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'vacancies' }">
					<a href="" class="menu__wrapp-nav-item-link-vacancies">{{
						$t('components.account_worker.menu.searchJob') }}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'responses' }">
					<a href="" class="menu__wrapp-nav-item-link-rates">{{
						$t('components.account_worker.menu.myApplications') }}</a>
				</router-link>

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'recommendations' }">
					<a href="" class="menu__wrapp-nav-item-link-recommendations">{{
						$t('components.account_worker.menu.recommendations') }}</a>
				</router-link>

				<!-- <router-link class="menu__wrapp-nav-item" :to="{ path: 'prices' }">
					<a href="" class="menu__wrapp-nav-item-link-rates">Pricing</a>
				</router-link> -->

				<router-link class="menu__wrapp-nav-item" :to="{ path: 'faq' }">
					<a href="" class="menu__wrapp-nav-item-link-faq">{{ $t('components.account_worker.menu.faq') }}</a>
				</router-link>
			</nav>
			<div class="menu__wrapp-chat">
				<button class="menu__wrapp-chat-text">{{ $t('components.account_worker.menu.chat') }}</button>
			</div>
			
			<div class="menu__wrapp-resume">
				<button class="menu__wrapp-resume-text">{{ $t('components.account_worker.menu.createJob') }}</button>
			</div>

			
			<div class="menu__wrapp-leave">
				<button class="menu__wrapp-leave-text">{{ $t('components.account_worker.menu.signOut') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Menu",
};
</script>


<style scoped>
.menu {
	padding-top: 59px;
	border-right: 1px solid #ebedf0;
	position: sticky;
	overflow-x: hidden;
	top: 0;
	left: 0;
	max-width: 290px;
	width: 100%;
	height: 100%;
	background: #fff;
}

.menu-mobile {
	display: none;
}

.menu__wrapp-back {
	direction: flex;
	align-items: center;
}

.menu__wrapp-back-btn {
	display: block;
	width: 31px;
	height: 10px;
	background: url("~@/assets/img/account-img/menu/back.svg");
	background-repeat: no-repeat;
	background-position: center;
}

.menu__wrapp-back-text {
	font-family: "Roboto";
	margin-left: auto;
	font-weight: 400;
	font-size: 13px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.menu__wrapp {
	margin: 0px auto 0 auto;
	width: 214px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.menu__wrapp-logo {
	width: 100%;
	padding: 0 0px 21px 61px;
	margin: 18px 0 0px 0;
	display: block;
	box-sizing: border-box;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.menu__wrapp-logo-img {
	height: 100%;
}

.menu__wrapp-nav {
	margin: 41px 0 0 0;
	width: 214px;
	align-items: center;
}

.menu__wrapp-nav :not(:last-child) {
	margin-bottom: 20px;
}

.menu__wrapp-nav-item {
	border-radius: 5px;
	display: flex;
	align-items: center;
	width: 214px;
	height: 45px;
	background: #fff;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
	transition: background 1s ease;
}

.menu__wrapp-nav-item:hover {
	background: #009ed1;
}

.menu__wrapp-nav-item:first-child {
	background: #009ed1;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.menu__wrapp-nav-item-link-active {
	background: #009ed1;
}

.menu__wrapp-nav-item-link-dashboard {
	color: #fff;
	padding-left: 48px;
	background: url("~@/assets/img/account-img/menu/dashboard.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-dashboard:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-settings {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/settings.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-settings:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-documents {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/documents.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-documents:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-rating {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/rating.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-rating:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-tests {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/tests.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-tests:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-vacancies {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/surch.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-vacancies:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-recommendations {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/recomendation.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-recommendations:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-rates {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/tarif.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-rates:hover {
	color: #fff;
}

.menu__wrapp-nav-item-link-faq {
	padding-left: 48px;
	color: #44444f;
	background: url("~@/assets/img/account-img/menu/question.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	transition: color 1s ease;
}

.menu__wrapp-nav-item-link-faq:hover {
	color: #fff;
}

.menu__wrapp-chat {
	margin-top: 70px;
	width: 100%;
	text-align: center;
	background: #009ed1;
	border-radius: 8px;
	box-shadow: 0px 3px 5px #009ed1;
	transition: opacity 1s ease;
}

.menu__wrapp-chat:hover {
	opacity: 0.5;
}

.menu__wrapp-chat-text {
	padding: 15px 23px 15px 23px;
	background: url("~@/assets/img/account-img/menu/chat.png");
	background-position: 0;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: #ffffff;
}

.menu__wrapp-resume {
	margin-top: 14px;
	width: 100%;
	letter-spacing: 0.02em;
	text-align: center;
	background: #e6f6fc;
	border: 1px solid #009ed1;
	border-radius: 8px;
	transition: opacity 1s ease;
}

.menu__wrapp-resume-text {
	padding: 15px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}

.menu__wrapp-resume:hover {
	opacity: 0.5;
}

.menu__wrapp-leave {
	width: 100%;
	text-align: center;
	margin-top: 14px;
	transition: opacity 1s ease;
}

.menu__nav-leave:hover {
	opacity: 0.5;
}

.menu__wrapp-leave-text {
	background: url("~@/assets/img/account-img/menu/exit.svg");
	background-repeat: no-repeat;
	background-position: 11px;
	background-position-y: center;
	padding-left: 48px;
	font-family: "Montserrat";
	font-style: normal;
	font-size: 16px;
	color: #44444f;
	letter-spacing: -0.02em;
}

@media (max-width: 1360px) {
	.menu {
		max-width: 100px;
	}

	.menu__wrapp-logo {
		padding-left: 34px;
	}

	.menu__wrapp {
		width: 45px;
	}

	.menu__wrapp-nav {
		width: 100%;
	}

	.menu__wrapp-nav-item {
		width: 100%;
		overflow: hidden;
	}

	.menu__wrapp-nav-item-link-dashboard,
	.menu__wrapp-nav-item-link-settings,
	.menu__wrapp-nav-item-link-rating,
	.menu__wrapp-nav-item-link-vacancies,
	.menu__wrapp-nav-item-link-recommendations,
	.menu__wrapp-nav-item-link-rates,
	.menu__wrapp-nav-item-link-faq {
		background-position: 13px;
	}

	.menu__wrapp-chat-text {
		background-position: 13px;
		overflow: hidden;
		padding: 16px 0px 16px 48px;
		padding-left: 48px;
	}

	.menu__wrapp-resume-text {
		background: url("~@/assets/img/account-img/menu/summary.svg");
		background-position: 15px;
		background-repeat: no-repeat;
		background-position-y: center;
		overflow: hidden;
		padding: 8px 0px 8px 48px;
		color: #fff;
	}

	.menu__wrapp-leave-text {
		background: url("~@/assets/img/account-img/menu/exit.svg");
		background-repeat: no-repeat;
		background-position: 9px;
		color: #fff;
	}
}

@media (max-width: 950px) {
	.menu {
		display: none;
		margin-top: 48px;
		max-width: 100%;
		padding: 0 10px;
		width: 100%;
		box-sizing: border-box;
		position: fixed;
		z-index: 2;
	}

	.menu__wrapp {
		width: 100%;
		max-width: 400px;
	}

	.menu-mobile {
		display: flex;
	}

	.menu__wrapp-logo {
		display: none;
	}

	.menu__wrapp-nav {
		margin-top: 17px;
	}

	.menu__wrapp-back {
		margin-top: 27px;
		display: flex;
		width: 100%;
	}

	.menu__wrapp-chat {
		margin-top: 40px;
	}

	.menu__wrapp-chat-text {
		padding: 16px 0 16px 0;
		background: none;
	}

	.menu__wrapp-resume-text {
		background: none;
		padding: 16px 0px 16px 0px;
		color: #009ed1;
	}

	.menu__wrapp-leave {
		width: 100%;
		margin-top: 14px;
		background: rgba(209, 0, 62, 0.05000000074505806);
		border-radius: 8px;
		text-align: center;
		transition: opacity 1s ease;
	}

	.menu__wrapp-leave:hover {
		opacity: 0.5;
	}

	.menu__wrapp-leave-text {
		background: none;
		padding: 16px 0px 16px 0px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.02em;
		color: #d1003f;
		opacity: 0.6;
	}
}
</style>
