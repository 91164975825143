<template>
	<footer class="footer">
		<div class="footer__info">
			<div class="container container-footer">
				<div class="footer__info-wrapper">
					<img src="~@/assets/img/all-img/footer/logo-footer-top.png" alt="" class="footer__info-wrapper-logo" />
					<div class="footer__info-wrapper-list info-list">
						<p class="footer__info-wrapper-list-title info-title">{{ $t('components.landing.footer.company') }}</p>
						<ul class="footer__info-wrapper-list-column info-column">
							<li>
								<router-link :to="{ name: 'home' }" class="footer__info-wrapper-list-column-element info-text">{{ $t('components.landing.footer.mainPage') }}
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'about' }" class="footer__info-wrapper-list-column-element info-text">{{ $t('components.landing.footer.aboutUs') }}
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'help' }" class="footer__info-wrapper-list-column-element info-text">{{ $t('components.landing.footer.help') }}
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'contact' }" class="footer__info-wrapper-list-column-element info-text">
									{{ $t('components.landing.footer.contactUs') }}
								</router-link>
							</li>
						</ul>
					</div>
					<div class="footer__info-wrapper-list info-list">
						<p class="footer__info-wrapper-list-column-title info-title">
							{{ $t('components.landing.footer.product') }}
						</p>
						<ul class="footer__info-wrapper-list-column info-column">
							<li>
								<router-link to="/candidate/registration" class="footer__info-wrapper-list-column-element info-text">
									{{ $t('components.landing.footer.forJobSeekers') }}
								</router-link>
							</li>
							<li>
								<router-link to="/employer/registration" class="footer__info-wrapper-list-column-element info-text">
									{{ $t('components.landing.footer.forEmployers') }}
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'employerReg' }" class="footer__info-wrapper-list-column-element info-text">
									{{ $t('components.landing.footer.registration') }}
								</router-link>
							</li>
							<li>
								<div class="header__wrapper-lang">
									<a @click="changeLanguage('ru')" class="header-lang__element" :class="$i18n.locale === 'ru' ? 'lang-chosen' : ''" >Ru</a>
									<p class="header-lang__element-distinction">/</p>
									<a @click="changeLanguage('en')" class="header-lang__element" :class="$i18n.locale === 'en' ? 'lang-chosen' : ''">En</a>
								</div>
							</li>
						</ul>
					</div>
					<div class="footer__info-wrapper-list info-list">
						<p class="footer__info-wrapper-list-title info-title">Information</p>
						<ul class="footer__info-wrapper-list-column info-column">
							<li>
								<router-link :to="{ name: 'blog' }" class="footer__info-wrapper-list-column-element info-text">{{ $t('components.landing.footer.blog') }}
								</router-link>
							</li>
							<!-- <li>
								<router-link :to="{ name: 'tariff' }" class="footer__info-wrapper-list-column-element info-text">Pricing
								</router-link>
							</li> -->
						</ul>
					</div>
					<div class="footer__info-wrapper-update">
						<p class="footer__info-wrapper-update-title info-title">
							{{ $t('components.landing.footer.signUpTitle') }}
						</p>
						<p class="footer__info-wrapper-update-text info-text">
							{{ $t('components.landing.footer.signUpText') }}
						</p>
						<form action="" class="footer__info-wrapper-update-distribution">
							<input :placeholder="$t('components.landing.footer.enterEmail')" type="email" maxlength="50"
								class="footer__info-wrapper-update-distribution-text" />
							<button type="submit" class="footer__info-wrapper-update-distribution-btn">
								{{ $t('components.landing.footer.signUpButton') }}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="copyright">
			<div class="container">
				<div class="footer__copyright-wrapper">
					<a href="" class="footer__copyright-wrapper-rools footer-text">{{ $t('components.landing.footer.privacyPolicy') }}</a>
					<img src="~@/assets/img/all-img/footer/footer-logo.png" alt="logo"
						class="footer__copyright-wrapper-logodesktop" />
					<img src="~@/assets/img/all-img/footer/footer-logo-mobile.png" alt="logo"
						class="footer__copyright-wrapper-logomobile" />
					<a href="" class="footer__copyright-wrapper-rights footer-text">{{ $t('components.landing.footer.allRightsReserved') }}</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	methods:{
	changeLanguage(lang) {
    this.$i18n.locale = lang;
    }
	}
};
</script>

<style scoped>
.container-footer {
	overflow: visible;
}

.footer__info {
	padding-bottom: 114px;
	background-color: #f3f4f6;
	padding-top: 97px;
}

.footer__info-wrapper-list-column-element {
	display: block;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 40px;
	color: #7b7f87;
}

.footer__info-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.info-column {
	margin-top: 41px;
}

.info-title {
	max-width: 321px;
	line-height: 28px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 18px;
	color: #1c283f;
}

.info-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	color: #7b7f87;
	transition: opacity 1s ease;
}

.info-text:hover {
	opacity: 0.5;
}

.info-list {
	width: 100%;
	max-width: 200px;
	box-sizing: border-box;
}

.footer__info-wrapper-logo {
	display: none;
}

.footer__info-wrapper-update {
	width: 100%;
	max-width: 413px;
	box-sizing: border-box;
}

.footer__info-wrapper-update-text {
	padding-top: 21px;
	max-width: 303px;
	line-height: 22.5px;
}

.footer__info-wrapper-update-distribution {
	border-radius: 9px;
	padding: 27px;
	position: relative;
	margin-top: 34px;
	box-shadow: 0px 32px 84px rgba(14, 86, 124, 0.17);
	max-width: 413px;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
	background: #ffffff;
	border: 1px solid #a9a9a9;
}

.footer__info-wrapper-update-distribution-text {
	padding-left: 39px;
	max-width: 212px;
	outline: none;
	-webkit-appearance: none;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	color: #171725;
	background-image: url("~@/assets/img/main-page/mail.svg");
	background-repeat: no-repeat;
	background-position: 0;
	background-color: inherit;
	background-size: 20px;
}

.footer__info-wrapper-update-distribution-text::placeholder {
	color: #b7c1d1;
}

.footer__info-wrapper-update-distribution-btn {
	position: absolute;
	width: 160px;
	height: 63px;
	background: #009ed1;
	border-radius: 5px;
	width: 160px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	align-items: center;
	color: #ffffff;
	top: 5px;
	right: 4px;
	transition: opacity 1s ease;
}

.header__wrapper-lang {
	display: flex;
	gap: 3px;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #7b7f87;
	line-height: 40px;
	font-family: "Roboto";
	font-weight: 400;
}

.header-lang__element {
	transition: opacity 1s ease;
	color: inherit;
}

.header-lang__element:hover {
	opacity: 0.5;
	cursor: pointer;
}

.lang-chosen {

	color: #009ed1;
}

.footer__info-wrapper-update-distribution-btn:hover {
	opacity: 0.5;
}

@media (max-width: 1000px) {
	.footer__info-wrapper {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.info-list {
		width: 33%;
		text-align: center;
	}

	.footer__info-wrapper-update {
		margin-top: 28px;
	}

	.footer__info-wrapper-update-title {
		text-align: center;
		margin: 0 auto;
	}

	.footer__info-wrapper-update-text {
		text-align: center;
		margin: 0 auto;
	}

	.header__wrapper-lang {
		justify-content: center;
	}
}

@media (max-width: 640px) {
	.footer__info {
		padding-top: 0;
		padding-bottom: 0;
	}

	.footer__info-wrapper-logo {
		display: inline-block;
		margin: 37px auto 10px auto;
		width: 140px;
	}

	.footer__info-wrapper-list-column {
		margin-top: 18px;
	}

	.footer__info-wrapper {
		flex-direction: column;
	}

	.info-list {
		width: 100%;
		margin: 0 auto;
	}

	.footer__info-wrapper-update-distribution {
		text-align: left;
		max-width: 300px;
		position: relative;
		box-shadow: 0px 32px 84px rgb(14 86 124 / 17%);
		margin: 18px auto 40px auto;
		padding: 17px;
	}

	.footer__info-wrapper-update-distribution-text {
		padding-left: 22px;
		font-size: 13px;
		line-height: 14px;
		background-position: 0;
		max-width: 152px;
		background-size: 14px;
	}

	.footer__info-wrapper-update {
		height: 100%;
	}

	.info-title {
		margin: 18px auto 0px auto;
		font-size: 13px;
		line-height: 32px;
	}

	.info-text {
		font-size: 12px;
		line-height: 26px;
	}

	.footer__info-wrapper-update-text {
		padding-top: 5px;
	}

	.footer__info-wrapper-update {
		margin-top: 28px;
		text-align: center;
		margin: 28px auto 0px auto;
	}

	.footer__info-wrapper-update-distribution-btn {
		width: 116px;
		height: 45px;
		font-size: 13px;
		top: 4px;
		right: 4px;
	}

	.footer__info-wrapper-update-title {
		margin: 0 auto;
	}

	.footer__info-wrapper-update-text {
		margin: 0 auto;
	}

	.header__wrapper-lang {
		font-size: 12px;
		line-height: 26px;
	}
}

@media (max-width: 330px) {
	.footer__info-wrapper-update-distribution {
		width: 100%;
	}

	.footer__info-wrapper-update-distribution-text {
		width: 100%;
	}
}

.copyright {
	background: #023c61;
}

.footer__copyright-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 54px;
}

.footer-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.footer-text:hover {
	opacity: 0.5;
}

.footer__copyright-wrapper-logomobile {
	display: none;
}

.footer__copyright-wrapper-logodesktop {
	width: 137px;
}

@media (max-width: 640px) {
	.footer__copyright-wrapper-logodesktop {
		display: none;
	}

	.footer__copyright-wrapper-rools {
		order: 1;
	}

	.footer__copyright-wrapper-rights {
		order: 2;
	}

	.footer__copyright-wrapper-logomobile {
		display: inline-block;
		margin-top: 13px;
		width: 37px;
		order: 3;
	}

	.footer__copyright-wrapper {
		height: 138px;
		flex-direction: column;
		justify-content: start;
		padding-top: 16px;
	}

	.footer-text {
		font-size: 12px;
		line-height: 26px;
		color: #ffffff;
		opacity: 0.7;
	}
}
</style>
