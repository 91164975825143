var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu__wrapp"},[_c('div',{staticClass:"menu__wrapp-back menu-mobile"},[_c('div',{staticClass:"menu__wrapp-back-btn"}),_c('p',{staticClass:"menu__wrapp-back-text"},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.personalCabinet')))])]),_c('nav',{staticClass:"menu__wrapp-nav"},[_c('router-link',{staticClass:"menu__wrapp-nav-item item-dashboard",class:{
          'item-blue': this.$route.path === '/employer/account/dashboard',
        },attrs:{"to":{ name: 'dashboardCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-dashboard",class:{
            'item-white-dashboard':
              this.$route.path === '/employer/account/dashboard',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.personalCabinet')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-friends",class:{
          'item-blue': this.$route.path === '/user/social/friends',
        },attrs:{"to":{ name: 'friends' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-friends",class:{
            'item-white-friends':
              this.$route.path === '/user/social/friends',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.friends'))+" "),(_vm.user.newFriendRequests && _vm.user.newFriendRequests > 0)?_c('span',{staticClass:"friends-messages"},[_vm._v(_vm._s(_vm.user.newFriendRequests)+"+")]):_vm._e()])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-communities",class:{
          'item-blue': this.$route.path === '/user/social/communities',
        },attrs:{"to":{ name: 'communities' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-communities",class:{
            'item-white-communities':
              this.$route.path === '/user/social/communities',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.communities')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-settings",class:{
          'item-blue': this.$route.path === '/employer/account/settings',
        },attrs:{"to":{ name: 'settingsCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-settings",class:{
            'item-white-settings':
              this.$route.path === '/employer/account/settings',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.profileSettings')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-rating",class:{
          'item-blue': this.$route.path === '/employer/account/rate',
        },attrs:{"to":{ name: 'rateCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-rating",class:{
            'item-white-rating':
              this.$route.path === '/employer/account/rate',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.rating')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-tests",class:{
          'item-blue': this.$route.path === '/employer/account/interview',
        },attrs:{"to":{
          name: 'interviewPage'
        }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-tests",class:{
            'item-white-tests':
              this.$route.path === '/employer/account/interview',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.testing')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-vacancies",class:{
          'item-blue': this.$route.path === '/employer/account/vacancies',
        },attrs:{"to":{ name: 'vacanciesCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-vacancies",class:{
            'item-white-vacancies':
              this.$route.path === '/employer/account/vacancies',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.searchForSpecialists')))])]),(false)?_c('router-link',{staticClass:"menu__wrapp-nav-item item-favorites",class:{
          'item-blue': this.$route.path === '/employer/account/favorites',
        },attrs:{"to":{ name: 'favorites' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-favorites",class:{
            'item-white-favorites':
              this.$route.path === '/employer/account/favorites',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.favorites'))+" "),_c('span',{staticClass:"friends-messages favorites--message"},[_vm._v("25+")])])]):_vm._e(),_c('router-link',{staticClass:"menu__wrapp-nav-item item-responses",class:{
          'item-blue': this.$route.path === '/employer/account/vacancy',
        },attrs:{"to":{ name: 'vacancyCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-responses",class:{
            'item-white-responses':
              this.$route.path === '/employer/account/vacancy',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.myVacancies')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-recommendations",class:{
          'item-blue':
            this.$route.path === '/employer/account/recommendations',
        },attrs:{"to":{ name: 'recommendationsCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-recommendations",class:{
            'item-white-recommendations':
              this.$route.path === '/employer/account/recommendations',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.recommendations')))])]),_c('router-link',{staticClass:"menu__wrapp-nav-item item-faq",class:{
          'item-blue': this.$route.path === '/employer/account/faq',
        },attrs:{"to":{ name: 'faqCompany' }}},[_c('a',{staticClass:"menu__wrapp-nav-item-link-faq",class:{
            'item-white-faq': this.$route.path === '/employer/account/faq',
          },attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.faq')))])]),_c('router-link',{staticClass:"menu__wrapp-chat-text",attrs:{"to":{ name: 'allChats' }}},[_c('svg',{attrs:{"width":"16","height":"15","viewBox":"0 0 16 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H9C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V6C11 6.53043 10.7893 7.03914 10.4142 7.41421C10.0391 7.78929 9.53043 8 9 8H7L4 11V8H2C1.46957 8 0.960859 7.78929 0.585786 7.41421C0.210714 7.03914 0 6.53043 0 6V2Z","fill":"white"}}),_c('path',{attrs:{"d":"M13.0005 4V6C13.0005 7.06087 12.5791 8.07828 11.8289 8.82843C11.0788 9.57857 10.0614 10 9.0005 10H7.8285L6.0625 11.767C6.3425 11.916 6.6615 12 7.0005 12H9.0005L12.0005 15V12H14.0005C14.5309 12 15.0396 11.7893 15.4147 11.4142C15.7898 11.0391 16.0005 10.5304 16.0005 10V6C16.0005 5.46957 15.7898 4.96086 15.4147 4.58579C15.0396 4.21071 14.5309 4 14.0005 4H13.0005Z","fill":"white"}})]),_vm._v(" "+_vm._s(_vm.$t('components.account_company.menuCompany.chat'))+" "),(_vm.user.newMessages && _vm.user.newMessages > 0)?_c('span',{staticClass:"friends-messages"},[_vm._v(_vm._s(_vm.user.newMessages)+"+")]):_vm._e()])],1),_c('div',{staticClass:"menu__wrapp-end"},[_c('router-link',{staticClass:"menu__wrapp-resume-text",attrs:{"to":{ name: 'createVacancy' }}},[_c('span',{staticClass:"text-hiden"},[_vm._v(_vm._s(_vm.$t('components.account_company.menuCompany.createVacancy')))])]),_c('button',{staticClass:"menu__wrapp-leave-text",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('components.account_company.menuCompany.signOut'))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }