<template>
  <div>
    <HeaderAccountCompany></HeaderAccountCompany>

    <main class="main">
      <div class="account">
        <MenuCompany></MenuCompany>
        <div class="container-account">
          <div class="filling">
            <div class="search">
              <h1 class="search__title">{{ $t('pages.account.account_company.accountCompanyFavoritesPage.title') }}</h1>
              <h2 class="search__subtitle">{{ $t('pages.account.account_company.accountCompanyFavoritesPage.subtitle')
                }}</h2>
              <div class="search__specifications">
                <span class="search__specifications-work-span"></span>
                <div class="search__specifications-work-wrapper pop-up">
                  <input type="text" placeholder="UX Designer" class="search__specifications-work"
                    v-model="searchVacanciesForm.specialization" />
                  <div class="pop-up-list" v-if="false">
                    <input type="text" placeholder="Search" class="pop-up-list__element" />
                    <button class="pop-up-list__element">Тест</button>
                    <button class="pop-up-list__element">Тест</button>
                    <button class="pop-up-list__element">Тест</button>
                    <button class="pop-up-list__element">Тест</button>
                    <button class="pop-up-list__element">Тест</button>
                  </div>
                </div>
                <div class="search__specifications-rating">
                  <span class="search__specifications-rating-span"></span>
                  <p class="search__specifications-rating-text">{{
                    $t('pages.account.account_company.accountCompanyFavoritesPage.rating') }}</p>
                  <p class="search__specifications-rating-input-text">{{
                    $t('pages.account.account_company.accountCompanyFavoritesPage.from') }}</p>
                  <input type="text" class="search__specifications-rating-input"
                    v-model="searchVacanciesForm.rateFrom" />
                  <p class="search__specifications-rating-input-text">{{
                    $t('pages.account.account_company.accountCompanyFavoritesPage.to') }}</p>
                  <input type="text" class="search__specifications-rating-input" v-model="searchVacanciesForm.rateTo" />
                </div>
                <div class="search-wrapper">
                  <button @click="cityVisible = !cityVisible" class="test__top-bottom-town">
                    {{ searchVacanciesForm.city ? cityName :
                      $t('pages.account.account_company.accountCompanyFavoritesPage.cityPlaceholder') }}
                    <span class="test__top-bottom-town-img"></span>
                  </button>
                  <div class="search-list" v-if="cityVisible">
                    <input placeholder="City" type="text" @input="(e) => searchCity(e.target.value)"
                      class="search-list__element search-list__element-input" />
                    <button class="search-list__element" @click="pickCity" :value="item.id" v-for="item in dataCities"
                      :key="item.id">
                      {{ item.name }}
                    </button>
                  </div>
                </div>
                <div class="test__top-bottom-work-container">
                  <button ref="select" class="test__top-bottom-work" @click="selectVisible = !selectVisible"
                    @blur="closeSelect">
                    <span>{{ $t('pages.account.account_company.accountCompanyFavoritesPage.employmentType') }}</span>
                  </button>
                  <div class="test__top-bottom-work-group" v-if="selectVisible">
                    <input type="radio" id="one" value="0" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="one" :class="{
                      select_picked: searchVacanciesForm.employType === '0',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.fullEmployment') }}</label>

                    <input type="radio" id="two" value="1" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="two" :class="{
                      select_picked: searchVacanciesForm.employType === '1',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.partTimeEmployment') }}</label>

                    <input type="radio" id="three" value="2" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="three" :class="{
                      select_picked: searchVacanciesForm.employType === '2',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.projectActivity') }}</label>

                    <input type="radio" id="four" value="3" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="four" :class="{
                      select_picked: searchVacanciesForm.employType === '3',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.internship') }}</label>

                    <input type="radio" id="five" value="4" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="five" :class="{
                      select_picked: searchVacanciesForm.employType === '4',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.volunteering') }}</label>

                    <input type="radio" id="six" value="5" class="test__top-bottom-work-group-element-input"
                      v-model="searchVacanciesForm.employType" @change="pickedEmplType" @click="checkEmpl" />
                    <label for="six" :class="{
                      select_picked: searchVacanciesForm.employType === '5',
                    }" class="test__top-bottom-work-group-element-label">{{
                        $t('pages.account.account_company.accountCompanyFavoritesPage.distantWork') }}</label>
                  </div>
                </div>
                <button class="search__specifications-btn" @click="fetchVacancyByFilter">
                  {{ $t('pages.account.account_company.accountCompanyFavoritesPage.findJobs') }}
                </button>
              </div>
              <p style="display: none" class="search__sorting-text mobile">
                {{ $t('pages.account.account_company.accountCompanyFavoritesPage.sorting') }}
              </p>
            </div>
            <div class="work">
              <div class="work__workers">
                <SeparateCompanyResume v-for="item in vacancies" :key="item.id" :summary="item"></SeparateCompanyResume>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany";
import SeparateCompanyResume
  from "../../../components/account/account_company/account_company_resume/item/SeparateCompanyResume.vue";
import { config } from "@/lib/config";

export default {
  computed: {
    dataCities() {
      return this.$store.getters.dataCities;
    },
  },
  components: { HeaderAccountCompany, MenuCompany, SeparateCompanyResume },
  name: "AccountCompanyFavoritesPage",
  props: ["resumeName"],
  data() {
    return {
      vacancies: [],
      searchVacanciesForm: {},
      selectVisible: false,
      cityVisible: false,
      cityName: null,
    };
  },
  methods: {
    searchCity(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$store.dispatch("searchCity", val);
      }, 1000);
    },
    pickCity(e) {
      this.cityVisible = false;
      this.cityName =
        this.dataCities[
          this.dataCities.map((item) => item.id).indexOf(e.target.value)
        ].name;
      this.searchVacanciesForm.city = this.cityName;
      this.$store.dispatch("searchCity", "");
    },
    closeSelect() {
      setTimeout(() => {
        this.selectVisible = false;
      }, 300);
    },
    pickedEmplType() {
      this.selectVisible = !this.selectVisible;
    },
    checkEmpl() {
      if (this.searchVacanciesForm.employType !== null)
        this.searchVacanciesForm.employType = null;
    },
    fetchVacancyByFilter() {
      let xmlHttp = new XMLHttpRequest();
      let url = config.apiPrefix + "employer/summary/search";
      xmlHttp.open("POST", url, false);
      xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      xmlHttp.setRequestHeader(
        "Content-Type",
        "application/json;charset=UTF-8"
      );
      try {
        xmlHttp.send(JSON.stringify(this.searchVacanciesForm));
        let response = JSON.parse(xmlHttp.response);
        this.vacancies = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  beforeMount() {
    this.$store.dispatch("searchCity", "");
    this.searchVacanciesForm = {
      specialization: null,
      city: null,
      employType: null,
      rateFrom: null,
      rateTo: null,
    };
    if (this.resumeName) {
      this.searchVacanciesForm.specialization = this.resumeName;
    }
    this.fetchVacancyByFilter();
    scroll(0, 0);
  },
};
</script>


<style scoped>
/* Caption css */
.account {
  display: flex;
}


.search__title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.search__subtitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 35px;
  color: #000000;
}

.search__specifications {
  box-shadow: inset -1px -1px 0px #f1f1f5;
  position: relative;
  margin-top: 66px;
  width: 100%;
  box-sizing: border-box;
  height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search__specifications-work-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/find.svg");
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  left: 2.9%;
  z-index: 2;
}

.search__specifications-work-wrapper {
  max-width: 355px;
  width: 100%;
  height: 100%;
  position: relative;
}

.search__specifications-work {
  border-right: 1px solid #f1f1f5;
  width: 100%;
  max-width: 100%;
  flex-shrink: 1;
  border-radius: 10px 0px 0px 10px;
  padding: 0px 0 0px 64px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171625;
  height: inherit;
  box-sizing: border-box;
  transition: background 1s ease;
}

.pop-up {
  position: relative;
}

.pop-up-list {
  position: absolute;
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 5;
  box-sizing: border-box;
  border: 1px solid #171625;
  max-height: 250px;
  overflow: auto;
}

.pop-up-list :first-child {
  border-radius: 10px 10px 0 0;
}

.pop-up-list :last-child {
  border-radius: 0 0 10px 10px;
  border-bottom: none;
}

.pop-up-list__element:hover {
  background: #eefbff;
}

.pop-up-list__element {
  max-width: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171625;
  font-family: "Roboto";
  border-bottom: 1px solid #171625;
  padding: 10px 15px;
  text-align: left;
  background: #fff;
  box-sizing: border-box;
  transition: all 1s ease-in-out;
}

.search__specifications-work:hover {
  background: #eefbff;
}

.search__specifications-rating-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/rating.svg");
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  left: 2.9%;
}

/* search */
.test__top-bottom-town {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  background-image: url("~@/assets/img/landing-img/main-page/place-blue.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 36px;
  text-align: left;
  background-color: #fff;
}

.test__top-bottom-town-img {
  position: absolute;
  display: block;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 9px;
  right: 20px;
  top: calc(50% - 4px);
}

.search-wrapper {
  width: 26.7%;
  height: 70px;
  box-shadow: inset -1px -1px 0px #f1f1f5;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  position: relative;
}

.search-list {
  position: absolute;
  background: #fff;
  max-height: 278px;
  overflow-y: scroll;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eaeaee;
}

.search-list__element {
  padding-left: 60px;
}

.search-list {
  z-index: 101;
}

.search-list__element {
  padding-left: 60px;
}

.search-list__element {
  -webkit-appearance: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
  line-height: 1;
}

.search-list__element-input {
  cursor: auto;
  border-bottom: 1px solid #eaeaee;
  padding-right: 20px;
}

.search-list__element:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.search-list__element:last-child {
  border-radius: 0px 0px 7px 7px;
  border-bottom: none;
}

.test__top-bottom-town::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
}

/* /search */
.search__specifications-rating {
  border-right: 1px solid #f1f1f5;
  position: relative;
  height: inherit;
  width: 305px;
  flex-shrink: 1;
  display: flex;
  background: #fff;
  padding-right: 24px;
  transition: background 1s ease;
}

.search__specifications-rating-text {
  padding: 23px 0 23px 64px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  background: inherit;
  line-height: 26px;
  letter-spacing: 0.1px;
  transition: inherit;
  color: #171625;
}

.search__specifications-rating-input {
  margin-left: 10px;
  width: 32px;
  border-bottom: 1px solid #50b5ff;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #171625;
  padding: 0px 0 0px 0px;
  background: inherit;
  height: 26px;
  transition: inherit;
  margin-top: 23px;
}

.search__specifications-rating-input-text {
  font-family: "Roboto";
  margin-left: 11px;
  padding: 23px 0 23px 0px;
  font-weight: 400;
  font-size: 16px;
  transition: inherit;
  line-height: 26px;
  background: inherit;
  letter-spacing: 0.1px;
  color: #171625;
  transition: background 1s ease;
}

.search__specifications-rating:hover {
  background: #eefbff;
}

.search__specifications-rating-span {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/rating.svg");
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  left: 13.9%;
  top: 30px;
}


.search__specifications-btn {
  justify-content: center;
  flex-shrink: 1;
  z-index: 2;
  box-sizing: border-box;
  width: 200px;
  font-family: "Roboto";
  border-radius: 0px 10px 10px 0px;
  height: inherit;
  background: #009ed1;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  transition: opacity 1s ease;
}

.search__specifications-btn:hover {
  opacity: 0.5;
}


.search__sorting-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #696974;
}


.mobile {
  display: none;
}



@media (max-width: 1550px) {
  .search__specifications-work-wrapper {
    max-width: 100%;
  }

  .search__specifications {
    background: #fff;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    padding: 20px 20px 10px 20px;
    border: 1px solid #f1f1f5;
    border-radius: 20px;
    margin: 25px auto 0 auto;
  }

  .search__specifications-work {
    font-size: 14px;
    padding: 0px 0 0px 41px;
    margin-bottom: 10px;
    height: 42px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-work-span {
    left: 35px;
    top: 33px;
  }

  .search__specifications-rating {
    font-size: 14px;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .search__specifications-rating-text {
    font-size: 14px;
    padding: 0px 0 0px 41px;
  }

  .search__specifications-rating-input-text {
    font-size: 14px;
    padding: 0px 0 0px 0px;
  }

  .search__specifications-rating-span {
    left: 16px;
    top: 17px;
  }


  .search__specifications-rating-input {
    margin: 10px 0 auto 10px;
  }


  .search__specifications-btn {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    border-radius: 10px;
  }

  /* search */
  .test__top-bottom-town {
    padding-left: 42px;
    background-position: 18px;
  }

  .search-wrapper {
    font-size: 14px;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
  }

  .test__top-bottom-town {
    border-radius: 10px;
  }

  .search-list {
    border-radius: 10px;
  }
}

@media (max-width: 760px) {
  .search__title {
    font-size: 13px;
  }

  .search__subtitle {
    font-size: 20px;
  }

  .search__specifications {
    width: 100%;
    font-size: 14px;
  }

  .search__specifications-work {
    font-size: 14px;
  }

  .search__specifications-rating {
    font-size: 14px;
  }

  .search__specifications-rating-input {
    font-size: 14px;
  }

  .search__specifications-rating-text {
    font-size: 14px;
  }

  .search__specifications-rating-input-text {
    font-size: 14px;
  }


  .search__specifications-btn {
    font-size: 14px;
  }

  .mobile {
    display: block;
    padding-top: 23px;
  }



  .desctop {
    display: none;
  }


  .search__sorting-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #696974;
  }


}

@media (max-width: 380px) {
  .search__specifications-work {
    padding-left: 41px;
    background-position: 15px;
  }

  .search__specifications-rating-text {
    padding-left: 41px;
    background-position: 15px;
  }


}

/* Search */

/* Work */
.work {
  margin-top: 50px;
  display: flex;
}

.work__filters {
  margin-right: 39px;
  width: 374px;
}

.work__filters-element :not(:last-child) {
  margin-bottom: 10px;
}

.work__filters-element :first-child {
  margin-bottom: 14px;
}


.work__filters-element-filter-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #19191d;
}

.work__filters-element-filter-checkbox+label::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #4a4b57;
  border-radius: 2px;
  margin-right: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.work__filters-element-filter-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}


.work__workers {
  width: 100%;
}



.work__workers {
  display: grid;
  row-gap: 20px;
  margin-bottom: 30px;
}




@media (max-width: 1190px) {
  .work__workers {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .search__specifications {
    width: 100%;
  }


}


@media (max-width: 500px) {

  .work__filters-element-filter-checkbox+label {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .work__workers {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

/* Work */

/* Select */

.test__top-bottom-work-container {
  height: 100%;
  position: relative;
  width: 22.2%;
  border-left: 1px solid #f1f1f5;
}

.test__top-bottom-work-container::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/work.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  left: 36px;
  top: 28px;
}

.test__top-bottom-work-container {
  height: 100%;
  position: relative;
  width: 22.2%;
}

.test__top-bottom-work-container::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("~@/assets/img/vacancies-page/search/work.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  left: 36px;
  top: 28px;
}

.test__top-bottom-work {
  text-align: left;
  position: relative;
  padding-left: 60px;
  width: 100%;
  height: 70px;
  cursor: pointer;
  border-left: 1px solid #f1f1f5;
  border-right: none;
  border-top: none;
  border-bottom: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #171725;

  height: inherit;
  flex-shrink: 1;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  transition: background-color 1s ease;
}

.test__top-bottom-work-group {
  position: absolute;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 70px;
  left: 0;
  z-index: 10;
  border-radius: 0px 0px 7px 7px;
  box-shadow: inset -1px -1px 0px #f1f1f5;
}

.test__top-bottom-work-group-element-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
  border: 1px solid #eaeaee;
}

.test__top-bottom-work-group-element-label:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.test__top-bottom-work-group-element-label:last-child {
  border-radius: 0px 0px 7px 7px;
}

.test__top-bottom-work-group-element-input {
  -webkit-appearance: none;
  height: 0px;
}

@media (max-width: 1550px) {
  .test__top-bottom-work-container {
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #f1f1f5;
  }

  .test__top-bottom-work-container::after {
    left: 16px;
    top: 17px;
  }

  .test__top-bottom-work {
    border-radius: 10px;
    padding-left: 41px;
    background-position: calc(97%);
  }

  .test__top-bottom-work-group {
    top: 48px;
    border-radius: 7px;
  }

  .test__top-bottom-work-group-element-label:nth-child(1) {
    border-radius: 7px;
  }


  .test__top-bottom-work {
    font-size: 14px;
  }

  .test__top-bottom-work-group-element-label {
    font-size: 14px;
  }
}

.select_picked {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

/* /Select */
</style>
