<template>
	<div class="speciality">
		<div class="speciality-filling">
			<button @click="$emit('closeWindow')" class="close">
				<img src="~@/assets/img/all-img/documents/close.svg" alt="" class="close-img">
			</button>
			<p class="title">{{ $t('components.account_worker.specialitySelect.specialization') }}</p>
			<input type="search" v-model="searchName"
				:placeholder="$t('components.account_worker.specialitySelect.quick_search')" class="search">
			<div class="speciality-list">
				<div v-for="industria in industries" :key="industria.id">

					<template v-if="getByCategory(industria.name).length">
						<div class="speciality-wrapper">
							<div class="speciality-element">
								<button @click="toggleCategory(industria.name)" class="speciality-btn"
									:class="{ 'speciality-btn__open': viewCategories.includes(industria.name) }">

									<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 6L8 10L4 6" stroke="#474747" stroke-width="1.33333"
											stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
								</button>
								<label for="speciality-1" class="speciality-caption">
									<input :value="industria.id" :checked="separatePostNew === industria.id"
										type="radio" v-if="false" id="speciality-1" class="checkbox">
									<p class="speciality-text">{{ industria.name }}</p>
								</label>
							</div>
							<div class="categories" v-if="viewCategories.includes(industria.name)">

								<label :for="item.id" class="categories-element"
									v-for="item in getByCategory(industria.name)" :key="item.id">
									<input :id="item.id" :value="item.id" :checked="separatePostNew === item.id"
										v-model="newSpecialization" type="radio" class="checkbox"
										@click="handleRadioClick(item.id)">
									<p class="speciality-text">{{ item.name }}
									</p>
								</label>
							</div>
						</div>
					</template>
				</div>


			</div>
			<div class="actions">
				<button class="actions-btn save" @click="$emit('pickSpecialization', newSpecialization)">{{
					$t('components.account_worker.specialitySelect.save') }}</button>
				<button class="actions-btn cancel" @click="$emit('closeWindow')">{{
					$t('components.account_worker.specialitySelect.cancel') }}</button>
			</div>
		</div>
	</div>
</template>


<script>
import separatePostNew from "@/components/social/account_communitis_main/SeparatePostNew.vue";

export default {
	name: "SpecialitySelect",
	props: ['specialisations', 'currentSpecialisation', 'industries'],
	watch: {
		findSpecializations(newVal) {
			if (newVal.length <= 25) {
				this.viewCategories = this.industries.map(industria => industria.name);
			} else {
				this.viewCategories = [];
			}
		}
	},

	computed: {
		separatePostNew() {
			return separatePostNew
		},
		findSpecializations() {
			return this.searchName ? this.specialisations.filter(item => {
				return item.name?.toLowerCase().includes(this.searchName.toLowerCase()) || item.category?.toLowerCase().includes(this.searchName.toLowerCase())
			}) : this.specialisations
		}
	},
	data() {
		return {
			searchName: null,
			viewCategories: [],
			newSpecialization: null
		}
	},
	beforeMount() {
		this.newSpecialization = this.currentSpecialisation;
	},
	methods: {
		getByCategory(category) {
			return this.findSpecializations.filter(item => item.category.toLowerCase() === category.toLowerCase()).map(item => {
				item.name = item?.name?.charAt(0)?.toUpperCase() + item?.name?.slice(1);
				return item
			});
		},
		toggleCategory(categoryName) {
			const index = this.viewCategories.indexOf(categoryName);
			if (index > -1) {
				this.viewCategories.splice(index, 1);
			} else {
				this.viewCategories.push(categoryName);
			}
		},
		handleRadioClick(id) {
			// Проверяем, активен ли уже этот радиобатон
			if (this.newSpecialization === id) {
				this.newSpecialization = null;
			}
		},
	}
}
</script>

<style scoped>
* {
	box-sizing: border-box;
}

.speciality {
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.5);

	height: 100vh;
	height: 100dvh;
	width: 100%;
	max-width: 100%;
	padding: 40px;

}

.speciality-filling {
	position: relative;
	max-width: 900px;
	width: 100%;
	background: #fff;
	height: 100%;
	margin: 0 auto;
	padding: 30px 20px;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
}

.close {
	position: absolute;
	right: 20px;
	top: 20px;
}


.title {
	font-size: 26px;
	font-weight: 600;
}

.search {
	margin-top: 20px;
	max-width: 100%;
	width: 100%;
	height: 46px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #171725;
	flex-shrink: 1;
	box-sizing: border-box;
	width: 100%;
	padding: 10px 20px;
}

.speciality-caption {
	display: flex;
	align-items: center;
	gap: 10px;
}

.speciality-list {
	margin-top: 30px;
	height: 100%;
	overflow: auto;
	scrollbar-width: thin;
	display: flex;
	flex-direction: column;
}

.speciality-wrapper {
	margin-bottom: 12px;
}

.speciality-element {
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 100%;
	gap: 10px;
}

.checkbox {
	width: 20px;
	height: 20px;
}

.speciality-btn {
	width: 18px;
	height: 18px;
	transform: rotate(-90deg);
	display: flex;
	flex-shrink: 0;
}


.speciality-btn__open {
	transform: rotate(0deg);
}

.speciality-btn svg {
	width: 100%;
	height: 100%;
}

.categories {
	max-width: 100%;
	width: 100%;
	padding-left: 65px;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.categories-element {
	display: flex;
	align-items: center;
	gap: 10px;
}

.actions {
	bottom: 20px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	gap: 20px;
	z-index: 1001;
	background-color: white;
	width: 100%;
	padding: 20px;
	max-width: 900px;
	margin: 0 auto;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
}


.actions-btn {
	max-width: 150px;
	width: 100%;
	display: flex;
	padding: 16px;
	text-align: center;
	justify-content: center;
	border-radius: 10px;
	transition: all 1s ease-in-out;
}

.actions-btn:hover {
	opacity: 0.5;
}

.save {
	background: #14C686;
	color: #fff;
}

.cancel {
	background: #db0a0a;
	color: #fff;
}

@media (max-width: 767px) {
	.speciality {
		padding: 20px 10px;
	}

	.actions {
		justify-content: center;
	}

	.title {
		font-size: 20px;
	}

	.search {
		margin-top: 12px;
	}

	.speciality-filling {
		padding: 20px 12px;
	}
}
</style>