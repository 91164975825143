<template>
	<div class="test-container">
		<div class="test">
			<div class="test__wrapper">
				<div class="test__wrapper-content">
					<div class="test__wrapper-content-caption">
						<p class="test__wrapper-content-caption-number">
							{{ $t('components.tests.testExaminationWrapper.questionNumber', { count: count + 1 }) }}
						</p>
						<p class="test__wrapper-content-caption-description">
							{{ passes.questionBody }}
						</p>
						<img v-if="passes.photoPath !== null" :src="passes.photoPath" alt=""
							class="test__wrapper-content-caption-picture" />
						<video v-if="passes.videoPath !== null" :src="passes.videoPath" controls
							class="test__wrapper-content-caption-video"></video>
						<div class="test__wrapper-content-caption-anwer" v-if="passes.questionType === 'open'">
							<p class="test__wrapper-content-caption-anwer-text">
								{{ $t('components.tests.testExaminationWrapper.candidateResponse') }}
							</p>
							<p class="test__wrapper-content-caption-anwer-filling">
								{{ passes.openAnswer }}
							</p>
						</div>
						<TestExaminationSeaprateAnswer v-else v-for="item in passes.answers" :key="item.id"
							:answer="item" :correct-answer="item.correct" :user-answer="item.pickedByUser"
							:questionType="passes.questionType">
						</TestExaminationSeaprateAnswer>
					</div>
					<flash-message v-if="!display"></flash-message>
					<div class="test__wrapper-content-actions" v-if="display && change">
						<button class="test__wrapper-content-actions-accept" @click="addPasses(true)">
							{{ $t('components.tests.testExaminationWrapper.acceptAnswer') }}
						</button>
						<button class="test__wrapper-content-actions-cancel" @click="addPasses(false)">
							{{ $t('components.tests.testExaminationWrapper.wrongAnswer') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TestExaminationSeaprateAnswer from "../../test_examination_elements/item/TestExaminationSeaprateAnswer.vue";
require('vue-flash-message/dist/vue-flash-message.min.css');

export default {
	components: { TestExaminationSeaprateAnswer },
	name: "TestExaminationWrapper",
	props: {
		passes: {},
		count: {},
		change: {}
	},
	data() {
		return {
			display: true
		}
	},
	methods: {
		countTrueAnswer(passes) {
			if (typeof passes.answers !== "string") {
				let count = 0;
				for (let i = 0; i < passes.answers.length; i++) {
					if (passes.answers[i].correct === true)
						count++;
				}
				return count;
			} else return null;
		},
		addPasses(choose) {
			let temp = {
				questionId: this.passes.questionId,
				correct: choose
			};
			this.display = false;
			this.$store.commit('addSavePassing', temp)
			if (choose)
				this.flash(this.$t('components.tests.testExaminationWrapper.answerAccepted'), "success", { timeout: 1000 });
			else this.flash(this.$t('components.tests.testExaminationWrapper.answerNotAccepted'), "warning", { timeout: 1000 });
		}
	}
};
</script>



<style scoped>
/* Modificators */
.red-text {
	color: #ab3e5e;
}

/* /Modificators */

/* Caption */

.test-container {
	border-top: 2px solid #e8e8e8;
	padding: 64px 0 62px 0px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
}

.test__wrapper-content-caption {
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	padding-bottom: 20px;
}

.test__wrapper-content-caption-number {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 15px;
	line-height: 1.28;
	color: #009ed1;
}

.test__wrapper-content-caption-number-span {
	color: #14c686;
}

.test__wrapper-content-caption-description {
	padding-top: 20px;
	width: 100%;
	box-sizing: border-box;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 18px;
	line-height: 1.78;
	color: #3b4c68;
}

@media (max-width: 700px) {
	.test-container {
		padding: 18px 0 30px 0;
	}

	.test__wrapper-content-caption {
		border-bottom: none;
	}

	.test__wrapper-content-caption-name-title {
		font-size: 12px;
	}
}

/* /Caption */

/* Wrapper */
.test {
	display: flex;
	max-width: 1518px;
	margin: 0 auto;
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;
}

.test__wrapper {
	margin: 0 auto;
	background: #ffffff;
	border: 1px solid #e9e9f9;
	border-radius: 19px;
	max-width: 1200px;
	width: 100%;
	box-sizing: border-box;
}

.test__wrapper-content {
	padding: 46px 63px 59px 62px;
}

.test__wrapper-content-caption-picture {
	margin-top: 20px;
	height: 300px;
	border-radius: 16px;
	object-fit: cover;
	width: 100%;
	box-sizing: border-box;
}

.test__wrapper-content-caption-anwer {
	width: 100%;
	box-sizing: border-box;
	margin-top: 20px;
}

.test__wrapper-content-caption-anwer-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #83939a;
}

.test__wrapper-content-caption-anwer-filling {
	margin-top: 15px;
	padding: 15px 30px;
	width: 100%;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.78;
	color: #3b4c68;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #dae9f3;
	border-radius: 16px;
}

.test__wrapper-content-actions {
	border-top: 1px solid #e9e9f9;
	padding-top: 20px;
	display: flex;
}

.test__wrapper-content-actions-accept {
	padding: 19px 0;
	width: 208px;
	background: #14c686;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.test__wrapper-content-actions-accept:hover {
	opacity: 0.5;
}

.test__wrapper-content-actions-cancel {
	margin-left: 20px;
	padding: 19px 0;
	width: 208px;
	background: #f9eeee;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #ab3e5e;
	transition: opacity 1s ease;
}

.test__wrapper-content-actions-cancel:hover {
	opacity: 0.5;
}

.test__wrapper-content-caption-video {
	margin-top: 20px;
	background: #000;
	border-radius: 16px;
	height: 300px;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
}

@media (max-width: 700px) {
	.test__wrapper {
		margin: 22px 0 0 0;
	}

	.test {
		flex-wrap: wrap;
	}

	.test__change {
		margin-left: auto;
		order: 2;
		display: flex;
	}

	.test__wrapper {
		border: none;
		order: 3;
	}

	.test__wrapper-content {
		padding: 0;
	}

	.test__wrapper-content {
		background: #fafafb;
	}

	.test__wrapper-content-caption-number {
		font-size: 13px;
	}

	.test__wrapper-content-caption-description {
		padding-top: 10px;
		font-size: 13px;
		line-height: 1.72;
		color: #000000;
	}

	.test__wrapper-content-caption-picture {
		height: 160px;
	}

	.test__wrapper-content-caption-video {
		height: 160px;
	}

	.test__wrapper-content-caption-anwer {
		margin-top: 15px;
	}

	.test__wrapper-content-caption-anwer-text {
		font-size: 11px;
	}

	.test__wrapper-content-caption-anwer-filling {
		margin-top: 10px;
		padding: 10px 15px;
		font-size: 11px;
		line-height: 1.67;
	}

	.test__wrapper-content-actions {
		padding-top: 10px;
		border-top: none;
		justify-content: center;
	}

	.test__wrapper-content-actions-accept {
		padding: 13px 0;
		width: 140px;
		border-radius: 10px;
		font-size: 13px;
	}

	.test__wrapper-content-actions-cancel {
		padding: 13px 0;
		width: 140px;
		border-radius: 10px;
		font-size: 13px;
	}
}

/* /Wrapper */
</style>
