<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="container-account">
					<div class="recomedation">
						<h1 class="recomedation__title">{{
							$t('pages.account.account_company.accountCompanyResponsesPage.responsesToJob') }}</h1>
						<div class="recomedation__wrapper">
							<Response v-for="item in dataResponse" :key="item.responseId" :response="item"></Response>
						</div>
						<div class="recomedation__more">
							<button class="recomedation__more-btn">{{
								$t('pages.account.account_company.accountCompanyResponsesPage.seeAll') }}</button>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import Response from "../../../components/account/account_company/account_company_responses/item/Response.vue";
import { warningNotification } from "@/lib/notification";
import { config } from "@/lib/config";

export default {
	components: { MenuCompany, HeaderAccountCompany, Response },
	name: "AccountCompanyResponsesPage",
	props: {
		vacancyId: {},
	},
	data() {
		return {
			dataResponse: null,
		};
	},
	beforeMount() {
		let xmlHttp = new XMLHttpRequest();
		let url =
			config.apiPrefix + "employer/vacancy/responses/" +
			this.vacancyId;
		xmlHttp.open("GET", url, false);
		xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
		try {
			xmlHttp.send();
			let response = JSON.parse(xmlHttp.response);
			if (response.data && response.data.length > 0) {
				this.dataResponse = response.data;
			} else {
				warningNotification(this, this.$t('pages.account.account_company.accountCompanyResponsesPage.noVacanciesPosted'));
			}
		} catch (e) {
			console.log(e);
		}
	},
};
</script>



<style scoped>
/* Recomendation */
.account {
	display: flex;
}

.recomedation {
	margin-bottom: 40px;
	width: 100%;
	box-sizing: border-box;
}

.recomedation__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.recomedation__text-desctop {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.recomedation__wrapper {
	box-sizing: border-box;
	width: 100%;
	margin-top: 36px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 12px;
}

.recomedation__more {
	margin-top: 47px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.recomedation__more-btn {
	display: none;
	height: 50px;
	width: 25%;
	border: 1px solid #009ed1;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #009ed1;
	transition: opacity 1s ease;
}

.recomedation__more-btn:hover {
	opacity: 0.5;
}

.mobile {
	display: none;
}

.recomedation__text-mobile {
	display: none;
}

@media (max-width: 1650px) {
	.recomedation__wrapper {
		width: 100%;
		margin-top: 36px;
		display: grid;
		row-gap: 12px;
		grid-template-columns: repeat(2, 1fr);
	}

	.recomedation__title {
		text-align: center;
	}

	.recomedation__text-desctop {
		text-align: center;
	}
}

@media (max-width: 1488px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}
}

@media (max-width: 1100px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 767px) {
	.recomedation__more-btn {
		display: block;
	}
}

@media (max-width: 654px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.recomedation__more-btn {
		width: 300px;
	}
}

@media (max-width: 360px) {
	.recomedation__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.recomedation__text-desctop {
		display: none;
	}

	.recomedation__text-mobile {
		display: block;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.recomedation__wrapper {
		margin-top: 15px;
	}
}

/* /Recomendation */
</style>
