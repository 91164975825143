<template>
	<div class="chat__wrapper-contacts-people-separate" @click="viewDialog">
		<div class="chat__wrapper-contacts-people-separate-avatar">
			<img :src="message.avatarUrl !== undefined ? message.avatarUrl : require('/src/assets/img/account-img/chat/avatar.svg')"
				alt="" class="chat__wrapper-contacts-people-separate-avatar-img" />
		</div>
		<div class="chat__wrapper-contacts-people-separate-filling">
			<p class="chat__wrapper-contacts-people-separate-filling-name">
				{{ role === 'admin' ? $t('compocomponents.account_company.separateCompanyChatPersone.administrator') :
					message.userName }}
			</p>
			<p class="chat__wrapper-contacts-people-separate-filling-message">
				{{ message.message ? limit(message.message, 48) : (message.files) ?
					$t('compocomponents.account_company.separateCompanyChatPersone.files') :
					$t('compocomponents.account_company.separateCompanyChatPersone.noMessages') }}
			</p>
		</div>
		<div class="chat__wrapper-contacts-people-separate-info">
			<time class="chat__wrapper-contacts-people-separate-info-time">{{ (Date.now - message.createDate >=
				86400000) ?
				getDay(message.createDate) : getTime(message.createDate)
				}}
			</time>
			<div class="
          chat__wrapper-contacts-people-separate-info-stage
          stage-shipped stage-viewed
        "></div>
			<p class="chat__wrapper-contacts-people-separate-info-notifications" v-if="false"></p>
			<div v-if="message && !message.read && (message.from === 'admin' || message.from !== 'myself' && message.from !== 'user')"
				class="chat__wrapper-contacts-people-separate-info-notifications-block"></div>
		</div>
	</div>
</template>

<script>
import { textSymbolLimit } from "../../../../../lib/textSymbolLimit";
import { getDate, getTime } from "@/lib/dateUtils";

export default {
	props: ['message', 'role'],
	data() {
		return {
			date: null,
			limit: textSymbolLimit,
			getDay: getDate,
			getTime: getTime
		}
	},
	methods: {
		lastMessage() {
			if (this.role === 'admin')
				this.$store.dispatch('fetchLastMessageEmployer', this.$store.getters.token);
			else
				this.$store.dispatch(
					"fetchDialoguesWithUsers",
					{
						token: this.$store.getters.token,
						role: this.role,
						limit: this.$store.getters.getLimitDialogue,
						page: this.$store.getters.getPageDialogue
					}
				);

		},
		readMessage() {
			if (this.role === 'admin')
				this.$store.dispatch('readMessageEmployer', {
					token: this.$store.getters.token,
					callback: this.lastMessage
				});
			else
				this.$store.dispatch('readMessageWithUsers', {
					id: this.message.userId,
					token: this.$store.getters.token,
					callback: this.lastMessage
				});
		},
		viewDialog() {
			this.$store.commit('resetPageMessages');
			this.$store.commit('setUserAvatarInDialogue', null);
			this.$store.commit('setUserNameInDialogue', null);
			if (this.role === 'admin') {
				this.$store.dispatch('fetchAllMessageEmployer', {
					token: this.$store.getters.token,
					callback: this.readMessage
				});
				this.$store.commit('setCurrentDialogueId', 'admin');

			} else {
				this.$store.commit('setUserAvatarInDialogue', this.message.avatarUrl);
				this.$store.commit('setUserNameInDialogue', this.message.userName);
				this.$store.dispatch('fetchAllMessageWithUser', {
					id: this.message.userId,
					page: this.$store.getters.getPageMessages,
					limit: this.$store.getters.getLimitMessages,
					token: this.$store.getters.token,
					callback: this.readMessage
				});
				this.$store.commit('setCurrentDialogueId', this.message.userId)

			}
		}
	},
	name: "SeparateCompanyChatPersone",
};
</script>


<style scoped>
.chat__wrapper-contacts-people-separate-filling {
	margin-left: 16px;
	display: flex;
	flex-direction: column;
}

.chat__wrapper-contacts-people-separate-avatar-notification {
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	bottom: 1px;
	right: 0;
}

.chat__wrapper-contacts-people-separate-avatar {
	width: 48px;
	height: 48px;
	position: relative;
	flex-shrink: 0;
}

.chat__wrapper-contacts-people-separate-avatar-img {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	object-fit: cover;
	object-position: center;
}

.chat__wrapper-contacts-people-separate-filling-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
}

.chat__wrapper-contacts-people-separate-filling-message {
	margin-top: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #999999;
}

.chat__wrapper-contacts-people-separate-info {
	margin-left: auto;
	display: flex;
	flex-direction: column;
}

.chat__wrapper-contacts-people-separate-info-time {
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999999;
}

.chat__wrapper-contacts-people-separate-info-stage {
	display: none;
	margin-top: 8px;
}

.chat__wrapper-contacts-people-separate-info-notifications {
	margin: 5px auto 0 auto;
	border-radius: 100%;
	text-align: center;
	background: #14c686;
	font-family: "OpenSans";
	font-weight: 600;
	font-size: 10px;
	line-height: 24px;
	width: 24px;
	color: #ffffff;
}

@media (max-width: 500px) {
	.chat__wrapper-contacts-people-separate-filling {
		margin: 10px;
	}

	.chat__wrapper-contacts-people-separate-filling-name {
		font-size: 15px;
	}

	.chat__wrapper-contacts-people-separate-filling-message {
		font-size: 11px;
	}

	.chat__wrapper-contacts-people-separate-info-time {
		font-size: 11px;
	}
}

/* Modificators */
.notification-online {
	width: 9px;
	height: 9px;
	background: #14c686;
}

.stage-shipped {
	background: url("~@/assets/img/account-img/chat/stage-shipped.svg");
	width: 14px;
	height: 12px;
}

.stage-viewed {
	width: 29px;
	height: 19px;
	background: url("~@/assets/img/account-img/chat/stage-viewed.svg");
}

.chat__wrapper-contacts-people-separate-info-notifications-block {
	width: 13px;
	height: 13px;
	border-radius: 100%;
	background: #14c686;
	margin: 3px auto 0 auto;
}

/* /Modificators */
</style>