<template>
	<div>
	<Header></Header>
<main class="main">
		<div class="about">
		<div class="container">
			<div class="about__wrapper">
		<div class="about__wrapper-caption mobile-about">
				<button class="about__wrapper-caption-back"></button>
				<p class="about__wrapper-caption-text">{{ $t('pages.landing.aboutCompanyPage.about.employerCard') }}</p>
		</div>
			<div class="about__wrapper-preview-container">
				<div class="about__wrapper-preview">
				<div class="about__wrapper-preview-representation">
					<img src="~@/assets/img/about-company-page/about/company-logo.png" alt="" class="about__wrapper-preview-representation-icon" />
					<div class="about__wrapper-worker-person-representation-rating about-rating">
					<p class="about__wrapper-worker-person-representation-rating-number">
						78
					</p>
					</div>
					<p class="about__wrapper-preview-representation-employer">{{ $t('pages.landing.aboutCompanyPage.about.companyName') }}</p>
					<p class="about__wrapper-preview-representation-designation">{{ $t('pages.landing.aboutCompanyPage.about.designation') }}</p>
					<p class="responses__wrapper-element-description-place">{{ $t('pages.landing.aboutCompanyPage.about.location') }}</p>
				</div>
				<div class="about__wrapper-preview-more">
					<button class="about__wrapper-preview-more-btn">{{ $t('pages.landing.aboutCompanyPage.about.editButton') }}</button>
				</div>
				</div>
			</div>
			<div class="about__wrapper-info">
				<h1 class="about__wrapper-info-title">{{ $t('pages.landing.aboutCompanyPage.about.aboutCompanyTitle') }}</h1>
				<p class="about__wrapper-info-text">{{ $t('pages.landing.aboutCompanyPage.about.aboutCompanyText') }}</p>
				<h2 class="about__wrapper-info-contacts-title">{{ $t('pages.landing.aboutCompanyPage.about.contactInfoTitle') }}</h2>
				<div class="about__wrapper-info-contacts-group">
				<div class="about__wrapper-info-contacts-group-element">
					<h3 class="about__wrapper-info-contacts-group-element-title">{{ $t('pages.landing.aboutCompanyPage.about.websiteTitle') }}</h3>
					<a href="#" class="about__wrapper-info-contacts-group-element-link">{{ $t('pages.landing.aboutCompanyPage.about.websiteLink') }}</a>
				</div>
				<div class="about__wrapper-info-contacts-group-element">
					<h3 class="about__wrapper-info-contacts-group-element-title">{{ $t('pages.landing.aboutCompanyPage.about.telephoneTitle') }}</h3>
					<a href="#" class="about__wrapper-info-contacts-group-element-link">{{ $t('pages.landing.aboutCompanyPage.about.telephoneLink1') }}</a>
					<a href="#" class="about__wrapper-info-contacts-group-element-link">{{ $t('pages.landing.aboutCompanyPage.about.telephoneLink2') }}</a>
				</div>
				<div class="about__wrapper-info-contacts-group-element">
					<h3 class="about__wrapper-info-contacts-group-element-title">{{ $t('pages.landing.aboutCompanyPage.about.emailTitle') }}</h3>
					<a href="#" class="about__wrapper-info-contacts-group-element-link">{{ $t('pages.landing.aboutCompanyPage.about.emailLink') }}</a>
				</div>
				<div class="about__wrapper-info-contacts-group-element">
					<h3 class="about__wrapper-info-contacts-group-element-title">{{ $t('pages.landing.aboutCompanyPage.about.socialTitle') }}</h3>
					<div class="about__wrapper-info-contacts-group-element-social">
					<div class="about__wrapper-info-contacts-group-element-social-logo"></div>
					<div class="about__wrapper-info-contacts-group-element-social-logo"></div>
					<div class="about__wrapper-info-contacts-group-element-social-logo"></div>
					<div class="about__wrapper-info-contacts-group-element-social-logo"></div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		<div class="responses">
		<div class="container">
			<h4 class="responses-caption">{{ $t('pages.landing.aboutCompanyPage.about.companyWorkTitle') }}</h4>
			<div class="responses__wrapper">
			<AboutCompanyVacancy></AboutCompanyVacancy>
			<AboutCompanyVacancy></AboutCompanyVacancy>
			<AboutCompanyVacancy></AboutCompanyVacancy>
			<AboutCompanyVacancy></AboutCompanyVacancy>
			<AboutCompanyVacancy></AboutCompanyVacancy>
			<AboutCompanyVacancy></AboutCompanyVacancy>
			</div>
			<div class="responses__more">
			<button class="responses__more-btn">{{ $t('pages.landing.aboutCompanyPage.about.showMoreButton') }}</button>
			</div>
		</div>
		</div>
		<div class="application">
		<div class="container">
			<div class="application__wrapper">
			<picture class="application__wrapper-picture">
				<source srcset="~@/assets/img/about-company-page/application/application-img-1000.png" media="(max-width: 1000px)" />
				<img src="~@/assets/img/about-company-page/application/application-img.png" alt="" class="application__wrapper-picture-img" />
			</picture>
			<div class="application__wrapper-content">
				<div class="application__wrapper-content-shell">
				<h5 class="application__wrapper-content-shell-title">{{ $t('pages.landing.aboutCompanyPage.about.applicationTitle') }}</h5>
				<p class="application__wrapper-content-shell-text">{{ $t('pages.landing.aboutCompanyPage.about.applicationText') }}</p>
				<a href="" class="application__wrapper-content-shell-send">{{ $t('pages.landing.aboutCompanyPage.about.applicationButton') }}</a>
				</div>
			</div>
			</div>
		</div>
		</div>
	</main>
	<Footer></Footer>
	</div>
</template>
<script>
import Header from "@/components/landing/Header.vue";
import Footer from "@/components/landing/Footer.vue";
import AboutCompanyVacancy from "@/components/landing/AboutCompanyVacancy.vue";
export default {
	components: { Header, Footer, AboutCompanyVacancy },
	name: "AboutCompanyPage"
};
</script>

<style scoped>
/* About */
.about {
	padding-top: 123px;
}

.about__wrapper {
	display: flex;
	padding-bottom: 25px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.about__wrapper-worker-person-representation-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
}

.about__wrapper-worker-person-representation-rating-number {
	padding: 5px 2px 5px 25px;
	background: url("~@/assets/img/about-person-page/responses/star.png");
	background-repeat: no-repeat;
	background-position: 9px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.about-rating {
	width: 49px;
	margin: 0px auto 0 auto;
	order: unset;
}

.about__wrapper-preview-representation-employer {
	margin-top: 10px;
}

.responses__wrapper-element-description-place {
	background: url("~@/assets/img/all-img/place.svg");
	text-align: center;
	padding-left: 0;
	padding-top: 19px;
	background-position-y: 21px;
	background-repeat: no-repeat;
	background-position-x: 0px;
}

.about__wrapper-preview-more {
	width: 100%;
	display: flex;
	justify-content: center;
}

.about__wrapper-preview-more-btn {
	width: 166px;
	margin-top: 27px;
	height: 45px;
	background: #f1f1f5;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #696974;
}

.about__wrapper-preview-representation {
	padding: 35px 22px 25px 22px;
	width: 290px;
	background: #ffffff;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
}

.about__wrapper-preview-representation-icon {
	object-fit: cover;
	object-position: center;
	width: 90px;
	height: 90px;
	margin: 20px auto;
	border-radius: 64px;
}

.about__wrapper-preview-representation-employer {
	text-align: center;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #3b4c68;
}

.about__wrapper-preview-representation-designation {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #b7c1d1;
}

.about__wrapper-preview-representation-more {
	margin-top: 46px;
	height: 50px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
	background: #f1f1f5;
	border-radius: 10px;
}

.about__wrapper-info {
	margin-left: 104px;
	max-width: 786px;
}

.about__wrapper-info-title {
	margin-top: 5px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #3b4c68;
}

.about__wrapper-info-text {
	padding-top: 25px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	color: #424242;
}

.about__wrapper-info-contacts-title {
	margin-top: 55px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #3b4c68;
}

.about__wrapper-info-contacts-group {
	margin-top: 25px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.about__wrapper-info-contacts-group-element {
	display: flex;
	flex-direction: column;
}

.about__wrapper-info-contacts-group-element-title {
	margin-bottom: 9px;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}

.about__wrapper-info-contacts-group-element-link {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.5;
	color: #009ed1;
}

.about__wrapper-info-contacts-group-element-social {
	display: flex;
}

.about__wrapper-info-contacts-group-element-social-logo {
	margin-right: 9px;
	width: 30px;
	height: 30px;
	background: rgba(0, 158, 209, 0.15000000596046448);
	border-radius: 100%;
}

.mobile-about {
	display: none;
}

@media (max-width: 1200px) {
	.about__wrapper-info-contacts-group {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 15px;
	}

	.about__wrapper-info {
		margin-left: 40px;
	}
}

@media (max-width: 950px) {
	.about {
		padding-top: 112px;
	}
}

@media (max-width: 786px) {
	.about {
		padding-top: 48px;
	}

	.mobile-about {
		display: block;
	}

	.about__wrapper-caption {
		display: flex;
		align-items: center;
	}

	.about__wrapper-caption-back {
		width: 30px;
		height: 10px;
		background: url("~@/assets/img/about-company-page/about/back.svg");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.about__wrapper-caption-text {
		margin-left: auto;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		line-height: 35.5px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.about__wrapper {
		flex-direction: column;
		margin-top: 25px;
	}

	.about__wrapper-preview {
		margin: 17px auto 0 auto;
	}

	.about__wrapper-info {
		margin-top: 23px;
		margin-left: 0px;
		text-align: center;
	}

	.about__wrapper-info-contacts-group-element-social {
		justify-content: center;
	}
}

@media (max-width: 400px) {
	.about__wrapper-preview-representation {
		padding: 20px 17px 27px 17px;
		width: 100%;
		box-sizing: border-box;
	}

	.about__wrapper-preview-representation-more {
		margin-top: 20px;
	}

	.about__wrapper-preview-representation-employer {
		font-size: 24px;
	}

	.about__wrapper-preview-representation-designation {
		font-size: 16px;
	}

	.responses__wrapper-element-description-place {
		background-position-x: 4px;
	}

	.about__wrapper-preview-more-btn {
		width: 100%;
	}

	.about__wrapper-info {
		text-align: left;
	}

	.about__wrapper-info-title {
		font-size: 15px;
	}

	.about__wrapper-info-text {
		padding-top: 15px;
		font-size: 13px;
	}

	.about__wrapper-info-contacts-title {
		font-size: 15px;
		margin-top: 30px;
	}

	.about__wrapper-info-contacts-group {
		grid-template-columns: repeat(1, 1fr);
	}

	.about__wrapper-info-contacts-group-element-title {
		margin-bottom: 4px;
	}

	.about__wrapper-info-contacts-group-element-link {
		font-size: 17px;
	}

	.about__wrapper-info-contacts-group-element-social {
		justify-content: left;
	}
}

/* /About */

/* Responses */

.responses {
	padding-top: 41px;
}

.responses-caption {
	text-align: center;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #3b4c68;
}

.responses__wrapper {
	margin-top: 38px;
	display: grid;
	row-gap: 33px;
	grid-template-columns: repeat(3, 1fr);
}

.responses__more {
	width: 100%;
	display: flex;
}

.responses__more-btn {
	height: 60px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #009ed1;
	width: 234px;
	margin: 54px auto 0 auto;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.responses__more-btn:hover {
	opacity: 0.5;
}

.mobile {
	display: none;
}

@media (max-width: 1860px) {
	.responses__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 1370px) {
	.responses__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 850px) {
	.mobile {
		display: block;
	}

	.responses__wrapper {
		margin-top: 23px;
	}
}

@media (max-width: 640px) {
	.responses {
		padding-top: 21px;
	}

	.responses-caption {
		font-size: 15px;
	}

	.responses__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.responses__more-btn {
		width: 298px;
	}
}

@media (max-width: 330px) {
	.responses-caption {
		text-align: left;
	}
}

/* /Similar */

/* Application */
.application {
	margin-top: 106px;
	margin-bottom: 106px;
}

.application__wrapper {
	display: flex;
	width: 100%;
	background: #0b6c96;
	border-radius: 17px;
	height: 318px;
}

.application__wrapper-picture {
	width: 50%;
}

.application__wrapper-picture-img {
	background: #0b6c96;
	max-width: 557px;
	object-fit: cover;
	border-radius: 17px;
	height: 100%;
	width: 100%;
}

.application__wrapper-content {
	max-width: 50%;
	margin: auto 0;
}

.application__wrapper-content-shell {
	padding: 0 103px 0 63px;
}

.application__wrapper-content-shell-title {
	width: 100%;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 32px;
	line-height: 43px;
	color: #ffffff;
}

.application__wrapper-content-shell-text {
	padding-top: 15px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.5;
	color: #ffffff;
}

.application__wrapper-content-shell-send {
	display: block;
	padding: 24px;
	margin-top: 31px;
	background: #14c686;
	border: 1px solid #69e7b9;
	border-radius: 7px;
	width: 200px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.application__wrapper-content-send:hover {
	opacity: 0.5;
}

@media (max-width: 1086px) {
	.application__wrapper-content-shell {
		padding: 0 25px 0px 25px;
	}
}

@media (max-width: 854px) {
	.application__wrapper-content-shell {
		padding: 0 25px 0px 25px;
	}

	.application__wrapper-content-shell-send {
		width: 100%;
		box-sizing: border-box;
	}

	.application__wrapper-content-shell-title {
		font-size: 20px;
	}

	.application__wrapper-content-shell-text {
		font-size: 14px;
	}

	.application__wrapper-content-shell-send {
		font-size: 13px;
		padding: 16px;
	}
}

@media (max-width: 650px) {
	.application__wrapper-content-shell {
		padding: 0 25px 0px 25px;
	}

	.application__wrapper-content-shell-send {
		width: 100%;
		box-sizing: border-box;
	}
}

@media (max-width: 584px) {
	.application__wrapper {
		height: 386px;
		position: relative;
	}

	.application__wrapper-picture {
		position: absolute;
		bottom: -4px;
		z-index: 1;
		width: unset;
	}

	.application__wrapper-content-shell {
		padding: 29px 20px 0px 20px;
	}

	.application__wrapper-content-shell-send {
		width: 100%;
		box-sizing: border-box;
	}

	.application__wrapper-content {
		z-index: 2;
		max-width: unset;
		margin: 0;
		text-align: center;
	}
}

@media (max-width: 360px) {
	.application__wrapper-content {
		text-align: left;
	}

	.application__wrapper-content-shell-title {
		line-height: unset;
	}
}

/* /Application */
</style>
