<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<p class="responses__text mobile">{{
						$t('pages.account.account_worker.accountWorkerSeparateResponsePage.personalCabinet') }}</p>
					<h1 class="responses__title mobile">{{
						$t('pages.account.account_worker.accountWorkerSeparateResponsePage.myApplications') }}</h1>
					<div class="responses__caption desctop">
						<h2 class="responses__caption-title">{{
							$t('pages.account.account_worker.accountWorkerSeparateResponsePage.myApplications') }}</h2>
						<p class="responses__caption-text">{{ response.vacancyName ? response.vacancyName :
							$t('pages.account.account_worker.accountWorkerSeparateResponsePage.untitled') }}</p>
					</div>
					<button class="responses__caption-back mobile-back">
						{{ $t('pages.account.account_worker.accountWorkerSeparateResponsePage.loremIpsum') }}
					</button>
					<div class="responses__wrapper">
						<div class="responses__wrapper-company">
							<div class="responses__wrapper-company-representation">
								<img :src="response.companyAvatarUrl" alt=""
									class="responses__wrapper-company-representation-icon" />
								<p class="responses__wrapper-company-representation-employer">
									{{ response.companyName ? response.companyName :
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.untitled') }}
								</p>
								<p class="responses__wrapper-company-representation-designation">{{
									$t('pages.account.account_worker.accountWorkerSeparateResponsePage.status') }}</p>
								<router-link
									:to="{ name: 'employerProfile', params: { summaryId: response.companyId } }"
									class="responses__wrapper-company-representation-more">
									<button>{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.moreAboutEmployer')
										}}</button>
								</router-link>
								<router-link
									:to="{ name: 'interview', params: { interviewId: response.responseInterviewId } }">
									<button class="responses__wrapper-company-contact"
										v-if="this.response.responseStatus === 'invite'">
										{{
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.passInterview')
										}}
									</button>
								</router-link>
							</div>
							<div class="responses__wrapper-company-status">
								<div class="responses__wrapper-company-status-caption">
									<p class="responses__wrapper-company-status-caption-name">{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.status') }}
									</p>
									<p class="responses__wrapper-company-status-caption-date">
										{{ response.responseDate ? new
											Date(response.responseDate).toISOString().slice(0, 10) :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.noData') }}
									</p>
								</div>
								<p class="responses__wrapper-company-status-info" :class="{
									'suggestions-work-gray': this.response.responseStatus === 'new',
									'suggestions-work-red': this.response.responseStatus === 'reject',
									'suggestions-work-gray': this.response.responseStatus === 'passed',
								}">
									{{ status }}
								</p>
							</div>
							<router-link
								:to="{ name: 'interview', params: { interviewId: response.responseInterviewId } }">
								<button class="responses__wrapper-company-contact"
									v-if="this.response.responseStatus === 'invite'">
									{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.passInterview')
									}}
								</button>
							</router-link>
							<router-link
								:to="{ name: 'passingInterviewCandidate', params: { passingId: response.passingId, status: 'view' } }">
								<button class="responses__wrapper-company-contact"
									v-if="this.response.responseStatus === 'checked'">
									{{ $t('pages.account.account_worker.accountWorkerSeparateResponsePage.watchResult')
									}}
								</button>
							</router-link>
						</div>
						<div class="responses__wrapper-info">
							<h3 class="responses__wrapper-info-title">{{ response.vacancyName ? response.vacancyName :
								$t('pages.account.account_worker.accountWorkerSeparateResponsePage.untitled') }}</h3>
							<div class="responses__wrapper-info-list">
								<div class="responses__wrapper-info-list-element">
									<p class="responses__wrapper-info-list-element-name">{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.schedule') }}
									</p>
									<p class="responses__wrapper-info-list-element-information">{{
										response.vacancyEmployType ? response.vacancyEmployType :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.undefined')
										}}</p>
								</div>
								<div class="responses__wrapper-info-list-element">
									<p class="responses__wrapper-info-list-element-name">{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.experience')
										}}</p>
									<p class="responses__wrapper-info-list-element-information">{{
										response.vacancyWorkExperience ? response.vacancyWorkExperience :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.undefined')
										}}</p>
								</div>
								<div class="responses__wrapper-info-list-element">
									<p class="responses__wrapper-info-list-element-name">{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.education')
										}}</p>
									<p class="responses__wrapper-info-list-element-information">{{
										response.vacancyEducation ? response.vacancyEducation :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.undefined')
										}}</p>
								</div>
								<div class="responses__wrapper-info-list-element">
									<p class="responses__wrapper-info-list-element-name">{{
										$t('pages.account.account_worker.accountWorkerSeparateResponsePage.wage') }}</p>
									<p class="responses__wrapper-info-list-element-information text-blue">{{
										response.vacancyPrice ? response.vacancyPrice + '$' :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.undefined')
										}}</p>
								</div>
							</div>
							<p class="responses__wrapper-info-text">{{ response.vacancyDescription ?
								response.vacancyDescription :
								$t('pages.account.account_worker.accountWorkerSeparateResponsePage.noDescription') }}
							</p>
							<div class="responses__wrapper-info-message">
								<p class="responses__wrapper-info-message-caption">{{
									$t('pages.account.account_worker.accountWorkerSeparateResponsePage.yourMessage') }}
								</p>
								<div class="responses__wrapper-info-message-filling">
									<p class="responses__wrapper-info-message-filling-text">{{
										response.responseCoverLetter ? response.responseCoverLetter :
											$t('pages.account.account_worker.accountWorkerSeparateResponsePage.letterIsEmpty')
										}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import { config } from "@/lib/config";

export default {
	components: { MenuWorker, HeaderAccountWorker },
	props: {
		responseId: { type: String },
		category: { type: String },
	},
	name: "AccountWorkerSeparateResponsePage",
	data() {
		return {
			response: {},
			status: null,
		};
	},
	beforeMount() {
		let xmlHttp = new XMLHttpRequest();
		let url = config.apiPrefix + "candidate/response/get/" + this.responseId;
		xmlHttp.open("GET", url, false);
		xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
		try {
			xmlHttp.send();
			let response = JSON.parse(xmlHttp.response);
			this.response = response.data;
		} catch (e) {
			console.log(e);
		}
		scroll(0, 0);
		switch (this.response.responseStatus) {
			case "new":
				this.status = this.$t('pages.account.account_worker.accountWorkerSeparateResponsePage.pendingVerification');
				break;
			case "reject":
				this.status = this.$t('pages.account.account_worker.accountWorkerSeparateResponsePage.dismissed');
				break;
			case "invite":
				this.status = this.$t('pages.account.account_worker.accountWorkerSeparateResponsePage.invitationForInterview');
				break;
			case "passed":
				this.status = this.$t('pages.account.account_worker.accountWorkerSeparateResponsePage.waitingForResults');
				break;
			case "checked":
				this.status = this.$t('pages.account.account_worker.accountWorkerSeparateResponsePage.interviewIsVerified');
				break;
		}
	},
};
</script>



<style scoped>
.account {
	display: flex;
}

.mobile {
	display: none;
}

.responses__text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.responses__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.responses__caption {
	display: flex;
	align-items: center;
}

.responses__caption-title {
	position: relative;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.responses__caption-title::after {
	position: absolute;
	top: 7%;
	right: -25px;
	content: "/";
	display: inline-block;
	width: 10px;
	height: 32px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
	opacity: 0.29;
}

.responses__caption-text {
	padding-left: 48px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	color: #009ed1;
}

.responses__wrapper {
	margin-top: 50px;
	display: flex;
}

.responses__wrapper-company {
	max-width: 337px;
}

.responses__wrapper-company-representation {
	padding: 35px 22px 25px 22px;
	width: 290px;
	background: #ffffff;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
}

.responses__wrapper-company-representation-icon {
	width: 90px;
	height: 90px;
	object-fit: cover;
	border-radius: 100%;
	margin: 0 auto;
}

.responses__wrapper-company-representation-employer {
	padding-top: 20px;
	text-align: center;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #3b4c68;
}

.responses__wrapper-company-representation-designation {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #b7c1d1;
}

.responses__wrapper-company-representation-more {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 46px;
	height: 50px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
	background: #f1f1f5;
	border-radius: 10px;
	transition: opacity 1s ease;
}

.responses__wrapper-company-representation-more:hover {
	opacity: 0.5;
}

.responses__wrapper-company-representation-more button {
	width: 100%;
	padding: 10px;
	border: none;
	background: inherit;
	cursor: pointer;
}


.responses__wrapper-company-status {
	margin-top: 21px;
	padding: 16px 22px 17px 22px;
	background: #ffffff;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
}

.responses__wrapper-company-status-caption {
	display: flex;
	align-items: center;
}

.responses__wrapper-company-status-caption-name {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.responses__wrapper-company-status-caption-date {
	margin-left: auto;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
	opacity: 0.4;
}

.responses__wrapper-company-status-info {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 11px;
	width: 100%;
	height: 29px;
	background: rgba(184, 242, 193, 0.20000000298023224);
	border-radius: 20px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #44c194;
}

.responses__wrapper-company-contact {
	margin-top: 21px;
	width: 100%;
	height: 50px;
	background: #14c686;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.responses__wrapper-company-contact:hover {
	opacity: 0.5;
}

.responses__wrapper-info {
	max-width: 1064px;
	margin-left: 80px;
	width: 100%;
	box-sizing: border-box;
}

.responses__wrapper-info-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 32px;
	line-height: 43px;
	color: #000000;
}

.responses__wrapper-info-list {
	margin-top: 35px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.responses__wrapper-info-list-element-name {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
}

.responses__wrapper-info-list-element-information {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #000000;
}

.text-blue {
	color: #009ed1;
}

.responses__wrapper-info-text {
	margin-top: 35px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.89;
	color: #424242;
}

.responses__wrapper-info-message {
	margin-top: 45px;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
}

.responses__wrapper-info-message-caption {
	padding: 15px 0 15px 37px;
	background: #f6f6f8;
	border-radius: 20px 20px 0px 0px;
	align-items: center;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 15px;
	line-height: 1.6;
	color: #2f2f2f;
}

.responses__wrapper-info-message-filling {
	padding: 33px 31px 38px 37px;
	border-radius: 0px 0px 20px 20px;
	background: #fff;
}

.responses__wrapper-info-message-filling-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 24px;
	line-height: 1.6;
	color: #2f2f2f;
}

.responses__wrapper-info-message-filling-text {
	padding-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 19px;
	line-height: 1.6;
	color: #424242;
}

.responses__wrapper-info-message-filling-file {
	margin-top: 40px;
	width: 242px;
	background: #e6f6fc;
	border-radius: 7px;
}

.responses__wrapper-info-message-filling-file-text {
	background: url("~@/assets/img/responses-page/responses/file.png");
	background-position: 16px;
	background-repeat: no-repeat;

	padding: 11px 17px 11px 47px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	color: #009ed1;
}

.mobile-back {
	display: none;
}

@media (max-width: 1470px) {
	.responses__wrapper-info-list {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 20px;
	}
}

@media (max-width: 1180px) {
	.responses__wrapper-info {
		margin-left: 40px;
	}
}

@media (max-width: 850px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.responses {
		padding-top: 28px;
	}

	.responses__text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
		text-align: center;
	}

	.responses__title {
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
		text-align: center;
	}

	.responses__wrapper {
		margin-top: 28px;
		flex-direction: column;
	}

	.responses__wrapper-company {
		margin: 0 auto;
	}

	.responses__wrapper-info {
		margin-top: 46px;
		margin-left: 0;
	}

	.responses__wrapper-info-title {
		text-align: center;
		font-size: 25px;
	}

	.responses__wrapper-info-list {
		text-align: center;
	}

	.responses__wrapper-info-text {
		font-size: 15px;
		text-align: center;
	}
}

@media (max-width: 370px) {
	.mobile-back {
		display: block;
	}

	.responses__caption-back {
		margin-top: 10px;
		background: url("~@/assets/img/responses-page/responses/back.svg");
		background-position: 0;
		background-repeat: no-repeat;
		margin-top: 10px;
		padding-left: 32px;
		font-family: "Montserrat";
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #009ed1;
	}

	.responses__wrapper-company {
		width: 100%;
	}

	.responses__wrapper-company-representation {
		padding: 20px 17px 27px 17px;
		width: 100%;
		box-sizing: border-box;
	}

	.responses__wrapper-company-representation-more {
		margin-top: 20px;
	}

	.responses__wrapper-company-contact {
		height: 42px;
	}

	.responses__text {
		text-align: left;
	}

	.responses__title {
		text-align: left;
	}

	.responses__wrapper-info-title {
		text-align: left;
	}

	.responses__wrapper-info-list {
		text-align: left;
	}

	.responses__wrapper-info-list-element-name {
		font-size: 10px;
	}

	.responses__wrapper-info-list-element-information {
		font-size: 11px;
	}

	.responses__wrapper-info-text {
		text-align: left;
	}

	.responses__wrapper-info-message {
		margin-top: 30px;
	}

	.responses__wrapper-info-message-caption {
		text-align: center;
		padding-left: 0;
		font-size: 11px;
		padding-top: 11px;
		padding-bottom: 11px;
	}

	.responses__wrapper-info-message-filling {
		padding: 20px 15px 23px 15px;
	}

	.responses__wrapper-info-message-filling-title {
		font-size: 15px;
	}

	.responses__wrapper-info-message-filling-text {
		font-size: 13px;
		padding-top: 15px;
	}

	.responses__wrapper-info-message-filling-file {
		width: 100%;
		margin-top: 30px;
	}

	.responses__wrapper-info-message-filling-file-text {
		text-align: left;
	}
}

.suggestions-work-red {
	background: #f9eeee;
	color: #ab3e5e;
}

.suggestions-work-green {
	background: #14c686;
}

.suggestions-work-gray {
	background: #f1f1f5;
	color: #696974;
}
</style>
