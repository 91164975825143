<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="container-account tariff-container">
				<p class="tariff__text mobile">{{ $t('pages.landing.tariffPage.tariffPage.personalCabinet') }}</p>
				<h1 class="tariff__title">{{ $t('pages.landing.tariffPage.tariffPage.pricing') }}</h1>
				<p class="tariff__text desctop">{{ $t('pages.landing.tariffPage.tariffPage.welcome') }}</p>
				<div class="tariff__group">
					<div class="tariff__group-element">
						<span class="tariff__group-element-deoartion-1-1"></span>
						<span class="tariff__group-element-deoartion-1-2"></span>
						<span class="tariff__group-element-deoartion-1-3"></span>
						<h2 class="tariff__group-element-title">{{ $t('pages.landing.tariffPage.tariffPage.description1') }}</h2>
						<p class="tariff__group-element-text">{{ $t('pages.landing.tariffPage.tariffPage.text1') }}</p>
						<p class="tariff__group-element-price">{{ $t('pages.landing.tariffPage.tariffPage.price') }}</p>
						<div class="tariff__group-element-buy">
							<button class="tariff__group-element-buy-btn">{{ $t('pages.landing.tariffPage.tariffPage.buyTariff')
								}}</button>
						</div>
					</div>
					<div class="tariff__group-element turquoise-bg">
						<span class="tariff__group-element-deoartion-2-1"></span>
						<span class="tariff__group-element-deoartion-2-2"></span>
						<span class="tariff__group-element-deoartion-2-3"></span>
						<h2 class="tariff__group-element-title">{{ $t('pages.landing.tariffPage.tariffPage.description1') }}</h2>
						<p class="tariff__group-element-text">{{ $t('pages.landing.tariffPage.tariffPage.text1') }}</p>
						<p class="tariff__group-element-price">{{ $t('pages.landing.tariffPage.tariffPage.price') }}</p>
						<div class="tariff__group-element-buy turquoise-price">
							<button class="tariff__group-element-buy-btn turquoise-btn">{{
								$t('pages.landing.tariffPage.tariffPage.buyTariff') }}</button>
						</div>
					</div>
					<div class="tariff__group-element orange-bg">
						<span class="tariff__group-element-deoartion-3-1"></span>
						<span class="tariff__group-element-deoartion-3-2"></span>
						<span class="tariff__group-element-deoartion-3-3"></span>
						<h2 class="tariff__group-element-title">{{ $t('pages.landing.tariffPage.tariffPage.description1') }}</h2>
						<p class="tariff__group-element-text">{{ $t('pages.landing.tariffPage.tariffPage.text1') }}</p>
						<p class="tariff__group-element-price orange-price">{{ $t('pages.landing.tariffPage.tariffPage.price') }}</p>
						<div class="tariff__group-element-buy">
							<button class="tariff__group-element-buy-btn orange-btn">{{ $t('pages.landing.tariffPage.tariffPage.buyTariff')
								}}</button>
						</div>
					</div>
				</div>
			</div>
		</main>

		<Footer></Footer>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";

export default {
	components: { Footer, Header },

	name: "TariffPage",
	beforeMount() {
		scroll(0, 0);
	},
};
</script>

<style scoped>
.tariff-container {
	padding-bottom: 80px;
}

.mobile {
	display: none;
}

.tariff__text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #b7c1d1;
}

.tariff__title {
	text-align: center;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.tariff__group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.tariff__group-element {
	margin-top: 85px;
	position: relative;
	background: #f1f5f9;
	border: 1px solid #efeff4;
	border-radius: 9px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
	padding: 42px;
	max-width: 75%;
}

.turquoise-bg {
	background: #f1f8f9;
}

.orange-bg {
	background: #f9f4f1;
}

.tariff__group-element-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 1.64;
	color: #3b4c68;
}

.tariff__group-element-text {
	padding-top: 29px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0.25px;
	color: #464655;
	opacity: 0.8;
}

.tariff__group-element-price {
	padding-top: 56px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 60px;
	line-height: 1.64;
	color: #5fa2e4;
}

.turquoise-price {
	color: #69b8c3;
}

.orange-price {
	color: #e4b14c;
}

.tariff__group-element-buy-btn {
	margin-top: 71px;
	width: 100%;
	height: 65px;
	background: #5fa1e4;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.tariff__group-element-buy-btn:hover {
	opacity: 0.5;
}

.turquoise-btn {
	background: #69b8c3;
}

.orange-btn {
	background: #e4b04c;
}

.tariff__group-element-deoartion-1-1 {
	position: absolute;
	width: 54px;
	height: 54px;
	background: url("~@/assets/img/account-img/tariff/decoration-1.svg");
	background-position: center;
	background-repeat: no-repeat;
	top: 98px;
	right: 459px;
}

.tariff__group-element-deoartion-1-2 {
	position: absolute;
	width: 52px;
	height: 57px;
	background: url("~@/assets/img/account-img/tariff/decoration-2.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	top: 240px;
	right: -33px;
}

.tariff__group-element-deoartion-1-3 {
	position: absolute;
	width: 59px;
	height: 59px;
	background: url("~@/assets/img/account-img/tariff/decoration-3.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	bottom: 298px;
	left: -27px;
}

.tariff__group-element-deoartion-2-1 {
	position: absolute;
	width: 40px;
	height: 39px;
	background: url("~@/assets/img/account-img/tariff/decoration-4.svg");
	background-position: center;
	background-repeat: no-repeat;
	top: -14px;
	right: 9px;
}

.tariff__group-element-deoartion-2-2 {
	position: absolute;
	width: 40px;
	height: 42px;
	background: url("~@/assets/img/account-img/tariff/decoration-5.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	top: 72px;
	left: -21px;
}

.tariff__group-element-deoartion-2-3 {
	position: absolute;
	width: 95px;
	height: 94px;
	background: url("~@/assets/img/account-img/tariff/decoration-6.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	top: 275px;
	right: -50px;
}

.tariff__group-element-deoartion-3-1 {
	position: absolute;
	width: 115px;
	height: 116px;
	background: url("~@/assets/img/account-img/tariff/decoration-7.svg");
	background-position: center;
	background-repeat: no-repeat;
	top: -30px;
	right: -47px;
}

.tariff__group-element-deoartion-3-2 {
	position: absolute;
	width: 52px;
	height: 51px;
	background: url("~@/assets/img/account-img/tariff/decoration-8.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	top: 176px;
	left: -24px;
}

.tariff__group-element-deoartion-3-3 {
	position: absolute;
	width: 25px;
	height: 25px;
	background: url("~@/assets/img/account-img/tariff/decoration-9.svg");
	background-repeat: repeat;
	background-position: center;
	background-repeat: no-repeat;
	top: 298px;
	right: -10px;
}

@media (max-width: 1900px) {
	.tariff__group {
		display: flex;
		padding: 0 100px;
		flex-wrap: wrap;
	}

	.tariff__group-element {
		margin: 85px auto 0 auto;
		max-width: 375px;
	}

	.tariff__text {
		text-align: center;
	}

	.tariff__title {
		text-align: center;
	}
}

@media (max-width: 1520px) {
	.tariff__group-element {
		margin: 85px auto 0 auto;
		max-width: 398px;
	}
}

@media (max-width: 1379px) {
	.tariff__group {
		display: flex;
		flex-wrap: wrap;
	}

	.tariff__group-element {
		margin: 85px auto 0 auto;
		max-width: 375px;
	}

	.tariff__group-element-deoartion-1-1 {
		left: -20px;
	}
}

@media (max-width: 1250px) {
	.tariff__group {
		display: grid;
		padding: 0;
		grid-template-columns: repeat(1, 1fr);
	}

	.tariff__group-element {
		margin: 40px auto 0 auto;
		max-width: 398px;
	}
}

@media (max-width: 767px) {
	.profile-container {
		margin: 0 auto 0 auto;
	}

	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.tariff__text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.tariff__title {
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.tariff__group {
		margin-top: 11px;
	}

	.tariff__group-element {
		padding: 23px 16px 30px 16px;
		margin: 17px auto 0 auto;
	}

	.tariff__group-element-title {
		font-size: 15px;
	}

	.tariff__group-element-text {
		font-size: 12px;
	}

	.tariff__group-element-price {
		padding-top: 26px;
		line-height: 50px;
		font-size: 50px;
	}

	.tariff__group-element-buy {
		margin-top: 32px;
	}

	.tariff__group-element-buy-btn {
		margin-top: 0;
		font-size: 13px;
		border-radius: 10px;
		height: 42px;
	}

	.tariff__group-element-deoartion-1-1 {
		top: 16px;
		left: unset;
		right: -10px;
		width: 18px;
		height: 18px;
		background-size: cover;
	}

	.tariff__group-element-deoartion-3-1 {
		width: 31px;
		height: 42px;
		top: 1px;
		right: -9px;
		background-size: cover;
	}

	.tariff__group-element-deoartion-3-2 {
		top: 114px;
	}
}

@media (max-width: 520px) {
	.tariff__group-element {
		max-width: 300px;
	}
}

@media (max-width: 340px) {
	.tariff__text {
		text-align: left;
	}

	.tariff__title {
		text-align: left;
	}
}
</style>