<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>

				<div class="container-account">
					<div class="filling__caption">
						<div class="filling__caption-head">
							<div class="filling__caption-head-greetings">
								<p class="filling__caption-head-greetings-text mobile">
									{{ $t('pages.account.account_worker.accountWorkerRatingPage.personalCabinet') }}
								</p>
								<h1 class="filling__caption-head-greetings-title">
									{{ $t('pages.account.account_worker.accountWorkerRatingPage.personalRating') }}
								</h1>
								<p class="filling__caption-head-greetings-text desctop-rating">
									{{ $t('pages.account.account_worker.accountWorkerRatingPage.welcome') }}
								</p>
							</div>
							<SearchElement :role="$store.getters.role">
							</SearchElement>
						</div>
						<div class="filling__caption-wrapp desctop">
							<div class="filling__caption-wrapp-statistics">
								<div class="filling__caption-wrapp-statistics-circle">
									<div class="filling__caption-wrapp-statistics-circle-description">
										<h5 class="filling__caption-wrapp-statistics-circle-description-title">
											{{ $t('pages.account.account_worker.accountWorkerRatingPage.rating') }}
										</h5>
										<p class="filling__caption-wrapp-statistics-circle-description-text">
											{{
												$t('pages.account.account_worker.accountWorkerRatingPage.ratingDescription')
											}}
										</p>
									</div>
									<p class="filling__caption-wrapp-statistics-circle-rating">
										<span>
											{{ dataWorker.score }}
										</span>
									</p>
								</div>
								<span class="filling__caption-wrapp-statistics-border"></span>
								<div class="filling__caption-wrapp-statistics-graph">
									<h2 class="filling__caption-wrapp-statistics-graph-title">
										{{ $t('pages.account.account_worker.accountWorkerRatingPage.statistics') }}
									</h2>
									<div class="filling__caption-wrapp-statistics-graph-group">
										<div class="filling__caption-wrapp-statistics-graph-group-element">
											<div class="filling__caption-wrapp-statistics-graph-group-element-text">
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-category">
													{{
														$t('pages.account.account_worker.accountWorkerRatingPage.levelOfEducation')
													}}
												</p>
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-points">
													{{ dataScore.educationScore }} {{
														$t('pages.account.account_worker.accountWorkerRatingPage.points') }}
												</p>
											</div>
											<div class="filling__caption-wrapp-statistics-graph-group-element-field">
												<div class="filling__caption-wrapp-statistics-graph-group-element-fill-repletion"
													:style="{ width: dataScore.educationScore + '%' }"></div>
											</div>
										</div>
										<div class="filling__caption-wrapp-statistics-graph-group-element">
											<div class="filling__caption-wrapp-statistics-graph-group-element-text">
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-category">
													{{
														$t('pages.account.account_worker.accountWorkerRatingPage.technicalSkills')
													}}
												</p>
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-points">
													{{ dataScore.techScore }} {{
														$t('pages.account.account_worker.accountWorkerRatingPage.points') }}
												</p>
											</div>
											<div class="filling__caption-wrapp-statistics-graph-group-element-field">
												<div class="filling__caption-wrapp-statistics-graph-group-element-fill-repletion"
													:style="{ width: dataScore.techScore + '%' }"></div>
											</div>
										</div>
										<div class="filling__caption-wrapp-statistics-graph-group-element">
											<div class="filling__caption-wrapp-statistics-graph-group-element-text">
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-category">
													{{
														$t('pages.account.account_worker.accountWorkerRatingPage.professionalTesting')
													}}
												</p>
												<p
													class="filling__caption-wrapp-statistics-graph-group-element-text-points">
													{{ dataScore.profTestScore }} {{
														$t('pages.account.account_worker.accountWorkerRatingPage.points') }}
												</p>
											</div>
											<div class="filling__caption-wrapp-statistics-graph-group-element-field">
												<div class="filling__caption-wrapp-statistics-graph-group-element-fill-repletion"
													:style="{ width: dataScore.profTestScore + '%' }"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="filling__caption-wrapp-test">
								<div class="filling__caption-wrapp-test-description">
									<h5 class="filling__caption-wrapp-test-description-title">
										{{
											$t('pages.account.account_worker.accountWorkerRatingPage.personalRatingDescription')
										}}
									</h5>
									<p class="filling__caption-wrapp-test-description-text">
										{{ $t('pages.account.account_worker.accountWorkerRatingPage.personalRatingText')
										}}
									</p>
									<div class="filling__caption-wrapp-test-description-start">
										<button @click="$router.push({ name: 'testing' })"
											class="filling__caption-wrapp-test-description-start-btn">
											{{ $t('pages.account.account_worker.accountWorkerRatingPage.getTested') }}
										</button>
									</div>
								</div>
								<div class="filling__caption-wrapp-test-roundabout">
									<div class="filling__caption-wrapp-test-roundabout-caption">
										<p class="filling__caption-wrapp-test-roundabout-caption-number">
											{{ carousel ? iter + 1 + "/8" : "" }}
										</p>
										<div class="filling__caption-wrapp-test-roundabout-caption-strip">
											<div class="filling__caption-wrapp-test-roundabout-caption-strip-filling"
												:style="{ maxWidth: (iter + 1) * 12.5 + '%' }"></div>
										</div>
										<div class="test__top-save-roundabout-caption-btns">
											<button class="test__top-save-roundabout-caption-btns-element-back"
												@click="prevIter"></button>
											<button class="test__top-save-roundabout-caption-btns-element-next"
												@click="nextIter"></button>
										</div>
									</div>
									<div class="filling__caption-wrapp-test-roundabout-progress">
										<div class="test__top-save-roundabout-right-img" :class="{
											'roundabout-right-2': iter === 1,
											'roundabout-right-3': iter === 2,
											'roundabout-right-4': iter === 3,
											'roundabout-right-5': iter === 4,
											'roundabout-right-6': iter === 5,
											'roundabout-right-7': iter === 6,
											'roundabout-right-8': iter === 7
										}"></div>
									</div>
									<div class="filling__caption-wrapp-test-roundabout-description">
										<h5 class="filling__caption-wrapp-test-roundabout-description-title">
											{{ carousel ? carousel.header : "" }}
										</h5>
										<p class="filling__caption-wrapp-test-roundabout-description-text">
											{{ carousel ? carousel.body : "" }}
										</p>
									</div>
									<button class="filling__caption-wrapp-test-roundabout-description-start-btn"
										@click="$router.push({ name: 'testing' })">
										{{ $t('pages.account.account_worker.accountWorkerRatingPage.getTested') }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import SearchElement from "../../../components/account/SearchElement.vue";
import { config } from "@/lib/config";

export default {
	components: { MenuWorker, HeaderAccountWorker, SearchElement },
	data() {
		return {
			dataWorker: {},
			dataScore: null
		};
	},
	methods: {
		nextIter() {
			this.$store.commit("carouselIncrementRating");
			clearInterval(this.carouselTimer);
			this.carouselTimer = setInterval(() => {
				this.$store.commit("carouselIncrementRating");
			}, 7000);
		},
		prevIter() {
			this.$store.commit("previterRatingRating");
			clearInterval(this.carouselTimer);
			this.carouselTimer = setInterval(() => {
				this.$store.commit("carouselIncrementRating");
			}, 7000);
		},
		fetchDataWorker() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "Select";
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.dataWorker = response.data;
			} catch (e) {
				console.log(e);
			}
		},
		fetchDataScore() {
			const req = new XMLHttpRequest();
			const url = config.apiPrefix + "candidate/rate";
			req.open("GET", url, false);
			req.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				req.send();
				const res = JSON.parse(req.response);
				this.dataScore = res.data;
			} catch (e) {
				console.log(e);
			}
		}
	},
	name: "AccountWorkerRatingPage",
	computed: {
		carousel() {
			return this.$store.getters.carouselRating;
		},
		iter() {
			return this.$store.getters.iterRating;
		},
		translatedCarouselTextRating(){
			let data = [
				{
					header: this.$t('pages.landing.homePage.carouselRating.slides[0].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[0].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[1].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[1].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[2].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[2].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[3].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[3].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[4].header'),
					body:this.$t('pages.landing.homePage.carouselRating.slides[4].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[5].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[5].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[6].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[6].body')
				},
				{
					header:  this.$t('pages.landing.homePage.carouselRating.slides[7].header'),
					body: this.$t('pages.landing.homePage.carouselRating.slides[7].body')
				},
			];
			return data;
		}
	},
	beforeMount() {
		scroll(0, 0);
		this.fetchDataWorker();
		this.fetchDataScore();
		this.$store.dispatch("fetchCarouselTextRating", this.translatedCarouselTextRating);
		this.carouselTimer = setInterval(() => {
			this.$store.commit("carouselIncrementRating");
		}, 7000);
	}
};
</script>


<style scoped>
.account {
	display: flex;
}

.mobile {
	display: none;
}

.container-account {
	overflow: visible;
}

.filling__caption {
	width: 100%;
}

.filling__caption-head {
	display: flex;
	align-items: center;
}

.filling__caption-head-greetings {
	margin: auto 0;
}

.filling__caption-head-greetings-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.filling__caption-head-greetings-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.filling__caption-head-surch {
	margin-left: auto;
	position: relative;
}

.filling__caption-head-surch-entry {
	height: 55px;
	margin: auto 0;
	width: 307px;
	padding-left: 33px;
	align-items: center;
	background: #ffffff;
	border: 1px solid #f0f0f0;
	border-radius: 100px;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #171725;
	padding-right: 60px;
}

.filling__caption-head-surch-entry::placeholder {
	color: #b7c1d1;
}

.filling__caption-head-surch-find {
	width: 36px;
	height: 36px;
	top: 9px;
	position: absolute;
	display: inline-block;
	right: 10px;
	background-repeat: no-repeat;
	background: url("~@/assets/img/profile-page/caption/head-surch.png");
}

.filling__caption-wrapp {
	margin-top: 40px;
	flex-direction: column;
	display: flex;
}

.filling__caption-wrapp-statistics {
	width: 100%;
	border: 1px solid #ebedf0;
	box-sizing: border-box;
	background: #fff;
	border-radius: 20px;
	display: flex;
	padding: 29px 23px 21px 45px;
}

.filling__caption-wrapp-statistics-graph {
	padding-left: 43px;
	width: 100%;
}

.filling__caption-wrapp-statistics-graph-title {
	font-family: "Montserrat";
	margin-bottom: 17px;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group {
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.filling__caption-wrapp-statistics-graph-group-element {
	margin-top: 15px;
}

.filling__caption-wrapp-statistics-graph-group-element-text {
	display: flex;
}

.filling__caption-wrapp-statistics-graph-group-element-text-category {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group-element-text-points {
	margin-left: auto;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;
}

.filling__caption-wrapp-statistics-graph-group-element-field {
	margin-top: 17px;
	width: 100%;
	height: 10px;
	background: #e3f0ff;
	border-radius: 69px;
}

.filling__caption-wrapp-statistics-graph-group-element-fill-repletion {
	max-width: 100%;
	height: 100%;
	border-radius: 69px;
	background: #009ed1;
}

.filling__caption-wrapp-statistics-circle {
	width: 100%;
	box-sizing: border-box;
	padding-right: 17px;
	display: flex;
}

.filling__caption-wrapp-statistics-circle-description {
	width: 100%;
	box-sizing: border-box;
	padding-right: 20px;
}

.filling__caption-wrapp-statistics-circle-description-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #3b4c68;
}

.filling__caption-wrapp-statistics-circle-description-grade {
	padding-top: 24px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 29px;
	line-height: 35px;
	color: #14c686;
}

.filling__caption-wrapp-statistics-circle-description-text {
	padding-top: 15px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.7;
	color: #3b4c68;
	opacity: 0.51;
}

.filling__caption-wrapp-statistics-circle-rating {
	padding: 50px 46px;
	background: #f3f3f3;
	font-family: "Poppins";
	font-weight: 700;
	font-size: 48px;
	border-radius: 100%;
	line-height: 48px;
	margin: auto;
	text-align: center;
	color: #14c686;
	width: 56px;
	height: 48px;
	flex-shrink: 0;
}

.filling__caption-wrapp-statistics-circle-rating span {
	display: flex;
	justify-content: center;
}

.filling__caption-wrapp-statistics-border {
	margin-left: auto;
	width: 2px;
	background: url("~@/assets/img/profile-page/caption/border.png");
}

.test__top-save-roundabout-caption-btns {
	width: 50px;
	margin-left: 20px;
	flex-shrink: 0;
}

.test__top-save-roundabout-caption-btns-element-back {
	padding: 10px;
	background: url("~@/assets/img/landing-img/main-page/randabout/last.svg");
	background-repeat: no-repeat;
	background-position-y: 3px;
	cursor: pointer;
}

.test__top-save-roundabout-caption-btns-element-next {
	padding: 10px;
	background: url("~@/assets/img/landing-img/main-page/randabout/next.svg");
	background-repeat: no-repeat;
	background-position-y: 3px;
	background-position-x: 14px;
	cursor: pointer;
}

@media (max-width: 1360px) {
	.filling__caption-wrapp-statistics-graph-title {
		padding-top: 4px;
	}
}


/* Test */

.filling__caption-wrapp-test {
	margin-top: 26px;
	padding: 29px 23px 21px 45px;
	display: flex;
	background: #fff;
	border: 1px solid #ebedf0;
	border-radius: 20px;
	width: 100%;
	box-sizing: border-box;
}

.filling__caption-wrapp-test-description {
	position: relative;
	padding-right: 43px;
	width: 100%;
	box-sizing: border-box;
}

.filling__caption-wrapp-test-description-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	line-height: 1.51;
	color: #3b4c68;
}

.filling__caption-wrapp-test-description-text {
	padding-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.7;
	color: #3b4c68;
	opacity: 0.51;
}

.filling__caption-wrapp-test-description-start-btn {
	position: absolute;
	bottom: 0px;
	margin-top: 30px;
	border-radius: 7px;
	width: calc(100% - 47px);
	box-sizing: border-box;
	background: #14c686;
	text-align: center;
	font-family: "Roboto";
	padding: 23px 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	color: #ffffff;
	transition: opacity 1s ease;
}

.filling__caption-wrapp-test-description-start-btn:hover {
	opacity: 0.5;
}

/* /Test */

/* Roundabout */

.filling__caption-wrapp-test-roundabout {
	max-width: 688px;
	width: 90%;
	padding: 28px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	min-height: 330px;
	background: linear-gradient(141.3deg, #0b6c96 27.21%, #70d4ff 100%);
	border: 1px solid rgba(132, 176, 190, 0.4);
	border-radius: 9px;
	backdrop-filter: blur(24px);
	position: relative;
	height: 440px;
	overflow: hidden;
}

.filling__caption-wrapp-test-roundabout-caption {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
}

.filling__caption-wrapp-test-roundabout-caption-number {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 13px;
	color: #ffffff;
	opacity: 0.7;
}

.filling__caption-wrapp-test-roundabout-caption-strip {
	margin-left: 32px;
	width: 100%;
	box-sizing: border-box;
	height: 5px;
	background: #eef0f3;
	border-radius: 69px;
}

.filling__caption-wrapp-test-roundabout-caption-strip-filling {
	height: 100%;
	background: #009ed1;
	border-radius: 69px;
}

.filling__caption-wrapp-test-roundabout-progress {
	display: flex;
	margin: 34px 0 0 0;
	height: 179px;
	justify-content: space-around;
}

.test__top-save-roundabout-right-img {
	position: absolute;
	content: "";
	background: url("~@/assets/img/landing-img/main-page/randabout/rating-group1.svg");
	background-repeat: no-repeat;
	width: 100%;
	display: block;
	width: 383px;
	height: 157px;
	left: calc(50% - 191px);
	background-size: cover;
	transition: background 1s linear;
}

.roundabout-right-2 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-2-mobile.svg");
	background-repeat: no-repeat;
	width: 282px;
	height: 193px;
	left: calc(50% - 141px);
	top: 60px;
	background-size: cover;
}

.roundabout-right-3 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-3-mobile.svg");
	background-repeat: no-repeat;
	width: 412px;
	top: 0px;
	left: calc(50% - 206px);
	top: 71px;
	height: 197px;
	background-size: cover;
}

.roundabout-right-4 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-4-mobile.svg");
	background-repeat: no-repeat;
	width: 210px;
	top: 0px;
	left: calc(50% - 105px);
	top: 60px;
	height: 195px;
	background-size: cover;
}

.roundabout-right-5 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-5-mobile.svg");
	background-repeat: no-repeat;
	width: 298px;
	top: 0px;
	left: calc(50% - 149px);
	top: 60px;
	height: 228px;
	background-size: cover;
}

.roundabout-right-6 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-6-mobile.svg");
	background-repeat: repeat;
	background-repeat: no-repeat;
	width: 198px;
	left: calc(50% - 100px);
	top: 60px;
	height: 186px;
	background-size: cover;
}

.roundabout-right-7 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-7-mobile.svg");
	background-repeat: repeat;
	background-repeat: no-repeat;
	width: 320px;
	top: 80px;
	left: calc(50% - 160px);
	height: 151px;
}

.roundabout-right-8 {
	background: url("~@/assets/img/landing-img/main-page/randabout/group-8-mobile.svg");
	background-repeat: repeat;
	background-repeat: no-repeat;
	width: 330px;
	top: 70px;
	left: calc(50% - 165px);
	height: 151px;
}

.filling__caption-wrapp-test-roundabout-progress-bell {
	padding: 25px 28px;
	background: #4c8ab2;
	border-radius: 10px;
	box-shadow: 0px 19px 22px rgba(77, 139, 178, 0.3);
}

.filling__caption-wrapp-test-roundabout-progress-check {
	margin-top: 44px;
	padding: 28px 28px;
	background: #009ed1;
	border-radius: 10px;
	box-shadow: 0px 19px 22px rgba(0, 158, 209, 0.6);
}

.filling__caption-wrapp-test-roundabout-progress-company {
	padding: 18px 22px;
	background: #000000;
	border-radius: 10px;
}

.filling__caption-wrapp-test-roundabout-progress-stage {
	margin-top: 70px;
}

.filling__caption-wrapp-test-roundabout-description {
	width: 100%;
}

.filling__caption-wrapp-test-roundabout-description-title {
	width: 100%;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 28px;
	line-height: 1.27;
	color: #ffffff;
}

.filling__caption-wrapp-test-roundabout-description-text {
	padding-top: 10px;
	font-family: "SFUIDisplay";
	font-weight: 400;
	font-size: 16px;
	line-height: 1.71;
	color: #ffffff;
	opacity: 0.8;
}

.filling__caption-wrapp-test-roundabout-description-start {
	display: none;
	transition: opacity 1s ease;
}

.filling__caption-wrapp-test-roundabout-description-start:hover {
	opacity: 0.5;
}

.filling__caption-wrapp-test-roundabout-description-start-btn {
	display: none;
}

@media (max-width: 1760px) {
	.filling__caption-wrapp-test-roundabout {
		height: 487px;
	}
}

/* /Roundabout */

/* Head and MenuWorker media */
@media (max-width: 1190px) {
	.header {
		display: block;
	}

	.container-account {
		padding-top: 84px;
	}

	.filling__caption-wrapp-statistics {
		padding: 0;
		border: none;
		flex-direction: column;
		max-width: 690px;
		margin: 0 auto;
		background: none;
	}

	.filling__caption-wrapp-statistics-circle {
		padding: 20px;
		border: 1px solid #d8dbe1;
		border-radius: 20px;
		background: #fff;
	}

	.filling__caption-wrapp-statistics-border {
		display: none;
	}

	.filling__caption-wrapp-statistics-graph {
		max-width: 100%;
		box-sizing: border-box;
		margin-top: 20px;
		padding: 20px;
		border: 1px solid #d8dbe1;
		border-radius: 20px;
		background: #fff;
	}

	.filling__caption-wrapp-test {
		padding: 20px;
		border: none;
		flex-direction: column;
		max-width: 690px;
		margin: 20px auto 0 auto;
	}

	.filling__caption-wrapp-test-description {
		padding: 0;
		text-align: center;
	}

	.filling__caption-wrapp-test-description-start-btn {
		display: none;
	}

	.filling__caption-wrapp-test-roundabout {
		margin-top: 20px;
		max-width: 650px;
		width: 100%;
		box-sizing: border-box;
		height: 530px;
	}

	.filling__caption-wrapp-test-roundabout-description-start-btn {
		bottom: 28px;
		left: calc(50% - 200px);
		position: absolute;
		font-family: "Roboto";
		padding: 23px 0;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.1px;
		color: #ffffff;
		max-width: 400px;
		width: 100%;
		margin: 20px auto 0 auto;
		display: block;
		text-align: center;
		background: #14c686;
		border-radius: 7px;
		box-sizing: border-box;
		transition: opacity 1s ease;
	}

	.filling__caption-wrapp-test-roundabout-description-start-btn:hover {
		opacity: 0.5;
	}
}

@media (max-width: 640px) {
	.desctop-rating {
		display: none;
	}

	.mobile {
		display: block;
	}

	.filling__caption-head-greetings-text {
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.filling__caption-head-greetings-title {
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.filling__caption-head {
		text-align: center;
	}

	.filling__caption-head-greetings {
		width: 100%;
	}

	.filling__caption-head-surch {
		display: none;
	}

	.filling__caption-wrapp {
		margin-top: 20px;
	}

	.filling__caption-wrapp-test-roundabout {
		height: 605px;
	}
}

@media (max-width: 500px) {
	.filling__caption-wrapp-statistics-circle-description {
		padding-right: 10px;
	}

	.filling__caption-wrapp-statistics-circle-description-title {
		font-size: 13px;
	}

	.filling__caption-wrapp-statistics-circle-description-grade {
		padding-top: 7px;
		font-size: 16px;
		line-height: 1.6;
	}

	.filling__caption-wrapp-statistics-circle-description-text {
		padding-top: 5px;
		font-size: 11px;
		line-height: 1.6;
	}

	.filling__caption-wrapp-statistics-circle-rating {
		padding: 35px 12px 35px 12px;
		font-size: 39px;
	}

	.filling__caption-wrapp-statistics-circle-rating {
		width: 95px;
	}

	.filling__caption-wrapp-statistics-graph-title {
		padding: 0;
		font-size: 13px;
		margin-bottom: 0;
	}

	.filling__caption-wrapp-statistics-graph-group-element-text {
		font-size: 12px;
	}

	.filling__caption-wrapp-test {
		padding: 0;
		background: none;
		margin-top: 40px;
		text-align: left;
	}

	.filling__caption-wrapp-test-description-title {
		font-size: 13px;
	}

	.filling__caption-wrapp-test-description-text {
		padding-top: 20px;
		font-size: 12px;
	}

	.filling__caption-wrapp-test-roundabout {
		margin-top: 34px;
	}

	.filling__caption-wrapp-test-roundabout-progress {
		height: 150px;
	}

	.test__top-save-roundabout-right-img {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-1-mobile.svg");
		width: 311px;
		height: 123px;
		left: calc(50% - 155px);
		top: 90px;
	}

	.roundabout-right-2 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-2-mobile.svg");
		background-repeat: no-repeat;
		width: 229px;
		height: 155px;
		left: calc(50% - 122px);
		top: 60px;
	}

	.roundabout-right-3 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-3-mobile.svg");
		background-repeat: no-repeat;
		width: 275px;
		top: 0px;
		left: calc(50% - 138px);
		top: 90px;
		height: 133px;
	}

	.roundabout-right-4 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-4-mobile.svg");
		background-repeat: no-repeat;
		width: 176px;
		top: 0px;
		left: calc(50% - 88px);
		top: 60px;
		height: 151px;
	}

	.roundabout-right-5 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-5-mobile.svg");
		background-repeat: no-repeat;
		width: 222px;
		top: 0px;
		left: calc(50% - 111px);
		top: 60px;
		height: 151px;
	}

	.roundabout-right-6 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-6-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 160px;
		top: 0px;
		left: calc(50% - 75px);
		top: 60px;
		height: 151px;
	}

	.roundabout-right-7 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-7-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 320px;

		left: calc(50% - 160px);
		top: 60px;
		height: 151px;
	}

	.roundabout-right-8 {
		background: url("~@/assets/img/landing-img/main-page/randabout/group-8-mobile.svg");
		background-repeat: repeat;
		background-repeat: no-repeat;
		width: 330px;

		left: calc(50% - 165px);
		top: 60px;
		height: 151px;
	}

	.filling__caption-wrapp-test-roundabout-progress-stage {
		margin-top: 0;
	}

	.filling__caption-wrapp-test-roundabout-description {
		padding-top: 12px;
	}

	.filling__caption-wrapp-test-roundabout-description-title {
		font-size: 18px;
	}

	.filling__caption-wrapp-test-roundabout-description-text {
		font-size: 13px;
	}

	.filling__caption-wrapp-test-roundabout-description-start {
		max-width: 300px;
	}

	.filling__caption-wrapp-test-roundabout-description-start-btn {
		padding: 16px 0;
		font-size: 13px;
		max-width: 256px;
		left: calc(50% - 126px);
	}

	.filling__caption-wrapp-test-roundabout {
		height: 500px;
	}
}

@media (max-width: 400px) {
	.filling__caption-wrapp-test-roundabout {
		padding: 20px;
		height: 510px;
	}
}

@media (max-width: 360px) {
	.filling__caption-head {
		text-align: left;
	}

	.filling__caption-wrapp-test-description-title {
		text-align: left;
	}

	.filling__caption-wrapp-test-description-text {
		text-align: left;
	}

	.filling__caption-wrapp-test-roundabout-progress-container-ring {
		left: 15%;
	}

	.filling__caption-wrapp-test-roundabout {
		height: 543px;
	}
}
</style>