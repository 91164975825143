<template>
  <div :key="category">
    <HeaderAccountWorker></HeaderAccountWorker>
    <QuestionModal @close="closeModal" v-if="viewModal" @createNews="createNews" page="testing"></QuestionModal>
    <main class="main">
      <div class="account">
        <MenuWorker></MenuWorker>

        <div class="container-account">
          <div class="test">
            <div class="test__caption">
              <p class="test__caption-text test-mobile">{{ $t('pages.tests.test_worker.testAvailableListPage.personalCabinet')
                }}</p>
              <h1 class="test__caption-title">{{ $t('pages.tests.test_worker.testAvailableListPage.testing') }}</h1>
              <p class="test__caption-text test-desctop">{{ $t('pages.tests.test_worker.testAvailableListPage.welcome') }}</p>
              <div class="test__caption-category">
                <a class="test__caption-category-btn" @click="displayActive"
                  :class="{ 'active-item-blue': activeTrigger }">{{
                    $t('pages.tests.test_worker.testAvailableListPage.activeTests') }}</a>
                <a class="test__caption-category-btn" @click="displayComplete"
                  :class="{ 'active-item-blue': completeTrigger }">{{
                    $t('pages.tests.test_worker.testAvailableListPage.completedTests') }}</a>
                <div class="select">
                  <div class="select-filling" @click="viewCategory = !viewCategory">
                    <p class="select-text">{{
                      category === 'prof' ? $t('pages.tests.test_worker.testAvailableListPage.professionalTests') :
                        category === 'all' ? $t('pages.tests.test_worker.testAvailableListPage.allTests') :
                          category === 'lang' ? $t('pages.tests.test_worker.testAvailableListPage.foreignLanguages') :
                            category === 'sql' ? $t('pages.tests.test_worker.testAvailableListPage.sqlTests') :
                              $t('pages.tests.test_worker.testAvailableListPage.iqTests')
                    }}</p>
                  </div>
                  <div class="pop-up" v-if="viewCategory">
                    <button class="pop-up__element" v-for="item in categories" @click="pickCategory(item)"
                      :key="item.id">{{
                        item === 'prof' ? $t('pages.tests.test_worker.testAvailableListPage.professionalTests') :
                          item === 'all' ? $t('pages.tests.test_worker.testAvailableListPage.allTests') :
                            item === 'lang' ? $t('pages.tests.test_worker.testAvailableListPage.foreignLanguages') :
                              item === 'sql' ? $t('pages.tests.test_worker.testAvailableListPage.sqlTests') :
                                $t('pages.tests.test_worker.testAvailableListPage.iqTests')
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="test__group">
              <available-test v-for="(item) in activeTesting" :key="item.id" :test="item" :date="item.passingDate"
                :completeTrigger="completeTrigger"></available-test>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import AvailableTest from "../../../components/tests/worker_test_available_list_element/item/AvailableTest.vue";
import MenuWorker from "../../../components/account/account_worker/MenuWorker";
import QuestionModal from "@/components/account/account_worker/modals/item/QuestionModal";

export default {
  components: { MenuWorker, HeaderAccountWorker, AvailableTest, QuestionModal },
  computed: {
    getSaveSetting() {
      return this.$store.getters.getSaveSetting;
    },
    activeTesting() {
      return this.$store.getters.activeTesting;
    },
    passedTestingInfo() {
      return this.$store.getters.passedTestingInfo;
    }

  },
  data() {
    return {
      activeTrigger: true,
      completeTrigger: false,
      viewCategory: false,
      category: 'prof',
      categories: ['all', 'lang', 'prof', 'sql', 'iq'],
      viewModal: false
    };
  },
  name: "TestAvailableListPage",
  beforeMount() {
    scroll(0, 0);
    require("vue-flash-message/dist/vue-flash-message.min.css");
    if (this.passedTestingInfo) {
      this.viewModal = true;
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.category = this.$route.hash.slice(1);
    }
    this.$store.dispatch('fetchAvailableTest', this.category);
  },
  methods: {
    createNews() {
      const data = {
        newsType: 'test',
        fromUser: null,
        toUser: null,
        body: JSON.stringify({
          testingName: this.passedTestingInfo.title,
          description: this.passedTestingInfo.description,
        })
      }
      this.$store.dispatch('createNews', data).then(() => {
        this.viewModal = false;
        this.$store.commit('setPassedTestingInfo', null);
      })
    },
    closeModal() {
      this.viewModal = false;
      this.$store.commit('setPassedTestingInfo', null);
    },
    displayActive() {
      this.completeTrigger = false;
      this.activeTrigger = true;
      this.$store.dispatch('fetchAvailableTest', this.category);
    },
    displayComplete() {
      this.activeTrigger = false;
      this.completeTrigger = true;
      this.$store.dispatch('fetchComplitedTest', this.category);
    },
    pickCategory(value) {
      this.category = value;
      if (this.activeTrigger) {
        this.$store.dispatch('fetchAvailableTest', this.category);
      } else {
        this.$store.dispatch('fetchComplitedTest', this.category);
      }
      this.viewCategory = false;
    }
  },
};
</script>



<style scoped>
.account {
  display: flex;
}

.test__caption-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.test__caption-text {
  padding-top: 3px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.test__caption-category {
  margin-top: 34px;
  display: flex;
}

.test__caption-category-btn:not(:last-child) {
  margin-right: 14px;
}

.test__caption-category-btn {
  padding: 16px 20px;
  border: 1px solid #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #009ed1;
  transition: background 1s ease, border 1s ease;
  cursor: pointer;
}

.select {
  position: relative;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 500;
  box-sizing: border-box;

  color: #171725;
}

.select-filling {
  min-width: 250px;
  padding: 16px 20px;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease, border 1s ease, color 1s ease;
}

.select-filling:hover {
  background-color: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  border: 1px solid #009ed1;
  color: #009ed1;
}

.pop-up {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #bcbcbc;
  border-top: none;
  border-radius: 8px;
  left: 0;
  background-color: #fff;
  z-index: 20;
}

.pop-up__element {
  text-align: left;
  padding: 16px 20px;
  transition: color 1s ease;
}

.pop-up__element:hover {
  color: #009ed1;
}

.pop-up__element:not(:first-child) {
  border-top: 1px solid #bcbcbc;
}


.active-item-blue {
  border: 1px #e6f6fc solid;
  padding: 16px 20px;
  background: #e6f6fc;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #009ed1;
}

.test__caption-category-btn:hover {
  background: #e6f6fc;
  border: 1px #e6f6fc solid;
}

.test__group {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 48px;
}

.test-mobile {
  display: none;
}

@media (max-width: 1729px) {
  .test__group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1369px) {
  .test__caption {
    text-align: center;
  }

  .test__caption-category {
    display: flex;
    justify-content: center;
  }

  .test__group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 950px) {
  .account {
    min-height: 563px;
  }
}

@media (max-width: 753px) {
  .test-mobile {
    display: block;
  }

  .test-desctop {
    display: none;
  }

  .test__caption-text {
    color: #009ed1;
  }

  .test__group {
    grid-template-columns: repeat(1, 1fr);
  }

  .test__caption {
    max-width: 336px;
    margin: 0 auto;
  }

  .test__caption-category {
    flex-direction: column;
    gap: 15px;
  }

  .test__caption-category-btn:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 400px) {
  .test__caption-title {
    font-size: 20px;
  }

  .test__caption-text {
    font-size: 13px;
  }

  .test__caption-category {
    margin-top: 10px;
  }

  .test__caption-category-active {
    margin-right: 10px;
    padding: 12px;
    font-size: 11px;
  }

  .test__caption-category-completed {
    padding: 12px 16px;
    font-size: 11px;
  }

  .test__group {
    margin-top: 30px;
    row-gap: 12px;
  }
}

@media (max-width: 340px) {
  .test__caption {
    text-align: left;
  }

  .test__caption-category {
    justify-content: unset;
  }
}
</style>
