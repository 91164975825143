<template>
	<div class="test__group-element">
		<div class="test__group-element-caption">
			<p v-if="completeTrigger" class="test__group-element-caption-times">
				{{ $t('components.tests.availableTest.numberOfPasses') }}: {{ test.passed }}
			</p>
			<div class="test__group-element-caption-rating-container">
				<div class="test__group-element-caption-rating">
					<div class="test__group-element-caption-rating-number-container" v-if="completeTrigger">
						<p class="test__group-element-caption-rating-number">
							{{ $t('components.tests.availableTest.result') }}: {{ test.score }}
						</p>
					</div>
					<div class="test__group-element-caption-rating-number-container" v-else>
						<p class="test__group-element-caption-rating-number">
							{{ test.questionsCount }}
						</p>
					</div>
					<p class="test__group-element-caption-rating-date" v-if="completeTrigger">
						{{ new Date(test.passingDate).toISOString().slice(0, 10) }}
					</p>
				</div>
			</div>
		</div>
		<div class="test__group-element-description">
			<h2 class="test__group-element-description-title">{{ limit(test.title, 25) }}</h2>
			<p class="test__group-element-description-text">
				{{ limit(test.description, 125) }}
			</p>
		</div>
		<router-link :to="{ name: 'activeTest', params: { testingId: test.id } }" class="test__group-element-start">
			<div class="test__group-element-start-btn">
				<span v-if="!completeTrigger">{{ $t('components.tests.availableTest.startTest') }}</span>
				<span v-else>{{ $t('components.tests.availableTest.startTestAgain') }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";

export default {
	name: "AvailableTest",
	props: {
		test: {
			type: Object,
		},
		date: {},
		completeTrigger: {
			type: Boolean,
		},
	},
	data() {
		return {
			DESCRIPTION_SYMBOL_LIMIT: 125,
			completeTestDate: "",
			limit: textSymbolLimit
		};
	},
};
</script>


<style scoped>
.test__group-element {
	max-width: 338px;
	width: 100%;
	height: 400px;
	position: relative;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 9px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.test__group-element-caption {
	position: relative;
	height: 147px;
	border-radius: 9px 9px 0 0;
	background: url("~@/assets/img/test-page/test/test-element-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.test__group-element-caption-rating-container {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 16px 19px;
}

.test__group-element-caption-rating-number-container {
	background: #3cd598;
	border-radius: 20px;
}

.test__group-element-caption-times {
	bottom: 16px;
	left: 19px;
	position: absolute;
	display: inline;
	padding: 4px 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 11px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
	max-width: 100%;
	text-align: center;
	background: rgba(140, 150, 171, 0.7);
	border-radius: 20px;
}

.test__group-element-caption-rating {
	display: flex;
}

.test__group-element-caption-rating-date {
	padding: 0 7px;
	margin-left: auto;
	background: rgba(50, 56, 76, 0.6000000238418579);
	border-radius: 20px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.test__group-element-caption-rating-number {
	padding: 0 10px 0 22px;
	background: url("~@/assets/img/test-page/test/star.png");
	background-position: 9px;
	background-position-y: 6px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.test__group-element-description {
	padding: 15px 25px 20px 20px;
}

.test__group-element-description-company {
	display: flex;
	align-items: center;
}

.test__group-element-description-company-logo {
	background: #000000;
	border-radius: 100%;
	width: 22px;
	height: 22px;
	object-fit: contain;
}

.test__group-element-description-company-name {
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.test__group-element-description-title {
	padding-top: 14px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.36;
	color: #3b4c68;
}

.test__group-element-description-text {
	padding-top: 11px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 21.5px;
	letter-spacing: 0.1px;
	color: #686873;
}

.test__group-element-start-btn:hover {
	opacity: 0.5;
}

.test__group-element-start {
	text-align: center;
	border-top: 1px solid #f1f1f5;
	padding: 13px 16px 16px 20px;
	width: 100%;
	box-sizing: border-box;
	text-align: center;

	bottom: 0;
	position: absolute;
}

.test__group-element-start-btn {
	display: block;
	border-radius: 10px;
	padding: 14px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #009ed1;
	transition: opacity 1s ease;
	background: #e6f6fc;
}

@media (max-width: 1369px) {
	.test__group-element {
		margin: 0 auto;
	}
}

@media (max-width: 400px) {
	.test__group-element {
		max-width: 300px;
	}

	.test__group-element-description {
		padding: 15px 25px 5px 20px;
		border-bottom: none;
	}

	.test__group-element-description-company-logo {
		padding: 9px 11px;
		width: 18px;
		height: 22px;
		object-fit: cover;
	}

	.test__group-element-description-title {
		font-size: 15px;
		line-height: 1.48;
	}

	.test__group-element-description-text {
		padding-top: 7px;
		font-size: 13px;
		line-height: 24px;
	}

	.test__group-element-start-btn {
		font-size: 12px;
	}
}
</style>
