<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="container-account">
					<div class="recomedation">
						<p class="recomedation__text-mobile">{{
							$t('pages.account.account_company.accountCompanyGroupsPage.personalCabinet') }}</p>
						<h1 class="recomedation__title">{{
							$t('pages.account.account_company.accountCompanyGroupsPage.title') }}</h1>
						<p class="recomedation__text-desctop">{{
							$t('pages.account.account_company.accountCompanyGroupsPage.welcome') }}</p>
						<div class="recomedation__wrapper">
							<SeparateGroup v-for="n in 8" :key="n"></SeparateGroup>
						</div>
						<div class="recomedation__more">
							<button class="recomedation__more-btn">{{
								$t('pages.account.account_company.accountCompanyGroupsPage.seeAll') }}</button>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import SeparateGroup from "../../../components/account/account_company/account_company_group/item/SeparateGroup.vue";

export default {
	components: { MenuCompany, HeaderAccountCompany, SeparateGroup },
	name: "AccountCompanyGroupsPage",
	mounted() {
		scroll(0, 0);
	}
};
</script>


<style scoped>
/* Recomendation */
.account {
	display: flex;
}

.recomedation {
	margin-bottom: 40px;
	width: 100%;
	box-sizing: border-box;
}

.recomedation__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.recomedation__text-desctop {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.recomedation__more {
	margin-top: 47px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.recomedation__more-btn {
	display: none;
	height: 50px;
	width: 25%;
	border: 1px solid #009ed1;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #009ed1;
	transition: opacity 1s ease;
}

.recomedation__more-btn:hover {
	opacity: 0.5;
}

.recomedation__wrapper {
	margin-top: 42px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 40px;
}

.mobile {
	display: none;
}

.recomedation__text-mobile {
	display: none;
}

@media (max-width: 1740px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(3, 1fr);
	}

	.group__element {
		margin: 0 auto;
	}

	.recomedation__text-desctop {
		text-align: center;
	}

	.recomedation__title {
		text-align: center;
	}
}

@media (max-width: 1422px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}
}

@media (max-width: 1422px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 730px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 360px) {
	.recomedation__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.recomedation__text-desctop {
		display: none;
	}

	.recomedation__text-mobile {
		display: block;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}
}
</style>
