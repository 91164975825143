<template>
	<header class="header">
		<div class="header__wrapper">
			<router-link :to="{ name: 'dashboard' }" class="header__wrapper-logo logo"><img
					src="~@/assets/img/all-img/logo.png" alt="" class="header__wrapper-logo-img" />
			</router-link>
			<div class="header__wrapper-burger desctop" @click="menuVisible = !menuVisible">
				<span class="header__wrapper-burger-span"></span>
			</div>
			<div class="header__wrapper-burger mobile" @click="menuMobileVisible = !menuMobileVisible">
				<span class="header__wrapper-burger-span"></span>
			</div>
			<ul class="header__wrapper-nav">
				<li>
					<router-link :to="{ name: 'home' }" class="header__wrapper-nav-item">{{
						$t('components.account_worker.headerAccountWorker.main_page') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'about' }" class="header__wrapper-nav-item">{{
						$t('components.account_worker.headerAccountWorker.about_us') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'blog' }" class="header__wrapper-nav-item">{{
						$t('components.account_worker.headerAccountWorker.blog') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'allChats', query: { needHelp: 'true' } }"
						class="header__wrapper-nav-item">{{ $t('components.account_worker.headerAccountWorker.help')
						}}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'contact' }" class="header__wrapper-nav-item">{{
						$t('components.account_worker.headerAccountWorker.contact_us') }}</router-link>
				</li>
			</ul>
			<div class="header__wrapper-btngroup">
				<router-link v-if="!authorized" to="/user/login" class="header__wrapper-btngroup-btn">{{
					$t('components.account_worker.headerAccountWorker.for_job_seekers') }}</router-link>
				<router-link v-if="!authorized" to="/user/login" class="header__wrapper-btngroup-btn">{{
					$t('components.account_worker.headerAccountWorker.for_employers') }}</router-link>
			</div>
			<div class="header__wrapper-profile">
				<div class="menu-container" style="
            display: flex;
            align-items: center;
            position: relative;
          ">
					<div class="menu-worker" v-if="menuWorkerVisible">
						<div class="menu-worker__wrapper">
							<nav class="menu-worker__wrapper-nav">
								<router-link class="menu-worker__wrapper-nav-item item-dashboard" :class="{
									'item-blue':
										this.$route.path === '/candidate/account/dashboard',
								}" :to="{ name: 'dashboard' }">
									<a href="" class="menu-worker__wrapper-nav-item-link-dashboard" :class="{
										'item-white-dashboard':
											this.$route.path === '/candidate/account/dashboard',
									}">{{ $t('components.account_worker.headerAccountWorker.personal_cabinet') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-friends" :class="{
									'item-blue': this.$route.path === '/user/social/friends',
								}" :to="{ name: 'friends' }">
									<a href="" class="menu-worker__wrapper-nav-item-link-friends" :class="{
										'item-white-friends':
											this.$route.path === '/user/social/friends',
									}">{{ $t('components.account_worker.headerAccountWorker.friends') }} <span class="friends-messages "
											v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{
												user.newFriendRequests }}
												<!-- {{
												$t('components.account_worker.headerAccountWorker.new_friend_requests')
											}} -->
											</span></a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-communities" :class="{
									'item-blue': this.$route.path === '/candidate/account/communities',
								}" :to="{ name: 'communities' }">
									<a href="" class="menu-worker__wrapper-nav-item-link-communities" :class="{
										'item-white-communities':
											this.$route.path === '/candidate/account/communities',
									}">{{ $t('components.account_worker.headerAccountWorker.communities') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-settings"
									:to="{ name: 'settings' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/settings',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-settings" :class="{
										'item-white-settings':
											this.$route.path === '/candidate/account/settings',
									}">{{ $t('components.account_worker.headerAccountWorker.profile_settings') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-documents"
									:to="{ name: 'documents' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/documents',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-documents" :class="{
										'item-white-documents':
											this.$route.path === '/candidate/account/documents',
									}">{{ $t('components.account_worker.headerAccountWorker.my_documents') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-rating" :to="{ name: 'rate' }"
									:class="{
										'item-blue': this.$route.path === '/candidate/account/rate',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-rating" :class="{
										'item-white-rating':
											this.$route.path === '/candidate/account/rate',
									}">{{ $t('components.account_worker.headerAccountWorker.rating') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-tests" :to="{ name: 'testing' }"
									:class="{
										'item-blue':
											this.$route.path === '/candidate/account/testing',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-tests" :class="{
										'item-white-tests':
											this.$route.path === '/candidate/account/testing',
									}">{{ $t('components.account_worker.headerAccountWorker.career_test') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-interview"
									:to="{ name: 'videoInterview' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/video-interview',
									}">
									<a href="" class="menu__wrapp-nav-item-link-interview" :class="{
										'item-white-interview':
											this.$route.path === '/candidate/account/video-interview',
									}">{{ $t('components.account_worker.headerAccountWorker.video_interview') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-vacancies"
									:to="{ name: 'vacancies' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/vacancies',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-vacancies" :class="{
										'item-white-vacancies':
											this.$route.path === '/candidate/account/vacancies',
									}">{{ $t('components.account_worker.headerAccountWorker.search_a_job') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-favorites"
									:to="{ name: 'favorites' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/favorites',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-favorites" :class="{
										'item-white-favorites':
											this.$route.path === '/candidate/account/favorites',
									}">{{ $t('components.account_worker.headerAccountWorker.favorites') }}<span
											class="friends-messages favorites--message">{{
												totalFavorites > 25 ? '25+' : totalFavorites }}</span></a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-rates"
									:to="{ name: 'responses' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/responses',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-rates" :class="{
										'item-white-rates':
											this.$route.path === '/candidate/account/responses',
									}">{{ $t('components.account_worker.headerAccountWorker.my_applications') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-recommendations"
									:to="{ name: 'recommendations' }" :class="{
										'item-blue':
											this.$route.path === '/candidate/account/recommendations',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-recommendations" :class="{
										'item-white-recommendations':
											this.$route.path ===
											'/candidate/account/recommendations',
									}">{{ $t('components.account_worker.headerAccountWorker.recommendations') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-faq" :to="{ name: 'faq' }"
									:class="{
										'item-blue': this.$route.path === '/candidate/account/faq',
									}">
									<a href="" class="menu-worker__wrapper-nav-item-link-faq" :class="{
										'item-white-faq':
											this.$route.path === '/candidate/account/faq',
									}">{{ $t('components.account_worker.headerAccountWorker.faq') }}</a>
								</router-link>

								<router-link class="menu-worker__wrapper-nav-item item-main" :to="{ name: 'home' }"
									:class="{
										'item-blue': this.$route.path === '/',
									}">
									<a class="menu-worker__wrapper-nav-item-link-main" :class="{
										'item-white-main':
											this.$route.path === '/',
									}">{{ $t('components.account_worker.headerAccountWorker.main') }}</a>
								</router-link>

							</nav>
							<div class="menu-worker__wrapper-end">
								<div class="menu-worker__wrapper-leave">
									<button class="menu-worker__wrapper-leave-text" @click="logout">
										{{ $t('components.account_worker.headerAccountWorker.log_out') }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<img @click="openMenuAvatar" :src="avatarPath" class="header__wrapper-profile-img" alt="" />
					<div style="
					display: flex;
					align-items: center;
					position: relative;
					cursor: pointer;
					" class="menu-click-wrapper" @click="menuWorkerVisible = !menuWorkerVisible">
						<p class="header__wrapper-profile-name">{{ name ? limit(name, 20) : ' ' }}</p>
						<button class="header__wrapper-profile-list"></button>
					</div>
				</div>
			</div>
		</div>
		<div class="header__menu" v-if="menuVisible">
			<a href="" class="header__menu-logo logo"><img src="~@/assets/img/main-page/Logo.svg" alt=""
					class="header__menu-logo-img" /></a>
			<ul class="header__menu-nav">
				<li>
					<router-link :to="{ name: 'home' }" class="header__menu-nav-item">{{
						$t('components.account_worker.headerAccountWorker.main_page') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'about' }" class="header__menu-nav-item">{{
						$t('components.account_worker.headerAccountWorker.about_us') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'blog' }" class="header__menu-nav-item">{{
						$t('components.account_worker.headerAccountWorker.blog') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'allChats', query: { needHelp: 'true' } }"
						class="header__menu-nav-item">{{ $t('components.account_worker.headerAccountWorker.help')
						}}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'contact' }" class="header__menu-nav-item">{{
						$t('components.account_worker.headerAccountWorker.contact_us') }}</router-link>
				</li>
			</ul>
			<div class="header__menu-btngroup">
				<router-link v-if="!authorized" to="/user/login" class="header__menu-btngroup-btn">{{
					$t('components.account_worker.headerAccountWorker.for_job_seekers') }}</router-link>
				<router-link v-if="!authorized" to="/user/login" class="header__menu-btngroup-btn">{{
					$t('components.account_worker.headerAccountWorker.for_employers') }}</router-link>
			</div>
		</div>
		<div class="menu" v-if="menuMobileVisible">
			<div class="menu__wrapp">
				<div class="menu__wrapp-back menu-mobile">
					<div class="menu__wrapp-back-btn"></div>
					<p class="menu__wrapp-back-text">{{
						$t('components.account_worker.headerAccountWorker.personal_cabinet') }}</p>
				</div>
				<nav class="menu__wrapp-nav">
					<router-link class="menu__wrapp-nav-item item-dashboard" :class="{
						'item-blue': this.$route.path === '/candidate/account/dashboard',
					}" :to="{ name: 'dashboard' }">
						<a href="" class="menu__wrapp-nav-item-link-dashboard" :class="{
							'item-white-dashboard':
								this.$route.path === '/candidate/account/dashboard',
						}">{{ $t('components.account_worker.headerAccountWorker.personal_cabinet') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-friends" :class="{
						'item-blue': this.$route.path === '/user/social/friends',
					}" :to="{ name: 'friends' }">
						<a href="" class="menu__wrapp-nav-item-link-friends" :class="{
							'item-white-friends':
								this.$route.path === '/user/social/friends',
						}">{{ $t('components.account_worker.headerAccountWorker.friends') }} <span class="friends-messages"
								v-if="user && user.newFriendRequests && user.newFriendRequests > 0">{{
									user.newFriendRequests }}{{
									$t('components.account_worker.headerAccountWorker.new_friend_requests')
								}}</span></a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-communities" :class="{
						'item-blue': this.$route.path === '/user/social/communities',
					}" :to="{ name: 'communities' }">
						<a href="" class="menu__wrapp-nav-item-link-communities" :class="{
							'item-white-communities':
								this.$route.path === '/user/social/communities',
						}">{{ $t('components.account_worker.headerAccountWorker.communities') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-settings" :to="{ name: 'settings' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/settings',
					}">
						<a href="" class="menu__wrapp-nav-item-link-settings" :class="{
							'item-white-settings':
								this.$route.path === '/candidate/account/settings',
						}">{{ $t('components.account_worker.headerAccountWorker.profile_settings') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-documents" :to="{ name: 'documents' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/documents',
					}">
						<a href="" class="menu__wrapp-nav-item-link-documents" :class="{
							'item-white-documents':
								this.$route.path === '/candidate/account/documents',
						}">{{ $t('components.account_worker.headerAccountWorker.my_documents') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-rating" :to="{ name: 'rate' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/rate',
					}">
						<a href="" class="menu__wrapp-nav-item-link-rating" :class="{
							'item-white-rating':
								this.$route.path === '/candidate/account/rate',
						}">{{ $t('components.account_worker.headerAccountWorker.rating') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-tests" :to="{ name: 'testing' }" :class="{
						'item-blue':
							this.$route.path === '/candidate/account/testing',
					}">
						<a href="" class="menu__wrapp-nav-item-link-tests" :class="{
							'item-white-tests':
								this.$route.path === '/candidate/account/testing',
						}">{{ $t('components.account_worker.headerAccountWorker.career_test') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-interview" :to="{ name: 'videoInterview' }" :class="{
						'item-blue':
							this.$route.path === '/candidate/account/video-interview',
					}">
						<a href="" class="menu__wrapp-nav-item-link-interview" :class="{
							'item-white-interview':
								this.$route.path === '/candidate/account/video-interview',
						}">{{ $t('components.account_worker.headerAccountWorker.video_interview') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-vacancies" :to="{ name: 'vacancies' }" :class="{
						'item-blue':
							this.$route.path === '/candidate/account/vacancies',
					}">
						<a href="" class="menu__wrapp-nav-item-link-vacancies" :class="{
							'item-white-vacancies':
								this.$route.path === '/candidate/account/vacancies',
						}">{{ $t('components.account_worker.headerAccountWorker.search_a_job') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-favorites" :to="{ name: 'favorites' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/favorites',
					}">
						<a href="" class="menu__wrapp-nav-item-link-favorites" :class="{
							'item-white-favorites':
								this.$route.path === '/candidate/account/favorites',
						}">{{ $t('components.account_worker.headerAccountWorker.favorites') }}<span
								class="friends-messages favorites--message">{{
									totalFavorites > 25 ? '25+' : totalFavorites }}</span></a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-rates" :to="{ name: 'responses' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/responses',
					}">
						<a href="" class="menu__wrapp-nav-item-link-rates" :class="{
							'item-white-rates':
								this.$route.path === '/candidate/account/responses',
						}">{{ $t('components.account_worker.headerAccountWorker.my_applications') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-recommendations" :to="{ name: 'recommendations' }"
						:class="{
							'item-blue':
								this.$route.path === '/candidate/account/recommendations',
						}">
						<a href="" class="menu__wrapp-nav-item-link-recommendations" :class="{
							'item-white-recommendations':
								this.$route.path === '/candidate/account/recommendations',
						}">{{ $t('components.account_worker.headerAccountWorker.recommendations') }}</a>
					</router-link>

					<router-link class="menu__wrapp-nav-item item-faq" :to="{ name: 'faq' }" :class="{
						'item-blue': this.$route.path === '/candidate/account/faq',
					}">
						<a href="" class="menu__wrapp-nav-item-link-faq" :class="{
							'item-white-faq': this.$route.path === '/candidate/account/faq',
						}">{{ $t('components.account_worker.headerAccountWorker.faq') }}</a>
					</router-link>

					<button @click="$router.push({ name: 'allChats' })" class="menu__wrapp-chat-text">
						{{ $t('components.account_worker.headerAccountWorker.chat') }}
						<span v-if="user && user.newMessages && user.newMessages > 0" class="friends-messages">{{
							user.newMessages }}{{ $t('components.account_worker.headerAccountWorker.new_messages')
							}}</span>
					</button>

					<li>
						<div class="header__wrapper-lang">
							<a @click="changeLanguage('ru')" class="header-lang__element"
								:class="$i18n.locale === 'ru' ? 'lang-chosen' : ''">Ru</a>
							<p class="header-lang__element-distinction">/</p>
							<a @click="changeLanguage('en')" class="header-lang__element"
								:class="$i18n.locale === 'en' ? 'lang-chosen' : ''">En</a>
						</div>
					</li>

				</nav>
				<div class="menu__wrapp-end">

					<div class="menu__wrapp-resume">
						<button class="menu__wrapp-resume-text">{{
							$t('components.account_worker.headerAccountWorker.create_resume')
						}}</button>
					</div>
					<div class="menu__wrapp-leave">
						<button class="menu__wrapp-leave-text" @click="logout">
							{{ $t('components.account_worker.headerAccountWorker.sign_out') }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<ShowPhotos :change="change" @delete="iter => $emit('delete', iter)" @close="$emit('close')" v-if="avatar"
			:photos="photos">
		</ShowPhotos>
		<transition name="fade">
			<Notification v-if="notificationView">
			</Notification>
		</transition>
	</header>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import ShowPhotos from "../account_worker/accout_worker_header-element/item/ShowPhotos.vue";
import Notification from "../../social/Notification.vue";

export default {
	props: ['avatar', 'photos', 'change'],
	name: "HeaderAccountWorker",
	components: { ShowPhotos, Notification },
	data() {
		return {
			menuVisible: false,
			menuMobileVisible: false,
			menuWorkerVisible: false,
			limit: textSymbolLimit,
			viewLanguage: false
		};
	},

	computed: {
		avatarPath() {
			return this.$store.getters.avatarPath;
		},
		name() {
			return this.$store.getters.name;
		},
		authorized() {
			return this.$store.getters.authorized
		},
		user() {
			return this.$store.getters.user;
		},
		notificationView() {
			return this.$store.getters.notificationView;
		},
		totalFavorites() {
			return this.$store.getters.favoriteVacancy.length;
		},
		windowSize() {
			return window.innerWidth < 950;
		}
	},
	methods: {
		logout() {
			this.$store.dispatch("logout");
		},
		openMenuAvatar() {
			this.$route.name !== 'dashboard' ? this.$router.push({ name: 'dashboard' }) : null
			this.menuWorkerVisible = !this.menuWorkerVisible
		},
		changeLanguage(lang) {
			this.$i18n.locale = lang;
		}
	},
	beforeMount() {
		this.$store.dispatch("fetchFavoriteVacancy", { token: this.$store.getters.token });
	}
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
	{
	opacity: 0;
}



.header {
	width: 100%;
	position: fixed;
	background: #fff;
	z-index: 1000;
}

.header__wrapper {
	box-shadow: inset 0px -1px 0px #e2e2ea;
	max-height: 70px;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0 auto;
	font-family: "Montserrat", sans-serif;
}

.header__wrapper-logo {
	padding-left: 49px;
	order: 1;
	height: 31px;
}

.header__wrapper-logo-img {
	width: 177px;
	height: 31px;
}

.header__wrapper-nav {
	margin-left: auto;
	position: relative;
	order: 2;
}

.header__wrapper-nav-item:hover {
	color: #009ed1;
}

.header__wrapper-nav-item {
	text-decoration: none;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #44444f;
	transition: color 1s ease;
}

.header__wrapper-nav :not(:last-child) {
	padding-right: 84px;
}

.header__wrapper-btngroup {
	display: flex;
	margin-left: auto;
	margin-right: 42px;
	order: 3;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.1px;
	transition: color 1s ease, background 1s ease;
}

.header__wrapper-btngroup :hover {
	color: #009ed1;
	background: #e5f6fb;
}

.header__wrapper-btngroup :not(:last-child) {
	margin-right: 13px;
}

.header__wrapper-btngroup-btn {
	display: block;
	text-decoration: none;
	width: 150px;
	padding: 13px 0;
	border-radius: 4px;
	background: #f0f4f9;
	color: #607088;
	transition: color 1s ease, background 1s ease;
}

.header__wrapper-bell {
	width: 18px;
	height: 21px;
	background-image: url("~@/assets/img/aboutus-page/header/bell.svg");
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.header__wrapper-lang-wrapper {

	background: #fff;
	border: 1px solid #EAEAEE;
	border-radius: 6px;
	position: relative;
	margin-right: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #171725;
}

.header__wrapper-lang-selected {
	padding: 5px 25px 5px 5px;
	background: url(../../../assets/img/all-img/list-grey.svg);
	background-position: 33px;
	background-size: 8px;
	background-repeat: no-repeat;
}

.header__wrapper-lang-choice {
	position: absolute;
	left: -1px;
	bottom: -22px;
}

.header__wrapper-lang-choice-element {
	padding: 5px 24px 5px 5px;
	background: #fff;
	border-radius: 6px;
	border: 1px solid #EAEAEE;
	border-radius: 6px;
	transition: all 1s ease-in-out;
	color: #000;
}

.header__wrapper-lang-choice-element:hover {
	background: #e3f4fa;
	color: #009ed1;
}

.header__wrapper-lang {
	display: flex;
	gap: 3px;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #000;
	cursor: pointer;

}

.header-lang__element {
	transition: opacity 1s ease;
	color: inherit;
}

.header-lang__element:hover {
	opacity: 0.5;
}

.lang-chosen {

	color: #009ed1;
}

.header__wrapper-bell-notifications {
	display: none;
	position: absolute;
	width: 12px;
	height: 12px;
	background: #14c686;
	border-radius: 12px;
	top: -4px;
	right: -6px;
}

.header__wrapper-profile {
	margin-right: 32px;
	order: 4;
	display: flex;
	text-align: center;
	align-items: center;
}

.header__wrapper-profile-img {
	width: 32px;
	height: 32px;
	margin-left: 10px;
	border-radius: 100%;
	background: url("https://winaero.com/blog/wp-content/uploads/2018/08/Windows-10-user-icon-big.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: cover;
	cursor: pointer;
	object-position: center;
}

.header__wrapper-profile-name {
	padding-left: 10px;
	font-weight: 600;
	font-size: 13px;
	line-height: 14px;
	letter-spacing: 0.1px;
	color: #171625;
	max-width: 152px;
	overflow: hidden;
	display: block;
	box-sizing: border-box;
}

.header__wrapper-profile-list {
	padding-left: 62px;
	width: 0px;
	height: 7px;
	background: url("~@/assets/img/aboutus-page/header/header-list.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.header__menu {
	display: none;
}


@media (max-width: 1700px) {
	.header__wrapper-profile {
		margin-right: 14px;
	}

	.header__wrapper-logo {
		padding-left: 29px;
	}

	.header__wrapper-nav {
		display: none;
	}

	.header__wrapper-burger {
		cursor: pointer;
		margin-right: 18px;
		order: 5;
		display: block;
		position: relative;
		width: 30px;
		height: 20px;
	}

	.header__wrapper-burger::after,
	.header__wrapper-burger::before {
		content: "";
		background-color: #009ed1;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
	}

	.header__wrapper-burger::after {
		top: 0;
	}

	.header__wrapper-burger::before {
		bottom: 0;
	}

	.header__wrapper-burger-span {
		background-color: #009ed1;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 9px;
	}

	.mobile {
		display: none;
	}
}

@media (max-width: 1700px) {
	.header__wrapper-nav {
		display: none;
	}

	.header__menu {
		box-shadow: inset 0px -1px 0px #e2e2ea;
		display: block;
		background: #fafafb;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		margin-right: auto;
		flex-direction: column;
		padding: 0px 24px 30px 26px;
		text-align: center;
	}

	.header__menu-logo {
		display: none;
	}

	.header__menu-nav {
		padding-top: 19px;
		transition: color 1s ease;
	}

	.header__menu-btngroup {
		display: none;
	}

	.header__menu-nav-item {
		font-size: 20px;
		display: block;
		font-family: "Montserrat";
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.1px;
		color: #44444f;
		margin-bottom: 19px;
		padding-bottom: 21px;
		border-bottom: 1px solid rgba(68, 68, 79, 0.11);
		transition: color 1s ease;
	}

	.header__menu-nav-item :hover {
		color: #009ed1;
	}
}

@media (max-width: 950px) {
	.header__wrapper-profile {
		margin-left: auto;
	}

	.header__menu-logo {
		margin-top: 23px;
		display: block;
	}

	.header__menu {
		text-align: left;
		padding-bottom: 30px;
	}

	.header__menu-btngroup {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		order: 3;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.1px;
		transition: color 1s ease, background 1s ease;
	}

	.header__menu-btngroup :hover {
		color: #009ed1;
		background: #e5f6fb;
	}

	.header__menu-btngroup :not(:last-child) {
		margin-bottom: 12px;
	}

	.header__menu-btngroup-btn {
		text-decoration: none;
		width: 100%;
		padding: 13px 0px;
		text-align: center;
		box-sizing: border-box;
		border-radius: 4px;
		background: #f0f4f9;
		color: #607088;
		transition: color 1s ease, background 1s ease;
	}

	.header__wrapper-logo {
		display: none;
	}

	.header__wrapper-btngroup {
		display: none;
	}

	.header__wrapper-burger {
		order: 1;
		margin-left: 24px;
	}

	.header__wrapper-burger {
		margin-right: 49px;
		order: 3;
		display: block;
		position: relative;
		width: 16px;
		height: 10px;
	}

	.header__wrapper-burger::before {
		content: "";
		background-color: #92929d;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
	}

	.header__wrapper-burger::after {
		content: none;
	}

	.header__wrapper-burger-span {
		background-color: #92929d;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 2px;
	}

	.desctop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.item-main {
		display: block;
	}
}

@media (max-width: 767px) {
	.header__wrapper-profile-list {
		display: none;
	}

	.header__wrapper-profile {
		margin-right: 24px;
	}
}

@media (max-width: 500px) {
	.header__wrapper-profile-name {
		display: none;
	}

	.header__wrapper-lang {
		font-size: 13px;
		gap: 2px;
	}
}

/* On click */
.menu {
	border-right: 1px solid #ebedf0;
	position: sticky;
	overflow-x: hidden;
	overflow-y: hidden;
	top: 0;
	left: 0;
	max-width: 100%;
	width: 100%;
	height: calc(100vh);
	background: #fff;
	overflow-y: scroll;
	scrollbar-width: thin;
	padding-bottom: 70px;
}

.menu-mobile {
	display: none;
}

.menu__wrapp-back {
	direction: flex;
	align-items: center;
}

.menu__wrapp-back-btn {
	display: block;
	width: 31px;
	height: 10px;
	background: url("~@/assets/img/account-img/menu/back.svg");
	background-repeat: no-repeat;
	background-position: center;
}

.menu__wrapp-back-text {
	font-family: "Roboto";
	margin-left: auto;
	font-weight: 400;
	font-size: 13px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.menu__wrapp {
	box-sizing: border-box;
	max-width: 340px;
	margin: 0px auto 0 auto;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 60px;
}

.menu__wrapp-logo {
	width: 100%;
	padding: 0 0px 21px 61px;
	margin: 18px 0 0px 0;
	display: block;
	box-sizing: border-box;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.menu__wrapp-logo-img {
	height: 100%;
}

.friends-messages {
	margin-left: 11px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 12px;
	display: inline;
	padding: 3px 8px;
	background: #14c686;
	border-radius: 20px;
	color: #fff;
	line-height: 1;
}

.favorites--message {
	background: #FAFAFB;
	color: #009ED1;
}

.menu__wrapp-nav {
	margin: 24px 0 0 0;
	width: 100%;
	border: center;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 0 20px;
}

.menu__wrapp-nav :not(:last-child) {
	margin-bottom: 15px;
}

.menu__wrapp-nav-item {
	color: #44444f;
	border-radius: 5px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	background: #fff;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
	transition: background 1s ease, color 1s ease;
	display: flex;
	justify-content: center;
}

.menu__wrapp-nav-item:hover {
	background: #009ed1;
	color: #fff;
}

.item-blue {
	background: #009ed1;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #fff;
}

.menu__wrapp-nav-item-link-dashboard {
	width: 260px;
	color: inherit;
	background: url("~@/assets/img/account-img/menu/dashboard.svg");
	background-repeat: no-repeat;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-friends {
	width: 260px;
	color: inherit;
	background-image: url("~@/assets/img/account-img/menu/friends.png");
	background-repeat: no-repeat;
	background-size: 24px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-communities {
	width: 260px;
	color: inherit;
	background-image: url("~@/assets/img/account-img/menu/communities.png");
	background-repeat: no-repeat;
	background-size: 24px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}


.menu__wrapp-nav-item-link-settings {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/settings.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-documents {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/documents.svg");
	background-repeat: no-repeat;
	width: 260px;
	text-align: center;
	background-position: 0px;
	background-position-y: center;
}

.menu__wrapp-nav-item-link-rating {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/rating.svg");
	background-repeat: no-repeat;
	width: 260px;
	text-align: center;
	background-position: 0px;
	background-position-y: center;
}

.menu__wrapp-nav-item-link-tests {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/tests.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-interview {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/video.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-vacancies {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/surch.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-favorites {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/surch.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-recommendations {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/recomendation.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-rates {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/like.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: center;
}

.menu__wrapp-nav-item-link-faq {
	color: inherit;
	width: 260px;
	background: url("~@/assets/img/account-img/menu/question.svg");
	background-repeat: no-repeat;
	text-align: center;
	background-position: 0px;
	background-position-y: center;
}

.menu__wrapp-nav-item-link-tarif {
	width: 260px;
	color: inherit;
	background: url("~@/assets/img/account-img/menu/tarif.svg");
	background-repeat: no-repeat;
	text-align: center;
	background-position: 0px;
	background-position-y: center;
}

.menu__wrapp-chat-text {
	display: block;
	padding: 15px 0;
	background-image: url("~@/assets/img/account-img/menu/chat.png");
	background-position: 24px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: #ffffff;

	width: 100%;
	text-align: center;
	background-color: #009ed1;
	border-radius: 8px;
	box-shadow: 0px 3px 5px #009ed1;
	transition: opacity 1s ease;
}

.menu__wrapp-chat-text:hover {
	opacity: 0.5;
}

.menu__wrapp-resume-text {
	display: none;
	padding: 15px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	color: #009ed1;

	margin-top: 14px;
	width: 100%;
	letter-spacing: 0.02em;
	text-align: center;
	background: #e6f6fc;
	border: 1px solid #009ed1;
	border-radius: 8px;
	transition: opacity 1s ease;
}

.menu__wrapp-resume-text:hover {
	opacity: 0.5;
}

.menu__wrapp-leave-text {
	margin-bottom: 100px;
	padding: 15px 0;
	max-width: 100%;
	box-sizing: border-box;
	width: 100%;
	margin-top: 20px;
	background: rgba(209, 0, 62, 0.05000000074505806);
	border-radius: 8px;
	transition: opacity 1s ease;

	color: #e3668c;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
}

.menu__wrapp-leave-text:hover {
	opacity: 0.5;
}

.menu__wrapp-end {
	max-width: 340px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 20px;
	margin-top: 40px;
}


/* /On click */

/* Worker */
.menu-worker {
	position: absolute;
	top: 40px;
	right: 20px;
	width: 230px;
	background: #fafafa;
	border-radius: 0px 0px 7px 7px;

}

.menu-worker__wrapper {
	overflow-y: hidden;
	overflow-x: hidden;
	box-sizing: border-box;
	width: 230px;
}

.menu-worker__wrapper::-webkit-scrollbar {
	width: 10px;

	height: 8px;

	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.menu-worker__wrapper::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.item-main {
	display: none;
}

.item-main:hover a {
	background-image: url("~@/assets/img/account-img/menu/white-home-svgrepo-com.svg");
}

.item-main:hover {
	background-color: #009ed1;
	color: #fff;
}

@media (max-height: 850px) {
	.menu-worker__wrapper {
		height: calc(100vh - 120px);
	}

	.menu-worker__wrapper {
		overflow-y: scroll;
		scrollbar-width: thin;
	}
}

.menu-worker__wrapper-nav {
	margin-top: 11px;
	width: 100%;
	border: center;
	display: flex;
	padding: 0 10px;
	flex-direction: column;
	box-sizing: border-box;
}

.menu-worker__wrapper-nav :not(:last-child) {
	margin-bottom: 15px;
}

.menu-worker__wrapper-nav-item {
	color: #44444f;
	border-radius: 5px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	box-sizing: border-box;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
	display: flex;
	padding-left: 10px;
	text-align: left;
}

.menu-worker__wrapper-nav-item:hover {
	background: #009ed1;
	color: #fff;
}

.item-blue {
	background: #009ed1;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 15px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #fff;
}

.menu-worker__wrapper-nav-item-link-dashboard {
	width: 260px;
	color: inherit;
	background: url("~@/assets/img/account-img/menu/dashboard.svg");
	background-repeat: no-repeat;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-friends {
	width: 260px;
	color: inherit;
	padding-left: 30px;
	background-image: url("~@/assets/img/account-img/menu/friends.png");
	background-repeat: no-repeat;
	background-size: 24px;
	background-position: 0px;
	background-position-y: center;
}

.menu-worker__wrapper-nav-item-link-communities {
	width: 260px;
	color: inherit;
	padding-left: 30px;
	background-image: url("~@/assets/img/account-img/menu/communities.png");
	background-repeat: no-repeat;
	background-size: 24px;
	background-position: 0px;
	background-position-y: center;
}

.menu-worker__wrapper-nav-item-link-settings {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/settings.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-documents {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/documents.svg");
	background-repeat: no-repeat;
	width: 260px;
	text-align: left;
	background-position: -3px;
	background-position-y: center;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-rating {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/rating.svg");
	background-repeat: no-repeat;
	width: 260px;
	text-align: left;
	background-position: 0px;
	padding-left: 30px;
	background-position-y: center;
}

.menu-worker__wrapper-nav-item-link-tests {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/tests.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-interview {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/video.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-vacancies {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/surch.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-favorites {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/favorites.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-recommendations {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/recomendation.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-rates {
	color: inherit;
	background: url("~@/assets/img/account-img/menu/like.svg");
	background-repeat: no-repeat;
	width: 260px;
	background-position: 0px;
	background-position-y: center;
	text-align: left;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-faq {
	color: inherit;
	width: 260px;
	background: url("~@/assets/img/account-img/menu/question.svg");
	background-repeat: no-repeat;
	text-align: left;
	background-position: 0px;
	background-position-y: center;
	padding-left: 30px;
}

.menu-worker__wrapper-nav-item-link-main {
	color: inherit;
	width: 260px;
	background: url("~@/assets/img/account-img/menu/home-svgrepo-com.svg");
	background-repeat: no-repeat;
	text-align: left;
	background-position: 0px;
	background-position-y: center;
	padding-left: 30px;
}



.menu-worker__wrapper-nav-item-link-tarif {
	width: 260px;
	color: inherit;
	background: url("~@/assets/img/account-img/menu/tarif.svg");
	background-repeat: no-repeat;
	text-align: left;
	background-position: 0px;
	background-position-y: center;
	padding-left: 30px;
}

.menu-worker__wrapper-leave-text {
	padding: 15px 0;
	max-width: 100%;
	box-sizing: border-box;
	width: 100%;
	background: rgba(220, 11, 11, 0.1599999964237213);

	border: 1px solid rgba(220, 11, 11, 0.4);
	border-radius: 8px;
	transition: opacity 1s ease;
	color: #db0a0a;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
}

.menu-worker__wrapper-leave-text:hover {
	opacity: 0.5;
}

.menu-worker__wrapper-end {
	width: 100%;
	box-sizing: border-box;
	padding: 0 10px;
	margin: 17px 0 22px 0;
}

@media (max-width: 958px) {
	.menu-worker {
		top: 40px;
	}
}

@media (max-width: 768px) {
	.menu-worker {
		right: 0;
	}
}

@media (max-width: 350px) {
	.menu-worker__wrapper-chat-text {
		background-position: 103px;
	}
}

/* /Worker */

/* Modificators */
.item-white-dashboard {
	background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
	background-repeat: no-repeat;
}

.item-white-friends {
	background-image: url("~@/assets/img/account-img/menu/friends-white.png");
	background-repeat: no-repeat;
}

.item-white-communities {
	background-image: url("~@/assets/img/account-img/menu/communities-white.png");
	background-repeat: no-repeat;
}

.item-white-settings {
	background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
	background-repeat: no-repeat;
}

.item-white-documents {
	background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
	background-repeat: no-repeat;
}

.item-white-rating {
	background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
	background-repeat: no-repeat;
}

.item-white-tests {
	background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
	background-repeat: no-repeat;
}

.item-white-interview {
	background-image: url("~@/assets/img/account-img/menu/video-white.svg");
	background-repeat: no-repeat;
}

.item-white-vacancies {
	background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
	background-repeat: no-repeat;
}

.item-white-favorites {
	background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
	background-repeat: no-repeat;
}

.item-white-recommendations {
	background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
	background-repeat: no-repeat;
}

.item-white-rates {
	background-image: url("~@/assets/img/account-img/menu/like-white.svg");
	background-repeat: no-repeat;
}

.item-white-faq {
	background-image: url("~@/assets/img/account-img/menu/question-white.svg");
	background-repeat: no-repeat;
}

.item-white-faq {
	background-image: url("~@/assets/img/account-img/menu/question-white.svg");
	background-repeat: no-repeat;
}

.item-white-main {
	background-image: url("~@/assets/img/account-img/menu/white-home-svgrepo-com.svg");
	background-repeat: no-repeat;
}

.item-white-main {
	background-image: url("~@/assets/img/account-img/menu/white-home-svgrepo-com.svg");
	background-repeat: no-repeat;
}

.item-white-tarif {
	background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
	background-repeat: no-repeat;
}

/*  */

.item-dashboard:hover a {
	background-image: url("~@/assets/img/account-img/menu/dashboard-white.svg");
}

.item-dashboard:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-friends:hover a {
	background-image: url("~@/assets/img/account-img/menu/friends-white.png");
}

.item-friends:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-communities:hover a {
	background-image: url("~@/assets/img/account-img/menu/communities-white.png");
}

.item-communities:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-settings:hover a {
	background-image: url("~@/assets/img/account-img/menu/sittings-white.svg");
}

.item-settings:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-documents:hover a {
	background-image: url("~@/assets/img/account-img/menu/documents-white.svg");
}

.item-documents:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-rating:hover a {
	background-image: url("~@/assets/img/account-img/menu/rating-white.svg");
}

.item-rating:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-tests:hover a {
	background-image: url("~@/assets/img/account-img/menu/tests-white.svg");
}

.item-tests:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-interview:hover a {
	background-image: url("~@/assets/img/account-img/menu/video-white.svg");
}

.item-interview:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-vacancies:hover a {
	background-image: url("~@/assets/img/account-img/menu/surch-white.svg");
}

.item-vacancies:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-favorites:hover a {
	background-image: url("~@/assets/img/account-img/menu/favorites-white.svg");
}

.item-favorites:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-recommendations:hover a {
	background-image: url("~@/assets/img/account-img/menu/recomendation-white.svg");
}

.item-recommendations:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-rates:hover a {
	background-image: url("~@/assets/img/account-img/menu/like-white.svg");
}

.item-rates:hover {
	background-color: #009ed1;
	color: #fff;
}

.item-faq:hover a {
	background-image: url("~@/assets/img/account-img/menu/question-white.svg");
}

.item-faq:hover {
	background-color: #009ed1;
	color: #fff;
}



.item-tarif:hover a {
	background-image: url("~@/assets/img/account-img/menu/tarif-white.svg");
}

.item-tarif:hover {
	background-color: #009ed1;
	color: #fff;
}

/* /Modificators */
</style>
