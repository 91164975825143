export function inputParse(text) {
    text = text.slice().replaceAll('<title>', '<h1>');
    text = text.slice().replaceAll('</title>', '</h1>');
    text = text.slice().replaceAll('<img>', '<img src="');
    text = text.slice().replaceAll('</img>', '">');
    return text;
}

export function outputParse(text) {
    text = text.slice().replaceAll('<h1>', '<title>');
    text = text.slice().replaceAll('</h1>', '</title>');
    let data = new DOMParser().parseFromString(text, 'text/html');
    let images = data.getElementsByTagName('img');
    const regex = /<img[^>]+>/;
    for (let i = 0; i < images.length; i++) {
        text = text.slice().replace(regex, '<img>' + images[i].src + '</img>');
    }
    return text;
}