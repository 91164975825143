<template>
  <div>
    <HeaderAccountWorker></HeaderAccountWorker>

    <main class="main">
      <div class="account">
        <MenuWorker></MenuWorker>
        <div class="container-account">
          <loading :active="isLoading" :is-full-page="true" />
          <p class="documents__text mobile">{{
            $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.personalArea') }}</p>
          <h1 class="documents__title-first">{{
            $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.myDocuments') }}</h1>
          <p class="documents__text desctop">{{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.welcome') }}
          </p>

          <div class="documents-content">
            <h2 class="documents__title">{{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.school') }}</h2>

            <div class="documents__line">
              <div class="documents-wrapper">
                <button class="delete-document" v-if="false">
                  <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
                </button>
                <h3 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.averageSchoolCertificateScore') }}
                </h3>
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="school-attest"
                      :url="getDocumentsData.school.cr1.docUrl" :status="getDocumentsData.school.cr1.status">
                    </SeparateDocumentCopy>
                    <h3 class="document__subtitlte">{{
                      $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                    <select-form-copy :values="getCountries" field="school-country"
                      :status="getDocumentsData.school.cr1.status"
                      :current="getDocumentsData.school.country"></select-form-copy>
                    <h3 class="document__subtitlte">{{
                      $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.ballroomSystem') }}</h3>
                    <select-form-copy @changePointSystem="$refs.school.changePointSystem()" :values="systemPoints"
                      field="school-system" :status="getDocumentsData.school.cr1.status"
                      :current="getCurrentSystemPoint(getDocumentsData.school.pointSystem)"></select-form-copy>
                  </div>
                  <div class="document-right">
                    <h3 class="document__subtitlte mt-0">
                      {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.contentOfDocument') }}
                    </h3>
                    <school-atestate-copy ref="school" field="school-attest"
                      :values="getDocumentsData.school.cr1.attestat" :status="getDocumentsData.school.cr1.status">
                    </school-atestate-copy>
                  </div>
                </div>
                <div class="document-wrapper-filling" v-show="item?.category === 'cr1'"
                  v-for="(item, index) in getDocumentsData?.additionUniversity" :key="item.id ? item.id : index">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="school-attest" :url="item.docUrl"
                      :index="index" :status="item.status">
                    </SeparateDocumentCopy>
                    <h3 class="document__subtitlte">{{
                      $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                    <select-form-copy :values="getCountries" field="school-country" :status="item.status" :index="index"
                      :current="item.country"></select-form-copy>
                    <h3 class="document__subtitlte">{{
                      $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.ballroomSystem') }}</h3>
                    <select-form-copy @changePointSystem="$refs[`school${index}`][0]?.changePointSystem()"
                      :values="systemPoints" :index="index" field="school-system" :status="item.status"
                      :current="getCurrentSystemPoint(item.pointSystem)"></select-form-copy>
                  </div>
                  <div class="document-right">
                    <h3 class="document__subtitlte mt-0">
                      {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.contentOfDocument') }}
                    </h3>
                    <school-atestate-copy :ref="`school${index}`" field="school-attest" :index="index"
                      :values="item.attestat" :status="item.status">
                    </school-atestate-copy>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button v-if="getDocumentsData?.additionUniversity?.filter(item => item?.category === 'cr1')?.length < 5"
                    class="button-add-row" tabindex="-1" @click="
                      $store.commit('addUniversityInList', 'cr1')">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>

            <div class="documents__line">
              <div class="documents-wrapper">
                <button class="delete-document" v-if="false">
                  <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
                </button>
                <h3 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.schoolEndingWithDegreeOfDifference') }}
                </h3>
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="school-difference"
                      :url="getDocumentsData.school.cr2.docUrl" :status="getDocumentsData.school.cr2.status">
                    </SeparateDocumentCopy>
                  </div>
                  <div class="document-right mw-273">
                    <h3 class="document__subtitlte mt-0">
                      {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.contentOfDocument') }}
                    </h3>
                    <select-form-copy :values="difference" field="school-difference"
                      :status="getDocumentsData.school.cr2.status"
                      :current="getCurrentDifference(getDocumentsData.school.cr2.difference)"></select-form-copy>
                  </div>
                </div>
                <div class="document-wrapper-filling" v-for="(item, index) in getDocumentsData?.additionalDoc"
                  :key="item.id ? item.id : index" v-show="item.category === 'cr2'">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="school-difference"
                      :url="item?.sideData?.docUrl" :index="index" :status="item.status">
                    </SeparateDocumentCopy>
                  </div>
                  <div class="document-right mw-273">
                    <h3 class="document__subtitlte mt-0">
                      {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.contentOfDocument') }}
                    </h3>
                    <select-form-copy :values="difference" field="school-difference" :status="item.status"
                      :index="index" :current="getCurrentDifference(item?.sideData?.difference)"></select-form-copy>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr2')?.length < 5"
                    class="button-add-row" tabindex="-1" @click="
                      $store.commit('addDocumentInAdditionalData', { category: 'cr2', componentContext })">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div class="documents__line">
            <h2 class="documents__title">{{
              $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.averageScoreAtUniversity') }}</h2>
            <div class="documents-wrapper">
              <div class="university">
                <div class="document-wrapper-column">
                  <button class="delete-document delete-document__col" v-if="false">
                    <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
                  </button>
                  <h3 class="documents-wrapper__title mt0">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.nameOfUniversity') }}
                  </h3>
                  <div class="document-wrapper-filling">
                    <div class="document-left">
                      <select-form-copy style="margin-bottom: 20px; margin-top: 0" :values="getUniversity"
                        field="university-name" :status="getDocumentsData.university.cr3.status"
                        :current="getDocumentsData.university.name" :find="true"></select-form-copy>
                      <SeparateDocumentCopy :context="componentContext" field="university-attest"
                        :url="getDocumentsData.university.cr3.docUrl" :status="getDocumentsData.university.cr3.status">
                      </SeparateDocumentCopy>
                      <h3 class="document__subtitlte">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                      <select-form-copy :values="getCountries" field="university-country"
                        :status="getDocumentsData.university.cr3.status"
                        :current="getDocumentsData.university.country"></select-form-copy>
                      <h3 class="document__subtitlte">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.ballroomSystem') }}</h3>
                      <select-form-copy @changePointSystem="
                        $refs.university.changePointSystem()
                        " :values="systemPoints" field="university-system"
                        :status="getDocumentsData.university.cr3.status"
                        :current="getCurrentSystemPoint(getDocumentsData.university.pointSystem)"></select-form-copy>
                      <h3 class="document__subtitlte">
                        {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.stepOfHigherEducation') }}
                      </h3>
                      <select-form-copy @changePointSystem=" $refs.university.changePointSystem()" :values="[
                        { id: '0', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.bachelor'), value: 1 },
                        { id: '1', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.mastersDegree'), value: 2 },
                        { id: '2', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.specialty'), value: 3 },
                        { id: '3', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.graduateSchool'), value: 4 },
                        { id: '4', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.phdProgram'), value: 5 },
                      ]" field="university-education-level" :status="getDocumentsData.university.cr3.status"
                        :current="getDocumentsData.university.educationLevel"></select-form-copy>
                    </div>
                    <div class="document-right">
                      <school-atestate-copy style="margin-top: 0" ref="university" field="university-attest"
                        :values="getDocumentsData.university.cr3.attestat"
                        :status="getDocumentsData.university.cr3.status">
                      </school-atestate-copy>
                    </div>
                  </div>
                </div>
                <div class="document-wrapper-column" v-show="!item.category || item?.category === 'cr3'"
                  v-for="(item, index) in getDocumentsData?.additionUniversity" :key="item.id ? item.id : index">
                  <button class="delete-document delete-document__col" v-if="false">
                    <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
                  </button>
                  <h3 class="documents-wrapper__title mt0">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.nameOfUniversity') }}
                  </h3>
                  <div class="document-wrapper-filling">
                    <div class="document-left">
                      <select-form-copy style="margin-bottom: 20px; margin-top: 0" :values="getUniversity"
                        field="university-name" :index="index" :status="item.status" :current="item.name"
                        :find="true"></select-form-copy>
                      <SeparateDocumentCopy :context="componentContext" field="university-attest" :url="item.docUrl"
                        :index="index" :status="item.status">
                      </SeparateDocumentCopy>
                      <h3 class="document__subtitlte">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                      <select-form-copy :values="getCountries" field="university-country" :status="item.status"
                        :index="index" :current="item.country"></select-form-copy>
                      <h3 class="document__subtitlte">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.ballroomSystem') }}</h3>
                      <select-form-copy @changePointSystem="
                        $refs[`university${index}`][0].changePointSystem()
                        " :values="systemPoints" field="university-system" :status="item.status"
                        :current="getCurrentSystemPoint(item.pointSystem)" :index="index"></select-form-copy>
                      <h3 class="document__subtitlte">
                        {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.stepOfHigherEducation') }}
                      </h3>
                      <select-form-copy @changePointSystem="
                        $refs[`university${index}`][0].changePointSystem()
                        " :values="[{ id: '0', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.bachelor'), value: 1 },
                        { id: '1', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.mastersDegree'), value: 2 },
                        { id: '2', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.specialty'), value: 3 },
                        { id: '3', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.graduateSchool'), value: 4 },
                        { id: '4', name: $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.phdProgram'), value: 5 },
                        ]" field="university-education-level" :index="index" :status="item.status"
                        :current="item.educationLevel"></select-form-copy>
                    </div>
                    <div class="document-right">
                      <school-atestate-copy style="margin-top: 0" :ref="`university${index}`" field="university-attest"
                        :values="item.attestat" :index="index" :status="item.status">
                      </school-atestate-copy>
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button
                    v-if="getDocumentsData.additionUniversity?.filter(item => !item.category || item.category === 'cr3')?.length < 5"
                    class="button-add-row" tabindex="-1" @click="
                      $store.commit('addUniversityInList', 'cr3')">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.graduationWithDegreeOfDifference') }}
                </h2>
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="university-difference"
                      :url="getDocumentsData.university.cr4.docUrl" :status="getDocumentsData.university.cr4.status">
                    </SeparateDocumentCopy>
                    <select-form-copy :values="difference" field="university-difference"
                      :status="getDocumentsData.university.cr4.status"
                      :current="getCurrentDifference(getDocumentsData.university.cr4.difference)"></select-form-copy>
                  </div>
                </div>
                <div class="document-wrapper-filling" v-for="(item, index) in getDocumentsData?.additionalDoc"
                  :key="item.id ? item.id : index" v-show="item.category === 'cr4'">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="university-difference"
                      :url="item?.sideData?.docUrl" :index="index" :status="item.status"></SeparateDocumentCopy>
                    <select-form-copy :values="difference" field="university-difference" :status="item.status"
                      :index="index" :current="getCurrentDifference(item?.sideData?.difference)"></select-form-copy>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr4')?.length < 5"
                    class="button-add-row" tabindex="-1" @click="
                      $store.commit('addDocumentInAdditionalData', { category: 'cr4', componentContext })">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.nameScholarships') }}
                </h2>
                <div class="document-left document-left-group">
                  <div class="scholarships-wrapper">
                    <div class="scholarships-element" v-for="(item, index) in getDocumentsData.technicalSkills
                      .cr5.grant" :key="item.id">
                      <SeparateDocumentCopy :context="componentContext" field="university-name-stipend"
                        :url="item.docUrl" :status="item.status" :index="index"></SeparateDocumentCopy>
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button class="button-add-row" tabindex="-1" @click="
                    $store.commit(
                      'addDocumentsDataTable',
                      { field: 'university-name-stipend' }
                    )
                    ">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper" style="display: flex; flex-direction: column">
              <button v-if="getDocumentsData.technicalSkills.cr6.value || getDocumentsData.technicalSkills.cr6.url"
                class="delete-document" @click="$store.commit('deleteDocuments', { field: 'citation-index' })">
                <img src="~@/assets/img/all-img/documents/close.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">{{
                $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.citationIndex') }}</h2>
              <div class="flex-documents">
                <div class="document-input-wrapper">
                  <h3 class="document__subtitlte mt-0">{{
                    $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                  <input class="document-input" :value="getDocumentsData.technicalSkills.cr6.url" @change="(e) =>
                    $store.commit('setDocumentsDataSelect', {
                      field: 'technical-quote-url',
                      value: e.target.value,
                    })
                    " :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.linkToPlatform')"
                    :disabled="getDocumentsData.technicalSkills.cr6.status ===
                      'checking' ||
                      getDocumentsData.technicalSkills.cr6.status === 'approved'
                      " />
                </div>
                <div class="document-input-wrapper">
                  <h3 class="document__subtitlte mt-0-20">{{
                    $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                  <input class="document-input" :value="getDocumentsData.technicalSkills.cr6.value"
                    @keydown="numberValidation" @change="(e) =>
                      $store.commit('setDocumentsDataSelect', {
                        field: 'technical-quote-value',
                        value: e.target.value,
                      })"
                    :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.citationIndex')"
                    :disabled="getDocumentsData.technicalSkills.cr6.status === 'checking' || getDocumentsData.technicalSkills.cr6.status === 'approved'" />
                </div>
              </div>

            </div>
            <div class="documents-wrapper" style="display: flex; flex-direction: column"
              v-for="(item, index) in getDocumentsData?.additionalDoc" :key="item.id ? item.id : index"
              v-show="item.category === 'cr6'">
              <button v-if="item?.sideData?.value || item?.sideData?.url" class="delete-document"
                @click="$store.commit('deleteDocuments', { field: 'citation-index', index: index })">
                <img src="~@/assets/img/all-img/documents/close.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">{{
                $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.citationIndex') }}</h2>
              <div class="flex-documents">
                <div class="document-input-wrapper">
                  <h3 class="document__subtitlte mt-0">{{
                    $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                  <input class="document-input" :value="item?.sideData?.url" @change="(e) =>
                    $store.commit('setDocumentsDataSelect', {
                      field: 'technical-quote-url',
                      value: e.target.value,
                      index: index
                    })
                    " :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.linkToPlatform')"
                    :disabled="item.status ===
                      'checking' ||
                      item.status === 'approved'
                      " />
                </div>
                <div class="document-input-wrapper">
                  <h3 class="document__subtitlte mt-0-20">{{
                    $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                  <input class="document-input" :value="item?.sideData?.value" @keydown="numberValidation" @change="(e) =>
                    $store.commit('setDocumentsDataSelect', {
                      field: 'technical-quote-value',
                      value: e.target.value,
                      index: index
                    })"
                    :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.citationIndex')"
                    :disabled="item.status === 'checking' || item.status === 'approved'" />
                </div>
              </div>

            </div>
            <div class="button-add-row-wrapper">
              <button v-if="getDocumentsData?.additionalDoc?.filter(item => item?.category === 'cr6')?.length < 5"
                class="button-add-row" tabindex="-1" @click="
                  $store.commit('addDocumentInAdditionalData', { category: 'cr6', componentContext })">
                {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                <span class="button-add-row__icon"></span>
              </button>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.participationAndPrizes') }}
                </h2>
                <div class="document-left document-left-group">
                  <div class="scholarships-wrapper">
                    <div class="scholarships-element scholarships-element-select" v-for="(item, index) in getDocumentsData.technicalSkills
                      .cr7.competition" :key="item.id">
                      <SeparateDocumentCopy :context="componentContext" field="technical-event" :url="item.docUrl"
                        :status="item.status" :index="index"></SeparateDocumentCopy>
                      <select-form-copy :values="['Prize place', 'Participation']" field="technical-event"
                        :current="item.position" :index="index" :status="item.status"></select-form-copy>
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button class="button-add-row" tabindex="-1" @click="
                    $store.commit('addDocumentsDataTable', { field: 'technical-event' })
                    ">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.participationAndPrizesProfessional') }}
                </h2>
                <div class="document-left document-left-group">
                  <div class="scholarships-wrapper">
                    <div class="scholarships-element scholarships-element-select" v-for="(item, index) in getDocumentsData.technicalSkills
                      .cr8.competition" :key="item.id">
                      <SeparateDocumentCopy :context="componentContext" field="technical-event-professional"
                        :url="item.docUrl" :status="item.status" :index="index">
                      </SeparateDocumentCopy>
                      <select-form-copy :values="['Prize place', 'Participation']" field="technical-event-professional"
                        :current="item.position" :index="index" :status="item.status"></select-form-copy>
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button class="button-add-row" tabindex="-1" @click="
                    $store.commit(
                      'addDocumentsDataTable',
                      { field: 'technical-event-professional' }
                    )
                    ">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">
                {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.presenceOfProfessionalCertificate') }}
              </h2>
              <div class="document-wrapper-column">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="technical-certificate"
                      :url="getDocumentsData.technicalSkills.cr9.docUrl"
                      :status="getDocumentsData.technicalSkills.cr9.status"></SeparateDocumentCopy>
                  </div>
                  <div class="document-right">
                    <div class="document-input-wrapper">
                      <h3 class="document__subtitlte mt-0">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                      <input class="document-input"
                        :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.name')"
                        :disabled="getDocumentsData.technicalSkills.cr9.status ===
                          'checking' ||
                          getDocumentsData.technicalSkills.cr9.status ===
                          'approved'
                          " :value="getDocumentsData.technicalSkills.cr9.name"
                        @change="(e) => $store.commit('setDocumentsDataSelect', { field: 'technical-certificate', value: e.target.value, })" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="document-wrapper-column" v-for="(item, index) in getDocumentsData?.additionalDoc"
                :key="item.id ? item.id : index" v-show="item.category === 'cr9'">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="technical-certificate"
                      :url="item?.sideData?.docUrl" :index="index" :status="item.status"></SeparateDocumentCopy>
                  </div>
                  <div class="document-right">
                    <div class="document-input-wrapper">
                      <h3 class="document__subtitlte mt-0">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.side') }}</h3>
                      <input class="document-input"
                        :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.name')"
                        :disabled="item.status ===
                          'checking' ||
                          item.status ===
                          'approved'
                          " :value="item?.sideData?.name"
                        @change="(e) => $store.commit('setDocumentsDataSelect', { field: 'technical-certificate', value: e.target.value, index: index })" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-add-row-wrapper">
                <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr9')?.length < 5"
                  class="button-add-row" tabindex="-1" @click="
                    $store.commit('addDocumentInAdditionalData', { category: 'cr9', componentContext })">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                  <span class="button-add-row__icon"></span>
                </button>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">{{
                $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.availabilityOfWorkExperience') }}</h2>
              <div class="document-wrapper-column">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="technical-experience"
                      :url="getDocumentsData.technicalSkills.cr10.docUrl"
                      :status="getDocumentsData.technicalSkills.cr10.status"></SeparateDocumentCopy>
                  </div>
                  <div class="document-right">
                    <div class="document-input-wrapper">
                      <h3 class="document__subtitlte mt-0">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.company') }}</h3>
                      <select-form-copy :values="getCompanies" field="technical-experience"
                        :status="getDocumentsData.technicalSkills.cr10.status"
                        :current="getDocumentsData.technicalSkills.cr10.name" :find="true"></select-form-copy>
                    </div>
                  </div>
                </div>
              </div>
              <div class="document-wrapper-column" v-for="(item, index) in getDocumentsData?.additionalDoc"
                :key="item.id ? item.id : index" v-show="item.category === 'cr10'">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="technical-experience"
                      :url="item?.sideData?.docUrl" :index="index" :status="item.status"></SeparateDocumentCopy>
                  </div>
                  <div class="document-right">
                    <div class="document-input-wrapper">
                      <h3 class="document__subtitlte mt-0">{{
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.company') }}</h3>
                      <select-form-copy :values="getCompanies" field="technical-experience" :status="item.status"
                        :current="item?.sideData?.name" :index="index" :find="true"></select-form-copy>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-add-row-wrapper">
                <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr10')?.length < 5"
                  class="button-add-row" tabindex="-1" @click="
                    $store.commit('addDocumentInAdditionalData', { category: 'cr10', componentContext })">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                  <span class="button-add-row__icon"></span>
                </button>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">
                {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.professorsRecommendedLetter') }}
              </h2>
              <div class="document-wrapper-column">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="university-message"
                      :url="getDocumentsData.university.cr11.docUrl" :status="getDocumentsData.university.cr11.status">
                    </SeparateDocumentCopy>
                  </div>
                </div>
              </div>
              <div class="document-wrapper-column" v-for="(item, index) in getDocumentsData?.additionalDoc"
                :key="item.id ? item.id : index" v-show="item.category === 'cr11'">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="university-message"
                      :url="item?.sideData?.docUrl" :index="index" :status="item.status">
                    </SeparateDocumentCopy>
                  </div>
                </div>
              </div>
              <div class="button-add-row-wrapper">
                <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr11')?.length < 5"
                  class="button-add-row" tabindex="-1" @click="
                    $store.commit('addDocumentInAdditionalData', { category: 'cr11', componentContext })">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                  <span class="button-add-row__icon"></span>
                </button>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <h2 class="documents-wrapper__title">
                {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.recommendedLetterOfEmployer') }}
              </h2>
              <div class="document-wrapper-column">
                <div class="document-wrapper-filling">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="personal-message"
                      :url="getDocumentsData.personalQualities.cr12.docUrl"
                      :status="getDocumentsData.personalQualities.cr12.status"></SeparateDocumentCopy>
                    <select-form-copy :values="getCompanies" field="personal-message"
                      :current="getDocumentsData.personalQualities.cr12.name" :find="true"
                      :status="getDocumentsData.personalQualities.cr12.status"></select-form-copy>
                  </div>
                </div>
                <div class="document-wrapper-filling" v-for="(item, index) in getDocumentsData?.additionalDoc"
                  :key="item.id ? item.id : index" v-show="item.category === 'cr12'">
                  <div class="document-left">
                    <SeparateDocumentCopy :context="componentContext" field="personal-message" :index="index"
                      :url="item?.sideData?.docUrl" :status="item.status"></SeparateDocumentCopy>
                    <select-form-copy :values="getCompanies" field="personal-message" :index="index"
                      :current="item?.sideData?.name" :find="true" :status="item.status"></select-form-copy>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button v-if="getDocumentsData?.additionalDoc?.filter(item => item.category === 'cr12')?.length < 5"
                    class="button-add-row" tabindex="-1" @click="
                      $store.commit('addDocumentInAdditionalData', { category: 'cr12', componentContext })">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.recommendationsOfUser') }}
                </h2>
                <div class="document-left document-left-group">
                  <div class="scholarships-wrapper">
                    <div class="scholarships-element scholarships-element-select" v-for="(item, index) in getDocumentsData.personalQualities
                      .cr13.message" :key="item.id">
                      <SeparateDocumentCopy :context="componentContext" field="personal-message-user" :url="item.docUrl"
                        :status="item.status" :index="index"></SeparateDocumentCopy>
                      <input :disabled="item.status === 'approved' ||
                        item.status === 'checking'
                        " class="document-input"
                        :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.description')"
                        :value="item.description" @change="(e) =>
                          $store.commit('setDocumentsDataSelect', {
                            field: 'personal-message-user',
                            value: e.target.value,
                            index: index,
                          })
                          " />
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button class="button-add-row" v-if="getDocumentsData.personalQualities.cr13.message.length < 5
                  " tabindex="-1" @click="
                      $store.commit(
                        'addDocumentsDataTable',
                        { field: 'personal-message-user' }
                      )
                      ">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.volunteerActivities') }}
                </h2>
                <div class="document-left document-left-group">
                  <div class="scholarships-wrapper">
                    <div class="scholarships-element scholarships-element-select" v-for="(item, index) in getDocumentsData.personalQualities
                      .cr14.competition" :key="item.id">
                      <input :disabled="item.status === 'approved' ||
                        item.status === 'checking'
                        " class="document-input mt-0 mb-20"
                        :placeholder="$t('pages.account.account_worker.accountWorkerDocumentsPageCopy.name')"
                        :value="item.name" @change="(e) =>
                          $store.commit('setDocumentsDataSelect', {
                            field: 'personal-event',
                            value: e.target.value,
                            index: index,
                          })
                          " />
                      <SeparateDocumentCopy :context="componentContext" field="personal-event" :url="item.docUrl"
                        :status="item.status" :index="index"></SeparateDocumentCopy>
                      <select-form-copy :status="item.status" :values="[
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.improvingEcology'),
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.socialSphere'),
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.formationOfEffectiveInstitutions'),
                        $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.improvingQualityOfLife'),
                      ]" field="personal-event-select" :current="item.theme" :index="index"></select-form-copy>
                    </div>
                  </div>
                </div>
                <div class="button-add-row-wrapper">
                  <button class="button-add-row" tabindex="-1" @click="
                    $store.commit('addDocumentsDataTable', { field: 'personal-event' })
                    ">
                    {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.addNote') }}
                    <span class="button-add-row__icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line">
            <div class="documents-wrapper">
              <button class="delete-document" v-if="false">
                <img src="~@/assets/img/vacancy/delete.svg" alt="" class="delete-document-img">
              </button>
              <div class="document-wrapper-column">
                <h2 class="documents-wrapper__title">
                  {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.numberOfSubscribers') }}
                </h2>
                <div class="document-left document-left-group">
                  <socials-table-copy field="personal-social" :values="getDocumentsData.personalQualities.cr15.socialNetwork
                    "></socials-table-copy>
                </div>
              </div>
            </div>
          </div>

          <div class="documents__line all-save-line">
            <button @click="saveDataRequest" class="all-save">
              {{ $t('pages.account.account_worker.accountWorkerDocumentsPageCopy.saveDocuments') }}
            </button>
          </div>
        </div>
      </div>
    </main>
    <DocumentDelete @approve="approveDeletingDocument" v-if="documentToBeDeleted"
      @cancel="$store.commit('setDocumentBeDeleted', null)">
    </DocumentDelete>
  </div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import DocumentDelete from "@/components/account/account_worker/modals/item/DocumentDelete";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectFormCopy from "@/components/account/account_worker/account_worker_documents/itemCopy/SelectFormCopy";
import { mapGetters } from "vuex";
import SeparateDocumentCopy
  from "@/components/account/account_worker/account_worker_documents/itemCopy/SeparateDocumentCopy";
import SchoolAtestateCopy
  from "@/components/account/account_worker/account_worker_documents/itemCopy/SchoolAtestateTableCopy";
import SocialsTableCopy from "@/components/account/account_worker/account_worker_documents/itemCopy/SocialsTableCopy";

export default {
  computed: {
    ...mapGetters([
      "getDocumentsData",
      "getCountries",
      "getCompanies",
      "getDifference",
      "getSystemPoints",
      "getUniversity",
      "isLoading",
      "documentToBeDeleted"
    ]),
    systemPoints(){
      return this.$store.getters.getSystemPoints.map(e=>this.currentLang ==='en' ? {id:e.id, name:e.name} : {id:e.id, name:e.name_ru})
    },
    difference(){
      return this.$store.getters.getDifference.map(e=>this.currentLang ==='en' ? {id:e.id, name:e.name} : {id:e.id, name:e.name_ru})
    },
    componentContext() {
      return this;
    },
    currentLang(){
      return this.$i18n.locale;
    }
  },
  watch:{
    currentLang(){
      this.$store.dispatch("fetchDifference", this.$i18n.locale);
      this.$store.dispatch("fetchSystemPoints", this.$i18n.locale);
    }
  },
  components: {
    SocialsTableCopy,
    SchoolAtestateCopy,
    SeparateDocumentCopy,
    SelectFormCopy,
    Loading,
    MenuWorker,
    HeaderAccountWorker,
    DocumentDelete
  },
  name: "AccountWorkerDocumentsPageCopy",
  beforeMount() {
    this.$store.dispatch("fetchCountries");
    this.$store.dispatch("fetchDifference", this.$i18n.locale);
    this.$store.dispatch("fetchSystemPoints", this.$i18n.locale);
    this.$store.dispatch("fetchCompanies");
    this.$store.dispatch("fetchUniversity");
    this.$store.dispatch("fetchDocuments");



  },
  methods: {
    approveDeletingDocument() {
      this.$store.dispatch('deleteDocument');
    },
    numberValidation(e) {
      if (e)
        if (e.code !== "Backspace") if (!/\d/.test(e.key)) e.preventDefault();
    },
    getCurrentSystemPoint(value) {
        return this.systemPoints.find(e => e.id === value) || null;
    },
    getCurrentDifference(value) {
        return this.difference.find(e => e.id === value) || null;
    },
    saveDataRequest() {
      this.$store.dispatch("saveDocumentData", {
        files: () => {
          this.$store.dispatch("uploadDocumentDataFiles", () =>
            this.$store.dispatch("saveDocumentData", {
              doc: () => this.$store.dispatch("fetchDocuments"),
            })
          )
          //	this.replaceUniversityName()
        },
        doc: () => {
          this.$store.dispatch("fetchDocuments")

          //			this.replaceUniversityName()
        },
      });

    },
    // fetchUniversityById(id) {
    // 			const req = new XMLHttpRequest();
    // 			const url = config.apiPrefix + 'system/university?id=' + id;
    // 			req.open('GET', url, false);
    // 			req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
    // 			try {
    // 					req.send();
    // 					const {data} = JSON.parse(req.response);
    // 					return data;

    // 			} catch (e) {
    // 					console.log(e);
    // 			}
    // 	},
    // 	replaceUniversityName(){
    // 		console.log(this.getDocumentsData)
    // 	if(this.getDocumentsData.university.name!=null){
    // 		this.getDocumentsData.university.name = this.fetchUniversityById(this.getDocumentsData.university.name)
    // 	}
    // 	if(this.getDocumentsData.additionUniversity!=null){
    // 		for(let i =0;i<this.getDocumentsData.additionUniversity.length;i++){
    // 			this.getDocumentsData.additionUniversity[i].name = this.fetchUniversityById(this.getDocumentsData.additionUniversity[i].name)
    // 		}

    // 	}

    // 	}
  },
};
</script>


<style scoped>
.account {
  display: flex;
}

.documents-content {
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #cecece;
}

.documents__title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #3b4c68;
  margin-bottom: 20px;
}

.document__subtitlte {
  margin-top: 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #171725;
}

.documents__title-first {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.documents__text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.documents-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
  padding: 30px 20px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 8px;
  position: relative;
}

.document-wrapper-column {
  position: relative;
}

.delete-document {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
}

.delete-document__col {
  right: 0;
  top: 0;
}

.delete-document-img {
  max-width: 100%;
  width: 17px;
  height: auto;
}

.university {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.document-wrapper-filling {
  display: flex;
  margin-top: 30px;
  gap: 20px;
  align-items: flex-start;
}

.documents-wrapper__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.document-left {
  max-width: 273px;
  width: 100%;
  gap: 20px;
}

.document-left-group {
  max-width: 100%;
  margin-top: 30px;
}

.document-right {
  max-width: 100%;
  width: 100%;
  margin-left: 20px;
}

.document-right-small {
  max-width: 273px;
  margin-left: auto;
}

.mw-273 {
  max-width: 273px;
}

.documents__line {
  margin-top: 20px;
}

.all-save-line {
  margin-top: 40px;
}

.button-add-row-wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 13px 0;
  border: 1px solid #efeff4;
  border-radius: 8px;
  margin-top: 20px;
}

.button-add-row {
  margin: 0 auto;
  display: flex;
  background: #009ed1;
  border-radius: 7px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  padding: 8px 60px;
  color: #ffffff;
  align-items: center;
}

.button-add-row__icon {
  display: flex;
  width: 28px;
  height: 28px;
  background: url(../../../assets/img/all-img/pkus.svg);
  flex-shrink: 0;
}

.documents__line-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b4c68;
}

.documents__line-container {
  margin-top: 15px;
  width: 100%;
  height: 6px;
  background: #eceef1;
  border-radius: 55px;
}

.documents__line-container-filling {
  height: 6px;
  background: #14c686;
  border-radius: 55px;
}

.documents__group {
  margin-top: 67px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
}

.mobile {
  display: none;
}

.mb20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.documents-wrapper-textarea textarea ::placeholder {
  color: #c7c7cc;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.documents-wrapper-textarea textarea {
  margin-left: 40px;
  display: flex;
}

.documents-wrapper-textarea textarea {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  padding: 13px 16px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  height: 342px;
}

.documents-wrapper input[type="radio"] {
  display: none;
}

.documents-wrapper label {
  display: inline-block;
  cursor: pointer;
  position: relative;

  margin-right: 60px;
  line-height: 18px;
  user-select: none;
}

.documents-wrapper label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 48px;
  bottom: -5px;
  border-radius: 100%;
  padding: 5px;
  border: 2px solid rgba(59, 76, 104, 0.5);
  box-sizing: border-box;
}

/* Checked */

.documents-wrapper input[type="radio"]:checked+label:before {
  background: #009ed1;
  border: none;
}

.all-save {
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 0px;
  display: flex;
  margin-top: 20px;
  background: #009ed1;
  border-radius: 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  padding: 15px 30px;
  color: #ffffff;
  width: 220px;
  text-align: center;
  display: block;
}

.mb20 {
  margin-bottom: 20px;
}

/* new documents */
.document-input ::placeholder {
  color: rgba(23, 23, 37, 0.20000000298023224);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.document-input-wrapper {
  max-width: 100%;
  width: 100%;
}

.document-input {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 13px 16px;
  border: 1px solid #efeff4;
  border-radius: 8px;
}

.scholarships-element {
  width: 100%;
  max-width: 327px;
  box-sizing: border-box;
  border: none;
  display: flex;
}

.scholarships-element-select {
  flex-direction: column;
}

.scholarships-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.flex-documents {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}

.max300 {
  max-width: 300px;
}

.mt0 {
  margin-top: 0;
}

.mt-0-20 {
  margin-top: 0px;
}

@media (max-width: 1670px) {
  .documents__group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1100px) {
  .scholarships-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 950px) {
  .table {
    display: flex;
  }

  .document__subtitlte {
    text-align: left;
  }

  .documents-wrapper {
    margin-top: 20px;
    padding: 30px 10px 30px 10px;
    flex-direction: column;
  }

  .documents__line {
    margin-top: 30px;
    max-width: 500px;
    width: 100%;
    margin: 30px auto 0 auto;
  }

  .document-wrapper-filling {
    flex-direction: column;
  }

  .document-left {
    max-width: 100%;
  }

  .document-right {
    margin-left: 0;
    margin-top: 20px;
  }

  .documents-wrapper__title {
    text-align: center;
    font-size: 16px;
  }

  .documents__group-element {
    padding: 20px 30px;
  }

  .documents__title-first {
    text-align: center;
  }

  .documents__title {
    text-align: center;
  }

  .profile-container {
    margin: 0 auto 0 auto;
  }

  .documents__group {
    grid-template-columns: repeat(2, 1fr);
  }

  .mobile {
    display: block;
  }

  .documents__text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .desctop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .documents {
    padding-top: 28px;
  }

  .documents__text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #009ed1;
  }

  .documents__title {
    text-align: center;
    font-size: 20px;
  }

  .documents__line {
    margin-top: 28px;
    text-align: center;
  }

  .documents__line-container {
    margin: 15px auto 0 auto;
    max-width: 300px;
  }

  .documents__group {
    margin-top: 40px;
    grid-template-columns: repeat(1, 1fr);
  }

  .documents-wrapper-textarea textarea {
    margin-left: 0;
    max-width: 300px;
    width: 100%;
    box-sizing: border-box;
    margin: 20px auto 0 auto;
  }

  .all-save {
    margin-top: 60px;
    width: 100%;
  }

  .wrapper-radio {
    flex-direction: row;
  }

  .pb50 {
    padding-bottom: 50px;
  }

  .pb30 {
    padding-bottom: 30px;
  }

  .scholarships-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .scholarships-element {
    padding-bottom: 20px;
    max-width: 100%;
  }

  .flex-documents {
    flex-direction: column;
    gap: 0;
  }

  .document-input {
    margin: 20px 0 0 0;
  }

  .documents__title {
    text-align: center;
    font-size: 16px;
  }

  .mw-273 {
    max-width: 100%;
  }

  .documents-content {
    margin-top: 40px;
    padding-bottom: 30px;
  }

  .mt0 {
    margin-bottom: 20px;
  }

  .documents__group-element {
    padding: 0;
  }

  .mt-0-20 {
    margin-top: 20px;
  }

  .delete-document {
    max-width: 22px;
    right: 6px;
    top: 10px;
  }

  .delete-document__col {
    right: 0;
    top: 0;
  }
}

@media (max-width: 330px) {
  .documents__text {
    text-align: left;
  }

  .documents__line {
    text-align: left;
  }

  .documents__title-first {
    text-align: left;
  }

  .all-save {
    width: 100%;
  }
}

.documents__group-element-caption-stage-circle {
  width: 11px;
  height: 11px;
  background: #c4c4c4;
  border-radius: 100%;
}

.stage-circle-green {
  background: #14c686;
}

.stage-circle-blue {
  background: #009ed1;
}

.stage-circle-red {
  background: #ff3c47;
}

.documents__group-element-caption-stage {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.documents__group-element-caption-stage-text {
  margin-left: 11px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3b4c68;
}

.mt-0 {
  margin-top: 0;
}

.mb-20 {
  margin-bottom: 20px;
}
</style>
