<template>
	<div>
		<ResponseMessage :vacancyId="vacancyId" :messageVisible="messageVisible" v-if="messageVisible"
			@displayMessage="unDisplayMessage"></ResponseMessage>

		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<div class="filling">
						<div class="caption">
							<div class="row tablet">
								<button class="back" @click="$router.go(-1)"></button>
								<p class="caption__subtitle">
									{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.vacancy') }}
								</p>
							</div>
							<h1 class="caption__title tablet">
								Head Lead Product Design
							</h1>
							<div class="caption__nomination">
								<div class="caption__nomination-left">
									<div class="company">
										<h3 class="company-title desctop">
											{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.company')
											}}
										</h3>
										<div class="caption__nomination-name">
											<div class="caption__nomination-imgcontainer">
												<img :src="vacancyData.companyAvatarUrl" alt="Company"
													class="caption__nomination-imgcontainer-img" />
											</div>
											<div class="caption__nomination-name-company">
												<h3 class="company-title tablet">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.company')
													}}
												</h3>
												<p class="
							caption__nomination-name-company-name
							caption-designation
						  ">
													{{
														vacancyData.companyName
															? vacancyData.companyName
															:
															$t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
													}}
												</p>
												<div class="caption__nomination-name-company-rating">
													<p class="caption__nomination-name-company-rating-tally">
														{{ vacancyData.companyRate }}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div class="rating rating-top">
										<h3 class="company-title">
											{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.rating') }}
										</h3>
										<div class="caption__rating">
											<div class="rating-filling">
												<div class="rating-index">
													<p class="rating-index__name">
														{{
															$t('pages.account.account_worker.accountWorkerVacancyInfoPage.necessary')
														}}
													</p>
													<div class="rating-line"
														:class="{ 'green-background-line': vacancyData.vacancyRate >= 80, 'red-background-line': vacancyData.vacancyRate < 50 }">
														<div class="line-filling-company"
															:class="{ 'yellow-line': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'red-line': vacancyData.vacancyRate < 50 }"
															:style="{ width: vacancyData.vacancyRate + '%' }"></div>
													</div>
													<p class="rating-index__number"
														:style="vacancyData.vacancyRate < 50 ? redText : vacancyData.vacancyRate >= 80 ? greenText : ''">
														<span class="rating-index__number-star "
															:class="{ 'star-orange': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'star-red': vacancyData.vacancyRate < 50 }">
														</span>
														{{ vacancyData.vacancyRate }}
													</p>
												</div>
												<div class="worker-index">
													<p class="rating-index__name">
														{{
															$t('pages.account.account_worker.accountWorkerVacancyInfoPage.your')
														}}
													</p>
													<div class="rating-line"
														:class="{ 'green-background-line': candidateRate >= 80, 'red-background-line': candidateRate < 50 }">
														<div class="line-filling-worker"
															:style="{ width: candidateRate + '%' }"
															:class="{ 'yellow-line': candidateRate >= 50 && candidateRate < 80, 'red-line': candidateRate < 50 }">
														</div>
													</div>
													<p class="rating-index__number"
														:style="candidateRate < 50 ? redText : candidateRate >= 80 ? greenText : ''">
														<span class="rating-index__number-star"
															:class="{ 'star-orange': candidateRate >= 50 && candidateRate < 80, 'star-red': candidateRate < 50 }">
														</span>
														{{ candidateRate }}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="caption__nomination-group desctop">
									<div class="error desctop-block " v-if="checkRate()">
										<img src="~@/assets/img/all-img/error.svg" alt="" class="error__img"
											@mouseover="active = true" @mouseout="active = false">
										<div class="error-description" v-if="active">
											<div class="error-description__wrapper">
												<p class="error-description__text">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.errorMessage')
													}}
												</p>
											</div>
										</div>
									</div>
									<button class="caption__nomination-group-btn btn-green" @click="respond('response')"
										:class="{ 'btn-grey': hasResponse || checkRate() }">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.apply') }}
										<div class="error tablet-block" v-if="checkRate()">
											<img src="~@/assets/img/all-img/error.svg" alt="" class="error__img"
												@mouseover="active = true" @mouseout="active = false">
											<div class="error-description" v-if="active">
												<div class="error-description__wrapper">
													<p class="error-description__text">
														{{
															$t('pages.account.account_worker.accountWorkerVacancyInfoPage.errorMessage')
														}}
													</p>
												</div>
											</div>
										</div>
									</button>
									<div class="flex">
										<a class="caption__nomination-group-btn btn-blue"
											:class="{ 'btn-grey': hasResponse || checkRate() }"
											@click="respond('phone')">{{ phoneShowing }}</a>
										<button @click="toggleFavoriteList" class="favorite-wrapper"
											:class="{ 'favorite--active': isFavorite }">
											<span class="favorite">
												<svg data-v-1171bf72="" viewBox="0 0 17 24" fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path data-v-1171bf72=""
														d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
														stroke="#009ED1"></path>
												</svg></span>
										</button>
									</div>
								</div>
							</div>
							<div class="row desctop">
								<button class="back" @click="$router.go(-1)"></button>
								<h1 class="caption__title ">
									{{
										vacancyData.vacancyName
											? vacancyData.vacancyName
											: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
									}}
								</h1>
							</div>
							<div class="caption__info">
								<div class="caption__info-charttablet">
									<p class="caption__info-chart-name caption-name">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.schedule') }}
									</p>
									<p class="caption__info-chart-schedule caption-designation">
										{{
											vacancyData.employType
												? vacancyData.employType
												: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
										}}
									</p>
								</div>
								<div class="caption__info-experiencetablet">
									<p class="caption__info-experience-name caption-name">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.experience') }}
									</p>
									<p class="caption__info-experience-time caption-designation">
										{{
											vacancyData.workExperience
												? vacancyData.workExperience
												: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
										}}
									</p>
								</div>
								<div class="caption__info-educationtablet">
									<p class="caption__info-education-name caption-name">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.education') }}
									</p>
									<p class="caption__info-education-level caption-designation">
										{{
											vacancyData.education
												? vacancyData.education
												: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
										}}
									</p>
								</div>
								<div class="caption__info-pricetablet">
									<p class="caption__info-price-name caption-name">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.wage') }}
									</p>
									<p class="caption__info-price-payment">
										{{ salary }}
									</p>
								</div>
							</div>

							<div class="rating rating-bottom">
								<h3 class="company-title">
									{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.rating') }}
								</h3>
								<div class="caption__rating">
									<div class="rating-filling">
										<div class="rating-index">
											<p class="rating-index__name">
												{{
													$t('pages.account.account_worker.accountWorkerVacancyInfoPage.necessary')
												}}
											</p>
											<div class="rating-line"
												:class="{ 'green-background-line': vacancyData.vacancyRate >= 80, 'red-background-line': vacancyData.vacancyRate < 50 }">
												<div class="line-filling-company"
													:class="{ 'yellow-line': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'red-line': vacancyData.vacancyRate < 50 }"
													:style="{ width: vacancyData.vacancyRate + '%' }"></div>
											</div>
											<p class="rating-index__number"
												:style="vacancyData.vacancyRate < 50 ? redText : vacancyData.vacancyRate >= 80 ? greenText : ''">
												<span class="rating-index__number-star "
													:class="{ 'star-orange': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'star-red': vacancyData.vacancyRate < 50 }">
												</span>
												{{ vacancyData.vacancyRate }}
											</p>
										</div>
										<div class="worker-index">
											<p class="rating-index__name">
												{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.your')
												}}
											</p>
											<div class="rating-line"
												:class="{ 'green-background-line': candidateRate >= 80, 'red-background-line': candidateRate < 50 }">
												<div class="line-filling-worker" :style="{ width: candidateRate + '%' }"
													:class="{ 'yellow-line': candidateRate >= 50 && candidateRate < 80, 'red-line': candidateRate < 50 }">
												</div>
											</div>
											<p class="rating-index__number"
												:style="candidateRate < 50 ? redText : candidateRate >= 80 ? greenText : ''">
												<span class="rating-index__number-star"
													:class="{ 'star-orange': candidateRate >= 50 && candidateRate < 80, 'star-red': candidateRate < 50 }">
												</span>
												{{ candidateRate }}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div class="caption__nomination-group nomination-tablet-group tablet">
								<button class="caption__nomination-group-btn btn-green" @click="respond('response')"
									:class="{ 'btn-grey': hasResponse || checkRate() }">
									{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.apply') }}
									<div class="error tablet block" v-if="checkRate()">
										<img src="~@/assets/img/all-img/error.svg" alt="" class="error__img"
											@mouseover="active = true" @mouseout="active = false">
										<div class="error-description" v-if="active">
											<div class="error-description__wrapper">
												<p class="error-description__text">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.errorMessage')
													}}
												</p>
											</div>
										</div>
									</div>
								</button>
								<div class="flex">
									<a class="caption__nomination-group-btn btn-blue"
										:class="{ 'btn-grey': hasResponse || checkRate() }" @click="respond('phone')">{{
											phoneShowing }}</a>
									<button @click="toggleFavoriteList" class="favorite-wrapper"
										:class="{ 'favorite--active': isFavorite }">
										<span class="favorite">
											<svg data-v-1171bf72="" viewBox="0 0 17 24" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path data-v-1171bf72=""
													d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
													stroke="#009ED1"></path>
											</svg></span>
									</button>
								</div>
							</div>
						</div>

						<picture class="caption__after">
							<source :srcset="vacancyData.previewImgUrl" media="(max-width: 700px)" />
							<img :src="vacancyData.previewImgUrl" alt="AboutCompany" class="caption__after-img" />
						</picture>
					</div>

					<div class="warp">
						<div class="warp__document">
							<p class="warp__document-text">
								{{
									vacancyData.description
										? vacancyData.description
										: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
								}}
							</p>
						</div>

						<div class="caption__nomination caption__nomination-bottom">
							<div class="caption__nomination-left">
								<div class="company">
									<h3 class="company-title desctop">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.company') }}
									</h3>
									<div class="caption__nomination-name">
										<div class="caption__nomination-imgcontainer">
											<img :src="vacancyData.companyAvatarUrl" alt="Company"
												class="caption__nomination-imgcontainer-img" />
										</div>
										<div class="caption__nomination-name-company">
											<h3 class="company-title tablet">
												{{
													$t('pages.account.account_worker.accountWorkerVacancyInfoPage.company')
												}}
											</h3>
											
											<p class="caption__nomination-name-company-namecaption-designation">
												{{
													vacancyData.companyName
														? vacancyData.companyName
														: $t('pages.account.account_worker.accountWorkerVacancyInfoPage.noData')
												}}
											</p>
											<div class="caption__nomination-name-company-rating">
												<p class="caption__nomination-name-company-rating-tally">
													{{ vacancyData.companyRate }}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="rating rating-top">
									<h3 class="company-title">
										{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.rating') }}
									</h3>
									<div class="caption__rating">
										<div class="rating-filling">
											<div class="rating-index">
												<p class="rating-index__name">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.necessary')
													}}
												</p>
												<div class="rating-line"
													:class="{ 'green-background-line': vacancyData.vacancyRate >= 80, 'red-background-line': vacancyData.vacancyRate < 50 }">
													<div class="line-filling-company"
														:class="{ 'yellow-line': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'red-line': vacancyData.vacancyRate < 50 }"
														:style="{ width: vacancyData.vacancyRate + '%' }"></div>
												</div>
												<p class="rating-index__number"
													:style="vacancyData.vacancyRate < 50 ? redText : vacancyData.vacancyRate >= 80 ? greenText : ''">
													<span class="rating-index__number-star "
														:class="{ 'star-orange': vacancyData.vacancyRate >= 50 && vacancyData.vacancyRate < 80, 'star-red': vacancyData.vacancyRate < 50 }">
													</span>
													{{ vacancyData.vacancyRate }}
												</p>
											</div>
											<div class="worker-index">
												<p class="rating-index__name">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.your')
													}}
												</p>
												<div class="rating-line"
													:class="{ 'green-background-line': candidateRate >= 80, 'red-background-line': candidateRate < 50 }">
													<div class="line-filling-worker"
														:style="{ width: candidateRate + '%' }"
														:class="{ 'yellow-line': candidateRate >= 50 && candidateRate < 80, 'red-line': candidateRate < 50 }">
													</div>
												</div>
												<p class="rating-index__number"
													:style="candidateRate < 50 ? redText : candidateRate >= 80 ? greenText : ''">
													<span class="rating-index__number-star"
														:class="{ 'star-orange': candidateRate >= 50 && candidateRate < 80, 'star-red': candidateRate < 50 }">
													</span>
													{{ candidateRate }}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="caption__nomination-group desctop">
								<div class="error desctop-block" v-if="checkRate()">
									<img src="~@/assets/img/all-img/error.svg" alt="" class="error__img"
										@mouseover="active = true" @mouseout="active = false">
									<div class="error-description" v-if="active">
										<div class="error-description__wrapper">
											<p class="error-description__text">
												{{
													$t('pages.account.account_worker.accountWorkerVacancyInfoPage.errorMessage')
												}}
											</p>
										</div>
									</div>
								</div>
								<button class="caption__nomination-group-btn btn-green" @click="respond('response')"
									:class="{ 'btn-grey': hasResponse || checkRate() }">
									{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.apply') }}
									<div class="error tablet-block" v-if="checkRate()">
										<img src="~@/assets/img/all-img/error.svg" alt="" class="error__img"
											@mouseover="active = true" @mouseout="active = false">
										<div class="error-description" v-if="active">
											<div class="error-description__wrapper">
												<p class="error-description__text">
													{{
														$t('pages.account.account_worker.accountWorkerVacancyInfoPage.errorMessage')
													}}
												</p>
											</div>
										</div>
									</div>
								</button>
								<div class="flex">
									<a class="caption__nomination-group-btn btn-blue"
										:class="{ 'btn-grey': hasResponse || checkRate() }" @click="respond('phone')">{{
											phoneShowing }}</a>
									<button @click="toggleFavoriteList" class="favorite-wrapper"
										:class="{ 'favorite--active': isFavorite }">
										<span class="favorite">
											<svg data-v-1171bf72="" viewBox="0 0 17 24" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path data-v-1171bf72=""
													d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
													stroke="#009ED1"></path>
											</svg></span>
									</button>
								</div>
							</div>
						</div>

						<div class="similar">
							<h4 class="similar__title">
								{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.moreVacancies') }}
							</h4>
							<p class="similar__text">
								{{ $t('pages.account.account_worker.accountWorkerVacancyInfoPage.similarVacancies') }}
							</p>
							<div class="similar__vacancy">
								<SeparateRecomendation v-for="item in familiarVacancy" :key="item.id" :vacancy="item"
									:isFavorite="isVacancyFavorite(item.id)">
								</SeparateRecomendation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import ResponseMessage from "../../../components/account/account_worker/account_worker_response_message/item/ResponseMessage.vue";
import SeparateRecomendation from "../../../components/account/account_worker/account_worker_same_vacancy/item/SeparateRecomendation.vue";
import { warningNotification } from "@/lib/notification";
import { config } from "@/lib/config";
import { convertSalary } from "@/lib/values";

export default {
	components: { HeaderAccountWorker, MenuWorker, ResponseMessage, SeparateRecomendation },
	computed: {
		responseVacancy() {
			return this.$store.getters.responseVacancy;
		},
		salary() {
			return this.convertSalary(this.vacancyData?.payFrom ? this.vacancyData?.payFrom : 0, this.vacancyData?.expectedSalaryCurrency ? this.vacancyData?.expectedSalaryCurrency.toUpperCase() : "usd", this.$store.getters?.user?.expectedSalaryCurrency ? this.$store.getters?.user?.expectedSalaryCurrency.toUpperCase() : 'usd')
		},
	},
	name: "AccountWorkerVacancyInfoPage",
	props: {
		vacancyId: {},
	},

	data() {
		return {
			vacancyData: {},
			messageVisible: false,
			phoneShowing: this.$t('pages.account.account_worker.accountWorkerVacancyInfoPage.showPhone'),
			hasResponse: false,
			active: false,
			candidateRate: null,
			familiarVacancy: null,
			greenText: {
				color: '#14c686'
			},
			redText: {
				color: '#FF3124'
			},
			isFavorite: false,
		}
	},
	methods: {
		isVacancyFavorite(vacancyId) {
			const favoriteVacancies = this.$store.getters.favoriteVacancy;
			return favoriteVacancies.some(vacancy => vacancy.id === vacancyId);
		},
		toggleFavoriteList() {
			this.isFavorite = !this.isFavorite;
			if (this.isFavorite) {
				this.$store.dispatch("addFavoriteVacancy", {
					token: this.$store.getters.token,
					vacancy: this.vacancyData,
				})
			} else {
				this.$store.dispatch("removeFavoriteVacancy", {
					token: this.$store.getters.token,
					vacancy: this.vacancyData,
				})
			}
		},
		convertSalary,
		unDisplayMessage(dataMessage) {
			if (dataMessage !== null) {
				this.hasResponse = true
			}
			this.messageVisible = false;
		},
		fetchDataVacancy() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/vacancy/get/" + this.vacancyId;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.vacancyData = response.data;
				this.hasResponse = this.vacancyData.hasResponse;
			} catch (e) {
				console.log(e);
			}
		},
		showPhone() {
			this.phoneShowing = this.vacancyData.companyPhone;
		},
		alreadyResponse() {
			if (this.hasResponse) {
				warningNotification(this, this.$t('pages.account.account_worker.accountWorkerVacancyInfoPage.responded'));
			}
		},
		fetchCandidateData() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/data?lang=en";
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.candidateRate = response.data.score;
			} catch (e) {
				console.log(e);
			}
		},
		checkRate() {
			return this.vacancyData.vacancyRate > this.candidateRate;
		},
		respond(value) {
			if (value === 'response') {
				if (!this.hasResponse && !this.checkRate()) {
					this.messageVisible = !this.messageVisible;
				} else {
					if (this.checkRate()) {
						this.active = true;
						setTimeout(() => {
							this.active = false
						}, 4000);
					}
				}
			} else {
				if (!this.checkRate() && !this.hasResponse) {
					this.phoneShowing = this.vacancyData.companyPhone;
				} else {
					if (this.checkRate()) {
						this.active = true;
						setTimeout(() => {
							this.active = false
						}, 4000);
					}
				}
			}
		},
		fetchFamiliarVacancy() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "vacancy/familiar/" + this.vacancyId;
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.familiarVacancy = response.data.slice(0, 3);
			} catch (e) {
				console.log(e);
			}
		}
	},
	beforeMount() {
		scroll(0, 0);
		this.fetchDataVacancy();
		this.fetchCandidateData();
		this.$store.dispatch("fetchFavoriteVacancy", { token: this.$store.getters.token });
		if (this.checkRate()) {
			this.hasResponse = true;
		}
		if (!this.hasResponse && this.responseVacancy) {
			this.messageVisible = true;
		}
		this.isFavorite = this.isVacancyFavorite(this.vacancyData.id);
		this.fetchFamiliarVacancy();
	},
	beforeDestroy() {
		this.$store.commit('setResponseVacancy', false);
	}
}
</script>


<style scoped>
/* Caption css */
.account {
	display: flex;
}

.caption {
	max-width: 100%;
}

.caption-container {
	max-width: 1020px;
}

.caption__nomination {
	display: flex;
	align-items: center;
	padding-bottom: 28px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.caption__nomination-bottom {
	border-bottom: none;
	margin-top: 40px
}

.caption__nomination-left {
	display: flex;
}

.caption__nomination-bottom {
	margin-top: 50px;
	border-bottom: none;
}

.caption-name {
	margin-right: 0;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
	margin-right: 0;
}

.caption-designation {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #000000;
}

.caption__nomination-imgcontainer-img {
	width: 57px;
	height: 57px;
	border-radius: 100%;
	object-fit: cover;
	display: flex;
	flex-shrink: 0;
}

.caption__nomination-name {
	margin-top: 12px;
	display: flex;
	gap: 17px;
}

.company-title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 11px;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.5);
}

.caption__nomination-name-company {
	align-items: center;
	margin-top: 4px;
}

.caption__nomination-name-company-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	margin-top: 8px;
}

.rating-description {
	display: flex;
	align-items: center;
}

.rating {
	margin-left: 106px;
}

.rating-bottom {
	display: none;
}

.rating-description__text {
	font-family: "Roboto";
	font-weight: 400;
	margin-right: 4px;
	font-size: 13px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.5);
}

.caption__nomination-name-company-rating-tally {
	background-image: url("~@/assets/img/company-page/caption/star.svg");
	background-size: 11px;
	background-repeat: no-repeat;
	background-position: 9px;
	background-position-y: 5px;
	padding: 4px 9px 4px 25px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #14c686;
}

.rating-from {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.5);
	display: block;
}


.caption__nomination-group {
	margin-left: auto;
	display: flex;
}

.error {
	position: relative;
	align-items: center;
	display: flex;
	margin-right: 14px;
	z-index: 20;
}

.error__img {
	margin-right: 0;
	margin-top: 5px;
}

.error-description {
	position: absolute;
	left: -30px;
	bottom: -100px;
	z-index: 20;
}

.error-description__wrapper {
	position: relative;
	padding: 26px 15px 15px;
	background: #FFFFFF;
	border: 1px solid #EFEFF4;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	width: 296px;
}

.error-description__wrapper::after {
	position: absolute;
	top: -10px;
	left: 35px;
	display: block;
	width: 14px;
	height: 10px;
	background: url("~@/assets/img/account-img/friends/arrow.svg");
	content: "";
	background-repeat: no-repeat;
}

.error-description__text {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.6);
}

.caption__nomination-group-btn {
	padding: 16px 35px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.1px;
	color: #ffffff;
	border-radius: 7px;
	cursor: pointer;
	transition: opacity 1s ease;
}

.btn-green {
	background: #14c686;
	margin-right: 14px;
}

.caption__nomination-group-btn:hover {
	opacity: 0.5;
}

.btn-blue {
	background: #009ed1;
}

.btn-grey {
	background: #d3d3d3;
	color: #ffffff;
	cursor: default;
}

.caption__title {
	display: inline;

	font-family: "Montserrat";
	font-weight: 700;
	font-size: 32px;
	line-height: 43px;
	color: #000000;
}

.caption__subtitle {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #009ed1;
}

.caption__info {
	margin-top: 39px;
	display: flex;
	gap: 40px;
}

tablet:not(:last-child) {
	margin-right: 40px;
}


.caption__info-price-payment {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #009ed1;
}

.caption__info-views-name {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
	opacity: 0.5;
}

.caption__info-views-number {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #696974;
}

.caption__after {
	box-sizing: border-box;
	margin: 37px auto 0 auto;
	display: flex;
	width: 100%;
}

.caption__after-img {
	background: #c4c4c4;
	object-fit: cover;
	object-position: center;
	width: 100%;
	box-sizing: border-box;
	border-radius: 8px;
}

.invisable {
	display: none;
}

.tablet {
	display: none;
}

.bottom-rating-tablet {
	display: none !important;
}

.rating-wrapper:nth-child(2) {
	margin-left: 40px;
}

.rating-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

}

.rating-text {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	letter-spacing: 0.1px;

	color: #000000;
}

.rating-filling {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.rating-index {
	display: flex;
	align-items: center;
}

.rating-line {
	width: 150px;
	height: 6px;
	background: rgba(220, 149, 11, 0.20000000298023224);
	border-radius: 200px;
	position: relative;
	display: flex;
	flex-shrink: none;
}

.line-filling-worker {
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	height: 6px;
	background: #00b884;
	border-radius: 200px;
	max-width: 150px;
}

.line-filling-company {

	height: 100%;
	background: #38c485;
	border-radius: 200px;
	max-width: 150px;
}


.worker-index {
	margin-top: 9px;
	display: flex;
	align-items: center;
}

.rating-index__name {
	font-family: "Roboto";
	font-weight: 300;
	font-size: 11px;
	line-height: 1.5;
	color: #000000;
	margin-right: 7px;
}

.worker-index__round {
	display: block;
	width: 5px;
	height: 5px;
	background: #009ed1;
	border-radius: 100%;
}

.worker-index__text {
	margin-left: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 10px;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.4000000059604645);
}

.caption__rating-filling {
	display: inline;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	margin-top: 4px;
}

.rating-index__number {
	margin-left: 12px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	display: flex;
	color: #db940a;
	align-items: center;
	width: 36px;
}

.worker-index__number {
	margin-left: 12px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	display: flex;
	align-items: center;
	color: #14c686;
}

.rating-index__number-star {
	display: block;
	background-image: url("~@/assets/img/company-page/caption/star.svg");
	width: 11px;
	height: 10px;
	margin-right: 4px;
}

.star-orange {
	background-image: url("~@/assets/img/company-page/caption/star-orange.svg");
}

.star-red {

	background-image: url("~@/assets/img/company-page/caption/star-red.svg");
}

.row {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 34px;
}

.back {
	width: 30px;
	height: 9px;
	background: url("~@/assets/img/company-page/caption/back.svg");
	background-size: cover;
	background-repeat: no-repeat;
}

.favorite-wrapper {
	margin-left: 14px;
	position: relative;
	width: 47px;
	height: 47px;
	border-radius: 7px;
	display: flex;
	justify-content: center;
}

.favorite {
	right: unset;
}

.tablet {
	display: none;
}

@media (max-width: 786px) {
	.tablet {
		display: block;
	}

	.tablet-block {
		display: block;
	}


	.rating-wrapper {
		text-align: center;
	}


	tablet:not(:last-child) {
		margin-right: 0;
	}

	.caption-name {
		font-size: 10px;
	}

	.bottom-rating {
		display: none;
	}

	.caption__back {
		display: flex;
		height: 30px;
		align-items: center;
	}


	.caption__title {
		text-align: center;
		margin-top: 13px;
		font-size: 25px;
	}

	.caption__nomination {
		justify-content: center;
		border: none;
		padding-bottom: 0;
	}


	.caption__info {
		padding-bottom: 23px;
		max-width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}


	.caption__info-chart {
		width: 150px;
	}

	.caption__info-chart-schedule {
		font-size: 11px;
	}

	.caption__info-experience {
		width: 150px;
	}

	.caption__info-experience-time {
		font-size: 11px;
	}

	.caption__info-education {
		width: 150px;
	}

	.caption__info-education-level {
		font-size: 11px;
	}

	.caption__info-price {
		width: 150px;
	}

	.caption__info-price-payment {
		font-size: 11px;
	}

	.caption__info-views {
		width: 150px;
	}

	.caption__info-views-name {
		font-size: 10px;
	}

	.caption__info-views-number {
		font-size: 11px;
	}

	.caption__nomination-group {
		margin-top: 23px;
		margin-bottom: 23px;
		display: flex;
		width: 100%;
		box-sizing: border-box;
		justify-content: center;
	}

	.caption__nomination-group-btn {
		padding: 13px 12px;
	}

	.btn-green {
		padding: 13px 24px;
	}


	.caption__after-img {
		height: 297px;
	}

	.desctop {
		display: none;
	}

	.nomination-tablet-group {
		margin-left: 0;
		max-width: 100% !important;
		justify-content: center;
	}

	.caption__nomination-group-btn {
		margin-left: unset !important;
	}


	.caption__nomination-name {
		margin-top: 0;
	}

	.row {
		margin-top: 0;
		display: flex;
	}

	.back {
		width: 20px;
	}

	.desctop {
		display: none;
	}

	.favorite-wrapper {
		width: 41px;
		height: 41px;
	}
}


/*/ Caption css */

/* Warp css */
.warp__document {
	margin-top: 64px;
	padding-bottom: 67px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.warp__document-title {
	margin-top: 30px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 26px;
	line-height: 1.6;
	color: #424242;
}

.warp__document-text {
	margin-top: 30px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 20px;
	line-height: 1.6;
	color: #424242;
}

.second-nomination {
	margin-top: 50px;
	padding-bottom: 0;
	border-bottom: none;
}

@media (max-width: 786px) {
	.warp__document {
		margin-top: 10px;
		padding-bottom: 40px;
	}

	.warp__document-title {
		font-size: 15px;
	}

	.warp__document-text {
		margin-top: 16px;
		font-size: 13px;
	}

	.second-nomination {
		margin-top: 20px;
	}

	.caption__title {
		text-align: left;
		font-family: "SFUIDisplay", ;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		color: #3e4453;
		margin-top: 6px;
	}

	.caption__text {
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #009ed1;
		margin-top: 23px;
	}

	.caption__nomination {
		margin: 33px 0 0 0;
		justify-content: left;
	}

	.bottom-rating-tablet {
		margin-left: 0;
		display: flex !important;
		justify-content: center;
	}

	.bottom-rating-tablet-element {
		margin-left: 0 !important;
		justify-content: center;
	}

	.bottom-rating-tablet-element:nth-child(2) {
		margin-left: 40px !important;
	}

	.rating-description {
		width: 100%;
	}

	.error {
		top: 9px !important;
	}

	.error-description {
		left: -113px !important;
	}

	.error-description__wrapper::after {
		left: 111px !important;
	}

	.rating-filling {
		margin-top: 12px;
	}
}

.block-button {
	opacity: 0.3;
	pointer-events: none;
}

/*/ Warp css */

/* Similar */
.similar {
	padding-top: 50px;
}

.similar__title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.similar__text {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	line-height: 43px;
	color: #000000;
}

.similar__vacncy {
	width: 100%;
	box-sizing: border-box;
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 20px;
}

.similar__vacncy .similar__btn {
	margin: 66px auto 114px auto;
	justify-content: center;
	display: flex;
	width: 234px;
	height: 60px;
	align-items: center;
	text-align: center;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #009ed1;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.similar__btn:hover {
	opacity: 0.5;
}

.similar__btn {
	margin: 66px auto 114px auto;
	justify-content: center;
	display: flex;
	width: 234px;
	height: 60px;
	align-items: center;
	text-align: center;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #009ed1;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.similar__vacncy-element:nth-child(3n + 2) {
	margin: 0 auto;
}


.similar__vacncy-element:nth-child(3n + 3) {
	margin-left: auto;
}

.similar__vacncy-element:nth-child(3n + 4) {
	margin-left: 0;
}


.similar__btn:hover {
	opacity: 0.5;
}


.tablet-block {
	display: none;

}

.favorite {
	position: relative;
}

.flex {
	display: flex;
}

@media (max-width: 1025px) {
	.desctop-block {
		display: none;
	}


	.tablet-block {
		display: block;
	}

	.error-description__wrapper {
		width: 300px;
		box-sizing: border-box;
	}

	.error-description {
		left: -266px;
	}

	.error-description__wrapper::after {
		left: 271px;
	}


	.caption__nomination-group-btn {
		position: relative;
		box-sizing: border-box;
		text-align: center;
		margin-left: auto;
	}

	.caption__nomination-group {
		max-width: 384px;
	}

	.error {
		position: absolute;
		margin-right: 0;
		right: 6px;
		top: 12px;
	}


	.error__img {
		margin: 0;
	}

	.caption-designation {
		margin-top: 4px;
	}

	.caption__info-price-payment {
		margin-top: 4px;
	}

	.rating-description {
		display: flex;
		justify-content: center;
	}

	.caption__nomination-name-company-rating {
		margin-left: 0;
	}

	.caption__nomination-name-company-name {
		margin-top: 2px;
	}

	.caption__nomination-name-company {
		margin-top: 0;
	}

	.caption__nomination-name-company-rating {
		margin-top: 7px;
	}

	.caption__nomination-imgcontainer-img {
		height: 67px;
		width: 67px;
	}

	.rating {
		margin-left: 30px;
	}
}


@media (max-width: 1070px) {
	.similar__vacncy {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 960px) {
	.similar {
		padding-top: 54px;
	}

	.similar__title {
		text-align: center;
	}

	.similar__text {
		text-align: center;
	}

	.similar__btn {
		width: 367px;
		margin: 36px auto 57px auto;
	}

	.similar__vacncy-element:nth-child(1) {
		margin: 0 0 0 0;
	}

	.similar__vacncy-element:nth-child(2n) {
		margin: 0 auto;
	}


	.similar__vacncy {
		margin-top: 22px;
	}

}

@media (max-width: 640px) {
	.similar__vacncy {
		grid-template-columns: repeat(1, 1fr);
	}

	.similar__vacncy-element:nth-child(1) {
		margin: 0 auto;
	}

	.similar__vacncy-element:nth-child(3n + 4) {
		margin: 0 auto;
	}

	.similar__vacncy-element:nth-child(3n + 3) {
		margin: 0 auto;
	}

	.similar__vacncy-element:nth-child(3n + 2) {
		margin: 0 auto;
	}

	.similar__btn {
		width: 300px;
	}
}

@media (max-width: 470px) {
	.caption__title {
		text-align: left;
	}

	.caption__nomination {
		justify-content: left;
	}


	.caption__info-views {
		text-align: left;
		width: 150px;
	}

	.caption__info {
		grid-template-columns: repeat(2, 1fr);
		border-bottom: none;
	}

	.caption__nomination {
		margin: 25px 0;
	}

	tablet {
		text-align: left;
	}

	tablet {
		margin: 0;
	}

	.rating-description {
		justify-content: left;
		margin-top: 0;
	}

	.caption-designation {
		margin-top: 0;
	}

	.caption__info-price-payment {
		margin-top: 0;
	}

	.caption__nomination-group {
		margin: 20px 0 0 0;
		flex-direction: column;
		gap: 12px;
	}

	.caption__nomination-group-btn {
		width: 100%;
		text-align: center;
	}

	.bottom-rating-tablet {
		justify-content: left;
		margin-bottom: 25px;
	}
}

@media (max-width: 400px) {
	.similar {
		padding-top: 24px;
	}

	.tablet {
		display: block;
	}

	.nomination-tablet-group {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		width: 100%;
	}

	.btn-blue {
		box-sizing: border-box;
	}


	.similar__title {
		font-size: 13px;
		text-align: left;
		line-height: 13px;
	}

	.similar__text {
		text-align: left;
		font-size: 20px;
		line-height: 20px;
		margin-top: 8px;
	}

	.caption__nomination-group-btn {
		max-width: 100% !important;
	}

	.error-description__wrapper {
		left: -154px;
	}

	.error-description__wrapper::after {
		left: 264px !important;
	}

	.row {
		display: flex;
	}

	.desctop {
		display: none;
	}
}

/* /Similar */

@media (max-width: 470px) {
	.mobile-block {
		diplay: block;
	}

	.rating-bottom {
		display: block;
	}

	.rating {
		margin-left: 0px;
	}

	.rating-top {
		display: none
	}
}

.green-background-line {
	background-color: rgba(56, 196, 133, 0.3);
}

.red-background-line {
	background-color: rgba(142, 21, 11, 0.3);
}

.yellow-line {
	background-color: #DC950B;
}

.red-line {
	background-color: #FF3124;
}
</style>
