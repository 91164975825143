<template>
  <label class="filling__caption-head-surch">
    <input type="search" :placeholder="$t('components.account_worker.searchElement.searchPlaceholder')"
      class="filling__caption-head-surch-entry" v-model="search" />
    <button v-if="false" class="filling__caption-head-surch-find">
      <img src="~@/assets/img/all-img/surch.svg" alt="" class="filling__caption-head-surch-find-img" />
    </button>
    <div class="search-list" v-if="search && search.trim() !== ''">
      <button class="search-list-element" @click="$router.push({ name: item.path })"
        v-for="item in searchingPaths.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)"
        :key="item.id">{{ $t('components.account_worker.searchElement.' + item.name.replace(/\s+/g, '')) }}
      </button>
    </div>
  </label>
</template>

<script>
export default {
  name: "SearchElement",
  props: [
    'role'
  ],
  beforeMount() {
    if (this.role === 'CANDIDATE') {
      this.searchingPaths = [
        {
          name: 'friends',
          path: 'friends'
        },
        {
          name: 'myDocuments',
          path: 'documents'
        },
        {
          name: 'communities',
          path: 'communities'
        },
        {
          name: 'profileSettings',
          path: 'settings'
        },
        {
          name: 'rating',
          path: 'rate'
        },
        {
          name: 'searchJob',
          path: 'vacancies'
        },
        {
          name: 'myApplications',
          path: 'responses'
        },
        {
          name: 'recommendations',
          path: 'vacancies'
        },
        {
          name: 'takeTest',
          path: 'testing'
        },
        {
          name: 'videoInterview',
          path: 'videoInterview'
        },
        {
          name: 'faq',
          path: 'faq'
        },
        {
          name: 'chat',
          path: 'allChats'
        }
      ];
    } else {
      this.searchingPaths = [
        {
          name: 'profileSettings',
          path: 'settingsCompany'
        },
        {
          name: 'rating',
          path: 'rateCompany'
        },
        {
          name: 'testing',
          path: 'interviewPage'
        },
        {
          name: 'searchSpecialists',
          path: 'vacancyCompany'
        },
        {
          name: 'myVacancies',
          path: 'vacanciesCompany'
        },
        {
          name: 'recommendations',
          path: 'recommendationsCompany'
        },
        {
          name: 'faq',
          path: 'faqCompany'
        },
        {
          name: 'chat',
          path: 'allChats'
        },
        {
          name: 'communities',
          path: 'communities'
        },
        {
          name: 'friends',
          path: 'friends'
        }
      ];
    }
  },
  data() {
    return {
      search: null,
      searchingPaths: null
    }
  }
};
</script>


<style scoped>
.filling__caption-head-surch {
  margin-left: auto;
  position: relative;
}

.filling__caption-head-surch-entry {
  height: 55px;
  margin: auto 0;
  width: 307px;
  padding-left: 33px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  letter-spacing: 0.02em;
  color: #171725;
  font-weight: 400;
  padding-right: 61px;
  font-size: 16px;
}

.filling__caption-head-surch-entry::placeholder {
  color: #b7c1d1;
}

.filling__caption-head-surch-find {
  top: 9px;
  position: absolute;
  display: inline-block;
  right: 10px;
  transition: opacity 1s ease;
  padding: 12px;
  line-height: 0;
  background: linear-gradient(192.41deg, #4099ff 0%, #a1a8ff 138.89%);
  border-radius: 100%;
}

.filling__caption-head-surch-find-img {
  object-fit: cover;
  width: 12px;
  height: 12px;
}

.filling__caption-head-surch-find:hover {
  opacity: 0.5;
}

.search-list {
  position: absolute;
  z-index: 30;
  max-height: 250px;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  overflow-y: scroll;
  scrollbar-width: thin;
  max-width: 100%;
  width: 100%;
}

.search-list-element {
  text-align: left;
  padding: 12px 12px 12px 33px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  letter-spacing: 0.02em;
  color: #171725;
  font-weight: 400;
  padding-right: 61px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
}

.search-list :first-child {
  border-radius: 20px 20px 0 0;
}

.search-list :last-child {
  border-radius: 0 0 20px 20px;
}

@media (max-width: 1360px) {
  .filling__caption-head-surch-entry {
    width: 256px;
    height: 46px;
    padding-left: 27px;
  }

  .filling__caption-head-surch-find {
    padding: 9px;
    top: 8px;
  }

  .search-list-element {
    padding-left: 27px;
  }
}

@media (max-width: 600px) {
  .filling__caption-head {
    flex-direction: column;
  }

  .filling__caption-head-greetings-text {
    text-align: center;
  }

  .filling__caption-head-surch {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }

  .filling__caption-head-surch-entry {
    width: 100%;
  }
}
</style>
