<template>
  <div>
    <HeaderAccountWorker @close="viewAvatar = false" :photos="photos" :avatar="viewAvatar"></HeaderAccountWorker>
    <QuestionModal v-if="viewModal" @close="closeModal" @createNews="createNews" page="birthDay"></QuestionModal>
    <big-photo v-if="bigPhotoUrl" @closeBigPhoto="bigPhotoUrl = false" :url="bigPhotoUrl"></big-photo>

    <main class="main" ref="main">
      <div class="account">
        <MenuWorker></MenuWorker>
        <div class="filling container-account">
          <div class="filling__caption">
            <div class="filling__caption-head">
              <div class="filling__caption-head-greetings">
                <p class="filling__caption-head-greetings-text mobile">
                  {{ $t('pages.account.account_worker.accountWorkerDashboardPage.personalCabinet') }}
                </p>
                <h1 class="filling__caption-head-greetings-title">
                  {{ $t('pages.account.account_worker.accountWorkerDashboardPage.personalCabinet') }}
                </h1>
                <p class="filling__caption-head-greetings-text desctop">
                  {{ $t('pages.account.account_worker.accountWorkerDashboardPage.welcome') }} {{ dataWorker.name }}!
                </p>
              </div>
              <SearchElement v-if="false" :role="$store.getters.role">
              </SearchElement>
            </div>
            <div class="filling__caption-wrapp desctop">
              <div class="filling__caption-wrapp-statistics">
                <div class="filling__caption-wrapp-statistics-graph">
                  <h2 class="filling__caption-wrapp-statistics-graph-title">
                    {{ $t('pages.account.account_worker.accountWorkerDashboardPage.statistics') }}
                  </h2>
                  <div class="filling__caption-wrapp-statistics-graph-group">
                    <div class="
                        filling__caption-wrapp-statistics-graph-group-element
                      ">
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-text
                        ">
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-category
                          ">
                          {{ $t('pages.account.account_worker.accountWorkerDashboardPage.educationLevel') }}
                        </p>
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-points
                          ">
                          {{ dataScore.educationScore }} {{
                            $t('pages.account.account_worker.accountWorkerDashboardPage.points') }}
                        </p>
                      </div>
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-field
                        ">
                        <div class="filling__caption-wrapp-statistics-graph-group-element-fill-repletion"
                          :style="{ width: dataScore.educationScore + '%' }"></div>
                      </div>
                    </div>
                    <div class="
                        filling__caption-wrapp-statistics-graph-group-element
                      ">
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-text
                        ">
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-category
                          ">
                          {{ $t('pages.account.account_worker.accountWorkerDashboardPage.technicalSkills') }}
                        </p>
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-points
                          ">
                          {{ dataScore.techScore }} {{
                            $t('pages.account.account_worker.accountWorkerDashboardPage.points') }}
                        </p>
                      </div>
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-field
                        ">
                        <div class="
                            filling__caption-wrapp-statistics-graph-group-element-fill-repletion
                          " :style="{ width: dataScore.techScore + '%' }"></div>
                      </div>
                    </div>
                    <div class="
                        filling__caption-wrapp-statistics-graph-group-element
                      ">
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-text
                        ">
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-category
                          ">
                          {{ $t('pages.account.account_worker.accountWorkerDashboardPage.professionalTesting') }}
                        </p>
                        <p class="
                            filling__caption-wrapp-statistics-graph-group-element-text-points
                          ">
                          {{ dataScore.profTestScore }} {{
                            $t('pages.account.account_worker.accountWorkerDashboardPage.points') }}
                        </p>
                      </div>
                      <div class="
                          filling__caption-wrapp-statistics-graph-group-element-field
                        ">
                        <div class="
                            filling__caption-wrapp-statistics-graph-group-element-fill-repletion
                          " :style="{ width: dataScore.profTestScore + '%' }"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="filling__caption-wrapp-statistics-border"></span>
                <div class="filling__caption-wrapp-statistics-circle">
                  <div class="filling__caption-wrapp-statistics-circle-title">
                    <h4 class="
                        filling__caption-wrapp-statistics-circle-title-text
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.rating') }}
                    </h4>
                    <router-link :to="{ name: 'rate' }" class="
                        filling__caption-wrapp-statistics-circle-title-link
                      ">
                      <img src="~@/assets/img/all-img/arrow-45.svg" alt="" />
                    </router-link>
                  </div>
                  <p class="filling__caption-wrapp-statistics-circle-rating">
                    <span>{{ dataWorker.score }}</span>
                  </p>
                  <p class="filling__caption-wrapp-statistics-circle-text">
                    {{ $t('pages.account.account_worker.accountWorkerDashboardPage.positive') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="filling__caption-profile mobile">
              <div class="user__wrapper-person">
                <img :src="dataWorker.avatarPath" @click="viewAvatar = true" :style="{
                  backgroundImage: 'url(' + dataWorker.avatarPath + ')'
                }" alt="Company" class="user__wrapper-person-icon" />
                <p class="user__wrapper-person-name">{{ dataWorker.name }}</p>
                <p class="user__wrapper-person-finder">{{
                  $t('pages.account.account_worker.accountWorkerDashboardPage.jobSeeker') }}</p>
                <button class="user__wrapper-person-change" @click="$router.push({ name: 'settings' })">
                  <p class="user__wrapper-person-change-btn">{{
                    $t('pages.account.account_worker.accountWorkerDashboardPage.employer') }}</p>
                </button>
              </div>
              <div class="user__wrapper-person-tests">
                <div class="user__wrapper-person-tests-caption">
                  <h5 class="user__wrapper-person-tests-caption-title">
                    {{ $t('pages.account.account_worker.accountWorkerDashboardPage.availableTests') }}
                  </h5>
                  <router-link :to="{ name: 'testing' }" class="user__wrapper-person-tests-caption-text">
                    {{ $t('pages.account.account_worker.accountWorkerDashboardPage.seeAll') }}
                  </router-link>
                </div>
                <div class="user__wrapper-person-tests-group">
                  <div class="user__wrapper-person-tests-group-element">
                    <img src="~@/assets/img/account-img/dashboard/bank.svg" alt=""
                      class="user__wrapper-person-tests-group-element-preview" />
                    <div class="user__wrapper-person-tests-group-element-description">
                      <h5 class="user__wrapper-person-tests-group-element-description-title">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.sqlTest') }}
                      </h5>
                      <p class="user__wrapper-person-tests-group-element-description-text">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                      </p>
                    </div>
                    <button @click="goToTesting('sql')" class="user__wrapper-person-tests-group-element-start"></button>
                  </div>
                  <div class="user__wrapper-person-tests-group-element">
                    <img src="~@/assets/img/account-img/dashboard/cash.svg" alt=""
                      class="user__wrapper-person-tests-group-element-preview" />
                    <div class="user__wrapper-person-tests-group-element-description">
                      <h5 class="user__wrapper-person-tests-group-element-description-title">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.profTesting') }}
                      </h5>
                      <p class="user__wrapper-person-tests-group-element-description-text">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                      </p>
                    </div>
                    <button @click="goToTesting('prof')"
                      class="user__wrapper-person-tests-group-element-start"></button>
                  </div>
                  <div class="user__wrapper-person-tests-group-element">
                    <img src="~@/assets/img/account-img/dashboard/profile-icon.svg" alt=""
                      class="user__wrapper-person-tests-group-element-preview" />
                    <div class="user__wrapper-person-tests-group-element-description">
                      <h5 class="user__wrapper-person-tests-group-element-description-title">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.iqTest') }}
                      </h5>
                      <p class="user__wrapper-person-tests-group-element-description-text">
                        {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                      </p>
                    </div>
                    <button @click="goToTesting('iq')" class="user__wrapper-person-tests-group-element-start"></button>
                  </div>
                </div>
                <div class="user__wrapper-description">
                  <p class="user__wrapper-description-text">
                    {{ dataWorker.aboutText }}
                  </p>
                </div>
              </div>
            </div>

            <div class="proposed">
              <div class="proposed-caption">
                <h3 class="proposed__title">{{
                  $t('pages.account.account_worker.accountWorkerDashboardPage.recommendedVacancies') }}</h3>
                <router-link :to="{ name: 'recommendations' }" class="proposed__link">{{
                  $t('pages.account.account_worker.accountWorkerDashboardPage.seeAll') }}</router-link>
              </div>
              <div class="proposed-group">
                <DetailedVacancy v-for="item in dataVacancy" :key="item.id" :vacancy="item"
                  :worker-rate="dataWorker.score" :isFavorite="isFavoriteVacancy(item.id)">
                </DetailedVacancy>
              </div>
            </div>

            <div class="proposed">
              <div class="proposed-caption">
                <h3 class="proposed__title">{{ $t('pages.account.account_worker.accountWorkerDashboardPage.testing') }}:
                </h3>
                <router-link :to="{ name: 'testing' }" class="proposed__link">{{
                  $t('pages.account.account_worker.accountWorkerDashboardPage.seeAll') }}</router-link>
              </div>
              <div class="proposed-group">
                <button class="recomended-test big-recomended-test">
                  <div class="recomended-test-caption">
                    <img alt="" src="~@/assets/img/vacancy/test-bg.svg" class="recomended-test-caption__img">
                    <div class="recomended-description">
                      <p class="recomended-description__comany">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.myTesting') }}</p>
                      <p class="recomended-description__format" v-if="false">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.totalTests') }}: {{
                          dataInterview.length
                        }}</p>
                    </div>
                  </div>
                  <div class="recomended-test-info">
                    <div class="recomended-info-element green">
                      <p class="recomended-info-name">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.activeTests') }}</p>
                      <div class="recomended-info-text">
                        <p class="recomended-info-text__number">{{ dataStat.available }}</p>
                        <p class="recomended-info-text__notifications" v-if="false">+99</p>
                      </div>
                    </div>
                    <div class="recomended-info-element red">
                      <p class="recomended-info-name">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.passedTests') }}:</p>
                      <div class="recomended-info-text">
                        <p class="recomended-info-text__number">{{ dataStat.passed }}</p>
                        <p class="recomended-info-text__notifications" v-if="false">-1</p>
                      </div>
                    </div>
                  </div>
                  <button @click="$router.push({ name: 'testing' })" class="recomended-test__start">{{
                    $t('pages.account.account_worker.accountWorkerDashboardPage.readMore') }}</button>
                </button>
                <DetailedTest :key="item.id" v-for="item in activeTesting" :name="item.title" :employer="false"
                  :avatar="require('@/assets/img/test-page/test/test-element-bg.png')" :test="item">
                </DetailedTest>
              </div>
            </div>

            <div class="proposed">
              <div class="proposed-caption">
                <h3 class="proposed__title">{{ $t('pages.account.account_worker.accountWorkerDashboardPage.communities')
                  }}:</h3>
                <router-link :to="{ name: 'communities' }" class="proposed__link">{{
                  $t('pages.account.account_worker.accountWorkerDashboardPage.seeAll') }}</router-link>
              </div>
              <div class="proposed-group">
                <button class="recomended-test">
                  <div class="recomended-test-caption">
                    <img alt="" src="~@/assets/img/vacancy/community-bg.svg" class="recomended-test-caption__img">
                    <div class="recomended-description">
                      <p class="recomended-description__comany">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.myCommunities') }}:</p>
                      <p class="recomended-description__format">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.totalCommunities') }}:
                        {{ dataStat.closedComms + dataStat.openComms }}</p>
                    </div>
                  </div>
                  <div class="recomended-test-info">
                    <div class="recomended-info-element green">
                      <p class="recomended-info-name">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.open') }}:</p>
                      <div class="recomended-info-text">
                        <p class="recomended-info-text__number">{{ dataStat.openComms }}</p>
                        <p class="recomended-info-text__notifications" v-if="false">+99</p>
                      </div>
                    </div>
                    <div class="recomended-info-element green">
                      <p class="recomended-info-name">{{
                        $t('pages.account.account_worker.accountWorkerDashboardPage.closed') }}:</p>
                      <div class="recomended-info-text">
                        <p class="recomended-info-text__number">{{ dataStat.closedComms }}</p>
                        <p class="recomended-info-text__notifications" v-if="false">+99</p>
                      </div>
                    </div>
                  </div>
                  <button class="recomended-test__start" @click="$router.push({ name: 'communities' })">{{
                    $t('pages.account.account_worker.accountWorkerDashboardPage.readMore') }}
                  </button>
                </button>
                <DetailedCommunity v-for="item in myCommunities.filter(item => item.requestApproved === 'in')"
                  :community="item" :key="item.id">
                </DetailedCommunity>
              </div>
            </div>

            <div class="post-wrapper">
              <div class="post-caption">
                <h2 class="post__title">{{ $t('pages.account.account_worker.accountWorkerDashboardPage.latestEvents')
                  }}:</h2>
                <div class="select">
                  <button class="select__btn" @click="viewFilter = !viewFilter">
                    {{
                      filter === 'self' ? $t('pages.account.account_worker.accountWorkerDashboardPage.myNews') : filter
                        === 'friends' ? $t('pages.account.account_worker.accountWorkerDashboardPage.friendsNews') : filter
                          === 'extended' ?
                        $t('pages.account.account_worker.accountWorkerDashboardPage.recommendedNews') :
                        $t('pages.account.account_worker.accountWorkerDashboardPage.allNews')
                    }}<span class="list-icon"></span></button>
                  <div class="select-list" v-if="viewFilter">
                    <span class="select-list-icon"></span>
                    <button class="select-list__element" @click="changeFilter('self')">{{
                      $t('pages.account.account_worker.accountWorkerDashboardPage.myNews') }}</button>
                    <button class="select-list__element" @click="changeFilter('friends')">{{
                      $t('pages.account.account_worker.accountWorkerDashboardPage.friendsNews') }}</button>
                    <button class="select-list__element" @click="changeFilter('extended')">{{
                      $t('pages.account.account_worker.accountWorkerDashboardPage.recommendedNews') }}
                    </button>
                    <button class="select-list__element" @click="changeFilter('all')">{{
                      $t('pages.account.account_worker.accountWorkerDashboardPage.allNews') }}</button>
                  </div>
                </div>
              </div>

              <LastNewsWrapper :big-photo="bigPhotoUrl" @viewBigPhoto="val => bigPhotoUrl = val" :posts="posts">
              </LastNewsWrapper>

            </div>

          </div>
        </div>
        <div class="user desctop-user">
          <div class="user__wrapper">
            <div class="user__wrapper-caption">
              <h6 class="user__wrapper-caption-title">{{
                $t('pages.account.account_worker.accountWorkerDashboardPage.profile') }}</h6>
              <div class="user__wrapper-caption-btns">
                <button class="user__wrapper-caption-btns-bell">
                  <span class="user__wrapper-caption-btns-bell-notifications"></span>
                  <img src="~@/assets/img/all-img/bell-blue.svg" alt="" class="user__wrapper-caption-btns-bell-img" />
                </button>
                <router-link :to="{ name: 'settings' }" class="user__wrapper-caption-btns-change">
                  <img src="~@/assets/img/all-img/change.svg" alt="" class="user__wrapper-caption-btns-change-img" />
                </router-link>
              </div>
            </div>
            <div class="user__wrapper-person">
              <img :src="dataWorker.avatarPath" @click="viewAvatar = true" :style="{
                backgroundImage: 'url(' + dataWorker.avatarPath + ')'

              }" alt=" " class="user__wrapper-person-icon" />
              <p class="user__wrapper-person-name">{{ dataWorker.name }}</p>
              <p class="user__wrapper-person-finder">{{
                $t('pages.account.account_worker.accountWorkerDashboardPage.jobSeeker') }}</p>
            </div>
            <div class="user__wrapper-person-tests">
              <div class="user__wrapper-person-tests-caption">
                <h5 class="user__wrapper-person-tests-caption-title">
                  {{ $t('pages.account.account_worker.accountWorkerDashboardPage.availableTests') }}
                </h5>
                <router-link :to="{ name: 'testing' }" class="user__wrapper-person-tests-caption-text">
                  {{ $t('pages.account.account_worker.accountWorkerDashboardPage.seeAll') }}
                </router-link>
              </div>
              <div class="user__wrapper-person-tests-group">
                <div class="user__wrapper-person-tests-group-element">
                  <img src="~@/assets/img/account-img/dashboard/bank.svg" alt=""
                    class="user__wrapper-person-tests-group-element-preview" />
                  <div class="user__wrapper-person-tests-group-element-description">
                    <h5 class="
                        user__wrapper-person-tests-group-element-description-title
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.sqlTest') }}
                    </h5>
                    <p class="
                        user__wrapper-person-tests-group-element-description-text
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                    </p>
                  </div>
                  <button @click="goToTesting('sql')" class="user__wrapper-person-tests-group-element-start"></button>
                </div>
                <div class="user__wrapper-person-tests-group-element">
                  <img src="~@/assets/img/account-img/dashboard/cash.svg" alt=""
                    class="user__wrapper-person-tests-group-element-preview" />
                  <div class="user__wrapper-person-tests-group-element-description">
                    <h5 class="
                        user__wrapper-person-tests-group-element-description-title
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.profTesting') }}
                    </h5>
                    <p class="
                        user__wrapper-person-tests-group-element-description-text
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                    </p>
                  </div>
                  <button @click="goToTesting('prof')" class="user__wrapper-person-tests-group-element-start"></button>
                </div>
                <div class="user__wrapper-person-tests-group-element">
                  <img src="~@/assets/img/account-img/dashboard/profile-icon.svg" alt=""
                    class="user__wrapper-person-tests-group-element-preview" />
                  <div class="user__wrapper-person-tests-group-element-description">
                    <h5 class="
                        user__wrapper-person-tests-group-element-description-title
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.iqTest') }}
                    </h5>
                    <p class="
                        user__wrapper-person-tests-group-element-description-text
                      ">
                      {{ $t('pages.account.account_worker.accountWorkerDashboardPage.plus5Points') }}
                    </p>
                  </div>
                  <button @click="goToTesting('iq')" class="user__wrapper-person-tests-group-element-start"></button>
                </div>
              </div>
            </div>
            <div class="user__wrapper-opportunities" v-if="false">
              <span class="user__wrapper-opportunities-decoration-1"></span>
              <span class="user__wrapper-opportunities-decoration-2"></span>
              <span class="user__wrapper-opportunities-decoration-3"></span>
              <span class="user__wrapper-opportunities-decoration-4"></span>
              <p class="user__wrapper-opportunities-text">
                {{ $t('pages.account.account_worker.accountWorkerDashboardPage.upgradeYourPlan') }}
              </p>
              <router-link :to="{ name: 'prices' }" class="user__wrapper-opportunities-button-link">
                {{ $t('pages.account.account_worker.accountWorkerDashboardPage.morePossibilities') }}
              </router-link>
            </div>
            <div class="user__wrapper-help">
              <a href="#" class="user__wrapper-help-btn">{{
                $t('pages.account.account_worker.accountWorkerDashboardPage.needHelp') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import DetailedVacancy
  from "../../../components/account/account_worker/account_worker_vacancy/item/DetailedVacancy.vue";
import SearchElement
  from "../../../components/account/SearchElement.vue";
import { config } from "@/lib/config";
import { logoutWithToken } from "@/lib/logoutWithToken";
import DetailedTest from "@/components/account/account_worker/account_worker_dashboard/item/DetailedTest";
import DetailedCommunity from "@/components/account/account_worker/account_worker_dashboard/item/DetailedCommunity";
import Cookies from "js-cookie";
import QuestionModal from "@/components/account/account_worker/modals/item/QuestionModal";
import LastNewsWrapper from "@/components/social/account_news/LastNewsWrapper";
import BigPhoto from "@/components/social/account_communitis_main/BigPhoto.vue";

export default {
  computed: {
    myCommunities() {
      return this.$store.getters.getMyCommunities;
    },
    activeTesting() {
      return this.$store.getters.activeTesting;
    },
    user() {
      return this.$store.getters.user;
    },
    photos() {
      let photos = [];
      if (this.dataWorker?.avatarPath) {
        photos.push(this.dataWorker.avatarPath);
      }
      if (this.dataWorker?.avatarArchive && typeof this.dataWorker?.avatarArchive === 'object') {
        photos = photos.concat(this.dataWorker.avatarArchive);
      }
      return photos;
    }
  },
  components: {
    BigPhoto,
    MenuWorker,
    HeaderAccountWorker,
    DetailedVacancy,
    DetailedTest,
    DetailedCommunity,
    SearchElement,
    QuestionModal,
    LastNewsWrapper,
  },
  data() {
    return {
      viewAvatar: false,

      dataStat: null,
      dataWorker: {},
      dataVacancy: [],
      dataScore: null,
      vacancyName: null,
      posts: null,
      page: 1,
      limit: 6,
      filter: 'self',

      viewFilter: false,
      viewModal: false,
      bigPhotoUrl: null
    };
  },
  name: "AccountWorkerDashboardPage",
  methods: {
    closeModal() {
      this.viewModal = false;
      Cookies.set('closeModal', true);
    },
    createNews() {
      const data = {
        fromUser: null,
        toUser: null,
        newsType: 'birthday'
      }
      this.$store.dispatch('createNews', data).then(() => {
        this.closeModal();
      });
    },
    changeFilter(filter) {
      this.filter = filter;
      this.page = 1;
      this.viewFilter = false;
      this.loadPosts();
      this.paginationLoad();

    },
    goToTesting(category) {
      this.$router.push({ name: 'testing', hash: '#' + category });
    },
    fetchDataWorker() {
      this.$store.dispatch('fetchCandidateData').then(() => {
        this.dataWorker = this.user;
        this.dataWorker.avatarArchive = this.dataWorker.avatarArchive ? this.dataWorker.avatarArchive.split(',').filter(item => item !== '') : null;
        const today = new Date();
        const birthDay = new Date(this.dataWorker.birthDate);
        if ((today.getMonth() === birthDay.getMonth()) && (today.getDate() === birthDay.getDate() + 1)) {
          let notification = Cookies.get('closeModal');
          if (notification == null || notification === 'undefined' || notification === 'null') {
            this.viewModal = true;
          }
        }
      })
    },
    fetchVacancy() {
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + "candidate/recommendations";
      request.open("GET", url, false);
      request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      try {
        request.send();
        logoutWithToken(request.status, this.$store);
        let response = JSON.parse(request.response);
        this.dataVacancy = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    fetchDataScore() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'candidate/rate';
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        logoutWithToken(req.status, this.$store);
        const res = JSON.parse(req.response);
        this.dataScore = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    loadPosts() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'user/news?page=' + this.page + '&limit=' + this.limit + '&filter=' + this.filter;
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        const { data } = JSON.parse(req.response);
        if (this.page > 1) {
          this.posts = this.posts.concat(data)
        } else {
          this.posts = data;
        }
        if (data.length < this.limit) {
          window.onscroll = null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    paginationLoad() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= this.$refs.main.offsetHeight;
        if (bottomOfWindow) {
          this.page++;
          this.loadPosts();
        }
      }
    },
    fetchStatistic() {
      const req = new XMLHttpRequest();
      const url = config.apiPrefix + 'candidate/testing/stats';
      req.open('GET', url, false);
      req.setRequestHeader('X-Auth-Token', this.$store.getters.token);
      try {
        req.send();
        const { data } = JSON.parse(req.response);
        this.dataStat = data;

      } catch (e) {
        console.log(e);
      }
    },
    fetchCommunity() {
      this.$store.dispatch('fetchMyCommunities', { page: 1, limit: 4, token: this.$store.getters.token });

    },
    fetchCandidateTesting() {
      this.$store.dispatch('fetchAvailableTest', 'prof');
    },
    isFavoriteVacancy(vacancyId) {
      const favoriteVacancies = this.$store.getters.favoriteVacancy;
      return favoriteVacancies.some(vacancy => vacancy.id === vacancyId);
    },
  },
  beforeMount() {
    scroll(0, 0);
    this.fetchDataWorker();
    this.fetchVacancy();
    this.fetchDataScore();
    this.loadPosts();
    this.paginationLoad();
    this.fetchStatistic();
    this.fetchCommunity();
    this.fetchCandidateTesting();
  },
  beforeDestroy() {
    window.onscroll = null;
  }
};
</script>


<style scoped>
.account {
  display: flex;
}

.mobile {
  display: none;
}

.filling {
  display: flex;
  width: 100%;

}

.filling__caption {
  width: 100%;
}

.filling__caption-head {
  display: flex;
  align-items: center;
}

.filling__caption-head-greetings {
  margin: auto 0;
}

.filling__caption-head-greetings-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.filling__caption-head-greetings-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.filling__caption-head-surch {
  margin-left: auto;
  position: relative;
}

.filling__caption-head-surch-entry {
  padding-right: 58px;
  height: 55px;
  margin: auto 0;
  width: 307px;
  padding-left: 33px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #171725;
}

.filling__caption-head-surch-entry::placeholder {
  color: #b7c1d1;
}

.filling__caption-head-surch-find {
  top: 9px;
  position: absolute;
  display: inline-block;
  right: 10px;
  transition: opacity 1s ease;
  padding: 12px;
  line-height: 0;
  background: linear-gradient(192.41deg, #4099ff 0%, #a1a8ff 138.89%);
  border-radius: 100%;
}

.filling__caption-head-surch-find-img {
  object-fit: cover;
  width: 12px;
  height: 12px;
}

.filling__caption-head-surch-find:hover {
  opacity: 0.5;
}

.filling__caption-wrapp {
  margin-top: 40px;
  display: flex;
  max-height: 267px;
}

.filling__caption-wrapp-statistics-graph {
  padding-right: 43px;
  width: 100%;
}

.filling__caption-wrapp-statistics-graph-title {
  font-family: "Montserrat";
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filling__caption-wrapp-statistics-graph-group-element {
  margin-top: 15px;
}

.filling__caption-wrapp-statistics-graph-group-element-text {
  display: flex;
}

.filling__caption-wrapp-statistics-graph-group-element-text-category {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-graph-group-element-text-points {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #009ed1;
}

.filling__caption-wrapp-statistics-graph-group-element-field {
  margin-top: 17px;
  width: 100%;
  max-width: 100%;
  height: 10px;
  background: #e3f0ff;
  border-radius: 69px;
}

.filling__caption-wrapp-statistics-graph-group-element-fill-repletion {
  height: 100%;
  max-width: 100%;
  border-radius: 69px;
  background: #009ed1;
}

.filling__caption-wrapp-statistics-circle {
  padding-left: 17px;
}

.filling__caption-wrapp-statistics-circle-title {
  display: flex;
  align-items: center;
}

.filling__caption-wrapp-statistics-circle-title-text {
  padding-left: 19px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__caption-wrapp-statistics-circle-title-link {
  margin-left: auto;
  padding: 6px 8px;
  background: #f2fbfc;
  border-radius: 100%;
}

.filling__caption-wrapp-statistics-circle-rating {
  background: #f3f3f3;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 28px;
  border-radius: 100%;
  margin: 39px auto 0 auto;
  text-align: center;
  color: #14c686;
  line-height: 28px;
  display: flex;
  width: 104px;
  height: 104px;
  justify-content: center;
  align-items: center;
}

/* proposed */
.proposed {
  margin-top: 40px;
  max-width: 100%;
  width: 100%;
}

.proposed-caption {
  display: flex;
  justify-content: space-between;
}

.proposed__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #3b4c68;
}

.proposed__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;

  color: #4099ff;
  transition: all 0.5s ease;
}

.proposed__link:hover {
  opacity: 0.5;
}

.proposed-group {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* post */
.post-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.select {
  position: relative;
}

.select__btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #4099ff;
  transition: all 0.5s ease;
}

.select__btn:hover {
  opacity: 0.5;
}

.list-icon {
  background: url(../../../assets/img/all-img/list-grey.svg);
  width: 8px;
  height: 6px;
  margin-left: 6px;
  background-size: contain;
}

.select-list {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 218px;
  bottom: -130px;
  right: -15px;
  z-index: 2;
}

.select-list-icon {
  display: block;
  position: absolute;
  background: url(../../../assets/img/all-img/select-arrow.svg);
  right: 12px;
  top: -10px;
  width: 14px;
  height: 10px;
  content: "";
  background-size: contain;
}

.select-list__element {
  padding: 6px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  max-width: 100%;
  width: 100%;
  text-align: left;
  color: #44444f;
  transition: all 0.5s ease;
}

.select-list__element:hover {
  color: #4099ff;
}

.post__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #3b4c68;

}

.post-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filling__caption-wrapp-statistics-circle-rating span {
  display: flex;
  justify-content: center;
}

.filling__caption-wrapp-statistics-circle-text {
  text-align: center;
  padding-top: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: #14c686;
  min-width: 160px;
}

/*recomended-test*/
.recomended-test {
  max-width: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #f1f5f9;
  border-radius: 10px;
  height: 205px;
  box-sizing: border-box;
  font-family: "Roboto";
  text-align: left;
}

.big-recomended-test {
  height: 225px;
}

.recomended-test-caption {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.recomended-test-caption__img {
  width: 53px;
  height: 53px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  object-fit: cover;
}

.recomended-description__comany {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  display: flex;
  align-items: center;
  gap: 6px;
}

.recomended-description__format {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.recomended-description__comany-status {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-top: 2px;
}

.recomended-test-info {
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  gap: 5px;
  width: 100%;
}

.recomended-info-element {
  display: flex;
  max-width: 100%;
  text-align: left;
  width: 100%;
  flex-direction: column;
}

.recomended-info-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(59, 76, 104, 0.800000011920929);
}

.recomended-info-text {
  display: flex;
  align-items: center;
  margin-top: 5px;

}

.recomended-info-text__number {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #3b4c68;
}

.recomended-info-text__notifications {
  margin-left: 5px;
  padding: 4px 8px;
  border-radius: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #14c686;

}

.red .recomended-info-text__notifications {
  background: #db0a0a;
}

.recomended-test__start {
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #3b4c68;
  border-top: 1px solid rgba(231, 233, 236, 0.8);
  padding-top: 20px;
  margin-top: auto;
  padding-bottom: 10px;
  color: #009ed1;
}

@media (max-width: 1360px) {
  .filling__caption-wrapp-statistics-graph-title {
    padding-top: 4px;
  }
}

.filling__caption-wrapp-statistics {
  width: 100%;
  background: #fff;
  border: 1px solid #ebedf0;
  border-radius: 20px;
  display: flex;
  padding: 29px 23px 21px 45px;
}

.filling__caption-wrapp-statistics-border {
  margin-left: auto;
  width: 1px;
  height: 213px;
  background: url("~@/assets/img/profile-page/caption/border.png");
}

@media (max-width: 1360px) {
  .filling__caption-wrapp-statistics {
    padding: 18px 20px 29px 25px;
  }
}

/* Vacancy */
.filling__vacancy {
  margin-top: 39px;
}

.filling__vacancy-caption {
  display: flex;
  align-items: center;
}

.filling__vacancy-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.filling__vacancy-caption-btn {
  margin-left: auto;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4099ff;
}

.filling__vacancy-group {
  display: grid;
  padding-bottom: 30px;
}

@media (max-width: 1360px) {
  .user__wrapper {
    margin: 40px auto 0 auto;
  }

  .filling__caption-head-surch-entry {
    width: 256px;
    height: 46px;
    padding-left: 27px;
  }

  .filling__caption-head-surch-find {
    padding: 9px;
    top: 8px;
  }
}

@media (max-width: 720px) {
  .filling__caption-profile {
    flex-direction: column;

  }

  .user__wrapper-person {
    margin: 25px auto 0 auto;
  }

  .user__wrapper-description {
    margin: 20px auto 0 auto;
  }

  .list-icon {
    display: none;
  }

  .select__btn {
    font-size: 0;
    background: url(../../../assets/img/all-img/select-icon.svg);
    width: 24px;
    height: 24px;
  }

  .select-list {
    bottom: -130px;
    right: 0;
    width: 300px;
  }

  .select-list-icon {
    right: 6px;
  }
}

@media (max-width: 600px) {
  .filling__caption-head {
    flex-direction: column;
  }

  .filling__caption-head-greetings-text {
    text-align: center;
  }

  .filling__caption-head-surch {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }

  .filling__caption-head-surch-entry {
    width: 100%;
  }

  .filling__caption-head-greetings-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .filling__caption-head-greetings-title {
    color: #000000;
    font-size: 20px;
  }
}

@media (max-width: 490px) {
  .filling__vacancy-caption-title {
    font-size: 13px;
  }

  .filling__vacancy-caption-btn {
    font-size: 12px;
  }
}

@media (max-width: 340px) {
  .filling__caption-head-greetings {
    margin: 0;
  }

  .filling__caption-head-greetings-text {
    text-align: left;
  }

  .filling__caption-head {
    align-items: unset;
  }
}

/* /Vacancy */

/* Smmary */

.recomedation__wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-top: 36px;
  display: grid;
  row-gap: 20px;
}

@media (max-width: 1840px) {
  .recomedation__wrapper {
    width: 100%;
    margin-top: 36px;
    display: grid;
    row-gap: 20px;
    grid-template-columns: repeat(3, 300px);
    column-gap: 72px;
  }
}

@media (max-width: 1710px) {
  .proposed-group {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media (max-width: 1200px) {
  .proposed-group {
    grid-template-columns: repeat(2, 1fr);
  }

}


@media (max-width: 950px) {

  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {

  /* proposed */
  .proposed {
    margin: 40px auto 0 auto;
    max-width: 315px;
  }

  .proposed__title {
    font-size: 14px;
  }

  .proposed__link {
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto";
    color: #4099ff;
  }

  .proposed-group {
    grid-template-columns: repeat(1, 1fr);
  }

  /* post */
  .post-wrapper {
    margin: 40px auto 40px auto;
    max-width: 315px;
  }

  .post__title {
    font-size: 14px;
  }
}

@media (max-width: 490px) {}

@media (max-width: 360px) {
  .recomedation__wrapper {
    margin-top: 15px;
  }
}

/* /Smmary */

/* User */
.user {
  scrollbar-width: thin;
  padding-top: 59px;
  background: #fff;
  max-width: 429px;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  border-left: 1px solid #ebedf0;
}

@media (max-height: 950px) {
  .user {
    scrollbar-width: thin;
  }
}

.user__wrapper {
  display: flex;
  width: 284px;
  margin: 26px auto 0 auto;
  flex-direction: column;
}

.user__wrapper-caption {
  display: flex;
  align-items: center;
}

.user__wrapper-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  color: #3b4c68;
}

.user__wrapper-caption-btns {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.user__wrapper-caption-btns-bell {
  margin-right: 9px;
  position: relative;
  background-position: center;
  transition: opacity 1s ease;
  background: #edf7f9;
  border-radius: 100%;
  padding: 12px 13px;
  line-height: 0;
}

.user__wrapper-caption-btns-bell:hover {
  opacity: 0.5;
}

.user__wrapper-caption-btns-bell-notifications {
  position: absolute;
  content: "";
  top: 8px;
  right: 9px;
  display: none;
  width: 10px;
  height: 10px;
  background: #14c686;
  border-radius: 100%;
}

.user__wrapper-caption-btns-change {
  background: #edf7f9;
  border-radius: 100%;
  padding: 13px 13px;
  line-height: 0;
  transition: opacity 1s ease;
}

.user__wrapper-caption-btns-change:hover {
  opacity: 0.5;
}

.user__wrapper-person {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.user__wrapper-person-icon {
  cursor: pointer;
  margin: 20px auto 0 auto;
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  background-color: #edeef0;
}

.user__wrapper-person-name {
  padding-top: 32px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.user__wrapper-person-finder {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #b7c1d1;
}

.user__wrapper-person-tests {
  margin-top: 30px;
  width: 100%;
}

.user__wrapper-person-tests-caption {
  align-items: center;
  display: flex;
}

.user__wrapper-person-tests-caption-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b4c68;
}

.user__wrapper-person-tests-caption-text {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4099ff;
}

.user__wrapper-person-tests-group-element {
  margin-top: 35px;
  display: flex;
  align-items: center;
}

.user__wrapper-person-tests-group-element-preview {
  object-fit: cover;
  object-position: center;
}

.user__wrapper-person-tests-group-element-description {
  margin-left: 13px;
}

.user__wrapper-person-tests-group-element-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.user__wrapper-person-tests-group-element-description-text {
  padding-top: 5px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8b94a2;
}

.user__wrapper-person-tests-group-element-start {
  margin-left: auto;
  background: url("~@/assets/img/profile-page/account_worker_page/start.svg");
  background-position: center;
  background-size: cover;
  width: 26px;
  height: 26px;
  transition: opacity 1s ease;
}

.user__wrapper-person-tests-group-element-start:hover {
  opacity: 0.5;
}

.user__wrapper-opportunities {
  margin-top: 37px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #ecf5fa;
  border-radius: 20px;
}

.user__wrapper-opportunities-decoration-1 {
  position: absolute;
  display: block;
  top: -8px;
  left: 16px;
  background: url("~@/assets/img/account-img/dashboard/decoration-1.svg");
  width: 24px;
  height: 22px;
}

.user__wrapper-opportunities-decoration-2 {
  position: absolute;
  display: block;
  right: -11px;
  top: -8px;
  background: url("~@/assets/img/account-img/dashboard/decoration-2.svg");
  width: 27px;
  height: 28px;
}

.user__wrapper-opportunities-decoration-3 {
  position: absolute;
  display: block;
  bottom: 21px;
  left: -11px;
  background: url("~@/assets/img/account-img/dashboard/decoration-3.svg");
  width: 24px;
  height: 30px;
}

.user__wrapper-opportunities-decoration-4 {
  position: absolute;
  display: block;
  bottom: 0;
  right: -5px;
  background: url("~@/assets/img/account-img/dashboard/decoration-4.svg");
  width: 28px;
  height: 29px;
}

.user__wrapper-opportunities-text {
  padding: 27px 20px 0 34px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.02em;
  color: #3b4c68;
}

.user__wrapper-opportunities-button-link {
  display: block;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #ffffff;

  margin: 22px auto 26px auto;
  width: 232px;
  padding: 16px 0;
  box-sizing: border-box;
  background: #009ed1;
  text-align: center;
  border-radius: 8px;
  transition: background 1s ease;
}

.user__wrapper-opportunities-button-link:hover {
  background: #80cfe8;
}

.user__wrapper-help-btn {
  display: block;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #009ed1;

  margin: 22px auto 26px auto;
  width: 232px;
  padding: 16px 0;
  box-sizing: border-box;
  background: rgba(0, 158, 209, 0.05000000074505806);
  text-align: center;
  border-radius: 8px;
  transition: opacity 1s ease;
}

.user__wrapper-help-btn:hover {
  opacity: 0.5;
}

@media (max-width: 950px) {
  .desctop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .filling__caption-profile {
    margin-top: 28px;
    display: flex;
  }

  .user__wrapper-person {
    margin-top: 0;
    padding: 20px 20px 30px 20px;
    max-width: 315px;
    border: 1px solid #ebedf0;
    border-radius: 20px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
  }

  .user__wrapper-person-name {
    padding-top: 23px;
  }

  .user__wrapper-person-change {
    margin-top: 20px;
    padding: 15px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #009ed1;
    border-radius: 8px;
  }

  .user__wrapper-person-change-btn {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #009ed1;
  }

  .user__wrapper-person-tests {
    margin-top: 0;
    margin-left: 28px;
    max-width: 315px;
    width: 100%;
  }

  .user__wrapper-person-tests-caption-title {
    font-size: 13px;
  }

  .user__wrapper-person-tests-group {
    margin-top: 32px;
    width: 100%;
    box-sizing: border-box;
  }

  .user__wrapper-person-tests-group-element {
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .user__wrapper-person-tests-group-element:not(:last-child) {
    margin-bottom: 35px;
  }

  .user__wrapper-description {
    border: 1px solid #ebedf0;
    margin-top: 0;
    margin-left: auto;
    max-width: 314px;
    width: 100%;
    border-radius: 20px;
  }

  .user__wrapper-description-text {
    padding: 18px 15px 24px 21px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.73;
  }

  .user__wrapper-description {
    display: none;
  }
}

@media (max-width: 720px) {
  .user__wrapper-description {
    margin-top: 20px;
  }

  .user__wrapper-person-tests {
    margin: 31px auto 0 auto;
    max-width: 315px;
    width: 100%;
  }
}

@media (max-width: 1740px) {
  .user {
    max-width: 326px;
  }
}

@media (max-width: 950px) {
  .desctop-user {
    display: none;
  }
}

@media (max-width: 1710px) {
  .recomedation__wrapper-element {
    max-width: 350px;
  }
}

@media (max-width: 950px) {
  .recomedation__wrapper-element {
    max-width: 300px;
  }
}

/* /User */
</style>
