<template>
  <div @click="goToVacancy" class="response">
    <div class="response__settings-wrapper">
      <button class="response__settings" @click="viewMenu"></button>
      <div class="popap-list" v-if="menuVisible">
        <button class="popap-list__button delete" @click="deleteVacancy">{{ $t('components.account_company.separateVacancy.delete') }}</button>
        <button class="popap-list__button change" @click="editVacancy">{{ $t('components.account_company.separateVacancy.edit') }}</button>
      </div>
    </div>
    <img :src="vacancy.previewImgUrl" alt="" class="response__caption"/>
    <div class="response__filling">
      <div class="response__filling-element">
        <p class="response__filling-profession-name">{{ $t('components.account_company.separateVacancy.jobTitle') }}</p>
        <p class="response__filling-profession-descripton">
          {{ vacancy.name ? vacancy.name : "Untitled" }}
        </p>
      </div>
      <div class="response__filling-element">
        <p class="response__filling-profession-name">{{ $t('components.account_company.separateVacancy.description') }}</p>
        <p class="response__filling-profession-descripton">
          {{ vacancy.description ? limit(vacancy.description, 40) : "no description" }}
        </p>
      </div>
      <div class="response__filling-element">
        <p class="response__filling-schedule-name">{{ $t('components.account_company.separateVacancy.schedule') }}</p>
        <p class="response__filling-schedule-descripton">
          {{
            vacancy.employType === '0' ? $t('components.account_company.separateVacancy.fullEmployment') : 
            vacancy.employType === '1' ? $t('components.account_company.separateVacancy.partTimeEmployment') : 
            vacancy.employType === '2' ? $t('components.account_company.separateVacancy.projectActivity') : 
            vacancy.employType === '3' ? $t('components.account_company.separateVacancy.internship') : 
            vacancy.employType === '4' ? $t('components.account_company.separateVacancy.volunteering') : 
            vacancy.employType === '5' ? $t('components.account_company.separateVacancy.distantWork') : 
            $t('components.account_company.separateVacancy.schedule')
          }}
        </p>
      </div>
      <div class="response__filling-element">
        <p class="response__filling-schedule-name">{{ $t('components.account_company.separateVacancy.experience') }}</p>
        <p class="response__filling-schedule-descripton">
          {{
            vacancy.workExperience === '0' ? $t('components.account_company.separateVacancy.over10Years') : 
            vacancy.workExperience === '1' ? $t('components.account_company.separateVacancy.years5to10') : 
            vacancy.workExperience === '2' ? $t('components.account_company.separateVacancy.years3to5') : 
            vacancy.workExperience === '3' ? $t('components.account_company.separateVacancy.years1to3') : 
            vacancy.workExperience === '4' ? $t('components.account_company.separateVacancy.lessThan1Year') : 
            vacancy.workExperience === '5' ? $t('components.account_company.separateVacancy.noExperience') : 
            $t('components.account_company.separateVacancy.experience')
          }}
        </p>
      </div>
      <div class="response__filling-element">
        <div class="response__filling-place-name">
          {{ vacancy.city ? vacancy.city : "undefined" }}
        </div>
      </div>
      <div class="response__filling-element">
        <p class="response__filling-salary-name">{{ $t('components.account_company.separateVacancy.expectedSalary') }} :</p>
        <p class="response__filling-salary-price">
          {{
            vacancy.payFrom
                ? salary
                : $t('components.account_company.separateVacancy.basedOnInterview')
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { convertSalary } from "@/lib/values";

export default {
  name: "SeparateVacancy",
  props: {
    vacancy: {},
  },
  computed: {
    salary() {
      return this.convertSalary(this.vacancy?.payFrom ? this.vacancy?.payFrom : 0, this.vacancy?.expectedSalaryCurrency ? this.vacancy?.expectedSalaryCurrency.toUpperCase() : "usd", this.vacancy?.expectedSalaryCurrency ? this.vacancy?.expectedSalaryCurrency.toUpperCase() : "usd")
    },
  },
  data() {
    return {
      limit: textSymbolLimit,
      menuVisible: false
    }
  },
  methods: {
    convertSalary,
    goToVacancy() {
      this.$router.push({ name: 'vacancyResponses', params: { vacancyId: this.vacancy.id } });
    },
    viewMenu(e) {
      e.stopPropagation();
      this.menuVisible = !this.menuVisible;
    },
    editVacancy(e) {
      e.stopPropagation();
      this.$router.push({ name: 'updateVacancy', params: { vacancyId: this.vacancy.id } });
    },
    deleteVacancy(e) {
      e.stopPropagation();
      this.$store.dispatch('deleteVacancy', {
        id: this.vacancy.id, callback: () => {
          this.$emit('callback');
        }
      });
    }
  }
};
</script>


<style scoped>
.response__settings {
  position: relative;
  display: block;
  background: url("~@/assets/img/account-img/friends/settings.svg");
  width: 40px;
  height: 40px;
  margin: 0 20px 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.response__settings-wrapper {
  position: relative;
}

.popap-list {
  position: absolute;
  text-align: left;
  right: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #EAEAEE;
}

.popap-list__button {
  text-align: left;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding-left: 10px;
  width: 100%;
  cursor: pointer;

  border-radius: 4px;
  transition: all 1s ease-in-out;
}

.delete:hover {
  background: #f8cece;
  color: #DC0B0B;
}

.change:hover {
  background: #e3f4fa;
  color: #009ed1;
}


.response {
  box-sizing: border-box;
  max-width: 380px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
}

.response__filling {
  padding: 0 20px 20px 20px;
}

.response__caption {
  height: 147px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.response__filling-element:not(:last-child) {
  border-bottom: 1px solid #f1f1f5;
}

.response__filling-element {
  padding: 10px 0px;
}

.response__filling-element:last-child {
  padding: 10px 0 0 0;
}

.response__filling-schedule {
  margin-top: 10px;
}

.response__filling-schedule-name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #171625;
}

.response__filling-schedule-descripton {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.response__filling-profession-name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #171625;
}

.response__filling-profession-descripton {
  padding-top: 7px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.response__filling-place {
  margin-top: 15px;
}

.response__filling-place-name {
  padding-left: 20px;
  background: url("~@/assets/img/all-img/place.svg");
  background-position-y: 3px;
  background-repeat: no-repeat;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.response__filling-salary {
  padding-bottom: 13px;
  margin-top: 15px;
}

.response__filling-salary-name {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.response__filling-salary-price {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

@media (max-width: 400px) {
  .response__interactions-feedback-invite {
    margin-right: 10px;
  }

  .response {
    max-width: 100%;
    box-sizing: border-box;
  }
}
</style>