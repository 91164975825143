<template>
  <div class="form-wrapper">
    <div class="form">
      <input type="text" v-on:keypress.enter="findUser" v-model="searchParams.name" class="form__input" maxlength="70"
        :placeholder="$t('components.social.searchCom.placeholder')" />
      <button @click="cancelSearch" v-if="searchParams.name" class="cancel__button"></button>
      <span class="surch-blue"></span>
      <div class="settigs-wrapper">
        <button v-if="friends" type="button" class="from__button"
          v-on:click="viewSearchParams = !viewSearchParams"></button>
        <div class="friend__settings-popap" v-if="viewSearchParams">
          <div class="surch-wrapper">
            <h3 class="surch__title">{{ $t('components.social.searchCom.options') }}</h3>
            <div class="surch-persone" v-if="false">
              <button class="surch-persone__select surch-persone__select-chozen">
                {{ $t('pages.account.account_worker.accountWorkerSettingsPage.applicant') }}
              </button>
              <button class="surch-persone__select">{{$t('components.general.support.chatBot.employer')}}</button>
            </div>
            <div>
              <div class="surch-select__element" v-if="false" style="margin-top: 20px">
                <h4 class="surch__subtitle">Field of activity:</h4>
                <button type="button" class="surch-select">
                  Web design <span class="surch-select__list-icon"></span>
                </button>
              </div>
              <div class="surch-select__element" v-if="false">
                <h4 class="surch__subtitle">City:</h4>
                <button type="button" class="surch-select">
                  Russia Moscow <span class="surch-select__list-icon"></span>
                </button>
              </div>
              <div class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle">{{$t('pages.account.account_company.accountCompanyFavoritesPage.sorting')}}:</h4>
                <div class="select-wrapper" ref="currencyContainer">
                  <button class="select-btn" @click="toggleCurrencyVisible">
                    {{
                      searchParams.sort === "dec"
                        ? $t('pages.account.account_worker.accountWorkerVacancyPage.decreasing')
                        : searchParams.sort === "inc"
                          ? $t('pages.account.account_worker.accountWorkerVacancyPage.increasing')
                          : currentLang==='ru' ? 'По умолчанию' : 'Default'
                    }}
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.29367 0.333252C9.71292 0.333252 9.94601 0.818218 9.68411 1.1456L5.39109 6.51188C5.19092 6.76208 4.81038 6.76208 4.61022 6.51188L0.317195 1.1456C0.0552893 0.818217 0.288377 0.333252 0.70763 0.333252L9.29367 0.333252Z"
                        fill="#009ED1" />
                    </svg>
                  </button>
                  <div class="select-list" v-if="viewSortMenu">
                    <button class="select-list__element" @click="bySort('default')">
                      {{ currentLang==='ru' ? 'По умолчанию' : 'Default' }}
                    </button>
                    <button class="select-list__element" @click="bySort('inc')">
                      {{ $t('pages.account.account_worker.accountWorkerVacancyPage.decreasing') }}
                    </button>
                    <button class="select-list__element" @click="bySort('dec')">
                    {{  $t('pages.account.account_worker.accountWorkerVacancyPage.increasing')}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle"> {{  $t('pages.account.account_worker.accountWorkerVacancyPage.cityName')}}:</h4>
                <div class="select-wrapper">
                  <button class="select-btn" @click="toggleCityVisible">
                    {{ cityName }}
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.29367 0.333252C9.71292 0.333252 9.94601 0.818218 9.68411 1.1456L5.39109 6.51188C5.19092 6.76208 4.81038 6.76208 4.61022 6.51188L0.317195 1.1456C0.0552893 0.818217 0.288377 0.333252 0.70763 0.333252L9.29367 0.333252Z"
                        fill="#009ED1" />
                    </svg>
                  </button>
                  <div class="select-list" v-if="viewCity">
                    <input @input="(e) => searchCity(e.target.value)" type="search"
                      class="select-list__element select-list__input" :placeholder="$t('components.social.searchCom.placeholder')" maxlength="70" />
                    <button v-for="item in cities" :key="item.id" class="select-list__element" :value="item.id" :class="{
                      'select-list__element-selected':
                        item.name === searchParams.city,
                    }" @click="pickCity(item)">
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="false" class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle">{{ $t('pages.account.account_worker.accountWorkerFavoritesPage.typeOfEmployment') }}</h4>
                <div class="select-wrapper">
                  <button class="select-btn" @click="viewEmplType = !viewEmplType">
                    {{
                      searchParams.empl_type === "0"
                        ? $t('pages.account.account_worker.accountWorkerFavoritesPage.fullEmployment')
                        : searchParams.empl_type === "1"
                          ? $t('pages.account.account_worker.accountWorkerFavoritesPage.partTimeEmployment')
                          : searchParams.empl_type === "2"
                            ? $t('pages.account.account_worker.accountWorkerFavoritesPage.projectWork')
                            : searchParams.empl_type === "3"
                              ? $t('pages.account.account_worker.accountWorkerFavoritesPage.internship')
                              : searchParams.empl_type === "4"
                                ? $t('pages.account.account_worker.accountWorkerFavoritesPage.volunteering')
                                : searchParams.empl_type === "5"
                                  ? $t('pages.account.account_worker.accountWorkerFavoritesPage.distantWork')
                                  : $t('pages.tests.test_worker.testPreviewPage.notSelected')
                    }}
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.29367 0.333252C9.71292 0.333252 9.94601 0.818218 9.68411 1.1456L5.39109 6.51188C5.19092 6.76208 4.81038 6.76208 4.61022 6.51188L0.317195 1.1456C0.0552893 0.818217 0.288377 0.333252 0.70763 0.333252L9.29367 0.333252Z"
                        fill="#009ED1" />
                    </svg>
                  </button>
                  <div class="select-list" v-if="viewEmplType">
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '0',
                    }" @click="pickEmplType('0')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.fullEmployment') }}
                    </button>
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '1',
                    }" @click="pickEmplType('1')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.partTimeEmployment') }}
                    </button>
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '2',
                    }" @click="pickEmplType('2')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.projectWork') }}
                    </button>
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '3',
                    }" @click="pickEmplType('3')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.internship') }}
                    </button>
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '4',
                    }" @click="pickEmplType('4')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.volunteering') }}
                    </button>
                    <button class="select-list__element" :class="{
                      'select-list__element-selected':
                        searchParams.empl_type === '5',
                    }" @click="pickEmplType('5')">
                      {{ $t('pages.account.account_worker.accountWorkerFavoritesPage.distantWork') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle">{{ $t('pages.account.account_worker.accountWorkerVacancyPage.speciality') }}:</h4>
                <div class="select-wrapper">
                  <button class="select-btn" @click="viewProfession = !viewProfession">
                    {{ specializationName }}
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.29367 0.333252C9.71292 0.333252 9.94601 0.818218 9.68411 1.1456L5.39109 6.51188C5.19092 6.76208 4.81038 6.76208 4.61022 6.51188L0.317195 1.1456C0.0552893 0.818217 0.288377 0.333252 0.70763 0.333252L9.29367 0.333252Z"
                        fill="#009ED1" />
                    </svg>
                  </button>
                  <!-- <div class="select-list" v-if="viewProfession && specialties">
                    <input v-model="searchSpecial" type="search" class="select-list__element select-list__input"
                      placeholder="Поиск" />
                    <button
                      v-for="item in specialties.filter(spec => searchSpecial ? spec.name.toLowerCase().indexOf(searchSpecial.toLowerCase()) !== -1 : true)"
                      :key="item.id" class="select-list__element" :class="{
                        'select-list__element-selected':
                          item.id === searchParams.profession,
                      }" @click="pickProfession(item)">
                      {{ item.name }}
                    </button>
                  </div> -->
                </div>
              </div>
              <!-- <div class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle">City:</h4>
                <div class="select-wrapper" ref="cityContainer">
                  <button class="select-btn" @click="toggleCityVisible">
                    {{ cityName }}
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.29367 0.333252C9.71292 0.333252 9.94601 0.818218 9.68411 1.1456L5.39109 6.51188C5.19092 6.76208 4.81038 6.76208 4.61022 6.51188L0.317195 1.1456C0.0552893 0.818217 0.288377 0.333252 0.70763 0.333252L9.29367 0.333252Z"
                        fill="#009ED1" />
                    </svg>
                  </button>
                  <div class="select-list" v-if="viewCity">
                    <input @input="(e) => searchCity(e.target.value)" type="search"
                      class="select-list__element select-list__input" placeholder="Search" />
                    <button v-for="item in cities" :key="item.id" class="select-list__element" :value="item.id" :class="{
                      'select-list__element-selected':
                        item.name === searchParams.city,
                    }" @click="pickCity(item)">
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </div> -->
              <div class="surch-select__element" style="margin-top: 20px">
                <h4 class="surch__subtitle">{{ $t('pages.account.account_worker.accountWorkerVacancyPage.rating') }}:</h4>
                <div class="rating-wrapper">
                  <div class="rating-element">
                    <p class="rating-text">{{ $t('pages.account.account_worker.accountWorkerVacancyPage.from') }}:</p>
                    <input type="text" v-model="searchParams.minrate" class="raing-input" />
                  </div>
                  <div class="rating-element">
                    <p class="rating-text">{{ $t('pages.account.account_worker.accountWorkerVacancyPage.to') }}:</p>
                    <input type="number" v-model="searchParams.maxrate" class="raing-input" />
                  </div>
                </div>
              </div>
              <button type="submit" class="surch__start-surch" @click="findUser">
                {{ $t('components.social.searchCom.placeholder') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="findUser" class="find-button">{{ $t('components.social.searchCom.placeholder') }}</button>
    <SpecialitySelect @pickSpecialization="pickProfession" @closeWindow="viewProfession = false"
      :current-specialisation="searchParams.profession" :specialisations="specialties" :industries="dataIndustries"
      v-if="viewProfession">
    </SpecialitySelect>
  </div>
</template>

<script>
import { config } from "@/lib/config";
import SpecialitySelect from "@/components/account/account_worker/modals/item/SpecialitySelect";

export default {
  name: "SurchBlue",
  components: {
    SpecialitySelect
  },
  props: ["default", "friends"],

  data() {
    return {
      viewSearchParams: false,
      viewSortMenu: false,
      viewCity: false,
      viewEmplType: false,
      viewProfession: false,

      searchParams: {
        name: "",
        sort: "default",
        minrate: "",
        maxrate: "",
        city: "",
        profession: "",
      },

      //OTHER
      cityName: this.currentLang ==='en'?"Select":"Выбрать",
      professionName: this.currentLang ==='en'?"Select":"Выбрать",
      timer: null,
      specialties: null,

      searchSpecial: null
    };
  },
  computed: {
    cities() {
      return this.$store.getters.dataCities;
    },
    dataIndustries() {
      return this.$store.getters.dataIndustries;
    },
    specializationName() {
      if (!this.searchParams?.profession) {
        return this.currentLang ==='en'?"Select":"Выбрать";
      }
      const index = this.specialties?.map(item => item.id).indexOf(this.searchParams?.profession);
      return index > -1 ? this.specialties[index].name : this.currentLang ==='en'?"Select":"Выбрать";
    },
    currentLang(){
			return this.$i18n.locale;
		}
  },
  watch:{
    currentLang(){
      this.specialtiesLoad();
      this.$store.dispatch("getIndustries", this.$i18n.locale);
    }
  },
  methods: {
    findUser() {
      this.$emit("find", this.searchParams);
      this.viewSearchParams = false;
      this.viewSortMenu = false;
    },
    cancelSearch() {
      this.$emit("default");
      this.searchParams.name = "";
      this.searchParams.sort = "default";
      this.searchParams.minrate = "";
      this.searchParams.maxrate = "";
      this.searchParams.city = "";
      this.searchParams.profession = "";
      this.cityName = "Select";
    },
    deletePagination() {
      this.$emit("deletePagination");
    },
    bySort(sortBy) {
      this.searchParams.sort = sortBy;
      this.viewSortMenu = false;
    },
    // pickEmplType(val) {
    //   this.searchParams.empl_type = val;
    //   this.viewEmplType = false;
    // },
    pickCity(city) {
      if (this.searchParams.city === city.name) {
        this.cityName = this.currentLang ==='en'?"Select":"Выбрать";
        this.searchParams.city = "";
      } else {
        this.cityName = city.name;
        this.searchParams.city = city.name;
        this.$store.dispatch("searchCity", "");
      }
      this.viewCity = false;
    },
    pickProfession(item) {
      this.searchParams.profession = item;
      this.viewProfession = false;
    },
    searchCity(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$store.dispatch("searchCity", val);
      }, 1000);
    },
    specialtiesLoad() {
      let request = new XMLHttpRequest();
      let url = config.apiPrefix + "employer/specialization/all?lang="+this.currentLang;
      request.open("GET", url, false);
      try {
        request.send();
        let response = JSON.parse(request.response);
        this.specialties = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    toggleCityVisible() {
      this.viewSortMenu = false;
      this.viewCity = !this.viewCity;
    },
    toggleCurrencyVisible() {
      this.viewCity = false;
      this.viewSortMenu = !this.viewSortMenu;
    },
    handleClickOutside(event) {
      // Проверяем, был ли сделан клик вне блока .search__sorting-select-container для города
      if (!(this.$refs.cityContainer === event.target || this.$refs.cityContainer.contains(event.target))) {
        this.viewCity = false;
      }

      // Проверяем, был ли сделан клик вне блока .search__sorting-select-container для валюты
      if (!(this.$refs.currencyContainer === event.target || this.$refs.currencyContainer.contains(event.target))) {
        this.viewSortMenu = false;
      }
    }
  },
  mounted() {
    // Добавляем обработчик события click на документ
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    // Удаляем обработчик события click с документа при размонтировании компонента
    document.removeEventListener('click', this.handleClickOutside);
  },
  beforeMount() {
    this.$store.dispatch("searchCity", "");
    this.$store.dispatch("getIndustries", this.$i18n.locale);
    this.specialtiesLoad();
  },
};
</script>

<style scoped>
.form-wrapper {
  display: flex;
  margin-top: 20px;
}

.form {
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #009ed1;
  background: #fff;
  gap: 10px;
  padding-right: 15px;
  align-items: center;
  border-radius: 8px;
  letter-spacing: 0.02em;

  box-sizing: border-box;
}

.surch-blue {
  position: absolute;
  left: 20px;
  display: block;
  top: calc(50% - 12px);
  background: url("~@/assets/img/account-img/friends/surch.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-size: cover;
  display: block;
}

.find-button {
  margin-left: 20px;
  max-width: 120px;
  width: 100%;
  height: 45px;
  background: #009ed1;
  border-radius: 8px;
  box-shadow: 0px 4px 12px -6px #009ed1;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.form__input {
  padding: 15px 0px 15px 54px;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  color: #009ed1;
  font-size: 12px !important;
}

.form__input::placeholder {
  opacity: 1;
}

.settigs-wrapper {
  position: relative;
}

.cancel__button {
  background: url("~@/assets/img/all-img/close.svg");
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-size: cover;
  display: block;
  cursor: pointer;
  z-index: 20;
  display: flex;
  flex-shrink: 0;
}

.from__button {
  background: url("~@/assets/img/all-img/filters-blue.svg");
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-size: cover;
  display: block;
  cursor: pointer;
  z-index: 20;
}

.friend__settings-popap {
  position: absolute;
  border: 1px solid #efeff4;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  padding: 20px;
  text-align: left;
  width: 300px;
  box-sizing: border-box;
  bottom: -570px;
  right: -22px;
  z-index: 20;
}

.surch-wrapper {
  position: relative;
  text-align: left;
}

.surch-wrapper::after {
  position: absolute;
  top: -30px;
  right: 0px;
  display: block;
  width: 14px;
  height: 10px;
  background: url("~@/assets/img/account-img/friends/arrow.svg");
  content: "";
  background-repeat: no-repeat;
}

.surch__title {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
}

.friend__info-rating {
  padding: 4px 6px 4px 18px;
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
  background-repeat: no-repeat;
  background-position-x: 6px;
  background-position-y: 4px;
  color: #14c686;
  line-height: 11px;
}

.surch-persone {
  margin-top: 15px;
  background: #efeff4;
  border-radius: 8px;
  width: 100%;
  display: flex;
  height: 45px;
  margin-bottom: 8px;
}

.surch-persone__select {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #44444f;
  width: 100%;
  box-sizing: border-box;
}

.surch-persone__select.surch-persone__select-chozen {
  color: #ffffff;
  background: #009ed1;
  border-radius: 8px;
}

.surch-select {
  margin-top: 6px;
  background: #efeff4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 15px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: rgba(68, 68, 79, 0.5);
  width: 100%;
  cursor: pointer;
}

.surch__subtitle {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  margin-top: 12px;
}

.surch-select__list-icon {
  width: 12px;
  height: 6px;
  display: block;
  margin-left: auto;
  background: url("~@/assets/img/account-img/friends/list-black-down.svg");
}

.surch__start-surch {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #ffffff;
  background: #009ed1;
  border-radius: 8px;
}

.activation-group {
  max-width: 100%;
  width: 100%;
  display: flex;
}

.select-wrapper {
  margin-top: 10px;
  position: relative;
  font-size: 12px;
  max-width: 100%;
  width: 100%;
}

.select-btn {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: 45px;
  padding: 0 14px;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease,
    border 1s ease, color 1s ease;
  justify-content: space-between;
}

.select-btn:hover {
  background-color: rgba(0, 158, 209, 0.10999999940395355);
  border: 1px solid #009ed1;
  color: #009ed1;
}

.select-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  max-height: 240px;
  overflow-y: scroll;
  z-index: 100;
  border-radius: 8px;
}

.select-list :first-child {
  border-radius: 8px 8px 0 0;
}

.select-list :last-child {
  border-radius: 0 0 8px 8px;
}

.select-list__element {
  padding: 0px 14px;
  height: 45px;
  border: 1px solid #bcbcbc;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease,
    border 1s ease, color 1s ease;
  gap: 10px;
  text-align: left;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.select-list__input {
  cursor: text;
  font-size: 12px !important;
}

.select-list__element:hover {
  background-color: #009ed1;
  border: 1px solid #009ed1;
  color: #fff;
}

.select-list__input:hover {
  background: #fff;
  color: #000;
}

.rating-wrapper {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rating-element {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating-text {
  font-family: "Roboto";
  font-weight: 500;
  color: #44444f;
  font-size: 12px;
  line-height: 1;
}

.raing-input {
  background: #efeff4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: rgba(68, 68, 79, 0.5);
  width: 100%;
  font-size: 12px !important;
}

@media (max-width: 720px) {
  .activation-group {
    margin-top: 20px;
  }

  .friend__settings-popap {
    right: -27px;
  }

  .find-button {
    margin-left: 10px;
    max-width: 100px;
  }

  .select-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .friend__settings-popap {
    right: -125px;
  }

  .surch-wrapper::after {
    right: 104px;
  }
}

.select-list__element-selected {
  background-color: #009ed1;
  border: 1px solid #009ed1;
  color: #fff;
}
</style>
