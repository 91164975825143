<template>
  <div class="documents__group-element">
    <button class="delete" @click="deleteDocument" v-if="url">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9694 12.9706L1.65565 1.65685M12.9694 1.65685L1.65565 12.9706L12.9694 1.65685Z" stroke="#3B4C68"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <div class="documents__group-element-download" :style="{ display: (url ? 'flex' : 'none') }">
      <div class="documents__demonstration-container">
        <img v-if="url"
          :src="documentType === 'img' ? (url.includes('blob:') ? url : `${url}?token=${$store.getters.token}`) : documentType === 'pdf' ? require('/src/assets/img/all-img/pdf-icon.png') : documentType === 'doc' ? require('/src/assets/img/all-img/doc-icon.svg.png') : require('/src/assets/img/all-img/exel-icon.svg.png')"
          alt="" class="documents__demonstration" :class="{ 'documents-icon': documentType !== 'img' }">
      </div>
      <input type="file" ref="file" hidden v-on:change="changeFile" accept="image/*,.pdf,.docx,.doc,.xlsx" />
      <a ref="download"
        :href="(documentType === 'img' || documentType === 'pdf') ? (url.includes('blob:') ? url : `${url}?token=${$store.getters.token}`) : (documentType === 'exel' || documentType === 'doc') ? ('http://docs.google.com/viewer?url=' + url + '?token=' + $store.getters.token) : url + '?token=' + $store.getters.token"
        target="_blank" />
    </div>
    <div class="activities">
      <button class="documents__group-element-download-btn" :class="{
        'download-btn-blue':
          status === 'checking' || status === 'approved',
      }" @click="uploadFile">
        <span v-if="status === 'unloaded' || status == null || status === 'rejected'">{{
          $t('components.account_worker.separateDocumentCopy.upload') }}</span>
        <span v-else-if="
          status === 'checking' || status === 'approved'">{{
              $t('components.account_worker.separateDocumentCopy.watchDocument') }}</span>
        <span class="download-icon"></span>
      </button>
      <div class="documents__group-element-caption-stage">
        <div class="documents__group-element-caption-stage-circle" :class="{
          'documents__group-element-caption-stage-circle':
            status === 'unloaded' || status == null,
          'stage-circle-blue': status === 'checking',
          'stage-circle-green': status === 'approved',
          'stage-circle-red': status === 'rejected',
        }"></div>
        <p class="documents__group-element-caption-stage-text">
          <span v-if="status === 'unloaded' || status == null">{{
            $t('components.account_worker.separateDocumentCopy.requiresLoading') }}</span>
          <span v-else-if="status === 'checking'">{{ $t('components.account_worker.separateDocumentCopy.atTheTest')
            }}</span>
          <span v-else-if="status === 'approved'">{{ $t('components.account_worker.separateDocumentCopy.checked')
            }}</span>
          <span v-else-if="status === 'rejected'">{{ $t('components.account_worker.separateDocumentCopy.divestable')
            }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from "@/lib/errorHandler";

export default {
  name: "SeparateDocumentCopy",
  props: ['url', 'status', 'index', 'field', 'context'],
  data() {
    return {
      documentType: null,
      documentUrl: null
    };
  },
  watch: {
    url(val) {
      this.setDocType(val);
    },

  },
  methods: {
    deleteDocument() {
      this.$store.commit('deleteDocuments', { index: this.index, field: this.field });
    },
    uploadFile() {
      switch (this.status) {
        case "unloaded":
          this.$refs.file.click();
          break;
        case "checking":
          this.$refs.download.click();
          break;
        case "approved":
          this.$refs.download.click();
          break;
        case "rejected":
          this.$refs.file.click();
          break;
        default:
          this.$refs.file.click();
          break;
      }
    },

    async changeFile() {
      let file = this.$refs.file.files[this.$refs.file.files.length - 1];
      if (typeof file === "object") {
        if (file.size === 0) {
          errorHandler({ status: 400, page: 'emptyFile' })
        } else {
          if (file.size < 41943040) {
            await this.$store.commit('setDocumentsDataFile', {
              data: {
                file,
                field: this.field,
                index: this.index
              },
              callback: async (data) => {
                this.context?.$forceUpdate();
                this.$forceUpdate();
                await this.$store.commit('setFileQueue', data)
              }
            })
            await this.setDocType(this.url, file);
          } else errorHandler({ status: 400, page: 'fileSize' })
        }
      }
    },
    setDocType(name, file) {
      if (!name) return;

      let isNew = false;
      if (file) {
        isNew = true;
        name = file.name;
      }
      let type = name?.split('.');
      type = type[type.length - 1].toLowerCase();
      if (type === 'raw' || type === 'jpeg' || type === 'tiff' || type === 'psd' || type === 'bmp' || type === 'gif' || type === 'png' || type === 'jp2' || type === 'jpg') {
        this.documentType = 'img';
        if (!isNew) {
          this.documentUrl = this.url + '?token=' + this.$store.getters.token;
        } else {
          this.documentUrl = this.url;
        }
      } else if (type === 'doc' || type === 'docx') {
        this.documentType = 'doc';
        this.documentUrl = require('/src/assets/img/all-img/doc-icon.svg.png');
      } else if (type === 'pdf') {
        this.documentType = 'pdf';
        this.documentUrl = require('/src/assets/img/all-img/pdf-icon.png');
      } else if (type === 'xlsx' || type === 'xls') {
        this.documentType = 'exel';
        this.documentUrl = require('/src/assets/img/all-img/exel-icon.svg.png');
      }
      this.context?.$forceUpdate();
      this.$forceUpdate();
    },
  },
  beforeMount() {
    this.setDocType(this.url);
  }
};
</script>


<style scoped>
.documents__group-element {

  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 8px;
  min-height: 100%;
  position: relative;
}

.delete {
  position: absolute;
  right: 15px;
  top: 15px;
}

.documents__group-element-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.documents__group-element-caption-stage {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documents__group-element-caption-stage-circle {
  width: 6px;
  height: 6px;
  background: #d9d9d9;
  border-radius: 100%;
}

.documents__group-element-caption-stage-text {
  font-family: "Roboto";
  margin-left: 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: rgba(23, 23, 37, 0.20000000298023224);
}

.documents__group-element-caption-stage-change {
  display: none;
  margin-left: auto;
  transition: opacity 1s ease;
}

.documents__group-element-caption-stage-change:hover {
  opacity: 0.5;
}

.documents__group-element-caption-stage-change-img {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 8px;
  background: #edf7f9;
}

.documents__group-element-text {
  margin: 0px 20px 0px 20px;
  padding-top: 8px;
  height: 95px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  line-height: 1.39;
  color: #3b4c68;
}

.documents__group-element-download {}

.documents__demonstration-container {
  height: 190px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documents__demonstration {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;

  border-radius: 9px;
}

.activities {
  margin-top: auto;
  padding: 30px 20px;
}

.documents__group-element-download-btn {
  height: 45px;
  background: #009ed1;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-align: center;
  transition: opacity 1s ease;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-icon {
  display: flex;
  width: 18px;
  height: 18px;
  background: url(../../../../../assets/img/all-img/file_download.svg);
  flex-shrink: 0;
  margin-left: 4px;
}

.documents__group-element-download-btn:hover {
  opacity: 0.5;
}

.mobile {
  display: none;
}

/* Modificators */
.stage-circle-green {
  background: #14c686;
}

.stage-circle-blue {
  background: #009ed1;
}

.stage-circle-red {
  background: #ff3c47;
}

.change-visable {
  display: block;
}

/* /Modificators */

@media (max-width: 950px) {
  .documents-wrapper {
    flex-direction: column;
  }

  .documents__group-element {
    margin-top: 0;
    margin: 0 auto;
  }
}

.documents-icon {
  height: 75px;
  width: 75px;
}
</style>