var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documents__group-element"},[_c('div',{staticClass:"documents__group-element-caption"},[_c('div',{staticClass:"documents__group-element-caption-stage"},[_c('div',{staticClass:"documents__group-element-caption-stage-circle",class:{
				'documents__group-element-caption-stage-circle':
					_vm.documentStatus === 'unloaded',
				'stage-circle-blue': _vm.documentStatus === 'checking',
				'stage-circle-green': _vm.documentStatus === 'approved',
				'stage-circle-red': _vm.documentStatus === 'rejected',
			}}),_c('p',{staticClass:"documents__group-element-caption-stage-text"},[(
					_vm.documentStatus === 'unloaded' ||
					!_vm.documentStatus ||
					_vm.documentStatus === undefined
				)?_c('span',[_vm._v("Requires download")]):(_vm.documentStatus === 'fileLoaded')?_c('span',[_vm._v("File downloaded")]):(_vm.documentStatus === 'checking')?_c('span',[_vm._v("Under review")]):(_vm.documentStatus === 'approved')?_c('span',[_vm._v("Checked")]):(_vm.documentStatus === 'rejected')?_c('span',[_vm._v("Rejected")]):_vm._e()])]),_vm._m(0)]),_c('div',{staticClass:"documents__group-element-download"},[_c('div',{staticClass:"documents__demonstration-container"},[(_vm.documentView)?_c('img',{staticClass:"documents__demonstration",class:{ 'documents-icon': _vm.documentType !== 'img' },attrs:{"src":_vm.documentView,"alt":""}}):_vm._e()]),_c('button',{staticClass:"documents__group-element-download-btn",class:{
			'download-btn-blue':
				_vm.documentStatus === 'checking' || _vm.documentUrl === 'approved',
		},on:{"click":_vm.uploadFile}},[(
				_vm.documentStatus === 'unloaded' ||
				!_vm.documentStatus ||
				_vm.documentStatus === undefined ||
				_vm.documentStatus === 'rejected' ||
				_vm.documentStatus === 'fileLoaded'
			)?_c('span',[_vm._v("Download")]):(
				_vm.documentStatus === 'checking' || _vm.documentStatus === 'approved'
			)?_c('span',[_vm._v("View Document")]):_vm._e()]),_c('input',{ref:"file",attrs:{"type":"file","hidden":"","accept":"image/*,.pdf,.docx,.doc,.xlsx"},on:{"change":_vm.changeFile}}),_c('a',{ref:"download",attrs:{"href":(_vm.documentType === 'img' || _vm.documentType === 'pdf') ? (_vm.documentUrl + '?token=' + _vm.$store.getters.token) : (_vm.documentType === 'exel' || _vm.documentType === 'doc') ? ('http://docs.google.com/viewer?url=' + _vm.documentUrl + '?token=' + _vm.$store.getters.token) : _vm.documentUrl + '?token=' + _vm.$store.getters.token,"target":"_blank"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"documents__group-element-caption-stage-change"},[_c('img',{staticClass:"documents__group-element-caption-stage-change-img",attrs:{"src":require("@/assets/img/all-img/documents/change.svg"),"alt":"Change"}})])
}]

export { render, staticRenderFns }