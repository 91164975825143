<template>
	<div class="message-wrapper">
		<img v-if="message.from !== myName" :src="message.avatarUrl" alt="" class="message-wrapper-icon">
		<div class="chat__wrapper-dialogue-message" :class="{
			'my-message': message.from === myName,
			'over-message': message.from !== myName
		}">

			<p class="chat__wrapper-dialogue-messages-user-text" v-html="message.body.replaceAll('\n', '<br/>')"></p>
			<picture class="picture-group" v-if="files">
				<img v-for="item in files" :key="item.id" class="chat__wrapper-dialogue-messages-user-img" :src="item" alt="">
			</picture>

			<div class="chat__wrapper-dialogue-message-user-info">
				<time class="chat__wrapper-dialogue-message-user-info-time">{{
						Date.now() - message.createDate > 86400000 ? getDay(message.createDate) : getTime(message.createDate)
				
				}}
				</time>
				<!--        <span-->
				<!--            :class="{-->
				<!--            'messages-stage-shipped': !message.read,-->
				<!--            'chat__wrapper-dialogue-message-user-info-stage': message.read,-->
				<!--          }"-->
				<!--        ></span>-->
				<a class="chat__wrapper-dialogue-message-user-info-name">
					{{ message.from }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { getDate } from "@/lib/dateUtils";
import { getTime } from "@/lib/dateUtils";
import Cookies from "js-cookie";

export default {
	props: ['message'],
	name: "SeparateMessage",
	data() {
		return {
			myName: null,
			date: null,
			getTime: getTime,
			getDay: getDate,
      files:null
		};
	},
	beforeMount() {
		this.myName = Cookies.get('name')
    if(this.message.files)
      this.files=this.message.files.split(';');
	}
};
</script>

<style scoped>
.message-wrapper {
	display: flex;
}

.message-wrapper-icon {
	width: 40px;
	height: 40px;
	object-fit: cover;
	margin-right: 20px;
	border-radius: 10px;
}

.chat__wrapper-dialogue-message {
	margin-bottom: 30px;
}

.my-message {
	position: relative;
	padding: 7px 16px;
	max-width: 80%;
	box-sizing: border-box;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	background: #009ed1;
	margin-left: auto;
	border-radius: 10px;
}

.my-message a {
	display: none;
}

.my-message::after {
	content: "";
	position: absolute;
	right: -12px;
	top: 0;
	display: block;
	background-image: url("~@/assets/img/account-img/chat/user-chat-element.svg");
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
}

.my-message time {
	font-size: 12px;
	margin-left: auto;
	display: block;
}

.my-message div {
	margin-top: 0px;
	margin-left: auto;
	display: flex;
	align-items: center;
}

.my-message span {
	margin-top: -2px;
}

.over-message {
	padding: 7px 16px;
	position: relative;
	font-family: "OpenSans";
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #666666;
	background: #e4f6fc;
	border-radius: 10px;
	max-width: 80%;
	box-sizing: border-box;
	display: block;
	margin-right: auto;
}

.over-message::after {
	content: "";
	position: absolute;
	left: -10px;
	top: 0;
	display: block;
	background-image: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
}

.over-message p {
	color: inherit;
}

.over-message time {
	font-size: 12px;
	display: block;
}

.over-message span {
	display: none;
}

.over-message div {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
	align-items: center;
}

.over-message a {
	font-size: 12px;
	opacity: 0.8;
}

.chat__wrapper-dialogue-message-user-info-stage {
	background: url("~@/assets/img/account-img/chat/messages-stage-viewed.svg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 24px;
	height: 19px;
}

.messages-stage-shipped {
	margin-left: 6px;
	background: url("~@/assets/img/account-img/chat/messages-stage-shipped.svg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 12px;
	height: 9px;
}

.chat__wrapper-dialogue-messages-user-img {
	max-width: 144px;
	object-fit: cover;
	margin-top: 10px;
	max-height: 200px;
	cursor: pointer;
}

.picture-group:not(:last-child) {
	margin-right: 5px;
}

@media (max-width: 950px) {
	.message-wrapper-icon {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
}

@media (max-width: 430px) {
	.my-message {
		padding: 10px;
		font-size: 14px;
		max-width: 260px;
	}

	.my-message time {
		font-size: 11px;
	}

	.over-message {
		padding: 10px;
		font-size: 14px;
		max-width: 260px;
	}

	.over-message div {
		margin-top: 15px;
	}

	.over-message a {
		font-size: 11px;
	}

	.over-message time {
		font-size: 11px;
	}
}
</style>