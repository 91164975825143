<template>
  <div class="message_wrapper">
    <div v-if="!workingRequest" class="chat__wrapper-dialogue-message" :class="{
      'my-message': message.from === 'user' || message.from === 'myself',
      'over-message': message.from !== 'user' && message.from !== 'myself',
    }">
      <pre v-if="!messagePost" class="chat__wrapper-dialogue-messages-user-text"
        v-html="message.message ? message.message.slice().replaceAll('\n', '<br/>') : ''"></pre>
      <picture class="picture-group" v-for="item in files" :key="item.id">
        <img @click="$emit('bigPicture', item)" class="chat__wrapper-dialogue-messages-user-img" :src="item" alt="">
      </picture>
      <div v-if="!messagePost" class="chat__wrapper-dialogue-message-user-info">
        <time class="chat__wrapper-dialogue-message-user-info-time">{{
          new Date().getTime() - message.createDate > 86400000 || (new Date().getDate() !== new
            Date(message.createDate).getDate()) ? getDay(message.createDate) + ' / ' + getTime(message.createDate) :
            getTime(message.createDate)
        }}
        </time>
        <span :class="{
          'messages-stage-shipped': !message.read,
          'chat__wrapper-dialogue-message-user-info-stage': message.read,
        }"></span>
        <a class="chat__wrapper-dialogue-message-user-info-name">
          {{ message.adminName }}
        </a>
      </div>
      <RepostElement @viewBigPost="val => $emit('viewBigPost', val)" :is-my-message="message.from === 'myself'"
        v-if="messagePost" :post="messagePost">
      </RepostElement>
    </div>
    <div v-else class="special-wrapper">
      <div class="special-filling"
        :class="{ 'rejected-message': workingMessages[0] === 'cancel', 'correct-message': workingMessages[0] === 'accept' }">
        <div class="special-message">
          <h3 v-if="workingMessages[0] === 'status' && $store.getters.role === 'EMPLOYER'">
            {{ $t('components.account_worker.separateWorkerChatMessage.employmentConfirmation') }}
          </h3>
          <img src="" alt="" class="special-message__icon">
          <p class="special-message__text" v-if="workingMessages[0] === 'cancel'">
            {{ $t('components.account_worker.separateWorkerChatMessage.rejected') }}
          </p>
          <p class="special-message__text" v-if="workingMessages[0] === 'accept'">
            {{ $t('components.account_worker.separateWorkerChatMessage.received') }}
          </p>
          <p class="special-message__text"
            v-if="workingMessages[0] === 'status' && $store.getters.role === 'CANDIDATE'">
            {{ $t('components.account_worker.separateWorkerChatMessage.employmentApplicationSent') }}
          </p>
          <p class="special-message__text" v-if="workingMessages[0] === 'status' && $store.getters.role === 'EMPLOYER'">
            {{ workingMessages[2] }}
          </p>
        </div>
        <div class="special-info">
          <time class="chat__wrapper-dialogue-message-user-info-time">{{
            new Date().getTime() - message.createDate > 86400000 || (new Date().getDate() !== new
              Date(message.createDate).getDate()) ? getDay(message.createDate) + ' / ' + getTime(message.createDate) :
              getTime(message.createDate)
          }}
          </time>
          <span class="special-info__status"></span>
          <a href="" v-if="false" class="special-info__name">Иванов Иван</a>
        </div>
      </div>
      <div class="special-btns" v-if="workingMessages[0] === 'status' && $store.getters.role === 'EMPLOYER'">
        <button class="special__agree" @click="acceptRequest">
          {{ $t('components.account_worker.separateWorkerChatMessage.toAccept') }}
        </button>
        <button class="special__refuse" @click="cancelRequest">
          {{ $t('components.account_worker.separateWorkerChatMessage.reject') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDate, getTime } from "@/lib/dateUtils";
import Cookies from "js-cookie";
import RepostElement from "./RepostElement.vue";

export default {
  props: ['message'],
  name: "SeparateWorkerChatMessage",
  components: {
    RepostElement
  },
  data() {
    return {
      workingRequest: false,
      workingMessages: null,
      messagePost: null,
      getDay: getDate,
      getTime: getTime,
      files: [],
      reg: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
    };
  },
  methods: {
    acceptRequest() {
      const id = Cookies.get('currentDialogueId');
      const data = {
        fromUser: id,
        toUser: this.$store.getters.user.id,
        newsType: 'job',
        body: JSON.stringify({
          companyName: this.$store.getters.user.orgName
        })
      }
      this.$store.dispatch('createNews', data).then(() => {
        let message = this.workingMessages;
        message[0] = 'accept';
        message = message.join('!$');
        this.$store.dispatch('saveWorkingMessage', { id: this.message.id, message }).then(() => {
          this.workingMessages = this.message.message.split('!$');
        })
      })
    },
    cancelRequest() {
      let message = this.workingMessages;
      message[0] = 'cancel';
      message = message.join('!$');
      this.$store.dispatch('saveWorkingMessage', { id: this.message.id, message }).then(() => {
        this.workingMessages = this.message.message.split('!$');
      })
    }
  },
  beforeMount() {
    const working = this.message?.message?.split('!$');
    if (this.reg.test(working[1])) {
      this.workingMessages = working;
      this.workingRequest = true;
    } else {
      const post = this.message?.message?.split('#@');
      if (post.length >= 2 && post[0] === 'communityPost') {
        try {
          this.messagePost = JSON.parse(post[1])
        } catch (e) {
          console.log(e);
        }
      } else {
        if (this.message.files)
          this.files = this.message.files.split(';');
        this.files.splice(this.files.length - 1, 1)
      }
    }
  },
  mounted() {
    this.$emit('registerRef', this, this.message.id);
  },
  beforeDestroy() {
    this.$emit('unregisterRef', this.message.id);
  },
};
</script>


<style scoped>
.message_wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
}

.chat__wrapper-dialogue-messages-user-text {
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
}

.special-wrapper {
  margin-bottom: 30px;
}

.special-filling {

  padding: 7px 16px;
  position: relative;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  background: #e4f6fc;
  border-radius: 10px;
  max-width: 80%;
  box-sizing: border-box;
  display: block;
  margin-right: auto;
}

.special-filling::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.special-message__text {
  color: inherit;
  display: inline-block;
}

.special-info__time {
  font-size: 12px;
  display: block;
}

.special-info__status {
  display: none;
}

.special-info {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
}

.special-info__name {
  color: inherit;
  font-size: 12px;
  opacity: 0.8;
}

.chat__wrapper-dialogue-message-user-info-stage {
  background: url("~@/assets/img/account-img/chat/messages-stage-viewed.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 19px;
}

.messages-stage-shipped {
  margin-left: 6px;
  background: url("~@/assets/img/account-img/chat/messages-stage-shipped.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 9px;
}

.chat__wrapper-dialogue-messages-user-img {
  max-width: 144px;
  object-fit: cover;
  margin-top: 10px;
  max-height: 200px;
  cursor: pointer;
}

.special-btns {
  margin-top: 15px;
  display: flex;
  max-width: 100%;
  width: 100%;
}

.special__agree {
  background: #14C686;
  color: #fff;
  padding: 14px 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  border-radius: 7px;
  margin-right: 10px;
}

.special__refuse {
  background: #DC0B0B;
  color: #fff;
  padding: 14px 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  border-radius: 7px;
}

.rejected-message {
  color: #fff;
  background: #DC0B0B;
  max-width: 100%;
  box-sizing: border-box;
}

.rejected-message::after {
  background-image: url("~@/assets/img/all-img/chat-icon.svg");
}

.correct-message {
  color: #fff;
  background: #14C686;
  max-width: 100%;
  box-sizing: border-box;
}

.correct-message::after {
  background-image: url("~@/assets/img/all-img/chat-icon-green.svg");
}


.chat__wrapper-dialogue-message {
  margin-bottom: 30px;
}

.my-message {
  position: relative;
  padding: 7px 16px;
  max-width: 80%;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #009ed1;
  margin-left: auto;
  border-radius: 10px;
}

.my-message a {
  display: none;
}

.my-message::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/user-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.my-message p {
  color: inherit;
  display: block;
}

.my-message time {
  font-size: 12px;
  margin-left: auto;
  display: block;
}

.my-message div {
  margin-top: 0px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.my-message span {
  margin-top: -2px;
}

.over-message {
  padding: 7px 16px;
  position: relative;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  background: #e4f6fc;
  border-radius: 10px;
  max-width: 80%;
  box-sizing: border-box;
  display: block;
  margin-right: auto;
}

.over-message::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.over-message p {
  color: inherit;
  display: inline-block;
}

.over-message time {
  font-size: 12px;
  display: block;
}

.over-message span {
  display: none;
}

.over-message div {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
}

.over-message a {
  font-size: 12px;
  opacity: 0.8;
}

.chat__wrapper-dialogue-message-user-info-stage {
  background: url("~@/assets/img/account-img/chat/messages-stage-viewed.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 19px;
}

.messages-stage-shipped {
  margin-left: 6px;
  background: url("~@/assets/img/account-img/chat/messages-stage-shipped.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 9px;
}


.picture-group:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 430px) {
  .my-message {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .my-message time {
    font-size: 11px;
  }

  .over-message {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .over-message div {
    margin-top: 15px;
  }

  .over-message a {
    font-size: 11px;
  }

  .over-message time {
    font-size: 11px;
  }

  .special-filling {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .special-info__time {
    font-size: 11px;
  }

  .over-message {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .special-info {
    margin-top: 15px;
  }

  .special-info__name {
    font-size: 11px;
  }
}
</style>