<template>
	<div class="blog-list__element">
		<img src="~@/assets/img/account-img/communities/bg-main.png" alt="" class="blog-list__element-img">
		<p class="blog-list__element-description">Amet minim mollit non deserunt ullamco</p>
	</div>
</template>

<script>

export default {
	name: "SeparateBlog",

};


</script>

<style scoped>
.blog-list__element {
	padding: 20px 10px;
	border-bottom: 1px solid #efeff4;
}

.blog-list__element-img {
	width: 100%;
	box-sizing: border-box;
	border-radius: 5px;
	height: 61px;
	object-fit: cover;
}

.blog-list__element-description {
	margin-top: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 1.6;
	color: #424242;
}
</style>