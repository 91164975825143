var render = function render(){var _vm=this,_c=_vm._self._c;return (
  (_vm.mode === 'community' && _vm.comData.requestApproved === 'in') ||
  (_vm.mode === 'request' && _vm.comData.requestApproved === 'request') ||
  (_vm.role === 'all' && _vm.mode === 'community') ||
  _vm.role === 'myCreate'
)?_c('div',{staticClass:"community-wrapper"},[_c('div',{staticClass:"communitys-separate__left",on:{"click":_vm.goToCommunity}},[_c('div',{staticClass:"community__face"},[_c('img',{staticClass:"community__face-img",attrs:{"src":_vm.comData.avatarUrl,"alt":""}})]),_c('div',{staticClass:"community__info"},[_c('div',{staticClass:"community__info-caption"},[_c('h3',{staticClass:"community__info-name"},[_vm._v(" "+_vm._s(_vm.comData.name ? _vm.limit(_vm.comData.name, 25) : _vm.$t('components.social.mySeparateCommunity.noData'))),_c('span',{staticClass:"community__status",class:{
            'status-cancellation': _vm.comData.communityType === 'close',
          }},[_vm._v(_vm._s(_vm.comData.communityType === "close" ? _vm.$t('components.social.mySeparateCommunity.closed') : _vm.$t('components.social.mySeparateCommunity.open')))])])]),_c('p',{staticClass:"community__info-speciality"},[_vm._v(" "+_vm._s(_vm.comData?.description?.slice(0, 70) + (_vm.comData?.description?.length > 70 ? "..." : ""))+" ")]),_c('p',{staticClass:"community__info-designation"},[_vm._v(" "+_vm._s(_vm.comData.userCount ? _vm.comData.userCount : 0)+" "+_vm._s(_vm.$t('components.social.mySeparateCommunity.participants'))+" ")])])]),(
    _vm.role !== 'my' && _vm.role !== 'myCreate' && _vm.comData.requestApproved !== 'in'
  )?_c('button',{staticClass:"community__join-button",class:{
      'button-disable': _vm.sendRequest || _vm.comData.requestApproved === 'request',
      'button-join': _vm.joinCommunity,
    },attrs:{"type":"button"},on:{"click":_vm.joinInCommunity}},[_vm._v(" "+_vm._s(_vm.comData.communityType === "close" ? _vm.comData.requestApproved === "request" ? _vm.$t('components.social.mySeparateCommunity.applicationUnderConsideration') : _vm.$t('components.social.mySeparateCommunity.apply') : _vm.$t('components.social.mySeparateCommunity.joinCommunity'))+" ")]):_vm._e(),(_vm.role === 'myCreate' || _vm.mode === 'request')?_c('button',{staticClass:"community__settings community-desctop",attrs:{"type":"button"},on:{"click":function($event){_vm.menuVisible = !_vm.menuVisible}}},[(_vm.menuVisible)?_c('div',{staticClass:"community__settings-popap"},[(_vm.role === 'myCreate')?_c('button',{staticClass:"community__settings-delete",on:{"click":function($event){return _vm.$store.dispatch('deleteMyCommunities', {
          id: _vm.comData.id,
          token: _vm.$store.getters.token,
          index: _vm.index,
        })}}},[_vm._v(" "+_vm._s(_vm.$t('components.social.mySeparateCommunity.delete'))+" ")]):_vm._e(),(_vm.role === 'my')?_c('button',{staticClass:"community__settings-delete",on:{"click":function($event){return _vm.$store.dispatch('deleteMyApplicationInCommunities', {
          id: _vm.comData.id,
          token: _vm.$store.getters.token,
          index: _vm.index,
        })}}},[_vm._v(" "+_vm._s(_vm.$t('components.social.mySeparateCommunity.cancel'))+" ")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }