<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="help">
				<div class="container help-container">
					<div class="help__explanation">
						<h1 class="help__explanation-title">{{ $t('pages.landing.helpPage.helpPage.title') }}</h1>
						<div class="help__explanation-box">
							<h2 class="help__explanation-box-title">{{ $t('pages.landing.helpPage.helpPage.explanationTitle') }}
							</h2>
							<img src="~@/assets/img/help-page/help/help-women.png" alt="Women"
								class="help__explanation-box-img" />
							<p class="help__explanation-box-text">
								{{ $t('pages.landing.helpPage.helpPage.explanationText') }}
							</p>
						</div>
					</div>
					<div class="help__card">
						<div class="help-list" v-if="!endThirstPart">
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.addressingOnBehalfOf') }}</p>
								<div class="select">
									<button ref="select" class="select__active-btn" @click="toggleSelectList1">
										{{ $t(`pages.landing.helpPage.helpPage.${formData.role}`) }}
										<img src="@/assets/img/select-icon.svg" alt=" " class="select__icon"
											:class="{ 'select__icon--active': openSelectList1 }">
									</button>
									<div class="select__list" :class="{ 'select__list--open': openSelectList1 }">
										<button class="select__btn" @click="pickRole('aJobSeeker')">{{
											$t('pages.landing.helpPage.helpPage.aJobSeeker') }}</button>
										<button class="select__btn" @click="pickRole('anEmployer')">{{
											$t('pages.landing.helpPage.helpPage.anEmployer') }}</button>
									</div>
								</div>
							</div>
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.subjectOfAppeal') }}</p>
								<div class="select">
									<button ref="select" class="select__active-btn" @click="toggleSelectList2">
										{{ $t(`pages.landing.helpPage.helpPage.${formData.subject}`) }}
										<img src="@/assets/img/select-icon.svg" alt=" " class="select__icon"
											:class="{ 'select__icon--active': openSelectList2 }">
									</button>
									<div class="select__list" :class="{ 'select__list--open': openSelectList2 }">
										<button class="select__btn" @click="pickSubject('askQuestion')">{{
											$t('pages.landing.helpPage.helpPage.askQuestion') }}</button>
										<button class="select__btn" @click="pickSubject('makeComplaint')">{{
											$t('pages.landing.helpPage.helpPage.makeComplaint') }}</button>
										<button class="select__btn" @click="pickSubject('expressGratitude')">{{
											$t('pages.landing.helpPage.helpPage.expressGratitude') }}</button>
										<button class="select__btn" @click="pickSubject('other')">{{
											$t('pages.landing.helpPage.helpPage.other') }}</button>
									</div>
								</div>
							</div>
						</div>
						<div class="help-list" v-if="endThirstPart">
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.subjectOfAppeal') }}</p>
								<input type="text" :placeholder="$t('pages.landing.helpPage.helpPage.writeYourTopic')"
									class="help__card-input" v-model="formData.title" />
							</div>
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.describeYourProblem') }}</p>
								<textarea name="textarea" :placeholder="$t('pages.landing.helpPage.helpPage.havingProblems')"
									class="help__card-textarea" v-model="formData.message"></textarea>
							</div>
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.emailForFeedback') }}</p>
								<input type="email" :placeholder="$t('pages.landing.helpPage.helpPage.emailForFeedback')"
									class="help__card-input" v-model="formData.email" />
							</div>
							<div class="field">
								<p class="help__card-text">{{ $t('pages.landing.helpPage.helpPage.fullName') }}</p>
								<input type="text" :placeholder="$t('pages.landing.helpPage.helpPage.fullName')"
									class="help__card-input" v-model="formData.name" />
							</div>
						</div>

						<div class="help__card-btns">
							<button class="help__card-btns-send" v-if="!endThirstPart"
								@click="endThirstPart = !endThirstPart">{{ $t('pages.landing.helpPage.helpPage.nextButton')
								}}</button>
							<button class="help__card-btns-send" v-if="endThirstPart" @click="submitForm">{{
								$t('pages.landing.helpPage.helpPage.sendButton') }}</button>
						</div>
						<p class="help__card-disclaimer" v-if="endThirstPart">
							{{ $t('pages.landing.helpPage.helpPage.agreeToPrivacyPolicy') }}
							<span class="help__card-disclaimer-span">{{ $t('pages.landing.helpPage.helpPage.privacyPolicy')
								}}</span>.
						</p>
					</div>
				</div>
			</div>

			<div class="questions">
				<div class="container questions-container">
					<h2 class="questions__title">{{ $t('pages.landing.helpPage.helpPage.faqTitle') }}</h2>
					<h3 class="questions__subtitle">{{ $t('pages.landing.helpPage.helpPage.faqSubtitle') }}</h3>
					<div class="questions__list">
						<div class="questions__list questions__list-left">
							<div class="questions__list-element">
								<button @click="display(0)" :class="{
									'questions__list-element-btn questions-btn-close': displayHelpQuestion[0],
									'questions__list-element-btn': !displayHelpQuestion[0],
								}"></button>
								<h1 class="questions__list-element-title">
									{{ $t('pages.landing.helpPage.helpPage.faqQuestion1') }}
								</h1>
								<p class="questions__list-element-text" v-if="displayHelpQuestion[0]">
									{{ $t('pages.landing.helpPage.helpPage.faqAnswer1') }}
								</p>
							</div>
							<div class="questions__list-element">
								<button @click="display(1)" :class="{
									'questions__list-element-btn questions-btn-close': displayHelpQuestion[1],
									'questions__list-element-btn': !displayHelpQuestion[1],
								}"></button>
								<h1 class="questions__list-element-title">
									{{ $t('pages.landing.helpPage.helpPage.faqQuestion2') }}
								</h1>
								<p class="questions__list-element-text" v-if="displayHelpQuestion[1]">
									{{ $t('pages.landing.helpPage.helpPage.faqAnswer2') }}
								</p>
							</div>
							<div class="questions__list-element">
								<button @click="display(2)" :class="{
									'questions__list-element-btn questions-btn-close': displayHelpQuestion[2],
									'questions__list-element-btn': !displayHelpQuestion[2],
								}"></button>
								<h1 class="questions__list-element-title">
									{{ $t('pages.landing.helpPage.helpPage.faqQuestion3') }}
								</h1>
								<p class="questions__list-element-text" v-if="displayHelpQuestion[2]">
									{{ $t('pages.landing.helpPage.helpPage.faqAnswer3') }}
								</p>
							</div>
						</div>
						<div class="questions__list questions__list-right">
							<div class="questions__list-element">
								<button @click="display(3)" :class="{
									'questions__list-element-btn questions-btn-close': displayHelpQuestion[3],
									'questions__list-element-btn': !displayHelpQuestion[3],
								}"></button>
								<h1 class="questions__list-element-title">
									{{ $t('pages.landing.helpPage.helpPage.faqQuestion4') }}
								</h1>
								<p class="questions__list-element-text" v-if="displayHelpQuestion[3]">
									{{ $t('pages.landing.helpPage.helpPage.faqAnswer4') }}
								</p>
							</div>
							<div class="questions__list-element">
								<button @click="display(4)" :class="{
									'questions__list-element-btn questions-btn-close': displayHelpQuestion[4],
									'questions__list-element-btn': !displayHelpQuestion[4],
								}"></button>
								<h1 class="questions__list-element-title">
									{{ $t('pages.landing.helpPage.helpPage.faqQuestion5') }}
								</h1>
								<p class="questions__list-element-text" v-if="displayHelpQuestion[4]">
									{{ $t('pages.landing.helpPage.helpPage.faqAnswer5') }}
								</p>
							</div>
						</div>
					</div>
					<div class="questions__btn">
						<button class="questions__btn-element">{{ $t('pages.landing.helpPage.helpPage.askQuestionButton')
							}}</button>
					</div>
				</div>
			</div>
		</main>

		<Footer></Footer>
	</div>
</template>

<script>
import { config } from "@/lib/config";
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";
import { errorHandler } from "@/lib/errorHandler";
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
	mixins: [validationMixin],
	computed: {
		displayHelpQuestion() {
			return this.$store.getters.displayHelpQuestion;
		},

	},
	components: { Header, Footer },
	beforeMount() {
		scroll(0, 0);
	},
	methods: {
		toggleSelectList2() {
			this.openSelectList1 = false;
			this.openSelectList2 = !this.openSelectList2;
		},
		toggleSelectList1() {
			this.openSelectList2 = false;
			this.openSelectList1 = !this.openSelectList1;
		},
		display(id) {
			this.$store.commit("displayHelpQuestions", id);
			this.$forceUpdate();
		},
		pickRole(role) {
			this.formData.role = role;
			this.openSelectList1 = !this.openSelectList1
		},
		pickSubject(subject) {
			this.formData.subject = subject;
			this.openSelectList2 = !this.openSelectList2;
		},
		submitForm() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				errorHandler({ page: "incorrectFields", status: 400 });
				return;
			}

			let xhr = new XMLHttpRequest();
			let url = config.apiPrefix + "chatbot/supportmessage";
			xhr.open("POST", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						console.log("Успешно:", xhr.responseText);
						errorHandler({ status: 200 });

					} else {
						console.error("Ошибка запроса:", xhr.status, xhr.statusText);
					}
				}
			};
			var messageInput = JSON.stringify({
				senderEmail: this.formData.email,
				senderRole: this.formData.role === 'aJobSeeker' ? 'candidate' : 'employer',
				title: this.formData.title,
				subject: this.$t(`pages.landing.helpPage.helpPage.${this.formData.subject}`),
				message: this.formData.message,
				name: this.formData.name
			});

			xhr.send(messageInput);
			this.$router.go(-1);

		},
	},

	validations: {
		formData: {
			role: { required },
			subject: { required },
			name: { required },
			title: { required, minLength: minLength(4), maxLength: maxLength(30) },
			email: { required, email, maxLength: maxLength(100) },
		},
	},

	data() {
		return {
			filtersVisible: false,
			openSelectList1: false,
			openSelectList2: false,
			endThirstPart: false,
			questionCategory: null,
			errorHandler,
			formData: {
				role: 'aJobSeeker',
				subject: 'askQuestion',
				title: '',
				message: '',
				email: '',
				name: '',
			},
		};
	},

	name: "Help",
};
</script>

<style scoped>
/*Help*/
.help {
	background: #fafafb;
}

.help-container {
	padding-top: 150px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 87px;
	align-items: flex-start;
	overflow: visible;
	gap: 30px;
}

.help__explanation {
	margin-left: 9px;
	max-width: 414px;
	width: 100%;
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
}

.help__explanation-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	line-height: 1.3;
	color: #000000;
}

.help__explanation-box {
	margin-top: 57px;
	background: url("~@/assets/img/help-page/help/help-bg.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	border-radius: 8px;
	width: 100%;
}

.help__explanation-box-img {
	position: absolute;
	top: 28px;
	left: -19px;
}

.help__explanation-box-text {
	padding: 483px 27px 55px 33px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.help__card {
	margin-top: 101px;
	max-width: 554px;
	width: 100%;
	padding: 26px 63px 30px 63px;
	background: #ffffff;

	border: 1px solid #bebebe;
	border-radius: 8px;
}

.help__card-text {
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.help__card-input::placeholder {
	opacity: 0.3;
}

.help__card-input {
	margin-top: 8px;
	padding: 13px 25px 13px 16px;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #464655;
	transition: 1s ease border;
}

.help__card-category {
	margin-top: 8px;
	padding-left: 12px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	height: 46px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-repeat: no-repeat;
	background-position: calc(97%);
	background-size: 10px;
	transition: background-color 1s ease;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	color: #464655;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	transition: border 1s ease, background-image 1s ease;
}

.help__card-category:hover {
	border: 1px solid #009ed1;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.help__card-input:hover {
	border: 1px solid #009ed1;
}

.help__card-textarea:hover {
	border: 1px solid #009ed1;
}

.help__card-textarea::placeholder {
	opacity: 0.3;
}

.help__card-textarea {
	margin-top: 8px;
	box-sizing: border-box;
	width: 100%;
	padding: 13px 25px 13px 16px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.25px;
	color: #464655;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	height: 193px;
	transition: 1s ease border;
	resize: vertical;
}

.input-question {
	background: url("~@/assets/img/help-page/help/help-list-hover.svg");
	align-items: center;
	background-repeat: no-repeat;
	background-position: 95%;
	opacity: 1;
}

.help__card-btns-eddfile {
	margin-top: 24px;
	width: 100%;
	box-sizing: border-box;
	height: 40px;
	background: #88a1aa;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 13px;
	letter-spacing: 0.1px;
	text-align: center;
	align-items: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.help__card-btns-eddfile:hover {
	opacity: 0.5;
}

.help__card-btns-send {
	margin-top: 33px;
	width: 100%;
	box-sizing: border-box;
	height: 65px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	text-align: center;
	align-items: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.help__card-btns-send:hover {
	opacity: 0.5;
}

.help__card-disclaimer {
	margin-top: 23px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 17.5px;
	letter-spacing: 0.25px;
	text-align: center;
	color: #9aafb6;
}

.help__card-disclaimer-span {
	cursor: pointer;
	text-decoration: underline;
	transition: opacity 1s ease;
}

.help__card-disclaimer-span:hover {
	opacity: 0.5;
}

.help__explanation-box-title {
	display: none;
}

.help-list {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.select {
	margin-top: 8px;
	position: relative;
	max-width: 100%;
	width: 100%;
}

.select__active-btn {
	text-align: left;
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	padding: 13px 25px 13px 16px;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #c7c7cc;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	transition: all 0.5s ease;
}

.select__icon {
	width: 12px;
	height: 12px;
	object-fit: contain;
	display: flex;
	flex-shrink: 0;
	transition: all 0.5s ease;
}

.select__icon--active {
	transform: rotate(180deg);
}

.select__list {
	max-width: 100%;
	width: 100%;
	margin-top: 6px;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid #bcbcbc;
	max-height: 260px;
	overflow: auto;
	scrollbar-width: thin;
	position: absolute;
	background: #fff;
	z-index: 2;
	transform: translateY(10%);
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s ease;
}

.select__list--open {
	transform: translateY(0);
	opacity: 1;
	pointer-events: all;
}

.select__btn {
	max-width: 100%;
	width: 100%;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 16px;
	border-bottom: 1px solid #bcbcbc;
	transition: all 0.5s ease;
}

.select__btn:hover {
	color: #009ed1;
}

.select__btn:last-child {
	border-bottom: none;
}


@media (max-width: 1150px) {

	.help-container {
		justify-content: space-around;
	}

	.help__explanation-box-text {
		padding: 497px 27px 55px 33px;
		position: relative;
		z-index: 3;
	}
}

@media (max-width: 936px) {
	.help__explanation {
		max-width: 100%;
	}

	.help__explanation-box {
		padding-top: 43px;
	}

	.help__card {
		width: 100%;
		background: #fafafb;
		box-sizing: border-box;
		padding-bottom: 60px;
	}

	.help__explanation-title {
		display: none;
	}

	.help__explanation-box-text {
		padding: 515px 27px 55px 33px;
	}

	.help-container {
		padding: 0;
		flex-direction: column;
		justify-content: space-between;
	}

	.help__explanation {
		overflow: hidden;
		margin-top: 0px;
		margin-left: 0px;
		width: 100%;
	}


	.help__explanation-box {
		margin-top: 0;

	}

	.help__explanation-box-img {
		position: absolute;
		top: 95px;
		left: -19px;
		z-index: 1;
		left: 25%;
	}

	.help__explanation-box-title {
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 33px;
		line-height: 34px;
		color: #ffffff;
		display: block;
		padding-top: 43px;
		text-align: center;
		z-index: 3;
		position: relative;
	}

	.help__explanation-box-text {
		padding: 414px 24px 41px 24px;
		text-align: center;
	}

	.help__card {
		margin: 28px auto 0 auto;
		width: 100%;
		padding: 0 10px 60px 10px;
		border: none;
	}

	.help__card-btns {
		display: flex;
		flex-direction: column;
	}

	.help__card-btns-eddfile {
		width: 50%;
		margin: 18px auto 0 auto;
		box-sizing: border-box;
		height: 40px;
	}

	.help__card-btns-send {
		margin: 27px auto 0 auto;
		height: 40px;
		width: 50%;
	}

	.help__card-disclaimer {
		margin-top: 18px;
		margin-bottom: 37px;
	}
}

@media (max-width: 620px) {
	.help__explanation-box-img {
		left: 15%;
	}
}

@media (max-width: 500px) {
	.help__explanation-box-img {
		left: 5%;
		width: 320px;
	}

	.help__card-btns-eddfile {
		width: 100%;
	}

	.help__card-btns-send {
		width: 100%;
	}
}

@media (max-width: 320px) {
	.help__card-text {
		font-size: 12px;
	}

	.help__card-input {
		font-size: 14px;
	}

	.help__card-textarea {
		height: 226px;
		font-size: 14px;
		line-height: 1.42;
	}

	.help__explanation-box-title {
		text-align: left;
		font-size: 20px;
		padding: 43px 9px 0px 9px;
	}

	.help__card-btns-eddfile {
		font-size: 12px;
	}

	.help__card-btns-send {
		font-size: 12px;
	}

	.help__card-disclaimer {
		font-size: 12px;
	}

	.help__explanation-box-img {
		left: 0%;
	}

	.help__explanation-box-text {
		text-align: left;
		font-size: 14px;
	}
}

/*/Help*/

/*Questions*/
.questions {
	background: #fff;
}

.questions__title {
	padding-top: 79px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.questions__subtitle {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.questions__list {
	margin-top: 40px;
	display: flex;
	width: 100%;
	box-sizing: border-box;
}

.questions__list-left {
	margin-right: 19px;
	display: flex;
	flex-direction: column;
}

.questions__list-right {
	display: flex;
	flex-direction: column;
}

.questions__list-element {
	position: relative;
	padding: 19px 29px 19px 29px;
	background: #fafafb;
	border: 1px solid #e9e9ea;
	border-radius: 8px;
	margin-bottom: 29px;
}

.questions__list-element-title {
	width: 74%;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 18px;
	line-height: 1.5;
	color: #636d79;
}

.questions__list-element-btn {
	position: absolute;
	background: url("~@/assets/img/help-page/questions/btn-open.svg");
	width: 20px;
	height: 20px;

	background-size: cover;
	background-repeat: no-repeat;
	top: calc(50 - 10px);
	right: 25px;
}

.questions-btn-close {
	background: url("~@/assets/img/help-page/questions/btn-close.svg");
	background-position: center;
}

.questions__list-element-text {
	padding-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #a2a9b0;
}

.questions__btn {
	display: flex;
	justify-content: center;
}

.questions__btn-element {
	margin: 30px 0 50px 0;
	padding: 24px 67px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #009ed1;
	border: 2px solid #009ed1;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.questions__btn-element:hover {
	opacity: 0.5;
}

.questions-text-unusing {
	display: none;
}

@media (max-width: 960px) {

	.questions-container {
		padding: 0 9px 0 9px;
	}

	.questions__list {
		margin-top: 20px;
		flex-direction: column;
	}

	.questions__list {
		margin-top: 10px;
	}

	.questions__list:first-child {
		margin-top: 14px;
	}

	.questions__list-element {
		margin-bottom: 13px;
	}

	.questions__title {
		padding-top: 14px;
		font-size: 13px;
	}

	.questions__subtitle {
		font-size: 20px;
	}

	.questions__list-left {
		margin-right: 19px;
		display: flex;
		flex-direction: column;
	}

	.questions__list-right {
		display: flex;
		flex-direction: column;
	}

	.questions__list-element {
		position: relative;
		padding: 21px 60px 15px 22px;
	}

	.questions__list-element-title {
		width: 95%;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
	}

	.questions__list-element-text {
		padding-top: 7px;
		font-weight: 400;
		font-size: 12px;
		line-height: 1.75;
	}

	.questions__list-element-btn {
		right: 17px;
	}

	.questions__btn-element {
		margin: 21px 0 37px 0;
		padding: 13px 67px;
		font-size: 13px;
		border: 1px solid #009ed1;
		border-radius: 10px;
	}
}

@media (max-width: 530px) {
	.questions__btn-element {
		width: 100%;
	}
}

/*/Questions*/

/* Select */
.test__top-bottom-work-container {
	margin-top: 8px;
	height: 46px;
	position: relative;
	width: 100%;
}

.test__top-bottom-work {
	text-align: left;
	padding: 13px 25px 13px 16px;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #c7c7cc;
	height: inherit;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-repeat: no-repeat;
	background-position: calc(95%);
	background-size: 10px;
	transition: background-color 1s ease;

	border: 1px solid #bcbcbc;
	border-radius: 8px;
	transition: color 1s ease, border 1s ease;
}

.test__top-bottom-work:hover {
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	border: 1px solid #009ed1;
	color: #464655;
}

.test__top-bottom-work-group {
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	top: 46px;
	left: 0;
	z-index: 1000;
	border-radius: 0px 0px 7px 7px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
}

.test__top-bottom-work-group-element-label {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 16px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
	border: 1px solid #bcbcbc;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-label:last-child {
	border-radius: 0px 0px 7px 7px;
}

.test__top-bottom-work-group-element-input {
	-webkit-appearance: none;
	height: 0px;
}

.first-label {
	border-radius: 7px 7px 0 0;
}

@media (max-width: 320px) {
	.test__top-bottom-work {
		font-size: 14px;
	}

	.test__top-bottom-work-group-element-label {
		font-size: 14px;
	}
}

/* Select */
.select_picked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}
</style>
