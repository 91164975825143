import {errorHandler} from "../../lib/errorHandler";
import {config} from "../../lib/config";

export const examinationInterview = {
    actions: {
        fetchPassing({commit}, payload) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+'interview/passings/get/'+payload.passingId;
            xmlHttp.open('GET',url,false);
            xmlHttp.setRequestHeader('X-Auth-Token',payload.token);
            try{
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            }catch (e) {
                console.log(e);
            }
            commit('setPassing', data)
        },
        saveExamination({state},token){
            let xmlhttp=new XMLHttpRequest();
            let url = config.apiPrefix+'interview/passings/save'
            xmlhttp.open('POST',url,false);
            xmlhttp.setRequestHeader('X-Auth-Token',token);
            xmlhttp.setRequestHeader('Content-Type','application/json;charset=UTF-8');
            try {
                xmlhttp.send(JSON.stringify(state.savePassing));
                errorHandler({status:xmlhttp.status});
            }catch (e) {
                console.log(e);
            }
        }
    },
    mutations: {
        setPassing(state, data) {
            state.dataPassing = data
        },
        setSavePassingId(state, data) {
            state.savePassing = {
                passingId: data,
                passes: []
            };
        },
        addSavePassing(state, data) {
            state.savePassing.passes.push(data)
        }

    },
    state: {
        dataPassing: null,
        savePassing: null,
    },
    getters: {
        getPassing(state) {
            return state.dataPassing;
        },
        getSavePassing(state) {
            return state.savePassing
        }

    }
}