<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<div class="filling">
						<div class="work__filters invisable-menu" v-if="filtersVisible">
							<div class="work__filters-caption">
								<div class="work__filters-caption-back" v-on:click="showFilters()">
									<button class="work__filters-caption-back-img"></button>
								</div>
								<p class="work__filters-caption-text">{{
									$t('pages.account.account_worker.accountWorkerFavoritesPage.filtration') }}</p>
							</div>

							<div class="work__filters-group">
								<div class="work__filters-element">
									<h3 class="work__filters-element-title">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.workExperience') }}
									</h3>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-1" name="filter-1" value="0"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-1">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.moreThan10Years')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-2" name="filter-2" value="1"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-2">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.5to10Years')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3" name="filter-3" value="2"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-3">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.3to5Years')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-4" name="filter-4" value="3"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-4">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.1to3Years')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-5" name="filter-5" value="4"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-5">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.lessThan1Year')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-6" name="filter-6" value="5"
											v-model="searchVacanciesForm.work_experience" />
										<label for="filter-6">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.noExperience')
											}}</label>
									</div>
								</div>
								<div class="work__filters-element">
									<h3 class="work__filters-element-title">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.education') }}</h3>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-1" name="filter-3-1" value="0"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-1">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.academicDegree')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-2" name="filter-3-2" value="1"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-2">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.higher')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-3" name="filter-3-3" value="2"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-3">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.secondaryVocational')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-4" name="filter-3-4" value="3"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-4">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.averageCommon')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-5" name="filter-3-5" value="4"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-5">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.mainCommon')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input type="checkbox" class="work__filters-element-filter-checkbox"
											id="filter-3-6" name="filter-3-6" value="5"
											v-model="searchVacanciesForm.education" />
										<label for="filter-3-6">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.irrelevant')
											}}</label>
									</div>
								</div>
								<div class="work__filters-element">
									<h3 class="work__filters-element-title">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.keySkills') }}</h3>
									<div class="work__filters-element-filter">
										<input id="0" type="checkbox" class="work__filters-element-filter-checkbox"
											value="0" v-model="searchVacanciesForm.key_skills" />
										<label for="0">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.projectManagement')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="1" type="checkbox" class="work__filters-element-filter-checkbox"
											value="1" v-model="searchVacanciesForm.key_skills" />
										<label for="1">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.negotiation')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="2" type="checkbox" class="work__filters-element-filter-checkbox"
											value="2" v-model="searchVacanciesForm.key_skills" />
										<label for="2">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.teamwork')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="3" type="checkbox" class="work__filters-element-filter-checkbox"
											value="3" v-model="searchVacanciesForm.key_skills" />
										<label for="3">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.pcUser')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="4" type="checkbox" class="work__filters-element-filter-checkbox"
											value="4" v-model="searchVacanciesForm.key_skills" />
										<label for="4">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.organizationalSkills')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="5" type="checkbox" class="work__filters-element-filter-checkbox"
											value="5" v-model="searchVacanciesForm.key_skills" />
										<label for="5">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.businessConversation')
											}}</label>
									</div>
								</div>
								<div class="work__filters-element">
									<h3 class="work__filters-element-title">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.typeOfEmployment')
										}}</h3>
									<div class="work__filters-element-filter">
										<input id="null" type="radio" class="work__filters-element-filter-checkbox"
											:value="null" v-model="searchVacanciesForm.empl_type" />
										<label for="null">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.not') }}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="0e" type="radio" class="work__filters-element-filter-checkbox"
											value="0" v-model="searchVacanciesForm.empl_type" />
										<label for="0e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.fullEmployment')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="1e" type="radio" class="work__filters-element-filter-checkbox"
											value="1" v-model="searchVacanciesForm.empl_type" />
										<label for="1e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.partTimeEmployment')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="2e" type="radio" class="work__filters-element-filter-checkbox"
											value="2" v-model="searchVacanciesForm.empl_type" />
										<label for="2e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.projectWork')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="3e" type="radio" class="work__filters-element-filter-checkbox"
											value="3" v-model="searchVacanciesForm.empl_type" />
										<label for="3e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.internship')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="4e" type="radio" class="work__filters-element-filter-checkbox"
											value="4" v-model="searchVacanciesForm.empl_type" />
										<label for="4e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.volunteering')
											}}</label>
									</div>
									<div class="work__filters-element-filter">
										<input id="5e" type="radio" class="work__filters-element-filter-checkbox"
											value="5" v-model="searchVacanciesForm.empl_type" />
										<label for="5e">{{
											$t('pages.account.account_worker.accountWorkerFavoritesPage.distantWork')
											}}</label>
									</div>
								</div>
								<div class="selects">
									<div class="search-wrapper">
										<button class="test__top-bottom-town" @click="cityVisible = !cityVisible">
											{{ cityName ? cityName :
												$t('pages.account.account_worker.accountWorkerFavoritesPage.cityNamePlaceholder')
											}}<span class="test__top-bottom-town-img"></span>
										</button>
										<div class="search-list" v-if="cityVisible">
											<input
												:placeholder="$t('pages.account.account_worker.accountWorkerFavoritesPage.cityNamePlaceholder')"
												type="text" class="search-list__element search-list__element-input"
												@input="e => searchCity(e.target.value)" />
											<button class="search-list__element" @click="pickCity" :value="item.id"
												v-for="item in dataCities" :key="item.id">{{ item.name }}</button>
										</div>
									</div>
									<div class="search__sorting-select-container">
										<button ref="select" class="search__sorting-select"
											@click="industryCategoriesVisible = !industryCategoriesVisible">
											{{ $t('pages.account.account_worker.accountWorkerFavoritesPage.industry') }}
											<span class="test__top-bottom-town-img"></span>
										</button>
										<div v-if="industryCategoriesVisible"
											class="search__sorting-select-speciality-group">
											<div class="search__sorting-select-speciality-group-element"
												v-for="(item, index) in dataIndustries" :key="index">
												<input type="checkbox" :id="item.id" :value="item.id"
													class="search__sorting-select-speciality-group-element-input"
													v-model="selectedCompanyIndustryCategories" />
												<label :for="item.id"
													class="search__sorting-select-speciality-group-element-label">{{
													item.name }}</label>
											</div>
										</div>
									</div>
									<div class="search-wrapper speciality">
										<button class="test__top-bottom-town"
											@click="chooseSpecialty = !chooseSpecialty">
											{{ specializationName ? specializationName :
												$t('pages.account.account_worker.accountWorkerFavoritesPage.speciality')
											}}<span class="test__top-bottom-town-img"></span>
										</button>
									</div>
								</div>
							</div>
							<div class="work__filters-btn-container">
								<button class="work__filters-btn" v-on:click="showFilters()">
									{{ $t('pages.account.account_worker.accountWorkerFavoritesPage.apply') }}
								</button>
							</div>
						</div>
						<div class="search" v-if="!filtersVisible">
							<h1 class="search__title">{{
								$t('pages.account.account_worker.accountWorkerFavoritesPage.work') }}</h1>
							<h2 class="search__subtitle">{{
								$t('pages.account.account_worker.accountWorkerFavoritesPage.favoritePositions') }}</h2>
							<div class="search__specifications" v-if="false">
								<span class="search__specifications-work-span"></span>
								<input type="text" placeholder="UX Designer" class="search__specifications-work" />
								<div class="search__specifications-rating">
									<span class="search__specifications-rating-span"></span>
									<p class="search__specifications-rating-text">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.rating') }}</p>
									<p class="search__specifications-rating-input-text">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.from') }}</p>
									<input type="text" class="search__specifications-rating-input"
										v-model="searchVacanciesForm.rate_from" />
									<p class="search__specifications-rating-input-text">{{
										$t('pages.account.account_worker.accountWorkerFavoritesPage.to') }}</p>
									<input type="text" class="search__specifications-rating-input"
										v-model="searchVacanciesForm.rate_to" />
								</div>
								<button class="search__sorting-filter" v-on:click="showFilters()">
									<img src="~@/assets/img/all-img/filters/filter-open-btn.svg" alt="filter"
										class="search__sorting-filter-img" />
								</button>
								<button class="search__specifications-btn" @click="vacancyLoad">
									{{ $t('pages.account.account_worker.accountWorkerFavoritesPage.findPositions') }}
								</button>
							</div>
							<p class="search__sorting-text mobile" v-if="false">{{
								$t('pages.account.account_worker.accountWorkerFavoritesPage.sortingTextMobile') }}</p>
							<div class="search__sorting" v-if="false">
								<p class="search__sorting-text desctop">{{
									$t('pages.account.account_worker.accountWorkerFavoritesPage.sortingTextDesktop') }}
								</p>
								<div class="search__sorting-price-container">
									<button tabindex="1" ref="select" class="search__sorting-price"
										@click="priceVisible = !priceVisible" @blur="closeSort">
										{{ $t('pages.account.account_worker.accountWorkerFavoritesPage.wage') }}
									</button>
									<div class="search__sorting-price-group" v-if="priceVisible">
										<input type="radio" id="up" value="Increasing"
											class="search__sorting-price-element-input"
											@click="sortedVacancy('Increasing')" />
										<label for="up" :class="{ sort_picked: sortPrice === 'Increasing' }"
											class="search__sorting-price-element-label first-price-label">{{
												$t('pages.account.account_worker.accountWorkerFavoritesPage.increasing')
											}}</label>
										<input type="radio" id="down" value="Decrease"
											class="search__sorting-price-element-input"
											@click="sortedVacancy('Decrease')" />
										<label for="down" :class="{ sort_picked: sortPrice === 'Decrease' }"
											class="search__sorting-price-element-label">{{
												$t('pages.account.account_worker.accountWorkerFavoritesPage.decrease')
											}}</label>
									</div>
								</div>
							</div>
						</div>
						<div class="work" v-if="!filtersVisible">
							<div class="proposed-group-filling">
								<div class="proposed-group-wrapper">
									<div class="proposed-group">
										<SeparateRecomendation v-show="favoriteVacancies"
											v-for="item in favoriteVacancies" :isFavorite="isVacancyFavorite(item.id)"
											:worker-rate="dataWorker.score" :key="item.id" :vacancy="item">
										</SeparateRecomendation>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
		<SpecialitySelect @pickSpecialization="pickSpecialization" @closeWindow="chooseSpecialty = false"
			:specialisations="specialties" :current-specialisation="searchVacanciesForm.specialization"
			v-if="chooseSpecialty"></SpecialitySelect>
	</div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import { config } from "@/lib/config";
import { logoutWithToken } from "@/lib/logoutWithToken";
import SpecialitySelect from "@/components/account/account_worker/modals/item/SpecialitySelect";
import SeparateRecomendation from "@/components/account/account_worker/account_worker_recomendation/item/SeparateRecomendation.vue";

export default {
	components: {
		SeparateRecomendation,
		HeaderAccountWorker,
		MenuWorker,
		SpecialitySelect
	},

	name: "AccountWorkerFavoritesPage",
	computed: {
		vacancies() {
			return this.$store.getters.vacancies;
		},
		getSearchVacanciesParams() {
			return this.$store.getters.searchVacanciesParams;
		},
		dataCities() {
			return this.$store.getters.dataCities;
		},
		specializationName() {
			if (!this.searchVacanciesForm?.specialization) {
				return this.$t('pages.account.account_worker.accountWorkerFavoritesPage.speciality');
			}
			const index = this.specialties?.map(item => item.id).indexOf(this.searchVacanciesForm?.specialization);
			return index > -1 ? this.specialties[index].name : this.$t('pages.account.account_worker.accountWorkerFavoritesPage.speciality');
		},
		favoriteVacancies() {
			return this.$store.getters.favoriteVacancy;
		}
	},

	data() {
		return {
			filtersVisible: false,
			showWorkType: false,
			selectVisible: false,
			cityVisible: false,
			chooseSpecialty: false,
			cityName: null,
			priceVisible: false,
			industryCategoriesVisible: false,
			dataWorker: null,
			timer: null,

			searchVacanciesForm: {
				specialization: null,
				city: null,
				empl_type: null,
				rate_from: null,
				rate_to: null,
				education: [],
				work_experience: [],
				key_skills: []
			},
			keySkills: [],
			sortPrice: null,
			sortDate: null,
			copyVacancy: null,
			searchVacancy: false,
			specialties: []
		};
	},

	methods: {
		isVacancyFavorite(vacancyId) {
			const favoriteVacancies = this.$store.getters.favoriteVacancy;
			return favoriteVacancies.some(vacancy => vacancy.id === vacancyId);
		},

		pickSpecialization(item) {
			this.searchVacanciesForm.specialization = item;
			this.chooseSpecialty = false;
		},
		specialtiesLoad() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "employer/specialization/all?lang=en";
			request.open("GET", url, false);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.specialties = response.data;
			} catch (e) {
				console.log(e);
			}
		},

		closeSelect() {
			setTimeout(() => {
				this.selectVisible = false;
			}, 300);
		},
		closeSort() {
			setTimeout(() => {
				this.priceVisible = false;
			}, 300);
		},
		checkSelect() {
			if (this.searchVacanciesForm.empl_type !== null) this.searchVacanciesForm.empl_type = null;
		},
		showFilters() {
			this.filtersVisible = !this.filtersVisible;
		},
		updateFilters() {
			this.$store.dispatch("setNewSearchVacanciesParams", this.searchVacanciesForm);
			this.copyVacancy = this.vacancies.slice();
		},
		vacancyLoad() {
			this.searchVacancy = true;
			this.updateFilters();
			this.$store.dispatch("getVacancies");
			this.copyVacancy = this.vacancies.slice();
		},

		sortedVacancy(params) {
			if (this.sortPrice === params) this.sortPrice = null;
			else this.sortPrice = params;

			switch (this.sortPrice) {
				case "Возрастание":
					this.copyVacancy = this.copyVacancy.slice().sort(function (a, b) {
						return a.payFrom - b.payFrom;
					});
					break;
				case "Убывание":
					this.copyVacancy = this.copyVacancy.slice().sort(function (a, b) {
						return b.payFrom - a.payFrom;
					});
					break;
				case null:
					this.copyVacancy = this.vacancies;
					break;
			}
			this.priceVisible = !this.priceVisible;
		},
		getKeySkills() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/skills/all";
			request.open("GET", url, false);
			try {
				request.send();
				let response = JSON.parse(request.response);
				this.keySkills = response.data;
			} catch (e) {
				console.log(e);
			}
		},
		searchCity(val) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.$store.dispatch("searchCity", val);
			}, 1000);
		},
		pickCity(e) {
			this.cityVisible = false;
			this.cityName = this.dataCities[this.dataCities.map(item => item.id).indexOf(e.target.value)].name;
			this.searchVacanciesForm.city = this.cityName;
			this.$store.dispatch("searchCity", "");
		},
		fetchDataWorker() {
			let request = new XMLHttpRequest();
			let url = config.apiPrefix + "candidate/data?lang=en";
			request.open("GET", url, false);
			request.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				request.send();
				logoutWithToken(request.status, this.$store, this.$router);
				let response = JSON.parse(request.response);
				this.dataWorker = response.data;
			} catch (e) {
				console.log(e);
			}
		},
		companyLoad() {
			const req = new XMLHttpRequest();
			const url = config.apiPrefix + "friends/search?role=employer&page=1&limit=10&name";
			req.open("GET", url, false);
			req.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				req.send();
				const { data } = JSON.parse(req.response);
				this.$store.commit("setCompanyCard", data);
			} catch (e) {
				console.log(e);
			}
		}
	},

	beforeMount() {
		this.searchVacancy = true;
		this.$store.dispatch("searchCity", "");
		this.$store.dispatch("fetchFavoriteVacancy", { token: this.$store.getters.token });
		this.fetchDataWorker();
		if (this.getSearchVacanciesParams) {
			if (this.getSearchVacanciesParams.specialization) {
				this.searchVacanciesForm.specialization = this.getSearchVacanciesParams.specialization;
			}
		}
		scroll(0, 0);
		this.getKeySkills();
		this.specialtiesLoad();
		if (sessionStorage.getItem("searchVacanciesForm")) {
			this.searchVacanciesForm = JSON.parse(sessionStorage.getItem("searchVacanciesForm"));
			sessionStorage.removeItem("searchVacanciesForm");
			this.vacancyLoad();
			return;
		}

		this.companyLoad();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name === "vacancyDashboard") {
			sessionStorage.setItem("searchVacanciesForm", JSON.stringify(this.searchVacanciesForm));
		}
		next();
	}
};
</script>


<style scoped>
/* Caption css */
.account {
	display: flex;
}

.filling {
	position: relative;
}

.show {
	display: block;
}

.main {
	background: #edeef0;
}

.proposed-group-filling {
	max-width: 100%;
	width: 100%;
}

.search__title {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.search__subtitle {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
}

.search__specifications {
	box-shadow: inset -1px -1px 0px #f1f1f5;
	position: relative;
	margin-top: 66px;
	width: 100%;
	box-sizing: border-box;
	height: 70px;
	border-radius: 10px;
	display: flex;
	align-items: center;
}

.search__specifications-work-span {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/find.svg");
	width: 14px;
	height: 14px;
	background-repeat: no-repeat;
	left: 2.9%;
}

.search__specifications-work {
	border-right: 1px solid #f1f1f5;
	width: 100%;
	max-width: 100%;
	flex-shrink: 1;
	border-radius: 10px 0px 0px 10px;
	padding: 0px 0 0px 64px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #171625;
	height: inherit;
	transition: background 1s ease;
}

.search__specifications-work:hover {
	background: #eefbff;
}

.search__specifications-rating-span {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/rating.svg");
	width: 14px;
	height: 14px;
	background-repeat: no-repeat;
	left: 2.9%;
}

.search__specifications-rating {
	border-right: 1px solid #f1f1f5;
	position: relative;
	height: inherit;
	max-width: 305px;
	width: 100%;
	flex-shrink: 1;
	display: flex;
	background: #fff;
	padding-right: 24px;
	transition: background 1s ease;
}

/* search */
.test__top-bottom-town {
	width: 100%;
	height: 100%;
	padding-left: 60px;
	background-image: url("~@/assets/img/landing-img/main-page/place-blue.svg");
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 36px;
	text-align: left;
}

.test__top-bottom-town-img {
	position: absolute;
	display: block;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-size: 10px;
	width: 10px;
	height: 9px;
	right: 20px;
	top: calc(50% - 4px);
}

.search-wrapper {
	max-width: 100%;
	width: 100%;
	height: 50px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
	position: relative;
	background: #f7f7fa;
}


.search-list {
	position: absolute;
	background: #f7f7fa;
	max-height: 278px;
	overflow-y: scroll;
	border-radius: 0px 0px 7px 7px;
}

.search-list__element {
	padding-left: 60px;
	background: #f7f7fa;
}

.search-list {
	z-index: 101;
}

.search-list__element {
	padding-left: 60px;
}

.search-list__element {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
	line-height: 1;
}

.search-list__element-input {
	cursor: auto;
	border-bottom: 1px solid #eaeaee;
	padding-right: 20px;
}

.search-list__element:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.search-list__element:last-child {
	border-radius: 0px 0px 7px 7px;
	border-bottom: none;
}

.test__top-bottom-town::placeholder {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
}

/* /search */

.search__specifications-rating-text {
	padding: 23px 0 23px 64px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	background: inherit;
	line-height: 26px;
	letter-spacing: 0.1px;
	transition: inherit;
	color: #171625;
	transition: background 1s ease;
}

.search__specifications-rating-input {
	margin-left: 10px;
	width: 32px;
	border-bottom: 1px solid #50b5ff;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
	padding: 0px 0 0px 0px;
	background: inherit;
	height: 26px;
	transition: inherit;
	margin-top: 23px;
	transition: background 1s ease;
}

.search__specifications-rating-input-text {
	font-family: "Roboto";
	margin-left: 11px;
	padding: 23px 0 23px 0px;
	font-weight: 400;
	font-size: 16px;
	transition: inherit;
	line-height: 26px;
	background: inherit;
	letter-spacing: 0.1px;
	color: #171625;
	transition: background 1s ease;
}

.search__specifications-rating:hover {
	background: #eefbff;
}

.search__specifications-rating-span {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/rating.svg");
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
	left: 13.9%;
	top: 30px;
}

.search__specifications-place-container {
	align-items: center;
	height: inherit;
	position: relative;
	flex-shrink: 1;
	width: 305px;
	background: #fff;
}

.search__specifications-place-container-input {
	width: 100%;
	height: 70px;
	padding: 0px 0px 0px 64px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #171625;
	background: #fff;
	transition: background 1s ease;
}

.search__specifications-place-container-input:hover {
	background: #eefbff;
}

.search__specifications-place-container-span {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/place.svg");
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
	left: 15.4%;
	top: 28px;
}

.search__specifications-employment-container {
	cursor: pointer;
	padding-left: 70px;
	border-left: 1px solid #f1f1f5;
	border-right: none;
	border-top: none;
	border-bottom: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-family: "Roboto";
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #171725;
	align-items: center;
	height: inherit;
	position: relative;
	flex-shrink: 1;
	width: 305px;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
	transition: background-color 1s ease;
}

.search__specifications-employment-container:hover {
	background-color: #eefbff;
}

.search__specifications-btn {
	justify-content: center;
	flex-shrink: 1;
	z-index: 2;
	box-sizing: border-box;
	max-width: 200px;
	width: 100%;
	font-family: "Roboto";
	border-radius: 0px 10px 10px 0px;
	height: inherit;
	background: #009ed1;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.search__specifications-btn:hover {
	opacity: 0.5;
}

.search__sorting {
	margin-top: 34px;
	display: flex;
	align-items: center;
}

.search__sorting-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #696974;
}

.search__sorting-date {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	width: 129px;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-position: 104px;
	background-size: 10px;
	background-repeat: no-repeat;
	padding: 10px 12px 10px 12px;
	margin-left: 30px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #787885;
	background-color: #fff;
	border: 1px solid #b4b4bb;
	border-radius: 4px;
	transition: color 1s ease, border 1s ease;
	cursor: pointer;
	box-sizing: border-box;
}

.search__sorting-date:hover {
	border: 1px solid #009ed1;
	color: #19191d;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
}

/* Vacancy */
.proposed-group {
	max-width: 100%;
	width: 100%;
	display: grid;
	gap: 20px;
}

.proposed-group-company {
	grid-template-columns: repeat(3, 1fr);
}

.proposed-group-wrapper {
	margin-bottom: 40px;
}

.proposed__title {
	font-weight: 600;
	font-size: 16px;
	line-height: 1.2;
	color: #3b4c68;
}

.proposed__link {
	font-weight: 600;
	font-size: 14px;
	line-height: 1.2;
	color: #3b4c68;
}

.proposed-caption {
	display: flex;
	justify-content: space-between;
}

.mobile {
	display: none;
}

.invisable-menu {
	display: none;
}

.search__sorting-filter {
	display: block;
	padding: 0 20px;
	height: 70px;
	background: #fff;
}

@media (max-width: 1754px) {


	.search__sorting-filter-img {
		padding: 10px 9px;
		background: #e6f6fc;
		border: 1px solid #009ed1;
		border-radius: 9px;
		transition: opacity 1s ease;
		display: flex;
		flex-shrink: 0;
	}

	.search__sorting-filter-img:hover {
		opacity: 0.5;
	}
}

@media (max-width: 1190px) {
	.search__specifications {
		background: #fff;
		width: 100%;
		box-sizing: border-box;
		flex-direction: column;
		height: 100%;
		padding: 20px 20px 10px 20px;
		border: 1px solid #f1f1f5;
		border-radius: 20px;
		margin: 25px auto 0 auto;
	}

	.search__specifications-rating {
		max-width: 100%;
	}

	.search-wrapper {
		max-width: 100%;
	}

	.search__sorting-filter {
		height: 36px;
		display: flex;
		padding: 0;
		justify-content: left;
		margin-bottom: 10px;
	}

	.search__specifications-work {
		font-size: 14px;
		padding: 0px 32px 0px 41px;
		margin-bottom: 10px;
		height: 48px;
		width: 100%;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.search__specifications-work-span {
		left: 35px;
		top: 36px;
	}

	.search__specifications-rating {
		font-size: 14px;
		margin-bottom: 10px;
		align-items: center;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.search__specifications-rating-text {
		font-size: 14px;
		padding: 0px 0 0px 41px;
	}

	.search__specifications-rating-input-text {
		font-size: 14px;
		padding: 0px 0 0px 0px;
	}

	.search__specifications-rating-span {
		left: 16px;
		top: 17px;
	}

	.search__specifications-place-container {
		font-size: 14px;
		margin-bottom: 10px;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
		overflow: hidden;
	}

	.search__specifications-place-container-input {
		font-size: 14px;
		padding-left: 41px;
		height: inherit;
		border-radius: 10px;
		box-sizing: border-box;
		padding-right: 36px;
	}

	.search__specifications-place-container-span {
		top: 15px;
		left: 16px;
	}

	.search__specifications-employment-container {
		padding-left: 37px;
		font-size: 14px;
		margin-bottom: 10px;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
		background-position: calc(97%);
		overflow: hidden;
	}

	.search__specifications-rating-input {
		margin: 10px 0 auto 10px;
	}

	.search__specifications-place {
		margin-bottom: 10px;
		width: 100%;
		height: 42px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.search__specifications-employment {
		margin-bottom: 10px;
		width: 100%;
		height: 42px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.search__specifications-btn {
		margin-bottom: 10px;
		max-width: 100%;
		box-sizing: border-box;
		height: 48px;
		border-radius: 10px;
	}

	/* search */
	.test__top-bottom-town {
		padding-left: 42px;
		background-position: 18px;
	}

	.search-wrapper {
		font-size: 14px;
		align-items: center;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		border: 1px solid #f1f1f5;
		border-radius: 10px;
	}

	.test__top-bottom-town {
		border-radius: 10px;
	}


	.search-list {
		border-radius: 10px;
	}
}

@media (max-width: 1190px) {
	.proposed-group {
		grid-template-columns: repeat(3, 1fr);
	}

	.proposed-group-company {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 760px) {
	.search__title {
		font-size: 13px;
	}

	.search__subtitle {
		font-size: 20px;
	}

	.search__specifications {
		width: 100%;
		font-size: 14px;
	}

	.search__specifications-work {
		font-size: 14px;
	}

	.search__specifications-rating {
		font-size: 14px;
	}

	.search__specifications-rating-input {
		font-size: 14px;
	}

	.search__specifications-rating-text {
		font-size: 14px;
	}

	.search__specifications-rating-input-text {
		font-size: 14px;
	}

	.search__specifications-place {
		font-size: 14px;
	}

	.search__specifications-employment {
		font-size: 14px;
	}

	.search__specifications-btn {
		font-size: 14px;
	}

	.mobile {
		display: block;
		padding-top: 23px;
	}

	.search__sorting {
		margin-top: 12px;
		width: 100%;
		box-sizing: border-box;
	}

	.desctop {
		display: none;
	}

	.search__sorting-date {
		margin-left: 0;
	}

	.search__sorting-text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #696974;
	}

	.search__sorting-date {
		width: 103px;
		background-position: 79px;
		font-size: 12px;
		padding: 10px 14px 10px 6px;
	}

	.proposed-group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 700px) {
	.proposed-group-company {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (max-width: 500px) {
	.proposed-group {
		max-width: 315px;
		margin: 0px auto 0 auto;
		grid-template-columns: repeat(1, 1fr);
	}

	.proposed-group-company {
		max-width: 100%;
	}
}

@media (max-width: 380px) {
	.search__specifications-work {
		padding-left: 41px;
		background-position: 15px;
	}

	.search__specifications-rating-text {
		padding-left: 41px;
		background-position: 15px;
	}

	.search__specifications-place {
		font-size: 14px;
		padding-left: 41px;
		background-position: 15px;
	}

	.search__specifications-employment {
		padding-left: 41px;
		background-position: 15px;
	}
}

/* Search */

/* Work */
.work {
	margin-top: 50px;
	display: flex;
	padding-bottom: 100px;
	align-items: flex-start;
}

.work__filters {
	display: none;
	max-width: 100%;
	width: 100%;

}

.work__filters-element :not(:last-child) {
	margin-bottom: 10px;
}

.work__filters-element :first-child {
	margin-bottom: 14px;
}

.work__filters-element {
	margin-bottom: 14px;
	padding: 8px;
	width: 100%;
	background: #f7f7fa;
	border-radius: 4px;
	box-sizing: border-box;
}

.work__filters-element-title {
	margin-bottom: 4px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	color: #19191d;
}

.work__filters-element-filter-checkbox {
	position: absolute;
	z-index: 1;
	opacity: 0;
}

.work__filters-element-filter-checkbox+label {
	display: inline-flex;
	align-items: center;
	user-select: none;
	font-family: "Inter";
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #19191d;
}

.work__filters-element-filter-checkbox+label::before {
	content: "";
	display: inline-block;
	width: 14px;
	height: 14px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 2px solid #4a4b57;
	border-radius: 2px;
	margin-right: 18px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.work__filters-element-filter-checkbox:checked+label::before {
	border-color: #0b76ef;
	background-color: #0b76ef;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.work__filters-btn {
	padding: 16px 8px;
	width: 100%;
	box-sizing: inherit;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: #fafafb;
	background: #009ed1;
	border-radius: 10px;
}

.work__filters-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	row-gap: 20px;
	column-gap: 20px;
}

.work__filters-element {
	margin-bottom: 0px;
	width: 100%;
	border-radius: 4px;
}

.work__filters-caption {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 30px 0;
}

.work__filters-caption-back {
	height: 30px;
	align-items: center;
}

.work__filters-caption-back-img {
	background: url("~@/assets/img/vacancies-page/search/back.svg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 30px;
	height: 9px;
}

.work__filters-caption-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 35.5px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.work__filters-btn {
	width: 25%;
}

.work__filters-btn-container {
	margin: 37px 0 80px 0;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	justify-content: center;
}

.work__workers {
	width: 100%;
}

.jobs__group-element {
	margin-bottom: 11px;
}

.work__workers {
	display: flex;
	margin-bottom: 30px;
	flex-direction: column;
}

.invisable-menu {
	max-height: 100%;
	box-sizing: border-box;
	z-index: 3;
	display: block;
	background: #edeef0;
	width: 100%;
	height: 100%;
	max-width: 100%;
}

.selects {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.search__sorting-select-container {
	position: relative;
	max-width: 100%;
	width: 100%;
}



.search__sorting-select {
	text-align: left;
	height: 50px;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	line-height: 1.76;
	width: 100%;
	transition: color 1s ease, border 1s ease;
	background-size: 10px;
	padding-left: 60px;
	color: #171625;
	font-weight: 500;
	position: relative;
	background: #f7f7fa;
}

.search__sorting-select-speciality-group {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;

	z-index: 100;
	max-height: 250px;
	overflow: auto;
	scrollbar-width: thin;
	background: #f7f7fa;
}

.search__sorting-select-speciality-group-element {
	cursor: pointer;
	width: 100%;
	border-bottom: 1px solid #b4b4bb;
	border-top: none;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;

	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
	display: flex;
	padding: 2px 9px 2px 12px;
}

.search__sorting-select-speciality-group-element-input {}

.search__sorting-select-speciality-group-element-label {
	width: 100%;
	box-sizing: border-box;
	display: block;
	width: 100%;
	padding: 7px 12px;
	cursor: pointer;
}


.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.search__sorting-select-speciality-group-element:last-child {
	border-bottom: none;
}

@media (max-width: 1754px) {
	.active {
		display: block;
	}


}

@media (max-width: 1754px) {
	.work__workers {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 20px;
	}
}

@media (max-width: 1058px) {
	.invisable-menu {
		width: 100%;
	}

	.work__workers {
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}

	.work__filters-group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 768px) {
	.work {
		padding-bottom: 40px;
	}

	.work__filters-btn {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.work__filters-group {
		grid-template-columns: repeat(1, 1fr);
		row-gap: 14px;
	}

	.work__filters-element-title {
		font-size: 15px;
	}

	.work__filters-element-filter-checkbox+label {
		font-size: 14px;
	}
}

@media (max-width: 650px) {
	.work__workers {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}
}

/* Work */

/* Select */

.test__top-bottom-work-container {
	height: 100%;
	position: relative;
	width: 22.2%;
	border-left: 1px solid #f1f1f5;
}

.test__top-bottom-work-container::after {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/work.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	left: 36px;
	top: 28px;
}

.test__top-bottom-work-container {
	height: 100%;
	position: relative;
	width: 22.2%;
}

.test__top-bottom-work-container::after {
	position: absolute;
	content: "";
	display: inline-block;
	background: url("~@/assets/img/vacancies-page/search/work.svg");
	width: 16px;
	height: 15px;
	background-repeat: no-repeat;
	left: 36px;
	top: 28px;
}

.test__top-bottom-work {
	text-align: left;
	position: relative;
	padding-left: 60px;
	width: 100%;
	height: 70px;
	cursor: pointer;
	border-left: 1px solid #f1f1f5;
	border-right: none;
	border-top: none;
	border-bottom: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #171725;

	height: inherit;
	flex-shrink: 1;
	background-color: #fff;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
	transition: background-color 1s ease;
}

.test__top-bottom-work-group {
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	top: 70px;
	left: 0;
	z-index: 10;
	border-radius: 0px 0px 7px 7px;
	box-shadow: inset -1px -1px 0px #f1f1f5;
}

.test__top-bottom-work-group-element-label {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 32px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
	border: 1px solid #eaeaee;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-label:last-child {
	border-radius: 0px 0px 7px 7px;
}

.test__top-bottom-work-group-element-input {
	-webkit-appearance: none;
	height: 0px;
}

@media (max-width: 1550px) {
	.test__top-bottom-work-container {
		width: 100%;
		height: 48px;
		margin-bottom: 10px;
		border-radius: 10px;
		border: 1px solid #f1f1f5;
	}

	.test__top-bottom-work-container::after {
		left: 16px;
		top: 17px;
	}

	.test__top-bottom-work {
		border-radius: 10px;
		padding-left: 41px;
	}

	.test__top-bottom-work-group {
		top: 48px;
		border-radius: 7px;
	}

	.test__top-bottom-work-group-element-label:nth-child(1) {
		border-radius: 7px;
	}

	.first-label {
		border-radius: 7px 7px 0 0;
	}

	.test__top-bottom-work {
		font-size: 14px;
	}

	.test__top-bottom-work-group-element-label {
		font-size: 14px;
	}
}

/* /Select */

/* Price select */
.search__sorting-price-container {
	margin-left: 20px;
	width: 177px;
	border: 1px solid #b4b4bb;
	border-radius: 4px;
	position: relative;
	box-sizing: border-box;
	display: block;
	height: 35px;
	align-items: center;
	transition: border 1s ease;
}

.search__sorting-price-container:hover {
	border: 1px solid #009ed1;
}

.search__sorting-price {
	padding: 9px 12px;
	width: 100%;
	border-radius: 4px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: color 1s ease;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
}

.search__sorting-price:hover {
	color: #19191d;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search__sorting-price-group {
	background: #fafafb;
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	z-index: 100;
	border-radius: 4px;
}

.search__sorting-price-element-input {
	-webkit-appearance: none;
	height: 0px;
}

.search__sorting-price-element-label {
	cursor: pointer;
	padding: 7px 12px;
	width: 100%;
	border: 1px solid #b4b4bb;
	border-top: none;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
}

.search__sorting-price-element-label:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.search__sorting-price-element-label:last-child {
	border-radius: 0px 0px 4px 4px;
}

.first-price-label {
	border-radius: 4px 4px 0 0;
}

@media (max-width: 1190px) {
	.search__sorting-select {
		height: 46px;
		border-radius: 10px;
		padding-left: 42px;
	}
}

@media (max-width: 766px) {
	.search__sorting-price-container {
		margin-left: 0;
	}
}

/* /Price select */
.select_picked {
	background-color: #eefbff;
	color: #009ed1;
}

.sort_picked {
	background-color: #eefbff;
	color: #009ed1;
}

.speciality .test__top-bottom-town {
	background-image: none;
}
</style>
