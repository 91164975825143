<template>
	<div>
		<SocialHeader :role="$store.getters.role"></SocialHeader>
		<main class="main">
			<div class="account">
				<SocialMenu :role="$store.getters.role"></SocialMenu>
				<div class="all">
					<div class="caption">
						<div class="caption__head">
							<div class="caption-head__content">
								<p class="subtitle mobile-block">{{
									$t('pages.social.socialCommunitiesApplications.applicantProfile') }}</p>
								<div class="caption-head__content-elements">
									<button @click="$router.go(-1)" class="back"></button>
									<h1 class="caption__title">{{
										$t('pages.social.socialCommunitiesApplications.applications') }}</h1>
								</div>
							</div>
							<button class="friends-who mobile-block" v-on:click="statusVizable = !statusVizable">
								<p class="friends-who-text">{{ $t('pages.social.socialCommunitiesApplications.list') }} <span
										class="friends-who-img"></span></p>
								<div class="friends" v-if="statusVizable">
									<div class="friends__caption">
										<a href="" class="friends__caption-btn friends__caption-btn-selected">{{
											$t('pages.social.socialCommunitiesApplications.editCommunity') }}</a>
										<router-link :to="{ name: 'outgoingRequest' }" class="friends__caption-btn">{{
											$t('pages.social.socialCommunitiesApplications.statisticsCommunities')
											}}</router-link>
										<router-link :to="{ name: 'myFriendReq' }" class="friends__caption-btn">{{
											$t('pages.social.socialCommunitiesApplications.myApplications') }} <span
												class="friends-messages">99+</span></router-link>
									</div>
								</div>
							</button>
						</div>

						<nav class="nav desctop-block" v-if="false">
							<div class="list">
								<a href="" class="list__item list__item-selected">{{
									$t('pages.social.socialCommunitiesApplications.all') }}</a>
								<a href="" class="list__item ">{{ $t('pages.social.socialCommunitiesApplications.jobSeekers')
									}}</a>
								<a href="" class="list__item">{{ $t('pages.social.socialCommunitiesApplications.employers')
									}}</a>
							</div>
						</nav>
					</div>
					<div class="filling">
						<div class="filling-people">
							<SeparateApplications v-for="(item, index) in getCommunitiesRequest" :key="item.id"
								:application="item" :communityId="communitiesId" :index="index">
							</SeparateApplications>
						</div>
						<div class="interaction" v-if="false">
							<div class="community interaction-element desctop-block">
								<div class="community__caption">
									<a href="" class="community__wright"><span class="community__wright-img"></span>{{
										$t('pages.social.socialCommunitiesApplications.writeToChat') }}</a>
								</div>
								<div class="community__filling">
									<button class="community__filling-btn">{{
										$t('pages.social.socialCommunitiesApplications.recommendCommunity') }}</button>
									<button class="community__filling-btn">{{
										$t('pages.social.socialCommunitiesApplications.addToFavorites') }}</button>
									<button class="community__filling-btn">{{
										$t('pages.social.socialCommunitiesApplications.findSimilarCommunities') }}</button>
								</div>
							</div>
							<div class="participants interaction-element desctop-block">
								<div class="participants-caption">
									<h3 class="participants__title">{{
										$t('pages.social.socialCommunitiesApplications.members') }}</h3>
									<p class="participants-number">9 999 999</p>
								</div>
								<div class="participants-group">
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
									<SeparatePersone></SeparatePersone>
								</div>
							</div>
							<div class="blog interaction-element">
								<div class="participants-caption blog-caption">
									<h3 class="participants__title">{{ $t('pages.social.socialCommunitiesApplications.blog')
										}}</h3>
									<p class="participants-number">999</p>
								</div>
								<div class="blog-list">
									<SeparateBlog></SeparateBlog>
									<SeparateBlog></SeparateBlog>
									<SeparateBlog></SeparateBlog>
								</div>
								<button class="possible__show">{{ $t('pages.social.socialCommunitiesApplications.viewAll') }}
									<span class="possible__show-icon"></span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu.vue";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparatePersone from "../../components/social/account_communitis_main/SeparatePersone.vue";
import SeparateBlog from "../../components/social/account_communitis_main/SeparateBlog.vue";
import SeparateApplications from "../../components/social/account_communitis_my/SeparateApplications.vue";

export default {
	components: { SocialMenu, SocialHeader, SeparatePersone, SeparateBlog, SeparateApplications },
	name: "SocialCommunitiesApplications",
	computed: {
		getPageCommunities() {
			return this.$store.getters.getPageCommunities;
		},
		getCommunitiesRequest() {
			return this.$store.getters.getCommunitiesRequest;
		}
	},
	props: ['communitiesId'],
	data() {
		return {
			statusVizable: false,
			menuVisible: false,
			role: null
		};
	},
	methods: {
		fetchAllRequest() {
			this.$store.commit('resetPageCommunities');
			this.$store.dispatch('fetchCommunitiesRequest', {
				page: this.getPageCommunities,
				token: this.$store.getters.token,
				limit: this.$store.getters.getLimitCommunities,
				communityId: this.communitiesId,
				callback: this.deletePagination
			})
		},
		deletePagination() {
			window.onscroll = null;
		},
		addRequests() {
			this.$store.commit('upPageCommunities');
			this.$store.dispatch('fetchCommunitiesRequest', {
				page: this.getPageCommunities,
				token: this.$store.getters.token,
				limit: this.$store.getters.getLimitCommunities,
				communityId: this.communityId,
				callback: this.deletePagination
			})
		}
	},
	beforeMount() {
		this.fetchAllRequest();
	},
	beforeCreate() {
		window.onscroll = () => {
			let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
			if (bottomOfWindow) {
				this.addRequests();
			}
		}
	}
};
</script>

<style scoped>
.account {
	display: flex;
}

.all {
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	padding: 59px 30px 0 30px;
}


.caption {
	width: 100%;
	box-sizing: border-box;
	align-items: center;
}

.back {
	background: url("~@/assets/img/account-img/communities/back-black.svg");
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	display: block;
	background-position: left;
}

.caption__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.nav {
	margin-top: 40px;
}

.list__item:not(:last-child) {
	margin-right: 40px;
}

.list__item:not(:last-child) {
	margin-right: 40px;
}

.list__item {
	position: relative;
	padding-bottom: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #3b4c68;
}

.list__item.list__item-selected::after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	content: "";
	display: block;
	background: #009ed1;
}


.filling {
	max-width: 100%;
	width: 100%;
	display: flex;
	margin-top: 40px;
}

.filling-people {
	width: 100%;
	max-width: 100%;
}

.interaction {
	max-width: 388px;
	width: 100%;
	box-sizing: border-box;
	margin-left: 30px;
}

.community {
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
}

.community__caption {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
}

.interaction-element {
	margin-top: 20px;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	padding: 20px 12px;
}

.caption__head {
	display: flex;
	align-items: center;
	padding-top: 37px;
}

.caption-head__content-elements {
	display: flex;
	align-items: center;
}

.community {
	margin-top: 0;
}

.community__caption {
	padding: 0 0 20px 18px;
	border-bottom: 1px solid #efeff4;
	width: 100%;
}

.community__wright {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	color: #009ed1;
	display: flex;
	align-items: center;
}

.community__wright-img {
	margin-right: 7px;
	background: url("~@/assets/img/account-img/communities/chat-blue.svg");
	width: 16px;
	height: 15px;
	display: block;
	background-repeat: no-repeat;
}

.community__filling {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
	padding: 10px 0 0 18px;
}

.community__filling-btn {
	margin-top: 10px;
	display: inline;
	width: 100%;
	text-align: left;
}

.participants {
	padding: 20px 22px;
}


.participants-caption {
	display: flex;
	align-items: center;
}

.participants__title {
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: #44444f;
}

.participants-number {
	margin-left: 10px;
	font-family: "Roboto";
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.03em;
	color: rgba(68, 68, 79, 0.5);
}

.participants-group {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 20px;
	column-gap: 20px;
}

.blog {
	padding: 20px 12px;
}

.back {
	height: 20px;
}

.blog-caption {
	padding: 0 10px;
}

.possible__show {
	display: flex;
	justify-content: center;
	width: 100%;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.03em;
	color: #44444f;
	padding: 15px 0 0 0;
}

.possible__show-icon {
	width: 9px;
	height: 9px;
	margin-left: 4px;
	display: flex;
	background-image: url("~@/assets/img/account-img/friends/list.svg");
	background-size: cover;
	cursor: pointer;
	margin-top: 3px;
}


.mobile-block {
	display: none;
}


@media (max-width: 720px) {
	.desctop-block {
		display: none;
	}

	.mobile-block {
		display: block;
	}

	.interaction {
		display: none;
	}

	.all {
		padding: 47px 10px 0 10px;
		max-width: 400px;
	}

	.caption {
		border-radius: 0;
		height: 156px;
	}

	.caption__head {
		align-items: flex-end;
	}

	.filling {
		margin-top: 30px;
	}


	.friends-who {
		margin-left: auto;
		text-align: left;
		padding: 10px 0 10px 10px;
		position: relative;
	}

	.friends-who-text {
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #3e4453;
		position: relative;
		align-items: center;
		display: flex;
	}

	.friends-who-img {
		display: block;
		background-image: url("~@/assets/img/account-img/friends/list-blue.svg");
		background-repeat: no-repeat;
		margin-left: 10px;
		width: 10px;
		height: 10px;
		background-position-y: 1px;
	}

	.friends {
		background: #ffffff;
		border: 1px solid #efeff4;
		border-radius: 10px;
		position: absolute;
		z-index: 20;
		width: 300px;
		left: -229px;
		bottom: -135px;
	}

	.friends__caption {
		display: flex;
		flex-direction: column;
		width: 100%;
		box-sizing: border-box;
	}

	.friends__caption-btn {
		margin-top: 10px;
		padding: 5px 0 5px 22px;
		font-family: "Roboto";
		font-weight: 600;
		font-size: 14px;
		line-height: 1.5;
		color: #44444f;

	}

	.friends__caption-btn.friends__caption-btn-selected {
		display: block;
		background: rgba(239, 239, 244, 0.5);
		position: relative;
	}

	.friends__caption-btn.friends__caption-btn-selected::before {
		position: absolute;
		content: "";
		left: -1px;
		border-radius: 0px 20px 20px 0;
		width: 2px;
		height: 100%;
		display: block;
		background: #009ED1;
		top: 0;
	}

	.friends__best-container {
		margin-top: 15px;
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
		padding: 0 12px;
	}

	.friends__best {
		display: flex;
		width: 100%;
		padding: 18px 6px 13px 6px;
		border-top: 1px solid #efeff4;
		box-sizing: border-box;
		align-items: center;

	}

	.friends__best-text {
		font-family: "Roboto";
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.03em;
		color: #44444f;
		margin: 2px 0 0 3px;
	}

	.friends__best-icon {
		background: url("~@/assets/img/account-img/friends/star.svg");
		width: 24px;
		height: 24px;
		display: block;
	}


	.friends-messages {
		margin-left: 11px;
		font-family: "Montserrat";
		font-weight: 600;
		font-size: 12px;
		display: inline;
		padding: 3px 8px;
		background: #14c686;
		border-radius: 20px;
		color: #fff;
		line-height: 1;
		display: none;
	}

	.caption-head__content-elements {
		margin-top: 6px;
	}

	.subtitle {
		display: block;
		font-family: "SFUIDisplay";
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #009ed1;
	}
}
</style>