<template>
	<div>
		<Header></Header>
		<main class="main">
			<div class="about">
				<div class="container">
					<div class="about__wrapper">
						<div class="about__wrapper-caption mobile">
							<button class="about__wrapper-caption-back"></button>
							<p class="about__wrapper-caption-text">Applicant card</p>
						</div>
						<div class="about__wrapper-worker">
							<div class="about__wrapper-worker-person">
								<div class="about__wrapper-worker-person-representation">
									<img :src="summary.avatarUrl" alt="" class="about__wrapper-worker-person-representation-icon" />
									<div class="
                      about__wrapper-worker-person-representation-rating
                      about-rating
                    ">
										<p class="
                        about__wrapper-worker-person-representation-rating-number
                      ">
											78
										</p>
									</div>
									<p class="about__wrapper-worker-person-representation-employer">
										{{ summary.name }}
									</p>
									<p class="
                      about__wrapper-worker-person-representation-designation
                    ">
										Job seeker
									</p>
									<div class="
                      about__wrapper-worker-person-representation-information
                    ">
										<p class="
                        about__wrapper-worker-person-representation-information-place
                      ">
											{{ summary.city }}
										</p>
										<p class="
                        about__wrapper-worker-person-representation-information-age
                      ">
											27 years
										</p>
									</div>
								</div>
								<div class="about__wrapper-worker-person-suggestions">
									<button class="about__wrapper-worker-person-suggestions-work">
										Invite for an interview
									</button>
									<button class="about__wrapper-worker-person-suggestions-test">
										Offer to take a test
									</button>
								</div>
							</div>
							<!--              <div class="about__wrapper-worker-change">-->
							<!--                <button class="about__wrapper-worker-change-btn">-->
							<!--                  Edit-->
							<!--                </button>-->
							<!--              </div>-->
						</div>
						<div class="about__wrapper-data">
							<div class="about__wrapper-data-info">
								<h1 class="about__wrapper-data-info-title">About myself</h1>
								<p class="about__wrapper-data-info-text">
									{{ summary.aboutText }}
								</p>
								<h2 class="about__wrapper-data-info-contacts-title">
									Contact Information
								</h2>
								<div class="about__wrapper-data-info-contacts-group">
									<div class="about__wrapper-data-info-contacts-group-element">
										<p class="data-text">Telephone</p>
										<a href="#" class="
                        about__wrapper-data-info-contacts-group-element-link
                      ">{{ summary.phone }}</a>
									</div>
									<div class="about__wrapper-data-info-contacts-group-element">
										<p class="data-text">E-mail</p>
										<a href="#" class="
                        about__wrapper-data-info-contacts-group-element-link
                      ">{{ summary.email }}</a>
									</div>
									<div class="about__wrapper-data-info-contacts-group-element">
										<p class="data-text data-social">Social networks</p>
										<div class="
                        about__wrapper-data-info-contacts-group-element-social
                      ">
											<div class="
                          about__wrapper-data-info-contacts-group-element-social-logo
                        "></div>
											<div class="
                          about__wrapper-data-info-contacts-group-element-social-logo
                        "></div>
											<div class="
                          about__wrapper-data-info-contacts-group-element-social-logo
                        "></div>
											<div class="
                          about__wrapper-data-info-contacts-group-element-social-logo
                        "></div>
										</div>
									</div>
								</div>
							</div>
							<div class="about__wrapper-data-education data-standart-block">
								<h3 class="about__wrapper-data-education-title">
									Name of the university
								</h3>
								<p class="education-text"></p>
								<p class="education-description">
									{{ summary.universityName }}
								</p>
								<p class="education-text"></p>
								<h3 class="about__wrapper-data-education-title">
									School name
								</h3>
								<p class="education-text"></p>
								<p class="education-description">
									{{ summary.schoolName }}
								</p>
							</div>
							<div class="about__wrapper-data-qualification data-standart-block">
								<h3 class="about__wrapper-data-education-title">Skills</h3>
								<p class="education-description">
									{{ summary.skills }}
								</p>
							</div>
							<div class="about__wrapper-data-experience data-standart-block">
								<h4 class="about__wrapper-data-experience-title">Achievements</h4>

								<div class="about__wrapper-data-experience-group">
									<div class="about__wrapper-data-experience-group-element">
										<p class="education-description">
											{{ summary.achievements }}
										</p>
									</div>
								</div>
							</div>
							<div class="about__wrapper-data-skills data-standart-block">
								<h5 class="about__wrapper-data-skills-title">
									Key skills
								</h5>
								<div class="about__wrapper-data-skills-group">
									<ul class="
                      about__wrapper-data-skills-group-element
                      skills-left-element
                    ">
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Vel leo proin facilisis metus
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Sit ut cursus sagittis
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Diam donec mus malesuada
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Et ac vulputate isis
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Faucibus nunc congue urna diam.
											</p>
										</li>
									</ul>
									<ul class="about__wrapper-skills-data-group-element">
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Vel leo proin facilisis metus
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Sit ut cursus sagittis
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Diam donec mus malesuada
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Et ac vulputate isis
											</p>
										</li>
										<li>
											<p class="about__wrapper-data-skills-group-element-text">
												Faucibus nunc congue urna diam.
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<Footer></Footer>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";

export default {
	components: { Header, Footer },
	props: {
		vacancyId: {
			type: String,
		},
	},
	data() {
		return {
			summary: {},
		};
	},
	name: "AboutWorkerPage",
	beforeMount() {
		scroll(0, 0);
		this.summary = {
			id: this.vacancyId,
			name: "Ivan Ibragimov",
			specialization: "Lead Product Design",
			city: "Moscow",
			salary: 124000,
			email: "worker@gmail.com",
			phone: "89877627477",
			birthDate: "12-11-2000",
			aboutText:
				"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
			achievements:
				"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
			skills:
				"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
			schoolName: "Higher School",
			universityName: "Universitet  ",
			avatarUrl:
				"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJDttTAXqG8umpyihxFZe_6pY2Vin1QwZXPA&usqp=CAU",
		};
	},
};
</script>

<style scoped>
/* About */
.about {
	padding-top: 123px;
}

.about__wrapper {
	display: flex;
	padding-bottom: 81px;
}

.about__wrapper-worker-person-representation {
	max-width: 336px;
}

.about__wrapper-worker-person-representation-employer {
	margin-top: 10px;
}

.responses__wrapper-element-description-place {
	text-align: center;
	padding-left: 0;
	padding-top: 19px;
	background-position-y: 21px;
	background-position-x: 21px;
}

.about__wrapper-worker-person-representation-more {
	width: 100%;
	display: flex;
	justify-content: center;
}

.about__wrapper-worker {
	min-width: 310px;
	width: 310px;
}

.about__wrapper-worker-person {
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
}

.about__wrapper-worker-person-representation {
	padding: 20px 22px 25px 22px;
	box-sizing: border-box;
	flex-direction: column;
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.about__wrapper-worker-person-suggestions {
	box-sizing: border-box;
	width: 100%;
	padding: 32px 22px 36px 22px;
}

.about__wrapper-worker-person-representation-icon {
	object-fit: cover;
	object-position: center;
	width: 90px;
	height: 90px;
	margin: 0 auto 20px auto;
	border-radius: 100%;
}

.about__wrapper-worker-person-representation-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
}

.about__wrapper-worker-person-representation-rating-number {
	padding: 5px 2px 5px 25px;
	background: url("~@/assets/img/about-person-page/responses/star.png");
	background-repeat: no-repeat;
	background-position: 9px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.about-rating {
	width: 49px;
	margin: -12px auto 0 auto;
	order: unset;
}

.about__wrapper-worker-person-representation-employer {
	text-align: center;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #3b4c68;
}

.about__wrapper-worker-person-representation-designation {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #b7c1d1;
}

.about__wrapper-worker-person-representation-more {
	margin-top: 46px;
	height: 50px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
	background: #f1f1f5;
	border-radius: 10px;
}

.about__wrapper-worker-person-representation-information {
	justify-content: center;
	align-items: center;
	margin-top: 19px;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #000000;
}

.about__wrapper-worker-person-representation-information-place {
	padding-left: 18px;
	background: url("~@/assets/img/about-person-page/responses/place.png");
	background-repeat: no-repeat;
	background-position-y: 0px;
	border-right: 1px solid #919eb4;
	padding-right: 13px;
}

.about__wrapper-worker-person-representation-information-age {
	padding-left: 16px;
}

.about__wrapper-worker-person-suggestions-work {
	width: 100%;
	height: 45px;
	background: #009ed1;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.about__wrapper-worker-person-suggestions-work:hover {
	opacity: 0.5;
}

.about__wrapper-worker-person-suggestions-test {
	width: 100%;
	box-sizing: border-box;
	margin-top: 10px;
	height: 45px;
	background: #f1f5f9;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #009ed1;
	transition: opacity 1s ease;
}

.about__wrapper-worker-person-suggestions-test:hover {
	opacity: 0.5;
}

.about__wrapper-worker-change {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
}

.about__wrapper-worker-change-btn {
	margin-top: 25px;
	height: 45px;
	background: #f1f1f5;
	border-radius: 8px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.02em;
	color: #696974;
	text-align: center;
	width: 166px;
}

/* right part */

.about__wrapper-data {
	max-width: 786px;
	margin-left: 100px;
}

.about__wrapper-data-info {
	padding-top: 11px;
	padding-bottom: 51px;
}

.about__wrapper-data-info-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.about__wrapper-data-info-text {
	padding-top: 25px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	color: #424242;
}

.about__wrapper-data-info-contacts-title {
	padding-top: 37px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.about__wrapper-data-info-contacts-group {
	margin-top: 25px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.data-text {
	margin-bottom: 9px;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}

.about__wrapper-data-info-contacts-group-element-link {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.5;
	color: #009ed1;
}

.about__wrapper-data-info-contacts-group-element-social {
	display: flex;
}

.data-social {
	margin-bottom: 5px;
}

.about__wrapper-data-info-contacts-group-element-social-logo {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin-right: 9px;
	background: rgba(0, 158, 209, 0.15000000596046448);
}

/* data tipicale block */

.data-standart-block {
	padding: 32px 0;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.about__wrapper-data-education-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.about__wrapper-data-qualification-title {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	color: #009ed1;
}

.about__wrapper-data-experience-title {
	margin-bottom: 32px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.about__wrapper-data-education-degree {
	padding-top: 24px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 14px;
	color: #14c686;
}

.education-text {
	padding-top: 20px;
	font-family: "Roboto";
	font-weight: 300;
	font-size: 12px;
	color: #000000;
}

.education-description {
	padding-top: 4px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.5;
	color: #3b4c68;
}

/* data skills */

.about__wrapper-data-skills-title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.about__wrapper-data-skills-group {
	margin-top: 29px;
	display: flex;
	justify-content: space-between;
}

.skills-left-element {
	margin-right: 10px;
}

.about__wrapper-data-skills-group-element-text {
	padding-left: 45px;
	background: url("~@/assets/img/about-person-page/about/custome-li.jpg");
	background-repeat: no-repeat;
	background-position: 0;
	margin-bottom: 26px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 17px;
	color: #3b4c68;
}

.mobile {
	display: none;
}

@media (max-width: 1044px) {
	.about__wrapper-data {
		margin-left: 50px;
	}

	.about__wrapper-data-info-contacts-group {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 20px;
	}
}

@media (max-width: 950px) {
	.about {
		padding-top: 112px;
	}
}

@media (max-width: 786px) {
	.mobile {
		display: block;
	}

	.about {
		padding-top: 86px;
	}

	.about__wrapper-caption {
		display: flex;
		align-items: center;
	}

	.about__wrapper-caption-back {
		width: 30px;
		height: 10px;
		background: url("~@/assets/img/about-person-page/about/back.svg");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.about__wrapper-caption-text {
		margin-left: auto;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		line-height: 35.5px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.about__wrapper {
		flex-direction: column;
	}

	.about__wrapper-worker {
		margin: 17px auto 0 auto;
	}

	.about__wrapper-data {
		margin-left: 0px;
	}

	.about__wrapper-data {
		text-align: center;
	}

	.about__wrapper-data-info {
		padding-top: 30px;
	}

	.about__wrapper-worker-change {
		display: none;
	}

	.about__wrapper-data-info-contacts-group {
		grid-template-columns: repeat(3, 1fr);
	}

	.about__wrapper-data-info-contacts-group-element-social {
		justify-content: center;
	}
}

@media (max-width: 600px) {
	.about__wrapper {
		padding-bottom: 0;
	}

	.about__wrapper-worker {
		width: 300px;
		min-width: 300px;
	}

	.about__wrapper-data-info {
		padding-bottom: 36px;
	}

	.about__wrapper-worker-person-suggestions {
		display: flex;
		flex-direction: column;
	}

	.about__wrapper-worker-person-suggestions-work {
		width: 232px;
		margin: 0 auto;
		font-size: 13px;
	}

	.about__wrapper-worker-person-suggestions-test {
		width: 232px;
		margin: 10px auto 0px auto;
		font-size: 13px;
	}

	.about__wrapper-data-info-title {
		font-size: 15px;
	}

	.about__wrapper-data-info-text {
		padding-top: 15px;
		font-size: 15px;
	}

	.about__wrapper-data-info-contacts-title {
		font-size: 15px;
	}

	.about__wrapper-data-info-contacts-group {
		grid-template-columns: repeat(1, 1fr);
	}

	.about__wrapper-data-info-contacts-group-element-link {
		font-size: 17px;
	}

	.about__wrapper-data-education-title {
		font-size: 15px;
	}

	.about__wrapper-data-education-degree {
		font-size: 14px;
		padding-top: 16px;
	}

	.education-text {
		padding-top: 16px;
		font-size: 14px;
	}

	.education-description {
		padding-top: 6px;
		font-size: 15px;
		line-height: 1.7;
	}

	.about__wrapper-data-qualification-title {
		font-size: 14px;
	}

	.about__wrapper-data-experience-title {
		font-size: 15px;
		margin-bottom: 16px;
	}

	.about__wrapper-data-skills-title {
		font-size: 15px;
	}

	.about__wrapper-data-skills-group {
		flex-direction: column;
	}

	.about__wrapper-data-skills-group-element-text {
		padding-left: 0;
		margin-bottom: 23px;
	}

	.skills-left-element {
		margin-right: 0;
	}
}

@media (max-width: 400px) {
	.about__wrapper-data {
		text-align: left;
	}

	.about__wrapper-data-info-contacts-group-element-social {
		justify-content: left;
	}

	.about__wrapper-data-skills-group-element-text {
		padding-left: 25px;
	}

	.about__wrapper-worker-person-representation {
		padding: 20px 17px 27px 17px;
		width: 100%;
		box-sizing: border-box;
	}

	.about__wrapper-worker-person-representation-more {
		margin-top: 20px;
	}

	.about__wrapper-worker-person-representation-employer {
		font-size: 24px;
	}

	.about__wrapper-worker-person-representation-designation {
		font-size: 16px;
	}
}

/* /About */

/* Similar */
.similar {
	padding-top: 48px;
	padding-bottom: 87px;
}

.similar__title {
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 20px;
	color: #3b4c68;
}

.similar__vacncy {
	width: 100%;
	box-sizing: border-box;
	margin-top: 48px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 960px) {
	.similar__title {
		text-align: center;
	}

	.similar__vacncy {
		margin-top: 22px;
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.similar__title {
		font-size: 15px;
	}
}

@media (max-width: 400px) {
	.similar {
		padding: 24px 0 65px 0;
	}

	.similar__title {
		text-align: left;
	}

	.similar__vacncy {
		margin-top: 22px;
		display: flex;
		flex-direction: column;
	}
}

/* /Similar */
</style>
