<template>
	<div>
		<Header></Header>
		<div class="wrapper">
			<div class="filling">
				<h1 class="unknown">404</h1>
				<div class="text-wrapper">
					<p class="text">{{ $t('pages.landing.unknownPage.unknownPage.pageNotFound') }}</p>
					<router-link :to="{ name: 'home' }" class="link">{{
						$t('pages.landing.unknownPage.unknownPage.goToMainPage') }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";

export default {
	components: { Header },

	name: "UnknownPage",
};
</script>

<style scoped>
.wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filling {
	max-width: 470px;
}

.unknown {
	font-weight: 700;
	font-size: 200px;
	text-align: center;
	width: 100%;
	color: #000000;
}

.text-wrapper {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
}

.text {}

.link {
	color: #009ED1;
	margin-left: 5px;
}

@media (max-width: 720px) {
	.unknown {
		font-size: 140px;
	}

	.text-wrapper {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
	}
}
</style>
