import {
    successNotification,
    limitWarning,
    errorNotification,
    warningNotification,
    infoNotification
} from './notification'
import {router} from "@/main";


export function errorHandler(payload) {
    if (payload?.name === 'noAccess') {
        warningNotification(this, 'No access');
        return
    }
    switch (payload?.status) {
        case 200:
            if (payload.page === 'sharedPost')
                successNotification(this, 'Successfully');
            if (payload.page === 'linkCopy')
                successNotification(this, 'Link copied');
            if (payload.page === 'postDelete')
                warningNotification(this, 'The post is removed');
            if (payload.page === 'news')
                successNotification(this, 'The news is created');
            if (payload.page === 'interviewDelete')
                warningNotification(this, 'The interview is deleted');
            if (payload.page === 'newMsg')
                successNotification(this, 'Message sent');
            if (payload.page === 'deleteFriend')
                warningNotification(this, 'The user is deleted');
            if (payload.page === 'deleteApplication') {
                if (payload.index)
                    warningNotification(this, 'Application canceled');
                else
                    warningNotification(this, 'You have left the community');

            }
            if (payload.page === 'deleteCom')
                warningNotification(this, 'The community is deleted');

            if (payload.page === 'deleteComment')
                warningNotification(this, 'The comment is deleted');
            if (payload.page === 'createPost')
                successNotification(this, 'The post was created')
            if (payload.page === 'acceptReq')
                successNotification(this, 'The application is accepted')
            if (payload.page === 'cancelReq')
                warningNotification(this, 'The application is rejected')
            if (payload.page === 'createCommunity')
                successNotification(this, 'The community has been created')
            if (payload.page === 'joinCommunity')
                successNotification(this, 'You joined the community')
            if (payload.page === 'reqCommunity')
                successNotification(this, 'The application to the community was sent')
            if (payload.page === 'acceptIncoming')
                successNotification(this, 'The application is accepted')
            if (payload.page === 'addFriend')
                successNotification(this, 'The application for friends was sent')
            if (payload.page === 'doc')
                successNotification(this, 'The data is successfully saved')
            if (payload.page === 'reg')
                successNotification(this, 'Are you registered!Enter your account')
            if (payload.page === 'invite')
                successNotification(this, 'The user is invited to an interview')
            if (payload.page === 'reject')
                successNotification(this, 'The response is successfully rejected');
            if (payload.page === 'interview')
                successNotification(this, 'Successfully passed.The answers are saved');
            if (payload.page === 'restore') {
                successNotification(this, 'The password is successfully updated');
                router.push({name: 'home'})
            }
            if (payload.page === 'userDelete') {
                infoNotification(this, 'The account is deleted');
            }
            if (payload.page === 'help') {
                successNotification(this, 'Your question is sent');
            } 
            if (payload.page === undefined)
                successNotification(this, 'Successfully')
            break;

        case 400:
            if (payload.page === 'restore')
                warningNotification(this, 'Password mismatch');
            if (payload.page === 'reg')
                warningNotification(this, 'An account with such an email or phone number already exists');
            if (payload.page === 'reqCom')
                warningNotification(this, 'Application for consideration');
            if (payload.page === 'closeCom')
                warningNotification(this, 'The community is closed.Apply an application for entry');
            if (payload.page === 'cancelOutgoing')
                warningNotification(this, 'Request canceled ');
            if (payload.page === 'cancelIncoming')
                warningNotification(this, 'The application is rejected')
            if (payload.page === 'emptyFile')
                warningNotification(this, 'Empty file')  
							if (payload.page === 'incorrectFields')
                warningNotification(this, 'Please fill correct out all fields.')
            if (payload.page === 'login')
                warningNotification(this, 'Unimportant login');
            if (payload.page === 'fileSize')
                warningNotification(this, 'File size exceeds the permissible value')
            if (payload.page === 'notAllData')
                warningNotification(this, 'Not all data is filled out')
            if (payload.page === 'emptyMessage')
                warningNotification(this, 'Empty message')
            if (payload.page === 'password')
                warningNotification(this, 'The password does not match')
            if (payload.page === 'invalidField') {
                let str = 'Incorrect fields:'
                for (let i = 0; i < payload.stack.length; i++) {
                    if (payload.stack[i] === 'email')
                        str += '\nEmail';
                    if (payload.stack[i] === 'name')
                        str += '\nFull name'
                    if (payload.stack[i] === 'date')
                        str += '\nBirthday'
                    if (payload.stack[i] === 'passwordReplay')
                        str += '\nIn the password, characters are repeated'
                    if (payload.stack[i] === 'passwordNotMatch')
                        str += '\nPassword mismatch'
                    if (payload.stack[i] === 'companyName')
                        str += '\nCompany name'
                    if (payload.stack[i] === 'phone')
                        str += '\nPhone number'
                }
                warningNotification(this, str);
            }
            if (payload.page === "incorrectPassword")
                warningNotification(this, "Incorrect Password")   
						if (payload.page === "incorrectEmail")
                warningNotification(this, "Incorrect Email")
						if (payload.page === "incorrectPhone")
                warningNotification(this, "Incorrect Phone") 
						if (payload.page === undefined)
                warningNotification(this, "Incorrect data")
            break;
        case
        401
        :
            if (payload.page === 'invalidToken') {
                infoNotification(this, 'Authorization is required')
            }
            if (payload.page === 'interview') {
                infoNotification(this, 'Attention! You have left the testing tab')
            }
            break;
        case
        403:
            if (payload.page === 'invalidToken') {
                infoNotification(this, 'Authorization is required')
                break;
            }
						if (payload.page === 'login') { 
							errorNotification(this, 'Account Banned'); 
							break;
					}
            if (payload.page === 'test')
                limitWarning(this, 'The limit of the number of passages exceeded');
            if (payload.page === 'interview')
                limitWarning(this, 'There is no permission.The interview has already been completed');
            break;
        case
        406
        :
            if (payload.page === 'interview')
                limitWarning(this, 'The interview time has expired');
            if (payload.page === undefined)
                infoNotification(this, 'Authorization is required')
            break;
				case 
				429
				:
						if(payload.page === 'login')
								limitWarning(this, 'Your account is temporarily blocked due to multiple failed login attempts. Please wait 30 minutes.')
						break;
        case
        500
        :
            if (payload.page === 'sharedPost')
                warningNotification(this, 'Post not shared');
            if (payload.page === 'openPost')
                warningNotification(this, 'Failed to open post');
            if (payload.page === 'linkCopy')
                warningNotification(this, 'Failed to copy link');
            if (payload.page === 'doc')
                errorNotification(this, 'Error')
            if (payload.page === 'interview')
                errorNotification(this, 'Your test result is canceled due to repeated violations')
            if (payload.page === undefined)
                errorNotification(this, 'Error')
            if (payload.page === 'video') {
                warningNotification(this, 'Not supported on this device');
            }
            break;
        case 415:
            if (payload.page === 'video') {
                warningNotification(this, 'There are no video camera or microphone');
            }
            break;
        default:
            errorNotification(this, 'Error')
            break;

    }

}