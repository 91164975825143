<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="registration">
				<div class="registration__img">
					<img src="~@/assets/img/registration-page/background-logo.svg" alt=""
						class="registration__img-logo" />
				</div>
				<div class="registration__wrapper">
					<div class="registration__wrapper-authorization">
						<div class="registration__wrapper-authorization-who">
							<p class="registration__wrapper-authorization-text">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.registration') }}
							</p>
							<h1 class="registration__wrapper-authorization-title">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.createAccount') }}
							</h1>
							<div class="registration__wrapper-authorization-who-btns">
								<button class="registration__wrapper-authorization-who-btns-employer">
									{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.employer') }}
								</button>
								<span class="registration__wrapper-authorization-who-btns-border"></span>
								<button class="registration__wrapper-authorization-who-btns-worker">
									{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.jobSeeker') }}
								</button>
							</div>
						</div>
						<div class="registration__wrapper-authorization-fields">
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.companyName') }}
							</p>
							<input type="text" placeholder="LLC Romashka"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.phoneNumber') }}
							</p>
							<input type="tel" placeholder="+7 999 999-99-99"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.email') }}
							</p>
							<input type="email" placeholder="e-mail@website.ru"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.password') }}
							</p>
							<div class="registration__wrapper-authorization-password">
								<input type="password"
									:placeholder="$t('pages.authorization.authorizationEmploerRegistrationBigPage.password')"
									class="registration__wrapper-authorization-password-input" />
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.confirmPassword') }}
							</p>
							<div class="registration__wrapper-authorization-password">
								<input type="password"
									:placeholder=" $t('pages.authorization.authorizationEmploerRegistrationBigPage.password')"
									class="registration__wrapper-authorization-password-input" />
							</div>
						</div>
						<div class="registration__wrapper-authorization-more">
							<p class="registration__wrapper-authorization-designation">{{
								$t('pages.authorization.authorizationEmploerRegistrationBigPage.fullName') }}</p>
							<input type="text" placeholder="Ivanov Ivan Ivanovich"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.jobTitle') }}
							</p>
							<input type="text" placeholder="HR manager"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.organizationDetails')
								}}
							</p>
							<input type="text" placeholder="2341235234235235235235"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.legalAddress') }}
							</p>
							<input type="text" placeholder="Lorem ipsum dolor sit amet"
								class="registration__wrapper-authorization-mail" />
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.actualAddress') }}
							</p>
							<input type="text" placeholder="Lorem ipsum dolor sit amet"
								class="registration__wrapper-authorization-mail" />
							<div class="registration__wrapper-authorization-more-logotype">
								<div class="registration__wrapper-authorization-more-logotype-group">
									<img src="~@/assets/img/registration-page/company-logotipe-img.png" alt=""
										class="registration__wrapper-authorization-more-logotype-img" />
									<p
										class="registration__wrapper-authorization-more-logotype-description-text mobile">
										{{
											$t('pages.authorization.authorizationEmploerRegistrationBigPage.uploadLogoInstruction')
										}}
									</p>
								</div>
								<div class="registration__wrapper-authorization-more-logotype-description">
									<p
										class="registration__wrapper-authorization-more-logotype-description-text desctop">
										{{
											$t('pages.authorization.authorizationEmploerRegistrationBigPage.uploadLogoInstruction')
										}}
									</p>
									<button class="registration__wrapper-authorization-more-logotype-description-edd">
										{{
											$t('pages.authorization.authorizationEmploerRegistrationBigPage.downloadFile')
										}}
									</button>
								</div>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.companyDescription')
								}}
							</p>
							<textarea name="" class="registration__wrapper-authorization-more-textaria">
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </textarea>
							<button class="registration__wrapper-authorization-enter-btn">
								{{ $t('pages.authorization.authorizationEmploerRegistrationBigPage.register') }}
							</button>
							<button class="registration__wrapper-authorization-fields-text">
								{{
									$t('pages.authorization.authorizationEmploerRegistrationBigPage.removeOptionalFields')
								}}
							</button>

							<button class="registration__wrapper-authorization-fields-away mobile">
								{{
									$t('pages.authorization.authorizationEmploerRegistrationBigPage.optionalAtRegistration')
								}}
							</button>
						</div>
					</div>
					<div class="registration__wrapper-help">
						<a href="" class="registration__wrapper-help-registration">{{
							$t('pages.authorization.authorizationEmploerRegistrationBigPage.alreadyHaveAccount') }}</a>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";

export default {
	components: { Header },

	name: "AuthorizationEmploerRegistrationBigPage",
	methods: {
		validateEmail(email) {
			const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return re.test(String(email).toLowerCase());
		},
		validatePhoneNumber(phone) {
			const re = /^\d{10,12}$/;
			return re.test(String(phone));
		},
		validatePassword(password) {
			const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
			return re.test(String(password));
		},
	}
};
</script>


<style scoped>
.registration {
	padding-top: 58px;
	padding-right: 40px;
	display: flex;
	height: calc(100vh);
}

.registration__img {
	display: flex;
	max-width: 733px;
	height: 100%;
	width: 100%;
	background: url("~@/assets/img/registration-page/background.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.registration__img-logo {
	max-width: 328px;
	margin: auto;
	height: 187px;
}

.registration__wrapper {
	padding-top: 61px;
	margin: auto auto auto 144px;
	max-width: 683px;
	width: 100%;
}

.registration__wrapper-authorization {
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 8px;
	width: 100%;
	box-sizing: border-box;
}

.registration__wrapper-authorization-who {
	padding: 27px 63px 37px 63px;
	border-bottom: 2px solid #f6f4f3;
}

.registration__wrapper-authorization-fields {
	padding: 9px 63px 34px 63px;
	border-bottom: 2px solid #f6f4f3;
}

.registration__wrapper-authorization-more {
	padding: 9px 63px 37px 63px;
}

.registration__wrapper-authorization-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.registration__wrapper-authorization-title {
	padding-top: 5px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
	padding-bottom: 16px;
}

.registration__wrapper-authorization-who-btns {
	align-items: center;
	height: 47px;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	border: 2px solid #009ed1;
	border-radius: 10px;
}

.registration__wrapper-authorization-who-btns-employer {
	width: 50%;
	height: 100%;
	border-radius: 5px 0px 0px 5px;
	background: #009ed1;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #fff;
	transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-border {
	display: block;
	content: "";
	height: 100%;
	width: 2px;
	background: #009ed1;
}

.registration__wrapper-authorization-who-btns-worker {
	width: 50%;
	height: 100%;
	border-radius: 0px 10px 10px 0px;
	background: #fff;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #1c283f;
	transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-worker:hover {
	background: #009ed1;
	color: #ffffff;
	border-radius: 0px 5px 5px 0px;
}

.registration__wrapper-authorization-designation {
	margin-top: 21px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.registration__wrapper-authorization-mail {
	margin-top: 8px;
	width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #464655;
	opacity: 0.3;
	box-sizing: border-box;
}

.registration__wrapper-authorization-password {
	margin-top: 8px;
	width: 100%;
	border: none;
	align-items: center;
}

.registration__wrapper-authorization-password-input {
	border: 1px solid #bcbcbc;
	width: 100%;
	box-sizing: border-box;
	padding: 13px 16px;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #464655;
	opacity: 0.3;
}

.registration__wrapper-authorization-more-logotype {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	margin-top: 21px;
	padding: 9px 10px;
	align-items: center;
	background: #fafafb;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
}

.registration__wrapper-authorization-more-logotype-img {
	width: 97px;
	border-radius: 23px;
	border: none;
	height: 97px;
	background: url("~@/assets/img/registration-page/company-logotipe.png");
	background-position: center;
	background-repeat: no-repeat;
}

.registration__wrapper-authorization-more-logotype-description {
	margin-left: 20px;
	max-width: 373px;
}

.registration__wrapper-authorization-more-logotype-description-text {
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
	width: 100%;
}

.registration__wrapper-authorization-more-logotype-description-edd {
	margin-top: 11px;
	width: 100%;
	height: 40px;
	background: #dae2e4;
	border-radius: 7px;
	box-sizing: border-box;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #44494b;
	transition: opacity 1s ease;
}

.registration__wrapper-authorization-more-logotype-description-edd:hover {
	opacity: 0.5;
}

.registration__wrapper-authorization-more-textaria {
	margin-top: 8px;
	width: 100%;
	box-sizing: border-box;
	height: 346px;
	overflow: hidden;
	padding: 15px 15px 25px 15px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 25.5px;
	letter-spacing: 0.25px;
	color: #464655;
	opacity: 0.3;
}

.registration__wrapper-authorization-enter-btn {
	margin-top: 31px;
	width: 100%;
	box-sizing: border-box;
	height: 65px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.registration__wrapper-authorization-enter-btn:hover {
	opacity: 0.5;
}

.registration__wrapper-authorization-fields-text {
	padding-top: 22px;
	width: 100%;
	box-sizing: border-box;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	text-align: center;
	color: #9aafb6;
	transition: opacity 1s ease;
}

.registration__wrapper-authorization-fields-text:hover {
	opacity: 0.5;
}

.registration__wrapper-help {
	margin-top: 43px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.registration__wrapper-help-registration {
	text-decoration: underline;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #009ed1;
}

.mobile {
	display: none;
}

@media (max-width: 1250px) {
	.registration__wrapper {
		margin: auto auto auto 80px;
	}
}

@media (max-width: 1000px) {
	.registration {
		flex-direction: column;
		padding-right: 0;
	}

	.registration__img {
		display: none;
	}

	.registration__wrapper {
		box-sizing: border-box;
		margin: 50px auto auto auto;
		max-width: 683px;
		width: 100%;
		padding: 0px 20px 0px 20px;
	}
}

@media (max-width: 950px) {
	.registration {
		padding-top: 48px;
	}
}

@media (max-width: 600px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.registration__wrapper {
		margin: 28px auto auto auto;
		padding: 0;
	}

	.registration__wrapper-authorization {
		padding: 0;
		border: none;
	}

	.registration__wrapper-authorization-who {
		padding: 0 10px 30px 10px;
	}

	.registration__wrapper-authorization-fields {
		padding: 8px 10px 30px 10px;
	}

	.registration__wrapper-authorization-more {
		padding: 8px 10px 30px 10px;
	}

	.registration__wrapper-authorization-more-logotype-description {
		margin-top: 7px;
		margin-left: 0;
		width: 100%;
		max-width: none;
	}

	.registration__wrapper-authorization-who-btns {
		margin-top: 22px;
		height: 37px;
	}

	.registration__wrapper-authorization-who-btns-employer {
		font-size: 12px;
	}

	.registration__wrapper-authorization-who-btns-worker {
		font-size: 12px;
	}

	.registration__wrapper-authorization-text {
		font-size: 13px;
	}

	.registration__wrapper-authorization-title {
		font-size: 20px;
		padding-bottom: 0;
	}

	.registration__wrapper-authorization-designation {
		margin-top: 14px;
	}

	.registration__wrapper-authorization-mail {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorizationpassword-input {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorization-password-input {
		padding: 12px 18px;
		font-size: 14px;
	}

	.registration__wrapper-authorization-more-logotype {
		flex-direction: column;
	}

	.registration__wrapper-authorization-more-logotype-group {
		display: flex;
		align-items: center;
	}

	.registration__wrapper-authorization-more-logotype-description-text {
		margin-left: 13px;
	}

	.registration__wrapper-authorization-more-logotype-img {
		width: 81px;
		height: 81px;
	}

	.registration__wrapper-authorization-more-textaria {
		font-size: 14px;
		line-height: 1.42;
		height: 193px;
	}

	.registration__wrapper-authorization-fields-text {
		margin-top: 13px;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
		height: 42px;
		padding-top: 0;
		border: 1px solid #009ed1;
		border-radius: 10px;
		font-family: "Roboto";
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		text-align: center;
		color: #009ed1;
	}

	.registration__wrapper-authorization-enter-btn {
		margin-top: 50px;
		height: 42px;
		border-radius: 10px;
		font-size: 13px;
	}

	.registration__wrapper-authorization-fields-away {
		margin-top: 10px;
		width: 100%;
		text-align: center;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: #979797;
		transition: opacity 1s ease;
	}

	.registration__wrapper-authorization-fields-away:hover {
		opacity: 0.5;
	}

	.registration__wrapper-authorization-fields-text {
		font-size: 12px;
	}

	.registration__wrapper-help {
		margin-top: 20px;
	}

	.registration__wrapper-help-registration {
		padding-right: 0px;
		font-size: 13px;
	}
}
</style>
