<template>
	<div class="test__top-bottom-work-container">
		<button ref="select" class="test__top-bottom-work" :class="{
			'disable-btn': status === 'checking' || status === 'approved',
		}" @click="toggleSelect">
			<span>{{
				current ? (current.id ? current.name : current) :
					$t('components.account_worker.selectFormCopy.notChosen')
				}}</span>
		</button>

		<div class="test__top-bottom-work-group" v-if="selectVisible">
			<div v-if="find" class="search__sorting-select-speciality-group-element">
				<input type="text" id="find" style="height: 30px"
					class="test__top-bottom-work-group-element-label search-input" :value="findingStr"
					:placeholder="$t('components.account_worker.selectFormCopy.findPlaceholder')" @input="finding" />
			</div>

			<div class="search__sorting-select-speciality-group-element" v-for="(item, index) in values" :key="item.id">
				<input type="radio" :id="index" class="test__top-bottom-work-group-element-input" :value="item"
					@change="changeSelect(item)" />
				<label :for="index" class="test__top-bottom-work-group-element-label"
					:class="{ select_picked: item === current }">
					{{ item.id ? item.name : item }}
				</label>
			</div>

			<div v-if="findingStr !== null && findingStr !== ''"
				class="search__sorting-select-speciality-group-element">
				<input type="radio" id="not" class="test__top-bottom-work-group-element-input" :value="findingStr"
					@change="changeSelect(findingStr)" />
				<label for="not" class="test__top-bottom-work-group-element-label" :class="{ select_picked: !current }">
					{{ $t('components.account_worker.selectFormCopy.saveYourOption') }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SelectFormCopy",
	props: ["values", "current", "status", "field", "index", "find"],
	data() {
		return {
			selectVisible: false,
			timer: null,
			findingStr: null,
		};
	},
	methods: {
		changeSelect(value) {
			this.selectVisible = !this.selectVisible;
			this.$store.commit("setDocumentsDataSelect", {
				field: this.field,
				index: this.index,
				value,
			});

			this.$store.dispatch("fetchUniversity");
			this.findingStr = null;
			this.$store.dispatch("fetchCompanies");
			this.$emit("changePointSystem");
		},
		blurSelect() {
			setTimeout(() => {
				this.selectVisible = false;
			}, 400);
		},
		finding(e) {
			this.selectVisible = true;
			this.findingStr = e.target.value;
			clearInterval(this.timer);
			this.timer = setInterval(() => {
				if (this.field === "university-name") {
					let universityCountryId = this.getCountryId();
					if (universityCountryId !== null) {
						this.$store.dispatch("fetchUniversity", {
							name: this.findingStr,
							country: universityCountryId,
							callback: () => {
								clearInterval(this.timer);
							},
						});
					} else if (this.selectVisible && this.field !== "university-name") {
						this.$store.dispatch("fetchUniversity", {
							name: this.findingStr,
							callback: () => {
								clearInterval(this.timer);
							},
						});
					}
				} else {
					this.$store.dispatch("fetchCompanies", {
						name: this.findingStr,
						callback: () => {
							clearInterval(this.timer);
						},
					});
				}
			}, 1000);
		},
		getCountryId() {
			if (this.field === "university-name") {
				if (this.index) {
					return this.$store.getters.getDocumentsData.additionUniversity[this.index]?.country?.id;
				}
				return this.$store.getters.getDocumentsData.university.country?.id;
			}
			return null;
		},
		toggleSelect() {
			if (!this.selectVisible) {
				let universityCountryId = this.getCountryId();
				if (universityCountryId !== null) {
					this.$store.dispatch("fetchUniversity", { country: universityCountryId });
				} else if (this.selectVisible && this.field !== "university-name") {
					this.$store.dispatch("fetchUniversity");
				}
			}
			this.selectVisible = !this.selectVisible;
		},
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
};
</script>


<style scoped>
button {
	margin: 0;
}

span {
	margin: 0;
	-webkit-appearance: none;
}

.test__top-bottom-work-container {
	height: 50px;
	position: relative;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 8px;
	margin-top: 14px;
}

.test__top-bottom-work {
	border-radius: 8px;
	text-align: left;
	position: relative;
	padding-left: 17px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: rgba(23, 23, 37, 0.20000000298023224);

	height: inherit;
	flex-shrink: 1;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
	background-repeat: no-repeat;
	background-position: calc(93%);
	background-size: 10px;
	transition: background-color 1s ease;
	box-sizing: border-box;
}

.test__top-bottom-work-group {
	position: absolute;
	width: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	box-shadow: inset -1px -1px 0px #f1f1f5;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #efeff4;
	border-radius: 8px;
	max-height: 260px;
	overflow: auto;
	scrollbar-width: thin;
}

.test__top-bottom-work-group-element-label {
	-webkit-appearance: none;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0.1px;
	text-align: left;
	padding: 9px 0px 9px 17px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 1s ease, box-shadow 1s ease, color 1s ease;
}

.search-input {
	border-radius: 8px 8px 0 0;
	border-top: none;
	cursor: auto;
}

.search__sorting-select-speciality-group-element:not(:last-child) {
	border-bottom: 1px solid #efeff4;
}

.search__sorting-select-speciality-group-element:first-child {
	border-radius: 8px 8px 0 0;
}

.test__top-bottom-work-group-element-label:hover {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

.test__top-bottom-work-group-element-input {
	-webkit-appearance: none;
	height: 0px;
}

.search__sorting-select-speciality-group-element {
	cursor: pointer;
	max-width: 100%;
	width: 100%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
	display: flex;
}

.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.select_picked {
	background: rgba(0, 158, 209, 0.10999999940395355);
	box-shadow: inset -1px -1px 0px #f1f1f5;
	color: #009ed1;
}

@media (max-width: 950px) {
	.test__top-bottom-work {
		background-position: 96%;
	}
}

@media (max-width: 330px) {
	.test__top-bottom-work-container {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}
}

.disable-btn {
	opacity: 0.5;
	pointer-events: none;
}
</style>
