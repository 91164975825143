<template>
  <div>
    <HeaderAccountCompany></HeaderAccountCompany>

    <main class="main">
      <div class="account">
        <MenuCompany></MenuCompany>
        <div class="container-account">
          <div class="recomedation">
            <div class="recomedation__caption">
              <div class="recomedation__caption-wrapper">
                <p class="recomedation__caption-wrapper-text-mobile">
                  {{ $t('pages.account.account_company.accountCompanyVacancyPage.personalCabinet') }}
                </p>
                <h1 class="recomedation__caption-wrapper-title">
                  {{ $t('pages.account.account_company.accountCompanyVacancyPage.myVacancies') }}
                </h1>
                <p class="recomedation__caption-wrapper-text-desctop">
                  {{ $t('pages.account.account_company.accountCompanyVacancyPage.personalCabinet') }}
                </p>
              </div>
              <router-link :to="{ name: 'createVacancy' }" class="recomedation__caption-make">
                {{ $t('pages.account.account_company.accountCompanyVacancyPage.createJob') }}
              </router-link>
            </div>
            <div class="recomedation__wrapper">
              <separate-vacancy @callback="fetchVacancies" v-for="item in dataVacancy" :key="item.id" :vacancy="item"></separate-vacancy>
            </div>
            <div class="recomedation__more">
              <button class="recomedation__more-btn">
                {{ $t('pages.account.account_company.accountCompanyVacancyPage.seeAll') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import SeparateVacancy from "../../../components/account/account_company/account_company_vacancies/item/SeparateVacancy.vue";
import { config } from "@/lib/config";

export default {
  components: { SeparateVacancy, MenuCompany, HeaderAccountCompany },
  data() {
    return {
      dataVacancy: {},
    };
  },
  name: "AccountCompanyVacancyPage",
  beforeMount() {
    scroll(0, 0);
    this.fetchVacancies();
  },
  methods: {
    fetchVacancies() {
      let xmlHttp = new XMLHttpRequest();
      let url = config.apiPrefix + "employer/vacancy/list";
      xmlHttp.open("GET", url, false);
      xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
      try {
        xmlHttp.send();
        let response = JSON.parse(xmlHttp.response);
        this.dataVacancy = response.data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>



<style scoped>
/* Recomendation */
.account {
  display: flex;
}

.recomedation {
  margin-bottom: 40px;
  width: 100%;
  box-sizing: border-box;
}

.recomedation__caption {
  display: flex;
  align-items: center;
}

.recomedation__caption-wrapper-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.recomedation__caption-wrapper-text-desctop {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.recomedation__caption-make {
  margin-left: auto;
  display: block;
  padding: 15px 56px;
  background: #009ed1;
  border-radius: 7px;
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: opacity 1s ease;
}

.recomedation__caption-make:hover {
  opacity: 0.5;
}

.recomedation__wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
}

.response:nth-child(3n - 1) {
  margin: 0 auto;
}

.response:nth-child(3n + 1) {
  margin-right: auto;
}

.response:nth-child(3n) {
  margin-left: auto;
}

.recomedation__more {
  margin-top: 47px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recomedation__more-btn {
  display: none;
  height: 50px;
  width: 25%;
  border: 1px solid #009ed1;
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #009ed1;
  transition: opacity 1s ease;
}

.recomedation__more-btn:hover {
  opacity: 0.5;
}

.mobile {
  display: none;
}

.recomedation__caption-wrapper-text-mobile {
  display: none;
}

@media (max-width: 1715px) {
  .response {
    max-width: 400px;
  }
}

@media (max-width: 1615px) {
  .response {
    max-width: 300px;
  }
}

@media (max-width: 1050px) {
  .recomedation__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .response {
    max-width: 400px;
  }

  .response:nth-child(3n - 1) {
    margin: 0;
  }

  .response:nth-child(3n + 1) {
    margin-right: 0;
  }

  .response:nth-child(3n) {
    margin-left: 0;
  }

  .response:nth-child(2n) {
    margin-left: auto;
  }

  .response:nth-child(2n + 1) {
    margin-right: auto;
  }

  .response {
    max-width: 340px;
  }
}

@media (max-width: 1488px) {
  .mobile {
    display: block;
  }

  .desctop {
    display: none;
  }
}

@media (max-width: 950px) {
  .recomedation {
    margin: 0 auto;
    max-width: 800px;
  }
}

@media (max-width: 805px) {
  .response:nth-child(2n) {
    margin: 0 auto;
  }

  .response:nth-child(2n + 1) {
    margin: 0 auto;
  }

  .response {
    margin: 0 auto;
  }

  .recomedation__more-btn {
    display: block;
  }

  .recomedation__more-btn {
    display: block;
  }

  .recomedation__wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }

  .recomedation__more-btn {
    width: 300px;
  }

  .recomedation__caption {
    display: flex;
    flex-direction: column;
  }

  .recomedation__caption-make {
    margin: 30px auto 0 auto;
    width: 300px;
    box-sizing: border-box;
    text-align: center;
  }

  .recomedation__caption-wrapper-title {
    text-align: center;
  }

  .recomedation__caption-wrapper-text-desctop {
    text-align: center;
  }
}

@media (max-width: 360px) {
  .recomedation__caption-wrapper-title {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    color: #000000;
  }

  .recomedation__caption-wrapper-text-desctop {
    display: none;
  }

  .recomedation__caption-wrapper-text-mobile {
    display: block;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
    text-align: center;
  }

  .recomedation__wrapper {
    margin-top: 30px;
  }
}
</style>
