<template>
	<div class="test-preview">
		<div class="test__number">
			<p class="test__number-element number-desctop">1</p>
		</div>
		<div class="test-preview__wrapper">
			<div class="test-preview__wrapper-filling">
				<div class="test-preview__wrapper-filling-caption-name">
					<h2 class="test-preview__wrapper-filling-caption-name-title">
						{{ $t('components.tests.previewWrapperWatch..title') }}
					</h2>
					<p class="test-preview__wrapper-filling-caption-name-textarea">
						{{ dataPreview.title }}
					</p>
				</div>
				<div class="test-preview__wrapper-filling-caption-description">
					<h3 class="test-preview__wrapper-filling-caption-description-title">
						{{ $t('components.tests.previewWrapperWatch..description') }}
					</h3>
					<p class="test-preview__wrapper-filling-caption-description-textarea">
						{{ dataPreview.description }}
					</p>
				</div>
				<h3 class="test-preview__wrapper-filling-timer-title">
					{{ $t('components.tests.previewWrapperWatch..timer') }}
				</h3>
				<div class="test-preview__wrapper-filling-timer">
					<p>
						{{ $t('components.tests.previewWrapperWatch..timer') }}:
						<span v-if="dataPreview.maxPassingTimeSeconds !== 0">
							{{ dataPreview.maxPassingTimeSeconds }} {{ $t('components.tests.previewWrapperWatch..minutes') }}
						</span>
						<span v-else>{{ $t('components.tests.previewWrapperWatch..none') }}</span>
					</p>
				</div>
			</div>
			<div class="test-preview__wrapper-picture" :style="{ backgroundImage: 'url(' + dataPreview.previewUrl + ')' }">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PreviewWrapper",
	data() {
		return {
			dataPreview: null,
		};
	},
	props: {
		preview: Object,
	},
	beforeMount() {
		this.dataPreview = {
			title: this.preview.title,
			previewUrl: this.preview.previewUrl,
			maxPassingTimeSeconds: this.preview.maxPassingTimeSeconds / 60,
			description: this.preview.description,
		};
	},
};
</script>



<style scoped>
.test__number-element {
	padding: 17px 24px;
	background: #f0f4fa;
	border-radius: 9px;
	color: #a0a9b7;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 21px;
	line-height: 1.28;
}

.test-preview {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	padding: 64px 0 62px 0px;
	display: flex;
}

.number-mobile {
	display: none;
}

.test-preview__wrapper {
	display: flex;
	margin: 0 140px 0 80px;
	background: #ffffff;
	border: 1px solid #e9e9f9;
	border-radius: 19px;
	max-width: 1200px;
	width: 100%;
	box-sizing: border-box;
}

.test-preview__wrapper-filling {
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 35px 75px 38px 34px;
}

.test-preview__wrapper-filling-caption-name-title {
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #3b4c68;
	opacity: 0.5;
}

.test-preview__wrapper-filling-caption-name-textarea {
	margin-top: 10px;
	width: 100%;
	box-sizing: border-box;
	padding: 19px 9px 23px 28px;
	font-family: "Montserrat";
	font-weight: 700;
	min-height: 161px;
	font-size: 31px;
	line-height: 1.28;
	color: #3b4c68;
	border: 1px solid #dae9f3;
	border-radius: 16px;
}

.test-preview__wrapper-filling-caption-description-title {
	font-family: "Roboto";
	padding-top: 20px;
	font-weight: 500;
	font-size: 13px;
	color: #3b4c68;
	opacity: 0.5;
}

.test-preview__wrapper-filling-caption-description-textarea {
	margin-top: 10px;
	width: 100%;
	box-sizing: border-box;
	padding: 15px 24px 25px 28px;
	border: 1px solid #dae9f3;
	border-radius: 16px;
	min-height: 170px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 15px;
	line-height: 1.76;
	color: #424242;
}

.test-preview__wrapper-filling-timer-title {
	margin-top: 27px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	color: #3b4c68;
	opacity: 0.5;
}

.test-preview__wrapper-filling-timer {
	display: flex;
	margin-top: 13px;
	align-items: center;
}

/* Checkbox */
.test-preview__wrapper-filling-timer-choice {
	max-width: 160px;
	width: 100%;
	box-sizing: border-box;
}

.test-preview__wrapper-filling-timer-choice-label:not(:last-child) {
	margin-right: 16px;
}

.test-preview__wrapper-filling-timer-choice-checkbox {
	position: relative;
	display: none;
	z-index: -1;
	opacity: 0;
}

.test-preview__wrapper-filling-timer-choice-checkbox+.test-preview__wrapper-filling-timer-choice-label {
	position: relative;
	cursor: pointer;
}

.test-preview__wrapper-filling-timer-choice-checkbox+.test-preview__wrapper-filling-timer-choice-label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0px;
	width: 16px;
	height: 16px;
	border: 1px solid #009ed1;
	border-radius: 31px;
}

.test-preview__wrapper-filling-timer-choice-checkbox:checked+.test-preview__wrapper-filling-timer-choice-label:before {
	background: #009ed1;
}

.test-preview__wrapper-filling-timer-choice-checkbox+.test-preview__wrapper-filling-timer-choice-label {
	position: relative;
	padding: 0 0 0 25px;
	cursor: pointer;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 15px;
	line-height: 1.76;
	color: #424242;
}

/* Checkbox */

/* Slider */
.test-preview__wrapper-filling-timer-slider {
	position: relative;
	margin-left: 20px;
	max-width: 100%;
	width: 100%;
	margin-top: -6px;
	box-sizing: border-box;
}

.test-preview__wrapper-filling-timer-slider-element {
	box-sizing: border-box;
	-webkit-appearance: none;
	width: 100%;
	height: 4px;
	background: #e6fcf6;

	border-radius: 31px;
	outline: none;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
}

.test-preview__wrapper-filling-timer-slider-element:hover {
	opacity: 1;
}

.test-preview__wrapper-filling-timer-slider-element::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	background: #4caf50;
	cursor: pointer;
}

.test-preview__wrapper-filling-timer-slider-element::-moz-range-thumb {
	background: #ffffff;
	border: 1px solid #f8f8f8;
	box-shadow: 0px 3px 13px rgba(77, 139, 178, 0.5);
	cursor: pointer;
}

/* /Slider */

.test-preview__wrapper-picture {
	border-radius: 0px 19px 19px 0px;
	position: relative;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.test-preview__wrapper-picture-add {
	position: absolute;
	bottom: 33px;
	left: calc(50% - 104px);
	max-width: 208px;
	width: 100%;
}

.test-preview__wrapper-picture-add-btn {
	padding: 19px 0;
	border-radius: 7px;
	background: #009ed1;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	color: #ffffff;
	width: 100%;
	text-align: center;
	transition: opacity 1s ease;
}

.test-preview__wrapper-picture-add-btn:hover {
	opacity: 0.5;
}

@media (max-width: 1740px) {
	.test-preview__wrapper {
		margin: 0 100px 0 40px;
	}
}

@media (max-width: 950px) {
	.test-preview {
		flex-direction: column;
		padding: 27px 0 30px 0;
	}

	.test-preview__wrapper {
		margin: 0 auto;
		order: 2;
		border: none;
		flex-wrap: wrap;
	}

	.number-desctop {
		display: none;
	}

	.number-mobile {
		display: block;
	}

	.test-preview__wrapper-filling {
		order: 2;
		background: #fafafb;
		padding: 17px 0 0 0;
	}

	.test-preview__wrapper-picture {
		min-height: 250px;
		border: 1px solid #e9e9f9;
		border-radius: 19px;
	}

	.test-preview__wrapper-picture-add-btn {
		font-size: 13px;
		padding: 13px 35px;
	}

	.test-preview__wrapper-filling-caption-name-textarea {
		font-size: 17px;
		line-height: 1.48;
		padding: 12px 15px 15px 15px;
		min-height: 100px;
	}

	.test-preview__wrapper-filling-caption-description-textarea {
		font-size: 13px;
		line-height: 1.75;
		padding: 11px 16px 18px 17px;
		min-height: 164px;
	}
}

@media (max-width: 500px) {
	.test-preview__wrapper-filling-timer {
		flex-direction: column;
		align-items: unset;
	}

	.test-preview__wrapper-filling-timer-slider {
		margin-top: 30px;
	}
}
</style>