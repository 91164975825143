import { render, staticRenderFns } from "./AccountCompanyVacancyPage.vue?vue&type=template&id=527a5656&scoped=true"
import script from "./AccountCompanyVacancyPage.vue?vue&type=script&lang=js"
export * from "./AccountCompanyVacancyPage.vue?vue&type=script&lang=js"
import style0 from "./AccountCompanyVacancyPage.vue?vue&type=style&index=0&id=527a5656&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527a5656",
  null
  
)

export default component.exports