<template>
  <div>
    <big-photo v-if="bigView" @closeBigPhoto="bigView = false" :url="bigUrl"></big-photo>
    <SocialHeader :role="$store.getters.role"></SocialHeader>
    <main class="main">
      <div class="account">
        <SocialMenu :role="$store.getters.role"></SocialMenu>
        <div class="chat">
          <div class="chat__caption" v-if="!currentDialogue || desctop">
            <div class="chat__caption-head">
              <div class="`chat`__caption-head-greetings">
                <p class="chat__caption-head-greetings-text mobile">
                  {{ $t('pages.social.socialChatPage.personalCabinet') }}
                </p>
                <h1 class="chat__caption-head-greetings-title">{{ $t('pages.social.socialChatPage.chat') }}</h1>
                <p class="chat__caption-head-greetings-text desctop-rating">
                  {{ $t('pages.social.socialChatPage.welcome') }}
                </p>
              </div>
            </div>
          </div>
          <div class="chat__wrapper">
            <div class="chat__wrapper-contacts" v-if="!currentDialogue || desctop">
              <div class="chat__wrapper-contacts-caption">
                <div class="contacts-caption__interactment" @click="pickChat = !pickChat">
                  <p class="chat__wrapper-contacts-caption-messages">
                    {{
                      currentRole === 'admin' ? $t('pages.social.socialChatPage.administrator') :
                        currentRole === 'candidate' ? $t('pages.social.socialChatPage.jobSeeker') :
                          currentRole === 'employer' ? $t('pages.social.socialChatPage.employer') :
                            currentRole === 'all' ? $t('pages.social.socialChatPage.all') :
                              $t('pages.social.socialChatPage.noData')
                    }}
                  </p>
                  <button class="chat__wrapper-contacts-caption-more"></button>
                </div>
                <div class="test__top-bottom-work-group" v-if="pickChat">
                  <div class="search__sorting-select-speciality-group-element">
                    <button @click="changeRole('admin')" class="test__top-bottom-work-group-element-label first-select"
                      :class="{ 'select_picked': currentRole === 'admin' }">
                      {{ $t('pages.social.socialChatPage.administrator') }}
                    </button>
                    <button @click="changeRole('all')" class="test__top-bottom-work-group-element-label first-select"
                      :class="{ 'select_picked': currentRole === 'all' }">
                      {{ $t('pages.social.socialChatPage.all') }}
                    </button>
                    <button @click="changeRole('candidate')" class="test__top-bottom-work-group-element-label"
                      :class="{ 'select_picked': currentRole === 'candidate' }">
                      {{ $t('pages.social.socialChatPage.jobSeeker') }}
                    </button>
                    <button @click="changeRole('employer')"
                      class="test__top-bottom-work-group-element-label last-select"
                      :class="{ 'select_picked': currentRole === 'employer' }">
                      {{ $t('pages.social.socialChatPage.employer') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="chat__wrapper-contacts-people" id="dialogs">
                <SeparateWorkerChatPersone :ref="`dialogues`" v-for="(item) in allDialogues" :key="item.id"
                  :dialogue="item" :role="currentRole" @goToChat="goToChat(item)">
                </SeparateWorkerChatPersone>
              </div>
            </div>
            <div class="chat__wrapper-dialogue-contaner">
              <SeparateWorkerChatDialogue @bigPicture="bigPicture" ref="dialogue" v-if="currentDialogue"
                :role="currentRole" @allDialoguesReload="reloadTimer" @stopTimer="stopTimer">
              </SeparateWorkerChatDialogue>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SocialMenu from "../../components/social/SocialMenu";
import SocialHeader from "../../components/social/SocialHeader.vue";
import SeparateWorkerChatDialogue
  from "../../components/account/account_worker/account_worker_chat/item/SeparateWorkerChatDialogue.vue";
import SeparateWorkerChatPersone
  from "../../components/account/account_worker/account_worker_chat/item/SeparateWorkerChatPersone.vue";
import Cookies from "js-cookie";
import bigPhoto from "@/components/social/account_communitis_main/BigPhoto";

export default {
  components: {
    SocialMenu, SocialHeader,
    SeparateWorkerChatDialogue,
    SeparateWorkerChatPersone,
    bigPhoto
  },
  props: ['needHelp'],
  computed: {
    currentDialogue() {
      return this.$store.getters.currentDialogue;
    },
    currentRole() {
      return this.$store.getters.currentRole;
    },
    allDialogues() {
      return this.$store.getters.allDialogues;
    }
  },
  name: "SocialChatPage",
  beforeMount() {
    this.$store.commit('resetPageDialogues');
    this.reloadTimer();
    this.getOneDialogue();
    if (window.matchMedia("(max-width: 900px)").matches) {
      this.desctop = false;
    }
    this.firstLoad = false;
    if (this.$route.query.needHelp) {
      this.changeRole('admin');
    }
  },
  beforeDestroy() {
    this.$store.commit('clearDialogue');
  },
  data() {
    return {
      desctop: true,
      pickChat: false,
      timerId: null,
      oneDialogue: false,
      firstLoad: true,
      bigUrl: null,
      bigView: false,

    }
  },
  methods: {
    bigPicture(item) {
      console.log('test')
      this.bigUrl = item;
      this.bigView = true;
    },
    goToChat(item) {
      this.stopTimer();
      if (item)
        item.read = true;
      this.userName = Cookies.get('currentDialogueName');
      this.userAvatar = Cookies.get('currentDialogueAvatar');
      this.userId = Cookies.get('currentDialogueId');
      this.userRole = Cookies.get('currentUserRole');
      this.$store.commit('updateUserData')
    },
    pagination() {
      if (this.currentRole !== 'admin') {
        this.$store.commit('upPageMessages');
        this.$store.dispatch('fetchAllDialoguesWithUser');
      }
    },
    getOneDialogue() {
      let role = Cookies.get('currentDialogueRole');
      if (role)
        this.$store.commit('setCurrentRole', role);
      if (Cookies.get('postReport') && role === 'admin') {
        setTimeout(() => {
          this.$refs.dialogues[0]?.loadDialogue();
          setTimeout(() => {
            this.$refs.dialogue?.setNewMessage(Cookies.get('postReport'));
            Cookies.remove('postReport');
          }, 100)
        }, 100)
      } else
        role = 'all';
      const id = Cookies.get('currentDialogueId');
      if (id) {
        this.oneDialogue = true;
        this.$store.commit('resetPageMessages');
        this.$store.commit('updateUserData');
        this.$store.dispatch('fetchAllMessageWithUser', { id: id });
        this.stopTimer()
      }
      this.changeRole(role);
      this.oneDialogue = false;

    },
    changeRole(role) {
      if (!this.oneDialogue)
        this.$store.commit('clearDialogue');
      this.$store.commit('resetPageDialogues');
      this.$store.commit('setCurrentRole', role);
      this.$store.commit('setAllDialogues', null);
      if (!this.firstLoad)
        this.addPagination();
      Cookies.set('currentDialogueRole', role);
      if (role === 'admin') {
        this.$store.dispatch('fetchAllDialoguesWithAdmin');
      } else {
        this.$store.dispatch('fetchAllDialoguesWithUser');
      }
      this.pickChat = false;
      this.stopTimer();
      this.reloadTimer();
    },
    reloadTimer() {
      clearInterval(this.timerId);
      if (this.currentRole === 'admin')
        this.$store.dispatch('fetchAllDialoguesWithAdmin');
      else this.$store.dispatch('fetchAllNewDialogues');
    },
    stopTimer() {
      clearInterval(this.timerId);
    },
    deletePagination() {
      document.getElementById('dialogs').onscroll = null;
    },
    addPagination() {
      const el = document.getElementById('dialogs');
      if (this.currentRole !== 'admin')
        el.onscroll = () => {
          if (el.scrollHeight === el.scrollTop + el.clientHeight) {
            this.$store.commit('upPageDialogues');
            this.$store.dispatch('fetchAllDialoguesWithUser', this.deletePagination)
          }
        }
    }
  },
  mounted() {
    this.addPagination();
  },
  destroyed() {
    this.stopTimer();
    Cookies.remove('currentDialogueRole');
  }
};
</script>



<style scoped>
.account {
  display: flex;
}

.chat {
  padding-top: 96px;
  width: 100%;
  box-sizing: border-box;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.mobile {
  display: none;
}

.chat__caption {
  padding-left: 61px;
  max-width: 545px;
  width: 100%;
}

.chat__caption-head {
  display: flex;
  align-items: center;
}

.chat__caption-head-greetings {
  margin: auto 0;
}

.chat__caption-head-greetings-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.chat__caption-head-greetings-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.chat__caption-head-surch {
  margin-left: auto;
  position: relative;
}

.chat__caption-head-surch-entry {
  height: 55px;
  margin: auto 0;
  width: 307px;
  padding-left: 33px;
  padding-right: 58px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #171725;
}

.chat__caption-head-surch-entry::placeholder {
  color: #b7c1d1;
}

.chat__caption-head-surch-find {
  top: 9px;
  position: absolute;
  display: inline-block;
  right: 10px;
  transition: opacity 1s ease;
  padding: 12px;
  line-height: 0;
  background: linear-gradient(192.41deg, #4099ff 0%, #a1a8ff 138.89%);
  border-radius: 100%;
}

.chat__caption-head-surch-find-img {
  object-fit: cover;
  width: 12px;
  height: 12px;
}

.chat__caption-head-surch-find:hover {
  opacity: 0.5;
}

.chat__wrapper {
  margin-top: 26px;
  width: 100%;
  max-width: 100%;
  border-top: 1px solid rgba(182, 210, 255, 0.5);
  display: flex;
  height: 100%;
  overflow: hidden;
}

.chat__wrapper-contacts {
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid rgba(182, 210, 255, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat__wrapper-contacts-caption {
  position: relative;
  width: 100%;
  padding: 18px 24px 18px 61px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(182, 210, 255, 0.5);
  display: flex;
  align-items: center;
  z-index: 100;
}

.contacts-caption__interactment {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chat__wrapper-contacts-caption-messages {
  max-width: 132px;
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}

.chat__wrapper-contacts-caption-more {
  margin-left: 33px;
  width: 16px;
  height: 10px;
  flex-shrink: 0;
  background: url("~@/assets/img/account-img/chat/more-chat.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1s ease;
}


.chat__wrapper-contacts-caption-tabs {
  margin-left: auto;
  background: url("~@/assets/img/account-img/chat/tabs.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1s ease;
}

.chat__wrapper-contacts-caption-tabs:hover {
  opacity: 0.5;
}

.chat__wrapper-contacts-people {
  display: flex;
  flex-direction: column;
  height: 100%;
  scrollbar-width: thin;
  overflow: auto;
  z-index: 10;
}

.chat__wrapper-contacts-people::-webkit-scrollbar {
  width: 0px;

  height: px;

  background-color: #f6f8fa;
  border-radius: 0px 8px 8px 0;
}

.chat__wrapper-contacts-people::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border-radius: 10px;
}

.chat__wrapper-contacts-people-separate {
  cursor: pointer;
  padding: 24px 24px 24px 61px;
  display: flex;
  align-items: center;
  transition: background 1s ease;
}

.chat__wrapper-contacts-people-separate:hover {
  background: #e5f7fd;
}

.chat__wrapper-contacts-people-separate-avatar {
  background: url("~@/assets/img/account-img/chat/avatar.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  position: relative;
}

.chat__wrapper-contacts-people-separate-avatar-notification {
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  bottom: 1px;
  right: 0;
}

.notification-online {
  width: 9px;
  height: 9px;
  background: #14c686;
}

.chat__wrapper-dialogue-contaner {
  width: 100%;
}

.mobile-chat {
  display: none;
}

@media (max-width: 1780px) {
  .chat__wrapper-contacts {
    max-width: 454px;
    flex-shrink: 0;
  }

  .chat__wrapper-dialogue {
    flex-shrink: 1;
  }
}

@media (max-width: 1780px) {
  .chat__wrapper-dialogue-messages-user {
    max-width: 270px;
  }

  .chat__wrapper-dialogue-messages-interlocutor {
    max-width: 270px;
  }
}

@media (max-width: 950px) {
  .chat {
    padding-top: 68px;
  }

  .chat__caption {
    box-sizing: border-box;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 20px;
  }

  .chat__caption-head-greetings-title {
    font-size: 20px;
  }

  .chat__caption-head-greetings-text {
    font-size: 13px;
  }

  .chat__caption-head-surch {
    margin-left: auto;
  }

  .chat__caption-head-surch-entry {
    font-size: 15px;
    padding-left: 27px;
  }

  .chat__wrapper-contacts-caption {
    width: 100%;
    padding: 18px 24px 18px 24px;
  }

  .chat__wrapper-contacts-people-separate {
    padding: 24px 24px 24px 25px;
  }
}

@media (max-width: 900px) {
  main {
    height: 100%;
    overflow: hidden;
  }

  .chat {
    padding-top: 47px;
  }

  .chat__wrapper-dialogue-caption {
    padding: 18px 24px 18px 24px;
  }

  .chat__wrapper-contacts {
    max-width: 100%;
  }

  .chat__wrapper-dialogue-contaner {
    height: 100%;
  }

  .chat__caption-head {
    display: none;
  }

  .chat__wrapper {
    margin-top: 0;
    position: sticky;
    top: 0;
  }

  .chat__wrapper-contacts-people {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .chat__caption-head {
    flex-direction: column;
  }

  .chat__caption-head-surch {
    margin-top: 15px;
    max-width: 300px;
    margin-left: 0;
  }

  .chat__caption-head-greetings {
    text-align: center;
  }

  .chat__wrapper-contacts-caption {
    padding: 15px 10px 15px 10px;
  }

}

@media (max-width: 700px) {
  .chat__caption {
    flex-direction: column;
  }

  .chat__caption {
    padding-left: 10px;
    padding-right: 10px;
  }

  .chat__caption-head-surch {
    max-width: 300px;
    width: 100%;
  }

  .chat__caption-head-surch-entry {
    width: 100%;
  }

  .chat__wrapper-contacts-people-separate {
    padding: 15px 10px;
  }

  .chat__wrapper-contacts-people-separate-filling {
    max-width: 200px;
  }

  .chat__wrapper-contacts-people-separate-filling-message {
    max-height: 16px;
    overflow: hidden;
  }
}

.search__sorting-select-speciality-group-element {
  background: white;
  cursor: pointer;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: black;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
  background: white;
  cursor: pointer;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: black;
  box-sizing: border-box;
  transition: background-color 1s ease, color 1s ease;
  border-radius: 8px;
}

.first-select {
  border-radius: 8px 8px 0 0;
}

.last-select {
  border-radius: 0 0 8px 8px;
}


.test__top-bottom-work-group {
  border: 1px solid #bcbcbc;
  position: absolute;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 49px;
  left: -1px;
  z-index: 1000;
  box-shadow: inset -1px -1px 0px #f1f1f5;
  box-sizing: border-box;
  width: 300px;
  border-radius: 8px;
}


.test__top-bottom-work-group-element-label {
  -webkit-appearance: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 0px 9px 32px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 1s ease, box-shadow 1s ease, color 1s ease;
}

.select_picked {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.test__top-bottom-work-group-element-label:hover {
  background: rgba(0, 158, 209, 0.10999999940395355);
  box-shadow: inset -1px -1px 0px #f1f1f5;
  color: #009ed1;
}

.search__sorting-select-speciality-group-element:hover {
  background-color: #eefbff;
  color: #009ed1;
}
</style>
