<template>
	<div class="message">
		<img src="@/assets/img/test-data/admin-face.png" alt="" class="face">
		<div class="message__content">
			<p class="message__name">{{ $t('components.general.support.adminMessage.adminName') }}</p>
			<div class="message__filling">
				<pre class="message__text" v-if="!isUrl(message)">{{ message }}</pre>
				<a v-else :href="message">
					<pre class="message__text">{{ $t('components.general.support.adminMessage.clickUrl') }}</pre>
				</a>
				<p class="time">{{ time }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AdminMessage",
	props: {
		message: String,
		time: String
	},
	methods: {
		isUrl(str) {
			const pattern = /^(https?:\/\/)/i; 
			return pattern.test(str);
		}
	}
}
</script>


<style scoped>
.message {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	max-width: 100%;
}

.face {
	width: 44px;
	height: 44px;
	background: #ffffff;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
	display: flex;
	flex-shrink: 0;
}

.message__name {
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	color: #595959;
}

.message__filling {
	margin-top: 6px;
}

.message__text {
	background: #ffffff;
	border-radius: 15px;
	padding: 19px 16px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
}

a .message__text {
	text-decoration: underline;
	
}

.time {
	margin-top: 4px;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	color: rgba(115, 115, 115, 0.5);
}

@media (max-width: 850px) {
	.message {
		gap: 7px;
	}

	.face {
		width: 30px;
		height: 30px;
	}

	.message__name {
		font-size: 13px;
		line-height: 1.2;
	}

	.message__filling {
		margin-top: 4px;
	}

	.message__text {
		border-radius: 10px;
		padding: 12px;
		font-size: 14px;
		line-height: 1.2;
	}

	.time {
		margin-top: 2px;
		font-size: 9px;
		line-height: 1.2;
	}
}
</style>