<template>
	<div class="wrapper">
		<div v-if="questionType === 'single'" class="radioBtn">
			<label class="selection" :class="{ 'selct-label': item.id === choose }" v-for="item in question.answers"
				:key="item.id">
				{{ item.body }}
				<input type="radio" v-model="pickAnswer" :value="item.id" class="selection__input"
					v-on:change="pickChoose" />
				<span class="selection__checkbox" :class="{ 'selct-checkbox': item.id === choose }"
					:style="{ borderRadius: '' + 100 + '' + '%' }"></span>
			</label>
		</div>

		<div v-if="questionType === 'open'" class="checkbox">
			<textarea class="wrapper__textarea" :placeholder="$t('pages.interview.interviewInputCircle.enterAnswer')"
				v-model="pickAnswer" v-on:change="pickChoose"></textarea>
		</div>

		<div v-if="questionType === 'multi'" class="checkbox">
			<label class="selection" :class="{ 'selct-label': checkCheckbox(item.id) }" v-for="item in question.answers"
				:key="item.id">
				{{ item.body }}
				<input type="checkbox" v-model="pickAnswer" :value="item.id" class="selection__input"
					v-on:change="pickChoose" />
				<span class="selection__checkbox" :class="{ 'selct-checkbox': checkCheckbox(item.id) }"></span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		question: {},
		questionType: {},
		choose: {}
	},
	data() {
		return {
			pickAnswer: [],
		};
	},
	name: "InterviewInputCircle",
	methods: {
		pickChoose() {
			if (this.pickAnswer !== null) {
				this.$store.commit("setChooseInterview", this.pickAnswer);
			} else this.$store.commit("setChooseInterview", null);
		},
		checkCheckbox(id) {
			if (this.choose)
				return this.choose.indexOf(id) !== -1;
		}
	}
};
</script>


<style scoped>
.selection {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #f5f8f9;
	border: 1px solid #dae9f3;
	border-radius: 16px;
	padding: 20px 40px 20px 90px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	color: #3b4c68;
	transition: background 1s ease, border 1s ease;
	line-height: 1.67;
}

.selection:hover {
	background: #eef8ff;
	border: 1px solid #009ed1;
}

.selection:not(:last-child) {
	margin-bottom: 18px;
}

.selection__input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.selection__checkbox {
	position: absolute;
	border: 2px solid rgba(59, 76, 104, 0.5);
	border-radius: 6px;
	/* border-radius:100%; Ring */
	top: calc(50% - 12px);
	left: 33px;
	height: 21px;
	width: 21px;
	background-color: #fff;
}

.selection__input~.selection__checkbox {
	transition: background 1s ease, border 1s ease;
}

.selection__input:hover~.selection__checkbox {
	background: #009ed1;
	border: 2px solid #009ed1;
}

.selection__checkbox::before {
	content: "";
	position: absolute;
	display: none;
}

.selection__input:checked~.selection__checkbox::before {
	display: block;
}

.wrapper__textarea {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.78;
	background: #ffffff;
	border: 1px solid #dae9f3;
	border-radius: 16px;
	color: #3b4c68;
	padding: 15px 30px;
}

@media (max-width: 700px) {
	.selection:not(:last-child) {
		margin-bottom: 12px;
	}

	.selection {
		padding: 10px 18px 10px 43px;
		font-size: 11px;
	}

	.selection__checkbox {
		left: 14px;
		width: 12px;
		height: 12px;
		top: calc(50% - 8px);
	}

	.wrapper__textarea {
		padding: 10px 16px;
		font-size: 11px;
		background: inherit;
	}
}

/* Modificators */
.selct-checkbox {
	background: #009ed1;
	border: 2px solid #009ed1;
}

.selct-label {
	background: #eef9ff;
	border: 1px solid #009ed1;
}

/* /Modificators */
</style>