<template>
	<router-link :to="{
		name: 'separateResponse',
		params: { responseId: response.responseId },
	}">
		<div class="response">
			<div class="response__caption">
				<div class="response__caption-wrapper">
					<img :src="response.candidateAvatarUrl" alt="" class="response__caption-face" />
					<div class="response__caption-wrapper-description">
						<div class="response__caption-wrapper-raing">
							<p class="response__caption-wrapper-raing-number">
								{{ response.candidateRate }}
							</p>
						</div>
						<p class="response__caption-wrapper-name">
							{{ response.candidateName }}
						</p>
					</div>
				</div>
				<div class="response__caption-status">
					<p class="response__caption-status-text" :class="{
						'status-text-refusal': this.response.responseStatus === 'reject',
						'status-text-application':
							this.response.responseStatus === 'invite',
					}">
						{{ status }}
					</p>
					<p class="response__caption-status-date">
						{{ new Date(response.responseDate).toDateString() }}
					</p>
				</div>
			</div>
			<div class="response__filling">
				<div class="response__filling-profession">
					<p class="response__filling-profession-name">
						{{ response.candidateSpecialization }}
					</p>
					<p class="response__filling-profession-descripton">
						{{
							response.candidateAboutText
								? response.candidateAboutText.substring(
									0,
									DESCRIPTION_SYMBOL_LIMIT
								)
								: $t('components.account_company.response.noDescription')
						}}...
					</p>
				</div>
				<div class="response__filling-place">
					<div class="response__filling-place-name">
						{{
							response.candidateCity ? response.candidateCity :
								$t('components.account_company.response.undefined')
						}}
					</div>
				</div>
				<div class="response__filling-salary">
					<p class="response__filling-salary-name">{{ $t('components.account_company.response.expectedSalary')
						}} :</p>
					<p class="response__filling-salary-price">
						{{
							response.candidateSalary && response.candidateSalary > 0
								? response.candidateSalary
								: $t('components.account_company.response.undefined')
						}}
						$
					</p>
				</div>
			</div>

		</div>
	</router-link>
</template>

<script>
export default {
	name: "Response",
	props: {
		response: {},
	},
	data() {
		return {
			DESCRIPTION_SYMBOL_LIMIT: 123,
			status: null,
		};
	},
	beforeMount() {
		switch (this.response.responseStatus) {
			case "new":
				this.status = this.$t('components.account_company.response.pendingVerification');
				break;
			case "reject":
				this.status = this.$t('components.account_company.response.denied');
				break;
			case "invite":
				this.status = this.$t('components.account_company.response.invitedForInterview');
				break;
		}
	},
};
</script>


<style scoped>
.response {
	max-width: 420px;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	background: #ffffff;
	border: 1px solid #efeff4;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.response__caption {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	align-items: center;
}

.response__caption-face {
	border-radius: 10px;
	height: 64px;
	width: 64px;
}

.response__caption-wrapper {
	align-items: center;
	display: flex;
}

.response__caption-wrapper-description {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
}

.response__caption-wrapper-raing {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	max-width: 54px;
	width: 100%;
}

.response__caption-wrapper-raing-number {
	padding: 0 10px 0 22px;
	background: url("~@/assets/img/all-img/star-green.svg");
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-repeat: no-repeat;
	background-position: 8px;
	background-position-y: center;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
	background-position-y: 6px;
}

.response__caption-wrapper-name {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.response__caption-status {
	margin-left: auto;
}

.response__caption-status-text {
	text-align: center;
	font-family: "Roboto";
	padding: 0 10px;
	background: rgba(204, 210, 229, 0.20000000298023224);
	border-radius: 20px;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #979eb8;
}

.response__caption-status-date {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	text-align: right;
	color: #686873;
	opacity: 0.4;
}

.response__filling {
	margin-top: 17px;
}

.response__filling-profession-name {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #171625;
}

.response__filling-profession-descripton {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.response__filling-place {
	margin-top: 15px;
}

.response__filling-place-name {
	padding-left: 20px;
	background: url("~@/assets/img/all-img/place.svg");
	background-position-y: 3px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.response__filling-salary {
	padding-bottom: 13px;
	margin-top: 15px;
}

.response__filling-salary-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.response__filling-salary-price {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.response__interactions {
	border-top: 1px solid #f1f1f5;
	padding-top: 13px;
}

.response__interactions-feedback {
	width: 100%;
	box-sizing: border-box;
	display: flex;
}

.response__interactions-feedback-invite {
	text-align: center;
	max-width: 100%;
	width: 100%;
	padding: 13px 0;
	margin-right: 20px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 21px;
	color: #009ed1;
	border: 1px solid #009ed1;
	border-radius: 10px;
	transition: opacity 1s ease;
}

.response__interactions-feedback-invite:hover {
	opacity: 0.5;
}

.response__interactions-feedback-reject {
	text-align: center;
	max-width: 100%;
	width: 100%;
	padding: 13px 0;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 21px;
	color: #696974;
	background: #f1f1f5;
	border-radius: 10px;
	transition: opacity 1s ease;
}

.response__interactions-feedback-reject:hover {
	opacity: 0.5;
}

.response__interactions-viewing {
	display: flex;
	margin-top: 8px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
}

.response__interactions-viewing-link {
	text-align: center;
	padding: 13px 0;
	background: #e6f6fc;
	border-radius: 10px;
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 21px;
	color: #009ed1;
	transition: opacity 1s ease;
}

.response__interactions-viewing-link:hover {
	opacity: 0.5;
}

@media (max-width: 1650px) {
	.response {
		margin: 0 auto;
	}
}

@media (max-width: 700px) {
	.response {
		max-width: 300px;
	}

	.response__caption {
		align-items: baseline;
		flex-direction: column;
	}

	.response__caption-wrapper {
		margin-top: 20px;
		order: 2;
	}

	.response__caption-status {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		margin-left: 0;
	}

	.response__caption-status-date {
		margin-left: auto;
	}
}

@media (max-width: 400px) {
	.response__interactions-feedback-invite {
		margin-right: 10px;
	}

	.response {
		max-width: 100%;
		box-sizing: border-box;
	}
}

/* Modificators */
.status-text-refusal {
	color: #cf4e5d;
	background: rgba(246, 161, 181, 0.20000000298023224);
}

.status-text-application {
	color: #44c194;
	background: rgba(184, 242, 193, 0.20000000298023224);
}

/* /Modificators */
</style>