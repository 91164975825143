var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test__group-element"},[_c('div',{staticClass:"test__group-element-caption",style:({ backgroundImage: 'url(' + _vm.interview.previewUrl + ')' })},[_c('div',{staticClass:"test__group-element-caption-rating-container"},[_c('div',{staticClass:"test__group-element-caption-rating"},[_c('div',{staticClass:"test__group-element-caption-rating-number-container"},[_c('p',{staticClass:"test__group-element-caption-rating-number"},[_vm._v(" "+_vm._s(_vm.interview.companyRate ? _vm.interview.companyRate : 0)+" ")])])])])]),_c('div',{staticClass:"test__group-element-description"},[_c('div',{staticClass:"test__group-element-description-company"},[_c('img',{staticClass:"test__group-element-description-company-logo",attrs:{"src":_vm.interview.companyAvatarUrl,"alt":""}}),_c('p',{staticClass:"test__group-element-description-company-name"},[_vm._v(" "+_vm._s(_vm.limit(_vm.interview.companyName, 15))+" ")])]),_c('h2',{staticClass:"test__group-element-description-title"},[_vm._v(" "+_vm._s(_vm.limit(_vm.interview.title, 23))+" ")]),_c('p',{staticClass:"test__group-element-description-text"},[_vm._v(" "+_vm._s(_vm.limit(_vm.interview.description, 78))+" ")])]),_c('div',{staticClass:"test__group-element-end"},[_c('div',{staticClass:"test__group-element-opportunities"},[_c('router-link',{staticClass:"test__group-element-opportunities-preview",attrs:{"to":{
				name: 'interviewConstructPreview',
				params: { interviewId: this.interview.id },
			}}},[_vm._v(" "+_vm._s(_vm.$t('components.tests.separateInterview.preview'))+" ")]),_c('router-link',{staticClass:"test__group-element-opportunities-edit",attrs:{"to":{
				name: 'interviewConstruct',
				params: { interviewId: this.interview.id },
			}}},[_vm._v(" "+_vm._s(_vm.$t('components.tests.separateInterview.edit'))+" ")])],1),_c('router-link',{staticClass:"test__group-element-results",attrs:{"to":{
			name: 'resultInterview',
			params: { interviewId: this.interview.id },
		}}},[_vm._v(" "+_vm._s(_vm.$t('components.tests.separateInterview.watchResults'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }