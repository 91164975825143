<template>
  <div class="table-wrapper">
    <div class="table" border="1px" v-for="(item, index) in values" :key="item.id">
      <div class="table-row" v-if="!item.delete">
        <div class="table-column">
          <div class="document-input-wrapper">
            <h3 class="document__subtitlte mt-0">{{ $t('components.account_worker.socialsTableCopy.link') }}</h3>
            <input type="text" :value="item.url"
              :placeholder="$t('components.account_worker.socialsTableCopy.linkPlaceholder')" :disabled="item.status === 'checking' || item.status === 'approved'"
              @change="e => $store.commit('setDocumentsDataSelect', { field: field + '-url', index: index, value: e.target.value })" />

          </div>
        </div>
        <div class="table-second-element">
          <div class="table-column">
            <div class="document-input-wrapper">
              <h3 class="document__subtitlte mt-0">{{
                $t('components.account_worker.socialsTableCopy.numberOfSubscribers') }}</h3>
              <input class="input-description" :value="item.count"
                @change="e => $store.commit('setDocumentsDataSelect', { field: field + '-count', index: index, value: e.target.value })"
                @keydown="numberValidation"
                :placeholder="$t('components.account_worker.socialsTableCopy.numberOfSubscribersPlaceholder')"
                :disabled="item.status === 'checking' || item.status === 'approved'
                  " />
            </div>
          </div>
          <div class="close-btn">
            <button @click="deleteRow(item.id, index)">
              <img src="~@/assets/img/all-img/documents/close.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="button-add-row-wrapper">
      <button class="button-add-row" @click="addData">{{ $t('components.account_worker.socialsTableCopy.addNote')
        }}<span class="button-add-row__icon"></span></button>
    </div>
  </div>
</template>

<script>

export default {
  name: "SocialsTableCopy",
  props: ['values', 'field'],
  methods: {
    addData() {
      this.$store.commit("addDocumentsDataTable", { field: this.field });
    },
    numberValidation(e) {
      if (e)
        if (e.code !== 'Backspace')
          if (!/\d/.test(e.key) || Number(e.target.value) > 10000000000 || Number(e.target.value) < 0)
            e.preventDefault();
    },
    deleteRow(id, index) {
      this.$store.commit("deleteDocumentDataTable", { id, index, field: this.field });
    },
  },
};
</script>


<style scoped>
.table {
  max-width: 100%;
  width: 100%;

}

.table-wrapper {
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.table-wrapper input ::placeholder {
  color: #c7c7cc;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.table-wrapper input {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  padding: 17px 16px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
}

.table-row {
  display: flex;
  gap: 20px;
  max-width: 100%;
  width: 100%;
  align-items: flex-end;
}

.table-column {
  max-width: 100%;
  width: 100%;
}

.document__subtitlte {
  margin-top: 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #171725;
}

.close-btn {
  width: 16px;
  margin-bottom: 16px;
}

.button-add-row-wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 13px 0;
  border: 1px solid #efeff4;
  border-radius: 8px;
}

.button-add-row {
  margin: 0 auto;
  display: flex;
  background: #009ed1;
  border-radius: 7px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  padding: 8px 60px;
  color: #ffffff;
  align-items: center;
}

.button-add-row__icon {
  display: flex;
  width: 28px;
  height: 28px;
  background: url(../../../../../assets/img/all-img/pkus.svg);
  flex-shrink: 0;
}

.documents__line-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b4c68;
}

.mt-0 {
  margin-top: 0;
}

.table-second-element {
  display: flex;
}

@media (max-width: 950px) {
  .table-wrapper {
    margin-top: 20px;
    margin-left: 0;
  }

  .table {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .table-second-element {
    max-width: 100%;
    width: 100%;
    align-items: flex-end;
  }

  .table-row {
    max-width: 100%;
    flex-direction: column;
    width: 100%;
  }

  .document__subtitlte {
    text-align: left;
  }

  .table-column {
    text-align: center;
  }

  .close-btn {
    margin-left: 10px;
  }
}

@media (max-width: 330px) {
  .button-add-row {
    text-align: center;
  }

}

.documents__group-element-caption-stage-circle {
  width: 11px;
  height: 11px;
  background: #c4c4c4;
  border-radius: 100%;
  margin: 20px 0;
}

.stage-circle-green {
  background: #14c686;
}

.stage-circle-blue {
  background: #009ed1;
}

.stage-circle-red {
  background: #ff3c47;
}

.documents__group-element-caption-stage-text {
  margin-left: 11px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3b4c68;
}

.status {
  display: flex;
  align-items: center;
}
</style>