<template>
  <div>
    <Header></Header>

    <main class="main">
      <div class="caption">
        <div class="container">
          <p class="caption__text">{{ $t('pages.landing.aboutUsPage.aboutUs.captionText') }}</p>
          <h1 class="caption__title">{{ $t('pages.landing.aboutUsPage.aboutUs.captionTitle') }}</h1>
          <picture class="caption__imgafter">
            <source srcset="~@/assets/img/aboutus-page/caption/caption-after-img-mobile.png" media="(max-width: 464px)"/>
            <img src="~@/assets/img/aboutus-page/caption/caption-after-img.png" alt="Picture" class="caption__imgafter-img"/>
          </picture>
        </div>
      </div>

      <div class="info">
        <div class="container">
          <h2 class="info__title">
            {{ $t('pages.landing.aboutUsPage.aboutUs.infoTitle') }}
          </h2>
          <div class="info__text">
            <p class="info__text-element">
              {{ $t('pages.landing.aboutUsPage.aboutUs.infoText[0]') }}
            </p>
            <p class="info__text-element">
              {{ $t('pages.landing.aboutUsPage.aboutUs.infoText[1]') }}
            </p>
          </div>
          <div class="info__evidence" v-if="false">
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">3 000 000</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.jobApplications') }}</p>
            </div>
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">2 300 000</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.resumes') }}</p>
            </div>
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">1 063 thousand</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.availableJobs') }}</p>
            </div>
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">8 460 000</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.visitors') }}</p>
            </div>
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">1 930 000</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.companies') }}</p>
            </div>
            <div class="info__evidence-element">
              <p class="info__evidence-element-number">30</p>
              <span class="info__evidence-element-line"></span>
              <p class="info__evidence-element-text">{{ $t('pages.landing.aboutUsPage.aboutUs.evidence.services') }}</p>
            </div>
          </div>
        </div>
        <div class="info__ranabout">
          <div class="info__ranabout-wrapper">
            <VueSlickCarousel class="info__ranabout-wrapper-all" v-bind="settings" @init="onInitCarousel">
              <div class="info__ranabout-wrapper-picture">
                <img src="~@/assets/img/aboutus-page/info/randabout-img2.png" alt="" class="info__ranabout-wrapper-picture-img"/>
              </div>
              <div class="info__ranabout-wrapper-picture">
                <img src="~@/assets/img/aboutus-page/info/randabout-img1.png" alt="" class="info__ranabout-wrapper-picture-img"/>
              </div>
              <div class="info__ranabout-wrapper-picture">
                <img src="~@/assets/img/aboutus-page/info/randabout-img2.png" alt="" class="info__ranabout-wrapper-picture-img"/>
              </div>
              <div class="info__ranabout-wrapper-picture">
                <img src="~@/assets/img/aboutus-page/info/randabout-img2.png" alt="" class="info__ranabout-wrapper-picture-img"/>
              </div>
              <template #prevArrow="arrowOption">
                <div class="custom-arrow-prev">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #nextArrow="arrowOption">
                <div class="custom-arrow-next">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </div>

      <div class="map" v-if="false">
        <div class="container map-container">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.75567029275!2d49.144507441506384!3d55.784953880510315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415ead2213d91e8b%3A0x2d529e14c58e372!2z0JLRgdC1INC00LvRjyDQvNGD0LfRi9C60LDQvdGC0L7Qsg!5e0!3m2!1sru!2sru!4v1642703589055!5m2!1sru!2sru"
              class="map__wrapper map-dectop" allowfullscreen="" loading="lazy"></iframe>
          <div class="map__info">
            <p class="map__info-text">{{ $t('pages.landing.aboutUsPage.aboutUs.mapInfoText') }}</p>
            <a class="map__info-place">{{ $t('pages.landing.aboutUsPage.aboutUs.address') }}</a>
            <a href="" class="map__info-telephone">{{ $t('pages.landing.aboutUsPage.aboutUs.telephoneNumber') }}
              <span class="map__info-telephone-place">{{ $t('pages.landing.aboutUsPage.aboutUs.mapPlace') }}</span></a>
            <a href="" class="map__info-telephone">{{ $t('pages.landing.aboutUsPage.aboutUs.telephoneNumber') }}
              <span class="map__info-telephone-place">{{ $t('pages.landing.aboutUsPage.aboutUs.mapCountry') }}</span></a>
            <a href="" class="map__info-mail">{{ $t('pages.landing.aboutUsPage.aboutUs.emailAddress') }}</a>
          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import Footer from "../../components/landing/Footer.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {Header, Footer, VueSlickCarousel},

  name: "AboutUsPage",
  beforeMount() {
    scroll(0, 0);
  },
  data() {
    return {
      settings: {
        useCSS: true,
        useTransform: true,
        centerPadding: "40px",
        focusOnSelect: true,
        infinite: true,
        arrows: true,
        dots: false,
        variableWidth: true,
      },
    };
  },
};
</script>

<style scoped>
/* Caption css */
.caption {
  background: url("~@/assets/img/aboutus-page/caption/caption-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.caption__imgafter {
  position: absolute;
  max-width: 1240px;
  padding: 0px 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 374px auto 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.caption__imgafter-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.caption__text {
  padding-top: 189px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 35.5px;
  color: #ffffff;
}

.caption__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  color: #ffffff;
  padding-bottom: 510px;
}

@media (max-width: 1038px) {
  .caption__title {
    padding-bottom: 410px;
  }
}

@media (max-width: 808px) {
  .caption__title {
    padding-bottom: 310px;
  }
}

@media (max-width: 680px) {
  .caption__imgafter-img {
    min-height: 313px;
  }

  .caption__text {
    padding-top: 110px;
    font-size: 14px;
  }

  .caption__title {
    font-size: 36px;
    padding-bottom: 232px;
  }

  .caption__imgafter {
    margin: 216px auto 0 auto;
  }
}

@media (max-width: 464px) {
  .caption__imgafter-img {
    height: auto;
  }

  .caption__imgafter {
    margin: 251px auto 0 auto;
  }
}

@media (max-width: 325px) {
  .caption__imgafter {
    margin: 281px auto 0 auto;
  }

  .info__title {
    padding-top: 170px;
  }
}

/* /Caption css */

/* /Info  css */
.info {
  padding-bottom: 143px;
  background: #fff;
}

.info__title {
  padding-top: 215px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 24px;
  line-height: 1.6;
  color: #2f2f2f;
}

.info__text {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.info__text :not(:last-child) {
  margin-right: 40px;
}

.info__text-element {
  max-width: 580px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  line-height: 1.75;
  color: #424242;
}

.info__evidence {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.info__evidence-element {
  max-width: 373px;
}

.info__evidence-element-number {
  position: relative;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 52px;
  color: #009ed1;
}

.info__evidence-element-line {
  margin-top: 15px;
  content: "";
  width: 100%;
  display: block;
  background: #a8cbd7;
  height: 1px;
}

.info__evidence-element-text {
  padding-top: 25px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  color: #424242;
}

.info__ranabout {
  overflow: hidden;
  margin-top: 160px;
}

.mobile-btns {
  display: none;
}

.info__ranabout-btngroup-element-arrowleft {
  object-fit: cover;
  object-position: center;
  width: 16px;
  height: 16px;
  padding: 20px;
  cursor: pointer;
  background: #f1f1f1;
}

.info__ranabout-btngroup-element-arrowright {
  object-fit: cover;
  object-position: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 20px;
  background: #009ed1;
}

.desctop-element-arrowleft {
  position: absolute;
  left: -25.7%;
  height: 100%;
  transition: opacity 1s ease;
}

.desctop-element-arrowleft:hover {
  opacity: 0.5;
}

.desctop-element-arrowright {
  position: absolute;
  height: 100%;
  right: -25.7%;
  transition: opacity 1s ease;
}

.desctop-element-arrowright:hover {
  opacity: 0.5;
}

@media (max-width: 1100px) {
  .info__ranabout {
    margin-top: 62px;
    padding: 0px 10px;
  }

  .info__title {
    padding-top: 160px;
  }

  .desctop-element-arrowleft {
    display: none;
  }

  .desctop-element-arrowright {
    display: none;
  }

  .info__ranabout-wrapper {
    justify-content: center;
  }

  .mobile-btns {
    margin-top: 30px;
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 950px) {
  .info {
    padding-bottom: 114px;
  }

  .info__evidence {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }

  .info__ranabout-wrapper-img3 {
    display: none;
  }

  .info__ranabout-wrapper :not(:last-child) {
    margin-right: 0px;
  }
}

@media (max-width: 680px) {
  .info {
    background: #fafafb;
  }

  .info__evidence-element-number {
    font-size: 38px;
  }

  .info__evidence-element-text {
    font-size: 12px;
  }

  .info__title {
    font-size: 15px;
    padding-top: 179px;
  }

  .info__text :not(:last-child) {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .info__text {
    flex-direction: column;
  }

  .info__text-element {
    max-width: none;
    font-size: 13px;
  }

  .info__evidence {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .info__evidence-element {
    margin: 0 auto;
    text-align: center;
  }

  .info__ranabout {
    margin-top: 62px;
  }
}

@media (max-width: 464px) {
  .info__ranabout-wrapper {
    height: 276px;
  }

  .info__ranabout-wrapper-img2 {
    height: 100%;
    width: 273px;
  }

  .info__title {
    padding-top: 300px;
  }
}

@media (max-width: 380px) {
  .info__title {
    padding-top: 220px;
  }
}

@media (max-width: 320px) {
  .info__title {
    padding-top: 179px;
  }

  .info__evidence-element {
    margin: 0;
    text-align: left;
  }
}

@media (max-width: 325px) {
  .info__title {
    padding-top: 170px;
  }
}

/* /Info  css */

/* Map css */
.map {
  background: #fff;
  padding-bottom: 113px;
}

.map-container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 10px;
  overflow: hidden;
  position: relative;
}

.map__wrapper {
  width: 100%;
  height: 480px;
  box-sizing: border-box;
  border: none;
}

.map__img {
  box-sizing: border-box;
  width: 100%;
}

.map__info {
  display: flex;
  flex-direction: column;
  padding: 31px 40px 40px 40px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea, 0px 26px 24px rgba(64, 64, 64, 0.25);
  width: 318px;
  height: 227px;
  top: 85px;
  left: 99px;
  position: absolute;
}

.map__info-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #696974;
}

.map__info-place {
  padding-top: 20px;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-block-place.svg");
  background-repeat: no-repeat;
  background-position: 0% 37%;
  padding-left: 36px;
}

.map__info-telephone {
  padding-top: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-phone.svg");
  background-repeat: no-repeat;
  background-position: 0% 84%;
  padding-left: 36px;
}

.map__info-telephone-place {
  margin-left: 15px;
  padding-left: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  opacity: 0.6;
  border-left: 1px solid #8f959a;
}

.map__info-mail {
  padding-top: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 21.5px;
  letter-spacing: 0.1px;
  color: #444e57;
  background-image: url("~@/assets/img/aboutus-page/map/map-block-mail.svg");
  background-repeat: no-repeat;
  background-position: 0% 84%;
  padding-left: 36px;
}

@media (max-width: 780px) {
  .map {
    background: #f3f4f6;
    padding: 0;
    padding-bottom: 0;
  }

  .map-container {
    max-width: 100%;
    margin: 0;
    padding: 0px;
  }

  .map__wrapper {
    height: 559px;
  }

  .map__info {
    padding: 14px 16px 26px 16px;
    background: #ffffff;
    width: 266px;
    height: 203px;
    top: 237px;
    left: 10px;
  }

  .map__info-text {
    font-size: 12px;
  }

  .map__info-place {
    padding-top: 5px;
    font-size: 11px;
    background-position: 0% 28%;
    padding-left: 32px;
  }

  .map__info-telephone {
    font-size: 12px;
    padding-left: 32px;
  }

  .map__info-telephone-place {
    font-size: 12px;
  }

  .map__info-mail {
    padding-top: 10px;
    padding-left: 32px;
  }
}

/* /Map css */

/* Slider */
.info__ranabout-wrapper {
  position: relative;
}

.info__ranabout-wrapper-picture {
  max-width: 580px;
  width: 100%;
  margin: 0 20px;
  box-sizing: border-box;
  height: 405px;
  box-sizing: border-box;
}

.info__ranabout-wrapper-picture-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
}

.slick-slide {
  max-width: 30%;
  width: 100%;
}

.custom-arrow-prev {
  position: absolute;
  background: url("~@/assets/img/landing-img/about-page/arrow-left.svg");
  background-repeat: no-repeat;
  z-index: 20;
  width: 56px;
  height: 56px;
  left: 205px;
  top: calc(50%);
  transition: opacity 1s ease;
}

.custom-arrow-prev:hover {
  opacity: 0.5;
}

.custom-arrow-prev:before {
  content: "";
}

.custom-arrow-next {
  position: absolute;
  background: url("~@/assets/img/landing-img/about-page/arrow-right.svg");
  background-repeat: no-repeat;
  z-index: 20;
  width: 56px;
  height: 56px;
  right: 205px;
  top: calc(50%);
  transition: opacity 1s ease;
}

.custom-arrow-next:hover {
  opacity: 0.5;
}

.custom-arrow-next:before {
  content: "";
}

@media (max-width: 1300px) {
  .info__ranabout-wrapper-picture {
    max-width: 400px;
    width: 100%;
    height: 305px;
  }

  .custom-arrow-prev {
    width: 43px;
    background-position: center;
    background-size: cover;
    height: 43px;
    left: 105px;
  }

  .custom-arrow-next {
    width: 43px;
    height: 43px;
    background-position: center;
    background-size: cover;
    right: 105px;
  }
}

@media (max-width: 680px) {
  .info__ranabout-wrapper {
    position: relative;
    padding-bottom: 77px;
    overflow: visible;
  }

  .info__ranabout-wrapper-all {
    height: 278px;
  }

  .info__ranabout-wrapper-picture {
    max-width: 273px;
    width: 100%;
    height: 276px;
  }

  .custom-arrow-prev {
    top: unset;
    left: calc(50% - 50px);
    bottom: -91px;
  }

  .custom-arrow-next {
    top: unset;
    right: calc(50% - 50px);
    bottom: -91px;
  }
}

@media (max-width: 350px) {
  .info__ranabout-wrapper-picture {
    margin-left: 10px;
  }

  .info__ranabout {
    padding: 0;
  }
}

/* /Slider */
</style>
