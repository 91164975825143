<template>
  <div class="modal-wrapper" id="main">
    <div class="modal">
      <h2 class="modal__title"> {{ text }}</h2>
      <p class="modal__text">{{ $t('components.account_worker.questionModal.share_news') }}</p>
      <button v-if="false" class="modal__close"></button>
      <div class="modal-btns">
        <button class="modal__agree" @click="$emit('createNews')">
          {{ $t('components.account_worker.questionModal.accept') }}
        </button>
        <button @click="$emit('close')" class="modal__refuse">
          {{ $t('components.account_worker.questionModal.reject') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['page'],
  name: "QuestionModal",
  data() {
    return {
      text: null
    }
  },
  beforeMount() {
    switch (this.page) {
      case 'friends':
        this.text = this.$t('components.account_worker.questionModal.friends');
        break;
      case 'birthDay':
        this.text = this.$t('components.account_worker.questionModal.birthDay');
        break;
      case 'community':
        this.text = this.$t('components.account_worker.questionModal.community');
        break;
      case 'testing':
        this.text = this.$t('components.account_worker.questionModal.testing');
        break;
      case 'post':
        this.text = this.$t('components.account_worker.questionModal.post');
        break;
    }
  },
};
</script>


<style scoped>
.modal-wrapper {
  position: fixed;
  z-index: 1000000;
  height: 100vh;
  max-width: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  padding: 40px 30px;
  background: #fff;
  position: relative;
  border-radius: 8px;
  max-width: 50%;
}

.modal__close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 8px;
  top: 8px;
  background: url(../../../../../assets/img/all-img/close.svg);
  background-size: contain;
}

.modal__title {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #3b4c68;
}

.modal__text {
  margin-top: 20px;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
}

.modal-btns {
  margin-top: 30px;
  justify-content: space-between;
  display: flex;
  max-width: 100%;
  width: 100%;
}

.modal__agree {
  background: #14C686;
  color: #fff;
  padding: 14px 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  border-radius: 7px;
  margin-right: 10px;
}

.modal__refuse {
  background: #DC0B0B;
  color: #fff;
  padding: 14px 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  border-radius: 7px;
}

@media (max-width: 950px) {
  .modal-wrapper {
    padding: 30px 20px;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .modal {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .modal {
    padding: 30px 20px;
  }

  .modal__title {
    font-size: 18px;
  }

  .modal__text {
    font-size: 16px;
    margin-top: 10px;
  }

  .modal-btns {
    margin-top: 20px;
  }
}
</style>