<template>
  <div v-if="videoUrl!==null" class="test__wrapper-content-video-container">
    <video
        controls
        :src="videoUrl"
        class="test__wrapper-content-video"
    ></video>

  </div>
</template>

<script>
export default {
  props: {
    videoUrl: null,
  },

  name: "TestVideo",
};
</script>

<style scoped>
.test__wrapper-content-video-container {
  position: relative;
  height: 296px;
}

.test__wrapper-content-video-container:not(:last-child) {
  padding-bottom: 20px;
}

.test__wrapper-content-video {
  height: 100%;
  background: #000;
  border-radius: 16px;
  height: 296px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content-video-play {
  width: 72px;
  height: 72px;
  position: absolute;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
  border-radius: 100%;
  background: #009ed1;
  transition: opacity 1s ease;
}

.test__wrapper-content-video-play:hover {
  opacity: 0.5;
}

.test__wrapper-content-video-play-img {
  height: 100%;
  object-position: center;
}

@media (max-width: 700px) {
  .test__wrapper-content-video-play {
    width: 42px;
    height: 42px;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
  }
}
</style>