<template>
  <div class="recommendation">
    <router-link :to="{
      name: 'aboutWorker',
      params: {
        summaryId: this.summary.id,
      },
    }" class="recommendation__wrapper-element">
      <div class="recommendation__wrapper-element-container1">
        <div class="recommendation__wrapper-element-container1-company">
          <div class="recommendation__wrapper-element-container1-company-icon">
            <img :src="summary.avatarUrl" alt="" class="recommendation__wrapper-element-container1-company-icon-img" />
          </div>
          <div class="recommendation__wrapper-element-container1-company-name">
            <p class="recommendation__wrapper-element-container1-company-name-disignation">
              {{ $t('components.account_company.summary.name') }}
            </p>
            <p class="recommendation__wrapper-element-container1-company-name-gang">
              {{ summary.name ? limit(summary.name, 25) : $t('components.account_company.summary.noData') }}
            </p>
            <div class="recommendation__wrapper-element-container2-rating-part sumarry-mobile">
              <p class="rating-number">
                {{ summary.rate ? summary.rate : 0 }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="recommendation__wrapper-element-container2 desctop-recommendation">
        <div class="recommendation__wrapper-element-container2-rating">
          <p class="recommendation__wrapper-element-container2-rating-name">
            {{ $t('components.account_company.summary.rating') }}
          </p>
          <div class="recommendation__wrapper-element-container2-rating-part">
            <p class="rating-number">
              {{ summary.rate ? summary.rate : 0 }}
            </p>
          </div>
        </div>
      </div>
      <div class="recommendation__wrapper-element-container3">
        <div class="recommendation__wrapper-element-container3-specification">
          <h3 class="recommendation__wrapper-element-container3-specification-title">
            {{ $t('components.account_company.summary.profession') }}
          </h3>
          <p class="recommendation__wrapper-element-container3-specification-text">
            {{ summary.specialization ? limit(summary.specialization, 20) :
              $t('components.account_company.summary.noData') }}
          </p>
        </div>
      </div>
      <div class="recommendation__wrapper-element-container4">
        <div class="recommendation__wrapper-element-container4-place">
          <p class="recommendation__wrapper-element-container4-place-town desctop-recommendation">
            {{ $t('components.account_company.summary.city') }}
          </p>
          <p class="recommendation__wrapper-element-container4-place-name">
            {{ summary.city ? summary.city : $t('components.account_company.summary.noData') }}
          </p>
        </div>
      </div>
      <div class="recommendation__wrapper-element-container5">
        <div class="recommendation__wrapper-element-container5-price">
          <p class="recommendation__wrapper-element-container5-price-text desctop-recommendation">
            {{ $t('components.account_company.summary.expectedSalary') }}
          </p>
          <p class="recommendation__wrapper-element-container5-price-number">
            {{ salary }}
          </p>
        </div>
      </div>
    </router-link>
    <button @click="addToFavorite = !addToFavorite" class="favorite" :class="{ 'favorite--active': addToFavorite }"
      v-if="false">
      <svg viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9062 0.499817V21.7954C15.9062 22.7004 14.7999 23.1399 14.1789 22.4816L8.34375 16.2969L2.50862 22.4816C1.88757 23.1399 0.78125 22.7004 0.78125 21.7954V0.499817"
          stroke="#009ED1" />
      </svg>
    </button>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { convertSalary } from "@/lib/values";

export default {
  name: "Summary",
  props: {
    summary: {
      type: Object,
    },
  },
  computed: {
    salary() {
      return this.convertSalary(
        this.summary?.salary ? this.summary?.salary : 0,
        this.summary?.candidateCurrency ? this.summary?.candidateCurrency.toUpperCase() : "USD",
        this.$store.getters?.user?.expectedSalaryCurrency ? this.$store.getters?.user?.expectedSalaryCurrency.toUpperCase() : 'USD'
      );
    },
  },
  methods: {
    convertSalary,
  },
  data() {
    return {
      limit: textSymbolLimit,
      addToFavorite: false,
    };
  },
};
</script>


<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import {convertSalary} from "@/lib/values";

export default {
  name: "Summary",
  props: {
    summary: {
      type: Object,
    },
  },
  computed: {
    salary() {
      return this.convertSalary(this.summary?.salary ? this.summary?.salary : 0, this.summary?.candidateCurrency ? this.summary?.candidateCurrency.toUpperCase() : "usd", this.$store.getters?.user?.expectedSalaryCurrency ? this.$store.getters?.user?.expectedSalaryCurrency.toUpperCase() : 'usd')
    },
  },
  methods: {
    convertSalary
  },
  data() {
    return {
      limit: textSymbolLimit,
      addToFavorite: false
    };
  },
};
</script>

<style scoped>
.recommendation {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.recommendation__wrapper-element {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  height: 105px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  display: flex;
}

.recommendation__wrapper-element-container1 {
  flex-shrink: 1;
  overflow: hidden;
  flex-basis: 25%;
}

.recommendation__wrapper-element-container2 {
  flex-shrink: 1;
  overflow: hidden;
  flex-basis: 11%;
}

.recommendation__wrapper-element-container3 {
  flex-shrink: 1;
  overflow: hidden;
  flex-basis: 27%;
}

.recommendation__wrapper-element-container4 {
  flex-shrink: 1;
  overflow: hidden;
  flex-basis: 17.5%;
}

.recommendation__wrapper-element-container5 {
  width: 226px;
  flex-shrink: 1;
  overflow: hidden;
  flex-basis: 19.5%;
}

.recommendation__wrapper-element-container1-company {
  display: flex;
  padding: 25px 0 25px 25px;
  align-items: center;
}

.recommendation__wrapper-element-container1-company-icon-img {
  object-fit: cover;
  object-position: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.recommendation__wrapper-element-container1-company-name {
  margin-left: 32px;
}

.recommendation__wrapper-element-container1-company-name-disignation {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container1-company-name-gang {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.recommendation__wrapper-element-container2-rating {
  padding: 25px 10px 25px 25px;
}

.recommendation__wrapper-element-container2-rating-name {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container2-rating-part {
  margin-top: 4px;
}

.rating-number {
  display: inline;
  padding: 4px 13px 4px 32px;
  position: relative;
  margin-top: 11px;
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  background-image: url("~@/assets/img/main-page/jobs/stars/star1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 16px;
  background-position-y: 7px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  color: #14c686;
}

.recommendation__wrapper-element-container2-rating-part-number {
  background-image: url("~@/assets/img/recommendation-page/recommendation/star.svg");
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: 9px;
  padding: 0px 9px 0px 25px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #14c686;
}

.recommendation__wrapper-element-container3-specification {
  padding: 25px 10px 25px 25px;
}

.recommendation__wrapper-element-container3-specification-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container3-specification-text {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #171625;
}

.recommendation__wrapper-element-container4-place {
  padding: 25px 10px 25px 25px;
}

.recommendation__wrapper-element-price-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container4-place-name {
  padding-left: 18px;
  background-image: url("~@/assets/img/recommendation-page/recommendation/place.svg");
  background-position: 0;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.recommendation__wrapper-element-container4-place-town {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container5-price {
  padding: 25px 10px 25px 25px;
}

.recommendation__wrapper-element-container5-price-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #686873;
}

.recommendation__wrapper-element-container5-price-number {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.sumarry-mobile {
  display: none;
}

.recommendation__text-sumarry-mobile {
  display: none;
}

@media (max-width: 1840px) {
  .sumarry-mobile {
    display: block;
  }

  .desctop-recommendation {
    display: none;
  }

  .recommendation__wrapper-element {
    flex-direction: column;
    display: block;
    height: 100%;
  }

  .recommendation__wrapper-element-container1 {
    width: 100%;
    flex-basis: 100%;
  }

  .recommendation__wrapper-element-container2 {
    width: 100%;
    flex-basis: 100%;
  }

  .recommendation__wrapper-element-container3 {
    width: 100%;
    flex-basis: 100%;
  }

  .recommendation__wrapper-element-container4 {
    width: 100%;
    flex-basis: 100%;
  }

  .recommendation__wrapper-element-container5 {
    width: 100%;
    flex-basis: 100%;
  }

  .recommendation__wrapper-element-container1-company {
    padding: 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  .recommendation__wrapper-element-container3-specification {
    padding: 11px 20px 20px 20px;
  }

  .recommendation__wrapper-element-container4-place {
    padding: 0 20px 11px 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  .recommendation__wrapper-element-container5-price {
    padding: 12px 20px 20px 20px;
  }

  .recommendation__wrapper-element-container1-company-name-disignation {
    font-size: 12px;
  }

  .recommendation__wrapper-element-container2-rating-part {
    margin-top: 2px;
  }

  .recommendation__wrapper-element-container3-specification-title {
    font-size: 15px;
  }

  .recommendation__wrapper-element-container3-specification-text {
    font-size: 13px;
  }

  .recommendation__wrapper-element-status {
    padding: 18px 20px 0 20px;
    display: flex;
  }

  .recommendation__wrapper-element-status-text {
    padding: 0 9px 0 9px;
    background: rgba(204, 210, 229, 0.20000000298023224);
    border-radius: 20px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #979eb8;
  }

  .recommendation__wrapper-element-status-date {
    margin-left: auto;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #686873;
    opacity: 0.4;
  }

  .recommendation__wrapper-element:nth-child(2n) {
    margin-left: 0 auto;
  }
}

@media (max-width: 1140px) {
  .recommendation__wrapper-element {
    margin: 0 auto;
  }
}

@media (max-width: 950px) {
  .recommendation__wrapper-element {
    margin: 0;
    width: 100%;
  }

  .recommendation__wrapper-element:nth-child(2n) {
    margin-left: auto;
  }
}

@media (max-width: 720px) {
  .recommendation__wrapper-element {
    margin: 0 auto;
    width: 100%;
  }
}
</style>
