var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main"},[_c('div',{staticClass:"blog",style:({ backgroundImage: 'url(' + _vm.fourBlogs[0].files[0] + ')' })},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"blog__text"},[_vm._v(_vm._s(_vm.$t('pages.landing.blogPage.blog.title')))]),_c('div',{staticClass:"blog__topic"},[_c('p',{staticClass:"blog__topic-text topic-text"},[_vm._v(" "+_vm._s(_vm.fourBlogs[0].categories[0])+" ")]),_c('p',{staticClass:"blog__profile-date mobile"},[_vm._v(" "+_vm._s(new Date(_vm.fourBlogs[0].createDate).toISOString().slice(0, 10))+" ")])]),_c('h1',{staticClass:"blog__title"},[_vm._v(" "+_vm._s(_vm.fourBlogs[0].title)+" ")]),_c('div',{staticClass:"blog__profile"},[_c('div',{staticClass:"blog__profile-face"}),_c('p',{staticClass:"blog__profile-name"},[_vm._v(_vm._s(_vm.fourBlogs[0].author))]),_c('p',{staticClass:"blog__profile-date"},[_vm._v(" "+_vm._s(new Date(_vm.fourBlogs[0].createDate).toISOString().slice(0, 10))+" ")])]),_c('div',{staticClass:"blog__interacrive"},[_c('router-link',{staticClass:"blog__interacrive-btn",attrs:{"to":{
			name: 'articles',
			params: { articlesId: _vm.fourBlogs[0].id },
		}}},[_vm._v(" "+_vm._s(_vm.$t('pages.landing.blogPage.blog.readArticle'))+" ")]),_c('div',{staticClass:"blog__interacrive-randabout"})],1)])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"themes"},[_c('h2',{staticClass:"themes__title"},[_vm._v(_vm._s(_vm.$t('pages.landing.blogPage.blog.mostPopularTopics')))]),_c('div',{staticClass:"themes__nav"},[_c('ul',{staticClass:"themes__nav-list"},_vm._l((_vm.category),function(item){return _c('label',{key:item.id,staticClass:"themes__nav-list-elemnt",class:{ active: _vm.pickedCategory === item }},[_c('span',[_vm._v(_vm._s(item))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pickedCategory),expression:"pickedCategory"}],staticClass:"themes__nav-list-elemnt-btn",attrs:{"type":"radio"},domProps:{"value":item,"checked":_vm._q(_vm.pickedCategory,item)},on:{"click":function($event){return _vm.chooseCategory(item)},"change":function($event){_vm.pickedCategory=item}}})])}),0)]),_c('div',{staticClass:"themes__group"},_vm._l((_vm.allBlog),function(item){return _c('Article',{key:item.id,attrs:{"blog-data":item}})}),1),(_vm.display)?_c('a',{staticClass:"themes-btnmore",on:{"click":_vm.updateBlogData}},[_vm._v(_vm._s(_vm.$t('pages.landing.blogPage.blog.moreNews')))]):_vm._e()])]),_c('div',{staticClass:"tidings"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tidings__wrapper"},[_c('router-link',{attrs:{"to":{
			name: 'articles',
			params: { articlesId: _vm.fourBlogs[1].id },
		}}},[_c('div',{staticClass:"tidings__wrapper-mainarticle",style:({ backgroundImage: 'url(' + _vm.fourBlogs[1].files[0] + ')' })},[_c('div',{staticClass:"tidings__wrapper-mainarticle-topic"},[_c('p',{staticClass:"tidings__wrapper-mainarticle-topic-text"},[_vm._v(" "+_vm._s(_vm.fourBlogs[1].categories[0])+" ")])]),_c('h4',{staticClass:"tidings__wrapper-mainarticle-title tidings-title"},[_vm._v(" "+_vm._s(_vm.fourBlogs[1].title)+" ")]),_c('p',{staticClass:"tidings__wrapper-mainarticle-text"},[_vm._v(" "+_vm._s(_vm.fourBlogs[1].body.substring(0, _vm.BODY_SYMBOL_LIMIT))+"... ")]),_c('p',{staticClass:"tidings__wrapper-mainarticle-date date"},[_vm._v(" "+_vm._s(new Date(_vm.fourBlogs[1].createDate).toISOString().slice(0, 10))+" "),_c('span',{staticClass:"tidings__wrapper-mainarticle-date-span"})])])]),_c('div',{staticClass:"tidings__wrapper-smallarticle"},[_c('InterestingPage',{attrs:{"blogData":_vm.fourBlogs[2]}}),_c('InterestingPage',{attrs:{"blogData":_vm.fourBlogs[3]}})],1)],1)])])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }