<template>
  <div class="news">

    <div class="news-caption">
      <img src="../../../assets/img/news/place.svg" alt="" class="news-caption__icon">
      <div class="caption-info">
        <div class="caption-info-top">
          <h3 class="caption-info__title">A new place of work!!</h3>
          <time class="news-caption__time">{{ getDate(post.createDate) + ' ' + getTime(post.createDate) }}</time>
        </div>
        <p class="caption-info__text">{{ post.userFromName }}works in the company{{ post.userToName }} And he wants
          Tell about it</p>
      </div>
    </div>
    <div class="news-filling">
      <div class="distinction__line line-right"></div>
      <div class="distinction__line line-left"></div>
      <div class="news-persone" style="cursor: pointer"
           @click="$router.push(post.userFromRole === 'candidate' ?{name:'candidateProfile',params:{summaryId:post.userFromId}}:{name:'employerProfile',params:{summaryId:post.userFromId}})">
        <img :src="post.userFromAvatar" alt="" class="news-persone__face">
        <p class="news-persone__descr">{{ post.userFromRole === 'candidate' ? 'Applicant' : 'Employer' }}</p>
        <p class="news-persone__name">{{ post.userFromName }}</p>
        <p class="news-persone__info" v-if="false">Head Lead Product Design</p>
      </div>
      <div class="news-filling__distinction">
        <img src="../../../assets/img/news/place-icon.svg" alt="" class="distinction__icon">
      </div>
      <div class="news-persone" style="cursor: pointer"
           @click="$router.push(post.userToRole === 'candidate' ?{name:'candidateProfile',params:{summaryId:post.userToId}}:{name:'employerProfile',params:{summaryId:post.userToId}})">
        <img :src="post.userToAvatar" alt="" class="news-persone__face">
        <p class="news-persone__descr">{{ post.userToRole === 'candidate' ? 'Applicant' : 'Employer' }}</p>
        <p class="news-persone__name">{{ post.userToName }}</p>
        <p class="news-persone__info" v-if="false">Head Lead Product Design</p>
      </div>
    </div>
  </div>
</template>

<script>

import {textSymbolLimit} from "@/lib/textSymbolLimit";
import {getDate, getTime} from "@/lib/dateUtils";

export default {
  name: "LastNewsPlace",
  props: ['post'],
  data() {
    return {
      body: null,
      textSymbolLimit, getDate, getTime
    }
  },
  beforeMount() {
    this.body = JSON.parse(this.post.body);
  }

};


</script>

<style scoped>
p{
  word-break: break-word;
}
h3{
  word-break: break-word;
}
.news {
  font-family: "Roboto";
  padding: 18px 12px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  position: relative;
}

.news-caption {
  display: flex;
  align-items: flex-start;
}

.news-caption__icon {
  background: #f7f7f7;
  width: 53px;
  height: 53px;
  object-fit: cover;
  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
}

.caption-info {
  margin-left: 16px;
  max-width: 100%;
  width: 100%;
}
.caption-info-top{
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}
.caption-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #3b4c68;
}

.caption-info__text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(23, 23, 37, 0.4000000059604645);
}

.news-caption__time {
  max-width: 97px;
width: 100%;
  margin-left: auto;
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.news-filling {
  padding: 20px 18px 0 18px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
}

.news-persone {
  display: flex;
  align-items: center;
  max-width: 145px;
  width: 100%;
  flex-direction: column;
  text-align: center;
}

.news-persone__face {
  background: #000000;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.news-persone__descr {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.news-persone__name {
  margin-top: 8px;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.news-persone__info {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(23, 22, 37, 0.5);
}

.news-filling__distinction {
  margin-top: 17px;
  position: relative;
}

.distinction__icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-shrink: 0;
}

.distinction__line {
  position: absolute;
  top: 52px;
  width: 37px;
  height: 1px;
  background: #efeff4;
  width: 16%;
}

.line-left {
  left: 28.5%;
}

.line-right {
  right: 28.5%;
}

@media (max-width: 1770px) {
  .news-filling {
    padding: 20px 0 0 0;
  }

  .distinction__line {
    width: 8%;
  }

  .line-left {
    left: 35.5%;
  }

  .line-right {
    right: 35.5%;
  }
}

@media (max-width: 950px) {
  .distinction__line {
    width: 16%;
  }

  .line-left {
    left: 28.5%;
  }

  .line-right {
    right: 28.5%;

  }

  .news-filling {
    padding: 20px 18px 0 18px;
  }
}

@media (max-width: 720px) {
  .news {
    padding: 12px;
  }

  .caption-info {
    margin-left: 14px;
    max-width: 100%;
  }
  .caption-info-top{
    flex-direction: column-reverse;
    gap: 6px;
  }
  .news-caption__time{
    margin: 0;
  }

  .caption-info__title {
    font-size: 16px;
  }

  .caption-info__text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.4;
  }

  .news-filling {
    padding: 28px 10px 0 10px;
  }

  .news-persone {
    max-width: 87px;
  }

  .news-persone__face {
    width: 48px;
    height: 48px;
  }

  .news-persone__descr {
    margin-top: 8px;
    font-size: 10px;
  }

  .news-persone__name {
    margin-top: 6px;
    font-size: 14px;
  }

  .news-persone__info {
    margin-top: 6px;
    font-size: 10px;
  }

  .news-filling__distinction {
    margin-top: 17px;
    position: relative;
  }

  .distinction__icon {
    width: 20px;
    height: 20px;
  }

  .distinction__line {
    position: absolute;
    top: 54px;
    height: 1px;
    width: 37px;
  }
  .line-left{
    left: 84px;
  }
  .line-right{
    right: 84px;
  }
}
</style>
