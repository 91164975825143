<template>
  <div class="separateanswer-container">
    <label
        style="cursor: default"
      class="separateanswer"
      :class="{ 'separateanswer-green': dataAnswer.correct }"
    >

      <span
        class="separateanswer__circle"
        :class="{ 'circle-green': dataAnswer.correct }"
        v-if="questionType === 'radio'"
      ></span>
      <span
        class="separateanswer__circle"
        :class="{ 'circle-green': dataAnswer.correct }"
        style="border-radius: 6px"
        v-else
      ></span>
      <p class="separateanswer__text">
        {{dataAnswer.body }}
      </p>
    </label>
  </div>
</template>

<script>
export default {
  name: "TestInputCircleWatch",
  props: {
    answer: {},
    questionType: {},
  },
  data() {
    return {
      dataAnswer: null,
    };
  },
  beforeMount() {
    this.dataAnswer = this.answer
  }
};
</script>

<style scoped>
.separateanswer-container {
  cursor: none;
  margin-top: 20px;
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.cancellation {
  padding: 20px 20px 20px 20px;
}

.separateanswer {
  padding: 23px 82px 23px 33px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #f5f8f9;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  transition: background 1s ease, border 1s ease;
}


.separateanswer__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.separateanswer__checkbox {
  position: absolute;
  background: #deeaf3;
  border-radius: 6px;
  top: calc(50% - 17px);
  height: 34px;
  right: 17px;
  width: 34px;
}

.separateanswer__input ~ .separateanswer__checkbox {
  transition: background-color 1s ease;
}


.separateanswer__checkbox::before {
  content: "";
  position: absolute;
  display: none;
}

.separateanswer:checked ~ .separateanswer__checkbox::before {
  display: block;
}

.separateanswer__circle {
  /* border-radius:6px; Box */
  border-radius: 100%;
  display: block;
  width: 100%;
  height: 21px;
  border: 2px solid rgba(59, 76, 104, 0.5);
  max-width: 21px;
}

.separateanswer__text {
  background: inherit;
  width: 100%;
  margin-left: 37px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.78;
  color: #3b4c68;
}

@media (max-width: 950px) {
  .separateanswer-container {
    margin-top: 14px;
  }

  .cancellation {
    padding: 15px;
  }

  .cancellation__img {
    width: 11px;
    height: 11px;
    object-fit: cover;
    object-position: center;
  }

  .separateanswer {
    padding: 10px 30px 10px 14px;
  }

  .separateanswer__circle {
    max-width: 12px;
    height: 12px;
  }

  .separateanswer__checkbox {
    display: none;
  }

  .separateanswer__input ~ .separateanswer__checkbox {
    transition: border 1s ease;
  }



  .separateanswer__input:checked ~ .separateanswer__circle {
    border: 2px solid #14c686;
    background: #14c686;
  }

  .separateanswer__text {
    margin-left: 13px;
    font-size: 11px;
    line-height: 1.67;
  }
}

/* Modificator */
.circle-green {
  border: 2px solid #14c686;
}

.checkbox-green {
  background-color: #14c686;
  background-image: url("~@/assets/img/test-img/constractor/checkbox-yes.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.separateanswer-green {
  background: #eefff4;
  border: 1px solid #14c686;
}

/* /Modificator */
</style>