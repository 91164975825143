import {config} from "@/lib/config";
import {errorHandler} from "@/lib/errorHandler";

export const complaint = {
	actions: {
		sendComplaint(_,payload) {
			const request = new XMLHttpRequest();
			const url = config.apiPrefix + 'complaint/send';
			request.open('POST', url, false);
			console.log(payload)
			request.setRequestHeader('X-Auth-Token', payload.token);
			request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
	
			try {
					request.send(JSON.stringify(
						{
							categoryId: payload.categoryId, 
							url: payload.url,
							addresserId: payload.addresserId,
							addresserRole: payload.addresserRole,
						}
						));
					errorHandler({status: request.status})

			} catch (e) {
					console.log(e);
			}
		},
		fetchComplaintCategories({commit}, payload){
			const request = new XMLHttpRequest();
			const url = config.apiPrefix + 'complaint/types?lang='+payload.locale;
			request.open('GET', url, false);
			request.setRequestHeader('X-Auth-Token', payload.token);
			request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
			try {
					request.send();
					const response = JSON.parse(request.response);
					commit("setComplaintCategories",response.data);
				//	errorHandler({status: request.status})
			} catch (e) {
					console.log(e);
			}
		}
	},
	mutations: {
		setComplaintCategories(state, data) {
			state.complaintCategories = data;
	},
	},
	state: {
		complaintCategories:[],
	},
	getters: {
		getComplaintCategories(state) {
			return state.complaintCategories;
	}
	},
}