<template>
  <div class="message" id="message">
    <div class="message__wrapper">
      <div class="message__wrapper-caption">
        <button
            class="message__wrapper-captionback"
            @click="$emit('close')"
        >
          <img
              src="~@/assets/img/all-img/back.svg"
              alt=""
              class="message__wrapper-caption-back-img"
          />
        </button>
        <h3 class="message__wrapper-caption-title">{{ $t('components.social.workingMessage.title') }}</h3>
      </div>
      <textarea
          class="message__wrapper-text"
          :placeholder="$t('components.social.workingMessage.placeholder')" 
          v-model="message"
      ></textarea>
      <div class="message__wrapper-respond">
        <button class="message__wrapper-respond-btn" @click="$emit('accept', message)">
          {{ $t('components.social.workingMessage.sendButton') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkingMessage",
  data() {
    return {
      message: ''
    }
  },
  mounted() {
    document.getElementById('message').onclick = (event) => {
      if (event.target.id) {
        this.$emit('close');
      }
    }
  }
}
</script>


<style scoped>
.message {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: calc(100vh);
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.message__wrapper {
  position: absolute;
  top: calc(50% - 315px);
  left: calc(50% - 21%);
  max-width: calc(42%);
  height: 630px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(59, 76, 104, 0.1);
  border-radius: 20px;
}

.message__wrapper-caption {
  padding: 0px 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: #f6f6f8;
  border: 1px solid rgba(59, 76, 104, 0.1);
  border-radius: 20px 20px 0px 0px;
  align-items: center;
}

.message__wrapper-captionback {
  padding: 25px 15px;
}

.message__wrapper-caption-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  color: #2f2f2f;
}

.message__wrapper-text {
  padding: 20px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 19px;
  line-height: 1.6;
  color: #424242;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: calc(100% - 140px);
}

.message__wrapper-text:focus,
input:focus {
  outline: none;
}

.message__wrapper-respond {
  border-top: 1px solid rgba(59, 76, 104, 0.1);
  padding: 10px 0;
  width: 100%;
  justify-content: center;
  display: flex;
}

.message__wrapper-respond-btn {
  padding: 19px 0;
  background: #14c686;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.1px;
  color: #ffffff;
  width: 170px;
  transition: opacity 1s ease;
}

.message__wrapper-respond-btn:hover {
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .message__wrapper {
    left: calc(50% - 30%);
    max-width: calc(60%);
  }
}

@media (max-width: 700px) {
  .message__wrapper {
    left: calc(50% - 40%);
    max-width: calc(80%);
    height: 630px;
    width: 100%;
  }

  .message__wrapper-caption-title {
    font-size: 16px;
  }

  .message__wrapper-caption {
    padding: 0 5px;
  }

  .message__wrapper-text {
    padding: 10px 20px;
    font-size: 15px;
    height: calc(100% - 130px);
  }

  .message__wrapper-respond-btn {
    padding: 14px 0;
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .message__wrapper-caption-title {
    font-size: 13px;
  }

  .message__wrapper-captionback {
    padding: 17px 9px;
  }

  .message__wrapper-text {
    font-size: 13px;
    height: calc(100% - 114px);
  }
}

@media (max-height: 700px) {
  .message__wrapper {
    top: calc(50% - 250px);
    height: 500px;
  }
}
</style>