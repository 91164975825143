<template>
  <div class="form-wrapper" id="parent" @click="handleBackgroundClick">
    <div class="form-content">
      <img :src="url" alt="" class="form-element">
      <button class="cancel-buttonn" @click="$emit('closeBigPhoto')"></button>
    </div>
  </div>
</template>

<script>

export default {
  name: "BigPhoto",
  props: ['url'],
	methods:{
		handleBackgroundClick(event) {
    if (event.target === event.currentTarget) {
      this.$emit('closeWindow');
    }
  },
	},
  mounted() {
    document.getElementById('parent').onclick = (event) => {
      if ((event.target.id)) {
        this.$emit('closeBigPhoto');
      }
    }
  }
}
;


</script>

<style scoped>
.form-wrapper {
  max-width: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.5);
  padding: 60px;
  height: 100vh;
  height: 100dvh;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
}

.form-content {
  position: relative;
  max-width: calc(70%);
  width: 100%;
  border-radius: 20px;
  margin: auto;
}

.form-element {
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 20px;

}

.form-element_wrapper {
  width: 100%;
  box-sizing: border-box;
}

.cancel-buttonn {
  background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
  height: 15px;
  width: 15px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 15px;
  right: 15px;
}
@media (max-width: 720px) {
  .form-wrapper{
    padding: 20px;
  }
  .form-content{
    max-width: 100%;
  }
}
</style>