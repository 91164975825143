<template>
	<div>
	  <HeaderAccountWorker></HeaderAccountWorker>
  
	  <main class="main">
		<div class="account">
		  <MenuWorker></MenuWorker>
		  <div class="container-account">
			<div class="faq">
			  <h1 class="faq__text mobile">{{ $t('personalCabinet') }}</h1>
			  <h1 class="faq__title">{{ $t('faq') }}</h1>
			</div>
		  </div>
		</div>
	  </main>
	</div>
  </template>
  

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";

export default {
	components: { MenuWorker, HeaderAccountWorker },
	name: "AccountWorkerFaq",
};
</script>


<style scoped>
.account {
	display: flex;
}

.mobile {
	display: none;
}

.faq__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.faq__text {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

@media (max-width: 1650px) {
	.faq__title {
		text-align: center;
	}

	.faq__text {
		text-align: center;
	}
}

@media (max-width: 700px) {
	.desctop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.faq__text {
		padding-top: 0;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		line-height: 35.5px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}
}
</style>