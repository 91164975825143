<template>
  <div class="test-container" v-if="!dataQuestion.deleted">
    <div class="test">
      <div class="test__number">
        <p class="test__number-element">{{ count + 2 }}</p>
      </div>
      <div class="test__wrapper">
        <div class="test__wrapper-content">
          <div class="test__wrapper-content-caption">
            <div class="test__wrapper-content-caption-name">
              <h2 class="test__wrapper-content-caption-name-title">
                {{ $t('components.tests.testConstructorWrapperWatch.questionTitle') }}
              </h2>
              <p class="test__wrapper-content-caption-name-textarea">
                {{ dataQuestion.title }}
              </p>
            </div>
            <div class="test__wrapper-content-caption-photo" v-if="dataQuestion.photoPath !== null">
              <h3 class="test__wrapper-content-caption-visual-title">{{
                $t('components.tests.testConstructorWrapperWatch.image') }}</h3>
              <div class="test__wrapper-content-caption-photo-container">
                <img :src="dataQuestion.photoPath" alt="Add photo" class="test__wrapper-content-caption-visual-photo" />
              </div>
            </div>
            <div class="test__wrapper-content-caption-video" v-if="dataQuestion.videoPath !== null">
              <h3 class="test__wrapper-content-caption-visual-title">{{
                $t('components.tests.testConstructorWrapperWatch.video') }}</h3>
              <div class="test__wrapper-content-caption-video-container">
                <video v-if="dataQuestion.videoPath !== null" controls :src="dataQuestion.videoPath"
                  class="test__wrapper-content-caption-visual-video"></video>
              </div>
            </div>
            <div class="test__wrapper-content-caption-description">
              <h3 class="test__wrapper-content-caption-description-title">
                {{ $t('components.tests.testConstructorWrapperWatch.description') }}
              </h3>
              <p class="test__wrapper-content-caption-description-textarea">
                {{ dataQuestion.body }}
              </p>
            </div>
          </div>
          <div class="test__wrapper-content-filling">
            <textarea class="test__wrapper-content-caption-description-textarea"
              placeholder="{{ $t('components.tests.testConstructorWrapperWatch.enterAnswer') }}"
              v-if="dataQuestion.questionType === 'open'" readonly="readonly"></textarea>

            <TestSeparateAnswerWatch v-else v-for="item in dataQuestion.answers" :key="item.id" :answer="item"
              :questionType="dataQuestion.questionType"></TestSeparateAnswerWatch>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestSeparateAnswerWatch from "../../test_contructor_wrapper_elements/item/TestSeparateAnswerWatch";

export default {
  components: { TestSeparateAnswerWatch },
  props: {
    question: {},
    count: {},
  },
  data() {
    return {
      dataQuestion: null,
    };
  },
  name: "TestConstructorWrapperWatch",
  beforeMount() {
    this.dataQuestion = this.question;
  },
};
</script>


<style scoped>
/* Caption */

.test-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 64px 0 62px 0px;
}

.test__wrapper-content-caption {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.test__wrapper-content-caption-name-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-name-textarea {
  width: 100%;
  box-sizing: border-box;
  min-height: 155px;
  margin-top: 10px;
  padding: 13px 31px 25px 25px;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.test__wrapper-content-caption-description {
  padding-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content-caption-description-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 1.28;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-description-textarea {
  min-height: 87px;
  box-sizing: border-box;
  padding: 15px 31px 20px 23px;
  width: 100%;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.76;
  color: #424242;
}

@media (max-width: 950px) {
  .test-container {
    padding: 18px 0 30px 0;
  }

  .test__wrapper-content-caption {
    border-bottom: none;
  }

  .test__wrapper-content-caption-name-title {
    font-size: 12px;
  }

  .test__wrapper-content-caption-name-textarea {
    font-size: 11px;
    padding: 11px 16px 14px 16px;
    line-height: 1.72;
    min-height: 117px;
  }

  .test__wrapper-content-caption-description {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-description-title {
    font-size: 11px;
  }

  .test__wrapper-content-caption-description-textarea {
    padding: 11px 16px 16px 16px;
    font-size: 13px;
    line-height: 1.75;
  }
}

/* /Caption */

/* Filling and add */
.test__wrapper-content-filling {
  padding-top: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.test__wrapper-content-edd {
  padding-top: 25px;
  max-width: 273px;
}

.test__wrapper-content-edd-btn {
  width: 100%;
  text-align: center;
  padding: 23px 0;
  font-family: "Roboto";
  background: #e6f6fc;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #009ed1;
  transition: opacity 1s ease;
}

.test__wrapper-content-edd-btn:hover {
  opacity: 0.5;
}

.test__wrapper-content-filling-select {
  margin-top: 10px;
  padding: 14px 25px;
  background: #ffffff;
  border: 1px solid #009ed1;
  border-radius: 13px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  color: #3b4c68;
  color: #171725;
  width: 300px;
  background-color: #fff;
  background-image: url("~@/assets/img/all-img/list-blue.svg");
  background-repeat: no-repeat;
  background-position: calc(93%);
  background-size: 10px;
  transition: background-color 1s ease;
}

.test__wrapper-content-filling-select:hover {
  background-color: #eefbff;
}

@media (max-width: 950px) {
  .test__wrapper-content-filling-select {
    padding: 11px 15px;
    margin: 0;
    font-size: 12px;
  }

  .test__wrapper-content-filling {
    border-bottom: none;
  }

  .test__wrapper-content-edd {
    max-width: 200px;
    padding-top: 0;
  }

  .test__wrapper-content-edd-btn {
    padding: 13px 0;
    font-size: 13px;
  }
}

@media (max-width: 360px) {
  .test__wrapper-content-filling-select {
    width: 100%;
  }
}

/* /Filling and add */

/* Wrapper */
.test {
  display: flex;
  max-width: 1518px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.test__number-element {
  padding: 17px 24px;
  background: #f0f4fa;
  border-radius: 9px;
  color: #a0a9b7;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 21px;
  line-height: 1.28;
}

.test__wrapper {
  margin: 0 80px;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content {
  padding: 46px 63px 59px 62px;
}

@media (max-width: 1740px) {
  .test__wrapper {
    margin: 0 40px;
  }
}

@media (max-width: 950px) {
  .test__wrapper {
    margin: 22px 0 0 0;
  }

  .test {
    flex-wrap: wrap;
  }

  .test__number {
    padding-top: 6px;
    order: 1;
    max-width: 76px;
  }

  .test__number-element {
    width: 100%;
    padding: 0;
    background: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .test__change {
    margin-left: auto;
    order: 2;
    display: flex;
  }

  .test__wrapper {
    border: none;
    order: 3;
  }

  .test__wrapper-content {
    padding: 0;
  }

  .test__wrapper-content {
    background: #fafafb;
  }
}

/* /Wrapper */

/* Change */

.test__change-more {
  margin-bottom: 25px;
  transition: opacity 1s ease;
}

.test__change-more:hover {
  opacity: 0.5;
}

.test__change-more-img {
  padding: 26px 15px 25px 15px;
  background: #e6f6fc;
  border: 1px solid #009ed1;
  border-radius: 9px;
}

.test__change-options {
  padding: 20px 0;
  display: flex;
  border-top: 1px solid #dde0e6;
  flex-direction: column;
}

.test__change-options-element:not(:last-child) {
  margin-bottom: 14px;
}

.test__change-options-element-video {
  padding: 18px 18px 18px 18px;
  background: #e6f6fc;
  border-radius: 9px;
  transition: opacity 1s ease;
}

.test__change-options-element-video:hover {
  opacity: 0.5;
}

.test__change-options-element-photo {
  padding: 19px 18px 19px 18px;
  background: #e6f6fc;
  border-radius: 9px;
  transition: opacity 1s ease;
}

.test__change-options-element-photo:hover {
  opacity: 0.5;
}

.test__change-options-element-view {
  padding: 19px 17px 20px 18px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-view:hover {
  opacity: 0.5;
}

.test__change-options-element-delete {
  padding: 16px 18px 17px 19px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-delete:hover {
  opacity: 0.5;
}

.test__change-options-element-up {
  padding: 26px 23px 25px 22px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-up:hover {
  opacity: 0.5;
}

.test__change-options-element-down {
  padding: 26px 23px 25px 22px;
  transition: opacity 1s ease;
  background: #f0f4fa;
  border-radius: 9px;
}

.test__change-options-element-down:hover {
  opacity: 0.5;
}

@media (max-width: 950px) {
  .test__change-more {
    margin-left: 9px;
    margin-bottom: 0;
    order: 3;
  }

  .test__change-more-img {
    width: 12px;
    height: 3px;
    padding: 12px 7px 11px 7px;
    background: #e6f6fc;
    border: 1px solid #009ed1;
    border-radius: 9px;
  }

  .test__change-options {
    padding: 0px 9px;
    display: flex;
    border-top: none;
    border-right: 1px solid #dde0e6;
    flex-direction: row;
  }

  .test__change-options-element:not(:last-child) {
    margin-bottom: 0;
    margin-right: 9px;
  }

  .test__change-options-element-view {
    width: 12px;
    height: 10px;
    padding: 9px 8px 9px 8px;
  }

  .test__change-options-element-delete {
    width: 14px;
    height: 14px;
    padding: 7px;
  }

  .test__change-options-element-up {
    width: 8px;
    height: 11px;
    padding: 8px 10px 9px 10px;
  }

  .test__change-options-element-down {
    width: 8px;
    height: 11px;
    padding: 8px 10px 9px 10px;
  }

  .desctop-option {
    display: none;
  }
}

/* /Change */

/* Visual */
.test__wrapper-content-caption-photo {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
}

.test__wrapper-content-caption-video {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
}

.test__wrapper-content-caption-visual-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-caption-photo-container {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 300px;
}

.test__wrapper-content-caption-video-container {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 300px;
}

.test__wrapper-content-caption-visual-delete {
  z-index: 42;
  top: 17px;
  right: 23px;
  border-radius: 100%;
  position: absolute;
  padding: 13px 15px 13px 15px;
  background: #febdbd;
  border-radius: 24px;
  transition: opacity 1s ease;
}

.test__wrapper-content-caption-visual-delete:hover {
  opacity: 0.5;
}

.test__wrapper-content-caption-photo-visual-delete-img {
  width: 17px;
  height: 17px;
}

.test__wrapper-content-caption-visual-add {
  z-index: 42;
  top: 74px;
  right: 23px;
  border-radius: 100%;
  position: absolute;
  padding: 13px 15px 12px 15px;
  background: #99d8ed;
  border-radius: 24px;
  transition: opacity 1s ease;
}

.test__wrapper-content-caption-visual-add:hover {
  opacity: 0.5;
}

.test__wrapper-content-caption-visual-add-img {
  width: 16px;
  height: 18px;
}

.test__wrapper-content-caption-visual-photo {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  background: #000;
}

.test__wrapper-content-caption-visual-video {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
  border-radius: 16px;
  background: #000;
}

@media (max-width: 950px) {
  .test__wrapper-content-caption-photo {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-video {
    padding-top: 15px;
  }

  .test__wrapper-content-caption-visual-title {
    font-size: 11px;
  }

  .test__wrapper-content-caption-photo-container {
    margin-top: 15px;
    max-height: 160px;
  }

  .test__wrapper-content-caption-video-container {
    margin-top: 15px;
    max-height: 160px;
  }

  .test__wrapper-content-caption-visual-delete {
    top: 11px;
    right: 13px;
    padding: 9px 12px 8px 12px;
  }

  .test__wrapper-content-caption-photo-visual-delete-img {
    width: 11px;
    height: 14px;
  }

  .test__wrapper-content-caption-visual-add {
    top: 54px;
    right: 13px;
    padding: 9px 12px 8px 12px;
  }

  .test__wrapper-content-caption-visual-add-img {
    width: 12px;
    height: 13px;
  }

  .test__wrapper-content-caption-visual-photo {
    height: 160px;
  }

  .test__wrapper-content-caption-visual-video {
    height: 160px;
  }
}

/* /Visual */
</style>
