<template>
  <div :key="queIterator">
    <HeaderAccount></HeaderAccount>

    <main class="main">
      <div class="account">
        <MenuWorker></MenuWorker>

        <div class="container-account">
          <div class="test">
            <div class="test__caption">
              <div class="test__caption-description">
                <h1 class="test__caption-description-title">{{ $t('pages.interview.interviewInputPage.testName') }}
                </h1>
                <p class="test__caption-description-text">
                  {{ getDataInterview.title }}
                </p>
              </div>
            </div>
            <div class="test__wrapper">
              <div class="test__wrapper-content">
                <div class="test__wrapper-content-caption">
                  <p class="test__wrapper-content-caption-question">
                    {{ $t('pages.interview.interviewInputPage.question') }} #{{ queIterator + 1 }}
                  </p>
                  <p class="test__wrapper-content-caption-time">
                    {{ $t('pages.interview.interviewInputPage.timeLeft') }}:
                    <time class="time" v-if="getDataInterview.maxPassingTimeSeconds !== null">{{
                      timeParse(getDataInterview.maxPassingTimeSeconds) }}
                    </time>
                    <span v-else>{{ $t('pages.interview.interviewInputPage.limitless') }}</span>
                  </p>
                </div>
                <p class="test__wrapper-content-text">
                  {{ questions[queIterator].body }}
                </p>
                <div class="video__wrapper" v-if="questions[queIterator].videoPath !== null">
                  <video class="video__wrapper-filling" controls>
                    <source :src="questions[queIterator].videoPath" />
                  </video>
                </div>
                <div class="photo__wrapper" v-if="questions[queIterator].photoPath !== null">
                  <img class="photo__wrapper-img" :src="questions[queIterator].photoPath" alt="error" />
                </div>
                <form class="test__wrapper-content-answers">
                  <InterviewInputCircle :question="questions[queIterator]"
                    :questionType="questions[queIterator].questionType" :choose="getChooseInterview">
                  </InterviewInputCircle>
                </form>
              </div>
              <nav class="test__wrapper-nav">
                <p class="test__wrapper-nav-number">
                  {{ queIterator + 1 }}/{{ questions.length }}
                </p>
                <div class="test__wrapper-nav-next" v-if="queIterator !== questions.length - 1" @click="nextQue">
                  <button class="test__wrapper-nav-next-btn">
                    {{ $t('pages.interview.interviewInputPage.nextQuestion') }}
                  </button>
                </div>
                <div class="test__wrapper-nav-next" v-else @click="closeTest">
                  <button class="test__wrapper-nav-next-btn">
                    {{ $t('pages.interview.interviewInputPage.completeTest') }}
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderAccount from "../../components/account/HeaderAccount.vue";
import InterviewInputCircle from "../../pages/interview/InterviewInputCircle.vue";
import MenuWorker from "../../components/account/account_worker/MenuWorker";
import { errorHandler } from "../../lib/errorHandler";

export default {
  computed: {
    getChooseInterview() {
      return this.$store.getters.getChooseInterview;
    },
    getDataInterview() {
      return this.$store.getters.getDataInterview;
    },
  },
  components: { MenuWorker, HeaderAccount, InterviewInputCircle },
  props: {
    interviewId: {
      type: String,
    },
  },
  name: "InterviewInputPage",
  methods: {
    saveAnswer() {
      //RADIO BUTTON
      if (this.questions[this.queIterator].questionType === 'single')
        this.$store.commit("saveQuestionAnswerInterview", {
          answer: {
            interviewQuestion: this.questions[this.queIterator].id,
            interviewAnswer: this.getChooseInterview,
            openAnswer: null,
          },
        });
      //CHECKBOX
      if (this.questions[this.queIterator].questionType === 'multi') {
        this.getChooseInterview.forEach((a) => {
          let b = {
            answer: {
              interviewQuestion: this.questions[this.queIterator].id,
              interviewAnswer: a,
              openAnswer: null,
            },
          };
          this.$store.commit("saveQuestionAnswerInterview", b);
        });
      }

      //TEXTAERA
      if (this.questions[this.queIterator].questionType === 'open') {
        this.$store.commit("saveQuestionAnswerInterview", {
          answer: {
            interviewQuestion: this.questions[this.queIterator].id,
            interviewAnswer: null,
            openAnswer: this.getChooseInterview,
          },
        });
      }
    },
    nextQue() {
      this.saveAnswer();
      scroll(0, 0);
      this.queIterator++;
    },
    closeTest() {
      clearInterval(this.timerId);
      this.saveAnswer();
      this.$store.dispatch("saveDataInterview", {
        token: this.$store.getters.token,
        status: true,
      });
      errorHandler({ status: 200, page: 'interview' });
      this.$router.push({ name: "responses" });
    },

    closeTestError() {
      clearInterval(this.timerId);
      this.$store.dispatch("saveDataInterview", {
        token: this.$store.getters.token,
        status: false,
      });
      errorHandler({ status: 500, page: 'interview' });

      this.$router.push({ name: "responses" });
    },

    kickInterview() {
      clearInterval(this.timerId);
      this.$store.dispatch("saveDataInterview", {
        token: this.$store.getters.token,
        status: false,
      });
      errorHandler({ status: 406, page: 'interview' });

      this.$router.push({ name: "responses" });
    },

    timeDecrement() {
      this.$store.commit("setPassingTime");
      if (this.getDataInterview.maxPassingTimeSeconds)
        if (this.getDataInterview.maxPassingTimeSeconds <= 0)
          this.kickInterview();
        else {
          this.getDataInterview.maxPassingTimeSeconds--;
        }
    },
    timeParse(time) {
      let minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;
      let hours = Math.floor(time / 3600);
      if (hours === 0) return minutes + ":" + seconds;
      else return hours + ":" + minutes + ":" + seconds;
    },
  },
  data() {
    return {
      queIterator: 0,
      questions: null,
      timerId: {},
    };
  },
  beforeMount() {
    this.questions = this.getDataInterview.questions;
    window.onblur = this.closeTestError;
  },
  destroyed() {
    window.onblur = () => "";
    clearInterval(this.timerId);
  },
  created() {
    this.$store.commit("setInterviewId", this.interviewId);
    this.timerId = setInterval(this.timeDecrement, 1000);
  },
};
</script>



<style scoped>
.account {
  display: flex;
}

.container-account {
  max-width: 1220px;
}

.test__caption {
  display: flex;
  align-items: center;
}

.test__caption-description {
  max-width: 343px;
  width: 100%;
  box-sizing: border-box;
}

.test__caption-description-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 35.5px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.test__caption-description-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: #3b4c68;
}

.test__caption-cancel {
  margin-left: auto;
  background: #f1f1f5;
  border-radius: 7px;
  max-width: 207px;
  width: 100%;
  text-align: center;
}

.test__caption-cancel-btn {
  padding: 17px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #696974;
  transition: opacity 1s ease;
}

.test__caption-cancel-btn:hover {
  opacity: 0.5;
}

.test__wrapper {
  margin-top: 38px;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.test__wrapper-content {
  padding: 46px 63px 59px 62px;
  border-bottom: 1px solid #e9e9f9;
}

.test__wrapper-content-caption {
  display: flex;
}

.test__wrapper-content-caption-question {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 15px;
  line-height: 1.28;
  color: #009ed1;
}

.test__wrapper-content-caption-time {
  margin-left: auto;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 1.28;
  color: #3b4c68;
  opacity: 0.5;
}

.test__wrapper-content-text {
  margin-top: 23px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.test__wrapper-content-answers {
  margin-top: 39px;
  display: flex;
  flex-direction: column;
}

.test__wrapper-nav {
  position: relative;
  padding: 20px 63px 20px 62px;
  display: flex;
  align-items: center;
}

.test__wrapper-nav-last {
  max-width: 218px;
  width: 100%;
  text-align: center;
  background: #eef5f9;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.test__wrapper-nav-last:hover {
  opacity: 0.5;
}

.test__wrapper-nav-last-btn {
  padding: 18px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.test__wrapper-nav-number {
  margin: 0 auto;
  padding: 13px 29px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #82939a;
  background: #edf1f4;
  border-radius: 28px;
}

.test__wrapper-nav-next {
  position: absolute;
  right: 63px;
  cursor: pointer;
  max-width: 207px;
  width: 207px;
  text-align: center;
  background: #009ed1;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.test__wrapper-nav-next:hover {
  opacity: 0.5;
}

.test__wrapper-nav-next-btn {
  padding: 19px 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

@media (max-width: 800px) {
  .container-account {
    padding-top: 77px;
  }

  .test__wrapper-content {
    padding: 20px;
  }

  .test__wrapper-nav {
    padding: 20px;
  }
}

@media (max-width: 700px) {
  .test {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .test__caption {
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
    order: 2;
    flex-direction: column;
    align-items: unset;
  }

  .test__wrapper-content {
    background: #fafafb;
  }

  .test__wrapper-nav {
    background: #fafafb;
  }

  .test__caption-description {
    max-width: 100%;
    width: 100%;
  }

  .test__caption-description-title {
    font-size: 13px;
  }

  .test__caption-description-text {
    font-size: 14px;
  }

  .test__caption-cancel {
    margin: 20px 0 0 0;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .test__caption-cancel-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper {
    margin-top: 0;
    border: none;
  }

  .test__wrapper-content {
    padding: 0 0 40px 0;
  }

  .test__wrapper-content-caption-question {
    font-size: 13px;
  }

  .test__wrapper-content-caption-time {
    font-size: 13px;
  }

  .test__wrapper-content-text {
    margin-top: 10px;
    font-size: 13px;
  }

  .test__wrapper-content-answers {
    margin-top: 16px;
    margin-bottom: 85px;
  }

  .test__wrapper-nav {
    padding: 25px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .test__wrapper-nav-number {
    display: none;
  }

  .test__wrapper-nav-last {
    max-width: 100%;
    margin-right: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .test__wrapper-nav-last-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper-nav-next {
    position: unset;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .test__wrapper-nav-next-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper-content-answers {
    margin-bottom: 0px;
  }
}

/* Video */
.video__wrapper {
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
}

.video__wrapper-filling {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: none;
}

@media (max-width: 700px) {
  .video__wrapper {
    height: 160px;
  }
}

/* /Video */

/* Photo */
.photo__wrapper {
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  height: 300px;
}

.photo__wrapper-img {
  border-radius: 16px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .photo__wrapper {
    height: 160px;
  }
}

/* /Photo*/
</style>