<template>
  <div>
    <div class="chat__wrapper-dialogue-message" :class="{
      'my-message': message.from === 'user' || message.from === 'myself',
      'over-message': message.from !== 'user' && message.from !== 'myself'
    }">
      <p class="chat__wrapper-dialogue-messages-user-text" v-html="message.message.replaceAll('\n', '<br/>')"></p>
      <div class="chat__wrapper-dialogue-message-user-info">
        <time class="chat__wrapper-dialogue-message-user-info-time">{{
          Date.now() - message.createDate > 86400000 ? getDay(message.createDate) : getTime(message.createDate)
        }}
        </time>
        <span :class="{
          'messages-stage-shipped': !message.read,
          'chat__wrapper-dialogue-message-user-info-stage': message.read,
        }"></span>
        <a class="chat__wrapper-dialogue-message-user-info-name" v-if="role === 'admin'">
          {{ role === 'admin' ? $t('compocomponents.account_company.separateCompanyChatMessage.adminName') : '' }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getDate } from "@/lib/dateUtils";
import { getTime } from "@/lib/dateUtils";

export default {
  props: ['message', 'role'],
  name: "SeparateCompanyChatMessage",
  data() {
    return {
      date: null,
      getTime: getTime,
      getDay: getDate
    };
  }
};
</script>


<style scoped>
.chat__wrapper-dialogue-message {
  margin-bottom: 30px;
}

.my-message {
  position: relative;
  padding: 16px;
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #009ed1;
  margin-left: auto;
  border-radius: 10px;
}

.my-message a {
  display: none;
}

.my-message::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/user-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.my-message time {
  font-size: 12px;
  margin-left: auto;
  display: block;
}

.my-message div {
  margin-top: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.my-message span {
  margin-top: -2px;
}

.over-message {
  padding: 16px;
  position: relative;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  background: #e4f6fc;
  border-radius: 10px;
  max-width: 380px;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.over-message::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.over-message p {
  color: inherit;
}

.over-message time {
  font-size: 12px;
  display: block;
}

.over-message span {
  display: none;
}

.over-message div {
  display: flex;
  margin-top: 21px;
  justify-content: space-between;
  align-items: center;
}

.over-message a {
  font-size: 12px;
  opacity: 0.8;
}

.chat__wrapper-dialogue-message-user-info-stage {
  background: url("~@/assets/img/account-img/chat/messages-stage-viewed.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 19px;
}

.messages-stage-shipped {
  margin-left: 6px;
  background: url("~@/assets/img/account-img/chat/messages-stage-shipped.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 9px;
}

@media (max-width: 430px) {
  .my-message {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .my-message time {
    font-size: 11px;
  }

  .over-message {
    padding: 10px;
    font-size: 14px;
    max-width: 260px;
  }

  .over-message div {
    margin-top: 15px;
  }

  .over-message a {
    font-size: 11px;
  }

  .over-message time {
    font-size: 11px;
  }
}
</style>