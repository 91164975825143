<template>
  <div class="news" :class="{ 'news-my': isMyMessage }" @click="$emit('viewBigPost', post)">
    <div class="news-filling">
      <div class="news-community">
        <img :src="post?.communityAvatarUrl" alt="" class="news-persone__face">
        <div class="community-description">
          <div class="description-name">
            <p class="description-name__text">{{ post?.communityName }}</p>
          </div>
          <p class="description__text">{{ post?.postBody ? textSymbolLimit(post?.postBody, 120) : null }}</p>
        </div>
      </div>
    </div>
    <img v-if="post?.postFile" :src="post?.postFile" alt="" class="post-img">
  </div>
</template>

<script>

import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { getDate, getTime } from "@/lib/dateUtils";

export default {
  name: "RepostElement",
  props: ['post', 'isMyMessage'],
  data() {
    return {
      textSymbolLimit, getDate, getTime
    }
  },
};


</script>

<style scoped>
.news {
  cursor: pointer;
  font-family: "Roboto";
  padding: 5px 0;
  background: #e4f6fc;
  border-radius: 10px;
  position: relative;
  max-width: 400px;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-start !important;
  color: #fff !important;
}

.news * {
  color: #666666 !important;
}

.news-my {
  padding: 5px 0;
  background: none;
}

.news-my * {
  color: #fff !important;
}

.news-my {
  color: #fff;
}

.news::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  display: block;
  background-image: url("~@/assets/img/account-img/chat/interlocutor-chat-element.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.news-my::after {
  display: none;
}

.news-caption {
  display: flex;
  align-items: flex-start;
}

.news-caption__icon {
  background: #f7f7f7;
  width: 53px;
  height: 53px;
  object-fit: cover;
  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
}

.caption-info {
  margin-left: 16px;
  max-width: 100%;
}

.caption-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #3b4c68;
}

.caption-info__text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: rgba(23, 23, 37, 0.4000000059604645);
}

.news-caption__time {
  max-width: 97px;
  width: 100%;
  margin-left: auto;
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(59, 76, 104, 0.4000000059604645);
}

.news-filling {
  display: flex;
  align-items: center;
}

.news-community {
  max-width: 300px;
  display: flex;
  align-items: flex-start;
}

.community-description {
  margin-left: 16px;
}

.description-name {
  display: flex;
  align-items: center;
}

.description-name__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.description-name__status {
  margin-left: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #14c686;
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  padding: 5px 6px 4px 6px;
}

.news-persone__face {
  display: flex;
  flex-shrink: 0;
  background: #000000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.description__direction {
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.description__text {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: rgba(23, 22, 37, 0.5);
}

.news-request {
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #009ed1;
  display: flex;
  align-items: center;
}

.news-request svg {
  margin-right: 7px;
}

.post-img {
  margin-top: 16px;
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

@media (max-width: 1770px) {
  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 950px) {
  .news-filling {
    flex-direction: row;
    align-items: center;
  }

  .news-request {
    margin: 0 0 0 auto;
  }
}

@media (max-width: 720px) {
  .news {
    margin-top: 0 !important;
  }

  .news-my {
    padding: 0;
  }

  .caption-info {
    margin-left: 14px;
    max-width: 170px;
  }

  .caption-info__title {
    font-size: 16px;
  }

  .caption-info__text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.4;
  }

  .news-filling {
    flex-direction: column;
    align-items: unset;
  }

  .news-request {
    margin: 20px auto 0 auto;
  }

  .news-persone__face {
    width: 48px;
    height: 48px;
  }

  .community-description {
    margin-left: 14px;
  }

  .description-name__text {
    font-size: 14px;
  }

  .description-name__status {
    font-size: 10px;
  }

  .description__direction {
    font-size: 10px;
  }

  .description__text {
    font-size: 10px;
  }

  .news-request {
    font-size: 12px;
  }
}

/* Modificators */
.status-close {
  background: #ffe3e3;
  color: #b53151;
}
</style>
