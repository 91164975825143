<template>
	<div class="table-wrapper">
		<div class="table" border="1px" v-for="item in recomend" :key="item.id">
			<div class="table-column">
				<separate-document :document-url="item.docUrl" :document-status="item.approved" :index-doc="item.storeId"
					type-doc="recommend" @updateStatus="displayData"></separate-document>
				<div class="select-column">
					<select-form :iter="item.storeId" :select-data="['prorector', 'teacher', 'employer']"
						:select-name="['Vice-rector', 'Teacher', 'Employer']" :select-model-data="item.owner"
						:select-type-data="'recommends'" :select-status="item.approved" @updateSelect="updateSelect"></select-form>
					<button v-if="item.approved !== 'checking' && item.approved !== 'approved'"
						@click="deleteRow(item.id, item.storeId)">
						<img class="close" src="~@/assets/img/all-img/documents/close.svg" alt="" />
					</button>
				</div>
			</div>
		</div>
		<button class="button-add-row" @click="addData">Add a note</button>
	</div>
</template>

<script>
import SeparateDocument from "@/components/account/account_worker/account_worker_documents/item/SeparateDocument";
import SelectForm from "@/components/account/account_worker/account_worker_documents/item/SelectForm";

export default {
	computed: {
		trigger() {
			return this.$store.getters.trigger;
		}
	},
	watch: {
		trigger() {
			this.displayData();
		}
	},
	components: { SelectForm, SeparateDocument },
	name: "RecommendsTable",
	props: {
		data: {
			type: Array,
		},
	},
	data() {
		return {
			recomend: null
		}
	},
	beforeMount() {
		this.displayData();
	},
	methods: {
		updateSelect() {
			this.displayData();
		},
		displayData() {
			let buffer = [];
			for (let i = 0; i < this.data.length; i++) {
				if (this.data[i].delete !== true) {
					buffer.push({
						docUrl: this.data[i].docUrl,
						id: this.data[i].id,
						owner: this.data[i].owner,
						approved: this.data[i].approved,
						storeId: i
					});
				}
			}
			this.recomend = buffer;
		},
		addData() {
			this.$store.commit("addRecommendsData");
			this.displayData();

		},
		deleteRow(id, index) {
			this.$store.commit('deleteRecomm', {
				id: id,
				index: index
			})
			this.displayData();
		}
	}
};
</script>

<style scoped>
.table {
	margin-top: 20px;
	width: 100%;
	max-width: 100%;
	padding-bottom: 50px;
	box-sizing: border-box;
	border: none;
	display: flex;
}

.table-column {
	width: 100%;
	max-width: 300px;
	box-sizing: border-box;
	position: relative;
}

.table-column-feald {
	max-width: 300px;
	width: 100%;
	display: flex;
	flex-shrink: 0;
	width: 300px;
	margin-right: 40px;
}

.table-wrapper {
	width: 100%;
	box-sizing: border-box;
	position: relative;
	padding-bottom: 50px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.button-add-row {
	display: flex;
	margin: 0 auto;
}

.table-row {
	position: relative;
}

.button-add-row {
	margin: 0 auto;
	display: flex;
	margin-top: 23px;
	background: #009ed1;
	border-radius: 7px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	padding: 15px 60px;
	color: #ffffff;
	position: absolute;
	bottom: -25px;
	left: calc(50% - 132px);
}

.close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 10;
	display: block;
	width: 14px;
	height: 14px;
}

.select-column {
	margin-top: 20px;
}

@media (max-width: 1125px) {
	.table-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 816px) {
	.table-wrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.button-add-row {
		position: unset;
	}

	.close {
		margin-top: 40px;
		position: unset;
	}

	.select-column {
		text-align: center;
	}
}
</style>