import {config} from "@/lib/config";

export const interview = {
    actions: {
        fetchInterview({commit}, payload) {
            let data;
            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+"interview/get/" + payload.interviewId;
            xmlHttp.open('GET', url, false);
            xmlHttp.setRequestHeader('X-Auth-Token', payload.token);
            try {
                xmlHttp.send();
                let response = JSON.parse(xmlHttp.response);
                data = response.data;
            } catch (e) {
                console.log(e);
            }
            commit('setInterview', data);
        },
        saveDataInterview({commit, state}, payload) {
            if (!payload.status)
                commit('setNullAnswerInterview');


            let xmlHttp = new XMLHttpRequest();
            let url = config.apiPrefix+'interview/pass';
            xmlHttp.open('POST', url, false);
            xmlHttp.setRequestHeader('X-Auth-Token', payload.token);
            xmlHttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
            try {
                xmlHttp.send(JSON.stringify(state.saveInterview));
            } catch (e) {
                console.log(e);
            }
        }
    },
    mutations: {
        setPassingTime(state) {
            state.saveInterview.passingTimeSeconds++;
        },
        setInterview(state, data) {
            let questions = [];
            for (let i = 0; i < data.questions?.length; i++) {
                if (!data.questions[i].deleted) {
                    questions.push(data.questions[i]);
                }
            }

            for (let i = 0; i < questions.length; i++) {
                let answers = [];
                for (let j = 0; j < questions[i].answers?.length; j++)
                    if (!questions[i].answers[j].deleted)
                        answers.push(questions[i].answers[j]);
                questions[i].answers=answers;
            }
            data.questions = questions;
            state.dataInterview = data;
        },
        setInterviewId(state, data) {
            state.saveInterview.interviewId = data;
            state.saveInterview.passingTimeSeconds = 0;
            state.saveInterview.answers = [];
        },
        saveQuestionAnswerInterview(state, payload) {
            state.saveInterview.answers.push(payload.answer)
        },
        setChooseInterview(state, data) {
            state.choose = data;
        },
        setStatusInterview(state, data) {
            state.status = data;
        },
        setNullAnswerInterview(state) {
            state.saveInterview.answers = null;
            state.saveInterview.passingTimeSeconds = 0;
        }

    },
    state: {
        dataInterview: null,
        saveInterview: {
            passingTimeSeconds: null,
            interviewId: null,
            answers: null
        },
        choose: null,
        status: null
    },
    getters: {
        getDataInterview(state) {
            return state.dataInterview;
        },
        getChooseInterview(state) {
            return state.choose;
        },
        getSaveSettingInterview(state) {
            return state.saveInterview
        },
        getStatusInterview(state) {
            return state.status
        }
    }
}