<template>
	<div>
		<Header></Header>

		<main class="main">
			<div class="registration">
				<div class="registration__img">
					<img src="~@/assets/img/registration-page/background-logo.svg" alt=""
						class="registration__img-logo" />
				</div>
				<div class="registration__wrapper">
					<div class="registration__wrapper-authorization">
						<div class="registration__wrapper-authorization-who">
							<p class="registration__wrapper-authorization-text">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.registration') }}
								<span>*</span>
							</p>
							<h1 class="registration__wrapper-authorization-title">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.createAccount') }}
								<span>*</span>
							</h1>
							<div class="registration__wrapper-authorization-who-btns">
								<router-link :to="{ name: 'employerReg' }"
									class="registration__wrapper-authorization-who-btns-employer">
									{{ $t('pages.authorization.authorizationWorkerRegistrationPage.employer') }}
								</router-link>
								<button class="registration__wrapper-authorization-who-btns-worker">
									{{ $t('pages.authorization.authorizationWorkerRegistrationPage.jobSeeker') }}
								</button>
							</div>
							<div class="registration__wrapper-help">
								<router-link :to="{ name: 'candidateLogin' }"
									class="registration__wrapper-help-registration">
									{{ $t('pages.authorization.authorizationWorkerRegistrationPage.alreadyHaveAccount')
									}}
								</router-link>
							</div>
						</div>
						<div class="registration__wrapper-authorization-fields">
							<p class="registration__wrapper-authorization-designation">{{
								$t('pages.authorization.authorizationWorkerRegistrationPage.fullName') }}<span>*</span>
							</p>
							<input id="initials" type="text" :placeholder="$t('pages.authorization.authorizationWorkerRegistrationPage.enterFullName')" v-model="dataCandidate.name"
								class="registration__wrapper-authorization-mail" />
							<div v-if="$v.dataCandidate.name.$error" class="error-message">
								<span>{{ $t('pages.authorization.authorizationWorkerRegistrationPage.nameRequired')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.dateOfBirth')
								}}<span>*</span>
							</p>
							<input type="date" v-model="dataCandidate.birthDate"
								class="registration__wrapper-authorization-mail" />
							<div v-if="$v.dataCandidate.birthDate.$error" class="error-message">
								<span v-if="!$v.dataCandidate.birthDate.required">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.dateOfBirthRequired')
									}}</span>
								<span v-else-if="!$v.dataCandidate.birthDate.beforeToday">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.birthDateFuture')
									}}</span>
								<span v-else-if="!$v.dataCandidate.birthDate.isDateReasonable">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.birthDateUnreasonable')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.phoneNumber')
								}}<span>*</span>
							</p>
							<PhoneMaskInput showFlag v-model="dataCandidate.phone" :placeholder="$t('pages.authorization.authorizationWorkerRegistrationPage.enterPhoneNumber')"
								wrapperClass="registration__wrapper-authorization-mail" style="width: 100%;"></PhoneMaskInput>
							<div v-if="$v.dataCandidate.phone.$error" class="error-message">
								<span>{{ $t('pages.authorization.authorizationWorkerRegistrationPage.phoneIncorrect')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.email') }}<span>*</span>
							</p>
							<input type="email" :placeholder="$t('pages.authorization.authorizationWorkerRegistrationPage.enterEmail')" v-model="dataCandidate.email"
								class="registration__wrapper-authorization-mail" />
							<div v-if="$v.dataCandidate.email.$error" class="error-message">
								<span v-if="!$v.dataCandidate.email.required">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.emailRequired')
									}}</span>
								<span v-else-if="!$v.dataCandidate.email.email">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.emailInvalid') }}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.password')
								}}<span>*</span>
							</p>
							<div class="registration__wrapper-authorization-password">
								<input type="password" :placeholder="$t('pages.authorization.authorizationWorkerRegistrationPage.enterPassword')" v-model="dataCandidate.password"
									class="registration__wrapper-authorization-password-input" />
							</div>
							<div v-if="$v.dataCandidate.password.$error" class="error-message">
								<span>{{ $t('pages.authorization.authorizationWorkerRegistrationPage.passwordInvalid')
									}}</span>
							</div>
							<p class="registration__wrapper-authorization-designation">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.confirmPassword')
								}}<span>*</span>
							</p>

							<div class="registration__wrapper-authorization-password">
								<input type="password" :placeholder="$t('pages.authorization.authorizationWorkerRegistrationPage.confirmPassword')"
									v-model="dataCandidate.checkPassword"
									class="registration__wrapper-authorization-password-input"
									@blur="$v.dataCandidate.checkPassword.$touch()" />
							</div>
							<div v-if="$v.dataCandidate.checkPassword.$error" class="error-message">
								<span v-if="!$v.dataCandidate.checkPassword.sameAsPassword">{{
									$t('pages.authorization.authorizationWorkerRegistrationPage.passwordsMustMatch')
									}}</span>
							</div>

							<button class="registration__wrapper-authorization-enter-btn" @click="saveData">
								{{ $t('pages.authorization.authorizationWorkerRegistrationPage.register') }}
							</button>
						</div>
					</div>

				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import { errorHandler } from "@/lib/errorHandler";
import { config } from "@/lib/config";
import PhoneMaskInput from "vue-phone-mask-input";
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

const beforeToday = value => {
	const today = new Date();
	const inputDate = new Date(value);
	today.setHours(0, 0, 0, 0);
	return inputDate <= today;
};

const isDateReasonable = value => {
	const minDate = new Date('1900-01-01');
	const birthDate = new Date(value);
	return birthDate >= minDate;
};

export default {
	components: { Header, PhoneMaskInput },
	beforeMount() {
		scroll(0, 0);
	},
	mixins: [validationMixin],
	validations: {
		dataCandidate: {
			name: { required, maxLength: maxLength(100) },
			birthDate: { required, beforeToday, isDateReasonable },
			phone: { required, minLength: minLength(10), maxLength: maxLength(14) },
			email: { required, email, maxLength: maxLength(100) },
			password: {
				required,
				maxLength: maxLength(100),
				minLength: minLength(8),
				hasUpperCase: value => /[A-Z]/.test(value),
				hasLowerCase: value => /[a-z]/.test(value),
				hasNumeric: value => /[0-9]/.test(value),
				hasSpecial: value => /[\W]/.test(value)
			},
			checkPassword: {
				sameAsPassword: sameAs('password')
			}
		}
	},
	data() {
		return {
			dataCandidate: {
				name: null,
				surname: null,
				lastName: null,
				birthDate: null,
				password: null,
				phone: null,
				email: null,
				checkPassword: null,
			}
		};
	},
	methods: {
		nameValidation() {
			let name = document.getElementById('initials');
			name.addEventListener('keypress', e => {
				if (this.dataCandidate.name) {
					let count = 0;
					for (let i = 0; i < this.dataCandidate.name.length; i++) {
						if (this.dataCandidate.name[i] === ' ')
							++count;
					}
					if (e.code !== 'Backspace')
						if (count === 3) {
							e.preventDefault();
						}
				}
			});
		},
		checkEmail(value) {
			const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return regex.test(value);
		},
		repeatingChar(value) {
			const element = value[0];
			let count = 0;
			for (let i = 0; i < value.length; i++) {
				if (value[i] === element)
					count++;
			}
			return count !== value.length;
		},
		phoneValidation() {
			let phone = document.getElementById('phone');
			phone.addEventListener('keypress',
				e => {
					if (!/\d/.test(e.key))
						e.preventDefault();
				}
			);
		},
		checkNullField() {
			let stack = [];
			if (!this.dataCandidate.name || this.dataCandidate.name.replace(/\s/g, "") === '')
				stack.push('name');
			if (!this.dataCandidate.birthDate)
				stack.push('date');
			if (!this.dataCandidate.phone || this.dataCandidate.phone.replace(/\s/g, "") === '')
				stack.push('phone');
			if (!this.dataCandidate.email || !this.checkEmail(this.dataCandidate.email) || this.dataCandidate.email.replace(/\s/g, '') === '')
				stack.push('email');
			if (!this.dataCandidate.password || !this.repeatingChar(this.dataCandidate.password))
				stack.push('passwordReplay');
			if (!this.dataCandidate.checkPassword || this.dataCandidate.checkPassword !== this.dataCandidate.password)
				stack.push('passwordNotMatch');
			if (stack.length !== 0) {
				errorHandler({ status: 400, page: 'invalidField', stack: stack });
				stack = [];
				return false;
			} else {
				return true;
			}
		},
		saveData() {
			let a = false;
			this.$v.$touch();
			if (this.$v.$invalid) {
				a = true;
			}
			if (this.checkNullField()) {
				if (a === true) {
					return;
				}

				let data = {
					name: this.dataCandidate.name,
					surname: this.dataCandidate.name,
					lastName: this.dataCandidate.name,
					birthDate: Date.parse(this.dataCandidate.birthDate),
					password: this.dataCandidate.password,
					phone: this.dataCandidate.phone,
					email: this.dataCandidate.email,
				};
				let xmlHhtp = new XMLHttpRequest();
				let url = config.apiPrefix + "candidate/register";
				xmlHhtp.open("POST", url, false);
				xmlHhtp.setRequestHeader(
					"Content-Type",
					"application/json;charset=UTF-8"
				);
				try {
					xmlHhtp.send(JSON.stringify(data));
					errorHandler({ status: xmlHhtp.status, page: "reg" });
					this.$router.push({ name: "candidateLogin" });
				} catch (e) {
					console.log(e);
				}
			}
		},
	},
	name: "AuthorizationWorkerRegistrationPage",
	mounted() {
		this.nameValidation();
	}
};
</script>


<style scoped>
.error-message {
	color: red;
	font-size: 12px;
}

.registration {
	display: flex;
	padding-top: 58px;
	padding-right: 40px;
	align-items: flex-start;
}

.registration__img {
	padding: 45px 0;
	display: flex;
	max-width: 733px;
	height: calc(100vh - 59px);
	position: sticky;
	top: 59px;
	width: 100%;
	background: url("~@/assets/img/login-page/background.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.registration__img-logo {
	max-width: 328px;
	margin: auto;
	height: 187px;
}

.registration__wrapper {
	padding-top: 61px;
	padding-bottom: 20px;
	margin: auto auto auto 144px;
	max-width: 683px;
	width: 100%;
}

.registration__wrapper-authorization {
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 8px;
	width: 100%;
	box-sizing: border-box;
}

.registration__wrapper-authorization-who {
	padding: 27px 63px 37px 63px;
	border-bottom: 2px solid #f6f4f3;
}

.registration__wrapper-authorization-fields {
	padding: 9px 63px 34px 63px;
}

.registration__wrapper-authorization-text {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.registration__wrapper-authorization-title {
	padding-top: 5px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 35px;
	color: #000000;
	padding-bottom: 16px;
}

.registration__wrapper-authorization-who-btns {
	align-items: center;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	border: 2px solid #009ed1;
	border-radius: 10px;
}

.registration__wrapper-authorization-who-btns-employer {
	width: 100%;
	box-sizing: border-box;
	padding: 16px 0;
	display: block;
	border-radius: 10px 0px 0px 10px;
	background: #fff;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	color: #009ed1;
	transition: color 1s ease, background 1s ease, border-radius 1s ease;
}

.registration__wrapper-authorization-who-btns-employer:hover {
	background: #009ed1;
	color: #fff;
	border-radius: 5px 0px 0px 5px;
}

.registration__wrapper-authorization-who-btns-border {
	display: block;
	content: "";
	height: 100%;
	width: 2px;
	background: #009ed1;
}

.registration__wrapper-authorization-who-btns-worker {
	width: 100%;
	box-sizing: border-box;
	height: 100%;
	border-radius: 0px 5px 5px 0px;
	background: #009ed1;
	font-family: "Inter";
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	color: #fff;
	display: block;
	padding: 16px 0;
}

.registration__wrapper-authorization-designation {
	margin-top: 21px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.registration__wrapper-authorization-designation span {
	color: red;
}

.registration__wrapper-authorization-mail {
	display: flex;
	align-items: center;
	margin-top: 8px;
	width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #171725;
	box-sizing: border-box;
}

.registration__wrapper-authorization-mail::placeholder {
	color: #b7c1d1;
}

.registration__wrapper-authorization-password {
	margin-top: 8px;
	width: 100%;
	border: none;
	align-items: center;
}

.registration__wrapper-authorization-password-input {
	border: 1px solid #bcbcbc;
	width: 100%;
	box-sizing: border-box;
	padding: 13px 16px;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #171725;
}

.registration__wrapper-authorization-password-input::placeholder {
	color: #b7c1d1;
}

.registration__wrapper-authorization-enter-btn {
	margin-top: 31px;
	width: 100%;
	box-sizing: border-box;
	height: 65px;
	background: #009ed1;
	border-radius: 7px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #ffffff;
}

.registration__wrapper-authorization-fields-text {
	padding-top: 22px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	text-align: center;
	color: #9aafb6;
}

.registration__wrapper-help {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.registration__wrapper-help-registration {
	text-decoration: underline;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.25px;
	color: #009ed1;
}

.mobile {
	display: none;
}

@media (max-width: 1250px) {
	.registration__wrapper {
		margin: auto auto auto 80px;
	}
}

@media (max-width: 1000px) {
	.registration {
		flex-direction: column;
		padding-right: 0;
	}

	.registration__img {
		display: none;
	}

	.registration__wrapper {
		box-sizing: border-box;
		margin: 50px auto auto auto;
		max-width: 683px;
		width: 100%;
		padding: 0px 20px 30px 20px;
	}
}

@media (max-width: 950px) {
	.registration {
		padding-top: 48px;
	}
}

@media (max-width: 600px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.registration__wrapper {
		margin: 28px auto auto auto;
	}

	.registration__wrapper-authorization {
		padding: 0;
		border: none;
		background: inherit;
	}

	.registration__wrapper-authorization-who {
		padding: 0 10px 20px 10px;
	}

	.registration__wrapper-authorization-fields {
		padding: 8px 10px 0px 10px;
	}

	.registration__wrapper-authorization-who-btns {
		margin-top: 22px;
	}

	.registration__wrapper-authorization-who-btns-employer {
		padding: 13px 0;
		font-size: 12px;
	}

	.registration__wrapper-authorization-who-btns-worker {
		font-size: 12px;
		padding: 13px 0;
	}

	.registration__wrapper-authorization-text {
		font-size: 13px;
	}

	.registration__wrapper-authorization-title {
		font-size: 20px;
		padding-bottom: 0;
	}

	.registration__wrapper-authorization-designation {
		margin-top: 14px;
	}

	.registration__wrapper-authorization-mail {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorizationpassword-input {
		font-size: 14px;
		padding: 12px 18px;
	}

	.registration__wrapper-authorization-password-input {
		padding: 12px 18px;
		font-size: 14px;
	}

	.registration__wrapper-authorization-enter-btn {
		margin-top: 50px;
		height: 42px;
		border-radius: 10px;
		font-size: 13px;
	}

	.registration__wrapper-authorization-fields-fields-addfields {
		margin-top: 13px;
		width: 100%;
		padding: 13px 0;
		border: 1px solid #009ed1;
		border-radius: 10px;
		font-family: "Roboto";
		font-weight: 500;
		font-size: 13px;
		text-align: center;
		color: #009ed1;
	}

	.registration__wrapper-authorization-fields-text {
		font-size: 12px;
	}

	.registration__wrapper-help {
		margin-top: 20px;
	}

	.registration__wrapper-help-registration {
		padding-right: 0px;
		font-size: 13px;
	}
}
</style>
