<template>
	<div>
		<HeaderAccountWorker></HeaderAccountWorker>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>
				<div class="container-account">
					<loading :active="loader" :is-full-page="true" />
					<p class="documents__text mobile">Personal cabinet</p>
					<h1 class="documents__title-first">My documents</h1>
					<p class="documents__text desctop">Welcome!</p>
					<div class="documents__line">
						<h2 class="documents__title">School certificate</h2>
						<div class="documents-wrapper">
							<SeparateDocument :document-url="allDocuments.schoolAtestatePhotoUrl"
								:document-status="allDocuments.schoolAtestateApproved" type-doc="attest" :index-doc="0">
							</SeparateDocument>
							<school-atestate :data="allDocuments.schoolAtestate" :status="allDocuments.schoolAtestateApproved">
							</school-atestate>
						</div>
					</div>
					<div class="documents__line">
						<h2 class="documents__title">School medal</h2>
						<div class="documents__group-element-caption-stage">
							<div class="documents__group-element-caption-stage-circle" :class="{
								'documents__group-element-caption-stage-circle':
									allDocuments.schoolMedalApproved === 'unloaded' ||
									allDocuments.schoolMedalApproved === null ||
									allDocuments.schoolMedalApproved === undefined,
								'stage-circle-blue':
									allDocuments.schoolMedalApproved === 'checking',
								'stage-circle-green':
									allDocuments.schoolMedalApproved === 'approved',
								'stage-circle-red':
									allDocuments.schoolMedalApproved === 'rejected',
							}"></div>
							<p class="documents__group-element-caption-stage-text">
								{{ statusMedal }}
							</p>
						</div>
						<select-form :select-data="['gold', 'silver', 'none']" :select-name="['Golden', 'Silver', 'none']"
							:select-model-data="allDocuments.schoolMedal" :select-type-data="'atestat'"
							:select-status="allDocuments.schoolMedalApproved"></select-form>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">School olympiads</h2>
						<school-olympiad :data="allDocuments.schoolOlimpiads"></school-olympiad>
					</div>

					<div class="documents__line">
						<h2 class="documents__title mb20">Grants for education</h2>
						<SeparateDocument :document-url="allDocuments.grandDocUrl" :document-status="allDocuments.grandDocApproved"
							type-doc="grand" :index-doc="0"></SeparateDocument>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">SAT</h2>
						<div class="documents-wrapper">
							<SeparateDocument :document-url="allDocuments.egePhotoUrl" :document-status="allDocuments.egeApproved"
								type-doc="ege" :index-doc="0"></SeparateDocument>
							<ege-table :data="allDocuments.egeScores" :status="allDocuments.egeApproved"></ege-table>
						</div>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Bachelor's diploma</h2>
						<div class="documents-wrapper">
							<SeparateDocument :document-url="allDocuments.bakalavrAtestatePhotoUrl"
								:document-status="allDocuments.bakalavrAtestateApproved" type-doc="baklava" :index-doc="0">
							</SeparateDocument>
							<bakalavar-diplom-table :data="allDocuments.bakalavrAtestate"
								:status="allDocuments.bakalavrAtestateApproved"></bakalavar-diplom-table>
						</div>
					</div>

					<div class="documents__line">
						<h2 class="documents__title mb20">Named scholarships</h2>
						<SeparateDocument :document-url="allDocuments.stipendyPhotoUrl"
							:document-status="allDocuments.stipendyApproved" type-doc="stipend" :index-doc="0"></SeparateDocument>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Scientific works</h2>
						<science-works-table :data="allDocuments.scienceWorks"></science-works-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Other educational programs</h2>
						<edu-table :data="allDocuments.addEduDocs"></edu-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title mb20">MBA Diploma</h2>
						<SeparateDocument :document-url="allDocuments.mwaDiplomUrl" :document-status="allDocuments.mwaApproved"
							type-doc="mwa" :index-doc="0"></SeparateDocument>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Recommendations</h2>
						<recommends-table ref="recommend" :data="allDocuments.recommends">
						</recommends-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">
							Participation in professional competitions
						</h2>
						<professional-competition-table :data="allDocuments.profCompetition"></professional-competition-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">International Olympiads</h2>
						<school-olympiad-by-title-table :data="allDocuments.schoolOlympiads"></school-olympiad-by-title-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">
							Participate in events in an active role
						</h2>
						<event-activity-table :data="allDocuments.eventsActivity"></event-activity-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Volunteering</h2>
						<volonter-activity-table :data="allDocuments.volonteerActivity"></volonter-activity-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Work in public organizations</h2>
						<public-work-table :data="allDocuments.publicWork"></public-work-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">Work during the study period</h2>
						<div class="documents-wrapper documents-wrapper-textarea">
							<separate-document :document-status="allDocuments.studyAndWorkApproved"
								:document-url="allDocuments.studyAndWorkUrl" type-doc="study&work" :index-doc="0"></separate-document>
							<textarea
								:disabled="allDocuments.studyAndWorkApproved === 'checking' || allDocuments.studyAndWorkApproved === 'approved'"
								placeholder="Description" v-model="allDocuments.studyAndWorkDescription" />
						</div>
					</div>

					<div class="documents__line pb50">
						<h2 class="documents__title">
							Level of information technology proficiency
						</h2>
						<div class="documents-wrapper documents-wrapper-textarea">
							<separate-document :document-status="allDocuments.infoTechApproved"
								:document-url="allDocuments.infoTechKnowDocUrl" type-doc="info&tech" :index-doc="0"></separate-document>
							<textarea
								:disabled="allDocuments.infoTechApproved === 'checking' || allDocuments.infoTechApproved === 'approved'"
								placeholder="Description" v-model="allDocuments.infoTechKnowDocDescription" />
						</div>
					</div>

					<div class="documents__line pb50">
						<h2 class="documents__title">International certificates</h2>
						<world-cerificate-table :data="allDocuments.worldCerificate"></world-cerificate-table>
					</div>

					<div class="documents__line pb50">
						<h2 class="documents__title">Internships and study abroad</h2>
						<study-abroad-table :data="allDocuments.studyAbroad"></study-abroad-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">
							Published translations (articles, books)
						</h2>
						<translations-table :data="allDocuments.translations"></translations-table>
					</div>

					<div class="documents__line">
						<h2 class="documents__title">The number of social followers networks</h2>
						<socials-table :data="allDocuments.socials"></socials-table>
					</div>

					<div class="documents__line pb50">
						<h2 class="documents__title mb20">The work of the head of the group</h2>
						<separate-document :document-status="allDocuments.groupOlderApproved"
							:document-url="allDocuments.groupOlderUrl" type-doc="group&older" :index-doc="0"></separate-document>
					</div>

					<div class="documents__line">
						<h2 class="documents__title mb20">The presence of a red diploma</h2>
						<div class="documents__group-element-caption-stage">
							<div class="documents__group-element-caption-stage-circle" :class="{
								'documents__group-element-caption-stage-circle':
									allDocuments.redDiplomApproved === 'unloaded' ||
									allDocuments.redDiplomApproved === undefined ||
									allDocuments.redDiplomApproved === null,
								'stage-circle-blue':
									allDocuments.redDiplomApproved === 'checking',
								'stage-circle-green':
									allDocuments.redDiplomApproved === 'approved',
								'stage-circle-red':
									allDocuments.redDiplomApproved === 'rejected',
							}"></div>
							<p class="documents__group-element-caption-stage-text">
								{{ statusRedDiplome }}
							</p>
						</div>
						<div class="documents-wrapper wrapper-radio">
							<input
								:disabled="allDocuments.redDiplomApproved === 'checking' || allDocuments.redDiplomApproved === 'approved'"
								class="radio-input" type="radio" id="one" v-model="allDocuments.redDiplom" :value="true" @change="
									$store.commit('changeRedDiplom', allDocuments.redDiplom)
								" />
							<label class="radio-label" for="one">There is</label>
							<input
								:disabled="allDocuments.redDiplomApproved === 'checking' || allDocuments.redDiplomApproved === 'approved'"
								type="radio" id="two" v-model="allDocuments.redDiplom" :value="false" @change="
									$store.commit('changeRedDiplom', allDocuments.redDiplom)
								" />
							<label for="two">none</label>
						</div>
					</div>

					<div class="documents__line">
						<button @click="saveData" class="all-save">Save</button>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import RecommendsTable from "@/components/account/account_worker/account_worker_documents/item/RecommendsTable";
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";
import SchoolAtestate from "@/components/account/account_worker/account_worker_documents/item/SchoolAtestateTable";
import SeparateDocument
	from "../../../components/account/account_worker/account_worker_documents/item/SeparateDocument.vue";
import SelectForm from "@/components/account/account_worker/account_worker_documents/item/SelectForm";
import SchoolOlympiad from "@/components/account/account_worker/account_worker_documents/item/SchoolOlympiadTable";
import EgeTable from "@/components/account/account_worker/account_worker_documents/item/EgeTable";
import BakalavarDiplomTable
	from "@/components/account/account_worker/account_worker_documents/item/BakalavarDiplomTable";
import ScienceWorksTable from "@/components/account/account_worker/account_worker_documents/item/ScienceWorksTable";
import EduTable from "@/components/account/account_worker/account_worker_documents/item/EduTable";
import ProfessionalCompetitionTable
	from "@/components/account/account_worker/account_worker_documents/item/ProfessionalCompetitionTable";
import SchoolOlympiadByTitleTable
	from "@/components/account/account_worker/account_worker_documents/item/SchoolOlympiadByTitleTable";
import EventActivityTable from "@/components/account/account_worker/account_worker_documents/item/EventActivityTable";
import PublicWorkTable from "@/components/account/account_worker/account_worker_documents/item/PublicWorkTable";
import WorldCerificateTable
	from "@/components/account/account_worker/account_worker_documents/item/WorldCerificateTable";
import StudyAbroadTable from "@/components/account/account_worker/account_worker_documents/item/StudyAbroadTable";
import SocialsTable from "@/components/account/account_worker/account_worker_documents/item/SocialsTable";
import TranslationsTable from "@/components/account/account_worker/account_worker_documents/item/TranslationsTable";
import VolonterActivityTable
	from "@/components/account/account_worker/account_worker_documents/item/VolonterActivityTable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	computed: {
		allDocuments() {
			return this.$store.getters.getAllDocuments;
		},
		files() {
			return this.$store.getters.documentsFiles;
		},
		loader() {
			return this.$store.getters.docLoading;
		},
	},
	components: {
		Loading,
		VolonterActivityTable,
		TranslationsTable,
		SocialsTable,
		StudyAbroadTable,
		WorldCerificateTable,
		PublicWorkTable,
		EventActivityTable,
		SchoolOlympiadByTitleTable,
		ProfessionalCompetitionTable,
		EduTable,
		ScienceWorksTable,
		BakalavarDiplomTable,
		EgeTable,
		SchoolOlympiad,
		SelectForm,
		SchoolAtestate,
		MenuWorker,
		HeaderAccountWorker,
		SeparateDocument,
		RecommendsTable,
	},
	name: "AccountWorkerDocumentsPage",
	beforeMount() {
		this.getDocument();
		this.updateStatusTitle();
	},
	data() {
		return {
			statusMedal: null,
			statusRedDiplome: null,
		};
	},
	methods: {
		getDocument() {
			scroll(0, 0);
			this.$store.dispatch("fetchAllDocuments", this.$store.getters.token);
			this.$store.commit('upTrigger');
		},
		updateStatusTitle() {
			switch (this.allDocuments.schoolMedalApproved) {
				case "unloaded":
					this.statusMedal = "Requires download";
					break;
				case "checking":
					this.statusMedal = "Under review";
					break;
				case "approved":
					this.statusMedal = "Checked";
					break;
				case "rejected":
					this.statusMedal = "Rejected";
					break;
				case undefined:
					this.statusMedal = "Requires download";
					break;
				case null:
					this.statusMedal = "Requires download";
					break;
			}

			switch (this.allDocuments.redDiplomApproved) {
				case "unloaded":
					this.statusRedDiplome = "Requires download";
					break;
				case "checking":
					this.statusRedDiplome = "Under review";
					break;
				case "approved":
					this.statusRedDiplome = "Checked";
					break;
				case "rejected":
					this.statusRedDiplome = "Rejected";
					break;
				case undefined:
					this.statusRedDiplome = "Requires download";
					break;
				case null:
					this.statusRedDiplome = "Requires download";
					break;
			}
		},
		test() {
		},
		saveDataRequest() {
			this.$store.dispatch("saveAllDocument", {
				token: this.$store.getters.token,
				callback: this.getDocument,
			});
		},
		saveData() {
			if (this.files.length > 0) {
				this.$store.dispatch("loadAllFiles", {
					token: this.$store.getters.token,
					callback: this.saveDataRequest
				});
			} else this.saveDataRequest();
		},
	},
};
</script>


<style scoped>
.account {
	display: flex;
}

.documents__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 20px;
	line-height: 32px;
	color: #3b4c68;
}

.documents__title-first {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.documents__text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.documents-wrapper {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	margin-top: 20px;
	max-width: 100%;
}

.documents__line {
	margin-top: 54px;
}

.documents__line-title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #3b4c68;
}

.documents__line-container {
	margin-top: 15px;
	width: 100%;
	height: 6px;
	background: #eceef1;
	border-radius: 55px;
}

.documents__line-container-filling {
	height: 6px;
	background: #14c686;
	border-radius: 55px;
}

.documents__group {
	margin-top: 67px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 40px;
}

.mobile {
	display: none;
}

.mb20 {
	margin-bottom: 20px;
}

.documents-wrapper-textarea textarea ::placeholder {
	color: #c7c7cc;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.documents-wrapper-textarea textarea {
	margin-left: 40px;
	display: flex;
}

.documents-wrapper-textarea textarea {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	height: 342px;
}

.documents-wrapper input[type="radio"] {
	display: none;
}

.documents-wrapper label {
	display: inline-block;
	cursor: pointer;
	position: relative;

	margin-right: 60px;
	line-height: 18px;
	user-select: none;
}

.documents-wrapper label:before {
	content: "";
	display: inline-block;
	width: 25px;
	height: 25px;
	position: absolute;
	left: 48px;
	bottom: -5px;
	border-radius: 100%;
	padding: 5px;
	border: 2px solid rgba(59, 76, 104, 0.5);
	box-sizing: border-box;
}

/* Checked */

.documents-wrapper input[type="radio"]:checked+label:before {
	background: #009ed1;
	border: none;
}

.all-save {
	margin: 0 auto;
	margin-top: 0px;
	margin-top: 0px;
	display: flex;
	margin-top: 20px;
	background: #009ed1;
	border-radius: 7px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	padding: 15px 60px;
	color: #ffffff;
	width: 265px;
	text-align: center;
	display: block;
}

.mb20 {
	margin-bottom: 20px;
}

@media (max-width: 1670px) {
	.documents__group {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 1046px) {
	.documents__group {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 13px;
	}
}

@media (max-width: 816px) {
	.table {
		display: flex;
	}

	.documents-wrapper {
		flex-direction: column;
	}

	.documents__line {
		margin-top: 30px;
		max-width: 300px;
		width: 100%;
		margin: 30px auto 0 auto;
	}

	.documents__title-first {
		text-align: center;
	}

	.documents__title {
		text-align: center;
	}

	.profile-container {
		margin: 0 auto 0 auto;
	}

	.documents__group {
		grid-template-columns: repeat(2, 1fr);
	}

	.mobile {
		display: block;
	}

	.documents__text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.desctop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.documents {
		padding-top: 28px;
	}

	.documents__text {
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		text-align: center;
		color: #009ed1;
	}

	.documents__title {
		text-align: center;
		font-size: 20px;
	}

	.documents__line {
		margin-top: 28px;
		text-align: center;
	}

	.documents__line-container {
		margin: 15px auto 0 auto;
		max-width: 300px;
	}

	.documents__group {
		margin-top: 40px;
		grid-template-columns: repeat(1, 1fr);
	}

	.documents-wrapper-textarea textarea {
		margin-left: 0;
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
		margin: 20px auto 0 auto;
	}

	.all-save {
		margin-top: 60px;
		width: 100%;
	}

	.wrapper-radio {
		flex-direction: row;
	}

	.pb50 {
		padding-bottom: 50px;
	}

	.pb30 {
		padding-bottom: 30px;
	}
}

@media (max-width: 330px) {
	.documents__text {
		text-align: left;
	}

	.documents__title {
		text-align: left;
	}

	.documents__line {
		text-align: left;
	}

	.documents__title-first {
		text-align: left;
	}

	.all-save {
		width: 100%;
	}
}

.documents__group-element-caption-stage-circle {
	width: 11px;
	height: 11px;
	background: #c4c4c4;
	border-radius: 100%;
}

.stage-circle-green {
	background: #14c686;
}

.stage-circle-blue {
	background: #009ed1;
}

.stage-circle-red {
	background: #ff3c47;
}

.documents__group-element-caption-stage {
	display: flex;
	align-items: center;
	margin: 10px 0;
}

.documents__group-element-caption-stage-text {
	margin-left: 11px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #3b4c68;
}
</style>
