<template>
	<div>
		<HeaderAccount></HeaderAccount>

		<main class="main">
			<div class="account">
				<MenuWorker></MenuWorker>

				<div class="container-account">
					<div class="test">
						<div class="test__wrapper">
							<h1 class="test__wrapper-title">{{
								$t('pages.interview.interviewSeparatePreviewPage.testingTitle') }}</h1>

							<div class="test__wrapper-description">
								<h2 class="test__wrapper-description-title">
									{{ getDataInterview.title }}
								</h2>
								<p class="test__wrapper-description-text">
									{{ getDataInterview.description }}
								</p>
								<div class="test__wrapper-description-company">
									<img :src="getDataInterview.companyAvatarUrl" alt=""
										class="test__wrapper-description-company-logo" />
									<div class="test__wrapper-description-company-specification">
										<div class="rating-number-container">
											<p class="rating-number">
												{{ getDataInterview.companyRate }}
											</p>
										</div>
										<p class="test__wrapper-description-company-specification-name">
											{{ getDataInterview.companyName }}
										</p>
									</div>
								</div>
								<div class="test__wrapper-description-actions">
									<div class="test__wrapper-description-actions-start">
										<router-link :class="{ 'block-button': getDataInterview.passed }" :to="{
											name: 'actualInterview',
											params: { interviewId: interviewId },
										}">
											<button class="test__wrapper-description-actions-start-btn">
												<p>{{ $t('pages.interview.interviewSeparatePreviewPage.passInterview')
													}}</p>
											</button>
										</router-link>
									</div>
									<button class="test__wrapper-description-actions-cancel-btn"
										@click="$router.go(-1)">
										{{ $t('pages.interview.interviewSeparatePreviewPage.cancelButton') }}
									</button>
								</div>
							</div>
							<div class="test__wrapper-display" :style="{
								backgroundImage: 'url(' + getDataInterview.previewUrl + ')',
							}"></div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuWorker from "../../components/account/account_worker/MenuWorker";
import HeaderAccount from "../../components/account/HeaderAccount.vue";
import { errorHandler } from "../../lib/errorHandler";

export default {
	computed: {
		getDataInterview() {
			return this.$store.getters.getDataInterview;
		},
	},
	components: { MenuWorker, HeaderAccount },
	name: "TestSeparatePreviewPage",
	props: {
		interviewId: {},
	},
	beforeMount() {
		this.$store.dispatch("fetchInterview", {
			interviewId: this.interviewId,
			token: this.$store.getters.token,
		});
		if (this.getDataInterview.passed)
			errorHandler({ status: 403, page: "interview" });
		scroll(0, 0);
	},
};
</script>


<style scoped>
.account {
	display: flex;
}

.container-account {
	padding-top: 0;
	max-width: 1220px;
	margin: auto;
}

.test {
	padding-top: 86px;
}

.test__wrapper {
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e9e9f9;
	border-radius: 19px;
	display: flex;
}

.test__wrapper-title {
	display: none;
}

.test__wrapper-description {
	width: 100%;
	box-sizing: border-box;
	padding: 70px 38px 53px 62px;
}

.test__wrapper-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 36px;
	line-height: 1.28;
	color: #3b4c68;
}

.test__wrapper-description-text {
	padding-top: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 15px;
	line-height: 1.6;
	color: #424242;
}

.test__wrapper-description-company {
	align-items: center;
	padding-top: 30px;
	display: flex;
}

.test__wrapper-description-company-logo {
	border-radius: 10px;
	width: 64px;
	height: 64px;
	object-fit: cover;
}

.test__wrapper-description-company-specification {
	margin-left: 15px;
}

.test__wrapper-description-company-specification-rating {
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
	max-width: 54px;
	align-items: center;
}

.test__wrapper-description-company-specification-rating-number {
	background: url("~@/assets/img/test-page/test/Rating-star.svg");
	background-position: 9px;
	background-repeat: no-repeat;
	padding: 0px 7px 0 23px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.test__wrapper-description-company-specification-name {
	padding-top: 3px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.test__wrapper-description-actions {
	display: flex;
	margin-top: 60px;
}

.test__wrapper-description-actions-start {
	background: #009ed1;
	width: 100%;
	margin-right: 23px;
	border-radius: 7px;
	max-width: 207px;
	box-sizing: border-box;
	text-align: center;
	transition: opacity 1s ease;
}

.test__wrapper-description-actions-start:hover {
	opacity: 0.5;
}

.test__wrapper-description-actions-start-btn {
	width: 100%;
	box-sizing: border-box;
	padding: 23px 0;
	color: #ffffff;
}

.test__wrapper-description-actions-cancel-btn {
	max-width: 207px;
	width: 100%;
	box-sizing: border-box;
	background: #f1f1f5;
	border-radius: 7px;
	text-align: center;
	transition: opacity 1s ease;
	display: block;
	padding: 23px 0;
	letter-spacing: 0.1px;
	width: 100%;
	box-sizing: border-box;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 16px;
	color: #696974;
}

.test__wrapper-description-actions-cancel-btn:hover {
	opacity: 0.5;
}

.test__wrapper-display {
	width: 100%;
	box-sizing: border-box;
	background: url("~@/assets/img/test-page/test/preview-discription-img.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid #e9e9f9;
	border-radius: 0px 19px 19px 0px;
}

.rating-number-container {
	margin: 10px auto 0 auto;
}

.rating-number {
	display: inline;
	padding: 4px 13px 4px 32px;
	position: relative;
	margin-top: 11px;
	background-color: rgba(60, 213, 152, 0.20000000298023224);
	background-image: url("~@/assets/img/main-page/jobs/stars/star1.svg");
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 16px;
	background-position-y: 7px;
	font-weight: 700;
	font-size: 13px;
	border-radius: 20px;
	color: #14c686;
}

@media (max-width: 950px) {
	.test {
		padding-top: 0px;
	}

	.container-account {
		padding-top: 76px;
	}

	.test__wrapper-description {
		padding: 20px;
	}

	.test__wrapper-description-title {
		font-size: 20px;
		line-height: 1.48;
		color: #000000;
	}

	.test__wrapper-description-text {
		font-size: 13px;
		line-height: 1.75;
		text-align: left;
		color: #424242;
	}

	.test__wrapper-description-company {
		padding-top: 23px;
	}

	.test__wrapper-description-actions {
		margin-top: 28px;
	}

	.test__wrapper-description-actions-start {
		max-width: 100%;
	}

	.test__wrapper-description-actions-start-btn {
		font-size: 13px;
		padding: 13px 0;
	}

	.test__wrapper-description-actions-cancel {
		max-width: 100%;
	}

	.test__wrapper-description-actions-cancel-btn {
		font-size: 13px;
		padding: 13px 0;
	}
}

@media (max-width: 676px) {
	.test__wrapper {
		background: #fafafb;
		margin: 0 auto;
		flex-direction: column;
		border: none;
		max-width: 400px;
	}

	.test__wrapper-title {
		order: 1;
		display: block;
		text-align: center;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 16px;
		line-height: 35.5px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.test__wrapper-description {
		margin-top: 18px;
		padding: 0;
		order: 3;
	}

	.test__wrapper-display {
		order: 2;
		margin-top: 7px;
		border: 1px solid #e9e9f9;
		border-radius: 19px;
		height: 246px;
	}
}

.block-button {
	opacity: 0.3;
	pointer-events: none;
}
</style>
