<template>
  <div class="chat__wrapper-contacts-people-separate" @click="loadDialogue">
    <div class="chat__wrapper-contacts-people-separate-avatar">
      <img
        :src="dialogue.avatarUrl != null ? dialogue.avatarUrl : require('@/../src/assets/img/account-img/chat/avatar.svg')"
        alt="" class="chat__wrapper-contacts-people-separate-avatar-img" />
    </div>
    <div class="chat__wrapper-contacts-people-separate-filling">
      <p class="chat__wrapper-contacts-people-separate-filling-name">
        {{ role === 'admin' ? $t('components.account_worker.separateWorkerChatPersone.administrator') :
          dialogue.userName ? limit(dialogue.userName, 20) :
            $t('components.account_worker.separateWorkerChatPersone.noData') }}
      </p>
      <p v-if="workingRequest" class="chat__wrapper-contacts-people-separate-filling-message">
        {{ $t('components.account_worker.separateWorkerChatPersone.employmentConfirmation') }} {{
          workingMessages[0] === 'accept' ? $t('components.account_worker.separateWorkerChatPersone.received') :
            workingMessages[0] === 'cancel' ? $t('components.account_worker.separateWorkerChatPersone.rejected') :
              $t('components.account_worker.separateWorkerChatPersone.awaitingVerification')
        }}
      </p>
      <p class="chat__wrapper-contacts-people-separate-filling-message" v-else-if="messagePost">{{
        $t('components.account_worker.separateWorkerChatPersone.communityPost') }}</p>
      <p v-else class="chat__wrapper-contacts-people-separate-filling-message">
        {{ dialogue.message ? limit(dialogue.message, 48) :
          $t('components.account_worker.separateWorkerChatPersone.noMessages') }}
      </p>
    </div>
    <div class="chat__wrapper-contacts-people-separate-info">
      <time class="chat__wrapper-contacts-people-separate-info-time">{{
        dialogue.createDate ? new Date().getTime() - dialogue.createDate > 86400000 || (new Date().getDate() !== new
          Date(dialogue.createDate).getDate()) ? getDay(dialogue.createDate) + ' / ' + getTime(dialogue.createDate) :
          getTime(dialogue.createDate) : ''
        }}
      </time>
      <div class="
          chat__wrapper-contacts-people-separate-info-stage
          stage-shipped stage-viewed
        "></div>
      <p class="chat__wrapper-contacts-people-separate-info-notifications"></p>
      <div
        v-if="dialogue && !dialogue.read && (dialogue.from === 'admin' || dialogue.from !== 'myself' && dialogue.from !== 'user')"
        class="chat__wrapper-contacts-people-separate-info-notifications-block"></div>
    </div>
  </div>
</template>

<script>

import { textSymbolLimit } from "../../../../../lib/textSymbolLimit";
import { getDate, getTime } from "@/lib/dateUtils";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      limit: textSymbolLimit,
      getDay: getDate,
      getTime: getTime,
      workingRequest: false,
      workingMessages: null,
      messagePost: false,
      reg: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
    }
  },
  props: ['dialogue', 'role'],
  name: "SeparateWorkerChatPersone",
  methods: {
    loadDialogue() {
      this.$store.commit('setCurrentDialogue', null)
      if (this.role === 'admin') {
        Cookies.remove('currentDialogueId');
        Cookies.remove('currentDialogueName');
        Cookies.remove('currentDialogueAvatar');
        Cookies.remove('currentDialogueRole');
        Cookies.set('currentDialogueRole', 'admin');
        this.$store.dispatch('fetchAllMessageWithAdmin');
      } else {
        Cookies.set('currentDialogueId', this.dialogue.userId);
        Cookies.set('currentDialogueName', this.dialogue.userName);
        Cookies.set('currentDialogueAvatar', this.dialogue.avatarUrl);
        Cookies.set('currentUserRole', this.dialogue.role);
        this.$store.commit('setCurrentRole', this.role);
        this.$store.commit('resetPageMessages');
        this.$store.dispatch('fetchAllMessageWithUser', { id: this.dialogue.userId });
      }
      this.$emit('goToChat');
    }
  },
  beforeMount() {
    const working = this.dialogue.message ? this.dialogue.message.split('!$') : null;
    if (working && this.reg.test(working[1])) {
      this.workingMessages = working;
      this.workingRequest = true;
    } else {
      const post = this.dialogue?.message?.split('#@');
      if (post?.length >= 2 && post[0] === 'communityPost') {
        this.messagePost = true;
      }
    }

  }
}
</script>


<style scoped>
.chat__wrapper-contacts-people-separate-filling {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.chat__wrapper-contacts-people-separate-avatar-notification {
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  bottom: 1px;
  right: 0;
}

.chat__wrapper-contacts-people-separate-avatar {
  width: 48px;
  height: 48px;
  position: relative;
  flex-shrink: 0;
}

.chat__wrapper-contacts-people-separate-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.chat__wrapper-contacts-people-separate-filling-name {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.chat__wrapper-contacts-people-separate-filling-message {
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}

.chat__wrapper-contacts-people-separate-info {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.chat__wrapper-contacts-people-separate-info-time {
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}

.chat__wrapper-contacts-people-separate-info-stage {
  display: none;
  margin-top: 8px;
}

.chat__wrapper-contacts-people-separate-info-notifications {
  margin: 5px auto 0 auto;
  border-radius: 100%;
  text-align: center;
  background: #14c686;
  font-family: "OpenSans";
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  width: 24px;
  color: #ffffff;
}

.chat__wrapper-contacts-people-separate-info-notifications-block {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #14c686;
  margin: 3px auto 0 auto;
}

@media (max-width: 500px) {
  .chat__wrapper-contacts-people-separate-filling {
    margin: 10px;
  }

  .chat__wrapper-contacts-people-separate-filling-name {
    font-size: 15px;
  }

  .chat__wrapper-contacts-people-separate-filling-message {
    font-size: 11px;
  }

  .chat__wrapper-contacts-people-separate-info-time {
    font-size: 11px;
  }
}

/* Modificators */
.notification-online {
  width: 9px;
  height: 9px;
  background: #14c686;
}

.stage-shipped {
  background: url("~@/assets/img/account-img/chat/stage-shipped.svg");
  width: 14px;
  height: 12px;
}

.stage-viewed {
  width: 29px;
  height: 24px;
  background: url("~@/assets/img/account-img/chat/stage-viewed.svg");
}

/* /Modificators */

.block-event {
  pointer-events: none;
}
</style>