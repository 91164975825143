<template>
	<div>
		<HeaderAccountCompany></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="container-account">
					<div class="recomedation">
						<p class="recomedation__text-mobile">{{
							$t('pages.account.account_company.accountCompanyRecomendationPage.personalCabinet') }}</p>
						<h1 class="recomedation__title">{{
							$t('pages.account.account_company.accountCompanyRecomendationPage.personalRecommendations')
							}}</h1>
						<p class="recomedation__text-desctop">{{
							$t('pages.account.account_company.accountCompanyRecomendationPage.welcome') }}</p>

						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_company.accountCompanyRecomendationPage.candidates') }}</h2>
							<router-link :to="{ name: 'communities' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_company.accountCompanyRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>

						<div class="recomedation__wrapper">
							<Sumarry v-for="item in dataSummary" :key="item.id" :summary="item"></Sumarry>
						</div>

						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_company.accountCompanyRecomendationPage.possibleFriends') }}
							</h2>
							<router-link :to="{ name: 'friends' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_company.accountCompanyRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>

						<div class="recommendations__wrapper">
							<SeparateFriend v-for="(item, index) in dataFriends" :is-friends="isFriends" :key="item.id"
								:friend="item" :index="index" @messageVisible="messageVisible">
							</SeparateFriend>
						</div>

						<div class="container-recomendation__header">
							<h2 class="recommendations__subtitle">{{
								$t('pages.account.account_company.accountCompanyRecomendationPage.communities') }}</h2>
							<router-link :to="{ name: 'communities' }">
								<button class="recommendations__link">{{
									$t('pages.account.account_company.accountCompanyRecomendationPage.readMore')
									}}</button>
							</router-link>
						</div>

						<div class="recommendations__wrapper__group">
							<div class="proposed-group">
								<button class="recommendations-test">
									<div class="recommendations-test-caption">
										<img alt=" "
											src="~@/assets/img/recommendation-page/recommendation/community.svg"
											class="recommendations-test-caption__img">
										<div class="recommendations-description">
											<p class="recommendations-description__comany">{{
												$t('pages.account.account_company.accountCompanyRecomendationPage.myCommunities')
												}}</p>
											<p class="recommendations-description__format">{{
												$t('pages.account.account_company.accountCompanyRecomendationPage.totalCommunities')
												}}: {{ getCountMyOpenCommunities + getCountMyClosedCommunities }}</p>
										</div>
									</div>
									<div class="recommendations-test-info">
										<div class="recommendations-info-element green">
											<p class="recommendations-info-name">{{
												$t('pages.account.account_company.accountCompanyRecomendationPage.open')
												}}:</p>
											<div class="recommendations-info-text">
												<p class="recommendations-info-text__number">{{
													getCountMyOpenCommunities }}</p>
											</div>
										</div>
										<div class="recommendations-info-element green">
											<p class="recommendations-info-name">{{
												$t('pages.account.account_company.accountCompanyRecomendationPage.closed')
												}}:</p>
											<div class="recommendations-info-text">
												<p class="recommendations-info-text__number">{{
													getCountMyClosedCommunities }}</p>
											</div>
										</div>
									</div>
									<router-link :to="{ name: 'communities' }" class="recommendations-test__start">{{
										$t('pages.account.account_company.accountCompanyRecomendationPage.readMore')
										}}</router-link>
								</button>

								<SeparateCommunity v-for="item in dataCommunity" :comData="item" :key="item.id">
								</SeparateCommunity>
							</div>
						</div>

					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import Sumarry from "../../../components/account/account_company/account_company_dashboard/item/Sumarry.vue";
import SeparateFriend from "../../../components/social/account_friensds/SeparateFriend.vue";
import SeparateCommunity from "../../../components/social/account_communitis/SeparateCommunityMini.vue"
import { config } from "@/lib/config";

export default {
	components: { Sumarry, MenuCompany, HeaderAccountCompany, SeparateFriend, SeparateCommunity },

	name: "AccountCompanyRecomendationPage",
	data() {
		return {
			dataSummary: [],
			dataFriends: {},
			dataCommunity: {},
			isFriends: true,
		};
	},
	computed: {
		getMyCommunities() {
			return this.$store.getters.getMyCommunities;
		},

		getCountMyClosedCommunities() {
			const closedCommunities = this.$store.getters.getMyCommunities.filter(community => community.communityType === "closed");
			return closedCommunities.length;
		},

		getCountMyOpenCommunities() {
			const openCommunities = this.$store.getters.getMyCommunities.filter(community => community.communityType === "open");
			return openCommunities.length;
		},
	},
	methods: {
		fetchDataSummary() {
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "employer/recommends";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataSummary = response.data.slice(0, 4);
			} catch (e) {
				console.log(e);
			}
		},
		fetchDataFriends() {
			let xmlHttp = new XMLHttpRequest();
			let url =
				config.apiPrefix + "candidate/recommendations/friends?lang=ru";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataFriends = response.data.slice(0, 4);
			} catch (e) {
				console.log(e);
			}
		},
		fetchDataCommunity() {
			let xmlHttp = new XMLHttpRequest();
			let url =
				config.apiPrefix + "candidate/recommendations/community?lang=ru";
			xmlHttp.open("GET", url, false);
			xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
			try {
				xmlHttp.send();
				let response = JSON.parse(xmlHttp.response);
				this.dataCommunity = response.data.slice(0, 3);
			} catch (e) {
				console.log(e);
			}
		},
	},
	beforeMount() {
		this.fetchDataSummary();
		this.fetchDataFriends();
		this.fetchDataCommunity();
		this.$store.dispatch('fetchMyBlockedFriends', {
			token: this.$store.getters.token,
			page: this.$store.getters.friendPage,
			role: 'all',
			limit: this.$store.getters.getLimit,
			callback: this.deletePagination
		})
		
		this.$store.dispatch("fetchMyCommunities", { page: 1, limit: 4, token: this.$store.getters.token });

		scroll(0, 0);
	},
};
</script>



<style scoped>
/* Recomendation */
.account {
	display: flex;
}

.container-recomendation__header {
	margin-top: 36px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.recommendations__link {
	font-size: 16px;
	color: #009ED1;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;
}

.recommendations__subtitle {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 24px;
	color: #44444F;
}

.recommendations__wrapper {
	box-sizing: border-box;
	width: 100%;
	margin-top: 12px;
	display: grid;
	gap: 12px;
}

.recommendations__wrapper:deep(.friend-wrapper) {
	margin-bottom: 0;
}


.recomedation {
	margin-bottom: 40px;
	width: 100%;
	box-sizing: border-box;
}

.recomedation__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.recomedation__text-desctop {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.recomedation__wrapper {
	box-sizing: border-box;
	width: 100%;
	margin-top: 12px;
	display: grid;
	row-gap: 12px;
}

.recomedation__more {
	margin-top: 47px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.recomedation__more-btn {
	display: none;
	height: 50px;
	width: 25%;
	border: 1px solid #009ed1;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #009ed1;
	transition: opacity 1s ease;
}

.recomedation__more-btn:hover {
	opacity: 0.5;
}

.mobile {
	display: none;
}

.recomedation__text-mobile {
	display: none;
}

/*recommendations-test*/

.proposed-group {
	max-width: 100%;
	width: 100%;
	margin-top: 12px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 20px;
}

.recommendations-test {
	max-width: 100%;
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background: #009ED1;
	border-radius: 10px;
	height: 205px;
	box-sizing: border-box;
	font-family: "Roboto";
	text-align: left;
}



.recommendations-test-caption {
	display: flex;
	max-width: 100%;
	align-items: center;
	gap: 10px;
	width: 100%;
}

.recommendations-test-caption__img {
	width: 53px;
	height: 53px;
	background: #c4c4c4;
	border-radius: 10px;
	display: flex;
	flex-shrink: 0;
	object-fit: cover;
}

.recommendations-description__comany {
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
	display: flex;
	align-items: center;
	gap: 6px;
}

.recommendations-description__format {
	margin-top: 6px;
	font-weight: 500;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
}


.recommendations-test-info {
	margin-top: 20px;
	display: flex;
	max-width: 100%;
	gap: 5px;
	width: 100%;
}

.recommendations-info-element {
	display: flex;
	max-width: 100%;
	text-align: left;
	width: 100%;
	flex-direction: column;
}

.recommendations-info-name {
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: #fff;
}

.recommendations-info-text {
	display: flex;
	align-items: center;
	margin-top: 5px;

}

.recommendations-info-text__number {
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: #fff;
}


.recommendations-test__start {
	max-width: 100%;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.1px;
	color: #fff;
	border-top: 1px solid rgba(231, 233, 236, 0.8);
	padding-top: 20px;
	margin-top: auto;
	padding-bottom: 10px;
}

.mobile {
	display: none;
}

@media (max-width: 1840px) {
	.recomedation__wrapper {
		width: 100%;
		margin-top: 12px;
		display: grid;
		row-gap: 12px;
		grid-template-columns: repeat(3, 1fr);
	}

	.recomedation__wrapper-element[data-v-53e4e22c]:nth-child(2n) {
		margin-left: 0 auto;
	}
}

@media(max-width: 1575px) {
	.recommendations__wrapper:deep(.friend-wrapper) {
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
	}
}

@media (max-width: 1488px) {
	.mobile {
		display: block;
	}

	.desctop {
		display: none;
	}

	.recomedation__wrapper {
		width: 100%;
		margin-top: 12px;
		display: grid;
		row-gap: 12px;
		grid-template-columns: repeat(3, 1fr);
	}

}

@media (max-width: 1380px) {
	.recomedation__title {
		text-align: center;
	}

	.recomedation__text-desctop {
		text-align: center;
	}

	.recommendations__link {
		font-size: 14px;
	}
}

@media(max-width: 1190px) {
	.recommendations__wrapper {
		width: 100%;
		margin-top: 12px;
		display: grid;
		row-gap: 12px;
		/* grid-template-columns: repeat(3, 1fr); */
	}
}

@media (max-width: 1040px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 767px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.recomedation__more-btn {
		display: block;
	}
}

@media (max-width: 654px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.recomedation__more-btn {
		width: 300px;
	}
}

@media (max-width: 360px) {
	.recomedation__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

	.recomedation__text-desctop {
		display: none;
	}

	.recomedation__text-mobile {
		display: block;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.recomedation__wrapper {
		margin-top: 12px;
	}

	.recommendations__wrapper {
		margin-top: 8px;
	}

	.recommendations__wrapper__group {
		margin-top: 8px;
	}
}

/* /Recomendation */

/* Summary */
@media (max-width: 1380px) {
	.recomedation__wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.recomedation__wrapper-element {
		margin: 0 auto;
	}
}

@media (max-width: 670px) {
	.recomedation__wrapper {
		margin-top: 8px;
		grid-template-columns: repeat(1, 1fr);
	}

	.recomedation__wrapper-element {
		margin: 0 auto;
	}
}

/* Summary */
</style>
