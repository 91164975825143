<template>
  <div>
    <HeaderAccountCompany></HeaderAccountCompany>

    <main class="main">
      <div class="comtainer-account">
        <div class="account">
          <MenuCompany></MenuCompany>
          <loading :active="loader" :is-full-page='true' />
          <div class="account-filling">
            <div class="account-filling-caption">
              <div class="account-filling-caption-description">
                <p class="account-filling-caption-description-text filling-caption-mobile">
                  {{ $t('pages.tests.test_company.testConstructorPage.createTest') }}
                </p>
                <h2 class="account-filling-caption-description-title">
                  {{ $t('pages.tests.test_company.testConstructorPage.testing') }}
                </h2>
                <p class="account-filling-caption-description-text filling-caption-desctop">
                  {{ $t('pages.tests.test_company.testConstructorPage.createTest') }}
                </p>
              </div>
              <div class="account-filling-caption-cancle">
                <button class="account-filling-caption-cancle-btn" @click="$router.go(-1)">
                  {{ $t('pages.tests.test_company.testConstructorPage.cancel') }}
                </button>
                <button v-if="interviewId !== 'new'" style="margin-left: 10px"
                  class="account-filling-caption-cancle-btn" @click="deleteInterview">
                  {{ $t('pages.tests.test_company.testConstructorPage.delete') }}
                </button>
              </div>
            </div>
            <preview-wrapper :preview="dataPreview"></preview-wrapper>
            <TestConstructorWrapper v-for="(item, index) in question" :key="item.id" :question="item" :count="index">
            </TestConstructorWrapper>
            <div class="account-filling-end">
              <button class="account-filling-end-add" @click="$store.commit('addQuestion')">
                <img src="~@/assets/img/test-img/constractor/add.svg" alt="Add" class="account-filling-end-add-img" />
              </button>
              <div class="account-filling-end-finish">
                <button class="account-filling-end-finish-btn" @click="saveAnswers">
                  <p v-if="interviewId !== null">{{ $t('pages.tests.test_company.testConstructorPage.saveChanges') }}
                  </p>
                  <p v-else>{{ $t('pages.tests.test_company.testConstructorPage.createTestButton') }}</p>
                </button>
              </div>
              <div class="account-filling-end-cancel">
                <button class="account-filling-end-cancel-btn" @click="$router.go(-1)">
                  {{ $t('pages.tests.test_company.testConstructorPage.cancel') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany.vue";
import TestConstructorWrapper from "../../../components/tests/test_constructor_elements/wrapper_component/TestConstructorWrapper.vue";
import PreviewWrapper from "@/components/tests/test_constructor_elements/wrapper_component/PreviewWrapper";
import { errorHandler } from "../../../lib/errorHandler";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  computed: {
    loader() {
      return this.$store.getters.loader;
    },
    token() {
      return this.$store.getters.token;
    },
    construct() {
      return this.$store.getters.getConstruct;
    },
    dataFile() {
      return this.$store.getters.dataFile;
    },
    previewFile() {
      return this.$store.getters.previewFile;
    },
  },
  components: {
    PreviewWrapper,
    MenuCompany,
    HeaderAccountCompany,
    TestConstructorWrapper,
    Loading
  },
  props: {
    interviewId: {},
  },
  data() {
    return {
      dataPreview: null,
      question: null,
    };
  },
  beforeMount() {
    scroll(0, 0);
    this.$store.dispatch("fetchConstruct", {
      id: this.interviewId,
      token: this.$store.getters.token,
    });
    this.dataPreview = {
      title: this.construct.title,
      previewUrl: this.construct.previewUrl,
      description: this.construct.description,
      maxPassingTimeSeconds: this.construct.maxPassingTimeSeconds,
    };
    this.question = this.construct.questions;
  },
  methods: {
    deleteInterview() {
      this.$store.dispatch('deleteInterview', {
        id: this.interviewId, callback: () => {
          this.$router.push({ name: 'interviewPage' })
        }
      });
    },
    checkNullField() {
      let count = 0;
      if (!this.construct.title) count++;
      if (!this.construct.description) count++;

      let countDeleted = 0;
      if (this.construct.questions.length > 0) {
        this.construct.questions.forEach((que) => {
          if (!que.body) count++;
          if (!que.title) count++;
          if (que.deleted) countDeleted++;
          else {
            if (que.questionType !== null) {
              if (que.questionType !== 'open') {
                let countFalse = 0;
                let usingAnswer = 0;
                que.answers.forEach(a => {
                  if (!a.body) count++;
                  if (!a.correct) countFalse++;
                  if (!a.deleted) usingAnswer++;
                });
                if (usingAnswer < 2) count++;
                if (countFalse === usingAnswer) count++;
              }
            } else {
              count++;
            }
          }
        });
        if (countDeleted === this.construct.questions.length) count++;
      } else {
        count++;
      }
      return count > 0;
    },
    routerBack() {
      this.$store.commit('statusLoader', false);
      this.$router.go(-1);
    },
    requestSave() {
      if (this.interviewId !== "new")
        this.$store.dispatch("saveDataConstruct", {
          token: this.token,
          routeBack: this.routerBack
        });
      else
        this.$store.dispatch("saveNewConstruct", {
          token: this.token,
          routeBack: this.routerBack
        });
    },
    saveAnswers() {
      if (this.checkNullField()) {
        errorHandler({ status: 400, message: this.$t('pages.tests.test_company.testConstructorPage.invalidInputError') });
        scroll(0, 0);
      } else {
        this.$store.commit('statusLoader', true);
        if (this.dataFile.length !== 0 || this.previewFile)
          this.$store.dispatch("updateFile", {
            id: this.testingId,
            token: this.token,
            requestSave: this.requestSave
          });
        else {
          this.requestSave();
        }
      }
    },
  },
  name: "TestConstructorPage"
};
</script>



<style scoped>
.account {
  display: flex;
}

.account-filling {
  padding: 96px 30px 77px 30px;
  max-width: 1518px;
  width: 100%;
  margin: 0 auto;
}

.account-filling-end {
  margin-top: 30px;
  border-top: 2px solid #e8e8e8;
  width: 100%;
  box-sizing: border-box;
  padding-top: 102px;
  position: relative;
}

.account-filling-end-add {
  position: absolute;
  top: -34px;
  left: calc(50% - 34px);
  transition: opacity 1s ease;
}

.account-filling-end-add:hover {
  opacity: 0.5;
}

.account-filling-end-add-img {
  padding: 19px;
  background: #009ed1;
  border-radius: 16px;
}

.account-filling-end-finish {
  display: flex;
  justify-content: center;
}

.account-filling-end-finish-btn {
  padding: 23px 0;
  width: 273px;
  background: #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: opacity 1s ease;
}

.account-filling-end-finish-btn:hover {
  opacity: 0.5;
}

.account-filling-end-cancel {
  display: none;
}

@media (max-width: 950px) {
  .account-filling {
    padding: 76px 10px 35px 10px;
  }

  .account-filling-end {
    padding-top: 54px;
    margin-top: 38px;
  }

  .account-filling-end-add {
    top: -23px;
    left: calc(50% - 22px);
  }

  .account-filling-end-add-img {
    padding: 13px;
    border-radius: 16px;
    width: 18px;
    height: 18px;
  }

  .account-filling-end-finish-btn {
    padding: 13px 0;
    font-size: 13px;
    width: 300px;
    border-radius: 10px;
  }

  .account-filling-end-cancel {
    margin-top: 11px;
    display: flex;
    justify-content: center;
  }

  .account-filling-end-cancel-btn {
    width: 133px;
    padding: 13px 0;
    background: #f1f1f5;
    border-radius: 10px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    color: #696974;
    transition: opacity 1s ease;
  }

  .account-filling-end-cancel-btn:hover {
    opacity: 0.5;
  }
}

/* Caption */
.filling-caption-mobile {
  display: none;
}

.account-filling-caption {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}

.account-filling-caption-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.account-filling-caption-description-text {
  padding-top: 3px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.account-filling-caption-cancle {
  margin-left: auto;
}

.account-filling-caption-cancle-btn {
  padding: 16px 54px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #696974;

  background: #f1f1f5;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.account-filling-caption-cancle-btn:hover {
  opacity: 0.5;
}

@media (max-width: 700px) {
  .filling-caption-desctop {
    display: none;
  }

  .filling-caption-mobile {
    display: block;
  }

  .account-filling-caption-description-text {
    color: #009ed1;
    font-size: 13px;
  }

  .account-filling-caption-description-title {
    color: #000000;
    font-size: 20px;
  }
}

@media (max-width: 460px) {
  .account-filling-caption {
    padding-bottom: 16px;
    flex-direction: column;
    align-items: unset;
    align-items: center;
    text-align: center;
  }

  .account-filling-caption-cancle {
    margin-left: 0;
    margin-top: 15px;
  }

  .account-filling-caption-cancle-btn {
    font-size: 13px;
    padding: 12px 56px;
  }
}

/* /Caption */
</style>
