<template>
  <div
      :class="{ 'record-photos-three': url.length === 3, 'record-photos-two': url.length === 2, 'record-photos-one': url.length === 1 }">
    <div class="record-wrapper" v-for="(item, index) in url" :key="item.id">
      <img id="viewBigPhoto" v-if="imgCheck(item, index)" :src="item" alt="" class="record-photos__elemnt" @click="viewBigPhoto(item)">
      <video controls v-else :src="item"></video>
      <button v-if="!postId" class="cancel-button" @click="deleteFile(index)" type="button"></button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SeparateContent",
  props: ['url', 'files', 'postId'],
  methods: {
    imgCheck(value, index) {
      let name = value.split('.');
      if (this.files&&this.files[index]) {
        name = this.files[index].type.slice().split('/');
      }
      const impl = name[name.length - 1].toLowerCase()
      return impl === 'jpg' || impl === 'jpeg' || impl === 'psd' || impl === 'png' || impl === 'raw' || impl === 'tiff' || impl === 'bmp';
    },
    deleteFile(index) {
      this.$store.commit('deleteFileInCommunity', index);
    },
    viewBigPhoto(item) {
      this.$emit('viewBigPhoto', item);
    }
  }
};


</script>

<style scoped>
video {
  border-radius: 10px;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  box-sizing: border-box;
}

img {
  width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-sizing: border-box;
  max-height: 100%;
}


.record-wrapper {
  position: relative;
  width: 100%;
}

.record-photos-three {
  margin-top: 40px;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 143px);
  gap: 10px;
  position: relative;
  max-height: 100%;
}

.record-photos-three div {
  border: 1px solid #efeff4;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.record-photos-three div:nth-child(3n+1) {
  grid-column: 1 span;
  grid-row: 2 span;
}

.record-photos-three div:nth-child(3n+2) {
  grid-column: 2 span;
  grid-row: 4 span;
}

.record-photos-three div:nth-child(3n+3) {
  grid-column: 1 span;
  grid-row: 2 span;
}

.record-photos-two {
  max-height: 602px;
  margin-top: 40px;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.record-photos-two div:first-child {
  margin-right: 10px;
}

.record-photos-one {
  max-height: 602px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
}

.cancel-button {
  background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
  height: 15px;
  width: 15px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 20;
}


@media (max-width: 1575px) {
  .record-photos-three {
    grid-template-rows: repeat(4, 80px);
  }

  .record-photos-two {
    height: 350px;
  }

  .record-photos-one {
    height: 350px;
  }
}

@media (max-width: 950px) {

  .record-photos-three {
    margin-top: 20px;
    grid-template-rows: repeat(4, 46px);
    gap: 2px;
  }

  .record-photos-two {
    height: 190px;
  }

  .record-photos-one {
    height: 190px;
  }
}
</style>