<template>
  <div class="record community-block">
    <div class="record-caption">
      <div class="">
        <h4 class="record-caption__description-name">{{ title ? title : null }}</h4>
        <p class="record-caption__description-time">
          <time>{{ date(post.createDate) + ' ' + time(post.createDate) }}</time>
        </p>
      </div>
      <button class="delete-post-button"
              v-if="false"></button>
    </div>
    <p class="record-info">{{ text ? text : null }}</p>
  </div>
</template>

<script>
import {getDate, getTime} from "@/lib/dateUtils";

export default {
  props: ['post'],
  name: "SeparatePostNew",
  data() {
    return {
      date: getDate,
      time: getTime,
      title: null,
      text: null
    }
  },
  beforeMount() {
    switch (this.post.newsType) {
      case 'birthday':
        this.title = this.$t('components.social.separatePostNew.birthdayTitle', { user: this.post.userFromName });
        this.text = this.$t('components.social.separatePostNew.birthdayText');
        break;
      case 'friends':
        this.title = this.$t('components.social.separatePostNew.friendsTitle');
        this.text = this.$t('components.social.separatePostNew.friendsText', { user1: this.post.userFromName, user2: this.post.userToName });
        break;
      case 'test':
        this.title = this.$t('components.social.separatePostNew.testTitle');
        this.text = this.$t('components.social.separatePostNew.testText', { user: this.post.userFromName, testName: JSON.parse(this.post.body).testingName });
        break;
      case 'community':
        this.title = this.$t('components.social.separatePostNew.communityTitle', { user: this.post.userFromName });
        this.text = this.$t('components.social.separatePostNew.communityText', { user: this.post.userFromName, communityName: JSON.parse(this.post.body).communityName });
        break;
      case 'job':
        this.title = this.$t('components.social.separatePostNew.jobTitle');
        this.text = this.$t('components.social.separatePostNew.jobText', { user: this.post.userFromName, companyName: JSON.parse(this.post.body).companyName });
        break;
    }
  }
};
</script>


<style scoped>
.community-block {
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  padding: 30px;
  margin-bottom: 40px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.delete-post-button {
  margin-left: auto;
  margin-bottom: auto;
  background-image: url("~@/assets/img/test-img/constractor/cancel.svg");
  max-height: 15px;
  height: 15px;
  width: 15px;
  background-size: cover;
  background-repeat: no-repeat;

}

.record-caption {
  display: flex;
  width: 100%;
  align-items: center;
}

.record-caption__logo {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;
}

.record-caption__description {
  margin-left: 20px;
}

.record-caption__description-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #000000;
}

.record-caption__description-time {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.4000000059604645);
  margin-top: 10px;
}

.community__settings {
  position: relative;
  display: block;
  background: url("~@/assets/img/account-img/friends/settings.svg");
  width: 40px;
  height: 40px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.community__settings-popap {
  position: absolute;
  border: 1px solid #efeff4;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  padding: 10px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  bottom: -113px;
  right: -45px;
  z-index: 20;
}

.popap-list {
  position: relative;
  text-align: left;
}

.popap-list::after {
  position: absolute;
  top: -20px;
  right: 47px;
  display: block;
  width: 14px;
  height: 10px;
  background: url("~@/assets/img/account-img/friends/arrow.svg");
  content: "";
  background-repeat: no-repeat;
}

.popap-list__button {
  text-align: left;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #44444f;
  padding-left: 10px;
  width: 100%;
  cursor: pointer;
}

.popap-list__button:not(:first-child) {
  margin-top: 6px;
}

.popap-list__button:last-child {
  padding-bottom: 10px;
}

.community__settings-delete {
  padding: 10px 10px 0px 10px;
  border-top: 1px solid #efeff4;
  font-family: "Roboto";
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #e7665e;
  cursor: pointer;
}

.record-info {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
  margin-top: 20px;
  max-width: 70%;
  width: 100%;

}


.record-activity {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.record-activity__element {
  display: flex;
  align-items: center;

}

.record-activity__element:not(:last-child) {
  margin-right: 40px;
}

.record-activity__like-img {
  background: url("~@/assets/img/account-img/communities/like.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 18px;
  display: block;
}

.record-activity__text {
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(68, 68, 79, 0.5);
}

.record-activity__comments-img {
  background: url("~@/assets/img/account-img/communities/comment.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: block;
}

.record-activity__share-img {
  background: url("~@/assets/img/account-img/communities/share.svg");
  background-repeat: no-repeat;
  width: 19px;
  height: 18px;
  display: block;
}

.record-answer {
  display: flex;
  margin-top: 40px;
}

.record-caption__logo {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.answer-form {
  margin-left: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.answer-textarea-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;

}

.answer-textarea {
  width: 100%;
  border: 1px solid #efeff4;
  border-radius: 10px;
  padding: 24px 60px 26px 20px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #434343;
  overflow: auto;
  height: 64px;
  scrollbar-width: auto;
}


.answer-textarea::placeholder {
  color: rgba(66, 66, 66, 0.5);
}

.answer-textarea-file {
  position: absolute;
  right: 20px;
  top: 21px;
  background: url("~@/assets/img/account-img/communities/file.svg");
  display: block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.answer-send {
  background: url("~@/assets/img/account-img/communities/send.svg");
  display: block;
  background-repeat: no-repeat;
  width: 34px;
  height: 34px;
  margin-left: 16px;
}

@media (max-width: 1575px) {
  .record-info {
    max-width: 100%;
  }

}


@media (max-width: 950px) {
  .community-block {
    padding: 20px 14px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .record-caption__logo {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .record-caption__description {
    margin-left: 10px;
  }

  .record-caption__description-time {
    font-size: 12px;
    margin-top: 6px;
  }

  .record-photos__elemnt {
    border-radius: 5px;
  }

  .record-activity {
    margin-top: 10px;
  }

  .record-activity__element:not(:last-child) {
    margin-right: 20px;
  }

  .record-activity__text {
    font-size: 12px;
  }

  .record-caption__logo {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .answer-form {
    margin-left: 10px;
  }

  .answer-textarea {
    height: 40px;
    padding: 13px 24px 13px 10px;
    line-height: 14px;
  }

  .answer-textarea-file {
    height: 13px;
    width: 13px;
    right: 10px;
    top: 13px;
    background-size: cover;
  }

  .answer-send {
    height: 25px;
    width: 25px;
    background-size: cover;
    margin-left: 10px;
  }
}


.load-comments {
  background: #009ed1;
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin: 30px auto 0;
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
}

.load-comments:hover {
  opacity: 0.5;
}

.text-view-btn {
  margin-top: 15px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 1;
  color: #0b76ef;
}
</style>
