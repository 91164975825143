import { render, staticRenderFns } from "./AccountCompanyGroupsPage.vue?vue&type=template&id=476fb5fb&scoped=true"
import script from "./AccountCompanyGroupsPage.vue?vue&type=script&lang=js"
export * from "./AccountCompanyGroupsPage.vue?vue&type=script&lang=js"
import style0 from "./AccountCompanyGroupsPage.vue?vue&type=style&index=0&id=476fb5fb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476fb5fb",
  null
  
)

export default component.exports