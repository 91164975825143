<template>
  <div>
    <HeaderAccountWorker></HeaderAccountWorker>

    <main class="main">
      <div class="account">
        <MenuWorker></MenuWorker>

        <div class="container-account">
          <div class="test">
            <div class="test__wrapper">
              <h1 class="test__wrapper-title">
                {{ $t('pages.tests.test_worker.testSeparatePreviewPage.testing') }}
              </h1>

              <div class="test__wrapper-description">
                <h2 class="test__wrapper-description-title">
                  {{ getDataTesting.title }}
                </h2>
                <p class="test__wrapper-description-text">
                  {{ getDataTesting.description }}
                </p>
                <div class="result" v-if="passedTestingAnswers">
                  <p class="result__text">{{ $t('pages.tests.test_worker.testSeparatePreviewPage.result') }}:</p>
                  <div class="stars">
                    <p
                        class="statistics__number"
                        :class="{
                        yellow:
                          passedTestingAnswers.allCorrectAnswers ===
                          Math.round(passedTestingAnswers.allAnswers / 2),
                        green:
                          passedTestingAnswers.allCorrectAnswers >
                          Math.round(passedTestingAnswers.allAnswers / 2),
                        red:
                          passedTestingAnswers.allCorrectAnswers <
                          Math.round(passedTestingAnswers.allAnswers / 2),
                      }"
                    >
                      <svg
                          class="statistics__number-icon"
                          width="11"
                          height="10"
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
                            fill="#0000"
                        />
                      </svg>
                      {{ passedTestingAnswers.allCorrectAnswers }}
                    </p>
                    <p class="stars__text">{{ $t('pages.tests.test_worker.testSeparatePreviewPage.from') }}</p>
                    <p class="statistics__number green">
                      <svg
                          class="statistics__number-icon"
                          width="11"
                          height="10"
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M4.79596 0.485061C5.01791 -0.161687 5.9819 -0.161687 6.20311 0.485061L6.99472 2.79678C7.04308 2.93757 7.1371 3.06024 7.26333 3.14726C7.38956 3.23429 7.54154 3.28121 7.69755 3.28132H10.2588C10.9757 3.28132 11.2731 4.15207 10.6938 4.55234L8.62234 5.98066C8.49585 6.06776 8.40166 6.19063 8.35329 6.33167C8.30491 6.47271 8.30482 6.62466 8.35304 6.76575L9.14465 9.07747C9.3666 9.72422 8.58608 10.2628 8.00532 9.86255L5.93381 8.43423C5.80746 8.34716 5.65531 8.30026 5.49917 8.30026C5.34302 8.30026 5.19088 8.34716 5.06452 8.43423L2.99301 9.86255C2.41299 10.2628 1.63321 9.72422 1.85442 9.07747L2.64603 6.76575C2.69425 6.62466 2.69416 6.47271 2.64579 6.33167C2.59741 6.19063 2.50322 6.06776 2.37674 5.98066L0.305968 4.55304C-0.273315 4.15277 0.0248344 3.28202 0.740985 3.28202H3.30152C3.45766 3.28206 3.6098 3.23521 3.73618 3.14817C3.86256 3.06114 3.95669 2.93838 4.00509 2.79748L4.7967 0.485763L4.79596 0.485061Z"
                            fill="#0000"
                        />
                      </svg>
                      {{ passedTestingAnswers.allAnswers }}
                    </p>
                  </div>
                </div>
                <div class="test__wrapper-description-actions">
                  <div
                      v-if="!passedTestingAnswers"
                      class="test__wrapper-description-actions-start"
                  >
                    <router-link
                        :to="{
                        name: 'activeQuestion',
                        params: { testingId: this.testingId },
                      }"
                    >
                      <button
                          class="test__wrapper-description-actions-start-btn"
                      >
                        <p v-if="getDataTesting.passed < 1">{{ $t('pages.tests.test_worker.testSeparatePreviewPage.passTest') }}</p>
                        <p v-else>{{ $t('pages.tests.test_worker.testSeparatePreviewPage.passTestAgain') }}</p>
                      </button>
                    </router-link>
                  </div>

                  <div
                      class="test__wrapper-description-actions-start"
                      v-if="saveTesting.testingId && passedTestingAnswers"
                  >
                    <button
                        @click="$router.push({ name: 'viewTestingResults' })"
                        class="test__wrapper-description-actions-start-btn"
                    >
                      <p>{{ $t('pages.tests.test_worker.testSeparatePreviewPage.viewAnswers') }}</p>
                    </button>
                  </div>

                  <div class="test__wrapper-description-actions-cancel">
                    <button
                        class="test__wrapper-description-actions-cancel-btn"
                        @click="$router.push({ name: 'testing' })"
                    >
                      {{ passedTestingAnswers ? $t('pages.tests.test_worker.testSeparatePreviewPage.return') : $t('pages.tests.test_worker.testSeparatePreviewPage.cancellation') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="test__wrapper-display">
                <div class="successful-end" v-if="passedTestingAnswers"></div>
              </div>
            </div>
            <p class="warning">
              {{ $t('pages.tests.test_worker.testSeparatePreviewPage.warning') }}
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker.vue";

export default {
  computed: {
    getDataTesting() {
      return this.$store.getters.getDataTesting;
    },
    passedTestingAnswers() {
      return this.$store.getters.passedTestingAnswers;
    },
    saveTesting() {
      return this.$store.getters.getSaveSetting;
    },
  },
  components: { MenuWorker, HeaderAccountWorker },
  name: "TestSeparatePreviewPage",
  props: {
    testingId: {},
  },
  beforeMount() {
    if (!this.passedTestingAnswers) {
      this.$store.dispatch("fetchTesting", {
        testingId: this.testingId,
        token: this.$store.getters.token,
      });
    }
    scroll(0, 0);
  },
  beforeDestroy() {
    this.$store.commit("setPassedTestingAnswers", null);
  },
};
</script>


<style scoped>
.account {
  display: flex;
}

.container-account {
  max-width: 1220px;
  margin: auto;
}

.test__wrapper {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
  display: flex;
}

.warning {
  font-size: 16px;
  max-width: 570px;
  margin: 20px auto 0 auto;
  text-align: center;
  font-weight: 500;
}

.test__wrapper-title {
  display: none;
}

.test__wrapper-description {
  width: 100%;
  box-sizing: border-box;
  padding: 70px 38px 53px 62px;
}

.test__wrapper-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 36px;
  line-height: 1.28;
  color: #3b4c68;
}

.test__wrapper-description-text {
  padding-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6;
  color: #424242;
}

.test__wrapper-description-company {
  align-items: center;
  padding-top: 30px;
  display: flex;
}

.test__wrapper-description-company-logo {
  padding: 14px 17px;
  background: #000000;
  border-radius: 10px;
  width: 30px;
  height: 36px;
  object-fit: cover;
}

.test__wrapper-description-company-specification {
  margin-left: 15px;
}

.test__wrapper-description-company-specification-rating {
  background: rgba(60, 213, 152, 0.20000000298023224);
  border-radius: 20px;
  max-width: 54px;
  align-items: center;
}

.test__wrapper-description-company-specification-rating-number {
  background: url("~@/assets/img/test-page/test/Rating-star.svg");
  background-position: 9px;
  background-repeat: no-repeat;
  padding: 0px 7px 0 23px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #14c686;
}

.test__wrapper-description-company-specification-name {
  padding-top: 3px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.test__wrapper-description-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 60px;
  flex-direction: column;
  gap: 15px;
}

.test__wrapper-description-actions-start {
  background: #009ed1;
  width: 100%;
  border-radius: 7px;
  max-width: 100%;
  box-sizing: border-box;
  text-align: center;
  transition: opacity 1s ease;
}

.test__wrapper-description-actions-start:hover {
  opacity: 0.5;
}

.test__wrapper-description-actions-start-btn {
  width: 100%;
  box-sizing: border-box;
  padding: 23px 0;
  color: #ffffff;
}

.test__wrapper-description-actions-cancel {
  width: 100%;
  box-sizing: border-box;
  background: #f1f1f5;
  border-radius: 7px;
  text-align: center;
  transition: opacity 1s ease;
}

.test__wrapper-description-actions-cancel:hover {
  opacity: 0.5;
}

.test__wrapper-description-actions-cancel-btn {
  padding: 23px 0;
  letter-spacing: 0.1px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  color: #696974;
}

.test__wrapper-display {
  width: 100%;
  box-sizing: border-box;
  background: url("~@/assets/img/test-page/test/preview-discription-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #e9e9f9;
  border-radius: 0px 19px 19px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.successful-end {
  width: 275px;
  height: 275px;
  display: flex;
  flex-shrink: 0;
  background: url(../../../assets/img/all-img/successful-end.svg);
  position: absolute;
}

.result {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.result__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #696974;
  margin-right: 8px;
}

.stars {
  display: flex;
  align-items: center;
}

.stars__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: #424242;
  margin: 0 4px;
}

.statistics__number {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  flex-shrink: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1px;
  display: inline;
  padding: 4px 7px;
  border-radius: 200px;
}

.statistics__number svg {
  margin-right: 2px;
}

@media (max-width: 950px) {
  .container-account {
    padding-top: 76px;
  }

  .test__wrapper-description {
    padding: 20px;
  }

  .test__wrapper-description-title {
    font-size: 20px;
    line-height: 1.48;
    color: #000000;
  }

  .test__wrapper-description-text {
    font-size: 13px;
    line-height: 1.75;
    text-align: left;
    color: #424242;
  }

  .test__wrapper-description-company {
    padding-top: 23px;
  }

  .test__wrapper-description-actions {
    margin-top: 28px;
  }

  .test__wrapper-description-actions-start {
    max-width: 100%;
  }

  .test__wrapper-description-actions-start-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .test__wrapper-description-actions-cancel {
    max-width: 100%;
  }

  .test__wrapper-description-actions-cancel-btn {
    font-size: 13px;
    padding: 13px 0;
  }

  .successful-end {
    width: 161px;
    height: 161px;
    background-size: cover;
  }
}

@media (max-width: 676px) {
  .test__wrapper {
    background: none;
    margin: 0 auto;
    flex-direction: column;
    border: none;
    max-width: 400px;
  }

  .test__wrapper-description-actions {
    grid-template-columns: repeat(1, 1fr);
  }

  .test__wrapper-title {
    order: 1;
    display: block;
    text-align: center;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 35.5px;
    letter-spacing: 0.1px;
    color: #009ed1;
  }

  .test__wrapper-description {
    margin-top: 18px;
    padding: 0;
    order: 3;
  }

  .test__wrapper-display {
    order: 2;
    margin-top: 7px;
    border: 1px solid #e9e9f9;
    border-radius: 19px;
    height: 246px;
  }
}

.non-click-coursor {
  pointer-events: none;
  opacity: 0.3;
}

/* Modificators */
.yellow {
  background: rgba(220, 149, 11, 0.20000000298023224);
}

.yellow path {
  fill: #db940a;
}

.yellow {
  color: #db940a;
}

.green {
  background: rgba(20, 198, 134, 0.20000000298023224);
}

.green path {
  fill: #14c686;
}

.green {
  color: #14c686;
}

.red {
  background: rgba(220, 11, 11, 0.20000000298023224);
}

.red path {
  fill: #db0a0a;
}

.red {
  color: #db0a0a;
}
</style>
