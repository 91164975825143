<template>
  <div>
    <Header></Header>

    <main class="main">
      <div class="restore">
        <div class="restore__img">
          <img src="~@/assets/img/restore-page/background-logo.svg" alt="" class="restore__img-logo" />
        </div>
        <div class="restore__wrapper">
          <div class="restore__wrapper-authorization">
            <h1 class="restore__wrapper-authorization-title">
              {{ $t('pages.authorization.authorizationRestorePasswordPage.createNewPassword') }}
            </h1>
            <p class="restore__wrapper-authorization-designation">{{
              $t('pages.authorization.authorizationRestorePasswordPage.password') }}</p>
            <input type="password" placeholder="password" class="restore__wrapper-authorization-mail"
              v-model="password" />
            <p class="restore__wrapper-authorization-designation">{{
              $t('pages.authorization.authorizationRestorePasswordPage.confirmPassword') }}</p>
            <input type="password" placeholder="confirm password" class="restore__wrapper-authorization-mail"
              v-model="confirmPassword" />
            <button class="restore__wrapper-authorization-enter-btn" @click="saveNewPassword">
              {{ $t('pages.authorization.authorizationRestorePasswordPage.sendButton') }}
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../../components/landing/Header.vue";
import { errorHandler } from "@/lib/errorHandler";

export default {
  props: ['code'],
  components: { Header },
  name: "AuthorizationRestorePasswordPage",
  data() {
    return {
      password: null,
      confirmPassword: null
    }
  },
  methods: {
    async saveNewPassword() {
      if ((this.password && this.confirmPassword) && this.password === this.confirmPassword) {
        await this.$store.dispatch('restorePassword', { code: this.code, password: this.password });
      } else {
        errorHandler({ status: 400, page: 'restore' })
      }
    }
  }
};
</script>


<style scoped>
.restore {
  padding-top: 58px;
  padding-right: 40px;
  display: flex;
  height: calc(100vh);
}

.restore__img {
  display: flex;
  max-width: 733px;
  height: 100%;
  width: 100%;
  background: url("~@/assets/img/restore-page/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.restore__img-logo {
  max-width: 328px;
  margin: auto;
  height: 187px;
}

.restore__wrapper {
  margin: auto auto auto 144px;
  max-width: 683px;
  width: 100%;
}

.restore__wrapper-authorization {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 8px;
  padding: 27px 63px 50px 63px;
  width: 100%;
  box-sizing: border-box;
}

.restore__wrapper-authorization-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #009ed1;
}

.restore__wrapper-authorization-title {
  padding-top: 5px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 35px;
  color: #000000;
  padding-bottom: 4px;
}

.restore__wrapper-authorization-explanation {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #59666a;
}

.restore__wrapper-authorization-designation {
  margin-top: 39px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #595959;
}

.restore__wrapper-authorization-mail {
  margin-top: 8px;
  width: 100%;
  padding: 21px 16px;
  background: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #464655;
  opacity: 0.3;
  box-sizing: border-box;
}

.restore__wrapper-authorization-enter-btn {
  margin-top: 31px;
  width: 100%;
  box-sizing: border-box;
  height: 65px;
  background: #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
}

.restore__wrapper-help-restore {
  margin-top: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #009ed1;
}

.restore__wrapper-help-restore:hover {
  opacity: 0.5;
}

@media (max-width: 1250px) {
  .restore__wrapper {
    margin: auto auto auto 40px;
  }
}

@media (max-width: 1000px) {
  .restore {
    flex-direction: column;
    padding-right: 0;
  }

  .restore__img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    width: 100%;
    height: 220px;
  }

  .restore__img-logo {
    width: 141px;
    height: 86px;
  }

  .restore__wrapper {
    box-sizing: border-box;
    margin: 50px auto auto auto;
    max-width: 683px;
    width: 100%;
    padding: 0px 20px 0px 20px;
  }
}

@media (max-width: 950px) {
  .restore {
    padding-top: 48px;
  }
}

@media (max-width: 600px) {
  .restore__wrapper {
    margin: 25px auto auto auto;
    padding: 0;
  }

  .restore__wrapper-authorization {
    padding: 0px 9px 0px 9px;
    border: none;
  }

  .restore__wrapper-authorization-text {
    font-size: 13px;
  }

  .restore__wrapper-authorization-title {
    font-size: 20px;
    padding-bottom: 0;
  }

  .restore__wrapper-authorization-explanation {
    font-size: 13px;
  }

  .restore__wrapper-authorization-designation {
    margin-top: 30px;
  }

  .restore__wrapper-authorization-mail {
    font-size: 14px;
    padding: 12px 18px;
  }

  .restore__wrapper-authorizationpassword-input {
    font-size: 14px;
    padding: 12px 18px;
  }

  .restore__wrapper-authorization-enter-btn {
    margin-top: 150px;
    height: 42px;
    border-radius: 10px;
    font-size: 13px;
  }

  .restore__wrapper-help-restore {
    margin-top: 20px;
    flex-direction: column;
    padding-right: 0px;
    margin-bottom: 19px;
    font-size: 13px;
    transition: opacity 1s ease;
  }

  .restore__wrapper-help-restore:hover {
    opacity: 0.5;
  }

  .restore__wrapper-help-losepassword {
    font-size: 13px;
  }
}
</style>
