<template>
  <div class="community-wrapper">
    <div class="communitys-separate__left">
      <div class="community__face">
        <img :src="avatarUrl" alt="" class="community__face-img" />
      </div>
      <div class="community__info">
        <h3 class="community__info-name">
          {{
            name ? limit(name, 20) : $t('components.social.separateCommunityMain.noData')
          }}<span class="community__status" :class="{ 'status-cancellation': comType === 'close' }">{{ comType ===
            "close" ? $t('components.social.separateCommunityMain.closed') :
            $t('components.social.separateCommunityMain.open') }}</span>
        </h3>
        <p class="community__info-designation">
          {{ userCount ? userCount : 0 }} {{ $t('components.social.separateCommunityMain.participants') }}
        </p>
      </div>
    </div>
    <button @click="joinToCommunity" v-if="inCommunity !== 'in'" type="button" class="community__message">
      <span class="community__message-icon"></span>{{ $t('components.social.separateCommunityMain.joinCommunity') }}
    </button>
    <div class="community__caption community-mobile">
      <button @click="goToChat" class="community__wright">
        <span class="community__wright-img"></span>{{ $t('components.social.separateCommunityMain.writeToChat') }}
      </button>
      <div v-if="inCommunity === 'in'" class="community__filling">
        <button @click="$emit('leave')" class="community__filling-btn exit">{{
          $t('components.social.separateCommunityMain.leaveCommunity') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import Cookies from "js-cookie";

export default {
  props: ["avatarUrl", "name", "comType", "userCount", "id", "inCommunity"],
  name: "SeparateCommunityMain",
  data() {
    return {
      limit: textSymbolLimit,
    };
  },
  methods: {
    goToChat() {
      this.$router.push({
        name: "communityChat",
        params: { communityId: this.id },
      });
      Cookies.set("avatarCom", this.avatarUrl);
      Cookies.set("nameCom", this.name);
    },
    joinToCommunity() {
      this.$store.dispatch("sendRequestJoinCommunity", {
        id: this.id,
        token: this.$store.getters.token,
        callback: this.callback,
      });
    },
    callback() {
      this.$store.commit("setDataCommunities", null);
      this.$emit("updatePage");
      this.$forceUpdate();
    },
  },
};
</script>


<style scoped>
.community-wrapper {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  padding: 20px 30px;
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 10px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  margin-bottom: 20px;
}

.communitys-separate__left {
  display: flex;
}

.community__face {
  position: relative;
}

.community__face-img {
  width: 64px;
  height: 64px;
  display: flex;
  flex-shrink: 0;
  border-radius: 10px;
}

.community__info {
  margin-left: 19px;
}

.community__info-name {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.community__status {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: rgba(60, 213, 152, 0.20000000298023224);
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  color: #14c686;
}

.community__status.status-cancellation {
  color: #e7665e;
  background: rgba(231, 102, 94, 0.20000000298023224);
}

.community__info-speciality {
  margin-top: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: rgba(23, 22, 37, 0.5);
}

.community__info-designation {
  padding-top: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #009ed1;
}

.community__message {
  background: #009ed1;
  border-radius: 8px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin: auto 0 auto auto;
  width: 214px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
}

.community__message:hover {
  opacity: 0.5;
}

.community__message-settings {
  background-image: url("~@/assets/img/account-img/menu/chat.svg");
  width: 16px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.community__settings-delete {
  padding: 10px 10px 0px 10px;
  border-top: 1px solid #efeff4;
  font-family: "Roboto";
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #e7665e;
  cursor: pointer;
}

.community__message-icon {
  background-image: url("~@/assets/img/account-img/menu/communities-white.png");
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  margin-right: 7px;
  background-size: cover;
}

.community-mobile {
  display: none;
}

@media (max-width: 1575px) {
  .community-wrapper {
    max-width: 100%;
    padding: 0;
    flex-direction: column;
    padding: 20px 14px;
  }

  .community-desctop {
    display: none;
  }

  .communitys-separate__left {
    align-items: center;
  }

  .community__info {
    width: 100%;
  }

  .community__info-designation {
    padding-top: 0;
  }

  .community__info-designation {
    align-items: center;
  }

  .community__info-name {
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .community__place-town {
    color: #696974;
    margin-top: 5px;
  }

  .community__message {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
  }

  .community__info-speciality {
    margin-top: 2px;
  }

  .community__status {
    padding: 5px 11px;
    font-size: 10px;
    line-height: 1;
  }

  .community__info-designation {
    margin-top: 6px;
  }
}

@media (max-width: 950px) {
  .community__info {
    margin-left: 10px;
  }

  .community-mobile {
    display: flex;
  }

  .community__caption {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .community__wright {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: #009ed1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .community__wright-img {
    margin-right: 7px;
    background: url("~@/assets/img/account-img/communities/chat-blue.svg");
    width: 16px;
    height: 15px;
    display: block;
    background-repeat: no-repeat;
  }

  .community__filling {
    border-top: 1px solid #efeff4;
    margin-top: 15px;
    padding-top: 15px;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.03em;
    color: #44444f;
  }

  .community__filling-btn:first-child {
    margin-top: 0;
  }

  .community__filling-btn {
    margin-top: 10px;
    display: inline;
    width: 100%;

    text-align: center;
  }

  .exit {
    color: #db0a0a;
  }
}
</style>
