<template>
	<div class="answer">
		<p class="answer__caption">Enter an answer from the dropdown list</p>
		<div class="answer__entry">
			<input type="text" class="answer__entry-input"
				placeholder="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor " />
			<button class="answer__entry-input-btn" v-on:click="showFilters()">
				<img src="~@/assets/img/test-page/test/drop-down-list.svg" alt="" class="answer__entry-input-btn-img" />
			</button>
		</div>
		<div class="answer__entry-list" v-if="filtersVisible">
			<button class="answer__entry-list-element">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			</button>
			<button class="answer__entry-list-element">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			</button>
			<button class="answer__entry-list-element">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "TestDropdownList",
	data() {
		return {
			filtersVisible: false,
		};
	},

	methods: {
		showFilters() {
			this.filtersVisible = !this.filtersVisible;
		},
	},
};
</script>

<style scoped>
.answer {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.answer__caption {
	margin-top: 20px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #83939a;
}

.answer__entry {
	position: relative;
	margin-top: 15px;
	width: 100%;
	box-sizing: border-box;
}

.answer__entry-input {
	background: #ffffff;
	border: 1px solid #009ed1;
	border-radius: 16px;
	padding: 20px 55px 20px 30px;
	line-height: 1.68;
	width: 100%;
	box-sizing: border-box;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	color: #3b4c68;
}

.answer__entry-input-btn {
	content: "";
	width: 40px;
	height: 40px;
	position: absolute;
	top: calc(50% - 20px);
	right: 10px;
	transition: opacity 1s ease;
}

.answer__entry-input-btn:hover {
	opacity: 0.5;
}

.answer__entry-list {
	position: absolute;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #dae9f3;
	border-radius: 16px;
}

.answer__entry-list-element {
	width: 100%;
	box-sizing: border-box;
	padding: 21px 55px 21px 30px;
	transition: background 1s ease, border 1s ease;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 17px;
	line-height: 1.68;
	background: #fff;
	text-align: left;
	color: #83939a;
}

.answer__entry-list-element:first-child {
	border-radius: 16px 16px 0 0;
}

.answer__entry-list-element:last-child {
	border-radius: 0 0 16px 16px;
}

.answer__entry-list-element:hover {
	background: #eef8ff;
}

@media (max-width: 700px) {
	.answer__entry-input {
		padding: 10px 44px 10px 16px;
		font-size: 11px;
	}

	.answer__entry-input-btn {
		top: calc(50% - 20px);
		right: 0;
	}

	.answer__entry-list-element {
		padding: 11px 44px 11px 16px;
		font-size: 11px;
	}
}
</style>