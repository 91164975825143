<template>
	<router-link :to="{
		name: 'responsePage',
		params: {
			responseId: this.vacancy.id,
		},
	}" class="responses__wrapper-element" v-if="checkCategory()">
		<div class="responses__wrapper-element-caption">
			<div class="responses__wrapper-element-caption-company">
				<img :src="vacancy.companyAvatarUrl" alt="" class="responses__wrapper-element-caption-company-logo-img" />
				<div class="responses__wrapper-element-caption-company-info">
					<div class="responses__wrapper-element-caption-company-info-rating">
						<p class="responses__wrapper-element-caption-company-info-rating-number">
							{{ vacancy.companyRate ? vacancy.companyRate : 0 }}
						</p>
					</div>
					<p class="responses__wrapper-element-caption-company-info-name">
						{{ vacancy.companyName ? limit(vacancy.companyName, 23) : $t('components.account_worker.response.untitled') }}
					</p>
				</div>
			</div>
			<div class="responses__wrapper-element-caption-company-status">
				<p class="responses__wrapper-element-caption-company-status-text" :class="{
					'responses__wrapper-element-caption-company-status-text':
						(this.status === $t('components.account_worker.response.underConsideration') || this.status === $t('components.account_worker.response.waitingResults')),
					'status-text-refusal': this.status === $t('components.account_worker.response.refusal'),
					'status-text-application': (this.status === $t('components.account_worker.response.invitation') || this.status === $t('components.account_worker.response.verified')),
				}">
					{{ status ? status : $t('components.account_worker.response.unknownStatus') }}
				</p>
				<p class="responses__wrapper-element-caption-company-status-date">
					{{
						vacancy.responseDate
							? new Date(vacancy.responseDate).toISOString().slice(0, 10)
							: "undefined"
					}}
				</p>
			</div>
		</div>
		<div class="responses__wrapper-element-description">
			<h2 class="responses__wrapper-element-description-title">
				{{ vacancy.vacancyName ? limit(vacancy.vacancyName, 23) : $t('components.account_worker.response.untitled') }}
			</h2>
			<p class="responses__wrapper-element-description-text">
				{{
					vacancy.vacancyDescription
						? limit(vacancy.vacancyDescription, 60)
						: $t('components.account_worker.response.noDescription')
				}}...
			</p>
			<p class="responses__wrapper-element-description-place">
				{{ vacancy.vacancyCity ? limit(vacancy.vacancyCity, 23) : "undefined" }}
			</p>
		</div>
		<p class="responses__wrapper-element-price">
			{{
				vacancy.vacancyPrice ? vacancy.vacancyPrice + "$" : $t('components.account_worker.response.byAgreement')
			}}
		</p>
		<div class="responses__wrapper-element-detailed"></div>
	</router-link>
</template>

<script>
import { textSymbolLimit } from "../../../../../lib/textSymbolLimit";

export default {
	beforeMount() {
		switch (this.vacancy.responseStatus) {
			case "new":
				this.status = this.$t('components.account_worker.response.underConsideration');
				break;
			case "reject":
				this.status = this.$t('components.account_worker.response.refusal');
				break;
			case "invite":
				this.status = this.$t('components.account_worker.response.invitation');
				break;	
			case "passed":
				this.status = this.$t('components.account_worker.response.waitingResults');		
				break;
			case "checked":
				this.status = this.$t('components.account_worker.response.verified');
				break;
		}
	},
	name: "Vacancy",
	props: {
		vacancy: {
			type: Object,
		},
		category: {
			type: String,
		},
	},
	methods: {
		checkCategory() {
			return this.status === this.category || this.category === "All responses";
		},
	},
	data() {
		return {
			limit: textSymbolLimit,
			DESCRIPTION_SYMBOL_LIMIT: 60,
			status: null,
		};
	},
};
</script>


<style scoped>
.responses__wrapper-element {
	width: 471px;
	height: 270px;
	background: #ffffff;
	border: 1px solid #efeff4;
	position: relative;
	border-radius: 10px;
	box-shadow: inset 0px -1px 0px #e2e2ea;
}

.responses__wrapper-element-caption {
	margin: 20px 25px 0px 29px;
	align-items: center;
	display: flex;
}

.responses__wrapper-element-caption-company {
	align-items: center;
	display: flex;
}

.responses__wrapper-element-caption-company-logo-img {
	background: #000000;
	border-radius: 10px;
	width: 64px;
	height: 64px;
	object-fit: contain;
}

.responses__wrapper-element-caption-company-info {
	margin-left: 16px;
}

.responses__wrapper-element-caption-company-info-rating {
	width: 53px;
	background: rgba(60, 213, 152, 0.20000000298023224);
	border-radius: 20px;
}

.responses__wrapper-element-caption-company-info-rating-number {
	padding-left: 22px;
	background: url("~@/assets/img/responses-page/responses/star.png");
	background-position: 7px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 13px;
	line-height: 23px;
	letter-spacing: 0.1px;
	color: #14c686;
}

.responses__wrapper-element-caption-company-info-name {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.responses__wrapper-element-caption-company-status {
	margin-left: auto;
}

.responses__wrapper-element-caption-company-status-text {
	font-family: "Roboto";
	padding: 0 10px;
	background: rgba(204, 210, 229, 0.20000000298023224);
	border-radius: 20px;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #979eb8;
}

.responses__wrapper-element-caption-company-status-date {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.1px;
	text-align: right;
	color: #686873;
	opacity: 0.4;
}

.responses__wrapper-element-description {
	padding: 17px 27px 15px 29px;
}

.responses__wrapper-element-description-title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #171625;
}

.responses__wrapper-element-description-text {
	padding-top: 7px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #686873;
}

.responses__wrapper-element-description-place {
	padding-left: 23px;
	padding-top: 14px;
	background: url("~@/assets/img/responses-page/responses/place.png");
	background-position-y: 15px;
	background-repeat: no-repeat;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #000000;
}

.responses__wrapper-element-price {
	width: 100%;
	border-top: 1px solid #f1f1f5;
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	padding: 14px 27px 20px 29px;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0.1px;
	color: #009ed1;
}

.responses__wrapper-element-detailed {
	width: 418px;
	margin: 22px 28px 31px 29px;
}

.responses__wrapper-element-detailed-btn {
	width: 100%;
	box-sizing: border-box;
	height: 50px;
	background: #f1f1f5;
	border-radius: 10px;
	font-family: "Roboto";
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	color: #696974;
}

/* Modificators */
.status-text-refusal {
	color: #cf4e5d;
	background: rgba(246, 161, 181, 0.20000000298023224);
	width: 39px;
	margin-left: auto;
	text-align: right;
}

.status-text-application {
	color: #44c194;
	background: rgba(184, 242, 193, 0.20000000298023224);
}

/* /Modificators */
@media (max-width: 1860px) {
	.responses__wrapper-element {
		margin: 0 auto;
	}
}

@media (max-width: 1100px) {
	.responses__wrapper-element {
		width: 298px;
		height: 335px;
	}

	.responses__wrapper-element-caption {
		flex-direction: column;
		margin: 0;
		padding: 18px 20px 22px 22px;
		align-items: normal;
		border-bottom: 1px solid #f1f1f5;
	}

	.responses__wrapper-element-caption-company {
		order: 2;
		margin-top: 20px;
	}

	.responses__wrapper-element-caption-company-info {
		display: flex;
		flex-direction: column;
	}

	.responses__wrapper-element-caption-company-info-rating {
		margin-top: 3px;
		order: 3;
	}

	.responses__wrapper-element-caption-company-info-discription {
		order: 1;
		font-family: "Roboto";
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.1px;
		color: #696974;
	}

	.responses__wrapper-element-caption-company-info-name {
		order: 2;
		font-weight: 700;
	}

	.responses__wrapper-element-caption-company-status {
		order: 1;
		margin: 0;
		display: flex;
	}

	.responses__wrapper-element-caption-company-status-date {
		margin-left: auto;
	}

	.responses__wrapper-element-description-title {
		font-size: 15px;
	}

	.responses__wrapper-element-description-text {
		font-size: 13px;
	}

	.responses__wrapper-element-price {
		padding-bottom: 20px;
	}

	.responses__wrapper-element-detailed {
		display: none;
	}

	.status-text-refusal {
		margin-left: 0;
	}

	.status-text-application {
		margin-left: 0;
	}
}

@media (max-width: 640px) {
	.responses__wrapper-element {
		margin: 0 auto;
	}
}

@media (max-width: 330px) {
	.responses__wrapper-element {
		margin: 0 auto;
	}
}
</style>
