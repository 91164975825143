<template>
	<div class="table-wrapper">
		<table class="table" border="1px" v-for="(item, index) in data" :key="item.id">
			<tr v-if="!item.delete">
				<div class="status">
					<div class="documents__group-element-caption-stage-circle" :class="{
						'documents__group-element-caption-stage-circle':
							item.approved === 'unloaded' ||
							item.approved === null ||
							item.approved === undefined,
						'stage-circle-blue': item.approved === 'checking',
						'stage-circle-green': item.approved === 'approved',
						'stage-circle-red': item.approved === 'rejected',
					}" />
					<p class="documents__group-element-caption-stage-text">
						<span v-if="
							item.approved === 'unloaded' ||
							item.approved === null ||
							item.approved === undefined
						">Download required</span>
						<span v-if="item.approved === 'checking'">Under review</span>
						<span v-if="item.approved === 'approved'">Checked</span>
						<span v-if="item.approved === 'rejected'">Rejected</span>
					</p>
				</div>
			</tr>
			<tr class="table-row" v-if="!item.delete">
				<th class="table-column mb20">
					<input placeholder="Link to translation" class="input-description" v-model="item.translationLink" :disabled="
						item.approved === 'checking' || item.approved === 'approved'
					" />
				</th>
				<th class="table-column table-textarea">
					<textarea placeholder="Description" class="input-description" v-model="item.description" :disabled="
						item.approved === 'checking' || item.approved === 'approved'
					" />
				</th>
				<th class="close-btn">
					<button v-if="item.approved !== 'checking' && item.approved !== 'approved'"
						@click="deleteRow(item.id, index)">
						<img src="~@/assets/img/all-img/documents/close.svg" alt="" />
					</button>
				</th>
			</tr>
		</table>
		<button class="button-add-row" @click="addData">Add a note</button>
	</div>
</template>

<script>
export default {
	name: "TranslationsTable",
	props: {
		data: {
			type: Array,
		},
	},
	methods: {
		addData() {
			this.$store.commit("addTranslationsData");
		},
		deleteRow(id, index) {
			this.$store.commit("deleteTranslations", { id: id, index: index });
			this.$forceUpdate();
		},
	},
};
</script>

<style scoped>
.table {
	margin-top: 20px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
}

.table-column {
	border: solid 1px;
	display: flex;
}

.table-wrapper {
	width: 100%;
	box-sizing: border-box;
	position: relative;
	padding-bottom: 50px;
}

.button-add-row {
	display: flex;
	margin: 0 auto;
}

.table-row {
	position: relative;
}

.button-add-row {
	margin: 0 auto;
	display: flex;
	margin-top: 23px;
	background: #009ed1;
	border-radius: 7px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1px;
	padding: 15px 60px;
	color: #ffffff;
	position: absolute;
	bottom: -25px;
	left: calc(50% - 132px);
}

.table-wrapper table {
	border: none;
}

.table-wrapper tr {
	border: none;
}

.table-wrapper th {
	border: none;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
}

.table-wrapper textarea ::placeholder {
	color: #c7c7cc;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.table-textarea {
	display: flex;
}

.table-textarea textarea {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	height: 100%;
	height: 250px;
}

.documents__group-element {
	margin-top: 0;
}

.table-wrapper input ::placeholder {
	color: #c7c7cc;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.table-wrapper input {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	padding: 13px 16px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
}

.table-row th:nth-child(1) {
	padding-right: 20px;
}

.table-row th:nth-child(2n) {
	padding-right: 20px;
}

th p {
	font-size: 20px;
	margin-bottom: 10px;
}

.table-status {
	max-width: 300px;
	width: 300px;
	box-sizing: border-box;
}

.close-btn {
	width: 16px;
}

.mb20 {
	margin-bottom: 20px;
}

@media (max-width: 816px) {
	.table-wrapper {
		margin-top: 20px;
		margin-left: 0;
	}

	tr {
		display: flex;
		flex-direction: column;
	}

	.table-row th:nth-child(2n + 1) {
		padding-right: 0px;
	}

	.table-row th:nth-child(2n) {
		padding-right: 0px;
	}

	.table-row th:nth-child(3n) {
		padding-bottom: 30px;
	}

	.table {
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.table-row th {
		padding-bottom: 10px;
	}

	.button-add-row {
		position: unset;
		max-width: 300px;
		width: 100%;
		display: block;
	}

	.table-row {
		width: 300px;
	}

	.table-column {
		text-align: center;
	}

	.table-textarea {
		margin-left: 0;
	}

	.table-row {
		max-width: 300px;
		width: 100%;
		box-sizing: border-box;
	}

	.table-status {
		width: 100%;
		box-sizing: border-box;
	}

	.close-btn {
		margin: 0 auto;
	}
}

@media (max-width: 330px) {
	.table {
		text-align: center;
	}
}

.documents__group-element-caption-stage-circle {
	width: 11px;
	height: 11px;
	background: #c4c4c4;
	border-radius: 100%;
	margin: 20px 0;
}

.stage-circle-green {
	background: #14c686;
}

.stage-circle-blue {
	background: #009ed1;
}

.stage-circle-red {
	background: #ff3c47;
}

.status {
	display: flex;
	align-items: center;
}

.documents__group-element-caption-stage-text {
	margin-left: 11px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 12px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #3b4c68;
}
</style>