<template>
  <div>
    <HeaderAccountWorker></HeaderAccountWorker>

    <main class="main">
      <div class="comtainer-account">
        <div class="account">
          <MenuWorker></MenuWorker>

          <div class="account-filling">
            <div class="account-filling-caption">
              <div class="account-filling-caption-description">
                <p class="account-filling-caption-description-text filling-caption-mobile">
                  {{ $t('pages.tests.test_worker.testPreviewPage.previewTest') }}
                </p>
                <h2 class="account-filling-caption-description-title">
                  {{ $t('pages.tests.test_worker.testPreviewPage.testing') }}
                </h2>
                <p class="account-filling-caption-description-text filling-caption-desctop">
                  {{ $t('pages.tests.test_worker.testPreviewPage.testResults') }}
                </p>
              </div>
              <div class="account-filling-caption-cancle">
                <button class="account-filling-caption-cancle-btn" @click="closePreview">
                  {{ $t('pages.tests.test_worker.testPreviewPage.cancel') }}
                </button>
              </div>
            </div>
            <div class="prevertering container-account">
              <div class="prevertering-left">
                <p class="prevertering-left__title">
                  {{ dataTesting?.title }}
                </p>
                <p class="prevertering-left__text">
                  {{ dataTesting?.description }}
                </p>
              </div>
              <img src="../../../assets/img/test-page/test/preview-discription-img.png" alt=""
                class="prevertering__img" />
            </div>
            <div class="border"></div>
            <div class="questions container-account">
              <div class="question" v-for="(item, index) in dataTesting?.questions" :key="item.id">
                <div class="question-info">
                  <p class="question-number">{{ $t('pages.tests.test_worker.testPreviewPage.question') }} #{{ index + 1
                    }}</p>
                  <p class="question__text">
                    {{ item?.title }}
                  </p>
                  <img v-if="item.photoPath" :src="item.photoPath" alt="" class="question__img" />
                  <video v-if="item.videoPath" :src="item.videoPath" class="question__video"></video>
                </div>
                <div class="audio" v-if="item.audioPath">
                  <audio ref="audioPlayer" controls :src="item.audioPath" class="audio__player"></audio>
                  <button v-if="false" class="audio-start" @click="playAudio">
                    <img src="../../../assets/img/audio.svg" alt="" />
                  </button>
                </div>
                <div class="question-choice" v-if="getQuestionType(index) === 'confrontation'">
                  <div class="question-choice__element" :class="getCorrectAnswer(answer, 'choice')"
                    v-for="answer in item.answers" :key="answer.id">
                    <p class="choice__text">
                      {{ answer.body }}
                    </p>
                    <p class="choice__text">
                      {{ getConfrontationAnswer(answer) }}
                    </p>
                  </div>
                </div>
                <div class="inputs" v-if="getQuestionType(index) === 'other'">
                  <div class="input-element" :class="getCorrectAnswer(answer, 'circle')" v-for="answer in item.answers"
                    :key="answer.id">
                    <div class="input-element__circle"></div>
                    <p class="input-element__text">
                      {{ item.body }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="account-filling-end">
              <div class="account-filling-end-finish">
                <button class="account-filling-end-finish-btn" @click="closePreview">
                  <p>{{ $t('pages.tests.test_worker.testPreviewPage.finishViewing') }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MenuWorker from "../../../components/account/account_worker/MenuWorker.vue";
import HeaderAccountWorker from "../../../components/account/account_worker/HeaderAccountWorker";

export default {
  computed: {
    dataTesting() {
      return this.$store.getters.getDataTesting;
    },
    saveTesting() {
      return this.$store.getters.getSaveSetting;
    },
  },
  components: {
    MenuWorker,
    HeaderAccountWorker,
  },
  props: {
    interviewId: {},
  },
  data() {
    return {};
  },
  methods: {
    playAudio() {
      this.$refs.audioPlayer.play();
    },
    getQuestionType(index) {
      let type;
      const confrontation =
        this.dataTesting.questions[index].answers.map((item) => item.comparisonBody).indexOf(null) === -1;

      if (confrontation) {
        type = "confrontation";
      } else {
        type = "other";
      }
      return type;
    },
    getCorrectAnswer(answer, type) {
      const green = type === "circle" ? "green-input" : "green-choice";
      const red = type === "circle" ? "red-input" : "red-choice";
      let index = this.saveTesting.answers.map((item) => item.testingAnswerId).indexOf(answer.id);
      if (index !== -1) {
        return type === "circle" ? (answer.correct ? green : red) : this.saveTesting.answers[index].comparisonBody === answer.comparisonBody ? green : red;
      }
    },
    getConfrontationAnswer(answer) {
      let index = this.saveTesting.answers.map((item) => item.testingAnswerId).indexOf(answer.id);
      if (index !== -1) {
        return this.saveTesting.answers[index].comparisonBody;
      } else {
        return this.$t('pages.tests.test_worker.testPreviewPage.notSelected');
      }
    },
    closePreview() {
      this.$store.commit("clearTesting");
      this.$router.go(-1);
    },
  },
  beforeMount() {
    scroll(0, 0);
    if (!this.dataTesting || !this.saveTesting.testingId) {
      this.$router.go(-1);
    }
  },
  name: "TestPreviewPage",
};
</script>


<style scoped>
.menu {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

video {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.account {
  display: flex;
}

.account-filling {
  padding: 96px 30px 77px 30px;
  max-width: 1518px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.account-filling-end {
  margin-top: 30px;
  border-top: 2px solid #e8e8e8;
  width: 100%;
  box-sizing: border-box;
  padding-top: 102px;
  position: relative;
}

.account-filling-end-add {
  position: absolute;
  top: -33px;
  left: calc(50% - 33px);
  transition: opacity 1s ease;
}

.account-filling-end-add:hover {
  opacity: 0.5;
}

.account-filling-end-add-img {
  padding: 19px;
  background: #009ed1;
  border-radius: 16px;
}

.account-filling-end-finish {
  display: flex;
  justify-content: center;
}

.account-filling-end-finish-btn {
  padding: 23px 0;
  width: 273px;
  background: #009ed1;
  border-radius: 7px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: opacity 1s ease;
}

.account-filling-end-finish-btn:hover {
  opacity: 0.5;
}

.account-filling-end-cancel {
  display: none;
}

.container-account {
  padding: 0;
}

.prevertering {
  display: flex;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e9e9f9;
  border-radius: 19px;
}

.border {
  margin-top: 58px;
  max-width: 100%;
  width: 100%;
  height: 2px;
  background: #e8e8e8;
}

.prevertering-left {
  max-width: 100%;
  width: 100%;
  padding: 70px 38px 53px 62px;
}

.prevertering-left__title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 36px;
  line-height: 1.28;
  color: #3b4c68;
}

.prevertering-left__text {
  padding-top: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6;
  color: #424242;
}

.prevertering-left__time {
  margin-top: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #696974;
}

.prevertering-left__time span {
  color: #009ed1;
}

.prevertering__img {
  max-width: 100%;
  width: 100%;
  border-radius: 0 19px 19px 0;
  object-fit: cover;
  object-position: center;
}

/* question */
.questions {
  margin-top: 58px;
  padding: 63px;
  background: #ffffff;

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.question-number {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 15px;
  line-height: 1.28;
  color: #009ed1;
}

.question-info {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 100%;
  width: 100%;
}

.question__text {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #3b4c68;
}

.status {
  margin-left: 10px;
}

.status-green {
  color: #14c686;
}

.status-red {
  color: #ab3e5e;
}

.question-choice {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.question-choice__element {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.green-choice .choice__text {
  border-color: #14c686;
  background: #eefff5;
}

.red-choice .choice__text {
  border-color: #c6143f;
  background: #faf6f6;
}

.choice__text {
  padding: 22px 22px;
  background: #f6f8fa;
  border: 1px solid #dae9f3;
  border-radius: 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.77;
  color: #3b4c68;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.input-element {
  display: flex;
  align-items: center;
  padding: 22px 22px;

  background: #f6f8fa;

  border: 1px solid #dae9f3;
  border-radius: 16px;
  gap: 22px;
}

.green-input {
  border-color: #14c686;
  background: #eefff5;
}

.red-input {
  border-color: #c6143f;
  background: #faf6f6;
}

.green-input .input-element__circle {
  background: #14c686;
  border-color: #14c686;
}

.red-input .input-element__circle {
  background: #c6143f;
  border-color: #c6143f;
}

.input-element__circle {
  width: 25px;
  height: 25px;
  display: flex;
  flex-shrink: 0;
  border: 2px solid rgba(59, 76, 104, 0.5);
  border-radius: 100%;
}

.input-element__text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 17px;
  line-height: 1.77;
  color: #3b4c68;
}

@media (max-width: 700px) {
  .account-filling {
    padding: 76px 10px 35px 10px;
  }

  .account-filling-end {
    padding-top: 54px;
    margin-top: 38px;
  }

  .account-filling-end-add {
    top: -23px;
    left: calc(50% - 22px);
  }

  .account-filling-end-add-img {
    padding: 13px;
    border-radius: 16px;
    width: 18px;
    height: 18px;
  }

  .account-filling-end-finish-btn {
    padding: 13px 0;
    font-size: 13px;
    width: 300px;
    border-radius: 10px;
  }

  .account-filling-end-cancel {
    margin-top: 11px;
    display: flex;
    justify-content: center;
  }

  .account-filling-end-cancel-btn {
    width: 133px;
    padding: 13px 0;
    background: #f1f1f5;
    border-radius: 10px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    color: #696974;
    transition: opacity 1s ease;
  }

  .account-filling-end-cancel-btn:hover {
    opacity: 0.5;
  }

  .audio-start {
    margin-top: 8px;
    height: 28px;
  }

  .audio-start img {
    width: 28px;
    height: 28px;
  }

  .prevertering {
    flex-direction: column-reverse;
    background: unset;
    border: none;
    border-radius: unset;
  }

  .border {
    margin-top: 23px;
    height: 1px;
  }

  .prevertering-left {
    padding: 0;
  }

  .prevertering-left__title {
    font-size: 17px;
    margin-top: 17px;
  }

  .prevertering-left__text {
    padding-top: 10px;
    font-size: 13px;
  }

  .prevertering-left__time {
    margin-top: 10px;
    font-size: 12px;
  }

  .prevertering__img {
    border: 1px solid #e9e9f9;
    border-radius: 19px;
  }

  /* question */
  .questions {
    margin-top: 23px;
    padding: 0px;
    background: unset;
    border-radius: 0;
    gap: 25px;
  }

  .question {
    gap: 15px;
  }

  .question-number {
    font-size: 13px;
  }

  .question-info {
    gap: 15px;
  }

  .question__text {
    font-size: 13px;
  }

  .status {
    margin-left: 5px;
  }

  .question-choice {
    gap: 12px;
  }

  .question-choice__element {
    gap: 10px;
  }

  .choice__text {
    background: #f6f8fa;
    border: 1px solid #dae9f3;
    border-radius: 13px;
    font-size: 13px;
    line-height: 1.78;
    padding: 10px;
  }

  .inputs {
    gap: 12px;
  }

  .input-element {
    padding: 10px;
    background: #f6f8fa;
    border: 1px solid #dae9f3;
    border-radius: 11px;
    gap: 12px;
    align-items: flex-start;
  }

  .input-element__circle {
    width: 16px;
    height: 16px;
  }

  .input-element__text {
    font-size: 11px;
    line-height: 1.67;
  }
}

/* Caption */
.filling-caption-mobile {
  display: none;
}

.account-filling-caption {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}

.account-filling-caption-description-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #3b4c68;
}

.account-filling-caption-description-text {
  padding-top: 3px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b7c1d1;
}

.account-filling-caption-cancle {
  margin-left: auto;
}

.account-filling-caption-cancle-btn {
  padding: 16px 54px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.1px;
  color: #696974;

  background: #f1f1f5;
  border-radius: 7px;
  transition: opacity 1s ease;
}

.account-filling-caption-cancle-btn:hover {
  opacity: 0.5;
}

@media (max-width: 700px) {
  .filling-caption-desctop {
    display: none;
  }

  .filling-caption-mobile {
    display: block;
  }

  .account-filling-caption-description-text {
    color: #009ed1;
    font-size: 13px;
  }

  .account-filling-caption-description-title {
    color: #000000;
    font-size: 20px;
  }
}

@media (max-width: 460px) {
  .account-filling-caption {
    padding-bottom: 16px;
    flex-direction: column;
    align-items: unset;
    align-items: center;
    text-align: center;
  }

  .account-filling-caption-cancle {
    margin-left: 0;
    margin-top: 15px;
  }

  .account-filling-caption-cancle-btn {
    font-size: 13px;
    padding: 12px 56px;
  }
}

/* /Caption */
</style>
