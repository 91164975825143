export const homePage = {
	actions: {
		fetchCarouselText({ commit }, translatedData) {
			// let data = [
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[0].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[0].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[1].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[1].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[2].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[2].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[3].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[3].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[4].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[4].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[5].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[5].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[6].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[6].body')
			// 	},
			// 	{
			// 		header: $t('pages.landing.homePage.carousel.slides[7].header'),
			// 		body: $t('pages.landing.homePage.carousel.slides[7].body')
			// 	},
			// ];
			commit('setCarouselArray', translatedData);
		},
		fetchCarouselTextRating({ commit }, translatedData) {
			
			// let data = [
			// 	{
			// 		header: $t('pages.landing.homePage.carouselRating.slides[0].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[0].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[1].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[1].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[2].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[2].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[3].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[3].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[4].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[4].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[5].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[5].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[6].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[6].body')
			// 	},
			// 	{
			// 		header:  $t('pages.landing.homePage.carouselRating.slides[7].header'),
			// 		body: $t('pages.landing.homePage.carouselRating.slides[7].body')
			// 	},
			// ];
			commit('setCarouselArrayRating', translatedData);
		}
	},
	mutations: {

		setCarouselArrayRating(state, data) {
			state.allTextRating = data;
			state.currentCarouselTextRating = {
				header: state.allTextRating[state.iterRating].header,
				body: state.allTextRating[state.iterRating].body
			}
		},
		carouselIncrementRating(state) {
			if (state.iterRating + 1 === state.allTextRating.length)
				state.iterRating = 0;
			else state.iterRating++;
			state.currentCarouselTextRating.header = state.allTextRating[state.iterRating].header;
			state.currentCarouselTextRating.body = state.allTextRating[state.iterRating].body;
		},
		previterRatingRating(state) {
			if (state.iterRating !== 0)
				state.iterRating--;
			else state.iterRating = 7
			state.currentCarouselTextRating.header = state.allTextRating[state.iterRating].header;
			state.currentCarouselTextRating.body = state.allTextRating[state.iterRating].body;
		},


		setCarouselArray(state, data) {
			state.allText = data;
			state.currentCarouselText = {
				header: state.allText[state.iter].header,
				body: state.allText[state.iter].body
			}
		},
		carouselIncrement(state) {
			if (state.iter + 1 === state.allText.length)
				state.iter = 0;
			else state.iter++;
			state.currentCarouselText.header = state.allText[state.iter].header;
			state.currentCarouselText.body = state.allText[state.iter].body;
		},
		prevIter(state) {
			if (state.iter !== 0)
				state.iter--;
			else state.iter = 7
			state.currentCarouselText.header = state.allText[state.iter].header;
			state.currentCarouselText.body = state.allText[state.iter].body;
		}

	},
	state: {
		allText: null,
		currentCarouselText: null,
		iter: 0,

		allTextRating: null,
		currentCarouselTextRating: null,
		iterRating: 0
	},
	getters: {
		carousel(state) {
			return state.currentCarouselText;
		},
		iter(state) {
			return state.iter;
		},

		carouselRating: (state) => {
			return state.currentCarouselTextRating;
		},
		iterRating: (state) => {
			return state.iterRating;
		}


	}
}