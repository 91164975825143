<template>
	<div class="modal" id="main">
		<div class="modal-wrapper">
			<button class="modal__close" @click="$emit('close')">{{ $t('components.account_worker.modalWindow.close')
				}}</button>
			<video :src="video" controls class="modal__video">{{
				$t('components.account_worker.modalWindow.video_controls') }}</video>
			<div class="questions">
				<p class="questions__element" v-for="item in questions" :key="item.id">
					{{ item.title }}: {{ item.description }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalWindow",
	props: ['questions', 'video'],
	mounted() {
		document.getElementById('main').onclick = (event) => {
			if ((event.target.id)) {
				this.$emit('close');
			}
		}
	}
};
</script>


<style scoped>
.modal {
	position: relative;
	max-width: 100%;
	width: 100%;
	height: calc(100vh);
	position: fixed;
	z-index: 100000;
	background: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
	position: absolute;
	top: 5%;
	flex-direction: column;
	left: calc(50% - 22%);
	max-width: calc(44%);
	height: 80%;
	padding: 30px;
	box-sizing: border-box;
	width: 100%;
	background: #ffffff;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
	overflow: hidden;
	align-items: flex-start;
}

.modal-wrapper::-webkit-scrollbar {
	width: 10px;
	height: 8px;
	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.modal-wrapper::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.modal__video {
	width: 100%;
	border-radius: 30px;
	background: #000;
	height: 350px;
}

.modal__close {
	position: absolute;
	width: 20px;
	height: 20px;
	background: url(../../../../assets/img/document-page/close.svg);
	background-size: cover;
	background-repeat: no-repeat;
	right: 10px;
	top: 10px;
}

.questions {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	box-sizing: border-box;
	overflow: hidden;
	scrollbar-width: thin;
	height: 57%;
	max-width: 100%;
	width: 100%;
}

.questions:hover {
	overflow-y: scroll;
}

.questions::-webkit-scrollbar {
	width: 10px;
	height: 8px;
	background-color: #f6f8fa;
	border-radius: 0px 8px 8px 0;
}

.questions::-webkit-scrollbar-thumb {
	background-color: #e1e1e1;
	border-radius: 10px;
}

.questions__element {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.78;

	color: #3b4c68;
}

@media (max-height: 1000px) {
	.questions {
		height: 42%;
	}
}

@media (max-width: 1200px) {
	.modal-wrapper {
		left: calc(50% - 30%);
		max-width: calc(60%);
	}
}

@media (max-width: 700px) {
	.modal-wrapper {
		left: unset;
		max-width: calc(80%);
		height: 80%;
		width: 100%;
		top: unset;
	}

	.questions {
		overflow-y: scroll;
		padding-bottom: 30px;
	}

	.modal__video {
		height: 200px;
	}

	.questions {
		height: 67%;
	}

	.questions__element {
		font-size: 14px;
	}
}
</style>