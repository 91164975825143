<template>
	<div>
		<HeaderAccountCompany @close="viewAvatar = false"
			:photos="[...(dataEmployer.logoPath ? [dataEmployer.logoPath] : []), ...(typeof dataEmployer.avatarArchive === 'object' ? dataEmployer.avatarArchive : [])]"
			@delete="deleteAvatar" :avatar="viewAvatar" :change="true"></HeaderAccountCompany>

		<main class="main">
			<div class="account">
				<MenuCompany></MenuCompany>
				<div class="container-account">
					<p class="settings__text mobile">
						{{ $t('pages.account.account_company.accountCompanySettingsPage.welcome') }}
					</p>
					<h1 class="settings__title">{{
						$t('pages.account.account_company.accountCompanySettingsPage.profileSettings') }}</h1>
					<p class="settings__text desctop">
						{{ $t('pages.account.account_company.accountCompanySettingsPage.welcome') }}
					</p>
					<div class="settings-nav desktop">
						<button class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'settingProfile' }"
							@click="switchSettings('settingProfile')">{{
								$t('pages.account.account_company.accountCompanySettingsPage.profileSettings') }}</button>
						<button class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'privacy' }"
							@click="switchSettings('privacy')">{{
								$t('pages.account.account_company.accountCompanySettingsPage.confidentiality') }}</button>
						<button class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'blockedUsers' }"
							@click="switchSettings('blockedUsers')">{{
								$t('pages.account.account_company.accountCompanySettingsPage.blockedUsers') }}</button>
						<button class="settings-nav__element"
							:class="{ 'settings-nav__element--selected': selectedItem === 'about' }"
							@click="switchSettings('about')">{{
								$t('pages.account.account_company.accountCompanySettingsPage.aboutApp') }}</button>
						<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
							$t('pages.account.account_company.accountCompanySettingsPage.help') }}</router-link>
					</div>
					<div class="settings__wrapper" v-if="selectedItem === 'settingProfile'">
						<div class="user-container">
							<div class="user">
								<div class="user-face">
									<button @click="viewAvatar = true" class="user-face-img" :style="{
										pointerEvents: (dataEmployer.logoPath ? 'default' : 'none'),
										cursor: 'pointer',
										backgroundImage: 'url(' + dataEmployer.logoPath + ')',
									}"></button>
									<p class="user-face-name">
										{{ dataEmployer.name ? limit(dataEmployer.name, 23) :
											$t('pages.account.account_company.accountCompanySettingsPage.noData') }}
									</p>
									<p class="user-face-designation">
										{{ $t('pages.account.account_company.accountCompanySettingsPage.employer') }}
									</p>
								</div>
								<div class="user-change">
									<button @click="$refs.file.click()" class="user-change-photo">
										{{ $t('pages.account.account_company.accountCompanySettingsPage.changePhoto') }}
									</button>
									<input type="file" ref="file" @change="handleFileUpload" hidden />
								</div>
								<button @click="$store.dispatch('deleteUser')" class="delete">{{
									$t('pages.account.account_company.accountCompanySettingsPage.deleteAccount')
								}}</button>
							</div>
						</div>
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">
								{{ $t('pages.account.account_company.accountCompanySettingsPage.profileSettings') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button class="settings-nav__element" @click="switchSettings('privacy')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.confidentiality')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('blockedUsers')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.blockedUsers')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('about')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.aboutApp') }}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_company.accountCompanySettingsPage.help') }}</router-link>
							</div>
						</div>
						<div class="content">
							<div class="settings-block info">
								<div class="info-small">
									<div class="info-column">
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.fullName')
											}}</p>
											<input v-model="dataEmployer.name" type="text" class="info-input" />
											<span v-if="$v.dataEmployer.name.$error" class="error-message">
												<span v-if="!$v.dataEmployer.name.required">{{
													$t('pages.account.account_company.accountCompanySettingsPage.invalidField.name')
												}}</span>
												<span v-if="!$v.dataEmployer.name.maxLength">{{
													$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
														{ max: 100 }) }}</span>
											</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.registrationAddress')
											}}</p>
											<input v-model="dataEmployer.legalAddress" type="text" class="info-input" />
											<span v-if="$v.dataEmployer.legalAddress.$error" class="error-message">{{
												$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
													{ max: 200 }) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.residenceAddress')
											}}</p>
											<input v-model="dataEmployer.actualAddress" type="text"
												class="info-input" />
											<span v-if="$v.dataEmployer.actualAddress.$error" class="error-message">{{
												$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
													{ max: 200 }) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.industries')
											}}</p>
											<div class="search__sorting-select-container">
												<button ref="select" class="search__sorting-select"
													@click="industryCategoriesVisible = !industryCategoriesVisible">
													{{
														$t('pages.account.account_company.accountCompanySettingsPage.industry')
													}}
												</button>
												<div v-if="industryCategoriesVisible"
													class="search__sorting-select-speciality-group">
													<div class="search__sorting-select-speciality-group-element"
														v-for="(item, index) in dataIndustries" :key="index">
														<input type="checkbox" :id="item.id" :value="item.id"
															class="search__sorting-select-speciality-group-element-input"
															v-model="selectedCompanyIndustryCategories" />
														<label :for="item.id"
															class="search__sorting-select-speciality-group-element-label">{{
																item.name
															}}</label>
													</div>

												</div>
											</div>
										</div>
									</div>
									<div class="info-column ">
										<div class="settings__field">
											<p class="info-text">
												{{
													$t('pages.account.account_company.accountCompanySettingsPage.organizationName')
												}}
											</p>
											<input type="text" v-model="dataEmployer.orgName" class="info-input" />
											<span v-if="$v.dataEmployer.orgName.$error" class="error-message">{{
												$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
													{ max: 100 }) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.email') }}
											</p>
											<input v-model="dataEmployer.email" type="email" class="info-input" />
											<span v-if="$v.dataEmployer.email.$error" class="error-message">
												<span v-if="!$v.dataEmployer.email.required">{{
													$t('pages.account.account_company.accountCompanySettingsPage.invalidField.email')
												}}</span>
												<span v-if="!$v.dataEmployer.email.email">{{
													$t('pages.account.account_company.accountCompanySettingsPage.invalidField.email')
												}}</span>
												<span v-if="!$v.dataEmployer.email.maxLength">{{
													$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
														{ max: 100 }) }}</span>
											</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.jobTitle')
											}}</p>
											<input v-model="dataEmployer.post" type="text" class="info-input" />
											<span v-if="$v.dataEmployer.post.$error" class="error-message">{{
												$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
													{ max: 100 }) }}</span>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.currency')
											}}</p>
											<div class="search__sorting-select-container">
												<button ref="select" class="search__sorting-select"
													@click="viewCurrencies = !viewCurrencies">
													{{ currencyName ? currencyName :
														$t('pages.account.account_company.accountCompanySettingsPage.currency')
													}}
												</button>
												<div class="search__sorting-select-speciality-group"
													v-if="viewCurrencies">
													<div class="search__sorting-select-speciality-group-element"
														v-for="(item, index) in currencies" :key="index">
														<input type="radio" @change="viewCurrencies = false"
															:id="item.value" :value="item.value"
															class="search__sorting-select-speciality-group-element-input"
															v-model="dataEmployer.expectedSalaryCurrency" />
														<label :for="item.value"
															class="search__sorting-select-speciality-group-element-label">{{
																item.name
															}}</label>
													</div>
												</div>
											</div>
										</div>
										<div class="settings__field">
											<p class="info-text">{{
												$t('pages.account.account_company.accountCompanySettingsPage.lang')
											}}</p>
											<div class="search__sorting-select-container">
												<button ref="select" class="search__sorting-select"
													@click="viewLang = !viewLang">
													{{ $i18n.locale ? locales.find((el) => el.value === $i18n.locale).name :
														$t('pages.account.account_company.accountCompanySettingsPage.lang')
													}}
												</button>
												<div class="search__sorting-select-speciality-group"
													v-if="viewLang">
													<div class="search__sorting-select-speciality-group-element"
														v-for="(item, index) in locales" :key="index">
														<input type="radio" @change="viewLang = false"
															:id="item.value" :value="item.value"
															class="search__sorting-select-speciality-group-element-input"
															@click ="changeLanguage(item.value)" />
														<label :for="item.value"
															class="search__sorting-select-speciality-group-element-label">{{
																item.name
															}}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="info-big">
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_company.accountCompanySettingsPage.moreAboutCompany')
										}}</p>
										<textarea name="" id="" class="info-textaria"
											v-model="dataEmployer.orgDetails" />
										<span v-if="$v.dataEmployer.orgDetails.$error" class="error-message">{{
											$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
												{ max: 300 }) }}</span>
									</div>
									<div class="settings__field">
										<p class="info-text">{{
											$t('pages.account.account_company.accountCompanySettingsPage.description')
										}}</p>
										<textarea name="" id="" class="info-textaria"
											v-model="dataEmployer.companyDescription" />
										<span v-if="$v.dataEmployer.companyDescription.$error" class="error-message">{{
											$t('pages.account.account_company.accountCompanySettingsPage.maxLength',
												{ max: 300 }) }}</span>
									</div>
								</div>
							</div>

							<div class="info-btngroup">
								<button @click="updateSettings" class="info-btngroup-btn btn-blue">
									{{ $t('pages.account.account_company.accountCompanySettingsPage.save') }}
								</button>
								<button class="info-btngroup-btn" @click="$router.go(-1)">
									{{ $t('pages.account.account_company.accountCompanySettingsPage.cancel') }}
								</button>
							</div>
						</div>
					</div>
					<div class="settings__wrapper" v-if="selectedItem === 'privacy'">
						<div class="user-container">
							<div class="user">
								<div class="user-face">
									<button @click="viewAvatar = true" class="user-face-img" :style="{
										pointerEvents: (dataEmployer.logoPath ? 'default' : 'none'),
										cursor: 'pointer',
										backgroundImage: 'url(' + dataEmployer.logoPath + ')',
									}"></button>
									<p class="user-face-name">
										{{ dataEmployer.name ? limit(dataEmployer.name, 23) :
											$t('pages.account.account_company.accountCompanySettingsPage.noData') }}
									</p>
									<p class="user-face-designation">
										{{ $t('pages.account.account_company.accountCompanySettingsPage.employer') }}
									</p>
								</div>
								<div class="user-change">
									<button @click="$refs.file.click()" class="user-change-photo">
										{{ $t('pages.account.account_company.accountCompanySettingsPage.changePhoto') }}
									</button>
									<input type="file" ref="file" @change="handleFileUpload" hidden />
								</div>
								<button @click="$store.dispatch('deleteUser')" class="delete">{{
									$t('pages.account.account_company.accountCompanySettingsPage.deleteAccount')
								}}</button>
							</div>
							<div class="settings-nav mobile">
								<button class="settings-nav__element settings-nav__element--selected"
									@click="openNavList = !openNavList">
									{{ $t('pages.account.account_company.accountCompanySettingsPage.confidentiality') }}
									<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
										:class="{ 'settings-nav__icon--active': openNavList }"></button>
								<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
									<button class="settings-nav__element" @click="switchSettings('settingProfile')">{{
										$t('pages.account.account_company.accountCompanySettingsPage.profileSettings')
									}}</button>
									<button class="settings-nav__element" @click="switchSettings('blockedUsers')">{{
										$t('pages.account.account_company.accountCompanySettingsPage.blockedUsers')
									}}</button>
									<button class="settings-nav__element" @click="switchSettings('about')">{{
										$t('pages.account.account_company.accountCompanySettingsPage.aboutApp')
									}}</button>
									<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
										$t('pages.account.account_company.accountCompanySettingsPage.help')
									}}</router-link>
								</div>
							</div>
						</div>
						<div class="settings-block confidentiality">
							<div class="settings_wrapper-info-checkbox">
								<input type="checkbox" id="invisible-in-friend" v-model="dataEmployer.hidden">
								<label class="friend-checkbox" for="invisible-in-friend">{{
									$t('pages.account.account_company.accountCompanySettingsPage.hideFromFindFriends')
								}}</label>
							</div>
							<div class="settings_wrapper-info-checkbox">
								<input type="checkbox" id="invisible-in-friend"
									v-model="dataEmployer.allowFriendsOnly" />
								<label class="friend-checkbox" for="invisible-in-friend">{{
									$t('pages.account.account_company.accountCompanySettingsPage.preventMessagesFromNonFriends')
								}}</label>
							</div>
							<div class="info-btngroup">
								<button @click="updateSettings" class="info-btngroup-btn btn-blue">
									{{ $t('pages.account.account_company.accountCompanySettingsPage.save') }}
								</button>
								<button class="info-btngroup-btn" @click="$router.go(-1)">
									{{ $t('pages.account.account_company.accountCompanySettingsPage.cancel') }}
								</button>
							</div>
						</div>
					</div>
					<div class="settings__wrapper" v-if="selectedItem === 'blockedUsers'">
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">
								{{ $t('pages.account.account_company.accountCompanySettingsPage.blockedUsers') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button class="settings-nav__element" @click="switchSettings('settingProfile')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.profileSettings')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('privacy')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.confidentiality')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('about')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.aboutApp') }}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_company.accountCompanySettingsPage.help') }}</router-link>
							</div>
						</div>
						<div class="settings-block blocked-users mt15">
							<SeparateFriend v-for="(item, index) in blockedUsers" :is-friends="false" :key="item.id"
								:friend="item" :index="index">
							</SeparateFriend>
							<div v-if="blockedUsers.length <= 0">
								<h2>{{ $t('pages.account.account_company.accountCompanySettingsPage.noBlockedUsers') }}
								</h2>
							</div>
						</div>
					</div>
					<div class="settings_wrapper" v-if="selectedItem === 'about'">
						<div class="settings-nav mobile">
							<button class="settings-nav__element settings-nav__element--selected"
								@click="openNavList = !openNavList">
								{{ $t('pages.account.account_company.accountCompanySettingsPage.aboutApp') }}
								<img src="@/assets/img/select-icon.svg" alt=" " class="settings-nav__icon"
									:class="{ 'settings-nav__icon--active': openNavList }"></button>
							<div class="settings-nav__list" :class="{ 'settings-nav__list--open': openNavList }">
								<button class="settings-nav__element" @click="switchSettings('settingProfile')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.profileSettings')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('privacy')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.confidentiality')
								}}</button>
								<button class="settings-nav__element" @click="switchSettings('blockedUsers')">{{
									$t('pages.account.account_company.accountCompanySettingsPage.blockedUsers')
								}}</button>
								<router-link :to="{ name: 'help' }" class="settings-nav__element">{{
									$t('pages.account.account_company.accountCompanySettingsPage.help') }}</router-link>
							</div>
						</div>
						<div class="blocked-users">

						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import MenuCompany from "../../../components/account/account_company/MenuCompany.vue";
import HeaderAccountCompany from "../../../components/account/account_company/HeaderAccountCompany";
import { errorHandler } from "../../../lib/errorHandler";
import { config } from "@/lib/config";
import { textSymbolLimit } from "@/lib/textSymbolLimit";
import { values } from "@/lib/values";
import SeparateFriend from "@/components/social/account_friensds/SeparateFriend.vue";
import { required, maxLength, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
	components: { SeparateFriend, MenuCompany, HeaderAccountCompany },
	mixins: [validationMixin],
	computed: {
		blockedUsers() {
			return this.$store.getters.getMyBlockedUser;
		},
		currencyName() {
			const index = this.currencies.map(item => item.value).indexOf(this.dataEmployer.expectedSalaryCurrency)
			return index > -1 ? this.currencies[index].name : null;
		},
		user() {
			return this.$store.getters.user
		},
		dataIndustries() {
			return this.$store.getters.dataIndustries;
		},
		selectedIndustriesNames() {
			if (!this.selectedCompanyIndustryCategories) return 'No industries selected';

			// Фильтрация списка отраслей, чтобы найти те, что были выбраны
			const selected = this.dataIndustries.filter(industry =>
				this.selectedCompanyIndustryCategories.includes(industry.id)
			);
			// Возвращение строк с именами выбранных отраслей, разделенных запятой
			return selected.map(industry => industry.name).join(', ') || 'No industries selected';
		},

	},

	validations: {
		dataEmployer: {
			actualAddress: { maxLength: maxLength(200) },
			companyDescription: { maxLength: maxLength(300) },
			legalAddress: { maxLength: maxLength(200) },
			orgDetails: { maxLength: maxLength(300) },
			email: { required, email, maxLength: maxLength(100) },
			post: { maxLength: maxLength(100) },
			name: { required, maxLength: maxLength(100) },
			orgName: { required, maxLength: maxLength(100) },
		}
	},

	data() {
		return {
			dataEmployer: {},
			file: "",
			checkChangeFile: false,
			limit: textSymbolLimit,
			viewAvatar: false,
			currencies: values,
			viewCurrencies: false,
			viewLang: false,
			viewAdditionalSettings: false,
			industryCategoriesVisible: false,
			openNavList: false,
			selectedCompanyIndustryCategories: [],
			locales:[
			{
				value:"ru",
				name:"Русский"
			}, 
			{
				value:"en",
				name:"English"
			}],
			selectedItem: 'settingProfile',
		};
	},
	name: "AccountCompanySettingsPage",
	methods: {
		checkEmail(value) {
			const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			return regex.test(value)
		},
		deleteAvatar(iter) {
			if (iter === -1) {
				this.dataEmployer.logoPath = "";
				if (this.dataEmployer?.avatarArchive?.length) {
					this.dataEmployer.logoPath = this.dataEmployer?.avatarArchive[0];
					this.dataEmployer.avatarArchive.shift();
					this.checkChangeFile = false;
				}
			} else {
				this.dataEmployer.avatarArchive.splice(iter, 1);
			}
			this.viewAvatar = false;
		},
		checkEmptyField() {
			let stack = [];
			if (!this.dataEmployer.email || !this.checkEmail(this.dataEmployer.email))
				stack.push('email');
			if (!this.dataEmployer.orgName || this.dataEmployer.orgName.replace(/\s/g, "") === '')
				stack.push('companyName');
			if (!this.dataEmployer.name || this.dataEmployer.name.replace(/\s/g, "") === '')
				stack.push('name');

			if (stack.length !== 0) {
				errorHandler({ status: 400, page: 'invalidField', stack: stack });
				stack = [];
				return false;
			} else {
				return true;
			}
		},
		updateSettings() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return;
			}
			if (this.checkChangeFile) this.loadFile();
			this.saveEmployerSettings();
			scroll(0, 0);
		},
		saveEmployerSettings() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return;
			}
			if (this.checkEmptyField()) {
				let companyIndustryCategories = this.selectedCompanyIndustryCategories.length > 0 ? this.selectedCompanyIndustryCategories : [];
				let saveDataEmployer = {
					orgName: this.dataEmployer.orgName,
					phone: this.dataEmployer.phone,
					name: this.dataEmployer.name,
					surname: this.dataEmployer.surname,
					lastName: this.dataEmployer.lastName,
					post: this.dataEmployer.post,
					avatarArchive: this.dataEmployer.avatarArchive.join(","),
					orgDetails: this.dataEmployer.orgDetails,
					legalAddress: this.dataEmployer.legalAddress,
					actualAddress: this.dataEmployer.actualAddress,
					logoPath: this.dataEmployer.logoPath,
					expectedSalaryCurrency: this.dataEmployer.expectedSalaryCurrency,
					companyDescription: this.dataEmployer.companyDescription,
					email: this.dataEmployer.email,
					hidden: this.dataEmployer.hidden,
					companyIndustryCategories: companyIndustryCategories,
					allowFriendsOnly: this.dataEmployer.allowFriendsOnly
				};
				let xmlHttp = new XMLHttpRequest();
				let url = config.apiPrefix + "employer/update";
				xmlHttp.open("POST", url, false);
				xmlHttp.setRequestHeader("X-Auth-Token", this.$store.getters.token);
				xmlHttp.setRequestHeader(
					"Content-Type",
					"application/json;charset=UTF-8"
				);
				try {
					xmlHttp.send(JSON.stringify(saveDataEmployer));
					errorHandler({ status: xmlHttp.status });
				} catch (e) {
					console.log(e);
				}
				// updating upper page logo and name
				this.$store.dispatch("changeAvatar", this.dataEmployer.logoPath);
				this.$store.dispatch("changeName", this.dataEmployer.name);
				this.fetchDataEmployer();
			}
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[this.$refs.file.files.length - 1];
			if (this.dataEmployer.logoPath) {
				this.dataEmployer?.avatarArchive?.unshift(this.dataEmployer?.logoPath);
			}
			this.dataEmployer.logoPath = URL.createObjectURL(this.file);
			this.checkChangeFile = true;
		},
		loadFile() {
			let formData = new FormData();
			formData.append("file", this.file);
			let xmlHttp = new XMLHttpRequest();
			let url = config.apiPrefix + "file/upload";
			xmlHttp.open("POST", url, false);
			try {
				xmlHttp.send(formData);
				let response = JSON.parse(xmlHttp.response);
				this.dataEmployer.logoPath = response.data.url;
			} catch (e) {
				console.log(e);
			}
		},
		deletePagination() {
			window.onscroll = null
		},
		fetchDataEmployer() {
			this.$store.dispatch('fetchEmployerData').then(() => {
				this.dataEmployer = JSON.parse(JSON.stringify(this.user));
				this.dataEmployer.avatarArchive = this.dataEmployer.avatarArchive ? this.dataEmployer.avatarArchive?.split(",").filter((item) => item) : [];
				this.checkChangeFile = false;
				if (this.dataEmployer.companyIndustryCategories && this.dataEmployer.companyIndustryCategories.length > 0) {
					this.selectedCompanyIndustryCategories = this.dataEmployer.companyIndustryCategories;
				}
			})
		},
		switchSettings(selectedItem) {
			this.openNavList = false;
			this.selectedItem = selectedItem;
		},
		changeLanguage(lang) {
			this.$i18n.locale = lang;
		}
	},
	beforeMount() {
		scroll(0, 0);
		this.fetchDataEmployer();
		this.$store.dispatch("getIndustries", this.$i18n.locale);
		this.$store.dispatch("fetchMyBlockedFriends", {
			token: this.$store.getters.token,
			page: this.$store.getters.friendPage,
			role: 'all',
			limit: this.$store.getters.getLimit,
			callback: this.deletePagination
		});
	},
};
</script>


<style scoped>
.error-message {
	color: red;
	font-size: 12px;
	margin-top: 0.5rem;
}

.blocked-users {
	width: 100%;
	min-height: 250px;
}

.account {
	display: flex;
}

.settings__title {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #3b4c68;
}

.settings-nav {
	margin-top: 24px;
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.settings-nav__element {
	padding: 14px 0;
	transition: all 0.5s ease;
	font-family: "SFUIDisplay", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 1.2;
	letter-spacing: -0.01em;
	text-align: left;
	color: rgba(0, 0, 0, 0.5);
}

.settings-nav__element--selected {
	color: #009ed1;
	background: #eaf5f9;
	border-radius: 7px;
	padding: 14px 50px;
	display: flex;

}

.content {
	max-width: 100%;
	width: 100%;
}

.town-select {

	max-height: 300px;
	overflow: auto;
}

.select-btn {
	padding: 7px 12px;
	font-size: 16px;
}

.settings__text {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #b7c1d1;
}

.settings__wrapper {
	margin-top: 32px;
	display: flex;
	gap: 69px;
	align-items: flex-start;
}

.user-container {
	max-width: 315px;
	width: 100%;
}

.user {
	max-width: 100%;
	width: 100%;
	padding: 35px 45px 27px 45px;
	border-radius: 20px;
	text-align: center;
	border: 1px solid rgba(59, 76, 104, 0.1);
	border-radius: 20px;
}

.delete {
	display: block;
	margin: 20px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	text-align: center;
	padding: 14px 32px 16px 32px;
	background: #f1f5f9;
	border-radius: 8px;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: #009ed1;
}


.user-face-img {
	cursor: pointer;
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background-position: center;
	background-repeat: repeat;
	background-size: auto;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 100%;
}

.user-face-name {
	margin-top: 20px;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.02em;
	color: #3b4c68;
}

.user-face-designation {
	padding-top: 7px;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #b7c1d1;
}

.user-change {
	margin-top: 20px;
	background: #009ed1;
	border-radius: 8px;
}

.user-change-photo {
	height: 45px;
	width: 100%;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #ffffff;
	transition: opacity 1s ease;
}

.user-change-photo:hover {
	opacity: 0.5;
}

.info {
	max-width: 100%;
	width: 100%;
}

.info-small {
	display: flex;
	gap: 20px;
	justify-content: space-between;
	max-width: 100%;

}


.info-column {
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.info-text {
	overflow-y: hidden;

	width: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #595959;
}

.info-input {
	display: flex;
	align-items: center;
	margin-top: 8px;
	padding-left: 16px;
	height: 46px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #171725;
	flex-shrink: 1;

	width: 100%;
	padding-right: 20px;
	-webkit-appearance: none;
}

.info-input::placeholder {
	color: #b7c1d1;
}

.info-big {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.info-textaria {
	font-family: "Montserrat";

	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.25px;
	text-align: left;
	margin-top: 8px;
	width: 100%;
	height: 95px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	padding: 12px 16px;
	color: #171725;
}

.info-textaria::placeholder {
	color: #b7c1d1;
}


.info-btngroup {
	display: flex;
	margin-top: 51px;
	width: 100%;

}

.info-btngroup-btn {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.1px;
	text-align: center;
	color: #696974;
	width: 100%;
	height: 65px;
	background: #f1f1f5;
	border-radius: 7px;
	transition: opacity 1s ease;
}

.info-btngroup-btn:hover {
	opacity: 0.5;
}

.btn-blue {
	margin-right: 54px;
	color: #ffffff;
	background: #009ed1;
}

.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	width: 100%;
	max-width: 100%;
}

.confidentiality {
	display: flex;
	flex-direction: column;
	gap: 20px;
}


.mobile {
	display: none;
}


.blocked-users {
	gap: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.blocked-users :deep(.friend-wrapper) {
	margin-bottom: 0;
}

@media (max-width: 1576px) {
	.blocked-users {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.settings__wrapper {
		gap: 30px;
	}
}

@media (max-width: 1028px) {
	.settings__title {
		text-align: center;
	}

	.settings__text {
		text-align: center;
	}

	.settings__wrapper {
		margin-top: 20px;
		flex-direction: column;
	}

	.user-container {
		margin: 0 auto;
	}

	.info {
		margin-left: 0;
	}

	.user {
		margin: 0px auto 0 auto;
	}

	.info-btngroup {
		justify-content: space-around;
	}

	.info-btngroup-btn {
		height: 42px;
		width: 300px;
		border-radius: 10px;
	}


}

@media (max-width: 767px) {
	.mobile {
		display: block;
	}

	.desktop {
		display: none;
	}

	.settings__wrapper {
		margin-top: 20px;
	}

	.settings__text {
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.info-small {
		flex-direction: column;
	}

	.info-column {
		max-width: 100%;
	}

	.settings-nav {
		position: relative;
		max-width: 315px;
		width: 100%;
		margin: 8px auto;
	}

	.settings-nav__list {
		margin-top: 8px;
		position: absolute;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border: 1px solid #efeff4;
		border-radius: 10px;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
		padding: 33px 16px;
		gap: 8px;
		transform: translateY(10%);
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s ease;
		z-index: 2;
	}

	.settings-nav__list--open {
		transform: translateY(0%);
		opacity: 1;
		pointer-events: all;
	}

	.settings-nav__element {
		padding: 13px 22px;
		background: #efeff4;
		border-radius: 8px;
	}

	.settings-nav__element--selected {
		max-width: 100%;
		width: 100%;
		background: #eaf5f9;
		justify-content: space-between;
		gap: 10px;
		border-radius: 7px;
		align-items: center;
	}

	.settings-nav__icon {
		display: flex;
		flex-shrink: 0;
		width: 12px;
		height: 12px;
		transition: all 0.5s ease;
	}

	.settings-nav__icon--active {
		transform: rotate(180deg);
	}

	.grid-2 {
		gap: 10px;
		grid-template-columns: 1fr;
	}

	.info-big {
		gap: 10px;
	}

	.info-column {
		gap: 10px;
	}

	.info-small {
		gap: 10px;
	}
}

@media (max-width: 720px) {
	.blocked-users {
		gap: 20px;
	}
}

@media (max-width: 640px) {
	.info-btngroup {
		flex-direction: column;
	}

	.info-btngroup-btn {
		margin: 0 auto 15px auto;
	}

}

@media (max-width: 570px) {
	.blocked-users {
		grid-template-columns: repeat(1, 1fr);
	}

	.blocked-users:deep(.friend-wrapper) {
		max-width: 100%;
	}
}

@media (max-width: 370px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.settings__text {
		text-align: left;
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		letter-spacing: 0.1px;
		color: #009ed1;
	}

	.settings__title {
		text-align: left;
		font-family: "Montserrat";
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		color: #000000;
	}

}

.search__sorting-select-container {
	width: 100%;
	height: 46px;
	margin-top: 8px;
	position: relative;

	display: block;
	align-items: center;
	transition: border 1s ease;
	border-radius: 8px;
}

.search__sorting-select-container {
	width: 100%;
	height: 46px;
	margin-top: 8px;
	position: relative;

	display: block;
	align-items: center;
	transition: border 1s ease;
	border-radius: 8px;
}

.search__sorting-select {
	text-align: left;
	padding: 10px 20px 9px 20px;
	background: #ffffff;
	border: 1px solid #bcbcbc;
	border-radius: 8px;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 1.76;
	color: #424242;
	width: 100%;
	transition: color 1s ease, border 1s ease;
	background-image: url("~@/assets/img/all-img/list-grey.svg");
	background-repeat: no-repeat;
	background-position: calc(96%);
	background-size: 10px;
}

.search__sorting-select-speciality-group {
	background: #fff;
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;

	z-index: 100;
	max-height: 250px;
	overflow: auto;
	scrollbar-width: thin;
	border: 1px solid #b4b4bb;
	border-radius: 8px;
}

.search__sorting-select-speciality-group-element {
	cursor: pointer;
	width: 100%;
	border-bottom: 1px solid #b4b4bb;
	border-top: none;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #787885;
	box-sizing: border-box;
	transition: background-color 1s ease, color 1s ease;
	display: flex;
	padding-left: 4px;
}

.search__sorting-select-speciality-group-element-input {}

.search__sorting-select-speciality-group-element-label {
	width: 100%;
	box-sizing: border-box;
	display: block;
	width: 100%;
	padding: 7px 12px;
	cursor: pointer;
}


.search__sorting-select-speciality-group-element:hover {
	background-color: #eefbff;
	color: #009ed1;
}

.search__sorting-select-speciality-group-element:last-child {
	border-bottom: none;
}


.search__sorting-select:hover {
	color: #19191d;
	border: 1px solid #009ed1;
	background-image: url("~@/assets/img/all-img/list-blue.svg");
}

.search__sorting-select-speciality-group-element-label {
	width: 100%;

	display: block;
	width: 100%;
	padding: 7px 12px;
	cursor: pointer;
}

.settings_wrapper-info-checkbox {
	width: 100%;
}

.friend-checkbox {
	margin-left: 10px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: 16px;
	color: #171725;
}


.header__wrapper-lang-wrapper {

	background: #fff;
	border: 1px solid #EAEAEE;
	border-radius: 6px;
	position: relative;
	margin-right: 10px;
	font-family: "Roboto";
	font-weight: 400;
	font-size: 14px;
	color: #171725;
}

.header__wrapper-lang-selected {
	padding: 5px 25px 5px 5px;
	background: url(../../../assets/img/all-img/list-grey.svg);
	background-position: 33px;
	background-size: 8px;
	background-repeat: no-repeat;
}

.header__wrapper-lang-choice {
	position: absolute;
	left: -1px;
	bottom: -22px;
}

.header__wrapper-lang-choice-element {
	padding: 5px 24px 5px 5px;
	background: #fff;
	border-radius: 6px;
	border: 1px solid #EAEAEE;
	border-radius: 6px;
	transition: all 1s ease-in-out;
	color: #000;
}

.header__wrapper-lang-choice-element:hover {
	background: #e3f4fa;
	color: #009ed1;
}

.header__wrapper-lang {
	display: flex;
	gap: 3px;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.1px;
	color: #000;
	cursor: pointer;

}

.header-lang__element {
	transition: opacity 1s ease;
	color: inherit;
}

.header-lang__element:hover {
	opacity: 0.5;
}

.lang-chosen {

	color: #009ed1;
}
</style>